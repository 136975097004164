import React from 'react';
import ModalWindow from "./Modal";
import styled from "styled-components";
import ReactImageMagnify from "react-image-magnify";

type Props = {
    title: string;
    width: string;
    cancelModal: () => void;
    src: string;
}

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

function ImageModal({title, width, cancelModal, src}: Props) {
    return (
        <ModalWindow title={title} width={width} cancelModal={cancelModal} cancelText={'Close'} backgroudHeight={'auto'}>
            <ImageContainer>
                <ReactImageMagnify
                    {...{
                        smallImage: { isFluidWidth: true, src: src, width: 700, height: 380 },
                        largeImage: { src: src, width: 900, height: 500 },
                        enlargedImageContainerStyle: { background: "#fff", zIndex: 5999, enlargedImagePosition: "over" },
                    }}
                    />
            </ImageContainer>
        </ModalWindow>
    );
}

export default ImageModal;