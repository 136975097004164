import React, { useState, useEffect } from "react";
import { portalPromise } from "../../../../../components/Dialog";
import { Button } from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import { useForm } from "react-hook-form";
import { upload } from "../../../../../Auth";
import { Theme, Icon, Text, SubTitle } from "@dspworkplace/ui";
import styled from 'styled-components';
import { DropUploader } from "../../../../../components/DropUploader";
import { mimeTypes } from "../../../../../patterns";

const DropUploaderList = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
`;

const DropUploaderPreview = styled.div`
    box-sizing: border-box;
    width: 145px;
    height: 145px;
    padding: 4px;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid ${Theme.colors.neutrals.silver};
    background-color: ${Theme.colors.extra.whiteSmoke};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: ${Theme.font.extraSmall.size};
    font-family: ${Theme.font.main};
    color: ${Theme.colors.neutrals.silver};
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    margin-right: 8px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-size: cover;
        background-position: center;
        background-image: url(${props => typeof props.preview === 'string' && props.preview});
    }

    + div,
    + label {
        // margin-right: 8px;
    }

    button {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 4px;
        padding: 4px;
        height: auto;
        line-height: 0;
    }

    label& {
        cursor: pointer;
        border-style: dashed;

        &:hover,
        &.highlight {
            background-color: ${Theme.colors.info.bg};
        }

        &:active, {
        &.highlight {
            border-color: ${Theme.colors.secondary};
            box-shadow: 0 0 6px ${Theme.colors.secondary};
        }
    }
`;

const UploadForm = ({ success = () => {}, cancel = () => {}, task = {} }) => {

    const { register, handleSubmit, errors, control, watch } = useForm({
        defaultValues: {},
    });
    const [images, setImages] = useState(task.images);
    const [isNotValid, setIsNotValid] = useState(false);
    const onSubmit = async (data) => {
        if (data.images.length > 0) {
            let isUpload = [];
            for (let i = 0; i < data.images.length; i++) {
                let file = data.images[i];
                let isCheck = mimeTypes["images"].includes(file.type) || false;
                isUpload.push(isCheck);
            }
            if (!isUpload.includes(false)) { 
                let formData = new FormData();
                Array.from(data.images).forEach((file) => formData.append("image[]", file));

                let res = await upload(formData).catch((err) => console.log(err));

                let nImages = images || [];
                if (data.images.length == 1) {
                    nImages.push(res.data.s3URL);
                    success(nImages);
                } else {
                    let temp = res.data.map((d) => d.s3URL);
                    if (nImages) {
                        nImages = nImages.concat(temp);
                    } else {
                        nImages = temp;
                    }
                    success(nImages);
                }
            } else {
                setIsNotValid(true);
            }
        }
    }

    const expand = file => {
        let w = window.open(file, '_blank', `width=${window.outerWidth * .9},height=${window.outerHeight * .9},scrollbars=1`);

        if (!file.match(/^http/i)) {
            w.document.write(`
                <img src="${file}"/>
            `);
        }
    };

    const download = async file => {
        let blob = await fetch(file).then(res => res.blob(), rej => false);

        if (!blob)
            return window.open(file);

        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = file.split('/').pop();
        anchor.click();
    }

    let regexCheck= /\.(jpg|JPG|gif|GIF|png|PNG)$/;
    return (
        <Modal
            width={"650px"}
            visible={true}
            title={"Task Upload Image"}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DropUploader
                    name='images'
                    label={'Images'}
                    multiple={true}
                    ref={register({required: 'Please select image'})}
                    hideAfterFileSelect={false}
                    onDelete={async (key, file) => {
                        // if (documents[key]) {
                        //     let nDocuments = [...documents];
                        //     nDocuments.splice(key, 1);
                        //     setDocuments(nDocuments);
                        // }
                    }}
                    accept={"image/jpeg,image/png,image/gif"}
                    Required
                    error={errors.images && errors.images.message}
                    valid={!errors.images && images && images.length > 0}
                />
                <Text style={{color:"red"}}>{errors.images ? errors.images.message : isNotValid && 'Only image allowed jpg,png,gif.'}</Text>
                <SubTitle style={{ paddingTop: "30px", paddingBottom: "10px" }}>Images List</SubTitle>
                <DropUploaderList>
                    {images && images.map((file, k) =>
                        <DropUploaderPreview
                            key={k}
                            preview={file}
                        >

                            <Button type='delete' Type='button' onClick={() => {
                                let nImages = [...images];
                                nImages.splice(k, 1);
                                setImages(nImages);
                            }} style={{ top: 'unset', bottom: '4px' }}>
                                <Icon.Times size='16px' color={Theme.colors.error.text} />
                            </Button>
                            {regexCheck.test(file) && (
                                <Button Type='button' onClick={() => expand(file)}>
                                    <Icon.Expand size='16px' />
                                </Button>
                            )}
                            {!regexCheck.test(file) && typeof file === 'string' && (
                                <>
                                    <Button Type='button' title={`Download ${file}`} onClick={() => download(file)}>
                                        <Icon.Download size='16px' />
                                    </Button>
                                    <Icon.Document />
                                    <Text>{file.split('/').pop().substr(-20, 20)}</Text>
                                </>
                            )}
                            {!regexCheck.test(file) && typeof file !== 'string' && file}
                        </DropUploaderPreview>
                    )}
                </DropUploaderList>

                <Button type="primary" style={{ display: "block", marginLeft: "auto" }}>
                    Save
                </Button>
            </form>
        </Modal>
    );
};

export const openUploadTaskImageForm = (task=null) => {
    return portalPromise((success, cancel) => {
        return <UploadForm success={success} cancel={cancel} task={task} />;
    });
};
