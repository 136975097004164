import styled from "styled-components";
import React from "react";
import {Theme} from '@dspworkplace/ui';

// ${props => (props.gap ? props.gap : "8px")};
export const FirstRow = styled.div`
    display: grid;
    flex-direction: row;
    grid-column-gap: 8px;
    grid-template-columns: max-content auto;
    align-items: center;

    &.sectionTitle {
        grid-column-gap: 16px;
    }

    .itemContent {
        color: #516F90;
        font-family: circe-rounded, serif;
        font-size: 18px;
    }
`;

const defaultShadow = (color) => {
    return `0px 0px 6px ${color}`;
}

export const PrimaryButton = styled.div`
    font-family: circe-rounded, serif;
    font-weight: bold;
    border-radius: ${Theme.defaultRadius};
    cursor: pointer;
    background: ${Theme.colors.info.bg}
    color: ${Theme.colors.secondary}
    padding: 0 16px;
    font-size: ${Theme.font.medium.size};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => (props.width ? props.width : "80px")};
    height: 32px;
    
    > * {
        border-color: ${Theme.colors.info.shadow};
    }
    
    &.icon {
        width: ${props => (props.size ? props.size : "24px")};
        height: ${props => (props.size ? props.size : "24px")};
        padding: 0;
    }

    svg path {
        fill: ${Theme.colors.secondary};
    }

    &:hover,
    &.hover {
        background: ${Theme.colors.info.shadow};
    }

    &:focus,
    &.focus {
        background: ${Theme.colors.info.bg};
        box-shadow: ${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &:active,
    &.active {
        background: ${Theme.colors.info.shadow};
        box-shadow: ${defaultShadow(Theme.form.button.secondary.bg)};
    }

    &.selected {
        border: 1px solid ${Theme.form.button.secondary.bg};
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed;
    }

`;


// type DivComponentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
// const DivComponent = ({children, ...otherProps}: ButtonComponentProps) => {
//     return <Button {...otherProps}>{children}</Button>;
// }
// const OptionsContainer = styled.div<DivComponentProps> `
//
// `;
// const Div = styled.div< React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>`
//
// `;
// type ButtonComponentProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
export type ButtonComponentProps = {
    children?: HTMLCollection | string | Element | any,
    onClick: (e?: React.MouseEvent) => void
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = styled.button`
    font-family: circe-rounded, serif;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    font-size: 16px;
    background: #EAF0F6;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0071BC;
    padding: 0;
    margin: 0;

    &:active,
    &:focus,
    &:after {
        //background: #CBD6E2;
        outline: 0;
        text-shadow: none;
        box-shadow: none;
        border-radius: 2px;
    }

    &:hover {
        background: #CBD6E2;
        box-shadow: 0 0 6px #0071bc;
        border-radius: 2px;
    }
`;

const ButtonComponent = ({children, ...otherProps}: ButtonComponentProps) => {
    return <Button {...otherProps}>{children}</Button>;
};

export {ButtonComponent};