import React, {useCallback, useMemo, useState} from 'react';
import { Popover, Transition } from '@headlessui/react'
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';
import {Placement} from '@popperjs/core'
// @ts-ignore
import style from './tooltip.module.css';

interface ActionIconProps {
    direction?: Placement
    children: React.FC | React.ReactNode,
    content: React.FC | React.ReactNode | string | number,
    startOpen?: boolean,
    trigger?: 'hover' | 'click'
}

const Tooltip = ({direction = 'auto',children,content, startOpen, trigger='hover'}:ActionIconProps) => {
    const [isShowing, setIsShowing] = useState(startOpen)
    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()
    const [arrowElement, setArrowElement] = useState();

    const {attributes, styles} = usePopper(referenceElement,popperElement, {
        placement: direction,
        modifiers: [
            { name: 'offset', options: { offset: [0,8] } },
            { name: 'arrow', options: { element: arrowElement } }
        ]
    })

    const isClick = useMemo(()=> trigger === 'click',[trigger]);

    const handleMouseEnter = useCallback(()=>{
        !isClick && setIsShowing(true)
    },[isClick])

    const handleMouseLeave = useCallback(()=>{
        !isClick && setIsShowing(false)
    },[isClick])

    return (
        <Popover
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={style.reference}>
                {/*@ts-ignore*/}
                <Popover.Button ref={setReferenceElement} as='div'>
                    {children}
                </Popover.Button>
            </div>
            <PanelPortal>
                <Transition show={isClick ? undefined : isShowing}>
                    {/*@ts-ignore*/}
                    <Popover.Panel as='div' ref={setPopperElement} className={style.tooltip} style={styles.popper} {...attributes.popper} static={!isClick} focus={true}>
                        <div className={`${style.content} ${trigger}`}>
                            {content}
                        </div>
                        {/*@ts-ignore*/}
                        <div ref={setArrowElement} style={styles.arrow} className={style.arrow} data-popper-arrow="true"/>
                    </Popover.Panel>
                </Transition>
            </PanelPortal>
        </Popover>
    )
}

const PanelPortal = ({children}) => {
    // @ts-ignore
    return createPortal(children,document.getElementById('modal-root'))
}

export default React.memo(Tooltip);