import React, {useContext} from "react";
import {SubTitle} from "@dspworkplace/ui";
import SettingsContext from "../context";
import {GradingTable} from "../../Routes/ScheduleBalance/filter";

const App = () => {
    const [state, dispatch] = useContext(SettingsContext);

    return <section>
        <SubTitle>Grading Settings</SubTitle>
        <div style={{width: 560}}>
            <GradingTable
                rules={state.gradingRules}
                onAdd={item => dispatch({ type: 'ADD_RULE', payload: item })}
                onEdit={(item, index) => dispatch({ type: 'UPDATE_RULE', payload: {item, index} })}
                onDelete={(item, index) => dispatch({ type: 'DELETE_RULE', payload: {item, index} })}
            />
        </div>
    </section>
}

export default App;