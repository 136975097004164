import React from 'react';
import {Icon, Spacer, Theme, SubTitle, Text, Button} from "@dspworkplace/ui";
import styled from "styled-components";
import {getCompanyId} from "../../Auth";
import Skeleton from '../../components/Skeleton';
import {Link, useLocation} from "react-router-dom";
import {useSelectedStation} from "../../components/StationSelector";
import {AccessCheck, IsGranted} from "../../security";

const Card = styled.div`
    display: block;
    box-sizing: border-box;
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0 3px 6px #33333326;
    width: 340px;
    height: 100%;
    border-radius: ${Theme.defaultRadius};

    .icon-action {
        &:hover {
            > path {
                fill: ${Theme.colors.info.text};
            }
        }
    }
`;

const Row = styled.div`
    box-sizing: border-box;
    width: 100%;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;

    > div:not(.small) {
        flex: 1;
    }

    > .small {
        width: 80px;
    }

    .title {
        font-size: 12px;
        color: ${Theme.colors.info.text};
        font-weight: 600;
        margin-bottom: 2px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: ${Theme.colors.neutrals.gray};
    }
`;

const Tag = styled.div`
    box-sizing: border-box;
    width: 36px;
    height: 20px;
    background: ${Theme.colors.info.bg} 0% 0% no-repeat padding-box;
    border-radius: ${Theme.defaultRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: ${Theme.colors.info.text};
    border: 1px solid ${Theme.colors.info.border};
`;

const SpacerInline = styled(Spacer)`
    display: inline-flex;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

const SkeletonApp = ({animation, children}) => {

    return (
        <Card style={{position: 'relative'}}>
            <Spacer all={5}>
                <div style={{height: '20px'}}>
                    <Skeleton style={{width: '11%', float: 'left'}} animation={animation}/>
                    <Skeleton style={{width: '25%', float: 'right'}} animation={animation}/>
                </div>
                <Spacer top={3}/>

                <div style={{height: '28px'}}>
                    <Skeleton style={{width: '40%', float: 'left'}} animation={animation}/>
                </div>
                <Spacer top={3}/>

                <div style={{height: '46px'}}>
                    <Skeleton style={{width: '65%', float: 'left'}} animation={animation}/>
                    <Skeleton style={{width: '80px', float: 'right'}} animation={animation}/>
                </div>
                <Spacer top={3}/>

                <div style={{height: '46px'}}>
                    <Skeleton style={{width: '65%', float: 'left'}} animation={animation}/>
                    <Skeleton style={{width: '80px', float: 'right'}} animation={animation}/>
                </div>
                <Spacer top={5}/>

                <div style={{height: '20px'}}>
                    <Skeleton animation={animation} width={100}/>
                </div>

            </Spacer>
            {children}
        </Card>
    );
}

const App = ({idList, station, setEdit, setCopy, handleDelete}) => {
    const [selected, setSelected] = useSelectedStation();
    const {pathname} = useLocation();
    let singularPath = pathname.substring(0, pathname.length - 1);
    const FullRole = ['ROLE_OWNER', 'ROLE_OPERATIONS_ACCOUNT_MANAGER', 'ROLE_OPERATIONS_MANAGER', 'ROLE_FLEET_MANAGER', 'ROLE_HR_ADMINISTRATOR', 'ROLE_CENTRAL_DISPATCHER'];
    return (
        <Card>
            <Spacer all={5}>
                <Actions>
                    <Tag>{station.code}</Tag>

                    <div style={{position: 'relative', top: '2px'}}>
                        <SpacerInline>
                            {/*<SpacerInline right={3}>
                                <div><Icon.Expand size='18px' color={Theme.colors.info.border} /></div>
                            </SpacerInline>*/}

                            {/*{FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1 && */}
                            <>
                                <IsGranted feature={AccessCheck.features.CM_STATIONS} ops={[AccessCheck.OPS.C]} station={station.id}>
                                    <SpacerInline right={3}>
                                        <div onClick={() => {
                                            setCopy(station, idList)
                                        }}><Icon.Copy size='18px' color={Theme.colors.info.border} className='icon-action'/>
                                        </div>
                                    </SpacerInline>
                                </IsGranted>
                                <IsGranted feature={AccessCheck.features.CM_STATIONS} ops={[AccessCheck.OPS.U]} station={station.id}>
                                    <SpacerInline right={3}>
                                        <div onClick={() => {
                                            setEdit(station, idList)
                                        }}><Icon.Edit size='18px' color={Theme.colors.info.border} className='icon-action'/>
                                        </div>
                                    </SpacerInline>
                                </IsGranted>
                                <IsGranted feature={AccessCheck.features.CM_STATIONS} ops={[AccessCheck.OPS.D]} station={station.id}>
                                    { !station.isStationOwner &&
                                        <SpacerInline right={3}>
                                            <div onClick={() => {
                                                handleDelete(station.id, idList)
                                            }}><Icon.Times size='18px' color={Theme.colors.info.border}
                                                        className='icon-action'/></div>
                                        </SpacerInline>
                                    }
                                </IsGranted>
                            </>
                            {/*}*/}

                        </SpacerInline>
                    </div>
                </Actions>
                <Spacer top={3}/>

                <Row>
                    <SubTitle>{station.name}</SubTitle>
                </Row>

                <Spacer top={2}/>

                <Row style={{justifyContent: 'space-between'}}>
                    <div>
                        <Text className='title'>Address</Text>
                        <Text>{station.stationAddress}</Text>
                    </div>
                    <div className='small'>
                        <Text className='title'>Geofence</Text>
                        <Text>{station.stationGeofence}</Text>
                    </div>
                </Row>

                <Spacer top={3}/>

                <Row style={{justifyContent: 'space-between'}}>
                    <div>
                        <Text className='title'>Parking Lot</Text>
                        <Text>{station.parkingLotAddress}</Text>
                    </div>
                    <div className='small'>
                        <Text className='title'>Geofence</Text>
                        <Text>{station.parkingLotGeofence}</Text>
                    </div>
                </Row>
                <Spacer top={5}/>

                <IsGranted feature={AccessCheck.features.CM_STATIONS} ops={[AccessCheck.OPS.U, AccessCheck.OPS.D]}>
                    <Actions>
                        <Link
                            to={{
                                pathname: `${singularPath}/${station.id}/schedules`,
                                state: {singularPath, station}
                            }}
                        >
                            <Button size='small' onClick={() => {
                                if (station && typeof station.id === 'number') {
                                    setSelected(station.id);
                                }
                            }}>Manage</Button>
                        </Link>
                    </Actions>
                </IsGranted>
            </Spacer>

        </Card>
    );
}

export {SkeletonApp};

export default App;
