import React from "react";
import ChannelChatContainer from "./AppContainerForChannels";
import CGCOptions from "./CGCOptions";
import DMModal from "./DMModal";
import ArchiveGroupModal from "./ArchiveGroupModal";
import ListBadWordsModal from "./ListBadWordsModal";
// @ts-ignore
import {Token} from "../hooks/useGlobalData";
import AppChatSidebar from "./AppChatSidebar";
import AppChatClient from "./AppChatClient";
// import {useChatTrackedState} from "./store";
import ChatIconContainer from "./ChatIcon";
import {debugLog} from "./utils";

export let audio = document.getElementById("audio-notification")!;
debugLog({audio});


const AppChat: React.FC = () => {
    // console.log('Render AppChat');
    return (
        <>
            <AppChatClient/>
            <ChatIconContainer/>
            <AppChatSidebar/>
            <ChannelChatContainer/>
            <CGCOptions/>
            <DMModal/>
            <ArchiveGroupModal/>
            <ListBadWordsModal/>
        </>
    );

}

export default AppChat;
