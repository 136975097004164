import React, { useEffect, useRef, useState } from "react";
// import { apiKeyTinyMce } from "../../Conf";
// import { Editor } from "@tinymce/tinymce-react";
import { Label } from "../../components/UI";
import styled from "styled-components";
import { Input, Icon, Theme } from "@dspworkplace/ui";
import CKeditorComponent from "../CkEditor/CKeditorComponent";

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;
const SpanTag = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    color: rgb(81, 111, 144);
    margin-top: 4px;
`;
const Description = styled.div`
    border: 1px gray dashed;
    padding-left: 10px;
    padding-bottom: 5px;
`;

// Tiny Mce
const TinyMce = React.forwardRef(({ name, placeholder, ...props }, ref) => {
    // const tinyMceRef = useRef();
    const [valueInput, setValueInput] = useState(props.defaultValue);
    const [showDescription, setShowDescription] = useState(false);
    // const handleEditorChange = (value, editor) => {
    //     setValueInput(value);
    // };
    useEffect(() => {
        setValueInput(props.defaultValue);
    }, [props.defaultValue]);
    return (
        <div style={{ display: "inline-block" }}>
            <Label>{props.label}</Label>
            {/* <Editor
                apiKey={apiKeyTinyMce}
                onInit={(evt, editor) => (tinyMceRef.current = editor)}
                initialValue={props.defaultValue}
                {...props}
                init={{
                    height: props.init.height ? props.init.height : 400,
                    width: props.init.width ? props.init.width : 500,
                    selector: props.init.type ? props.init.type : "textarea",
                    plugins: [
                        "preview",
                        "searchreplace",
                        "autolink",
                        "code",
                        "visualblocks",
                        "visualchars",
                        "fullscreen",
                        "image",
                        "link",
                        "media",
                        "template",
                        "codesample",
                        "table",
                        "charmap",
                        "pagebreak",
                        "nonbreaking",
                        "anchor",
                        "insertdatetime",
                        "advlist",
                        "lists",
                        "wordcount",
                        "help",
                        "charmap",
                        "quickbars",
                        "emoticons",
                    ],
                    menubar: true,
                    menu: {
                        file: { title: "File", items: "preview" },
                        edit: {
                            title: "Edit",
                            items: "undo redo | cut copy paste pastetext | selectall | searchreplace",
                        },
                        view: { title: "View", items: "code" },
                        insert: { title: "Insert", items: "link emoticons insertdatetime" },
                        format: {
                            title: "Format",
                            items: "bold italic underline strikethrough superscript subscript | forecolor backcolor | removeformat",
                        },
                        table: { title: "Table", items: "" },
                        tools: { title: "Tools", items: "wordcount" },
                        help: { title: "Help", items: "" },
                    },
                    toolbar:
                        "undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist | forecolor backcolor emoticons link | preview removeformat",
                    toolbar_mode: "sliding",
                    content_style:
                        "body { font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size:11px }",
                    default_link_target: "_blank",
                    contextmenu: "bold italic underline strikethrough link lists",
                    quickbars_insert_toolbar: "",
                    quickbars_selection_toolbar: "",
                    link_quicklink: true,
                    ...props.init,
                }}
                onEditorChange={handleEditorChange}
            /> */}
            <CKeditorComponent
                content={valueInput}
                onChange={(evt) => {
                    var content = evt.editor.getData();
                    setValueInput(content);
                }}
            />
            <div>
                <div
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => {
                        setShowDescription(!showDescription);
                    }}
                >
                    <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                        <Icon.Info
                            size="16px"
                            color={Theme.colors.neutrals.medium}
                            style={{
                                verticalAlign: "middle",
                                position: "relative",
                                top: -1,
                            }}
                        />{" "}
                        Template Variable
                    </SpanTag>
                </div>
            </div>
            <div>
                {showDescription && (
                    <Description>
                        <SpanTag>
                            <span>[DRIVER_NAME] = "Driver name of Incident"</span>
                        </SpanTag>
                        <SpanTag>
                            <span>[DATE] = "Date of Incident"</span>
                        </SpanTag>
                        <SpanTag>
                            <span>[STATION] = "Station of Incident"</span>
                        </SpanTag>
                    </Description>
                )}
            </div>
            <HiddenInput type="text" name={name} id={props.token} ref={ref} value={valueInput} />
        </div>
    );
});
export default TinyMce;
