import apiFactory from '../../../api/factory';

type VehicleUploadImagesInterface = {
    id: number,
    images: string
}

const {call} = apiFactory();
const resource = 'vehicle/maintenance/task'
export const VehicleUploadImages = (props: VehicleUploadImagesInterface) => {
    const {id,images} = props
    return call({images:images},`api/v2/${resource}/${id}/uploadImages`,'put')
}