import React, { useEffect, useState } from "react";
import { Icon, Spacer, SubTitle, TagInput, Text, Theme, ThirdTitle, Title } from "@dspworkplace/ui";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {ButtonIcon, Card, Divider, GridColumns} from "../../../components/UI";
import LineChart from "../../../components/Charts/LineChart";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../components/Table";
import { getCompanyId, useAuth } from "../../../Auth";
import {
    DOCUMENT_STATUS,
    ReportMentorSchemaInclude,
    ReportNetradyneSchemaInclude,
    ReportSafetyMetricsSchemaColumns,
    ReportScorecardSchemaColumns,
    ReportScorecardSchemaInclude,
    ReportScorecardSchemaCETColumns,
    ReportScorecardSchemaCETInclude,
    ReportEngineOffSchemaInclude,
    ReportCustomerDeliveryFeedBackSchemaInclude,
    ReportCustomerDeliveryFeedBackSchemaColumns,
    ReportProperParkingSequenceSchemaColumns,
    ReportProperParkingSequenceSchemaInclude
} from "../schema";
import Loading, { LoadingWrapper } from "../../../components/Loading";
import Empty from '../../../components/Empty';
import { getColumnAvg, getColumnTotal } from "../../Reports/common";
import DatePicker from '../../../components/Calendar/picker';
import { ScorecardProvider } from "../context";
import ScorecardCard from "../../Reports/Scorecards/MobileScorecard/OverviewTemplate/OverviewTab/Scores/Card"
import { asDatabaseTime, databaseToMilliseconds } from "../../../components/TimeFormat"
import Modal from "../../../components/Modal";

const Smaller = styled('small')`
    font-size: x-small;
    font-weight: bold;
    align-self: self-end;
    line-height: 2;
    padding-left: 8px;
    color: ${Theme.colors.neutrals.medium};
`;

const ExpandableScorecardCard = ({
    children,
    expanded
}) => {
    const [visible, setVisible] = useState(false);

    return <div onClick={() => setVisible(!visible)} style={{position: 'relative', cursor: 'pointer'}}>
        <ButtonIcon icon={Icon.Expand} style={{
            position: 'absolute',
            top: 12,
            right: 24,
        }}/>
        {children}
        {!visible ? null : expanded(setVisible)}
    </div>
}

export default function (props) {
    return <ScorecardProvider>
        <ScorecardDrivers {...props} />
    </ScorecardProvider>
}

function ScorecardDrivers() {
    const { api } = useAuth();
    const [status, setStatus] = useState(DOCUMENT_STATUS.FETCHING);
    const [drivers, setDrivers] = useState([]);
    const [chartData, setChartData] = useState({});
    const [station] = useSelectedStation();
    const { register, handleSubmit, reset, watch } = useForm();

    const fetchDrivers = () => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        return api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    Driver: {
                        custom: {
                            get: "drivers",
                            functionName: "getActiveEmployeesForChat",
                            criteria: {
                                companyId: getCompanyId(),
                                stationId: station,
                            },
                        }
                    }
                }
            }
        }).then(
            response => response?.data?.data?.drivers
        ).then(
            response => {
                if (!response)
                    return response;

                setDrivers(response);
                setStatus(DOCUMENT_STATUS.READY);

                return response;
            }
        )
    };

    const fetchReport = ({ driver, date }) => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        const [from, to] = date.split(' – ');

        return api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    ReportScorecard: {
                        findBy: {
                            get: 'scorecards',
                            criteria: {
                                driver: driver,
                            },
                            orderBy: {
                                date: 'DESC'
                            },
                            matching: {
                                date: {
                                    between: [from, to]
                                }
                            },
                            includes: (JSON.parse(localStorage.getItem('station')).timezone == 'CET')?ReportScorecardSchemaCETInclude:ReportScorecardSchemaInclude
                        }
                    },
                    ReportMentor: {
                        findBy: {
                            get: 'mentor',
                            criteria: {
                                driver: driver,
                            },
                            orderBy: {
                                date: 'DESC'
                            },
                            matching: {
                                date: {
                                    between: [from, to]
                                }
                            },
                            includes: ReportMentorSchemaInclude
                        }
                    },
                    ReportNetradyne: {
                        findBy: {
                            get: 'netradyne',
                            criteria: {
                                driver: driver,
                            },
                            orderBy: {
                                date: 'DESC'
                            },
                            matching: {
                                date: {
                                    between: [from, to]
                                }
                            },
                            includes: ReportNetradyneSchemaInclude
                        }
                    },
                    ReportEngineOffCompliance: {
                        findBy: {
                            get: 'engineOffCompliance',
                            criteria: {
                                driver: driver,
                            },
                            orderBy: {
                                date: 'DESC'
                            },
                            matching: {
                                date: {
                                    between: [from, to]
                                }
                            },
                            includes: ReportEngineOffSchemaInclude
                        }
                    },
                    ReportCustomerDeliveryFeedBack: {
                        findBy: {
                            get: 'customerDeliveryFeedbacks',
                            criteria: {
                                driver: driver,
                            },
                            orderBy: {
                                date: 'DESC'
                            },
                            matching: {
                                date: {
                                    between: [from, to]
                                }
                            },
                            includes: ReportCustomerDeliveryFeedBackSchemaInclude
                        }
                    },
                    "ReportProperParkingSequence": {
                        "custom": {
                            "functionName": "getPPSByDriverBetweenDates",
                            "get": "properParkingSequence",
                            "criteria": {
                                "stationId": JSON.parse(localStorage.getItem("station")).id,
                                "driverId": driver,
                                "startDate": from,
                                "endDate": to
                            },
                            "excludes": {},
                            "includes": ReportProperParkingSequenceSchemaInclude
                        }
                    }
                }
            }
        }).then(
            response => response?.data?.data
        ).then(
            response => {
                if (!response || response.length === 0)
                    return false;

                let scorecardGoalPPS = response.properParkingSequence.scorecardGoal;
                setChartData({
                    ...response,
                    scorecards: response.scorecards.map(d => ({
                        ...d,
                        ref: `${d.week}/${d.year}`
                    })).reverse(),
                    customerDeliveryFeedbacks: response.customerDeliveryFeedbacks.map(d => ({
                        ...d,
                        cdfScore:parseFloat(d.cdfScore),
                        negativeCdfScore:+(100-parseFloat(d.cdfScore)).toFixed(2),
                        ref: `${d.week}/${d.year}`
                    })),
                    properParkingSequence: response.properParkingSequence.result.sort((a, b) => {return (a.year+a.week) - (b.year+b.week)})
                    .map((d) => {
                        //Positive PPS
                        if (d.percentagePPSCompliance >= scorecardGoalPPS) {
                            return {
                                ...d,
                                positivePPS: parseFloat(d.percentagePPSCompliance),
                                ref: `${d.week}/${d.year}`,
                            };
                        //Negative PPS
                        } else if (d.percentagePPSCompliance <= scorecardGoalPPS) {
                            return {
                                ...d,
                                negativePPS: parseFloat(d.percentagePPSCompliance),
                                ref: `${d.week}/${d.year}`,
                            };
                        }
                    }),
                });
                setStatus(DOCUMENT_STATUS.READY);

                return response;
            }
        )
    };

    const triggerSubmit = () =>
        setTimeout(() =>
            document.forms.filter.dispatchEvent(new Event('submit'))
            , 200);

    const onSubmit = (data) => {
        let { driver, date } = data;
        if (!driver?.[0] || !date.trim().replace(' – ', ''))
            return setChartData({});

        driver = driver[0];

        fetchReport({ driver, date });
    }

    useEffect(() => {
        fetchDrivers();
        setDrivers([]);
        reset({
            driver: [],
        });
    }, [station]);

    const safetyMetrics = (chartData?.mentor || [])
        .concat(chartData?.netradyne || [])
        .concat(chartData?.engineOffCompliance || [])
        .reduce((all, next) => {
            let i = all.findIndex(m => m.date.timestamp === next.date.timestamp)
            if (i >= 0)
                all[i] = {
                    ...all[i],
                    ...next
                }
            else
                all.push(next)

            return all;
        }, []);

    const safetyEventsColumns = [
        'accel',
        'braking',
        'cornering',
        'speeding',
        'distraction',
        'seatbelt',
        'backUp',
        'highG',
        'lowImpact',
        'signViolations',
        'trafficLightViolation',
        'uTurn',
        'hardBraking',
        'hardTurn',
        'hardAcceleration',
        'driverDistraction',
        'followingDistance',
        'speedingViolations',
        'weaving',
        'harshCornering',
        'harshBraking',
        'seatbeltCompliance',
        'driverIntiated'
    ];

    const getTotalSafetyEvents = () =>
        safetyEventsColumns.reduce((all, next) =>
            all + getColumnTotal(safetyMetrics, next)
        , 0)

    return <section>
        <Title>Driver Performance</Title>
        <Spacer bottom={3} />

        <Spacer bottom={5} as='form' name='filter' onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <StationSelector />
            <Spacer right={5} inline />
            <TagInput
                ref={register}
                name='driver'
                label='Driver'
                visibleOptionsQty={6}
                options={drivers.map(d => ({
                    name: d.friendlyName,
                    value: d.driverId
                }))}
                singleOption
                placeholder={(() => {
                    if (!drivers.length && status === DOCUMENT_STATUS.FETCHING)
                        return 'Loading...';

                    if (drivers.length && status === DOCUMENT_STATUS.READY && !watch('driver').length)
                        return 'Select a driver...'

                    return '';
                })()}
                onChange={()=>setTimeout(()=>handleSubmit(onSubmit)(),1)}
            />
            <Spacer right={5} inline />
            <DatePicker
                ref={register}
                name='date'
                label='Week Range'
                onChange={()=>setTimeout(()=>handleSubmit(onSubmit)(),1)}
                range='week'
                input='[W]W/YYYY'
            />

            {/*<Spacer inline left={3} style={{marginLeft: 'auto'}}>*/}
            {/*    <Button>Share</Button>*/}
            {/*</Spacer>*/}
            {/*<Spacer inline left={3}>*/}
            {/*    <Button>Download</Button>*/}
            {/*</Spacer>*/}
        </Spacer>

        <Divider top={0} bottom={5} />

        {status === DOCUMENT_STATUS.FETCHING && <div style={{ position: 'relative' }}>
            <LoadingWrapper style={{ height: 80 }}>
                <Loading />
            </LoadingWrapper>
        </div>}

        {status === DOCUMENT_STATUS.READY && Object.keys(chartData).length === 0 ? <>
            <Spacer all={10} style={{ textAlign: 'center' }}>
                <Icon.TrendUp />
                <SubTitle>Select a driver and a date range to start</SubTitle>
            </Spacer>
        </> : null}

        {status === DOCUMENT_STATUS.READY && Object.keys(chartData).length > 0 ? <>
            {/*<Spacer bottom={5}>*/}
            {/*    <Alert.Info*/}
            {/*        useIcon*/}
            {/*        title='workbot suggestion'*/}
            {/*        content='This drivers needs more safety training'*/}
            {/*    />*/}
            {/*</Spacer>*/}

            <SubTitle>Weekly Scorecard</SubTitle>
            <Spacer bottom={3} />

            <GridColumns>
                <Card>
                    <LineChart
                        title='Ranking'
                        data={LineChart.extract({
                            source: chartData.scorecards,
                            x: 'ref',
                            y: 'ranking',
                        })}
                        scale={{
                            y: {
                                type: 'cat',
                                values: chartData.scorecards
                                    .map(s => s.ranking)
                                    .sort((a, b) => {
                                        a = a || 0; // default value for undefined "a"
                                        b = b || 0; // default value for undefined "b"
                                        return b.toString().localeCompare(
                                            a.toString(), undefined, { numeric: true }
                                        )
                                    }),
                                range: [0, 1]
                            },
                        }}
                    />
                </Card>
                <Card>
                    <LineChart
                        title='FICO Score'
                        data={LineChart.extract({
                            source: chartData.scorecards,
                            x: 'ref',
                            y: 'fico',
                        })}
                        max={850}
                    />
                </Card>
                <Card>
                    <LineChart
                        title='Customer Delivery Feedback DPMO'
                        data={LineChart.extract({
                            source: chartData.scorecards,
                            x: 'ref',
                            y: 'customerDeliveryFeedbackDPMO',
                        })}
                        max={100}
                    />
                </Card>
            </GridColumns>
            <Spacer bottom={4} />

            <Card>
                <Table style={{ overflow: 'auto' }}>
                    <TableHeader
                        style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
                        headers={(JSON.parse(localStorage.getItem('station')).timezone == 'CET')?Object.values(ReportScorecardSchemaCETColumns):Object.values(ReportScorecardSchemaColumns)}
                        compact={true}
                    />
                    {chartData.scorecards.length === 0 &&
                        <TableRow style={{ position: 'sticky', left: 0 }}>
                            <TableData width='100%' compact={true} style={{ justifyContent: 'center' }}>
                                <Empty />
                            </TableData>
                        </TableRow>
                    }
                    {chartData.scorecards.map(scorecard =>
                        <TableRow key={scorecard.id} style={{ width: 'fit-content' }}>
                            {(JSON.parse(localStorage.getItem('station')).timezone == 'CET')?Object.entries(ReportScorecardSchemaCETColumns).map(([k, column]) =>
                                <TableData key={k} compact={true} width={column.width}>
                                    {(column.render || (str => str)).call(null, scorecard?.[k], scorecard)}
                                </TableData>
                            ):Object.entries(ReportScorecardSchemaColumns).map(([k, column]) =>
                            <TableData key={k} compact={true} width={column.width}>
                                {(column.render || (str => str)).call(null, scorecard?.[k], scorecard)}
                            </TableData>
                        )}
                        </TableRow>
                    ).reverse()}
                    {chartData.scorecards.length !== 0 &&
                        <TableFooter style={{ paddingLeft: 8, paddingRight: 0, width: 'fit-content' }}>
                            <ThirdTitle style={{ minWidth: 72 }}>Avg:</ThirdTitle>
                            {(JSON.parse(localStorage.getItem('station')).timezone == 'CET')?
                                Object.entries(ReportScorecardSchemaCETColumns).map(([k, column]) =>
                                    k === 'week' ? false :
                                        <TableData key={k} compact={true} width={column.width}>
                                            <ThirdTitle>
                                                {(column.render || (str => str)).call(null, getColumnAvg(chartData.scorecards, k))}
                                            </ThirdTitle>
                                        </TableData>
                                ).filter(r => r):
                            Object.entries(ReportScorecardSchemaColumns).map(([k, column]) =>
                                k === 'week' ? false :
                                    <TableData key={k} compact={true} width={column.width}>
                                        <ThirdTitle>
                                            {(column.render || (str => str)).call(null, getColumnAvg(chartData.scorecards, k))}
                                        </ThirdTitle>
                                    </TableData>
                            ).filter(r => r)}
                        </TableFooter>
                    }
                </Table>
            </Card>

            <Divider top={5} bottom={5} />
            
            <SubTitle>Weekly CDF</SubTitle>
            <Spacer bottom={3} />

            <GridColumns>
                <Card>
                    <LineChart
                        title='Positive Feedback'
                        data={LineChart.extract({
                            source: chartData.customerDeliveryFeedbacks,
                            x: 'ref',
                            y: 'cdfScore',
                        })}
                        scale={{
                            y: {
                                formatter: d => d+"%",
                            },
                        }}
                        max={100}
                    />
                </Card>
                <Card>
                    <LineChart
                        title='Negative Feedback'
                        data={LineChart.extract({
                            source: chartData.customerDeliveryFeedbacks,
                            x: 'ref',
                            y: 'negativeCdfScore',
                        })}
                        scale={{
                            y: {
                                formatter: d => d+"%",
                            },
                        }}
                        max={100}
                    />
                </Card>
                <Card>
                    <LineChart
                        title='No Feedback'
                        data={LineChart.extract({
                            source: chartData.customerDeliveryFeedbacks,
                            x: 'ref',
                            y: 'noFeedback',
                        })}
                    />
                </Card>
            </GridColumns>
            <Spacer bottom={4} />

            <Card>
                <Table style={{ overflow: 'auto' }}>
                    <TableHeader
                        style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
                        headers={Object.values(ReportCustomerDeliveryFeedBackSchemaColumns)}
                        compact={true}
                    />
                    {chartData.customerDeliveryFeedbacks.length === 0 &&
                        <TableRow style={{ position: 'sticky', left: 0 }}>
                            <TableData width='100%' compact={true} style={{ justifyContent: 'center' }}>
                                <Empty />
                            </TableData>
                        </TableRow>
                    }
                    {chartData.customerDeliveryFeedbacks.map(customerDeliveryFeedback =>
                        <TableRow key={customerDeliveryFeedback.id} style={{ width: 'fit-content' }}>
                            {Object.entries(ReportCustomerDeliveryFeedBackSchemaColumns).map(([k, column]) =>
                                <TableData key={k} compact={true} width={column.width}>
                                    {(column.render || (str => str)).call(null, customerDeliveryFeedback?.[k], customerDeliveryFeedback)}
                                </TableData>
                            )}
                        </TableRow>
                    ).reverse()}
                    {chartData.customerDeliveryFeedbacks.length !== 0 &&
                        <TableFooter style={{ paddingLeft: 8, paddingRight: 0, width: 'fit-content' }}>
                            <ThirdTitle style={{ minWidth: 72 }}>Avg:</ThirdTitle>
                            {Object.entries(ReportCustomerDeliveryFeedBackSchemaColumns).map(([k, column]) =>
                                k === 'week' ? false :
                                    <TableData key={k} compact={true} width={column.width}>
                                        <ThirdTitle>
                                            {(column.render || (str => str)).call(null, getColumnAvg(chartData.customerDeliveryFeedbacks, k))}
                                        </ThirdTitle>
                                    </TableData>
                            ).filter(r => r)}
                        </TableFooter>
                    }
                </Table>
            </Card>

            <Divider top={5} bottom={5} />
            
            <SubTitle>Weekly Proper Parking Sequence</SubTitle>
            <Spacer bottom={3} />
            <GridColumns>
                <Card>
                    <LineChart
                        title='Positive PPS'
                        data={LineChart.extract({
                            source: chartData.properParkingSequence.filter((p) => { return (p?.positivePPS !== undefined) ? true : false; }),
                            x: 'ref',
                            y: 'positivePPS',
                        })}
                        scale={{
                            y: {
                                formatter: d => d+"%",
                            },
                        }}
                        max={100}
                    />
                </Card>
                <Card>
                    <LineChart
                        title='PPS Compliance'
                        data={LineChart.extract({
                            source: chartData.properParkingSequence.filter((p) => { return (p?.negativePPS !== undefined) ? true : false; }),
                            x: 'ref',
                            y: 'negativePPS',
                        })}
                        scale={{
                            y: {
                                formatter: d => d+"%",
                            },
                        }}
                        max={100}
                    />
                </Card>
            </GridColumns>
            <Spacer bottom={4} />              
            <Card>
                <Table style={{ overflow: 'auto' }}>
                    <TableHeader
                        style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
                        headers={Object.values(ReportProperParkingSequenceSchemaColumns)}
                        compact={true}
                    />
                    {chartData.properParkingSequence.length === 0 &&
                        <TableRow style={{ position: 'sticky', left: 0 }}>
                            <TableData width='100%' compact={true} style={{ justifyContent: 'center' }}>
                                <Empty />
                            </TableData>
                        </TableRow>
                    }
                    {chartData.properParkingSequence.map(properParkingSequence =>
                        <TableRow key={properParkingSequence.id} style={{ width: 'fit-content' }}>
                            {Object.entries(ReportProperParkingSequenceSchemaColumns).map(([k, column]) =>
                                <TableData key={k} compact={true} width={column.width}>
                                    {(column.render || (str => str)).call(null, properParkingSequence?.[k], properParkingSequence)}
                                </TableData>
                            )}
                        </TableRow>
                    ).reverse()}
                    {chartData.properParkingSequence.length !== 0 &&
                        <TableFooter style={{ paddingLeft: 8, paddingRight: 0, width: 'fit-content' }}>
                            <ThirdTitle style={{ minWidth: 72 }}>Avg:</ThirdTitle>
                            {Object.entries(ReportProperParkingSequenceSchemaColumns).map(([k, column]) =>
                                k === 'week' ? false :
                                    <TableData key={k} compact={true} width={column.width}>
                                        <ThirdTitle>
                                            {(column.render || (str => str)).call(null, getColumnAvg(chartData.properParkingSequence, k))}
                                        </ThirdTitle>
                                    </TableData>
                            ).filter(r => r)}
                        </TableFooter>
                    }
                </Table>
            </Card>
            <Divider top={5} bottom={5} />

            <SubTitle>Daily Safety Metrics</SubTitle>
            <Spacer bottom={3} />

            {safetyMetrics.length === 0 ? (
                <Card>
                    <TableRow style={{ position: 'sticky', left: 0 }}>
                        <TableData width='100%' compact={true} style={{ justifyContent: 'center' }}>
                            <Empty />
                        </TableData>
                    </TableRow>
                </Card>
            ) : (
                <>
                    <Text>Data from {watch('date')}</Text>
                    <Spacer bottom={3} />

                    <GridColumns columns={4} style={{
                        margin: '-4px -16px',
                        gap: 0,
                    }}>
                        <ScorecardCard
                            metricName={<>FICO <Smaller>average</Smaller></>}
                            metricValue={Math.round(getColumnAvg(safetyMetrics, 'fico'))}
                            change={''}
                            trend={''}
                            scenario={'tilde'}
                            metricType={'number'}
                            trendStyle={''}
                        />
                        <ScorecardCard
                            metricName={<>Green Zone % <Smaller>average</Smaller></>}
                            metricValue={Math.round(getColumnAvg(safetyMetrics.filter(r => r.minutesAnalyzed > 0), 'greenMinutesPercentage'))}
                            change={''}
                            trend={''}
                            scenario={'tilde'}
                            metricType={'number'}
                            trendStyle={''}
                        />
                        <ExpandableScorecardCard
                            expanded={toggle => (
                                <Modal
                                    visible={true}
                                    setVisible={() => toggle(false)}
                                    title={'Total Events per Day'}
                                >
                                    <LineChart
                                        title='Safety Events'
                                        data={
                                            safetyMetrics?.sort((a, b) =>
                                                a.date.timestamp - b.date.timestamp
                                            ).map(s => ({
                                                x: ReportSafetyMetricsSchemaColumns.date.render(null, s),
                                                y: safetyEventsColumns.reduce((all, next) =>
                                                        s[next]
                                                            ? all + parseFloat(s[next])
                                                            : all
                                                    , 0),
                                                type: 'a',
                                            }))
                                        }
                                    />
                                </Modal>
                            )}
                        >
                            <ScorecardCard
                                metricName={<>Total Events <Smaller>sum</Smaller></>}
                                metricValue={getTotalSafetyEvents()}
                                change={''}
                                trend={''}
                                scenario={'tilde'}
                                metricType={'number'}
                                trendStyle={''}
                            />
                        </ExpandableScorecardCard>
                        <ScorecardCard
                            metricName={<>EOC % <Smaller>average</Smaller></>}
                            metricValue={Math.round(getColumnAvg(chartData.engineOffCompliance, 'eoc'))}
                            change={''}
                            trend={''}
                            scenario={'tilde'}
                            metricType={'number'}
                            trendStyle={''}
                        />
                    </GridColumns>

                    <Spacer bottom={3} />

                    <Card style={{
                        position: 'relative',
                    }}>
                        <Table style={{ overflow: 'auto' }}>
                            <TableHeader
                                compact={true}
                                headers={Object.values(ReportSafetyMetricsSchemaColumns).reduce((all, next) => {
                                    all[next.group] = all[next.group] || {};
                                    all[next.group].label = next.group;
                                    all[next.group].headers = all[next.group].headers || [];
                                    all[next.group].headers.push(next);
                                    return all;
                                }, {})}
                                style={{
                                    paddingLeft: 112
                                }}
                            />
                            {safetyMetrics.map(metric =>
                                <TableRow
                                    key={metric.id}
                                    style={{
                                        width: 'fit-content',
                                        paddingLeft: ReportSafetyMetricsSchemaColumns.date.width
                                    }}
                                >
                                    {Object.entries(ReportSafetyMetricsSchemaColumns).map(([k, column], i) =>
                                        <TableData key={k} compact={true} width={column.width} style={{
                                            position: i === 0 ? 'absolute' : 'static',
                                            left: 20,
                                            background: 'white',
                                        }}>
                                            {(column.render || (str => str)).call(null, metric?.[k], metric)}
                                        </TableData>
                                    )}
                                </TableRow>
                            )}
                            <TableFooter style={{ paddingLeft: 120, paddingRight: 0, width: 'fit-content' }}>
                                <ThirdTitle style={{
                                    minWidth: 112,
                                    left: 20,
                                    paddingLeft: 8,
                                    position: 'absolute',
                                    backgroundColor: Theme.colors.extra.gray93,
                                }}>Totals:</ThirdTitle>
                                {Object.entries(ReportSafetyMetricsSchemaColumns).map(([k, column]) =>
                                    k === 'date' ? false :
                                        <TableData key={k} compact={true} width={column.width}>
                                            <ThirdTitle>
                                                {k === 'time' ? (
                                                    asDatabaseTime(
                                                        safetyMetrics
                                                            .reduce((all, m) => all + databaseToMilliseconds(m.time), 0)
                                                    )
                                                ) : (
                                                    column.totals
                                                        ? column.totals(safetyMetrics, k)
                                                        : (column.render || (str => str))
                                                            .call(null, getColumnTotal(safetyMetrics, k))
                                                )}
                                            </ThirdTitle>
                                        </TableData>
                                ).filter(r => r)}
                            </TableFooter>
                        </Table>
                    </Card>
                </>
            )}

            <Spacer bottom={20} />
        </> : null}
    </section>
}