class filterResolverServer {
    constructor(filterObj,config) {
        this.filter = filterObj;
        this.config = config || {};
        this.matching = {};
        return this.apply();
    }

    query = {
        string: (value) => {
            return value.toString();
        },
        number: (value) => {
            return parseInt(value);
        },
        in: (value) => {
            const v = typeof value === 'object' ? value : [value];
            return {in: v};
        },
        date: (value) => {
            let date;
            if(typeof value === 'object'){
                if (value[1]){
                    date = [value[0],value[1]]
                }else{
                    date = value[0]
                }
            }else{
                date = value.split(' – ');
            }
            if(date[1]){
                return {
                    between: [date[0],date[1]]
                }
            }
            return value;
        },
        like: (value) => {
            return {contains: value}
        }
    }

    _format = (field,value) => {
        let type = this.config[field] || 'string';
        return this.query[type](value);
    }

    _sanitizeField = () => {
        const fields = Object.keys(this.filter);
        const matchingObj = fields.reduce((acc,field)=>{
            if(this.filter[field].toString().length){
                acc[field] = this._format(field,this.filter[field]);
            }
            return acc;
        },{});
        return matchingObj;
    }

    apply = ()=>{
        this.matching = this._sanitizeField();
        return this.matching;
    }
}

export default filterResolverServer;