import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link, Route, Switch} from "react-router-dom";
import {Button, Icon, Spacer, SubTitle, Text, Title} from "@dspworkplace/ui";
import {Card} from "../../components/UI";
import Loading, {LoadingWrapper} from "../../components/Loading";
import {callDynamicReportRoutes} from "./api";
import Dynamic from "./Dynamic";
import {getCompanyId, getUserId, useAuth} from "../../Auth";
import {ROUTES} from "../../Route_temp";
import {IsGranted} from "../../security";


const Body  = styled.div`
    box-sizing: border-box;
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items: stretch;
    padding-left: 156px;
    padding-bottom:40px;
`;

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(340px, 340px) );
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

const Reports = () => {
    const {api} = useAuth();
    const companyId = getCompanyId();
    const userId = getUserId();
    const [loading, setLoading] = useState(false);
    const reportRoute = ROUTES.find(r => r.name === 'Reports');
    const dynamicReportRoutes = [];
    const dynamicSubReportReports = [];
    const [dynamicSubReport,setDynamicSubReport] = useState([]);

    const fetchDynamicReportRoutes = async () => {
        setLoading(true);
        await callDynamicReportRoutes(api, companyId, userId, true, null).then((data) => {
            for(let [key, value] of Object.entries(data)) {
                dynamicReportRoutes.push({
                    name: value.title,
                    description: value.subtitle,
                    path: "/dynamic/"+key,
                    matchPath: "/custom-reports/dynamic/"+key,
                    component: DynamicRoute,
                    renderMenu: false,
                    isDynamic: true,
                });
                for(let [subReportKey, subReportValue] of Object.entries(value.menus)) {
                    dynamicSubReportReports.push({
                        name: subReportValue.title,
                        path: "/custom-reports/dynamic/"+key+"/"+subReportKey,
                        icon:Icon.Segment,
                        component: Dynamic
                    });
                }
            }
            let totalRoutes = reportRoute.routes;
            totalRoutes = totalRoutes.filter(r => typeof r.isDynamic === 'undefined');
            reportRoute.routes = totalRoutes.concat(dynamicReportRoutes);
        });
    };

    useEffect(() => {
        fetchDynamicReportRoutes().then(() => {
            setLoading(false);
            setDynamicSubReport(dynamicSubReportReports);
        }).catch(e => {
            setLoading(false);
            setDynamicSubReport([]);
        });
    }, []);

    return (
        <Body>
            <Spacer top={5} />
            <Title>Custom Reports</Title>
            <Spacer top={5} />
            <Wrap>
            {loading && (
                <LoadingWrapper>
                    <Loading style={{height: '80px', marginTop: '20px'}}/>
                </LoadingWrapper>
            )}
            {!loading && (
                <>
                {reportRoute.routes.map(r =>
                    !r.isCompany || r.isCompany?.includes(parseInt(getCompanyId())) ? (
                        <IsGranted feature={r.feature}>
                            <Card key={r.path}>
                                <SubTitle>{r.name}</SubTitle>
                                <Spacer top={2}/>
                                <Text>{r.description}</Text>
                                <Spacer top={5}/>
                                <Button
                                    as={Link}
                                    to={`${reportRoute.path}${r.path}`}
                                    size="small"
                                >Details</Button>
                            </Card>
                        </IsGranted>
                    ) : null
                )}
                </>
            )}
            {!loading && dynamicSubReport.length > 0 &&
                <Switch>
                    {dynamicSubReport.map((option,key)=>(
                        <Route exact path={option.path} key={key}>
                            <option.component />
                        </Route>
                    ))}
                </Switch>
            }
            </Wrap>
        </Body>
    );
}

const DynamicRoute = ({match}) => {
    return (
        <Switch>
            <Route exact path={match.path}>
                <Dynamic/>
            </Route>
        </Switch>
    );
};


export {DynamicRoute};
export default Reports;
