import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
    Button,
    Icon,
    Input,
    InputColor,
    Spacer, SubTitle,
    TagInput,
    Text,
    Theme,
    ThirdTitle,
    Title,
    Toggle,
    CheckBox
} from "@dspworkplace/ui";
import ScorecardContext, { ScorecardProvider } from "../context";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import { TabList } from "../../../components/Tab";
import {ButtonIcon, HelpButton, Label, Link, Status, Tag} from "../../../components/UI";
import Empty from "../../../components/Empty";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../components/Table";
import useScorecardApi from "../../../api/scorecard";
import Skeleton from "../../../components/Skeleton";
import { Dialog } from "../../../components/Dialog";
import Loading, { LoadingWrapper } from "../../../components/Loading";
import { METRIC_COLUMNS, METRIC_COLUMNS_DRIVER, METRIC_COLUMNS_STATION, AutomatedScorecardCoachingMetricArray } from "../schema";
import { showToast } from "../../../Utilities";
import Modal from "../../../components/Modal";
import { SlideEditor } from "../../Announcements/Sections/Slides/Editor";
import { AddOrEdit } from "../../SchedulerLoadOut";
import { Action } from "../../Scheduler/Common/UI";
import { confirm } from "../../../components/Confirm";
import { CompetitionApp } from "./Coaching/Competition";

export default function (props) {
    return (
        <ScorecardProvider>
            <GoalsAndTips {...props} />
        </ScorecardProvider>
    )
};

export const getFieldsForMetric = (metric) => {

    let fields = [];

    switch (metric?.metricType) {
        case 'LOWEST_PERCENT':
        case 'HIGHEST_PERCENT':
            fields.push({
                field: Input,
                props: {
                    name: 'goal',
                    label: 'Goal',
                    // Required: true,
                    type: 'number',
                    max: 100,
                    min: 1,
                    step: metric?.options?.validation?.step || .1,
                    defaultValue: metric?.goal
                },
                validation: {
                    max: 100,
                    min: 1,
                    step: metric?.options?.validation?.step || .1,
                }
            })
            break;
        default:
            switch (metric?.baseType) {
                case 'NUMBER':
                    fields.push({
                        field: Input,
                        props: {
                            name: 'goal',
                            label: 'Goal',
                            // Required: true,
                            type: 'number',
                            max: metric.options?.validation?.max,
                            min: metric.options?.validation?.min,
                            step: metric.options?.validation?.step,
                            defaultValue: metric?.goal
                        },
                        validation: metric?.options?.validation
                    })
                    break
                case 'LIST':
                    fields.push({
                        field: TagInput,
                        props: {
                            name: 'goal',
                            label: 'Goal',
                            // Required: true,
                            singleOption: true,
                            options: Object.entries(metric.options.list).map(([key, value]) => ({
                                name: value.label ?? value.title ?? key,
                                value: key
                            }))
                        },
                        validation: metric?.options?.validation
                    })
                    break;
                default:
                    fields.push({
                        field: Input,
                        props: {
                            name: 'goal',
                            label: 'Goal',
                            // Required: true                            
                            //defaultValue: metric?.goal
                        },
                        validation: metric?.options?.validation
                    });
            }
    }

    return fields;
}

export const getFieldsForTriggerMetric = (metric) => {
    let fields = [];

    switch (metric?.metricType) {
        case "LOWEST_PERCENT":
        case "HIGHEST_PERCENT":
            fields.push({
                field: Input,
                props: {
                    name: "triggers",
                    label: "Trigger",
                    // Required: true,
                    type: "number",
                    max: 100,
                    min: 1,
                    step: metric?.options?.validation?.step || 0.1,
                    defaultValue: metric?.triggers,
                },
                validation: {
                    max: 100,
                    min: 1,
                    step: metric?.options?.validation?.step || 0.1,
                },
            });
            break;
        default:
            switch (metric?.baseType) {
                case "NUMBER":
                    fields.push({
                        field: Input,
                        props: {
                            name: "triggers",
                            label: "Trigger",
                            // Required: true,
                            type: "number",
                            max: metric.options?.validation?.max,
                            min: metric.options?.validation?.min,
                            step: metric.options?.validation?.step,
                            defaultValue: metric?.triggers,
                        },
                        validation: metric?.options?.validation,
                    });
                    break;
                case "LIST":
                    fields.push({
                        field: TagInput,
                        props: {
                            name: "triggers",
                            label: "Trigger",
                            // Required: true,
                            singleOption: true,
                            options: Object.entries(metric.options.list).map(([key, value]) => ({
                                name: value.label ?? value.title ?? key,
                                value: key,
                            })),
                            defaultValue: metric?.triggers,
                        },
                        validation: metric?.options?.validation,
                    });
                    break;
                default:
                    fields.push({
                        field: Input,
                        props: {
                            name: "triggers",
                            label: "Trigger",
                            // Required: true
                            //defaultValue: metric?.goal
                        },
                        validation: metric?.options?.validation,
                    });
            }
    }

    return fields;
};

const SaveGoalDialog = ({
    goal,
    success,
    cancel,
}) => {
    const [{ metrics, loading, ...state }] = useContext(ScorecardContext);
    const { saveGoal, loadEntitiesByType } = useScorecardApi(ScorecardContext);
    const [metric, setMetric] = useState(goal?.metric);

    const handleSubmit = ({ data }) => {
        saveGoal({
            ...goal,
            ...data,
            goal: Array.isArray(data.goal) ? data.goal[0] : data.goal,
            metric: data.metric[0],
            entityId: Array.isArray(data.entityId) ? data.entityId[0] : data.entityId,
            entity: goal?.type === 'INDIVIDUAL'
                ? 'Driver'
                : goal?.type === 'TEAM'
                    ? 'Team'
                    : undefined,
        }).then(success)
    }

    const fields = useMemo(() => {
        let fields = [];

        if (goal?.type === 'TEAM' || goal?.type === 'INDIVIDUAL') {
            if (!state.entities?.[goal.type]) {
                loadEntitiesByType(goal.type);
                return fields;
            }

            fields.push({
                field: TagInput,
                props: {
                    name: 'entityId',
                    label: goal?.type === 'TEAM' ? 'Team' : 'Driver',
                    singleOption: true,
                    options: state.entities[goal.type].map(e => ({
                        name: e?.user?.friendlyName ?? e.name,
                        value: e.id,
                        entity: e,
                    })),
                    Required: true,
                }
            })
        }

        // todo create fields resolver

        fields.push({
            field: TagInput,
            props: {
                name: 'metric',
                label: 'Metric',
                singleOption: true,
                options: metrics?.map(m => ({
                    name: m.name,
                    value: m.key,
                    metric: m,
                })),
                Required: true,
                onChange: ([value]) => setMetric(value?.metric)
            }
        });

        fields = fields.concat(getFieldsForMetric(metric));

        return fields;
    }, [metric, metrics, goal?.type, state.entities])

    return (
        <Dialog
            success={handleSubmit}
            cancel={cancel}
            modal={{
                removeHeader: false,
                closeOnOverlay: false,
                title: goal.id ? 'Edit Custom Coaching' : 'Add Custom Coaching',
            }}
            align={{
                header: 'left',
                content: 'left',
                footer: 'right'
            }}
            fields={[...fields]}
            buttons={[{
                type: 'primary',
                text: 'Save'
            }]}
            defaultValues={{
                ...goal,
                metric: goal?.metric?.key
            }}
        >
            {(loading?.savingGoal || loading?.entities) ? (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            ) : null}
        </Dialog>
    )
}

const AddGoalButton = ({
    type
}) => {
    const [visible, setVisible] = useState(false);

    const success = () => {
        setVisible(false);
        showToast({
            type: 'success',
            title: 'Goal Saved',
            timeout: 5000,
        })
    };

    const cancel = () => {
        setVisible(false);
    }

    return <>
        <Button
            onClick={() => setVisible(true)}
            size={'small'}
            type={'primary'}
        >Add Custom Coaching</Button>

        {visible && (
            <SaveGoalDialog
                goal={{
                    type,
                }}
                success={success}
                cancel={cancel}
            />
        )}
    </>
}

const MetricGoalController = ({
    metric
}) => {
    // const [{ loading }] = useContext(ScorecardContext);
    const { saveGoal } = useScorecardApi(ScorecardContext);
    const [loading, setLoading] = useState(false);

    const { register, getValues } = useForm({
        defaultValues: {
            goal: metric?.goal?.goal
        }
    });
    const inputRef = useRef();

    const fields = getFieldsForMetric(metric);

    const handleSave = hide => {
        let goal = [getValues('goal')].flat().pop();
        setLoading(true);
        saveGoal({
            ...metric.goal,
            metric: metric.key,
            goal,
        }).then(() => {
            setLoading(false);
            showToast({
                type: 'success',
                title: 'Goal saved',
                timeout: 5000,
            })
            hide();
        })
    }

    const handleRemove = () => {
        setLoading(true);
        saveGoal({
            ...metric.goal,
            goal: null,
        }).then(() => {
            setLoading(false);
            showToast({
                type: 'success',
                title: 'Goal removed',
                timeout: 5000,
            })
        })
    }

    return (
        <AddOrEdit
            mode={'inline'}
            enabled={!(metric && metric.key === 'HoursWorked')}
            collection={metric?.goal?.goal ? [metric?.goal?.goal] : []}
            onOpen={() => {
                setTimeout(
                    () => {
                        const input = inputRef.current.parentNode.querySelector("input");
                        input.style.minWidth = 0;
                        input.focus();
                    },
                    10
                );
            }}
            collectionRender={(collection, { show }) =>
                <Link style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                    <span>{collection.join(", ")}</span>
                    <Spacer left={1} />
                    {(metric && metric.key !== 'HoursWorked') && <>
                        <ButtonIcon
                            title={'Edit'}
                            icon={Icon.Edit}
                            size="20px"
                            onClick={show}
                        />
                        <ButtonIcon
                            title={'Remove'}
                            icon={Icon.Times}
                            size="20px"
                            color={Theme.colors.error.border}
                            onClick={handleRemove}
                        />
                    </>}
                    {loading && <LoadingIndicator width={20} />}
                </Link>
            }
        >
            {({ hide }) => (
                <>
                    {fields.map(({ field, props }) =>
                        React.createElement(
                            field,
                            {
                                ...props,
                                key: props.name,
                                ref: elm => {
                                    inputRef.current = elm;
                                    register(elm);
                                },
                                label: '',
                                size: 'extraSmall'
                            }
                        )
                    )}
                    <Spacer left={1} inline>
                        <Action
                            type="primary"
                            onClick={() => handleSave(hide)}
                        >
                            <Icon.Check color={'white'} size={'20px'} />
                        </Action>
                        <Action
                            type="cancel"
                            onClick={hide}
                        >
                            <Icon.Times color={Theme.colors.info.border} size={'20px'} />
                        </Action>
                    </Spacer>
                    {loading && <LoadingIndicator width={20} />}
                </>
            )}
        </AddOrEdit>
    )
}

const OnOffController = ({
    metric
}) => {
    const [isOn, setIsOn] = useState(metric?.goal?.onOff !== false);
    const { saveGoal } = useScorecardApi(ScorecardContext);
    const [loading, setLoading] = useState(false);

    const handleChange = () => {
        setLoading(true);
        saveGoal({
            ...metric.goal,
            metric: metric.goal?.metric?.key ?? metric.key,
            onOff: isOn ? false : true,
        }).then(() => {
            setLoading(false);
            showToast({
                type: "success",
                title: "Forced Coaching On/Off saved",
                timeout: 5000,
            });
        });

        setIsOn(!isOn);
    };

    return (<><Toggle name={"active"} on={isOn} onChange={handleChange} />{ loading && <LoadingIndicator width={20} /> }</>);
}

const TriggerController = ({
    metric
}) => {
    // const [{ loading }] = useContext(ScorecardContext);
    const { saveGoal } = useScorecardApi(ScorecardContext);
    const [loading, setLoading] = useState(false);

    const { register, getValues } = useForm({
        defaultValues: {
            triggers: metric?.goal?.triggers
        }
    });
    const inputRef = useRef();
    const fields = getFieldsForTriggerMetric(metric);
    
    const handleSave = hide => {
        let triggers = [getValues("triggers")].flat().pop();
        setLoading(true);
        saveGoal({
            ...metric.goal,
            metric: metric.key,
            triggers,
        }).then(() => {
            setLoading(false);
            showToast({
                type: "success",
                title: "Forced Coaching Trigger saved",
                timeout: 5000,
            });
            hide();
        })
    }

    const handleRemove = () => {
        saveGoal({
            ...metric.goal,
            triggers: 0,
        }).then(() => {
            showToast({
                type: "success",
                title: "Forced Coaching Trigger removed",
                timeout: 5000,
            });
        });
    }

    return (
        <AddOrEdit
            mode={'inline'}
            enabled={!(metric && metric.key === 'HoursWorked')}
            collection={metric?.goal?.triggers ? [metric?.goal?.triggers] : []}
            onOpen={() => {
                setTimeout(
                    () => {
                        const input = inputRef.current.parentNode.querySelector("input");
                        input.style.minWidth = 0;
                        input.focus();
                    },
                    10
                );
            }}
            collectionRender={(collection, { show }) =>
                <Link style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                    <span>{collection.join(", ")}</span>
                    <Spacer left={1} />
                    {(metric && metric.key !== 'HoursWorked') && <>
                        <ButtonIcon
                            title={'Edit'}
                            icon={Icon.Edit}
                            size="20px"
                            onClick={show}
                        />
                        <ButtonIcon
                            title={'Remove'}
                            icon={Icon.Times}
                            size="20px"
                            color={Theme.colors.error.border}
                            onClick={handleRemove}
                        />
                    </>}
                    {loading && <LoadingIndicator width={20} />}
                </Link>
            }
        >
            {({ hide }) => (
                <>
                    {fields.map(({ field, props }) =>
                        React.createElement(
                            field,
                            {
                                ...props,
                                key: props.name,
                                ref: elm => {
                                    inputRef.current = elm;
                                    register(elm);
                                },
                                label: '',
                                size: 'extraSmall'
                            }
                        )
                    )}
                    <Spacer left={1} inline>
                        <Action
                            type="primary"
                            onClick={() => handleSave(hide)}
                        >
                            <Icon.Check color={'white'} size={'20px'} />
                        </Action>
                        <Action
                            type="cancel"
                            onClick={hide}
                        >
                            <Icon.Times color={Theme.colors.info.border} size={'20px'} />
                        </Action>
                    </Spacer>
                    {loading && <LoadingIndicator width={20} />}
                </>
            )}
        </AddOrEdit>
    )
}

export const AutomatedTipsPreviewStyle = styled.div`
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 30%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
    } 
`;


const CustomStatus = styled(Status)`
  height:auto;
  display:flex;
  align-items:baseline;
  width:90%;
  word-break:initial;
  line-height:1.5
`;

const CustomSpacer = styled(Spacer)`
position:relative;

&:before {
    content: "*";
    position:absolute
    left:6px;
    top:0;    
  }
`;

const AutomatedTipsController = ({
    metric
}) => {
    const newKey = '__new';
    const defaultList = metric.goal?.options?.list ?? metric?.options?.list ?? {};
    const [list, setList] = useState(defaultList);

    const { register, getValues, watch, setValue, handleSubmit, triggerValidation } = useForm();
    const fields = getFieldsForMetric(metric);

    const [{ loading }] = useContext(ScorecardContext);
    const { saveGoal } = useScorecardApi(ScorecardContext);

    const onSubmit = async data => {
        delete data[newKey];

        const newList = Object.keys(list).reduce((all, next) => {
            let key = watch(next).key ?? next;

            let label = key;
            switch (metric.metricType) {
                case 'LOWEST_PERCENT':
                case 'HIGHEST_PERCENT':
                    label = `${key}%`;
                    break;
            }

            all[key] = {
                ...list[next],
                ...data[next],
                color: watch(next)?.color?.[0] ?? list[next].color,
                label,
            }
            return all;
        }, {});

        await saveGoal({
            ...metric.goal,
            metric: metric.goal?.metric?.key ?? metric.key,
            options: {
                ...metric.goal?.options,
                list: newList
            }
        }).then(() => {
            setList(newList);
            showToast({
                type: 'success',
                title: 'Goal Saved',
                timeout: 5000,
            })
        })
    }

    const unsetKey = key => {
        let copy = { ...list };
        delete copy[key];
        setList(copy);
    }

    const setKey = async evt => {
        evt.preventDefault();

        const valid = await triggerValidation(`${newKey}.key`);

        if (!valid)
            return;

        let copy = { ...list };
        let values = getValues();
        const key = Array.isArray(values[`${newKey}.key`]) ? values[`${newKey}.key`][0] : values[`${newKey}.key`];

        if (key === undefined)
            return;

        const defaultColor = Theme.colors.info.shadow;

        copy[key] = {
            color: values[`${newKey}.color`][0] ?? defaultColor,
            desc: values[`${newKey}.desc`] ?? '',
        }

        setValue(key, copy[key]);
        setValue(newKey, {
            key: '',
            color: defaultColor,
            desc: '',
        });
        setList(copy);
    }
    const defaultColors = ['#7C98B6', '#00A4BD', '#00BDA5', '#F5C26B', '#F2545B', '#f03063', '#BD37A4', '#7B87D4', '#516F90', '#0091AE', '#00A38D', '#DBAE60', '#D94C53', '#cc2957', '#853E78', '#5A65B0', '#CBD6E2', '#7FD1DE', '#7FDED2', '#FAE0B5', '#F8A9AD', '#f59fb7', '#E3AFD9', '#B4BBE8', '#EAF0F6', '#E5F5F8', '#E5F8F6', '#FEF8F0', '#FDEDEE', '#fce3eb', '#F7E4F4', '#F0F1FA'];

    return <AutomatedTipsPreviewStyle>
        <AddOrEdit
            collection={[defaultList]}
            modalProps={{
                title: 'Edit Tips',
                closeOnOverlay: false,
                width: '740px',
            }}
            collectionRender={([collection], { show }) => <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: 8,
                }} onClick={show}>
                    {Object.entries(collection).map(([key, value]) =>
                        <CustomStatus key={key} small={false} style={{
                            borderColor: value.color,
                            color: value.color,
                            backgroundColor: `${value.color}22`,
                        }}>
                            <strong >
                                {value.label}
                            </strong>

                            {value.desc.length > 0 &&
                                <CustomSpacer inline left={3} right={2}>
                                    {value.desc}
                                </CustomSpacer>
                            }

                        </CustomStatus>
                    )}
                    <Tag inline style={{
                        border: 0,
                    }}>
                        <Icon.Edit size={'16px'} color={Theme.colors.info.border} style={{ verticalAlign: 'text-bottom' }} />
                        Edit Tips
                    </Tag>
                </div>
            </>}
        >
            {({ hide }) => <>
                <form onSubmit={evt => {
                    evt.preventDefault();
                    handleSubmit(onSubmit)().then(hide)
                }}>
                    <Table style={{ width: '100%' }}>
                        <TableHeader
                            headers={[
                                { label: 'Color', width: '64px' },
                                { label: 'Up To', width: '164px' },
                                { label: 'Message', width: '324px' },
                                { label: '', width: 'auto' },
                            ]}
                        />
                        {Object.entries(list).map(([key, value]) =>
                            <TableRow key={key}>
                                <TableData width={'64px'} style={{ position: 'relative', zIndex: 1 }}>
                                    <InputColor
                                        ref={register}
                                        name={`${key}.color`}
                                        value={defaultColors.includes(value.color)?value.color:'#CBD6E2'}
                                    />
                                </TableData>
                                <TableData width={'164px'}>
                                    {fields.map(({ field, props, validation }) =>
                                    {
                                        return (
                                            props?.options !== undefined ?
                                                React.createElement(field, {
                                                    ...props,
                                                    ref: register({
                                                        ...validation,
                                                    }),
                                                    name: `${key}.key`,
                                                    key: props.name,
                                                    label: false,
                                                    size: "small",
                                                    defaultValues: [key],
                                                    disabled: metric && metric.key === "HoursWorked",
                                                }) : React.createElement(field, {
                                                    ...props,
                                                    ref: register({
                                                        ...validation,
                                                    }),
                                                    name: `${key}.key`,
                                                    key: props.name,
                                                    label: false,
                                                    size: 'small',
                                                    defaultValue: key,
                                                    disabled: metric && metric.key === 'HoursWorked'
                                                }))
                                    }
                                        
                                    )}
                                </TableData>
                                <TableData width={'324px'}>
                                    <Input
                                        ref={register}
                                        name={`${key}.desc`}
                                        defaultValue={value.desc}
                                    />
                                </TableData>
                                {metric && metric.key !== 'HoursWorked' && <TableData width={'auto'} style={{ marginLeft: 'auto' }}>
                                    <ButtonIcon
                                        title={'Remove tip'}
                                        icon={Icon.Times}
                                        color={Theme.colors.error.text}
                                        onClick={() => unsetKey(key)}
                                    />
                                </TableData>}
                            </TableRow>
                        )}
                        {metric && metric.key !== 'HoursWorked' && <TableFooter style={{
                            justifyContent: 'left',
                            alignItems: 'start',
                            padding: 0,
                        }}>
                            <TableData width={'64px'} style={{ position: 'relative', zIndex: 1 }}>
                                <InputColor
                                    ref={register}
                                    name={`${newKey}.color`}
                                />
                            </TableData>
                            <TableData width={'164px'}>
                                {fields.map(({ field, props, validation }) =>
                                    React.createElement(field, {
                                        ...props,
                                        ref: register({
                                            ...validation,
                                        }),
                                        name: `${newKey}.key`,
                                        key: props.name,
                                        label: false,
                                        size: 'small',
                                        help: 'Only one tip per value',
                                        Required: false,
                                    })
                                )}
                            </TableData>
                            <TableData width={'324px'}>
                                <Input
                                    ref={register}
                                    name={`${newKey}.desc`}
                                />
                            </TableData>
                            <TableData width={'auto'} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type={'default'}
                                    onClick={setKey}
                                >Add</Button>
                            </TableData>
                        </TableFooter>}
                    </Table>

                    <Spacer top={5} style={{ textAlign: 'right' }}>
                        <Button
                            type={'primary'}
                        >
                            Save
                        </Button>
                    </Spacer>
                </form>
                {loading.savingGoal && <LoadingIndicator />}
            </>}
        </AddOrEdit>
    </AutomatedTipsPreviewStyle>
}

export const CoachingPreviewStyle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    width: 100%;
    
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 130%);
    } 
`;

const MetricCoachingController = ({
    metric
}) => {
    const [{ loading }] = useContext(ScorecardContext);
    const { saveGoal } = useScorecardApi(ScorecardContext);

    const [visible, setVisible] = useState(false);

    const { register, getValues } = useForm();

    const defaultCoaching = metric.goal?.coaching || metric.coaching;

    const handleSave = () => {
        saveGoal({
            ...metric.goal,
            ...getValues(),
            coaching: getValues().coaching || defaultCoaching,
            metric: metric.key,
        }).then(() => {
            setVisible(false);
            showToast({
                type: 'success',
                title: 'Coaching content saved',
                timeout: 5000,
            })
        })
    }

    return <>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 4,
                width: '100%',
                cursor: 'pointer',
            }}
            onClick={() => setVisible(true)}
        >
            <CoachingPreviewStyle>
                <Link>
                    {metric.goal?.driverDescription ?? metric.description}
                </Link>
            </CoachingPreviewStyle>
            <ButtonIcon
                icon={Icon.Edit}
                style={{
                    flexShrink: 0,
                }}
            />
        </div>

        {visible ? (
            <Modal
                visible={true}
                setVisible={() => setVisible(false)}
                drawer={'right'}
                closeOnOverlay={false}
                title={metric.name}
            >
                <Input
                    ref={register}
                    name={'driverDescription'}
                    label={'Definition'}
                    size={'big'}
                    as={'textarea'}
                    style={{
                        height: 80,
                        fontFamily: 'inherit',
                    }}
                    help={'Short definition for the driver'}
                    defaultValue={metric.goal?.driverDescription ?? metric.description}
                />
                <Spacer bottom={3} />
                <Label>Coaching Content</Label>
                <SlideEditor
                    ref={register}
                    name={'coaching'}
                    defaultValue={defaultCoaching}
                    showModalSection={"scorecardCoaching"} 
                />
                {metric?.goal?.videoObj?.videoUrl && <>
                    <CheckBox
                        name={'isMetricVideoDisplay'}
                        ref={register}
                        defaultValues={[metric.goal.isMetricVideoDisplay.toString()]}
                        options={[{ label: "Disable below video in mobile", value: "true" }]}
                    />
                    <iframe src={metric?.goal?.videoObj?.videoUrl} width="426" height="240" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"></iframe>
                </>
                }

                <div style={{ textAlign: 'right' }}>
                    <Button type='primary' onClick={handleSave}>
                        Save
                    </Button>
                </div>
                {loading?.savingGoal ? <LoadingIndicator /> : null}
            </Modal>
        ) : null}
    </>
}

const MetricStatusToggler = ({
    metric
}) => {
    const [isOn, setIsOn] = useState(metric?.goal?.status !== 2);
    const { saveGoal } = useScorecardApi(ScorecardContext);
    const [loading, setLoading] = useState(false);
    const handleChange = () => {
        setLoading(true);
        saveGoal({
            ...metric.goal,
            metric: metric.goal?.metric?.key ?? metric.key,
            status: isOn ? 2 : 1,
        }).then(() => {
            setLoading(false);
            showToast({
                type: 'success',
                title: 'Goal saved',
                timeout: 5000,
            })
        });

        setIsOn(!isOn);
    }

    return (
        <><Toggle
            name={'active'}
            on={isOn}
            onChange={handleChange}
        />{loading && <LoadingIndicator width={20} />}</>
    )
}

const ArchiveGoalButton = ({
    goal
}) => {
    const [{ loading }] = useContext(ScorecardContext);
    const { saveGoal } = useScorecardApi(ScorecardContext);

    const handleArchive = async () => {
        if (!await confirm({
            icon: true,
            title: 'Please confirm your action',
            text: `
                Do you really want to archive this goal?
                If you just need to disable for a while use the status switcher.
                <br/>
                <br/>
                The goal historical record will remain available for reports.
            `,
        }))
            return;

        saveGoal({
            ...goal,
            isArchive: true
        }).then(() =>
            showToast({
                type: 'success',
                title: 'Goal archived',
            })
        );
    }

    return !goal?.type || goal?.type === 'STATION' ? null : (
        <>
            {loading?.savingGoal ? <LoadingIndicator width={16} /> : null}
            <ButtonIcon
                icon={Icon.Trash}
                color={Theme.colors.error.text}
                onClick={handleArchive}
            />
        </>
    )
}

const MetricRow = ({
    metric,
    children,
    enableAutomatedScorecardCoaching,
    tab
}) => {

    let METRIC_COLUMNS_NEW = (tab === 'station' && enableAutomatedScorecardCoaching) ? METRIC_COLUMNS_STATION : (tab === 'individual') ? METRIC_COLUMNS_DRIVER : METRIC_COLUMNS;

    return (
        <TableRow style={{
            alignItems: 'flex-start',
            position: 'relative',
        }}>
            {children}
            <TableData
                width={METRIC_COLUMNS_NEW.metric.width}
                style={{
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: 4,
                    wordBreak: 'break-word',
                }}>
                <strong>{metric.name}</strong>
                <small>[{metric.sourceAbbr}] {metric.timeframe}</small>
                {/*<small>{  JSON.stringify({v: metric && metric.goal && metric.goal.key !== 'HoursWorked', type: typeof metric && metric.goal && metric.goal.key !== 'HoursWorked'})}</small>*/}
                {/*<small>{  JSON.stringify({key : metric.key, goal: metric.goal.metric})}</small>*/}
                {/*/!*<small>{  JSON.stringify(metric.goal)}</small>*!/*/}

            </TableData>
            <TableData width={METRIC_COLUMNS_NEW.goal.width}>
                <MetricGoalController
                    metric={metric}
                />
            </TableData>
            {(enableAutomatedScorecardCoaching && tab === "station") ? (AutomatedScorecardCoachingMetricArray.includes(metric.key) ?
                <TableData width={METRIC_COLUMNS_NEW.onOff.width}>
                    <OnOffController
                        metric={metric}
                    />
                </TableData> : <TableData width={METRIC_COLUMNS_NEW.onOff.width}></TableData>) : ''}
            {(enableAutomatedScorecardCoaching && tab === "station") ? (AutomatedScorecardCoachingMetricArray.includes(metric.key) ?
                <TableData width={METRIC_COLUMNS_NEW.trigger.width}>
                    <TriggerController
                        metric={metric}
                    />
                </TableData> : <TableData width={METRIC_COLUMNS_NEW.trigger.width}></TableData>) : ''}
            <TableData width={METRIC_COLUMNS_NEW.automatedTips.width}>
                <AutomatedTipsController
                    metric={metric}
                />
            </TableData>
            <TableData width={'100%'} style={{ minWidth: 0 }}>
                <MetricCoachingController
                    metric={metric}
                />
            </TableData>
            <TableData width={'auto'} style={{ marginLeft: 'auto', gap: 12 }}>
                <MetricStatusToggler
                    metric={metric}
                />
                <ArchiveGoalButton
                    goal={metric.goal}
                />
            </TableData>
        </TableRow>
    )
}

const LoadingIndicator = ({ width = 40 }) =>
    <LoadingWrapper>
        <Loading style={{ width }} />
    </LoadingWrapper>;

export const LoadingRow = ({ children }) => {
    const [{ enableAutomatedScorecardCoaching, tab }] = useContext(ScorecardContext);
    let METRIC_COLUMNS_NEW = (tab === 'station') ? METRIC_COLUMNS_STATION : (tab === 'individual') ? METRIC_COLUMNS_DRIVER : METRIC_COLUMNS;
    return <TableRow>
        {children}
        <TableData width={METRIC_COLUMNS_NEW.metric.width}>
            <Skeleton height={20} />
        </TableData>
        <TableData width={METRIC_COLUMNS_NEW.goal.width}>
            <Skeleton height={20} />
        </TableData>
        {(enableAutomatedScorecardCoaching && tab === "station") && <TableData width={METRIC_COLUMNS_NEW.onOff.width}>
            <Skeleton height={20} />
        </TableData>}
        {(enableAutomatedScorecardCoaching && tab === "station") && <TableData width={METRIC_COLUMNS_NEW.trigger.width}>
            <Skeleton height={20} />
        </TableData>}
        <TableData width={METRIC_COLUMNS_NEW.automatedTips.width}>
            <Skeleton height={20} />
        </TableData>
        <TableData width={'20%'}>
            <Skeleton height={20} />
        </TableData>
    </TableRow>
}

const MetricGroups = () => {
    const [{ metrics, type, enableAutomatedScorecardCoaching, tab }] = useContext(ScorecardContext);
    const [contracted, setContracted] = useState([]);

    const grouped = metrics?.filter(m => type === 'STATION' || m.goal?.type === type).reduce((all, next) => {
        all[next.source] = all[next.source] || [];
        all[next.source].push(next);
        return all;
    }, {}) ?? {};

    const toggleContracted = source => {
        let c = [...contracted];

        if (c.includes(source))
            c.splice(c.indexOf(source), 1);
        else
            c.push(source);

        setContracted(c)
    }

    return <>
        {Object.keys(grouped).map(source =>
            <TableRow
                key={source}
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${Theme.colors.info.shadow}`,
                }}
            >
                <TableData
                    width={'100%'}
                    style={{
                        gap: 8,
                        // paddingBottom: 4,
                    }}
                    onClick={() => toggleContracted(source)}
                >
                    <ThirdTitle>
                        {source}
                    </ThirdTitle>
                    <ButtonIcon
                        icon={contracted.includes(source) ? Icon.Expand : Icon.Contract}
                        size={'16px'}
                    />
                </TableData>
                {contracted.includes(source) ? null : (
                    <Table>
                        {grouped[source]?.map(metric =>
                            <MetricRow
                                key={metric.key}
                                metric={metric}
                                enableAutomatedScorecardCoaching={enableAutomatedScorecardCoaching}
                                tab={tab}
                            />
                        )}
                    </Table>
                )}
            </TableRow>
        )}
    </>
}

const StationTab = () => {
    const [state] = useContext(ScorecardContext);
    let METRIC_COLUMNS_NEW = (state.tab === 'station' && state.enableAutomatedScorecardCoaching) ? METRIC_COLUMNS_STATION : METRIC_COLUMNS;
    
    return (
        <Spacer top={5} bottom={10}>
            <Table>
                <TableHeader headers={Object.values(METRIC_COLUMNS_NEW)} />
                {state.loading?.metrics ? (
                    <LoadingRow />
                ) : !state.metrics?.length ? (
                    <TableRow style={{ justifyContent: 'center' }}>
                        <Empty />
                    </TableRow>
                ) : (
                    <MetricGroups />
                )}
            </Table>
        </Spacer>
    )
}

const TeamGroups = () => {
    const [{ goals }] = useContext(ScorecardContext);
    const [contracted, setContracted] = useState([]);

    const toggleContracted = source => {
        let c = [...contracted];

        if (c.includes(source))
            c.splice(c.indexOf(source), 1);
        else
            c.push(source);

        setContracted(c)
    }

    const grouped = goals?.reduce((all, next) => {
        all[next.target?.name] = all[next.target?.name] || [];
        all[next.target?.name].push(next);
        return all;
    }, {}) ?? {};

    return <>
        {Object.keys(grouped).map(team =>
            <TableRow
                key={team}
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${Theme.colors.info.shadow}`,
                }}
            >
                <TableData
                    width={'100%'}
                    style={{
                        gap: 8,
                    }}
                    onClick={() => toggleContracted(team)}
                >
                    <ThirdTitle>
                        {team}
                    </ThirdTitle>
                    <ButtonIcon
                        icon={contracted.includes(team) ? Icon.Expand : Icon.Contract}
                        size={'16px'}
                    />
                </TableData>
                {contracted.includes(team) ? null : (
                    <Table>
                        {grouped[team]?.map(goal =>
                            <MetricRow key={goal.id} metric={{
                                ...goal.metric,
                                goal,
                            }}>
                                <TableData width={'164px'} />
                            </MetricRow>
                        )}
                    </Table>
                )}
            </TableRow>
        )}
    </>
}

const TeamsTab = () => {
    const [state] = useContext(ScorecardContext);

    return (
        <Spacer top={5} bottom={10}>
            <Table>
                <TableHeader
                    headers={[
                        { label: 'Team', width: '164px' },
                        ...Object.values(METRIC_COLUMNS)
                    ]}
                />
                {state.loading?.metrics ? (
                    <LoadingRow>
                        <TableData width={'164px'}>
                            <Skeleton height={20} />
                        </TableData>
                    </LoadingRow>
                ) : !state.goals?.length ? (
                    <TableRow style={{ justifyContent: 'center' }}>
                        <Empty />
                    </TableRow>
                ) : (
                    <TeamGroups />
                )}
                <TableFooter sticky>
                    <ThirdTitle>
                        {/*    {state.goals?.length !== undefined*/}
                        {/*        ? `${state.goals.length} goals`*/}
                        {/*        : <Skeleton width={40} height={16}/>}*/}
                    </ThirdTitle>
                    <AddGoalButton
                        type={'TEAM'}
                    />
                </TableFooter>
            </Table>
        </Spacer>
    )
}

const DriverGroups = () => {
    const [{ goals, enableAutomatedScorecardCoaching, tab }] = useContext(ScorecardContext);
    const [contracted, setContracted] = useState([]);

    const toggleContracted = source => {
        let c = [...contracted];

        if (c.includes(source))
            c.splice(c.indexOf(source), 1);
        else
            c.push(source);

        setContracted(c)
    }

    const grouped = goals?.reduce((all, next) => {
        all[next.target?.user?.friendlyName] = all[next.target?.user?.friendlyName] || [];
        all[next.target?.user?.friendlyName].push(next);
        return all;
    }, {}) ?? {};

    return <>
        {Object.keys(grouped).map(team =>
            <TableRow
                key={team}
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${Theme.colors.info.shadow}`,
                }}
            >
                <TableData
                    width={'100%'}
                    style={{
                        gap: 8,
                    }}
                    onClick={() => toggleContracted(team)}
                >
                    <ThirdTitle>
                        {team}
                    </ThirdTitle>
                    <ButtonIcon
                        icon={contracted.includes(team) ? Icon.Expand : Icon.Contract}
                        size={'16px'}
                    />
                </TableData>
                {contracted.includes(team) ? null : (
                    <Table>
                        {grouped[team]?.map(goal =>
                            <MetricRow key={goal.id} metric={{
                                ...goal.metric,
                                goal,
                            }}
                            enableAutomatedScorecardCoaching={enableAutomatedScorecardCoaching}
                            tab={tab}
                            >
                                <TableData width={'164px'} />
                            </MetricRow>
                        )}
                    </Table>
                )}
            </TableRow>
        )}
    </>
}

const DriversTab = () => {
    const [state] = useContext(ScorecardContext);

    return (
        <Spacer top={5} bottom={10}>
            <Table>
                <TableHeader
                    headers={[
                        { label: 'Driver', width: '164px' },
                        ...Object.values(METRIC_COLUMNS_DRIVER)
                    ]}
                />
                {state.loading?.metrics ? (
                    <LoadingRow>
                        <TableData width={'164px'}>
                            <Skeleton height={20} />
                        </TableData>
                    </LoadingRow>
                ) : !state.goals?.length ? (
                    <TableRow style={{ justifyContent: 'center' }}>
                        <Empty />
                    </TableRow>
                ) : (
                    <DriverGroups />
                )}
                <TableFooter sticky>
                    <ThirdTitle>
                        {/*{state.goals?.length !== undefined*/}
                        {/*    ? `${state.goals.length} goals`*/}
                        {/*    : <Skeleton width={40} height={16}/>}*/}
                    </ThirdTitle>
                    <AddGoalButton
                        type={'INDIVIDUAL'}
                    />
                </TableFooter>
            </Table>
        </Spacer>
    )
}

const GoalsAndTipsFilter = () => {
    const [selectedStation] = useSelectedStation();
    const [{ metrics, loading, ...state }, dispatch] = useContext(ScorecardContext);
    const { loadAvailableMetrics, cancel } = useScorecardApi(ScorecardContext);

    const { register, handleSubmit } = useForm();
    const onFilter = ({ source, metric }) => {
        // dispatch({
        //     type: 'ADD',
        //     payload: {
        //         source: source || null,
        //         key: metric[0],
        //     }
        // })
    }

    useEffect(() => {
        if (selectedStation && state.type)
            loadAvailableMetrics()

        return () => cancel();
    }, [selectedStation, state.type, state.source, state.key]);

    // const sources = useMemo(() => {
    //     return [{name: 'Any', value: ''}]
    //         .concat(
    //             [...new Set(metrics?.map(m => m.source))]
    //                 .map(s => ({
    //                     name: s,
    //                     value: s,
    //                 }))
    //         )
    // }, [metrics]);

    return (
        <form onSubmit={handleSubmit(onFilter)}>
            <StationSelector />
            <Spacer inline right={5} />
            {/*<Dropdown*/}
            {/*    ref={register}*/}
            {/*    name='source'*/}
            {/*    label='Source'*/}
            {/*    size={'small'}*/}
            {/*    singleOption={true}*/}
            {/*    placeholder={loading?.metrics ? 'Loading...' : 'Any'}*/}
            {/*    options={sources}*/}
            {/*    onChange={() => setTimeout(() => handleSubmit(onFilter)(), 10)}*/}
            {/*/>*/}
            {/*<Spacer inline right={5}/>*/}
            {/*<TagInput*/}
            {/*    ref={register}*/}
            {/*    name='metric'*/}
            {/*    label='Metric'*/}
            {/*    singleOption={true}*/}
            {/*    placeholder={loading?.metrics ? 'Loading...' : ''}*/}
            {/*    options={metrics?.map(m => ({*/}
            {/*        name: m.name,*/}
            {/*        value: m.key,*/}
            {/*        metric: m,*/}
            {/*    }))}*/}
            {/*    onChange={() => setTimeout(() => handleSubmit(onFilter)(), 10)}*/}
            {/*/>*/}
        </form>
    )
}

const GoalsAndTips = ({ history, location }) => {
    const [, dispatch] = useContext(ScorecardContext);
    const [showHelp, setShowHelp] = useState(false);

    const tab = useMemo(() => {
        return location.pathname.split('/').length === 4
            ? (location.pathname.split('/')).pop()
            : 'station'
            ;
    }, [location.pathname]);

    useEffect(() => {
        dispatch({
            type: 'ADD',
            payload: {
                tab,
                type: tab.toUpperCase(),
            }
        })
    }, [dispatch, tab]);

    return <>
        <Title>
            Coaching
            <HelpButton onClick={() => setShowHelp(true)}>How Does It Work?</HelpButton>
        </Title>
        <Spacer top={3} />
        <Text>Setup coaching, goals and automatic tip messages for you drivers, teams and station</Text>
        <Spacer top={5} />
        <GoalsAndTipsFilter />
        <Spacer bottom={3} />
        <TabList
            defaultTab={tab}
            tabs={{
                'station': {
                    title: 'Station',
                    content: <StationTab />
                },
                'individual': {
                    title: 'Drivers',
                    content: <DriversTab />
                }
            }}
            onChange={newTab => {
                if (location.pathname.match(tab))
                    history.replace(location.pathname.replace(tab, newTab))
                else
                    history.replace(location.pathname + `/${newTab}`)
            }}
        />

        {!showHelp ? null : (
            <Modal
                visible={true}
                setVisible={() => setShowHelp(false)}
                title={'How Does It Work?'}
                drawer={'right'}
            >
                <Text>Our Coaching web UI provides you granular control over how the driver's mobile scorecard looks and the expectations and coaching that they see.  Review the sections below to get started!</Text>
                <Spacer bottom={5} />

                <ThirdTitle>Station</ThirdTitle>
                <Spacer bottom={1} />
                <Text>Set the default goals, tips, and detailed coaching for the entire station.</Text>

                <Spacer bottom={3} />
                <ThirdTitle>Teams</ThirdTitle>
                <Spacer bottom={1} />
                <Text>Select the metrics and team goals that your drivers will compete on.</Text>

                <Spacer bottom={3} />
                <ThirdTitle>Drivers</ThirdTitle>
                <Spacer bottom={1} />
                <Text>Create a specific goal or provide individual coaching for a specific driver (these will override the station goals for that individual).</Text>

                <Spacer bottom={3} />
                <ThirdTitle>Goal</ThirdTitle>
                <Spacer bottom={1} />
                <Text>For any listed metric, pick your target goal for what you would like your DSP, Team, or individual driver to attain. It will display the goal value in the mobile app for each driver to see.</Text>

                <Spacer bottom={3} />
                <ThirdTitle>Automated Tips</ThirdTitle>
                <Spacer bottom={1} />
                <Text>Create metric levels and assign colors to visually communicate whether the goal was attained, and optionally add an automated tip to provide a helpful hint on what needs to be improved or celebrate the making of the goal.</Text>

                <Spacer bottom={3} />
                <ThirdTitle>Coaching</ThirdTitle>
                <Spacer bottom={1} />
                <Text>Located behind the metric and tip, the mobile app's coaching slider allows you to provide your driver detailed information about the metric itself and how to improve their score. We have provided default content, but feel free to edit or even paste in a video link. Like the Announcements, it will play right out of the slider!</Text>
            </Modal>
        )}
    </>
}
