import React from 'react';
import {MultipleChoicesType} from "./store";
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {Button, Icon} from '@dspworkplace/ui';
import {PrimaryButton} from "./shared";
import styled from "styled-components";

const ChoicesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    height: 40px;

    input.name {
        width: 320px;
    }

    input.checkbox {
        display: flex;
        align-items: center;
        height: 100%;
    }

    label.isPassRequired {
        display: flex;
        align-items: center;
        height: 100%;
    }

    label.notifyIfChosen {
        display: flex;
        align-items: center;
        height: 100%;
    }
`;

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: MultipleChoicesType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function MultipleChoicesQuestionForm(props: Props) {
    const {item, updateItem} = props;
    const {name, description, choices, disabledQuestion} = item.content;
    const { register, handleSubmit, control, reset } = useForm({
        defaultValues: {
            name,
            description,
            choices,
            disabledQuestion,
        },
    });
    // const [notifyChecked, setNotifyChecked] = useState<boolean>(notify);
    const {
        fields,
        append,
        remove
    } = useFieldArray<{ name: string, isPassRequired: boolean, notifyIfChosen: boolean, notifyIfNotChosen: boolean }>({
        name: "choices",
        control
    });

    const addChoice = () => {
        append({name: '', isPassRequired: false, notifyIfChosen: false, notifyIfNotChosen: false});
    }

    const removeChoice = (index) => () => {
        try {
            remove([index]);
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
    }, [fields.length])

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            {/*<label className={'notify'}*/}
            {/*       htmlFor={`notify`}>Switcher for notify</label>*/}
            {/*<input*/}
            {/*    name={`notify`}*/}
            {/*    type="checkbox"*/}
            {/*    className={'checkbox'}*/}
            {/*    defaultChecked={notifyChecked}*/}
            {/*    ref={register}*/}
            {/*    onChange={() => {*/}
            {/*        setNotifyChecked(!notifyChecked);*/}
            {/*    }*/}
            {/*    }*/}
            {/*/>*/}
            <div className={'choices'}>Choices</div>
            <div>
                <PrimaryButton size={'28px'} className={'icon'} onClick={addChoice}><Icon.Plus
                    size={'20px'}/></PrimaryButton>
                {fields.map((field, index) => {
                    return (
                        <ChoicesContainer key={field.id}>
                            <input
                                className={'name'}
                                name={`choices[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                            />
                            {/*<label className={'isPassRequired'}*/}
                            {/*       htmlFor={`choices[${index}].isPassRequired`}>Is required to Pass?</label>*/}
                            {/*<input*/}
                            {/*    name={`choices[${index}].isPassRequired`}*/}
                            {/*    type="checkbox"*/}
                            {/*    className={'checkbox'}*/}
                            {/*    defaultChecked={field.isPassRequired}*/}
                            {/*    ref={register()}/>*/}
                            {/*{notifyChecked && <>*/}
                            {/*    <label className={'notifyIfChosen'}*/}
                            {/*           htmlFor={`choices[${index}].notifyIfChosen`}>Notify if chosen?</label>*/}
                            {/*    <input*/}
                            {/*        name={`choices[${index}].notifyIfChosen`}*/}
                            {/*        type="checkbox"*/}
                            {/*        className={'checkbox'}*/}
                            {/*        defaultChecked={field.notifyIfChosen}*/}
                            {/*        ref={register()}/>*/}
                            {/*</>}*/}
                            {/*{!notifyChecked && <>*/}
                            {/*    <label className={'notifyIfNotChosen'}*/}
                            {/*           htmlFor={`choices[${index}].notifyIfNotChosen`}>Notify if not chosen?</label>*/}
                            {/*    <input*/}
                            {/*        name={`choices[${index}].notifyIfNotChosen`}*/}
                            {/*        type="checkbox"*/}
                            {/*        className={'checkbox'}*/}
                            {/*        defaultChecked={field.notifyIfNotChosen}*/}
                            {/*        ref={register()}/>*/}
                            {/*</>}*/}
                            <PrimaryButton size={'28px'} className={'icon'} onClick={removeChoice(index)}><Icon.Times
                                size={'20px'}/></PrimaryButton>
                        </ChoicesContainer>
                    );
                })}
            </div>
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default MultipleChoicesQuestionForm;