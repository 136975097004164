import React, {useMemo} from "react";
import {Combobox, ComboboxPropsOption} from "../../../Form/Combobox";
import {GridColumnProps} from "../../types";

export interface groupOptions {
    groupBy?: GridColumnProps[]
    selectedGroups?: readonly string[] | null
    setGroup:  React.Dispatch<React.SetStateAction<readonly string[]>>
}

const GroupBy = ({groupBy=[],setGroup,selectedGroups=[]}: groupOptions) => {
    const groupOptions:ComboboxPropsOption[number] = useMemo(()=>{
        const groupArray = groupBy.map(column =>({label:column.name, value: column.key}))
        groupArray.push({label:'Ungrouped', value: ''})
        return groupArray
    },[groupBy])
    return (
        <div className='grid-tools-group'>
            <Combobox
                size={'small'}
                label={'Group By'}
                options={groupOptions}
                initialValue={selectedGroups ? selectedGroups[0] : undefined}
                onChange={(field) => {
                    setGroup(field?.value ? [field.value] : [])
                }}
            />
        </div>
    )
}

export default GroupBy