import apiFactory from '../../../api/factory';
import {VehicleMaintenanceVendor} from "../types";

export type VehicleMaintenanceVendorGetParams = {
    id: number,
    station_id: number,
    name: string,
    location: string,
    phone:string
}

const {call} = apiFactory();
const resource = 'vehicle/maintenance/vendor'
export const GetMaintenanceVendors = (props: VehicleMaintenanceVendorGetParams): VehicleMaintenanceVendor[] => {
    const {station_id} = props
    return call(props,`api/v2/${resource}?station_id=${station_id}`,'get')
}