import React, {useEffect, useRef, useState} from "react";
import {Content, Menu, Navigator} from "../../components/Menus/Navigator";
import {Spacer, SubTitle, Theme, ThirdTitle} from "@dspworkplace/ui";
import PersonalSettings from './pages/personalSettings'
import PasswordPage from './pages/password'
import Loading, {LoadingWrapper} from "../../components/Loading";
import {getUserId, useAuth} from "../../Auth";
import {engine} from "../../Utilities/index";

const App = () => {
    const {api} = useAuth();
    const [status,setStatus] = useState('loading');
    const [data,setData] = useState({user:[]});
    const contentRef = useRef();

    const scrollSpy = evt => {
        let sections = contentRef.current.children;
        let offsetTop = contentRef.current.offsetTop + 40;
        let index = sections.length - [...sections].reverse()
            .findIndex(section => contentRef.current.scrollTop >= section.offsetTop - offsetTop ) - 1;
        let current = sections[index];

        if ( !current )
            current = sections[0];

        window.history.replaceState(
            null,
            null,
            `${window.location.pathname}#${current.id}`
        );

        let active = document.querySelector(`#menu a.active`);
        let willBeActive = document.querySelector(`a[href="#${current.id}"]`);

        if ( active )
            active.classList.remove( 'active' )

        if ( willBeActive )
            willBeActive.classList.add( 'active' )
    };

    useEffect( () => {
        if ( !contentRef.current )
            return ()=>{};

        contentRef.current.addEventListener( 'scroll', scrollSpy );

        return () => contentRef.current.removeEventListener( 'scroll', scrollSpy );
    }, [ contentRef ]);

    useEffect(()=>{
        scrollSpy();

        const params = {
            actions: {
                response: {
                    User: {
                        findOneBy: {
                            criteria: {
                                id: getUserId()
                            },
                            get: "user",
                            includes: {
                                0: "id",
                                1: "firstName",
                                2: "lastName",
                                3: "friendlyName",
                                4: "email",
                                "company":{
                                    "1":"recurringWeeks"
                                }
                            }
                        }
                    }
                }
            }
        };

        engine().post('/api/lazy/manage/data', params,{
            cache: {
                ignoreCache: true
            }
        }).then(
            response => {
                setData({user:response.data.data.user});
                setStatus('done');
            },
            error => { setStatus('error');}
        );

    },[])

    return (
        <div style={{display: 'flex'}}>
            {status === 'loading' && <LoadingWrapper>
                <Loading style={{height: 80}}/>
            </LoadingWrapper>}
            <Navigator className='scrollable'>
                <SubTitle>Profile</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                <Menu id='menu'>
                    <li>
                        <ThirdTitle>Personal Settings</ThirdTitle>
                    </li>
                    <li>
                        <a href="#user-information">User Information</a>
                        <a href="#password">Password</a>
                    </li>
                </Menu>
            </Navigator>
            <Content className='scrollable content' ref={contentRef}>
                <PersonalSettings user={data.user} />
                <PasswordPage />
            </Content>
        </div>
    )
}



export default App;
