import React, { useContext, useEffect, useState } from "react";
import { SchedulesAddDispatchContext, SchedulesAddStateContext } from "../index";
import { Spacer, Button, Input, CheckBox, Alert } from "@dspworkplace/ui";
import Information from "./Information";
import styled from "styled-components";
import Drivers from "./Drivers";
import Design from "./Design";
import ConflictRoutes from "./ConflictRoutes";
import { useLocation } from "react-router-dom";
import { getCompanyId, useAuth } from "../../../../../../Auth";
import { Grid } from "../index";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loading from "../../../../../../components/Loading";
import { alert } from "../../../../../../components/Alert";
import { confirm } from "../../../../../../components/Confirm";
import toast from "../../../../../../components/Toasts/toast";
import { useForm } from "react-hook-form";
import { AccessCheck, IsGranted } from "../../../../../../security";

const TableData = styled.div`
    display: inline-block;
    margin: 5px;
    display: inline-block;
`;

const ConfirmationPanel = (preState) => {
    const { state, dispatch } = React.useContext(SchedulesAddStateContext);
    const schedulesAddDispatchContext = useContext(SchedulesAddDispatchContext);
    const station = preState.preState.station;
    const { api } = useAuth();
    const [loading, setLoading] = useState(false);
    const [sendNotification, setSendNotification] = useState(false);
    const { register, handleSubmit, errors, getValues } = useForm();

    const getConflictRoutes = async () => {
        let driverList = [];
        Object.keys(state.drivers).forEach(function (i) {
            driverList.push(state.drivers[i].id);
        });
        let tempParam = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getConflictDriverRoutes",
                            criteria: {
                                design: state.design.shiftTypes,
                                startDate: moment(state.information.startDate).utc().format("YYYY-MM-DD"),
                                endDate: moment(state.information.endDate).utc().format("YYYY-MM-DD"),
                                driver: driverList,
                                scheduleId: state.isEdit == 1 ? state.id : 0,
                                driversEffectiveDate:state.driversEffectiveDate,
                            },
                            get: "routes",
                        },
                    },
                },
            },
        };
        try {
            let conflictRouteResponse = await api.post("/api/lazy/manage/data", tempParam);
            if (conflictRouteResponse.data.data.routes.length > 0) {
                schedulesAddDispatchContext.dispatch({ type: "setConflictStatus", status: true });
                schedulesAddDispatchContext.dispatch({
                    type: "setConflictRoutes",
                    routes: conflictRouteResponse.data.data.routes,
                    open: true,
                });
            } else {
                schedulesAddDispatchContext.dispatch({ type: "setConflictStatus", status: true });
                schedulesAddDispatchContext.dispatch({ type: "setIsRouteConflictModalOpen", open: false });
            }
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    function weeksBetween(date) {
        if (moment(date).day() === 0) {
            return moment(date, "YYYY-MM-DD").add(1, "days").isoWeekYear();
        } else {
            return moment(date, "YYYY-MM-DD").isoWeekYear();
        }
    }

    const formSubmit = async (data) => {
        let notification = {};
        notification["sendNotifications"] = getValues().sendNotifications === "true";
        notification["sendSMSNotifications"] = getValues().sendSMSNotifications === "true";
        notification["message"] = getValues().notification ? getValues().notification : null;
        notification["type"] = "Schedule";
        setLoading(true);

        let enddate = "";
        let shiftSkillArray = {};
        let temp = [];
        let skillTemp = [];
        let shiftIdArray = [];
        let status = false;
        let dynamicWeek = state.information.recurringWeeks;
        let scheduleYear = parseInt(weeksBetween(moment(state.information.startDate).format("YYYY-MM-DD")));

        Object.keys(state.design.weekArr).forEach(function (index) {
            if (state.design.shiftTypes[index]) {
                Object.keys(state.design.shiftTypes[index]).forEach(function (sindex) {
                    temp = [];
                    if (shiftIdArray.indexOf(state.design.shiftTypes[index][sindex].skillId) == -1) {
                        shiftIdArray.push(state.design.shiftTypes[index][sindex].skillId);

                        let cnt = 0;
                        Object.keys(state.drivers).forEach(function (i) {
                            skillTemp = [];
                            Object.keys(state.drivers[i].skills).forEach(function (s) {
                                let tempSkillIDs = state.drivers[i].skills[s].skill.id;
                                skillTemp.push(tempSkillIDs.toString());
                            });

                            let tempId = state.design.shiftTypes[index][sindex].skillId;
                            if (!skillTemp.includes(tempId.toString())) {
                                let name = state.design.shiftTypes[index][sindex].skill;
                                temp[cnt] = {
                                    skill: name,
                                    shift: state.design.shiftTypes[index][sindex].name,
                                    driver: state.drivers[i].name,
                                };
                                cnt++;
                            }
                        });
                        shiftSkillArray[state.design.shiftTypes[index][sindex].skillId] = temp;
                    }
                });
            }
        });

        let messageString = "";
        let driverName = "";

        Object.keys(shiftIdArray).forEach(function (index) {
            if (shiftSkillArray[shiftIdArray[index]]) {
                driverName = "";
                Object.keys(shiftSkillArray[shiftIdArray[index]]).forEach(function (lindex) {
                    driverName = driverName + shiftSkillArray[shiftIdArray[index]][lindex].driver + " ,";
                });
                if (driverName != "") {
                    driverName = driverName.substring(0, driverName.length - 2);
                    messageString =
                        messageString +
                        '<tr><td style="border: 1px solid #e6d8d8;padding:5px;">' +
                        shiftSkillArray[shiftIdArray[index]][0].shift +
                        '</td> <td style="border: 1px solid #e6d8d8;padding:5px;">' +
                        driverName +
                        "</td></tr> ";
                }
            }
        });

        if (messageString != "") {
            messageString =
                'The following driver(s) do not possess the required Skill(s) for the assigned Shift Type. Please add the needed Skill(s) or remove driver(s) before continuing.</br></br></br><table  style="width:100%;border-collapse: collapse;margin-top: 13px;"><tr><th style="border: 1px solid #e6d8d8;padding:5px;">Shift Type</th><th style="border: 1px solid #e6d8d8;padding:5px;">Driver</th></tr>' +
                messageString +
                "</table><br/>";

            messageString.slice(0, -2);
            await alert(messageString);
            setLoading(false);
            return;
        }

        /*---- start check recurring conflict routes-------*/
        if (state.information.recurring === true) {
            let tempDriverList = [];
            Object.keys(state.drivers).forEach(function (i) {
                tempDriverList.push(state.drivers[i].id);
            });

            let tempParam = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "getRecurringConflictDriverRoutes",
                                criteria: {
                                    design: state.design.shiftTypes,
                                    startDate: moment(state.information.startDate).utc().format("YYYY-MM-DD"),
                                    driver: tempDriverList,
                                    scheduleId: state.isEdit == 1 ? state.id : 0,
                                    recurringWeeks: state.information.recurringWeeks,
                                    originalWeeks: state.weekArr.length,
                                    driversEffectiveDate:state.driversEffectiveDate,
                                },
                                get: "routes",
                            },
                        },
                    },
                },
            };
            try {
                let conflictRouteResponse = await api.post("/api/lazy/manage/data", tempParam);
                if (conflictRouteResponse.data.data.routes.length > 0) {
                    let responseData = conflictRouteResponse.data.data.routes;
                    let messageString = "";
                    responseData.map((item) => {
                        messageString =
                            messageString +
                            '<tr><td style="border: 1px solid #e6d8d8;padding:5px;">' +
                            item.driverName +
                            ' </td> <td style="border: 1px solid #e6d8d8;padding:5px;">' +
                            item.schedule +
                            " to " +
                            state.information.name +
                            "</td></tr> ";
                    });

                    if (messageString != "") {
                        messageString =
                            'The following employee(s) already belong to an existing, recurring schedule. This request will permanently remove them from their current assigned schedule.</br></br></br><div style="max-height:200px;overflow-y:scroll;"><table  style="width:100%;border-collapse: collapse;margin-top: 13px;">' +
                            messageString +
                            "</table></div><br/>Would you like to continue?";
                        let confirmation = await confirm({
                            icon: false,
                            text: messageString,
                            closeOnOverlay: "false",
                        });
                        if (confirmation) {
                            let scheduleParam = {
                                actions: {
                                    response: {
                                        Schedule: {
                                            custom: {
                                                functionName: "removeDriversFromSchedules",
                                                criteria: {
                                                    data: responseData,
                                                    startDate: moment(state.information.startDate)
                                                        .utc()
                                                        .format("YYYY-MM-DD"),
                                                    driversEffectiveDate:state.driversEffectiveDate
                                                },
                                                get: "schedule",
                                            },
                                        },
                                    },
                                },
                            };
                            await api.post("/api/lazy/manage/data", scheduleParam);
                        } else {
                            setLoading(false);
                            return false;
                        }
                    }
                }
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
            }
        }
        /*---- end check recurring conflict routes-------*/
        if (state.information.endDate) {
            enddate = moment(state.information.endDate).format("YYYY-MM-DD");
        }
        let driversId = [];
        Object.keys(state.drivers).forEach(function (i) {
            driversId.push(state.drivers[i].id);
        });
        let shiftDesign = {};
        let shiftDesignConnects = {};
        let params = {};
        if (state.isEdit === 1) {
            let weekDynamicNumber = 0;
            let yearDynamicNumber = 0;
            let endDate;
            if (state.design) {
                let shiftCounts = 1;
                let startDateString = state.information.startDate;
                if (
                    moment().utc().format("YYYY-MM-DD") > state.information.startDate &&
                    state.originalScheduleDesignWeekAndYear !== undefined
                ) {
                    // startDateString = moment(state.originalScheduleDesignWeekAndYear.startYear).add(state.originalScheduleDesignWeekAndYear.startWeek, 'weeks').startOf('week').add(1, 'days').format('YYYY-MM-DD');
                    startDateString = moment(state.originalScheduleDesignWeekAndYear.startYear)
                        .week(state.originalScheduleDesignWeekAndYear.startWeek)
                        .startOf("week")
                        .add(1, "days")
                        .format("YYYY-MM-DD");
                }

                let startDateObj = new Date(startDateString);
                if (moment(startDateObj).day() === 0) {
                    startDateObj = moment(startDateObj).add(1, "days");
                } else {
                    startDateObj = moment(startDateObj);
                }

                Object.keys(state.design.weekArr).forEach(function (index) {
                    if (index < state.originalWeekCount) {
                        let keyDesign = "";

                        if (index == 0) {
                            weekDynamicNumber = startDateObj.isoWeek();
                            yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                            endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        } else {
                            weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                            yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                            endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        }

                        if (state.design.shiftTypes[index]) {
                            Object.keys(state.design.shiftTypes[index]).forEach(function (sindex) {
                                keyDesign = "scheduleDesign_" + shiftCounts;
                                shiftDesign[keyDesign] = {
                                    week: weekDynamicNumber,
                                    sunday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[0].Sun) == 1
                                            ? true
                                            : false,
                                    monday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[1].Mon) == 1
                                            ? true
                                            : false,
                                    tuesday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[2].Tue) == 1
                                            ? true
                                            : false,
                                    wednesday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[3].Wed) == 1
                                            ? true
                                            : false,
                                    thursday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[4].Thu) == 1
                                            ? true
                                            : false,
                                    friday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[5].Fri) == 1
                                            ? true
                                            : false,
                                    saturday:
                                        parseInt(state.design.shiftTypes[index][sindex].days[6].Sat) == 1
                                            ? true
                                            : false,
                                    ordering: sindex,
                                    shifts: [state.design.shiftTypes[index][sindex].id],
                                    schedule: state.id,
                                    year: yearDynamicNumber,
                                };
                                shiftCounts++;
                            });
                        } else {
                            keyDesign = "scheduleDesign_" + shiftCounts + 1;
                            shiftDesign[keyDesign] = {
                                week: weekDynamicNumber,
                                sunday: false,
                                monday: false,
                                tuesday: false,
                                wednesday: false,
                                thursday: false,
                                friday: false,
                                saturday: false,
                                ordering: 0,
                                schedule: state.id,
                                year: yearDynamicNumber,
                            };
                            shiftCounts++;
                        }
                    }
                });

                if (state.information.recurring === true) {
                    let weekIndex = 0;
                    for (var i = state.originalWeekCount; i < state.design.weekArr.length; i++) {
                        if (weekIndex > state.originalWeekCount - 1) {
                            weekIndex = 0;
                        }
                        weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                        yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                        endDate = startDateObj.day(6).format("YYYY-MM-DD");

                        if (state.design.shiftTypes[weekIndex]) {
                            Object.keys(state.design.shiftTypes[weekIndex]).forEach(function (sindex) {
                                let keyDesign = "scheduleDesign_" + shiftCounts;
                                shiftDesign[keyDesign] = {
                                    week: weekDynamicNumber,
                                    sunday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[0].Sun) == 1
                                            ? true
                                            : false,
                                    monday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[1].Mon) == 1
                                            ? true
                                            : false,
                                    tuesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[2].Tue) == 1
                                            ? true
                                            : false,
                                    wednesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[3].Wed) == 1
                                            ? true
                                            : false,
                                    thursday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[4].Thu) == 1
                                            ? true
                                            : false,
                                    friday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[5].Fri) == 1
                                            ? true
                                            : false,
                                    saturday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[6].Sat) == 1
                                            ? true
                                            : false,
                                    ordering: sindex,
                                    schedule: state.id,
                                    shifts: [state.design.shiftTypes[weekIndex][sindex].id],
                                    year: yearDynamicNumber,
                                };
                                shiftCounts++;
                            });
                        } else {
                            let keyDesign = "scheduleDesign_" + shiftCounts + 1;
                            shiftDesign[keyDesign] = {
                                week: weekDynamicNumber,
                                sunday: false,
                                monday: false,
                                tuesday: false,
                                wednesday: false,
                                thursday: false,
                                friday: false,
                                saturday: false,
                                ordering: 0,
                                schedule: state.id,
                                year: yearDynamicNumber,
                            };
                            shiftCounts++;
                        }
                        weekIndex++;
                    }
                }
            }
            let shiftObj = {};
            let keyDesign = "";
            Object.keys(state.shiftDesignId).forEach(function (item, index) {
                keyDesign = "ScheduleDesign_" + (parseInt(index) + 1);
                shiftObj[keyDesign] = {
                    findOneBy: {
                        id: state.shiftDesignId[index],
                    },
                    isHardDelete: true,
                };
            });

            let tempStartDateObj = new Date(state.information.startDate);
            if (moment(tempStartDateObj).day() === 0) {
                tempStartDateObj = moment(tempStartDateObj).add(1, "days");
            } else {
                tempStartDateObj = moment(tempStartDateObj);
            }
            if (state.information.recurring === true) {
                let weekCnt =
                    state.originalWeekCount > state.weekArr.length ? state.weekArr.length : state.originalWeekCount;
                let totalOrignalWeekInDb = dynamicWeek >= weekCnt ? dynamicWeek : weekCnt;
                totalOrignalWeekInDb = totalOrignalWeekInDb != 0 ? totalOrignalWeekInDb - 1 : totalOrignalWeekInDb;
                let shiftDesignWeekAndYearArray = [];
                Object.keys(shiftDesign).forEach(function (sd) {
                    shiftDesignWeekAndYearArray.push(shiftDesign[sd]);
                });
                shiftDesignWeekAndYearArray = shiftDesignWeekAndYearArray.reverse();

                let updateParam = {
                    name: state.information.name,
                    startDate: moment(state.information.startDate).utc().format("YYYY-MM-DD"),
                    endDate: endDate,
                    isRecurring: state.information.recurring,
                    drivers: driversId,
                    previousAssignSchedule: state.information.checkbox,
                    endWeek: weekDynamicNumber,
                    scheduleLastDate: endDate,
                    originalWeekCount: weekCnt,
                    effectiveDate: null,
                    weekEndYear: yearDynamicNumber,
                    dateUpdated: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                };
                if (state.startDate != moment(state.information.startDate).utc().format("YYYY-MM-DD")) {
                    updateParam.startWeek = tempStartDateObj.isoWeek();
                    updateParam.year = tempStartDateObj.isoWeekYear();
                } else {
                    if (
                        shiftDesignWeekAndYearArray[totalOrignalWeekInDb] !== undefined &&
                        state.originalScheduleDesignWeekAndYear !== undefined
                    ) {
                        let startDateOfStartWeekAndYear = moment(state.originalScheduleDesignWeekAndYear.startYear)
                            .add(state.originalScheduleDesignWeekAndYear.startWeek, "weeks")
                            .startOf("week")
                            .add(1, "days")
                            .format("YYYY-MM-DD");

                        let startDateOfEndWeekAndYear = moment(String(shiftDesignWeekAndYearArray[0].year))
                            .add(shiftDesignWeekAndYearArray[0].week, "weeks")
                            .startOf("isoweek")
                            .format("YYYY-MM-DD");

                        if (startDateOfStartWeekAndYear > startDateOfEndWeekAndYear) {
                            updateParam.startWeek = shiftDesignWeekAndYearArray[totalOrignalWeekInDb].week;
                            updateParam.year = shiftDesignWeekAndYearArray[totalOrignalWeekInDb].year;
                        }
                    }
                }
                updateParam.driversEffectiveDate = JSON.stringify(state.driversEffectiveDate)
                params = {
                    notification: notification,
                    actions: {
                        update: {
                            Schedule: {
                                schedule_1: {
                                    findBy: {
                                        id: state.id,
                                    },
                                    updateRecord: updateParam,
                                },
                            },
                        },
                        delete: {
                            ScheduleDesign: shiftObj,
                        },
                        create: {
                            ScheduleDesign: shiftDesign,
                        },
                    },
                };
            } else {
                params = {
                    notification: notification,
                    actions: {
                        update: {
                            Schedule: {
                                schedule_1: {
                                    findBy: {
                                        id: state.id,
                                    },
                                    updateRecord: {
                                        name: state.information.name,
                                        startDate: moment(state.information.startDate).format("YYYY-MM-DD"),
                                        endDate: enddate,
                                        isRecurring: state.information.recurring,
                                        drivers: driversId,
                                        previousAssignSchedule: state.information.checkbox,
                                        startWeek: tempStartDateObj.isoWeek(),
                                        endWeek: weekDynamicNumber,
                                        scheduleLastDate: endDate,
                                        year: tempStartDateObj.isoWeekYear(),
                                        originalWeekCount: state.originalWeekCount,
                                        effectiveDate: state.effectiveDate,
                                        weekEndYear: yearDynamicNumber,
                                        dateUpdated: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                        driversEffectiveDate:JSON.stringify(state.driversEffectiveDate)
                                    },
                                },
                            },
                        },
                        delete: {
                            ScheduleDesign: shiftObj,
                        },
                        create: {
                            ScheduleDesign: shiftDesign,
                        },
                    },
                };
            }
        } else if (state.isCopy === 1) {
            let startWeekNumber = state.weekArr[0];
            let endWeekNumber = state.weekArr[state.originalWeekCount - 1];
            let weekNumber = 0;

            let startDateObj = new Date(state.information.startDate);
            if (moment(startDateObj).day() === 0) {
                startDateObj = moment(startDateObj).add(1, "days");
            } else {
                startDateObj = moment(startDateObj);
            }
            let weekDynamicNumber = 0;
            let yearDynamicNumber = 0;
            let endDate;

            if (state.design) {
                let shiftCounts = 0;
                Object.keys(state.design.weekArr).forEach(function (index) {
                    let keyDesign = "";

                    if (index < state.originalWeekCount) {
                        if (index == 0) {
                            weekDynamicNumber = startDateObj.isoWeek();
                            yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                            endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        } else {
                            weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                            yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                            endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        }

                        if (shiftCounts <= state.originalWeekCount) {
                            if (state.design.shiftTypes[index]) {
                                Object.keys(state.design.shiftTypes[index]).forEach(function (sindex) {
                                    keyDesign = "scheduleDesign_" + shiftCounts;
                                    shiftDesign[keyDesign] = {
                                        week: weekDynamicNumber,
                                        sunday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[0].Sun) == 1
                                                ? true
                                                : false,
                                        monday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[1].Mon) == 1
                                                ? true
                                                : false,
                                        tuesday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[2].Tue) == 1
                                                ? true
                                                : false,
                                        wednesday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[3].Wed) == 1
                                                ? true
                                                : false,
                                        thursday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[4].Thu) == 1
                                                ? true
                                                : false,
                                        friday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[5].Fri) == 1
                                                ? true
                                                : false,
                                        saturday:
                                            parseInt(state.design.shiftTypes[index][sindex].days[6].Sat) == 1
                                                ? true
                                                : false,
                                        ordering: sindex,
                                        shifts: [state.design.shiftTypes[index][sindex].id],
                                        year: yearDynamicNumber,
                                    };
                                    shiftDesignConnects[keyDesign] = {
                                        schedule: ["schedule_1"],
                                    };
                                    shiftCounts++;
                                });
                            } else {
                                keyDesign = "scheduleDesign_" + shiftCounts + 1;
                                shiftDesign[keyDesign] = {
                                    week: weekDynamicNumber,
                                    sunday: false,
                                    monday: false,
                                    tuesday: false,
                                    wednesday: false,
                                    thursday: false,
                                    friday: false,
                                    saturday: false,
                                    ordering: 0,
                                    year: yearDynamicNumber,
                                };
                                shiftDesignConnects[keyDesign] = {
                                    schedule: ["schedule_1"],
                                };
                                shiftCounts++;
                            }
                        }
                    }
                });
                let weekIndex = 0;
                let weekno = (weekNumber = state.weekArr[weekIndex] + state.originalWeekCount);
                if (state.information.recurring === true) {
                    let len = state.originalWeekCount - 1;
                    for (var i = state.originalWeekCount; i < dynamicWeek; i++) {
                        if (weekIndex > len) {
                            weekIndex = 0;
                        }

                        weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                        yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                        endDate = startDateObj.day(6).format("YYYY-MM-DD");

                        if (state.design.shiftTypes[weekIndex]) {
                            Object.keys(state.design.shiftTypes[weekIndex]).forEach(function (sindex) {
                                let keyDesign = "scheduleDesign_" + shiftCounts;
                                shiftDesign[keyDesign] = {
                                    week: weekDynamicNumber,
                                    sunday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[0].Sun) == 1
                                            ? true
                                            : false,
                                    monday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[1].Mon) == 1
                                            ? true
                                            : false,
                                    tuesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[2].Tue) == 1
                                            ? true
                                            : false,
                                    wednesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[3].Wed) == 1
                                            ? true
                                            : false,
                                    thursday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[4].Thu) == 1
                                            ? true
                                            : false,
                                    friday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[5].Fri) == 1
                                            ? true
                                            : false,
                                    saturday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[6].Sat) == 1
                                            ? true
                                            : false,
                                    ordering: sindex,
                                    shifts: [state.design.shiftTypes[weekIndex][sindex].id],
                                    year: yearDynamicNumber,
                                };
                                shiftDesignConnects[keyDesign] = {
                                    schedule: ["schedule_1"],
                                };
                                shiftCounts++;
                            });
                        } else {
                            let keyDesign = "scheduleDesign_" + shiftCounts + 1;
                            shiftDesign[keyDesign] = {
                                week: weekDynamicNumber,
                                sunday: false,
                                monday: false,
                                tuesday: false,
                                wednesday: false,
                                thursday: false,
                                friday: false,
                                saturday: false,
                                ordering: 0,
                                year: yearDynamicNumber,
                            };
                            shiftDesignConnects[keyDesign] = {
                                schedule: ["schedule_1"],
                            };
                            shiftCounts++;
                        }
                        weekIndex++;
                        weekno++;
                    }
                    weekNumber = weekno;
                }
            }

            let tempStartDateObj = new Date(state.information.startDate);
            if (moment(tempStartDateObj).day() === 0) {
                tempStartDateObj = moment(tempStartDateObj).add(1, "days");
            } else {
                tempStartDateObj = moment(tempStartDateObj);
            }
            if (state.information.recurring === true) {
                params = {
                    actions: {
                        create: {
                            Schedule: {
                                schedule_1: {
                                    company: localStorage.getItem("company"),
                                    station: station.id,
                                    name: state.information.name,
                                    startDate: moment(state.information.startDate).format("YYYY-MM-DD"),
                                    endDate: endDate,
                                    isRecurring: state.information.recurring,
                                    previousAssignSchedule: state.information.checkbox,
                                    drivers: driversId,
                                    startWeek: tempStartDateObj.isoWeek(),
                                    endWeek: weekDynamicNumber,
                                    scheduleLastDate: endDate,
                                    year: tempStartDateObj.isoWeekYear(),
                                    weekEndYear: yearDynamicNumber,
                                    originalWeekCount: state.originalWeekCount,
                                    effectiveDate: state.effectiveDate,
                                },
                            },
                            ScheduleDesign: shiftDesign,
                        },
                        connect: {
                            ScheduleDesign: shiftDesignConnects,
                        },
                    },
                };
            } else {
                params = {
                    actions: {
                        create: {
                            Schedule: {
                                schedule_1: {
                                    company: localStorage.getItem("company"),
                                    station: station.id,
                                    name: state.information.name,
                                    startDate: moment(state.information.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(state.information.endDate).format("YYYY-MM-DD"),
                                    isRecurring: state.information.recurring,
                                    previousAssignSchedule: state.information.checkbox,
                                    drivers: driversId,
                                    startWeek: tempStartDateObj.isoWeek(),
                                    endWeek: weekDynamicNumber,
                                    scheduleLastDate: moment(state.information.endDate).format("YYYY-MM-DD"),
                                    year: scheduleYear,
                                    weekEndYear: yearDynamicNumber,
                                    originalWeekCount: state.originalWeekCount,
                                    effectiveDate: state.effectiveDate,
                                },
                            },
                            ScheduleDesign: shiftDesign,
                        },
                        connect: {
                            ScheduleDesign: shiftDesignConnects,
                        },
                    },
                };
            }
        } else {
            let startWeekNumber = state.weekArr[0];
            //let endWeekNumber = state.weekArr[state.weekArr.length-1];
            let weekNumber = 0;

            let startDateObj = new Date(state.information.startDate);
            if (moment(startDateObj).day() === 0) {
                startDateObj = moment(startDateObj).add(1, "days");
            } else {
                startDateObj = moment(startDateObj);
            }
            let weekDynamicNumber = 0;
            let yearDynamicNumber = 0;
            let originalWeek = 1;
            let endDate;
            if (state.design) {
                let shiftCounts = 1;
                Object.keys(state.design.weekArr).forEach(function (index) {
                    let keyDesign = "";
                    if (index == 0) {
                        weekDynamicNumber = startDateObj.isoWeek();
                        yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                        endDate = startDateObj.day(6).format("YYYY-MM-DD");
                    } else {
                        weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                        yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                        endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        originalWeek++;
                    }
                    if (state.design.shiftTypes[index]) {
                        Object.keys(state.design.shiftTypes[index]).forEach(function (sindex) {
                            keyDesign = "scheduleDesign_" + shiftCounts;
                            shiftDesign[keyDesign] = {
                                week: parseInt(weekDynamicNumber),
                                sunday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[0].Sun) == 1 ? true : false,
                                monday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[1].Mon) == 1 ? true : false,
                                tuesday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[2].Tue) == 1 ? true : false,
                                wednesday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[3].Wed) == 1 ? true : false,
                                thursday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[4].Thu) == 1 ? true : false,
                                friday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[5].Fri) == 1 ? true : false,
                                saturday:
                                    parseInt(state.design.shiftTypes[index][sindex].days[6].Sat) == 1 ? true : false,
                                ordering: sindex,
                                shifts: [state.design.shiftTypes[index][sindex].id],
                                year: yearDynamicNumber,
                            };
                            shiftDesignConnects[keyDesign] = {
                                schedule: ["schedule_1"],
                            };
                            shiftCounts++;
                        });
                    } else {
                        keyDesign = "scheduleDesign_" + shiftCounts + 1;
                        shiftDesign[keyDesign] = {
                            week: weekDynamicNumber,
                            sunday: false,
                            monday: false,
                            tuesday: false,
                            wednesday: false,
                            thursday: false,
                            friday: false,
                            saturday: false,
                            ordering: 0,
                            year:
                                scheduleYear >
                                parseInt(
                                    moment()
                                        .day(0)
                                        .isoWeek(state.weekArr[state.design.weekArr[index] - 1])
                                        .utc()
                                        .isoWeekYear()
                                )
                                    ? scheduleYear
                                    : parseInt(
                                          moment()
                                              .day(0)
                                              .week(state.weekArr[state.design.weekArr[index] - 1])
                                              .utc()
                                              .isoWeekYear()
                                      ),
                        };
                        shiftDesignConnects[keyDesign] = {
                            schedule: ["schedule_1"],
                        };
                        shiftCounts++;
                    }
                });
                let weekIndex = 0;
                let weekno = (weekNumber = state.weekArr[weekIndex] + state.weekArr.length);
                if (state.information.recurring === true) {
                    let len = state.weekArr.length - 1;
                    for (var i = state.weekArr.length; i < dynamicWeek; i++) {
                        if (weekIndex > len) {
                            weekIndex = 0;
                        }

                        weekDynamicNumber = startDateObj.add(1, "weeks").isoWeek();
                        yearDynamicNumber = (weekDynamicNumber == 52 || weekDynamicNumber == 53 )?parseInt(startDateObj.day(0).format("YYYY")):((weekDynamicNumber == 1)?parseInt(startDateObj.day(6).format("YYYY")):parseInt(startDateObj.day(1).format("YYYY")));
                        endDate = startDateObj.day(6).format("YYYY-MM-DD");
                        if (state.design.shiftTypes[weekIndex]) {
                            Object.keys(state.design.shiftTypes[weekIndex]).forEach(function (sindex) {
                                let keyDesign = "scheduleDesign_" + shiftCounts;
                                shiftDesign[keyDesign] = {
                                    week: weekDynamicNumber,
                                    sunday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[0].Sun) == 1
                                            ? true
                                            : false,
                                    monday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[1].Mon) == 1
                                            ? true
                                            : false,
                                    tuesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[2].Tue) == 1
                                            ? true
                                            : false,
                                    wednesday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[3].Wed) == 1
                                            ? true
                                            : false,
                                    thursday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[4].Thu) == 1
                                            ? true
                                            : false,
                                    friday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[5].Fri) == 1
                                            ? true
                                            : false,
                                    saturday:
                                        parseInt(state.design.shiftTypes[weekIndex][sindex].days[6].Sat) == 1
                                            ? true
                                            : false,
                                    ordering: sindex,
                                    shifts: [state.design.shiftTypes[weekIndex][sindex].id],
                                    year: yearDynamicNumber,
                                };
                                shiftDesignConnects[keyDesign] = {
                                    schedule: ["schedule_1"],
                                };
                                shiftCounts++;
                            });
                        } else {
                            let keyDesign = "scheduleDesign_" + shiftCounts + 1;
                            shiftDesign[keyDesign] = {
                                week: weekDynamicNumber,
                                sunday: false,
                                monday: false,
                                tuesday: false,
                                wednesday: false,
                                thursday: false,
                                friday: false,
                                saturday: false,
                                ordering: 0,
                                year: yearDynamicNumber,
                            };
                            shiftDesignConnects[keyDesign] = {
                                schedule: ["schedule_1"],
                            };
                            shiftCounts++;
                        }
                        weekIndex++;
                        weekno++;
                    }
                }
            }

            let tempStartDateObj = new Date(state.information.startDate);
            if (moment(tempStartDateObj).day() === 0) {
                tempStartDateObj = moment(tempStartDateObj).add(1, "days");
            } else {
                tempStartDateObj = moment(tempStartDateObj);
            }

            if (state.information.recurring === true) {
                params = {
                    actions: {
                        create: {
                            Schedule: {
                                schedule_1: {
                                    company: localStorage.getItem("company"),
                                    station: station.id,
                                    name: state.information.name,
                                    startDate: moment(state.information.startDate).format("YYYY-MM-DD"),
                                    endDate: endDate,
                                    isRecurring: state.information.recurring,
                                    previousAssignSchedule: state.information.checkbox,
                                    drivers: driversId,
                                    startWeek: tempStartDateObj.isoWeek(),
                                    endWeek: weekDynamicNumber,
                                    scheduleLastDate: endDate,
                                    year: tempStartDateObj.isoWeekYear(),
                                    weekEndYear: yearDynamicNumber,
                                    originalWeekCount: originalWeek,
                                    effectiveDate: state.effectiveDate,
                                    driversEffectiveDate:JSON.stringify(state.driversEffectiveDate)
                                },
                            },
                            ScheduleDesign: shiftDesign,
                        },
                        connect: {
                            ScheduleDesign: shiftDesignConnects,
                        },
                    },
                };
            } else {
                params = {
                    actions: {
                        create: {
                            Schedule: {
                                schedule_1: {
                                    company: localStorage.getItem("company"),
                                    station: station.id,
                                    name: state.information.name,
                                    startDate: moment(state.information.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(state.information.endDate).format("YYYY-MM-DD"),
                                    isRecurring: state.information.recurring,
                                    previousAssignSchedule: state.information.checkbox,
                                    drivers: driversId,
                                    startWeek: tempStartDateObj.isoWeek(),
                                    endWeek: weekDynamicNumber,
                                    scheduleLastDate: moment(state.information.endDate).format("YYYY-MM-DD"),
                                    year: tempStartDateObj.isoWeekYear(),
                                    weekEndYear: moment(state.information.endDate).format("YYYY"),
                                    originalWeekCount: state.weekArr.length,
                                    effectiveDate: state.effectiveDate,
                                    driversEffectiveDate:JSON.stringify(state.driversEffectiveDate)
                                },
                            },
                            ScheduleDesign: shiftDesign,
                        },
                        connect: {
                            ScheduleDesign: shiftDesignConnects,
                        },
                    },
                };
            }
        }

        try {
            let queryResponse = await api.post("/api/lazy/manage/data", params);

            //Schedule create time send mail request param
            let mailParam = {
                actions: {
                    response: {
                        Schedule: {
                            custom: {
                                functionName: "sendMailScheduleCreate",
                                get: "schedule",
                                excludes: [
                                    "company",
                                    "scheduleDesigns",
                                    "shiftTemplates",
                                    "drivers",
                                    "station",
                                    "driverRoutes",
                                    "tempDriverRoutes",
                                    "skills",
                                ],
                                criteria: {
                                    schedule: params,
                                    isEdit: state.isEdit,
                                    enviorment: process.env.REACT_APP_BASE_URL,
                                    sheduleId: state.isEdit == 1 ? state.id : queryResponse.data.data.scheduleId,
                                },
                            },
                        },
                    },
                },
            };
            await api.post("/api/lazy/manage/data", mailParam);

            try {
                if (data.notification) {
                    let params = data;
                    params["drivers"] = state.drivers;
                    params["companyId"] = getCompanyId();
                    let response = await api.post("api/send_sms_schedule", params).catch(async (e) => {
                        console.error({ notification: e });
                        await toast({
                            type: "Error",
                            title: "Notifications failed!",
                        });
                    });
                    let result = response.data;
                    if (result) {
                        await toast({
                            type: "success",
                            title: "Notifications successfully sent!",
                        });
                    }
                    if (Array.isArray(result.errors) && result.errors.length > 0) {
                        await toast({
                            type: "Error",
                            title: "Some notifications failed.",
                        });
                    }
                }
            } catch (e) {
                console.error({ notification: e });
                await alert("Notifications error.");
            }

            toast({
                type: "success",
                title: "Schedule successfully created! Please allow up to 15 minutes for the entire schedule to post and be visible in the Scheduler.",
                useClose: true,
                timeout: false,
            });

            // let updateDriverRoute={
            //     "actions": {
            //         "response": {
            //             "DriverRoute": {
            //                 "custom": {
            //                     "functionName": "updateDriverRoutes",
            //                     "criteria": {
            //                         "id":queryResponse.data.data.responseId
            //                     },
            //                     "get":"schedules"
            //                 }
            //             }
            //         }
            //     }
            // };
            // await api.post('/api/lazy/manage/data', updateDriverRoute);

            if (state.view == "List") {
                document.querySelectorAll("#list-view")[0].click();
            } else {
                document.querySelectorAll("#grid-view")[0].click();
            }
        } catch (error) {
            console.error({ error });
            // if (error.response.status == '401') {
            //     window.location = "/logout";
            // }
        }
    };
    const goBack = async () => {
        document.querySelectorAll("[data-tab='tab']")[2].click();
    };
    const cancelBtn = async () => {
        document.getElementById("cancelBtn").click();
    };

    const checkboxCheckEvent = () => {
        let checkboxObj = document.querySelector("[name='sendNotifications']");
        if (checkboxObj.checked) {
            setSendNotification(true);
        } else {
            setSendNotification(false);
        }
    };
    const checkboxCheckEventSms = () => {
        let checkboxObjSms = document.querySelector("[name='sendSMSNotifications']");
        if (checkboxObjSms.checked) {
            setSendNotification(true);
        } else {
            setSendNotification(false);
        }
    };

    useEffect(() => {
        if (!state.information.name) {
            document.querySelectorAll("[data-tab='tab']")[0].click();
        }

        if (state.information.recurring === false) {
            schedulesAddDispatchContext.dispatch({ type: "setConflictStatus", status: false });
            getConflictRoutes();
        } /*else {
            getRecurringConflictRoutes();
        }*/
    }, []);
    return (
        <>
            {state.isRouteConflictModalOpen && <ConflictRoutes></ConflictRoutes>}
            <form onSubmit={handleSubmit(formSubmit)}>
                <Grid>
                    <Information></Information>
                    <Grid padding="20px 0 8px 0" color="#516F90" fontSize="22px" fontWeight="bold">
                        Design
                    </Grid>
                    <Design></Design>
                    <Drivers></Drivers>

                    <CheckBox
                        name="sendNotifications"
                        title={""}
                        ref={register({ required: false })}
                        onClick={() => {
                            checkboxCheckEvent();
                        }}
                        options={[{ value: true, label: "Notification send to Drivers" }]}
                    />
                    <CheckBox
                        name="sendSMSNotifications"
                        title={""}
                        ref={register({ required: false })}
                        onClick={() => {
                            checkboxCheckEventSms();
                        }}
                        options={[{ value: true, label: "SMS Notification send to Drivers" }]}
                    />

                    {sendNotification && (
                        <Spacer right={5} top={3} inline>
                            <Input
                                name="notification"
                                label="Message"
                                size="big"
                                ref={register({ required: true })}
                                style={{ height: 76 }}
                                as="textarea"
                            />
                        </Spacer>
                    )}

                    {loading && <Loading style={{ height: "100px", margin: "0 auto", padding: 20 }} />}

                    <Spacer top={3} right={6}>
                        <IsGranted
                            ops={[AccessCheck.OPS.C, AccessCheck.OPS.U]}
                            feature={AccessCheck.features.ST_SCHEDULES}
                        >
                            {state.conflictStatus ? (
                                loading ? (
                                    <Button type="primary" disabled>
                                        Confirm and Post Schedule
                                    </Button>
                                ) : (
                                    <Button type="primary">Confirm and Post Schedule</Button>
                                )
                            ) : (
                                <>
                                    <Spacer inline right={3} />
                                    <Alert.Error
                                        title="There are some shift conflict, please go back and select other driver or change date"
                                        content=""
                                    />
                                    <Spacer bottom={2} />
                                </>
                            )}
                        </IsGranted>

                        <Spacer inline right={3} top={3} />
                        {loading ? (
                            <Button
                                type={"button"}
                                onClick={() => {
                                    goBack();
                                }}
                                disabled
                            >
                                Back
                            </Button>
                        ) : (
                            <Button
                                type={"button"}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                Back
                            </Button>
                        )}
                        <Spacer inline right={3} top={3} />
                        {loading ? (
                            <Button
                                type={"cancel"}
                                onClick={() => {
                                    cancelBtn();
                                }}
                                disabled
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                type={"cancel"}
                                onClick={() => {
                                    cancelBtn();
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                    </Spacer>
                </Grid>
            </form>
        </>
    );
};

export default React.memo(ConfirmationPanel);
