import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Input, Password, Spacer, SubTitle, TagInput, Text, Theme, ThirdTitle, Toggle} from "@dspworkplace/ui";
import SettingsContext from "../context";
import {toast} from "../../../components/Toasts";
import {Label} from "../../../components/UI";
import apiFactory from "../../../api/factory";
import Loading, {LoadingWrapper} from "../../../components/Loading";

export default function RouteAssistSettings()
{
    const {call, cancel} = apiFactory();
    const [state, dispatch] = useContext(SettingsContext);
    const [options, setOptions] = useState({});
    const {register, handleSubmit, reset} = useForm();

    const fetchRoles = () =>
        call({
            actions: {
                response: {
                    Role: {
                        findBy: {
                            get: "roles",
                            criteria: {
                                "company": state.company.id
                            },
                            includes: [
                                "id",
                                "roleName",
                                "name"
                            ],
                            orderBy: {
                                'name': 'ASC'
                            }
                        }
                    },
                }
            }
        }).then(response =>
            setOptions(opt => ({
                ...opt,
                roles: response.data.data.roles
            }))
        )

    const roles = (() => options?.roles
        ? options.roles
            .map(r => ({name: r.roleName, value: r.name}))
            .filter(r => r.value !== 'ROLE_ALLOWED_TO_SWITCH')
            .sort((a, b) => a.name.localeCompare(b.name))
        : []
    )()

    const handleSaveCortex = ( data, evt ) => {
        let form = evt.target;

        form.querySelector('button').disabled = true;

        data.routeCodeAssignmentOptions = JSON.stringify(data.routeCodeAssignmentOptions);

        let params = { actions: {} };

        if ( data.id > 0 )
            params.actions = {
                update: {
                    RouteAssistSettings: {
                        update_settings_1: {
                            findBy: {
                                company: state.company.id
                            },
                            updateRecord: data
                        }
                    }
                }
            };
        else {
            delete data.id;

            params.actions = {
                create: {
                    RouteAssistSettings: {
                        create: {
                            company: state.company.id,
                            ...data
                        }
                    }
                },
            };
        }

        call(params).then(
            response => {
                if(response?.data?.created?.create.entityId > 0)
                    data.id = response.data.created.create.entityId;

                toast({
                    title: <>RouteAssist™ information updated</>,
                    type: 'info',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                });

                dispatch({
                    type: 'ADD',
                    payload: {
                        ...state,
                        routeAssistSettings: {
                            ...state.routeAssistSettings,
                            ...data
                        }
                    }
                })
            },
            error => toast({
                title: 'Error',
                content: error.response ? error.response.data.message : error.message,
                type: 'error',
                timeout: 8000,
                useIcon: true,
                useClose: true
            })
        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    useEffect(() => {
        if (state.company.id)
            fetchRoles().then(() => reset(state.routeAssistSettings?.[0]));

        return cancel;
    }, [state.company.id]);

    return <section>
        {!roles.length ? <LoadingWrapper><Loading/></LoadingWrapper> : null}
        <SubTitle dangerouslySetInnerHTML={{__html: 'RouteAssist&TRADE;'}}/>
        <Spacer bottom={3}/>
        <Text dangerouslySetInnerHTML={{__html: `
            In order to successfully run RouteAssist&TRADE;, your 'DSP ID' must be entered correctly.
            <br/>
            You can find it by looking in Cortex and by clicking Overview under the 
            <a 
                target='_blank' 
                href='https://logistics.amazon.com/metrics/organization_summary_view?navMenuVariant=external#'
                style="color: ${Theme.colors.secondary}; text-decoration: underline;"
            >Performance</a>
            menu item. 
            <br/>
            If you look at the URL once the page loads, in it you will see: "&companyId=<b>[YOUR DSP ID]</b>&". 
            <br/>
            Copy that string of characters and paste it into the field below.
        `}}/>
        <Spacer bottom={3}/>
        <Text>The best practice is to have an exclusive account for dspworkplace.</Text>
        <Spacer bottom={5}/>
        <form onSubmit={handleSubmit(handleSaveCortex)}>
            <ThirdTitle>Credentials</ThirdTitle>
            <Spacer bottom={3}/>
            <input type={'hidden'} name='id' ref={register} />
            <Input
                ref={register}
                name='cortexDspId'
                label='DSP ID'
            />
            <Spacer bottom={5}/>

            <ThirdTitle>Options</ThirdTitle>
            <Spacer bottom={3}/>
            <TagInput
                ref={register}
                name='routeCodeAssignmentOptions.excludeRoles'
                label='Do not assign routes to'
                options={roles}
            />
            <Spacer bottom={3}/>

            <Label>Guess Shift Types</Label>
            <Spacer bottom={1}/>
            <Toggle
                ref={register}
                name='routeCodeAssignmentOptions.guessShiftTypes'
                singleText='Automatically create training or rescues routes'
                help={<Spacer left={11}>If the service type is Nursery, or when a second driver were added to the route</Spacer>}
            />

            <Spacer bottom={5}/>
            <Button type='primary'>Save</Button>
        </form>
    </section>
}