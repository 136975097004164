import {engine, getCompanyId, getDriverId, getFriendlyName, getUserId, showToast} from "../../../Utilities";
import {Dispatch} from "react";
import {ActionLoadOutRTSTemplate, AiImageCategory} from "./store";
import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";

type DataFetch = {
    id: number;
    template: Array<any>;
    updatedAt: string | null;
    updatedBy: { friendlyName: string; };
    introduction: string;
    scenario: string;
    language: string;
    vehicleImageCategories: Array<VehicleImageCategory>;
    aiImageCategories: Array<AiImageCategory>;
}

const EmptyData: DataFetch = {
    id: 0,
    introduction: "",
    template: [],
    updatedAt: null,
    updatedBy: {friendlyName: ""},
    language: "en",
    scenario: "",
    vehicleImageCategories: [],
    aiImageCategories: [],
};

export async function checkIfCategoriesAreEmpty(categories: any) {
    if (!categories) {
        await showToast({
            type: 'Error',
            title: 'The categories for vehicle images are missing.',
            content: 'Please set up Vehicle Image Categories.'
        });
        return true;
    }
    if (Array.isArray(categories) && categories.length === 0) {
        await showToast({
            type: 'Error',
            title: 'The categories for vehicle images are missing.',
            content: 'Please set up Vehicle Image Categories.'
        });
        return true;
    }
    return false;
}

function checkIfNewTemplate(template: any) {
    if (!template) {
        return true;
    }
    return Array.isArray(template) && template.length === 0;
}

export async function fetchTemplate(stationId: number, dispatch: Dispatch<ActionLoadOutRTSTemplate>, scenario: string, language: string) {
    try {
        dispatch({type: 'SET_LOADING', loading: true});
        let params = {
            userId: getUserId(),
            driverId: getDriverId(),
            stationId,
            companyId: getCompanyId(),
            friendlyName: getFriendlyName(),
            scenario,
            type: 'SET_DATA',
            language
        };
        let response = await engine().post('/api/load_out_rts_template', params);
        let data: DataFetch = response.data;
        let {template, introduction, updatedBy, updatedAt} = data;

        await template.map((temp) => { 
            if (temp?.items != undefined) {
                temp?.items.forEach(obj => {
                    if(obj.content?.aiImageCategories != undefined){
                        return obj.content?.aiImageCategories.sort((a, b) => a.sortOrder - b.sortOrder)
                    }
                });
            }
        });

        if (checkIfNewTemplate(template) && scenario === 'LOAD_OUT') {
            let res = await createTemplate(stationId, scenario, 'en');
            dispatch({
                type: 'SET_DATA',
                id: res.id,
                template: res.template,
                stationId: stationId,
                scenario: scenario,
                introduction: res.introduction,
                updatedBy: res.updatedBy,
                updatedAt: res.updatedAt,
                language: res.language,
                vehicleImageCategories: res.vehicleImageCategories,
                aiImageCategories: res.aiImageCategories.map((ic) => ({
                    id: ic?.id,
                    name: ic?.name,
                    isArchive: ic?.isArchive,
                    checked: false,
                    frequency: ic?.frequency,
                    date: '',
                    sortOrder: ic?.sortOrder
                })),
            });
            await checkIfCategoriesAreEmpty(res.vehicleImageCategories);
            return res.template;
        }
        if (checkIfNewTemplate(template) && scenario === 'RTS') {
            let res = await createTemplate(stationId, scenario, 'en');
            dispatch({
                type: 'SET_DATA',
                id: res.id,
                template: res.template,
                stationId: stationId,
                scenario: scenario,
                introduction: res.introduction,
                updatedBy: res.updatedBy,
                updatedAt: res.updatedAt,
                language: res.language,
                vehicleImageCategories: res.vehicleImageCategories,
                aiImageCategories: res.aiImageCategories.map((ic) => ({
                    id: ic?.id,
                    name: ic?.name,
                    isArchive: ic?.isArchive,
                    checked: false,
                    frequency: ic?.frequency,
                    date: '',
                    sortOrder: ic?.sortOrder
                })),
            });
            await checkIfCategoriesAreEmpty(res.vehicleImageCategories);
            return res.template;
        }
        dispatch({
            type: 'SET_DATA',
            id: data.id,
            template: template,
            stationId: stationId,
            scenario: scenario,
            introduction: introduction,
            updatedBy: updatedBy,
            updatedAt: updatedAt,
            language: data.language,
            vehicleImageCategories: data.vehicleImageCategories,
            aiImageCategories: data?.aiImageCategories?.map((ic) => ({
                id: ic?.id,
                name: ic?.name,
                isArchive: ic?.isArchive,
                checked: ic?.isEnable,
                frequency: ic?.frequency,
                date: '',
                sortOrder: ic?.sortOrder
            })),
        });
        await checkIfCategoriesAreEmpty(data.vehicleImageCategories);
        return template;
    } catch (e) {
        console.log({e});
        if (e.response && e.response.data && typeof e.response.data.message === "string") {
            await showToast({
                type: 'Error',
                title: 'Error in finding Template',
                content: e.response.data.message,
            });
        } else {
            await showToast({
                type: 'Error',
                title: 'Error in finding Template',
            });
        }

        return [];
    }
}

export async function createTemplate(stationId: number, scenario: string, language: string): Promise<DataFetch> {
    try {
        let params = {
            type: 'NEW_DATA',
            userId: getUserId(),
            driverId: getDriverId(),
            stationId,
            companyId: getCompanyId(),
            friendlyName: getFriendlyName(),
            scenario,
            language,
        };
        let response = await engine().post('/api/load_out_rts_template', params);
        return response.data;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in creating Template',
            content: JSON.stringify(e),
        });
        return EmptyData;
    }
}

function handlerTemplate(template: any[]) {
    let verifiedTemplate: any[] = [];
    for (let i = 0; i < template.length; i++) {
        let section = template[i];
        if (Array.isArray(section.items) && section.items.length > 0) {
            verifiedTemplate.push(section);
        }
    }
    return verifiedTemplate;
}

export async function updateTemplate(stationId: number, template: any, scenario: string, id: number, action?: string): Promise<DataFetch> {
    try {
        let params = {
            id: id,
            userId: getUserId(),
            driverId: getDriverId(),
            stationId,
            companyId: getCompanyId(),
            friendlyName: getFriendlyName(),
            template: handlerTemplate(template),
            scenario,
            type: 'SET_TEMPLATE',
            action,
        };
        if (action === 'DELETE_ITEM_SECTION' || action === "UPDATE_SECTION_NAME") {
            for (let i = 0; i < template.length; i++) {
                let t = template[i];
                console.log({t});
                if (!(Array.isArray(t.items) && t.items.length > 0)) {
                    await showToast({
                        type: 'warning',
                        title: `The section "${t.name}" cannot be empty. Please add items.`,
                    });
                }
            }
            let response = await engine().post('/api/load_out_rts_template', params);
            return response.data;
        }
        for (let i = 0; i < template.length; i++) {
            let t = template[i];
            if (!(Array.isArray(t.items) && t.items.length > 0)) {
                await showToast({
                    type: 'Error',
                    title: `The section "${t.name}" cannot be empty. Please add items.`,
                });
            }
        }
        let response = await engine().post('/api/load_out_rts_template', params);
       
        await response.data.template.map((temp) => { 
            if (temp?.items != undefined) {
                temp?.items.forEach(obj => {
                    if(obj.content?.aiImageCategories != undefined){
                        console.log(obj.content?.aiImageCategories);
                        return obj.content?.aiImageCategories.sort((a, b) => a.sortOrder - b.sortOrder)
                    }
                });
            }
        });
        
        return response.data;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in updating Template',
            content: JSON.stringify(e),
        });
        return EmptyData;
    }
}

export async function updateIntroduction(stationId: number, introduction: string, scenario: string, id: number, dispatch?: Dispatch<ActionLoadOutRTSTemplate>): Promise<DataFetch> {
    try {
        let params = {
            id: id,
            userId: getUserId(),
            driverId: getDriverId(),
            stationId: stationId,
            companyId: getCompanyId(),
            friendlyName: getFriendlyName(),
            introduction: introduction,
            scenario: scenario,
            type: 'SET_INTRODUCTION',
        };
        if (dispatch) {

        }
        let response = await engine().post('/api/load_out_rts_template', params);
        return response.data;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in update of Introduction',
            content: JSON.stringify(e),
        });
        return EmptyData;
    }
}