import React from "react";
import { Grid } from "../../index";

const SkillCell = ({ skill, padding }) => {
    return (
        <Grid templateColumns="repeat(auto-fill, minmax(50px, auto))" rowGap="4px" columnGap="4px" padding={padding}>
            <Grid
                key={skill}
                padding="0 4px 0 4px"
                justifyContent="center"
                borderRadius="2px"
                fontSize="12px"
                color="#516F90"
                backgroundColor="#EAF0F6"
                borderRight="1px solid #7C98B6"
                borderTop="1px solid #7C98B6"
                borderLeft="1px solid #7C98B6"
                borderBottom="1px solid #7C98B6"
                height="auto"
            >
                {skill}
            </Grid>
        </Grid>
    );
};

export default React.memo(SkillCell);
