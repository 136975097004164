import React, { useState, forwardRef, useRef, useEffect } from "react";
import styled from "styled-components";
import { Theme, Input, Icon } from "@dspworkplace/ui";
import moment from "moment";

const Frag = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const OptionGroup = styled.div`
    color: #707070;
    font-size: 12px;
    padding: 8px;
    font-weight: bold;
    cursor: default;
    white-space: nowrap;
`;

const Options = styled.ul`
    padding: 0;
    margin: 4px 0 0 0;
    background: white;
    border: 1px solid ${Theme.colors.neutrals.silver};
    box-shadow: 0 0 6px ${Theme.colors.neutrals.silver};
    border-radius: 2px;
    list-style: none;
    width: auto;
    display: inline-block;
    position: absolute;
    top: 107%;
    left: -1px;
    z-index: 10;

    ${(props) =>
        props.isChild &&
        `
    position:absolute;
    left:100%;
    margin-left:6px;
    top:-4px;

    &::before {
        content: " ";
        height: 100%;
        width: 6px;
        background: transparent;
        display: block;
        position: absolute;
        left: -7px;
    }
  `}

    li {
        font-size: ${Theme.font.small.size};
        color: #707070;
        height: 32px;
        cursor: pointer;
        padding: 0px 4px 0 8px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > ul {
            display: none;
        }

        &:hover,
        &.selected {
            background: ${Theme.colors.info.bg};

            > ul {
                display: inline-block;
            }
        }

        &.alone {
            border-top: 1px solid ${Theme.colors.neutrals.silver};
        }
    }
`;

const timeZoneName = {
    'ET': 'America/Detroit',
    'CT': 'America/Chicago',
    'MT': 'America/Denver',
    'PT': 'America/Los_Angeles',
    'AKT': 'US/Alaska',
    'HT': 'Pacific/Honolulu',
    'AZ': 'America/Phoenix',
    'CET': 'Europe/Amsterdam',
    'UTC': 'UTC'
};

const mergeRefs = (refs) => {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

const padZero = (n) => (n < 10 ? "0" + n : n);

const pattern = /^(1[012]|[1-9]):([0-5][0-9]) ?(am|pm)$/i;

Date.prototype.stdTimezoneOffset = function () {
    const jan = new Date(this.getFullYear(), 0, 1);
    const jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.dst = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

const today = new Date();
// UTC conversion
// let offset = today.getTimezoneOffset();
/*if(!today.dst()){
    if(offset > 0){
        offset -= 60;
    }else{
        offset += 60;
    }
}*/

const fromMilliseconds = (milliseconds) => {
    let period = "am";

    let hours = Math.floor(milliseconds / 3600 / 1000);

    let fraction = milliseconds / 3600 / 1000 - hours;
    let minutes = Math.floor(fraction * 60);

    if (hours >= 12) {
        hours -= 12;
        period = "pm";
    }

    if (hours === 0) hours = 12;

    return {
        hours: hours,
        minutes: minutes,
        period: period,
    };
};

const TimePickerOptionsStyle = styled.div`
    position: relative;

    ${Options} {
        left: 0;
        top: -21px;
        height: 192px;
        overflow: auto;

        li {
            padding-right: 8px;
        }
    }

    ${OptionGroup} {
        font-size: ${Theme.font.extraSmall.size};
        line-height: ${Theme.font.extraSmall.lineHeight};
        position: sticky;
        top: 0;
        z-index: 1;
        background: ${Theme.colors.neutrals.white};

        &:hover {
            background: ${Theme.colors.neutrals.white};
        }
    }
`;

const TimePickerOptions = ({ time, setTime }) => {
    const optionsRef = useRef();

    const hours = [];
    for (let i = 1; i <= 12; i++)
        hours.push(
            <li key={i} onClick={() => handleSelect("hours", i)} className={time.hours === i ? "selected" : ""}>
                {i}
            </li>
        );

    const minutes = [];
    for (let i = 0; i <= 59; i++)
        minutes.push(
            <li key={i} onClick={() => handleSelect("minutes", i)} className={time.minutes === i ? "selected" : ""}>
                {i < 10 ? "0" + i : i}
            </li>
        );

    const handleSelect = (what, value) => {
        let newTime = { ...time };
        newTime[what] = value;
        setTime(newTime);
    };

    useEffect(() => {
        optionsRef.current.querySelectorAll(".selected").forEach((li) => li.scrollIntoView({ block: "center" }));
    }, [time]);

    return (
        <TimePickerOptionsStyle ref={optionsRef}>
            <Options>
                <OptionGroup as="li">Hour</OptionGroup>
                {hours}
            </Options>
            <Options style={{ left: 45 }}>
                <OptionGroup as="li">Minutes</OptionGroup>
                {minutes}
            </Options>
            <Options style={{ left: 108 }}>
                <OptionGroup as="li">&nbsp;</OptionGroup>
                <li onClick={() => handleSelect("period", "am")} className={time.period === "am" ? "selected" : ""}>
                    am
                </li>
                <li onClick={() => handleSelect("period", "pm")} className={time.period === "pm" ? "selected" : ""}>
                    pm
                </li>
            </Options>
        </TimePickerOptionsStyle>
    );
};

const TimePicker = forwardRef(
    (
        {
            name,
            label,
            size,
            placeholder,
            valid,
            error,
            onChange,
            Required,
            defaultValue,
            milliseconds,
            timeZoneShortName = "ET",
            dateForTimePicker=null,
            diffTime = false,
            ...props
        },
        ref
    ) => {
        const timeZoneSName = (timeZoneShortName == 'Custom') ? 'UTC' : timeZoneShortName;
        const timezone = timeZoneName[timeZoneSName];
        const [open, setOpen] = useState(false);
        const containerRef = useRef();
        const [offsetHours, setOffsetHours] = useState(0);
        const [offsetMinutes, setOffsetMinutes] = useState(0);

        const [time, setTime] = useState({
            hours: "",
            minutes: "",
            period: "",
        });
        const valueRef = useRef();
        const displayRef = useRef();

        if(dateForTimePicker == null) {
            dateForTimePicker = moment().tz(timezone).format('YYYY-MM-DD');
            if(moment(dateForTimePicker).day() === 0) {
                dateForTimePicker = moment(dateForTimePicker).add(1, 'days').format('YYYY-MM-DD');
            }
        }

        useEffect(() => {
            if (dateForTimePicker) {
                let offset = moment(dateForTimePicker).tz(timezone).format("Z");
                offset = offset.split(":")[0] * 60;
                if(offset < 0) offset = -(offset);
                setOffsetHours(Math.floor(offset / 60));
                setOffsetMinutes(offset % 60);
            }
        }, [dateForTimePicker]);

        const toUTC = ({ hours, minutes }) => {
            let m = (timeZoneShortName == "CET") ? (minutes - offsetMinutes) : (minutes + offsetMinutes);
            // let m = minutes;
            if (m < 0) m += 60;

            let h = (timeZoneShortName == "CET") ? (hours - offsetHours) : (hours + offsetHours);
            // let h = hours;
            h = h > 23 ? h - 24 : h < 0 ? 24 + h : h;
            return `${padZero(h)}:${padZero(m)}`;
        };

        const fromUTC = ({ hours, minutes }) => {
            let m = (timeZoneShortName == "CET") ? (minutes + offsetMinutes) : (minutes - offsetMinutes);
            // let m = minutes;
            if (m >= 60) m -= 60;
            let h = (timeZoneShortName == "CET") ? (hours + offsetHours) : (hours - offsetHours);
            // let h = hours;
            h = h < 0 ? 24 + h : h > 23 ? h - 24 : h;
            return {
                hours: h,
                minutes: m,
            };
        };

        const fromFormat = (str) => {
            let matches = str.match(pattern);

            if (matches === null) return null;

            let [input, hours, minutes, period] = matches;

            return {
                hours: parseInt(hours),
                minutes: parseInt(minutes),
                period: period,
            };
        };

        const toFormat = ({ hours, minutes, period }) => {
            if (!hours && !minutes && !period) return "";

            if (hours === 0 && minutes === 0 && period === "am") hours = 12;

            minutes = minutes === "" ? minutes : minutes < 10 ? "0" + minutes : minutes;

            return hours + ":" + minutes + " " + period;
        };

        const fromValue = (str) => {
            let matches = str.match(/^(\d{1,2}):(\d{1,2})$/);

            if (matches === null) return null;

            let { hours, minutes } = fromUTC({
                hours: parseInt(matches[1]),
                minutes: parseInt(matches[2]),
            });

            let period = "am";

            if (hours >= 12) {
                hours -= 12;
                period = "pm";
            }

            if (hours === 0) hours = 12;

            return {
                hours: hours,
                minutes: minutes,
                period: period,
            };
        };

        const toValue = ({ hours, minutes, period }) => {
            if (!hours && !minutes && !period) return "";

            let nw = hours;
            nw = period === "pm" ? nw + 12 : nw;

            if (nw === 12 && period === "am") {
                nw = 0;
            }

            if (nw === 24) nw = 12;

            return toUTC({
                hours: nw,
                minutes: minutes,
            });
        };

        const maybeClose = (evt) => {
            if (!open) return;

            if (!containerRef.current.contains(evt.target)) setOpen(false);
        };

        const handleFocus = (evt) => {
            setOpen(true);
        };

        const handleKeyDown = (evt) => {
            let key = evt.key.toLowerCase();

            switch (key) {
                case "tab":
                    evt.persist();
                    setTimeout(() => {
                        evt.target = document.activeElement;
                        maybeClose(evt);
                    }, 100);
                    break;
            }
        };

        const handleChange = (evt) => {
            let v = displayRef.current.value;

            if (!pattern.test(v)) {
                valueRef.current.value = "";
                return;
            }

            let [input, hours, minutes, period] = v.match(/^(1[012]|[0-9]):([0-5][0-9]) ?(am|pm)/i);

            setTime({
                hours: parseInt(hours),
                minutes: parseInt(minutes),
                period: period,
            });
        };

        const sendChange = () => {
            // if ( !pattern.test( displayRef.current.value ) ) {
            //     valueRef.current.value = '';
            //     return;
            // }

            valueRef.current.value = toValue(time);

            if (typeof onChange === "function") onChange(toValue(time));
        };

        // set events to show or hide dropdown
        useEffect(() => {
            document.addEventListener("click", maybeClose);

            return () => document.removeEventListener("click", maybeClose);
        }, [open]);

        // set default value
        let realDefaultValue;
        useEffect(() => {
            if (valueRef.current && valueRef.current.value) {
                realDefaultValue = fromValue(valueRef.current.value);
            } else if (defaultValue) {
                realDefaultValue = fromValue(defaultValue);
            } else if (milliseconds) {
                realDefaultValue = fromMilliseconds(milliseconds);
            }

            if (realDefaultValue) setTime(realDefaultValue);
        }, [offsetHours]);

        // send change events
        useEffect(() => {
            let { hours, minutes, period } = time;
            if (!hours && !minutes && !period) {
            } else {
                sendChange();
            }
        }, [time]);

        // update display format
        useEffect(() => {
            let display = "";
            let newTime = fromValue(valueRef.current.value);

            if (newTime) {
                if (toValue(time) !== toValue(newTime)) setTime(newTime);

                let format = toFormat(newTime);
                if (format) {
                    display = format;
                }
            } else if (displayRef.current.value && fromFormat(displayRef.current.value)) {
                display = displayRef.current.value;
                valueRef.current.value = toValue(fromFormat(display));
            }

            displayRef.current.value = display || (diffTime ? "multiple" : display);
        });
        let helpText = "hh:mm am/pm";
        if(timeZoneShortName == "Custom") {
            helpText += " (Station Timezone)";
        }

        if(timeZoneShortName == "UTC") {
            helpText += " (UTC)";
        }

        return (
            <Frag ref={containerRef}>
                <Input
                    label={label}
                    help={helpText}
                    size={size}
                    placeholder={placeholder}
                    valid={valid}
                    error={error}
                    Required={Required}
                    ref={displayRef}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    autoComplete="off"
                    mask="time"
                    {...props}
                >
                    <Icon.Clock
                        size="20px"
                        top="10px"
                        color={Theme.colors.secondary}
                        style={{ pointerEvents: "none" }}
                    />
                </Input>
                <input type="hidden" name={name} ref={mergeRefs([valueRef, ref])} />
                {open && <TimePickerOptions time={time} setTime={setTime} />}
            </Frag>
        );
    }
);

export default TimePicker;
