import { objectOf } from "prop-types";
import { STATUS } from "./index";

export const reducer = (state, action) => {
    const { payload } = action;

    switch (action.type) {
        case "LOAD_COMPETITIONS":
            const metricsKeys = Object.keys(payload.metrics[0]["metric"]);

            const flatMetrics = payload.metrics.map((v) => {
                let handleMetrics = {};
                for (let i = 0; i <= metricsKeys.length; i++) {
                    handleMetrics.goal = v["goal"];
                    handleMetrics[metricsKeys[i]] = v["metric"][metricsKeys[i]];
                }

                return handleMetrics;
            });

            return {
                status: STATUS.READY,
                competitions: payload.competitions,
                metrics: flatMetrics,
                //goals:payload.goals
            };
        case "ADD_COMPETITION":
            state.competitions.push(payload.competition);
            state.status = STATUS.READY;
            return state;
        case "SET_STATUS":
            state.status = payload.status;
            return state;
        default:
            return state;
    }
};
