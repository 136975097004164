
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Dropdown, TagInput, Theme, Icon, ThirdTitle} from "@dspworkplace/ui";
import { useAuth, getCompanyId } from "../../../Auth";
import SettingsContext from "../context";
import SettingTable from './attendance'
import styled from 'styled-components';
import { AccessCheck, IsGranted } from "../../../security";
import { Label } from "../../../components/UI";
import { showToast } from "../../../Utilities";

const Container = styled.div`
    display: grid;
    width: 100%;
`;

const Div = styled.div`
    padding: 7px;
    border-radius: ${Theme.defaultRadius};
    border-width:1px;
    border-style:solid;
    display:flex;
    align-items:center;
    position:relative;

    svg {
        margin-right: 8px;
        display: block;
    }
`;

const Content = styled.p`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
`;

const App = () => {
    const [state, dispatch] = useContext(SettingsContext);
    const { api } = useAuth();

    const getAttendanceSetting = async (data, evt) => {
        let param = {
            "actions": {
                "response": {
                    "AttendanceSettings": {
                        "findOneBy": {
                            "criteria": {
                                "company": getCompanyId(),
                                "isArchive": false
                            },
                            "get": "attendanceSettings",
                            "includes": {
                                0: "id",
                                1: "minLevel1",
                                2: "maxLevel1",
                                3: "colorLevel1",
                                4: "minLevel2",
                                5: "maxLevel2",
                                6: "colorLevel2",
                                7: "minLevel3",
                                8: "maxLevel3",
                                9: "colorLevel3",
                                10: "createdDate",
                                11: "notificationToDriverLower",
                                12: "notificationToDriverUpper",
                                13: "notificationToManagerLower",
                                14: "notificationToManagerUpper",
                                15: "notificationSentAt",
                                16: "costOfNCNS",
                                17: "costOfLate",
                                18: "costOfCallOut",
                                19: "reportDays",
                                20: "notificationManagers"
                            }
                        }
                    },
                }
            }
        }
        let res = await api.post('/api/lazy/manage/data', param);
        res = res.data.data.attendanceSettings;

        dispatch({
            type: 'UPDATE_ATTENDENCE_SETTING',
            payload: {
                ...state.attendanceRules,
                minLevel1: res.minLevel1,
                maxLevel1: res.maxLevel1,
                colorLevel1: res.colorLevel1,
                minLevel2: res.minLevel2,
                maxLevel2: res.maxLevel2,
                colorLevel2: res.colorLevel2,
                minLevel3: res.minLevel3,
                maxLevel3: res.maxLevel3,
                colorLevel3: res.colorLevel3,
                reportDays: res.reportDays,
                ncns: res.costOfNCNS,
                callout: res.costOfCallOut,
                late: res.costOfLate,
                notificationSentAt: res.notificationSentAt,
                notificationToDriverLower: res.notificationToDriverLower,
                notificationToDriverUpper: res.notificationToDriverUpper,
                notificationToManagerLower: res.notificationToManagerLower,
                notificationToManagerUpper: res.notificationToManagerUpper,
                notificationManagers: res.notificationManagers
            }
        })
    };

    useEffect(() => {
        getAttendanceSetting();
    },[]);

    return (
        <Container>
            <Spacer bottom={10}/>
                <Div style={{ background: Theme.colors['info'].bg, borderColor: Theme.colors['info'].border,color: Theme.colors['info'].text }}>
                <div style={{
                    padding: 0
                }}>
                    {React.createElement(
                        Icon.Info,
                        {
                            size: '40px',
                            color: Theme.colors['info'].text
                        }
                    )}
                </div>
                <div>
                    <ThirdTitle>{'Note'}</ThirdTitle>
                    <Content>
                        <div style={{marginBottom:'7px'}}>{'The Attendance Compliance Score is a percentage score calculated using the total number of days worked during the time period selected.'}</div>
                        <div>{'Example: In a 60 calendar-day period, a driver may be scheduled to work 37 days. If on those same 37 shifts, using the default "Cost of Incidents" values, the driver has 1 Call out, 1 NCNS, and 1 Lates assigned to him, the total events points would equal 6. 37 - 6 = 31. 31 divided by 37 = 83%  -- we do not round up. We then display the percentage on the employee "card" in any Scheduler view (click on an employee name to see it). The colors and alerts are configurable.'}</div>
                    </Content>
                </div>
            </Div>
            <Spacer bottom={5}/>
                <SettingTable />
            <Spacer bottom={20} />
            <ReportDays />
            <Spacer bottom={20}/>
            <CostOfIncident />
            <Spacer bottom={20}/>
            <NotificationSetting />
            <Spacer bottom={20}/>
        </Container>
    )
}

const ReportDays = () => {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, reset } = useForm();
    const [defaultWeeks, setDefaultWeeks] = useState(parseInt(localStorage.getItem('recurringWeeks')));

    useEffect(() => reset(state.company), [state.company]);

    let daysArr = [];
    for (let day = 1; day <= 365; day++) {
        daysArr.push({ 'name': day.toString(), 'value': day.toString() });
    }    

    const handleDaysSetting = async (data, evt) => {
        let param = {
            "actions": {
                "update": {
                    "AttendanceSettings": {
                        "attendance_settings_1": {
                            "findBy": {
                                "company": getCompanyId()
                            },
                            "updateRecord": {
                                "reportDays": data.reportDays,
                            }
                        }
                    }
                }
            }
        }
        await api.post('/api/lazy/manage/data', param);
        await showToast({
            type: 'success',
            title: 'Days of Attendance Compliance Setting',
            content: 'Updates completed'
        });
        
        dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, reportDays: data.reportDays } })
    };

    return (
        <form onSubmit={handleSubmit(handleDaysSetting)}>
            <SubTitle>Days of Attendance Compliance Setting</SubTitle>
            <Spacer bottom={3} />
            <Dropdown
                name='reportDays'
                placeholder='Select Days'
                ref={register()}
                label='Select Days'
                options={daysArr}
                defaultValue={state.attendanceRules.reportDays}
            />
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

const CostOfIncident = () => {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, reset } = useForm();

    useEffect(() => reset(state.company), [state.company]);

    const onSubmit = async(data, evt) => {
        let param = {
            "actions": {
                "update": {
                    "AttendanceSettings": {
                        "attendance_settings_1": {
                            "findBy": {
                                "company": getCompanyId()
                            },
                            "updateRecord": {
                                "costOfNCNS": data.ncns,
                                "costOfLate": data.late,
                                "costOfCallOut": data.callout,
                            }
                        }
                    }
                }
            }
        }
        await api.post('/api/lazy/manage/data', param);
        await showToast({
            type: 'success',
            title: 'Cost Of Incidents',
            content: 'Updates completed'
        });

        dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, ncns: data.ncns, late: data.late, callout: data.callout } })
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Cost Of Incidents</SubTitle>
            <Spacer bottom={3} />
            <Spacer inline right={7}>
                <Label>NCNS</Label>
                <Spacer bottom={1} />
                <Input
                    ref={register}
                    name='ncns'
                    size='extraSmall'
                    defaultValue={state.attendanceRules.ncns}
                />
            </Spacer>
            <Spacer inline right={7}>
                <Label>Late</Label>
                <Spacer bottom={1} />
                <Input
                    ref={register}
                    name='late'
                    size='extraSmall'
                    defaultValue={state.attendanceRules.late}
                />
            </Spacer>
            <Spacer inline right={7}>
                <Label>CallOut</Label>
                <Spacer bottom={1} />
                <Input
                    ref={register}
                    name='callout'
                    size='extraSmall'
                    defaultValue={state.attendanceRules.callout}
                />
            </Spacer>
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

function formatForTagInputForUser(list, user) {
    if (list) {
        if (list.includes(user.id.toString())) {
            return { name: user.firstName + ' ' + user.lastName, value: user.id, selected: true };
        } else {
            return { name: user.firstName + ' ' + user.lastName, value: user.id };
        }
    }
}

async function fetchManager(api, state, setDefaultUsers) {
    let param = {
        "actions": {
            "response": {
                "User": {
                    "custom": {
                        "functionName": "findManagers",
                        "get": "managers",
                        "criteria": {
                            "company": localStorage.getItem('company')
                        }
                    }
                }
            }
        }
    }
    let res = await api.post('/api/lazy/manage/data', param);
    let users = res.data.data.managers;

    let defaultUsers = state.attendanceRules.notificationManagers;
    let tags = [];
    let user;
    for (let i = 0; i < users.length; i++) {
        user = users[i];
        tags.push(formatForTagInputForUser(defaultUsers, user));
    }
    setDefaultUsers(tags);
    return true
}

const NotificationSetting = () => {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, reset } = useForm();
    const [defaultUsers, setDefaultUsers] = useState([]);

    const onSubmit = async (data, evt) => {
        let param = {
            "actions": {
                "response": {
                    "AttendanceSettings": {
                        "custom": {
                            "functionName": "updateManager",
                            "get": "managers",
                            "criteria": {
                                "company": getCompanyId(),
                                "notificationManagers": data.notificationManagers
                            }
                        }
                    }
                }
            }
        }
        await api.post('/api/lazy/manage/data', param);

        await showToast({
            type: 'success',
            title: 'Notification Sent to Manager',
            content: 'Updates completed'
        });

        dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, notificationManagers: data.notificationManagers } })
    };

    useEffect(() => {
        fetchManager(api, state, setDefaultUsers).then(() => {
        });
    }, [state.attendanceRules]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Notification Sent to Manager</SubTitle>
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Select Manager for Notification:"}
                        options={defaultUsers}
                        size="big"
                        name={'notificationManagers'}
                        ref={register}
                    />
                </TagInputContainer>
            </RowContainer>
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={3} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default App;