import React, {Suspense, useEffect, useReducer, useState} from "react";
import styled from "styled-components";
import {useDispatch, useTrackedState} from "./store";
import {Button, CheckBox, Icon, Theme, ThirdTitle} from "@dspworkplace/ui";
// import ChannelRow from "./ChannelRow";
import ChannelHeader from "./ChannelHeader";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import ChannelIcon from "./ChannelIcon";
import {
    getAllowToChatStream,
    getChatCompanyId,
    getChatTeam,
    getChatUserId,
    getCompanyId,
    getImpersonateStreamChatIdentifier,
    getIsEnableSMS,
    getPositionName,
    getRole,
    getStreamChatIdentifier,
    getUserId,
    setChatCompanyId,
    setChatTeam,
    setChatUserId,
    setImpersonateStreamChatIdentifier,
    setIsEnableSMS,
    setStreamChatIdentifier,
    upload
} from "../Auth";
import {Text} from "./styles";
// import 'stream-chat-react/dist/css/v2/index.css';
import {Picker} from 'emoji-mart';
import {useUpdate as useBotDispatch} from "./BotTask/Store"

import "./styles.css";
import type {EventComponentProps} from "stream-chat-react";
import {
    Avatar,
    Channel,
    ChannelList,
    ChannelSearch,
    ChannelSearchProps,
    InfiniteScroll,
    isChannel,
    Message,
    MessageInput,
    MessageList,
    PinIcon,
    SearchResultItemProps,
    Thread,
    TypingIndicator,
    useChannelActionContext,
    useChannelPreviewInfo,
    useChannelStateContext,
    useChatContext,
    useEmojiContext,
    useMessageContext,
    useMessageInputContext,
    Window
} from "stream-chat-react";
import ModalWindow from "../AppTwilioConversation/Modal";
import Driver from "../components/People";
import ReviewModalView from "./BotTask/ReviewModalView";
import moment from "moment";
import ChannelDetails from "./Components/ChannelDetails";
import PinnedMessageList from "./Components/PinnedMessageList";
import NewDirectMessageAdd from "./Components/NewDirectMessageAdd";
import NewGroupChannelAdd from "./Components/NewGroupChannelAdd";
import UpdateMembers from "./Components/UpdateMembers";
import {initialState, reducer} from "./CGCOptions";
import {getEmployeesTwilio, sendMessageAPI, sendMessageBotAPI} from "./utils";
import BotTaskButtons from "./BotTask/BotTaskButtons";
import {engine, getChatUserRole, getSelectedStationId, setChatUserRole, showErrorToast, showToast} from "../Utilities";
import CreatePoll from "./Components/CreatePoll";
import ProgressBar from "./Components/ProgressBar";
import PollDetails from "./Components/PollDetails";
import GiphyModal from "./Components/GiphyModal";
import {getBadWords} from "../ChatNew/utils";

const ChatSidebarContainer = styled.div`
    display: ${props => (props.display ? props.display : "grid")};
    width: auto;
    // width: 1000px;
    width:${props => (props.width ? props.width : '1000px')};
    height: calc(100vh - 64px);
    box-shadow: ${props => (props.boxShadow ? props.boxShadow : '0 3px 6px #00000029')} ;
    position: fixed;
    bottom: ${props => (props.bottom ? props.bottom : "5px")};
    right: ${props => (props.right ? props.right : "5px")};
    background-color: white;
`;

const ChannelsScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 64px);
    width: 1000;
    &::-webkit-scrollbar {
        width: 0;
    };
`;

const RowChannelKind = styled.div`
    display: grid;
    width: 260px;
    height: 44px;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding: 8px 0 0 0;
    cursor: default;
    
    & ${ThirdTitle} {
        color: ${Theme.colors.secondary};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`;

const RowOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: 8px;
    grid-template-columns: repeat(1, auto);
    //margin: 10px 16px;
`;

const Content = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 40px auto;
    grid-row-gap: 12px;
    min-height:unset;
    grid-template-rows:25px auto;
`;

const Image = styled.img`
    width:300px;
    margin:0 auto;
    display:block;
    height:auto;
`;

export const SmallRoundButton = styled(Button)`
    width: 32px;
    height: 32px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    
    > svg {
        flex-shrink: 0;
        width: 20px;
    }
`;

const CancelButton = styled(Button)`
    width: ${props => (props.width ? props.width : "22px")};
    height: ${props => (props.height ? props.height : "22px")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071bc;
        border-radius: 2px;
    }
`;
const MessageInfoDateTimeContent = styled.div`
    .str-chat {
        height: auto !important;
        width: auto !important;
        min-width: 400px;
    }
    hr {
        height: 1px;
        border: none;
        background-color: #858585;
        margin-top: 20px;
    }
`;
const PerticularSearch = () => {
    const state = useTrackedState();
    const { chatClient } = state;
    // const { setParticipantProfile, setSearching } = props;
    const [searching, setSearching] = useState(true);
    const { client } = useChatContext();

    const [participants, setParticipants] = useState([]);
    const [querying, setQuerying] = useState(false);
    const [showingSearchResults, setShowingSearchResults] = useState(false);

    useEffect(() => {
        const getParticipants = async () => {
            if (querying) return;
            setQuerying(true);

            // try {
            const { users } = await chatClient.queryUsers(
                // { type: "group",  members: { $in: [chatClient.userID] } }
                { id: { $ne: getStreamChatIdentifier() } },
                { id: 1, last_active: -1 },
                { limit: 10 },
            );
            // console.log(users);

            if (users.length) setParticipants(users);
            // } catch (err) {
            //   console.log(err);
            // }

            setQuerying(false);
        };

        getParticipants();
    }, []); // eslint-disable-line

    useEffect(() => {
        console.log(participants);
    }, [participants])


    const handleSelectResult = async (result) => {
        console.log('handleSelectResult');
        // console.log(result);
        if (isChannel(result)) return;
        //   setParticipantProfile(result);
        setSearching(false);
    };

    const onSelectResult = (
        params,
        result,
    ) => handleSelectResult(result);

    const extraParams: ChannelSearchProps['searchQueryParams'] = {
        userFilters: {
            options: { limit: 20 },
        },
    };


    const SearchLoading: React.FC = () => <div className='search-loading'>Loading participants...</div>;
    const SearchEmpty: React.FC = () => <div className='search-empty'>No participants found</div>;

    const onSetParticipants = (param) => {
        console.log(param);
    }

    return (
        <div className='search'>
            <div className='search-header'>
                <div className='search-header-close' onClick={() => setSearching(false)}>
                    X
                </div>
                <div className='search-header-title'>Participants</div>
            </div>
            <ChannelSearch
                // @ts-ignore todo: setChannels value should be ChannelOrUser
                setChannels={onSetParticipants}
            //   onSelectResult={onSelectResult}
            //   searchQueryParams={extraParams}
            //   SearchEmpty={SearchEmpty}
            //   SearchLoading={SearchLoading}
            //   SearchResultItem={SearchResult}
            //   ClearInputIcon={'Clear'}
            //   SearchInputIcon={'SearchIcon'}
            //   onSearch={() => setShowingSearchResults(true)}
            //   onSearchExit={() => setShowingSearchResults(false)}
            />
            {querying ? (
                <></>
            ) : showingSearchResults ? null : (
                participants?.length && (
                    <div className='.str-chat__channel-search-result-list'>
                        {participants.map((participant, i) => (
                            <SearchResult
                                index={i}
                                key={i}
                                result={participant}
                                selectResult={handleSelectResult}
                            />
                        ))}
                    </div>
                )
            )}
        </div>
    );
}

export const SearchResult: React.FC<SearchResultItemProps> = (props) => {
    const { focusedUser, index, result, selectResult } = props;

    const focused = focusedUser === index;

    if (isChannel(result)) return null;

    return (
        <div
            className={`search-result ${focused ? 'focused' : ''}`}
            onClick={() => selectResult(result)}
        >
            <Avatar image={result.image} name={result.name || result.id} user={result} />
            <div className='search-result-info'>
                <div className='search-result-info-name'>{result.name || result.id}</div>
                <div className='search-result-info-title'>{result.title || 'Attendee'}</div>
            </div>
        </div>
    );
};

const init = async (channel) => {
    const membersReadStatus = await Object.values(channel.state.read)
    const channelMessages = await channel.state.messages

    var messages = []

    for (var i = 0; i < channelMessages.length; i++) {
        await messages.push({ index: i, message: channelMessages[i] })
    }
    var userUpdatedStatusList = []
    for (var i = 0; i < membersReadStatus.length; i++) {
        // var message = await messages.find(val => val.message.id === membersReadStatus[i]?.last_read_message_id)
        // var message = await messages[(messages.length - 1) - membersReadStatus[i]?.unread_messages]
        var message = await messages.find(val => val.message.id === membersReadStatus[i]?.last_read_message_id)
        await userUpdatedStatusList.push({ user: membersReadStatus[i].user, message: message })

    }

    return { userUpdatedStatusList, messages }
}

const options = {
    member: true,
    watch: true,
    state: true, presence: true,
    // limit: 5
};

const NewChat: React.FC = ({ isPage }) => {
    const state = useTrackedState();
    const dispatch = useDispatch();
    const [newChannelModalState, newChannelModalDispatch] = useReducer(reducer, initialState);

    const { defaultAutoUsers } = newChannelModalState
    const { chatClient, showFilterForWords, filter, initialChannel, isOpen, duoCounter, groupCounter, botCounter, trainingVideo } = state;

    const [currentMessage, setCurrentMessages] = useState();
    const [readUsers, setReadUsers] = useState([])
    const [unreadUsers, setUnreadUsers] = useState([])
    const [isVisible, setVisible] = useState(false)
    const [isChecked, setChecked] = useState(true)
    const [parentMessage, setParent] = useState('')
    const [openReviewModal, setOPenReviewModal] = useState(false)
    const [reviewType, setReviewType] = useState('')
    const [isSMSChecked, setSMSChecked] = useState(false)
    const [group_expand, setGroupExpand] = useState(true)
    const [duo_expand, setDuoExpand] = useState(false)
    const [isShowChannelDetail, setShowChannelDetail] = useState(false)
    const [isOpenPinnedList, setOpenPinnedList] = useState(false)
    const [defaultUsersToChat, setDefaultUsersToChat] = useState([])

    const [isMenConstract, setMenuConstract] = useState(false)
    const [isHover, setHover] = useState(false)
    const [AllMessaegStatus, setAllMessageStatus] = useState([])
    const [pinChannels, setPinChannels] = useState([])
    const [isDirectMessageModalOpen, setDirectMessageModalOPen] = useState(false)
    const [isGroupAddModalOpen, setGroupAddModalOPen] = useState(false)
    const [isUpdateModalOpen, setUpdateModalOPen] = useState(false)
    const [allowGroupActions, setAllowGroupActions] = useState(false)
    const [currentUserRole, setCurrentUserRole] = useState(false)
    const [isDisableOnImpersonate, setIsDisableOnImpersonate] = useState(false)
    const [isShowCreatePoll, setShowCreatePoll] = useState(false)
    const [isShowPoll, setShowPoll] = useState(false)
    const [All_members, setAllMembers] = useState([]);
    const [isStickerModalVisible, setStickerModalVisible] = useState(false)
    const [activeGiphyType, setActiveGiphyType] = useState('gifs')
    const [visibleDeletedMessages, setVisibleDeletedMessages] = useState([])

    useEffect(() => {
        const identifier = getImpersonateStreamChatIdentifier();
        if (identifier && identifier.length > 0) {
            setIsDisableOnImpersonate(true);
        } else {
            setIsDisableOnImpersonate(false);
        }
    }, [getImpersonateStreamChatIdentifier()]);

    //Call bad word API 10 minutes
    useEffect(() => {
        getBadWords();
        const intervalId = setInterval(() => {
            getBadWords();
        }, 600000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const botDispatch = useBotDispatch();

    useEffect(() => {
        if (newChannelModalState.users.length == 0) {
            getEmployeesTwilio().then(result => {
                if (result) {
                    setDefaultUsersToChat(result?.defaultAutoUsers?.map((u) => u.userId));
                }
            });
            const abortController = new AbortController();
            newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
            getEmployeesTwilio()
                .then(result => {
                    if (result) {
                        try {
                            newChannelModalDispatch({
                                type: "SET_USERS",
                                users: result.users,
                                loadingUsers: false,
                                roles: result.roles,
                                skills: result.skills,
                                stations: result.stations,
                                schedules: result.schedules,
                                teams: result.teams,
                                chatVariable: result.chatVariable,
                                defaultAutoUsers: result.defaultAutoUsers
                            });
                        } catch (e) {
                            console.error({ e });
                        }
                    }

                })
                .catch(error => {
                    console.error({ error });
                    newChannelModalDispatch({
                        type: "SET_USERS",
                        users: [],
                        loadingUsers: false,
                        roles: [],
                        skills: [],
                        stations: [],
                        schedules: [],
                        teams: [],
                        chatVariable: [],
                        defaultAutoUsers: [],
                    });
                });

            return () => {
                abortController.abort();
            };
        }
    }, [newChannelModalDispatch]);

    const extraParams: ChannelSearchProps['searchQueryParams'] = {
        userFilters: {
            options: { limit: 20 },
        },
    };

    const customSearchGroup = async (
        props,
        event: { target: { value } },
        client
    ) => {
        const { setResults, setSearching, setQuery } = props;
        const value = event.target.value;


        const filters = {
            name: { $autocomplete: value },
            // members: { $in: [client.userID] },
            type: 'group',
            // teams: { $in: [getChatTeam()()] },
            members: { $in: [getStreamChatIdentifier()] }
        }

        setSearching(true);
        setQuery(value);
        const channels = await client.queryChannels(filters);
        // console.log(channels);
        setResults(channels);
        setSearching(false);
    };

    const customSearchDuo = async (
        props,
        event: { target: { value } },
        client
    ) => {
        const { setResults, setSearching, setQuery } = props;
        const value = event.target.value;

        let userIds = [];
        let channels = [];
        // console.log('value', value);
        setQuery(value);
        if (value && value.length > 0) {
            // console.log('enter');
            const userList = await chatClient.queryUsers(
                {
                    id: { $ne: getStreamChatIdentifier() },
                    $and: [{ name: { $autocomplete: value } }],
                },
                { id: 1 },
                { limit: 100 },
            );
            // $and: [{ name: { $autocomplete: value }, team: getChatTeam() }],

            await userList?.users?.map((user) => { console.log(user); userIds.push(user.id); });

            if (userIds.length > 0) {
                const filters = {
                    // name: { $autocomplete: value },
                    type: 'duo',
                    // teams: { $in: [getChatTeam()] },
                    members: { $in: userIds }
                    // $and: [ members: { $in: [chatClient.userID] } ]
                }

                setSearching(true);
                // setQuery(value);
                channels = await client.queryChannels(filters);
                await channels.map((channel) => {
                    Object.keys(channel.state.members).map((member) => {
                        if (member != client.userID) {
                            channel.data.name = channel.state.members[member].user.name;
                        }
                    });
                    return channel;
                });
                // console.log(channels);
                channels = await channels.filter((channel) => {
                    return channel.state.members.hasOwnProperty(client.userID);
                });
                // console.log(channels);

                await setResults(channels);
            } else {
                // setQuery(value);
            }
        }

        // setResults([]);
        // if(channels.length > 0){
        //     setSearching(false);
        // }else{
        //     // setSearching(true);
        // }
        setSearching(false);
    };

    const onChannelUpdated = (setChannels, event) => {
        const eventChannel = event.channel;

        // If the channel isn't frozen, then don't add it to the list.
        // if (!eventChannel?.id || !eventChannel.frozen) return;

        try {
            const newChannel = chatClient.channel(eventChannel.type, eventChannel.id);
            newChannel.watch();
            setChannels((channels) => {
                let isFind = false;
                channels?.map((channel, index) => {

                    if (channel.id == eventChannel.id) {
                        channels[index] = newChannel;
                        isFind = true;
                    }
                });
                if (isFind) {
                    return channels
                } else {
                    return [newChannel, ...channels]
                }
            });
            if (event.channel?.type && event.channel.id) {
                getPinChannels()
            }

            // let channels1 = await chatClient.queryChannels({ id: { $eq: eventChannel.id } });
            // let channels1 = await chatClient.queryChannels({ pinUser: { $eq: [chatClient.userID] },teams: { $in: [getChatTeam()] },  members: { $in: [chatClient.userID] } });
            // console.log(channels1);

            // setChannels(async (channels) => { return channels1;});
            // setChannels(async (channels) => {
            //     let tempChannels = await channels.map((channel, index) => {
            //         if(channel.id == eventChannel.id){
            //             return channels1[0];
            //         }
            //         return channel;
            //     }).filter((data) => {
            //         console.log(data.data.pinUser);
            //         return data.data.pinUser?.includes(chatClient.userID)})

            //         return tempChannels;
            // });
        } catch (error) {
            console.log(error);
        }
    };

    const customSearch = async (
        props,
        event: { target: { value } },
        client
    ) => {
        const { setResults, setSearching, setQuery } = props;
        const value = event.target.value;
        setQuery(value);
        setSearching(true);
        let userIds = [];
        let channels = [];
        if (value && value.length > 0) {
            const userList = await chatClient.queryUsers(
                {
                    id: { $ne: getStreamChatIdentifier() },
                    $and: [{ name: { $autocomplete: value } }],
                    team: { $in: [getChatTeam()] }
                },
                { id: 1 },
                { limit: 100 },
            );
            // $and: [{ name: { $autocomplete: value }, team: getChatTeam() }],

            await userList?.users?.map((user) => { userIds.push(user.id); });
            if (userIds.length > 0) {
                const filters = {
                    type: 'duo',
                    members: { $in: userIds }
                }
                // teams: { $in: [getChatTeam()] },

                channels = await client.queryChannels(filters, { last_updated: -1 }, { limit: 300 });
                await channels.map((channel) => {
                    Object.keys(channel.state.members).map((member) => {
                        if (member != client.userID) {
                            channel.data.name = channel.state.members[member].user.name;
                        }
                    });
                    return channel;
                });
                channels = await channels.filter((channel) => {
                    return channel.state.members.hasOwnProperty(client.userID);
                });

            } else {
                // setQuery(value);
            }

            const filter_group = {
                name: { $autocomplete: value },
                type: 'group',
                // teams: { $in: [getChatTeam()] },
                members: { $in: [getStreamChatIdentifier()] }
            }

            const channels_group = await client.queryChannels(filter_group, { last_updated: -1 }, { limit: 300 });
            await channels_group.map((ch) => {
                if (ch.data.isArchive == true) {
                    ch.data.name = ch.data.name + " (Archived)";
                }
                return ch;
            })
            await setResults([...channels, ...channels_group]);
        }
        setSearching(false);
    };

    const { channel, setActiveChannel, navOpen, } = useChatContext();
    const [pin, setPin] = useState(false);
    const [mute, setMute] = useState(false);

    async function filterReadUsers(message) {
        try {
            let membersLastReadMessage = []
            let messagesWithIndex = []

            await init(channel).then((data) => {
                messagesWithIndex = data.messages
                membersLastReadMessage = data.userUpdatedStatusList
            });

            await setCurrentMessages(message)
            const currentMessageIndex = await messagesWithIndex.find(i => i.message.id === message.id)
            var readList = []
            var unReadList = []
            await membersLastReadMessage.forEach((value) => {
                if (value.user.id !== message?.user?.id) {
                    if (value.message === undefined) {
                        unReadList.push(value.user)
                    }
                    else if (value.message.index < currentMessageIndex.index) {
                        unReadList.push(value.user)
                    } else {
                        readList.push(value.user)
                    }
                }
            })
            await setReadUsers(readList)
            await setUnreadUsers(unReadList)
            await setVisible(true)
        } catch (error) {
            setVisible(false)
            // reportErrorInstabug(error, 'filterReadUsers')
            console.log(error)
        }
    }

    const getReadAtTimestamp = (message, readBy, userId) => {
        const readByUser = readBy.find((read) => read.id === userId);
        return readByUser ? readByUser.updated_at : null;
    };

    if (channel) {
        var allMembers = Object.values(channel?.state?.members)
        var ActiveChannel = channel
    }

    useEffect(() => {
        getAllowUsers()
        getMembers()
        setVisibleDeletedMessages([])
    }, [channel])

    async function getAllowUsers() {
        const users = await [...defaultAutoUsers?.map(item => { return item.userId }), channel?.data?.created_by?.userid?.toString()]
        const currentUserRole = await localStorage.getItem('currentUserRole')
        setCurrentUserRole(currentUserRole)
        if (currentUserRole === 'ROLE_OWNER' || users?.includes(getChatUserId()?.toString())) {
            await setAllowGroupActions(true)
        }
    }

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    }

    async function clickToCall(message) {
        try {
            if (message?.user?.personalPhone) {
                await window.open(`tel:${message?.user?.personalPhone}`);
            }
            else {
                let response = await engine().post('/api/lazy/manage/data', {
                    "actions": {
                        "response": {
                            "User": {
                                "findOneBy": {
                                    "criteria": {
                                        "chatIdentifier": message?.user?.id
                                    },
                                    "get": "user",
                                    "includes": {
                                        "driver": {
                                            "0": "personalPhone"
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                console.log(response?.data?.data?.user)
                if (response?.data?.data?.user?.driver?.personalPhone) {
                 window.open(`tel:${response?.data?.data?.user?.driver?.personalPhone}`);
                    chatClient?.partialUpdateUser({ id: message?.user?.id, set: { personalPhone: response?.data?.data?.user?.driver?.personalPhone } })
                }
                else
                    alert('User phone number not exist')
            }
        } catch (err) {
            console.log(err)
            alert('User phone number not exist')
        }
    }

    async function handleDeletedMessageVisible(messageId) {
        setVisibleDeletedMessages((prev) => {
            return [...prev, messageId]
        })
    }

    const CustomeMessage = (props) => {
        const { messages, hasMore } = useChannelStateContext();
        const { message, isMyMessage, readBy } = useMessageContext();
        const { openThread, loadMore } = useChannelActionContext();
        let actions = isDisableOnImpersonate ? [] :
            isMyMessage() ?
                ['edit', 'quote', 'react', 'reply', 'pin']
                : isMyMessage() === false && channel?.type === 'group' ?
                    ['quote', 'react', 'reply', 'pin']
                    : currentUserRole === 'ROLE_OWNER' && channel?.type === "duo" ?
                        ['quote', 'react', 'reply', 'pin']
                        : ['quote', 'react', 'reply', 'pin'];

        if (message.user.image == "") {
            delete message.user.image;
        }

        message?.attachments?.map((attachment) => {
            if (attachment.type == 'document') {
                attachment.type = 'file';
                attachment.asset_url = (attachment.asset_url) ? attachment.asset_url : attachment.image_url;
                let extension = attachment.asset_url.substring(attachment.asset_url.lastIndexOf(".") + 1);
                let name = attachment.asset_url.substring(attachment.asset_url.lastIndexOf("/") + 1);
                attachment.title = name;

                switch (extension) {
                    case "csv":
                        attachment.mime_type = "text/csv";
                        break;
                    case "pdf":
                        attachment.mime_type = "application/pdf";
                        break;
                    case "doc":
                        attachment.mime_type = "application/msword";
                        break;
                    case "docx":
                        attachment.mime_type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                        break;
                    case "txt":
                        attachment.mime_type = "text/plain";
                        break;
                    case "zip":
                        attachment.mime_type = "application/zip";
                        break;
                    case "xls":
                        attachment.mime_type = "application/vnd.ms-excel";
                        break;
                    case "xlsx":
                        attachment.mime_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        break;
                    case "ppt":
                        attachment.mime_type = "application/vnd.ms-powerpoint";
                        break;
                    case "pptx":
                        attachment.mime_type = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
                        break;
                    case "zip":
                        attachment.mime_type = "application/gzip";
                        break;
                }
                delete attachment.image_url;
                return attachment
            }
            else if (attachment.type == 'sticker' || attachment.type == 'giphy') {
                attachment.title_link = attachment?.image_url ? attachment?.image_url : attachment?.image_url;
                return attachment
            }
            else {
                return attachment;
            }
        });

        var thread;
        if (message?.show_in_channel && message?.parent_id !== undefined) {
            thread = messages?.find(i => i.id === message.parent_id);
            // console.log(channel?.state.messages.some(i => (i.id === message.parent_id)), message.text)
        }

        const customOpenThread = async (props) => {
            await setParent(props);
            await openThread(props);
        }

        //Remove Replied to a thread reply action
        if(message?.parent_id !== undefined && actions.includes('reply') === true) {
            var filteredActions = actions.filter(function(action) {
                return action !== "reply";
            });
            actions = filteredActions;
        }
        let vimeoUrl = '';
        let metricName = '';
        if (message?.messageType === "metricVideo") {
            let videoObj = trainingVideo.find((video) => video.metricKey == message?.text);
            if(videoObj != undefined) {
                vimeoUrl = videoObj?.videoUrl;
                metricName = videoObj.metric;
            }
        }
        

        return (
            <div key={message?.id} className={message?.pinned ? "pinned-message" : ""} >
                {message.show_in_channel && (
                    <div key={message.id}
                        style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', justifyContent: message.user?.id === `${getStreamChatIdentifier()}` ? 'flex-end' : 'flex-start' }}>
                        <Text color={'#00000099'} style={{ flex: 'none' }} >Replied to a thread:</Text>
                        <button
                            style={{ backgroundColor: '#fff', cursor: 'pointer' }}
                            onClick={() => customOpenThread(thread)}
                        >

                            <Text color={'#0071BC'} fontWeight={'500'} style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden' }}   >
                                {thread?.text}</Text>
                        </button>
                    </div>
                )}
                {message?.pinned && (
                    <div className='str-chat__message-team-pin-indicator' style={{ justifyContent: isMyMessage() ? 'flex-end' : 'flex-start', fill: '#858688' }} >
                        <PinIcon />
                        {message.pinned_by
                            ? `Pinned by ${message.pinned_by?.name || message.pinned_by?.id}`
                            : 'Message pinned'}
                    </div>
                )}
                {message?.messageType === "poll" && message?.type === 'regular' ?
                    <li class="str-chat__li str-chat__li--bottom" data-message-id={message?.id} data-testid="str-chat__li str-chat__li--bottom" >
                        <div class="">
                            <div class={isMyMessage() ? "str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--me str-chat__message-simple--me str-chat__message--has-text" : "str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--other str-chat__message--has-text"}>
                                <Avatar image={message?.user?.image} name={message?.user?.name} size={25} />
                                <div class="str-chat__message-inner" data-testid="message-inner">
                                    <div class="str-chat__message-bubble">
                                        <div class="str-chat__message-text" tabindex="0">
                                            <div class="str-chat__message-text-inner str-chat__message-simple-text-inner" data-testid="message-text-inner-wrapper">
                                                <div>
                                                    <p>{message?.text}</p>
                                                    {message?.poll_options?.map((item, index) => {
                                                        return (
                                                            <div>
                                                                <div style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end', display: 'flex' }} >
                                                                    <p>{item?.value}</p>
                                                                    <p>{`${item?.total_votes} Votes`}</p>
                                                                </div>
                                                                <ProgressBar
                                                                    fillColor={'#00B281'}
                                                                    unfilledColor="#D6D6DC"
                                                                    percentage={`${((item?.total_votes / All_members?.length).toFixed(2) * 100)}%`}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{ alignSelf: 'center', marginTop: '25px', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderTop: isMyMessage() ? '1px solid #E4EAF4' : '1px solid #0071bc', paddingBlock: 'inherit', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setCurrentMessages(message)
                                                        setShowPoll(true)
                                                    }} >
                                                    <p style={{ color: isMyMessage() ? '#ffffff' : '#0071bc' }} >View/Edit votes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="str-chat__li--bottom str-chat__message-data str-chat__message-simple-data">
                                        {!isMyMessage() && (<span class="str-chat__message-simple-name">{message?.user?.name}</span>)}
                                        <time class="str-chat__message-simple-timestamp">{moment(message?.created_at).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    :
                    message?.attachments && message?.attachments.length === 1 && message?.attachments[0]?.type === 'sticker' ?
                        <div className={'str-chat__message-attachment-card--sticker'} >
                            <Message
                                {...props}
                                message={message}
                                messageActions={actions}
                                openThread={customOpenThread}
                                formatDate={(date)=>moment(date).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })}
                                customMessageActions={isMyMessage() && channel?.type === 'group' ? {
                                    'Copy text': (message) => {
                                        copyToClipboard(message.text);
                                    },
                                    'Message Info': (message) => {
                                        filterReadUsers(message)
                                    },
                                } : !isMyMessage() && channel?.type === 'group' ? {
                                    'Copy text': (message) => {
                                        copyToClipboard(message.text);
                                    },
                                    'Message Info': (message) => {
                                        filterReadUsers(message)
                                    },
                                    'Mark As Unread': async (message) => {
                                        await setUnreadMessage(message)
                                    },
                                } : isMyMessage() && channel?.type === 'duo' ? {
                                    'Copy text': (message) => {
                                        navigator.clipboard.writeText(message.text || '');
                                    },
                                }
                                    : {
                                        'Copy text': (message) => {
                                            navigator.clipboard.writeText(message.text || '');
                                        },
                                        'Mark As Unread': async (message) => {
                                            await setUnreadMessage(message)
                                        },
                                    }
                                }
                            >
                            </Message>
                        </div>
                        : message?.messageType === "metricVideo" ? <>
                            <li class="str-chat__li str-chat__li--bottom" data-message-id={message?.id} data-testid="str-chat__li str-chat__li--bottom" >
                                <div class="">
                                <div class={isMyMessage() ? "str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--me str-chat__message-simple--me str-chat__message--has-text" : "str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--other str-chat__message--has-text"}>
                                    <Avatar image={message?.user?.image} name={message?.user?.name} size={25} />
                                    <div class="str-chat__message-inner" data-testid="message-inner">
                                        <div class="str-chat__message-bubble">
                                        <div class="str-chat__message-text" tabindex="0">
                                            <div class="str-chat__message-text-inner str-chat__message-simple-text-inner" data-testid="message-text-inner-wrapper">
                                                <div>
                                                    <iframe src={vimeoUrl} width="426" height="240" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"></iframe>
                                                    <p>{metricName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="str-chat__li--bottom str-chat__message-data str-chat__message-simple-data">
                                            {!isMyMessage() && (<span class="str-chat__message-simple-name">{message?.user?.name}</span>)}
                                            <time class="str-chat__message-simple-timestamp">{moment(message?.created_at).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })}</time>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </li>                                
                        </>
                            : message?.type === 'deleted' && visibleDeletedMessages?.includes(message?.id) && message?.deleted_message ?
                                <div className="deleted-visible">
                                    <Message
                                        {...props}
                                        message={message?.deleted_message}
                                        messageActions={[]}
                                        formatDate={() => undefined}
                                        />
                                </div>
                                :
                                <Message
                                {...props}
                                message={message}
                                messageActions={actions}
                                openThread={customOpenThread}
                                formatDate={(date)=>moment(date).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })}
                                customMessageActions={isMyMessage() && channel?.type === 'group' ? {
                                    'Copy text': (message) => {
                                        copyToClipboard(message.text);
                                    },
                                    'Message Info': (message) => {
                                        filterReadUsers(message)
                                    },
                                    'Delete': async (message) => {
                                        await chatClient.partialUpdateMessage(message.id, {
                                            set: {
                                                deleted_message: message,
                                                deleted_by: { id: chatClient.user.id, name: chatClient.user.name },
                                                deleted_message_text: message.text,
                                            }
                                        });
                                        await chatClient.deleteMessage(message.id, false);
                                    }
                                } : !isMyMessage() && channel?.type === 'group' ? {
                                    'Copy text': (message) => {
                                        copyToClipboard(message.text);
                                    },
                                    'Message Info': (message) => {
                                        filterReadUsers(message)
                                    },
                                    'Mark As Unread': async (message) => {
                                        await setUnreadMessage(message)
                                    },
                                    'Click to call': async (message) => {
                                        await clickToCall(message)
                                    },
                                    'Delete': async (message) => {
                                        await chatClient.partialUpdateMessage(message.id, {
                                            set: {
                                                deleted_message: message,
                                                deleted_by: { id: chatClient.user.id, name: chatClient.user.name },
                                                deleted_message_text: message.text,
                                            }
                                        });
                                        await chatClient.deleteMessage(message.id, false);
                                    }
                                } : isMyMessage() && channel?.type === 'duo' ? {
                                    'Copy text': (message) => {
                                        navigator.clipboard.writeText(message.text || '');
                                    },
                                    'Delete': async (message) => {
                                        await chatClient.partialUpdateMessage(message.id, {
                                            set: {
                                                deleted_message: message,
                                                deleted_by: { id: chatClient.user.id, name: chatClient.user.name },
                                                deleted_message_text: message.text,
                                            }
                                        });
                                        await chatClient.deleteMessage(message.id, false);
                                    }
                                    }
                                        : currentUserRole === 'ROLE_OWNER' && channel?.type === "duo" ? {
                                            'Copy text': (message) => {
                                                navigator.clipboard.writeText(message.text || '');
                                            },
                                            'Mark As Unread': async (message) => {
                                                await setUnreadMessage(message)
                                            },
                                            'Click to call': async (message) => {
                                                await clickToCall(message)
                                            },
                                            'Delete': async (message) => {
                                                await chatClient.partialUpdateMessage(message.id, {
                                                    set: {
                                                        deleted_message: message,
                                                        deleted_by: { id: chatClient.user.id, name: chatClient.user.name },
                                                        deleted_message_text: message.text,
                                                    }
                                                });
                                                await chatClient.deleteMessage(message.id, false);
                                            }
                                }
                                    : {
                                        'Copy text': (message) => {
                                            navigator.clipboard.writeText(message.text || '');
                                        },
                                        'Mark As Unread': async (message) => {
                                            await setUnreadMessage(message)
                                        },
                                        'Click to call': async (message) => {
                                            await clickToCall(message)
                                        }
                                    }
                                }
                            >
                            </Message>
                }

                {message?.type === 'deleted' && visibleDeletedMessages?.includes(message?.id) && message?.deleted_message ?
                    <div style={{ display: 'flex', justifyContent: isMyMessage() ? 'right' : 'left', color: '#DD0040', fontSize: 11, marginTop: 5 }}>
                        <span>{`Deleted By: ${message?.deleted_by?.name} at ${moment(message?.deleted_at).format('YYYY-MM-DD hh:mm a')}`}</span>
                    </div>
                    :
                    isMyMessage() ?
                        <div style={{ display: 'inline-flex', width: "100%", justifyContent: 'right', gap: 10 }}>
                            {message?.deleted_message && allowGroupActions &&
                                <div onClick={() => handleDeletedMessageVisible(message.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px" fill='#7A7A7A' >
                                        <path d='M22.819 12C21.878 6.88 17.392 3 12 3 6.608 3 2.121 6.88 1.181 12c.941 5.12 5.427 9 10.819 9 5.392 0 9.879-3.88 10.819-9zm-2.042 0A9.005 9.005 0 0112 19a9.005 9.005 0 01-8.777-7 9.005 9.005 0 0117.554 0zM12 16.5a4.5 4.5 0 112.297-8.37 2.5 2.5 0 101.573 1.573A4.5 4.5 0 0112 16.5zm3.87-6.798a2.506 2.506 0 00-1.572-1.572 4.523 4.523 0 011.572 1.572z' />
                                    </svg>
                                </div>
                            }
                    {readBy?.length === allMembers?.length ?
                        <Text textAlign="right" color="#0071BC" fontSize="10px" latterSpacing="-1px" >
                            ✓✓</Text>
                        : readBy?.length === 0 || readBy?.length === 1 ?
                            <Text textAlign="right" color="gray" fontSize="10px" >
                                ✓</Text>
                            :
                            <div style={{ display: 'inline-flex', justifyContent: 'right' }}>
                                <Text textAlign="right" color="gray" fontSize="10px"  >
                                    {readBy?.filter(i => i.id !== getStreamChatIdentifier())?.length}</Text>
                                <Text textAlign="right" color="gray" fontSize="10px" latterSpacing="-1px" margin="2px" >
                                    ✓✓</Text>
                                    </div>
                            }
                        </div>
                        : message?.deleted_message && allowGroupActions ?
                            <div onClick={() => handleDeletedMessageVisible(message.id)} style={{marginLeft:40}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px" fill='#7A7A7A' >
                                    <path d='M22.819 12C21.878 6.88 17.392 3 12 3 6.608 3 2.121 6.88 1.181 12c.941 5.12 5.427 9 10.819 9 5.392 0 9.879-3.88 10.819-9zm-2.042 0A9.005 9.005 0 0112 19a9.005 9.005 0 01-8.777-7 9.005 9.005 0 0117.554 0zM12 16.5a4.5 4.5 0 112.297-8.37 2.5 2.5 0 101.573 1.573A4.5 4.5 0 0112 16.5zm3.87-6.798a2.506 2.506 0 00-1.572-1.572 4.523 4.523 0 011.572 1.572z' />
                                </svg>
                            </div>
                    : null
                }
            </div >
        )
    }

    const reviewHandler = async (message) => {
        try {
            if (message.attributes["task"]) {
                await setCurrentMessages(message)
                await setReviewType('OPEN_SHIFT')
                await botDispatch({ type: "REVIEW_TASK", message: message });
                await setOPenReviewModal(true)
            } else if (message && message.id) {
                let attr = Object.assign({}, message.attributes);
                attr['archived'] = true;
                await message.updateAttributes(attr);
                botDispatch({ type: "MESSAGE_REMOVED", sid: message.id });
            }
        } catch (error) {
            alert(error)
        }
    }

    const CustomeMessageBot = ({ props }) => {
        const { message, highlighted } = useMessageContext();
        let image = channel?.data.image || "";
        let name = channel?.data.name || "";
        let type = channel?.data.type || "";

        return (
            <div key={message?.id} style={{ display: 'flex', alignItems: 'flex-end' }} >
                <Avatar image={image} name={name} size={25} />
                <div className="custome-message-container" >
                    <BotTaskButtons reviewHandler={reviewHandler} message={message} channel={channel} />
                </div>
                {/* <Message
                    {...props}
                    message={message}
                    messageActions={[]}
                    groupStyles={['single']}
                >
                </Message>

                <BotTaskButtons reviewHandler={reviewHandler} message={message} channel={channel} /> */}


            </div>
        )
    }

    async function setUnreadMessage(message) {
        try {
            await channel?.markUnread({ message_id: message?.id }).then(async (res) => {
                await channel?.updatePartial({ set: { source: "system" } });
                await channel.watch();
            });
            await setActiveChannel(initialChannel);
        } catch (error) {
            console.log(error)
            alert(error)
        }
    }

    const CustomMessageInput = () => {
        const { channel } = useChannelStateContext();
        const { type } = channel || "";

        if (type == 'bot') {
            return <></>;
        }
        if (getAllowToChatStream() === "0" || getAllowToChatStream() === 0)
            return <></>
        if (channel?.data?.read_only_roles?.includes(getPositionName()))
            return <></>
        else {
            return (
                <div>
                    <div style={{ padding: '9px 40px' }}>
                        <CheckBox
                            name={'sendSMS'}
                            defaultChecked={isSMSChecked}
                            onClick={() => setSMSChecked(!isSMSChecked)}
                            disabled={getIsEnableSMS() === 'false' ? true : false}
                            options={[{ label: "Also send SMS", value: true }]}
                        />
                    </div>
                    <MessageInput
                        focus={true}
                        doFileUploadRequest={async (file, channel) => {
                            let formData = new FormData();
                            formData.append('image', file);
                            formData.append('name', file.name);
                            let result = await upload(formData,true);
                            return { file: result.data?.ObjectURL };
                        }}
                        doImageUploadRequest={async (file, channel) => {
                            let formData = new FormData();
                            formData.append('image', file);
                            formData.append('name', file.name);
                            let result = await upload(formData,true);
                            return { file: result.data?.ObjectURL };
                        }}
                        overrideSubmitHandler={overrideSubmitHandler}
                    />
                </div>
            );
        }
    };

    const overrideSubmitHandler = async (message) => {
        try {

            let isBadSentence = await filter?.isProfane(message?.text)
            if (isBadSentence) {
                let name = "";
                if (channel?.type == 'bot') {
                    name = 'workplace_bot';
                }
                if (channel?.type == 'duo') {
                    Object.keys(channel?.state.members).map((member) => {
                        if (member != getStreamChatIdentifier() && member != 'bot') {
                            name = channel?.state.members[member].user.name;
                        }
                    });
                }

                await Promise.all([
                    sendMessageBotAPI({
                        "body": `${name} has sent an inappropriate message and it has been blocked.`,
                        "companyId": getChatCompanyId(),
                        // "stationId": getSelectedStationId(),
                        "case": "BAD_WORDS",
                        "message": 'Unfiltered content:\n' + message?.text + '\nFiltered content:\n' + filter?.clean(message?.text),
                    }),
                    sendMessageAPI({
                        body: `Hi ${name}, your message was blocked due to inappropriate language, and your manager has been notified.`,
                        channelSid: channel?.id,
                        companyId: getChatCompanyId(),
                        channelType: channel?.type
                    })
                ]);
            } else {
                // Now send the message
                if (message.mentioned_users.length > 0) {
                    channel?.sendMessage({
                        ...message,
                        mentioned_users: message?.mentioned_users.map(i => { return i.id }),
                        // silent: channel?.data?.mute_for_all ? true : false
                    }, {
                        skip_push: channel?.data?.mute_for_all ? true : false,
                    })
                } else {
                    const data = await channel?.sendMessage({
                        ...message,
                        // silent: channel?.data?.mute_for_all ? true : false
                    }, {
                        skip_push: channel?.data?.mute_for_all ? true : false
                    })
                }
            }

            if (channel?.type === 'duo' && channel?.data.isRemoved && channel?.data.isRemoved?.length > 0)
                await channel?.updatePartial({ set: { isRemoved: [] } });

            if (isSMSChecked) {
                setSMSChecked(false);
                // Send the sms message
                let destinationDrivers = Object.keys(channel?.state?.members).filter(e => e !== getStreamChatIdentifier());
                destinationDrivers = Array.from(new Set(destinationDrivers));
                try {
                    let { data } = await engine().post('/api/send/sms/drivers', {
                        "companyId": getChatCompanyId(),
                        "stationId": getSelectedStationId(),
                        "destinationDrivers": destinationDrivers,
                        "content": message?.text,
                    });
                    if (!data.success) {
                        await showToast({
                            type: 'Error',
                            title: 'Error sending SMS.',
                        });
                    }
                    if (Array.isArray(data.results)) {
                        for (let i = 0; i < data.results.length; i++) {
                            await showToast({
                                type: 'Error',
                                title: 'Error sending SMS.',
                                content: data.results[i].message,
                            });
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'SMS Error', 'Error sending SMS.');
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const pinChannel = async () => {
        let pinUser = await channel?.data?.pinUser || [];
        if (pin) {
            if (pinUser?.includes(getStreamChatIdentifier())) {
                await pinUser?.splice(pinUser?.indexOf(getStreamChatIdentifier()), 1);
            }
        } else {
            if (!pinUser?.includes(getStreamChatIdentifier())) {
                await pinUser?.push(getStreamChatIdentifier());
            }
        }
        await channel?.updatePartial({ set: { pinUser: pinUser } });
        await setPin((channel?.data?.pinUser && channel?.data?.pinUser?.includes(getStreamChatIdentifier())) || false)
    };

    const RightArrowButton = (props) => {
        const { onPress } = props;
        return (
            <CancelButton
                type="cancel"
                onClick={onPress}
                width={'16px'}
                height={'16px'}
            >
                {(pin ? (
                    <Icon.Pin/>
                ) : (
                    <Icon.Unpin/>
                ))
                }
            </CancelButton>
        );
    };

    const MuteButton = (props) => {
        const { onPress } = props;
        return (
            <CancelButton
                type="cancel"
                onClick={onPress}
                width={'16px'}
                height={'16px'}
            >
                {(mute ? (
                    <Icon.SoundOff/>
                ) : (
                    <Icon.SoundOn/>
                ))}
            </CancelButton>
        );
    };

    //system message handle
    const CustomSystemMessage = (props: EventComponentProps) => {
        const { message } = props;
        const { created_at = '', text } = message;
        const date = moment(created_at);
        if (
            ((!getImpersonateStreamChatIdentifier() && getRole() === "ROLE_OWNER") || (
                getImpersonateStreamChatIdentifier() && getChatUserRole() === "ROLE_OWNER"
            ) ||
                defaultUsersToChat.includes(getChatUserId()?.toString())) &&
            message?.type === "system"
        ) {
            return (
                <div className="str-chat__message--system">
                    <div className="str-chat__message--system__text">
                        <div className="str-chat__message--system__line"></div>
                        <p>{text}</p>
                        <div className="str-chat__message--system__line"></div>
                    </div>
                    <div className="str-chat__message--system__date">
                        <strong>{date.format("dddd ")}</strong>
                        {date.format("[AT] hh:mm A")}
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const ChannelPreview = (props) => {
        const { agentChannelId, channel, customerChannelId, setActiveChannel, unread, displayTitle } = props;
        let image = channel.data.image || "";
        let name = channel.data.name || "";
        let type = channel.data.type || "";
        let subTitle = "";
        let userTitle = "";
        const [channelUsers, setChannelUsers] = useState<Array<{ name: string; online: boolean }>>(Object.values(channel.state.members).map((user) => ({
            name: user.user.name,
            online: !!user.user!.online,
        })));
        const [isHover, setHover] = useState(false)
        const [isremoveButtonHover, setRemoveButtonHover] = useState(false)

        if (channel.type == 'bot') {
            name = 'workplace_bot';
            subTitle = 'Your Work Assistant';
        }
        if (channel.type == 'duo') {
            Object.keys(channel.state.members).map((member) => {
                if (member != getStreamChatIdentifier() && member != 'bot') {
                    name = channel.state.members[member].user.name;
                    image = channel.state.members[member].user.image;
                    userTitle = channel.state.members[member].user.title;
                }
            });
        }

        const renderMessageText = () => {
            let lastMessageText = '';
            if (channel?.state?.messages?.length > 0) {
                lastMessageText = channel?.state?.messages[channel?.state?.messages?.length - 1]?.text;
            }

            return lastMessageText.length < 70 ? lastMessageText : `${lastMessageText.slice(0, 70)}...`;
        };

        async function removeChannelInList(channel) {
            let isRemoved = channel?.data?.isRemoved && channel?.data?.isRemoved?.includes(chatClient?.userID)
            let removedUser = await channel?.data?.isRemoved || [];
            if (isRemoved) {
                if (removedUser?.includes(chatClient?.userID)) {
                    await removedUser?.splice(removedUser?.indexOf(chatClient.userID), 1);
                }
            } else {
                if (!removedUser?.includes(chatClient.userID)) {
                    await removedUser?.push(chatClient.userID);
                }
            }
            await channel?.updatePartial({ set: { isRemoved: removedUser } });
        }

        const status = channelUsers?.find(i => i.name === displayTitle)
        if (isMenConstract) {
            return (
                <div style={{ backgroundColor: channel?.id === ActiveChannel?.id ? 'aliceblue' : "white", display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <ChannelContainerMenu style={{ borderBottom: 'none', cursor: 'pointer' }} title={name}
                        key={channel.id}
                        onClick={() => {
                            setOPenReviewModal(false)
                            setActiveChannel(channel)
                            setShowChannelDetail(false)
                            setOpenPinnedList(false)
                            setDirectMessageModalOPen(false)
                            setGroupAddModalOPen(false)
                            setUpdateModalOPen(false)
                            setShowPoll(false)
                            setShowCreatePoll(false)
                        }}
                    >
                        <ChannelIcon channel={channel} image={image} type={type} name={name} status={status} />
                        {unread > 0 && (
                            <UnreadContainer >
                                <Text fontSize={'10px'} textAlign='center' color="white">{unread}</Text>
                            </UnreadContainer>
                        )}
                    </ChannelContainerMenu >
                </div>
            )
        }
        return (
            <div style={{ backgroundColor: isHover ? 'aliceblue' : channel?.id === ActiveChannel?.id ? 'aliceblue' : "white" }} >
                <ChannelContainer
                    key={channel.id}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div style={{ display: 'contents' }}
                        onClick={() => {
                            setOPenReviewModal(false)
                            setActiveChannel(channel)
                            setShowChannelDetail(false)
                            setOpenPinnedList(false)
                            setDirectMessageModalOPen(false)
                            setGroupAddModalOPen(false)
                            setUpdateModalOPen(false)
                            setShowPoll(false)
                            setShowCreatePoll(false)
                        }}>
                        <ChannelIcon channel={channel} image={image} type={type} name={name} status={status} />
                        <TextInformationContainer>
                            <ChannelTitle width={'unset'} channelName={name} fontWeight={'500'} />
                            <ChannelSubtitle width={'unset'} channel={channel} type={type} userTitle={userTitle} />
                        </TextInformationContainer>
                        {channel?.type === 'bot' && botCounter > 0
                            ?
                            <Notification
                                width={String(unread).length > 3 ? '22px' : '20px'}
                                height={String(unread).length > 3 ? '22px' : '20px'}
                                fontSize={String(unread).length > 3 ? '8px' : '10px'}
                            >
                                {botCounter}</Notification>
                            : unread > 0 ? <Notification
                                width={String(unread).length > 3 ? '22px' : '20px'}
                                height={String(unread).length > 3 ? '22px' : '20px'}
                                fontSize={String(unread).length > 3 ? '8px' : '10px'}
                            >
                                {unread}</Notification>
                                : null
                        }
                    </div>
                    {isHover && channel?.type === 'duo' && (
                        <div style={{ cursor: 'pointer', display: 'flex', height: '26px', width: '26px', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => removeChannelInList(channel)}
                            onMouseEnter={() => setRemoveButtonHover(true)}
                            onMouseLeave={() => setRemoveButtonHover(false)} >
                            <Icon.TimesNarrow size={`14px`} color={isremoveButtonHover ? '#0071bc' : '#707070'} />
                        </div>
                    )}
                    {/* <ArrowRightButton type='cancel'><Icon.ArrowRight size='12px'/></ArrowRightButton> */}
                </ChannelContainer >
            </div >
        );
    };

    const muteChannel = async () => {
        if (channel?.muteStatus().muted) {
            await channel.unmute();
        } else {
            await channel?.mute();
        }
        await channel?.watch();
        await setMute(channel?.muteStatus().muted)
    };

    const sendThreadMessage = (props) => {
        channel?.sendMessage({ text: props.text, parent_id: parentMessage?.id, show_in_channel: isChecked })
    }

    const removeImpersonate = async () => {
        if (getCompanyId() != getChatCompanyId()) {
            const params = {
                actions: {
                    response: {
                        Company: {
                            custom: {
                                functionName: "getSmsPermission",
                                get: "getSmsPermission",
                                criteria: {
                                    company: getCompanyId(),
                                },
                                excludes: {},
                                includes: {},
                            },
                        },
                    },
                },
            };
            let response = await engine().post("/api/lazy/manage/data", params).then(async result => {
                if (result) {
                    try {
                        await setIsEnableSMS(result.data.data.getSmsPermission);
                    } catch (e) {
                        console.error({ e });
                    }
                }
            });
        }
        await setStreamChatIdentifier(getImpersonateStreamChatIdentifier());
        await setImpersonateStreamChatIdentifier("");
        await setChatTeam('company-' + getCompanyId());
        await setChatUserRole(getRole())
        await setChatCompanyId(getCompanyId());
        await setChatUserId(getUserId());
        window.location.reload();
    };
    const CustomThreadHeader = ({ closeThread, thread }) => {
        const { channel } = useChannelStateContext("");
        const { displayTitle } = useChannelPreviewInfo({ channel });
        let username = '';
        if (channel.type == 'duo') {
            Object.keys(channel.state.members).map((member) => {
                if (member != chatClient.userID && member != 'bot') {
                    username = channel.state.members[member].user.name;
                }
            });
        }

        const close = async () => {
            closeThread();
            const targetElement = await document.querySelector(`[data-message-id="${thread.id}"]`);
            if (targetElement) {
                await targetElement.scrollIntoView({ behavior: "smooth" });
            } 
        };
        return (
            <div className="wrapper">
                <div className="participants-wrapper">
                    <div className="str-chat__thread-header">
                        <div className="str-chat__thread-header-details">
                            <div className="str-chat__thread-header-title">Thread</div>
                            <div className="str-chat__thread-header-subtitle">{ username != '' ? username : displayTitle }</div>
                        </div>
                        <button
                            onClick={() => {
                                close();
                            }}
                            aria-label="Close thread"
                            className="str-chat__square-button str-chat__close-thread-button"
                            data-testid="close-button"
                        >
                            <Icon.TimesNarrow/>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        getPinChannels()
    }, [])

    async function getPinChannels() {
        const channels = await chatClient.queryChannels({
            pinUser: { $eq: [getStreamChatIdentifier()] },
            teams: { $in: [getChatTeam()] },
            members: { $in: [getStreamChatIdentifier()] }
        })
        await setPinChannels(channels)
    }

    async function getMembers() {
        try {
            let offset = 0;
            let limit = 50;
            let hasData = true;
            await setAllMembers([])
            while (hasData) {
                let data = await getMembersData(offset, limit)
                if (data?.length > 0) {
                    await setAllMembers((prevState) => [...prevState, ...data])
                    offset = offset + limit
                } else {
                    hasData = false
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getMembersData(offset, limit) {
        try {
            const data = await channel?.queryMembers({}, { created_at: -1 }, { limit: limit, offset: offset });
            return data?.members
        } catch (error) {
            console.log(error)
            return null;
        }
    }

    const Paginator = (props) => <InfiniteScroll {...props} threshold={50} />;

    if (chatClient) {
        return (
            <>
                <div style={{
                    width: isMenConstract && isPage ? '5%' : isMenConstract && !isPage ? '9%' : isPage ? '18%' : "35%", float: "left", backgroundColor: "#fff", overflowY: "auto", height: "100%",
                    padding: isMenConstract ? '5px' : 0, transition: 'width 50ms linear'
                }}
                    className={"clist"}>
                    {isMenConstract ?
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '10px 0 10px' }} >
                            <SmallRoundButton
                                onClick={() => setMenuConstract(false)}
                            >
                                <Icon.ArrowRight />
                            </SmallRoundButton>
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 16 }}>
                            {!isPage ?
                                <SmallRoundButton
                                    onClick={() => dispatch({ type: "OPEN_CHAT", isOpen: false })}
                                >
                                    <Icon.Times />
                                </SmallRoundButton>
                                : <div />
                            }
                            <RowOptions>
                                <SmallRoundButton
                                    onClick={() => setMenuConstract(true)}
                                >
                                    <Icon.ArrowLeft />
                                </SmallRoundButton>

                                {!isPage && (
                                    <SmallRoundButton
                                        onClick={() => {
                                            window.open('/chat', 'liveMatches', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1200,height=800')
                                            dispatch({ type: "OPEN_CHAT", isOpen: false })
                                        }}
                                    >
                                        <Icon.Maximize />
                                    </SmallRoundButton>
                                )}

                                {showFilterForWords && isHover &&
                                    <SmallRoundButton
                                        onClick={() => {
                                            setHover(false)
                                            dispatch({ type: "OPEN_LIST_BAD_WORDS_MODAL" })
                                        }}
                                    >
                                        <Icon.Swear/>
                                    </SmallRoundButton>
                                }
                                {(localStorage.getItem('currentUserRole') == 'ROLE_OWNER' || getRole() === 'ROLE_OPERATIONS_MANAGER' || getRole() === 'ROLE_OPERATIONS_ACCOUNT_MANAGER' || getRole() === 'ROLE_HR_ADMINISTRATOR') && isHover &&
                                    <SmallRoundButton
                                        onClick={() => {
                                            setHover(false)
                                            dispatch({ type: "OPEN_EXPORT_MODAL" })
                                        }}
                                    >
                                        <Icon.ExportChat/>
                                    </SmallRoundButton>
                                }
                                {(localStorage.getItem('currentUserRole') == 'ROLE_OWNER' || defaultUsersToChat.includes(getChatUserId()?.toString())) && isHover &&
                                    <SmallRoundButton
                                        onClick={() => {
                                            setHover(false)
                                            dispatch({ type: "OPEN_IMPERSONATE_MODAL" })
                                        }}
                                    >
                                        <Icon.Impersonate/>
                                    </SmallRoundButton>
                                }
                                {isHover && (
                                    <SmallRoundButton
                                        onClick={() => {
                                            setHover(false)
                                            dispatch({ type: "OPEN_ARCHIVE_GROUP_MODAL" })
                                        }}
                                    >
                                        <Icon.Archive size={"24px"} />
                                    </SmallRoundButton>
                                )}
                                <SmallRoundButton
                                    onClick={() => setHover(!isHover)}
                                >
                                    <Icon.Cog size={"28px"} />
                                </SmallRoundButton>
                            </RowOptions>
                        </div>
                    }
                    {getImpersonateStreamChatIdentifier() && <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%', cursor: 'pointer', borderBottom: `2px solid ${Theme.colors.error.border}`, borderTop: `2px solid ${Theme.colors.error.border}`, margin: '10px 0', padding: '10px 0' }} onClick={() => removeImpersonate()}>
                        <Text color={Theme.colors.error.text} fontSize={"15px"} margin={"0 7px"} lineHeight={"unset"} fontWeight={'bold'} textAlign={"center"} >
                            ({chatClient.user.name}) Exit Impersonate
                        </Text>
                    </div>}
                    <div className={isMenConstract ? "menu-contract" : ''} >
                        <ChannelList
                            // filters={{ type: 'bot', members: { $in: [getStreamChatIdentifier()] }, teams: { $in: [getChatTeam()] } }}
                            filters={{ type: 'bot', members: { $in: [getStreamChatIdentifier() || ''] }, teams: { $in: [getChatTeam()] } }}
                            sort={{ last_message_at: -1 }}
                            onChannelUpdated={(e) => { console.log(e); }}
                            options={options}
                            channelRenderFilterFn={(channels) => { return channels.filter((data) => data.type === "bot") }}
                            Preview={ChannelPreview}
                            setActiveChannelOnMount={false}
                            showChannelSearch={isMenConstract ? false : true}
                            additionalChannelSearchProps={{
                                searchFunction: (params, event) => {
                                    return customSearch(params, event, chatClient);
                                },
                                onSelectResult: async (params, result) => {
                                    setActiveChannel(result)
                                    let isRemoved = await result?.data?.isRemoved || [];
                                    if (isRemoved.includes(chatClient.userID)) {
                                        await isRemoved.splice(isRemoved.indexOf(chatClient.userID), 1);
                                    }
                                    await result.updatePartial({
                                        set: {
                                            isRemoved: isRemoved
                                        }
                                    });
                                    result.watch();
                                },
                            }}
                            EmptyStateIndicator={() => <></>}
                        />
                    </div>
                    {pinChannels && pinChannels.length > 0 ?
                        isMenConstract ?
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%', borderBottom: '2px solid #F3F3F3', marginTop: '10px' }} >
                                <ThirdTitle>
                                    Pin
                                </ThirdTitle>
                            </div>
                            :
                            <RowChannelKind style={{ "width": "100%", height: "auto", margin: "0px", padding: "12px 20px 0 20px", backgroundColor: "#fff", }} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12 }} >
                                    <Icon.Pin size={'16px'}/>
                                    <ThirdTitle>Pin</ThirdTitle>
                                </div>
                            </RowChannelKind>
                        : null}
                    {pinChannels && pinChannels.length > 0 && (
                        <div className={isMenConstract ? "menu-contract" : ''} >
                            <ChannelList
                                // filters={{ pinUser: { $eq: [chatClient.userID] }, members: { $in: [chatClient.userID] } }}
                                filters={{ pinUser: { $eq: [chatClient.userID] }, teams: { $in: [getChatTeam()] }, members: { $in: [chatClient.userID] } }}
                                sort={{ last_message_at: -1 }}
                                channelRenderFilterFn={(channels) => {
                                    return channels?.filter((data) => {
                                        return data.data.pinUser?.includes(chatClient.userID) && (data.data.isArchive === null || data.data.isArchive === undefined || data.data.isArchive === false) && (data?.data?.isRemoved === null || data?.data?.isRemoved === undefined || data?.data?.isRemoved?.every(i => i != chatClient?.userID))
                                    })
                                }}
                                options={options}
                                onChannelUpdated={onChannelUpdated}
                                Preview={ChannelPreview}
                                setActiveChannelOnMount={false}
                                EmptyStateIndicator={() => null}
                            />
                        </div>
                    )}
                    {/* <div style={{ margin: '7px', display: 'inline-grid', backgroundColor: 'white', borderRadius: '7px', padding: '5px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', justifyContent: 'center' }} > */}
                    {isMenConstract ?
                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%', cursor: 'pointer', borderBottom: '2px solid #F3F3F3', marginTop: '10px' }} onClick={() => setGroupExpand(!group_expand)}>
                            <ThirdTitle>
                                Groups
                            </ThirdTitle>
                            {group_expand ?
                                <Icon.ArrowDown size={"12px"} />
                                :
                                <Icon.ArrowUp size={"12px"} />
                            }
                        </div>
                        :
                        <RowChannelKind style={{ "width": "100%", height: "auto", margin: "0px", padding: "12px 20px 3px 20px", backgroundColor: "#fff" }} className="">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12 }} onClick={() => setGroupExpand(!group_expand)} >
                                <Icon.GroupFilled size={'16px'}/>
                                <ThirdTitle>
                                    Groups
                                </ThirdTitle>
                                {group_expand ?
                                    <Icon.ArrowDown size={"16px"} />
                                    :
                                    <Icon.ArrowUp size={"16px"} />
                                }
                            </div>
                            <Row>
                                {group_expand && groupCounter > 0 && (
                                    <Notification
                                        width={'20px'}
                                        height={'20px'}
                                        fontSize={'10px'}
                                        style={{ marginRight: '5px' }}
                                    >
                                        {groupCounter}</Notification>
                                )}
                                {(getAllowToChatStream() === "0" || getAllowToChatStream() === 0) ? null
                                    :
                                    <SmallRoundButton
                                        onClick={() => {
                                            setDirectMessageModalOPen(false)
                                            setGroupAddModalOPen(true)
                                        }}
                                    >
                                        <Icon.Plus />
                                    </SmallRoundButton>
                                }
                            </Row>
                        </RowChannelKind>
                    }
                    <div className={`group_channel_list ${group_expand ? `collapse` : ''}`}>
                        <div className={isMenConstract ? "menu-contract" : ''} >
                            {/* filters={{ type: "group", teams: { $in: [getChatTeam()] }, members: { $in: [getStreamChatIdentifier()] } }} */}
                            <ChannelList
                                filters={{ type: "group", members: { $in: [getStreamChatIdentifier()] }, teams: { $in: [getChatTeam()] } }}
                                sort={{ last_message_at: -1 }}
                                channelRenderFilterFn={(channels) => { return channels.filter((data) => data.type === "group" && (data?.data?.isArchive === null || data?.data?.isArchive === undefined || data.data.isArchive === false) && (data?.data?.pinUser === null || data?.data?.pinUser === undefined || data?.data?.pinUser?.every(i => i != chatClient?.userID))) }}
                                // additionalChannelSearchProps={{
                                //     searchFunction: (params, event) => {
                                //         return customSearchGroup(params, event, chatClient);
                                //     },
                                // }}
                                options={options}
                                // showChannelSearch
                                Preview={ChannelPreview}
                                onChannelUpdated={onChannelUpdated}
                                setActiveChannelOnMount={false}
                                EmptyStateIndicator={() => null}
                            // Paginator={Paginator}
                            />
                        </div>
                    </div>
                    {isMenConstract ?
                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%', cursor: 'pointer', borderBottom: '2px solid #F3F3F3', marginTop: '10px' }}
                            onClick={() => setDuoExpand(!duo_expand)}>
                            <ThirdTitle>
                                Direct
                            </ThirdTitle>
                            {duo_expand ?
                                <Icon.ArrowDown size={"12px"} />
                                :
                                <Icon.ArrowUp size={"12px"} />
                            }
                        </div>
                        :
                        <RowChannelKind style={{ "width": "100%", height: "auto", margin: "0px", padding: "12px 20px 3px 20px", backgroundColor: "#fff" }} className="">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12 }} onClick={() => setDuoExpand(!duo_expand)} >
                                <Icon.ChatFilled size={'16px'}/>
                                <ThirdTitle>
                                    Direct Messages
                                </ThirdTitle>
                                {duo_expand ?
                                    <Icon.ArrowDown size={"16px"} />
                                    :
                                    <Icon.ArrowUp size={"16px"} />
                                }
                            </div>
                            <Row>
                                {duo_expand && duoCounter > 0 && (
                                    <Notification
                                        width={'20px'}
                                        height={'20px'}
                                        fontSize={'10px'}
                                        style={{ marginRight: '5px' }}
                                    >
                                        {duoCounter}</Notification>
                                )}
                                {(getAllowToChatStream() === "0" || getAllowToChatStream() === 0) ?
                                    null :
                                    <SmallRoundButton
                                        onClick={() => {
                                            setGroupAddModalOPen(false)
                                            setDirectMessageModalOPen(true)
                                        }}
                                    >
                                        <Icon.Plus size={"30px"} />
                                    </SmallRoundButton>
                                }
                            </Row>
                        </RowChannelKind>
                    }
                    <div className={`normal_channel_list ${duo_expand ? `collapse` : ''}`}>
                        <div className={isMenConstract ? "menu-contract" : ''} >
                            {/* type: 'duo', teams: { $in: [getChatTeam()] }, members: { $in: [getStreamChatIdentifier()] } */}
                            <ChannelList
                                filters={{
                                    type: 'duo', members: { $in: [getStreamChatIdentifier()] }, teams: { $in: [getChatTeam()] }
                                }}
                                sort={{ last_message_at: -1 }}
                                // Paginator={InfiniteScroll}
                                Paginator={Paginator}
                                options={options}
                                // ChannelSearch={(channels) => { console.log(channels);
                                //     return true;
                                //     // return channels.filter((data) => (data.type === "duo" )) }
                                // }}
                                // additionalChannelSearchProps={{
                                //     searchFunction: (params, event) => {
                                //         return customSearchDuo(params, event, chatClient);
                                //     },
                                // }}
                                // showChannelSearch
                                channelRenderFilterFn={(channels) => {
                                    return channels.filter((data) => (data.type === "duo" && (data?.data?.isArchive === null || data?.data?.isArchive === undefined || data.data.isArchive === false) && (data?.data?.isRemoved === null || data?.data?.isRemoved === undefined || data?.data?.isRemoved?.every(i => i != chatClient?.userID))) && (data?.data?.pinUser === null || data?.data?.pinUser === undefined || data?.data?.pinUser?.every(i => i != chatClient?.userID)))
                                }}
                                // onAddedToChannel={onCreateDuoChannel}
                                Preview={ChannelPreview}
                                onChannelUpdated={onChannelUpdated}
                                setActiveChannelOnMount={false}
                                EmptyStateIndicator={() => null}
                            />
                        </div>
                    </div>
                </div>

                {isDirectMessageModalOpen ?
                    <NewDirectMessageAdd isMenConstract={isMenConstract} isPage={isPage} isDirectMessageModalOpen={isDirectMessageModalOpen} setDirectMessageModalOPen={setDirectMessageModalOPen} />
                    :
                    isGroupAddModalOpen ?
                        <NewGroupChannelAdd isMenConstract={isMenConstract} isPage={isPage} isGroupAddModalOpen={isGroupAddModalOpen} setGroupAddModalOPen={setGroupAddModalOPen} />
                        :
                        isUpdateModalOpen ?
                            <UpdateMembers channel={channel} isMenConstract={isMenConstract} isPage={isPage} isUpdateModalOpen={isUpdateModalOpen} setUpdateModalOPen={setUpdateModalOPen} />
                            :
                            <div style={{ width: isMenConstract && isPage ? '95%' : isMenConstract && !isPage ? '91%' : isPage ? '82%' : "65%", float: "left", backgroundColor: '#F5F6FA', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }} >
                                {channel?.type === 'initial_channel' ?
                                    <Channel channel={channel} MessageSystem={CustomSystemMessage}>
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F8F8F8', flexDirection: 'column', }} >
                                            <img src={require('../assets/chatIcon.png')} height={'85px'} width={'85px'} />
                                            <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px' }}>
                                                <Text
                                                    color={"#0071BC"}
                                                    textAlign={"center"}
                                                    fontSize={'22px'}
                                                    lineHeight={'unset'}
                                                    fontFamily={'circe-rounded, serif !important'}
                                                    fontWeight={'bold'}
                                                >
                                                    No Conversation Selected</Text>
                                                <Text
                                                    // color={"#3a3a3a"}
                                                    textAlign={"center"}
                                                    fontSize={'15px'}
                                                    lineHeight={'unset'}
                                                    margin={'15px'}
                                                    width={'85%'}
                                                    fontFamily={'circe-rounded, serif !important'}
                                                >
                                                    Communicate everything from one place with our integrated, SMS-capable chat engine. Create groups. It even has a swear-bot!</Text>

                                            </div>
                                        </div>
                                    </Channel>
                                    :
                                    <Channel
                                        MessageSystem={CustomSystemMessage}
                                        channel={channel}
                                        TypingIndicator={() => <TypingIndicator avatarSize={30} />}
                                        EmojiPicker={CustomEmojiPicker}
                                        // Avatar={({ image }) => {
                                        //     return (
                                        //         <Avatar image={image} size={25} />
                                        //     )
                                        // }}
                                        ThreadHeader={CustomThreadHeader}
                                        DateSeparator={(props) => {
                                            return (
                                                <div className="str-chat__date-separator">
                                                    <hr className="str-chat__date-separator-line" />
                                                    <div className="str-chat__date-separator-date">{moment(props.date).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })}</div>
                                                    <hr className="str-chat__date-separator-line" />
                                                </div>

                                            )
                                        }}
                                        EmptyStateIndicator={() => {
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }} >
                                                    {channel?.type === 'bot' ?
                                                        <Text fontSize={"18px"} color={'gray'} >Hooray! You have no unfinished tasks.</Text>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            )
                                        }}
                                    >
                                        <Window hideOnThread={true}>
                                            <ChannelHeader
                                                // RightContent={() => (<RightArrowButton onPress={pinChannel} />)}
                                                // MuteContent={() => (<MuteButton onPress={muteChannel} />)}
                                                setShowChannelDetail={setShowChannelDetail}
                                                setShowCreatePoll={setShowCreatePoll}
                                                setShowPoll={setShowPoll}
                                            />
                                            <MessageList
                                                hideDeletedMessages={channel?.type === 'bot' ? true : false}
                                                hideNewMessageSeparator={false}
                                                disableDateSeparator={false}
                                                Message={channel?.type === 'bot' ? CustomeMessageBot : CustomeMessage}
                                                returnAllReadData={true}
                                                noGroupByUser={true}
                                            />
                                            {isStickerModalVisible && (
                                                <GiphyModal activeGiphyType={activeGiphyType} setActiveGiphyType={setActiveGiphyType} channel={channel} setStickerModalVisible={setStickerModalVisible} isPage={isPage} />
                                            )}
                                            {(isDisableOnImpersonate) || (channel?.type === "bot") || (getAllowToChatStream() === "0" || getAllowToChatStream() === 0) || (channel?.data?.read_only_roles?.includes(getPositionName()))
                                                ?
                                                null
                                                :
                                                channel?.data?.isArchive != true && (
                                                    // <CustomMessageInput />
                                                    <div>
                                                        <div style={{ padding: '9px 40px' }}>
                                                            <CheckBox
                                                                name={'sendSMS'}
                                                                key={`_sms_${ isSMSChecked}`}
                                                                defaultValues={[isSMSChecked]}
                                                                onClick={() => setSMSChecked(!isSMSChecked)}
                                                                disabled={getIsEnableSMS() === 'false' ? true : false}
                                                                options={[{ label: "Also send SMS", value: true }]}
                                                            />
                                                        </div>
                                                        <div style={{ position: 'relative' }} >
                                                            <div onClick={() => setStickerModalVisible(true)}
                                                                style={{ position: 'absolute', left: '78px', bottom: '22px', zIndex: 999, opacity: 0.5, cursor: 'pointer' }}>
                                                                <Icon.Sticker/>
                                                            </div>
                                                            <MessageInput
                                                                focus={true}
                                                                doFileUploadRequest={async (file, channel) => {
                                                                    let formData = new FormData();
                                                                    formData.append('image', file);
                                                                    formData.append('name', file.name);
                                                                    let result = await upload(formData,true);
                                                                    return { file: result.data?.ObjectURL };
                                                                }}
                                                                doImageUploadRequest={async (file, channel) => {
                                                                    let formData = new FormData();
                                                                    formData.append('image', file);
                                                                    formData.append('name', file.name);
                                                                    let result = await upload(formData,true);
                                                                    return { file: result.data?.ObjectURL };
                                                                }}
                                                                overrideSubmitHandler={overrideSubmitHandler}
                                                                grow={true}
                                                                maxRows={20}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            {openReviewModal && (
                                                <ReviewModalView isOpen={openReviewModal} isPage={isPage} setOPenReviewModal={setOPenReviewModal} currentMessage={currentMessage} reviewType={reviewType} />
                                            )}
                                        </Window>
                                        <Thread
                                            Input={() => {
                                                return (
                                                    <div>
                                                        {(isDisableOnImpersonate) || (channel?.type === "bot") || (getAllowToChatStream() === "0" || getAllowToChatStream() === 0) || (channel?.data?.read_only_roles?.includes(getPositionName()))
                                                            ?
                                                            null
                                                            :
                                                            channel?.data?.isArchive != true && (<>
                                                                <div style={{ padding: '9px 40px' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={isChecked}
                                                                        className={'checkbox'}
                                                                        onChange={() => setChecked(!isChecked)}
                                                                    />
                                                                    <label className={'sendSMS'} htmlFor={`sendSMS`}>Also send to channel</label>
                                                                </div>
                                                                <MessageInput overrideSubmitHandler={sendThreadMessage} focus={true} grow={true} maxRows={20} />
                                                            </>)}
                                                    </div>
                                                )
                                            }}
                                        />

                                        <ChannelDetails
                                            isShowChannelDetail={isShowChannelDetail}
                                            setShowChannelDetail={setShowChannelDetail}
                                            channel={channel}
                                            isPage={isPage}
                                            setOpenPinnedList={setOpenPinnedList}
                                            setUpdateModalOPen={setUpdateModalOPen}
                                            setShowCreatePoll={setShowCreatePoll}
                                            setShowPoll={setShowPoll}
                                            allowGroupActions={allowGroupActions}
                                            isDisableOnImpersonate={isDisableOnImpersonate}
                                        />
                                        <PinnedMessageList isOpenPinnedList={isOpenPinnedList} setOpenPinnedList={setOpenPinnedList} isPage={isPage} />
                                        <CreatePoll
                                            isShowCreatePoll={isShowCreatePoll}
                                            setShowCreatePoll={setShowCreatePoll}
                                            channel={channel}
                                            isPage={isPage}
                                        />
                                        <PollDetails
                                            isShowPoll={isShowPoll}
                                            setShowPoll={setShowPoll}
                                            channel={channel}
                                            isPage={isPage}
                                            message={currentMessage}
                                        />
                                    </Channel>
                                }
                            </div>
                }

                {isVisible ?
                    <ModalWindow
                        width={"auto"}
                        cancelModal={() => setVisible(false)}
                        title={"Message Info"}
                        minHeight={'unset'}
                    >   
                        <MessageInfoDateTimeContent>
                            <Channel channel={channel}><Message formatDate={(date)=>moment(date).calendar(null,{
                                    lastDay : '[Yesterday] h:mm a',
                                    sameDay : '[Today] h:mm a',
                                    sameElse : 'MM/DD/yyyy h:mm a'
                                })} message={currentMessage} messageActions={[]} /></Channel>
                            <hr></hr>
                        </MessageInfoDateTimeContent>
                        < Content style={{ maxHeight: "500px", overflowY: 'auto', marginRight: '-15px' }} >
                            {readUsers && readUsers.length > 0 && <>
                                <Text color={"#516F90"} fontSize={"16px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                                   Read By
                                </Text>
                                {readUsers.map((item, index) => {
                                    return (
                                        <div style={{ display: 'flex', width: "max-content", gap: "5px", alignItems: 'center' }} >
                                            <Driver img={item.image} />
                                            <Text lineHeight={"unset"} height={"15px"}>{item.name}</Text>
                                        </div>
                                    )
                                })}
                            </>
                            }                          

                            {unreadUsers && unreadUsers.length > 0 && <>
                                <Text color={"#516F90"} fontSize={"16px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                                    Delivered
                                </Text>
                                {unreadUsers.map((item, index) => {
                                    return (
                                        <div style={{ display: 'flex', width: "max-content", gap: "5px", alignItems: 'center' }} >
                                            <Driver img={item.image} />
                                            <Text lineHeight={"unset"} height={"15px"}>{item.name}</Text>
                                        </div>
                                    )
                                })}
                            </>
                            }
                        </Content >
                    </ModalWindow >
                    : null}

            </>
        )
    }
    return <></>;
}

export const CustomEmojiPicker = () => {
    const { emojiConfig } = useEmojiContext();
    const { onSelectEmoji } = useMessageInputContext();


    const { emojiData } = emojiConfig || {};

    // delete ban emoji
    delete emojiData.emojis.middle_finger;

    return (
        <div className='wrapper'>
            <Suspense fallback={null} >
                <Picker onClick={onSelectEmoji} data={emojiData} />
            </Suspense>
        </div>
    );
};

const ChatSidebar: React.FC = ({ isPage }) => {
    const dispatch = useDispatch();
    const state = useTrackedState();
    const { initialChannelsReady, showFilterForWords, isOpen } = state;

    if (!initialChannelsReady || !isOpen) {
        return null;
    }

    return (
        <>
            <ChatSidebarContainer width={isPage ? '100%' : '1000px'} boxShadow={isPage ? 'none' : ' 0 3px 6px #00000029'} bottom={isPage ? 'none' : '5px'} right={isPage ? 'none' : '5px'} >
                <ChannelsScrollContainer>
                    <NewChat isPage={isPage} />
                    {/* <RowChannelKind>
                        <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                            Direct Messages
                        </Text>
                        <Row>
                            <ReturnButton
                                width={"30px"}
                                height={"30px"}
                                borderRadius={"50%"}
                                onClick={() => dispatch({type: "OPEN_DIRECT_MESSAGE_MODAL"})}
                            >
                                <Icon.Plus size={"24px"}/>
                            </ReturnButton>
                        </Row>
                    </RowChannelKind>
                    <DuoChannels/> */}
                    {/* <BotChannels/>
                    <PinChannels/>
                    <RowChannelKind>
                        <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                            Groups
                        </Text>
                        <Row>
                            <ReturnButton
                                width={"30px"}
                                height={"30px"}
                                borderRadius={"50%"}
                                onClick={() => dispatch({type: "OPEN_NEW_CHANNEL_MODAL"})}
                            >
                                <Icon.Plus size={"24px"}/>
                            </ReturnButton>
                        </Row>
                    </RowChannelKind>
                    <GroupChannels/>
                    <RowChannelKind>
                        <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                            Direct Messages
                        </Text>
                        <Row>
                            <ReturnButton
                                width={"30px"}
                                height={"30px"}
                                borderRadius={"50%"}
                                onClick={() => dispatch({type: "OPEN_DIRECT_MESSAGE_MODAL"})}
                            >
                                <Icon.Plus size={"24px"}/>
                            </ReturnButton>
                        </Row>
                    </RowChannelKind>
                    <DuoChannels/> */}
                </ChannelsScrollContainer>
            </ChatSidebarContainer>
        </>
    );


}

const ChannelContainer = styled.div`
    display: grid;
    width: unset;
    height: 56px;
    grid-template-columns: 40px auto 20px 12px;
    grid-column-gap: 6px;
    padding-top: 8px;
    padding-Bottom: 8px;
    align-items: center;
    border-Bottom: 2px solid #F3F3F3;
    margin-left: 16px;
    margin-right: 16px;
    &:hover {
        
    }
`;

const ChannelContainerMenu = styled.div`
    display: flex;
    width: unset;
    height: 56px;
    align-items: center;
    border-Bottom: 2px solid #F3F3F3;
    justify-content: center;
`;
// @ts-ignore
const ArrowRightButton = styled(Button)`
    width: 12px;
    height: 12px;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    &:active, &:focus, &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071BC;
        border-radius: 2px;
    }
`;

const TextInformationContainer = styled.div`
    display: grid;
    height: 32px;
    grid-template-columns: auto;
    grid-template-rows: 18px 14px;
    cursor: pointer;
`;

const Notification = styled.div`
    width: ${props => (props.width ? props.width : "20px")};
    height: ${props => (props.height ? props.height : "20px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    text-align: center;
    background-color: ${props => (props.bgColor ? props.bgColor : "#d94c53")};
    font-size: ${props => (props.fontSize ? props.fontSize : '10px')};
    color: white;
    cursor: pointer;
`;

const UnreadContainer = styled.div`
background-color: #d94c53;
display: flex;
height: 18px;
width: 18px;
border-radius: 50%;
border: 2px solid white;
position: relative;
top: -15px;
left: -22px;
min-width: 18px;
justify-content: center;
`

const ReviewButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0 0 8px;
    width: 100%;
    border-radious:20px;
`;

export const BotButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0 0 8px;
    width: unset;
    border-radius:20px;
    background-color: #ffffff;
    color:#F7931F;
    box-shadow:rgba(100, 100, 111, 0.2) 0px 1px 13px 0px !important;
    font-weight:normal;
    font-family: circe-rounded, serif !important;
    &:hover{
        background-color: #F7931F;
        color:#ffffff;
        box-shadow: rgba(247, 147, 31, 0.65) 0px 1px 13px 0px !important;
        font-weight:500
      }
`;

const Input = styled.textarea`
    // border: 1px solid #CCCCCC !important;
    // border-radius: 5px;
    opacity: 1;
    width: 300px;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 2px;
    position: relative;
    height:36px;

    &:hover, &:hover, &:focus, &:active {
        // border: 1px solid #CCCCCC;
    }

    .autocomplete {
        color: silver;
        z-index: 1;
    }
`;

export default ChatSidebar;