import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Button,
    Input,
    Spacer,
    ThirdTitle,
    Uploader,
    RadioBox,
    Dropdown,
    CheckBox,
    Alert,
    Icon,
    Text
} from "@dspworkplace/ui";
import { useForm } from "react-hook-form";

import Loading, { useLoading } from "../../../components/Loading";
import Modal, { useModal } from "../../../components/Modal";
import { getCompanyId, useAuth, baseUrl, upload } from "../../../Auth";
import { csvExtention } from "../../../patterns";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;
`;

const LoadingWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    > img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const App = ({ action, csvVisible, toggleCsv }) => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, getValues, reset, setError, clearError } = useForm({ defaultValues: {} });
    const [isLoading, setLoading] = useLoading(false);
    const [log, setLog] = useState("");
    const [success, setSuccess] = useState(false);
    const { profile } = getValues();

    const onSubmit = async data => {
        if (data.profile.length > 0) {
            setLoading(true);

            let formData = new FormData();
            formData.append('image', data.profile[0]);
            formData.append('name', data.profile[0].name);
            formData.append('csvUpload', true);
            let result = await upload(formData).catch(err => {
                console.log({err})
            });

            const FormDataParams = new FormData();
            FormDataParams.append("filePath", result.data.ObjectURL);
            FormDataParams.append("company", localStorage.getItem("company"));
            FormDataParams.append("user", localStorage.getItem("userId"));

            let response = await api.post("/api/vehicle/csv/upload", FormDataParams);
            if (response.data.message) {
                setLog(response.data.message);
                if (response.data.message == "") {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            } else {
                setLog("");
                setSuccess(true);
            }
            setLoading(false);
        }
    };

    const errorMessages = {
        profile: {
            required: "You need to provide an csv",
            pattern: "select file format is not valid"
        }
    };

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([log], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = "log.txt";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Modal
            title={"Import Vehicle CSV"}
            width="506px"
            visible={csvVisible}
            setVisible={() => {
                toggleCsv();
            }}
            zindex="40"
        >
            <Container>
                {isLoading && (
                    <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                )}
                <form name="new_employee" onSubmit={handleSubmit(onSubmit)}>
                    {success === true && (
                        <Alert.Success title="Success" content="Csv record add/updated successfully." />
                    )}
                    <Spacer top={4} />
                    <ThirdTitle style={{ fontWeight: "normal" }}>Select CSV File</ThirdTitle>
                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <input
                            type="file"
                            name="profile"
                            ref={register({
                                required: true,
                                pattern: csvExtention
                            })}
                            error={errors.profile && errorMessages.profile[errors.profile.type]}
                            valid={!errors.profile && profile && profile.length > 0}
                        />
                        <Text style={{color:"red"}}>{errors.profile && errorMessages.profile[errors.profile.type]}</Text>
                    </SpacerInline>
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <Text style={{ fontWeight: "normal" }}>
                            Example CSV
                            <a style={{ marginLeft: "10px" }} href={"https://s3.amazonaws.com/dsp.data.storage.general/Vehicle_template.xlsx"}>
                                <Icon.Download size="22px" />
                            </a>
                        </Text>
                    </SpacerInline>
                    <Spacer top={4} />
                    {log !== "" && (
                        <>
                            <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={2}>
                                <Text style={{ color: "red" }}>
                                    Please check log file for errors
                                    <span style={{ color: "blue", cursor: "pointer" }} onClick={downloadTxtFile}>
                                        {" "}
                                        Click Here
                                    </span>
                                </Text>
                            </SpacerInline>
                            <Spacer top={4} />
                        </>
                    )}

                    <Button type="primary" style={{ float: "right" }} Type="submit">
                        Import
                    </Button>
                </form>
            </Container>
        </Modal>
    );
};

export default App;
