import React, {useCallback} from 'react';
import {Controller, Control} from 'react-hook-form'
import {Combobox} from "./index";
import {ComboboxProps} from "./combobox";

type ComboboxPropWithoutInitialValue = Omit<ComboboxProps, "initialValue">

export interface FormComboboxProps extends ComboboxPropWithoutInitialValue{
    name: string,
    control?: Control
    rules?: Object
    defaultValue: unknown
}

const FormCombobox = ({name,control,...comboboxProps}:FormComboboxProps) => {
    const {onChange: comboboxOnChange} = comboboxProps;

    return (
        <Controller
            name={name}
            control={control}
            rules={comboboxProps.rules}
            defaultValue={comboboxProps.defaultValue}
            as={({onChange,value})=>{
                return <ControllerRender comboboxProps={comboboxProps} onChange={onChange} comboboxOnChange={comboboxOnChange} value={value} />
            }}
        />
    )
}

const ControllerRender = ({comboboxProps={},comboboxOnChange,onChange,value}) => {
    const handleChange = useCallback((row) => {
        onChange(row)
        if(comboboxOnChange) comboboxOnChange(row)
    },[comboboxOnChange, onChange])

    return <Combobox
        {...comboboxProps}
        initialValue={typeof value === 'object' ? value?.value : value }
        onChange={handleChange}
    />
}

export default FormCombobox