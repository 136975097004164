import {uploadToMedia} from "../../Auth";

const uploadMedia = async(fileObj,type,fileId,entityId) => {
    let formData = new FormData();
    for(let i = 0; i<= fileObj.length -1;++i){
        formData.append('file[]',fileObj[i]);
    }

    formData.append('type',type); //should have the exactly name of the entity that this file represents.
    if(entityId) formData.append('entityId',entityId)

    if(fileId && fileId.length > 0){
        for(let i = 0; i<= fileId.length -1;++i){
            formData.append('id[]',fileId[i]);
        }
    }

    let result = await uploadToMedia(formData).catch(err => {
        console.log({err})
    });
    return result.data;
};

export {uploadMedia};