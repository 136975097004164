import Filter from '../../components/Filter';
import {formatDevices, sortBy} from "./util";

const filterDefinition = {
    station: 'object',
    search: 'multiString',
    isPersonalDevice: (value, filter) => {
        if (filter == '1') return value === false;
        if (filter == '2') return value === true;
        return true;
    }
}

const DevicesReducer = (state, action) => {
    var {type, payload, callback} = action;
    let sorted;
    switch (type) {
        case 'SET_SORT_BY':
            let index = state.sortBy.findIndex(criteria => criteria.field === payload);
            if (index >= 0) {
                let criteria = state.sortBy[index];
                if (criteria.direction === 0)
                    criteria.direction = 1;
                else if (criteria.direction === 1)
                    state.sortBy.splice(index, 1);
            } else {
                state.sortBy.push({
                    field: payload,
                    direction: 0
                });
            }
            sorted = sortBy(state.devices, state.sortBy);
            const deviceFiltered = new Filter(filterDefinition, state.filter, sorted);
            return {...state, list: deviceFiltered, loading: false};
        case 'CREATE':
            let initialDevices = formatDevices(action.devices)
            sorted = sortBy(initialDevices, state.sortBy);
            const devicesFiltered = new Filter(filterDefinition, state.filter, sorted);
            return {
                ...state,
                devices: initialDevices,
                list: devicesFiltered,
                loading: false,
                androidVersion: action.androidVersion,
                iosVersion: action.iosVersion
            };
        case 'ADD':
            let add = [...state.devices, action.device];
            add = formatDevices(add);
            sorted = sortBy(add, state.sortBy);
            const filteredAdd = new Filter(filterDefinition, state.filter, sorted);
            return {...state, devices: add, list: filteredAdd, loading: false};
        case 'UPDATE':
            const indx = state.devices.findIndex((d) => {
                return d.id == action.device.id;
            })
            if (indx >= 0) {
                state.devices[indx] = action.device;
            }

            sorted = sortBy(state.devices, state.sortBy);
            const f = new Filter(filterDefinition, state.filter, sorted);

            //state.list[payload.key].allowLoadOutProcess = action.device.allowLoadOutProcess;

            // sorted = sortBy( state.devices, state.sortBy );

            return {...state, list: f, loading: false};

        case 'DELETE':

            const deleteIndx = state.devices.findIndex((d) => {
                return d.id == action.device.id;
            })

            if (deleteIndx >= 0) {
                state.devices.splice(deleteIndx, 1);
            }

            sorted = sortBy(state.devices, state.sortBy);
            const filteredDeleted = new Filter(filterDefinition, state.filter, sorted);

            return {...state, devices: state.devices, list: filteredDeleted, loading: false};
        case 'SET_LOADING':
            return {...state, loading: true};
        case 'SAVED_FILTER':
            return {...state, savedFilterList: action.savedFilterList}
        case 'SEARCH':
            sorted = sortBy(state.devices, state.sortBy);
            const filtered = new Filter(filterDefinition, payload, sorted);

            /*let filtered = payload.search ? sorted.filter( d => {
                for ( const property in d )
                    if( d.hasOwnProperty(property) && property !== 'id' )
                        if ( d[property] )
                            if ( d[property].toString().match( new RegExp( payload.search, 'ig' ) ) !== null )
                                return true;

                return false;
            }) : sorted;

            filtered = payload.station && payload.station != '-1' ? filtered.filter( d => {
                return d.station.id == payload.station;
            }) : filtered;

            if(payload.type && payload.type != 3 ){
                filtered = filtered.filter( d => {
                    if(payload.type === 1) return d.isPersonalDevice === false;
                    if(payload.type === 2) return d.isPersonalDevice === true;
                    return false;
                }) ;
            }*/
            return {...state, list: filtered, filter: payload, loading: false};

        default:
            return state;
    }
};

export default DevicesReducer;
