import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {
  Icon, Theme, ThirdTitle, Button, Spacer, Input
} from "@dspworkplace/ui";

const FilterBottom = ({
  onApplyClick = () => { },
  onSaveClick = () => { },
  onFilterSelect = (_val) => { },
  appliedFilter = '',
  filterList = []
}) => {

  const FilterName = styled.div`
        margin-left: 5px;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        cursor: pointer;
        word-break: break-all;
        overflow: hidden;
        color: #707070;
    `;

  const FilterListBoxContainer = styled.div`
        border: 1px solid #CCCCCC;
        border-radius: 2px;
        margin: 5px 0px;
        overflow-y: scroll;
        max-height: 90px;
    `

  const FilterListBox = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        margin: 0 5px;
        border-bottom: 1px dashed #cbd6e2;
        &:last-child {
            border-bottom: 0px;
        }
    `

  const RadioLabel = styled.div`
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
    `

  const RadioButton = styled.div`
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        height: 17px;
        width: 17px;
        border-radius: 17px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    `

  const [isSave, setIsSave] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const applyClicked = () => {
    onApplyClick && onApplyClick();
  }

  const saveFilterClicked = () => {
    setIsSave(true)
    onSaveClick && onSaveClick();
  }

  const handleSavedItemClick = (item) => {
    setSelectedItem(item?.name)
    onFilterSelect && onFilterSelect(item)
  }

  useEffect(() => {
    setSelectedItem(appliedFilter)
  }, [appliedFilter])

  return (
    <>
      {filterList?.length > 0 ?
        <>
          <Spacer top={2} />
          <ThirdTitle>Saved Filters</ThirdTitle>
          <FilterListBoxContainer>
            {filterList?.map(item => {
              return (
                <>
                  <FilterListBox>
                    <div
                      style={{ width: '90%', display: "flex", alignItems: "center" }}
                      onClick={() => handleSavedItemClick(item)}
                    >
                      <RadioLabel>
                        <RadioButton>
                          <div style={{ width: '17px' }}>
                            <Icon.Check
                              size={'17px'}
                              style={{
                                cursor: 'pointer',
                                marginTop: '3px',
                                display: selectedItem === item?.name ? '' : 'none'
                              }}
                              color={Theme.colors.secondary}
                              onClick={() => {
                              }}
                            />
                          </div>
                        </RadioButton>
                        <FilterName>{item.name}</FilterName>
                      </RadioLabel>
                    </div>
                    <Icon.Trash
                      size={'20px'}
                      style={{ cursor: 'pointer', marginBottom: 5 }}
                      color={Theme.colors.error.border}
                      onClick={() => {
                      }}
                    />
                  </FilterListBox>
                </>
              )
            })}
          </FilterListBoxContainer>
        </> : null}
      <div>
        <Button type='primary' style={{ width: '100%' }} onClick={applyClicked}>Apply filters</Button>
        <Spacer bottom={2} />
      </div>
      {/* Need below code */}
      {/* {isSave ?
        <div>
          <Input
            autoFocus={true}
            size={'small'}
          />
          <Spacer inline right={2} />
          <Button type="save" onClick={() => { setIsSave(false) }}>
            Save
          </Button>
          <Button type="cancel" onClick={() => { setIsSave(false) }}>
            Cancel
          </Button>
          <Spacer bottom={2} />
        </div> :
        <div>
          <Button type='primary' style={{ width: '100%' }} onClick={saveFilterClicked}>Save Applied filters</Button>
          <Spacer bottom={2} />
        </div>} */}
    </>
  )

}

export default FilterBottom;