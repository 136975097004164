import React, {useState} from 'react';
import {dialogPromise} from "../../../components/Dialog";
import Modal from "../../../components/Modal";
import { Alert, Button, Input, Spacer } from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import API from '../api';

const PanelForm = ({success,cancel,idPanel,panelName,pageReference,params}) => {
    const [state,setState] = useState({status:'pending',panel:{}});
    const {register,handleSubmit,errors} = useForm({defaultValues:{name:panelName ? panelName : ''}});

    const onSubmit = (data) => {
        setState({...state,status:'submitting'});
        API.addPanel({id:idPanel,name:data.name,pageReference:pageReference}).then(
            resolve => {
                if(resolve){
                    const data = resolve.data.data.lastInserted;
                    success(data);
                }
            },
            error => {
                setState({...state,status:'CREATE_ERROR'})
            }
        )
    }

    return (
        <Modal
            width={'340px'}
            visible={true}
            title={idPanel ? `Edit ${params?.title || 'Panel'}` : `Add ${params?.title || 'Panel'}`}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    name='name'
                    label='Name'
                    ref={register(({required:'Field Required'}))}
                    error={errors.name && errors.name.message}
                    Required
                />
                {state.status === 'CREATE_ERROR' &&
                    <Spacer top={5}>
                        <Alert.Error
                            title={`Unable to save the panel`}
                            content={'Try to refresh the page, if the problem persist please contact support.'} />
                    </Spacer>
                }
                <Spacer top={5} style={{display:'flex',justifyContent:'flex-end'}}>
                    <Button
                        type={'primary'}
                        Type={'submit'}
                        disabled={state.status === 'submitting'}
                    >
                        {state.status === 'submitting' ? 'Saving Panel' : 'Save Panel'}
                    </Button>
                </Spacer>
            </form>
        </Modal>
    )
}

export const openPanelForm = (idPanel,panelName,pageReference,params) => {
    return dialogPromise((success, cancel)=>{
        return <PanelForm
            success={success}
            cancel={cancel}
            idPanel={idPanel}
            panelName={panelName}
            pageReference={pageReference}
            params={params}
        />
    })
}