import React, {useEffect} from "react";
import styled from "styled-components";
import {Dropdown, Spacer, SubTitle, Text, Theme, ThirdTitle, Title} from "@dspworkplace/ui";
import {ScorecardProvider} from "../context";
import StationSelector from "../../../components/StationSelector";
import {Card, Divider, GridColumns, Label, Link} from "../../../components/UI";
import {getSelectedStationCode} from "../../../Utilities";
import DatePicker from "../../../components/Calendar/picker";
import moment from "moment";
import {useForm} from "react-hook-form";

export default function ScorecardOverall (props)
{
    return (
        <ScorecardProvider>
            <Overall {...props}/>
        </ScorecardProvider>
    )
};

const ClickableCard = styled(Card)`
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .3s ease;
    
    &:hover {
        background: ${Theme.colors.actions};
        border-color: ${Theme.colors.secondary};
    }
`;

const Overall = () => {
    const {register} = useForm({
        defaultValues: {
            date: moment().format('YYYY-MM-DD')
        }
    })

    useEffect( () => {

    }, []);

    return <>
        <Title>
            Overall
        </Title>
        <Spacer top={3}/>
        <Text>Keep track of your DSP performance. See the station as a whole or individually by driver.</Text>
        <Spacer top={5}/>

        <form>
            <StationSelector/>
            <Spacer right={5} inline/>
            <Dropdown
                ref={register}
                label={'Range'}
                name={'range'}
                size={'small'}
                defaultValue={'week'}
                options={[
                    {name: 'Days', value: 'day'},
                    {name: 'Weeks', value: 'week'},
                    {name: 'Months', value: 'month'},
                ]}
            />
            <Spacer right={5} inline/>
            <DatePicker
                ref={register}
                label={'Date'}
                name={'date'}
                size={'small'}
            />
        </form>

        <Divider top={5} bottom={5}/>

        <SubTitle>{getSelectedStationCode()} Overall Performance</SubTitle>
        <Spacer bottom={3}/>
        <GridColumns>
            <Card>
                <GridColumns columns={2}>
                    <div>
                        <Label>Completed Routes</Label>
                        <Title>27</Title>
                    </div>
                    <div>
                        <Label>Delivered Packages</Label>
                        <Title>5,548</Title>
                    </div>
                    <div>
                        <Label>Miles</Label>
                        <Title>1,241</Title>
                    </div>
                    <div>
                        <Label>Rescued Routes</Label>
                        <Title>0</Title>
                    </div>
                </GridColumns>
            </Card>
            <Card>
                <GridColumns columns={2}>
                    <div>
                        <Label>FICO <small>avg</small></Label>
                        <Title>822</Title>
                    </div>
                    <div>
                        <Label>GreenZone Score <small>avg</small></Label>
                        <Title>956</Title>
                    </div>
                    <div>
                        <Label>Safety Events</Label>
                        <Title>41</Title>
                    </div>
                    <div>
                        <Label>Incidents</Label>
                        <Title>3</Title>
                    </div>
                </GridColumns>
            </Card>
            <Card>
                <GridColumns columns={2}>
                    <div>
                        <Label>Station Goals</Label>
                        <Title>3 / 4</Title>
                    </div>
                    <div>
                        <Label>Team Goals</Label>
                        <Title>80 / 93</Title>
                    </div>
                    <div>
                        <Label>Driver Goals</Label>
                        <Title>141 / 200</Title>
                    </div>
                    <div>
                        <Label>All-Around Driving</Label>
                        <Title>
                            <Link style={{fontSize: 'inherit'}}>7</Link>
                        </Title>
                    </div>
                </GridColumns>
            </Card>
        </GridColumns>

        <Divider top={10} bottom={10}/>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <SubTitle>Top 5 Teams</SubTitle>
            <Link>View All</Link>
        </div>
        <Spacer bottom={3}/>
        <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'auto',
            margin: -20,
            marginRight: -60,
            padding: 20,
        }}>
            {[1, 2, 3, 5, 6, 8].map(i =>
                <ClickableCard key={i} style={{
                    minWidth: 'calc(33% - 24px)',
                    marginRight: 20,
                }}>
                    <ThirdTitle>Alpha Team</ThirdTitle>
                    <Spacer bottom={3}/>
                    <GridColumns columns={3}>
                        <div>
                            <Label>Routes</Label>
                            <SubTitle>4</SubTitle>
                        </div>
                        <div>
                            <Label>Goals</Label>
                            <SubTitle>17 / 20</SubTitle>
                        </div>
                        <div>
                            <Label>Safety Evts.</Label>
                            <SubTitle>4</SubTitle>
                        </div>
                    </GridColumns>
                </ClickableCard>
            )}
        </div>

        <Divider top={10} bottom={10}/>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <SubTitle>Top 10 Drivers</SubTitle>
            <Link>View All</Link>
        </div>
        <Spacer bottom={3}/>
        <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'auto',
            margin: -20,
            marginRight: -60,
            padding: 20,
        }}>
            {[1, 2, 3, 5, 6, 8].map(i =>
                <ClickableCard key={i} style={{
                    minWidth: 'calc(33% - 24px)',
                    marginRight: 20,
                }}>
                    <ThirdTitle>John Doe</ThirdTitle>
                    <Spacer bottom={3}/>
                    <GridColumns columns={3}>
                        <div>
                            <Label>FICO</Label>
                            <SubTitle>850</SubTitle>
                        </div>
                        <div>
                            <Label>GreenZone Score</Label>
                            <SubTitle>1050</SubTitle>
                        </div>
                        <div>
                            <Label>Safety Evts.</Label>
                            <SubTitle>4</SubTitle>
                        </div>
                    </GridColumns>
                </ClickableCard>
            )}
        </div>

        <Spacer bottom={20}/>
    </>
}