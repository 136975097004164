import axios from "axios";
import {getCompanyId,getUserId} from "../../../../Auth";
import {engine} from "../../../../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchLocations(params = {}){
        return API.call({
            actions: {
                response: {
                    Station:{
                        findOneBy: {
                            get: "Station",
                            criteria: {
                                company: getCompanyId(),
                                id: params.station
                            },
                            orderBy: {
                                name: 'DESC'
                            },
                            matching: params.matching || {},
                            includes: {
                                "0":"id",
                                "1":"code",
                                "stationMapLocations": {
                                    "0":"id",
                                    "1":"name",
                                    "2":"locations"
                                }
                            }
                        }
                    }
                }
            }
        });
    },
    getStationMap(idStationMap){
        return API.call({
            actions: {
                response: {
                    StationMap: {
                        findOneBy: {
                            get: "StationMap",
                            criteria: {
                                company:getCompanyId(),
                                id:idStationMap
                            },
                            includes: {
                                "1":"name",
                                "2":"locations"
                            }
                        }
                    }
                }
            }
        })
    },
    addMapLocation(params = {},idStationMap){
        params.company = getCompanyId();
        params.user = getUserId();

        //edit
        if(idStationMap){
            return API.call({
                actions: {
                    response: {
                        "StationMap": {
                            "custom": {
                                "functionName": "updateStationMap",
                                "criteria": {
                                    id: idStationMap,
                                    ...params
                                },
                                "get": "addStagingLocation",
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "locations",
                                }
                            },
                        }
                    }
                }
            });
        }else{
            return API.call({
                actions: {
                    "response": {
                        "StationMap": {
                            "custom": {
                                "functionName": "addMultipleStagingLocation",
                                "criteria": {
                                    locations: [params],
                                    station: params.station
                                },
                                "get": "addStagingLocation",
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "locations",
                                }
                            },
                        }
                    }
                }
            })
        }
        
    },
    addMultipleMapLocation(locations = [],station){
        return API.call({
            actions: {
                "response": {
                    "StationMap": {
                        "custom": {
                            "functionName": "addMultipleStagingLocation",
                            "criteria": {
                                locations : locations,
                                station : station.id,
                            },
                            "get": "addMultipleStagingLocation",
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "locations",
                            }
                        },
                    }
                }
            }
        })
    },
    removeStationMap: (id) => {
        return API.call({
            actions: {
                delete: {
                    StationMap: {
                        stationMap1: {
                            findOneBy: {
                                id: id
                            },
                            isHardDelete:true
                        }
                    }
                }
            }
        })
    },
    massDeleteRecord: (ids) =>{
        return API.call({
            ids: ids,
            isHardDelete: true
        },'/api/massDelete/StationMap')
    }
}

export default API;