import React, { useEffect, useState} from "react";
import styled from "styled-components";
import { Button, Theme, Input, Spacer, CheckBox, Dropdown, Alert, Title, Text, SubTitle} from "@dspworkplace/ui";
import { useForm} from "react-hook-form";
import { TabList } from "../../components/Tab";
import { MapAddressGeofence } from "../../components/GoogleMap";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import { useAuth, useLocalStorage } from "../../Auth";
import {useSelectedStation} from "../../components/StationSelector";
import toast from "../../components/Toasts/toast";
import {showDefaultErrorToast} from "../../Utilities";
import {Table, TableData} from "../../components/Table";
import Loading, {useLoading} from "../../components/Loading";
import Empty from '../../components/Empty';
import {confirm} from "../../components/Confirm";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;

    #stationGeofenceDiv {
        display: inline-flex;
        width: 170px;
        div {
            width: 160px;
        }
    }
`;
const StationContainer = styled.div`
    border-bottom: 1px solid #7C98B6;
`;
const Tag = styled.div`
    box-sizing: border-box;
    width: 36px;
    height: 20px;
    background: ${Theme.colors.info.bg} 0% 0% no-repeat padding-box;
    border-radius: ${Theme.defaultRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: ${Theme.colors.info.text};
    border: 1px solid ${Theme.colors.info.border};
`;



const App = ({ toogleModal, deleteId , deleteKey, handleDeleteDispacher}) => {
    const { api } = useAuth();
    const [deleteOption, setDeleteOption] = useState(true);
    const [isLoading, setIsLoading] = useLoading(false);
    const [deleteSelectedOption, setDeleteSelectedOption] = useState('');
    const [stationOption, setStationOption] = useState('');
    const [archiveStationData, setArchiveStationData] = useState({});

    const methods = useForm({
        mode: "onChange",
    });
    const { register, handleSubmit, errors, getValues, setValue, reset } = methods;
    const { category, station } = getValues();

    const archiveStation = async () => {
        setIsLoading(true);
        setDeleteOption(false);
        setDeleteSelectedOption('archiveStation');
        
        let params = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getStationConflictRecord",
                            get: "result",
                            excludes: [
                                "invoiceType",
                                "station",
                                "scheduleDesigns",
                                "skill",
                                "shiftTemplate",
                                "driverRoutes",
                                "tempDriverRoutes",
                                "balanceGroup",
                                "shiftRouteCommitments",
                                "company"
                            ],
                            criteria: {
                                stationId: deleteId,
                                companyId: localStorage.getItem("company")
                            }
                        }
                    }
                }
            }
        };
        const responseStation = await api.post("/api/lazy/manage/data", params);
        setArchiveStationData(responseStation.data.data.result);
        setIsLoading(false);
    };
    const deleteStationData = async() => {
        setIsLoading(true);
        let conf = await confirm({
            icon: true,
            text: 'Are you sure to archive station?',
        });
        if(conf){

            let params = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "deleteStationData",
                                get: "result",
                                excludes: [
                                    "invoiceType",
                                    "station",
                                    "scheduleDesigns",
                                    "skill",
                                    "shiftTemplate",
                                    "driverRoutes",
                                    "tempDriverRoutes",
                                    "balanceGroup",
                                    "shiftRouteCommitments",
                                    "company"
                                ],
                                criteria: {
                                    stationId: deleteId,
                                    companyId: localStorage.getItem("company")
                                }
                            }
                        }
                    }
                }
            };
            const responseStation = await api.post("/api/lazy/manage/data", params);
            handleDeleteDispacher(deleteId , deleteKey);
            toogleModal();
        }
        setIsLoading(false);
    }

    const reassignArchiveStation = () =>{
        setIsLoading(false);
        setDeleteOption(false);
        setDeleteSelectedOption('archiveReasignStation');

        api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    Station: {
                        findBy: {
                            criteria: {
                                company: localStorage.getItem('company'),
                            },
                            get: "station",
                            includes: {
                                0: "id",
                                1: "code",
                            }
                        }
                    }
                }
            }
        }).then(
            async (response) => {
                let filterdStation = await response.data.data.station.filter( s => s.id != deleteId); 
                setStationOption( filterdStation.map( s => ({ name: s.code, value: s.id }) ) )
            }
        )
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        if(data.vehicle.toString() == "false" && data.device.toString() == "false" && data.driver.toString() == "false"){
            archiveStation();
        } else {
            let conf = await confirm({
                icon: true,
                text: 'Are you sure you wish to reassign the selected items to a new station?',
            });
            if(conf){
                //asignStationData
                let params = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "asignStationData",
                                    get: "result",
                                    excludes: [
                                        "invoiceType",
                                        "station",
                                        "scheduleDesigns",
                                        "skill",
                                        "shiftTemplate",
                                        "driverRoutes",
                                        "tempDriverRoutes",
                                        "balanceGroup",
                                        "shiftRouteCommitments",
                                        "company"
                                    ],
                                    criteria: {
                                        stationId: deleteId,
                                        newStationId: data.station,
                                        driver: data.driver,
                                        device: data.device,
                                        vehicle: data.vehicle,
                                        companyId: localStorage.getItem("company")
                                    }
                                }
                            }
                        }
                    }
                };
                const responseStation = await api.post("/api/lazy/manage/data", params);

                if(data.vehicle == "true" && data.device == "true" && data.driver == "true"){
                    let params = {
                        actions: {
                            response: {
                                DriverRoute: {
                                    custom: {
                                        functionName: "deleteStationData",
                                        get: "result",
                                        excludes: [
                                            "invoiceType",
                                            "station",
                                            "scheduleDesigns",
                                            "skill",
                                            "shiftTemplate",
                                            "driverRoutes",
                                            "tempDriverRoutes",
                                            "balanceGroup",
                                            "shiftRouteCommitments",
                                            "company"
                                        ],
                                        criteria: {
                                            stationId: deleteId,
                                            companyId: localStorage.getItem("company")
                                        }
                                    }
                                }
                            }
                        }
                    };
                    const responseStation = await api.post("/api/lazy/manage/data", params);
                    handleDeleteDispacher(deleteId , deleteKey);
                    toogleModal();
                }else{
                    archiveStation();
                }
            }
        }
        setIsLoading(false);
    }

    return (
        <Container>
            { deleteOption && <>
                <Button size="medium" onClick={() => {archiveStation()}}>Archive selected station and all drivers/devices/vehicles assigned to it.</Button>
                <Spacer top={5}/>
                <Button size="medium" onClick={() => {reassignArchiveStation()}}>Archive selected station but reassign its drivers, devices, and/or vehicles to an existing station.</Button>
                <Spacer top={5}/>
                <Button size="medium" onClick={() => {toogleModal()}}>Cancel the archive request</Button>
            </>}
            {isLoading && (
                <Loading
                    style={{
                        width: "40px",
                        padding: 20,
                        margin: "0 auto"
                    }}
                />
            )}
            {!isLoading && deleteSelectedOption == 'archiveStation' && 
                <>
                    <Title>
                        {Object.keys(archiveStationData).length > 0 ?
                        'Archive selected station and all drivers/devices/vehicles assigned to it.' :
                        'Archive selected station.'
                        }</Title>
                    {Object.keys(archiveStationData).length > 0 &&
                    <Spacer top={5}>
                        <Alert.Warning
                            title='Warning'
                            content= {'The following items are shared with another station. If you continue, we will remove them from any other assigned station and archive them.'}
                        />
                    </Spacer>}
                    {Object.keys(archiveStationData).map((stationId)=>{
                        return(<StationContainer >
                                <Spacer top={5} />
                                <Spacer inline>
                                    <SubTitle>
                                        {archiveStationData[stationId]['information']['name']} 
                                    </SubTitle>
                                    <Tag style={{marginRight:'auto'}}>{archiveStationData[stationId]['information']['code']}</Tag>
                                </Spacer>
                                <Spacer top={5} />
                                {archiveStationData[stationId]['drivers'].length > 0 && <>
                                    <SubTitle>Driver List</SubTitle>
                                    <Table>
                                        <li className="header">
                                            <TableData width="192px">Driver</TableData>
                                        </li>
                                        {archiveStationData[stationId]['drivers'].length > 0 ?
                                        archiveStationData[stationId]['drivers'].map((i)=>{
                                            return(<li>
                                                <TableData width="192px">{i.name}</TableData>
                                            </li>);
                                        }) :
                                        <div style={{textAlign: 'center'}}>
                                            <Empty/>
                                        </div>}
                                    </Table>
                                </>}
                                {archiveStationData[stationId]['devices'].length > 0 && <>
                                    <Spacer top={5} />
                                    <SubTitle>Device List</SubTitle>
                                    <Table>
                                        <li className="header">
                                            <TableData width="192px">Device</TableData>
                                        </li>
                                        {archiveStationData[stationId]['devices'].length > 0 ?
                                        archiveStationData[stationId]['devices'].map((i)=>{
                                            return(<li>
                                            <TableData width="192px">{i.name}</TableData>
                                            </li>);
                                        }) :
                                        <div style={{textAlign: 'center'}}>
                                            <Empty/>
                                        </div>}
                                    </Table>
                                </>}
                                {archiveStationData[stationId]['vehicles'].length > 0 && <>
                                    <Spacer top={5} />
                                    <SubTitle>Vehicle List</SubTitle>
                                    <Table>
                                        <li className="header">
                                            <TableData width="192px">Vehicle</TableData>
                                        </li>
                                        {archiveStationData[stationId]['vehicles'].length > 0 ?
                                        archiveStationData[stationId]['vehicles'].map((i)=>{
                                            return(<li>
                                            <TableData width="192px">{i.name}</TableData>
                                            </li>);
                                        }) :
                                        <div style={{textAlign: 'center'}}>
                                            <Empty/>
                                        </div>}
                                    </Table>
                                </>}
                            <Spacer top={5} />
                        </StationContainer>)
                    })}
                    <Spacer top={5} />
                    <Spacer inline>
                        <Button size="small" style={{marginRight:'5px'}} onClick={() => {deleteStationData()}}>Ok</Button>
                        <Button size="small" style={{marginRight:'auto'}} onClick={() => {toogleModal()}}>Cancel</Button>
                    </Spacer>
                </>
            }
            {!isLoading && deleteSelectedOption == 'archiveReasignStation' && 
                <>
                    <Title>Archive selected station but reassign its drivers, devices, and/or vehicles to an existing station.</Title>
                    <Spacer top={5} />
                    <form name='category_form' onSubmit={handleSubmit(onSubmit)}>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            { <Dropdown
                                ref={register({required: 'Required field'})}
                                name='station'
                                label='Please select the reassigning station, and then select the items you wish to reassign to it. Unchecked items will be archived.'
                                size='small'
                                Required={true}
                                valid={station && !errors.station}
                                error={errors.station && errors.station.message}
                                options={stationOption}
                            /> }
                        </Spacer>
                        <Spacer top={5} />
                        <CheckBox
                            name="driver"
                            title={""}
                            ref={register()}
                            options={[{ value: 'true', label: "Employees" }]}
                        />
                        <Spacer top={5} />
                        <CheckBox
                            name="device"
                            title={""}
                            ref={register()}
                            options={[{ value: 'true', label: "Devices" }]}
                        />
                        <Spacer top={5} />
                        <CheckBox
                            name="vehicle"
                            title={""}
                            ref={register()}
                            options={[{ value: 'true', label: "Vehicles" }]}
                        />
                        <Spacer top={5} />
                        <Spacer inline>
                            <Button size='small' style={{marginRight:'5px'}} Type='submit'>Ok</Button>
                            <Button size="small" style={{marginRight:'auto'}} onClick={() => {toogleModal()}}>Cancel</Button>
                        </Spacer>
                    </form>
                </>
            }
        </Container>
    );
};

export default App;
