import React from 'react';
import moment from 'moment';
import {patternTimeValue} from "../../patterns";

// const offset = new Date().getTimezoneOffset();
// const offsetHours = Math.floor( offset / 60 );
// const offsetMinutes = offset % 60;
const timeZoneName = {
    'ET': 'America/Detroit',
    'CT': 'America/Chicago',
    'MT': 'America/Denver',
    'PT': 'America/Los_Angeles',
    'AKT': 'US/Alaska',
    'HT': 'Pacific/Honolulu',
    'AZ': 'America/Phoenix',
    'CET': 'Europe/Amsterdam'
};

export const getOffset = (dateLike, timezone = '') => {
    let m = (timezone != "") ? moment(dateLike).tz(timeZoneName[timezone]) : moment(dateLike);
    let offset = m.utcOffset() * -1;

    return {
        offset,
        hours: Math.floor(offset / 60),
        minutes: offset % 60
    };
}

const TimeFormat = ({ time }) => {
    let hours   = moment.duration(time.timestamp * 1000 ).hours();
    let minutes = moment.duration(time.timestamp * 1000 ).minutes();

    if ( minutes < 10 )
        minutes = '0' + minutes;

    return hours > 12
        ? ( +hours - 12 ) + ':' + minutes + 'pm'
        : `${hours}:${minutes}am`;
};

const covertUtcToLocal = (time, timezone = '') => {
    let hours   = moment.duration(time.timestamp * 1000 ).hours();
    let minutes = moment.duration(time.timestamp * 1000 ).minutes();
    let {hours: offsetHours, minutes: offsetMinutes} = (timezone != "") ? getOffset(moment().tz(timeZoneName[timezone]), timezone) : getOffset();

    let m = minutes - offsetMinutes;
    if ( m >= 60 )
        m -= 60;

    let h = hours - offsetHours;
    h = h < 0
        ? 24 + h
        : h > 23
            ? h - 24
            : h;

    if ( m < 10 )
        m = '0' + m;

    return h >= 12
        ? ( h == 12 ?  12 :  +h - 12 ) + ':' + m + 'pm'
        : `${h}:${m}am`;
};

const stringToMilliseconds = str => {
    if ( str.match( patternTimeValue ) === null )
        return 0;

    let pastNoon = str.match( 'pm' ) !== null;

    let [hours, minutes] = str.replace( /(am|pm|\s)/ig, '' ).split( ':' );

    let milliseconds  = minutes * 60 * 1000;
    milliseconds += ( pastNoon ? +hours + 12 : +hours ) * 60 * 60 * 1000;

    return milliseconds;
};

export const databaseToMilliseconds = (str, checkHours = false) => {
    if (checkHours) {
        let [hours, minutes] = str.replace( /(am|pm|\s)/ig, '' ).split( ':' );
        str = (hours == '00') ? '24:'+minutes : str;
    }
    let dur = moment.duration( str );

    return dur.isValid() ? dur.asMilliseconds() : 0;
};

const asDatabaseTime = milliseconds => {
    let duration = moment.duration( milliseconds );

    let h = parseInt(duration.asHours());
    let m = parseInt(duration.minutes());
    h = (h >= 24) ? (h - 24) : h;
    h = h < 10 ? '0' + h : ((h == 24) ? '00' : h);
    m = m < 10 ? '0' + m : m;

    return `${h}:${m}`;
};

const fullDateFromUTC = (timestamp, format = 'MMM D, Y – h:mma') => {
    return moment.utc(timestamp).local().format(format)
};


const calculateDuration = ( startTime, endTime, breakTime ) => {

    if(startTime && endTime){
        let [startTimeHours, startTimeMinutes] = startTime.replace( /(am|pm|\s)/ig, '' ).split( ':' );
        let [endTimeHours, endTimeMinutes] = endTime.replace( /(am|pm|\s)/ig, '' ).split( ':' );

        if( parseFloat(endTimeHours+'.'+endTimeMinutes) < parseFloat(startTimeHours+'.'+startTimeMinutes) )
            endTimeHours = parseInt(endTimeHours) + 24;

        endTime = endTimeHours+':'+endTimeMinutes;
    }
    let diff  = databaseToMilliseconds( endTime ) - databaseToMilliseconds( startTime );
    if (breakTime) {
        diff =  diff - databaseToMilliseconds(breakTime);
    }

    return covertTimeInNumber(diff);
};

export const covertTimeInNumber = (time) => {
    let hours = time / 3600 / 1000;

    if ( hours < 0 )
        hours += 12

    return Math.abs( hours ).toFixed( 2 );
};

export const intUtcToLocal = time => {
    if (time === null)
        return false;

    const {hours: offsetHours, minutes: offsetMinutes} = getOffset();

    let [hours, minutes] = time.toFixed(2).split('.');
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    let m = minutes - offsetMinutes;
    if ( m >= 60 )
        m -= 60;

    let h = hours - offsetHours;
    h = h < 0
        ? 24 + h
        : h > 23
            ? h - 24
            : h;

    return parseFloat(`${h}.${m}`)
};

export {stringToMilliseconds, asDatabaseTime, covertUtcToLocal, fullDateFromUTC, calculateDuration};
export default TimeFormat;