import React from 'react';
import {Button, Password, Spacer,CheckBox} from "@dspworkplace/ui";
import {validatePassword} from "../../patterns";
import Modal from "../../components/Modal";
import {useForm} from "react-hook-form";
import API from "../SchedulerLoadOut/api";
import toast from "../../components/Toasts/toast";
import {useAuth} from "../../Auth";

const errorMessages = {
    password: {
        required: "You need to provide a password",
        pattern: "You password must respect all rules",
    },
};



const App = ({setVisible,driver}) => {
    const { register, handleSubmit, errors,getValues } = useForm();
    const {password} = getValues();
    const { api } = useAuth();

    const editPasswordReset = async (data) => {
        let responsePassword = await API.passwordReset(api, data.driverId, data.password, data.terminateAllSession);
        if (responsePassword.data.success) {

            let responseMail = await API.passwordResetMail(api, localStorage.getItem('company'), data.driverEmail, data.driverName.trim(), data.password);
            setVisible(false);
            if (responseMail) {
                await toast({
                    type: 'success',
                    title: 'Password successfully changed.',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                });
            } else {
                await toast({
                    type: 'error',
                    title: 'Error in sending mail !!!',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                });
            }
        } else {
            let messageTxt = (responsePassword.data?.message) ? responsePassword.data?.message : 'Error in password reset !!!';
            await toast({
                type: 'error',
                title: messageTxt,
                timeout: 8000,
                useIcon: true,
                useClose: true
            });
        }
    }


    return (
        <Modal
            width='340px'
            title='Reset Password'
            visible={true}
            setVisible={()=> setVisible(false)}
            closeOnOverlay={false}
        >
            <form name="password_reset_form"  onSubmit={handleSubmit(editPasswordReset)}>
                <input type='hidden' name='driverId' value={driver.userId} ref={register} />
                <input type='hidden' name='driverEmail' value={driver.email} ref={register} />
                <input type='hidden' name='driverName' value={driver.friendlyName} ref={register} />
                {/*<Spacer top={3}/>*/}
                <Password
                    name="password"
                    label="New Password"
                    help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                    ref={register({
                        required: true,
                        validate: (value) => {
                            if (value) {
                                return validatePassword(value, driver.email, driver.oldPasswords);
                            }
                        }
                    })}
                    error={errors.password && errors.password.message}
                    valid={!errors.password && password && password.length > 0}
                />
                <Spacer top={3}>
                    <CheckBox
                        name='terminateAllSession'
                        ref={register}
                        style={{float:'right'}}
                        options={[{label:'Terminate all active sessions?',value:true}]}
                        defaultValues={false}
                    />
                </Spacer>
                <Spacer top={5} style={{ textAlign: "right" }} >
                    <Button type="primary" >Save</Button>
                </Spacer>
            </form>
        </Modal>
    )
}

export default App;