import React from "react";
import {ChartComponent} from "../index";
import {AxisHelper} from '../common';
import {
    Line as BizLine,
    Point,
    LineAdvance,
    Tooltip
} from 'bizcharts'
import {ILineGemoProps} from "bizcharts/lib/geometry/Line";

export interface ChartLine extends ChartComponent {
    showMarker? : boolean,
    soft? : true,
    splitBy? : string
    filled? : boolean
}

type IntervalProps = Omit<ILineGemoProps,"position">

export const Line: React.FC<ChartLine> = (props) => {
    const {
        y,
        x,
        tooltip = true,
        showMarker =  true,
        soft = false,
        filled =  true,
        splitBy,
        title
    } = props

    let extraProps: IntervalProps = {};

    if(soft){
        extraProps.shape = 'smooth'
    }
    if(splitBy){
        extraProps.color = splitBy
    }

    return (
        <>
            <AxisHelper title={title} x={x} y={y} />
            {
                filled
                ?
                    <LineAdvance
                        position={`${x}*${y}`}
                        {...extraProps}
                    />
                :
                    <BizLine
                        position={`${x}*${y}`}
                        {...extraProps}
                        color={[
                            "colorCode",
                            (colorCode) => {
                                return colorCode;
                            }
                        ]}
                    />
            }

            {showMarker && <Point position={`${x}*${y}`} />}
            <Tooltip
                visible={tooltip}
            />
        </>
    )
}