import React, {useEffect, useState, useRef} from 'react';
import {Theme, Icon, Spacer, Input, Button, ThirdTitle, Toggle,Text,Alert, async} from "@dspworkplace/ui";
import Modal, {useModal} from '../../components/Modal';
import {useForm} from "react-hook-form";
import StationSelector from "../../components/StationSelector";
import {alert} from '../../components/Alert';
import moment from "moment";
import DayPicker from "../../components/Calendar/dayNew";
import API from "./api";
import {toast} from "../../components/Toasts";
import Loading, {LoadingWrapper} from "../../components/Loading";
import {useLocalStorage,getUserId,getFriendlyName} from "../../Auth";
import { useHistory } from "react-router-dom";
import {FilterWeekDate,NarrativeAutoSave} from "../../hooks/useGlobalData";
import {useSelectedStation} from "../../components/StationSelector";
import { TimelineButton } from './timeline';
import { confirm } from "../../components/Confirm";

const SummaryButton = () => {
    const [visible, setVisible] = useModal(false);
    // const [currStation] = useLocalStorage('selectedStation');
    const [isResetPassword] = useLocalStorage('isResetPassword');
    const [currStation, ] = useSelectedStation('');
    let history = useHistory();

    const checkSelectedStation = (isResetPassword) => {
        if(isResetPassword === true) {
            toast({
                type: 'warning',
                title: "You must change your password first then you can access other sections.",
                useClose:true,
                timeout: 10000
            })
           return history.push("/edit-profile#user-information")
        } else {
            toast({
                type: 'warning',
                title: "To use any section it's necessary to add one station !!!",
                useClose:true,
                timeout: false
            })
            return history.push("/company-management/stations")
        }
    }

    return (
        <>
            <Icon.Notes size='26px' style={{cursor:'pointer'}} onClick={()=>{
                (currStation && isResetPassword != true) ? setVisible(!visible) : checkSelectedStation(isResetPassword);
            }}/>
            <Modal
                width={'350px'}
                drawer={'right'}
                title='Station Narrative'
                visible={visible}
                setVisible={setVisible}
                closeOnOverlay={true}
            >
                <SummaryForm />
            </Modal>
        </>
    )
}

const SummaryForm = () => {
    const {register,handleSubmit,getValues,errors,reset,setValue} = useForm();
    // const [currStation] = useLocalStorage('selected');
    const [currStation, ] = useSelectedStation('');
    const [currDate, setCurrDate] = FilterWeekDate();
    const [selectedStation, setSelectedStation] = useState(currStation);
    const [loading,setLoading] = useState(false);
    const [lockedUser, setLockedUser] = useState('');
    const [isDownload, setIsDownload] = useState(false);
    const [autoSave, setAutoSave] = NarrativeAutoSave();
    const [updatedDate, setUpdatedDate] = useState();
    const [summaryId, setSummaryId] = useState(null);

    const allValues = getValues();
    const {id,lockUser,driversHired,driversQuit,driversFired,interviewsScheduled,interviewsCompleted,summary} = allValues;
    const idRef = useRef();

    const currentUser = getFriendlyName();
    const submitHandler = async (data,evt) => {
        let form = evt.target;
        let formButton = form.querySelector('#submit');

        if(!data.date) {
            alert('You need to provide an date');
            return;
        }
        if(!data.station || data.station === 'all') {
            alert('You need to chose one single station');
            return;
        }


        formButton.disabled = true;
        if (data.summary == '') {
            data.summary = moment(currDate).format('MM/DD/YYYY');
            reset({
                driversHired:data.driversHired,
                driversQuit:data.driversQuit,
                driversFired:data.driversFired,
                scheduledInterviews:data.scheduledInterviews,
                interviewsCompleted:data.interviewsCompleted,
                summary:data.summary,
                lockUser:data.lockUser
            });
        }

        await API.fetchSummary(currDate,selectedStation).then(
            async response => {
                if(response.data.data.summary) {
                    const summary = response.data.data.summary;
                    if (summary.lockUser != null && summary.lockUser != currentUser) {
                        const confirmation = await confirm('This field is currently locked for editing by '+summary.lockUser+'. Would you like to close '+summary.lockUser+'\’s Narrative session?');
                        if (confirmation) {
                            setIsDownload(false);
                            if(response.data.data.summary){
                                reset({
                                    driversHired:summary.driversHired,
                                    driversQuit:summary.driversQuit,
                                    driversFired:summary.driversFired,
                                    scheduledInterviews:summary.scheduledInterviews,
                                    interviewsCompleted:summary.interviewsCompleted,
                                    summary:summary.summary,
                                    id:summary.id,
                                    lockUser:summary.lockUser
                                });

                                setSummaryId(summary.id);
                                setUpdatedDate(summary.updatedDate.timestamp);
                                idRef.current = summary.id;

                                if(summary.reportUrl != null){
                                    setIsDownload(true);
                                }

                                const lockInfo = {id: summary.id, lockUser: currentUser}
                                await API.updateSummary(lockInfo)
                                setLockedUser(currentUser);

                            }
                            return true;
                        } else {
                            setLockedUser(summary.lockUser);
                            return true;
                        }
                    } else {
                        data.modifiedBy = parseInt(getUserId());
                        data.updatedDate = moment().utc().format();
                        delete data.date;
                        delete data.station;
                        data.id = summaryId;
                        data.lockUser= currentUser;

                        API.updateSummary(data).then(result => {
                            if(result.data.code === 200){
                                idRef.current = summary.id;
                                setLockedUser(currentUser);
                                toast({
                                    type: 'success',
                                    title: 'Summary information updated successfully.'
                                });
                                setUpdatedDate(moment.utc().valueOf() / 1000)
                            }else{
                                alert('Error to update the summary, please contact support.');
                            }
                            formButton.disabled = false;
                        });
                    }
                } else {
                    delete data.id;
                    data.summaryDate = currDate;
                    delete data.date;

                    data.createdBy = parseInt(getUserId());
                    data.modifiedBy = data.createdBy;
                    data.lockUser = getFriendlyName();
                    setSummaryId(null);
                    API.addSummary(data).then(result => {
                        if(result.data.code === 200){
                            setLockedUser(currentUser);
                            setValue('id',result.data.data.dailyDriverSummaryId);

                            setSummaryId(result.data.data.dailyDriverSummaryId);
                            idRef.current = result.data.data.dailyDriverSummaryId;
                            toast({
                                type: 'success',
                                title: 'Summary information created successfully.'
                            });
                            setUpdatedDate(moment.utc().valueOf() / 1000)
                        }else{
                            alert('Error to create the summary, please contact support.');
                        }
                        formButton.disabled = false;
                    })
                }
                setLoading(false);
                return true;
            }
        );
    }

    const generateSummary = async(type) => {
        let tempArray = [];
        setLoading(true);

        tempArray['currentStation'] = selectedStation;
        tempArray['currentDate'] = currDate;
        await API.generateSummary(tempArray).then(result => {
            setSummaryId(result.data.data.dailySummary);
            if(type == 'generate'){
                API.dailySummaryDownload(tempArray).then(result => {
                    if(result.data.data.pdf && result.data.data.pdf != null){
                        window.open(result.data.data.pdf);
                    }
                });
            }
             reset({
                    driversHired:allValues.driversHired,
                    driversQuit:allValues.driversQuit,
                    driversFired:allValues.driversFired,
                    scheduledInterviews:allValues.scheduledInterviews,
                    interviewsCompleted:allValues.interviewsCompleted,
                    summary:allValues.summary,
                    id:allValues.id,
                    lockUser:allValues.lockUser
                });
            setLoading(false);
        });
        setIsDownload(true);
    }
    const summaryDownload = async(type) => {
        let tempArray = [];
        setLoading(true);
        tempArray['currentStation'] = selectedStation;
        tempArray['currentDate'] = currDate;
        await API.dailySummaryDownload(tempArray).then(result => {
            setLoading(false);
            if(result.data.data.pdf && result.data.data.pdf != null){
                window.open(result.data.data.pdf);
            }
        });

    };
    useEffect(() => {
        if (currStation != null) {
            const searchStation = typeof(currStation) === 'object' ? currStation[0] : currStation;
            if(!searchStation || !currDate) return;
            setSelectedStation(searchStation);
            reset({date:currDate,station:searchStation})
        }
    }, []);

    useEffect(()=>{
        if(!selectedStation || !currDate){
            toast({
                type: 'error',
                title: 'Error',
                content: 'This page needs an date and a single station to work'
            })
            return;
        }
        setLoading(true);

        API.fetchSummary(currDate,selectedStation).then(
            async response => {
                setLockedUser('');
                setIsDownload(false);
                setSummaryId(null);
                if(response.data.data.summary){
                    const summary = response.data.data.summary;
                    reset({
                        driversHired:summary.driversHired,
                        driversQuit:summary.driversQuit,
                        driversFired:summary.driversFired,
                        scheduledInterviews:summary.scheduledInterviews,
                        interviewsCompleted:summary.interviewsCompleted,
                        summary:summary.summary,
                        id:summary.id,
                        lockUser:summary.lockUser
                    });

                    setSummaryId(summary.id);
                    setUpdatedDate(summary.updatedDate.timestamp);
                    idRef.current = summary.id;

                    if(summary.reportUrl != null){
                        setIsDownload(true);
                    }

                }else{
                    reset({
                        driversHired:null,
                        driversQuit:null,
                        driversFired:null,
                        scheduledInterviews:null,
                        interviewsCompleted:null,
                        summary:null,
                        id:null,
                        lockUser:null

                    });
                    setIsDownload(false);
                    setUpdatedDate(null);
                }
                setLoading(false);

                if(response.data.data.summary) {
                    const summary = response.data.data.summary;
                    if (!summary.lockUser) {
                        const lockInfo = {id: summary.id, lockUser: currentUser}
                        await API.updateSummary(lockInfo)
                        setLockedUser(currentUser);
                    } else{
                        setLockedUser(summary.lockUser);
                    }
                }else{
                    setLockedUser('');
                }
            },
            error => {
                if ( error.response ){}
                toast({
                    type: 'error',
                    title: 'Error',
                    content: error.response ? error.response.data.message : 'Unexpected error'
                })
                setLockedUser('');
                setLoading(false);
            }
        );
    },[selectedStation,currDate])

    useEffect(()=>{
        if(idRef.current && idRef.current > 0 && currentUser == lockedUser){
            window.addEventListener("beforeunload", (ev) =>
            {
                const lockInfo = {id:idRef.current,lockUser:null}
                API.updateSummary(lockInfo);
            });
        }

        return () => {
            API.cancel();

            if(idRef.current && idRef.current > 0 && currentUser == lockedUser){
                const lockInfo = {id:idRef.current,lockUser:null}
                API.updateSummary(lockInfo);
            }
        }
    },[lockedUser])


    let autoSaveTimer = null;
    const handleAutoSave = (e) => {

        clearTimeout(autoSaveTimer);

        autoSaveTimer = setTimeout(()=>{
            document.getElementById('submit').click();
        },3000)

    }


    if(loading) return (
        <LoadingWrapper>
            <Loading style={{height:'80px',marginTop:'20px'}} />
        </LoadingWrapper>
    )

    return (
        <>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Spacer inline style={{display:'flex',alignItems:'flex-end',position:'relative',zIndex:5}} >
                    <input type='hidden' name='id' ref={register} />
                    <input type='hidden' name='lockUser' ref={register} />
                    <DayPicker
                        label='Date'
                        className='picker'
                        name='date'
                        ref={register}
                        size={'small'}
                        useControllers={true}
                        defaultValue={moment(currDate).format('YYYY-MM-DD')}
                        onChange={(date) => {
                            if(!date.original) return;
                            const localDate = moment(date.original,'MM/DD/YYYY').format('YYYY-MM-DD');
                            if(localDate === currDate) return;
                            setCurrDate(localDate);
                        }}
                    />
                    <Spacer inline left={5}>
                        <StationSelector
                            name='station'
                            size='extraSmall'
                            showAllOption={false}
                            ref={register}
                            onChange={(value)=>{
                                const searchStation = typeof(value) === 'object' ? value[0] : value;
                                setSelectedStation(searchStation);
                            }}
                        />
                    </Spacer>
                </Spacer>
                {lockedUser.length > 0 && currentUser != lockedUser &&
                <Spacer top={5}>
                    <ThirdTitle>This summary is open by user {lockUser}, try another date or station.</ThirdTitle>
                </Spacer>
                }
                {(!lockedUser || currentUser == lockedUser) &&
                <>
                    <Spacer top={5} bottom={1}>
                        <ThirdTitle>Drivers</ThirdTitle>
                    </Spacer>
                    <Spacer inline>
                        <Input
                            name='driversHired'
                            ref={register}
                            valid={driversHired && !errors.driversHired}
                            label='Hired'
                            type='number'
                            size='extraSmall'
                            onChange={autoSave ? handleAutoSave : undefined}
                        />
                    </Spacer>
                    <Spacer inline left={5}>
                        <Input
                            name='driversFired'
                            ref={register}
                            valid={driversFired && !errors.driversFired}
                            label='Fired'
                            type='number'
                            size='extraSmall'
                            onChange={autoSave ? handleAutoSave : undefined}
                        />
                    </Spacer>
                    <Spacer inline left={5}>
                        <Input
                            name='driversQuit'
                            ref={register}
                            valid={driversQuit && !errors.driversQuit}
                            label='Quit'
                            type='number'
                            size='extraSmall'
                            onChange={autoSave ? handleAutoSave : undefined}
                        />
                    </Spacer>

                    <Spacer top={5} bottom={1}>
                        <ThirdTitle>Interviews</ThirdTitle>
                    </Spacer>

                    <Spacer inline>
                        <Input
                            name='scheduledInterviews'
                            ref={register}
                            valid={interviewsScheduled && !errors.interviewsScheduled}
                            label='Scheduled'
                            type='number'
                            size='small'
                            onChange={autoSave ? handleAutoSave : undefined}
                        />
                    </Spacer>
                    <Spacer inline left={5}>
                        <Input
                            name='interviewsCompleted'
                            ref={register}
                            valid={interviewsCompleted && !errors.interviewsCompleted}
                            label='Completed'
                            type='number'
                            size='small'
                            onChange={autoSave ? handleAutoSave : undefined}
                        />
                    </Spacer>

                    <Spacer top={5} bottom={1}>
                        <ThirdTitle>Details</ThirdTitle>
                    </Spacer>

                    <Spacer inline>
                        <Input
                            name='summary'
                            ref={register}
                            valid={summary && !errors.summary}
                            label='Summary'
                            type='number'
                            as='textarea'
                            style={{height:120}}
                            onChange={autoSave ? handleAutoSave : undefined}
                            placeholder={moment(currDate).format('MM/DD/YYYY')}
                        />
                    </Spacer>


                    <Spacer top={5} bottom={3}>
                        <Button type='primary' id={'submit'} style={{width:'100%',opacity: autoSave ? 0.3 : 1}}>Save</Button>
                    </Spacer>
                    <div>
                        <Toggle name='toogle' on={autoSave} onText='Auto Save' onChange={(e) => setAutoSave(e.target.checked)} style={{ position: 'relative' }} />
                        <TimelineButton station={selectedStation} date={currDate} />
                        <Spacer top={3} />
                        {updatedDate && autoSave && <Text style={{fontWeight:400,color:'#ccc'}}>Last Saved {moment.duration(moment().diff(moment.unix(updatedDate))).humanize()} ago</Text>}
                    </div>
                </>
                }
            </form>

            {isDownload == false ? <>
                        { lockedUser.length < 1 ? (autoSave ? <Alert.Warning
                            content='Please wait 5 sec to allow any changes above to propagate before using the download feature.'
                        /> :<Alert.Warning
                            content='Please save your changes first before using the download feature.'
                        />): '' }
                        <Spacer top={2} bottom={1}>
                        <Button  style={{width:'100%'}} onClick={()=>{generateSummary('generate')}}>Generate & Download</Button>
                        </Spacer>
                        </> : <>
                        <Spacer top={2} bottom={1}>
                        <Button  style={{width:'100%'}} onClick={()=>{generateSummary('regenerate')}}>Re Generate </Button>
                        </Spacer>
                        <Spacer top={2} bottom={1}>
                        <Button  style={{width:'100%'}} onClick={()=>{summaryDownload()}}>Download</Button>
                        </Spacer>
                        </>}

        </>
    )
}


export {SummaryButton};
export default SummaryForm;
