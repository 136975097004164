import React from "react";
import {ChartComponent} from "../index";
import {
    Interval,
    Tooltip,
    Coordinate,
    View,
    Legend
} from 'bizcharts'
import {IIntervalGemoProps} from "bizcharts/lib/geometry/Interval";
import {AxisHelper} from "../common";

export interface ChartPie extends ChartComponent {
    format: 'numeric' | 'percent',
    dataLength: number,
    heading: string
}

type IntervalProps = Omit<IIntervalGemoProps,"position">

export const Pie: React.FC<ChartPie> = (props) => {
    const {
        y,
        x,
        tooltip = true,
        title,
        format = 'numeric',
        data,
        dataLength,
        heading
    } = props;

    // const divison = dataLength > 20 ? Math.round(dataLength / 20) : null;
    // let oldValue = null

    const setContentValue = (data, y, x, temp) => {
        // Keeping commented for time being
        // if (oldValue === data[y]) {
        //     return null
        // } else {
        //     oldValue = data[y] || 0;
        //     return `${data[y]}: ${data[x]}`
        // }
        const yCordinateArray = temp?.y || []
        if (Math.abs(yCordinateArray[1] - yCordinateArray[0]) > 4) {
            return heading === 'Device and OS Version Overview' ? `${data[x]}: ${data[y]}` : `${data[y]}: ${data[x]}`
        }
    }

    let extraProps: IntervalProps = {
        adjust: "stack",
        label: [
            y, {
                content: (data, temp, index) => {
                    // Keeping commented for time being
                    // if (divison) {
                    //     return (index % divison) === 0 ? setContentValue(data, y, x) : null;
                    // }
                    return setContentValue(data, y, x, temp)
                }
            }
        ],
    };

    let ViewProps = {
        scale:{}
    };
    ViewProps.scale[x] = {type:'linear'};

    if(format === 'percent'){
        ViewProps[x] = {
            formatter: val => {
                val = val * 100 + '%'
                return val
            }
        }

        extraProps.label[1].content = (data) => {
            return `${data[y]}: ${data[x] * 100}%`
        }
    }

    return (
        <>
            <AxisHelper title={title} x={x} y={y} />

                <Coordinate type="theta" radius={0.80} />
                <Interval
                    {...extraProps}
                    position={y}
                    color={[
                        "colorCode",
                        (colorCode) => {
                            return colorCode;
                        }
                    ]}
                    tooltip={[
                        'measure*color',
                        (measure, color) => {
                            return { name: color, value: measure }
                        },
                    ]}
                />


            <Tooltip
                visible={tooltip}
            />
            <Legend
                itemName={{
                    formatter: (text, item, index) => {
                        try {
                            if (typeof new Date(data?.[index]?.x).getMonth === 'function') {
                                return data?.[index]?.color || text;
                            } else {
                                return data?.[index]?.x || text;
                            }
                        } catch (error) {
                            return data?.[index]?.x || text;
                        }
                    },
                }}
            />
        </>
    )
}