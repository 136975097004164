import styled from "styled-components";
import React from "react";
import {Theme, ThirdTitle, Spacer} from '@dspworkplace/ui'
import Tooltip from '../../Tooltip/tooltip'
import StatusColored from "../../Form/Combobox/Options/statusColored";

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const LegendLayout = ({legendFields}) => {
    return (
        <div>
            {legendFields.map((field)=>{
                return (
                    <React.Fragment key={field.key}>
                        <ThirdTitle>{field.name}</ThirdTitle>
                        <Spacer top={1} />
                        <div style={{display:'inline-flex',gap:8, whiteSpace: 'nowrap'}}>
                            {field.validValues.map(f => {
                                return <StatusColored row={f} showLabel={true} key={f.value} />
                            })}
                        </div>
                        <Spacer bottom={2} />
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export const GridLegend = ({legendFields}) => {
    return (
        <>
            <Tooltip
                content={<LegendLayout legendFields={legendFields} />}
            >
                <Question>?</Question>
            </Tooltip>
        </>
    )
}