
const WorkFlowFormsReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {         
             
        case 'ADD':
            return {
                ...state,
                forms:payload.data,
                company: payload.company,
                isLoading:false,
                category:payload.category,
                sub_category:payload.sub_category
            };

        case 'ADD_ACTION':
            return {
                ...state,
                actions:payload,
            };

        case 'SET_CATEGORY':
            return {
                ...state,
                category:payload,
            };

        case 'SET_SUB_CATEGORY':
            return {
                ...state,
                sub_category:payload,
            };            

        case 'LOADING':
            return {...state,isLoading: payload.status};

        case 'MODAL_VISIBLE':
            return {...state,modalVisible: payload};   
                        
        case "REFRESH":
            return { ...state };
            
        default:
            return state;
    }
}
export default WorkFlowFormsReducer
