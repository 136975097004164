import React from 'react'
import { Avatar } from 'stream-chat-react';
import styled from "styled-components";
import { ReturnButton, Text } from '../styles';
import { Icon } from '@dspworkplace/ui';
import { AvatarCustome, getInitials } from '../ChannelIcon';

function Member({ item, selectedUser, type, callback, remove, removeMember, index, isNewUser }) {
    const { noOption, image, friendlyName, driverId, email, identity, role, roleName, schedules, skills, stations, userId, } = item
    return (
        <Container noOption={noOption} background={remove ? 'aliceblue' : selectedUser === item ? 'floralwhite' : 'white'}
            onClick={() => callback ? callback(userId) : null} key={index}
        >
            {/* <input
                type='radio'
                checked={userId === selectedUser ? true : false}
                onChange={() => setSelectedUser(userId)}
            /> */}
            {image ?
                <Avatar image={image} name={friendlyName} size={30} />
                :
                <AvatarCustome size={'30px'} fontSize="12px">
                    {getInitials(friendlyName).toUpperCase()}
                </AvatarCustome>}
            <div>
                <Text fontWeight="500" >
                    {friendlyName}</Text>
                    <Text color='#707070' fontSize="12px" >{item?.title}</Text>
            </div>
            <Frag>
                {Array.isArray(skills) && skills.map((data, index) => {
                    return (
                        <div className='direct-mesage-tag' key={index} >
                            <span>{data}</span>
                        </div>
                    )
                })}
            </Frag>
            <Frag >
                {Array.isArray(stations) && stations.map((data, index) => {
                    return (
                        <div className='direct-mesage-tag-station' key={index} >
                            <span>{data}</span>
                        </div>
                    )
                })}
            </Frag>
            {type === "createGroup" ?
                <Frag>
                    {Array.isArray(schedules) && schedules.map((data, index) => {
                        return (
                            <div className='direct-mesage-tag-schdule' key={index} >
                                <span>{data?.name}</span>
                            </div>
                        )
                    })}
                </Frag>
                :
                <Frag>
                    {Array.isArray(schedules) && schedules.map((data, index) => {
                        return (
                            <div className='direct-mesage-tag-schdule' key={index} >
                                <span>{data}</span>
                            </div>
                        )
                    })}
                </Frag>
            }
            {/* {remove && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} onClick={() => removeMember(userId)}>
                    <Icon.Times size="24px" />
                </div>
            )} */}
            <Frag>
            {isNewUser && (
                    <div className='new-tag' >
                        <span>New</span>
                    </div>
            )}
            {/* </Frag> */}
                {/* <Frag> */}
            {selectedUser && !remove && type != 'newDirectMessage' && (
                    <div className='default-tag' >
                        <span>Default</span>
                    </div>
            )}
            </Frag>
            
        </Container>
    )
}

export default Member

const Container = styled.div`
    display: grid;
    // grid-template-columns: ${props => (props.noOption ? "30px 30px 300px 320px 220px 300px" : "30px 30px 300px 320px 220px 300px")};
    grid-template-columns: ${props => (props.noOption ? "30px 24% 22% 8% 23% 6%" : "30px 26% 23% 8% 23% 4%")};
    grid-column-gap: 8px;
    align-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: dotted 1px #cccccc
    background-color:${props => (props.background ? props.background : "white")};
    cursor:pointer;

    &:hover{
        background-color : aliceblue;
    }
`;

const Frag = styled.div`
    display:inline-block;
    vertical-align:middle;
    
    >div {
        margin-right:4px;
        margin-bottom:4px;
        &:nth-child(n+4){
           
        }
    }
`;