import React from "react";

// @ts-ignore
import style from "./tool.module.css";

const Tools = ({children}: React.PropsWithChildren<any>) => {
    return (
        <div className={style.gridTools}>
            {children}
        </div>
    )
}

export default Tools;