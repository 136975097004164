import {Icon, Spacer, Theme} from "@dspworkplace/ui";
import React from "react";

const Index = () => {
    return (
        <Spacer all={5} style={{ textAlign: 'center', flex: 0.84 }}>
            <Icon.Empty size='40px' color={Theme.colors.info.shadow}/>
        </Spacer>
    )
};

export default Index;