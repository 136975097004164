import React, {useCallback, Dispatch, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import ChannelDetails from "./ChannelDetails";
import {LoadingView} from "../components/Loading";
import {NewChannelAction, NewChannelState} from "./CGCOptions";
import {useTrackedState} from "./store";
import {getItemFromStorage} from "./utils";

type Props = {
    newChannelModalState: NewChannelState;
    newChannelModalDispatch: Dispatch<NewChannelAction>;
}

const CGCPanelAddChannelInformation = (props: Props) => {
    const {newChannelModalState, newChannelModalDispatch} = props
    const {name, description, errorMessage, loadingCheckingChannelInformation, image, channelNotification} = newChannelModalState;
    const state = useTrackedState();
    const {client} = state;
    const [roles, setRoles] = useState<any[]>([]);

    const {register, handleSubmit} = useForm({
        mode: 'onBlur',
        defaultValues: {
            channelName: name,
            channelDescription: description,
            channelImage: image,
            permissions: [],
            channelNotification,
        }
    });

    useEffect(() => {
        const abortController = new AbortController();
        if (client) {
            let currentRoles = getItemFromStorage('roles');
            if(Array.isArray(currentRoles)){
                setRoles(currentRoles);
            }
            newChannelModalDispatch({
                type: "LOADING_CHECKING_CHANNEL_INFORMATION",
                loadingCheckingChannelInformation: false
            });
        }
        return () => {
            abortController.abort();
        };
    }, [client, newChannelModalDispatch]);

    const onSubmit = useCallback(
        async data => {
            console.log(data);
            try {
                if (!client) return;
                newChannelModalDispatch({
                    type: "LOADING_CHECKING_CHANNEL_INFORMATION",
                    loadingCheckingChannelInformation: true
                });
                let channels = await client.getSubscribedChannels();
                const channelExist = channels.items.findIndex(item => item.friendlyName === data.channelName);
                if (channelExist >= 0) {
                    newChannelModalDispatch({
                        type: 'ERROR_CHANNEL_NAME',
                        errorMessage: `Channel with the name of ${JSON.stringify(data.channelName)} already exists.`,
                        name: ''
                    });
                } else {
                    let permissions = [];
                    if (data.permissions && Array.isArray(data.permissions) && data.permissions.length > 0) {
                        permissions = data.permissions.map(e => parseInt(e, 10));
                    }
                    newChannelModalDispatch({
                        type: "SET_NEW_CHANNEL_INFORMATION",
                        name: data.channelName,
                        description: data.channelDescription,
                        image: data.channelImage,
                        errorMessage: '',
                        permissions: permissions,
                        channelNotification: data.channelNotification,
                    });
                    // @ts-ignore
                    document.querySelectorAll("[data-tab='tab']")[2].click();
                }
            } catch (e) {
                console.error(e);
            }
        },
        [client, newChannelModalDispatch],
    );

    if (loadingCheckingChannelInformation) {
        return (<LoadingView containerHeight={'315px'} containerWidth={'100%'} imgHeight={'150px'}/>);
    }

    return (
        <ChannelDetails
            errorMessage={errorMessage}
            inputRef={register({required: 'You need to provide a channel name.'})}
            pictureUploaderRef={register({required: false})}
            descriptionRef={register({required: false})}
            notificationRef={register({required: false})}
            showNextCallback={handleSubmit(onSubmit)}
            showNext={true}
            kind={'Create Channel'}
            roles={roles.map(e => ({name: e.roleName, value: e.id}))}
            dropdownRef={register}
        />
    );
}

export default CGCPanelAddChannelInformation;