import {selectorFamily} from "recoil";
import apiFactory from '../../../api/factory';
import {VMTemplateCategory} from "./VehicleMaintenancePlanCategory";

export type VMTemplate = {
    label: string
    value: number
    items: {
        category: VMTemplateCategory
        type: VMTemplateCategory
        service_interval_days: number
        service_interval_miles: number
    }
}

export const VehicleMaintenancePlan = selectorFamily<VMTemplate[],number>({
    key: 'VehicleMaintenancePlan',
    get: (stationId) => async () => {
        const {call} = apiFactory();
        const response = await call(null, `api/v2/vehicle/maintenance/plan?page=0&station_id=${stationId}`, 'get')
        
        
        if (!response) return;
        

        let data = [
            {
                value: null,
                label: "Select plan",
                items: [],
            },
        ];
        let getData = response.data.map((plan) => ({
            value: plan.id,
            label: plan.name,
            items: plan.items
        }));
        return [...data,...getData];
    }
})