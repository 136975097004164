import React, { useState, useEffect, Suspense, useRef } from "react";
import styled from "styled-components";
import { Text } from "../styles"
import { Channel, CloseIcon, Message, Window, useChannelStateContext, useMessageContext } from "stream-chat-react";
import ScrollToBottom from "react-scroll-to-bottom";
import { useTrackedState as useBotState, useUpdate as useBotDispatch } from "./Store";
import Loading, { useLoading } from "../../Chat/Loading";
import BotTaskList from "./BotTaskList";
import BotOPenShift from "./BotOpenShift";
import BotReleaseShift from "./BotReleaseShift";
import BotSwapShift from "./BotSwapShift";

const Container = styled.div`
display: ${props => (props.display ? props.display : "grid")};
position: absolute;
width: 100%;
height: 100%;
background-color: white;
max-width: 100%;
`
const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 68px;
min-height: 68px;
top: 0;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
`
const Scroll = styled(ScrollToBottom)`
    height: 500px;
    visibility: ${props => (props.visibility ? props.visibility : "visible")};;
`;
// chat/BotTaskInteraction.tsx
function ReviewModalView({ isOpen, isPage, setOPenReviewModal, currentMessage, reviewType }) {
    const { channel } = useChannelStateContext();
    const dispatch = useBotDispatch();

    const state = useBotState();
    const { messages, isReviewing, message } = state;
    const [loading, setLoading] = useState(true);
    const [taskInformation, setTaskInformation] = useState<{ id: number, type: string }>();
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) return;
        if (!mounted.current && messages.length > 0) {
            mounted.current = true;
            setLoading(false);
            return;
        }
        if (messages.length > 0) return;

    }, [channel, dispatch, setLoading, messages.length])

    useEffect(() => {
        if (message?.updated_at && message.attributes["task"]) {
            let task = message.attributes["task"];
            if (task["id"] && task["type"]) {
                setTaskInformation({ id: task["id"], type: task["type"] });
                setLoading(false);
            }
        }
    }, [message, setLoading, message.attributes,]);

    return (
        <Container display={isOpen ? 'flex' : 'none'}>
            <div style={{ flexDirection: 'column', width: isPage ? '82%' : '65%' }} >
                <HeaderContainer >
                    <div className="close__square-button-container-details" >
                        <Text
                            fontSize={'18px'}
                            lineHeight={'unset'}
                            fontFamily={'circe-rounded, serif !important'}
                            fontWeight={'bold'}
                        >
                            Review</Text>
                    </div>
                    <div className="close__square-button-container" onClick={() => setOPenReviewModal(false)} >
                        <CloseIcon />
                    </div>
                </HeaderContainer>
                {/* <Scroll visibility={"visible"}> */}
                <div style={{ padding: '1px 18px 0 18px', }} >
                    <Channel channel={channel} >
                        <Window hideOnThread={true}>
                            {/* {isReviewing && (
                                <BotTaskList currentMessage={currentMessage} reviewType={reviewType} />
                            )} */}
                            {taskInformation && taskInformation.type === "OPEN_SHIFT" && taskInformation.id && (
                                <BotOPenShift taskInformation={taskInformation} channel={channel} />
                            )}
                            {taskInformation && taskInformation.type === "RELEASE_SHIFT" && taskInformation.id && (
                                <BotReleaseShift taskInformation={taskInformation} channel={channel} />
                            )}
                            {taskInformation && taskInformation.type === "SWAP_SHIFT" && taskInformation.id && (
                                <BotSwapShift taskInformation={taskInformation} channel={channel} />
                            )}
                        </Window>
                    </Channel>
                </div>
                {/* </Scroll> */}
            </div>
        </Container>
    )
}

export default ReviewModalView
