import React from 'react';
import {NavLink, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import {Icon, Spacer, SubTitle, Theme} from '@dspworkplace/ui';
import {Back, Body, Content, Menu, Navigator} from "../../../components/Menus/Navigator";
import {Link} from "../../../components/UI";
import {ROUTES} from "../../../Route_oold";

const App = () => {
    const tabOptions = ROUTES.find(r => r.path === '/custom-reports')
        .routes.find(r => r.path === '/van');
    const history = useHistory();
    const {url, isExact} = useRouteMatch();

    if (isExact)
        history.replace(url + tabOptions.routes[0].path);

    return (
        <Body>
            <Navigator>
                <Back>
                    <Link to='/company-management/custom-reports'>
                        <Icon.ArrowLeft size='16px'/>
                        <span>Back to Custom Reports</span>
                    </Link>
                </Back>
                <SubTitle>Van Reports</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                <Menu>
                    {tabOptions.routes.map((option,key)=>
                        <li key={key}>
                            <NavLink to={`/custom-reports/van${option.path}`}>
                                <option.icon size={'20px'}/>
                                <span>{option.name}</span>
                            </NavLink>
                        </li>
                    )}
                </Menu>
                <Spacer top={16} />
            </Navigator>
            <Content>
                <Switch>
                    {tabOptions.routes.map((option,key)=>(
                        <Route exact path={`/custom-reports/van${option.path}`} key={key}>
                            <option.component />
                        </Route>
                    ))}
                </Switch>
            </Content>
        </Body>
    );
};

export default App;
