import React from 'react';

const ImageRender = ({blockProps}) => {
    const {src} = blockProps.resizeData;
    return (
        <div className={"EmbedImage"}>
            <img
                src={src}
                style={{width:'100%',objectFit:'cover'}}
            />
        </div>
    );
}


export {ImageRender};