import React, { useEffect, useState } from 'react'
import { Avatar, CloseIcon } from 'stream-chat-react';
import styled from "styled-components";
import { Text } from '../styles';
import { Icon } from '@dspworkplace/ui';
import { useDispatch, useTrackedState } from '../store';
import { engine } from '../../Utilities';
import { getUserId } from '../../Auth';
import moment from 'moment';
import Loading from '../../components/Loading';

function PollDetails({ isShowPoll, setShowPoll, channel, isPage, message }) {
    const state = useTrackedState();
    const dispatch = useDispatch();
    const { chatClient } = state;
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isShowPoll)
            getPollDetails()
    }, [isShowPoll, message])

    async function getPollDetails() {
        try {
            await setLoading(true)
            const res = await engine().post('/api/lazy/manage/data', {
                "actions": {
                    "response": {
                        "GetStreamMessagePoll": {
                            "custom": {
                                "functionName": "getStreamPoll",
                                "get": "getStreamPoll",
                                "excludes": [
                                ],
                                "includes": [
                                    "id",
                                    "friendlyName",
                                    "profileImage"
                                ],
                                "criteria": {
                                    "channel_id": channel?.id,
                                    "message_id": message?.id
                                }
                            }
                        }
                    }
                },
            })
            if (res?.data.data.getStreamPoll.userOptionsAnswer) {
                const questionOptions = await Object.values(res?.data.data.getStreamPoll.userOptionsAnswer)
                await setOptions(questionOptions)
                var updateOption = await questionOptions?.map(item => { return { value: item.label, total_votes: item.count } })
                await chatClient?.partialUpdateMessage(message?.id, {
                    set: {
                        poll_options: updateOption
                    }
                })
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    async function updateOption(option, isMyVote) {
        try {
            await setLoading(true)
            const res = await engine().post('/api/lazy/manage/data', {
                "actions": {
                    "response": {
                        "GetStreamMessagePoll": {
                            "custom": {
                                "functionName": "updateStreamPoll",
                                "get": "updateStreamPoll",
                                "excludes": [
                                ],
                                "criteria": {
                                    "channel_id": channel?.id,
                                    "message_id": message?.id,
                                    "answer": [
                                        {
                                            "user_id": getUserId(),
                                            "value": option?.label,
                                            "flag": isMyVote ? "remove" : "add"
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            })
            console.log(res?.data)
            if (res?.data)
                await getPollDetails()
            await setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    async function deletePoll() {
        try {
            const res = await engine().post('/api/lazy/manage/data', {
                "actions": {
                    "response": {
                        "GetStreamMessagePoll": {
                            "custom": {
                                "functionName": "deleteStreamPoll",
                                "get": "deleteStreamPoll",
                                "excludes": [

                                ],
                                "includes": [

                                ],
                                "criteria": {
                                    "channel_id": channel?.id,
                                    "message_id": message?.id
                                }
                            }
                        }
                    }
                },
            })
            if (res?.data) {
                if (res?.data?.data?.deleteStreamPoll?.success) {
                    await chatClient.deleteMessage(message?.id);
                    setShowPoll(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ display: isShowPoll ? 'flex' : 'none', width: isPage ? '500px' : '300px', borderLeft: 'solid 1px #EAF0F6', overflowY: 'scroll' }} >
            <Container>
                <HeaderContainer>
                    <div style={{ cursor: 'pointer', }} onClick={() => setShowPoll(false)} >
                        <CloseIcon />
                    </div>
                    <Text color={'#000000'} fontWeight={500} margin="15px" >
                        Poll Details</Text>
                    <div style={{ cursor: 'pointer', position: 'absolute', right: '15px' }} onClick={() => deletePoll()} >
                        <Icon.Trash color="#D94C54" size="25px" />
                    </div>
                </HeaderContainer>

                {loading ?
                    <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'center', alignItems: 'center' }} >
                        <Loading height="50px" width="50px" />
                    </div>
                    : <div style={{ padding: "20px" }} >
                        <QuestionContainer>{message?.text}</QuestionContainer>
                        {options?.map((item, index) => {
                            const isMyVote = item?.users?.some(i => i.user.id === getUserId())
                            return (
                                <OptionContainer>
                                    <div style={{ padding: '12px', display: 'flex', alignItems: 'center' }} onClick={() => updateOption(item, isMyVote)}  >
                                        <div style={{ cursor: 'pointer' }} >
                                            {isMyVote ?
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill='#0071bc' > <path d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M10,17.414l-4.707-4.707 l1.414-1.414L10,14.586l7.293-7.293l1.414,1.414L10,17.414z" /></svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill='#a0a0a0'><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z" /></svg>
                                            }
                                        </div>
                                        <Text width='72%' textAlign="left" fontSize="15px" margin={'0 5px 0'} >{item?.label}</Text>
                                        <Text width='25%' textAlign="right" fontSize="15px">{item?.count} votes</Text>
                                    </div>
                                    <div style={{ alignItems: 'center', display: 'block', borderTop: '1px solid #a0a0a0', cursor: 'pointer', padding: '12px' }}>
                                        {item?.users?.map((value, _index) => {
                                            return (
                                                <div style={{ flexDirection: 'row', alignItems: 'center', padding: '5px', borderBottomColor: '#a0a0a0', borderBottomWidth: .2, display: 'flex' }}>
                                                    <Avatar
                                                        size={26}
                                                        image={value?.user?.profileImage}
                                                        name={value?.user?.friendlyName}
                                                    />
                                                    <div style={{ flexDirection: 'row', alignItems: 'center', width: '93%', justifyContent: 'space-between', display: 'flex' }} >
                                                        <Text style={{ color: '#323234', fontWeight: '500', marginLeft: '3%', fontSize: 15, marginLeft: '3%', }} >{value?.user?.friendlyName}</Text>
                                                        <Text style={{ color: '#a0a0a0', fontWeight: '500', textAlign: 'right', fontSize: 14, marginLeft: '3%', }} >{moment(value?.timestamp).calendar()}</Text>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </OptionContainer>
                            )
                        })}
                    </div>
                }
            </Container>
        </div >
    )
}

export default PollDetails


const Container = styled.div`
background-color: #ffffff;
display: block;
width: 100%;
`;

const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 70px;
min-height: 70px;
top: 0;
display: flex;
align-items: center;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
`;

const QuestionContainer = styled.div`
    background-color: white;
    padding: 12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 3px;
`

const OptionContainer = styled.div`
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 3px;
    margin-top: 10px
`