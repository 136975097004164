import React, {Dispatch, useEffect, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
import {StateChannelChat} from "./ChannelChatWindow";
import {fetchPreviousMessages, setMessageForChat, setMessagesConsumed} from "./utils";
import {getChatIdentifier} from "../Auth";
import {LoadingView} from "../components/Loading";
import {alert} from "../components/Alert";
// import VirtuosoMessages from "./VirtuosoMessages";
// import VirtualizedMessages from "./VirtualizedMessages";
// import WindowMessages from "./WindowMessages";
import ScrollToBottomMessages from "./ScrollToBottomMessages";
import {Message} from "twilio-chat/lib/message";
import {useDispatch as useChatDispatch} from "./store";

type Props = {
    channel: Channel;
    state: StateChannelChat;
    dispatch: Dispatch<any>;
    componentType?: string;
    showInput: boolean;
};

function ChannelChatMessages(props: Props) {
    const currentUser = getChatIdentifier();
    const chatDispatch = useChatDispatch();
    const {channel, state, dispatch, showInput = true} = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let testCurrentUser = {type: typeof currentUser, currentUser};
        if (testCurrentUser.type !== "string") {
            alert("Something bad happen. The app will reload.").then(() => {
                // @ts-ignore
                window.location.reload();
            });
        }
        if (channel) {
            fetchPreviousMessages(channel, 30)
                .then(({messages, hasPrevPage, lastIndex, previousIndex}) => {
                    // devLog({hasPrevPage, lastIndex, previousIndex});
                    // devLog(messages.map(value => value.sid));
                    // dispatch({type: "setPreviousIndex", previousIndex: previousIndex});
                    // dispatch({type: "setHasPreviousPage", hasPreviousPage: hasPrevPage});
                    // dispatch({type: "setLastIndex", lastIndex: lastIndex});
                    dispatch({
                        type: "setInitialMessages",
                        messages: messages,
                        previousIndex: previousIndex,
                        hasPreviousPage: hasPrevPage,
                        lastIndex: lastIndex
                    });
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    console.error({fetchPreviousMessages: error});
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on("messageAdded", async (message: Message) => {
                let newMessage = await setMessageForChat(message);
                dispatch({type: "messageAdded", message: newMessage});
                chatDispatch({type: "MESSAGE_ADDED_ACTIVE_CHANNEL", channel: message.channel});
                await setMessagesConsumed(channel);
            });

            channel.on("messageUpdated", async ({message}) => {
                if (message.sid && message.attributes["archived"] === true) {
                    dispatch({type: "messageRemoved", messageSid: message.sid});
                } else {
                    let updatedMessage = await setMessageForChat(message);
                    dispatch({type: "messageUpdated", message: updatedMessage});
                }
            });

            channel.on("messageRemoved", async message => {
                if (message.state.sid) {
                    dispatch({type: "messageRemoved", messageSid: message.state.sid});
                }
            });
        }
        return () => {
            abortController.abort();
        };
    }, [dispatch, channel, chatDispatch]);

    if (loading) {
        return <LoadingView containerHeight={"330px"}/>;
    }

    if (loading) {
        return <LoadingView containerHeight={"330px"}/>;
    }

    // if (componentType === "VirtuosoMessages") {
    //     return <VirtuosoMessages messages={state.messages} channel={channel}/>;
    // }

    // if (componentType === "ScrollToBottomMessages") {
    //     return (
    //         <ScrollToBottomMessages
    //             messages={state.messages}
    //             channel={channel}
    //             lastIndex={state.lastIndex}
    //             dispatch={dispatch}
    //             previousIndex={state.previousIndex}
    //             hasPreviousPage={state.hasPreviousPage}
    //             init={state.init}
    //             showInput={showInput}
    //         />
    //     );
    // }

    // if (componentType === "WindowMessages") {
    //     return <WindowMessages messages={state.messages} channel={channel}/>;
    // }

    // if (componentType === "VirtualizedMessages") {
    //     return <VirtualizedMessages messages={state.messages} channel={channel}/>;
    // }

    return (
        <ScrollToBottomMessages
            messages={state.messages}
            channel={channel}
            lastIndex={state.lastIndex}
            dispatch={dispatch}
            previousIndex={state.previousIndex}
            hasPreviousPage={state.hasPreviousPage}
            init={state.init}
            showInput={showInput}
        />
    );
}

export default ChannelChatMessages;
