import React from 'react';
import styled from "styled-components";
import moment from "moment-timezone";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 10px;
`;

function formatTime(datetime){
    if(datetime){
        return moment.tz(datetime,"America/Detroit").format("dddd, MMMM Do YYYY, h:mm:ss a");
    }
    return null;
}

const Updated: React.FC<{ friendlyName?: string | null, updatedAt?: string | null }> = ({
                                                                                            friendlyName,
                                                                                            updatedAt
                                                                                        }) => {
    if (friendlyName && updatedAt) {
        return (
            <Container>
                <div>
                    Last updated by {friendlyName}
                </div>
                <div>
                    at {formatTime(updatedAt)}
                </div>
            </Container>
        );
    }
    return null;
};

export default Updated;
