import {Provider} from "../store";
import React from "react";
import Template from "./Template";

function RTSTemplate() {
    return (
        <Provider>
            <Template scenario={'RTS'}/>
        </Provider>
    );
}

export default RTSTemplate