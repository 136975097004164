import React, {useEffect, useState} from 'react';
import type {Channel} from "twilio-chat/lib/channel";
import Loading from "./Loading";
import {getChannelFriendlyName} from "./utils";
import {Text} from "./styles";
import {showErrorToast} from "../Utilities";
import {useChatTrackedState} from "./store";

type Props = {
    channel: Channel;
    kind: string;
}

const ChannelTitle: React.FC<Props> = ({channel, kind}) => {
    const [stateTitle, setStateTitle] = useState({
        loading: kind === 'Duo',
        channelName: channel.friendlyName ? channel.friendlyName : 'N/A'
    });
    const {loading, channelName} = stateTitle;
    const state = useChatTrackedState();
    const {client} = state;

    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on('updated', ({channel}) => {
                if (channel && channel.friendlyName) {
                    if (channel.attributes.kind === 'Group') {
                        setStateTitle(currState => ({...currState, channelName: channel.friendlyName}))
                    }
                }
            });
            getChannelFriendlyName(channel, client)
                .then((name) => {
                    setStateTitle(({loading: false, channelName: name}));
                })
                .catch(async (e) => {
                    setStateTitle(currState => ({...currState, loading: false}))
                    await showErrorToast(e, 'Error in Channel Name.');
                });
        }
        return () => {
            abortController.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (<Loading imgHeight={'15px'} containerHeight={'18px'}/>);
    }

    return (
        <Text height={'18px'}
              fontSize={channelName && channelName.length >= 22 ? '11px' : '14px'}>{channelName}</Text>);
}

function propsAreEqual(prev, next) {
    return prev.channel.sid === next.channel.sid;
}

const MemoizedChannelTitle = React.memo(ChannelTitle, propsAreEqual);
// export default ChannelTitle;
export default MemoizedChannelTitle;