import {selectorFamily} from "recoil";
import apiFactory from '../../../api/factory';

export type VMVendorType = {
    label: string
    value: number
}

export const VMVendor = selectorFamily<VMVendorType[],number>({
    key: 'VMVendor',
    get: (stationId) => async () => {
        const {call} = apiFactory();
        const response = await call(null,`api/v2/vehicle/maintenance/vendor?stationId=${stationId}`,'get')
        if(!response) return []
        return response.data.map((item)=> ({
           value: item.id,
           label: item.name,
        }))
    }
})