import React,
{ createContext, useReducer } from "react";
import SettingsReducer from "./reducer";

const SettingsContext = createContext({});

const SettingsProvider = ({ children }) => {
    const reducer = useReducer(
        SettingsReducer,
        {
            company: {},
            gradingRules: [],
            incidentSetting: [],
            attendanceRules: {
                minLevel1: 0,
                maxLevel1: 10,
                colorLevel1: '#fe0000',
                minLevel2: 0,
                maxLevel2: 10,
                colorLevel2: '#ff6500',
                minLevel3: 0,
                maxLevel3: 10,
                colorLevel3: '#4CBB17',
                reportDays: 1,
                ncns: 0,
                callout: 0,
                late: 0,
                notificationSentAt: '10',
                notificationToDriverLower: false,
                notificationToDriverUpper: false,
                notificationToManagerLower: false,
                notificationToManagerUpper: false,
                notificationManagers: []
            },
            companySetting: {}
        }
    );

    return (
        <SettingsContext.Provider value={reducer}>
            {children}
        </SettingsContext.Provider>
    )
};

export { SettingsProvider };
export default SettingsContext;