export const STATUS = {
    PENDING:'pending',
    READY:'Ready',
    ERROR:'error',
    FILTERING:'filtering'
}

export const STATUS_TAG_CONFIG = {
    'Active':'success',
    'Paused':'info',
    'Expired':'error'
}