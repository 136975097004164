import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, CustomDropdown, Input, Spacer, SubTitle, Toggle} from "@dspworkplace/ui";
import {useAuth} from "../../../Auth";
import SettingsContext from "../context";
import {showToast} from "../../../Utilities";
import { engine } from "../../../Utilities/index";
import { AccessCheck, IsGranted } from "../../../security";

function getShowMobileSwapShift(state) {
    try {
        // console.log('getShowMobileSwapShift')
        if (state.company) {
            // console.log({getShowMobileSwapShift: !!state.company.showMobileSwapShift})
            return !!state.company.showMobileSwapShift;
        }
        // @ts-ignore
        let r = JSON.parse(localStorage.getItem('showMobileSwapShift'));
        // console.log({showMobileSwapShift: r});
        return !!r;
    } catch (e) {
        return false;
    }
}

function getShowMobileReleaseShift(state) {
    try {
        // console.log('getShowMobileReleaseShift')
        if (state.company) {
            // console.log({getShowMobileReleaseShift: !!state.company.showMobileReleaseShift})
            return !!state.company.showMobileReleaseShift;
        }
        // @ts-ignore
        let r = JSON.parse(localStorage.getItem('showMobileReleaseShift'));
        // console.log({showMobileReleaseShift: r});
        return !!r;
    } catch (e) {
        return false;
    }
}

const MobileShowOptions = () => {
    // @ts-ignore
    const {api} = useAuth();
    // @ts-ignore
    const [state, dispatch] = useContext(SettingsContext);
    const [swapSwiftAvailabilitiesOptions, setSwapSwiftAvailabilitiesOptions] = useState([{name:'loading',value:'loading'}]);
    const {register, handleSubmit, reset, watch} = useForm({
        defaultValues: {
            showMobileSwapShift: getShowMobileSwapShift(state),
            showMobileReleaseShift: getShowMobileReleaseShift(state),
        }
    });
    const [allowDriverToSwapWithUnscheduledDriver, setAllowDriverToSwapWithUnscheduledDriver] = useState(false);
    const [allowSameDayBackupDriverSwap, setAllowSameDayBackupDriverSwap] = useState(false);

    // console.log({MobileShowOptions: state});
    useEffect(() => {
        // console.log('MobileShowOptions');
        reset(state.company)
    }, [state.company]);

    const fetchSwapSwiftAvailabilities = async () => {
        try {
            const response =  await engine().post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        Company: {
                            custom: {
                                functionName: "getSwapSwiftAvailabilities",
                                get: "swapSwiftAvailabilities",
                                criteria:[]
                            }
                        }
                    }
                }
            });
            setSwapSwiftAvailabilitiesOptions(response.data.data.swapSwiftAvailabilities);
        }catch(e){
            console.log(e.message);
        }
    };

    useEffect( () => {
        fetchSwapSwiftAvailabilities();
        setAllowDriverToSwapWithUnscheduledDriver(state.company.allowDriverToSwapWithUnscheduledDriver);
        setAllowSameDayBackupDriverSwap(state.company.allowSameDayBackupDriverSwap);
    },[])

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            form.querySelector('button').disabled = true;
            await api.post('/api/lazy/manage/data', {
                actions: {
                    update: {
                        Company: {
                            update_company: {
                                findBy: {
                                    id: parseInt(state.company.id)
                                },
                                updateRecord: {
                                    showMobileReleaseShift: data.showMobileReleaseShift,
                                    showMobileSwapShift: data.showMobileSwapShift,
                                    allowDriverToSwapWithUnscheduledDriver: data.allowDriverToSwapWithUnscheduledDriver,
                                    allowSameDayBackupDriverSwap: data.allowSameDayBackupDriverSwap,
                                    ...(data.swapSwiftAvailability && {swapSwiftAvailability:data.swapSwiftAvailability}),
                                    ...(data.releaseMinLockingPeriod && {releaseMinLockingPeriod:data.releaseMinLockingPeriod})
                                }
                            }
                        }
                    },
                }
            });
            let updatedCompany = state.company;
            updatedCompany['showMobileReleaseShift'] = data.showMobileReleaseShift;
            updatedCompany['showMobileSwapShift'] = data.showMobileSwapShift;
            updatedCompany["allowDriverToSwapWithUnscheduledDriver"] = data.allowDriverToSwapWithUnscheduledDriver;
            updatedCompany["allowSameDayBackupDriverSwap"] = data.allowSameDayBackupDriverSwap;
            await showToast({
                type: 'success',
                title: 'Mobile Shift View Options',
                content: 'Updates completed'
            });
            dispatch({type: 'UPDATE_COMPANY', payload: {company: {...updatedCompany, ...(data.swapSwiftAvailability && {swapSwiftAvailability:data.swapSwiftAvailability}), ...(data.releaseMinLockingPeriod && {releaseMinLockingPeriod:data.releaseMinLockingPeriod})}}})
            form.querySelector('button').disabled = false;
        } catch (e) {
            await showToast({type: 'error', title: 'Mobile Shift View Options', content: JSON.stringify(e)});
            form.querySelector('button').disabled = false;
        }
    };

    const {showMobileSwapShift:watchShowMobileSwapShift, showMobileReleaseShift:watchShowMobileReleaseShift} = watch(["showMobileSwapShift", "showMobileReleaseShift"]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Mobile Shift View Options</SubTitle>
            <Spacer bottom={2}/>
            <Toggle
                ref={register}
                on={getShowMobileSwapShift(state)} // bad component, have to add so update does not fail
                name='showMobileSwapShift'
                singleText='Show Swap Shift Button in mobile app'
            />
            {watchShowMobileSwapShift && (
                <>
                    <Spacer bottom={4} />
                    <Toggle
                        ref={register}
                        on={allowDriverToSwapWithUnscheduledDriver} // bad component, have to add so update does not fail
                        name="allowDriverToSwapWithUnscheduledDriver"
                        singleText="Allow driver to SWAP with an unscheduled driver"
                    />
                    <Spacer bottom={4} />
                    <Toggle
                        ref={register}
                        on={allowSameDayBackupDriverSwap} // bad component, have to add so update does not fail
                        name="allowSameDayBackupDriverSwap"
                        singleText="Allow same-day backup driver swap"
                    />
                    <Spacer top={4}>
                        <CustomDropdown
                            options={swapSwiftAvailabilitiesOptions}
                            name="swapSwiftAvailability"
                            size={"medium"}
                            label={"Swap Swift Available For"}
                            ref={register}
                            defaultValue={state.company.swapSwiftAvailability}
                        />
                    </Spacer>
                </>
            )}
            <Spacer bottom={4} />
            <Toggle
                ref={register}
                on={getShowMobileReleaseShift(state)}
                name='showMobileReleaseShift'
                singleText='Show Release Shift Button in mobile app'
            />
            {watchShowMobileReleaseShift && 
            <Spacer top={4}>
                <Input type="number" name="releaseMinLockingPeriod" label="Release Minimum Locking Hours" ref={register} defaultValue={state.company.releaseMinLockingPeriod} />
            </Spacer>}
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default MobileShowOptions;