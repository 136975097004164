import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {Channel} from "twilio-chat/lib/channel";
import {getChatIdentifier} from "../Auth";
import Message from "./Message";
// import {useAtTop, useScrollTo, useAtBottom, useScrollToBottom} from "react-scroll-to-bottom";
import {useObserveScrollPosition} from "react-scroll-to-bottom";
import {fetchPreviousMessages} from "./utils";
import {MessageOfChannel} from "./types";

const MessagesContainer = styled.div`
    display: grid;
    width: 340px;
    grid-template-columns: 340px;
    grid-template-rows: auto;
    grid-row-gap: 4px;
    padding-bottom: 8px;
`;

type Props = {
    channel: Channel;
    messages: Array<MessageOfChannel>;
    lastIndex?: number;
    dispatch: any;
    previousIndex: number;
    hasPreviousPage: boolean;
    init?: boolean;
    loading?: boolean;
    setLoading: any;
};

function Messages({messages, channel, dispatch, previousIndex, hasPreviousPage, init, setLoading}: Props) {
    const currentUser = getChatIdentifier();
    // @ts-ignore
    // const [atTop] = useAtTop();
    // @ts-ignore
    // const scrollTo = useScrollTo();
    // @ts-ignore
    // const [atBottom] = useAtBottom();
    // @ts-ignore
    // const scrollToBottom = useScrollToBottom();
    // let observer = new IntersectionObserver((), options);

    const [isFetching, setIsFetching] = useState(false);
    const [lastReadMessageIndexes, setLastReadMessageIndexes] = useState({});

    // @ts-ignore
    const fetchMessage = useCallback(
        ({scrollTop}) => {
            const abortController = new AbortController();
            // console.log({scrollTop, previousIndex, hasPreviousPage, isFetching, loading, init});
            if (init) {
                dispatch({type: "setInit", init: false});
            } else if (scrollTop <= 650 && !init) {
                if (scrollTop <= 650 && !isFetching) {
                    if (previousIndex - 1 >= 0 && hasPreviousPage) {
                        setIsFetching(true);
                        setLoading(true);
                        fetchPreviousMessages(channel, 7, previousIndex - 1).then(response => {
                            
                            dispatch({
                                type: "prependMessages",
                                messages: response.messages,
                                previousIndex: response.previousIndex,
                                hasPreviousPage: response.hasPrevPage
                            });
                            
                            setLoading(false);
                            setIsFetching(false);
                        });
                    } else {
                        setIsFetching(false);
                    }
                }
            }
            return abortController.abort();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [init, isFetching],
    );

    // @ts-ignore
    useObserveScrollPosition(fetchMessage, [init, isFetching]);
    // previousIndex, hasPreviousPage, init, messages

    // useEffect(() => {
    //     // scrollTo("100%", { behavior: "auto" });
    //     scrollToBottom();
    // }, []);

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     if (init) {
    //         dispatch({type: "setInit", init: false});
    //     } else if (atTop && !init) {
    //         console.log({atTop, lastIndex, previousIndex, hasPreviousPage});
    //         setLoading(true);
    //         if (previousIndex - 1 >= 0 && hasPreviousPage) {
    //             fetchPreviousMessages(channel, 1, previousIndex - 1).then(response => {
    //                 setLoading(true);
    //                 dispatch({type: "setHasPreviousPage", hasPreviousPage: response.hasPrevPage});
    //                 dispatch({type: "setPreviousIndex", previousIndex: response.previousIndex});
    //                 dispatch({type: "setLastIndex", lastIndex: response.lastIndex});
    //                 dispatch({type: "prependMessages", messages: response.messages});
    //                 console.log({len: response.messages.length});
    //                 scrollTo(response.messages.length * 128);
    //                 devLog({count: messages.length});
    //                 setLoading(false);
    //             });
    //         }
    //         setLoading(false);
    //     }
    //
    //     return () => {
    //         abortController.abort();
    //     };
    // }, [atTop]);

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     if (atBottom && messages.length > 200) {
    //         console.log({atBottom, lastIndex, previousIndex, hasPreviousPage});
    //     }
    //
    //     return () => {
    //         abortController.abort();
    //     };
    // }, [atBottom]);

    const setReadIndexes = async () => {
        let indexes = {};
        const participants = await channel.getMembers();
        participants.forEach((participant) => {
            if (participant.identity !== currentUser) {
                indexes[participant.identity] = {
                    lastConsumedMessageIndex: participant?.lastConsumedMessageIndex || null
                }
            }
        })
        setLastReadMessageIndexes(indexes)

        for (const participant of participants) {
            const participantDetails = await participant.getUser();
            if (participant.identity !== currentUser) {
                indexes[participant.identity] = {
                    lastConsumedMessageIndex: participant?.lastConsumedMessageIndex || null,
                    name: participantDetails?.friendlyName
                }
            }
        }
        setLastReadMessageIndexes(indexes)

        if (channel) {
            channel.on("memberUpdated", async (event) => {
                let userCurr = await event.member.getUser();
                let currentmember = event.member;
                if (currentmember.identity !== currentUser) {
                    indexes[currentmember.identity] = {
                        lastConsumedMessageIndex: currentmember?.lastConsumedMessageIndex || null,
                        name: userCurr?.state?.friendlyName
                    }
                }
                setLastReadMessageIndexes(indexes);
            });
        }
    }

    useEffect(() => {
        setReadIndexes()
    }, [messages])


    return (
        <MessagesContainer>
            {Array.isArray(messages) &&
                messages.map((message, index) => {
                    return (
                        <Message
                            lastReadMessageIndexes={lastReadMessageIndexes}
                            key={message.sid}
                            message={message}
                            currentUser={currentUser!}
                            previousMessage={messages[index - 1]}
                            channel={channel}
                        />
                    );
                })}
        </MessagesContainer>
    );
}

export default Messages;
