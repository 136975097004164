import React,{useState,useEffect} from 'react';
import {Button, Icon, Input, Spacer, ThirdTitle} from "@dspworkplace/ui";

import {Table, TableData, OrderIco, AddTable} from "../../components/Table";
import sorter from '../../components/SorterFn';
import {useForm} from "react-hook-form";
import { useAuth } from "../../Auth";

const Skills = ({registerToParent,data,edit}) => {

    const [contacts, setContacts] = useState({update:-1,data:[],sort:[]});
    const { api } = useAuth();
    const handleOrder = (fieldName,direction) => {
        const orderedData = sorter(contacts.data,fieldName,!direction);
        setContacts({sort:[fieldName,direction],data:orderedData});
    }

    const handlerCurrentOrder = (fieldName) => {
        if(!contacts.sort || !contacts.sort[0]) return 0;
        return contacts.sort && contacts.sort[0] == fieldName ? !contacts.sort[1] : 0
    }
    const onSubmit = async(data,key, editData) =>
    {
        const newContact = {name:data.contact_name,relation:data.contact_relation,number:data.contact_number};
        if(key>=0){
            if(edit > 0){
                let params = {
                    "actions": {
                        "update": {
                            "EmergencyContact": {
                                "EmergencyContact_1": {
                                    "findBy": {
                                        "id": editData.id
                                    },
                                    "updateRecord": {
                                        "name": data.contact_name,
                                        "number": data.contact_number,
                                        "relation": data.contact_relation
                                    }
                                }
                            }
                        }
                    }
                }
                await api.post('/api/lazy/manage/data', params);
                let copy = [...contacts.data];
                copy[key] = newContact;
                setContacts({data:copy});
            } else {
                let copy = [...contacts.data];
                copy[key] = newContact;
                setContacts({data:copy});
            }

        }else{
            if(edit > 0) {
                let params = {
                    "actions": {
                        "create": {
                            "EmergencyContact": {
                                "EmergencyContact_1": {
                                    "driver": edit,
                                    "name": data.contact_name,
                                    "number": data.contact_number,
                                    "relation": data.contact_relation
                                }
                            }
                        }
                    }
                }
                let response = await api.post('/api/lazy/manage/data', params);
                const newContact = {id:response.data.data.responseId,name:data.contact_name,relation:data.contact_relation,number:data.contact_number};
                setContacts({data:[...contacts.data,newContact]});
            } else {
                setContacts({data:[...contacts.data,newContact]});
            }

        }

    }

    const handleDelete = async(key,data) => {

        const confirmation = window.confirm('Confirm that you want to delete this item');
        if(!confirmation){
            return false;
        }
        if(edit > 0){
            let params = {
                "actions": {
                    "delete": {
                        "EmergencyContact": {
                            "EmergencyContact_1": {
                                "findOneBy": {
                                    "id": data.id
                                }
                            }
                        }
                    },
                }
            };
            await api.post('/api/lazy/manage/data', params);
        }
        contacts.data.splice(key,1);
        setContacts({...contacts,data:contacts.data});
    }

    useEffect(()=>{

        if(data){
            setContacts({...contacts,data})
        }
    },[data])

    return (
        <>
            <ThirdTitle style={{fontWeight:'normal'}}>Emergency Contacts</ThirdTitle>

            <Table>
                <li className='header'>
                    <TableData
                        width='100px'
                        order={1}
                        onClick={()=>{handleOrder('name', handlerCurrentOrder('name') )}}
                    >
                            Name <OrderIco direction={handlerCurrentOrder('name') }/>
                    </TableData>
                    <TableData
                        width='100px'
                        order={1}
                        onClick={()=>{handleOrder('relation', handlerCurrentOrder('relation') )}}
                    >
                            Relation <OrderIco direction={handlerCurrentOrder('relation') }/>
                    </TableData>
                    <TableData
                        width='180px'
                        order={1}
                        onClick={()=>{handleOrder('number', handlerCurrentOrder('number') )}}
                    >
                        Number <OrderIco direction={handlerCurrentOrder('number') } />
                    </TableData>
                    <TableData width='80px'></TableData>
                </li>
                {contacts.data.length > 0
                    ?
                        contacts.data.map((item,index)=>(
                            (index == contacts.update ?
                                <AddForm key={index} onSubmit={onSubmit} keyEdit={index} editData={item} />
                            :
                                <li key={index}>
                                    <TableData width='100px'>{item.name} <input type='hidden' ref={registerToParent} name={`contact[${index}].name`} defaultValue={item.name} /> </TableData>
                                    <TableData width='100px'>{item.relation} <input type='hidden' ref={registerToParent} name={`contact[${index}].relation`} defaultValue={item.relation} /></TableData>
                                    <TableData width='180px'>{item.number} <input type='hidden' ref={registerToParent} name={`contact[${index}].number`} defaultValue={item.number} /></TableData>
                                    <TableData width='80px' order={1}>
                                        <input type='hidden' ref={registerToParent} name={`contact[${index}].id`} defaultValue={item.id} />
                                        <Spacer inline>
                                            <Icon.Edit size='20px' color='#7C98B6' onClick={()=>{setContacts({...contacts,update:index})}} />
                                        </Spacer>
                                        <Spacer inline left={3}>
                                            <Icon.TimesNarrow size='12px' color='#7C98B6' onClick={()=>{handleDelete(index,item)}} />
                                        </Spacer>
                                    </TableData>
                                </li>
                            )
                        ))
                    :
                    (<li style={{display:'flex',justifyContent:'center',padding:'20px',border:'none'}}>
                        <Icon.Empty color='#CCCCCC' size='60px' />
                    </li>)
                }
            </Table>
           <AddForm onSubmit={onSubmit} />
        </>
    );
}

const AddForm = ({onSubmit,keyEdit,editData}) => {
    let defaultFormValue = {};
    if(keyEdit>=0){
        defaultFormValue = {contact_name:editData.name, contact_relation:editData.relation, contact_number:editData.number};
    }

    const { register, handleSubmit, errors, setValue } = useForm({mode:'onBlur',defaultValues:defaultFormValue});

    const submiter = async data => {

        await onSubmit(data,keyEdit,editData);

        setValue('contact_name','');
        setValue('contact_relation','');
        setValue('contact_number','');
    }


    return (
        <AddTable>
            <Spacer inline right={5}>
                <Input
                    name='contact_name'
                    size='extraSmall'
                    ref={register({required:"Field required"})}
                    error={errors.contact_name &&  errors.contact_name.message}
                />
            </Spacer>
            <Spacer inline right={5}>
                <Input
                    name='contact_relation'
                    ref={register}
                    size='extraSmall'
                />
            </Spacer>

            <Spacer inline right={5}>
                <Input
                    name='contact_number'
                    size='small'
                    mask='phone'
                    ref={register({required:"Field required"})}
                    error={errors.contact_number &&  errors.contact_number.message}
                />
            </Spacer>

            <Spacer inline style={{verticalAlign:'bottom',float:'right'}}>
                <Button size='extraSmall' type='button' onClick={handleSubmit(submiter)}>{(keyEdit>=0) ? 'Save' : 'Add'}</Button>
            </Spacer>
        </AddTable>
    );
}

export default Skills;
