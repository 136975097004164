import React, {useCallback, useEffect, useState} from 'react';
import Loading, {useLoading} from "./Loading";
import ModalWindow from "./Modal";
import {useTrackedState, useDispatch} from "./store";
import ChannelIcon from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import {FlexScroll, Grid, TagInputContainer, TextInformationContainer} from "./styles";
import {TagInput} from "@dspworkplace/ui";
import {FilterTagArrayType} from "./types";
import {Channel} from "twilio-chat/lib/channel";
import {devLog} from "../utils";

function ArchiveGroupModal() {
    const {archiveChannels, archiveGroupChannelModal} = useTrackedState();
    const dispatch = useDispatch();
    const {loading, setLoading} = useLoading();
    const [matchedArchiveChannels, setMatchedArchiveChannels] = useState<Array<Channel>>([]);
    const [searchFilterArchiveChannels, setSearchFilterArchiveChannels] = useState<Array<FilterTagArrayType>>([]);

    const closeModal = useCallback(
        () => {
            dispatch({type: "CLOSE_ARCHIVE_GROUP_MODAL"});
        },
        [dispatch],
    );

    const openArchiveChannel = useCallback(
        (item: Channel) => () => {
            if (item && item.sid) {
                setLoading(true);
                dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: item});
                setLoading(false);
                closeModal();
            }
        },
        [closeModal, dispatch, setLoading]
    );

    const handleTagChange = useCallback(
        data => {
            devLog(data);
            let filterSearch = data.map(item => item.value);
            devLog(filterSearch);
            if (filterSearch.length === 0) return;
            let result = archiveChannels.filter(channel => {
                return filterSearch.some(item => item.indexOf(channel.friendlyName) >= 0);
            });
            setMatchedArchiveChannels(result);
        },
        [archiveChannels]
    );

    useEffect(() => {
        if (archiveChannels) {
            setMatchedArchiveChannels(archiveChannels);
            let searchFilter = archiveChannels.map(item => ({name: item.friendlyName, value: item.friendlyName}));
            setSearchFilterArchiveChannels(searchFilter);
            setLoading(false);
        }
    }, [archiveChannels, setLoading]);

    if(!archiveGroupChannelModal){
        return null;
    }

    if (loading) {
        return (
            <ModalWindow width={"700px"} title={"Archived Group Channels"}>
                <Loading containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"}/>
            </ModalWindow>
        );
    }

    return (
        <ModalWindow width={"700px"} title={"Archived Group Channels"} cancelModal={closeModal}>
            <Grid templateRows={"auto auto"} rowGap={"16px"}>
                <TagInputContainer width={"658px"} templateRows={"auto"}>
                    <TagInput
                        label="Find by Group Name"
                        options={searchFilterArchiveChannels}
                        size="big"
                        onChange={handleTagChange}
                        name={'names'}
                    />
                </TagInputContainer>
                <FlexScroll height={"250px"} width="100%">
                    <Grid templateColumns={"auto auto auto"} columnGap={"8px"} rowGap={"8px"}>
                        {matchedArchiveChannels.map(item => {
                            return (
                                <Grid
                                    key={item.sid}
                                    templateColumns={"40px auto"}
                                    height={"56px"}
                                    columnGap={"8px"}
                                    padding={"4px"}
                                    border={"1px dashed #7C98B6"}
                                    width={"100%"}
                                    borderRadius={"4px"}
                                    onClick={openArchiveChannel(item)}
                                >
                                    <ChannelIcon channel={item} kind={item.attributes['kind']}/>
                                    <TextInformationContainer>
                                        <ChannelTitle channel={item} kind={item.attributes['kind']}/>
                                        <ChannelSubtitle channel={item} kind={item.attributes['kind']}/>
                                    </TextInformationContainer>
                                </Grid>
                            );
                        })}
                    </Grid>
                </FlexScroll>
            </Grid>
        </ModalWindow>
    );
}

export default ArchiveGroupModal;