import React, { useReducer, useEffect } from "react";
import { portalPromise } from "../../../../../components/Dialog";
import { Table, TableData, TableHeader } from "../../../../../components/Table";
import Loading from "../../../../../components/Loading";
import {Icon, Spacer,Theme} from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import { useForm } from "react-hook-form";
import AddAndEditForm from "./AddAndEditForm";
import { fetchVendorLocations, updateVendorLocation, createVendorLocation, deleteVendorLocation } from "./utils";

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET":
            return { ...state, updateKey: -1, isEdit: false, isLoading: false };
        case "SET_DATA":
            return { ...state, data: action.data, isLoading: false, updateKey: -1 };
        case "ADD":
            return { ...state, data: [...state.data, action.item], updateKey: -1, isLoading: false };
        case "ARCHIVE":
            return { ...state, data: state.data.filter(e => e.id !== action.item.id), isLoading: false, updateKey: -1 }
        case "SET_LOADING":
            return { ...state, isLoading: action.isLoading };
        case "SET_UPDATE_KEY":
            return { ...state, updateKey: action.updateKey };
        case "EDIT_NAME":
            return {
                ...state,
                updateKey: -1,
                isLoading: false,
                data: state.data.map(e => {
                    if (e.id === action.item.id) {
                        return action.item;
                    }
                    return e;
                })
            }
        default:
            return state;
    }
};

const LocationForm = ({ success = () => { }, cancel = () => { }, vendor }) => {
    const [state, dispatch] = useReducer(reducer, {
        data: vendor.locations || [],
        updateKey: -1,
        isLoading: false,
        isEdit: false,
    });
    const { handleSubmit, errors, getValues } = useForm({
        defaultValues: vendor,
    });


    const archiveHandler = async (item) => {
        try {
            dispatch(({ type: 'SET_LOADING', isLoading: true }));
            let res = await deleteVendorLocation(item);
            dispatch({ type: 'ARCHIVE', item: item });
        } catch (e) {
            console.error({ e });
            dispatch(({ type: 'SET_LOADING', isLoading: false }));
        }
    }

    const addHandler = async (item) => {
        try {
            dispatch(({ type: 'SET_LOADING', isLoading: true }));
            let res = await createVendorLocation(vendor.id,item);
            dispatch(({ type: 'ADD', item: { id: res.id, name: res.name} }));
        } catch (e) {
            console.error({ e });
            dispatch(({ type: 'SET_LOADING', isLoading: false }));
        }
    }

    const editHandler = async (item) => {
        try {
            dispatch(({ type: 'SET_LOADING', isLoading: true }));
            let res = await updateVendorLocation(vendor.id,item);
            dispatch(({ type: 'EDIT_NAME', item: { id: res.id, name: res.name } }));
        } catch (e) {
            console.error({ e });
            dispatch(({ type: 'SET_LOADING', isLoading: false }));
        }
    }

    const cancelHandler = async () => {
        try {
            dispatch({ type: 'RESET' });
        } catch (e) {
            console.error({ e });
        }
    }

    useEffect(() => {
        if (vendor) {
            fetchVendorLocations(vendor.id, dispatch).then();
        }
    }, [vendor]);

    return (
        <Modal
            width={"650px"}
            visible={true}
            title={"Vehicle Maintenance Vendor's Location"}
            closeOnOverlay={false}
            setVisible={cancel}
            cancelLabel="Close"
        >
            <Spacer top={4} />
            <Table width={'550px'}>
                <TableHeader
                    headers={[
                        { width: '100%', label: 'Location' },
                    ]}
                    sortByFunc={undefined} sortedBy={undefined} compact={undefined}
                />
                {state.isLoading && <Loading style={{ height: '100px', marginTop: '20px' }} />}
                {!state.isLoading && state.data && Array.isArray(state.data) &&
                    state.data.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
                        if (index === state.updateKey) {
                            return (
                                <AddAndEditForm key={index} index={index} isEdit={true} dispatch={dispatch}
                                    data={state}
                                    selected={item} add={addHandler} edit={editHandler}
                                    cancel={cancelHandler} />
                            );
                        }
                        return (
                            <li key={index}>
                                <TableData width='366px'>{item.name}</TableData>
                                <TableData width='84px' style={{ verticalAlign: 'middle', height: '40px' }}>
                                    <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => {
                                        dispatch({ type: 'SET_UPDATE_KEY', updateKey: index });
                                    }} />
                                    <Spacer inline right={3} />
                                    <Icon.Times size='20px' color={Theme.colors.info.border} onClick={() => {
                                        archiveHandler(item).then()
                                    }} />
                                </TableData>
                            </li>
                        )
                    })
                }
                {!state.isLoading && state.data && <AddAndEditForm
                    dispatch={dispatch}
                    data={state} isEdit={false} add={addHandler} edit={editHandler}
                    cancel={cancelHandler} />}
            </Table>
        </Modal>
    );
};

export const openLocationForm = (vendor = { phone: '', name: '', location: '' }) => {
    return portalPromise((success, cancel) => {
        return <LocationForm success={success} cancel={cancel} vendor={vendor}  />;
    });
};
