import React, {useEffect, useRef, useState, useCallback} from "react";
import {Icon, Input, Button} from '@dspworkplace/ui'
import {EditorProps} from "react-data-grid";
import styled from "styled-components";
import { confirm as confirmPortal } from "../../../../components/Confirm";

const SmallInput = styled.div`
    display: flex;
    height: 100%;
    >div {
        height: 100%;
        flex-grow: 1;
        width: auto;
        >div {
            width: 100%;
            height: 100%;
            input {
                width: 100%;
                padding: 8px 4px;
                height: 100%;
            }
        }
    }
    
    button {
        height: 100%;
        line-height: 1;
        padding:0 2px;
        width: 32px;
    }
`;

const handleInitialValue = (row,column) => {
    const columnKeyArray = column.key.split('.')
    return columnKeyArray.reduce((acc,col)=>{
        acc = acc[col]
        return acc
    },row)
}

const NumberInput = (props) => {
    const {row, column, onClose, onRowChange, isCellSelected} = props

    const [number, setNumber] = useState(() => handleInitialValue(row,column) ?? null)
    const inputRef = useRef<HTMLInputElement>(null);

    const commitChanges = useCallback(async(number) => {
        const result = await confirmPortal(`Do want to assign "${column.name}" to "${number}" on the ${row.id}?`)
        const { current } = inputRef
        if (current) {
            current.focus();
        }

        if (!result) {
            onRowChange({...row},true);
            onClose();
            return
        }
        
        const fieldArray = column.key.split('.')

        if(fieldArray.length <= 1){
            row[column.key] = number;
        }else{
            row[fieldArray[0]][fieldArray[1]] = number;
        }

        onRowChange({...row,[row[column.key]]:number},true)
    },[row, column.key, onRowChange])

    const handleNumberEvent = (e) => {
        commitChanges(e.target.value)
        setNumber(e.target.value)
    }

    const handleKey = useCallback((e)=>{
        e.stopPropagation();
        if(e.keyCode === 13) {
            setNumber(e.target.value)
            commitChanges(e.target.value)
            // onClose()
        }

        if(e.keyCode === 27) {
            onClose()
        }
    },[commitChanges,setNumber,onClose])

    useEffect(()=>{
        const {current} = inputRef
        if(current === null) return

        if(current){
            current.focus();
            current.addEventListener('keydown',handleKey);
        }

        if(!inputRef?.current){
            current.removeEventListener('keydown',handleKey)
        }

        return ()=> {
            if(current){
                current.removeEventListener('keydown',handleKey)
            }
        }
    },[handleKey, inputRef])

    return (
        <SmallInput>
            <Input
                ref={inputRef}
                type='number'
                size='extraSmall'
                defaultValue={number}
                onBlur={handleNumberEvent}
            />
            <Button onClick={()=>onClose()}>
                <Icon.Check
                    size={'20px'}
                    style={{cursor: "pointer"}}
                />
            </Button>
        </SmallInput>
    )
}
export default NumberInput