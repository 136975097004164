import React from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {RangeNumberType, useStoreReducer} from "./store";
import {Button} from '@dspworkplace/ui';
import styled from "styled-components";
import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";

export const CheckboxesContainerMileageType = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    height: 40px;

    input.name {
        width: 320px;
        margin-right: 32px;
    }

    input.checked {
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.forDriver {
        margin-left: 64px;
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.hide {
        display: none;
    }
`;

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: RangeNumberType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function MileageQuestionForm(props: Props) {
    const {appState} = useStoreReducer();
    const {item, updateItem} = props;
    const {
        name, description, isPassRequired,
        endRange, startRange, isCommentRequired, isPhotoRequired,
        notifyIfChosen, notifyOutOfRange, isRangeRequired, vehicleImageCategories, disabledQuestion
    } = item.content;
    const {register, handleSubmit, reset, watch, control} = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            endRange,
            startRange,
            isPhotoRequired,
            isCommentRequired,
            notifyIfChosen,
            notifyOutOfRange,
            isRangeRequired,
            vehicleImageCategories: Array.isArray(vehicleImageCategories) && vehicleImageCategories.length > 0 ? vehicleImageCategories : appState.vehicleImageCategories,
            disabledQuestion
        }
    });
    const isPhotoRequiredWatch = watch("isPhotoRequired");
    const {fields} = useFieldArray<VehicleImageCategory>({
        name: "vehicleImageCategories",
        control
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            {/*<label>Enable Range?</label>*/}
            {/*<input name="isRangeRequired" type="checkbox" className={'checkbox'} ref={register}/>*/}
            {/*{isRangeEnabled &&*/}
            {/*<>*/}
            {/*    <label htmlFor="startRange">Min. Range</label>*/}
            {/*    <input name="startRange" type="number" ref={register({*/}
            {/*        min: 0*/}
            {/*    })} defaultValue={0}/>*/}
            {/*    <label htmlFor="endRange">Max. Range</label>*/}
            {/*    <input name="endRange" type="number" ref={register({*/}
            {/*        required: true*/}
            {/*    })}/>*/}
            {/*</>}*/}
            <label>Is required to Pass?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {/*<label>Notify when step is completed?</label>*/}
            {/*<input name="notifyIfChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
            {/*<label>Notify if is out of range?</label>*/}
            {/*<input name="notifyOutOfRange" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <label>Require picture</label>
            <input name="isPhotoRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {isPhotoRequiredWatch && <div className={'choices'}>Vehicle Image Category</div>}
            <div>
                {isPhotoRequiredWatch && fields.map((field, index) => {
                    return (
                        <CheckboxesContainerMileageType key={field.id}>
                            <input
                                className={'name'}
                                name={`vehicleImageCategories[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                                disabled={true}
                            />
                            <input
                                className={'hide'}
                                name={`vehicleImageCategories[${index}].id`}
                                defaultValue={field.id}
                                ref={register()}
                                disabled={true}
                                hidden={true}
                            />
                            <input
                                name={`vehicleImageCategories[${index}].checked`}
                                type="checkbox"
                                className={'checked'}
                                defaultChecked={!!field.checked}
                                ref={register()}/>
                        </CheckboxesContainerMileageType>
                    );
                })}
            </div>
            {!isPhotoRequiredWatch && <div/>}
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default MileageQuestionForm;