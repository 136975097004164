import React, {useCallback, useEffect, useState} from "react";
import {useUpdate as useBotDispatch} from "./BotStore";
import {completeTask, deleteTwilioMessage} from "./utils";
import BotShowBadMessage from "./BotShowBadMessage";
import Loading from "./Loading";
import {Message} from "twilio-chat/lib/message";
import {Button} from "@dspworkplace/ui";
import styled from 'styled-components';
import {showErrorToast} from "../Utilities";
import {Task} from "./types";
import { engine } from "../Utilities";

export const ReviewButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0 0 8px;
    width: 100%;
`;

type TaskButtonProps = {
    reviewHandler?: () => void;
    attributes: Object | any;
    text?: string;
    message: Message;
    identity: string;
};

export function TaskButton(props: TaskButtonProps) {
    const {reviewHandler, attributes, message, identity} = props;
    const [showReview, setShowReview] = useState(false);
    const [showBadWordsType, setShowBadWordsType] = useState(false);
    const [showNotificationType, setShowNotificationType] = useState(false);
    const [showIncidentForm, setShowIncidentForm] = useState(false);
    const [showUnknownNotificationType, setShowUnknownNotificationType] = useState(false);
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState<Task>();
    const dispatch = useBotDispatch();

    const getHrFormIncident = async (taskId)=> {
        const params = {
            actions: {
                response: {
                   Task:{
                        custom: {
                            functionName: "findTask",
                            get: "task",
                            criteria: {
                                taskId: taskId
                            },
                            includes:{
                                0:"id",
                                hrFormIncident:{
                                    0:"id"
                                }
                            }
                        }
                   }
                },
            },
        };
        try{
            setLoading(true);
            const response = await engine().post('/api/lazy/manage/data', params);
            return response.data.data.task.hrFormIncident.id;
        } catch(e) {
            await showErrorToast(e, 'Error deleting message.');
        } finally {
            setLoading(false);
        }
       
    };

    const hrIncidentFormClickHandler = async () => {
        if(task){
            if(task.hrFormIncidentId){
                window.open(`/incidents/${task.hrFormIncidentId}`, '_blank');
            }else{
                await showErrorToast("incident not founded.", 'Error deleting message.');
            }
        }
    }

    const deleteMessageAndCompleteTask = useCallback(
        async () => {
            setLoading(true);
            try {
                if (task && task['type'] === 'BAD_WORDS') {
                    let result = await completeTask(task, message);
                    if (result) {
                        dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
                    }
                } else if (task && task['type'] === 'NOTIFICATION') {
                    let result = await completeTask(task, message);
                    if (result) {
                        dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
                    }
                } else if (task && task['type'] === 'HR_INCIDENT_FORM') {
                    let result = await completeTask(task, message);
                    if (result) {
                        dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
                    }
                } else {
                    throw new Error('No type for Task is defined.' + JSON.stringify(task));
                }
            } catch (e) {
                await showErrorToast(e, 'Error deleting message.');
            }
            setLoading(false);
        },
        [attributes, dispatch, message, task],
    );

    const deleteMessage = useCallback(
        async () => {
            setLoading(true);
            try {
                await deleteTwilioMessage(message.channel.sid, message.sid, attributes);
            } catch (e) {
                await showErrorToast(e, 'Error deleting message.');
            }
            setLoading(false);
        },
        [attributes, dispatch, message],
    );

    useEffect(() => {
        setLoading(true);
        let taskData;
        if (attributes["task"]) {
            if (attributes['task'] && attributes['task']['type']) {
                taskData = attributes["task"];
                setTask(taskData);
                if (taskData["type"] === "BAD_WORDS") {
                    setShowBadWordsType(true);
                } else if (taskData["type"] === "NOTIFICATION") {
                    setShowNotificationType(true);
                } else if (taskData["type"] === "HR_INCIDENT_FORM") {
                    setShowIncidentForm(true);
                } else {
                    setShowReview(true);
                }
            }
        } else if (attributes['id'] && attributes['type']) {
            taskData = attributes;
            setTask(taskData);
            if (taskData["type"] === "BAD_WORDS") {
                setShowBadWordsType(true);
            } else if (taskData["type"] === "NOTIFICATION") {
                setShowNotificationType(true);
            }
        } else if (attributes['companyId']) {
            setShowUnknownNotificationType(true);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if(task){
            if(task['type'] === 'HR_INCIDENT_FORM' && !task.hrFormIncidentId)
            getHrFormIncident(task.id).then(res => {
                const newTask = {...task, hrFormIncidentId:res};
                setTask(newTask);
            })
        }
    },[showIncidentForm]);

    if (loading) {
        return <Loading containerHeight={'28px'} containerWidth={'100%'} imgHeight={'auto'} imgWidth={'16px'}/>
    }

    if (showReview) {
        return <ReviewButton onClick={reviewHandler}>Review</ReviewButton>;
    }

    if (showBadWordsType) {
        return (
            <>
                <BotShowBadMessage identity={identity} text={attributes.message}/>
                <ReviewButton onClick={deleteMessageAndCompleteTask}>Clear Message</ReviewButton>
            </>
        );
    }
    if (showIncidentForm) {
        return (
            <>
               {task && task.hrFormIncidentId && <ReviewButton onClick={hrIncidentFormClickHandler}>
                    Review
                </ReviewButton>}
                <ReviewButton onClick={deleteMessageAndCompleteTask}>
                    Complete
                </ReviewButton>
            </>
        );
    }

    if (showNotificationType) {
        return (
            <>
                <ReviewButton onClick={deleteMessageAndCompleteTask}>
                    Clear Message
                </ReviewButton>
            </>
        );
    }

    if (showUnknownNotificationType) {
        return (
            <>
                <ReviewButton onClick={deleteMessage}>
                    Clear Message*
                </ReviewButton>
            </>
        );
    }

    return null;
}