import React,{useState} from 'react';
import styled from 'styled-components';
import Logo from '../../assets/logo.svg';
import {Theme, ThirdTitle, Spacer, Text, Input, Password, Button,Alert} from '@dspworkplace/ui';
import {useForm} from "react-hook-form";
import {patternEmail} from '../../patterns';
import {Link, Redirect} from 'react-router-dom';
import { useAuth } from "../../Auth";
import Footer from '../../components/Footer';
import Parser from 'html-react-parser';

const Body  = styled.div`
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items: stretch;
`;

const Section = styled.section`
    background:${Theme.colors.info.bg}
    display: flex;
    min-height: 100%;
    justify-content:center;
    align-items:center;
    flex-grow:1;

`;

const Card = styled.div`
    background:${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px #33333326;
    padding:20px;
    border-radius:${Theme.defaultRadius};
    max-width:340px;
`;

const Image = styled.img`
    width:300px;
    margin:0 auto;
    display:block;
    height:auto;

`;

const Btn = styled(Button)`
    width:100%;
`;

const BtnCentered = styled(Btn)`
    text-align:center;

`;



const errorMessages = {
    email: {
        required: 'Please provide email',
        pattern: 'Please provide valid email address'
    }
}
async function checkUserAuth(data,setUserError, changeRoute, api){
    try {
        const response = await api.post('/api/forgot-password', {
            email: data.email
        });
        if( response.status === 200 ){
            if(response.data.data){
                setUserError({error:1,msg:"Assuming the email address entered is valid, we\'ve sent a message to <b>"+data.email+"</b> to help you reset your password.<br><br>Thank you for your patience."});
            } else {
                setUserError({error:0,msg:response.data.message+' '+'If any issues please contact support@dspworkplace.com.'});
            }
        }
    } catch (error) {
            if(error.response){
                setUserError({error:0,msg:error.response.data.message});
            }
    }
}

const App = (changeRoute) => {
    const { register, handleSubmit, errors, getValues  } = useForm();
    const {email, password} = getValues();
    const [userError, setUserError] = useState({error:2,msg:''});
    const { api } = useAuth();
    const onSubmit = data =>
    {
        checkUserAuth(data,setUserError, changeRoute, api);
    }
    /*
    if(localStorage.getItem('userId')){
        return <Redirect to='/home'/>;
    }*/

    return (
        <Body>
            <Section>
                <div>
                    <Image src={Logo} />
                    <Spacer top={6} />
                    <Card>
                        <ThirdTitle>Forgot Password</ThirdTitle>
                        <Spacer top={2} />

                        { userError.error == 0 ? <Alert.Error title='Error' content={Parser(userError.msg)} /> : userError.error == 1 ? <Alert.Success title='Success' content={Parser(userError.msg)} /> : '' }
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                name='email'
                                label='Enter your registered email address'
                                ref={register({
                                    required:true,
                                    pattern: patternEmail
                                })}
                                error={errors.email && errorMessages.email[errors.email.type]}
                                valid={!errors.email && email && email.length > 0}
                                autoComplete='off'

                            />

                            <Spacer top={4} />

                            <Btn type='primary'>Reset Password</Btn>
                            <Spacer top={1} />
                        </form>
                    </Card>
                    <Spacer top={6} />
                    <Link to='/login'><BtnCentered type='cancel' size='small' >Login</BtnCentered></Link>
                </div>
            </Section>
            <Footer />
        </Body>
    );
}

export default App;
