const AppReduce = (state,action) => {
    const {type, payload} = action;

    switch (type) {
        case 'ADD':
            return {...state,data:payload,isLoading:false,week:action.week,year:action.year}
        case 'ADD_TEMP_DATA':
            return {...state,tempData:action.tempData}
        case 'ADD_BALANCE_GROUP':
            return {...state,balanceGroup:action.balanceGroup}
        case 'ADD_GROUP_OBJ':
            return {...state,balanceGroupObj:action.balanceGroupObj}
        case 'ADD_STATIONS':
            return {...state,stations:action.stations}
        case 'ADD_STATION':
            return {...state,station:action.station}
        case 'ADD_PREVIOUS':
            return {...state,previousData:action.previousData}
        case 'COPY':
            return {...state,data:state.previousData,tempData:state.previousData}
        case 'DELETE':
            state.data.splice(action.key,1);
            return {...state};
        case 'CHANGE':
            const {rowIndex,shiftIndex,column,oldValue,value} = payload;
            const d = state.data[rowIndex];
            d.shifts[shiftIndex][column] = +value;

            d[column] += +value - +oldValue;
            return {...state}
        case 'GROUP':
            state.grouped = payload;

            state.sortedBy[0] = state.grouped ? {
                field: 'balanceGroup',
                direction: 0
            } : {
                field: 'shiftType',
                direction: 0
            };

            state.data = sortBy(state.data, state.sortedBy[0]);

            return {...state}
        case 'LOADING':
            state.isLoading = payload;
            return {...state}

        case 'SORT':
            let sort = state.sortedBy[0];

            if ( !sort )
                sort = {field: payload, direction: 0}
            else
                if ( payload === sort.field )
                    sort.direction = !sort.direction
                else
                    sort = {
                        field: payload,
                        direction: 0,
                    }

            let data = sortBy(state.data, sort);

            return {...state, data: data, sortedBy: [sort]};
        default: return state;
    }
}

export default AppReduce;


const sortBy = ( collection, criteria ) => {
    collection.sort((a, b) => {
        a.total = [a.w0, a.w1, a.w2, a.w3, a.w4, a.w5, a.w6]
            .reduce((a,b) => a + b, 0)
            .toString();

        a.shiftType = a.shifts
            .sort((a, b) => a.shift.localeCompare(b.shift, undefined, {numeric: true}))
            .reduce((a, b) => a.shift + '-' + b.shift, '');

        if ( a.hasOwnProperty(criteria.field) && typeof a[criteria.field] === 'string' )
            return a[criteria.field].localeCompare(b[criteria.field], undefined, {numeric: true});
    });

    if ( criteria.direction )
        collection.reverse();

    return collection;
}
