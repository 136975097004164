import React, {useRef} from 'react';
import {useDrop,useDrag} from "react-dnd";

const TYPE = 'SLIDE';

const SlideDraggable = ({children,index,moveCard,isDeleted,...props}) => {
    const dragRef = useRef(null);

    const [{isOverCurrent}, drop] = useDrop({
        accept: TYPE,
        hover(item,monitor){
            if(!dragRef.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 1.5;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOverCurrent: monitor.isOver({ shallow: true }),
        })
    });

    const [{ isDragging }, drag,preview] = useDrag({
        item: { type: TYPE, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(dragRef));

    return (
        <React.Fragment>
            {children({dragRef,previewRef:preview,opacity,isOverCurrent})}
        </React.Fragment>
    )
};

export {SlideDraggable,TYPE};