import React, {useMemo, useCallback, useState, useEffect} from 'react';
import './Grid.css';
import {Icon, Spacer, Theme, Title, Button, Dropdown} from '@dspworkplace/ui';
import {default as ReactGrid, SortIconProps, Row} from 'react-data-grid';
import styled from 'styled-components';
import Empty from '../../components/Empty';
import Loading, {LoadingWrapper} from '../../components/Loading';
import {Tools, ToolGroup, ToolGroupFunc, Filter} from './Tools';
import {GridProps} from "./types";
import useSortColumn from "./hooks/useSortColumn";
import useGroupColumn from "./hooks/useGroupColumn";
import useFilter from "./hooks/useFilter";
import useColumnResolver from "./hooks/useColumnResolver";
import {AutoSizer} from 'react-virtualized';
import { Action } from "../../../src/pages/Scheduler/Common/UI";

import StationSelector from "../StationSelector";
import {GridLegend} from './Legend/Legend'

import SSkeletonPulse from '../Skeleton'


const FallbackContainer = styled.div`
    text-align: center;
    grid-column: 1/-1;
    margin-top: 20px;
`;

const SorterIcon = ({sortDirection}: SortIconProps) => {
    const iconSize = '14px';
    if(sortDirection === undefined) return <Icon.ArrowDown size={iconSize} color={Theme.colors.neutrals.silver} />
    if(sortDirection === 'ASC') return <Icon.ArrowUp size={iconSize} color={Theme.colors.secondary} />;
    return <Icon.ArrowDown size={iconSize} color={Theme.colors.secondary} />
}

// @ts-ignore
const columnRenderSkeleton =(column) => {
    const newColumn = {...column}
    newColumn.formatter = () => {
        return <SSkeletonPulse style={{width:'100%',height:26,verticalAlign:'middle'}}/>
    }
    newColumn.editor = false

    return newColumn
}

const RowRender = (props) => {
    const viewportColumns = props.row?._STATUS === 'LOADING-ROW' ? props.viewportColumns.map(columnRenderSkeleton) : props.viewportColumns
    props.row._INDEX = props.rowIdx
   return <Row {...props} viewportColumns={viewportColumns} />
}

const DropdownWrapper = styled.span`
    > div > .just-options > ul {
        right: 0;
        left: unset;
    } 
`;

const Grid = (props: GridProps) => {
    let {
        status = 'pending',
        rows = [],
        columns,
        onSort,
        onFilter,
        onEditorChange, title:TitleProp,
        groupBy = [],
        onAdd,
        onPrint,
        onExport,
        sortColumns,
        isStation = true,
        setCustomGroup = ()=>{},
        setCustomRowClass = null,
        defaultFiltersSelected,
        storeFilters = (e) => { },
        setGroupByFilter = (e) => { }
    } = props;

    const [options, setOptions] = useState([]);
    
    const RowClassGetter = (row)=>{
        let className = row?._STATUS;
        if(setCustomRowClass && typeof setCustomRowClass == "function"){
            className+=" "+setCustomRowClass(row);
        }
        return className;
    }

    useEffect(() => {
        let tempOption = [];
        if (onPrint) {
            tempOption.push({name: 'Print',value:"print", icon: Icon.Printer,onClick: (rows)=>{onPrint(rows,selectedGroups[0])}})
        }
        if (onExport) {
            tempOption.push({ name: 'Export CSV', value:"csv", icon: Icon.Download, onClick: (rows) => { { onExport(rows) } } })
        }
        setOptions(tempOption);
    }, [onExport,onPrint]);
    
    const components = useMemo(()=>{
        const components = {
            sortIcon: SorterIcon,
            noRowsFallback: <FallbackContainer><Empty /></FallbackContainer>,
            rowRenderer: RowRender
        }
        if(status === 'pending'){
            components.noRowsFallback = <FallbackContainer><LoadingWrapper><Loading /></LoadingWrapper></FallbackContainer>;
        }
        return components
    },[status])

    const { filtersSelected, setAllFilters } = useFilter(onFilter)
    const filtersSelectedData = (Object.keys(filtersSelected).length === 0) ? defaultFiltersSelected?.filters : filtersSelected;
    // @ts-ignore
    const {sortedColumns, onSortColumnsChange} = useSortColumn(onSort,sortColumns)
    const {selectedGroups, setGroup, ExpandGroupById, expandedIds} = useGroupColumn(rows,groupBy);
    const { columnsArray, groupsAvailable, filtersAvailable, legendsAvailable } = useColumnResolver(columns, selectedGroups)
    const [oldValueGroupBy, setOldValueGroupBy] = useState(groupBy[0]);
    
    useEffect(() => {
        storeFilters(filtersSelectedData);
    }, [filtersSelected]);

    useEffect(() => {
        if (selectedGroups.length > 0) {
            //Groups by according set filters
            let orderBy = 'DESC';
            if (sortedColumns.length > 0) {
                let order = sortedColumns[0]?.direction !== undefined ? sortedColumns[0]?.direction : orderBy;
                setGroupByFilter({ fields: [{ columnKey: selectedGroups[0], direction: order }] });
            } else {
                if (selectedGroups[0] !== oldValueGroupBy) {
                    setOldValueGroupBy(selectedGroups[0]);
                    setGroupByFilter({ fields: [{ columnKey: selectedGroups[0], direction: orderBy }] });
                }
            }
            setCustomGroup(selectedGroups);
        } else {
            setCustomGroup([]);
            setOldValueGroupBy('');
            setGroupByFilter({ fields: [] });
        }
    }, [selectedGroups]);

    const TitleRender = useCallback(()=>{
        if(!TitleProp) return <span></span>
        if (typeof TitleProp === 'string') {
            return <Title style={{fontSize:32}}>{TitleProp}</Title>
        }
        return <TitleProp />
    },[TitleProp])

    const rowGrouper = (rows)=>{
        return ToolGroupFunc(rows,selectedGroups[0]);
    }
    const keyGetter = (row) => {
        return Math.random().toString(16).slice(2) + '-'+ row.id;
    }

    const rowsChange = (rows,RowsChangeData)=>{
        if(onEditorChange){
            const { indexes, column } = RowsChangeData;
            const index = indexes[0];
            const keys = Object.values(column.key.split('.'));
            const value = keys.reduce((acc,key)=>{
                // @ts-ignore
                return acc[key]
            },rows[index]);
            onEditorChange({value,index: index, row: rows[index], column: column});
        }
    }

    let RowHeight = props.rowHeight || 60;
    if(selectedGroups?.length && props.rowHeightGrouped) RowHeight = props.rowHeightGrouped

    return (
        <AutoSizer>
            {({height,width})=> {
                if(height < 1) return false;
                // @ts-ignore
                return (
                    <div style={{ height: height - 60, width: width }}>
                        {TitleProp == "Maintenance TaskTracker" && <Spacer top={2} />}
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TitleRender />
                                {legendsAvailable.length > 0 && (
                                    <Spacer left={2}>
                                        <GridLegend legendFields={legendsAvailable} />
                                    </Spacer>
                                )}
                            </div>
                            <Tools>
                                {onAdd && (
                                    <Button type={"primary"} onClick={onAdd}>
                                        Add New
                                    </Button>
                                )}
                                {options.length > 0 && (
                                    <>
                                        <Action
                                            type="cancel"
                                            compact={true}
                                            onClick={() => {
                                                document.getElementById(`main-menu-option`).click();
                                            }}
                                        >
                                            <Icon.Download size="28px" color={Theme.colors.secondary} />
                                        </Action>
                                        <DropdownWrapper>
                                            <Dropdown
                                                className="just-options"
                                                id={`main-menu-option`}
                                                style={{ marginLeft: "-45px" }}
                                                options={options}
                                                onSelect={(option) => option.onClick(rows)}
                                                visibleOptionsQty={100}
                                            />
                                        </DropdownWrapper>
                                    </>
                                )}
                                {isStation && <StationSelector />}
                                {groupsAvailable.length > 0 && (
                                    <ToolGroup
                                        groupBy={groupsAvailable}
                                        setGroup={setGroup}
                                        selectedGroups={selectedGroups}
                                    />
                                )}
                                {filtersAvailable.length > 0 && (
                                    <Filter
                                        options={filtersAvailable}
                                        setAllFilters={setAllFilters}
                                        selectedFilters={filtersSelectedData}
                                        defaultFiltersSelected={defaultFiltersSelected}
                                    />
                                )}
                            </Tools>
                        </div>
                        {TitleProp === "Maintenance TaskTracker" || TitleProp === "Maintenance Templates" ? (
                            <Spacer top={8} />
                        ) : (
                            <Spacer top={4} />
                        )}
                        <ReactGrid
                            className={"rdg-light fill-grid dsp-header-show-text"}
                            components={components}
                            headerRowHeight={38}
                            onExpandedGroupIdsChange={ExpandGroupById}
                            expandedGroupIds={expandedIds}
                            rowGrouper={rowGrouper}
                            rowKeyGetter={keyGetter}
                            {...props}
                            onRowsChange={rowsChange}
                            rows={rows}
                            onSortColumnsChange={onSortColumnsChange}
                            sortColumns={sortedColumns}
                            rowHeight={RowHeight}
                            columns={columnsArray}
                            groupBy={selectedGroups}
                            style={{ gridAutoRows: `${RowHeight}px` }}
                            rowClass={RowClassGetter}
                        />
                        {rows.length > 1 && status == "pending" && (
                            <LoadingWrapper>
                                <Loading />
                            </LoadingWrapper>
                        )}
                    </div>
                );
            }}
        </AutoSizer>
    )
};


export default Grid;