import React, {useState} from 'react';
import styled from 'styled-components';
import {dialogPromise} from "../../../components/Dialog";
import Modal from "../../../components/Modal";
import { Button, Spacer, Dropdown } from "@dspworkplace/ui";
import API from '../api';
import Loading from "../../../components/Loading";
import DashboardItem from '../components/dashboardItem';
import {CubeProvider} from "@cubejs-client/react";
import cubeApi from "../../../api/cube";
import {generateActions} from "../useDashboardState";
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";

const ChartBoxList = styled.div`
    display:grid;
    grid-auto-rows:300px;
    grid-gap:20px;
    
    .cbox {
        width: 470px;
        .createBtn {
            display:none;
            position:absolute;
            margin: auto;
            left: calc(50% - 65px);
            right: 0;
            top: 0;
            bottom: 0;
            z-index:11;
            cursor:pointer;
        }
        .bg {
            display:none;
            background:rgb(234, 240, 246,0.3);
            width:100%;
            height:100%;
            position:absolute;
            z-index:10;
            left:0;
            top:0;
            width: 470px;
        }
        
        &:hover {
            .bg, .createBtn {
                display:block;
            }
        }
    }
`;

const AddChartForm = ({ success, cancel, idPanel, cardConfig, dispatch }) => {
    const [state,setState] = useState({status:'pending',categories:[],currentCategory:0,categoryOptions:[]});
    const actions = generateActions(dispatch,'dashboard');

    React.useEffect(()=>{
        API.getChartLibraryCategories().then(
            resolve => {
                const categories = resolve.data.data.categories;
                const categoryOptions = categories.map((c)=>({value:c.id,name:c.name}))
                setState({...state,status:'ready',categories:categories,categoryOptions:categoryOptions})
            },
            error => {

            }
        );
    },[]);

    return (
        <Modal
            width={'510px'}
            visible={true}
            title={'Add Chart'}
            closeOnOverlay={false}
            setVisible={cancel}
            drawer={'right'}
        >
            {state.status === 'pending'
                ?
                    <Loading style={{height: 80,margin:'50px auto',display:'block'}} />
                :
                    <>
                        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                        <Dropdown
                            name={'category'}
                            placeholder={'Choose a category'}
                            options={state.categoryOptions}
                            size={'big'}
                            onChange={(opt)=>{
                                setTimeout(() =>setState({...state,currentCategory:opt.value}),10)
                            }}
                        />
                        <Spacer top={4} />
                        <CubeProvider cubejsApi={cubeApi}>
                            <ChartBoxList>
                                {state.categories.filter((c)=> c.id === state.currentCategory)[0]?.charts?.map((c,k)=>{
                                    return(
                                        <div key={k} style={{position:'relative'}} className={'cbox'}>
                                            <Button
                                                type={'primary'}
                                                className={'createBtn'}
                                                onClick={ async () => {
                                                    success(c, true);
                                                    await actions.addChartCustom(idPanel, c, cardConfig);
                                                }}
                                            >
                                                Add To Panel
                                            </Button>
                                            <div className={'bg'}></div>
                                            <DashboardItem
                                                item={c}
                                                i={k}
                                                hideFilterBtn={true}
                                            />
                                        </div>
                                    )
                                })}
                            </ChartBoxList>
                        </CubeProvider>
                        </DndProvider>
                    </>
            }
        </Modal>
    )
}

export const openAddChartForm = (idPanel, cardConfig, dispatch) => {
    return dialogPromise((success, cancel)=>{
        return <AddChartForm
            success={success}
            cancel={cancel}
            idPanel={idPanel}
            cardConfig={cardConfig}
            dispatch={dispatch}
        />
    })
};