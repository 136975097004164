import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useTrackedState } from "./store";
import { Icon, Button, Spacer } from "@dspworkplace/ui";
import ChannelRow from "./ChannelRow";
import { Text, ReturnButton } from "./styles";

const ChatSidebarContainer = styled.div`
    display: ${props => (props.display ? props.display : "grid")};
    width: 300px;
    height: calc(100vh - 64px);
    box-shadow: 0 3px 6px #00000029;
    position: fixed;
    bottom: ${props => (props.bottom ? props.bottom : "5px")};
    right: ${props => (props.right ? props.right : "5px")};
    background-color: white;
`;

const ChannelsScrollContainer = styled.div`
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 73px);
    justify-content: flex-start;
    flex-direction: column;
    width: 300px;

    &::-webkit-scrollbar {
        width: 0;
    }
`;

const RowChannelKind = styled.div`
    display: grid;
    width: 260px;
    height: 44px;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding: 8px 0 0 0;
    cursor: default;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`;

const RowOptions = styled.div`
    display: grid;
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: 16px;
    grid-template-columns: repeat(3, auto);
    margin: 2px 16px;
`;

const countPerLoad = 20;

// @ts-ignore
const DuoChannels: React.FC = () => {
    const state = useTrackedState();
    const { duoChannels, closedCount } = state;
    const [duoCount, setDuoCount] = useState(countPerLoad);

    const handleMoreDuo = () => {
        setDuoCount(duoCount + countPerLoad);
    };

    return (
        <>
            {duoChannels?.slice(0, duoCount)?.map((item, index) => {
                if (index <= 300) {
                    return <ChannelRow closedCount={closedCount} key={`${item.sid}-${JSON.stringify(item.friendlyName)}`}
                        channel={item} kind={item.attributes['kind']} />;
                }
                return null;
            })}
            <Spacer top={2} />
            {duoCount < duoChannels?.length && (
                <Button
                    size="small"
                    onClick={handleMoreDuo}>
                    Load more
                    <Icon.Refresh size="16px" style={{ verticalAlign: 'middle' }} />
                </Button>
            )}
        </>
    )
}

const GroupChannels: React.FC = () => {
    const state = useTrackedState();
    const { groupChannels, closedCount } = state;
    const [groupCount, setGroupCount] = useState(countPerLoad);

    const handleMoreGroups = () => {
        setGroupCount(groupCount + countPerLoad);
    };

    return (
        <>
            {groupChannels?.slice(0, groupCount)?.map((item) => (
                <ChannelRow closedCount={closedCount} key={`${item.sid}-${JSON.stringify(item.friendlyName)}`} channel={item}
                    kind={item.attributes['kind']} />
            ))}
            <Spacer top={2} />
            {groupCount < groupChannels?.length && (
                <Button
                    size="small"
                    onClick={handleMoreGroups}>
                    Load more
                    <Icon.Refresh size="16px" style={{ verticalAlign: 'middle' }} />
                </Button>
            )}
        </>
    )
}
//@ts-ignore
const BotChannels: React.FC = () => {
    const state = useTrackedState();
    const { botChannels, closedCount } = state;
    const [botCount, setBotCount] = useState(countPerLoad);

    const handleMoreBot = () => {
        setBotCount(botCount + countPerLoad);
    };

    return (
        <>
            {botChannels?.slice(0, botCount)?.map(item => (
                <ChannelRow closedCount={closedCount} key={`${item.sid}-${JSON.stringify(item.friendlyName)}`} channel={item}
                    kind={item.attributes['kind']} />
            ))}
            <Spacer top={2} />
            {botCount < botChannels?.length && (
                <Button
                    size="small"
                    onClick={handleMoreBot}>
                    Load more
                    <Icon.Refresh size="16px" style={{ verticalAlign: 'middle' }} />
                </Button>
            )}
        </>
    )
}

const PinChannels: React.FC = () => {
    const state = useTrackedState();
    const { pinChannels, closedCount } = state;
    const [pinCount, setPinCount] = useState(countPerLoad);

    const handleMorePin = () => {
        setPinCount(pinCount + countPerLoad);
    };

    if (pinChannels && pinChannels.length > 0) {
        return (
            <>
                <RowChannelKind>
                    <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                        Pin
                    </Text>
                </RowChannelKind>
                {pinChannels?.slice(0, pinCount)?.map(item => (
                    <ChannelRow closedCount={closedCount} key={`${item.sid}-${JSON.stringify(item.friendlyName)}`} channel={item}
                        kind={item.attributes['kind']} />
                ))}
                <Spacer top={2} />
                {pinCount < pinChannels?.length && (
                    <Button
                        size="small"
                        onClick={handleMorePin}>
                        Load more
                        <Icon.Refresh size="16px" style={{ verticalAlign: 'middle' }} />
                    </Button>
                )}
            </>
        )
    }
    return null;
}

const ChatSidebar: React.FC = () => {
    const dispatch = useDispatch();
    const state = useTrackedState();
    const { initialChannelsReady, showFilterForWords } = state;

    if (!initialChannelsReady) {
        return null;
    }

    return (
        <>
            <ChatSidebarContainer>
                <ChannelsScrollContainer>
                    <RowOptions>
                        {/*<ReturnButton*/}
                        {/*    width={"30px"}*/}
                        {/*    height={"30px"}*/}
                        {/*    borderRadius={"50%"}*/}
                        {/*    margin={"0 0 0 8px"}*/}
                        {/*>*/}
                        {/*    <Icon.Card*/}
                        {/*        size={"24px"}/>*/}
                        {/*</ReturnButton>*/}
                        {showFilterForWords && <ReturnButton
                            width={"30px"}
                            height={"30px"}
                            borderRadius={"50%"}
                            margin={"0 0 0 8px"}
                            onClick={() => dispatch({ type: "OPEN_LIST_BAD_WORDS_MODAL" })}
                        >
                            <Icon.Filter
                                size={"24px"} />
                        </ReturnButton>}
                        <ReturnButton
                            width={"30px"}
                            height={"30px"}
                            borderRadius={"50%"}
                            margin={"0 0 0 8px"}
                            onClick={() => dispatch({ type: "OPEN_ARCHIVE_GROUP_MODAL" })}
                        >
                            <Icon.Archive
                                size={"24px"} />
                        </ReturnButton>
                    </RowOptions>
                    <BotChannels/>
                    <PinChannels />
                    <RowChannelKind>
                        <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                            Groups
                        </Text>
                        <Row>
                            <ReturnButton
                                width={"30px"}
                                height={"30px"}
                                borderRadius={"50%"}
                                onClick={() => dispatch({ type: "OPEN_NEW_CHANNEL_MODAL" })}
                            >
                                <Icon.Plus size={"24px"} />
                            </ReturnButton>
                        </Row>
                    </RowChannelKind>
                    <GroupChannels />
                    <RowChannelKind>
                        <Text color={"#516F90"} fontSize={"20px"} margin={"0"} lineHeight={"unset"} height={"30px"}>
                            Direct Messages
                        </Text>
                        <Row>
                            <ReturnButton
                                width={"30px"}
                                height={"30px"}
                                borderRadius={"50%"}
                                onClick={() => dispatch({ type: "OPEN_DIRECT_MESSAGE_MODAL" })}
                            >
                                <Icon.Plus size={"24px"} />
                            </ReturnButton>
                        </Row>
                    </RowChannelKind>
                    <DuoChannels />
                </ChannelsScrollContainer>
            </ChatSidebarContainer>
        </>
    );
}

export default ChatSidebar;

