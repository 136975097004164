import React, {useState} from "react";
import {useAuth} from "../../../Auth";
import toast from "../../../components/Toasts/toast";
import {Icon, Spacer, Theme} from "@dspworkplace/ui";
import Loading from "../../../components/Loading";
import {Action} from "./UI";

const PrintButton = ({params}) => {
    const [loading, setLoading] = useState(false);
    const {api} = useAuth();

    const exportExcel = async () => {
        setLoading(true);

        api.post("/api/lazy/manage/data", params).then(
            response => {
                let url = `${response.data.data.file}`;
                window.open(url);

                toast({
                    type: 'success',
                    title: 'Your print is ready',
                    content: <a style={{color: 'inherit'}} href={url} target="_blank">Click here to download your file</a>,
                    useClose: true,
                    timeout: false,
                    useIcon: true,
                })
            },
            error => {
                if (error.response)
                    toast({
                        type: 'error',
                        title: 'Error generating your file',
                        content: error.response.data.message
                    })
            }
        ).finally(()=>{
            setLoading(false);
        })
    };

    return (
        <Action
            type='button'
            onClick={exportExcel}
            disabled={loading}
        >
            <Spacer left={1} right={1}>
                {loading ? (
                    <Loading style={{width: 20}}/>
                ) : (
                    <Icon.Printer
                        size="20px"
                        color={Theme.colors.secondary}
                    />
                )}
            </Spacer>
        </Action>
    )
}

export default PrintButton;