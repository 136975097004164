import {useEffect, useRef} from "react";
import apiFactory from "./factory";

type ReportDocumentSource = 'manual' | 'crawler';

interface ReportSourceDocument {
    company: number,
    station: number,
    name: string,
    url: string,
    category: string,
    source: ReportDocumentSource
}

interface ReportSourceDocumentMetadata {
    document: number,
    key: string,
    value: any
}

const useReportSourceDocumentsApi = () => {
    const instance = useRef(apiFactory());

    const create = (documents: Array<ReportSourceDocument>) => {
        return instance.current.call({
            actions: {
                create: {
                    ReportSourceDocument: documents.reduce((all, next: ReportSourceDocument) => {
                        all[next.name] = next;

                        return all;
                    }, {})
                }
            }
        })
    }

    const setMetadata = (metadata: ReportSourceDocumentMetadata) => {
        return instance.current.call({
            actions: {
                response: {
                    ReportSourceDocument: {
                        custom: {
                            functionName: "setMetadata",
                            get: "result",
                            criteria: metadata
                        }
                    }
                }
            }
        })
    }

    useEffect(() => {
        return () => instance.current.cancel();
    }, [instance]);

    return {
        create,
        setMetadata
    };
}

export default useReportSourceDocumentsApi;