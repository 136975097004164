import React from "react";
import styled from "styled-components";
import moment from 'moment';
import {Theme} from "@dspworkplace/ui";
import {ProgressBar, Tag} from "../../../components/UI";
import {Tabs} from '../../../components/Menus/Main';
import IncidentsHeader from "./IncidentsHeader";
import IncidentsFilter from "./IncidentsFilter";

const IncidentProgress = ({phases, current, description, prefix, isReviewd, driverTaskRead, isSaved, isDraft}) => {
    let Progress = 0;
    if (prefix === "Coa" || prefix === "Cor"){
        if(isSaved) Progress = 10;
        if(current > 0) Progress = 50;
        if(driverTaskRead) Progress = 75;
        if(current == phases) Progress = 95;
        if(isReviewd) Progress = 100;
    }else if (prefix === "RRS") {
        Progress = 100;
    }else if (prefix === "Eno") {
        if(current < 1) Progress = 50;
        if(current > 0) Progress = 100;
    }else{
        Progress = Math.round(current/phases * 100);
    }
    if(isDraft) Progress = 0;
    return <ProgressBar
        progress={Progress}
        description={description}
    />
}

const DueBadgeStyle = styled(Tag).attrs(props => ({
    small: true
}))`
    width: auto;
    line-height: ${Theme.font.small.lineHeight};
    height: auto;
    display: inline-block;

    svg {
        vertical-align: text-top;
        padding-right: 4px;
    }

    background-color: ${Theme.colors.neutrals.white};
    border-color: ${Theme.colors.neutrals.silver};
    color: ${Theme.colors.neutrals.gray};
    path {
        fill: ${Theme.colors.neutrals.gray};
    }

    ${props => props.warning && !props.isCompleted && `
    background-color: ${Theme.colors.warnings.bg};
    border-color: ${Theme.colors.warnings.border};
    color: ${Theme.colors.warnings.text};
    path {
        fill: ${Theme.colors.warnings.text};
    }
    `}

    ${props => props.danger && !props.isCompleted && `
    background-color: ${Theme.colors.error.bg};
    border-color: ${Theme.colors.error.border};
    color: ${Theme.colors.error.text};
    path {
        fill: ${Theme.colors.error.text};
    }
    `}

    ${props => props.error && !props.isCompleted && `
    background-color: ${Theme.colors.error.border};
    border-color: ${Theme.colors.error.border};
    color: ${Theme.colors.neutrals.white};
    path {
        fill: ${Theme.colors.neutrals.white};
    }
    `}

    ${props => props.isCompleted && `
    background-color: ${Theme.colors.success.border};
    border-color: ${Theme.colors.success.border};
    color: ${Theme.colors.neutrals.white};
    path {
        fill: ${Theme.colors.neutrals.white};
    }
    `}
`;

const getDueStatus = due => {
    let diff = (due - new Date().getTime());
    let dur  = moment.duration(diff);

    const error = diff < 0;
    const danger = diff >= 0 && dur.asHours() < 1;
    const warning = dur.asHours() >= 1 && dur.asHours() <= 24;

    return {error, danger, warning, diff, dur};
};

const DueBadge = ({status, isCompleted}) => {
    const {warning, danger, error, diff, dur} = status;
    let str = dur.humanize();

    if ( diff >= 0 && dur.asMinutes() <= 30 )
        str = 'NOW';
    else if ( !error )
        str = `in ${str}`;

    return (
        <DueBadgeStyle warning={warning} danger={danger} error={error} isCompleted={isCompleted}>
            {
                isCompleted ? `Completed`
                : (
                    error ? (
                        `!!! ${str} late`
                    ) : (
                        `Due ${str}`
                    )
                )
            }
        </DueBadgeStyle>
    )
};

const IncidentPhasesTabs = styled(Tabs)`
    border-bottom: 1px solid ${Theme.colors.info.shadow};
    height: auto;

    li {
        box-sizing: border-box;
        height: 40px;
        line-height: 32px;

        &.disabled a {
            pointer-events: none;
            color: ${Theme.colors.neutrals.silver};
        }

        a {
            cursor: pointer;
        }
    }
`;

export {
    IncidentsFilter,
    IncidentsHeader,
    IncidentProgress,
    getDueStatus,
    DueBadge,
    IncidentPhasesTabs
}