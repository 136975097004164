const callDynamicReportRoutes = async (api, companyId, userId, isMainView, report, data) => {
    const params = {
        isMainView: isMainView,
        report: report,
        data: data,
    };

    try {
        const response = await api.post("/api/my-reports", params);
        return response.data.result;
    } catch (error) {}
};

const fetchStations = async (api, companyId) => {
    const params = {
        actions: {
            response: {
                Station: {
                    findBy: {
                        criteria: {
                            company: companyId,
                        },
                        get: "Station",
                        includes: {
                            0: "id",
                            1: "name",
                            2: "code",
                            3: "timezone",
                        },
                        excludes: [
                            "company",
                            "skill",
                            "users",
                            "parent",
                            "childrens",
                            "driverRoutes",
                            "payRate",
                            "skillRates",
                            "shifts",
                            "drivers",
                        ],
                    },
                },
            },
        },
    };
    try {
        return await api.post("/api/lazy/manage/data", params);
    } catch (error) {}
};

const fetchDrivers = async (api, stationIds, companyId) => {
    const params = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        get: "drivers",
                        functionName: "getActiveEmployeesForDynamicReport",
                        criteria: {
                            companyId: companyId,
                            stationIds: stationIds,
                        },
                    },
                },
            },
        },
    };

    try {
        return await api.post("/api/lazy/manage/data", params);
    } catch (error) {}
};

const exportDynamicReport = async (
    api,
    companyId,
    report,
    subReport,
    stations,
    drivers,
    startDate,
    endDate,
    dynamicRadio,
    dynamicCheckbox,
    driverId,
    driverStatus
) => {
    const params = {
        api: api,
        companyId: companyId,
        report: report,
        subReport: subReport,
        stations: stations,
        drivers: drivers,
        startDate: startDate,
        endDate: endDate,
        dynamicRadio: dynamicRadio,
        dynamicCheckbox: dynamicCheckbox,
        driverId: driverId,
        driverStatus: driverStatus,
    };

    try {
        return await api.post("/api/export", params);
    } catch (error) {}
};

export { callDynamicReportRoutes, fetchStations, fetchDrivers, exportDynamicReport };
