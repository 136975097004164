import React, {useRef, useState} from "react";
import ReactDOM from 'react-dom';
import Modal from "../../components/Modal";
import {Button, Spacer, SubTitle, Text, RadioBox, CheckBox, Input} from "@dspworkplace/ui";
import Parser from 'html-react-parser';
import {useForm} from "react-hook-form";
import Loading from "../Loading";
import {RecoilRoot} from "recoil";
import { Label } from "../UI";

const modalRoot = document.getElementById('modal-root');

const Dialog = props => {
    let {
        modal,
        title,
        text,
        icon,
        buttons,
        fields,
        align,
        defaultValues,
        success,
        cancel,
        children,
        childrenPosition='top'
    } = props;

    align = {
        header: 'center',
        content: 'center',
        footer: 'center',
        ...align
    };

    modal = {
        width: '340px',
        removeHeader: true,
        closeOnOverlay: true,
        title: title,
        ...modal,
        visible: true,
        setVisible: cancel,
    };

    const {register, handleSubmit, getValues, errors} = useForm({
        defaultValues: defaultValues
    });

    const actionRef = useRef();

    const onSubmit = data => {
        success({data, action: actionRef.current});
    };
    const values = getValues();

    const [routeStatus, setRouteStatus] = useState(defaultValues?.selectedShiftType && defaultValues.selectedShiftType.category == "8" ? true : false);
    const [backupStatus, setBackupStatus] = useState(
        (defaultValues?.selectedShiftType && defaultValues.selectedShiftType.category == "2" && defaultValues.selectedShiftType.isOT == true) ? true : false
    );
    return (
        <Modal {...modal}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!modal || (modal && modal.removeHeader) ? (
                    <div style={{textAlign: align.header}}>
                        {icon && <Spacer bottom={5} top={3}>{icon}</Spacer>}
                        {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                    </div>
                ) : null }
                <div style={{textAlign: align.content}}>
                    {childrenPosition === 'top' && children}
                    {text && <Text>{Parser(text)}</Text>}
                    {fields && fields.length ? fields.map( (item, k) =>{
                        const {handleShiftChange} = item.props;

                        return (item.props && item.props.Displayed == false) ?
                        <></>
                        :
                        (<Spacer key={k} top={k > 0 ? 3 : 0} style={{...item.props.spanStyle}}>
                            {React.createElement(
                            item.field,
                            {
                                ...item.props,
                                ref: register({
                                required: item.props.Required
                                }),
                                valid: values[item.props.name],
                                error: errors[item.props.name] && 'Invalid',
                            ...(handleShiftChange && {onChange: value => handleShiftChange(value, setRouteStatus, setBackupStatus)})
                            }
                            )}
                        </Spacer>);

                }) : null}
                    {childrenPosition === 'bottom' && children}
                    {routeStatus && (
                        <Spacer top={3}>
                            <Label>Shift Status</Label>
                            <RadioBox
                                ref={register({ required: "Required Shift Status" })}
                                name="routeStatus"
                                options={[
                                    { label: "Paid", value: "1" },
                                    { label: "Unpaid", value: "2" },
                                    { label: "Holiday", value: "3" },
                                ]}
                                Required={true}
                                valid={values.routeStatus && !errors.routeStatus}
                                error={errors.routeStatus && errors.routeStatus.message}
                            />
                            <Spacer top={3}></Spacer>
                            <CheckBox
                                ref={register()}
                                name="isVoluntary"
                                options={[{ value: true, label: "Voluntary" }]}
                            />
                        </Spacer>
                    )}
                    {backupStatus && (
                            <Spacer top={3}>
                                <Label>Shift Status</Label>
                                <RadioBox
                                    ref={register({ required: "Required Shift Status" })}
                                    name="backupStatus"
                                    options={[
                                        { label: "Rostered", value: "1" },
                                        { label: "Rescuer", value: "2" }
                                    ]}
                                    Required={true}
                                    valid={values.backupStatus && !errors.backupStatus}
                                />
                                <Spacer top={3}></Spacer>
                                <CheckBox
                                    ref={register()}
                                    name="onCall"
                                    options={[{ value: true, label: "onCall" }]}
                                />
                            </Spacer>
                            
                        )}
                </div>
                {buttons &&
                    <Spacer top={5} style={{textAlign: align.footer}}>
                        {buttons && buttons.map((btn, k) => {
                            let hasCallback = typeof btn.callback === 'function'
                                ? {onClick: () => success(btn.callback())}
                                : {};

                            return ((btn.props && btn.props.Displayed == false) ?
                                <></>
                                :
                                <Spacer
                                    key={k}
                                    inline={btn.inline !== undefined ? btn.inline : true}
                                    left={k > 0 && (btn.inline !== undefined ? btn.inline : true) ? 3 : 0}>
                                    <Button
                                        size={btn.size || 'small'}
                                        type={btn.type || 'default'}
                                        Type={btn.Type}
                                        style={btn.style || {}}
                                        disabled={btn.disabled || false}
                                        onClick={() => {
                                            if (btn.action)
                                                actionRef.current = btn.action;

                                            if (typeof btn.callback === 'function')
                                                success(btn.callback(values));
                                        }}
                                    >
                                        {btn.text}
                                    </Button>
                                </Spacer>
                            )
                        })}
                    </Spacer>
                }
            </form>
        </Modal>
    )
};

const hideDialog = ()=> setTimeout( ()=> {
    ReactDOM.unmountComponentAtNode( modalRoot );
}, 1 );

const dialog = async props => {
    return dialogPromise((success, cancel) =>
        <Dialog
            success={success}
            cancel={cancel}
            {...props}
        />
    );
};

const dialogPromise = async render => {
    return new Promise( ( resolve, reject ) => {
        const success = (data, type = false) => type ? resolve( data ) : hideDialog() && resolve( data );
        const cancel = data => hideDialog() && resolve( false );

        setTimeout(()=>
            ReactDOM.render( (
                render(success, cancel)
            ), modalRoot )
        , 10);
    });
};

const portalPromise = async render => {
    const el = document.createElement('div');
    el.setAttribute('id',Date.now());
    modalRoot.appendChild(el);

    const hideDialog = ()=> setTimeout( ()=> {
        ReactDOM.unmountComponentAtNode( el );
        if(document.getElementById(el.getAttribute('id'))){
            modalRoot.removeChild(el);
        }
    }, 1 );

    return new Promise( ( resolve, reject ) => {
        const success = data => hideDialog() && resolve( data );
        const cancel = data => hideDialog() &&  resolve( false );

        setTimeout(()=>
            ReactDOM.render( (
                <RecoilRoot>{render(success, cancel)}</RecoilRoot>
            ), el )
        , 10);
    });
}

const loadingDialog = () => {
    dialogPromise(success =>
        <Modal
            width={'300px'}
            visible={true}
            setVisible={success}
            removeHeader={true}
            closeOnOverlay={false}
        >
            <div style={{textAlign: 'center'}}>
                <Loading style={{width: 40}}/>
            </div>
        </Modal>
    );

    return hideDialog;
}

export {dialog, Dialog, dialogPromise, loadingDialog,portalPromise};
