import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Analytics from "./Utilities/Analytics";
import Menu from "./components/Menus/Main";
import { ToastContainer } from "./components/Toasts";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Register from "./pages/Register";
import Scheduler from "./pages/Scheduler/Week";
import SchedulerView from "./pages/Scheduler/Day";
import SchedulerLoadOut from "./pages/SchedulerLoadOut";
import Stations from "./pages/Stations";
import Employees from "./pages/Employees";
import VehicleManagement from "./pages/Vehicles/VehicleManagement";
import VanAffinity from "./pages/Vehicles/VanAffinity";
import VehicleImageCategories from "./pages/Vehicles/VehicleImageCategories";
import VanAutoAssignment from "./pages/Vehicles/VanAutoAssignment";
import VehicleImageNew from "./pages/Vehicles/VehicleImage";
import Vehicles from "./pages/Vehicles";
import StationNavigation from "./pages/Stations/Navigator";
import Skills from "./pages/Skills";
import Devices from "./pages/Devices";
import Dashboard from "./pages/Dashboard";
import RouteCommitment from "./pages/Routes/RouteCommitment";
import ScheduleBalance from "./pages/Routes/ScheduleBalance";
import EmployeeBalance from "./pages/Routes/Employee";
import Settings from "./pages/Settings";
import Incidents from "./pages/Incidents";
import Reports, { DynamicRoute } from "./pages/Reports";
import Dynamic from "./pages/Reports/Dynamic";
import VanNavigator from "./pages/Reports/VanNavigator";
import Mileage from "./pages/Reports/VanNavigator/Mileage";
import IncidentForm from "./pages/Incidents/form";
import { Token } from "./hooks/useGlobalData";
import { AuthContext, getChatIdentifier, getStreamChatIdentifier, getToken, getCompanyId, getUserId, getEnableOldChat, getEnableAutomatedScorecardCoachingMetric } from "./Auth";
import { engine, resetApp } from "./Utilities/index";
import { useSelectedStation } from "./components/StationSelector";
import EditProfile from "./pages/EditProfile";
import { DevWarning } from "./components/UI";
import toast from "./components/Toasts/toast";
import { AccessCheck, IsGranted } from "./security";
import * as SettingsSections from "./pages/Settings/Sections";
import { Icon } from "@dspworkplace/ui";
import Announcements from "./pages/Announcements";
import * as AnnouncementsSections from "./pages/Announcements/Sections";
import WorkFlowBuilder from "./pages/WorkFlowBuilders";
import Forms from "./pages/WorkFlowBuilders/Forms";
import Workflow from "./pages/WorkFlowBuilders/Workflow";

import { Provider as ChatProvider } from "./Chat/store";
import { Provider as ChatNewProvider } from "./ChatNew/store";
import ChatApp from "./Chat/AppChat";
import ChatDemo from "./ChatNew/Demo";
import CrispChat from "./Utilities/Crisp";

import VehicleMaintenanceTypes from "./pages/Vehicles/Maintenance/types";
import VehicleMaintenanceTemplate from "./pages/Vehicles/Maintenance/template";
import VehicleMaintenanceTasks from "./pages/Vehicles/Maintenance/tasks";
import VehicleMaintenanceTasksLog from "./pages/Vehicles/Maintenance/tasks/log";


// Scorecard
import ScorecardDrivers from "./pages/Scorecard/Sections/drivers";
import ScorecardTeams from "./pages/Scorecard/Sections/teams";
import ScorecardCoaching from "./pages/Scorecard/Sections/coaching";
import ScorecardDocuments from "./pages/Scorecard/Sections/documents";
import CompanyDocuments from "./pages/CompanyDocuments/documents";
import ScorecardDriverApp from "./pages/Scorecard/Sections/app";
import AutomatedScorecardCoaching from "./pages/Scorecard/Sections/automated_scorecard_coaching";
import MaintenanceVendorApp from "./pages/Vehicles/Maintenance/vendor";
import ChatSidebar from "./ChatNew/ChatSidebar";
import Chat from "./ChatNew/Chat";
import { StationInclude } from "./components/StationSelector/schema";
import RevenueReport from "./pages/Reports/Revenue";

export const ROUTES = [
    {
        path: "/login",
        component: Login,
        useMenu: false,
        isAuthorized: false,
    },
    {
        path: "/forgot-password",
        component: ForgotPassword,
        useMenu: false,
        isAuthorized: false,
    },
    {
        path: "/register",
        component: Register,
        useMenu: false,
        isAuthorized: false,
    },
    // {
    //     name: "Home",
    //     path: "/home",
    //     component: Home,
    //     renderMenu: false,
    //     isAuthorized: true
    // },
    {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        isAuthorized: true,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.DASHBOARD,
    },
    {
        name: "Chat",
        path: "/chat",
        isAuthorized: true,
        component: Chat,
        isResetPasswordMenu: true,
        renderMenu: false,
        feature: AccessCheck.features.DASHBOARD,
    },
    {
        name: "Scheduler",
        path: "/scheduler",
        isAuthorized: true,
        isStation: true,
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Week",
                path: "/week",
                component: Scheduler,
                isAuthorized: false,
                renderMenu: false,
            },
            {
                name: "Day",
                path: "/day",
                component: SchedulerView,
                isAuthorized: false,
                renderMenu: false,
            },
            {
                name: "Load Out",
                path: "/loadout",
                isAuthorized: false,
                renderMenu: false,
                component: SchedulerLoadOut,
            },
        ],
    },
    {
        name: "Routes",
        path: "/routes",
        isAuthorized: true,
        isStation: true,
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Route Commitment",
                path: "/route-commitment",
                component: RouteCommitment,
                feature: AccessCheck.features.ROUTE_COMMITMENT,
            },
            {
                name: "Schedule Balance",
                path: "/schedule-balanace",
                component: ScheduleBalance,
                feature: AccessCheck.features.SCHEDULE_BALANCE,
            },
            {
                name: "Employee Balance",
                path: "/employee-balance",
                component: EmployeeBalance,
                feature: AccessCheck.features.EMPLOYEE_BALANCE,
            },
        ],
    },
    {
        name: "Incident Form",
        path: "/incidents/:id",
        matchPath: "/incidents/:id",
        isAuthorized: true,
        component: IncidentForm,
        renderMenu: false,
        feature: AccessCheck.features.INCIDENTS,
    },
    {
        name: "Incidents",
        path: "/incidents",
        isAuthorized: true,
        component: Incidents,
        isStation: true,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.INCIDENTS,
    },
    {
        name: "Scorecard",
        path: "/scorecard",
        isAuthorized: true,
        isResetPasswordMenu: true,
        isStation: true,
        feature: AccessCheck.features.SCORECARD,
        routes: [
            // {
            //     name: <>Overall</>,
            //     path: '/overall',
            //     component: ScorecardOverall,
            // },
            {
                name: <>Coaching</>,
                path: "/coaching",
                component: ScorecardCoaching,
                feature: AccessCheck.features.SCORECARD,
            },
            {
                name: <>Teams</>,
                path: "/teams",
                component: ScorecardTeams,
                feature: AccessCheck.features.SCORECARD,
            },
            {
                name: "Driver Performance",
                path: "/driver-performance",
                component: ScorecardDrivers,
                feature: AccessCheck.features.SCORECARD,
            },
            {
                name: "Driver App",
                path: "/driver-app",
                component: ScorecardDriverApp,
                feature: AccessCheck.features.SCORECARD,
            },
            {
                name: "Documents",
                path: "/documents",
                component: ScorecardDocuments,
                feature: AccessCheck.features.SCORECARD,
            },
            {
                name: "Forced Coaching Metric",
                path: "/forced-coaching-metric",
                component: AutomatedScorecardCoaching,
                feature: AccessCheck.features.SCORECARD,
            },
        ],
    },
    {
        name: "Company Management",
        path: "/company-management",
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Stations",
                path: "/stations",
                matchPath: "/stations",
                component: Stations,
            },
            {
                name: "Stations Menu",
                path: "/station/:id",
                matchPath: "/station/:id",
                component: StationNavigation,
                renderMenu: false,
            },
            {
                name: "Skills",
                path: "/skills",
                component: Skills,
            },
            {
                name: "Employees",
                path: "/employees",
                matchPath: "/employee",
                component: Employees,
                isStation: true,
            },
            {
                name: "Devices",
                path: "/devices",
                component: Devices,
                isStation: true,
            },
            {
                name: "Vehicles",
                path: "/vehicles",
                component: Vehicles,
                isStation: true,
                routes: [
                    { name: "Management", path: "/vehicle-management", component: VehicleManagement },
                    { name: "Images", path: "/vehicle-image/:id", component: VehicleImageNew },
                    { name: "Image Categories", path: "/image_categories", component: VehicleImageCategories },
                    { name: "Van Affinity", path: "/van-affinity", component: VanAffinity },
                    // {
                    //     name: "Vehicle Image Viewer",
                    //     path: '/vehicle-image/:id',
                    //     component: VehicleImage,
                    //     renderMenu: false
                    // },
                    {
                        name: "Van Auto Assignment",
                        path: "/van-auto-assignment",
                        component: VanAutoAssignment,
                        // isCompany: [85, 104]
                    },
                    {
                        name: "TaskTracker",
                        path: "/maintenance/tasks",
                        component: VehicleMaintenanceTasks,
                        feature: AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN,
                        avoidPermission: [85],
                    },
                    {
                        name: "Maintenance Log",
                        path: "/maintenance/tasksLog",
                        component: VehicleMaintenanceTasksLog,
                        // feature: AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN,
                        // isCompany: [85, 104]
                    },
                    {
                        name: "Maint Templates",
                        path: "/maintenance/template",
                        component: VehicleMaintenanceTemplate,
                        feature: AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN,
                        avoidPermission: [85],
                    },
                    {
                        name: "Maint Vendors",
                        path: "/maintenance/vendor",
                        feature: AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN,
                        component: MaintenanceVendorApp,
                        avoidPermission: [85],
                    },
                    {
                        name: "Types",
                        path: "/maintenance/types",
                        feature: AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN,
                        component: VehicleMaintenanceTypes,
                        avoidPermission: [85],
                    },
                ],
            },
            {
                name: "Announcements",
                path: "/announcements",
                component: Announcements,
                isStation: true,
                feature: AccessCheck.features.ANNOUNCEMENTS,
                routes: [
                    { name: "Messages", path: "/messages", component: AnnouncementsSections.Messages },
                    { name: "Templates", path: "/template", component: AnnouncementsSections.Template },
                    //{name: "Segments",path:'/segment',component:AnnouncementsSections.Segment}
                ],
            },
            {
                name: "WorkFlow Builders",
                path: "/workflowbuilder",
                component: WorkFlowBuilder,
                isStation: true,
                renderMenu: true,
                isCompany: [85],
                routes: [
                    { name: "Forms", path: "/forms", component: Forms },
                    { name: "Workflow", path: "/workflow", component: Workflow },
                ],
            },
            {
                name: "Reports",
                path: "/custom-reports",
                component: Reports,
            },
            {
                name: "Documents",
                path: "/documents",
                component: CompanyDocuments,
            },
        ],
    },
    {
        name: "Reports",
        path: "/custom-reports",
        isAuthorized: true,
        component: Reports,
        renderMenu: false,
        isResetPasswordMenu: true,
        isStation: true,
        feature: AccessCheck.features.VAN_REPORT,
        routes: [
            {
                name: "Revenue Report",
                description: "See the last closed billing cycle revenue for metered billing products",
                path: "/revenue",
                matchPath: "/custom-reports/revenue",
                component: RevenueReport,
                renderMenu: false,
                isCompany: [85],
                feature: AccessCheck.features.SYS_ADMIN,
            },
            {
                name: "Van Reports",
                description: "Download your vans mileage report",
                path: "/van",
                matchPath: "/custom-reports/van",
                component: VanNavigator,
                renderMenu: false,
                feature: AccessCheck.features.VAN_REPORT,
                routes: [
                    {
                        name: "Mileage",
                        path: "/mileage",
                        icon: Icon.Calendar,
                        component: Mileage,
                        default: true,
                        feature: AccessCheck.features.VAN_REPORT,
                    },
                ],
            },
            {
                path: "/dynamic/:report",
                matchPath: "/custom-reports/dynamic/:report",
                isAuthorized: true,
                component: DynamicRoute,
                renderMenu: false,
                isResetPasswordMenu: true,
                isStation: false,
                isDynamic: true,
            },
            {
                path: "/dynamic/:report/:subreport",
                matchPath: "/custom-reports/dynamic/:report/:subreport",
                isAuthorized: true,
                component: Dynamic,
                renderMenu: false,
                isResetPasswordMenu: true,
                isStation: false,
                isDynamic: true,
            },
        ],
    },
    {
        name: "Edit Profile",
        path: "/edit-profile",
        component: EditProfile,
        isAuthorized: true,
        renderMenu: false,
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
        isAuthorized: true,
        renderMenu: false,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.SETTINGS,
        routes: [
            {
                name: "Company",
                routes: [
                    {
                        name: "Information",
                        path: "/company-information",
                        component: SettingsSections.CompanyInformation,
                    },
                    {
                        name: "Scheduler settings",
                        path: "/scheduler-settings",
                        component: SettingsSections.SchedulerSetting,
                    },
                    {
                        name: "Attendance settings",
                        path: "/attendance-settings",
                        component: SettingsSections.AttendanceSetting,
                    },
                    {
                        name: "Expiration settings",
                        path: "/expiration-settings",
                        component: SettingsSections.ExpirationSettings,
                    },
                ],
            },
            {
                name: "Subscription",
                routes: [
                    {
                        name: "Package",
                        path: "/package",
                        component: SettingsSections.SubscriptionPackage,
                    },
                    // {
                    //     name: "Payment Methods",
                    //     path: "/payment-methods",
                    //     component: SettingsSections.SubscriptionPaymentMethods
                    // },
                    {
                        name: "Invoices",
                        path: "/invoices",
                        component: SettingsSections.SubscriptionInvoices,
                    },
                ],
            },
            {
                name: "Integrations",
                routes: [
                    {
                        name: "RouteAssist&TRADE;",
                        path: "/routeassist",
                        component: SettingsSections.RouteAssist3Settings,
                    },
                    {
                        name: "Paycom",
                        path: "/paycom",
                        component: SettingsSections.PaycomSettings,
                        feature: AccessCheck.features.PAYCOM,
                    },
                    {
                        name: "ADP",
                        path: "/adp",
                        component: SettingsSections.ADPSettings,
                    },
                    {
                        name: "Generate ADP Certificate",
                        path: "/certificate",
                        isCompany: [85],
                        component: SettingsSections.ADPCertificate,
                    },
                ],
            },
            {
                name: "Incidents",
                routes: [
                    {
                        name: "Incident Settings",
                        path: "/incident/setting",
                        component: SettingsSections.IncidentSettings,
                    },
                ],
            },
        ],
    },
];

export function useRouteByPath(path) {
    if (!path) path = window.location.pathname;

    const all = {};
    const getRoutes = (route) => {
        if (Array.isArray(route.routes))
            route.routes.forEach((subRoute) => {
                all[[route.path, subRoute.path].join("")] = subRoute;

                getRoutes(subRoute);
            });
    };

    ROUTES.forEach((route) => {
        all[route.path] = route;
        getRoutes(route);
    });

    return all[path];
}

export function renderGrantedRoute({ feature, component, props, warning = true, loading = true, avoidPermission = false }) {
    const render = React.createElement(component, props);

    if (!feature)
        return render;

    if (avoidPermission) {
        return (<>{render}</>);
    }
    return (
        <IsGranted renderWarning={warning} feature={feature} renderLoading={loading}>
            {render}
        </IsGranted>
    );
}

const LayoutRouter = ({
    changeRoute,
    isAuthorized = true,
    useMenu = true,
    path,
    component,
    isStation,
    isResetPasswordMenu,
    ...rest
}) => {
    const [authToken] = Token();
    // const [stations, ] = useLocalStorage("selectedStation");
    const [selected] = useSelectedStation(null);
    let containerStyle = { padding: "0px" };
    if (!useMenu) {
        containerStyle.padding = "0";
    }

    if (isAuthorized && !authToken) {
        return <Redirect to="/login" />;
    }

    if (isResetPasswordMenu && localStorage.getItem("isResetPassword") == "true") {
        toast({
            type: "warning",
            title: "You must change your password first then you can access other sections.",
            useClose: true,
            timeout: 10000,
        });
        return <Redirect to="/edit-profile#user-informations" />;
    }

    if (isStation === true && !selected) {
        toast({
            type: "warning",
            title: "To use any section it's necessary to add one station !!!",
            useClose: true,
            timeout: false,
        });
        return <Redirect to="/company-management/stations" />;
    }
    if (
        !selected &&
        (window.location.pathname === "/company-management/devices" ||
            window.location.pathname === "/company-management/vehicles" ||
            window.location.pathname === "/company-management/employees")
    ) {
        toast({
            type: "warning",
            title: "To use any section it's necessary to add one station !!!",
            useClose: true,
            timeout: false,
        });
        return <Redirect to="/company-management/stations" />;
    }

    return (
        <>
            {useMenu && <Menu routes={ROUTES.filter((route) => (localStorage.getItem("currentUserRole") != "ROLE_CHAT_USER" || (localStorage.getItem("currentUserRole") == "ROLE_CHAT_USER" && (route.path == '/chat' || route.path == '/edit-profile'))))} />}
            <div style={containerStyle}>
                <Route
                    key={`route-${path}`}
                    path={path}
                    render={({ match: { url } }) => {
                        if (isAuthorized) localStorage.setItem("homepage", rest.location.pathname);

                        return (
                            <>
                                {component ? (
                                    <Route
                                        path={url}
                                        exact={
                                            url === "/" || (rest.routes && rest.routes.filter((r) => r.path).length > 0)
                                        }
                                        render={(props) =>
                                            renderGrantedRoute({
                                                feature: rest.feature,
                                                component,
                                                props
                                            })
                                        }
                                    />
                                ) : null}
                                {rest.routes &&
                                    rest.routes.map((subRoute, i) => {
                                        return (
                                            <Route
                                                key={`subroute-${url}${subRoute.name ?? subRoute.path}`}
                                                path={[`${url}${subRoute.path}`]}
                                                render={(props) =>
                                                    renderGrantedRoute({
                                                        feature: subRoute.feature,
                                                        component: subRoute.component,
                                                        props
                                                    })
                                                }
                                            />
                                        );
                                    })}
                            </>
                        );
                    }}
                />
            </div>
        </>
    );
};


const RouteApp = () => {
    const [authData, setAuthData] = useState({ authToken: getToken(), twilioIdentity: getChatIdentifier() });
    const { authToken, twilioIdentity } = authData;
    const [enableOldChat, setEnableOldChat] = useState(getEnableOldChat())

    const setAuthToken = useCallback((value) => {
        setAuthData((prev) => ({ ...prev, authToken: value }));
    }, []);

    const setTwilioIdentity = useCallback((value) => {
        setAuthData((prev) => ({ ...prev, twilioIdentity: value }));
    }, []);

    const isValidTokenAndIdentity = useCallback((token, identity) => {
        if (token === "null" || identity === "null") {
            localStorage.clear();
            window.location.reload();
            return false;
        }
        return typeof token === "string" && typeof identity === "string" && token.length > 500 && identity.length > 0;
    }, []);

    const isValidToken = useCallback(() => {
        if (authData.authToken === "null") {
            localStorage.clear();
            window.location.reload();
            return false;
        }
        return typeof authData.authToken === "string" && authData.authToken.length > 500;
    }, [authData.authToken]);

    useEffect(() => {
        if (isValidToken() && !localStorage.getItem('stations')) {
            api.post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        Station: {
                            custom: {
                                functionName: "getStationByRole",
                                get: "stations",
                                criteria: {
                                    company: getCompanyId(),
                                    user: getUserId()
                                },
                                includes: StationInclude
                            }
                        }
                    }
                }
            }).then(
                (response) => {
                    const stations = response?.data?.data?.stations || [];
                    localStorage.setItem('stations', JSON.stringify(stations))
                },
                (error) => console.log(error)
            );
        }
    }, [authData.authToken]);

    const logout = useCallback(async () => {
        await resetApp();
    }, []);

    useEffect(() => {
        setEnableOldChat(getEnableOldChat())
    }, [])

    const CancelToken = axios.CancelToken;
    const AxiosSource = CancelToken.source();
    const api = engine();

    const ENV =
        process.env.NODE_ENV === "development" || window.location.href.match("devapp") ? "development" : "production";

    const homeVar = (localStorage.getItem("homepage") == "/company-management/vehicles/vehicle-image/:id")?"/company-management/vehicles/vehicle-image/id":localStorage.getItem("homepage");

    return (
        <>
            {enableOldChat === true || enableOldChat === 'true' && (
                <ChatProvider>
                    {isValidTokenAndIdentity(authToken, twilioIdentity) && <ChatApp />}
                    {/*<ChatApp/>*/}
                </ChatProvider>
            )}
            {(getStreamChatIdentifier() != '' && getStreamChatIdentifier()) && <ChatNewProvider>
                {isValidTokenAndIdentity(authToken, twilioIdentity) && <ChatDemo twilioIdentity={twilioIdentity} />}
            </ChatNewProvider>}
            <AuthContext.Provider
                value={{
                    setAuthData,
                    setAuthToken,
                    setTwilioIdentity,
                    api,
                    AxiosSource,
                    logout,
                    ENV
                }}
            >
                {ENV === "development" && <DevWarning />}
                <CrispChat />
                <BrowserRouter>
                    <ToastContainer />
                    <Analytics>
                        <ChatNewProvider>
                            <Switch>
                                {isValidToken() && <Redirect from="/login" to={(localStorage.getItem("currentUserRole") == "ROLE_CHAT_USER")?'/chat':(homeVar ? homeVar : "/dashboard")} />}
                                {ROUTES.filter((route) => (localStorage.getItem("currentUserRole") != "ROLE_CHAT_USER" || (localStorage.getItem("currentUserRole") == "ROLE_CHAT_USER" && (route.path == '/chat' || route.path == '/edit-profile')))).map((route, i) => (
                                    <LayoutRouter key={`layoutRender-${route.path}`} {...route} />
                                ))}
                                {isValidToken() && homeVar && <Redirect from="*" to={homeVar} />}
                                {!isValidToken() && <Redirect from="*" to="/login" />}
                            </Switch>
                        </ChatNewProvider>
                    </Analytics>
                </BrowserRouter>
            </AuthContext.Provider>
        </>
    );
};

export default RouteApp;
