import React, {useReducer,useEffect} from 'react';
import styled from 'styled-components';
import {TabList} from '../../../../../components/Tab';
import {Tabs,useTabState, usePanelState } from '@bumaga/tabs';
import Information from './Information';
import Design from './Design';
import Drivers from './Drivers';
import Confirmation from './Confirmation';
import {Button, CheckBox} from '@dspworkplace/ui';
import {useAuth} from "../../../../../Auth";
import moment from "moment";
import {MenuItemBadge} from "../../../../../components/Menus/Main";

export const Grid = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : 'auto'};
    grid-column-gap: ${props=>props.columnGap ? props.columnGap : '0'};
    grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
    grid-row-gap: ${props=>props.rowGap ? props.rowGap : '0'};
    justify-content: ${props=>props.justifyContent ? props.justifyContent : 'normal'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    border: ${props=>props.border ? props.border : 'unset'};
    border-bottom: ${props=>props.borderBottom ? props.borderBottom : 'unset'};
    border-top: ${props=>props.borderTop ? props.borderTop : 'unset'};
    border-left: ${props=>props.borderLeft ? props.borderLeft : 'unset'};
    border-right: ${props=>props.borderRight ? props.borderRight : 'unset'};
    border-radius: ${props=>props.borderRadius ? props.borderRadius : 'unset'};
    font-size: ${props=>props.fontSize ? props.fontSize : '14px'};
    font-weight: ${props=>props.fontWeight ? props.fontWeight : 'normal'};
    color: ${props=>props.color ? props.color : '#333333'};
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : '#FFFFFF'};
    text-decoration: ${props=>props.textDecoration ? props.textDecoration : 'unset'};
    text-align: ${props=>props.textAlign ? props.textAlign : 'unset'};
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    cursor: ${props=>props.cursor ? props.cursor : 'auto'};
    grid-area: ${props=>props.gridArea ? props.gridArea : 'auto'};
`;

export const CheckBoxCustom = styled(CheckBox)`
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
`;

export const CustomButton = styled(Button)`
    box-sizing: border-box;
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    font-size: ${props=>props.size ? props.size : '14px'};

    &:active, &:focus, &:after {
                outline: 0;
                border: 0;
                text-shadow:none;
                box-shadow: none;
            }
`;


const Content = styled.div`
    box-sizing: border-box;
    display:flex;
    font-family: circe-rounded,serif;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    width: fit-content;
`;

const Scroll = styled.div`
    box-sizing: border-box;
    display:flex;
    font-family: circe-rounded,serif;
    height: calc(100vh - 52px - 53px - 20px - 44px - 4px - 16px - 20px - 20px - 44px);
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    width: fit-content;
`;

const Tab = ({children}) => {
    const {onClick, isActive} = useTabState();
    return  <h6 onClick={onClick} className={[isActive && 'tabActive']} style={{'pointer-events':'none'}} data-tab={'tab'}>{children}</h6>;
};

const ContentPanel = styled.div`
    box-sizing: border-box;
    display: flex;
    font-family: circe-rounded;
    flex-direction: column;
`;

const Panel = ({children}) => {
    const isActive = usePanelState();
    return (isActive ? <ContentPanel> {children} </ContentPanel> : <div style={{display: 'none'}}/>);
};

export const SchedulesAddStateContext = React.createContext();
export const SchedulesAddDispatchContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'setInformation':
            return { ...state, information: action.information};
        case 'setTotalWeeks':
            return { ...state, originalWeekCount: action.originalWeekCount};

        case 'setDrivers':
            return { ...state, drivers: action.drivers};
        case 'setDesign':
            return { ...state, design: action.design};
        case 'setConfirmation':
            return { ...state, confirmation: action.confirmation};
        case 'setIsEdit':
            return { ...state, isEdit: action.isEdit};
        case 'setIsCopy':
             let dShiftTypes=[];
             let dweekArray=[];
             let weekArray =[];
             for (var i = 0; i < state.originalWeekCount; i++) {
                 weekArray.push(state.weekArr[i]);
                 dShiftTypes.push( state.design.shiftTypes[i]);
                 dweekArray.push( state.design.weekArr[i]);
             }
             let obj = {'shiftTypes':dShiftTypes,'weekArr':dweekArray};
            return { ...state, weekArr:weekArray, design:obj,originalWeekCount:0,shiftDesignId:[],isCopy: action.isCopy};

        case 'setUpdate':
            return { ...state, isCopy: action.isCopy};
        case 'setWeeks':
            return { ...state, weekArr: action.weekArr};

        case 'setConflictRoutes':
            return { ...state, conflictRoutes: action.routes,isRouteConflictModalOpen: action.open};
        case 'setIsRouteConflictModalOpen':
            return { ...state, isRouteConflictModalOpen: action.open};
        case 'setConflictStatus':
            return { ...state, conflictStatus: action.status};
        case 'setEffectiveDate':
            return { ...state, effectiveDate: action.date};
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'setDriversEffectiveDate':
            return { ...state, driversEffectiveDate: action.driversEffectiveDate};
        case 'init':
            return { ...state,
                information:action.information,
                drivers:action.drivers,
                design:action.design,
                id:action.id,
                shiftDesignId:action.shiftDesignId,
                weekArr:action.weekArr,
                driverRoutes:action.driverRoutes,
                originalWeekCount:action.originalWeekCount,
                originalScheduleDesignWeekAndYear: action.originalScheduleDesignWeekAndYear,
                driversEffectiveDate:action.driversEffectiveDate ?? []
             };
        default:
            return state;
      }
}
const AddView = (data) => {
    let initialState={};
    if (data.data.updateStatus === 1 || data.data.copyStatus === 1 ) {
        let startDate = moment.unix(data.data.edit.startDate.timestamp).utc().format("YYYY-MM-DD");
        let endDate='';
        if(data.data.edit.endDate){
             endDate = moment.unix(data.data.edit.endDate.timestamp).utc().format("YYYY-MM-DD");
        }
        initialState = {
            'information': {name:data.data.copyStatus === 1 && data.data.updateStatus != 1 ? 'Copy of '+data.data.edit.name : data.data.edit.name,startDate:startDate,recurring:data.data.edit.recurring,endDate:endDate,checkbox:data.data.edit.previousAssignSchedule,recurringWeeks:data.data.edit.recurringWeeks },
            'drivers': [],
            'design': {},
            'confirmation': false,
            'schedule': {},
            'isEdit': data.data.updateStatus,
            'isCopy':data.data.copyStatus,
            'id':data.data.edit.id,
            'shiftDesignId':[],
            'station':data.data.station,
            'weekArr':[],
            'driverRoutes':0,
            'weekData':[],
            'originalWeekCount':data.data.originalWeekCount,
            'view':data.data.oldView,
            'isRouteConflictModalOpen':false,
            'conflictRoutes': {},
            'conflictStatus':true,
            'effectiveDate':null,
            'isLoading':false,
            'startDate':startDate,
            'schedules':data.data.schedules,
            'driversEffectiveDate': []
        }
    } else {
        initialState = {
            'information': {},
            'drivers': [],
            'design': {shiftTypes: [], weekArr: [1],realWeekArr: []},
            'confirmation': false,
            'schedule': {},
            'isEdit': 0,
            'isCopy':0,
            'id': 0,
            'shiftDesignId':[],
            'station':data.data.station,
            'weekArr':[],
            'driverRoutes':[],
            'weekData':[],
            'originalWeekCount':'',
            'view':data.data.oldView,
            'isRouteConflictModalOpen':false,
            'conflictRoutes': {},
            'conflictStatus':true,
            'effectiveDate':null,
            'isLoading':false,
            'startDate':null,
            'schedules':data.data.schedules,
            'driversEffectiveDate': []
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    const { api } = useAuth();
    useEffect(()=>{
        if(data.data.updateStatus === 1){
            dispatch({'type':'setIsEdit', isEdit: data.data.updateStatus});
        }
        if(data.data.copyStatus === 1){
            dispatch({'type':'setIsCopy', isCopy: data.data.copyStatus});
        }
    },[]);

    return (
        <SchedulesAddStateContext.Provider value={{state}}>
            <SchedulesAddDispatchContext.Provider value={{dispatch}}>
            <Content>
                <Tabs>
                    <TabList>
                        <Tab>1. Information</Tab>
                        <Tab>2. Design</Tab>
                        <Tab>3. Drivers</Tab>
                        <Tab>4. Review & Confirmation</Tab>
                    </TabList>
                <Scroll>
                    <Panel><Information /></Panel>
                    <Panel><Design preState={state} /></Panel>
                    <Panel><Drivers preState={state}/></Panel>
                    <Panel><Confirmation preState={state}/></Panel>
                </Scroll>
                </Tabs>
            </Content>
        </SchedulesAddDispatchContext.Provider>
        </SchedulesAddStateContext.Provider>
    );
};

export default React.memo(AddView);
