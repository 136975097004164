import React, {useState, useEffect} from 'react';
import {NavLink, useHistory, useRouteMatch, useParams} from 'react-router-dom';
import {Icon, Spacer, SubTitle, Theme} from '@dspworkplace/ui';
import {Back, Body, Content, Menu, Navigator} from "../../../components/Menus/Navigator";
import {Link} from "../../../components/UI";
import {callDynamicReportRoutes} from "../api";
import SubDynamic from "./SubDynamic";
import {getCompanyId, getUserId, useAuth} from "../../../Auth";
import Loading, {LoadingWrapper} from "../../../components/Loading";

const App = () => {
    const {api} = useAuth();
    const companyId = getCompanyId();
    const userId = getUserId();
    const [loading, setLoading] = useState(true);
    const {report} = useParams();
    const {url,isExact} = useRouteMatch();
    const history = useHistory();
    const [tabOptions,setTabOptions] = useState([]);
    let dynamicSubReports = [];
    let currentSelectedTab = null;

    const fetchDynamicReportRoutes = async () => {
        setLoading(true);
        await callDynamicReportRoutes(api, companyId, userId, false, report).then((data) => {

            for(let [key, value] of Object.entries(data)) {
                dynamicSubReports.push({
                    key: key,
                    name: value.title,
                    mainPath: "/custom-reports/dynamic/"+report,
                    path: "/custom-reports/dynamic/"+report+"/"+key,
                    icon: value.icon ? Icon[value.icon] : Icon.Segment,
                    fields: value.fields
                });
            }

        });
    };


    useEffect(() => {
        fetchDynamicReportRoutes().then(() => {
            setTabOptions(dynamicSubReports);
            setLoading(false);
        }).catch(e => {
            setTabOptions([])
            setLoading(false);
        });
    }, []);

    if (!loading && tabOptions.length === 0) {
        history.replace("/company-management/custom-reports");
    }

    if (!loading && tabOptions.length > 0) {
        if (isExact && tabOptions[0].mainPath === url) {
            history.replace(tabOptions[0].path);
            currentSelectedTab = tabOptions.filter(tab => tab.path === tabOptions[0].path);
        } else {
            currentSelectedTab = tabOptions.filter(tab => tab.path === url);
        }
        currentSelectedTab = currentSelectedTab[0];
    }

    return (
        <Body>
            {loading && (
                <LoadingWrapper>
                    <Loading style={{height: '80px', marginTop: '20px'}}/>
                </LoadingWrapper>
            )}
            {!loading && tabOptions.length > 0 && (
                <>
                    <Navigator>
                        <Back>
                            <Link to='/company-management/custom-reports'>
                                <Icon.ArrowLeft size='16px'/>
                                <span>Back to Custom Reports</span>
                            </Link>
                        </Back>
                        <SubTitle style={{textTransform: 'capitalize'}}>{report} Reports</SubTitle>
                        <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                        <Spacer top={4}/>
                        <Menu>
                            {tabOptions.map((option,key)=>
                                <li key={key}>
                                    <NavLink to={option.path}>
                                        <option.icon size={'20px'}/>
                                        <span>{option.name}</span>
                                    </NavLink>
                                </li>
                            )}
                        </Menu>
                        <Spacer top={16} />
                    </Navigator>
                    {currentSelectedTab && <Content>
                        <SubDynamic report={report} subReport={currentSelectedTab.key} name={currentSelectedTab.name} fields={currentSelectedTab.fields} />
                    </Content>}
                </>
            )}
        </Body>
    );
};

export default App;
