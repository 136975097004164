import React, {useRef, useState} from 'react';
import {

    Toggle,
} from "@dspworkplace/ui";
import {TableData} from "../../components/Table";
import Loading from "../../Chat/Loading";

const MyComponent = ({d, k, switchAllowLoadOutProcess}) => {
    const [track, setTrack] = useState<any>(1.0);
    const [loading, setLoading] = useState(false);
    const toggleRef = useRef<any>();
    return (
        <TableData width="60px" className={'toggleAllowLoadOut'}>
            <Toggle
                name={d.id}
                on={JSON.stringify(d.allowLoadOutProcess) !== 'false'}
                ref={toggleRef}
                onChange={() => switchAllowLoadOutProcess(d, k, toggleRef, track, setTrack, setLoading)}
            />
            {loading && <Loading containerHeight={'28px'} containerWidth={'28px'} imgHeight={'24px'} imgWidth={'24px'}
                                 margin={'4px 0 0 -6px'}/>}
        </TableData>
    );
};

export default MyComponent;
