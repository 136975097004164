import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { Title, Spacer, Text, Button, Icon, ButtonGroup } from "@dspworkplace/ui";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { confirm } from "../../../../components/Confirm";
import { useAuth } from "../../../../Auth";
import GridView from "./grid";
import ListView from "./list";
import AddView from "./Add";

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    // font-family: circe-rounded;
    height: calc(100vh - 52px - 53px - 20px - 44px - 4px - 16px - 20px - 20px);
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
`;

const Body = styled.div`
    box-sizing: border-box;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // font-family: circe-rounded;
`;

const Row = styled.div`
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const Column = styled.div`
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    display: flex;
    flex-grow: ${(props) => (props.grow ? props.grow : "unset")};
    color: ${(props) => (props.color ? props.color : "unset")};
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const TextContainer = styled(Text)`
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "16px")};
    display: flex;
    flex-grow: ${(props) => (props.grow ? props.grow : "unset")};
    color: ${(props) => (props.color ? props.color : "#333333")};
    font-size: ${(props) => (props.size ? props.size : "14px")};
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const CustomButtonContainer = styled(Button)`
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    color: ${(props) => (props.color ? props.color : "#333333")};
    font-size: ${(props) => (props.size ? props.size : "14px")};
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    display: flex;
    flex-grow: ${(props) => (props.grow ? props.grow : "unset")};
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.radius ? props.radius : "unset")};
    background-color: ${(props) => (props.bgColor ? props.bgColor : "unset")};
    border: ${(props) => (props.border ? props.border : "0px solid #0071BC")};

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: ${(props) => (props.border ? props.border : "0px solid #0071BC")};
        text-shadow: none;
        box-shadow: none;
        background-color: ${(props) => (props.bgColor ? props.bgColor : "unset")};
    }
    svg {
        box-sizing: border-box;
    }
`;

const reducer = (state, action) => {
    switch (action.type) {
        case "create":
            return { ...state, schedules: action.schedules, isLoading: action.isLoading };
        case "loading":
            return { ...state, isLoading: action.isLoading };
        case "add":
            return { ...state, schedules: [...state.rates, action.schedule], isLoading: action.isLoading };
        case "setUpdateKey":
            return { ...state, updateKey: action.updateKey };
        case "setView":
            return { ...state, view: action.view, updateStatus: 0, oldView: state.view };
        case "update":
            return { ...state, view: "Add", oldView: state.view, edit: action.editID, updateStatus: 1 };
        case "copy":
            return { ...state, view: "Add", oldView: state.view, edit: action.copyID, copyStatus: 1 };
        case "delete":
            state.schedules.splice(action.key, 1);
            return { ...state };
        case "updateStatus":
            return { ...state, updateStatus: 0 };
        default:
            return state;
    }
};
export const SchedulesContext = React.createContext();
const Schedules = (localState = "") => {
    const useLocationState = useLocation().state;
    const station = useLocationState && useLocationState.station ? useLocationState.station : localState.state.station;
    const initialState = {
        schedules: [],
        updateKey: -1,
        isLoading: true,
        view: "Grid",
        oldView: "Grid",
        station: station,
        tabIndex: 1,
        edit: "",
        updateStatus: -1,
        tab: [false, false, false, false],
    };
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { api } = useAuth();
    const [data, setSchedulesData] = useState({ update: -1, schedules: [] });

    const deleteSchedules = async (schedule, key) => {
        let confirmation = await confirm({
            icon: true,
            text: "Confirm that you want to delete this schedule and its related future driver routes?",
        });

        if (!confirmation) {
            return false;
        }

        dispatch({ type: "delete", key: key });

        let params = {
            actions: {
                delete: {
                    Schedule: {
                        schedule_1: {
                            findOneBy: {
                                id: schedule.id,
                            },
                        },
                    },
                },
            },
        };
        try {
            await api.post("/api/lazy/manage/data", params);
        } catch (error) {
            if (error.response.status == "401") {
                window.location = "/logout";
            }
        }
    };

    useEffect(() => {
        const getSchedules = async () => {
            dispatch({ type: "loading", isLoading: true });
            const params = {
                actions: {
                    response: {
                        Schedule: {
                            custom: {
                                functionName: "getStationWiseSchedule",
                                get: "schedules",
                                criteria: {
                                    station: station.id,
                                },
                            },
                        },
                    },
                },
            };
            try {
                let recurring = "";
                let response = await api.post("/api/lazy/manage/data", params, {
                    cache: {
                        ignoreCache: true,
                    },
                });
                dispatch({ type: "loading", isLoading: false });
                let r = response.data.data.schedules.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        drivers: item.drivers > 1 ? item.drivers + " Drivers" : item.drivers + " Driver ",
                        weeks:
                            item.originalWeekCount > 1
                                ? item.originalWeekCount + " Weeks"
                                : item.originalWeekCount + " Week",
                        type: item.isRecurring,
                        shiftTypes: item.sft > 1 ? item.sft + " Shift types" : item.sft + " Shift type",
                        startDate: item.startDate,
                        recurring: item.isRecurring,
                        endDate: item.endDate,
                        originalWeekCount: item.originalWeekCount,
                        recurringWeeks: item.recurringWeeks,
                        isShiftReminderNotification: item.isShiftReminderNotification,
                        isExpired: item.isExpired,
                        view: state.view,
                    };
                });
                dispatch({ type: "create", schedules: r, isLoading: false });
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
                dispatch({ type: "default" });
            }
        };
        if (state.view && state.station) {
            if (state.view !== "Add") {
                // dispatch({type:'loading', isLoading: false});
                getSchedules();
            }
        }
    }, [state.station, state.view]);

    return (
        <SchedulesContext.Provider value={{ state, dispatch }}>
            <Body>
                <Row>
                    <Column grow="1">
                        <Title>Schedules</Title>
                    </Column>

                    <ButtonGroup type={"button"} active={state.view}>
                        <Button
                            track={"List"}
                            id={"list-view"}
                            onClick={() => {
                                dispatch({
                                    type: "setView",
                                    view: "List",
                                    updateStatus: 0,
                                });
                            }}
                        >
                            <Icon.List size="16px" style={{ verticalAlign: "middle" }} />
                        </Button>
                        <Button
                            track={"Grid"}
                            id={"grid-view"}
                            onClick={() => {
                                dispatch({
                                    type: "setView",
                                    view: "Grid",
                                    updateStatus: 0,
                                });
                            }}
                        >
                            <Icon.Card size="16px" style={{ verticalAlign: "middle" }} />
                        </Button>
                    </ButtonGroup>
                    <div
                        id="cancelBtn"
                        onClick={() => {
                            if (localState.sid) {
                                history.push("/company-management/station/" + station?.id +"/schedules");
                            } else {
                                dispatch({
                                    type: "setView",
                                    view: state.oldView,
                                    updateStatus: 0,
                                });
                            }
                        }}
                    />
                </Row>
                <Spacer top={2} />
                <Text>Create and edit recurring schedules and assigned employees.</Text>
                <Spacer top={5} />
                {state.isLoading && <Loading style={{ height: "80px", marginTop: "20px" }} />}
                {!state.isLoading && state.view && state.view === "Grid" && (
                    <Content>
                        {" "}
                        <GridView deleteSchedules={deleteSchedules} sid={localState.sid} />{" "}
                    </Content>
                )}
                {!state.isLoading && state.view && state.view === "List" && (
                    <Content>
                        {" "}
                        <ListView deleteSchedules={deleteSchedules} />{" "}
                    </Content>
                )}
                {!state.isLoading && state.view && state.view === "Add" && <AddView data={state} />}
            </Body>
        </SchedulesContext.Provider>
    );
};

export default Schedules;
