import axios, { AxiosInstance } from "axios";
import { baseUrl } from "../Auth";
import ReactDOM from "react-dom";
import { alert } from "../components/Alert";
import { unregister } from "../serviceWorker";
import toast from "../components/Toasts/toast";
import moment from "moment-timezone";
import React, { CSSProperties } from "react";
import { Icon, Theme } from "@dspworkplace/ui";

export async function resetApp() {
    localStorage.clear();
    await unregister();
    ReactDOM.unmountComponentAtNode(document.getElementById("chat-root")!);
    ReactDOM.unmountComponentAtNode(document.getElementById("channels-root")!);
    ReactDOM.unmountComponentAtNode(document.getElementById("channel-modal")!);
    localStorage.clear();
    // @ts-ignore
    window.location.reload();
}

let contentErrorMessage = 'If this issue continues, please reach out to our support team via the orange chat button located in the lower-left area of the screen. If possible, it often helps to attach a screenshot or even a video. You can also reach us at: support@dspworkplace.com';
let api;
let apiOptions;
let counter = 0;


export function engine(options?: { hideDefaultMessage?: boolean }): AxiosInstance {
    apiOptions = options;
    const checkCompany = async () => {
        if (localStorage.getItem('company') == null || localStorage.getItem('company') == "") {
            showToast({
                type: 'Error',
                title: 'We are sorry 😥. Please, try again.',
                content: `Session expired. Login again, please`,
            });
            alert(`Session expired. Login again, please`);
            resetApp();
        }
    }
    if (!api) {
        api = axios.create({
            baseURL: baseUrl, // process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://127.0.0.1:8000',
            responseType: "json",
            withCredentials: true,
        });
    }
    if (getToken()) {
        checkCompany();
        let cookie = document.cookie.split(';').map(c => c.trim());
        let date = new Date();
        if (!cookie.includes('dspcoockie=true')) {
            api.post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        User: {
                            custom: {
                                functionName: "checkUserStatus",
                                criteria: {
                                },
                                get: "checkUserStatus",
                            }
                        }
                    }
                }
            }).then((res) => {
                if (res.data.data.checkUserStatus.success == true) {
                    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                    document.cookie = "dspcoockie = true; expires = " + date.toUTCString();
                } else {
                    resetApp();
                }
            });
        }
    }

    api.interceptors.request.use(request => {

        if (getToken()) {
            request.headers = {
                'Authorization': `Bearer ${getToken()}`
            }
        }

        if (request.data && request.data instanceof FormData) {
            return request;
        }

        if (request.url && (request.url.includes('upload') || request.url.includes('csv'))) {
            return request;
        }

        if (request.data && request.data instanceof FormData) {
            return request;
        }

        request.data = {
            ...request.data,
            appInformation: getAppInformation(),
        }

        return request;
    });

    api.interceptors.response.use(response => {
        if (response.headers['x-plan-tier'] && response.headers['x-payment-interval']) {
            window.dispatchEvent(
                new CustomEvent(
                    '_plandetails',
                    {
                        detail: [
                            response.headers['x-plan-tier'],
                            response.headers['x-payment-interval']
                        ]
                    }
                )
            );
        }
        return response;
    });

    api.interceptors.response.use(
        res => res,
        async error => {
            // console.log({apiOptions, counter});
            counter++;
            if (process.env.NODE_ENV === "development" || window.location.href.match("devapp")) {
                console.log({ apiOptions, counter });
                console.error({ error });
            }
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    await alert("Session expired. Login again, please").catch();
                    await resetApp();
                }
                if (apiOptions && apiOptions.hideDefaultMessage) {
                    throw error;
                } else {
                    await showToast({
                        type: 'Error',
                        title: 'We are sorry 😥. Please, try again.',
                        content: `${contentErrorMessage}`,
                    });
                }
            }
            throw error;
        }
    );
    return api;
}

export function getSelectedStationId(): number {
    return JSON.parse(localStorage.getItem('selectedStation')!);
}

export function getCompanyId() {
    return JSON.parse(localStorage.getItem('company')!);
}

export const setChatCompanyId = (companyId) => {
    localStorage.setItem('chatCompany', companyId);
};

export const getChatCompanyId = () => {
    return (localStorage.getItem('chatCompany')) ? localStorage.getItem('chatCompany') : getCompanyId();
};

export const setChatUserId = (userId) => {
    localStorage.setItem('chatUser', userId);
};

export const getChatUserId = () => {
    return (localStorage.getItem('chatUser')) ? localStorage.getItem('chatUser') : getUserId();
};

export const setChatTeam = (ChatTeam) => {
    localStorage.setItem('chatTeam', ChatTeam);
};

export const setChatUserRole = (role) => {
    localStorage.setItem('chatUserRole', role);
};

export const getChatTeam = () => {
    return localStorage.getItem('chatTeam');
};

export const getStreamChatIdentifier = () => {
    return localStorage.getItem('streamChatIdentifier');
}

export const getChatUserRole = () => {
    return localStorage.getItem('chatUserRole');
};

export const setStreamChatIdentifier = (streamChatIdentifier) => {
    return localStorage.setItem('streamChatIdentifier', streamChatIdentifier);
}

export const getImpersonateStreamChatIdentifier = () => {
    return localStorage.getItem('impersonateStreamChatIdentifier');
}

export const setImpersonateStreamChatIdentifier = (streamChatIdentifier) => {
    return localStorage.setItem('impersonateStreamChatIdentifier', streamChatIdentifier);
}

export const getIsOpenChat = () => {
    return localStorage.getItem('isOpenChat');
}

export const setIsOpenChat = (isOpenChat) => {
    return localStorage.setItem('isOpenChat', isOpenChat);
}

export function getUserId() {
    return JSON.parse(localStorage.getItem('userId')!);
}

export function getDriverId() {
    return JSON.parse(localStorage.getItem('driverId')!);
}

export function getFriendlyName() {
    return localStorage.getItem('friendlyName');
}

export function getToken() {
    return JSON.parse(localStorage.getItem('token')!);
}

export function setToken(str) {
    if (typeof str === "string") {
        localStorage.setItem('token', JSON.stringify(str));
    }
}

export function getMainStation() {
    return JSON.parse(localStorage.getItem('station')!);
}

export function setMainStation(station) {
    localStorage.setItem('station', JSON.stringify(station));
}

export function getMainStationId() {
    return JSON.parse(localStorage.getItem('stationId')!);
}

export function setMainStationId(stationId) {
    localStorage.setItem('stationId', JSON.stringify(stationId));
}

export function getCurrentTimezone(): string {
    return moment.tz.guess(true);
}

export const setIsEnableSMS = (isEnableSMS) => {
    localStorage.setItem("isEnableSMS", isEnableSMS);
};

export function getIsEnableSMS() {
    return localStorage.getItem("isEnableSMS");
}

export function setEnableAutomatedScorecardCoachingMetric(automatedScorecardCoachingMetric) {
    localStorage.setItem("enableAutomatedScorecardCoachingMetric", automatedScorecardCoachingMetric);
};

export function getEnableAutomatedScorecardCoachingMetric() {
    return localStorage.getItem("enableAutomatedScorecardCoachingMetric");
}

export type OptionsToast = {
    type: 'warning' | 'info' | 'success' | 'error' | 'Error',
    title: string,
    name?: string | null,
    content?: string | null,
    useClose?: boolean,
    timeout?: boolean | number,
}

export async function showToast(options: OptionsToast) {
    try {
        let { type, title, name, content, useClose = true, timeout = true } = options;
        await toast({
            type: type,
            title: title,
            content: name ? name : content,
            useClose: useClose,
            timeout: timeout
        })
    } catch (e) {
        await toast({
            type: 'error',
            title: "Error showToast()",
            content: JSON.stringify(e),
            useClose: true,
            timeout: false
        })
    }
}

export async function showDefaultErrorToast(e: any, title?: string, content?: string) {
    if (e.response && e.response.data && typeof e.response.data.message === "string") {
        await showToast({
            type: 'Error',
            title: title ? title : 'Error',
            content: e.response.data.message,
        });
    }
    if (typeof e.message === "string") {
        await showToast({
            type: 'Error',
            title: title ? title : 'Error',
            content: e.message,
        });
    }
    if (content && title) {
        await showToast({
            type: 'Error',
            title: title,
            content: content,
        });
    }
    if (!title && content) {
        await showToast({
            type: 'Error',
            title: 'Error',
            content: content,
        });
    }
}

export async function showErrorToast(e: any, title: string, content?: string) {
    let errorMessage = '';
    if (e.message) {
        errorMessage = content && e.message ? `${content} ${e.message}. ` : `${e.message}.\t `;
    }
    let errorResponseMessage = '';
    if (e.response && e.response.data && typeof e.response.data.message === 'string') {
        errorResponseMessage = e.response.data.message;
        if (errorResponseMessage.indexOf(".", errorResponseMessage.length - 1) === -1) {
            errorResponseMessage = errorResponseMessage + '.\t ';
        }
    }
    await showToast({
        type: 'Error',
        title: title,
        content: `${errorMessage}${errorResponseMessage}`,
    });
}

export function datetimeFormat(options: { datetime: string | null, format?: string, timezone?: string }): string {
    const { datetime, format = 'ddd, MMMM Do YYYY, h:mm:ss a', timezone = moment.tz.guess(true) } = options;
    try {
        if (typeof datetime === 'string') {
            return moment.tz(datetime, timezone).format(format);
        }
        return 'N/A';
    } catch (e) {
        return 'N/A';
    }
}

export const dspIcon = (args: { type: string, size?: string; color?: string, style?: CSSProperties }) => {
    let { type, color, size, style } = args;
    let icon = Icon[type] ? Icon[type] : Icon.Info;
    return React.createElement(
        icon,
        {
            size: size ? size : '16px',
            color: color ? color : Theme.colors.info.text,
            style: style ? style : {
                verticalAlign: 'middle'
            }
        }
    )
}

export function getAppVersion() {
    let appVersion = 'N/A';
    try {
        let message = process.env.REACT_APP_VERSION_NAME;
        if (!!message) {
            localStorage.setItem('REACT_APP_VERSION_NAME', message);
            let result = message!.match(/'([^']+)'/);
            if (Array.isArray(result) && result.length > 0) {
                appVersion = result[0];
                appVersion = appVersion.replace('release/', '');
                localStorage.setItem('appVersion', appVersion);
            }
            let date = process.env.REACT_APP_BUILD_DATE;
            if (!!date) {
                localStorage.setItem('REACT_APP_BUILD_DATE', date);
                let d = moment(date, "YYYY-MM-DD-hh:mm:ss A").format('YYYY-MM-DD-hh:mm:ss A Z');
                localStorage.setItem('REACT_APP_BUILD_DATE_FORMAT', d);
            }
        }
        return appVersion;
    } catch (e) {
        return appVersion;
    }
}

export function getDatetime() {
    return moment().toISOString();
}

export function getDay() {
    return moment().format('dddd, MMMM Do, YYYY h:mm:ssa z Z');
}

export function getESTDay() {
    return moment()
        .tz('America/Detroit')
        .format('dddd, MMMM Do, YYYY h:mm:ssa z Z');
}

export function getAppInformation() {
    return {
        ...process.env,
        dayString: getDay(),
        dayESTString: getESTDay(),
        datetime: getDatetime(),
        appVersion: getAppVersion(),
    };
}

export async function checkURL(url: string) {
    try {
        let res = await fetch(url);
        if (res.status >= 400) {
            // console.log(`${JSON.stringify({ url, status: res.status })}`);
            return null;
        } else {
            return url;
        }
    } catch (e) {
        return null;
    }
}

export function getSelectedStationCode(): string | undefined {
    try {
        let stations = JSON.parse(localStorage.getItem('stations') as string);
        const selected = JSON.parse(localStorage.getItem('selected') as string);

        return stations.find(s => s.id === selected)?.code;
    } catch (e) {

    }
}

export function getDriverLicenceExpiryNotiType(): Array<{label:string , value:Number}>{
    return ([
        { label: "Manager", value: 1 },
        { label: "Driver", value: 2 },
        { label: "Both", value: 3 },
    ]);
}