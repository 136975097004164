import React, {useEffect} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea, ManageDisabledCheckBox} from "./Item";
import {VehicleImageType, useStoreReducer} from "./store";
import {Button} from '@dspworkplace/ui';
import styled from "styled-components";
import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";
import {checkIfCategoriesAreEmpty} from './util';

const CheckboxesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    height: 40px;

    input.name {
        width: 320px;
        margin-right: 32px;
    }

    input.checked {
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.forDriver {
        margin-left: 64px;
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.hide {
        display: none;
    }
`;

const CategoriesHeader = styled.div`
    display: grid;
    grid-template-columns: 320px 70px auto;
    grid-column-gap: 18px;
    align-items: center;
    flex-direction: row;

    .optional {
        display: block;
        font-size: 10px;
        margin-left: 4px;
    }

    .default {
        display: block;
        height: 36px;
    }

    .driver {
        display: block;
        height: 36px;
    }
`;

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: VehicleImageType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function VehicleImageForm(props: Props) {
    const {item, updateItem} = props;
    const {appState} = useStoreReducer();
    const { name, description, isPassRequired, vehicleImageCategories, disabledQuestion} = item.content;
    const {register, handleSubmit, reset, control} = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            vehicleImageCategories: Array.isArray(vehicleImageCategories) && vehicleImageCategories.length > 0 ? vehicleImageCategories : appState.vehicleImageCategories,
            disabledQuestion
        }
    });

    const {
        fields,
    } = useFieldArray<VehicleImageCategory>({
        name: "vehicleImageCategories",
        control
    });

    useEffect(() => {
        const abortController = new AbortController();
        checkIfCategoriesAreEmpty(vehicleImageCategories).then();
        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            <label>Is required to Pass?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {/*<label>Notify?</label>*/}
            {/*<input name="notifyIfChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <div className={'choices'}>Vehicle Image Category</div>
            <div>
                <CategoriesHeader>
                    <div/>
                    <div className={'default'}>
                        <div>Default</div>
                        <div/>
                    </div>
                    <div className={'driver'}>
                        <div>Driver</div>
                        <div className={'optional'}>Optional</div>
                    </div>
                </CategoriesHeader>
                {fields.map((field, index) => {
                    return (
                        <CheckboxesContainer key={field.id}>
                            <input
                                className={'name'}
                                name={`vehicleImageCategories[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                                disabled={true}
                            />
                            <input
                                className={'hide'}
                                name={`vehicleImageCategories[${index}].id`}
                                defaultValue={field.id}
                                ref={register()}
                                disabled={true}
                                hidden={true}
                            />
                            <input
                                name={`vehicleImageCategories[${index}].checked`}
                                type="checkbox"
                                className={'checked'}
                                defaultChecked={!!field.checked}
                                ref={register()}/>
                            <input
                                name={`vehicleImageCategories[${index}].forDriver`}
                                type="checkbox"
                                className={'forDriver'}
                                defaultChecked={!!field.forDriver}
                                ref={register()}
                            />
                        </CheckboxesContainer>
                    );
                })}
            </div>
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default VehicleImageForm;