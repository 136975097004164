import React, {useEffect,forwardRef} from "react";
import Editor, {composeDecorators} from 'draft-js-plugins-editor';
import {EditorState, convertToRaw,convertFromRaw} from 'draft-js';

import {VideoRender,VideoButton} from './components/video';
import {ImageRender} from './components/image';

import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createVideoPlugin from '@draft-js-plugins/video';

import createEmbedVideoPlugin from './EditorPlugin/video-embed';
import createPasteImagePlugin from './EditorPlugin/paste-image';

import {
    HeadlineOneButton,
    HeadlineTwoButton,
    BoldButton,
    ItalicButton,
    UnderlineButton,
    OrderedListButton,
    UnorderedListButton
} from 'draft-js-buttons';

import editorStyle from './style/editor.module.css';
import buttonStyles from './style/button.module.css';
import toolbarStyles from './style/toolbar.module.css';
import alignmentStyle from './style/alignment.module.css';

const toolbarPlugin = createToolbarPlugin({
    theme: { buttonStyles, toolbarStyles }
});
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const alignmentPlugin = createAlignmentPlugin({
    theme: {
        alignmentToolStyles:alignmentStyle,
        buttonStyles
    },
});
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
    focusPlugin.decorator
);

const imagePlugin = createImagePlugin({
    decorator,
    imageComponent: ImageRender
});
const videoPlugin = createVideoPlugin({
    videoComponent: VideoRender
});
const embedVideoPlugin = createEmbedVideoPlugin();
const pasteImagePlugin = createPasteImagePlugin();

export const plugins = [resizeablePlugin,alignmentPlugin,focusPlugin,toolbarPlugin,imagePlugin,videoPlugin,embedVideoPlugin,pasteImagePlugin];

const Separator = () => (<div style={{background:'#ffffff4d',marginLeft:6,width:1,height:18}} />)

const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'header-one') {
        return editorStyle.title;
    }
    if (type === 'header-two') {
        return editorStyle.subtitle;
    }
}
const { Toolbar } = toolbarPlugin;

const SlideEditor = forwardRef(
({
        name,
        defaultValue,
        width,
        showModalSection = 'Announcements',
        metricVideo = [],
        update = false,
     }, ref) => {


    //let content = defaultValue ?  EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)))  :  EditorState.createWithContent(convertFromRaw(initialState));
    // let content = defaultValue ?  EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)))  :  EditorState.createEmpty();
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [inputValue,setInputValue] =  React.useState();
    const editor = React.useRef(null);

    useEffect(()=>{
        editor.current.focus();
    },[])

    useEffect(() => {
        if (defaultValue) {
            let newDefaultValue = JSON.parse(defaultValue);
            let entityMap = newDefaultValue?.entityMap;
            
            if(update){
                if (entityMap) {
                    for (let key in entityMap) {
                        if (entityMap[key].data.type === "metricVideo") {
                            let videoObj = metricVideo.find((video) => video?.videoUrl == entityMap[key].data.src);
                            if (videoObj != undefined) {
                                let metricKey = videoObj?.metricKey ? videoObj?.metricKey : "";
                                entityMap[key].data.src = metricKey;
                            }
                        }
                    }
                }
                setEditorState(EditorState.createWithContent(convertFromRaw(newDefaultValue)));
            } else {
                if (entityMap) {
                    for (let key in entityMap) {
                        if (entityMap[key].data.type === "metricVideo") {
                            let videoObj = metricVideo.find((video) => video.metricKey == entityMap[key].data.src);
                            if (videoObj != undefined) {
                                let vimeoUrl = videoObj?.videoUrl ? videoObj?.videoUrl : '';
                                entityMap[key].data.src = vimeoUrl;
                            }
                        }
                    }
                }
                setEditorState(EditorState.createWithContent(convertFromRaw(newDefaultValue)));
            }
            // setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue))));
        }
    }, [defaultValue])

    useEffect(() => {
        setInputValue(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])

        return (
            <>
                <div style={{ width: width ? width : "auto", position: "relative" }} className={editorStyle.editor}>
                    <input name={name} type={"hidden"} defaultValue={inputValue} ref={ref} />
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={setEditorState}
                        onBlur={() => {
                            setInputValue(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
                        }}
                        plugins={plugins}
                        blockStyleFn={myBlockStyleFn}
                    />
                    <AlignmentTool />
                    <Toolbar>
                        {(props) => {
                            return (
                                <div>
                                    <HeadlineOneButton {...props} />
                                    <HeadlineTwoButton {...props} />
                                    <Separator />
                                    <BoldButton {...props} />
                                    <ItalicButton {...props} />
                                    <UnderlineButton {...props} />
                                    <Separator />
                                    <OrderedListButton {...props} />
                                    <UnorderedListButton {...props} />
                                    <Separator />
                                    <VideoButton
                                        {...props}
                                        modifier={videoPlugin.addVideo}
                                        showModalSection={showModalSection}
                                        metricVideo={metricVideo}
                                    />
                                </div>
                            );
                        }}
                    </Toolbar>
                </div>
            </>
        );
    }
);


export {SlideEditor}