import React, { useState, useEffect } from "react";
import {portalPromise} from "../../../../../components/Dialog";
import {Input, Spacer,Button} from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import { useForm } from "react-hook-form";
import { patternPhone, patternEmail } from '../../../../../patterns';

const CreateForm = ({ success = () => { }, cancel = () => { }, vendor }) => {    
    const { register, handleSubmit, errors, getValues } = useForm({
        defaultValues: vendor,
    });
    const { phone, email } = getValues();

    const onSubmit = (data) => {
        success(data);
    }

    const errorMessages = {
        phone: {
            pattern: 'Enter a valid phone number',
        },
        email: {
            pattern: 'Enter a valid email',
        }
    }

    return (
        <Modal
            width={"340px"}
            visible={true}
            title={!vendor?.id ? "New Vehicle Maintenance Vendor" : "Editing Vehicle Maintenance Vendor"}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Spacer>
                    <Input
                        name='name'
                        label='Name'
                        type='text'
                        ref={register({ required: 'You need to Name' })}
                        error={errors.name && errors.name.message}
                        size={'medium'}
                    />
                </Spacer>
                <Spacer>
                    <Input
                        name='email'
                        label='Email'
                        type='text'
                        ref={register({
                            required: false,
                            pattern: patternEmail
                        })}
                        error={errors.email && errorMessages.email[errors.email.type]}
                        valid={!errors.email && email && email.length > 0}
                        size={'medium'}
                    />
                </Spacer>
                <Spacer>
                    <Input
                        name='phone'
                        label='phone'
                        type='number'
                        ref={register({
                            required: false,
                            pattern: patternPhone
                        })}
                        error={errors.phone && errorMessages.phone[errors.phone.type]}
                        valid={!errors.phone && phone && phone.length > 0}
                        size={'medium'}
                    />
                </Spacer>
                <Spacer>
                    <Input
                        name="location"
                        label="Location"
                        type="text"
                        ref={register({ required: "You need to Location" })}
                        error={errors.location && errors.location.message}
                        size={"medium"}
                    />
                </Spacer>

                <Spacer top={4} />

                <Button type="primary" style={{ display: "block", marginLeft: "auto" }}>
                    Save
                </Button>
            </form>
        </Modal>
    );
};

export const openCreateForm = (vendor = { phone: '', name: '' }) => {
    return portalPromise((success, cancel) => {
        return <CreateForm success={success} cancel={cancel} vendor={vendor}  />;
    });
};
