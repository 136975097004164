import React, {useContext, useEffect, useState} from "react";
import {Button, Icon, Spacer, SubTitle, Text, ThirdTitle} from "@dspworkplace/ui";
import SettingsContext from "../context";
import Skeleton from "../../../components/Skeleton";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../components/Table";
import {Tag} from "../../../components/UI";
import useSubscriptionsApi from "../../../api/useSubscriptionsApi";

export const formatCurrency = (n, d = 2, m = 2) => new Intl.NumberFormat('en',{
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: m,
        maximumFractionDigits: d,
    }).format(n);

export const formatDate = (d, s = {month:'short', day:'numeric', year:'numeric'}) => new Intl.DateTimeFormat('en', s).format(d)

const InvoiceTable = ({invoice}) => {
    const items = invoice.lines.data
        .filter(i => i.quantity > 0)
        .sort((a, b) => a.plan.metadata.order < b.plan.metadata.order);

    return (
        <Spacer left={8} right={25} style={{width: '100%'}}>
            <Table>
                <TableHeader
                    headers={[
                        {label: 'Qty', width: '8%'},
                        {label: 'Description', width: '62%'},
                        {label: 'Unit Price', width: '15%', style: {justifyContent: 'flex-end'}},
                        {label: 'Amount', width: '15%', style: {justifyContent: 'flex-end'}},
                    ]}
                />
                {items.map((i, k) =>
                    <TableRow key={k}>
                        <TableData width='8%'>
                            {i.quantity}x
                        </TableData>
                        <TableData width='62%'>
                            {i.plan.nickname}
                        </TableData>
                        <TableData width='15%' style={{justifyContent: 'flex-end'}}>
                            {formatCurrency(i.amount / 100 / i.quantity)}
                        </TableData>
                        <TableData width='15%' style={{justifyContent: 'flex-end'}}>
                            {formatCurrency(i.amount / 100)}
                        </TableData>
                    </TableRow>
                )}
                <TableFooter style={{paddingLeft: 0, paddingRight: 0}}>
                    <TableData width='85%' style={{justifyContent: 'flex-end'}}>
                        <ThirdTitle>Total due</ThirdTitle>
                    </TableData>
                    <TableData width='15%' style={{justifyContent: 'flex-end'}}>
                        <ThirdTitle>
                            {formatCurrency(invoice.amount_due / 100)}
                        </ThirdTitle>
                    </TableData>
                </TableFooter>
            </Table>
        </Spacer>
    )
}

const InvoiceList = ({list}) => {
    const [expanded, setExpanded] = useState([]);
    const toggle = k => {
        let e = [...expanded];

        if (e.indexOf(k) >= 0)
            e.splice(e.indexOf(k), 1);
        else
            e.push(k);

        setExpanded(e);
    }

    return (
        <Table>
            <TableHeader
                headers={[
                    {label: 'Amount', width: '10%', style: {justifyContent: 'right'}},
                    {label: 'Status', width: '10%'},
                    {label: 'Invoice Number', width: '60%'},
                    {label: 'Payment Date', width: '15%'},
                ]}
            />
            {list.sort((a, b) =>
                (b.status_transitions.paid_at ?? b.status_transitions.finalized_at)
                -
                (a.status_transitions.paid_at ?? a.status_transitions.finalized_at)
            ).map((i, k) => (
                <TableRow key={k} style={{flexWrap: 'wrap'}}>
                    <TableData width='10%' style={{justifyContent: 'right'}}>
                        {formatCurrency((i.amount_due || i.amount_paid) / 100)}
                    </TableData>
                    <TableData width='10%'>
                        <Tag type={i.status === 'paid' ? 'success' : undefined}>{i.status.toUpperCase()}</Tag>
                    </TableData>
                    <TableData width='60%'>
                        {i.number}
                        <Spacer inline left={3}/>
                        <Button type='cancel' size='small' onClick={() => toggle(k)}>
                            {expanded.indexOf(k) >= 0
                                ? <Icon.Contract size='16px' style={{verticalAlign: 'middle'}}/>
                                : <Icon.Expand size='16px' style={{verticalAlign: 'middle'}}/>}
                        </Button>
                    </TableData>
                    <TableData width='15%'>
                        {(()=>{
                            let time = i.status_transitions.paid_at ?? i.next_payment_attempt ?? i.due_date;
                            let date = new Date(time * 1000);
                            return !time ? '–' : formatDate(date);
                        })()}
                    </TableData>
                    {expanded.indexOf(k) >= 0 ?
                        <InvoiceTable invoice={i}/>
                        : null}
                </TableRow>
            ))}
        </Table>
    )
}


export default function SubscriptionInvoices()
{
    const {fetchInvoices} = useSubscriptionsApi();
    const [state, dispatch] = useContext(SettingsContext);

    useEffect(() => {
        if (!state.invoices)
            fetchInvoices().then(
                response => dispatch({
                    type: 'ADD',
                    payload: {
                        invoices: response.data.data.invoices
                    }
                }),
                error => console.error
            )
    }, []);

    return <section>
        <SubTitle>Invoices</SubTitle>
        <Spacer bottom={3}/>

        <ThirdTitle>Next Invoices</ThirdTitle>
        <Spacer top={2} />
        <Text>
            This is a preview of your next invoice. It may change if the subscription is updated.
        </Text>
        <Spacer top={3} />
        {state.invoices ? (
            <InvoiceList list={Object.values(state.invoices).map(s => s.upcoming)}/>
        ) : (
            <Skeleton height={20} width={600}/>
        )}
        <Spacer bottom={10}/>

        <ThirdTitle>Older Invoices</ThirdTitle>
        <Spacer top={2}/>
        {state.invoices ? (
            <InvoiceList list={Object.values(state.invoices).map(s => s.old).flat()}/>
        ) : (
            <Skeleton height={20} width={600}/>
        )}

    </section>
}