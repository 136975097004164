import React, { useCallback, useEffect, useState } from 'react'
import { FetchTaskOpenShift } from '../../Chat/types';
import {
    engine,
    getChatCompanyId,
    getDriverId,
    getFriendlyName,
    getMainStationId,
    getChatUserId,
    showToast,
} from "../../Utilities";
import { useLoading } from '../../Chat/Loading';
import { useTrackedState, useUpdate as useBotDispatch } from './Store';
import styled from "styled-components";
import { Button, CheckBox } from "@dspworkplace/ui";
import { useForm } from 'react-hook-form';
import BotMessage from './BotMessage';
import BotInput from './BotInput';
import { ButtonContainer, Container, ScrollContainer, Text } from './BotTaskList';
import { DriverButton } from './DriverButton';
import moment from 'moment';
import { devLog } from '../../utils';

function BotSwapShift({ taskInformation, channel }) {
    const state = useTrackedState();
    const { message } = state;
    const dispatch = useBotDispatch();
    let managerFriendlyName = getFriendlyName();
    const [systemMsg, setSystemMsg] = useState(message);
    const { loading, setLoading } = useLoading();
    const [stepOneOptions, setStepOneOptions] = useState(true);
    const [taskSwapShift, setTaskSwapShift] = useState();
    const [stepTwoOptions, setStepTwoOptions] = useState(false);
    const [stepThreeOptions, setStepThreeOptions] = useState(false);
    const [sendMessage, setSendMessage] = useState(false);
    const [caseTask, setCaseTask] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [placeholder, setPlaceholder] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState("Finish");
    const [disableButton, setDisableButton] = useState(true);
    const [messageToReply, setMessageToReply] = useState("");
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [taskLoading, setTaskLoading] = useState(false);
    const [approvedMessage, setApprovedMessage] = useState("");
    const [approvedMessageTwo, setApprovedMessageTwo] = useState("");
    const [rejectedMessageTwo, setRejectedMessageTwo] = useState("");
    const [rejectedMessage, setRejectedMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState("");


    useEffect(() => {
        const abortController = new AbortController();
        engine()
            .post("/api/fetch_task", {
                task: {
                    id: taskInformation.id,
                    type: taskInformation.type,
                },
                taskInformation,
                messageSid: message.id,
                element: message.body,
                attributes: message.attributes,
                channelSid: channel.id,
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getChatUserId(),
                driverId: getDriverId(),
            })
            .then((response) => {
                setSystemMsg({ user: message.user, date: message.date, text: message.text });
                let data = response.data;
                setTaskSwapShift(data);
                setLoading(false);
            })
            .catch(async e => {
                console.error({ fetchTaskSwapShift: e });
                let errorMessage = 'Error in fetching Task.';
                if (e.response && e.response.data && e.response.data.message) {
                    await showToast({
                        type: 'Error',
                        title: 'Error in fetching Task.',
                        name: e.response.data.message
                    });
                    errorMessage = e.response.data.message;
                } else {
                    await showToast({
                        type: 'Error',
                        title: 'Error',
                        name: 'Error in fetching Task.'
                    });
                }
                setHasError(true);
                setErrorMessageText(errorMessage);
                setTaskCompleted(true);
                setStepOneOptions(false);
                setStepTwoOptions(false);
                setTaskLoading(false);
                setLoading(false);
            });
        return () => {
            abortController.abort();
        };
    }, [message.created_at, message.text, message.user, setLoading, taskInformation.id, taskInformation.type]);

    const generateMessage = (extraMessage, message, rejectMessage, scenario) => {
        if (scenario === 'ACCEPT_TASK') {
            if (typeof extraMessage === "string" && extraMessage.length > 0) {
                return `${message}\n${extraMessage}`;
            }
            return `${message}`;
        }
        if (scenario === 'REJECT_TASK') {
            if (typeof extraMessage === "string" && extraMessage.length > 0) {
                return `${rejectMessage}\n${extraMessage}`;
            }
            return `${rejectMessage}`;
        }
    }

    // @ts-ignore
    const completeTask = async (message) => {
        setDisableButton(true);
        setDisabled(false);
        setTaskLoading(true);
        try {
            let params = {
                case: caseTask,
                task: {
                    id: taskInformation.id,
                    type: taskInformation.type,
                },
                message: generateMessage(message, approvedMessage, rejectedMessage, caseTask),
                messageTwo: generateMessage(message, approvedMessageTwo, rejectedMessageTwo, caseTask),
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getChatUserId(),
                driverId: getDriverId(),
            };
            devLog(params);
            let response = await engine({ hideDefaultMessage: true }).post("/api/complete_task", params);
            devLog(response.data);
            setTaskCompleted(true);
        } catch (e) {
            console.error(e);
            if (typeof e.message === "string") {
                await showToast({
                    type: 'Error',
                    title: 'Error in Task Completion',
                    name: e.message
                });
            }
            if (e.response && e.response.data && typeof e.response.data.message === "string") {
                await showToast({
                    type: 'Error',
                    title: 'Error in Task Completion',
                    name: e.message
                });
            }
        }
    }

    const reset = useCallback(
        () => {
            setStepTwoOptions(false);
            setDisabled(true);
            setDisableButton(true);
            setTaskCompleted(false);
            setTaskLoading(false);
            setCaseTask("");
            setMessageToReply("");
        },
        [],
    );

    const acceptTask = useCallback(
        () => {
            let driverRouteToSwapInformation = "";
            let driverRouteToSwapInformation1 = "";
            if (taskSwapShift?.driverRouteToSwap.length === 0) {
                driverRouteToSwapInformation1 = `You have been assigned to ${taskSwapShift?.driverRouteInformation}.`;
            } else {
                driverRouteToSwapInformation = `, and assigned to ${taskSwapShift?.driverRouteToSwapInformation}`;
                driverRouteToSwapInformation1 = `You have been unassigned from your ${taskSwapShift?.driverRouteToSwapInformation}, and assigned to ${taskSwapShift?.driverRouteInformation}.`;
            }
            setStepTwoOptions(true);
            setApprovedMessage(`${managerFriendlyName} has approved Swap Shift Request with ${taskSwapShift?.driverToSwap.fullName}. You have been unassigned from your ${taskSwapShift?.driverRouteInformation}${driverRouteToSwapInformation}.`);
            setApprovedMessageTwo(`${managerFriendlyName} has approved Swap Shift Request with ${taskSwapShift?.driverRequester.fullName}. ${driverRouteToSwapInformation1}`);
            setCaseTask("ACCEPT_TASK");
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, taskSwapShift],
    );

    const rejectTask = useCallback(
        () => {
            let driverRouteToSwapInformation = "";
            if (taskSwapShift?.driverRouteToSwap.length !== 0) {
                driverRouteToSwapInformation = `You still scheduled for ${taskSwapShift?.driverRouteToSwapInformation}.`;
            } 
            setStepTwoOptions(true);
            setCaseTask("REJECT_TASK");
            setRejectedMessage(`${managerFriendlyName} has rejected Swap Shift Request with ${taskSwapShift?.driverToSwap.fullName}. You still scheduled for ${taskSwapShift?.driverRouteInformation}.`);
            setRejectedMessageTwo(`${managerFriendlyName} has rejected Swap Shift Request with ${taskSwapShift?.driverRequester.fullName}. ${driverRouteToSwapInformation}`);
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, taskSwapShift],
    );


    const appendMessage = async (message) => {
        setDisabled(true);
        setDisableButton(true);
        if (message) {
            setMessageToReply(message);
        }
        await completeTask(message);
    };

    return (
        <>
            <ScrollContainer>
                <BotMessage currentMessage={message} />
                <Container className={"btn"}>
                    {taskSwapShift?.drivers.map(item => <DriverButton driver={item} key={item.id} type={'Block'} />)}
                </Container>
                {stepOneOptions &&
                    <Container className={"btn"}>
                        <ButtonContainer onClick={acceptTask}>Accept</ButtonContainer>
                        <ButtonContainer onClick={rejectTask}>Reject</ButtonContainer>
                    </Container>
                }
                {stepTwoOptions && caseTask === 'ACCEPT_TASK' &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `${taskSwapShift?.driverRequester.fullName} will receive this message: ${approvedMessage}\n${messageToReply}`
                        }
                        } />
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `${taskSwapShift?.driverToSwap.fullName} will receive this message: ${approvedMessageTwo}\n${messageToReply}`
                        }
                        } />
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "To complete the approval, please add any additional text to the message box below and press Finish Task.",
                        }
                        } />
                    </>
                }
                {stepTwoOptions && caseTask === 'REJECT_TASK' &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={
                            {
                                ...message,
                                date: new Date(),
                                text: `${taskSwapShift?.driverRequester.fullName} will receive this message: ${rejectedMessage}\n${messageToReply}`
                            }
                        } />
                        <BotMessage currentMessage={
                            {
                                ...message,
                                date: new Date(),
                                text: `${taskSwapShift?.driverToSwap.fullName} will receive this message: ${rejectedMessageTwo}\n${messageToReply}`
                            }
                        } />
                        <BotMessage currentMessage={
                            {
                                ...message,
                                date: new Date(),
                                text: "To complete the approval, please add any additional text to the message box below and press Finish Task.",
                            }
                        } />
                    </>
                }
                {taskLoading && <BotMessage currentMessage={{
                    ...message,
                    created_at: new Date(),
                    text: "Please wait, we are completing the task.",
                }} />}
                {taskCompleted && <BotMessage currentMessage={{
                    ...message,
                    created_at: new Date(),
                    text: "Task Completed!",
                }} />}
            </ScrollContainer>
            <BotInput disabled={disabled} submitMessage={appendMessage} placeholder={placeholder}
                submitButtonText={submitButtonText} disableSendButton={disableButton} />
        </>
    )
}

export default BotSwapShift

const taskData = {
    "driverRouteInformation": "(10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET",
    "driverRouteToSwapInformation": "(10 Hour Standard) Saturday, July 15th, 8:00am - 7:00pm ET",
    "task": {
        "id": 641237,
        "name": "Steven Chat has requested a shift swap with Alex Keaton.\nThe currently assigned shifts involved:\nSteven Chat - (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\nAlex Keaton - (10 Hour Standard) Saturday, July 15th, 8:00am - 7:00pm ET.",
        "description": null,
        "data": {
            "assignees": [
                {
                    "id": 1559,
                    "friendlyName": "Joe Rogan"
                },
                {
                    "id": 4546,
                    "friendlyName": "Shawn Kohen"
                },
                {
                    "id": 5586,
                    "friendlyName": "Test Driver"
                }
            ]
        },
        "isCompleted": false,
        "completedAt": null,
        "dueDate": "2023-07-15T06:26:07+00:00",
        "isArchived": false,
        "type": "SWAP_SHIFT",
        "assignees": [
            {
                "id": 1559,
                "friendlyName": "Joe Rogan",
                "identity": "a11a0bf4b51b2992a5570dab45dc5549",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 4546,
                "friendlyName": "Shawn Kohen",
                "identity": "4546-460c06a4cde3f6",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 5586,
                "friendlyName": "Test Driver",
                "identity": "5586-79a55b0f755da7",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 145,
                        "roleName": "Assistant Station Manager",
                        "name": "ROLE_ASSISTANT_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            }
        ],
        "completedBy": null,
        "isCanceled": false,
        "driverRoute": {
            "id": 2071800,
            "isArchive": false,
            "shiftName": "10 Hour Standard",
            "dateCreated": "2023-07-14T00:00:00+00:00",
            "startTime": "1970-01-01T12:00:00+00:00",
            "endTime": "1970-01-01T23:00:00+00:00",
            "category": 1
        },
        "messagesTwilioData": [
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM5f8aae56474148d6abe0b723444d42c5",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM5f8aae56474148d6abe0b723444d42c5",
                    "body": "Hi Joe Rogan. Steven Chat has requested a shift swap with Alex Keaton.\nThe currently assigned shifts involved:\nSteven Chat - (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\nAlex Keaton - (10 Hour Standard) Saturday, July 15th, 8:00am - 7:00pm ET.",
                    "index": 330,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM5f8aae56474148d6abe0b723444d42c5/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM5f8aae56474148d6abe0b723444d42c5/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641237,\"type\":\"SWAP_SHIFT\",\"createdAt\":\"2023-07-13T06:26:07+00:00\",\"name\":\"Steven Chat has requested a shift swap with Alex Keaton.\\nThe currently assigned shifts involved:\\nSteven Chat - (10 Hour Standard) Friday, Ju\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:26:08.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:26:08.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH70ce7932db0f4b19b25479fe74f6ed51"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM826dbb93d56b441bb6330c6f74800711",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM826dbb93d56b441bb6330c6f74800711",
                    "body": "Hi Shawn Kohen. Steven Chat has requested a shift swap with Alex Keaton.\nThe currently assigned shifts involved:\nSteven Chat - (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\nAlex Keaton - (10 Hour Standard) Saturday, July 15th, 8:00am - 7:00pm ET.",
                    "index": 49,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM826dbb93d56b441bb6330c6f74800711/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM826dbb93d56b441bb6330c6f74800711/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641237,\"type\":\"SWAP_SHIFT\",\"createdAt\":\"2023-07-13T06:26:07+00:00\",\"name\":\"Steven Chat has requested a shift swap with Alex Keaton.\\nThe currently assigned shifts involved:\\nSteven Chat - (10 Hour Standard) Friday, Ju\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:26:09.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:26:09.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH42282464978b4d91889b7a10f7efb6f6"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IMebcd00b3a7264f6b8659cf9f0b7d9003",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMebcd00b3a7264f6b8659cf9f0b7d9003",
                    "body": "Hi Test Driver. Steven Chat has requested a shift swap with Alex Keaton.\nThe currently assigned shifts involved:\nSteven Chat - (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\nAlex Keaton - (10 Hour Standard) Saturday, July 15th, 8:00am - 7:00pm ET.",
                    "index": 50,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMebcd00b3a7264f6b8659cf9f0b7d9003/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMebcd00b3a7264f6b8659cf9f0b7d9003/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641237,\"type\":\"SWAP_SHIFT\",\"createdAt\":\"2023-07-13T06:26:07+00:00\",\"name\":\"Steven Chat has requested a shift swap with Alex Keaton.\\nThe currently assigned shifts involved:\\nSteven Chat - (10 Hour Standard) Friday, Ju\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:26:11.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:26:11.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH23eb87dbef004f9f86b582068acec329"
                }
            }
        ],
        "isRejected": false,
        "isAccepted": false,
        "message": null,
        "openShiftRequest": null,
        "hrFormIncident": null,
        "updatedMessagesTwilioData": [],
        "updatedAt": "2023-07-13T06:26:11+00:00",
        "createdAt": "2023-07-13T06:26:07+00:00"
    },
    "driverToSwap": {
        "id": 2500,
        "fullName": "Alex Keaton"
    },
    "driverRequester": {
        "id": 2444,
        "fullName": "Steven Pignataro"
    },
    "timeZoneName": "America/Detroit",
    "timezone": "ET",
    "drivers": [
        {
            "id": 2444,
            "userId": 2696,
            "fullName": "Steven Pignataro",
            "weekHours": {
                "workedHours": 0,
                "scheduledHours": 33,
                "week": "28",
                "year": "2023",
                "$weekStartEndDay": {
                    "week_start": "2023-07-09",
                    "week_end": "2023-07-15"
                },
                "$sumWorked": 0,
                "$sumScheduled": 1980,
                "$minutesScheduled": [
                    660,
                    660,
                    660
                ],
                "$minutesWorked": [],
                "driverRoutes": [
                    {
                        "week": 28,
                        "id": 2071798,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-10T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071799,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-12T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071800,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-14T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    }
                ]
            }
        },
        {
            "id": 2500,
            "userId": 2754,
            "fullName": "Alex Keaton",
            "weekHours": {
                "workedHours": 0,
                "scheduledHours": 33,
                "week": "28",
                "year": "2023",
                "$weekStartEndDay": {
                    "week_start": "2023-07-09",
                    "week_end": "2023-07-15"
                },
                "$sumWorked": 0,
                "$sumScheduled": 1980,
                "$minutesScheduled": [
                    660,
                    660,
                    660
                ],
                "$minutesWorked": [],
                "driverRoutes": [
                    {
                        "week": 28,
                        "id": 2071868,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-09T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "27"
                    },
                    {
                        "week": 28,
                        "id": 2071869,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-14T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071870,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-15T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    }
                ]
            }
        }
    ],
    "driverRoute": {
        "dateCreated": "2023-07-14T00:00:00+00:00",
        "id": 2071800
    },
    "driverRouteToSwap": {
        "dateCreated": "2023-07-15T00:00:00+00:00",
        "id": 2071870
    }
}
