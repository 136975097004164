import React from "react";
import {useHistory} from "react-router-dom";
import {Button, ButtonGroup} from "@dspworkplace/ui";

export default props => {
    const history = useHistory();

    return (
        <ButtonGroup type={'default'} active={history.location.pathname}>
            <Button track='/scheduler/week' onClick={()=>history.push('week')}>
                Week
            </Button>
            <Button track='/scheduler/day' onClick={()=>history.push('day')}>
                Day
            </Button>
            <Button track='/scheduler/loadout' onClick={()=>history.push('loadout')}>
                Load Out
            </Button>
        </ButtonGroup>
    )
};