import {useState, useCallback} from 'react';

type FilterCallbackProp = Function | undefined

const useFilter = (callbackFn:FilterCallbackProp) => {
    const [filters, setFilters] = useState({});

    const setFilter= useCallback((key,data)=>{
        setFilters(currentFilters => {
            currentFilters[key] = data;
            return {...currentFilters}
        })
    },[])

    const setAllFilters = useCallback((data)=>{
        setFilters(data)
        if(callbackFn) {
            callbackFn(data)
        }
    },[callbackFn])

    return {filtersSelected: filters, setFilter, setAllFilters}
}

export default useFilter