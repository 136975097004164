import axios from "axios";
import { getChatCompanyId, getChatUserId, getFriendlyName, getStreamChatIdentifier } from "../Auth";
import moment from "moment";
import { getOffset } from "../components/TimeFormat";
import { engine } from "../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,

    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c) {
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },

    cancel() {
        if (API.current)
            return canceler();
        else
            return false;
    },

    addUser: (date, station) => {
        return API.call({
            // company: getChatCompanyId(),
            // user: getChatUserId(),
            // name: getFriendlyName(),
            streamChatIdentifier: getStreamChatIdentifier(),
            // userId: localStorage.getItem("userId")
        }, '/api/stream/create_stream_token');
    },
}
// streamChatIdentifier: getStreamChatIdentifier(),

export default API;