import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Modal from "../../components/Modal";
import { Theme, Spacer, Icon, Text, SubTitle } from "@dspworkplace/ui";
import Loading, { LoadingWrapper, useLoading } from "../../components/Loading";
import { engine } from "../../Utilities";
import moment from "moment-timezone";
import SimpleScroll from "../../components/SimpleScroll";

const DateContent = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: bold;
    color: ${Theme.colors.neutrals.medium};
`;

const HistContent = styled.div`
    > .hasPadding {
        cursor: pointer;

        &:last-child {
            padding-bottom: 0;

            .info {
                &:before {
                    min-height: 0;
                }
            }

            .hidden {
                &:before {
                    top: -8px;
                    height: calc(100% + 18px);
                }
            }
        }

        .info {
            flex: 1;
            justify-content: flex-start;

            &:before {
                content: " ";
                border-left: 1px dashed ${Theme.colors.info.border};
                display: block;
                min-height: 30px;
                top: 50%;
                position: absolute;
                margin-left: 5px;
            }
        }
    }

    > .noPointer {
        cursor: default !important;
    }
`;

const StatusTimeLine = styled.span`
    background: ${(props) => (props.color ? props.color : Theme.colors.info.border)};
    height: 8px;
    width: 8px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    &.open {
        background: #fff;
        border: 2px solid ${(props) => (props.color ? props.color : Theme.colors.info.border)};
        width: 12px;
        height: 12px;
        margin-left: 0;
        margin-right: 0;
    }

    &.error {
        background: ${Theme.colors.error.border};
        border-color: ${Theme.colors.error.border};
    }
`;

const StatusContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    border-color: ${Theme.colors.info.border};

    &.hasPadding {
        padding-bottom: 8px;
    }
`;

const Title = styled.h5`
    color: ${Theme.colors.info.text};
    font-size: ${(props) => (props.fontSize ? props.fontSize : Theme.font.medium.size)};
    line-height: ${Theme.font.medium.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: normal;
    display: inline-block;
    position: relative;
    left: 6px;
    width: ${(props) => (props.fontSize ? "378px" : "inherit")};

    &.error {
        color: ${Theme.colors.error.text};
    }
`;

const HiddenContent = styled.div`
    flex-basis: 100%;
    margin-left: 20px;
    padding: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    position: relative;
    border-radius: 4px;
    margin-top: 4px;

    &:before {
        content: " ";
        border-left: 1px dashed ${Theme.colors.info.border};
        position: absolute;
        left: -16px;
        top: 13px;
        height: calc(100% + 12px);
    }
`;

const ExtraInfos = styled.div`
    > p {
        color: ${Theme.colors.neutrals.medium};
        display: inline-block;
        font-family: ${Theme.font.body};
    }

    > p.title {
        color: #707070;
        width: 100px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const solveIcon = (type, color) => {
    let icon = Icon[type] ? Icon[type] : Icon.Info;
    return React.createElement(icon, {
        size: "16px",
        color: color || Theme.colors.info.text,
        style: {
            verticalAlign: "middle",
        },
    });
};

const Timeline = ({ timelineVisible, toggleTimeline, title, sid }) => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useLoading();

    const fetchTimeline = async () => {
        setLoading(true);
        try {
            let response = await engine().post("/api/lazy/manage/data", {
                actions: {
                    response: {
                        ChannelHistory: {
                            custom: {
                                functionName: "getChannelHistory",
                                criteria: {
                                    sid: sid,
                                    timezone: moment.tz.guess(),
                                },
                                get: "result",
                            },
                        },
                    },
                },
            });

            let { result } = response.data.data;
            setData(result);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTimeline()
            .then(() => {
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                console.error({ e });
            });
    }, []);

    return (
        <Modal width="506px" visible={timelineVisible} setVisible={toggleTimeline} drawer="right" removeHeader={true}>
            {isLoading && (
                <LoadingWrapper>
                    <Loading style={{ height: "80px", marginTop: "20px" }} />
                </LoadingWrapper>
            )}
            {!isLoading && (
                <>
                    <Header>
                        <SubTitle>{title} - Channel History</SubTitle>
                        <Spacer inline style={{ cursor: "pointer" }}>
                            <Spacer inline left={4}>
                                <Icon.Times
                                    color={Theme.colors.neutrals.silver}
                                    size="27px"
                                    onClick={() => {
                                        toggleTimeline();
                                    }}
                                />
                            </Spacer>
                        </Spacer>
                    </Header>
                    <Spacer top={5} />
                    <div style={{ width: "100%", height: "1px", background: "#F5F5F5", display: "block" }} />
                    <Spacer top={5} />
                    <SimpleScroll>
                        {data.length > 0 ? (
                            data.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <DateContent>
                                            {moment.utc(item.date).local().format("dddd, MMM Do Y")}
                                        </DateContent>
                                        <Spacer bottom={2} />
                                        <RowTimeline data={item} date={item.date} />
                                        <Spacer bottom={5} />
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ "text-align": "center", "font-size": "16px", color: "#707070" }}>
                                No timeline found for this channel history!!!
                            </div>
                        )}
                    </SimpleScroll>
                </>
            )}
        </Modal>
    );
};

const RowTimeline = ({ data, date }) => {
    const [open, setOpen] = useState([]);
    let groupdate = moment(date.substr(0, 10)).local().format("YYYY-MM-DD");
    const handleHiddenContent = (index) => {
        if (open.includes(index)) {
            const copy = open.filter((item) => item !== index);
            setOpen(copy);
        } else {
            setOpen([...open, index]);
        }
    };

    return (
        <HistContent>
            {Array.isArray(data.line) &&
                data.line.map((info, i) => (
                    <Fragment key={info.id}>
                        <StatusContainer
                            className={[info.information == "N/A" ? "noPointer hasPadding" : " hasPadding"]}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleHiddenContent(i);
                            }}
                        >
                            <StatusContainer className="info">
                                <StatusTimeLine
                                    color={info.groups ? Theme.colors.primary : null}
                                    className={[info.status === "error" ? "error" : "", open.includes(i) ? "open" : ""]}
                                />
                                <Spacer left={2}>
                                    {solveIcon(info.iconType, info.status === "error" ? Theme.colors.error.text : null)}
                                    {typeof info.title === "string" && info.title.length > 54 && (
                                        <Title
                                            className={[info.status === "incident" ? "error" : ""]}
                                            fontSize={"12px"}
                                        >
                                            {info.title}
                                        </Title>
                                    )}
                                    {typeof info.title === "string" && info.title.length <= 53 && (
                                        <Title className={[info.status === "incident" ? "error" : ""]}>
                                            {info.title}
                                        </Title>
                                    )}
                                </Spacer>
                            </StatusContainer>
                            {typeof info.date === "string" ? (
                                <DateContent>
                                    {groupdate != moment.parseZone(info.date).format("YYYY-MM-DD")
                                        ? moment.parseZone(info.date).format("MM/DD - h:mma")
                                        : moment.parseZone(info.date).format("h:mma")}
                                </DateContent>
                            ) : (
                                <DateContent>
                                    {groupdate != moment.parseZone(info.date).format("YYYY-MM-DD")
                                        ? moment.parseZone(info.date).format("MM/DD - h:mma")
                                        : moment.parseZone(info.date).format("h:mma")}
                                </DateContent>
                            )}
                            {info.information != "N/A" ? open.includes(i) && <ExtraData info={info} /> : <></>}
                        </StatusContainer>
                    </Fragment>
                ))}
        </HistContent>
    );
};

const HistComponent = ({ data }) => {
    const [open, setOpen] = useState([]);

    const handleHiddenContent = (index) => {
        if (open.includes(index)) {
            const copy = open.filter((item) => item !== index);

            setOpen(copy);
        } else {
            setOpen([...open, index]);
        }
    };

    return (
        <HistContent>
            {data.line &&
                data.line
                    .filter((i) => i.createdAt !== null)
                    .map((info, i) => (
                        <Fragment key={i}>
                            <StatusContainer
                                className="hasPadding"
                                onClick={() => {
                                    handleHiddenContent(i);
                                }}
                            >
                                <StatusContainer className="info">
                                    <StatusTimeLine
                                        className={[
                                            info.status === "error" ? "error" : "",
                                            open.includes(i) ? "open" : "",
                                        ]}
                                    />
                                    <Spacer left={2}>
                                        {solveIcon(
                                            info.iconType,
                                            info.status === "error" ? Theme.colors.error.text : null
                                        )}
                                        <Title className={[info.status === "error" ? "error" : ""]}>{info.title}</Title>
                                    </Spacer>
                                </StatusContainer>
                                <DateContent>{moment(info.data).format("h:mma")}</DateContent>
                                {open.includes(i) && <ExtraData info={info} />}
                            </StatusContainer>
                        </Fragment>
                    ))}
        </HistContent>
    );
};

const ExtraData = ({ info }) => {
    return (
        <>
            <HiddenContent className="hidden">
                <Text dangerouslySetInnerHTML={{ __html: info.information }} />
            </HiddenContent>
            <Spacer bottom={1} />
        </>
    );
};

export { HistComponent, HistContent, StatusContainer, DateContent, ExtraData, ExtraInfos, RowTimeline };
export default Timeline;
