import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import {Button, Spacer, Icon, Text, Title, Toggle} from "@dspworkplace/ui";
import "./Accordion.css";
import styled from "styled-components";

const Strong = styled.span`
  font-weight: bold;
`;

const Action = styled.div`
    float:right;
    cursor: pointer;
`;


function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active-accordian" : "");
    setHeightState(
      setActive === "active-accordian" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active-accordian" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className={props.class}>
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
            <p className="accordion__title">{props.title}</p>
            <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
        </button>
        <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className="accordion__content"
        >
        <div className="accordian_text">
            <Text><Strong>Field </Strong> : {props.data.type}</Text>
            <Spacer top={5} > 

            <Text><Strong>Is Required :</Strong> {props.data.rule.validations.required ? 'True' : 'False' }</Text>
            </Spacer>

            { props.data.rule.depend.isDepend &&
            <Spacer top={5} > 
            <Text><Strong>isDepend : </Strong>{props.data.rule.depend.isDepend}</Text>
            </Spacer> }

            {props.data.rule.depend.dependOn &&
            <Spacer top={5} > 
            <Text><Strong>dependOn : </Strong>{props.data.rule.depend.dependOn}</Text>
            </Spacer> }

            { props.data.rule.depend.dependOnValue &&
            <Spacer top={5} > 
            <Text><Strong>dependOnValue :</Strong> {props.data.rule.depend.dependOnValue}</Text>
            </Spacer> }

            { props.data.rule.depend.dependOnIncident &&
            <Spacer top={5} > 
            <Text><Strong>dependOnIncident :</Strong> {props.data.rule.depend.dependOnIncident}</Text>
            </Spacer> }

            {props.data.rule.depend.dependOnStep &&
            <Spacer top={5} > 
            <Text><Strong>dependOnStep : </Strong>{props.data.rule.depend.dependOnStep}</Text>
            </Spacer> }

            { props.data.rule.depend.dependOnPhase &&
            <Spacer top={5} > 
            <Text><Strong>dependOnPhase : </Strong>{props.data.rule.depend.dependOnPhase}</Text>
            </Spacer> }

            { props.data.rule.fieldData &&
            <Spacer top={5} > 
            <Text><Strong>dependOnPhase : </Strong>{props.data.rule.depend.dependOnPhase}</Text>
            </Spacer> }

            { props.data.fieldData &&
            <Spacer top={5} > 
            <Text><Strong>Field Data : </Strong>{props.data.fieldData}</Text>
            </Spacer> }
        </div>                    
        </div>
    </div>
  );
}

export default Accordion;