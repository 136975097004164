
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Dropdown, TagInput, Theme, Icon, ThirdTitle, Toggle, RadioBox} from "@dspworkplace/ui";
import { useAuth, getCompanyId } from "../../../Auth";
import SettingsContext from "../context";
import SettingTable from './attendance'
import styled from 'styled-components';
import {getDriverLicenceExpiryNotiType} from "../../../Utilities/index";
import { AccessCheck, IsGranted } from "../../../security";
import { Label } from "../../../components/UI";
import { showToast } from "../../../Utilities";

const Container = styled.div`
    display: grid;
    width: 100%;
`;

const ErrorM = styled.span`
    display: block;
    margin-top: 5px;
    font-size: 11px;
    color: #FF0000;
`

const App = () => {
    const [state, dispatch] = useContext(SettingsContext);
    const { api } = useAuth();
    const { register, handleSubmit, reset, watch, getValues, setValue, errors } = useForm();
    const watchSendDriverLicenceExpiryNoti = watch("sendDriverLicenceExpiryNoti");
    
    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            form.querySelector('button').disabled = true;
            const notificationManagers = data.notificationManagers.length > 0 ? data.notificationManagers.join(","): null;

            await api.post('/api/lazy/manage/data', {
                actions: {
                    update: {
                        Company: {
                            update_company: {
                                findBy: {
                                    id: parseInt(state.company.id)
                                },
                                updateRecord: {
                                    expiryNotificationManager: notificationManagers,
                                    sendNotificationBeforeDays:data.sendNotificationBeforeDays,
                                    sendVehicleExpiryNotification: data.sendVehicleExpiryNoti,
                                    sendDriverLicenceExpiryNotification: data.sendDriverLicenceExpiryNoti,
                                    ...(data.sendDriverLicenceExpiryNoti && { driverLicenceExpiryNotiType: data.driverLicenceExpiryNotiType}),
                                }
                            }
                        }
                    },
                }
            });
            let updatedCompany = state.company;
            updatedCompany['sendVehicleExpiryNotification'] = data.sendVehicleExpiryNoti;
            updatedCompany['sendDriverLicenceExpiryNotification'] = data.sendDriverLicenceExpiryNoti;
            updatedCompany['driverLicenceExpiryNotiType'] = data.driverLicenceExpiryNotiType;
            await showToast({
                type: 'success',
                title: 'Expiration Notification Settings',
                content: 'Updates completed'
            });
            dispatch({type: 'UPDATE_COMPANY', payload: {company: {...updatedCompany, ...(data.sendDriverLicenceExpiryNoti && { driverLicenceExpiryNotiType: data.driverLicenceExpiryNotiType}) }}})
            form.querySelector('button').disabled = false;
        } catch (e) {
            await showToast({type: 'error', title: 'Expiration Notification Settings', content: JSON.stringify(e)});
            form.querySelector('button').disabled = false;
        }
    };

    useEffect(() => {
        setValue('sendNotificationBeforeDays', state.company.sendNotificationBeforeDays)
        setValue('sendDriverLicenceExpiryNoti', state.company.sendDriverLicenceExpiryNotification)
        setValue('sendVehicleExpiryNoti', state.company.sendVehicleExpiryNotification)
    },[state.company])

    const values = getValues();
   
    const driverLicenceExpiryNotiTypeOptions = getDriverLicenceExpiryNotiType();

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NotificationSetting register={register} />
                <Spacer bottom={5} />
                <Label>Send Notification Before Days</Label>
                <Spacer bottom={1} />
                <Input
                    ref={register({ required: "Required Field" ,  validate: value => parseInt(value) > 0 || 'should be greater than 0'})}
                    type="number"
                    name='sendNotificationBeforeDays'
                    size='extraSmall'
                    defaultValue={state.attendanceRules.late}
                />
                {errors && errors.sendNotificationBeforeDays && <ErrorM>{errors.sendNotificationBeforeDays.message}</ErrorM>}
                <Spacer bottom={4} />
                <Toggle
                    ref={register}
                    on={values.sendVehicleExpiryNoti}
                    name="sendVehicleExpiryNoti"
                    singleText="Send Vehicle License Expiration Notification"
                />
                <Spacer bottom={4} />
                <Toggle
                    ref={register}
                    on={values.sendDriverLicenceExpiryNoti}
                    name="sendDriverLicenceExpiryNoti"
                    singleText="Send Driver License Expiration Notification"
                />
                {watchSendDriverLicenceExpiryNoti && (
                    <Spacer top={4}>
                        <Label>Notification Send to</Label>
                        <RadioBox
                            ref={register({ required: "Required Field" })}
                            name="driverLicenceExpiryNotiType"
                            options={driverLicenceExpiryNotiTypeOptions}
                            Required={true}
                            default={state.company.driverLicenceExpiryNotiType}
                            valid={getValues("driverLicenceExpiryNotiType") && !errors.driverLicenceExpiryNotiType}
                            error={errors.driverLicenceExpiryNotiType && errors.driverLicenceExpiryNotiType.message}
                        />
                    </Spacer>
                )}
                <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                    <Spacer bottom={3} />
                    <Button type="primary">Save</Button>
                </IsGranted>
            </form>
        </Container>
    );
}

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

function formatForTagInputForUser(list, user) {
    if (list) {
        if (list.includes(user.id.toString())) {
            return { name: user.firstName + ' ' + user.lastName, value: user.id, selected: true };
        } else {
            return { name: user.firstName + ' ' + user.lastName, value: user.id };
        }
    }
}

async function fetchManager(api, state, setDefaultUsers) {
    let param = {
        "actions": {
            "response": {
                "User": {
                    "custom": {
                        "functionName": "findManagers",
                        "get": "managers",
                        "criteria": {
                            "company": localStorage.getItem('company')
                        }
                    }
                }
            }
        }
    }
    let res = await api.post('/api/lazy/manage/data', param);
    let users = res.data.data.managers;

    let defaultUsers =  state.company?.expiryNotificationManager ? state.company?.expiryNotificationManager.split(',') : [];
    let tags = [];
    let user;
    for (let i = 0; i < users.length; i++) {
        user = users[i];
        tags.push(formatForTagInputForUser(defaultUsers, user));
    }
    setDefaultUsers(tags);
    return true
}

const NotificationSetting = ({register}) => {
    const [state, dispatch] = useContext(SettingsContext);
    const { api } = useAuth();
    const [defaultUsers, setDefaultUsers] = useState([]);

    useEffect(() => {
        fetchManager(api, state, setDefaultUsers).then(() => {
        });
    }, [state.company]);

    return (
        <>
            <SubTitle>Notification Sent to Manager</SubTitle>
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Select Manager for Notification:"}
                        options={defaultUsers}
                        size="big"
                        name={'notificationManagers'}
                        ref={register}
                    />
                </TagInputContainer>
            </RowContainer>
            </>
    )
}

export default App;