import React, {useLayoutEffect} from "react";
import {Icon} from '@dspworkplace/ui';
import styled from 'styled-components';
import useScript from "../hooks/useScript";

const ReleaseBadge = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    #HW_badge_cont {
        height: 100%;
        display: block;

        #HW_badge {
            margin-left: 13px;

            &:empty {
                opacity: 0;
            }
        }
    }
`;

const useReleaseNote = () => {
    const account = process.env.REACT_APP_HEADWAY_ACCOUNT;
    if (!account) {
        return (
            <span className={'release-note'} style={{position: 'relative', height: '100%', display: 'block'}}
                  id={'release-button'}>
                <Icon.Megaphone size='24px'/>
                <ReleaseBadge className={'release-note-badge'}/>
            </span>
        );
    }
    return <ReleaseNoteComponent account={account}/>

}

const ReleaseNoteComponent = ({account}) => {
    const releaseNoteScriptStatus = useScript("https://cdn.headwayapp.co/widget.js");

    useLayoutEffect(() => {
        if (releaseNoteScriptStatus === 'ready') {
            const HW_config = {
                selector: '.release-note-badge', // CSS selector where to inject the badge
                account: account
            }
            window.Headway.init(HW_config);

        }
    }, [releaseNoteScriptStatus])

    return <span className={'release-note'} style={{position: 'relative', height: '100%', display: 'block'}}
                 id={'release-button'}>
        <Icon.Megaphone size='24px'/>
        <ReleaseBadge className={'release-note-badge'}/>
    </span>;
}

export {useReleaseNote}