import { Dispatch, useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { getID } from "./data";
import { getSelectedStationId } from "../../../Utilities";
import { VehicleImageCategory } from "../../Vehicles/VehicleImageCategories";

export type AiImageCategory = {
    id: string;
    name: string;
    isArchive?: boolean;
    checked?: boolean;
    frequency?: string;
    date?: string;
    sortOrder?: number;
    isEnable?: boolean;
}

export type AiCategoryNotification = {
    label: string;
    description: string;
    notification?: boolean;
}

export type PassQuestionType = {
    name: string;
    description: string;
    passLabel: string;
    failLabel: string;
    extraLabel: string;
    enableExtraLabel: boolean;
    isPassRequired: boolean;
    isPhotoRequired: boolean;
    isCommentRequired: boolean;
    notifyIfChosen: boolean;
    notifyIfNotChosen: boolean;
    notifyIfExtraChosen: boolean;
    messageIfNotChosen: string;
    messageIfExtraChosen: string;
    requireReasonIfFailChosen: boolean;
    requireReasonIfExtraChosen: boolean;
    additionalFailQuestion: string[];
    workbotAlertManager: string;
    disabledQuestion: boolean;
}

export type VehicleImageType = {
    name: string;
    description: string;
    isPassRequired: boolean;
    notifyIfChosen: boolean;
    vehicleImageCategories: VehicleImageCategory[];
    disabledQuestion: boolean;
}

export type AiImageType = {
    name: string;
    description: string;
    isPassRequired: boolean;
    notifyIfChosen: boolean;
    aiImageCategories: AiImageCategory[];
    disabledQuestion: boolean;
    submitToAi?: boolean;
    aiCategoryNotification: AiCategoryNotification[]
}

export type ScannerType = {
    name: string;
    description: string;
    type: string;
    length: number;
    isPassRequired: boolean;
    notifyIfSuccess: boolean;
    notifyIfFailure: boolean;
    messageIfFailure: string;
    disabledQuestion: boolean;
}

export type InputType = {
    name: string;
    description: string;
    isPassRequired: boolean;
    isSignature: boolean;
    notifyIfChosen: boolean;
    isPhotoRequired: boolean;
    disabledQuestion: boolean;
}

export type InstructionType = {
    name: string;
    description: string;
    disabledQuestion: boolean;
}

export type RangeNumberType = {
    name: string;
    description: string;
    isPassRequired: boolean;
    isRangeRequired: boolean;
    startRange: number;
    endRange: number;
    isPhotoRequired: boolean;
    isCommentRequired: boolean;
    notifyIfChosen: boolean;
    notifyOutOfRange: boolean;
    vehicleImageCategories?: VehicleImageCategory[];
    aiImageCategories?: AiImageCategory[];
    disabledQuestion: boolean;
}

export type MileageVerificationType = {
    name: string;
    description: string;
    isPassRequired: boolean;
    isPhotoRequired: boolean;
    notifyIfChosen: boolean;
    rtsMilesCheck: number | string;
    loadOutMilesCheck: number | string;
    vehicleImageCategories?: VehicleImageCategory[];
    aiImageCategories?: AiImageCategory[];
    disabledQuestion: boolean;
}

export type MultipleChoicesType = {
    name: string;
    description: string;
    notify: boolean;
    choices: Array<{
        name: string;
        isPassRequired: boolean;
        notifyIfChosen: boolean;
        notifyIfNotChosen: boolean;
    }>;
    vehicleImageCategories?: VehicleImageCategory[];
    aiImageCategories?: AiImageCategory[];
    disabledQuestion: boolean;
}

export type FuelLevelType = {
    name: string;
    description: string;
    minFuelLevel: string | number;
    isPhotoRequired: boolean;
    choices: Array<{ name: string; notifyIfChosen: boolean; }>;
    vehicleImageCategories?: VehicleImageCategory[];
    aiImageCategories?: AiImageCategory[];
    disabledQuestion: boolean;
}

export type ContentType =
    | PassQuestionType
    | InputType
    | MultipleChoicesType
    | VehicleImageType
    | ScannerType
    | RangeNumberType
    | FuelLevelType
    | InstructionType
    | AiImageType
    ;

export type ItemType = {
    id: number;
    type: number;
    content: ContentType;
}

export type ContainerItemsType = {
    id: number;
    name: string;
    items: Array<ItemType>;
}

type MenuType = 'TEMPLATE' | 'WORK_FLOW';

type State = {
    id: number;
    template: Array<ContainerItemsType>;
    stationId: number;
    loading: boolean;
    track: number;
    menu: MenuType;
    introduction: string;
    scenario: string;
    updatedBy?: { friendlyName?: string | null };
    updatedAt?: string | null;
    language: string;
    vehicleImageCategories: Array<VehicleImageCategory>;
    aiImageCategories: Array<AiImageCategory>;
};

type Action =
    | { type: 'SET_STATION_ID'; stationId: number }
    | { type: 'SET_MENU'; menu: MenuType }
    | { type: 'ADD_SECTION'; }
    | { type: 'SET_LOADING'; loading: boolean; }
    | {
        type: 'DELETE_SECTION'; id: number; updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'ADD_ITEM_SECTION'; sectionId: number, item: ItemType;
        updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'UPDATE_ITEM_SECTION'; sectionId: number; item: ItemType; updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'DELETE_ITEM_SECTION'; sectionId: number; itemId: number;
        updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'UPDATE_SECTION_NAME'; name: string; sectionId: number; updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'SET_INTRODUCTION'; introduction: string; updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'SET_TEMPLATE'; template: Array<ContainerItemsType>, stationId: number; updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
    }
    | {
        type: 'SET_DATA';
        id: number;
        template: Array<ContainerItemsType>;
        stationId: number;
        scenario: string;
        introduction: string;
        updatedBy?: { friendlyName: string };
        updatedAt?: string | null;
        language: string;
        vehicleImageCategories: Array<VehicleImageCategory>;
        aiImageCategories: Array<AiImageCategory>;
    }
    ;

export type ActionLoadOutRTSTemplate = Action;
export type StateLoadOutRTSTemplate = State;

const reducer = (state: StateLoadOutRTSTemplate, action: ActionLoadOutRTSTemplate): StateLoadOutRTSTemplate => {
    let data = state.template;
    switch (action.type) {
        case "SET_DATA":
            return {
                ...state,
                id: action.id,
                stationId: action.stationId,
                introduction: action.introduction,
                template: action.template,
                scenario: action.scenario,
                updatedBy: action.updatedBy,
                updatedAt: action.updatedAt,
                loading: false,
                language: action.language,
                vehicleImageCategories: action.vehicleImageCategories,
                aiImageCategories: action.aiImageCategories,
            };
        case "SET_MENU":
            return { ...state, menu: action.menu };
        case "SET_LOADING":
            return { ...state, loading: action.loading };
        case "SET_STATION_ID":
            return { ...state, stationId: action.stationId };
        case "UPDATE_SECTION_NAME":
            data = updateSectionName(state, action);
            return {
                ...state,
                template: data,
                updatedBy: action.updatedBy,
                updatedAt: action.updatedAt,
                loading: false,
            };
        case "SET_INTRODUCTION":
            return {
                ...state,
                introduction: action.introduction,
                updatedBy: action.updatedBy,
                updatedAt: action.updatedAt,
                loading: false,
            };
        case 'SET_TEMPLATE':
            return {
                ...state,
                template: action.template,
                stationId: action.stationId,
                updatedAt: action.updatedAt,
                updatedBy: action.updatedBy,
                loading: false,
            };
        case 'ADD_SECTION':
            let lastIndex = state.template.length;
            return {
                ...state,
                loading: false,
                template: [...state.template, { id: getID(), name: `Section #${lastIndex + 1}`, items: [] }],
            };
        case 'DELETE_SECTION':
            data = deleteSection(state, action);
            return {
                ...state, template: data, updatedAt: action.updatedAt, updatedBy: action.updatedBy, loading: false,
            };
        case "UPDATE_ITEM_SECTION":
            data = updateItemSection(state, action);
            return {
                ...state,
                template: data,
                updatedAt: action.updatedAt,
                updatedBy: action.updatedBy,
                loading: false,
            };
        case 'ADD_ITEM_SECTION':
            return {
                ...state,
                template: state.template.map((e) => {
                    if (e.id === action.sectionId) {
                        let section = state.template.find(e => e.id === action.sectionId);
                        if (section) {
                            return { ...e, items: [...section.items, action.item] }
                        }
                    }
                    return e;
                }),
                updatedAt: action.updatedAt,
                updatedBy: action.updatedBy,
                loading: false,
            };
        case 'DELETE_ITEM_SECTION':
            data = deleteItemSection(state, action);
            return {
                ...state,
                template: data,
                updatedAt: action.updatedAt,
                updatedBy: action.updatedBy,
                loading: false,
            };
        default:
            return state;
    }
};


const initialState: State = {
    template: [],
    stationId: getSelectedStationId(),
    loading: true,
    track: Math.random(),
    menu: "TEMPLATE",
    introduction: "",
    scenario: "",
    id: 0,
    language: "en",
    vehicleImageCategories: [],
    aiImageCategories: []
}

const useValue = () => useReducer(reducer, initialState);

export const {
    Provider,
    useTracked,
    useTrackedState,
    useUpdate: useDispatch,
} = createContainer(useValue);

export function useStoreReducer(): {
    appState: StateLoadOutRTSTemplate;
    appDispatch: Dispatch<ActionLoadOutRTSTemplate>;
} {
    const [state, dispatch] = useTracked();
    return { appState: state, appDispatch: dispatch };
}

export function deleteSection(state, action: { id: number }) {
    return state.template.filter((e) => e.id !== action.id);
}

export function deleteItemSection(state: State, action: { itemId: number, sectionId: number }) {
    return state.template.map((e) => {
        if (e.id === action.sectionId) {
            let section = state.template.find(e => e.id === action.sectionId);
            if (section) {
                return {...e, items: section.items.filter(e => e.id !== action.itemId)}
            }
        }
        return e;
    })
}

export function updateSectionName(state: State, action: { name: string, sectionId: number }) {
    return state.template.map(e => {
        if (e.id === action.sectionId) {
            return {...e, name: action.name};
        }
        return e;
    })
}

export function updateItemSection(state: State, action: { sectionId: number, item: ItemType }) {
    return state.template.map((e) => {
        if (e.id === action.sectionId) {
            let section = state.template.find(e => e.id === action.sectionId);
            if (section) {
                return {
                    ...e, items: section.items.map(e => {
                        if (e.id === action.item.id) {
                            return action.item;
                        }
                        return e;
                    })
                }
            }
        }
        return e;
    });
}

export function addItemSection(state: State, action: { sectionId: number, item: ItemType }) {
    return state.template.map((e) => {
        if (e.id === action.sectionId) {
            let section = state.template.find(e => e.id === action.sectionId);
            if (section) {
                return {...e, items: [...section.items, action.item]}
            }
        }
        return e;
    });
}