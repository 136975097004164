import React, {Dispatch, useEffect, useReducer} from "react";
import styled from "styled-components";

import {Item} from "../../../../SortTemplate/Container";
import Card from "./Card";
import Incidents from "../Incidents/Incidents";
import Rescues from "../Rescues/Rescues";
import {LoadingView} from '../../../../../../../components/Loading';
import {dspIcon, engine, getCompanyId, getCurrentTimezone, showErrorToast} from "../../../../../../../Utilities";
import moment from "moment-timezone";
import {createContainer} from "react-tracked";
import Row from "./Row";
import {Icon, Theme} from '@dspworkplace/ui';
import {ItemScorecardInformation} from "../../../shared";

const mobileWidth = 428;
const cardWidth = mobileWidth - 16 - 16;
type Data = {
    scores: Array<any>;
    week: string;
    rescues: { sortedList: Array<ItemScorecardInformation> };
    incidents: { sortedList: Array<ItemScorecardInformation> };
}

type DataState = {
    loading: boolean;
    scores: Array<any>;
    week: string;
    focus: any;
    rescuesCards?: Array<ItemScorecardInformation>;
    incidentsCards?: Array<ItemScorecardInformation>;
};

const initialState: DataState = {
    loading: true,
    scores: [],
    week: 'N/A',
    focus: null,
    rescuesCards: [],
    incidentsCards: [],
};

type DataAction =
    | {
    type: 'SET_OVERVIEW';
    data: Data;
}
    | {
    type: 'SET_LOADING';
    loading: boolean;
};

function reducer(
    state: DataState,
    action: DataAction
): DataState {
    switch (action.type) {
        case "SET_OVERVIEW":
            try {
                let focus = null;
                let {scores, week, rescues, incidents} = action.data;
                let rescuesCards = rescues.sortedList;
                let incidentsCards = incidents.sortedList;
                if (scores) {
                    focus = action.data.scores.find(e => e.metricKey === 'focus')
                }
                return {
                    loading: false,
                    scores: scores,
                    focus: focus,
                    week: week,
                    rescuesCards: rescuesCards,
                    incidentsCards: incidentsCards
                };
            } catch (e) {
                console.error({e});
                return {loading: false, scores: [], focus: null, week: 'N/A', rescuesCards: [], incidentsCards: []};
            }
        case "SET_LOADING":
            return {...state, loading: action.loading};
        default:
            return state;
    }
}

const useValue = () => useReducer(reducer, initialState);

const {Provider, useTracked} = createContainer(
    useValue
);

export function useOverviewTracked(): {
    state: DataState;
    dispatch: Dispatch<DataAction>;
} {
    const [state, dispatch] = useTracked();
    return {state, dispatch};
}

// const mobileWidth = 428;
const mobileHeight = 580;
const MobileContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    height: ${mobileHeight}px;
    max-height: ${mobileHeight}px;
    overflow-x: unset;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid ${Theme.colors.info.border};
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

async function fetchWeekCardsScoresData(stationId: number | string, driverId: number | string): Promise<any> {
    try {
        let params = {
            driverId,
            stationId,
            companyId: getCompanyId(),
            datetime: moment().toISOString(),
            timezone: getCurrentTimezone(),
            view: "WEEK_CARDS",
            date: moment().day('Sunday').format('YYYY-MM-DD'),
        };
        let {data} = await engine().post('/api/scorecard/driver', params);
        let scores = data.scores;
        if (Array.isArray(scores) && scores.length > 0) {
            return data;
        }
        let limit = 8;
        for (let i = 0; i < limit; i++) {
            params = {
                driverId,
                stationId,
                companyId: getCompanyId(),
                datetime: moment().toISOString(),
                timezone: getCurrentTimezone(),
                view: "WEEK_CARDS",
                date: moment().subtract((i + 1) * 7, 'days').day('Sunday').format('YYYY-MM-DD'),
            };
            let response = await engine().post('/api/scorecard/driver', params);
            scores = response.data.scores;
            if (Array.isArray(scores) && scores.length > 0) {
                return data;
            }
        }
        return data;
    } catch (e) {
        return {error: e, success: false};
    }
}

async function fetchWeekCardsScores(stationId: number | string, driverId: number | string): Promise<any> {
    try {
        let data = await fetchWeekCardsScoresData(stationId, driverId);
        if (data.error && !data.success) {
            await showErrorToast(data.error, 'Error fetching the Overview Tab Driver.');
            return null;
        }
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching Scorecard Driver.');
        return null;
    }
}

const Overview: React.FC<{ driverId?: number, stationId?: number, preview?: Map<number, Item> }> = ({
                                                                                                        driverId,
                                                                                                        stationId,
                                                                                                        preview
                                                                                                    }) => {
    const {dispatch, state} = useOverviewTracked();
    const {loading} = state;

    useEffect(() => {
        if (preview)
            return () => {
            };

        if (driverId && stationId) {
            const abortController = new AbortController();
            dispatch({type: 'SET_LOADING', loading: true});
            fetchWeekCardsScores(stationId, driverId).then((result) => {
                if (result) {
                    dispatch({type: 'SET_OVERVIEW', data: result!});
                }
            })

            return () => {
                abortController.abort();
            }
        }
    }, [driverId]);

    if (preview)
        return (
            <MobileContainer>
                {Array.from(preview).map(([, card]) =>
                    <Card
                        key={card.id}
                        metricName={card.text}
                        metricType={'number'}
                        metricValue={'-'}
                        change={0}
                        trend={'+'}
                        scenario={'tilde'}
                        trendStyle={''}
                    />
                )}
            </MobileContainer>
        )

    if (
        driverId && stationId &&
        !loading
    ) {
        return (
            <MobileContainer>
                <ContainerScoresRows driverId={driverId} stationId={stationId}/>
                <Incidents/>
                <Rescues/>
            </MobileContainer>
        );
    }

    return <LoadingView containerWidth={'100%'} containerHeight={mobileHeight}/>;

};

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: space-between;
    margin: 12px 16px;

    .viewWeek {
        display: flex;
        flex-direction: row;
        height: 30px;
    }

    .textWeek {
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
    }

    .rightTextWeek {
        display: flex;
        color: #516F90;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        margin-left: 16px;
    }

    .fullScoresButton {
        display: flex;
        height: 30px;
        flex-direction: row;
    }

    .fullScoreText {
        display: flex;
        font-size: 18px;
        line-height: 30px;
        color: #0071BC;
        text-decoration: underline;
        text-decoration-color: #0071BC;
    }

    .fullScoresArrow {
        display: flex;
        height: 30px;
        flex-direction: row;
        align-items: center;

    }
`;

const Header = ({week = 'N/A'}) => {
    return (
        <HeaderContainer>
            <div className={'viewWeek'}>
                <div className={'textWeek'}>
                    Scores
                </div>
                <div className={'rightTextWeek'}>
                    week #{week}
                </div>
            </div>
            <div className={'fullScoresButton'}>
                <div className={'fullScoreText'}>
                    Full Score
                </div>
                <div className={'fullScoresArrow'}>
                    <Icon.ArrowRight
                        size="16px"
                        color={'#7C98B6'}
                    />
                </div>
            </div>
        </HeaderContainer>
    );
};

const FocusAreaContainer = styled.div`
    display: flex;
    background-color: #EAF0F6;
    border-color: #7C98B6;
    border-radius: 2px;
    border-width: 1px;
    padding: 12px;
    flex-direction: row;
    align-items: center;
    height: 80 + 16;
    max-height: 80 + 16;
    margin: 4px 16px;


    .focusAreaText {
        display: flex;
        color: #516F90;
        font-size: 20px;
    }

    .focusAreaTextBold {
        display: flex;
        color: #516F90;
        font-size: 20px;
        font-weight: bold;
        margin-left: 4px;
    }

    .focusAreaExplanationText {
        display: flex;
        color: #516F90;
        font-size: 16px;
    }

    .focusAreaLeftView {
        display: flex;
        flex-direction: column;
        // width: 64px;
    }

    .focusAreaRightView {
        display: flex;
        flex-direction: column;
            // width: ${cardWidth - 12 - 12 - 64 - 12}px;
        margin-left: 12px;
    }
`;

export const FocusArea: React.FC<{ text?: string; explanation?: string }> = ({
                                                                                 text,
                                                                                 explanation,
                                                                             }) => {
    return (
        <FocusAreaContainer className={'focusAreaView'}>
            <div className={'focusAreaLeftView'}>
                {dspIcon({type: 'Info', color: '#7C98B6', size: '64px'})}
            </div>
            <div className={'focusAreaRightView'}>
                <div>
                    <div className={'focusAreaText'}>
                        {'Focus Area: '}
                        <div className={'focusAreaTextBold'}>
                            {text ? text : ''}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'focusAreaExplanationText'}>
                        {explanation ? explanation : ''}
                    </div>
                </div>
            </div>
        </FocusAreaContainer>
    );
};

export function checkFocusArea(value: string) {
    return value === 'focus' || value === 'FOCUS';
}

const ScoresContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
`;

const ContainerScoresRows: React.FC<{ driverId: number, stationId: number }> = () => {
    const {state} = useOverviewTracked();
    const {scores, week, focus} = state;

    if (
        Array.isArray(scores)
    ) {
        return <ScoresContainer>
            <Header week={week}/>
            {scores.map((e, i) => {
                if (checkFocusArea(e.metricIdentifier)) {
                    return null;
                }
                return (
                    <Row e={{...e,scoreWeek:week}} key={i}/>
                )
            })}
            {focus && <FocusArea
                text={
                    focus && typeof focus.metricValue === 'string'
                        ? focus.metricValue
                        : 'N/A'
                }
                explanation={focus.explanation}
            />}
        </ScoresContainer>
    }

    return null;
}

const OverviewSlide: React.FC<{ driverId?: number, stationId?: number, preview?: Map<number, Item> }> = ({
                                                                                                             driverId,
                                                                                                             stationId,
                                                                                                             preview
                                                                                                         }) => {
    return (
        <Provider>
            <Overview driverId={driverId} stationId={stationId} preview={preview}/>
        </Provider>
    );
};

export default OverviewSlide