import React, { useCallback, useEffect, useState } from 'react'
import { FetchTaskOpenShift } from '../../Chat/types';
import {
    engine,
    getChatCompanyId,
    getDriverId,
    getFriendlyName,
    getMainStationId,
    getChatUserId,
    showErrorToast
} from "../../Utilities";
import { useLoading } from '../../Chat/Loading';
import { useTrackedState, useUpdate as useBotDispatch } from './Store';
import styled from "styled-components";
import { Button, CheckBox } from "@dspworkplace/ui";
import { useForm } from 'react-hook-form';
import BotMessage from './BotMessage';
import BotInput from './BotInput';
import ScrollToBottom, { useScrollToBottom, useScrollToEnd } from "react-scroll-to-bottom";
import { ButtonContainer, Container, ContainerItem, ContainerMultipleOpenShiftDriver, ScrollContainer, Text } from './BotTaskList';
import moment from 'moment';
import { confirm } from "../../components/Confirm";

function BotOPenShift({ taskInformation, channel }) {
    const state = useTrackedState();
    const { message } = state;
    const dispatch = useBotDispatch();

    const { loading, setLoading } = useLoading();
    const [systemMsg, setSystemMsg] = useState(message);
    const [count, setCount] = useState(0);
    const [shiftInformation, setShiftInformation] = useState("");
    const [task, setTask] = useState();
    const [shift, setShift] = useState();
    const [drivers, setDrivers] = useState([]);
    const [showDriversToSelect, setShowDriversToSelect] = useState(true);
    const [rejectedDrivers, setRejectedDrivers] = useState([]);
    const [showRejectionPart, setShowRejectionPart] = useState(false);
    const [showApprovalPart, setShowApprovalPart] = useState(false);
    const [placeholder, setPlaceholder] = useState("Type a message...");
    const [step, setStep] = useState("");
    const [disabled, setDisabled] = useState(true);
    let managerFriendlyName = getFriendlyName();
    const [rejectedMessage, setRejectedMessage] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState("Send");
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [approvedMessage, setApprovedMessage] = useState("");
    const [taskLoading, setTaskLoading] = useState(false);
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [taskErrorMessage, setTaskErrorMessage] = useState("");
    const [showFinalPart, setShowFinalPart] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [selectedDriver, setSelectDriver] = useState('')

    useEffect(() => {
        const abortController = new AbortController();
        setTaskForOpenShifts(taskInformation, message).then(data => {
            if (data) {
                setSystemMsg({ user: message?.user, date: message?.created_at, text: message?.text });
                setShiftInformation(data.shiftInformation);
                setShift(data.shift);
                setTask(data.task);
                setDrivers(data.drivers);
                setCount(data.count);
                if (data?.message && data?.message?.length > 0) {
                    setTaskErrorMessage(data.message);
                    setTaskCompleted(true);
                    setDisabled(true);
                }
                setLoading(false);
            }
        })
        return () => {
            abortController.abort();
        };
    }, []);

    async function setTaskForOpenShifts(taskInformation, message) {
        try {
            let { data } = await engine({ hideDefaultMessage: true })
                .post("/api/fetch_task", {
                    task: {
                        id: taskInformation.id,
                        type: taskInformation.type,
                    },
                    companyId: getChatCompanyId(),
                    stationId: getMainStationId(),
                    userId: getChatUserId(),
                    driverId: getDriverId(),
                    datetime: moment().toISOString(),
                    messageSid: message?.id,
                    channelSid: channel?.id,
                    attributes: message?.attributes,
                    taskInformation,
                });
            return data;
        } catch (e) {
            await showErrorToast(e, 'Error in fetching the task information for Open Shifts.')
            return null;
        }
    }

    const removeMessage = useCallback(
        async () => {
            dispatch({ type: "MESSAGE_REMOVED", sid: message.id });
        },
        [dispatch, message, message.id],
    );

    const approveMultipleDriver = useCallback(
        (checkedDrivers) => () => {
            let selected = checkedDrivers.filter(e => e.selected);
            let notSelected = checkedDrivers.filter(e => !e.selected);
            if (selected.length === 0) {
                return;
            }
            setSelectedDrivers(selected);
            // setRejectedDrivers(notSelected);
            setShowDriversToSelect(false);
            let msg = `${managerFriendlyName} has approved your open shift request ${shiftInformation}.`;
            setApprovedMessage(msg);
            setPlaceholder("Thank You!");
            setDisabled(false);
            setShowApprovalPart(true);
            setStep('SEND');
            if (selected.length === 1 && notSelected.length === 0) {
                setStep(" FINISH_TASK_ALL_PASS");
                setSubmitButtonText("Finish Task");
            }
            if (selected.length === drivers.length) {
                setStep(" FINISH_TASK_ALL_PASS");
                setSubmitButtonText("Finish Task");
            }
        },
        [drivers.length, managerFriendlyName, shiftInformation],
    );

    const selectOneDriver = async (driver) => {
        if (driver === '' || driver === undefined) {
            showErrorToast('', 'please first select user')
            return
        }

        if(driver.isConsicutive.status){
            const confirmation = await confirm(driver.isConsicutive.message.web);
            if (!confirmation) {
                return true;
            }
        }
        setShowDriversToSelect(false);
        let approved = [driver];
        let notApproved = drivers.filter(e => e.id !== driver.id);
        setSelectedDrivers(approved);
        setRejectedDrivers(notApproved);
        let msg = `${managerFriendlyName} has approved your open shift request ${shiftInformation}.`;
        setApprovedMessage(msg);
        setPlaceholder('Thank You!');
        setStep('SEND');
        setDisabled(false);
        setShowApprovalPart(true);
        if (drivers.length === 1) {
            setStep(" FINISH_TASK_ALL_PASS");
            setSubmitButtonText("Finish Task");
        }
    }

    const rejectDriver = useCallback(
        () => {
            setShowDriversToSelect(false);
            setRejectedDrivers(drivers);
            setShowRejectionPart(true);
            setShowApprovalPart(false);
            setPlaceholder("Sorry, this shift has been assigned, please look for another open shift.");
            setStep("FINISH_TASK");
            setDisabled(false);
            let msg = `${managerFriendlyName} has denied your open shift request ${shiftInformation}.`;
            setRejectedMessage(msg);
            setShowRejectionPart(true);
            setSubmitButtonText("Finish Task");
        },
        [drivers, managerFriendlyName, shiftInformation],
    );

    const reset = useCallback(
        () => {
            setSelectedDrivers([]);
            setRejectedDrivers([]);
            setApprovedMessage("");
            setShowDriversToSelect(true);
            setPlaceholder("");
            setDisabled(true);
            setTaskLoading(false);
            setTaskCompleted(false);
            setRejectedMessage("");
            setShowRejectionPart(false);
            setSubmitButtonText("Send");
            setStep("");
            setShowFinalPart(false);
            setShowApprovalPart(false);
            setDisableButton(false);
        },
        [],
    );

    const completeTask = useCallback(
        async ({ approvedMsg, rejectedMsg }) => {
            try {
                setDisableButton(true);
                setStep("");
                let params = {
                    datetime: moment().toISOString(),
                    task,
                    selectedDrivers,
                    rejectedDrivers,
                    approvedMessage: approvedMsg,
                    rejectedMessage: rejectedMsg,
                    stationId: getMainStationId(),
                    userId: getChatUserId(),
                    driverId: getDriverId(),
                }
                await engine().post('/api/complete_task', params);
                setTaskCompleted(true);
                setDisabled(true);
                await removeMessage();
            } catch (e) {
                await showErrorToast(e, 'Error in Task Open Shift completion.');
            }
        },
        [removeMessage, rejectedDrivers, selectedDrivers, task],
    );

    const submitMessage = useCallback(
        async (message) => {
            let msg = `${managerFriendlyName} has denied your open shift request ${shiftInformation}.`;
            if (step === "SEND") {
                setApprovedMessage(prevState => prevState + "\n" + message);
                setShowApprovalPart(false);
                setPlaceholder("Sorry, this shift has been assigned, please look for another open shift.");
                setStep("FINISH_TASK");
                setRejectedMessage(msg);
                setShowRejectionPart(true);
                setSubmitButtonText("Finish Task");
                return;
            }
            if (step === "FINISH_TASK") {
                setRejectedMessage(msg + '\n' + message);
                setShowFinalPart(true);
                setShowRejectionPart(false);
                setDisabled(true);
                setTaskLoading(true);
                setPlaceholder("");
                setDisabled(true);
                setSubmitButtonText("Send");
                await completeTask(
                    {
                        approvedMsg: approvedMessage,
                        rejectedMsg: msg + '\n' + message,
                    });
            }
            if (step === " FINISH_TASK_ALL_PASS") {
                setApprovedMessage(prevState => prevState + "\n" + message);
                setShowFinalPart(true);
                setShowRejectionPart(false);
                setDisabled(true);
                setTaskLoading(true);
                setPlaceholder("");
                setDisabled(true);
                setSubmitButtonText("Send");
                await completeTask(
                    {
                        approvedMsg: approvedMessage + '\n' + message,
                        rejectedMsg: msg,
                    });
            }
        },
        [approvedMessage, completeTask, managerFriendlyName, shiftInformation, step],
    );

    const showFinishTaskMessage = useCallback(
        () => {
            return (selectedDrivers.length === 1 && rejectedDrivers.length === 0) || (selectedDrivers.length === drivers.length);
        },
        [drivers.length, rejectedDrivers.length, selectedDrivers.length],
    );

    return (
        <>
            <ScrollContainer mode={'bottom'} height={"calc(100% - 156px) !important"}>
                <BotMessage currentMessage={message} />
                {count > 1 &&
                    <BotMessage currentMessage={{
                        ...systemMsg,
                        text: `There are ${count} available Open Shifts.`
                    }} />
                }
                {count > 1 && drivers.length > 1 && showDriversToSelect &&
                    <Container className={"btn"}>
                        <MultipleOpenShiftDriver drivers={drivers}
                            count={count}
                            approveMultipleDriver={approveMultipleDriver}
                        />
                        <ButtonContainer onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</ButtonContainer>
                    </Container>
                }
                {count > 1 && drivers.length === 1 && showDriversToSelect &&
                    <Container className={"btn"}>
                        {drivers.map(item => <OpenShiftDriverButton driver={item} key={item.id}
                            setSelectDriver={setSelectDriver} />)
                        }
                        <ButtonContainer onClick={() => selectOneDriver(selectedDriver)}>
                            Approve
                        </ButtonContainer>
                        <ButtonContainer onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</ButtonContainer>
                    </Container>
                }
                {count === 1 && showDriversToSelect &&
                    <Container className={"btn"}>
                        {drivers.map(item => <OpenShiftDriverButton driver={item} key={item.id}
                            setSelectDriver={setSelectDriver} />)}
                        <ButtonContainer onClick={() => selectOneDriver(selectedDriver)}>
                            Approve
                        </ButtonContainer>
                        <ButtonContainer onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</ButtonContainer>
                    </Container>
                }
                <MessagesToSent systemMsg={systemMsg} message={approvedMessage} messageProps={message}
                    drivers={selectedDrivers} scenario={"APPROVAL"} />
                {showApprovalPart && selectedDrivers.length > 0 && approvedMessage &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            text: showFinishTaskMessage() ? "To complete the approval, please add any additional text to the message box below and press Finish Task." : "To complete the approval, please add any additional text to the message box below and press Send.",
                        }} />
                    </>
                }
                {showRejectionPart && rejectedDrivers.length > 0 &&
                    <>
                        <BotMessage currentMessage={{
                            ...message,
                            date: new Date(),
                            text: `The rejected driver(s) will receive the following messages:`,
                        }} />
                        <MessagesToSent systemMsg={systemMsg} message={rejectedMessage} messageProps={message}
                            drivers={rejectedDrivers} scenario={"REJECTION"} />
                        <BotMessage currentMessage={{
                            ...message,
                            date: new Date(),
                            text: `Please type an additional message to send to the rejected driver(s) or press Finish Task.`,
                        }} />
                        <Container className={"btn"}>
                            <ButtonContainer onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                    </>
                }
                {showFinalPart &&
                    <>
                        <MessagesToSent systemMsg={systemMsg} message={rejectedMessage} messageProps={message}
                            drivers={rejectedDrivers} scenario={"REJECTION"} />
                    </>
                }
                {taskLoading &&
                    <BotMessage currentMessage={{
                        ...message,
                        date: new Date(),
                        text: "Please wait, we are completing the task.",
                    }} />
                }
                {taskErrorMessage && taskErrorMessage.length > 0 && 
                    <BotMessage currentMessage={{
                        ...message,
                        date: new Date(),
                        text: taskErrorMessage,
                    }} />
                }
                {taskCompleted &&
                    <BotMessage currentMessage={{
                        ...message,
                        date: new Date(),
                        text: "Task Completed!",
                    }} />
                }
            </ScrollContainer>
            <BotInput submitMessage={submitMessage} placeholder={placeholder} disableSendButton={disableButton} submitButtonText={submitButtonText} disabled={disabled} />
        </>
    )
}

export default BotOPenShift

function MultipleOpenShiftDriver({ drivers, count, approveMultipleDriver }) {
    const { register } = useForm({ mode: 'onBlur' });
    const [checkedDrivers, setCheckedDrivers] = useState([]);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();
        setCheckedDrivers((drivers.map(e => ({ ...e, disabled: false, selected: false }))));
        return () => {
            abortController.abort();
        };
    }, [drivers]);

    useEffect(() => {
        const abortController = new AbortController();
        if (currentCount === count) {
            setCheckedDrivers(prevDrivers => {
                return prevDrivers.map(e => {
                    if (!e.selected) {
                        return { ...e, disabled: true };
                    }
                    return e;
                });
            });
        }
        return () => {
            abortController.abort();
        };
    }, [count, currentCount]);

    const onClick = (driverId, isSelected) => {
        if (!isSelected) {
            setCheckedDrivers(prevDrivers => {
                return prevDrivers.map(e => {
                    if (e.id === driverId) {
                        if (currentCount + 1 > count) {
                            return { ...e };
                        } else {
                            setCurrentCount(prevCount => prevCount + 1);
                        }
                        return { ...e, selected: true };
                    }
                    return e;
                });
            });
        } else {
            if (currentCount === count) {
                setCheckedDrivers(prevDrivers => {
                    return prevDrivers.map(e => {
                        if (e.id === driverId) {
                            setCurrentCount(prevCount => prevCount - 1);
                            return { ...e, selected: false };
                        }
                        return { ...e, disabled: false };
                    });
                });
            } else {
                setCheckedDrivers(prevDrivers => {
                    return prevDrivers.map(e => {
                        if (e.id === driverId) {
                            setCurrentCount(prevCount => prevCount - 1);
                            return { ...e, selected: false };
                        }
                        return e;
                    });
                });
            }
        }
    }

    return (
        <>
            {checkedDrivers.map((driver) => {
                return <ContainerMultipleOpenShiftDriver key={driver.id}>
                    <ContainerItem columnGap={"4px"} className={'driver'}>
                        <Text className={"openShiftDriver"}>{driver.fullName}</Text>
                        <Text className={"openShiftDriver"}>{" - "}</Text>
                        <Text className={"openShiftDriver"}>
                            {driver.weekHours.workedHours}/{driver.weekHours.scheduledHours}</Text>
                    </ContainerItem>
                    <ContainerItem width={'auto'} className={'checkBox'} padding={'0'}>
                        <CheckBox
                            name={`driverId`}
                            disabled={driver.disabled}
                            ref={register}
                            onClick={() => {
                                onClick(driver.id, driver.selected);
                            }}
                            options={[{ label: "", value: driver.id }]}
                        />
                    </ContainerItem>
                </ContainerMultipleOpenShiftDriver>
            })}
            <ButtonContainer onClick={approveMultipleDriver(checkedDrivers)}>{"Approve"}</ButtonContainer>
        </>
    );
}

function OpenShiftDriverButton({ driver, setSelectDriver }) {
    return (
        <ButtonContainer onClick={() => setSelectDriver(driver)}>
            {driver.fullName}{" - "}{driver.weekHours.workedHours}/{driver.weekHours.scheduledHours}
        </ButtonContainer>
    );
}

function MessagesToSent({ drivers, systemMsg, message, messageProps, scenario }) {
    return (
        <>
            {drivers.map((e, i) => {
                if (i === 0) {
                    return <BotMessage currentMessage={{
                        ...messageProps,
                        created_at: new Date(),
                        text: scenario === "APPROVAL" ? `The approved driver will receive this message: "Hi ${e.fullName}. ${message}` : `The rejected driver will receive this message: "Hi ${e.fullName}. ${message}`,
                    }} />
                }
                return <BotMessage currentMessage={{
                    ...messageProps,
                    created_at: new Date(),
                    text: scenario === "APPROVAL" ? `The approved driver will receive this message: "Hi ${e.fullName}. ${message}` : `The rejected driver will receive this message: "Hi ${e.fullName}. ${message}`,
                }
                } />
            })}
        </>
    )
}

const taskData = {
    "count": 1,
    "countOpenShifts": 1,
    "countDrivers": 1,
    "shiftInformation": "(10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET",
    "drivers": [
        {
            "id": 2444,
            "fullName": "Steven Pignataro",
            "weekHours": {
                "workedHours": 0,
                "scheduledHours": 33,
                "week": "28",
                "year": "2023",
                "$weekStartEndDay": {
                    "week_start": "2023-07-09",
                    "week_end": "2023-07-15"
                },
                "$sumWorked": 0,
                "$sumScheduled": 1980,
                "$minutesScheduled": [
                    660,
                    660,
                    660
                ],
                "$minutesWorked": [],
                "driverRoutes": [
                    {
                        "week": 28,
                        "id": 2071798,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-10T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071799,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-12T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071800,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-14T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    }
                ]
            }
        }
    ],
    "shift": {
        "id": 2423590,
        "name": "10 Hr - Lvl 2",
        "hour": "8:15am - 7:45pm ET",
        "category": 3,
        "dateCreated": "2023-07-13T00:00:00+00:00",
        "date": "Thursday, 13th July",
        "startTime": "1970-01-01T08:15:00-05:00",
        "startTimeHour": "8:15am",
        "startTimeUTC": "47700",
        "endTime": "1969-12-31T19:45:00-05:00",
        "endTimeHour": "7:45pm",
        "endTimeUTC": "2700",
        "timeZoneName": "America/Detroit",
        "timezone": "ET"
    },
    "task": {
        "id": 641238,
        "name": "Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
        "description": null,
        "data": {
            "assignees": [
                {
                    "id": 1559,
                    "friendlyName": "Joe Rogan"
                },
                {
                    "id": 4546,
                    "friendlyName": "Shawn Kohen"
                },
                {
                    "id": 5586,
                    "friendlyName": "Test Driver"
                }
            ]
        },
        "isCompleted": false,
        "completedAt": null,
        "dueDate": "2023-07-14T06:49:27+00:00",
        "isArchived": false,
        "type": "OPEN_SHIFT",
        "assignees": [
            {
                "id": 1559,
                "friendlyName": "Joe Rogan",
                "identity": "a11a0bf4b51b2992a5570dab45dc5549",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 4546,
                "friendlyName": "Shawn Kohen",
                "identity": "4546-460c06a4cde3f6",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 5586,
                "friendlyName": "Test Driver",
                "identity": "5586-79a55b0f755da7",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 145,
                        "roleName": "Assistant Station Manager",
                        "name": "ROLE_ASSISTANT_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            }
        ],
        "completedBy": null,
        "isCanceled": false,
        "driverRoute": null,
        "messagesTwilioData": [
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM4496daf5170647d8b672f71e7d16d049",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049",
                    "body": "Hi Joe Rogan. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                    "index": 331,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:49:28.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:49:28.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH70ce7932db0f4b19b25479fe74f6ed51"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM2a64dce0d2874d2bb085f6ba5ef96d46",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46",
                    "body": "Hi Shawn Kohen. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                    "index": 50,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:49:29.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:49:29.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH42282464978b4d91889b7a10f7efb6f6"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IMa95463b03aa0478ea9dc9e7ed8f1a3ed",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed",
                    "body": "Hi Test Driver. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                    "index": 51,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:49:30.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:49:30.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH23eb87dbef004f9f86b582068acec329"
                }
            }
        ],
        "isRejected": false,
        "isAccepted": false,
        "message": null,
        "openShiftRequest": {
            "id": 3736,
            "isArchived": false,
            "isCompleted": false,
            "isRejected": false,
            "isCanceled": false,
            "category": 3,
            "shiftType": {
                "id": 252,
                "name": "10 Hr - Lvl 2",
                "category": 3,
                "categoryName": "Training"
            },
            "requesters": [
                {
                    "id": 2444,
                    "friendlyName": "Steven Chat",
                    "identity": "2696-fc8909c66172ed",
                    "isArchive": false
                }
            ],
            "openShifts": [
                {
                    "id": 2423590,
                    "startTime": "1970-01-01T08:15:00-05:00",
                    "endTime": "1969-12-31T19:45:00-05:00",
                    "dateCreated": "2023-07-13T00:00:00+00:00",
                    "shiftName": "10 Hr - Lvl 2",
                    "isArchive": false,
                    "category": 3
                }
            ],
            "dateCreated": "2023-07-13T00:00:00+00:00",
            "startTime": "1970-01-01T13:15:00+00:00",
            "endTime": "1970-01-01T00:45:00+00:00"
        },
        "hrFormIncident": null,
        "updatedMessagesTwilioData": [],
        "updatedAt": "2023-07-13T06:49:31+00:00",
        "createdAt": "2023-07-13T06:49:27+00:00"
    },
    "twilioMessages": [
        {
            "message": "Message created.",
            "success": true,
            "twilioMessage": {
                "sid": "IM4496daf5170647d8b672f71e7d16d049",
                "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049",
                "body": "Hi Joe Rogan. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                "index": 331,
                "links": {
                    "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049/ChannelMetadata",
                    "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM4496daf5170647d8b672f71e7d16d049/Receipts"
                },
                "media": null,
                "author": "system",
                "delivery": null,
                "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                "contentSid": null,
                "dateCreated": {
                    "date": "2023-07-13 06:49:28.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "dateUpdated": {
                    "date": "2023-07-13 06:49:28.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                "participantSid": null,
                "conversationSid": "CH70ce7932db0f4b19b25479fe74f6ed51"
            }
        },
        {
            "message": "Message created.",
            "success": true,
            "twilioMessage": {
                "sid": "IM2a64dce0d2874d2bb085f6ba5ef96d46",
                "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46",
                "body": "Hi Shawn Kohen. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                "index": 50,
                "links": {
                    "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46/ChannelMetadata",
                    "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM2a64dce0d2874d2bb085f6ba5ef96d46/Receipts"
                },
                "media": null,
                "author": "system",
                "delivery": null,
                "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                "contentSid": null,
                "dateCreated": {
                    "date": "2023-07-13 06:49:29.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "dateUpdated": {
                    "date": "2023-07-13 06:49:29.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                "participantSid": null,
                "conversationSid": "CH42282464978b4d91889b7a10f7efb6f6"
            }
        },
        {
            "message": "Message created.",
            "success": true,
            "twilioMessage": {
                "sid": "IMa95463b03aa0478ea9dc9e7ed8f1a3ed",
                "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed",
                "body": "Hi Test Driver. Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.",
                "index": 51,
                "links": {
                    "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed/ChannelMetadata",
                    "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IMa95463b03aa0478ea9dc9e7ed8f1a3ed/Receipts"
                },
                "media": null,
                "author": "system",
                "delivery": null,
                "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                "attributes": "{\"task\":{\"id\":641238,\"type\":\"OPEN_SHIFT\",\"createdAt\":\"2023-07-13T06:49:27+00:00\",\"name\":\"Open Shift Request for: (10 Hr - Lvl 2) Thursday, July 13th, 9:15am - 8:45pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\",\"driverRoute\":null,\"openShiftRequest\":{\"id\":3736}},\"companyId\":85}",
                "contentSid": null,
                "dateCreated": {
                    "date": "2023-07-13 06:49:30.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "dateUpdated": {
                    "date": "2023-07-13 06:49:30.000000",
                    "timezone": "Z",
                    "timezone_type": 2
                },
                "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                "participantSid": null,
                "conversationSid": "CH23eb87dbef004f9f86b582068acec329"
            }
        }
    ]
}
