import React, { useCallback, useEffect, useState } from 'react';
import ModalWindow from '../Chat/Modal';
import { useDispatch, useTrackedState } from './store';
import { Grid, PrimaryButton, TagInputContainer } from './styles';
import { TagInput } from '@dspworkplace/ui';
import { ChannelList } from 'stream-chat-react';
import { getChatUserId, getChatCompanyId, getChatTeam } from '../Auth';
import styled from "styled-components";
import ChannelIcon from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";

function ArchiveGroupModal() {
    const state = useTrackedState();
    const dispatch = useDispatch();
    const { archiveGroupChannelModal, chatClient } = state

    const options = {
        member: true,
        watch: true,
    };

    const closeModal = useCallback(
        () => {
            dispatch({ type: "CLOSE_ARCHIVE_GROUP_MODAL" });
        },
        [dispatch],
    );

    const ChannelPreview = (props) => {
        const { agentChannelId, channel, customerChannelId, setActiveChannel, unread } = props;

        let image = channel.data.image || "";
        let name = channel.data.name || "";
        let type = channel.data.type || "";
        let subTitle = "";
        let userTitle = "";

        if (channel.type == 'bot') {
            name = 'workplace_bot';
            subTitle = 'Your Work Assistant';
        }
        if (channel.type == 'duo') {
            Object.keys(channel.state.members).map((member) => {
                if (member != chatClient.userID && member != 'bot') {
                    name = channel.state.members[member].user.name;
                    image = channel.state.members[member].user.image;
                    userTitle = channel.state.members[member].user.user_role;
                }
            });
        }

        return (
            <ChannelContainer
                key={channel.id}
                onClick={() => {
                    closeModal()
                    setActiveChannel(channel)
                }}
            >
                <ChannelIcon channel={channel} image={image} type={type} name={name} />
                <TextInformationContainer>
                    <ChannelTitle width={'unset'} channelName={name} fontWeight={'500'} />
                    <ChannelSubtitle width={'unset'} channel={channel} type={type} userTitle={userTitle} />
                </TextInformationContainer>
                {unread > 0 && <Notification >{unread}</Notification>}
            </ChannelContainer>
        );
    };

    const customSearchGroup = async (props, event: { target: { value } }, client) => {
        const { setResults, setSearching, setQuery } = props;
        const value = event.target.value;

        let userIds = [];
        let channels = [];
        setQuery(value);
        if (value && value.length > 0) {
            const userList = await chatClient.queryUsers(
                {
                    id: { $ne: chatClient.userID },
                    $and: [{ name: { $autocomplete: value }, team: getChatTeam() }],
                },
                { id: 1 },
                { limit: 100 },
            );

            await userList?.users?.map((user) => { console.log(user); userIds.push(user.id); });

            if (userIds.length > 0) {
                const filters = {
                    type: 'duo',
                    teams: { $in: [getChatTeam()] },
                    members: { $in: userIds },
                    isArchive: true
                }

                setSearching(true);
                channels = await client.queryChannels(filters);
                await channels.map((channel) => {
                    Object.keys(channel.state.members).map((member) => {
                        if (member != client.userID) {
                            channel.data.name = channel.state.members[member].user.name;
                        }
                    });
                    return channel;
                });
                channels = await channels.filter((channel) => {
                    return channel.state.members.hasOwnProperty(client.userID);
                });

            } else {
                // setQuery(value);
            }

            const filter_group = {
                name: { $autocomplete: value },
                // type: 'group',
                // teams: { $in: [getChatTeam()] },
                members: { $in: [chatClient.userID] },
                isArchive: true
            }

            setSearching(true);
            setQuery(value);
            const channels_group = await client.queryChannels(filter_group);
            setResults([...channels, ...channels_group]);
            setSearching(false);
        }
        setSearching(false);
    };

    if (!archiveGroupChannelModal) {
        return null;
    }

    return (
        <ModalWindow width={"700px"} title={"Archived Group Channels"} cancelModal={closeModal}>
            <Grid templateRows={"auto auto"} rowGap={"16px"}>
                {/* <TagInputContainer width={"658px"} templateRows={"auto"}>
                    <TagInput
                        label="Find by Group Name"
                        // options={searchFilterArchiveChannels}
                        size="big"
                        // onChange={handleTagChange}
                        name={'names'}
                    />
                </TagInputContainer> */}

                <ChannelList
                    filters={{ type: 'group', teams: { $in: [getChatTeam()] }, members: { $in: [chatClient.userID] }, isArchive: true }}
                    sort={{ last_message_at: -1 }}
                    channelRenderFilterFn={(channels) => { return channels.filter((data) => data.data.isArchive === true) }}
                    additionalChannelSearchProps={{
                        searchFunction: (params, event) => {
                            return customSearchGroup(params, event, chatClient);
                        },
                    }}
                    options={options}
                    showChannelSearch
                    Preview={ChannelPreview}
                    // onChannelUpdated={onChannelUpdated}
                    setActiveChannelOnMount={false}
                />
            </Grid>
        </ModalWindow>
    )
}

export default ArchiveGroupModal

const ChannelContainer = styled.div`
    display: grid;
    width: unset;
    height: 56px;
    grid-template-columns: 40px auto 20px 12px;
    grid-column-gap: 6px;
    padding-top: 8px;
    padding-Bottom: 8px;
    align-items: center;
    border-Bottom: 1px dashed #7C98B6;
    margin-left: 16px;
    margin-right: 16px;
`;

const TextInformationContainer = styled.div`
    display: grid;
    height: 32px;
    grid-template-columns: auto;
    grid-template-rows: 18px 14px;
    cursor: pointer;
`;
const Notification = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    text-align: center;
    background-color: ${props => (props.bgColor ? props.bgColor : "#d94c53")};
    font-size: 10px;
    color: white;
    cursor: pointer;
`;