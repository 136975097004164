import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {ButtonComponent} from "./shared";
import {Icon, Button} from '@dspworkplace/ui';
import {useStoreReducer} from "./store";
import {useForm} from "react-hook-form";
import {updateIntroduction} from "./util";
import {Form, TextArea} from "./Item";
import Loading from "../../../Chat/Loading";
import {LoadOutIntroduction, RTSIntroduction} from "./data";

const Text = styled.div`
    display: grid;
    flex-direction: row;
    color: #516F90;
    font-family: circe-rounded, serif;
    font-size: 18px;
`;

const Title = styled.div`
    display: grid;
    flex-direction: row;
    color: #0071BC;
    font-family: circe-rounded, serif;
    font-size: 24px;
`;

const Container = styled.div`
    display: grid;
    flex-direction: column;
    color: #0071BC;
    font-family: circe-rounded, serif;
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 16px;
`;

const Row = styled.div`
    display: grid;
    flex-direction: row;
    grid-column-gap: 16px;
    grid-template-columns: max-content max-content;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    &.title {
        grid-template-columns: auto max-content;
    }

    .titleText {
        color: #516F90;
        font-family: circe-rounded, serif;
        font-size: 20px;
    }
`;

function isEmpty(introduction: any) {
    return !(typeof introduction === 'string' && introduction.length > 2);
}

const Introduction: React.FC<{
    introduction: string | null;
    scenario: string;
    stationId: number;
}> = ({
          introduction,
          scenario,
          stationId
      }) => {
    const [showEdit, setShowEdit] = useState(false);

    const showToEdit = useCallback(
        () => {
            setShowEdit(true);
        },
        [],
    );

    const closeEdit = useCallback(
        () => {
            setShowEdit(false);
        },
        [],
    );

    if (isEmpty(introduction)) {
        return (
            <Container>
                <Row>
                    <Title>
                        Introduction:
                    </Title>
                    {showEdit && <ButtonComponent onClick={closeEdit}>
                        <Icon.Times size={"20px"}/>
                    </ButtonComponent>}
                    {!showEdit && <ButtonComponent onClick={showToEdit}>
                        <Icon.Edit size={"20px"}/>
                    </ButtonComponent>}
                </Row>
                {showEdit && <FormIntroduction setShowEdit={setShowEdit} stationId={stationId}/>}
                {!showEdit && scenario === 'RTS' && <Text>
                    {RTSIntroduction}
                </Text>}
                {!showEdit && scenario === 'LOAD_OUT' && <Text>
                    {LoadOutIntroduction}
                </Text>}
            </Container>
        );
    }

    return (
        <Container>
            <Row>
                <Title>
                    Introduction:
                </Title>
                {showEdit && <ButtonComponent onClick={closeEdit}>
                    <Icon.Times size={"20px"}/>
                </ButtonComponent>}
                {!showEdit && <ButtonComponent onClick={showToEdit}>
                    <Icon.Edit size={"20px"}/>
                </ButtonComponent>}
            </Row>
            {showEdit && <FormIntroduction setShowEdit={setShowEdit} stationId={stationId}/>}
            {!showEdit && scenario === 'RTS' && <Text>
                {introduction}
            </Text>}
            {!showEdit && scenario === 'LOAD_OUT' && <Text>
                {introduction}
            </Text>}
        </Container>
    );
};

export default Introduction;

const FormIntroduction: React.FC<{
    stationId: number;
    setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
          setShowEdit,
      }) => {
    const {appState, appDispatch} = useStoreReducer();
    const {register, handleSubmit} = useForm({
        defaultValues: {
            introduction: appState.introduction ? appState.introduction : '',
        }
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        appDispatch({type: 'SET_LOADING', loading: true});
        setLoading(true);
        let res = await updateIntroduction(appState.stationId!, data.introduction.trim(), appState.scenario!, appState.id!);
        appDispatch({
            type: 'SET_INTRODUCTION',
            introduction: data.introduction.trim(),
            updatedBy: res.updatedBy,
            updatedAt: res.updatedAt
        });
        setLoading(false);
        setShowEdit(false);
    }

    return (
        <Form className={'introduction'} onSubmit={handleSubmit(onSubmit)}>
            <TextArea
                name="introduction"
                ref={register({required: true})}
                width={'calc(100vw - 300px - 200px - 32px)'}
            />
            {!loading && <Button type="primary" Type="submit" className={'buttonSaveIntroduction'}>
                Save
            </Button>}
            {loading && <Loading imgHeight={'28px'} imgWidth={'32px'}/>}
        </Form>
    );
}