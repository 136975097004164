import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {Icon} from '@dspworkplace/ui';
import PassQuestionForm from "./PassQuestionForm";
import {deleteItemSection, ItemType, updateItemSection, useStoreReducer} from "./store";
import PhotoQuestionForm from "./VehicleImageForm";
import ScannerQuestionForm from "./ScannerQuestionForm";
import MultipleChoicesQuestionForm from "./MultipleChoicesQuestionForm";
import MileageQuestionForm from "./MileageQuestionForm";
import InputForm from "./InputForm";
import {confirm} from '../../../components/Confirm';
import Loading from "../../../Chat/Loading";
import {updateTemplate} from "./util";
import FuelLevelQuestionForm from "./FuelLevelQuestionForm";
import InstructionQuestionForm from "./InstructionQuestionForm";
import PackagesQuestionForm from "./PackagesQuestionForm";
import AiImageInspectionForm from "./AiImageInspectionForm";
import MileageVerificationQuestionForm from "./MileageVerificationQuestionForm";

export const TextArea = styled.textarea`
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
    opacity: 1;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    width: ${props => (props.width ? props.width : "auto")};
    height: ${props => (props.height ? props.height : "auto")};

    &:hover,
    &:focus,
    &:active {
        border: 1px solid rgb(0, 113, 188);
        box-shadow: rgb(157, 221, 244) 0 0 6px;
    }
`;

export const Form = styled.form`
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    grid-row-gap: 8px;
    justify-content: flex-start;
    align-items: center;
    font-family: circe-rounded, serif;
    color: #707070;
    font-weight: normal;

    &.introduction {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-row-gap: 8px;
    }

    &.sectionTitle {
        grid-template-columns: auto auto auto;
        grid-column-gap: 8px;
    }

    .sectionTitleInput {
        height: 32px;
        margin: 0;
        font-size: 20px;
    }

    label {
        text-align: left;
        display: flex;
        align-items: center;
        color: #707070;
        font-size: 18px;
        font-family: circe-rounded, serif;
        height: 32px;
    }

    label.extraLabel {
        font-size: 14px;
    }

    div.isPhotoRequiredContainer {
        display: grid;
        grid-template-columns: 20px auto;
        grid-column-gap: 4px;
        align-items: center;
    }

    label.isPhotoRequired {
        display: flex;
        color: #707070;
        font-size: 12px;
        font-family: circe-rounded, serif;
    }

    label.isSignature {
        font-size: 16px;
    }

    .choices {
        text-align: left;
        display: flex;
        color: #707070;
        font-size: 18px;
        font-family: circe-rounded, serif;
    }

    .choicesList {
        display: grid;
        flex-direction: row;
        grid-column-gap: 8px;
        grid-template-columns: max-content auto;
        align-items: center;
    }

    input {
        font-family: circe-rounded, serif;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #EAF0F6;
        margin-bottom: 10px;
        font-size: 18px;
        max-height: 32px;
        margin-top: 8px;
    }

    .checkbox {
        display: flex;
        align-items: center;
        width: 12.5px;
        margin: unset;
    }

    .isPassRequired {
        font-size: 16px;
    }

    .descriptionInstruction {
        height: 160px;
    }

    .buttonSaveIntroduction {
        width: 12%;
        font-size: 18px;

    }
`;

export const ManageDisabledCheckBox = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    .ml_100 {
        margin-left: 100px;
    }
    @media (max-width: 992px) {
        .ml_100 {
            margin-left: 30px;
        }
    }
`;

const Container = styled.div`
    margin: 0;
`;
export const RowContent = styled.div`
    display: grid;
    flex-direction: row;
    grid-column-gap: 8px;
    grid-template-columns: max-content max-content max-content auto auto;
    align-items: center;
    justify-content: flex-start;

    &.sectionTitle {
        grid-column-gap: 16px;
    }

    .itemContent {
        color: #516F90;
        font-family: circe-rounded, serif;
        font-size: 18px;
    }
`;

type Props = {
    sectionId: number;
    item: ItemType;
    type: number;
    label: string;
    provided: {
        dragHandleProps: {
            'aria-describedby': string;
            'data-rbd-drag-handle-context-id': string;
            'data-rbd-drag-handle-draggable-id': string;
            draggable: boolean
            onDragStart: (event: any) => {}
            role: string
            tabIndex: number;
        },
        draggableProps: {
            'data-rbd-draggable-context-id': string;
            'data-rbd-draggable-id': string;
            onTransitionEnd: any;
            style: { transform: any, transition: any }
        },
        innerRef: any;
    };
    snapshot: {
        combineTargetFor: null
        combineWith: null
        draggingOver: null
        dropAnimation: null
        isClone: false
        isDragging: false
        isDropAnimating: false
        mode: null
    };
    stationId: number;
    scenario: string;
}

const Item: React.FC<Props> = (props) => {
    const {provided, item, type, sectionId, stationId, scenario} = props;
    const [showForm, setShowForm] = useState(false);
    const {appState, appDispatch} = useStoreReducer();
    const [loading, setLoading] = useState(false);

    const toggleForm = useCallback(
        () => {
            setShowForm(!showForm);
        },
        [showForm],
    );

    const update = async (sectionId, itemUpdated) => {
        appDispatch({type: 'SET_LOADING', loading: true});
        let data = updateItemSection(appState, {sectionId: sectionId, item: itemUpdated});
        let res = await updateTemplate(stationId, data, scenario, appState.id!);
        appDispatch({
            type: 'UPDATE_ITEM_SECTION',
            sectionId: sectionId,
            item: itemUpdated,
            updatedBy: res.updatedBy,
            updatedAt: res.updatedAt
        });
    }

    const updateItem = async (data) => {
        setLoading(true);
        let itemUpdated = item;
        itemUpdated.content = data;
        await update(sectionId, itemUpdated);
        setLoading(false);
        setShowForm(false);
    }

    const deleteItem = async () => {
        let r = await confirm('Are you sure you want to delete this item?');
        if (!r) {
            return;
        }
        appDispatch({type: 'SET_LOADING', loading: true});
        setLoading(true);
        let data = deleteItemSection(appState, {itemId: item.id, sectionId: sectionId});
        let res = await updateTemplate(stationId, data, scenario, appState.id!, 'DELETE_ITEM_SECTION');
        appDispatch({
            type: 'DELETE_ITEM_SECTION',
            itemId: item.id,
            sectionId: sectionId,
            updatedAt: res.updatedAt,
            updatedBy: res.updatedBy
        });
        setLoading(false);
    }

    return (
        <Container>
            <RowContent>
                <div {...provided.dragHandleProps}>
                    <Icon.Move size={'20px'}/>
                </div>
                <div onClick={deleteItem}>
                    <Icon.Trash size={'20px'}/>
                </div>
                <div onClick={toggleForm}>
                    {!showForm && <Icon.Edit size={'20px'}/>}
                    {showForm && <Icon.Contract size={'20px'}/>}
                </div>
                <div className={'itemContent'}>
                    {item.content.name}
                </div>
                {loading && <Loading imgHeight={'28px'} imgWidth={'32px'}/>}
            </RowContent>
            {showForm && type === 1 &&
            <PassQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 2 &&
            <PhotoQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 3 &&
            <MultipleChoicesQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 4 &&
            <InputForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 5 &&
            <ScannerQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 6 &&
            <MileageQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 11 &&
                <MileageVerificationQuestionForm
                    updateItem={updateItem}
                    type={type}
                    sectionId={sectionId}
                    itemId={item.id}
                    scenario={scenario}
                    //@ts-ignore
                    item={item}
                />}
            {showForm && type === 7 &&
            <FuelLevelQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 8 && <InstructionQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 9 && <PackagesQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 10 && <PackagesQuestionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
            {showForm && type === 12 && <AiImageInspectionForm
                updateItem={updateItem}
                type={type}
                sectionId={sectionId}
                itemId={item.id}
                //@ts-ignore
                item={item}
            />}
        </Container>
    );
}

export default Item;