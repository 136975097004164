import React from "react";
import {Link as RouterLink} from 'react-router-dom'
import styled from "styled-components";
import {Spacer, Theme, ThirdTitle} from "@dspworkplace/ui";

const Wrapper = styled.div`
    // min-height: 100vh;
    // max-width: calc(85vw - 164px);
    margin-left: 20px;
    margin-right: 20px;
    
    @media (min-width: 1220px) {
        // margin-left: 164px;
        margin-left: 164px;
        margin-right: 132px;
    }
`;

const LinkStyle = styled.a`
    color: ${Theme.colors.secondary};
    font-size: ${Theme.font.small.size};
    text-decoration: underline;
    cursor: pointer;
    word-break: break-word;
`;

const Link = props => {
    return ( props.to ) ? (
        <RouterLink to={props.to}>
            <LinkStyle as={'span'} {...props}>{props.children}</LinkStyle>
        </RouterLink>
    ) : (
        <LinkStyle {...props}>{props.children}</LinkStyle>
    );
};

const Label = styled.label`
  font-size :${Theme.font.medium.size};
  font-weight: normal;
  font-family: ${Theme.font.main};
  display: block;
  padding-bottom:2px;
  color: ${Theme.colors.neutrals.medium};
`;

const Badge = styled.div`
    position: absolute; 
    top: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 10px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;
    background: ${Theme.colors.error.text}
    border-radius: 50%;
`;

const Tag = styled.div`
    height: 24px;
    box-sizing: border-box;
    border-radius: ${Theme.defaultRadius};
    font-size: ${Theme.font.extraSmall.size};
    font-weight: 500;
    line-height: 24px;
    padding: 0 8px;

    ${props => `background: ${Theme.colors[props.type || 'info'].bg};`}
    ${props => `color: ${Theme.colors[props.type || 'info'].text};`}
    ${props => `border: 1px solid ${Theme.colors[props.type || 'info'].border};`}
    
    ${props => props.small && `
    height: 20px;
    line-height: 18px;
    font-size: 10px;
    `}
    
    ${props => props.inline && `
    display: inline-block;
    `}
`;

const DevWarning = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: 4,
            background: Theme.colors.primary,
            // textAlign: 'center',
            // paddingLeft: 400
        }}>
            <Badge style={{
                position: 'static',
                display: 'inline-block',
                padding: 4,
                fontSize: Theme.font.extraSmall.size,
                height: 'auto',
                width: 'auto',
                borderRadius: Theme.defaultRadius,
                background: Theme.colors.primary,
            }}>development</Badge>
        </div>
    );
}

const Card = styled(Spacer).attrs(() => ({
    all: 5
}))`
    display: block;
    box-sizing: border-box;
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}26;
    width: 100%;
    height: 100%;
    border-radius: ${Theme.defaultRadius};
    transition: all .2s ease;
    
    .icon-action {
        &:hover {
           > path {
             fill: ${Theme.colors.info.text};
           }
        }
    }
    
    &:hover {
        box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}44;
    }
`;

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const Divider = ({top = 3, bottom = 4}) => {
    return <>
        <Spacer top={top} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
        <Spacer top={bottom}/>
    </>
}

const Status = ({current = 'default', config = {}, children, ...props}) => {
    let style = {};
    let types = {
        'default': 'info',
        'success': 'success',
        'warning': 'warnings',
        'error': 'error'
    };

    if (config[current])
        if (typeof config[current] === 'string' && types[config[current]])
            style.type = types[config[current]];
        else
            style.style = {
                background: config[current].bg,
                color: config[current].text,
                borderColor: config[current].border,
            };
    else if (types[current])
        style.type = types[current];
    else
        style.type = 'info';

    return <Tag small inline {...props} {...style}>{children}</Tag>;
}

const CardDrag = styled(Card)`
    display: flex;
    align-items: center;   
    height:auto;
    padding:12px;
    position:relative; 
   
    .dragger {
        cursor: move;   
        margin-right:8px;
    }
    
    &:hover {
        border-color: ${Theme.colors.info.border};
    }
    
    ${props => props.isDragging ? `
        opacity: 0.2;
        background:${Theme.colors.neutrals.silver}
    ` : ''}
`;

const ButtonIcon = ({
    icon,
    ...props
}) =>
    React.createElement(icon, {
        color: Theme.colors.info.border,
        size: '20px',
        ...props
    });

const ProgressBarElm = styled.div`
    width: ${props => props.width || 104}px;
    height: ${props => props.height || 16}px;
    background: ${Theme.colors.neutrals.white};
    border: 1px solid ${props => props.progressBorder || Theme.colors.neutrals.silver};
    border-radius: ${Theme.defaultRadius};
    box-sizing: border-box;
    
    &::after {
        content: '';
        display: block;
        height: 100%;
        background: ${props => props.progressBg || Theme.colors.info.shadow};
        width: ${props => props.progress || 0}%;
    }
    
    ${props => props.progress === 100 && `
    border-color: ${Theme.colors.success.border};
    
    &::after {
        background: ${Theme.colors.success.bg};
    }
    `}
`;

const ProgressBar = props =>
    <div>
        <div style={{display: 'flex', gap: 8}}>
            <ProgressBarElm {...props}/>
            <ThirdTitle style={{fontWeight: 'normal'}}>{Math.round(props.progress)}%</ThirdTitle>
        </div>
        {props.description ? (
            <Spacer top={1}>
                <ThirdTitle style={{fontWeight: 'normal'}}>
                    <small>{props.description}</small>
                </ThirdTitle>
            </Spacer>
        ) : null}
    </div>

const GridColumns = styled.div.attrs(props => ({
    columns: props.columns || 3,
    unit: props.unit || '1fr',
}))`
    display: grid;
    grid-template-columns: ${props => new Array(props.columns + 1).join(`${props.unit} `)};
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    
    ${Card} {
        z-index: 0;
    }
`;

const HelpButton = styled.small`
    display: inline-block;
    text-align: center;
    min-width: 1em;
    height: 1em;
    background-color: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    font-size: ${Theme.font.small.size};
    line-height: ${Theme.font.medium.lineHeight};
    padding: 4px 8px;
    border-radius: 100px;
    vertical-align: middle;
    margin: -2px 0 0 8px;
    cursor: pointer;
`

export {
    Wrapper, Link,
    Label, Badge,
    Tag, DevWarning,
    Card, Divider, Status,
    Question,
    CardDrag,
    ButtonIcon,
    ProgressBar,
    GridColumns, HelpButton,
}