import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
    Button,
    CheckBox,
    CustomDropdown,
    Icon,
    Input,
    Spacer,
    TagInput,
    Text,
    Theme,
    ThirdTitle,
    Title,
    SubTitle
} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import {windowInfo} from "../../components/scrollHandler";
import {useLoading} from "../../components/Loading";
import Modal, {useModal} from "../../components/Modal";
import {Header, Btn} from "../../components/Grid/Tools/Filter/Filter";
import {Table} from "../../components/Table";

import Form from "./form";
import Csv from "./csv";
import Invitation from "./invitaion";
import ChangePassword from "./changePassword";
import ResetPassword from './resetPassword';
import DriverAlias from './driverAlias';
import DeleteShift from './deleteShift';
import Timeline from "./timeLine";
import Details from "./details";
import {useAuth} from "../../Auth";
import Moment from "moment";
import StationSelector, {useSelectedStation} from "../../components/StationSelector";
import {Token} from "../../hooks/useGlobalData";
import toast from "../../components/Toasts/toast";
import {dialog} from "../../components/Dialog";
import {showDefaultErrorToast} from "../../Utilities";
import {EmployeesList} from "./employeesList";
import {allowToChatUser} from "./allowToChat";
import useSubscriptionsApi from "../../api/useSubscriptionsApi";
import Tooltip from "../../components/Tooltip";
import { Badge } from "../../components/UI";
import FormCombobox from "../../components/Form/Combobox/combobox.hookForm";
import { get } from "sortablejs";
import e from "cors";
import FilterBottom from "../../components/FilterBottom";
import { AccessCheck, IsGranted } from "../../security";
import { alert } from "../../components/Alert";

const Body = styled.div`
    //min-height:100vh;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 156px;
    max-width: 1265px;
`;

export const FlexScroll = styled.div`
display: flex;
flex-direction: column;
overflow-y: auto;
overflow-x: hidden;
height: calc(100vh - 400px);
//&::-webkit-scrollbar {
//    width:0;
//}

&::-webkit-scrollbar-track-piece {
    background: #EAF0F6;
}
    
    &::-webkit-scrollbar-thumb {
        background: #7C98B6
    }
    `;

const Filter = styled.form`
    display: flex;
`;

const HiddenSubmit = styled.button`
    opacity: 0;
    width: 1px;
    height: 1px;
    position: relative;
    top: -20px;
`;

const CreateSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    padding: 16px;
    border-top: 1px solid ${Theme.colors.info.text};
    // max-width: 1064px;
    width: ${props=>props.width ? props.width : '100%'};;

    position: sticky;
    bottom: 0;

    &.fixed {
        position: fixed;
        bottom: 0;
    }

    > h3 {
        font-weight: normal;
    }
`;

const handleFixedAdd = ({ prevPos, currPos }, addBtnRef) => {
    const w = windowInfo();
    if (currPos.y > w.height || w.height >= addBtnRef.current.offsetTop) {
        document.body.style.paddingBottom = addBtnRef.current.offsetHeight + "px";
        addBtnRef.current.classList.add("fixed");
    } else {
        if (addBtnRef.current.classList.contains("fixed")) {
            document.body.style.paddingBottom = 0;
            addBtnRef.current.classList.remove("fixed");
        }
    }
};

//useScrollPosition((data)=>{ handleFixedAdd(data,addBtnRef)},[],addBtnRef,false,0);

const sortByF = (employees, criterias) => {
    return criterias.reverse().map(c => {
        let d = c.direction;
        return employees.sort((f, s) => {
            let str1 = f[c.field];
            let str2 = s[c.field];

            if (typeof f[c.field] === "object") {
                str1 = str1.sort().join("~");
            }
            if (typeof f[c.field] === "number") str1 = f[c.field].toString();
            if (typeof f[c.field] === "boolean") str1 = f[c.field] ? "1" : "0";

            if (typeof s[c.field] === "object") {
                str2 = str2.sort().join("~");
            }
            if (typeof s[c.field] === "number") str2 = s[c.field].toString();
            if (typeof s[c.field] === "boolean") str2 = s[c.field] ? "1" : "0";

            switch (c.field) {
                default:
                    return d === 0 ? str1.localeCompare(str2) : str2.localeCompare(str1);
            }
        });
    });
};

const App = () => {
    const [isLoading, setLoading] = useLoading();
    const { api } = useAuth();
    const [authToken] = Token();
    const [employees, setEmployees] = useState({
        data: [],
        loadData: [],
        allSkills: [],
        allSchedules: [],
        allRoles: [],
        allStations: [],
        editId: 0,
        currentStation:''
    });
    const [allEmployees, setAllEmployees] = useState({});
    const [dataFilters, setDataFilters] = useState({});
    const [driverStations, setDriverStations] = useState({});
    const [sortBy, setSortby] = useState([{ field: "name", direction: 0 }]);
    const [open, setOpen] = useState(false);

    const [csvVisible, toggleCsv] = useModal(false);
    const [invitationVisible, toggleInvitaion] = useModal(false);
    const [changePasswordVisible, toggleChangePassword] = useModal(false);
    const [formVisible, toggleForm] = useModal(false);
    const [timelineVisible, toggleTimeline] = useModal(false);
    const [detailsVisible, toggleDetails] = useModal(false);
    const [resetPasswordVisible, setResetPasswordVisible] = useModal(false);
    const [removeShiftVisible, setRemoveShiftVisible] = useModal(false);
    const [driverAliasVisible, setDriverAliasVisible] = useModal(false);
    const [editKey, setEditKey] = useState({});
    const [selectedStation] = useSelectedStation();
    const [rolesPermissionLevel, setRolesPermissionLevel] = useState([]);

    const FullRole = [
        "ROLE_OWNER",
        "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        "ROLE_OPERATIONS_MANAGER",
        "ROLE_FLEET_MANAGER",
        "ROLE_HR_ADMINISTRATOR",
        "ROLE_DISPATCHER",
        "ROLE_CENTRAL_DISPATCHER",
        "ROLE_STATION_MANAGER",
        "ROLE_ASSISTANT_STATION_MANAGER"
    ];
    const handleOrder = async field => {
        let index = sortBy.findIndex(criteria => criteria.field.toString() === field.toString());

        let sortCopy = [...sortBy];
        let sorted = [];

        if (index >= 0) {
            let criteria = sortCopy[index];

            if (criteria.direction === 0) {
                criteria.direction = 1;
                sorted = await sorter(employees.data, field, "ASC");
            } else if (criteria.direction === 1) {
                criteria.direction = 0;
                sorted = await sorter(employees.data, field, "DESC");
            }
        } else {
            sortCopy.push({ field: field, direction: 0 });
            sorted = await sorter(employees.data, field, "DESC");
        }

        // const sorted = sortByF([...employees.data] ,sortCopy );
        setSortby(sortCopy);

        if (sorted) {
            setEmployees({ ...employees, data: sorted ,loadData: sorted.slice(0,15)});
        }
    };

    const handleSearch = (search, schedules, skills, roles, stations, userStatus, nameSearchText) => {
        setDataFilters([]);
        let len = allEmployees.data.length;
        let lenSchedule = schedules?.length;
        let lenSkill = skills?.length;
        let lenRole = roles?.length;
        let lenUserStatus = userStatus?.length;
        let results = [];

        if ( stations.indexOf("-1") > -1 )
            stations = [];

        let lenStation = stations.length;

        if (search === "" && nameSearchText === "" && lenSchedule <= 0 && lenSkill <= 0 && lenRole <= 0 && lenStation <= 0 && lenUserStatus <= 0) {
            setEmployees(allEmployees);
        } else {
            for (let i = 0; i < len; i++) {
                let searchPass = false;
                let schedulePass = false;
                let skillPass = false;
                let rolePass = false;
                let stationPass = false;
                let userStatusPass = false;
                let userNamePass = false;
                const nameSearchArray = ['firstName', 'middleName', 'lastName', 'friendlyName']
                if (search !== "") {
                    searchPass =  RegExp(search,'ig').test(JSON.stringify(allEmployees.data[i]))
                    /*for (const property in allEmployees.data[i]) {
                        if (allEmployees.data[i][property]) {
                            if (
                                property !== "id" &&
                                allEmployees.data[i][property]
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                            ) {
                                console.log('aaa', allEmployees.data[i])
                                searchPass = true;
                                break;
                            }
                        }
                    }*/
                } else {
                    searchPass = true;
                }

                if (nameSearchText !== "") {
                    for (let p = 0; p < nameSearchArray.length; p++) {
                        const property = nameSearchArray[p];
                        if (allEmployees.data[i][property]) {
                            if (
                                allEmployees.data[i][property]
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(nameSearchText?.toLowerCase())
                            ) {
                                userNamePass = true;
                                break;
                            }
                        }
                    }
                } else {
                    userNamePass = true;
                }

                if (lenSchedule > 0) {
                    if (allEmployees.data[i]["schedules"]) {
                        for (let si = 0; si < lenSchedule; si++) {
                            if (allEmployees.data[i]["schedules"].some(el => el.name === schedules[si])) {
                                schedulePass = true;
                                break;
                            }
                        }
                    }
                } else {
                    schedulePass = true;
                }

                if (lenSkill > 0) {

                    if (allEmployees.data[i]["skills"]) {
                        for (let ski = 0; ski < lenSkill; ski++) {
                            if (allEmployees.data[i].skills.indexOf(skills[ski]) > -1) {
                                skillPass = true;
                                break;
                            }
                        }
                    }
                } else {
                    skillPass = true;
                }

                if (lenRole > 0) {
                    if (allEmployees.data[i]["roles"]) {
                        for (let ri = 0; ri < lenRole; ri++) {
                            if (allEmployees.data[i].roles.indexOf(roles[ri].toString()) > -1) {
                                rolePass = true;
                                break;
                            }
                        }
                    }
                } else {
                    rolePass = true;
                }

                if (lenStation > 0) {
                    if (allEmployees.data[i]["stations"]) {
                       stationPass = allEmployees.data[i].stations.some(el => stations.includes(el.id.toString()));
                    }
                } else {
                    stationPass = true;
                }

                if (lenUserStatus > 0) {
                    for (let st = 0; st < lenUserStatus; st++) {
                        let tempStatus = userStatus[st] == 3 ? 0 : userStatus[st];
                        if (tempStatus == "all" && allEmployees.data[i].employeeStatus != -2) {
                            userStatusPass = true;
                            break;
                        } else if( allEmployees.data[i].employeeStatus ==  tempStatus){
                            userStatusPass = true;
                            break;
                        }
                    }
                } else {
                    userStatusPass = true;
                }

                if (searchPass && schedulePass && skillPass && rolePass && stationPass && userStatusPass && userNamePass) {
                    results.push(allEmployees.data[i]);
                }
            }
            if (lenUserStatus > 0) {
                setDataFilters(userStatus);
            }

            setEmployees({ data: results,loadData: results.slice(0,15) });
        }
    };

    const sorter = async (array, field, direction = "DESC") => {
        //0 DESC
        //1 ASC
        const sorted = await array.sort((a, b) => {
            if (direction === "DESC") return (a[field] > b[field]) - (a[field] < b[field]);
            return (b[field] > a[field]) - (b[field] < a[field]);
        });
        return sorted;
    };
    const fetchEmployees = async (station,employeeStatus = []) => {
        setLoading(true);
        const paramsNew = {
            "actions":{
                "response":{
                    "Driver":{
                        "custom":{
                        "functionName":"getAllEmployees",
                        "get":"employees",
                        "criteria":{
                            "companyId":localStorage.getItem("company"),
                            "station":station ,
                            "employeeStatus" : employeeStatus
                        }
                        }
                    }
                }
            }
        };

        try {
            let response = await api.post("/api/lazy/manage/data", paramsNew);
            // console.table(response.data.data.employees.drivers);
            return new Promise((resolve, reject) => {
                setEmployees({
                    ...employees,
                    data: response.data.data.employees.drivers,
                    allSkills: response.data.data.employees.skills,
                    allSchedules: response.data.data.employees.schedules,
                    allRoles: response.data.data.employees.roles,
                    allStations: response.data.data.employees.stations
                });
                setAllEmployees({
                    ...employees,
                    data: response.data.data.employees.drivers,
                    allSkills: response.data.data.employees.skills,
                    allSchedules: response.data.data.employees.schedules,
                    allRoles: response.data.data.employees.roles,
                    allStations: response.data.data.employees.stations
                });
                setLoading(false);
            });
        } catch (error) {
            await showDefaultErrorToast(error);
        }
    };

    const modalHandler = async(id, type, key, empKey = null) => {
        if (id > 0) {
            const station = employees.data[key].stations ? employees.data[key].stations[0].name : '';
            setEmployees({ ...employees, editId: id, currentStation: station, driver:employees.data[key] });
        }

        switch (type) {
            case "timeline":
                toggleTimeline(false);
                break;
            case "edit":
                setEditKey(key);
                toggleForm(true);
                break;
            case "details":
                toggleDetails(id);
                break;
            case "archive":
                handleArchive(id, key);
                break;
            case "changePassword":
                setResetPasswordVisible(true);
                break;
            case "removeShift":
                setRemoveShiftVisible(true);
                break;
            case "driverAlias":
                setDriverAliasVisible(true);
                break;
            case "unlockUser":
                console.log(employees.data[key]);
                const paramsActive ={
                    "actions": {
                        "update": {
                            "User": {
                                "user_1": {
                                    "findBy": {
                                        "id": employees.data[key].userId
                                    },
                                    "updateRecord": {
                                        "lockUser": null,
                                        "failAttempts": 0
                                    }
                                }
                            }
                        },
                    }
                }
                await api.post('/api/lazy/manage/data', paramsActive);

                employees.data[key].lockUser = false;
                setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15)});

                break;
            case "empStatus":
                let value = key;
                let driver = id;
                let action = value.name;
                let offboardedDate = null;
                if(driver.employeeStatus == '-1') {
                    let offboardedDate = null;
                }
                let tempEmpKey =  allEmployees.data.filter(function(item,key) {
                    if( item.id == driver.id ){
                        empKey = key
                        return item;
                    }
                });

                let tempIndex  = 0;
                let tempIn =  employees.data.filter(function(item,key) {
                    if( item.id == driver.id ){
                        tempIndex = key
                        return item;
                    }
                });

                let status = '';
                if(driver.employeeStatus == '-2'){
                    status = 'Terminated';
                } else if(driver.employeeStatus == '-1'){
                    status = 'Offboarded';
                }else if(driver.employeeStatus == '0'){
                    status = 'Inactive';
                }else if(driver.employeeStatus == '1'){
                    status = 'Onboarding';
                }else {
                    status = 'Active';
                }

                // check company last owner more then one
                const companyLastOwnerParams = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "getCompanyLastOwner",
                                    criteria: {
                                        employeeEmail: driver.email
                                    },
                                    get: "result",
                                },
                            },
                        },
                    },
                };

                const companyLastOwnerResponse = await api.post("/api/lazy/manage/data", companyLastOwnerParams);
                if (companyLastOwnerResponse.data.data.result) {
                    const response = companyLastOwnerResponse.data.data.result;
                    let text = "You can't change " +driver.friendlyName+"'s status from "+status+" to Active, because this driver is Active at another company..!";
                    if(response.lastOwnerCount === 0){
                        text = "You can't change "+driver.friendlyName +"'s status because the company has only one active Owner/Operation Account Manager.";
                    }
                    if (response.lastOwnerCount === 0 || (response.activeEmployeeAnotherCompany === true && action === "Active")) {
                        alert({
                            text: text,
                            btnText: "Cancel",
                        });
                        await setEmployees({ ...employees, data: employees.data, loadData: [] });
                        setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                        return false;
                    }
                }

                const removeDriverFromVehicle ={
                    "actions": {
                        "response": {
                            "Vehicle": {
                                "custom": {
                                    "functionName": "removeDriverFromVehicle",
                                    "criteria": {
                                        "driver": driver.id
                                    },
                                    "get": "drivers"
                                }
                            }
                        }
                    }
                }

                switch (action) {
                    case "Active":
                        await dialog({
                            text: "You are about to change "+driver.friendlyName+"'s status from "+status+" to Active.",
                            buttons: [
                                {
                                    type: 'primary',
                                    text: 'Yes'
                                },
                                {
                                    text: 'No',
                                    callback: async () => {
                                        await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                        setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                        return false;
                                    },
                                }
                            ]
                        }).then( async (empStatus) => {
                            if(!empStatus){
                                setAllEmployees(allEmployees);
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });
                                return false;
                            } else {
                                setLoading(true);
                                allEmployees.data[empKey].employeeStatus = "2";
                                setAllEmployees(allEmployees);
                                employees.data[tempIndex].employeeStatus = "2";
                                //employees.data[tempIndex].allowToChat = true;
                                let tempEmployee = employees.data[tempIndex];
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });
                                if(dataFilters.length > 0){
                                    if ( dataFilters.indexOf("2") == -1 ) {
                                        employees.data.splice(tempIndex, 1);
                                    }
                                }
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });

                                const paramsActive ={
                                    "actions": {
                                        "update": {
                                            "Driver": {
                                                "driver_1": {
                                                    "findBy": {
                                                        "id": driver.id
                                                    },
                                                    "updateRecord": {
                                                        "employeeStatus" : 2,
                                                        "offboardedDate" : offboardedDate,
                                                        "terminationDate" : null
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                await api.post('/api/lazy/manage/data', paramsActive);
                                //await allowToChatUser( tempEmployee.userId,  tempEmployee.id,  tempEmployee.friendlyName,  tempEmployee.employeeStatus);
                                setLoading(false);
                                return toast({
                                    type: 'success',
                                    title: "Employee status changed to active successfully."
                                });
                            }
                        });
                        break;
                    case "Onboarding":
                        await dialog({
                            text: "You are about to change "+driver.friendlyName+"'s status from "+status+" to Onboarding.",
                            buttons: [
                                {
                                    type: 'primary',
                                    text: 'Yes'
                                },
                                {
                                    text: 'No',
                                    callback: async () => {
                                        await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                        setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                        return false;
                                    },
                                }
                            ]
                        }).then( empStatus => {
                            if(!empStatus){
                                setAllEmployees(allEmployees);
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15)});
                                return false;
                            } else {
                                setLoading(true);
                                allEmployees.data[empKey].employeeStatus = "1";
                                setAllEmployees(allEmployees);
                                employees.data[tempIndex].employeeStatus = "1";
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });
                                if(dataFilters.length > 0){
                                    if ( dataFilters.indexOf("1") == -1 ) {
                                        employees.data.splice(tempIndex, 1);
                                    }
                                }
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });

                                const paramsOnboarding ={
                                        "actions": {
                                            "update": {
                                                "Driver": {
                                                    "driver_1": {
                                                        "findBy": {
                                                            "id": driver.id
                                                        },
                                                        "updateRecord": {
                                                            "employeeStatus" : 1,
                                                            "offboardedDate" : offboardedDate
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    api.post('/api/lazy/manage/data', paramsOnboarding);
                                    api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                    setLoading(false);
                                    return toast({
                                        type: 'success',
                                        title: "Employee status changed to onboarding successfully."
                                    });
                            }
                        });
                        break;
                    case "Offboarded":
                        await dialog({
                            text: "You are about to change "+driver.friendlyName+"'s status from "+status+" to Offboarded. This will remove "+driver.friendlyName+" from any assigned schedule and delete future assigned shifts.",
                            buttons: [
                                {
                                    type: 'primary',
                                    text: 'Yes'
                                },
                                {
                                    text: 'No',
                                    callback: async () => {
                                        await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                        setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                        return false;
                                    },
                                }
                            ]
                        }).then( empStatus => {
                            if(!empStatus){
                                setAllEmployees(allEmployees);
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15)});
                                return false;
                            } else {
                                setLoading(true);
                                allEmployees.data[empKey].employeeStatus = "-1";
                                setAllEmployees(allEmployees);
                                employees.data[tempIndex].employeeStatus = "-1";
                                setEmployees({ ...employees, data: employees.data, loadData:employees.data.slice(0,15)});
                                if(dataFilters.length > 0){
                                    if ( dataFilters.indexOf("-1") == -1 ) {
                                        employees.data.splice(tempIndex, 1);
                                    }
                                }
                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });
                                const paramsOffboarded ={
                                    "actions": {
                                        "update": {
                                            "Driver": {
                                            "driver_1": {
                                                "findBy": {
                                                    "id": driver.id
                                                },
                                                "updateRecord": {
                                                    "employeeStatus" : -1,
                                                    "offboardedDate" : Moment().utc().format("YYYY-MM-DD HH:mm:ss")
                                                }
                                                }
                                            }
                                        },
                                        "response": {
                                            "DriverRoute": {
                                                "custom": {
                                                    "functionName": "removeDriverRoutesByEmployeeStatus",
                                                    "criteria": {
                                                        "date": Moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                                        "id": driver.id,
                                                        "status" : status + ' to Offboarded',
                                                        "removeFromSchedule" : true,
                                                    },
                                                    "get": "driverRoutes"
                                                }
                                            }
                                        }
                                    }
                                }
                                api.post('/api/lazy/manage/data', paramsOffboarded);
                                api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                setLoading(false);
                                return toast({
                                    type: 'success',
                                    title: "Employee status changed to offboarded successfully."
                                });
                            }
                        });
                        break;
                    case "Terminated":
                            const paramsCheckManager ={
                                "actions": {
                                    "response": {
                                        "Driver": {
                                            "custom": {
                                                "functionName": "getEmployeeFromManager",
                                                "criteria": {
                                                    "userId": driver.userId
                                                },
                                                "get": "users"
                                            }
                                        }
                                    }
                                }
                            }
                            let responsData = await api.post('/api/lazy/manage/data', paramsCheckManager);
                            let users = responsData.data.data.users;
                            if(users.length > 0) {
                                await dialog({
                                    text: driver.friendlyName+" is still listed as a manger for a group of employees. Would you like to assign his employees to another manager?",
                                    buttons: [
                                        {
                                            type: 'primary',
                                            text: 'Yes'
                                        },
                                        {
                                            text: 'No',
                                            callback: async () => {
                                                await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                                return false;
                                            },
                                        }
                                    ]
                                }).then( empStatus => {
                                    if(!empStatus){
                                        setAllEmployees(allEmployees);
                                        setEmployees({ ...employees, data: employees.data });
                                        return false;
                                    } else {
                                        const paramsGetkManager ={
                                            "actions": {
                                                "response": {
                                                    "User": {
                                                        "custom": {
                                                            "functionName": "findManagers",
                                                            "get": "users",
                                                            "criteria": {
                                                                "company": localStorage.getItem('company')
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        api.post('/api/lazy/manage/data', paramsGetkManager).then(
                                            response => {

                                                let userObj = [];
                                                let temp = [];
                                                response.data.data.users.map(u => {
                                                    if( u.id != driver.userId ){
                                                        temp = {
                                                            name: u.firstName+' '+u.lastName,
                                                            value: u.id
                                                        };
                                                        userObj.push(temp);
                                                    }
                                                });


                                                dialog({
                                                    title:'Assign Manager',
                                                    fields: [
                                                        {
                                                            field: CustomDropdown,
                                                            props: {
                                                                name: 'manager',
                                                                label: 'Select Manager',
                                                                options: userObj,
                                                                Required: true,
                                                                visibleOptionsQty: 6
                                                            }
                                                        }
                                                    ],
                                                    buttons: [
                                                        {
                                                            type: 'primary',
                                                            text: 'Assign'
                                                        },
                                                        {
                                                            text: 'Cancel',
                                                            callback: ()=> false,
                                                        }
                                                    ]
                                                }).then( managerStatus => {
                                                    if(managerStatus){
                                                        const assignNewManager ={
                                                            "actions": {
                                                                "response": {
                                                                    "Driver": {
                                                                        "custom": {
                                                                            "functionName": "setNewManager",
                                                                            "criteria": {
                                                                                "userId": driver.userId,
                                                                                "newManager":managerStatus.data.manager
                                                                            },
                                                                            "get": "users"
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        api.post('/api/lazy/manage/data', assignNewManager).then(() =>{
                                                            setLoading(true);
                                                            allEmployees.data[empKey].employeeStatus = "-2";
                                                            setAllEmployees(allEmployees);
                                                            employees.data[tempIndex].employeeStatus = "-2";
                                                            employees.data[tempIndex].allowToChat = false;
                                                            setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0,15) });
                                                            if(dataFilters.length > 0){
                                                                if ( dataFilters.indexOf("-2") === -1 ) {
                                                                    employees.data.splice(tempIndex, 1);
                                                                }
                                                            }
                                                            setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15) });
                                                            const paramsTerminated ={
                                                                "actions": {
                                                                    "update": {
                                                                        "Driver": {
                                                                            "driver_1": {
                                                                                "findBy": {
                                                                                    "id":   driver.id
                                                                                },
                                                                                "updateRecord": {
                                                                                    "employeeStatus" : -2,
                                                                                    "terminationDate" : Moment().utc().format("YYYY-MM-DD HH:mm:ss")
                                                                                }
                                                                            }
                                                                        },
                                                                        "User": {
                                                                            "user_1": {
                                                                                "findBy": {
                                                                                    "id": driver.userId,
                                                                                },
                                                                                "updateRecord": {
                                                                                    "allowToChat": false,
                                                                                }
                                                                            }
                                                                        },
                                                                    },
                                                                    "response": {
                                                                        "DriverRoute": {
                                                                            "custom": {
                                                                                "functionName": "removeDriverRoutesByEmployeeStatus",
                                                                                "criteria": {
                                                                                    "date": Moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                                                                    "id": driver.id,
                                                                                    "status" : status + ' to Terminated',
                                                                                    "removeFromSchedule" : true,
                                                                                },
                                                                                "get": "driverRoutes"
                                                                            }
                                                                        },
                                                                        "Task": {
                                                                            "custom": {
                                                                                "functionName": "archiveDriversTask",
                                                                                "criteria": {
                                                                                    "id": driver.id,
                                                                                    "companyId": localStorage.getItem("company")
                                                                                },
                                                                                "get": "archiveDriversTask"
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            api.post('/api/lazy/manage/data', paramsTerminated);
                                                            api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                                            setLoading(false);
                                                            return toast({
                                                                type: 'success',
                                                                title: "Employee status changed to terminated successfully."
                                                            });
                                                        });

                                                    } else {
                                                        setAllEmployees(allEmployees);
                                                        setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15) });
                                                        return false;
                                                    }
                                                });
                                            });
                                    }
                                });
                            } else {
                                await dialog({
                                    text: "You are about to change "+driver.friendlyName+"'s status from "+status+" to Terminated. This will remove "+driver.friendlyName+" from any assigned schedule and delete future assigned shifts.",
                                    buttons: [
                                        {
                                            type: 'primary',
                                            text: 'Yes'
                                        },
                                        {
                                            text: 'No',
                                            callback: async () => {
                                                await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                                setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                                return false;
                                            },
                                        }
                                    ]
                                }).then( async empStatus => {
                                    if(!empStatus){
                                        setAllEmployees(allEmployees);
                                        setEmployees({ ...employees, data: employees.data });
                                        return false;
                                    } else {
                                        setLoading(true);
                                        allEmployees.data[empKey].employeeStatus = "-2";
                                        setAllEmployees(allEmployees);
                                        employees.data[tempIndex].employeeStatus = "-2";
                                        employees.data[tempIndex].allowToChat = false;
                                        setEmployees({ ...employees, data: employees.data });
                                        if(dataFilters.length > 0){
                                            if ( dataFilters.indexOf("-2") === -1 ) {
                                                employees.data.splice(tempIndex, 1);
                                            }
                                        }
                                        setEmployees({ ...employees, data: employees.data });
                                        const paramsTerminated ={
                                            "actions": {
                                                "update": {
                                                    "Driver": {
                                                        "driver_1": {
                                                            "findBy": {
                                                                "id":   driver.id
                                                            },
                                                            "updateRecord": {
                                                                "employeeStatus" : -2,
                                                                "terminationDate" : Moment().utc().format("YYYY-MM-DD HH:mm:ss")
                                                            }
                                                        },
                                                    },
                                                    "User": {
                                                        "user_1": {
                                                            "findBy": {
                                                                "id": driver.userId,
                                                            },
                                                            "updateRecord": {
                                                                "allowToChat": false,
                                                            }
                                                        }
                                                    },
                                                },
                                                "response": {
                                                    "DriverRoute": {
                                                        "custom": {
                                                            "functionName": "removeDriverRoutesByEmployeeStatus",
                                                            "criteria": {
                                                                "date": Moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                                                "id": driver.id,
                                                                "status" : status + ' to Terminated',
                                                                "removeFromSchedule" : true,
                                                            },
                                                            "get": "driverRoutes"
                                                        }
                                                    },
                                                    "Task": {
                                                        "custom": {
                                                            "functionName": "archiveDriversTask",
                                                            "criteria": {
                                                                "id": driver.id,
                                                                "companyId": localStorage.getItem("company")
                                                            },
                                                            "get": "archiveDriversTask"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        api.post('/api/lazy/manage/data', paramsTerminated);
                                        api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                        setLoading(false);
                                        return toast({
                                            type: 'success',
                                            title: "Employee status changed to terminated successfully."
                                        });
                                    }
                                });
                            }

                       
                        break;
                    case "Inactive":
                        await dialog({
                            text: "You are about to change "+driver.friendlyName+"'s status from "+status+" to Inactive.<br/><br/><strong>Would you like to continue?</strong><br/><br/>",
                            fields: [
                                {
                                    field: CheckBox,
                                    props: {
                                        name: 'removeDriver',
                                        options:[{ value: 'true' ,label: "Remove driver from assigned schedule and delete future shifts."}]
                                    }
                                }
                            ],
                            buttons: [
                                {
                                    type: 'primary',
                                    text: 'Yes',
                                },
                                {
                                    text: 'No',
                                    callback: async () => {
                                        await setEmployees({ ...employees, data: employees.data, loadData: [] });
                                        setEmployees({ ...employees, data: employees.data, loadData: employees.data.slice(0, 15) });
                                        return false;
                                    },
                                }
                            ]
                        }).then(empStatus =>{
                            if(!empStatus){
                                setAllEmployees(allEmployees);
                                setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15) });
                                return false;
                            } else {
                                setLoading(true);
                                allEmployees.data[empKey].employeeStatus = "0";
                                setAllEmployees(allEmployees);
                                employees.data[tempIndex].employeeStatus = "0";
                                setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15)});
                                if(dataFilters.length > 0){
                                    if ( dataFilters.indexOf("3") == -1 ) {
                                        employees.data.splice(tempIndex, 1);
                                    }
                                }
                                setEmployees({ ...employees, data: employees.data,loadData: employees.data.slice(0,15)});
                                if( empStatus.data && empStatus.data.removeDriver == 'true' ){
                                    const paramsInactive ={
                                        "actions": {
                                            "update": {
                                                "Driver": {
                                                "driver_1": {
                                                    "findBy": {
                                                        "id": driver.id
                                                    },
                                                    "updateRecord": {
                                                        "employeeStatus" : 0,
                                                        "offboardedDate" : offboardedDate,
                                                        "inactiveAt" : Moment().utc().format("YYYY-MM-DD HH:mm:ss")
                                                    }
                                                }
                                            }
                                        },
                                        "response": {
                                        "DriverRoute": {
                                            "custom": {
                                                "functionName": "removeDriverRoutesByEmployeeStatus",
                                                "criteria": {
                                                    "date": Moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                                    "id": driver.id,
                                                    "status" : status + ' to Inactive',
                                                    "removeFromSchedule" : true,
                                                },
                                                "get": "driverRoutes"
                                            }
                                        }
                                        }
                                    }
                                    }
                                    api.post('/api/lazy/manage/data', paramsInactive);
                                    api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                } else {
                                    const paramsInactive ={
                                        "actions": {
                                            "update": {
                                                "Driver": {
                                                "driver_1": {
                                                    "findBy": {
                                                        "id": driver.id
                                                    },
                                                    "updateRecord": {
                                                        "employeeStatus" : 0,
                                                        "offboardedDate" : offboardedDate,
                                                        "inactiveAt" : Moment().utc().format("YYYY-MM-DD HH:mm:ss")
                                                    }
                                                }
                                            }
                                        }
                                        }
                                    }
                                api.post('/api/lazy/manage/data', paramsInactive);
                                api.post('/api/lazy/manage/data', removeDriverFromVehicle);
                                }
                                setLoading(false);
                                return toast({
                                    type: 'success',
                                    title: "Employee status changed to inactive successfully."
                                });
                            }
                        });
                        break;
                }
                break;
        }
    };

    const handleArchive = async (id, key) => {
        if (window.confirm("Are you sure you want to archive this driver?")) {
            let employee = employees.data[key];
            let params = {
                actions: {
                    delete: {
                        User: {
                            user_1: {
                                findOneBy: {
                                    id: employee.userId
                                }
                            }
                        },
                        Driver: {
                            driver_1: {
                                findOneBy: {
                                    id: employee.id
                                }
                            }
                        }
                    }
                }
            };

            try {
                await api.post("/api/lazy/manage/data", params);
                employees.data.splice(key, 1);
                employees.loadData= employees.data.slice(0,15);
                setEmployees(employees);
            } catch (error) {
                await showDefaultErrorToast(error);
            }
        }
    };

    const createEmployee = async employee => {
        return new Promise(async(resolve, reject) => {
            let tempEmployees = [...employees.data, employee];
            tempEmployees = await sorter(tempEmployees, sortBy.name, sortBy.direction);
            setEmployees({ data: tempEmployees, loadData: tempEmployees.slice(0,15)});

            if (employee.roles.toString() !== "Delivery Associate") {
                toggleForm(false);
            }

            /*--------for all employees---------*/
            let AllRolesObj = allEmployees.allRoles;
            if (AllRolesObj.indexOf(employee.job) == -1) {
                AllRolesObj.push({'name':employee.job,'value':employee.job});
            }
            allEmployees.allRoles = AllRolesObj;
            let allSkillsObj = allEmployees.allSkills;
            let tempArray = [];
            Object.keys(allSkillsObj).forEach(function(index) {
                tempArray.push(allSkillsObj[index].name);
            });
            Object.keys(employee.skills).forEach(function(index) {
                if (tempArray.indexOf(employee.skills[index]) == -1) {
                    allSkillsObj.push({'name':employee.skills[index],'value':employee.skills[index]});
                }
            });
            allEmployees.allSkills = allSkillsObj;
            allEmployees.data.push(employee);
            setLoading(false);
        });
    };

    const editEmployee = async employee => {
        if(employee.isDelete ){
            let tempIndex  = 0;
            let tempIn = await employees.data.filter(function(item,key) {
                if( item.id == employee.id ){

                    tempIndex = key
                    return item;
                }
            });
            employees.data.splice(tempIndex, 1);
            toggleForm(false);
            return true;
        }

        return new Promise((resolve, reject) => {
            if (employee.profile) {
                employees.data[editKey] = {
                    ...employees.data[editKey],
                    id: employee.id,
                    job: employee.job,
                    name: employee.name,
                    email: employee.email,
                    status: employee.status,
                    skills: employee.skills,
                    picture: employee.profile,
                    roles:[employee.roles]
                };

                allEmployees.data[editKey] = {
                    ...employees.data[editKey],
                    id: employee.id,
                    job: employee.job,
                    name: employee.name,
                    email: employee.email,
                    status: employee.status,
                    skills: employee.skills,
                    picture: employee.profile,
                    roles:[employee.roles]
                };

            } else {
                employees.data[editKey] = {
                    ...employees.data[editKey],
                    id: employee.id,
                    job: employee.job,
                    name: employee.name,
                    email: employee.email,
                    status: employee.status,
                    skills: employee.skills,
                    roles:[employee.roles]
                };

                allEmployees.data[editKey] = {
                    ...employees.data[editKey],
                    id: employee.id,
                    job: employee.job,
                    name: employee.name,
                    email: employee.email,
                    status: employee.status,
                    skills: employee.skills,
                    roles:[employee.roles]
                };
            }


            /*--------for all employees---------*/
            let AllRolesObj = allEmployees.allRoles;
            if (AllRolesObj.indexOf(employee.job) == -1) {
                AllRolesObj.push({'name':employee.job,'value':employee.job});
            }
            allEmployees.allRoles = AllRolesObj;
            let allSkillsObj = allEmployees.allSkills;
            let tempArray = [];
            Object.keys(allSkillsObj).forEach(function(index) {
                tempArray.push(allSkillsObj[index].name);
            });
            Object.keys(employee.skills).forEach(function(index) {
                if (tempArray.indexOf(employee.skills[index]) == -1) {
                    allSkillsObj.push({'name':employee.skills[index],'value':employee.skills[index]});
                }
            });
            allEmployees.allSkills = allSkillsObj;
            employees.loadData= employees.data.slice(0,15);

            setEmployees(employees);
            toggleForm(false);
            setLoading(false);
        });
    };

    const handleUpdateEmployees = async (data, index, field, value) => {
        console.log('handleUpdateEmployees');
        console.log({t: 1, index, field, value, data});
        console.log({before: employees});
        if (employees.data[index] && typeof employees.data[index][field] !== "undefined") {
            employees.data[index][field] = value;
            setEmployees(employees);
            console.log({t: 2, index, field, value, data});
            console.log({after: employees});
        }
        //  handleUpdateEmployees(employee, keyVal, 'allowToChat', !employee.allowToChat);

        // let message = '';
        // let st = true;
        // if (data.isEnabled === true) {
        //     message = 'Are you sure you want to change ' + data.name +'\'s status from active to inactive?';
        //     st = false;
        // } else {
        //     message = 'Are you sure you want to change ' + data.name +'\'s status from inactive to active?';
        //     st = true;
        // }
        // const confirmation = await confirm({
        //     icon: true,
        //     text: message
        // });

        // if (!confirmation) {
        //     if (data.isEnabled === true) {
        //         employees.data[key].isEnabled = true;
        //     } else {
        //         employees.data[key].isEnabled = false;
        //     }
        //     setEmployees(employees);
        //     return;
        // }

        // let params = {
        //     actions: {
        //         update: {
        //             User: {
        //                 user_1: {
        //                     findBy: {
        //                         id: data.userId
        //                     },
        //                     updateRecord: {
        //                         isEnabled: st
        //                     }
        //                 }
        //             },
        //             Driver: {
        //                 driver_1: {
        //                     findBy: {
        //                         id: data.id
        //                     },
        //                     updateRecord: {
        //                         isEnabled: st
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // };
        try {
            // await api.post("/api/lazy/manage/data", params);
            // return new Promise((resolve, reject) => {
            //     if (data.isEnabled === true) {
            //         employees.data[key].isEnabled = false;
            //     } else {
            //         employees.data[key].isEnabled = true;
            //     }
            //     setEmployees(employees);
            // });
        } catch (error) {
            await showDefaultErrorToast(error, 'Error updating employee.')
        }
    };

    const loadMoreData = () => {
        let res = employees.data.slice(employees.loadData.length,employees.loadData.length+10);
        
        setEmployees({...employees, loadData: [...employees.loadData, ...res] });
    };

    const getRolesPermissionLevel = async () => {
        const params = {
            "actions": {
                "response": {
                    "Role": {
                        "custom": {
                            "functionName": "getRolePermissionLevel",
                            "get": "roles",
                            "criteria": {
                                "singleArr": true
                            },
                        },
                    },
                },
            },
        };
        const response = await api.post("/api/lazy/manage/data", params);
        await setRolesPermissionLevel(response.data.data.roles.roleArray);
    };

    useEffect(() => {
        if (authToken) {
            fetchEmployees(selectedStation).then(r => r);
            getRolesPermissionLevel();
        } else {

        }
    }, [authToken]);

    const {fetchSeatsInformation} = useSubscriptionsApi();
    const [seatsInfo, setSeatsInfo] = useState({
        canAddEmployees: false,
    });
    useEffect(() => {
        fetchSeatsInformation().then(({
            activeEmployees,
            reservedSeats,
            flexibleSeats
        }) => {
            setSeatsInfo({
                activeEmployees,
                reservedSeats,
                flexibleSeats,
                canAddEmployees: (activeEmployees < reservedSeats && !flexibleSeats) || flexibleSeats
            });
        });
    }, [employees]);

    const reservedSeatsMessage = seatsInfo.canAddEmployees
        ? false
        : <Text>
            Your company reached the {seatsInfo.reservedSeats} reserved seats.
            {' '}Deactivate an employee, add more seats or enable flexible seats.
        </Text>;

    return (
        <Body style={{ height: "100vh" }}>
            <Spacer top={4} />
            <Title>Employees</Title>
            <Spacer top={3} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text>Enter and manage your employees information.</Text>
                <div style={{ display: "flex", marginTop: "-50px",width:"42%" }}>
                    <TopFilter
                        allEmployees={allEmployees}
                        handleSearch={handleSearch}
                        fetchEmployees={fetchEmployees}
                        open={open} setOpen={setOpen}
                    />
                </div>
            </div>
            <Spacer top={8} />
            <Table width={'105%'} style={{ flex: "0.84" }}>
                <EmployeesList
                    employees={employees.loadData}
                    isMoreData={employees.loadData.length < employees.data.length}
                    loadMoreData={loadMoreData}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    modalHandler={modalHandler}
                    handleOrder={handleOrder}
                    sortBy={sortBy}
                    handleUpdateEmployees={handleUpdateEmployees}
                    canAddEmployees={seatsInfo.canAddEmployees}
                    rolePermissionLevel={rolesPermissionLevel}
                />
            </Table>
            <CreateSection width={'105%'}>
                <ThirdTitle>Total: {employees.data.length} employees</ThirdTitle>
                <div>
                    {FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                        <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                        <Tooltip content={reservedSeatsMessage}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    toggleChangePassword(true);
                                }}
                                disabled={!seatsInfo.canAddEmployees}
                            >
                                Change Password
                            </Button>
                        </Tooltip>
                        </IsGranted>
                    )}
                    <Spacer right={3} inline/>
                    {FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                        <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                        <Tooltip content={reservedSeatsMessage}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    toggleInvitaion(true);
                                }}
                                disabled={!seatsInfo.canAddEmployees}
                            >
                                Send Invitation
                            </Button>
                        </Tooltip>
                        </IsGranted>
                    )}
                    <Spacer right={3} inline/>
                    {FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                        <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                        <Tooltip content={reservedSeatsMessage}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    toggleCsv(true);
                                }}
                                disabled={!seatsInfo.canAddEmployees}
                            >
                                Import CSV
                            </Button>
                        </Tooltip>
                        </IsGranted>
                    )}
                    <Spacer right={3} inline/>
                    {FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1 && (
                        <IsGranted feature={AccessCheck.features.CM_E_PERMISSION_CHANGE}>
                        <Tooltip content={reservedSeatsMessage}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setEmployees({ ...employees, editId: 0 });
                                    if (!formVisible) toggleForm(true);
                                }}
                                disabled={!seatsInfo.canAddEmployees}
                            >
                                Add Employee
                            </Button>
                        </Tooltip>
                        </IsGranted>
                    )}
                </div>
            </CreateSection>

            {csvVisible && <Csv toggleCsv={toggleCsv} csvVisible={csvVisible} />}
            {changePasswordVisible && <ChangePassword selectedStation={selectedStation} allEmployees={employees} changePasswordVisible={changePasswordVisible} toggleChangePassword={toggleChangePassword} />}
            {invitationVisible && <Invitation selectedStation={selectedStation} allEmployees={employees} invitationVisible={invitationVisible} toggleInvitaion={toggleInvitaion} />}
            {formVisible && (
                <Form
                    toggleForm={toggleForm}
                    formVisible={formVisible}
                    edit={employees.editId}
                    action={{ create: createEmployee, editRecord: editEmployee }}
                />
            )}
            {timelineVisible && (
                <Timeline
                    profileId={employees.editId}
                    employee={employees}
                    timelineVisible={timelineVisible}
                    toggleTimeline={toggleTimeline}
                    modalHandler={modalHandler}
                />
            )}
            {driverAliasVisible && (
                <DriverAlias
                    driver={employees.driver}
                    setVisible={setDriverAliasVisible}
                />
            )}
            {detailsVisible && (
                <Details
                    profileId={employees.editId}
                    detailVisible={detailsVisible}
                    toggleDetails={toggleDetails}
                    modalHandler={modalHandler}
                    station={employees.currentStation}
                    isEdit="true"
                />
            )}
            {resetPasswordVisible && (
                <ResetPassword
                    setVisible={setResetPasswordVisible}
                    driver={employees.driver}
                />
            )}
            {removeShiftVisible && (
                <DeleteShift
                    setVisible={setRemoveShiftVisible}
                    driver={employees.driver}
                />
            )}
        </Body>
    );
};

const TopFilter = ({ allEmployees, handleSearch, fetchEmployees }) => {
    const filterRef = useRef();
    const [open, setOpen] = useState(false);
    const [selectedStation] = useSelectedStation();
    const [tempData, setTempData] = useState({ roles: [], schedules: [], search: "", skills: [], stations: selectedStation, userStatus: ["all"], nameSearchText: "" });
    const { register, handleSubmit, triggerValidation, getValues, reset, setValue } = useForm({
        mode: "onBlur",
        defaultValues: { roles: [], schedules: [], search: "", skills: [], stations: selectedStation, userStatus: ["all"], nameSearchText: "" }
    });
    const [badge, setBadge] = useState(0);
    const [isChange, setIsChange] = useState(false);
    const [appliedFilter, setAppliedFilter] = useState(null);

    const onSubmit = () => {
        let data = { ...tempData, ...getValues() };
        setTempData(data);
        data["search"] = data["search"].trim();
        data["nameSearchText"] = data["nameSearchText"].trim();
        handleSearch(data["search"], data["schedules"], data["skills"], data["roles"], data["stations"], data["userStatus"], data["nameSearchText"]);
        setIsChange(!isChange);
    };

    const onSearch = () => {
        let data = { ...tempData, ...getValues() };
        setTempData(data);
        data["search"] = data["search"].trim();
    }

    const handleTagChange = () => {
        setTimeout(() => {
            if(filterRef && filterRef.current){
                filterRef.current.click();
            }
        }, 600);
    };

    useEffect(()=>{
      if(allEmployees.data){
          if(filterRef && filterRef.current){
              filterRef.current.click();
          }
      }
    }, [allEmployees, filterRef]);

    useEffect(()=>{
        if(allEmployees && !allEmployees.data){
            return;
        }
      fetchEmployees(selectedStation);
    }, [selectedStation]);

    const employeeStatus = [
        { 'name': 'All (Non Terminated)', 'value': "all" },
        { 'name': 'Active', 'value': 2 },
        { 'name': 'Inactive ', 'value': 3 },
        { 'name': 'Terminated ', 'value': -2 },
        { 'name': 'Offboarded ', 'value': -1 },
        { 'name': 'Onboarding ', 'value': 1 },
    ];


    useEffect(() => {
        let i = 0;
        let x = tempData;

        Object.keys(x).forEach((item) => {
            if (item !== "stations" && x[item] != "" && x[item] != "all") {
                i++;
            }
        });
        setBadge(i);
    }, [isChange, tempData]);


    useEffect(() => {
        let x = Object.keys(tempData);
        for (let i = 0; i < x.length; i++) {
            setValue(x[i], tempData[x[i]]);
        }
        reset(tempData);
    }, [open]);

    const applyFilter = (fromFilterSelect) => {
        onSubmit();
        setOpen(false);
        !fromFilterSelect && setAppliedFilter(null)
    }

    const handleFilterSelect = async (filter) => {
        setAppliedFilter(filter?.name)
        Object.keys(filter?.filterObj).length > 0 && Object.keys(filter?.filterObj).forEach(key => {
            setValue(key, filter?.filterObj[key])
        })
        applyFilter(true)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setOpen(false)
            setTimeout(() => {
                if (filterRef && filterRef.current) {
                    filterRef.current.click();
                }
            }, 300);
        }
    }

    const TooltipContent = ({ content }) => {
        return <div>{content}</div>
    }

    const ButtonRender = ({ children, content }) => {
        if (!content || content.length < 1) return children
        return <Tooltip verticalPosition="bottom" horizontalPosition="center" direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
    }

    const TooltipRender = ({ label, value }) => {
        return <p>{label} = {value}</p>
    }

    const appliedFilters = useMemo(() => {
        const filters = []
        Object.keys(tempData).forEach(filterKey => {
            const value = Array.isArray(tempData[filterKey]) && filterKey == "userStatus" ? tempData[filterKey]?.map((item) => {
                return (employeeStatus?.filter((items) => items.value == item)[0]?.name)
            }).join(",") : tempData[filterKey].hasOwnProperty('value') ? tempData[filterKey].value : tempData[filterKey]

            // @ts-ignore            
            if (value !== null && value != '' && value != "all" && filterKey != "stations") filters.push(<TooltipRender label={filterKey} value={Array.isArray(value) ? value?.join(",") : value} key={filterKey} />);
        })
        return filters
    }, [tempData, isChange])


    return (
        <>
            <Spacer inline right={3} />
            <StationSelector
                name={'stations'}
                ref={register} />
            <Spacer inline right={3} />
            <Input
                label="Search"
                name="search"
                ref={register}
                placeholder="Filter drivers by name, email, phone number, etc"
                onBlur={handleSubmit(onSubmit)}
                onChange={onSearch}
                onKeyPress={handleKeyPress}
            >
                <Icon.Search color={Theme.colors.info.border} size={"24px"} />
            </Input>
            <Spacer inline right={3} />
            <Filter onSubmit={handleSubmit(onSubmit)}>

                <div style={{ marginTop: "-2px" }}>
                    <Spacer top={7} />
                    <ButtonRender content={appliedFilters}>
                        <Btn onClick={() => {
                            setOpen(!open)
                        }}>
                            <span>Filter</span>
                            <Icon.Filter />
                            {badge > 0 && <Badge>{badge}</Badge>}
                        </Btn>
                    </ButtonRender>
                </div>
                <Modal
                    width={'340px'}
                    visible={open}
                    title={'Filters'}
                    setVisible={() => setOpen(false)}
                    drawer={'right'}
                    key={'modal-filter'}
                    closeOnOverlay={true}
                    zindex={999}
                    removeHeader={true}
                    paddingTopBottomZero={true}
                >
                    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                            <Header>
                                <SubTitle>Filters</SubTitle>
                                <Button type="cancel" onClick={() => {
                                    reset({ roles: [], schedules: [], skills: [], userStatus: "", search: "", nameSearchText: "" });
                                    setTempData(getValues());
                                    handleSearch(getValues().search, [], [], [], tempData.stations, "");
                                    setIsChange(!isChange)
                                    setAppliedFilter(null)
                                }}>
                                    Clear
                                </Button>
                            </Header>
                            <div style={{ flex: 1, overflow:"auto" }}>
                            <TagInput
                                ref={register}
                                size="large"
                                name='userStatus'
                                label='Status'
                                options={employeeStatus}
                                defaultValue={tempData.userStatus}
                            // onChange={handleTagChange}
                            />
                            <Spacer inline right={5} />
                            <TagInput
                                name="roles"
                                label="Permission Level"
                                options={allEmployees.allRoles}
                                size="large"
                                ref={register}
                                defaultValue={tempData.roles}
                            // onChange={handleTagChange}
                            />
                            <Spacer inline right={5} />
                            <TagInput
                                name="skills"
                                label="Skills"
                                size="large"
                                options={allEmployees.allSkills}
                                ref={register}
                            // onChange={handleTagChange}
                            />
                            <Spacer inline right={5} />
                            <TagInput
                                name="schedules"
                                label="Schedules"
                                options={allEmployees.allSchedules}
                                size="large"
                                ref={register}
                            // onChange={handleTagChange}
                            />
                            <Spacer inline right={5} />
                            <Input
                                label="Name"
                                name="nameSearchText"
                                ref={register}
                                size="large"
                                placeholder="Filter drivers by First Name, Last Name, Middle Name, Nickname"
                            >
                            </Input>
                        </div>
                        <FilterBottom
                            filterList={[]}
                            onApplyClick={applyFilter}
                            onFilterSelect={handleFilterSelect}
                            appliedFilter={appliedFilter}
                        />
                    </div>
                </Modal>
                <HiddenSubmit ref={filterRef} />
            </Filter>
        </>
    );
};

export default App;
