import {AtomicBlockUtils} from "draft-js";

const pasteImagePlugin = (config) => {

    const store = {
        getEditorState: undefined,
        setEditorState: undefined
    };

    return {
        initialize({getEditorState,setEditorState}){
            store.setEditorState = setEditorState;
            store.getEditorState = getEditorState;
        },
        handlePastedFiles:(files)=>{
            if(!files[0]) return false;

            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const editorState =  store.getEditorState();
                const ImageEntity = editorState.getCurrentContent().createEntity('image','IMMUTABLE',{src:reader.result});
                const ImageEntityKey = ImageEntity.getLastCreatedEntityKey();
                const newState = AtomicBlockUtils.insertAtomicBlock(editorState,ImageEntityKey,' ');
                store.setEditorState(newState);
            }
        }

    }
};

export default pasteImagePlugin;

