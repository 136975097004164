import React, {useCallback, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {Button, Icon, Input, Spacer, Text, Title} from '@dspworkplace/ui';
import Loading, {useLoading} from "../../components/Loading";
import {useForm} from "react-hook-form";
import {useAuth} from "../../Auth";
import {Table, TableData, TableFooter, TableHeader} from "../../components/Table";
import { alert } from "../../components/Alert";
import {AccessCheck, IsGranted} from "../../security";

const Body  = styled.div`
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items: stretch;
    padding-left: 156px;
    font-family: circe-rounded;
    max-width:90vw;
`;

const reducer = (state, action) => {
    switch (action.type) {
        case 'create':
            action.skills.sort((a, b) => a[state.filterName].toString().localeCompare(b[state.filterName].toString(), undefined, { numeric: true }));
            return { ...state, skills: action.skills, isLoading: action.isLoading };
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'add':
            return { ...state, skills: [...state.skills, action.rate], isLoading: action.isLoading};
        case 'setUpdateKey':
            return {...state, updateKey: action.updateKey}

        case 'update':
            state.skills[action.key] = action.skill;
            return { ...state, updateKey: -1 };

        case 'filter':
            if (state.filterOrder === 'ASC') {
                state.skills.sort((a, b) => b[action.filterName].toString().localeCompare(a[action.filterName].toString(), undefined, { numeric: true }));
                return { ...state, skills: state.skills, filterOrder: 'DESC', filterName: action.filterName };
            } else {
                state.skills.sort((a, b) => a[action.filterName].toString().localeCompare(b[action.filterName].toString(), undefined, { numeric: true }));
            }
            return { ...state, skills: state.skills, filterOrder: 'ASC', filterName: action.filterName };

        case 'delete':
            state.skills.splice(action.key, 1);
            return {...state}
        default:
            return state;
    }
}
const initialState = {
    skills: [],
    updateKey: -1,
    filterName: 'name',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
}
const App = () => {
    const { api } = useAuth();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setLoading] = useLoading();
    const [data,setData] = useState({update:-1,skills:[]});
    const [filter, setFilter] = useState({order:{name:'ASC'}});


    const handleDelete = async (id, key) =>
    {
        const confirmation = window.confirm('Confirm that you want to delete this skill?');
        if(!confirmation){
            return false;
        }
        try{
            const params1 = {
                "actions": {
                    "response": {
                        "Shift": {
                            "findBy": {
                                "criteria": {
                                    "company": localStorage.getItem('company'),
                                    "skill": id,
                                    "isArchive": false
                                },
                                "get": "ShiftType",
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "station": {
                                        "0": "name",
                                        "1": "isArchive"
                                    }
                                },
                            }
                        }
                    }
                }
            }
            let response1 = await api.post('/api/lazy/manage/data', params1);
            if (response1.data.data.ShiftType.length > 0) {
                let stationShiftArr = {};
                response1.data.data.ShiftType.map((s) => {
                    if (s.station.isArchive == false) {
                        if (!stationShiftArr.hasOwnProperty(s.station.name)) {
                            stationShiftArr[s.station.name] = [];
                        }
                        stationShiftArr[s.station.name].push(s.name);
                    }
                });

                let html = '';
                if (Object.keys(stationShiftArr).length > 0) {
                    html = html + '</br></br><table width="100%" style="padding-top: 10px;"><tr style="border: 1px solid black;"><th style="border: 1px solid black;">Station</th><th style="border: 1px solid black;">Shift Type</th></tr>';
                    Object.entries(stationShiftArr).map(([station, shiftType]) => {
                        html = html + '<tr style="border: 1px solid black;"><td style="border: 1px solid black;">' + station + '</td><td style="border: 1px solid black;">' + shiftType.join(", ") +'</td></tr>';
                    })
                    html = html + '</table>';
                    alert("Please first change this Skill in the Shift Type after that you can delete this Skill." + html);
                    return false;
                }
            }

            let params = {
                "actions": {
                    "delete": {
                        "Skill": {
                            "delete_rate_1": {
                                "findOneBy": {
                                    "id": id
                                }
                            }
                        }
                    },
                }
            };
            await api.post('/api/lazy/manage/data', params);

            dispatch({type:'delete', key: key});            

        }catch(error){
            if (error.response.status == '401') {
                window.location = "/logout";
            }
            dispatch({type:'default'});
        }
    }

    const filterBy = (sortFieldName, sortFieldType) =>{
         dispatch({type:'filter', filterName: sortFieldName, filterType: sortFieldType});
    }

    useEffect (() => {
        const getSkills = async () => {
            try{
                const params={
                    "actions": {
                        "response": {
                            "Skill": {
                                "findBy": {
                                    "criteria": {"company":localStorage.getItem('company')},
                                    "get": "Skills",
                                    "orderBy": {
                                        'name': 'ASC'
                                    },
                                    "includes": {
                                        "0":"id",
                                        "1":"name"
                                    },
                                    "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                                }
                            }
                        }
                    }
                }
                let response = await api.post('/api/lazy/manage/data', params);

                let r = response.data.data.Skills.map(item=>{ return {id: item.id, name: item.name }});
                dispatch({type:'create', skills: r, isLoading: false});
            }catch(error){
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                dispatch({type:'default'});
            }

        }
        getSkills();
    },[filter]);


    return (
        <Body>
            <Spacer top={5} />
            <Title>Skills</Title>
            <Spacer top={1} />
            <Text>Global skill names for all of your stations.</Text>
            <Spacer top={5} />
            <Table width={'400px'}>
                <TableHeader
                    headers={[
                        {width: '400px', label: 'Skill', sortBy: 'name'}
                    ]}
                    sortedBy={[{
                        field: state.filterName,
                        direction: 0
                    }]}
                    sortByFunc={field => {
                        filterBy(field, 'str');
                    }}
                />

                {state.isLoading && <Loading style={{height:'80px',marginTop:'20px'}} />}
                {!state.isLoading && state.skills &&
                state.skills.map((skill, key) => {
                    if(key === state.updateKey){
                        return (
                            <AddForm key={key} index={key} dispatch={dispatch} data={state} />
                        );
                    }

                    return(
                        <li key={key}>
                            <TableData width='320px'>{skill.name}</TableData>
                            <TableData width='80px' style={{verticalAlign: 'middle', height: '40px'}}>
                            <IsGranted feature={AccessCheck.features.CM_SKILL} ops={AccessCheck.OPS.U}>
                                <Spacer inline right={3}>
                                    <Icon.Edit size='20px' color='#7C98B6'
                                                style={{position: 'relative', top: '3px'}} onClick={() => { dispatch({type:'setUpdateKey' , updateKey: key}) }}/>
                            </Spacer>
                            </IsGranted>
                            <IsGranted feature={AccessCheck.features.CM_SKILL} ops={AccessCheck.OPS.D}>
                                <Icon.Times size='20px' color='#7C98B6' onClick={() => { handleDelete(skill.id, key) }} />
                            </IsGranted>    
                            </TableData>
                        </li>
                    )

                })
                }
                {!state.isLoading && state.skills && <AddForm dispatch={dispatch} data={state} />}
            </Table>
        </Body>
    );
}

const AddForm = ({dispatch,data,index}) => {

    const { api, logout } = useAuth();
    let defaultValues;
    let selected;
    if(index > -1){
        selected = data.skills[index];
        defaultValues =
            {
                name: selected.name
            }
    }
    const [isTextType, setIsTextType] = useState(false);
    const { register, handleSubmit, errors, reset, getValues, setError } = useForm({mode:'onBlur',defaultValues:defaultValues});
    const {name} = getValues();
    const handleEdit = async (form, selected, index) =>
    {
        try {
            let param = {
                "actions": {
                    "update": {
                        "Skill": {
                            "update_skill_1": {
                                "findBy": {
                                    "id": selected.id
                                },
                                "updateRecord": {
                                    "name":form.name
                                }
                            }
                        }
                    }
                }
            }
            let status =0;
            Object.keys(data.skills).forEach(function(sindex) {
                if(data.skills[sindex].name == form.name && data.skills[sindex].id !== data.skills[index].id){
                    status = 1
                }
            });
            if(status == 1){
                setError("name", "notMatch", "Please choose a different name");
            } else {
                await api.post('/api/lazy/manage/data', param);
                selected.name = form.name;
                dispatch({type:'update', skill: selected, key: index});
            }
            setIsTextType(false);

        } catch (error) {
            dispatch({type:'default'});
            alert("Error occurred. Login again, please").then(() => {

            });

        }
    }

    const errorMessages = {
        name: {
            required: 'You need to provide an name',
            notMatch: 'Please choose a different skill name',
        }
    }
    const cancelBtn = async ()=>{
        if(!selected){
            reset({name:''});
            setIsTextType(false);
        } else {
            dispatch({type:'update', skill: selected, key: index});
        }
        return false;
    }

    const handleOnChange = useCallback(event => {
        if(event.target.value.length > 0){
            setIsTextType(true);
        } else {
            setIsTextType(false);
        }
    });

    const onSubmit = async form  =>
    {
        if(selected){
            await handleEdit(form,selected,index);
        } else {
            let params = {
                "actions": {
                    "create": {
                        "Skill": {
                            "skill_1": {
                                "company": localStorage.getItem('company'),
                                "name": form.name
                            }
                        }
                    }
                }
            }
            try {
                if (data.skills.filter(skill => skill.name == form.name).length > 0) {
                    setError("name", "notMatch", "Please choose a different name");
                } else {
                    const createdSkill = await api.post('/api/lazy/manage/data', params);
                    if(createdSkill.status === 200){
                        const newSkill = {id:createdSkill.data.data.responseId, name:form.name };
                        dispatch({type:'add', rate: newSkill, isLoading: false});
                        reset({name:''});
                    }else{
                        alert('Error to create new skill');
                    }
                }
                setIsTextType(false);

            } catch(error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
    }

    const Form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TableData  width='320px'>
                <Input
                    name='name'
                    ref={register({
                        required: true,
                    })
                    }
                    error={errors.name && errorMessages.name[errors.name.type]}
                    valid={!errors.name && name && name.length > 0}
                    onChange={handleOnChange}
                />
            </TableData>
            <TableData width='80px' style={{verticalAlign:'middle',height:'40px',marginTop:(errors.name ? '-23px' : '0px' )}}>
                <Button type='primary' style={{ 'marginRight': '10px'}}  >{selected ? 'Save' : 'Add' }</Button>
                { isTextType || selected ?
                    <Button type={'cancel'} onClick={()=>{cancelBtn()}} >
                        <Icon.Times size={'20px'} style={{verticalAlign: 'middle'}}/>
                    </Button>
                :''}
            </TableData>
        </form>
    );

    return index > -1 ? (
        <li>
            {Form}
        </li>
    ) : (
        <TableFooter style={{padding: 0}}>
        <IsGranted feature={AccessCheck.features.CM_SKILL} ops={AccessCheck.OPS.U}>
            {Form}
        </IsGranted>
        </TableFooter>
    )
}

export default App;
