import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, matchPath } from "react-router-dom";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { alert } from "../../components/Alert";
import { confirm } from "../../components/Confirm";
import {
    Alert,
    Button,
    CheckBox,
    CustomDropdown,
    Dropdown,
    Icon,
    Input,
    InputColor,
    Password,
    RadioBox,
    Spacer,
    SubTitle,
    Text,
    Theme,
    TimePicker
} from "@dspworkplace/ui";
import { DropUploader } from "../../components/DropUploader";
import IncidentsContext, { IncidentsProvider } from "./context";
import { upload, getCompanyId, useAuth } from "../../Auth";
import Day from "../../components/Calendar/dayNew";
import Signature from "../../components/Signature";
import TinyMce from "../../components/TinyMce";
import { Content, Navigator } from "../../components/Menus/Navigator";
import { Label, Link, Tag } from "../../components/UI";
import People from "../../components/People";
import { IncidentPhasesTabs } from "./Common/UI";
import Loading, { LoadingWrapper } from "../../components/Loading";
import API from "./api";
import moment from "moment";
import { toast } from "../../components/Toasts";
import ShiftBlock from "../../components/ShiftBlock";
import { dialogPromise, dialog } from "../../components/Dialog";
import Modal from "../../components/Modal";
import SignatureCanvas from "react-signature-canvas";
import NewTimePicker from "../../components/TimePicker";

const IncidentTitleInputStyle = styled.div`
    &,
    .formAligner {
        width: 100%;

        div {
            display: block !important;
        }
    }

    input {
        width: 100%;
        font-family: ${Theme.font.main};
        font-weight: bold;
        font-size: 36px;
        height: 76px;
        color: ${Theme.colors.secondary};

        &,
        &:hover,
        &:focus,
        &:active {
            border: 0;
            border-bottom: 1px dashed ${Theme.colors.neutrals.silver};
        }

        &::placeholder {
            line-height: 1;
        }
    }
`;

const IncidentPast = styled.div`
    .past-incident {
        .formAligner {
            div {
                display: block !important;
            }
        }
    }
`;

const FileOverlay = styled.div`
    ${(props) =>
        props.props.disabled &&
        props.type === "File" &&
        props.currentPhase.isCompleted == true && { pointerEvents: "none" }}
`;

let renderCount = 0;

const FieldTypes = {
    Input: Input,
    Textarea: Input,
    Radio: RadioBox,
    Checkbox: CheckBox,
    Select: Dropdown,
    MultiSelect: CustomDropdown,
    File: DropUploader,
    Password: Password,
    TimePicker: TimePicker,
    InputColor: InputColor,
    Day: Day,
    Signature: Signature,
    TinyMce: TinyMce,
};

const RenderAsync = React.forwardRef(({ field, ...props }, ref) => {
    let fieldDataType = field && field.type ? field.type : "";
    if (!fieldDataType) {
        return true;
    }
    const [dynamicValue, setDynamicValue] = useState(() => props.dynamicValue);
    const [fieldType, setFieldType] = useState(() => fieldDataType);
    const [fieldProps, setFieldProps] = useState(props);
    useEffect(() => {
        if (dynamicValue) {
            if (Array.isArray(dynamicValue) && Array.isArray(props.dynamicValue)) {
                if (JSON.stringify(dynamicValue) !== JSON.stringify(props.dynamicValue)) {
                    setDynamicValue(props.dynamicValue);
                }
            } else if (dynamicValue !== props.dynamicValue) {
                setDynamicValue(props.dynamicValue);
            }
        } else {
            setDynamicValue(props.dynamicValue);
        }
    }, [props.dynamicValue]);
    useEffect(() => {
        // Set disabled while loading
        // setFieldProps(p => ({...p, disabled: true}));
        // this probably needs to come from the field data, something like:
        API.incidentDynamicFunction(props.dynamicFunction.entity, props.dynamicFunction.method, {
            incidentValues: props.dependOnIncident,
            fieldValue: dynamicValue,
        }).then((response) => {
            switch (fieldType) {
                case "Radio":
                case "Checkbox":
                case "Select":
                    setFieldProps((p) => ({
                        ...p,
                        // disabled: props.disabled,
                        options: response.data.data.result.map((v) => ({
                            [fieldType === "Select" ? "name" : "label"]: v.name,
                            value: v.id,
                        })),
                    }));
                    break;
                case "TinyMce":
                    setFieldProps((p) => ({
                        ...p,
                        // disabled: (props.editDisable)?props.editDisable:props.disabled,
                        defaultValue: props.isFirstLoad ? props.defaultValue : response.data.data.result.name,
                    }));
                    break;
                default:
                    break;
            }
        });
    }, [dynamicValue]);

    useEffect(() => {
        setFieldProps((p) => ({
            ...p,
            error: props.error,
        }));
    }, [props.renderCount]);

    return React.createElement(FieldTypes[fieldType], {
        ...fieldProps,
        ref,
    });
});

FieldTypes.RenderAsync = RenderAsync;

const IncidentForm = ({ success, cancel, incidentId, showTab }) => {
    const [state, dispatch] = useContext(IncidentsContext);
    const incId = incidentId;
    let questionName = {};
    renderCount++;
    const { register, getValues, errors, handleSubmit, watch, formState, reset } = useForm();
    const { dirty } = formState;
    const values = getValues();
    const live = watch();
    const [isEdit, setIsEdit] = useState(false);
    const [correctiveSendMail, setCorrectiveSendMail] = useState({ stepId: 0, fieldId: 0 });
    const [dropResponsibleParty, setDropResponsibleParty] = useState({ stepId: 0, fieldId: 0 });
    const [sendDriverViaWorkbot, setSendDriverViaWorkbot] = useState(true);
    const [correctionAction, setCorrectionAction] = useState({ stepId: 0, fieldId: 0 });
    const [refresh, setRefresh] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [isShowPrint, setIsShowPrint] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isTinyMceData, setIsTinyMceData] = useState("");
    const [reason, setReason] = useState({ id: "", options: [] });
    const history = useHistory();
    const [enablePastIncidentAdd, setEnablePastIncidentAdd] = useState(true);
    const [enableCurrentPhase, seEnableCurrentPhase] = useState("");
    const [isDirty, setIsDirty] = useState(dirty);
    const [requireWatchVideo, setRequireWatchVideo] = useState(false);
    const [requireComplete, setRequireComplete] = useState(false);

    useEffect(() => {
        API.fetchIncident({ incId }).then(
            async (response) => {
                if (response.data.data.result && response.data.data.result.logout == true) {
                    history.push("/incidents");
                }
                if (response.data.data.result && response.data.data.result.error) {
                    toast({
                        title: "Incident could not be found",
                        type: "error",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                    history.push("/incidents");
                }
                if (response.data.data.result?.isRequireComplete) {
                    setRequireComplete(response.data.data.result?.isRequireComplete);
                    setRequireWatchVideo(response.data.data.result?.isRequireWatchVideo);
                }

                dispatch({
                    type: "SET_INCIDENT",
                    payload: response.data.data.result,
                });
            },
            (error) => console.log
        );

        return () => API.cancel();
    }, [dispatch, incId, refresh]);

    useEffect(() => {
        if (state.incident && state.incident.phases) {
            if (state.incident.token == "Coa" || state.incident.token == "Cor") {
                let currentPhaseTemp = state.incident.phases.filter((phase) => phase.current === true);
                currentPhaseTemp[0].steps.map((step) => {
                    step.fields.map((field) => {
                        if (
                            field.token == "cor-detail-step1-field2" ||
                            field.token == "coa-coaching-detail-step1-field1"
                        ) {
                            setReason({ id: "steps." + step.id + ".fields." + field.id, options: field.props.options });
                        }
                    });
                });
            }
        }
    }, [state]);

    const setLoaded = (loaded = !state.loaded) =>
        dispatch({
            type: "SET_LOADED",
            payload: loaded,
        });

    const gotoPhase = (index) => {
        let currentPhase = state.incident.phases.filter((phase) => phase.current === true);
        let tStep = {};
        currentPhase = currentPhase[0];
        currentPhase.steps.map(function (step) {
            tStep[step.id] = [];
            let tField = {};
            step.fields.map(function (field) {
                let name = `steps.${step.id}.fields.${field.id}`;
                tField[field.id] = getValues(name);
            });
            tStep[step.id] = { fields: tField };
        });

        let phase = { steps: tStep };
        dispatch({
            type: "MERGE_VALUES",
            payload: [currentPhase, phase],
        });
        dispatch({
            type: "GOTO_PHASE",
            payload: index,
        });
        setTimeout(() => {
            setIsDirty(false);
            reset({values: {}}, { keepDirty: false });
        }, 1000);
    };

    const generateRandomString = (length = 5) => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join('');
    };

    const uploadToAmazon = async (fileObj) => {
        let formData = new FormData();
        let originalName = fileObj.name;
        let originalExtention = originalName.split(".")[1];
        let changedName = getCompanyId() +'-incident-'+incId + '-'+ moment().format('Y-MM-DD-HH-mm-ss') +'-'+ generateRandomString(3) + '.' + originalExtention;
        formData.append("image", fileObj);
        formData.append("name", fileObj.name);
        formData.append("newName", changedName);
        let result = await upload(formData,true).catch((err) => {
            //console.log({err})
        });
        return result.data.ObjectURL;
    };

    const prepareIncidentPhaseObject = async (phase) => {
        let inputs = {};

        for (const stepId of Object.keys(phase.steps)) {
            const fields = phase.steps[stepId].fields;

            inputs[stepId] = { fields: {} };

            for (const fieldId of Object.keys(fields)) {
                const value = fields[fieldId];
                inputs[stepId].fields[fieldId] = value;
                let imgUrl = [];
                if (value instanceof FileList) {
                    let cPhase = currentPhase.steps.filter((s) => s.id == stepId);
                    if (cPhase.length > 0) {
                        let cStep = cPhase[0].fields.filter((f) => f.id == fieldId);
                        if (cStep.length > 0) {
                            if (cStep[0].props.defaultValue != "") {
                                imgUrl = cStep[0].props.defaultValue.split(",");
                            }
                            if (value.length > 0 && cStep[0].props.isSingleUpload) {
                                imgUrl = [];
                            } else if (!value.length > 0 && cStep[0].props.isSingleUpload) {
                                imgUrl = cStep[0].props.defaultValue;
                            }
                        }
                    }

                    if (!value.length > 0) {
                        inputs[stepId].fields[fieldId] = { type: "image", data: imgUrl.toString() };
                    } else {
                        for (let i in value) {
                            if (!value.hasOwnProperty(i)) continue;
                            let tempUrl = await uploadToAmazon(value[i]);
                            imgUrl.push(tempUrl);
                        }

                        inputs[stepId].fields[fieldId] = { type: "image", data: imgUrl.toString() };
                    }
                }
            }
        }

        let output = {
            currentPhaseId: currentPhase.id,
            steps: inputs,
            title: phase.title,
        };

        output.isRequireComplete = phase?.isRequireComplete;
        output.isRequireWatchVideo = phase?.isRequireWatchVideo;

        return output;
    };
    const prepareSkipIncidentPhaseObject = async (phase) => {
        let inputs = {};

        for (const stepId of Object.keys(phase.steps)) {
            const fields = phase.steps[stepId].fields;

            inputs[stepId] = { fields: {} };

            for (const fieldId of Object.keys(fields)) {
                const value = fields[fieldId];
                inputs[stepId].fields[fieldId] = "";
                let imgUrl = [];
                if (value instanceof FileList) {
                    let cPhase = currentPhase.steps.filter((s) => s.id == stepId);
                    if (cPhase.length > 0) {
                        let cStep = cPhase[0].fields.filter((f) => f.id == fieldId);
                        if (cStep.length > 0) {
                            if (cStep[0].props.defaultValue != "") {
                                imgUrl = cStep[0].props.defaultValue.split(",");
                            }
                            if (value.length > 0 && cStep[0].props.isSingleUpload) {
                                imgUrl = [];
                            } else if (!value.length > 0 && cStep[0].props.isSingleUpload) {
                                imgUrl = cStep[0].props.defaultValue;
                            }
                        }
                    }

                    if (!value.length > 0) {
                        inputs[stepId].fields[fieldId] = { type: "image", data: imgUrl.toString() };
                    } else {
                        for (let i in value) {
                            if (!value.hasOwnProperty(i)) continue;
                            let tempUrl = await uploadToAmazon(value[i]);
                            imgUrl.push(tempUrl);
                        }

                        inputs[stepId].fields[fieldId] = { type: "image", data: imgUrl.toString() };
                    }
                }
            }
        }

        return {
            currentPhaseId: currentPhase.id,
            steps: inputs,
            title: phase.title,
        };
    };

    const printPhase = async (event) => {
        event.preventDefault();
        setLoaded(false);
        API.printPhaseDetail(state.incident.id, currentPhase.phaseToken, null).then(
            (response) => {
                setLoaded(true);
                toast({
                    title: "Incident Phase printed Successfully",
                    type: "success",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                if (state.incident.token == "Cor") {
                    toast({
                        title: "Your document is queued to generate and you will be notified in the workplace_bot when completed",
                        type: "success",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    window.open(response.data.data.result);
                }
            },
            (error) => console.log
        );
    };

    const reviewPhase = async (phase) => {
        // setLoaded(false);
        setLoaded(false);

        let isCreateCorrectiveAction = false;
        if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
            if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
                if (
                    correctionAction.stepId in phase.steps &&
                    correctionAction.fieldId in phase.steps[correctionAction.stepId].fields
                ) {
                    let selectedCorrectionAction =
                        phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
                    if (selectedCorrectionAction == "yes") {
                        let response = await API.checkChildIncident(state.incident.id);
                        if (response.data.data.result) {
                            if (response.data.data.result.length == 0) {
                                const confirmation = await confirm({
                                    icon: true,
                                    text: "Start a corrective action form for this incident once you save? ",
                                    cancelBtn: "Open Later",
                                    confirmBtn: "Yes",
                                });
                                if (confirmation) {
                                    isCreateCorrectiveAction = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        phase = await prepareIncidentPhaseObject(phase);
        phase.reviewPhase = true;
        let reviewPhaseTriggers = ["incident_review", "incident_edited"];
        if (currentPhase.isLastPhase) {
            reviewPhaseTriggers.push("incident_completed");
        }
        API.updateSavePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            reviewPhaseTriggers,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setIsDirty(false);
                setLoaded(true);
                toast({
                    title: "Incident Phase reviewed & updated Successfully",
                    type: "success",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                let lastPhaseSubmited = 0;
                {
                    currentPhase.submittedPhases.map((phaseId, i) => {
                        lastPhaseSubmited = phaseId;
                    });
                }
                // if (response.data.data.result.isFinish && (parseInt(lastPhaseSubmited) === parseInt(phase.currentPhaseId))) {
                if (response.data.data.result.isFinish) {
                    success(true);
                } else {
                    setRefresh(refresh + 1);
                }
                // } else {
                //     dispatch([
                //         {
                //             type: 'MERGE_VALUES',
                //             payload: [currentPhase, phase]
                //         },
                //         {
                //             type: 'GOTO_NEXT_PHASE'
                //         }
                //     ]);
                // }
            },
            (error) => console.log
        );
    };

    const finishPhaseWithRefresh = async (phase) => {
        if (state.incident.token == "Dro") {
            if (dropResponsibleParty && dropResponsibleParty.fieldId != 0 && dropResponsibleParty.stepId != 0) {
                if (
                    dropResponsibleParty.stepId in phase.steps &&
                    dropResponsibleParty.fieldId in phase.steps[dropResponsibleParty.stepId].fields
                ) {
                    let type = phase.steps[dropResponsibleParty.stepId].fields[dropResponsibleParty.fieldId];
                    if (type != "") {
                        let res = await API.checkDropTypeInStation(
                            state.incident.id,
                            state.incident.station.id,
                            state.incident.date,
                            type
                        );
                        if (res.data.data.result) {
                            alert(
                                "You can add only two drop/pickup incident in a day for each station and also Responsible type are diffret for each In the drop incident"
                            );
                            return true;
                        }
                    }
                }
            }
        }
        let isCreateCorrectiveAction = false;
        if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
            if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
                if (
                    correctionAction.stepId in phase.steps &&
                    correctionAction.fieldId in phase.steps[correctionAction.stepId].fields
                ) {
                    let selectedCorrectionAction =
                        phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
                    if (selectedCorrectionAction == "yes") {
                        let response = await API.checkChildIncident(state.incident.id);
                        if (response.data.data.result) {
                            if (response.data.data.result.length == 0) {
                                const confirmation = await confirm({
                                    icon: true,
                                    text: "Start a corrective action form for this incident once you save? ",
                                    cancelBtn: "Open Later",
                                    confirmBtn: "Yes",
                                });
                                if (confirmation) {
                                    isCreateCorrectiveAction = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        setLoaded(false);
        let finishPhaseTriggers = ["incident_submitted"];
        if (currentPhase.isLastPhase) {
            finishPhaseTriggers.push("incident_completed");
        }
        if (currentPhase.isAsignTo) {
            finishPhaseTriggers.push("incident_assign_to");
        }
        phase = await prepareIncidentPhaseObject(phase);
        API.savePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            finishPhaseTriggers,
            true,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setLoaded(true);
                setIsDirty(false);
                if (response.data.data.result.isError) {
                    toast({
                        title: response.data.data.result.errorMessage,
                        type: "error",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    if (response.data.data.result.isFinish) {
                        success(true);
                    } else {
                        setRefresh(refresh + 1);
                    }
                    if (state.incident.token == "Cor") {
                        if (correctiveSendMail && correctiveSendMail.fieldId != 0 && correctiveSendMail.stepId != 0) {
                            let type = phase.steps[correctiveSendMail.stepId].fields[correctiveSendMail.fieldId];
                            if (type == "checkbox_pdf_mail_to_employee") {
                                toast({
                                    title: "Your document is queued to generate. Once it'll create we'll attached and send an email to driver.",
                                    type: "success",
                                    timeout: 8000,
                                    useIcon: true,
                                    useClose: true,
                                });
                            }
                        }
                    }
                    toast({
                        title: "Incident Phase Submitted Successfully",
                        type: "success",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                }
            },
            (error) => console.log
        );
    };
    const skipPhaseWithRefresh = async (phase) => {
        // if (state.incident.token == "Dro") {
        //     if (dropResponsibleParty && dropResponsibleParty.fieldId != 0 && dropResponsibleParty.stepId != 0) {
        //         if (dropResponsibleParty.stepId in phase.steps && dropResponsibleParty.fieldId in phase.steps[dropResponsibleParty.stepId].fields) {
        //             let type = phase.steps[dropResponsibleParty.stepId].fields[dropResponsibleParty.fieldId];
        //             if (type != "") {
        //                 let res = await API.checkDropTypeInStation(
        //                     state.incident.id,
        //                     state.incident.station.id,
        //                     state.incident.date,
        //                     type
        //                 );
        //                 if (res.data.data.result) {
        //                     alert(
        //                         "You can add only two drop/pickup incident in a day for each station and also Responsible type are diffret for each In the drop incident"
        //                     );
        //                     return true;
        //                 }
        //             }
        //         }
        //     }
        // }
        let isCreateCorrectiveAction = false;
        // if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
        //     if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
        //         if ((correctionAction.stepId in phase.steps) && (correctionAction.fieldId in phase.steps[correctionAction.stepId].fields)) {
        //             let selectedCorrectionAction = phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
        //             if (selectedCorrectionAction == "yes") {
        //                 let response = await API.checkChildIncident(state.incident.id);
        //                 if (response.data.data.result) {
        //                     if (response.data.data.result.length == 0) {
        //                         const confirmation = await confirm({
        //                             icon: true,
        //                             text: "Start a corrective action form for this incident once you save? ",
        //                             cancelBtn: "Open Later",
        //                             confirmBtn: "Yes",
        //                         });
        //                         if (confirmation) {
        //                             isCreateCorrectiveAction = true;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        setLoaded(false);
        let finishPhaseTriggers = ["incident_submitted"];
        if (currentPhase.isLastPhase) {
            finishPhaseTriggers.push("incident_completed");
        }
        if (currentPhase.isAsignTo) {
            finishPhaseTriggers.push("incident_assign_to");
        }
        phase = await prepareSkipIncidentPhaseObject(phase);
        API.savePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            finishPhaseTriggers,
            true,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setLoaded(true);
                if (response.data.data.result.isError) {
                    toast({
                        title: response.data.data.result.errorMessage,
                        type: "error",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    if (response.data.data.result.isFinish) {
                        success(true);
                    } else {
                        setRefresh(refresh + 1);
                    }
                    // if (state.incident.token == 'Cor') {
                    //     if (correctiveSendMail && correctiveSendMail.fieldId != 0 && correctiveSendMail.stepId != 0) {
                    //         let type = phase.steps[correctiveSendMail.stepId].fields[correctiveSendMail.fieldId];
                    //         if (type == 'checkbox_pdf_mail_to_employee') {
                    //             toast({
                    //                 title: "Your document is queued to generate. Once it'll create we'll attached and send an email to driver.",
                    //                 type: "success",
                    //                 timeout: 8000,
                    //                 useIcon: true,
                    //                 useClose: true
                    //             });
                    //         }
                    //     }
                    // }
                    // toast({
                    //     title: "Incident Phase Submitted Successfully",
                    //     type: "success",
                    //     timeout: 8000,
                    //     useIcon: true,
                    //     useClose: true,
                    // });
                }
            },
            (error) => console.log
        );
    };

    const finishPhase = async (phase) => {
        // send incident current date to both place
        if (state.incident.token == "Dro") {
            if (dropResponsibleParty && dropResponsibleParty.fieldId != 0 && dropResponsibleParty.stepId != 0) {
                if (
                    dropResponsibleParty.stepId in phase.steps &&
                    dropResponsibleParty.fieldId in phase.steps[dropResponsibleParty.stepId].fields
                ) {
                    let type = phase.steps[dropResponsibleParty.stepId].fields[dropResponsibleParty.fieldId];
                    if (type != "") {
                        let res = await API.checkDropTypeInStation(
                            state.incident.id,
                            state.incident.station.id,
                            state.incident.date,
                            type
                        );
                        if (res.data.data.result) {
                            alert(
                                "You can add only two drop/pickup incident in a day for each station and also Responsible type are different for each In the drop incident"
                            );
                            return true;
                        }
                    }
                }
            }
        }
        let isCreateCorrectiveAction = false;
        if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
            if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
                if (
                    correctionAction.stepId in phase.steps &&
                    correctionAction.fieldId in phase.steps[correctionAction.stepId].fields
                ) {
                    let selectedCorrectionAction =
                        phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
                    if (selectedCorrectionAction == "yes") {
                        let response = await API.checkChildIncident(state.incident.id);
                        if (response.data.data.result) {
                            if (response.data.data.result.length == 0) {
                                const confirmation = await confirm({
                                    icon: true,
                                    text: "Start a corrective action form for this incident once you save? ",
                                    cancelBtn: "Open Later",
                                    confirmBtn: "Yes",
                                });
                                if (confirmation) {
                                    isCreateCorrectiveAction = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        setLoaded(false);
        let finishPhaseTriggers = ["incident_submitted"];
        if (currentPhase.isLastPhase) {
            finishPhaseTriggers.push("incident_completed");
        }
        if (currentPhase.isAsignTo) {
            finishPhaseTriggers.push("incident_assign_to");
        }
        phase = await prepareIncidentPhaseObject(phase);
        API.savePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            finishPhaseTriggers,
            false,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setLoaded(true);
                setIsDirty(false);
                if (response.data.data.result.isError) {
                    toast({
                        title: response.data.data.result.errorMessage,
                        type: "error",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    // if (response.data.data.result.isFinish) {
                    //     success(true)
                    // } else {
                    //     setRefresh(refresh+1);
                    // }
                    if (state.incident.token == "Cor") {
                        if (correctiveSendMail && correctiveSendMail.fieldId != 0 && correctiveSendMail.stepId != 0) {
                            let type = phase.steps[correctiveSendMail.stepId].fields[correctiveSendMail.fieldId];
                            if (type == "checkbox_pdf_mail_to_employee") {
                                toast({
                                    title: "Your document is queued to generate. Once it'll create we'll attached and send an email to driver.",
                                    type: "success",
                                    timeout: 8000,
                                    useIcon: true,
                                    useClose: true,
                                });
                            }
                        }
                    }
                    toast({
                        title: "Incident Phase Submitted Successfully",
                        type: "success",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                }
            },
            (error) => console.log
        );
    };

    const addPriorHistoryData = async (type, event) => {
        let selectedValueId = live[`${reason.id}`];
        if (Array.isArray(selectedValueId) == false) {
            selectedValueId = [selectedValueId];
        }
        let selectedValue = [];
        reason.options.map((o) => {
            if (selectedValueId.includes(o.value.toString())) {
                if (o.label) {
                    selectedValue.push(o.label);
                } else if (o.name) {
                    selectedValue.push(o.name);
                }
            }
        });
        if (event.target.value && event.target.checked) {
            await API.getIncidentPastHistoryData(
                type,
                event.target.value,
                state.incident.station.id,
                state.incident.id,
                state.incident.token,
                selectedValue.join(","),
                state.incident.driver.id
            ).then(
                async (response) => {
                    setLoaded(true);
                    if (response.data.data.incident) {
                        const textAreaSelector = document.getElementById("coa-coaching-detail-step1-field3")
                            ? document.getElementById("coa-coaching-detail-step1-field3")
                            : document.getElementById("cor-detail-step1-field5");
                        if (textAreaSelector) {
                            let tinyMceData = "";
                            tinyMceData = textAreaSelector.value
                                ? textAreaSelector.value + "" + response.data.data.incident
                                : "Date &emsp;&emsp;&emsp;&emsp; InsidentId &emsp;&emsp;&emsp; Reason<br />" +
                                  response.data.data.incident;
                            textAreaSelector.value = tinyMceData;
                            await setIsTinyMceData(tinyMceData);
                            // setRefresh(refresh+1);
                        }
                    }
                },
                (error) => console.log
            );
        }
    };

    const updatePhasewithRefresh = async (phase) => {
        if (state.incident.token == "Dro") {
            if (dropResponsibleParty && dropResponsibleParty.fieldId != 0 && dropResponsibleParty.stepId != 0) {
                if (
                    dropResponsibleParty.stepId in phase.steps &&
                    dropResponsibleParty.fieldId in phase.steps[dropResponsibleParty.stepId].fields
                ) {
                    let type = phase.steps[dropResponsibleParty.stepId].fields[dropResponsibleParty.fieldId];
                    if (type != "") {
                        let res = await API.checkDropTypeInStation(
                            state.incident.id,
                            state.incident.station.id,
                            state.incident.date,
                            type
                        );
                        if (res.data.data.result) {
                            alert(
                                "You can add only two drop/pickup incident in a day for each station and also Responsible type are diffret for each In the drop incident"
                            );
                            return true;
                        }
                    }
                }
            }
        }
        let isCreateCorrectiveAction = false;
        if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
            if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
                if (
                    correctionAction.stepId in phase.steps &&
                    correctionAction.fieldId in phase.steps[correctionAction.stepId].fields
                ) {
                    let selectedCorrectionAction =
                        phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
                    if (selectedCorrectionAction == "yes") {
                        let response = await API.checkChildIncident(state.incident.id);
                        if (response.data.data.result) {
                            if (response.data.data.result.length == 0) {
                                const confirmation = await confirm({
                                    icon: true,
                                    text: "Start a corrective action form for this incident once you save? ",
                                    cancelBtn: "Open Later",
                                    confirmBtn: "Yes",
                                });
                                if (confirmation) {
                                    isCreateCorrectiveAction = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        setLoaded(false);

        let updatePhaseTriggers = ["incident_edited"];
        if (currentPhase.isLastPhase) {
            updatePhaseTriggers.push("incident_completed");
        }
        if (currentPhase.isAsignTo) {
            updatePhaseTriggers.push("incident_assign_to");
        }
        phase = await prepareIncidentPhaseObject(phase);
        API.updateSavePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            updatePhaseTriggers,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setLoaded(true);
                setIsDirty(false);
                toast({
                    title: "Incident Phase updated Successfully",
                    type: "success",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                let lastPhaseSubmited = 0;
                {
                    currentPhase.submittedPhases.map((phaseId, i) => {
                        lastPhaseSubmited = phaseId;
                    });
                }
                // if (response.data.data.result.isFinish && (parseInt(lastPhaseSubmited) === parseInt(phase.currentPhaseId))) {

                if (state.incident.token == "Cor") {
                    if (correctiveSendMail && correctiveSendMail.fieldId != 0 && correctiveSendMail.stepId != 0) {
                        let type = phase.steps[correctiveSendMail.stepId].fields[correctiveSendMail.fieldId];
                        if (type == "checkbox_pdf_mail_to_employee") {
                            toast({
                                title: "Your document is queued to generate. Once it'll create we'll attached and send an email to driver.",
                                type: "success",
                                timeout: 8000,
                                useIcon: true,
                                useClose: true,
                            });
                        }
                    }
                }

                if (response.data.data.result.isFinish) {
                    success(true);
                } else {
                    setRefresh(refresh + 1);
                }
                // } else {
                //     dispatch([
                //         {
                //             type: 'MERGE_VALUES',
                //             payload: [currentPhase, phase]
                //         },
                //         {
                //             type: 'GOTO_NEXT_PHASE'
                //         }
                //     ]);
                // }
            },
            (error) => console.log
        );
    };
    const updatePhase = async (phase) => {
        if (state.incident.token == "Dro") {
            if (dropResponsibleParty && dropResponsibleParty.fieldId != 0 && dropResponsibleParty.stepId != 0) {
                let type = phase.steps[dropResponsibleParty.stepId].fields[dropResponsibleParty.fieldId];
                if (type != "") {
                    let res = await API.checkDropTypeInStation(
                        state.incident.id,
                        state.incident.station.id,
                        state.incident.date,
                        type
                    );
                    if (res.data.data.result) {
                        alert(
                            "You can add only two drop/pickup incident in a day for each station and also Responsible type are diffret for each In the drop incident"
                        );
                        return true;
                    }
                }
            }
        }
        let isCreateCorrectiveAction = false;
        if (state.incident.token == "Cal" || state.incident.token == "Ncn" || state.incident.token == "Lat") {
            if (correctionAction && correctionAction.fieldId != 0 && correctionAction.stepId != 0) {
                let selectedCorrectionAction = phase.steps[correctionAction.stepId].fields[correctionAction.fieldId];
                if (selectedCorrectionAction == "yes") {
                    let response = await API.checkChildIncident(state.incident.id);
                    if (response.data.data.result) {
                        if (response.data.data.result.length == 0) {
                            const confirmation = await confirm({
                                icon: true,
                                text: "Start a corrective action form for this incident once you save? ",
                                cancelBtn: "Open Later",
                                confirmBtn: "Yes",
                            });
                            if (confirmation) {
                                isCreateCorrectiveAction = true;
                            }
                        }
                    }
                }
            }
        }
        setLoaded(false);

        let updatePhaseTriggers = ["incident_edited"];
        if (currentPhase.isLastPhase) {
            updatePhaseTriggers.push("incident_completed");
        }
        if (currentPhase.isAsignTo) {
            updatePhaseTriggers.push("incident_assign_to");
        }

        phase = await prepareIncidentPhaseObject(phase);
        API.updateSavePhase(
            phase,
            state.incident.id,
            currentPhase.phaseToken,
            updatePhaseTriggers,
            isCreateCorrectiveAction
        ).then(
            (response) => {
                setLoaded(true);
                setIsDirty(false);
                toast({
                    title: "Incident Phase updated Successfully",
                    type: "success",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                let lastPhaseSubmited = 0;
                {
                    currentPhase.submittedPhases.map((phaseId, i) => {
                        lastPhaseSubmited = phaseId;
                    });
                }

                if (state.incident.token == "Cor") {
                    if (correctiveSendMail && correctiveSendMail.fieldId != 0 && correctiveSendMail.stepId != 0) {
                        let type = phase.steps[correctiveSendMail.stepId].fields[correctiveSendMail.fieldId];
                        if (type == "checkbox_pdf_mail_to_employee") {
                            toast({
                                title: "Your document is queued to generate. Once it'll create we'll attached and send an email to driver.",
                                type: "success",
                                timeout: 8000,
                                useIcon: true,
                                useClose: true,
                            });
                        }
                    }
                }

                // if (response.data.data.result.isFinish && (parseInt(lastPhaseSubmited) === parseInt(phase.currentPhaseId))) {

                // if (response.data.data.result.isFinish) {
                //     success(true)
                //         {
                //             type: 'MERGE_VALUES',
                //             payload: [currentPhase, phase]
                //         },
                //         {
                //             type: 'GOTO_NEXT_PHASE'
                //         }
                //     ]);
                // }
            },
            (error) => console.log
        );
    };

    const handleCheckbox = async (e, field) => {
        if (
            field.token == "cor-detail-step2-field3" ||
            field.token == "coa-coaching-detail-step1-field8" ||
            field.token == "thx-employee-detail-step1-field6"
        ) {
            setSendDriverViaWorkbot(e.target.checked);
        }
    };
    const inc = {
        station: {},
        driver: {},
        phases: [],
        ...state.incident,
    };
    const currentPhase = inc.phases.find((p) => p.current) || { steps: [] };
    let attentionMessage = "";
    if (currentPhase.hasAccess === false && inc.driver) {
        attentionMessage =
            "Your permissions do not allow for you to complete the Review step. To edit this step, you need to be the employee's direct manager. " +
            inc.driver.name +
            " currently is set to report to " +
            inc.driver.stationMangerName +
            ".";
    }

    const unSavedHandle = (unSaved = false) => {
        if (isDirty === false && unSaved === false) {
            setTimeout(() => {
                reset({}, { keepDirty: false });
            }, 1000);
            return false;
        } else if (isDirty === false && unSaved === true) {
            window.history.back();
        }
        if (isDirty === true && unSaved === true) {
            dialog({
                text: "Please remember to save your data before exiting to prevent any loss. Scroll to the bottom and click Save button to ensure your information is securely stored.",
                buttons: [
                    {
                        type: "primary",
                        text: "Yes",
                        callback: () => false,
                    },
                    {
                        text: "No",
                        callback: () => true,
                    },
                ],
            }).then((res) => {
                if (res) {
                    window.history.back();
                }
            });

        }
        return false;
    };

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    useEffect(() => {
        unSavedHandle(false);
        if (typeof currentPhase.phaseToken !== `undefined`) {
            if (currentPhase.phaseToken == "cor-detail" || currentPhase.phaseToken == "coa-coaching-detail") {
                let submittedPhases = currentPhase.submittedPhases;
                if (submittedPhases.includes(currentPhase.id)) {
                    setEnablePastIncidentAdd(false);
                } else {
                    setEnablePastIncidentAdd(true);
                }
            } else {
                setEnablePastIncidentAdd(false);
            }
        }
        currentPhase.steps.map((step, k) => {
            step.fields.map((field, key) => {
                if (field.token && field.token == "dro-phase1-step1-field1") {
                    setDropResponsibleParty({ stepId: step.id, fieldId: field.id });
                }
                if (
                    field.token &&
                    (field.token == "ncns-review-step5-field1" ||
                        field.token == "call-in-review-step3-field1" ||
                        field.token == "late-review-step3-field1")
                ) {
                    setCorrectionAction({ stepId: step.id, fieldId: field.id });
                }
            });
        });
    }, [currentPhase]);

    const timeZoneName = {
        'ET': 'America/Detroit',
        'CT': 'America/Chicago',
        'MT': 'America/Denver',
        'PT': 'America/Los_Angeles',
        'AKT': 'US/Alaska',
        'HT': 'Pacific/Honolulu',
        'AZ': 'America/Phoenix',
        'CET': 'Europe/Amsterdam'
    };

    return (
        <div style={{ display: "flex" }}>
            {!state.loaded && (
                <LoadingWrapper style={{ position: "fixed" }}>
                    <Loading style={{ width: "40px" }} />
                </LoadingWrapper>
            )}
            <>
                {showTab && (
                    <Navigator style={{ minHeight: "calc(100vh - 53px)" }}>
                        <Label
                            //as={Link}
                            onClick={() => unSavedHandle(true)}
                            to={"/incidents"}
                            style={{ color: Theme.colors.info.border, cursor: "pointer", textDecoration: "none" }}
                        >
                            <Icon.ArrowLeft
                                size={"16px"}
                                color={Theme.colors.info.border}
                                style={{ verticalAlign: "middle", marginRight: 4 }}
                            />
                            <small>Back to incidents list</small>
                        </Label>
                        <Spacer top={2} bottom={4}>
                            <SubTitle>Incident Information</SubTitle>
                        </Spacer>
                        <Spacer bottom={5} style={{ borderTop: `1px solid ${Theme.colors.info.bg}` }} />
                        {/* todo decide what comes here */}
                        <Label>Station</Label>
                        <Spacer top={1} bottom={5}>
                            <Tag inline>{inc.station.code}</Tag> <Text as={"span"}>{inc.station.name}</Text>
                        </Spacer>
                        <Label>Category or Reason</Label>
                        <Spacer top={1} bottom={5}>
                            <Text>{inc.type}</Text>
                        </Spacer>
                        {inc.driver.id && (
                            <>
                                <Label>Driver</Label>
                                <Spacer top={1} bottom={5}>
                                    <People
                                        name={inc.driver.name}
                                        jobTitle={inc.driver.title}
                                        img={inc.driver.profile}
                                    />
                                </Spacer>
                            </>
                        )}
                        <Label>Date</Label>
                        <Spacer top={1} bottom={5}>
                            <Text style={{ "fontSize": "13px" }}>
                                {inc.utctime && (moment.utc(inc.utctime.timestamp * 1000).tz(timeZoneName[inc.timezone]).format("d") === 0 ||
                                    moment.utc(inc.utctime.timestamp * 1000).tz(timeZoneName[inc.timezone]).format("d") === 6
                                    ? "Weekend "
                                    : "Weekday ")}
                                -{inc.utctime && moment.utc(inc.utctime.timestamp * 1000).tz(timeZoneName[inc.timezone]).format(" MMM D, Y - h:mma ")}
                                ({inc.timezone})
                                {isEdit === false && (
                                    <Icon.Edit
                                        onClick={() => {
                                            setIsEdit(true);
                                        }}
                                        size={"20px"}
                                        color={Theme.colors.info.border}
                                        style={{ verticalAlign: "middle", marginRight: 4 }}
                                    />
                                )}
                            </Text>
                            {isEdit === true && <IncidentDateTimeForm isEdit={isEdit} setIsEdit={setIsEdit} />}
                        </Spacer>
                        {inc.driverRoute && (
                            <>
                                <Label>Shift</Label>
                                <Spacer top={1} bottom={5}>
                                    <ShiftBlock
                                        type={inc.driverRoute.type}
                                        hour={inc.driverRoute.hour}
                                        background={inc.driverRoute.bg}
                                        color={inc.driverRoute.color}
                                        category={inc.driverRoute.category}
                                    />
                                </Spacer>
                            </>
                        )}
                        {(inc.token == "Cor" || inc.token == "Coa") && (
                            <>
                                {inc.driver.stationManger && (
                                    <>
                                        <Label>Supervisor</Label>
                                        <Spacer top={1} bottom={5}>
                                            <People
                                                name={inc.driver.superVisorName}
                                                jobTitle={inc.driver.superVisorTitle}
                                                img={inc.driver.superVisorProfile}
                                            />
                                        </Spacer>
                                    </>
                                )}
                                <Label>Past {inc.lastMonthIncident} months</Label>
                                <hr></hr>
                                <Spacer bottom={3}>
                                    <Label>Incident forms:</Label>
                                    <IncidentPast>
                                        <div className="past-incident">
                                            <CheckBox
                                                name={`normal_incident`}
                                                onClick={async (value) => {
                                                    await addPriorHistoryData("normal_incident", value);
                                                }}
                                                options={inc.incidentPastHistory.normal_incident}
                                                disabled={!enablePastIncidentAdd}
                                            />
                                        </div>
                                    </IncidentPast>
                                </Spacer>
                                <Spacer bottom={3}>
                                    <Label>Coaching forms:</Label>
                                    <IncidentPast>
                                        <div className="past-incident">
                                            <CheckBox
                                                name={`coaching_action`}
                                                onClick={async (value) => {
                                                    await addPriorHistoryData("coaching_action", value);
                                                }}
                                                options={inc.incidentPastHistory.coaching_action}
                                                disabled={!enablePastIncidentAdd}
                                            />
                                        </div>
                                    </IncidentPast>
                                </Spacer>
                                <Spacer bottom={3}>
                                    <Label>Corrective action forms:</Label>
                                    <IncidentPast>
                                        <div className="past-incident">
                                            <CheckBox
                                                name={`corrective_action`}
                                                onClick={async (value) => {
                                                    await addPriorHistoryData("corrective_action", value);
                                                }}
                                                options={inc.incidentPastHistory.corrective_action}
                                                disabled={!enablePastIncidentAdd}
                                            />
                                        </div>
                                    </IncidentPast>
                                </Spacer>
                                <Spacer bottom={3}>
                                    <Label>Thank you forms:</Label>
                                    <IncidentPast>
                                        <div className="past-incident">
                                            <CheckBox
                                                name={`thx_action`}
                                                onClick={async (value) => {
                                                    await addPriorHistoryData("thx_action", value);
                                                }}
                                                options={inc.incidentPastHistory.thx_action}
                                                disabled={!enablePastIncidentAdd}
                                            />
                                        </div>
                                    </IncidentPast>
                                </Spacer>
                            </>
                        )}
                    </Navigator>
                )}

                <Content
                    style={{ minHeight: showTab ? "calc(100vh - 53px)" : "inherit", height: !showTab && "auto" }}
                    as="form"
                >
                    <IncidentTitleInputStyle>
                        {inc && inc.createdBy && (
                            <Input
                                defaultValue={
                                    inc && inc.createdBy && inc.title ? inc.title : "Initiated By: " + inc.createdBy
                                }
                                name="title"
                                ref={register({
                                    required: true,
                                })}
                                // readOnly={(currentPhase.isPhaseCompleted === true)?true:false}
                                // disabled={(currentPhase.isPhaseCompleted === true)?true:false}
                                placeholder="Incident Title*"
                                valid={values["title"]}
                                error={errors["title"] && "Insert a incident title"}
                            />
                        )}
                    </IncidentTitleInputStyle>
                    <Spacer top={3} />

                    <IncidentPhasesTabs>
                        {inc.phases.map((phase, k) => (
                            <li
                                key={k}
                                className={`
                                    ${
                                        phase.current
                                            ? "active"
                                            : phase.submittedPhases.indexOf(phase.id) > -1 &&
                                              phase.hasReview === false &&
                                              !(phase.currentPhase !== phase.id)
                                            ? "disabled"
                                            : ""
                                    }
                                `}
                            >
                                <a
                                    onClick={() => {
                                        if (
                                            phase.submittedPhases.indexOf(phase.id) > -1 ||
                                            phase.currentPhase == phase.id
                                        )
                                            gotoPhase(k);
                                    }}
                                >
                                    {k + 1}. {phase.title}
                                    {phase.reviewedPhases.indexOf(phase.id) > -1 ? (
                                        <Spacer inline left={1}>
                                            <Icon.Check size={"16px"} color={Theme.colors.info.text} />
                                        </Spacer>
                                    ) : phase.submittedPhases.indexOf(phase.id) > -1 ? (
                                        <Spacer inline left={1}>
                                            <Icon.Check size={"16px"} color={Theme.colors.success.text} />
                                        </Spacer>
                                    ) : null}
                                </a>
                            </li>
                        ))}
                    </IncidentPhasesTabs>
                    {currentPhase.hasAccess === false && (
                        <Spacer top={5}>
                            <Alert.Warning title="Attention" content={attentionMessage} />
                        </Spacer>
                    )}
                    {currentPhase.steps.map((step, k) => (
                        <Spacer top={5} key={k}>
                            <Label style={{ color: Theme.colors.info.text }}>
                                {k + 1}. {step.title}
                            </Label>
                            <Text>{step.description}</Text>
                            <Spacer bottom={3} />
                            {step.fields.map((field, key) => {
                                let name = `steps.${step.id}.fields.${field.id}`;

                                let hasError =
                                    errors.steps &&
                                    `${step.id}` in errors.steps &&
                                    `${field.id}` in errors.steps[`${step.id}`].fields &&
                                    errors.steps[`${step.id}`].fields[`${field.id}`].message;

                                let props = {
                                    key: name,
                                    ...field.props,
                                    name: name,
                                    questionId: field.id,
                                    ref: register(field.props.validation),
                                    valid: values[name] && !hasError,
                                    error: hasError,
                                    invalid: values[name] && hasError,
                                    token: field.token,
                                };

                                questionName[field.id] = name;

                                if (field.disabled == true) {
                                    if (enableCurrentPhase != "" && typeof enableCurrentPhase == "boolean") {
                                        props.disabled = !enableCurrentPhase;
                                    } else {
                                        props.disabled = true;
                                    }
                                }
                                if (
                                    (field.editDisable == true &&
                                        currentPhase.isPhaseCompleted === true &&
                                        (inc.token == "Cor" || inc.token == "Coa" || inc.token == "Thx" || inc.token == "Eno")) ||
                                    (field.editDisable == true && currentPhase.isCompletedWithReview == true)
                                ) {
                                    if (enableCurrentPhase != "" && typeof enableCurrentPhase == "boolean") {
                                        props.disabled = !enableCurrentPhase;
                                    } else {
                                        props.disabled = true;
                                    }
                                }

                                if (field.type === "Radio") {
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.title = props.title + " *";
                                        }
                                    }
                                    props.default = field.props.defaultValue;
                                }
                                if (field.type === "Checkbox") {
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.title = props.title + " *";
                                        }
                                    }
                                    props.defaultValues = field.props.defaultValue;
                                    props.onClick = (e) => {
                                        handleCheckbox(e, field);
                                    };
                                }

                                if (props.token == "acc-driver-tasks-step1-field1" || props.token == "cor-detail-step1-field1") {
                                    if (field.props.defaultValue == "") {
                                        field.props.defaultValue = inc.datetime;
                                    }
                                }

                                if (field.type === "Day" && field.props.defaultValue) {
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.label = props.label == null ? " * " : props.label + " *";
                                        }
                                    }
                                    props.defaultValue = moment.utc(field.props.defaultValue).format("YYYY-MM-DD");
                                }

                                if (field.type === "File") {
                                    if (currentPhase.isCompleted == true && props.disabled) {
                                        props.disabled = true;
                                    }
                                    props.placeholder = [];
                                    props.hideAfterFileSelect = false;
                                    if (field.props.defaultValue) {
                                        if (field.props.defaultValue instanceof FileList != true) {
                                            let defaultValueArr = field.props.defaultValue.split(",");
                                            let regexCheck = /\.(jpg|JPG|gif|GIF|png|PNG)$/;
                                            props.placeholder = defaultValueArr.map(function (url) {
                                                if (regexCheck.test(url)) {
                                                    return { type: "image", src: url };
                                                } else {
                                                    return { type: "doc", src: url };
                                                }
                                            });
                                        }
                                    }
                                    props.Required = false;
                                    props.ref = register;
                                }
                                if (field.type === "Signature") {
                                    props.placeholder = [];
                                    if (field.props.defaultValue) {
                                        if (field.props.defaultValue instanceof FileList != true) {
                                            let defaultSignatureValue = field.props.defaultValue;
                                            let regexCheck = /\.(jpg|JPG|gif|GIF|png|PNG)$/;
                                            if (regexCheck.test(defaultSignatureValue)) {
                                                props.placeholder = [{ type: "image", src: defaultSignatureValue }];
                                            } else {
                                                props.placeholder = [{ type: "doc", src: defaultSignatureValue }];
                                            }
                                        }
                                    }
                                    props.Required = true;
                                    props.ref = register;
                                    props.label = field.props.label;
                                    // if (props?.validation?.required != false && props?.validation?.required.length > 0) {
                                    //     props.label = props.label + ' *';
                                    // }
                                }

                                if (field.type === "TinyMce") {
                                    props.init = {
                                        height: 400,
                                        width: 500,
                                    };
                                    if (field.props.defaultValue || isTinyMceData) {
                                        if (
                                            field.token == "cor-detail-step1-field5" ||
                                            field.token == "coa-coaching-detail-step1-field3"
                                        ) {
                                            props.defaultValue = isTinyMceData
                                                ? isTinyMceData
                                                : field.props.defaultValue;
                                        }
                                    }
                                    if (field.dependOn) {
                                        props.defaultParentName = `steps.${step.id}.fields.${field.dependOn}`;
                                    }
                                    props.Required = true;
                                    props.ref = register;
                                    props.label = field.label;
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.label = props.label + " *";
                                        }
                                    }
                                }

                                if (field.type === "Select") {
                                    props.label = props.title;
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.label = props.label + " *";
                                        }
                                    }
                                    props.id = props.token;
                                    props.onChange = (v) => {
                                        field.props.defaultValue = v.value;
                                        props.defaultValue = v.value;
                                        // gotta force re-render to make dependOn fields to work
                                        let values = watch();
                                        values[name] = v.value;
                                        setRequireComplete(v.isRequireComplete)
                                        setIsFirstLoad(false);
                                        // reset(values);
                                        if (field.props.onChange && field.props.onChange.apply) field.props.onChange(v);
                                    };
                                }

                                if (field.type === "Input") {
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.label = props.label + " *";
                                        }
                                    }
                                }
                                if (field.type === "Textarea") {
                                    props.as = "textarea";
                                    props.style = props.style || {};
                                    props.style.height = "88px";
                                    props.id = props.token;
                                    if (!Array.isArray(props?.validation)) {
                                        if (
                                            props?.validation?.required != false &&
                                            props?.validation?.required.length > 0
                                        ) {
                                            props.label = props.label + " *";
                                        }
                                    }
                                }

                                // if ( (currentPhase.submittedPhases.indexOf(currentPhase.id) > -1) ) {
                                //     props.readOnly = true;
                                //     props.disabled = true;
                                // }

                                if (!currentPhase.hasAccess) props.readOnly = true;

                                if (field.dependOn) {
                                    let watchValue = live[`steps.${step.id}.fields.${field.dependOn}`];
                                    if (field.props.dynamic) {
                                        props.dynamicValue = watchValue;
                                        props.field = { ...field };
                                        props.renderCount = renderCount;
                                        props.isFirstLoad = isFirstLoad;

                                        field.type = "RenderAsync";
                                    } else if (field.dependValueOnValue) {
                                        if (watchValue && typeof watchValue == "object") {
                                            let tempOptions = [];
                                            if (watchValue && watchValue.length > 0) {
                                                watchValue.map((value, key) => {
                                                    field.props.optionsValue[value].map((i) => {
                                                        tempOptions.push(i);
                                                    });
                                                });
                                            }
                                            props.options = tempOptions;
                                        } else if (watchValue && typeof watchValue == "string") {
                                            props.options = watchValue ? field.props.optionsValue[watchValue] : [];
                                        }
                                    } else if (field.dependOnValue && field.dependOnValue != watchValue) {
                                        return null;
                                    }
                                }

                                return (
                                    <Spacer top={key > 0 ? 3 : 0} key={key}>
                                        {/* <FileOverlay props={props} currentPhase={currentPhase} type={field.type}> */}
                                            {React.createElement(FieldTypes[field.type], props)}
                                        {/* </FileOverlay> */}
                                    </Spacer>
                                );
                            })}
                        </Spacer>
                    ))}

                    {currentPhase.hasAccess &&
                        currentPhase.review === true &&
                        currentPhase.hasReview === false &&
                        currentPhase.isPhaseCompleted === true && (
                            <Spacer top={5}>
                                <Alert.Warning
                                    title="Note"
                                    content="This incident phase is currently in review for final approval."
                                />
                            </Spacer>
                        )}
                    {errors && Object.keys(errors).length > 0 && (
                        <Spacer top={5}>
                            <Alert.Error title="Error" content="Missing required field's." />
                        </Spacer>
                    )}
                    {currentPhase.hasAccess && (
                        <Spacer top={5}>
                            {inc.token == "Cor" || inc.token == "Coa" ? (
                                <>
                                    {(inc.type === "Corrective Action" || inc.type === "Coaching" || inc.isCoaching == true) && (currentPhase.phaseToken == "cor-detail" || currentPhase.phaseToken == "coa-coaching-detail") && (
                                        <>
                                            <Spacer bottom={3} >
                                                <CheckBox
                                                    ref={register}
                                                    name="isRequireComplete"
                                                    options={[{ value: true, label: "Require to complete incident" }]}
                                                    defaultValues={[requireComplete]}
                                                    onClick={(e) => setRequireComplete(e.target.checked)}
                                                />
                                            </Spacer>
                                            {state.incident.isDisplayVideoCheckbox && requireComplete &&
                                                <Spacer bottom={3} >
                                                    <CheckBox
                                                        ref={register}
                                                        name="isRequireWatchVideo"
                                                        options={[{ value: true, label: "Require to watch video" }]}
                                                        defaultValues={[requireWatchVideo]}
                                                        onClick={(e) => {
                                                            setRequireWatchVideo(e.target.checked)
                                                        }}
                                                    />
                                                </Spacer>
                                            }
                                        </>
                                    )}

                                    {currentPhase.isPhaseCompleted === true ? (
                                        <>
                                            {!currentPhase.isLastPhase && (
                                                <Button type="primary" onClick={handleSubmit(updatePhase)}>
                                                    {"Save"}
                                                </Button>
                                            )}
                                            {/* <Spacer inline left={1}><Button type="primary" onClick={handleSubmit(updatePhasewithRefresh)}>
                                            {currentPhase.isLastPhase ? 'Save and Send' : 'Save and Send'}
                                        </Button></Spacer> */}
                                        </>
                                    ) : (
                                        <>
                                            {(currentPhase.phaseToken == "cor-employee-submit" ||
                                                currentPhase.phaseToken == "coa-coaching-employee-submit") && (
                                                <Spacer bottom={5}>
                                                    <CheckBox
                                                        name="makePhaseEnable"
                                                        title={""}
                                                        defaultValues={false}
                                                        options={[
                                                            {
                                                                value: true,
                                                                label: "Submitted on behalf of employee / Physical signature attached",
                                                            },
                                                        ]}
                                                        onClick={() => {
                                                            if (enableCurrentPhase) {
                                                                seEnableCurrentPhase(false);
                                                            } else {
                                                                seEnableCurrentPhase(true);
                                                            }
                                                        }}
                                                    />
                                                </Spacer>
                                            )}
                                            <Button type="primary" onClick={handleSubmit(finishPhase)}>
                                                {"Save"}
                                            </Button>
                                            {((currentPhase.phaseToken != "cor-employee-submit" &&
                                                currentPhase.phaseToken != "coa-coaching-employee-submit") ||
                                                enableCurrentPhase) && (
                                                <Spacer inline left={1}>
                                                    <Button
                                                        type="primary"
                                                        onClick={handleSubmit(finishPhaseWithRefresh)}
                                                    >
                                                        {currentPhase.isLastPhase
                                                            ? "Complete"
                                                            : sendDriverViaWorkbot
                                                            ? "Save and Send"
                                                            : "Save and Submit"}
                                                    </Button>
                                                </Spacer>
                                            )}
                                        </>
                                    )}
                                    {currentPhase.hasPrint === true && isShowPrint && (
                                        <Button type="button" style={{ marginLeft: "5px" }} onClick={printPhase}>
                                            {" "}
                                            <Icon.Printer
                                                size="20px"
                                                style={{ marginBottom: "-5px" }}
                                                color={Theme.colors.secondary}
                                            />{" "}
                                            Generate Document
                                        </Button>
                                    )}
                                    {currentPhase.hasReview === true &&
                                        currentPhase.reviewedPhases.indexOf(currentPhase.id) == -1 && (
                                            <Button
                                                type="primary"
                                                style={{ marginLeft: "5px" }}
                                                onClick={handleSubmit(reviewPhase)}
                                            >
                                                Review & Save
                                            </Button>
                                        )}
                                </>
                            ) : (
                                <>{inc.token == "Eno" ? (
                                    <>
                                    {currentPhase.isCompleted === false && <Button type="primary" onClick={handleSubmit(finishPhaseWithRefresh)}>
                                        {"Save"}
                                    </Button>}
                                    {currentPhase.hasPrint === true && isShowPrint && (
                                        <Button type="button" style={{ marginLeft: "5px" }} onClick={printPhase}>
                                            {" "}
                                            <Icon.Printer
                                                size="20px"
                                                style={{ marginBottom: "-5px" }}
                                                color={Theme.colors.secondary}
                                            />{" "}
                                            Generate Document
                                        </Button>
                                    )}
                                </>
                                ) : (<>
                                    {currentPhase.isPhaseCompleted === true ? (
                                        <>
                                            <Button type="primary" onClick={handleSubmit(updatePhase)}>
                                                {"Save"}
                                            </Button>
                                            <Spacer inline left={1}>
                                                <Button type="primary" onClick={handleSubmit(updatePhasewithRefresh)}>
                                                    {currentPhase.isLastPhase ? "Save and Close" : "Save and Submit"}
                                                </Button>
                                            </Spacer>
                                            {inc.token == "Acc" &&
                                                currentPhase.phaseToken != "acc-driver-tasks" &&
                                                currentPhase.phaseToken != "acc-phase8" && (
                                                    <Spacer inline left={1}>
                                                        <Button type="primary" onClick={() => setRefresh(refresh + 1)}>
                                                            {"Skip"}
                                                        </Button>
                                                    </Spacer>
                                                )}
                                        </>
                                    ) : (
                                        <>
                                            <Button type="primary" onClick={handleSubmit(finishPhase)}>
                                                {"Save"}
                                            </Button>
                                            <Spacer inline left={1}>
                                                <Button type="primary" onClick={handleSubmit(finishPhaseWithRefresh)}>
                                                    {currentPhase.isLastPhase
                                                        ? (inc.token == "Thx" || inc.token == "Eno")
                                                            ? sendDriverViaWorkbot
                                                                ? "Save and Send"
                                                                : "Save and Submit"
                                                            : "Save and Close"
                                                        : "Save and Submit"}
                                                </Button>
                                            </Spacer>
                                            {inc.token == "Acc" &&
                                                currentPhase.phaseToken != "acc-driver-tasks" &&
                                                currentPhase.phaseToken != "acc-phase8" && (
                                                    <Spacer inline left={1}>
                                                        <Button
                                                            type="primary"
                                                            onClick={handleSubmit(skipPhaseWithRefresh)}
                                                        >
                                                            {"Skip"}
                                                        </Button>
                                                    </Spacer>
                                                )}
                                        </>
                                    )}
                                    {currentPhase.hasPrint === true && isShowPrint && (
                                        <Button type="button" style={{ marginLeft: "5px" }} onClick={printPhase}>
                                            {" "}
                                            <Icon.Printer
                                                size="20px"
                                                style={{ marginBottom: "-5px" }}
                                                color={Theme.colors.secondary}
                                            />{" "}
                                            Print
                                        </Button>
                                    )}
                                    {currentPhase.hasReview === true &&
                                        currentPhase.reviewedPhases.indexOf(currentPhase.id) == -1 && (
                                            <Button
                                                type="primary"
                                                style={{ marginLeft: "5px" }}
                                                onClick={handleSubmit(reviewPhase)}
                                            >
                                                Review & Save
                                            </Button>
                                        )}
                                </>)}</>
                            )}
                        </Spacer>
                    )}
                    <Spacer bottom={showTab ? 20 : 4} />
                </Content>
            </>
        </div>
    );
};

const IncidentDateTimeForm = ({ isEdit, setIsEdit }) => {
    const { register, getValues, errors, handleSubmit } = useForm();
    const { incident_date, incident_time } = getValues();
    const [state, dispatch] = useContext(IncidentsContext);

    const inc = {
        station: {},
        driver: {},
        phases: [],
        ...state.incident,
    };

    const editIncidentDateTime = async (data) => {
        let incidentDate = data.incident_date;
        let incidentTime = data.incident_time;
        let dateTime = moment.utc(incidentDate).format("YYYY-MM-DD") + " " + incidentTime + ":00";

        API.updateIncident(dateTime, inc.id).then(
            (response) => {
                setIsEdit(false);
                dispatch({ type: "CHANGE_INCIDENT_DATETIME_VALUE", payload: response.data.data.result });
                toast({
                    title: "Incident Date & Time Updated Successfully",
                    type: "success",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
            },
            (error) => console.log
        );
    };

    return (
        <form name="edit_incident_datetime">
            <Spacer top={3} />
            <Day
                name="incident_date"
                ref={register({ required: true })}
                label="Incident Date"
                size="small"
                defaultValue={moment.utc(inc.utctime.timestamp * 1000).format("YYYY-MM-DD")}
            />
            <Spacer top={3} />
            <NewTimePicker
                ref={register({ required: true })}
                name="incident_time"
                label="Incident Time"
                size="small"
                timeZoneShortName={inc.timezone}
                defaultValue={moment.utc(inc.utctime.timestamp * 1000).format("HH:mm")}
                dateForTimePicker={moment.utc(inc.utctime.timestamp * 1000).format("YYYY-MM-DD")}
            />
            <Spacer top={3} />
            <Button type="primary" size="small" onClick={handleSubmit(editIncidentDateTime)}>
                Save
            </Button>
            <Spacer right={3} inline />
            <Button
                size="small"
                onClick={() => {
                    setIsEdit(false);
                }}
            >
                Cancel
            </Button>
        </form>
    );
};

const IncidentManagement = ({ success, cancel, incidentId, showTab = true }) => {
    return (
        <IncidentsProvider>
            <IncidentForm incidentId={incidentId} success={success} cancel={cancel} showTab={showTab} />
        </IncidentsProvider>
    );
};

const incidentPromise = async (incidentId) => {
    return dialogPromise((success, cancel) => (
        <Modal width={"860px"} visible={true} title={"Incident"} setVisible={cancel}>
            <IncidentManagement success={success} cancel={cancel} incidentId={incidentId} showTab={false} />
        </Modal>
    ));
};

const IncidentFormPage = (...props) => {
    const hist = props[0].history;
    const match = matchPath(hist.location.pathname, {
        path: "/incidents/:id",
        exact: true,
        strict: false,
    });
    const incidentId = match.params.id;
    const promiseHandler = () => {
        hist.push("/incidents");
    };
    return <IncidentManagement incidentId={incidentId} success={promiseHandler} cancel={promiseHandler} />;
};

export { incidentPromise };
export default IncidentFormPage;
