import styled from "styled-components";
import {Button, Theme} from "@dspworkplace/ui";

const Action = styled(Button)`
    padding: 6px 8px;
    vertical-align: top;
    position: relative;
    z-index: 1;

    ${props => props.type === "cancel" ? `
    background-color: ${Theme.colors.neutrals.white};

    &:hover,
    &:focus,
    &:active {
        background-color: ${Theme.colors.neutrals.white};
    }
    ` : ""}

    ${props => props.disabled ? `
    opacity: .5;
    cursor: not-allowed;
    ` : ""}

    ${props => props.compact ? `
    padding: 4px;
    height: 36px;
    width: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ` : ""}

    & + * .just-options {
        padding: 0;
        border: 0;
        width: 36px;
        height: 36px;
        margin-left: -36px;
    }
`;

export {Action};