import React,{useReducer,useEffect,useState} from 'react';
import {useForm} from "react-hook-form";
import {Dropdown, Icon, Input, Spacer, Theme, ThirdTitle, Button} from "@dspworkplace/ui";
import StationSelector,{useSelectedStation} from "../../../../components/StationSelector";
import Loading, {LoadingWrapper} from "../../../../components/Loading";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../../components/Table";
import Empty from '../../../../components/Empty';
import {Status} from '../../../../components/UI';

import {STATUS,STATUS_TAG_CONFIG} from './helper';
import {reducer,initialState} from './reducer';
import API from './api';
import {openMessageForm,openMessageArchiveForm} from './messageForm';
import moment from "moment";
import DatePicker from '../../../../components/Calendar/picker';
import {Action} from "../../../Scheduler/Common/UI";
import {FilterDayAnnouncement} from '../../../../hooks/useGlobalData';
import {openSlidePreview} from "../Slides";

const Message = () => {
    const [selectedStation] = useSelectedStation();
    const [announcementDay] = FilterDayAnnouncement();
    const [state,dispatch] = useReducer(reducer, {matching:{station:selectedStation,dateExecution:announcementDay.start+' – '+announcementDay.end}},initialState);

    useEffect(()=>{
        const includeSegmentLookup = state.filterLookups.segments ? false : true;
        API.fetchList({matching:state.matching,segment: includeSegmentLookup}).then(
            response => {
                let segmentOptions = [];
                if(response.data.data.AnncSegment){
                    segmentOptions = response.data.data.AnncSegment.map((s)=> ({name:`${s.name} (quantity: ${s.employeesQuantity})`,value:s.id}))
                }
                dispatch({type:'addData',payload:{filterOptions:{segments:segmentOptions},data:response.data.data.AnncMessage}})
            },
            error => console.log(error)
        );
    },[state.matching,state.filterLookups])

    useEffect(() => {
        API.fetchMetricVideo().then(
            (response) => {
                dispatch({ type: "addMetricVideo", payload: { data: response.data.data.getMetricVideo } });
            },
            (error) => console.log(error)
        );
    }, []);

    if(state.status === STATUS.PENDING){
        return(
            <LoadingWrapper>
                <Loading style={{height: 80}}/>
            </LoadingWrapper>
        )
    }

    return (
        <div>
            <Filter matching={state.matching} lookup={state.filterLookups}  dispatch={dispatch} />
            <MessageList status={state.status}  messages={state.data} dispatch={dispatch} matching={state.matching} metricVideo={state.metricVideos}/>
        </div>
    );
}

const MessageStatus = Object.keys(STATUS_TAG_CONFIG);
const Filter = ({matching,lookup,dispatch}) => {
    const [selectedDate, setSelectedDate] = FilterDayAnnouncement();
    const {register, handleSubmit,getValues} = useForm({
        mode: "onBlur",
        defaultValues: {
            status: matching.status || ''
        }
    });

    useEffect(()=>{
        const data = getValues();
        if(data.dateExecution != ""){
            setTimeout(()=>handleSubmit(onSearch)(),1)
        }
    },[selectedDate]);

    const onSearch = data => {
        dispatch({
            type: "addMatching",
            payload: data
        });
    };

    let timeout;
    const handleChange = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => handleSubmit(onSearch)(), 750)
    }

    return (
        <Spacer bottom={5} as="form" onSubmit={handleSubmit(onSearch)}>
            <StationSelector
                ref={register}
                name={'station'}
                onChange={() => setTimeout(()=>handleSubmit(onSearch)(),1)}
            />
            <Spacer
                inline={true}
                style={{ position: "relative", display:'inline-flex', alignItems: "flex-end", zIndex: 3 }}
                left={5}
            >
                {/* <Action
                    Type={'button'}
                    onClick={() => {
                        setSelectedDate(moment(selectedDate).subtract(1, "day").format('YYYY-MM-DD'));
                    }}
                    style={{verticalAlign: "bottom"}}
                >
                    <Icon.ArrowLeft
                        size="12px"
                        color={Theme.colors.info.border}
                        style={{ verticalAlign: "text-top" }}
                    />
                </Action> */}
                <DatePicker
                    ref={register}
                    name={'dateExecution'}
                    label={'Execution Date'}
                    onChange={date => {
                        if (date) {
                            const dateRangeArr = date.split(' – ');
                            const startDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[0]) : '';
                            const endDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[1]) : '';
                            if (startDate.isValid() && endDate.isValid()) {
                                setSelectedDate({start:startDate.format('YYYY-MM-DD'),end:endDate.format('YYYY-MM-DD')});
                            }
                        }
                    }}
                    defaultValues={[new Date(selectedDate.start), new Date(selectedDate.end)]}
                    size={'medium'}
                    range={'date'}
                />
                {/* <Action
                    Type={'button'}
                    onClick={() => {
                        setSelectedDate(moment(selectedDate).add(1, "day").format('YYYY-MM-DD'));
                    }}
                    style={{verticalAlign: "bottom"}}
                >
                    <Icon.ArrowRight
                        size="12px"
                        color={Theme.colors.info.border}
                        style={{ verticalAlign: "text-top" }}
                    />
                </Action> */}
            </Spacer>
            <Spacer left={5} top={5} inline/>
            <Dropdown
                name='status'
                ref={register}
                label='Status'
                options={MessageStatus.map((status)=> ({name:status,value:status}))}
                size={'small'}
                onChange={() => {
                    setTimeout(()=>handleSubmit(onSearch)(),1)
                }}
            />
            <Spacer right={5} inline/>
            <Input ref={register} name="title" label="Search" onChange={handleChange}>
                <Icon.Search/>
            </Input>
            {/*<Spacer left={5} top={5} inline/>
            <CustomDropdown
                name='segments'
                ref={register}
                label='Segments'
                options={lookup.segments}
                size={'small'}
                multiple={true}
                onChange={() => setTimeout(()=>handleSubmit(onSearch)(),1)}
            />*/}
        </Spacer>
    )
}

const StatusAction = ({message,dispatch,StatusMessage}) => {
    const [status,setStatus] = useState('initial');

    if(status === 'changingStatus'){
        return <Loading style={{height: 20}}/>;
    }

    const handleStatusChange = (nextStatus) => {
        setStatus('changingStatus');
        API.changeStatus(nextStatus,message.id).then(resolve=>{
            if(resolve){
                message.status = nextStatus;
                dispatch({type:'editMessage',payload:message})
            }
            setStatus('initial');
        })
    }

    if(message.status === 'Active'){
        let nextStatus = 'Paused';
        return <Icon.Pause
            size='20px'
            color={Theme.colors.info.border}
            style={{cursor:'pointer'}}
            onClick={()=>{handleStatusChange(nextStatus)}}
        />
    }

    if(message.status === 'Paused'){
        let nextStatus = 'Active';
        return <Icon.Play
            size='20px'
            color={Theme.colors.info.border}
            style={{cursor:'pointer'}}
            onClick={()=>{handleStatusChange(nextStatus)}}
        />
    }

    return null;
}

const FrequencyRender = ({data}) => {
    switch(data.type.toLowerCase()){
        case 'weekly':
            const days = data.weekly.days.map((d)=> moment().day(d).format('dddd'))
            return  `Every ${data.weekly.interval} week${data.weekly.interval > 1 ? 's' : ''} on ${days.slice(0,-1).join(', ')} and ${days.splice(-1)}`
        break;
        case 'monthly':
            let mdays = data.monthly.days;
            return  mdays ? `Every Month on days ${mdays.slice(0,-1).join(', ')} and ${mdays.splice(-1)}` : null;
            break;
        case 'once':
            return  `Once`
        break;
    }

    return null;
}

const MessageList = ({status,messages,dispatch,matching, metricVideo}) => {
    if(status === STATUS.FILTERING){
        return(
            <LoadingWrapper>
                <Loading style={{height: 40}}/>
            </LoadingWrapper>
        )
    }

    return (
        <Table width={'920px'}>
            <TableHeader
                headers={[
                    {label: 'Status', width: '10%'},
                    {label: 'Title', width: '25%'},
                    {label: 'Created By', width: '15%'},
                    {label: 'Original Dates', width: '15%'},
                    {label: 'Original Frequency', width: '20%'},
                    {label: '', width: '15%'},
                ]}
            />
            {
                messages && messages.length ?
                    messages.map((m,k)=> {

                        const isNew = moment.unix(m.dateExecution.timestamp).utcOffset(0).format('YYYY-MM-DD') != matching.dateExecution;
                        return (
                            <TableRow key={k} style={{flexWrap: 'wrap',background:isNew ? '#00A38D1A' : 'white'}}>
                                <TableData width='10%'>
                                    <Status current={m.status} config={STATUS_TAG_CONFIG}>{m.status}</Status>
                                </TableData>
                                <TableData width='25%'>
                                    {m.title}
                                </TableData>
                                <TableData width='15%'>
                                    {m.user.friendlyName}
                                </TableData>
                                <TableData width='15%' style={{flexDirection:'column',fontSize:13,alignItems:'flex-start',justifyContent:'space-between'}}>
                                    <div>
                                        Start: {moment.unix(m.dateReference.timestamp).utcOffset(0).format('MM/DD/YYYY')}
                                    </div>
                                    {m.dateEnd &&
                                    <div>
                                        End: {moment.unix(m.dateEnd.timestamp).utcOffset(0).format('MM/DD/YYYY')}
                                    </div>
                                    }
                                </TableData>
                                <TableData width='20%'>
                                    {m.frequency && <FrequencyRender data={m.frequency} />}
                                </TableData>
                                <TableData width='15%' style={{justifyContent:'flex-end'}}>
                                    <StatusAction message={m} dispatch={dispatch} />
                                    <Spacer inline right={3}/>
                                    <Icon.Eye
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            openSlidePreview({messageId:m.id,metricVideo}).then(
                                                resolve => {
                                                    console.log('resolve',resolve)
                                                }
                                            )
                                        }}
                                    />
                                    <Spacer inline right={3}/>
                                    <Icon.Edit
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        style={{cursor:'pointer'}}
                                        onClick={()=>
                                            openMessageForm(m.id,metricVideo).then(
                                                resolve => {
                                                    if(resolve) dispatch({type:'editMessage',payload:resolve})
                                                },
                                                error => {
                                                    //do nothing, the component it self validates everything
                                                }
                                            )
                                        }
                                    />
                                    <Spacer inline right={3}/>
                                    <Icon.Archive
                                        size='20px' color={Theme.colors.error.text}
                                        style={{cursor:'pointer'}}
                                        onClick={()=>
                                            openMessageArchiveForm(m.title).then(
                                                resolve => {
                                                    if(resolve){
                                                        API.removeMessage(m.id,resolve.propagate ? true : false).then(
                                                            resolve => {
                                                                dispatch({type:'removeMessage',payload:{id:m.id}})
                                                            },
                                                            error => {
                                                                alert('Something goes wrong, try again or contact support')
                                                            }
                                                        )
                                                    }
                                                }
                                            )
                                        }
                                    />
                                </TableData>
                            </TableRow>
                        );
                    }) :
                    <TableRow  style={{flexWrap: 'wrap'}}>
                        <TableData width='100%' style={{justifyContent:'center'}}>
                            <Empty />
                        </TableData>
                    </TableRow>
            }
            <TableFooter sticky={true}>
                <ThirdTitle style={{ fontWeight: "normal" }}>
                    Total: {messages.length} Message{messages.length > 1 && "s"}
                </ThirdTitle>
                <Button type="primary" size="small" onClick={()=>{
                    openMessageForm(null,metricVideo).then(
                        resolve => {
                            if(resolve) {
                                dispatch({type:'addMessage',payload:resolve})
                            }
                        },
                        error => {
                            //do nothing, the component it self validates everything
                        }
                    )
                }}>
                    New Message
                </Button>
            </TableFooter>
        </Table>
    )
}

export default Message;