
const WorkFlowReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {         
             
        case 'ADD':
            return {
                ...state,
                lists:payload.data,
                company: payload.company,
                isLoading:false,
            };          

        case 'LOADING':
            return {...state,isLoading: payload.status};

        case 'MODAL_VISIBLE':
            return {...state,modalVisible: payload};            

        case "REFRESH":
            return { ...state };
            
            
        default:
            return state;
    }
}
export default WorkFlowReducer
