import moment from "moment";
import {highlight, highlightDriver} from "./index";
import {showErrorToast, showToast} from "../../Utilities";

const LoadOutReducer = (state, action) => {
    var {type, payload, callback} = action;

    let drivers;
    let sendedHome = [];

    if (typeof callback !== "function") callback = () => {
    };

    const addChange = (station, ids) => {
        let stationId = station ? station : state.selectedStation;

        state.changes[stationId] = state.changes[stationId] || [];
        ids.forEach(function (id) {
            if (state.changes[stationId].length > 0) {
                if (!state.changes[stationId].includes(id.toString())) {
                    state.changes[stationId].push(id.toString());
                }
            } else {
                state.changes[stationId].push(id.toString());
            }
        });
    }


    switch (type) {

        case "SET_WAVE_DATA":
            ///drivers = payload.drivers.filter( d => d.clockedOut === false );
            drivers = sortBy(payload.result.drivers, state.sortBy);

            callback(state);

            drivers.forEach(driver => {
                if (driver.dateTimeEnded !== null) {
                    state.sendedHome.push(driver.id);
                }
            });

            let driverWiseRoute = [];
            let routeCodeArr = [];
            payload.result.drivers.map((driver, driverKey) => {
                if(driver?.routes && driver.routes.length > 0){
                    driver.routes.map((route,i)=>{
                        if (routeCodeArr.includes(route)) {
                            let duplicateText = (payload.result.drivers[driverKey].rescue === false) ? " Duplicate!" : "";
                            payload.result.drivers[driverKey].routes[i] = route + duplicateText;
                            return route + duplicateText;
                        } else {
                            routeCodeArr.push(route);
                            return route;
                        }
                    })
                }
                if(driverWiseRoute.hasOwnProperty(String(driver.driverId))) {
                    driverWiseRoute[String(driver.driverId)].push(driver);
                }else{
                    driverWiseRoute[String(driver.driverId)] = [driver];
                }

            });
            let driverStatus = [];
            driverWiseRoute.map((driver,key) => {
                let dr = {isSentHome:false,clockedOut: false,incident: null,punchedIn: false,onBreak: false,breakPunchOut: false,isBackup: false,returned:false,insideStation:false,onRoute:false,isLoadoutCompleted:false,es_name:"",dateTimeEndedTimeZoneConversion:null};
                driver.map((dri) => {
                    if(dr.es_name == ""){dr.es_name = dri.es_name;}
                    if(!dr.isSentHome){dr.isSentHome = dri.isSentHome;}
                    if(!dr.clockedOut){dr.clockedOut = dri.clockedOut;}
                    if(dr.incident == null){dr.incident = dri.incident;}
                    if(dr.dateTimeEndedTimeZoneConversion == null){dr.dateTimeEndedTimeZoneConversion = dri.dateTimeEndedTimeZoneConversion;}
                    if(!dr.punchedIn){dr.punchedIn = dri.punchedIn;}
                    if(!dr.onBreak){dr.onBreak = dri.onBreak;}
                    if(!dr.breakPunchOut){dr.breakPunchOut = dri.breakPunchOut;}
                    if(!dr.returned){dr.returned = dri.returned;}
                    if(!dr.insideStation){dr.insideStation = dri.insideStation;}
                    if(!dr.onRoute){dr.onRoute = dri.onRoute;}
                    if(!dr.isLoadoutCompleted){dr.isLoadoutCompleted = dri.isLoadoutCompleted;}
                });
                driverStatus[String(key)] = dr;
            });

            return {
                ...state,
                waveData: {
                    ...payload.result,
                    shiftTypes: payload.shiftTypes
                },
                driverStatus: driverStatus,
                routeCommitments: payload.result.routeCommitment,
                drivers: drivers,
            };

        case "SET_LOADING":
            return {...state, pageLoader: payload};

        case "SET_APPLIED_FILTERS":
            let count = 0;
            for (var i in payload) payload[i].length > 0 && count++;

            drivers = filterBy(state.waveData.drivers, payload);
            drivers = sortBy(drivers, state.sortBy);

            callback(state);

            return {...state, appliedFilters: count, drivers: drivers};

        case "SET_SORT_BY":
            // let index = state.sortBy.findIndex(
            //     (criteria) => criteria.field == payload.field
            // );
            //
            // if (index >= 0) {
            //     let criteria = state.sortBy[index];
            //
            //     if (criteria.direction == 0) criteria.direction = 1;
            //     else if (criteria.direction == 1) state.sortBy.splice(index, 1);
            // } else {
            //     state.sortBy.push({
            //         field: payload.field,
            //         direction: 0,
            //     });
            // }

            if (state.sortBy[0].field === payload.field)
                state.sortBy[0].direction = state.sortBy[0].direction === 0 ? 1 : 0;
            else
                state.sortBy[0] = {
                    field: payload.field,
                    direction: 0
                }

            drivers = sortBy(state.drivers, state.sortBy);

            callback(state);

            return {...state, drivers: drivers};

        // case "MANUALLY_SORT":
        //     const { from, to } = payload;
        //
        //     var ordered = [...state.drivers];
        //     ordered.splice(to, 0, ordered.splice(from, 1)[0]);
        //
        //     ordered.map((d, k) => {
        //         d.order = k;
        //     });
        //
        //     drivers = sortBy(ordered, []);
        //
        //     callback(state);
        //
        //     return { ...state, drivers: drivers, sortBy: [] };

        case "SET_QUICK_SHIFT_TYPE":
            return {...state, quickShiftType: payload, isBalanceGroup: 0};

        case "SET_BALANCE_GROUP":
            return {...state, quickShiftType: payload, isBalanceGroup: 1};

        case "ADD_CHANGE":
            /* console.log(payload);
             if (payload !== "device" && payload !== "vehicle" && payload !== "assign_route_to_driver") {
                 //state.changes[state.waveData.station].push(new Date().getTime());
                 state.changes = state.changes +1;
             }*/
            callback(state);

            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, changes: state.changes, drivers: drivers};

        case "ADD_OPEN_ROUTE":
            state.waveData.openRoutes.push(payload);
            return {...state};

        case "DROP_OPEN_ROUTE":
            state.waveData.openRoutes.splice(payload.key, 1);
            return {...state};

        case "DROP_MULTIPLE_OPEN_ROUTE":
            const openRoutes = state.waveData.openRoutes.filter(item => !payload.includes(parseInt(item.id)))
            state.waveData.openRoutes = openRoutes;
            return { ...state };

        case "SET_PUBLISH_COUNT":
            if (action.api)
                state.changes = {};
            addChange(action.station, action.ids);
            return {...state}

        case "REMOVE_CHANGES":
            let ch = state.changes[action.station];
            ch = ch.filter(function (item) {
                return item !== action.id.toString()
            });
            state.changes[action.station] = ch;
            return {...state}

        case "FILTER_NOT_PUNCH_IN":
            return {...state};

        case "FILTER_NOT_DEPARTED":
            return {...state};

        case "ASSIGNED_FILTER":
            return {...state, quickShiftType: payload};

        case "UNASSIGNED_FILTER":
            return {...state, quickShiftType: payload};

        case "SET_PUNCH_TIME":
            let notPunchedIn = 0;
            let notDeparted = 0;

            state.drivers.forEach(driver => {
                notPunchedIn += +(driver.punchedIn === false);
                notDeparted += +(!driver.clockedOut && driver.onRoute);

                if (driver.id === payload.driverRoute) {
                    if (payload.punchIn)
                        driver.punchedIn = moment.utc(payload.punchIn.date).local().format('h:mma');

                    if (payload.breakPunchOut && !payload.onBreak)
                        driver.onBreak = moment.utc(payload.breakPunchOut.date).local().format('h:mma');

                    if (payload.punchOut)
                        driver.clockedOut = moment.utc(payload.punchOut.date).local().format('h:mma');

                    highlightDriver(driver.id);
                }
            });

            if (state.waveData.notPunchedIn !== notPunchedIn)
                highlight('counter-not-punched-in');

            state.waveData.notPunchedIn = notPunchedIn;

            if (state.waveData.notDeparted !== notDeparted)
                highlight('counter-not-departed');

            state.waveData.notDeparted = notDeparted;

            return {...state};

        case "SET_BAG":
            state.drivers.forEach(driver => {
                if (driver.id === payload.driver.id) {
                    driver.hasBag = payload.driver.hasBag;
                    highlightDriver(driver.id);
                }
            });
            return {...state};

        case "SET_SHIFT_TYPE":
            state.drivers.forEach(driver => {
                if (driver.id != payload.driverRoute)
                    return;

                driver.shiftType = payload.shiftType;
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        case "UPDATE_RESCUER":
            state.drivers.forEach(driver => {
                if (driver.id != payload.id)
                    return;

                driver.dateTimeEnded = null;
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        case "UPDATE_ROUTE_CODES":
            state.drivers.forEach(driver => {
                if (driver.id === payload.shift.routeId) {
                    let tempRouteCode = [];
                    let tempStagingLocation = [];
                    let tempStagingRefrence = [];
                    if (payload.newShift) {
                        tempRouteCode = driver.routes.filter((r) => {
                            return !(payload.newShift.routeCode.indexOf(r) > -1)
                        });
                        let stagingLocationArr = driver.stagingLocation.split(',');
                        tempStagingLocation = stagingLocationArr.filter((r) => {
                            return !(payload.newShift.stagingLocation.indexOf(r) > -1)
                        });
                        let stagingRefrenceArr = (driver.stagingReference && driver.stagingReference.length > 0 )?driver.stagingReference.split(','):[];
                        tempStagingRefrence = stagingRefrenceArr.filter((r) => {
                            return !(payload.newShift.stagingReference.indexOf(r) > -1)
                        });
                        tempStagingLocation = tempStagingLocation.join(',');
                        tempStagingRefrence = tempStagingRefrence.length > 0 ? tempStagingRefrence.join(',') : null;
                    } else {
                        tempRouteCode = payload.shift.routeCode;
                        tempStagingLocation = payload.shift.stagingLocation;
                        tempStagingRefrence = payload.shift.stagingReference;
                    }
                    driver.stagingLocation = tempStagingLocation
                    driver.stagingReference = tempStagingRefrence
                    driver.routes = tempRouteCode
                    return driver;
                }

                if (payload.newShift && driver.id === payload.newShift.routeId) {
                    driver.stagingLocation = payload.newShift.stagingLocation;
                    driver.stagingReference = payload.newShift.stagingReference;
                    driver.routes = payload.newShift.routeCode;
                    return driver
                }
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        case "REFRESH":
            return {...state};

        case "UPDATE_SENT_HOME":
            state.drivers.forEach(driver => {
                if (driver.id === payload.id) {
                    driver.dateTimeEndedTimeZoneConversion = payload.displayTime;
                    driver.dateTimeEnded = payload.time;
                    driver.isLoading = true;
                    return driver;
                }
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        case "UPDATE_IS_LOADING":
            state.drivers.forEach(driver => {
                if (driver.id === payload.id) {
                    driver.isLoading = true;
                    return driver;
                }
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        case "SET_INCIDENT_TYPES":
            return {...state, incidentTypes: payload};

        case "REMOVE_DRIVER_ROUTE":
            let driverArray = [];
            state.drivers.forEach(driver => {
                if (driver.id == payload.id)
                    return;
                driverArray.push(driver);
            });
            drivers = sortBy(driverArray, state.sortBy);

            return {...state, drivers: drivers};

        case "UPDATE_ROUTE_CODE_BOX":
            if (payload == 'asign') {
                state.routeCommitments.codes = (state.routeCommitments.codes - 1);
                state.waveData.balanceGroups[0].routes = (state.waveData.balanceGroups[0].routes - 1);
                state.waveData.balanceGroups[0].drivers = (state.waveData.balanceGroups[0].drivers - 1);
            } else if ('open-route') {
                state.waveData.balanceGroups[0].routes = (state.waveData.balanceGroups[0].routes - 1);
                state.waveData.balanceGroups[0].drivers = (state.waveData.balanceGroups[0].drivers - 1);
            } else {
                state.waveData.balanceGroups[1].drivers = (state.waveData.balanceGroups[1].drivers - 1);
            }

            return {...state};

        case "UPDATE_LOADOUT_DATE":
            return {...state, selectedDate: payload};


        case "SET_KEY_VALUE":
            for (let k in payload)
                if (state.hasOwnProperty(k))
                    state[k] = payload[k];

            return {...state};

        case "UPDATE_ROUTE_STATUS":
            state.drivers.forEach(driver => {
                if (driver.id != payload.driverRoute)
                    return;

                driver.routeStatus = payload.routeStatus;
                driver.isVoluntary = payload.isVoluntary;
                driver.backupStatus = payload.backupStatus;
                driver.onCall = payload.onCall;
            });
            drivers = sortBy(state.drivers, state.sortBy);

            return {...state, drivers: drivers};

        default:
            return state;
    }
};

export default LoadOutReducer;

const filterBy = (drivers, criterias) => {
    if (!drivers.length) return [];

    if (criterias.schedules && criterias.schedules.length) {
        drivers = drivers.filter((d) => {
            return criterias.schedules.indexOf(`${d.schedule}`) >= 0;
        });
    }

    if (criterias.shiftTypes && criterias.shiftTypes.length) {
        drivers = drivers.filter((d) => {
            return criterias.shiftTypes.indexOf(`${d.shiftType.id}`) >= 0;
        });
    }

    if (criterias.skills && criterias.skills.length) {
        drivers = drivers.filter((d) => {
            return d.skills.some(function (skill) {
                return this.skills.includes(`${skill}`);
            }, criterias);
        });
    }

    if (criterias.drivers) {
        drivers = drivers.filter((d) => {
            let phones = "";
            if (d.devices.length)
                phones = d.devices.reduce((all, next) => `${all}-${next}`);

            let str = `${d.name}-${d.title}-${d.email}-${phones}`;
            str = str.toLocaleLowerCase().replace(/\s/g, "-");

            let search = criterias.drivers.toLocaleLowerCase().replace(/\s/g, "-");

            return str.match(search) !== null;
        });
    }

    if (criterias.clockedOut === false)
        drivers = drivers.filter((d) => d.clockedOut === false);

    if (criterias.ncns === false)
        drivers = drivers.filter((d) => d.ncns === false);

    return drivers;
};

const sortBy = (drivers, criterias) => {
    if (!drivers.length) return [];

    if (criterias === null || !criterias.length) {
        drivers.sort((f, s) => {
            return f.order - s.order;
        });

        drivers = groupDrivers(drivers);

        return drivers;
    }

    const getStatusPoints = (d) => {
        return (
            d.isSentHome
                ? 'Send Home' //1
                : //sent home
                d.incident && d.incident.type === 'NCNS'
                    ? 'NCNS' //1
                    : //ncns
                    d.incident && d.incident.type === 'Call Out'
                        ? 'Call Out' //10
                        : // call in
                        d.incident && d.incident.type === 'Late'
                            ? 'Late' //10
                            : // late
                            d.incident && d.incident.type === 'Accident/Injury'
                                ? 'Accident/Injury' //10
                                : // Accident/Injury
                                d.punchedIn === false && d.punchedOut === false && d.onBreak === false && d.breakPunchOut === false
                                    ? 'No data' //10
                                    : // not data
                                    (d.punchedIn && d.clockedOut === false && d.onBreak && d.breakPunchOut === false)
                                        ? 'Out to Lunch' //6
                                        : // Out Lunch
                                        d.punchedIn && d.clockedOut === false && d.onBreak && d.breakPunchOut
                                            ? 'In from Lunch' //6
                                            : // In Lunch
                                            d.clockedOut
                                                ? 'Punched Out' //2
                                                : // Punched Out
                                                d.returned
                                                    ? 'Returned to Station' //3
                                                    : // Returned to Station
                                                    d.punchedIn === false
                                                        ? 'Not Punched In' //10
                                                        : // Not Punched In
                                                        d.insideStation
                                                            ? 'Inside Station' //9
                                                            : // on route
                                                            d.onRoute
                                                                ? 'On Route' //5
                                                                : // on break
                                                                d.isProcessCompleted
                                                                    ? 'Ready to Stage' //8
                                                                    : 'Punched In'
        );
    };

    try {
        criterias.reverse().map((c) => {
            let d = c.direction;
            Array.prototype.makeOne = function () {
                return this.sort((a, b) => a.localeCompare(b, undefined, {numeric: true})).join('');
            }
            drivers.sort((f, s) => {
                f.sp = getStatusPoints(f);
                switch (c.field) {
                    case "shiftType":
                        return d === 0
                            ? f.shiftType.name.localeCompare(s.shiftType.name)
                            : s.shiftType.name.localeCompare(f.shiftType.name);
                    case "status":
                        return d === 0
                            ? getStatusPoints(f).localeCompare(getStatusPoints(s))
                            : getStatusPoints(s).localeCompare(getStatusPoints(f))
                    case "routes":
                        return d === 0
                            ? f.routes.makeOne().localeCompare(s.routes.makeOne(), undefined, {numeric: true})
                            : s.routes.makeOne().localeCompare(f.routes.makeOne(), undefined, {numeric: true})
                    case "vehicle":
                        if (!f.vehicle.unit)
                            f.vehicle.unit = '';

                        if (!s.vehicle.unit)
                            s.vehicle.unit = '';

                        return d === 0
                            ? f.vehicle.unit.localeCompare(s.vehicle.unit, undefined, {numeric: true})
                            : s.vehicle.unit.localeCompare(f.vehicle.unit, undefined, {numeric: true})
                    case "stagingLocation":
                        if (!f.stagingLocation) {
                            f.stagingLocation = '';
                        }
                        if (!s.stagingLocation) {
                            s.stagingLocation = '';
                        }
                        if (!f.stagingReference) {
                            f.stagingReference = '';
                        }
                        if (!s.stagingReference) {
                            s.stagingReference = '';
                        }

                        return d === 0
                            ? [f.stagingReference+f.stagingLocation].makeOne().localeCompare([s.stagingReference+s.stagingLocation], undefined, {numeric: true})
                            : [s.stagingReference+s.stagingLocation].makeOne().localeCompare([f.stagingReference+f.stagingLocation], undefined, {numeric: true});
                    case "devices":
                        return d === 0
                            ? f.devices.map(d => d.name).makeOne().localeCompare(s.devices.map(d => d.name), undefined, {numeric: true})
                            : s.devices.map(d => d.name).makeOne().localeCompare(f.devices.map(d => d.name), undefined, {numeric: true})
                    default:
                        if (!f[c.field]) {
                            f[c.field] = '';
                        }
                        if (!s[c.field]) {
                            s[c.field] = '';
                        }
                        return d === 0
                            ? [f[c.field]].makeOne().localeCompare([s[c.field]], undefined, {numeric: true})
                            : [s[c.field]].makeOne().localeCompare([f[c.field]], undefined, {numeric: true});
                }
            });
        });

    } catch (e) {
        showToast({type: 'error', title: 'It looks like there is a sorting error.'}).then()
    }
    drivers = groupDrivers(drivers);

    return drivers;
};

const groupDrivers = (drivers) => {
    drivers.forEach((d, i) => {
        if (!d.priorShiftRouteId)
            return;

        let parent = drivers.findIndex(dr => parseInt(dr.id) === parseInt(d.priorShiftRouteId))


        if (parent === -1)
            return;

        drivers.splice(parent + 1, 0, drivers.splice(i, 1)[0]);
    });

    return drivers;
}
