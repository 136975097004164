import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";

const useOutsideClick = (ref, callback, event) => {
    const handleClick = useCallback(e => {

        if (ref.current && !ref.current.contains(e.target)) {
            callback(e);
        }

    }, [ref, callback])

    useEffect(() => {
        document.addEventListener(event ? event : "click", handleClick);

        return () => {
            document.removeEventListener(event ? event : "click", handleClick);
        };
    }, [event, handleClick]);
};

const Text = styled.div`
    font-family: circe-rounded, serif; 
    justify-content: flex-end;
    display: flex;
    margin-right: 27px;
    margin-top: -10px;
    font-weight: 300;
    font-size: 12px;
    position: relative;
`
const Dots = styled.div`
    font-size: 28px;
    line-height: 0;
    letter-spacing: -3px;
    margin-top: 1px;
    cursor: pointer;
`
const RecipientBox = styled.div`
    position: absolute;
    max-height: 200px;
    width: 200px;
    border-radius: 3px;
    background: white;
    top: 16px;
    right: -2px;
    z-index: 1;
    box-shadow: 0 1px 6px #00000029;
`
const TabHeader = styled.div`
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #D3D3D3;
`
const Tab = styled.div`
    cursor: pointer;
    width: 100%;
    color: #0071BC;
    font-family: circe-rounded;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Separator = styled.div`
    border-right: 1px solid #D3D3D3;
`
const ListContainer = styled.div`
    padding: 5px 10px;
    max-height: 180px;
    overflow-y: scroll;
`

const ListItem = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`


type Props = {
    index: number;
    lastReadMessageIndexes?: {}
};

const ReadReceiptText: React.FC<Props> = ({ lastReadMessageIndexes = {}, index }) => {

    const [showReadRecipients, setShowReadRecipients] = useState(false);
    const [tabName, setTabName] = useState('read');
    const readRef = useRef();

    const keys = Object.keys(lastReadMessageIndexes || {}) || [];

    const open = () => {
        setShowReadRecipients(!showReadRecipients)
    }

    useOutsideClick(readRef, () => {
        setShowReadRecipients(false)
    }, 'mouseup');

    if (keys.length === 1) {
        return (<Text>
            {
                lastReadMessageIndexes[keys?.[0]]?.lastConsumedMessageIndex &&
                    lastReadMessageIndexes[keys?.[0]]?.lastConsumedMessageIndex >= index ?
                    'read' : 'unread'}
        </Text>)
    } else if (keys.length > 1) {
        let count = 0;
        let readList: string[] = [];
        let unreadList: string[] = []
        keys.forEach((key) => {
            if (lastReadMessageIndexes[key]?.lastConsumedMessageIndex &&
                lastReadMessageIndexes[key]?.lastConsumedMessageIndex >= index) {
                count = count + 1;
                readList.push(lastReadMessageIndexes[key]?.name)
            } else {
                unreadList.push(lastReadMessageIndexes[key]?.name)
            }
        })
        const text = `Read by ${count} of ${keys?.length} | `
        return (<Text ref={readRef}>
            {text}
            <Dots onClick={open}>...</Dots>
            {showReadRecipients &&
                <RecipientBox>
                    <TabHeader>
                        <Tab
                            style={{ background: tabName === 'read' ? "#EAF0F6" : "#ffffff" }}
                            onClick={() => setTabName('read')}
                        >
                            Read
                        </Tab>
                        <Separator></Separator>
                        <Tab
                            style={{ background: tabName === 'unread' ? "#EAF0F6" : "#ffffff" }}
                            onClick={() => setTabName('unread')}
                        >Unread</Tab>
                    </TabHeader>
                    <ListContainer>
                        {(tabName === 'read' ? readList : unreadList).map((item) => (
                            <ListItem>{item}</ListItem>
                        ))}
                    </ListContainer>
                </RecipientBox>}
        </Text>)
    } else {
        return null
    }
}

export default ReadReceiptText;