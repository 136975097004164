import React,{useReducer,useEffect} from 'react';
import {Icon,Spacer, Theme, ThirdTitle, Button} from "@dspworkplace/ui";
import Loading, {LoadingWrapper} from "../../../../components/Loading";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../../components/Table";
import {Tag} from "../../../../components/UI";
import Empty from '../../../../components/Empty';

import {openSlidePreview} from '../Slides';
import {STATUS} from './helper';
import {reducer,initialState} from './reducer';
import API from './api';
import {openTemplateForm,openTemplateArchiveForm} from "./templateForm";

import {SlideEditor} from "../Slides/Editor";

const Template = () => {
    const [state,dispatch] = useReducer(reducer, {},initialState);

    useEffect(()=>{
        API.fetchTemplates({}).then(
            response => {
                //openSlidePreview();
                dispatch({type:'addData',payload:{data:response.data.data.AnncTemplate}})
            },
            error => console.log(error)
        );
    },[state.matching,state.filterLookups])

    useEffect(() => {
        API.fetchMetricVideo().then(
            (response) => {
                dispatch({type:'addMetricVideo',payload:{data:response.data.data.getMetricVideo}})
            },
            (error) => console.log(error)
        );
    },[])

    if(state.status === STATUS.PENDING){
        return(
            <LoadingWrapper>
                <Loading style={{height: 80}}/>
            </LoadingWrapper>
        )
    }

    return (
        <div>
            {/*<SlideEditor width={'780px'} />*/}
            <TemplateList  templates={state.data} dispatch={dispatch} metricVideo={state.metricVideos}/>
        </div>
    );
}

const TemplateList = ({templates,dispatch, metricVideo}) => {
    return (
        <Table width={'1024px'}>
            <TableHeader
                headers={[
                    {label: 'Name', width: '45%'},
                    {label: 'Tags', width: '30%'},
                    {label: 'Slides', width: '10%'},
                    {label: '', width: '15%'},
                ]}
            />
            {
                templates && templates.length ?
                    templates.map((t,k)=> (
                            <TableRow key={k} style={{flexWrap: 'wrap'}}>
                                <TableData width='45%'>
                                    {t.name}
                                </TableData>
                                <TableData width='30%'>
                                    {t.tags && t.tags.map((tag,k)=> <Tag style={{marginRight:4}} key={k}>{tag}</Tag>)}
                                </TableData>
                                <TableData width='10%'>
                                    {t.slidesQuantity}
                                </TableData>
                                <TableData width='15%' style={{justifyContent:'flex-end'}}>
                                    <Spacer inline right={3}/>
                                    <Icon.Eye
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            openSlidePreview({templateId:t.id,metricVideo:metricVideo}).then(
                                                resolve => {
                                                    console.log('resolve',resolve)
                                                }
                                            )
                                        }}
                                    />
                                    <Spacer inline right={3}/>
                                    <Icon.Edit
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        style={{cursor:'pointer'}}
                                        onClick={()=>{
                                            openTemplateForm(t.id).then(
                                                resolve => {
                                                    if(resolve) {
                                                        dispatch({type:'editTemplate',payload:resolve})
                                                    }
                                                },
                                                error => {
                                                    //do nothing, the component it self validates everything
                                                }
                                            )
                                        }}
                                    />
                                    <Spacer inline right={3}/>
                                    <Icon.Archive
                                        size='20px' color={Theme.colors.error.text}
                                        style={{cursor:'pointer'}}
                                        onClick={()=>{
                                            openTemplateArchiveForm(t.name).then(
                                                resolve => {
                                                    if(resolve){
                                                        API.removeTemplate(t.id).then(
                                                            resolve => {
                                                                dispatch({type:'removeTemplate',payload:{id:t.id}})
                                                            },
                                                            error => {
                                                                alert('Something goes wrong, try again or contact support')
                                                            }
                                                        )
                                                    }
                                                }
                                            )
                                        }}
                                    />
                                </TableData>
                            </TableRow>
                    )) :
                    <TableRow  style={{flexWrap: 'wrap'}}>
                        <TableData width='100%' style={{justifyContent:'center'}}>
                            <Empty />
                        </TableData>
                    </TableRow>
            }
            <TableFooter sticky={true}>
                <ThirdTitle style={{ fontWeight: "normal" }}>
                    Total: {templates.length} Template{templates.length > 1 && "s"}
                </ThirdTitle>
                <Button type="primary" size="small" onClick={()=>{
                    openTemplateForm().then(
                        resolve => {
                            if(resolve) {
                                dispatch({type:'addTemplate',payload:resolve})
                            }
                        },
                        error => {
                            //do nothing, the component it self validates everything
                        }
                    )
                }}>
                    New Template
                </Button>
            </TableFooter>
        </Table>
    )
}

export default Template;