import React from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {MileageVerificationType, useStoreReducer} from "./store";
import {Button} from '@dspworkplace/ui';
import styled from "styled-components";
import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";

export const CheckboxesContainerMileageType = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    height: 40px;

    input.name {
        width: 320px;
        margin-right: 32px;
    }

    input.checked {
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.forDriver {
        margin-left: 64px;
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.hide {
        display: none;
    }
`;

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: MileageVerificationType;
    };
    type: number;
    updateItem: (data: any) => void;
    scenario: string;
}

function MileageVerificationQuestionForm(props: Props) {
    const {appState} = useStoreReducer();
    const {item, updateItem, scenario} = props;
    const {
        name,
        description,
        isPassRequired,
        isPhotoRequired,
        notifyIfChosen,
        vehicleImageCategories,
        loadOutMilesCheck,
        rtsMilesCheck,
        disabledQuestion
    } = item.content;
    const {register, handleSubmit, reset, watch, control} = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            isPhotoRequired,
            notifyIfChosen,
            loadOutMilesCheck, rtsMilesCheck,
            vehicleImageCategories: Array.isArray(vehicleImageCategories) && vehicleImageCategories.length > 0 ? vehicleImageCategories : appState.vehicleImageCategories,
            disabledQuestion
        }
    });
    const isPhotoRequiredWatch = watch("isPhotoRequired");
    const {fields} = useFieldArray<VehicleImageCategory>({
        name: "vehicleImageCategories",
        control
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            <label>Is required to Pass?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            <label>Require picture</label>
            <input name="isPhotoRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {scenario === 'LOAD_OUT' && <><label htmlFor="loadOutMilesCheck">Load Out Miles Check</label>
                <input name="loadOutMilesCheck" inputMode={"numeric"} ref={register({
                    required: true,
                    min: 1,
                })}/></>}
            {scenario === 'RTS' && <><label htmlFor="rtsMilesCheck">RTS Miles Check</label>
                <input name="rtsMilesCheck" inputMode={"numeric"} ref={register({
                    required: true,
                    min: 1,
                })}/></>}
            {isPhotoRequiredWatch && <div className={'choices'}>Vehicle Image Category</div>}
            <div>
                {isPhotoRequiredWatch && fields.map((field, index) => {
                    return (
                        <CheckboxesContainerMileageType key={field.id}>
                            <input
                                className={'name'}
                                name={`vehicleImageCategories[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                                disabled={true}
                            />
                            <input
                                className={'hide'}
                                name={`vehicleImageCategories[${index}].id`}
                                defaultValue={field.id}
                                ref={register()}
                                disabled={true}
                                hidden={true}
                            />
                            <input
                                name={`vehicleImageCategories[${index}].checked`}
                                type="checkbox"
                                className={'checked'}
                                defaultChecked={!!field.checked}
                                ref={register()}/>
                        </CheckboxesContainerMileageType>
                    );
                })}
            </div>
            {!isPhotoRequiredWatch && <div/>}
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default MileageVerificationQuestionForm;