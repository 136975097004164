import styled from "styled-components";
import React from "react";
import Logo from '../../assets/logo.svg';

const Image = styled.img`
    width: 300px;
    margin: 0 auto;
    display: block;
    height: auto;
`;

const NavImg = styled.img`
    height:32px;
    width:auto;
`;

const host = window.location.host;
const subdomain = host.split('.')[0];
const DmgImage = 'https://s3.amazonaws.com/images.dspworkplace.com/delivery_management_group.png';

const Final = subdomain === 'dmg' ? DmgImage : Logo;

const MainImage = () => {
    return <Image src={Final} />
}

const NavImage = () => {
    return <NavImg src={Final} />
}

export {MainImage,NavImage};