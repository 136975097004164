import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Theme, Title, Spacer, Text, Button, Icon } from '@dspworkplace/ui';
import Form from "./form";
import Modal, { useModal } from "../../../../components/Modal";
import Loading, { LoadingWrapper, useLoading } from "../../../../components/Loading";
import moment from 'moment';
import 'moment-timezone';
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../Auth";
import { covertUtcToLocal } from "../../../../components/TimeFormat";
import { Tag } from "../../../../components/UI";
import { confirm } from "../../../../components/Confirm";
import Skeleton from "../../../../components/Skeleton";
import { alert } from "../../../../components/Alert";
import { AccessCheck, IsGranted } from "../../../../security";

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(340px, 340px) );;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

const Card = styled.div`
    box-sizing: border-box;
    background:${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px #33333326;
    width: 340px;
    // height: 212px;
    border-radius:${Theme.defaultRadius};
    opacity: 1;
    position: relative;
`;

const Row = styled.div`
    box-sizing: border-box;
    vertical-align: middle;
    display: flex;
    // flex-wrap: wrap;
`;

const Col1 = styled.div`
    box-sizing: border-box;
    width: 24px;
    margin-right: 8px;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Circle = styled.div`
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    background-color: #00BDA5;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    opacity: 1;
    text-align: center;
    line-height: 24px;
`;

const Col2 = styled.div`
    box-sizing: border-box;
    vertical-align: middle;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const BoxTextTitle = styled.div`
    box-sizing: border-box;
    text-align: left;
    color: #516F90;
    opacity: 1;
    //height: 16px;
    width:50%;
    font-size: 16px;
    font-weight: normal;
    flex-grow: 2;
    font-family: ${Theme.font.main};
`;

const BoxTextParagraph = styled.div`
    box-sizing: border-box;
    text-align: left;
    opacity: 1;
    height: 16px;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    z-index: 3;

    &:before{
        position: absolute;
        content: attr(data-tooltip);
        pointer-events: none;
        opacity: 0;
        left:0;
        top:25px;
        font-size:14px;
        background:#EAF0F6;
        color:#777777;
        padding:4px;
        border-radius:4px;
        overflow: visible;
        text-overflow: none;
        white-space: normal;
    }

    &:hover {
        &:before {
            opacity:1;
        }
    }
`;

const BoxTextIcon = styled.div`
    box-sizing: border-box;
    text-align: center;
    width: 16px;
    height: 16px;
`;

const BoxTextButtonTitle = styled.div`
    box-sizing: border-box;
    text-align: left;
    color: #516F90;
    opacity: 1;
    height: 12px;
    font-size: 12px;
    font-weight: 500;
`;

const ShiftTypes = (localState = '') => {
    const useLocationState = useLocation().state;
    const { pathname, state } = useLocation();
    const [shiftTypes, setShiftTypes] = useState();
    const [data, setShiftTypesData] = useState({ update: -1, shiftTypes: [] });
    const station = (useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station;
    // const [loading, setLoading] = useState(false);
    const [isLoading, setLoading] = useLoading();
    const [edit, setEdit] = useState({});
    const [copy, setCopy] = useState({});
    const [visible, toggleModal] = useModal(false);
    const { api } = useAuth();    
    const timeZoneName = {
        'ET': 'America/Detroit',
        'CT': 'America/Chicago',
        'MT': 'America/Denver',
        'PT': 'America/Los_Angeles',
        'AKT': 'US/Alaska',
        'HT': 'Pacific/Honolulu',
        'AZ': 'America/Phoenix',
        'CET': 'Europe/Amsterdam'
    };
    let isDST = moment().tz(timeZoneName[station.timezone]).isDST();

    const getShiftTypes = async (skillRate) => {
        const params = {
            "actions": {
                "response": {
                    "Shift": {
                        "custom": {
                            "functionName": "getShiftsForShiftModule",
                            "criteria": {
                                "station": station.id,
                            },
                            "get": "Shifts",
                            "excludes": ["company", "shift"],
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "startTime",
                                "3": "endTime",
                                "4": "unpaidBreak",
                                "5": "note",
                                "6": "color",
                                "7": "category",
                                "8": "startTimeOnDst",
                                "9": "endTimeOnDst",
                                "10" : "countInWeekViewAsDriver",
                                "rateRule": ["name"],
                                "invoiceType": ["id", "name"],
                                "skill": ["id", "name"],
                                "balanceGroup": ["id", "name"]
                            }
                        },
                    }
                }
            }
        };
        try {
            let response = await api.post('/api/lazy/manage/data', params);

            return new Promise((resolve, reject) => {

                let shifts = response.data.data.Shifts.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
                setShiftTypes(shifts);
                setShiftTypesData({ ...data, shiftTypes: shifts });
                setLoading(false);
            });
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    };

    const createShiftTypes = async (newShiftType) => {
        toggleModal(false);
        setLoading(false);
        // setShiftTypesData({shiftTypes:[...data.shiftTypes,newShiftType]})
        getShiftTypes();
    };
    const editShiftTypes = async ({ idList, ...tempData }) => {
        if (tempData && tempData.edit) {
            data.shiftTypes.map((shiftType, key) => {
                if (shiftType.id == tempData.id) {
                    const copyState = [...data.shiftTypes];
                    delete tempData.edit;
                    copyState[key] = tempData;
                    // setShiftTypesData({shiftTypes:copyState})
                }
            });
            getShiftTypes();
            // setShiftTypesData({shiftTypes:[...data.shiftTypes,tempData]})
            toggleModal(false);
            setEdit({});
            setLoading(false);
        } else {
            setEdit(tempData);

            const params = {
                "actions": {
                    "response": {
                        "Shift": {
                            "custom": {
                                "functionName": "getShiftByStation",
                                "get": "Shifts",
                                "criteria": {
                                    "station": station.id
                                },
                                "excludes": ["company", "shift", "balanceGroup"],
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "startTime",
                                    "3": "endTime",
                                    "4": "unpaidBreak",
                                    "5": "note",
                                    "6": "color",
                                    "7": "startTimeOnDst",
                                    "8": "endTimeOnDst",
                                    "rateRule": ["name"],
                                    "invoiceType": ["id", "name"],
                                    "skill": ["id", "name"],
                                    "balanceGroup": ["id", "name"]
                                }
                            }
                        }
                    }
                }
            };
            try {
                let response = await api.post('/api/lazy/manage/data', params);

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let all_station = Object.assign([], response.data.data.shifts);
                        all_station[idList] = tempData;
                        setShiftTypes(all_station);
                        toggleModal(false);
                        setLoading(false);
                    }, 10)
                });
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
    };
    const deleteShiftTypes = async (shiftType, key) => {

        const confirmation = await confirm('Confirm that you want to delete this item');
        if (!confirmation) {
            return false;
        }


        let getDriverRoutes = {
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "getRoutesFromShiftAndSkill",
                            "criteria": {
                                "id": shiftType.id,
                                "type": "shift"
                            },
                            "get": "routes"
                        }
                    }
                }
            }
        };

        let shiftResponse = await api.post('/api/lazy/manage/data', getDriverRoutes);
        if (shiftResponse.data.data.routes.length > 0) {
            let messageString = '';
            shiftResponse.data.data.routes.map((item, key) => {
                messageString = messageString + '<tr><td style="border: 1px solid #e6d8d8;padding:5px;">' + item.schedule + '</td></tr> ';
            });
            messageString = 'This Shift Type is related with future Schedule(s). So you must remove this Shift Type from the Schedule(s) below before delete it.</br></br></br><table  style="width:100%;border-collapse: collapse;margin-top: 13px;"><tr><th style="border: 1px solid #e6d8d8;padding:5px;">Schedule</th></tr>' + messageString + '</table><br/>';
            alert(messageString);
            return false;
        }

        data.shiftTypes.splice(key, 1);
        setShiftTypesData({ ...data, shiftTypes: data.shiftTypes });

        let params = {
            "actions": {
                "delete": {
                    "Shift": {
                        "delete_shift_1": {
                            "findOneBy": {
                                "id": shiftType.id
                            }
                        }
                    }
                },
            }
        };
        try {
            await api.post('/api/lazy/manage/data', params);
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    };

    const copyShiftTypes = async ({ idList, ...tempData }) => {
        if (tempData && tempData.copy) {
            data.shiftTypes.map((shiftType, key) => {
                if (shiftType.id == tempData.id) {
                    const copyState = [...data.shiftTypes];
                    delete tempData.copy;
                    copyState[key] = tempData;
                    // setShiftTypesData({shiftTypes:copyState})
                }
            });
            getShiftTypes();
            toggleModal(false);
            setCopy({});
            setLoading(false);
        } else {
            setCopy(tempData);

            const params = {
                "actions": {
                    "response": {
                        "Shift": {
                            "custom": {
                                "functionName": "getShiftByStation",
                                "get": "Shifts",
                                "criteria": {
                                    "station": station.id
                                },
                                "excludes": ["company", "shift", "balanceGroup"],
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "startTime",
                                    "3": "endTime",
                                    "4": "unpaidBreak",
                                    "5": "note",
                                    "6": "color",
                                    "7": "textColor",
                                    "8": "startTimeOnDst",
                                    "9": "endTimeOnDst",
                                    "rateRule": ["name"],
                                    "invoiceType": ["id", "name"],
                                    "skill": ["id", "name"],
                                    "balanceGroup": ["id", "name"]
                                }
                            }
                        }
                    }
                }
            };
            try {
                let response = await api.post('/api/lazy/manage/data', params);

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        let all_station = Object.assign([], response.data.data.shifts);
                        all_station[idList] = tempData;
                        setShiftTypes(all_station);
                        toggleModal(false);
                        setLoading(false);
                    }, 10)
                });
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
    };

    useEffect(() => {
        if (!shiftTypes) {
            setLoading(true);
            getShiftTypes();
        }
    });

    return (
        <>
            <Spacer top={5} />
            <Title>Shift Types</Title>
            <Spacer top={1} />
            <Text>Create shift types to manage required skill levels, start/end times, and other settings.</Text>
            <Spacer top={5} />
            <Wrap>
                <IsGranted feature={AccessCheck.features.ST_SHIFT_TYPES} ops={AccessCheck.OPS.U}>
                <Card>
                    <Spacer top={5} right={4} bottom={5} left={4} style={{ opacity: .5 }}>
                        <Row>
                            <Col1>
                                <Skeleton width={24} height={24} style={{ borderRadius: '50%' }} animation={1} />
                            </Col1>
                            <Col2>
                                <BoxTextTitle>
                                    <Skeleton width={100} animation={1} />
                                </BoxTextTitle>
                                <div>
                                    <Icon.Copy size='20px' color={Theme.colors.info.border} />
                                    <Spacer right={2} inline />
                                    <Icon.Edit size='20px' color={Theme.colors.info.border} />
                                    <Spacer right={2} inline />
                                    <Icon.Times size='20px' color={Theme.colors.info.border} />
                                </div>
                            </Col2>
                        </Row>
                        <Row>
                            <Col1>
                            </Col1>
                            <Col2>
                                <BoxTextParagraph>
                                    <Skeleton width={40} animation={1} />
                                </BoxTextParagraph>
                            </Col2>
                        </Row>
                        <Spacer top={4} />
                        <Row>
                            <Col1>
                                <BoxTextIcon>
                                    <Icon.Balance size='16px' color={Theme.colors.info.border} />
                                </BoxTextIcon>
                            </Col1>
                            <Col2>
                                <BoxTextParagraph>
                                    <Skeleton width={140} animation={1} />
                                </BoxTextParagraph>
                            </Col2>
                        </Row>
                        <Spacer top={1} />
                        <Row>
                            <Col1>
                                <BoxTextIcon>
                                    <Icon.Dollar size='16px' color={Theme.colors.info.border} />
                                </BoxTextIcon>
                            </Col1>
                            <Col2>
                                <BoxTextParagraph>
                                    <Skeleton width={160} animation={1} />
                                </BoxTextParagraph>
                            </Col2>
                        </Row>
                        <Spacer top={1} />
                        <Row>
                            <Col1>
                                <BoxTextIcon>
                                    <Icon.Clock size='16px' color={Theme.colors.info.border} />
                                </BoxTextIcon>
                            </Col1>
                            <Col2>
                                <BoxTextParagraph>
                                    <Skeleton width={60} animation={1} />
                                </BoxTextParagraph>
                            </Col2>
                        </Row>
                        <Spacer top={1} />
                        <Row>
                            <Col1>
                                <BoxTextIcon>
                                    <Icon.Notes size='16px' color={Theme.colors.info.border}></Icon.Notes>
                                </BoxTextIcon>
                            </Col1>
                            <Col2>
                                <Skeleton width={160} animation={1} />
                            </Col2>
                        </Row>
                        <Spacer top={4} />
                        <Row>
                            <Col1 />
                            <Col2>
                                <BoxTextButtonTitle>
                                    Skills
                                </BoxTextButtonTitle>
                            </Col2>
                        </Row>
                        <Spacer top={1} />
                        <Row>
                            <Col1 />
                            <Col2>
                                <Skeleton width={60} height={24} animation={1} />
                            </Col2>
                        </Row>
                    </Spacer>
                    <Button
                        type='primary'
                        onClick={() => {
                            setEdit({});
                            setCopy({});
                            toggleModal(true);
                        }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: 3
                        }}
                    >Add New Shift Type</Button>
                </Card>
                </IsGranted>    
                {data.shiftTypes && data.shiftTypes.length > 0 ?
                    data.shiftTypes.map((shiftType, key) => (
                        <Card key={key}>
                            <Spacer top={5} right={4} bottom={5} left={4}>
                                <Row>
                                    <Col1>
                                        <Circle style={{ background: shiftType.color }}>
                                            {shiftType.category === 2 && <Icon.Replace size='16px' color={Theme.colors.neutrals.white} />}
                                            {shiftType.category === 3 && <Icon.GraduationHat size='16px' color={Theme.colors.neutrals.white} />}
                                        </Circle>
                                    </Col1>
                                    <Col2>
                                        <BoxTextTitle>
                                            {shiftType.name}
                                        </BoxTextTitle>
                                        <div >
                                            <IsGranted feature={AccessCheck.features.ST_SHIFT_TYPES} ops={AccessCheck.OPS.U}>
                                            {(shiftType.category === 1) && <Icon.Copy size='20px' color={Theme.colors.info.border} onClick={() => { copyShiftTypes(shiftType, key) }} />}
                                            <Spacer right={2} inline />
                                            <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => { editShiftTypes(shiftType, key) }} />
                                            </IsGranted>
                                            <IsGranted feature={AccessCheck.features.ST_SHIFT_TYPES} ops={AccessCheck.OPS.D}>
                                            <Spacer right={2} inline />
                                            {(shiftType.category === 1) && <Icon.Times size='20px' color={Theme.colors.info.border} onClick={() => { deleteShiftTypes(shiftType, key) }} />}
                                            </IsGranted>
                                        </div>
                                    </Col2>
                                </Row>
                                <Row>
                                    <Col1 />
                                    <Col2>
                                        {
                                            isDST ?
                                            (
                                                <>
                                                    <BoxTextParagraph>
                                                        {covertUtcToLocal(shiftType.startTimeOnDst, station.timezone)} – {covertUtcToLocal(shiftType.endTimeOnDst, station.timezone)}
                                                    </BoxTextParagraph>
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <BoxTextParagraph>
                                                        {covertUtcToLocal(shiftType.startTime, station.timezone)} – {covertUtcToLocal(shiftType.endTime, station.timezone)}
                                                    </BoxTextParagraph>
                                                </>
                                            )
                                        }

                                    </Col2>

                                </Row>
                                <Spacer top={4} />
                                <Row>
                                    <Col1>
                                        <BoxTextIcon>
                                            <Icon.Balance size='16px' color={Theme.colors.info.border} />
                                        </BoxTextIcon>
                                    </Col1>
                                    <Col2>
                                        <BoxTextParagraph>
                                            {shiftType.balanceGroup ? shiftType.balanceGroup.name : '-'}
                                        </BoxTextParagraph>
                                    </Col2>
                                </Row>
                                {shiftType.invoiceType && <>
                                    <Spacer top={1} />
                                    <Row>
                                        <Col1>
                                            <BoxTextIcon>
                                                <Icon.Dollar size='16px' color={Theme.colors.info.border} />
                                            </BoxTextIcon>
                                        </Col1>
                                        <Col2>
                                            <BoxTextParagraph>
                                                {shiftType.invoiceType.name}
                                            </BoxTextParagraph>
                                        </Col2>
                                    </Row> </>}
                                <Spacer top={1} />
                                <Row>
                                    <Col1>
                                        <BoxTextIcon>
                                            <Icon.Clock size='16px' color={Theme.colors.info.border} />
                                        </BoxTextIcon>
                                    </Col1>
                                    <Col2>
                                        <BoxTextParagraph>
                                            {shiftType.unpaidBreak}m
                                        </BoxTextParagraph>
                                    </Col2>
                                </Row>
                                <Spacer top={1} />
                                <Row>
                                    <Col1>
                                        <BoxTextIcon>
                                            {shiftType.category && <Icon.Card size='16px' color={Theme.colors.info.border} />}
                                        </BoxTextIcon>
                                    </Col1>
                                    <Col2>
                                        <BoxTextParagraph>
                                            {shiftType.category === 1 && <> Normal Category</>}
                                            {shiftType.category === 2 && <> Backup Category</>}
                                            {shiftType.category === 3 && <> Training Category</>}
                                            {shiftType.category === 8 && <> Time Off</>}
                                        </BoxTextParagraph>
                                    </Col2>
                                </Row>
                                <Spacer top={1} />
                                <Row>
                                    <Col1>
                                        <BoxTextIcon>
                                            <Icon.Notes size='16px' color={Theme.colors.info.border} />
                                        </BoxTextIcon>
                                    </Col1>
                                    <Col2>
                                        <BoxTextParagraph data-tooltip={shiftType.note}>
                                            {!shiftType.note && '–'}
                                            {shiftType.note && shiftType.note.length > 40 ? shiftType.note.slice(0, 40) + '...' : shiftType.note}
                                        </BoxTextParagraph>
                                    </Col2>
                                </Row>
                                <Spacer top={4} />
                                <Row>
                                    <Col1 />
                                    <Col2>
                                        <BoxTextButtonTitle>Skills</BoxTextButtonTitle>
                                    </Col2>
                                </Row>
                                <Spacer top={1} />
                                <Row>
                                    <Col1 />
                                    <Col2>
                                        <Tag>{shiftType.skill ? shiftType.skill.name : ''}</Tag>
                                    </Col2>
                                </Row>
                            </Spacer>
                        </Card>
                    )) : null
                }
            </Wrap>

            <Modal
                title={(edit && edit.id) ? 'Edit Shift Type' : (copy && copy.id) ? 'Copying Shift Type' : 'New Shift Type'}
                width='500px'
                visible={visible}
                setVisible={() => { setEdit({}); toggleModal(); setCopy({}); }}
            >
                {isLoading && <LoadingWrapper><Loading style={{ height: '80px' }} /></LoadingWrapper>}
                <Form toggleModal={toggleModal} setLoading={setLoading} station={station} edit={edit} copy={copy} action={{ create: createShiftTypes, edit: editShiftTypes, copy: copyShiftTypes }} />
            </Modal>
        </>

    );
};

export default ShiftTypes;
