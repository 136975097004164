import React from 'react';
import {
    TagInput,
    Icon,
    ThirdTitle,
    Spacer,
    Text,
    Input,
    Theme,
    ButtonGroup,
    Button,
    ButtonDropdown
} from "@dspworkplace/ui";
import {useForm} from 'react-hook-form'
import styled from 'styled-components';
import WithDragAndDrop, {DragItem} from "../../../../components/dragHandler";
import {Card, CardDrag} from "../../../../components/UI";
import {dialogPromise, portalPromise} from "../../../../components/Dialog";
import {DOCUMENT_STATUS} from "../../../Scorecard/schema";
import toast from "../../../../components/Toasts/toast";
import Modal from "../../../../components/Modal";

const CustomTagContainer = styled.form`
    >div {
        width:100%;
        >div {
            width:100%;
        }
    }
`

const MembersSelect = ({
    chartConfig, updater, options, columnOrder, setColumnOrder, tabIndex, ...props
}) => {
    const {register,reset,handleSubmit} = useForm({mode:'onChange'});

    const onSubmit = (data) => {
        const newMemberKey = data.newMemberSelect[0];
        const member = options[newMemberKey];

        if (member) {
            if (typeof member.value === 'object') {
                updater.add(member.value);
            } else {
                updater.set(member.value, 'asc');
            }
            reset();
            const tempColumns = columnOrder ? { ...columnOrder } : {};
            let tableOrder = tempColumns?.tableOrder ?? [];
            let detailTableOrder = tempColumns?.detailTableOrder ?? [];
            if (tabIndex === 1 && tempColumns?.tableOrder) {
                tableOrder.push(member?.value?.name)
                tempColumns.tableOrder = tableOrder
                setColumnOrder(tempColumns)
            } else if (tabIndex === 2 && tempColumns?.detailTableOrder) {
                detailTableOrder.push(member?.value?.name)
                tempColumns.detailTableOrder = detailTableOrder
                setColumnOrder(tempColumns)
            }
        }

    }

    return (
        <CustomTagContainer>
            <TagInput
                {...props}
                name={'newMemberSelect'}
                ref={register}
                options={options.map((opt,key)=>({name:opt.value.description || opt.name,value:key.toString()}))}
                onChange={()=>setTimeout(()=>handleSubmit(onSubmit)(),1)}
                singleOption={true}
            />
        </CustomTagContainer>

    );
};

const DropArea = styled.div`
    max-width:300px;
    width:100%;
    padding:12px;
`

const DropMemberList = WithDragAndDrop(({
    title, members, selectedMembers, updateMembers, chartConfig,
    allowRenameLabel = false, updateQuery, queryKey, itemLimit = 9999,
    columnOrder, setColumnOrder, tabIndex
}) => {
    let singularTitle = title[title.length-1] === 's' ? title.slice(0,title.length-1) : title;

    const [chartConf,dispatchChartConfig] = chartConfig

    const removeValue = (e, m) => {
        e.stopPropagation();
        updateMembers.remove(m);
        const tempColumns = columnOrder ? { ...columnOrder } : {};
        let tableOrder = tempColumns?.tableOrder ?? [];
        let detailTableOrder = tempColumns?.detailTableOrder ?? [];
        if (tabIndex === 1 && tempColumns?.tableOrder) {
            let indexToRemove = tableOrder.indexOf(m?.name);
            if (indexToRemove !== -1) {
                tableOrder.splice(indexToRemove, 1);
            }
            tempColumns.tableOrder = tableOrder
            setColumnOrder(tempColumns)
        } else if (tabIndex === 2 && tempColumns?.detailTableOrder) {
            let indexToRemove = detailTableOrder.indexOf(m?.name);
            if (indexToRemove !== -1) {
                detailTableOrder.splice(indexToRemove, 1);
            }
            tempColumns.detailTableOrder = detailTableOrder
            setColumnOrder(tempColumns)
        }
    }

    return (
        <div style={{width:'300px'}}>
            <ThirdTitle>{title}</ThirdTitle>
            <Spacer top={2}/>
            <Card className={'memberSelect'} style={{height:'auto',boxShadow:'1px 2px 6px #33333326'}}>
                {selectedMembers &&
                    <div style={{display:'grid',rowGap:12}}>
                        {selectedMembers?.filter((item)=>!item.error).map((m,k)=>{
                            const fieldLabel = (chartConf && chartConf.labels) ? chartConf.labels[m.name] : null;
                            return (
                                <CardDrag key={k} onClick={()=>{
                                    allowRenameLabel &&
                                    RenameWidget(m.name,m.shortTitle,fieldLabel).
                                        then(data =>{
                                            if(data){
                                                const label = {}
                                                label[data.key] = data.label;
                                                dispatchChartConfig({action:'SET_LABELS', labels:label})
                                            }
                                        })
                                }}>
                                    <Text>{m.description || m.shortTitle} {allowRenameLabel && fieldLabel && `(Label: ${fieldLabel})` }</Text>
                                    <div
                                        onClick={(e)=> {removeValue(e,m)}}
                                        style={{position:'absolute',right:-8,top:-8,background:Theme.colors.error.bg,cursor:'pointer',borderRadius:'100%'}}
                                    >
                                        <Icon.Times size={'16px'} color={Theme.colors.error.text} style={{position:'relative',top:1}} />
                                    </div>
                                </CardDrag>
                            )
                        })}
                    </div>
                }

                {selectedMembers.length < itemLimit &&
                    <>
                        {selectedMembers && <Spacer top={4} />}
                        <MembersSelect
                            options={members}
                            updater={updateMembers}
                            size={'small'}
                            placeholder={`Add ${singularTitle}`}
                            chartConfig={chartConfig}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                            tabIndex={tabIndex}
                        />
                    </>
                }

                {itemLimit <= 0 &&
                    <Text>This widget type does not support {title} </Text>
                }


            </Card>
        </div>
    )
})

const RenameWidget = (field,fieldName,label) => {
    return portalPromise((success, cancel)=>(
        <Modal
            visible={true}
            closeOnOverlay={true}
            setVisible={cancel}
            title={`Renaming ${fieldName}`}
            width={'340px'}
        >
            <RenameForm success={success} field={{name:fieldName,key:field,label:label}} />
        </Modal>
    ))
}

const RenameForm = ({success,field}) => {
    const {register,handleSubmit} = useForm({defaultValues:{field:field.label}})

    const onSubmit = (data) => {
        field.label = data.field;
        success(field)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input name={'field'} ref={register} label={'New Label'}  />
            <Spacer top={4} />
            <Button type={'primary'} style={{marginLeft:'auto',display:'block'}}>Save</Button>
        </form>
    )
}

const OrderMemberList = WithDragAndDrop(({title,availableMembers,selectedMembers,updateQuery,updateOrder,schema}) => {
    const moveMember = React.useCallback((dragIndex,hoverIndex)=>{
        if(!selectedMembers) return;
        const selectedMembersCopy = [...selectedMembers];
        const DraggedItem = selectedMembersCopy[dragIndex];
        selectedMembersCopy.splice(dragIndex,1);
        selectedMembersCopy.splice(hoverIndex,0,DraggedItem);

        updateQuery({order:selectedMembersCopy})
    }, [updateQuery, selectedMembers])

    if(selectedMembers) {
        if(typeof selectedMembers === 'string'){
            selectedMembers = [[selectedMembers,'none']]
        }
        if(!selectedMembers.length){
            const member = Object.keys(selectedMembers)[0]
            if(member){
                const memberOrder = Object.values(selectedMembers)[0]
                selectedMembers = [[member,memberOrder]]
            }
        }
    }

    const availableMembersFiltered = React.useMemo(()=>{
        if(!availableMembers.length){
            return [{text:'Select any dimension, measure or time dimension to order',fn:()=>{}}]
        }

        return availableMembers.filter((m)=>{
            return !selectedMembers?.find(sm => sm[0] === m.id)
        }).map((m)=>({text:m?.title?.replace(`${schema} `,''),fn:()=>{
            updateOrder.set(m.id)
        }}))
    },[availableMembers,selectedMembers])


    return (
        <DropArea style={{maxWidth:'400px',paddingLeft:0}}>
            <ThirdTitle>{title}</ThirdTitle>
            <Spacer top={5} />
            <ButtonDropdown
                text='Add Order'
                divider={false}
                options={availableMembersFiltered}
            />

            <Spacer top={2} >
                    <div style={{display:'grid',rowGap:12}}>
                        {selectedMembers && selectedMembers.map((m,k)=>{
                            const [member,memberOrder] = m;
                            const memberTitle = availableMembers.find(m => m.id === member)?.title
                            return (
                                <DragItem id={member} key={member} type={title} index={k} onMove={moveMember}>{({previewRef,dragRef}) => (
                                    <div ref={previewRef}>
                                        <CardDrag style={{justifyContent:'space-between'}}>
                                            <Spacer inline right={4}>
                                                <div ref={dragRef}>
                                                    <Icon.Move className={'dragger'} size={'16px'}  />
                                                </div>
                                            </Spacer>
                                            <Spacer inline right={4}>
                                                <Text>{memberTitle?.replace(`${schema} `,'')}</Text>
                                            </Spacer>
                                            {<OrderGroupBtn
                                                order={memberOrder}
                                                orderMember={member}
                                                updateQuery={updateQuery}
                                                currentMemberIndex={k}
                                                selectedMembers={selectedMembers}
                                            />}
                                        </CardDrag>
                                    </div>
                                )}

                                </DragItem>
                            )
                        })}
                    </div>

                </Spacer>


        </DropArea>
    )
})

const ORDER_LOOKUP = {
    asc:0,
    desc:1,
    none:2
}
const OrderGroupBtn = ({order='none',orderMember,updateQuery, selectedMembers, currentMemberIndex}) => {
    const [active, setActive] = React.useState(ORDER_LOOKUP[order]);

    const handleOrder = React.useCallback((index, cubeOrder) => {
        setActive(index)
        if (!selectedMembers) return;
        const selectedMembersCopy = [...selectedMembers];
        selectedMembersCopy[currentMemberIndex] = [orderMember, cubeOrder]
        if (cubeOrder === 'none') {
            selectedMembersCopy.splice(currentMemberIndex, 1);
        }
        updateQuery({ order: selectedMembersCopy })
    }, [updateQuery, selectedMembers])

    return (
        <ButtonGroup
            type={'delete'}
            size={'small'}
            active={active}
        >
            <Button
                track={0}
                onClick={()=>handleOrder(0,'asc')}
            >
                ASC
            </Button>
            <Button
                track={1}
                onClick={()=>handleOrder(1,'desc')}
            >
                DESC
            </Button>
            <Button
                track={2}
                onClick={()=>handleOrder(2,'none')}
            >
                NONE
            </Button>
        </ButtonGroup>
    )
}

const LimitInput = ({querylimit,limit,onChange}) => {
    const [value,setValue] = React.useState(querylimit || limit);

    return (
        <Input
            label="Max Result."
            type="number"
            onChange={(e) => {
                const v = e?.target?.value
                setValue(v)
            }}
            onBlur={()=>{
                if(value <= limit){
                    onChange(value)
                }
                if(value < 0){
                    setValue(0)
                    onChange(0)
                }
            }}
            value={value}
            error={value > limit && 'Max Result can not be bigger then '+limit}
            size={'small'}
        />
    )
}

export {MembersSelect, DropMemberList,OrderMemberList,LimitInput};