import React from 'react'
import { Message } from 'stream-chat-react'
import { useTrackedState, useUpdate as useBotDispatch } from './Store';

export default function BotMessage({ currentMessage }) {
    const state = useTrackedState();
    const { message } = state;
    const dispatch = useBotDispatch();

    return (
        <div style={{ marginTop: '15px' }} >
            <Message {...message} highlighted={false} message={currentMessage} messageActions={[]} groupStyles={['bottom']} />
        </div>
    )
}
