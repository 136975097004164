import React from "react";
import {
    AtomicBlockUtils
} from "draft-js";
import {getYoutubeId,videoBasePath} from '../../../Editor/components/video';

const embedVideoPlugin = (config) => {

    const store = {
        getEditorState: undefined,
        setEditorState: undefined
    };

    return {
        initialize({getEditorState,setEditorState}){
            store.setEditorState = setEditorState;
            store.getEditorState = getEditorState;
        },
        handlePastedText:(text,html,editorState)=>{
            const youtubeId = getYoutubeId(text);
            if(!youtubeId) return 'not-handled';

            const videoUrl = videoBasePath['youtube']+youtubeId;
            const VideoEntity = editorState.getCurrentContent().createEntity('draft-js-video-plugin-video','IMMUTABLE',{src:videoUrl,type:'youtube'});
            const VideoEntityKey = VideoEntity.getLastCreatedEntityKey();
            const newState = AtomicBlockUtils.insertAtomicBlock(editorState,VideoEntityKey,' ');
            store.setEditorState(newState);

            return 'handled';
        }
    }
};

export default embedVideoPlugin;

