import React from "react"
import {alert} from "../Alert";
// import {baseUrl} from "../../Auth";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log('GLOBAL EXCEPTION ERROR', error)
        console.log('GLOBAL EXCEPTION ERROR INFO', errorInfo)
    }

    render() {
        if (this.state.hasError) {
            alert({
                text: 'An error has occurred. Please try again. If the issue continues please contact support@dspworkplace.com',
                icon: true
            }).then(
                success => {
                    console.log({success});
                    // window.location.href = baseUrl + '/dashboard';
                    window.location.reload()
                }
            )
            return false;
        }

        return this.props.children;
    }
}

export default ErrorBoundary