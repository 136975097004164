import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CheckBox,
  Spacer,
  Text
} from "@dspworkplace/ui";
import { Table, TableData, TableHeader, TableRow } from "../../components/Table";
import ComboBox from "../../components/ComboBox";

const sortDrivers = (drivers, sortCriteria) => {
  sortCriteria.forEach(c => {
    const d = c.direction;
    drivers.sort((a, b) => {
      if (d === 0) {
        if (a[c.field] && b[c.field]) {
          return Date.parse('1970/01/01 ' + a[c.field].slice(0, -2) + ' ' + a[c.field].slice(-2)) - Date.parse('1970/01/01 ' + b[c.field].slice(0, -2) + ' ' + b[c.field].slice(-2))
        }
      } else {
        if (a[c.field] && b[c.field]) {
          return Date.parse('1970/01/01 ' + b[c.field].slice(0, -2) + ' ' + b[c.field].slice(-2)) - Date.parse('1970/01/01 ' + a[c.field].slice(0, -2) + ' ' + a[c.field].slice(-2))
        }
      }
    });
  });
  return drivers;
}
const RADriverNotification = ({ driversData = [], updateDriversList, driversList }) => {
  const { register, getValues, setValue, reset } = useForm()
  const [startTimes, setStartTimes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [driverListsState, setDriverListsState] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const oldSelections = useRef({
    oldStartTimeSelection: [],
    oldShiftTypeSelection: []
  })

  useEffect(() => {
    const shiftData = [];
    const startTime = [];
    const tempDriversData = [...driversData];
    (tempDriversData || []).forEach((driver) => {
      if (driver?.shiftType && !shiftData.find(item => item.id === driver?.shiftType?.id)) {
        shiftData.push({ ...driver?.shiftType, value: driver?.shiftType?.id })
      }
      if (!startTime.find(item => item.value === driver.startTime)) {
        startTime.push({
          name: driver.startTime,
          value: driver.startTime,
        })
      }
    })
    setShiftTypes(shiftData)
    setStartTimes(startTime)
    tempDriversData.sort((a, b) => a.name.localeCompare(b.name))
    resetAll()
    setDriverListsState(driversData)

    return () => {
      resetAll()
    };
  }, [driversData])

  const resetAll = () => {
    updateDriversList([])
    setValue('shiftType', [])
    setValue('startTime', [])
    driversData.forEach((d) => {
      delete d.isSelected
    })
    driverListsState.forEach((d) => {
      delete d.isSelected
    })
    reset()
  }

  const sortByField = field => {
    const tempSortBy = [...sortBy]
    const index = tempSortBy.findIndex(criteria => criteria.field === field);
    if (index >= 0) {
      let criteria = tempSortBy[index];
      if (criteria.direction === 0)
        criteria.direction = 1;
      else if (criteria.direction === 1)
        tempSortBy.splice(index, 1);
    } else {
      tempSortBy.push({
        field: field,
        direction: 0
      });
    }
    setSortBy(tempSortBy)
    const sorted = sortDrivers(driverListsState, tempSortBy);
    moveSelectedDriverToTop(sorted)
  };

  const handleStartTimeChange = (value) => {
    const startTimeNotInNewValue = oldSelections.current.oldStartTimeSelection.filter(oldST => !value.includes(oldST));
    oldSelections.current.oldStartTimeSelection = value || [];
    const tempDriversList = [...driversList]
    driverListsState.forEach((d) => {
      const existingInListIndex = tempDriversList.findIndex(od => d?.driverId === od?.driverId)
      if (existingInListIndex < 0 && value.includes(d.startTime)) {
        tempDriversList.push(d)
        setValue(`${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`, true)
        d.isSelected = true
      }
      const removeFromListIndex = tempDriversList.findIndex(od => (startTimeNotInNewValue.includes(od.startTime) &&
        d?.driverId === od?.driverId && !getValues('shiftType').includes(od?.shiftType?.id)))
      if (removeFromListIndex >= 0) {
        tempDriversList.splice(removeFromListIndex, 1)
        setValue(`${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`, false)
        d.isSelected = false
      }
    })
    updateDriversList(tempDriversList)
    if (tempDriversList.length === driverListsState.length) {
      setValue('selectAllDrivers', true)
    } else {
      setValue('selectAllDrivers', false)
    }
    moveSelectedDriverToTop(driverListsState)
  }

  const handleShiftTypeChange = (value) => {
    const shiftTypeNotInNewValue = oldSelections.current.oldShiftTypeSelection.filter(oldST => !value.includes(oldST));
    oldSelections.current.oldShiftTypeSelection = value || [];
    const tempDriversList = [...driversList]
    driverListsState.forEach((d) => {
      const existingInListIndex = tempDriversList.findIndex(od => d?.driverId === od?.driverId)
      if (existingInListIndex < 0 && value.includes(d.shiftType?.id)) {
        tempDriversList.push(d)
        setValue(`${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`, true)
        d.isSelected = true
      }
      const removeFromListIndex = tempDriversList.findIndex(od => (shiftTypeNotInNewValue.includes(od.shiftType?.id) &&
        d?.driverId === od?.driverId && !getValues('startTime').includes(od?.startTime)))
      if (removeFromListIndex >= 0) {
        tempDriversList.splice(removeFromListIndex, 1)
        setValue(`${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`, false)
        d.isSelected = false
      }
    })
    updateDriversList(tempDriversList)
    if (tempDriversList.length === driverListsState.length) {
      setValue('selectAllDrivers', true)
    } else {
      setValue('selectAllDrivers', false)
    }
    moveSelectedDriverToTop(driverListsState)
  }

  const handleSelectAll = () => {
    driverListsState.forEach(item => {
      setValue(`${item?.driverId}-${item?.startTime}-${item?.shiftType?.id}`, getValues().selectAllDrivers)
      item.isSelected = getValues().selectAllDrivers
    })
    updateDriversList(driverListsState);
    moveSelectedDriverToTop(driverListsState)
  };

  const handleDriverCheckBox = (d) => {
    const checkBoxId = `${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`
    const value = getValues(checkBoxId)
    const tempDriversList = [...driversList]
    const driverIndex = driverListsState.findIndex(dd => d?.driverId === dd?.driverId)
    const index = tempDriversList.findIndex(od => `${od?.driverId}-${od?.startTime}-${od?.shiftType?.id}` === checkBoxId);
    if (value) {
      if (index === -1) {
        tempDriversList.push(d)
        if (driverListsState[driverIndex]) { driverListsState[driverIndex].isSelected = true }
      }
      if (tempDriversList.length === driverListsState.length) {
        setValue('selectAllDrivers', true)
      }
      updateDriversList(tempDriversList);
    } else {
      setValue('selectAllDrivers', false)
      if (index >= 0) {
        tempDriversList.splice(index, 1)
        if (driverListsState[driverIndex]) { driverListsState[driverIndex].isSelected = false }
      }
      updateDriversList(tempDriversList);
    }
    moveSelectedDriverToTop(driverListsState)
  }

  const moveSelectedDriverToTop = (data = []) => {
    const tempData = [...data].sort((x, y) => {
      return (x.isSelected === y.isSelected) ? 0 : x.isSelected ? -1 : 1;
    });
    setDriverListsState(tempData)
  }

  return (
    <form>
      <div style={{ display: 'flex' }}>
        <ComboBox
          name='startTime'
          ref={register}
          label='Start Times'
          multiple={true}
          input={false}
          options={startTimes}
          size='small'
          onChange={handleStartTimeChange}
          visibleOptionsQty={4}
        />
        <Spacer inline right={3} />
        <ComboBox
          name='shiftType'
          ref={register}
          label='Shift Type'
          multiple={true}
          input={false}
          options={shiftTypes}
          size='small'
          type='shiftIcon'
          onChange={handleShiftTypeChange}
          visibleOptionsQty={4}
        />
      </div>
      <div>
        <Table width="auto" style={{ maxHeight: '510px', overflowY: 'scroll' }}>
          <TableHeader
            headers={[
              {
                label: <CheckBox
                  name={`selectAllDrivers`}
                  ref={register}
                  onClick={handleSelectAll}
                  options={[{ label: "", value: true }]}
                />,
                width: "30px"
              },
              { label: 'Driver Name', width: '150px' },
              { label: 'Start Time', width: '150px', sortBy: "startTime" },
              { label: 'Shift Type', width: '150px' },
            ]}
            sortByFunc={sortByField}
            sortedBy={sortBy}
          />
          {driverListsState.map(d =>
            <TableRow key={d?.driverId}>
              <TableData width={'30px'}>
                <CheckBox
                  name={`${d?.driverId}-${d?.startTime}-${d?.shiftType?.id}`}
                  ref={register}
                  options={[{ label: '', value: true }]}
                  onClick={() => handleDriverCheckBox(d)}
                >
                </CheckBox>
              </TableData>
              <TableData width={'150px'}>
                <Text>{d.name}</Text>
              </TableData>
              <TableData width={'150px'}>
                <Text>{d.startTime}</Text>
              </TableData>
              <TableData width={'150px'}>
                <Text>{d.shiftType?.name}</Text>
              </TableData>
            </TableRow>
          )}
        </Table>
      </div>
    </form>
  )

}

export default RADriverNotification