import {TableData} from "../../components/Table";
import Driver from "../../components/People";
import {Dropdown, Icon, Spacer, Text, Theme} from "@dspworkplace/ui";
import DriverTag from "../../components/Tag";
import {Link} from "react-router-dom";
import {Link as InternalLink} from "../../components/UI";
import AllowToChat from "./allowToChat";
import {AccessCheck, IsGranted} from "../../security";
import Tooltip from "../../components/Tooltip";
import React ,{useState, useEffect} from "react";
import { canEditCompanyEmployee } from "../Scheduler/Common/AccessCheck";

let FullRole = [
    "ROLE_OWNER",
    "ROLE_OPERATIONS_ACCOUNT_MANAGER",
    "ROLE_OPERATIONS_MANAGER",
    "ROLE_FLEET_MANAGER",
    "ROLE_HR_ADMINISTRATOR",
    "ROLE_CENTRAL_DISPATCHER",
    "ROLE_STATION_MANAGER",
    "ROLE_ASSISTANT_STATION_MANAGER"
];
let defaultRoles = ["ROLE_DISPATCHER", "ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE", "ROLE_STATION_MANAGER", "ROLE_ASSISTANT_STATION_MANAGER"];

let employeeStatusArr = [
    {value: '-2', name: 'Terminated'},
    {value: '-1', name: 'Offboarded'},
    {value: '0', name: 'Inactive'},
    {value: '1', name: 'Onboarding'},
    {value: '2', name: 'Active'},
];


export const EmployeeRow = ({keyVal, employee, modalHandler, handleUpdateEmployees,canAddEmployees, rolePermissionLevel}) => {

    const [currentUserHasRequireRole, setCurrentUserHasRequireRole] = useState(false);    

    useEffect(()=>{
        setCurrentUserHasRequireRole((FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1) || (defaultRoles.indexOf(localStorage.getItem("currentUserRole")) > -1));
    },[]);

    return (
        <li>
            <TableData width="200px">
                <Driver
                    name={employee.name ? employee.name : employee.friendlyName}
                    jobTitle={employee.job}
                    img={employee.picture}
                />
            </TableData>
            <TableData width="160px">
                <Text>{employee.transporterID}</Text>
            </TableData>
            <TableData width="200px">
                <DriverTag options={employee.skills}/>
            </TableData>
            <TableData width="170px" center={1} style={{flexDirection: "column"}}>
                {employee.schedules &&
                employee.schedules.map((value, index) => {
                    return (
                        <Link to={'/company-management/station/' + employee.stations[0].id + '/schedules/' + value.id} key={index} style={{ display: "block", padding: "2px 0" }}>
                            <InternalLink as="span">{value.name}</InternalLink>
                        </Link>
                    );
                })}
            </TableData>
            <TableData width="150px">
                <Dropdown
                    name='employee_status'
                    placeholder='Select Employee Status'
                    options={(canAddEmployees || employee.employeeStatus == 2)?employeeStatusArr:employeeStatusArr.filter((s)=> s.value != 2)}
                    defaultValue={employee.employeeStatus.toString()}
                    size='small'
                    onChange={v => modalHandler(employee, 'empStatus', v, keyVal)}
                    disabled={!canEditCompanyEmployee()}
                />
            </TableData>
            {/* <AllowToChat employee={employee} handleUpdateEmployees={handleUpdateEmployees} keyVal={keyVal}/> */}
            {canEditCompanyEmployee() ? <AllowToChat employee={employee} handleUpdateEmployees={handleUpdateEmployees} keyVal={keyVal}/> : <TableData width="160px"><Text style={{paddingLeft:20}}>{employee.allowToChat ? 'On' : 'Off'}</Text></TableData>}
            <TableData width="auto" style={{marginLeft: "auto"}}>
                <Tooltip content={'Timeline'}>
                    <Spacer inline={true} right={3}>
                        <Icon.TimeLine size={'14px'} color={Theme.colors.info.border}
                                    onClick={() => modalHandler(employee.id, 'timeline', keyVal)}/>
                    </Spacer>
                </Tooltip>    
                {(currentUserHasRequireRole && employee.driverStation) && (
                    <Tooltip content={'Employee Card'}>
                        <Spacer inline={true} right={3}>
                            <Icon.Expand size={'14px'} color={Theme.colors.info.border}
                                        onClick={() => modalHandler(employee.id, "details", keyVal)}/>
                        </Spacer>
                    </Tooltip>
                )}
                {rolePermissionLevel.includes(employee.roleName) ? (
                    currentUserHasRequireRole &&
                    employee.driverStation && (
                        <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                            <Tooltip content={"Edit"}>
                                <Spacer inline={true} right={3}>
                                    <Icon.Edit
                                        size={"14px"}
                                        color={Theme.colors.info.border}
                                        onClick={() => modalHandler(employee.id, "edit", keyVal)}
                                    />
                                </Spacer>
                            </Tooltip>
                        </IsGranted>  
                    )
                ) : currentUserHasRequireRole &&
                    employee.driverStation && (
                    <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>    
                        <Tooltip content={"Can't edit. You don't have permission!"}>
                            <Spacer inline={true} right={3}>
                                <Icon.Edit size={"14px"} color={Theme.colors.info.border} />
                            </Spacer>
                        </Tooltip>
                    </IsGranted> 
                )}
                <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                    <Spacer inline={true} right={3}>
                        <Tooltip content={"Alias lookups for Mentor and Netrdyne"}>
                            <Icon.Feather
                                size={"14px"}
                                color={Theme.colors.info.border}
                                onClick={() => modalHandler(employee.id, "driverAlias", keyVal)}
                            />
                        </Tooltip>
                    </Spacer>
                </IsGranted>
                <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                    <Spacer inline={true} right={3}>
                        <Tooltip content={"Remove Shift"}>
                            <Icon.Maintenance
                                size={"14px"}
                                color={Theme.colors.info.border}
                                onClick={() => modalHandler(employee.id, "removeShift", keyVal)}
                            />
                        </Tooltip>
                    </Spacer>
                </IsGranted>
                <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>
                    {employee.lockUser && employee.lockUser == true &&
                        <Spacer inline={true} right={3}>
                            <Tooltip content={"Unlock User"}>
                                <svg size={"14px"} onClick={() => modalHandler(employee.id, "unlockUser", keyVal)} data-name="Layer 1" viewBox="0 0 40 40" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                                    <path fill={Theme.colors.info.border} d="M 15.332 23.235 C 17.25 21.855 18.427 19.373 18.432 16.689 C 18.405 10.714 12.993 7.015 8.693 10.031 C 6.696 11.432 5.474 14.002 5.487 16.776 C 5.496 19.462 6.675 21.946 8.594 23.33 C 3.964 24.886 0.674 29.804 0.41 35.56 C 0.404 35.839 0.48 36.111 0.626 36.328 C 0.811 36.566 1.073 36.692 1.345 36.673 C 1.836 36.675 2.245 36.225 2.287 35.638 C 2.861 26.721 11.269 21.894 17.421 26.949 C 19.84 28.936 21.377 32.131 21.604 35.638 C 21.612 36.231 22.023 36.701 22.517 36.681 C 22.768 36.711 23.014 36.58 23.165 36.337 C 23.308 36.087 23.452 35.828 23.38 35.569 C 23.164 29.821 19.933 24.869 15.332 23.235 Z M 11.917 11.264 C 14.427 11.22 16.491 13.627 16.527 16.638 L 16.527 16.776 C 16.537 21.032 12.702 23.706 9.624 21.588 C 6.545 19.473 6.531 14.152 9.599 12.014 C 10.302 11.523 11.102 11.264 11.917 11.264 Z"></path>
                                    <path fill={Theme.colors.info.border} d="M 35.022 12.673 L 35.022 7.839 C 34.952 6.242 34.217 4.754 33.009 3.758 C 31.718 2.672 30.091 2.103 28.426 2.158 C 25.055 2.254 22.332 4.742 22.233 7.827 L 22.233 12.599 C 20.617 12.76 19.357 14.175 19.259 15.849 L 19.259 23.103 C 19.259 24.916 20.893 26.318 22.836 26.318 L 34.36 26.318 C 36.334 26.318 37.955 24.897 37.99 23.095 L 37.99 15.842 C 37.872 14.185 36.623 12.85 35.022 12.673 Z M 24.175 12.627 L 24.175 7.833 C 24.225 6.724 24.733 5.692 25.57 4.998 C 26.468 4.245 27.598 3.853 28.754 3.891 C 31.094 3.952 33.009 5.706 33.074 7.827 L 33.074 12.627 L 24.175 12.627 Z M 36.042 15.849 L 36.042 23.103 C 35.967 23.976 35.234 24.632 34.384 24.583 L 22.86 24.583 C 22.01 24.635 21.277 23.978 21.21 23.103 L 21.21 15.849 C 21.28 14.973 22.015 14.315 22.865 14.366 L 34.367 14.366 C 35.225 14.301 35.972 14.964 36.042 15.849 Z" ></path>
                                    <path fill={Theme.colors.info.border} d="M 28.47 18.035 C 28.196 18.034 27.933 18.119 27.736 18.27 C 27.534 18.415 27.417 18.622 27.416 18.839 C 27.42 19.16 27.663 19.448 28.034 19.571 L 27.877 20.26 C 27.858 20.333 27.88 20.41 27.938 20.47 C 27.994 20.532 28.08 20.573 28.175 20.585 L 28.694 20.585 C 28.79 20.585 28.881 20.556 28.95 20.503 C 29.017 20.451 29.056 20.38 29.056 20.306 C 29.057 20.29 29.057 20.273 29.056 20.257 L 28.899 19.568 C 29.356 19.414 29.601 19.014 29.472 18.631 C 29.34 18.277 28.932 18.034 28.47 18.035 Z M 28.21 20.462 Z" ></path>
                                </svg>
                            </Tooltip>
                        </Spacer>
                    }
                </IsGranted>
                {rolePermissionLevel.includes(employee.roleName) ? (
                    <IsGranted feature={AccessCheck.features.CM_E_RESET_PASSWORD}>
                        <Spacer inline={true} right={3}>
                            <Tooltip content={"Reset Password"}>
                                <Icon.Locker
                                    size={"14px"}
                                    color={Theme.colors.info.border}
                                    onClick={() => modalHandler(employee.id, "changePassword", keyVal)}
                                />
                            </Tooltip>
                        </Spacer>
                    </IsGranted>
                ) : (
                    <IsGranted feature={AccessCheck.features.CM_E_RESET_PASSWORD}>
                    <Spacer inline={true} right={3}>
                        <Tooltip content={"Can't reset password. You don't have permission!"}>
                            <Icon.Locker size={"14px"} color={Theme.colors.info.border} />
                        </Tooltip>
                    </Spacer>
                    </IsGranted>
                )}
            </TableData>
        </li>
    );
};
