import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";

const LabelFile = styled.label`
    cursor: pointer;
    width: ${props => (props.width ? props.width : "52px")};
    height: ${props => (props.height ? props.height : "52px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background-color: #EAF0F6;
    border: 1px solid #516F90;
    border-radius: 50%;

    svg {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: #0071BC
    }

    img {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }

    &:hover {
        background-color: #CBD6E2;
    }
`;

const InputFile = styled.input`
    display: none;
`;


// type PictureUploaderProps = {
//     register: () => void,
//     name: string,
//     id: string,
//     image?: string | null | undefined,
//     enable?: boolean
// }

const PictureUploader = (props) => {
    const { register, name, id, image = null, enable = true, setImageUrl, imageUrl, setImageFile } = props;

    return (
        <>
            <LabelFile htmlFor={name} height={props.height} width={props.width} >
                {typeof imageUrl !== "string" && <Icon.Group size='60px' />}
                {typeof imageUrl === "string" && <img src={imageUrl} alt={'channel'} />}
            </LabelFile>
            {enable &&
                <InputFile
                    name={name}
                    id={id}
                    type="file"
                    ref={register}
                    onChange={(e) => {
                        if (e.target.files) {
                            let file = e.target.files[0];
                            let url = URL.createObjectURL(file);
                            setImageUrl(url);
                            setImageFile(e.target.files[0])
                            localStorage.setItem('channelImage', url);
                        }
                    }}
                />}
        </>);
}
export default PictureUploader;