const fetchIncidentType = async (api,id) => {
    const params = {
            actions: {
                response: {
                    IncidentType:{
                        custom: {
                            functionName: "getIncidentTypeById",
                            get: "incident",
                            criteria: {
                                id: id
                            }
                        }
                    }
                }
            }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.incident;
    } catch (error) {
        console.log(error);
    }
}


const fetchAllIncidentTypes = async (api,company) => {
    let params = {
        actions: {
            response: {
                IncidentType: {
                    custom: {
                        functionName: "getIncidentTypeByCompany",
                        get: "incidentTypes",
                        criteria: {
                            company: company,
                            isInWeb: true
                        },
                        includes: {
                            0: "id",
                            1: "name"
                        },
                        orderBy: {
                            name: 'ASC'
                        }
                    }
                },
            }
        }
    };

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.incidentTypes;
    } catch (error) {
        console.log(error);
    }
}

const getAlldriverFromCompany = async (api,company) => {
    let params = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "getAlldriverFromCompany",
                        get: "drivers",
                        criteria: {
                            company: company,
                        }
                    }
                },
            }
        }
    };
    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.drivers;
    } catch (error) {
        console.log(error);
    }
}

const SaveWorkflow = async (api,data,category,actions,token,company,triggerId) => {
    let params = {
        actions: {
            response: {
                Workflow: {
                    custom: {
                        functionName: "saveWorkflow",
                        get: "workflow",
                        criteria: {
                            company: company,
                            data:data,
                            cat:category,
                            actions:actions,
                            token:token,
                            triggerId:triggerId
                        }
                    }
                },
            }
        }
    };
    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.workflow;
    } catch (error) {
        console.log(error);
    }
}

const getWorkflowTriggers = async (api,category,type,ignoreActions) => {

    let params = {
        actions: {
            response: {
                TriggerAction: {
                    custom: {
                        functionName: "getWorkflowTriggers",
                        get: "workflowTrigger",
                        criteria: {'isOption':true,'category':category,'subCategory':type,'ignoreActions':ignoreActions},                        
                    }
                },
            }
        }
    };
    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.workflowTrigger;
    } catch (error) {
        console.log(error);
    }
}

const getWorkflowActions = async (api,category,triggerToken,ignoreActions) => {
    let params = {
        actions: {
            response: {
                TriggerAction: {
                    custom: {
                        functionName: "getWorkflowActions",
                        get: "workflowAction",
                        criteria: {'isOption':true,'category':category,'trigger':triggerToken,'ignoreActions':ignoreActions},                        
                    }
                },
            }
        }
    };
    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.workflowAction;
    } catch (error) {
        console.log(error);
    }
}



const fetchAllWorkflowTriggers = async (api,id) => {
    const params = {
            actions: {
                response: {
                    Workflow:{
                        custom: {
                            functionName: "getAllWorkflow",
                            get: "workflow",
                            criteria: {
                                id: id
                            }
                        }
                    }
                }
            }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.workflow;
    } catch (error) {
        console.log(error);
    }
}

const removeWorkflowTrigger = async (api,id) => {
    const params = {
            actions: {
                update: {
                    Workflow:{
                        workflow_1: {
                            findBy:{
                                id: id
                            },
                            updateRecord: {
                                isArchive: true
                            }
                        }
                    }
                }
            }
    }

    try {
        await api.post('/api/lazy/manage/data', params);
        return true;
    } catch (error) {
        console.log(error);
    }
}






export {fetchIncidentType,fetchAllIncidentTypes,getAlldriverFromCompany,SaveWorkflow,getWorkflowTriggers,fetchAllWorkflowTriggers,removeWorkflowTrigger,getWorkflowActions}
