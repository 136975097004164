import {getCompanyId} from "../../../../Auth";
import {engine} from "../../../../Utilities/index";
import axios from "axios";


const HandleCreateSlide = (slides,connectionName) => {
    let param = {AnncSlide:{},AnncMessageTemplates:{},connect:{AnncSlide:{},AnncMessageTemplates:{}}};

    if(slides){
        slides.forEach((s,k)=>{
            if(s.template){
                let connName = `AnncMessageTemplates_${k}`;
                param.AnncMessageTemplates[connName] = {
                    template:s.template,
                    slideOrder:k+1
                }

                param.connect.AnncMessageTemplates[connName] = {};
                param.connect.AnncMessageTemplates[connName][connectionName.slice(0,-1)] = [connectionName]
            }else{
                let connName = `AnncSlide_${k}`;
                param.AnncSlide[connName] = {
                    company: getCompanyId(),
                    name: s.name,
                    content: s.content,
                    isRequired: s.isRequired,
                    slideOrder:k+1
                }

                param.connect.AnncSlide[connName] = {};
                param.connect.AnncSlide[connName][connectionName.slice(0,-1)] = [connectionName]
            }
        })
    }
    return {
        create:{slide:param.AnncSlide,template:param.AnncMessageTemplates},
        connections:param.connect
    }
}

const HandleUpdateSlide = (slides,extraParams) => {
    let param = {
        create:{
            AnncSlide:{},
            AnncMessageTemplates:{}
        },
        update: {
            AnncSlide: {},
            AnncMessageTemplates:{}
        },
        delete: {
            AnncSlide: {},
            AnncMessageTemplates: {}
        }
    };

    if(slides){
        slides.forEach((s,k)=>{
            if(s.template){
                let connName = `AnncMessageTemplates_${k}`;
                if(s.delete == "true"){
                    param.delete.AnncMessageTemplates[connName] = {
                        findOneBy: {
                            id: s.template
                        },
                        isHardDelete:true
                    }
                }else if(s.isNew == "true"){
                    param.create.AnncMessageTemplates[connName] = {
                        template:s.template,
                        message: extraParams.message,
                        slideOrder:k+1
                    }
                }else{
                    param.update.AnncMessageTemplates[connName] = {
                        findBy: {id: s.template},
                        updateRecord: {
                            slideOrder: k+1
                        }
                    }
                }
            }else{
                let connName = `AnncSlide_${k}`;
                if(s.delete == "true"){
                    param.delete.AnncSlide[connName] = {
                        findOneBy: {
                            id: s.id
                        },
                        isHardDelete:true
                    }
                }else if(s.id){
                    param.update.AnncSlide[connName] = {
                        findBy: {id: s.id},
                        updateRecord: {
                            name: s.name,
                            content: s.content,
                            isRequired: s.isRequired,
                            slideOrder: k + 1
                        }
                    }
                }else{
                    param.create.AnncSlide[connName] = {
                        company: getCompanyId(),
                        name: s.name,
                        content: s.content,
                        isRequired: s.isRequired,
                        slideOrder:k+1,
                        ...extraParams
                    }
                }
            }
        })
    }

    return {
        create:param.create,
        update:param.update,
        delete:param.delete,
        connections: {}
    }
}


const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchSlide(id){
        return API.call({
            actions: {
                response: {
                    AnncSlide:{
                        findOneBy: {
                            get: "AnncSlide",
                            criteria: {
                                company: getCompanyId(),
                                id: id
                            },
                            includes: {
                                "0":"name",
                                "1":"content",
                                "2":"slideOrder",
                                "3":"isRequired"
                            }
                        }
                    }
                }
            }
        });
    },
    fetchSlidesTemplate(templateId){
        return API.call({
            actions: {
                response: {
                    AnncSlide:{
                        findBy: {
                            get: "AnncSlide",
                            criteria: {
                                company: getCompanyId(),
                                template: templateId
                            },
                            orderBy: {
                                slideOrder: 'ASC'
                            },
                            includes: {
                                "0":"name",
                                "1":"content",
                                "2":"slideOrder",
                                "3":"isRequired"
                            }
                        }
                    }
                }
            }
        });
    },
    fetchSlidesMessage(messageId){
        return API.call({
            actions:{
                response:{
                    AnncSlide:{
                        findBy:{
                            get:"AnncSlide",
                            criteria:{
                                company:getCompanyId(),
                                message:messageId
                            },
                            orderBy:{
                                slideOrder:"ASC"
                            },
                            includes:{
                                0:"name",
                                1:"content",
                                2:"slideOrder",
                                3:"isRequired"
                            }
                        }
                    },
                    AnncMessageTemplates:{
                        findBy:{
                            get:"AnncMessageTemplates",
                            criteria:{
                                message:messageId
                            },
                            orderBy:{
                                slideOrder:"ASC"
                            },
                            includes:{
                                0:"slideOrder",
                                template:{
                                    slides:{
                                        0:"name",
                                        1:"content",
                                        3:"isRequired"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    }
}

export {HandleCreateSlide,HandleUpdateSlide,API};