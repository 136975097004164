import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Alert, Button, Input, Spacer, TagInput} from "@dspworkplace/ui";
import {SlideList} from "../Slides";

import Modal from "../../../../components/Modal";
import {dialogPromise} from "../../../../components/Dialog";
import Loading, {LoadingWrapper} from "../../../../components/Loading";
import API from "./api";
import {confirm} from "../../../../components/Confirm";


const TemplateForm = ({
   success = ()=>{},
   cancel = ()=>{},
   idTemplate
}) => {
    const [state,setState] = useState({status:'pending',slides:[],metricVideo:[]});
    const [tagOpts,setTagOpts] = useState([]);
    const {register,handleSubmit,errors,reset} = useForm({defaultValues:{}});
    
    const getMetricVideo = async () => {
        const videoData = await API.fetchMetricVideo().then(
            (response) => {
                return response.data.data.getMetricVideo;
            },
            (error) => console.log(error)
        );
        return videoData;;
    };

    const getEditTemplate = async () => {
        if (idTemplate) {
            let metricVideo = await getMetricVideo();
            API.fetchTemplateData(idTemplate).then((response) => {
                const template = response.data.data.AnncTemplate;
                const tags = template.tags.map((t) => ({ value: t, name: t, selected: true }));
                setTagOpts(tags);
                setState({ metricVideo: metricVideo, status: "ready", slides: template.slides });
                reset({
                    id: template.id,
                    name: template.name,
                    tags: template.tags,
                });
            });
        } else {
            let metricVideo = await getMetricVideo();
            setState({ ...state,metricVideo:metricVideo, status: "ready" });
        }
    }

    useEffect(() => {
        getEditTemplate();
    }, [idTemplate, reset])

    const onSubmit = (data) => {
        const tags = data.tags;
        setState({...state,status:'submitting'});

        if(idTemplate) data.id = idTemplate;
        API.addTemplate(data).then(
            response => {
                let message = response.data.data.lastInserted;
                message.tags = tags;
                success(message);
            },
            error => {
                setState({...state,status:'createTemplateError'});
            }
        );
    }


    return (
        <Modal
            width={'340px'}
            visible={true}
            title={idTemplate ? 'Edit Template' : 'New Template'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === 'pending' ?
                <LoadingWrapper>
                    <Loading style={{height: 80}}/>
                </LoadingWrapper>
            :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        name='name'
                        label='Name'
                        ref={register(({required:'Field Required'}))}
                        error={errors.name && errors.name.message}
                        Required
                    />
                    <Spacer top={4} />
                    <TagInput
                        name='tags'
                        label='Tags'
                        ref={register}
                        canCreate={true}
                        options={tagOpts}
                    />
                    <Spacer top={4} />
                    <SlideList data={state.slides} ref={register} name={'slides'} metricVideo={state.metricVideo}/>

                    {state.status === 'error' &&
                        <Spacer top={5}>
                            <Alert.Error
                                title={'Unable to fetch data'}
                                content={'Try to refresh the page, if the problem persist please contact support.'} />
                        </Spacer>
                    }
                    {state.status === 'createTemplateError' &&
                        <Spacer top={5}>
                            <Alert.Error
                                title={`Unable to ${idTemplate ? 'update' : 'create'} the template`}
                                content={'Try to refresh the page, if the problem persist please contact support.'} />
                        </Spacer>
                    }
                    <Spacer top={5} style={{display:'flex',justifyContent:'flex-end'}}>
                        <Button
                            // style={{float:'right'}}
                            type={'primary'}
                            Type={'submit'}
                            disabled={state.status === 'submitting'}
                        >
                            {state.status === 'submitting' ? (idTemplate ? 'Updating...' : 'Creating...') : (!idTemplate ? 'Create' : 'Update')}
                        </Button>
                    </Spacer>
                </form>
            }
        </Modal>
    )
}

const openTemplateForm = (idTemplate) => {
    return dialogPromise((success, cancel)=>{
        return <TemplateForm
            success={success}
            cancel={cancel}
            idTemplate={idTemplate}
        />
    })
}

const openTemplateArchiveForm = async (title) => {
    return confirm({
        icon: true,
        text: `Do you really want to archive the template "${title}"?`
    });
}
export {openTemplateForm,openTemplateArchiveForm,TemplateForm}