import React from "react";
import { Theme } from "@dspworkplace/ui";
import moment from "moment";
import { getColumnAvg, getColumnTotal } from "../Reports/common";

export class DOCUMENT_STATUS {
    static FETCHING = 1;
    static READY = 2;
    static ERROR = 3;
}

export const DOCUMENT_STATUS_CONFIG = {
    // new - uses default warning config
    1: 'warning',
    // queued - uses default
    2:  {
        bg: Theme.colors.neutrals.white,
        text: Theme.colors.info.border,
        border: Theme.colors.info.border,
    },
    // processing
    3: {
        bg: Theme.colors.info.border,
        text: Theme.colors.neutrals.white,
        border: Theme.colors.success.text,
    },
    // ready
    4: {
        bg: Theme.colors.success.text,
        text: Theme.colors.neutrals.white,
        border: Theme.colors.success.text,
    },
    // error
    5: 'error',
    6: {
        bg: Theme.colors.neutrals.white,
        text: Theme.colors.error.text,
        border: Theme.colors.error.border,
    },
    7: 'warning'
};

export const DOCUMENT_ERROR_KEY = {
    1: 'NEW',
    // queued - uses default
    2: 'In a queue, waiting to be picked up for processing',
    // processing
    3: 'Actually processing the report data',
    // ready
    4: 'READY',
    // error
    5: 'Processed successfully, but has a few errors',
    6: 'Could not process data successfully at all',
    7: 'Processed successfully, but has a few errors'
};

export const DOCUMENT_STATUS_MAP = [
    null,
    'NEW',
    'QUEUED',
    'PROCESSING',
    'READY',
    'UNKNOWN TYPE',
    'PARSE ERROR',
    'PARTIAL ERROR'
];

export const ReportSourceDocumentSchemaInclude = {
    0: 'id',
    1: 'name',
    2: 'category',
    3: 'url',
    4: 'source',
    5: 'status',
    6: 'createdAt',
    metadata: [
        'key',
        'value'
    ]
};

export const ReportScorecardSchemaInclude = {
    0: 'id',
    1: 'date',
    2: 'week',
    3: 'year',
    4: 'ranking',
    5: 'name',
    6: 'tier',
    7: 'delivered',
    8: 'focus',
    9: 'fico',
    10: 'seatbeltOffRate',
    11: 'speedingEventRate',
    21: 'distractionsRate',
    22: 'followingDistanceRate',
    23: 'signViolationRate',
    12: 'deliveryCompletionRate',
    13: 'deliveryAndReceived',
    14: 'photoOnDelivery',
    15: 'contactCompliance',
    16: 'scanCompliance',
    17: 'attendedCompliance',
    18: 'dnrsCount',
    19: 'photoOpportunities',
    20: 'contactOpportunities',
    24: 'deliverySlotPerformance',
    25: 'harshCornering',
    26: 'harshBraking',
    27: 'starRating',
    28: 'customerDeliveryFeedback',
    29: 'customerEscalationDefect',
    30: 'deliverySuccessBehaviors',
    31: 'customerDeliveryFeedbackDPMO',
};

export const ReportScorecardSchemaCETInclude = {
    0: 'id',
    1: 'date',
    2: 'week',
    3: 'year',
    4: 'ranking',
    5: 'name',
    6: 'tier',
    7: 'delivered',
    8: 'focus',
    // 9: 'fico',
    10: 'seatbeltOffRate',
    11: 'speedingEventRate',
    21: 'distractionsRate',
    22: 'followingDistanceRate',
    23: 'signViolationRate',
    12: 'deliveryCompletionRate',
    13: 'deliveryAndReceived',
    14: 'photoOnDelivery',
    15: 'contactCompliance',
    16: 'scanCompliance',
    17: 'attendedCompliance',
    18: 'dnrsCount',
    19: 'photoOpportunities',
    20: 'contactOpportunities',
    24: 'deliverySlotPerformance',
    25: 'harshCornering',
    26: 'harshBraking',
    27: 'starRating',
    28: 'customerDeliveryFeedback',
    29: 'customerEscalationDefect',
    30: 'deliveryExperience',
    31: 'preferenceHonorRate',
    32: 'totalScore',
    33: 'deliverySuccessBehaviors',
};

export const ReportMentorSchemaInclude = {
    0: 'id',
    1: 'date',
    4: 'trips',
    5: 'miles',
    6: 'time',
    7: 'fico',
    8: 'accel',
    9: 'braking',
    10: 'cornering',
    11: 'speeding',
    21: 'distraction',
    22: 'seatbelt',
    23: 'backUp',
    12: 'sse',
    13: 'mpg',
    14: 'idling',
    15: 'engineOff',
    16: 'preDvcr',
    17: 'postDvcr',
    18: 'trainingAssigned',
    19: 'trainingCompleted',
};

export const ReportNetradyneSchemaInclude = {
    0: 'id',
    1: 'date',
    4: 'minutesAnalyzed',
    5: 'greenMinutesPercentage',
    6: 'overspeedingPercentage',
    7: 'driverScore',
    8: 'driverStar',
    9: 'highG',
    10: 'lowImpact',
    11: 'driverInitiated',
    23: 'signViolations',
    24: 'trafficLightViolation',
    25: 'uTurn',
    12: 'hardBraking',
    13: 'hardTurn',
    14: 'hardAcceleration',
    15: 'driverDistraction',
    16: 'followingDistance',
    17: 'speedingViolations',
    18: 'seatbeltCompliance',
    19: 'cameraObstruction',
    20: 'weaving',
    21: 'requestedVideo',
    22: 'totalEvents',
};
export const ReportEngineOffSchemaInclude = {
    0: 'id',
    1: 'date',
    4: 'eoc'
};

export const ReportCustomerDeliveryFeedBackSchemaInclude = {
    0: 'id',
    1: 'date',
    2: 'week',
    3: 'year',
    4: 'ranking',
    5: 'name',
    6: 'tier',
    7: 'cdfScore',
    8: 'noFeedback',
    9: 'deliveryWasGreat',
    10: 'respectfulOfProperty',
    11: 'followedInstructions',
    12: 'friendly',
    13: 'aboveAndBeyond',
    14: 'deliveredWithCare',
    15: 'deliveryWasNotSoGreat',
    16: 'driverMishandledPackage',
    17: 'driverWasUnprofessional',
    18: 'driverDidNotFollowMyDeliveryInstructions',
    19: 'deliveredToWrongAddress',
    20: 'neverReceivedDelivery',
    21: 'onTime',
    22: 'lateDelivery',
    23: 'itemDamaged',
};

export const ReportProperParkingSequenceSchemaInclude = {
    0: "id",
    1: "date",
    2: "week",
    3: "year",
    4: "evaluatedStops",
    5: "ppsCompliance",
    6: "percentagePPSCompliance",
    7: "invalidPPS",
    8: "percentageInvalidPPS",
    9: "missingGearInPark",
    10: "percentageMissingGearInPark",
    11: "missingParkingBrake",
    12: "percentageMissingParkingBrake",
    13: "totalBreaches",
    14: "percentageTotalBreaches",
};

/** percentage */
const p = (n, d = 2) => n === null || n === undefined || isNaN(parseFloat(n)) ? '–' : parseFloat(n).toFixed(d) + '%';
/** fraction */
const f = (n, d) => n === null || n === undefined || isNaN(parseFloat(n)) ? '–' : parseFloat(n).toFixed(d);
/** rounded */
const r = n => n === null || n === undefined || isNaN(parseFloat(n)) ? '–' : Math.round(parseFloat(n));

export const ReportScorecardSchemaColumns = {
    week: {
        label: 'Week #',
        width: '80px',
        render: (v, scorecard) => `${scorecard.week}/${scorecard.year}`
    },
    ranking: {
        label: 'Ranking',
        width: '80px',
        render: v => r(v),
    },
    tier: {
        label: 'Tier',
        width: '100px'
    },
    delivered: {
        label: 'Delivered',
        width: '80px',
        render: v => r(v),
    },
    focus: {
        label: 'Focus',
        width: '200px'
    },
    fico: {
        label: 'FICO Score',
        width: '80px',
        render: v => f(v, 0),
    },
    seatbeltOffRate: {
        label: 'SOR',
        width: '60px',
        render: v => f(v, 2),
    },
    speedingEventRate: {
        label: 'SER',
        width: '60px',
        render: v => f(v, 2),
    },
    distractionsRate: {
        label: 'Distractions Rate',
        width: '108px',
        render: v => f(v, 2)
    },
    followingDistanceRate: {
        label: 'Following Distance Rate',
        width: '160px',
        render: v => f(v, 2)
    },
    signViolationRate: {
        label: 'Sign/ Signal Violations Rate',
        width: '180px',
        render: v => f(v, 2)
    },
    customerDeliveryFeedback: {
        label: 'CDF',
        width: '80px',
        render: v => p(v),
    },
    customerDeliveryFeedbackDPMO: {
        label: 'CDF DPMO',
        width: '120px',
        render: v => r(v),
    },
    customerEscalationDefect: {
        label: 'CED',
        width: '80px',
        render: v => p(v),
    },
    deliveryCompletionRate: {
        label: 'DCR',
        width: '80px',
        render: v => p(v)
    },
    deliveryAndReceived: {
        label: 'DAR',
        width: '60px',
        render: v => r(v),
    },
    deliverySuccessBehaviors: {
        label: 'DSB',
        width: '60px',
        render: v => r(v),
    },
    photoOnDelivery: {
        label: 'SWC-POD',
        width: '80px',
        render: v => p(v),
    },
    contactCompliance: {
        label: 'SWC-CC',
        width: '80px',
        render: v => p(v),
    },
    scanCompliance: {
        label: 'SWC-SC',
        width: '80px',
        render: v => p(v),
    },
    attendedCompliance: {
        label: 'SWC-AD',
        width: '80px',
        render: v => r(v),
    },
    dnrsCount: {
        label: 'DNRs',
        width: '60px',
        render: v => r(v),
    },
    /*photoOpportunities: {
        label: 'POD Opps',
        width: '80px',
        render: v => r(v),
    },
    contactOpportunities: {
        label: 'CC Opps',
        width: '80px',
        render: v => r(v),
    },*/
    starRating: {
        label: 'Star Rating',
        width: '80px',
        render: v => r(v),
    },
    deliverySlotPerformance: {
        label: 'DSLP',
        width: '50px',
        render: v => r(v),
    },
    harshCornering: {
        label: 'Harsh Cornering',
        width: '110px',
        render: v => f(v, 2),
    },
    harshBraking: {
        label: 'Harsh Braking',
        width: '110px',
        render: v => f(v, 2),
    },
}
export const ReportScorecardSchemaCETColumns = {
    week: {
        label: 'Week #',
        width: '80px',
        render: (v, scorecard) => `${scorecard.week}/${scorecard.year}`
    },
    ranking: {
        label: 'Ranking',
        width: '80px',
        render: v => r(v),
    },
    tier: {
        label: 'Tier',
        width: '100px'
    },
    delivered: {
        label: 'Delivered',
        width: '80px',
        render: v => r(v),
    },
    focus: {
        label: 'Focus',
        width: '200px'
    },
    // fico: {
    //     label: 'FICO Score',
    //     width: '80px',
    //     render: v => f(v, 0),
    // },
    seatbeltOffRate: {
        label: 'SOR',
        width: '60px',
        render: v => f(v, 2),
    },
    speedingEventRate: {
        label: 'SER',
        width: '60px',
        render: v => f(v, 2),
    },
    distractionsRate: {
        label: 'Distractions Rate',
        width: '108px',
        render: v => f(v, 2)
    },
    followingDistanceRate: {
        label: 'Following Distance Rate',
        width: '160px',
        render: v => f(v, 2)
    },
    signViolationRate: {
        label: 'Sign/ Signal Violations Rate',
        width: '180px',
        render: v => f(v, 2)
    },
    customerDeliveryFeedback: {
        label: 'CDF',
        width: '80px',
        render: v => p(v),
    },
    customerDeliveryFeedbackDPMO: {
        label: 'CDF DPMO',
        width: '100px',
        render: v => r(v),
    },
    customerEscalationDefect: {
        label: 'CED',
        width: '80px',
        render: v => p(v),
    },
    deliveryCompletionRate: {
        label: 'DCR',
        width: '80px',
        render: v => p(v)
    },
    deliveryAndReceived: {
        label: 'DAR',
        width: '60px',
        render: v => r(v),
    },
    deliverySuccessBehaviors: {
        label: 'DSB',
        width: '60px',
        render: v => r(v),
    },
    photoOnDelivery: {
        label: 'SWC-POD',
        width: '80px',
        render: v => p(v),
    },
    contactCompliance: {
        label: 'SWC-CC',
        width: '80px',
        render: v => p(v),
    },
    scanCompliance: {
        label: 'SWC-SC',
        width: '80px',
        render: v => p(v),
    },
    attendedCompliance: {
        label: 'SWC-AD',
        width: '80px',
        render: v => r(v),
    },
    dnrsCount: {
        label: 'DNRs',
        width: '60px',
        render: v => r(v),
    },
    /*photoOpportunities: {
        label: 'POD Opps',
        width: '80px',
        render: v => r(v),
    },
    contactOpportunities: {
        label: 'CC Opps',
        width: '80px',
        render: v => r(v),
    },*/
    starRating: {
        label: 'Star Rating',
        width: '80px',
        render: v => r(v),
    },
    deliverySlotPerformance: {
        label: 'DSLP',
        width: '50px',
        render: v => r(v),
    },
    harshCornering: {
        label: 'Harsh Cornering',
        width: '110px',
        render: v => f(v, 2),
    },
    harshBraking: {
        label: 'Harsh Braking',
        width: '110px',
        render: v => f(v, 2),
    },
    preferenceHonorRate: {
        label: 'PHR',
        width: '110px',
        render: v => f(v, 2),
    },
    deliveryExperience: {
        label: 'DEX',
        width: '110px',
        render: v => f(v, 2),
    },
    totalScore: {
        label: 'Total Score',
        width: '110px',
        render: v => f(v, 2),
    },
}

export const ReportSafetyMetricsSchemaColumns = {
    date: {
        label: 'Date',
        width: '120px',
        style: {
            // position: 'absolute',
            left: 20,
            top: 40,
            background: 'white',
        },
        render: (v, metric) => moment.utc(metric.date.timestamp * 1000).format('MMM D, Y')
    },
    eoc: {
        group: 'EOC',
        label: 'EOC',
        width: '70px',
        render: v => r(v),
        totals: (all, k) => {
            let valid = all.filter(r => r.eoc > 0);
            return <>{p(getColumnAvg(valid, k), 0)} <small>avg</small></>;
        },
    },
    //Netradyne columns
    minutesAnalyzed: {
        group: 'Netradyne',
        label: 'Min. Analyzed',
        width: '120px',
        render: r
    },
    greenMinutesPercentage: {
        group: 'Netradyne',
        label: 'Green Minutes %',
        width: '140px',
        render: v => p(v, 0),
        totals: (all, k) => {

            let valid = all.filter(r => r.minutesAnalyzed > 0);

            //get total minutes analyzed
            const totalMinAnalyzed = getColumnTotal(valid, 'minutesAnalyzed');

            //convert percent in minutes and sum
            const totalConverted = valid.reduce((total, v) =>
                total + (parseInt(v.minutesAnalyzed) / 100) * parseInt(v.greenMinutesPercentage)
                , 0)

            //new percent
            const newPercent = (100 * Math.round(totalConverted)) / totalMinAnalyzed

            return <>{p(newPercent, 0)} <small>avg</small></>;
        },
    },
    overspeedingPercentage: {
        group: 'Netradyne',
        label: 'Overspeeding %',
        width: '140px',
        render: v => p(v, 0),
        totals: (all, k) => {
            let valid = all.filter(r => r.minutesAnalyzed > 0);
            return <>{p(getColumnAvg(valid, k), 0)} <small>avg</small></>;
        },
    },
    driverScore: {
        group: 'Netradyne',
        label: 'Driver Score',
        width: '100px',
        render: r,
        totals: () => '–',
        // totals: (all, k) => {
        //     let valid = all.filter(r => r.minutesAnalyzed > 0);
        //     return <>{r(getColumnAvg(valid, k))} <small>avg</small></>;
        // },
    },
    driverStar: {
        group: 'Netradyne',
        label: 'Driver Star',
        width: '100px',
        render: r
    },
    highG: {
        group: 'Netradyne',
        label: 'High G',
        width: '100px',
        render: r
    },
    lowImpact: {
        group: 'Netradyne',
        label: 'Low Impact',
        width: '100px',
        render: r
    },
    driverInitiated: {
        group: 'Netradyne',
        label: 'Driver Initiated',
        width: '140px',
        render: r
    },
    signViolations: {
        group: 'Netradyne',
        label: 'Sign Violations',
        width: '120px',
        render: v => f(v, 0),
    },
    trafficLightViolation: {
        group: 'Netradyne',
        label: 'Traffic Light Violation',
        width: '160px',
        render: r
    },
    uTurn: {
        group: 'Netradyne',
        label: 'U Turn',
        width: '100px',
        render: r
    },
    hardBraking: {
        group: 'Netradyne',
        label: 'Hard Braking',
        width: '120px',
        render: r
    },
    hardTurn: {
        group: 'Netradyne',
        label: 'Hard Turn',
        width: '120px',
        render: v => f(v, 0),
    },
    hardAcceleration: {
        group: 'Netradyne',
        label: 'Hard Acceleration',
        width: '140px',
        render: r
    },
    driverDistraction: {
        group: 'Netradyne',
        label: 'Distraction',
        width: '100px',
        render: r
    },
    followingDistance: {
        group: 'Netradyne',
        label: 'Following Distance',
        width: '140px',
        render: r
    },
    speedingViolations: {
        group: 'Netradyne',
        label: 'Speeding Violations',
        width: '160px',
        render: r
    },
    seatbeltCompliance: {
        group: 'Netradyne',
        label: 'Seatbelt Compliance',
        width: '160px',
        render: r
    },
    cameraObstruction: {
        group: 'Netradyne',
        label: 'Camera Obstruction',
        width: '160px',
        render: r
    },
    weaving: {
        group: 'Netradyne',
        label: 'Weaving ',
        width: '120px',
        render: v => f(v, 0),
    },
    requestedVideo: {
        group: 'Netradyne',
        label: 'Requested Video',
        width: '120px',
        render: r
    },
    // totalEvents: {
    //     group: 'Netradyne',
    //     label: 'Total Events',
    //     width: '100px',
    //     render: r
    // },
    //Mentor columns
    trips: {
        group: 'Mentor',
        label: 'Trips',
        width: '60px',
        render: v => r(v),
    },
    miles: {
        group: 'Mentor',
        label: 'Miles',
        width: '72px',
        render: v => r(v),
    },
    time: {
        group: 'Mentor',
        label: 'Tracked Time',
        width: '120px',
        render: v => v
    },
    fico: {
        group: 'Mentor',
        label: 'FICO',
        width: '100px',
        render: v => r(v),
        totals: () => '–',
        // totals: (all, k) => {
        //     let valid = all.filter(r => r.fico > 0);
        //     return <>{r(getColumnAvg(valid, k))} <small>avg</small></>;
        // },
    },
    accel: {
        group: 'Mentor',
        label: 'Acceleration',
        width: '100px',
        render: v => r(v),
    },
    braking: {
        group: 'Mentor',
        label: 'Braking',
        width: '100px',
        render: v => r(v),
    },
    cornering: {
        group: 'Mentor',
        label: 'Cornering',
        width: '100px',
        render: v => r(v),
    },
    speeding: {
        group: 'Mentor',
        label: 'Speeding',
        width: '100px',
        render: v => r(v),
    },
    distraction: {
        group: 'Mentor',
        label: 'Distraction',
        width: '100px',
        render: v => r(v),
    },
    seatbelt: {
        group: 'Mentor',
        label: 'Seatbelt Off',
        width: '100px',
        render: v => r(v),
    },
    backUp: {
        group: 'Mentor',
        label: 'Reversing',
        width: '100px',
        render: v => r(v),
    },
    sse: {
        group: 'Mentor',
        label: 'SSE',
        width: '100px',
        render: v => r(v),
    },
    mpg: {
        group: 'Mentor',
        label: 'MPG',
        width: '100px',
        render: v => r(v),
        totals: (all, k) => {
            let valid = all.filter(r => r.mpg > 0);
            return <>{p(getColumnAvg(valid, k), 0)} <small>avg</small></>;
        },
    },
    idling: {
        group: 'Mentor',
        label: 'Idling',
        width: '100px',
        render: v => r(v),
    },
    engineOff: {
        group: 'Mentor',
        label: 'Engine Off',
        width: '100px',
        render: v => r(v),
    },
    preDvcr: {
        group: 'Mentor',
        label: 'Pre DVCR',
        width: '100px',
        render: v => r(v),
    },
    postDvcr: {
        group: 'Mentor',
        label: 'Post DVCR',
        width: '100px',
        render: v => r(v),
    },
    trainingAssigned: {
        group: 'Mentor',
        label: 'Training Assigned',
        width: '140px',
        render: v => r(v),
        totals: () => ''
    },
    trainingCompleted: {
        group: 'Mentor',
        label: 'Training Completed',
        width: '160px',
        render: v => r(v),
    },
}

export const METRIC_COLUMNS = {
    metric: {
        label: "Metric",
        width: "200px",
    },
    goal: {
        label: "Goal",
        width: "150px",
    },
    automatedTips: {
        label: "Automated Tips",
        width: "32%",
    },
    coaching: {
        label: "Coaching",
        width: "auto",
    },
};

export const METRIC_COLUMNS_STATION = {
    metric: {
        label: "Metric",
        width: "200px",
    },
    goal: {
        label: "Goal",
        width: "150px",
    },
    onOff: {
        label: "On/Off",
        width: "150px",
        mainLabel:"Forced Coaching"
    },
    trigger: {
        label: "Trigger",
        width: "150px",
        mainLabel:"Forced Coaching"
    },
    automatedTips: {
        label: "Automated Tips",
        width: "32%",
    },
    coaching: {
        label: "Coaching",
        width: "auto",
    },
};

export const METRIC_COLUMNS_DRIVER = {
    metric: {
        label: "Metric",
        width: "200px",
    },
    goal: {
        label: "Goal",
        width: "150px",
    },
    automatedTips: {
        label: "Automated Tips",
        width: "32%",
    },
    coaching: {
        label: "Coaching",
        width: "auto",
    },
};

export const TEAMS_COLUMNS = {
    name: {
        label: 'Name',
        width: '13%',
        // sortBy: 'name'
    },
    members: {
        label: 'Drivers',
        width: '47%'
    },
    // progress: {
    //     label: 'Last Week Goals (assigned / achieved)',
    //     width: '20%',
    //     sortBy: 'progress'
    // }
}

export const ReportCustomerDeliveryFeedBackSchemaColumns = {
    week: {
        label: 'Week #',
        width: '80px',
        render: (v, cdf) => `${cdf.week}/${cdf.year}`
    },
    ranking: {
        label: 'Ranking',
        width: '80px',
    },
    tier: {
        label: 'Tier',
        width: '100px'
    },
    deliveryWasGreat: {
        label: 'Delivery was Great',
        width: '130px',
        render: v => r(v),
    },
    respectfulOfProperty: {
        label: 'Respectful of Property',
        width: '150px',
        render: v => r(v),
    },
    followedInstructions: {
        label: 'Followed Instructions',
        width: '150px',
        render: v => r(v),
    },
    friendly:{
        label: 'Friendly',
        width: '80px',
        render: v => r(v),
    },
    aboveAndBeyond:{
        label: 'Above and Beyond',
        width: '150px',
        render: v => r(v),
    },
    deliveredWithCare:{
        label: 'Delivered with Care',
        width: '150px',
        render: v => r(v),
    },
    onTime:{
        label: 'On Time',
        width: '80px',
        render: v => r(v),
    },
    deliveryWasNotSoGreat:{
        label: 'Delivery was not so Great',
        width: '180px',
        render: v => r(v),
    },
    driverMishandledPackage:{
        label: 'Driver Mishandled Package',
        width: '180px',
        render: v => r(v),
    },
    driverWasUnprofessional:{
        label: 'Driver was Unprofessional',
        width: '180px',
        render: v => r(v),
    },
    driverDidNotFollowMyDeliveryInstructions:{
        label: 'Driver did not follow My Delivery Instructions',
        width: '300px',
        render: v => r(v),
    },
    deliveredToWrongAddress:{
        label: 'Delivered to Wrong Address',
        width: '180px',
        render: v => r(v),
    },
    lateDelivery:{
        label: 'Late Delivery',
        width: '100px',
        render: v => r(v),
    },
    itemDamaged:{
        label: 'Item Damaged',
        width: '80px',
        render: v => r(v),
    },
}

export const AutomatedScorecardCoachingMetricArray = [
    "AMAZON_FICO",
    "AMAZON_SEATBELT_OFF",
    "AMAZON_SPEEDING_EVENT_RATE",
    "AMAZON_DISTRACTIONS_RATE",
    "AMAZON_FOLLOWING_DISTANCE_RATE",
    "AMAZON_SIGN_VIOLATION_RATE",
    "AMAZON_CDF",
    "AMAZON_CDF_DPMO",
    "AMAZON_CED",
    "AMAZON_DCR",
    "AMAZON_DSB",
    "AMAZON_SWC_POD",
    "AMAZON_SWC_AD",
    "AMAZON_SWC_CC",
    "AMAZON_EOC",
    "AMAZON_PPS",
];

export const ReportProperParkingSequenceSchemaColumns = {
    week: {
        label: "Week #",
        width: "80px",
        render: (v, pps) => `${pps.week}/${pps.year}`,
    },
    evaluatedStops: {
        label: "Evaluated Stops",
        width: "100px",
        render: (v) => r(v),
    },
    ppsCompliance: {
        label: "PPS Compliance",
        width: "100px",
        render: (v) => r(v),
    },
    percentagePPSCompliance: {
        label: "% PPS Compliance",
        width: "100px",
        render: (v) => p(v),
    },
    invalidPPS: {
        label: "Invalid PPS",
        width: "100px",
        render: (v) => r(v),
    },
    percentageInvalidPPS: {
        label: "% Invalid PPS",
        width: "100px",
        render: (v) => p(v),
    },
    missingGearInPark: {
        label: "Missing Gear in Park",
        width: "150px",
        render: (v) => r(v),
    },
    percentageMissingGearInPark: {
        label: "% Missing Gear in Park",
        width: "160px",
        render: (v) => p(v),
    },
    missingParkingBrake: {
        label: "Missing Parking Brake",
        width: "150px",
        render: (v) => r(v),
    },
    percentageMissingParkingBrake: {
        label: "% Missing Parking Brake",
        width: "165px",
        render: (v) => p(v),
    },
    totalBreaches: {
        label: "Total Breaches",
        width: "100px",
        render: (v) => r(v),
    },
    percentageTotalBreaches: {
        label: "% Total Breaches",
        width: "100px",
        render: (v) => p(v),
    },
};