import React from 'react'
import { BlockRow } from "../../AppTwilioConversation/BotTaskInteraction";
import { ButtonContainer, Text } from "./BotTaskList";

export function DriverButton({ driver, selectDriver, disabled = false, type = 'Button' }) {
    let workedHours = typeof driver.weekHours.workedHours === 'number' ? driver.weekHours.workedHours.toFixed(3) : 'N/A';
    let scheduledHours = typeof driver.weekHours.scheduledHours === 'number' ? driver.weekHours.scheduledHours : 'N/A';
    if (type !== 'Button') {
        return (
            <ButtonContainer>
                {driver.fullName}{" - "}{workedHours}/{scheduledHours}
            </ButtonContainer>
        );
    }

    return (
        <ButtonContainer onClick={async () => {
            if (selectDriver) {
                try {
                    selectDriver(driver);
                } catch (e) {
                    await alert('Error in selecting Driver.');
                }
            }
        }} disabled={disabled}>
            {driver.fullName}{" - "}{workedHours}/{scheduledHours}
        </ButtonContainer>
    );
}