import React, { useState } from "react";
import { SchedulesAddStateContext, Grid, SchedulesAddDispatchContext } from "../../index";
import { Icon } from "@dspworkplace/ui";
import SkillCell from "../../Drivers/SkillCell";
import DriverCell from "../../Drivers/DriverCell";
import sorter from "../../../../../../../components/SorterFn";
import moment from "moment";

const Drivers = () => {
    const { state } = React.useContext(SchedulesAddStateContext);
    // const schedulesAddDispatchContext = React.useContext(SchedulesAddDispatchContext);
    const [allDrivers, setAllDrivers] = useState(state.drivers);

    const [skillsResult, setSkillsResult] = React.useState("");

    const handleOrder = (fieldName, direction) => {
        const orderedData = sorter(allDrivers, fieldName, !direction);
        setAllDrivers(orderedData);
    };

    const handlerCurrentOrder = (fieldName) => {
        if (!allDrivers.sort || !allDrivers.sort[0]) return 0;
        // eslint-disable-next-line eqeqeq
        return allDrivers.sort && allDrivers.sort[0] == fieldName ? !allDrivers.sort[1] : 0;
    };

    const isCurrentSchedule = (schedule) => {
        if (schedule.isArchive === false) {
            let today = moment().utc().format("YYYY-MM-DD");
            let isRecurring = schedule.isRecurring;
            let startDate = moment.unix(schedule.startDate.timestamp).utc().format("YYYY-MM-DD");
            if (isRecurring === true) {
                if (today >= startDate) {
                    return true;
                }
            } else {
                let endtDate = moment.unix(schedule.endDate.timestamp).utc().format("YYYY-MM-DD");
                if (today >= startDate && endtDate >= today) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    };

    React.useEffect(() => {
        var allSkills = {};
        var skills = "";
        if (state.drivers.length > 0) {
            state.drivers.forEach(function (driver) {
                if (driver.skills && driver.skills.length > 0) {
                    driver.skills.forEach(function (skill) {
                        if (skill.skill.name in allSkills) {
                            allSkills[skill.skill.name] = parseInt(allSkills[skill.skill.name]) + 1;
                        } else {
                            allSkills[skill.skill.name] = 1;
                        }
                    });
                }
            });
        }
        var skills = "";
        if (state.drivers.length > 0) {
            for (const key in allSkills) {
                for (let index = 0; index < state.drivers.length; index++) {
                    if (state.drivers[index].skills && state.drivers[index].skills.includes(key)) {
                        allSkills[key] = allSkills[key] + 1;
                    }
                }
            }
            for (const key in allSkills) {
                if (allSkills[key] > 0) {
                    skills = skills + key + " (" + allSkills[key] + ") ";
                }
            }
            setSkillsResult(skills);
        }
    }, [state.drivers]);

    const getDriverEffectiveDate = (driverId) => {
        const driverWithDate = state.driversEffectiveDate.find((item) => item.driverId == driverId)
        return driverWithDate?.date;
    }
    
    return (
        <Grid>
            <Grid padding="20px 0 8px 0" color="#516F90" fontSize="22px" fontWeight="bold">
                Drivers
            </Grid>
            <Grid templateColumns="280px 320px 180px 200px " borderBottom="1px solid #7C98B6">
                <Grid templateColumns="auto auto" padding="12px 0 12px 40px" columnGap="8px">
                    <Grid
                        onClick={() => {
                            handleOrder("name", handlerCurrentOrder("name"));
                        }}
                    >
                        {" "}
                        Driver
                    </Grid>{" "}
                    <Icon.ArrowDown
                        size="16px"
                        color="#7C98B6"
                        onClick={() => {
                            handleOrder("name", handlerCurrentOrder("name"));
                        }}
                    ></Icon.ArrowDown>
                </Grid>
                <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px">
                    <Grid>Skills</Grid>
                </Grid>
                <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px">
                    <Grid>Current Schedule</Grid>
                </Grid>
                <Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
                    <Grid>Effective Date</Grid>
                </Grid>
                {/*<Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
                            <Grid>Grade</Grid> <Icon.ArrowDown size='16px' color='#7C98B6'></Icon.ArrowDown>
                        </Grid> */}
            </Grid>
            {allDrivers.length > 0 &&
                allDrivers.map((item, index) => {
                    return (
                        <Grid
                            key={index}
                            templateColumns="280px 320px 180px 152px 48px"
                            borderBottom={index === state.drivers.length - 1 ? "0" : "1px dashed #CBD6E2"}
                        >
                            <DriverCell
                                name={item.name}
                                role={item.role}
                                cel={item.cel}
                                image={item.image}
                                padding="8px"
                            ></DriverCell>
                            <SkillCell skills={item.skills} padding="8px 8px 8px 0"></SkillCell>
                            <Grid textDecoration="underline" color="#0071BC">
                                {item.schedule && item.schedule.length > 0
                                    ? item.schedule.map((value, index) => {
                                          if (item.onAddDriver == false) {
                                              if (isCurrentSchedule(value)) {
                                                  if (index === item.schedule.length - 1) {
                                                      return value.name;
                                                  }
                                                  return value.name + ",";
                                              }
                                          } else {
                                              if (index === item.schedule.length - 1) {
                                                  return value.name;
                                              }
                                              return value.name + ",";
                                          }
                                      })
                                    : ""}
                            </Grid>
                            <Grid templateColumns='auto'>{getDriverEffectiveDate(item.id)}</Grid>
                        </Grid>
                    );
                })}
            {allDrivers.length > 0 ? (
                <Grid templateColumns="280px auto" backgroundColor="#FAFAFA" padding="16px 0 16px 0">
                    <Grid color="#516F90" backgroundColor="#FAFAFA" fontSize="16px" padding="0 0 0 8px">
                        Total: {state.drivers.length} drivers
                    </Grid>
                    <Grid color="#516F90" backgroundColor="#FAFAFA" fontSize="16px">
                        Skills: {skillsResult}
                    </Grid>
                </Grid>
            ) : (
                <Grid templateColumns="280px auto" backgroundColor="#FAFAFA" padding="16px 0 16px 0">
                    No driver found.
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(Drivers);
