import React, {useContext, useReducer} from "react";
import styled from 'styled-components';
import {Theme} from "@dspworkplace/ui";

const TabListStyle = styled.div`
    width: 100%;
    display: inline-block;
    
    > h6 {
        display: inline-block;
        font-size: ${Theme.font.medium.size};
        color: ${Theme.colors.secondary};
        padding: 12px 16px;
        font-weight: 400;
        font-family: ${Theme.font.main};
        cursor: pointer;
        position: relative;
        
        &.tabActive {
            font-weight: bold;
            
            &::before {
                content: " ";
                height: 2px;
                background: ${Theme.colors.secondary};
                width: 100%;
                display: block;    
                border: 1px solid transparent;  
                border-radius: ${Theme.defaultRadius};               
                position: absolute;
                left: 0;
                bottom: -2.50px;         
            }
        }
    }
    
    &::after {
        content: " ";
        height: 1px;
        background: ${Theme.colors.neutrals.silver};
        width: 100%;
        display: block;
    }
`;

const TabContext = React.createContext();

export const TabList = ({
    defaultTab = '',
    tabs,
    onChange,
    children,
}) => {
    const [state, dispatch] = useReducer((state, action) => ({
        activeTab: action,
    }), {activeTab: defaultTab})

    return <TabContext.Provider value={{
        activeTab: state.activeTab,
        setActiveTab: tab => {
            if (typeof onChange === 'function')
                try {
                    onChange(tab);
                } catch (e) {}

            dispatch(tab);
        }
    }}>
        <TabListStyle>
            {tabs ? Object.entries(tabs).map(([k, tab]) =>
                <TabListItem tab={k} key={k}>
                    {tab.title}
                </TabListItem>
            ) : null}
            {children}
        </TabListStyle>
        {tabs ? Object.entries(tabs).map(([k, tab]) =>
            <TabListContent showFor={k} key={k}>
                {tab.content}
            </TabListContent>
        ) : null}
    </TabContext.Provider>
}

export const TabListItem = ({
    tab,
    children,
}) => {
    const {activeTab, setActiveTab} = useContext(TabContext);

    return (
        <h6
            className={activeTab === tab ? 'tabActive' : ''}
            onClick={() => setActiveTab(tab)}
        >
            {children}
        </h6>
    )
}

export const TabListContent = ({
    showFor,
    children,
}) => {
    const {activeTab} = useContext(TabContext);

    if (showFor !== activeTab)
        return null;

    return <div>{children}</div>;
}
