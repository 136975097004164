import React, { useState, useEffect, useReducer, useCallback } from 'react';
import styled from 'styled-components';
import { Title, Spacer, Text, Input, Button, Icon, Dropdown } from '@dspworkplace/ui';
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Loading, { useLoading } from "../../../../components/Loading";
import { useAuth } from "../../../../Auth";
import { Table, TableData, TableFooter } from "../../../../components/Table";
import { AccessCheck, IsGranted } from "../../../../security";

const CustomButton = styled.a`
    font-family: circe-rounded;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    background: #EAF0F6;
    color: #0071BC;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    font-size: 21px;
`;

const OrderIco = ({ direction = 'ASC' }) => {
    if (direction === 'DESC') return (<Icon.ArrowUp size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />);
    return (<Icon.ArrowDown size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />)
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'create':
            return { ...state, invoiceTypes: action.invoiceTypes, isLoading: action.isLoading };
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'add':
            return { ...state, invoiceTypes: [...state.invoiceTypes, action.invoiceType], isLoading: action.isLoading };
        case 'setUpdateKey':
            return { ...state, updateKey: action.updateKey }
        case 'update':
            state.invoiceTypes[action.key] = action.invoiceType;
            return { ...state, updateKey: -1 };
        case 'cancel':
            return { ...state, updateKey: -1 };
        case 'filter':
            if (state.filterOrder === 'ASC') {
                state.invoiceTypes.sort((a, b) => b[action.filterName].toString().localeCompare(a[action.filterName].toString(), undefined, { numeric: true }));

                return { ...state, invoiceTypes: state.invoiceTypes, filterOrder: 'DESC', filterName: action.filterName };
            } else {
                state.invoiceTypes.sort((a, b) => a[action.filterName].toString().localeCompare(b[action.filterName].toString(), undefined, { numeric: true }));
            }
            return { ...state, invoiceTypes: state.invoiceTypes, filterOrder: 'ASC', filterName: action.filterName };
        case 'delete':
            state.invoiceTypes.splice(action.key, 1);
            return { ...state }
        default:
            return state;
    }
}

const initialState = {
    rates: [],
    updateKey: -1,
    filterName: 'name',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
}

const App = (localState = '') => {

    const useLocationState = useLocation().state;
    const { api } = useAuth();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setLoading] = useLoading();
    const [data, setData] = useState({ update: -1, invoiceTypes: [] });
    const [filter, setFilter] = useState({ order: { name: 'ASC' } });
    const [optionRates, setOptionRates] = useState({ rates: [] });
    const [optionHours, setOptionHours] = useState({ hours: [] });
    const station = (useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station;


    const getRates = async () => {
        const params = {
            "actions": {
                "response": {
                    "Rate": {
                        "custom": {
                            "functionName": "getRateByStation",
                            "get": "Rates",
                            "criteria": {
                                "station": station.id
                            },
                            "excludes": ["company", "station", "users", "parent", "childrens", "driverRoutes", "payRate", "Rates", "skillRates", "shifts", "drivers"],
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "billableHour",
                                "3": "invoiceType"
                            },
                        }
                    }
                }
            }
        };
        try {
            let response = await api.post('/api/lazy/manage/data', params);
            return new Promise((resolve, reject) => {
                setOptionRates({ ...optionRates, rates: response.data.data.Rates })
            });
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    }

    const getHours = () => {
        let hoursArr = [];
        let temp = [];
        for (let i = 1; i <= 20; i++) {
            temp = {
                'name': i.toString(),
                'value': i.toString()
            }
            hoursArr.push(temp);
        }

        hoursArr.push({
            'name': "Flat Rate",
            'value': "flatRate"
        });

        setOptionHours({ ...optionHours, hours: hoursArr })
    }


    const handleDelete = async (id, key) => {

        const confirmation = window.confirm('Confirm that you want to delete this item');
        if (!confirmation) {
            return false;
        }
        let params = {
            "actions": {
                "delete": {
                    "InvoiceType": {
                        "delete_InvoiceType_1": {
                            "findOneBy": {
                                "id": id
                            }
                        }
                    }
                },
            }
        };
        try {
            await api.post('/api/lazy/manage/data', params);
            dispatch({ type: 'delete', key: key });
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    }


    const filterBy = (sortFieldName, sortFieldType) => { dispatch({ type: 'filter', filterName: sortFieldName, filterType: sortFieldType }); }

    useEffect(() => {
        const getInvoiceTypes = async () => {
            try {
                const params = {
                    "actions": {
                        "response": {
                            "InvoiceType": {
                                "custom": {
                                    "functionName": "getAllInvoiceTypeByStation",
                                    "get":"InvoiceTypes",
                                    "criteria": {
                                        "stationId" : station.id
                                    },
                                    "excludes": ["company"],
                                    "includes": {
                                        "0": "id",
                                        "1": "billableHour",
                                        "2": "name",
                                        "3": "invoiceType",
                                        "rateRule": ["id", "name"],
                                    }
                                }
                            }
                        }
                    }
                };

                let response = await api.post('/api/lazy/manage/data', params);
                let r = response.data.data.InvoiceTypes.map(item => { return { id: item.id, name: item.name, billableHour: item.billableHour, rateId: item.rateRule ? item.rateRule.id : null, rateName: item.rateRule ? item.rateRule.name : null, invoiceType: item.invoiceType } });
                
                dispatch({ type: 'create', invoiceTypes: r.sort((a, b) => a.invoiceType.localeCompare(b.invoiceType)), isLoading: false });
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                dispatch({ type: 'default' });
            }
        }
        getInvoiceTypes();
        getHours();
        getRates();

    }, [filter]);
    return (
        <>
            <Spacer top={5} />
            <Title>Invoice Types</Title>
            <Spacer top={1} />
            <Text>Enter billable events and service types as they appear in Amazon's Work Summary Tool and Invoices.</Text>
            <Spacer top={5} />

            <Table>
                <li className='header'>
                    <TableData order={1} width='152px' onClick={() => { filterBy('invoiceType', 'str') }}>Invoice Type {state.filterOrder && state.filterName === 'invoiceType' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData order={1} width='312px' onClick={() => { filterBy('name', 'str') }}>Name {state.filterOrder && state.filterName === 'name' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData order={1} width='152px' onClick={() => { filterBy('billableHour', 'str') }}>Billable Hours {state.filterOrder && state.filterName === 'billableHour' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />}  </TableData>
                    <TableData order={1} width='312px' onClick={() => { filterBy('rateName', 'str') }}>Rate Rule {state.filterOrder && state.filterName === 'rateName' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }} />
                </li>

                {/*<div>*/}
                {state.isLoading && <Loading style={{ height: '80px', margin: '20px auto', display: 'block' }} />}
                {!state.isLoading && state.invoiceTypes &&
                    state.invoiceTypes.map((invoiceType, key) => {

                        if (key == state.updateKey) {
                            return (
                                <li><AddForm key={key} index={key} dispatch={dispatch} data={state} optionRates={optionRates} optionHours={optionHours} station={station} /></li>
                            );
                        }

                        return (
                            <li key={key}>
                                <TableData width='152px'>{invoiceType.invoiceType}</TableData>
                                <TableData width='312px'>{invoiceType.name}</TableData>
                                <TableData width='152px'>{(invoiceType.billableHour == 'flatRate') ? 'Flat Rate' : (invoiceType.billableHour + ' Hr' + (invoiceType.billableHour == 1 ? '' : 's'))}</TableData>
                                <TableData width='312px'>{invoiceType.rateName}</TableData>
                                <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }}>
                                    <IsGranted feature={AccessCheck.features.ST_INVOICE_TYPES} ops={AccessCheck.OPS.U}>
                                    <Spacer inline right={3}>
                                        <Icon.Edit size='20px' color='#7C98B6'
                                            style={{ position: 'relative', top: '3px' }} onClick={() => { dispatch({ type: 'setUpdateKey', updateKey: key }) }} />
                                    </Spacer>
                                    </IsGranted>  
                                    <IsGranted feature={AccessCheck.features.ST_INVOICE_TYPES} ops={AccessCheck.OPS.U}>
                                        <Icon.Times size='20px' color='#7C98B6' onClick={() => { handleDelete(invoiceType.id, key) }} />
                                    </IsGranted>    
                                </TableData>
                            </li>
                        )

                    })
                }
                {/*</div>*/}
                {!state.isLoading && state.invoiceTypes && <TableFooter sticky={true}><AddForm dispatch={dispatch} data={state} optionRates={optionRates} optionHours={optionHours} station={station} /></TableFooter>}

            </Table>
        </>
    );
}

const AddForm = ({ index, dispatch, data, optionRates, optionHours, station }) => {
    const { api, logout } = useAuth();
    let defaultHour;
    let defaultInvoiceType;
    let defaultRate;
    let defaultValues;
    let selected;
    let RateObj = [];
    let HoursObj = optionHours.hours;
    if (index > -1) {
        selected = data.invoiceTypes[index];
        defaultHour = selected.billableHour;
        defaultInvoiceType = selected.invoiceType;
        defaultRate = selected.rateId;
        defaultValues =
        {
            name: selected.name
        }
    }
    const { register, handleSubmit, errors, reset, formState } = useForm({ defaultValues: defaultValues });
    const [formData, setFormData] = useState({ name: '', invoiceType: '', billableHour: '', rateRule: '' });
    const [isCancelButton, setIsCancelButton] = useState(false);

    let temp = [];
    Object.keys(optionRates.rates).forEach(function (index) {
        temp = {
            'name': optionRates.rates[index].name.toString(),
            'value': optionRates.rates[index].id.toString(),
        }
        RateObj.push(temp);
    });

    const handleEdit = async (form, selected, index, currentRate) => {
        try {
            let params =
            {
                "actions": {
                    "update": {
                        "InvoiceType": {
                            "invoiceType_1": {
                                "findBy": {
                                    "id": selected.id
                                },
                                "updateRecord": {
                                    "name": form.name,
                                    "billableHour": form.billableHour,
                                    "rateRule": form.rateRule,
                                    "invoiceType": form.invoiceType,
                                }
                            }
                        }
                    }
                }
            }

            api.post('/api/lazy/manage/data', params).then(result => dispatch({ type: 'update', invoiceType: updatedInvoiceType, key: index }));
            const updatedInvoiceType = { id: selected.id, name: form.name, billableHour: form.billableHour, rateId: currentRate.id, rateName: currentRate.name, invoiceType: form.invoiceType }
        } catch (error) {
            dispatch({ type: 'default' });
            alert("Error to update rate. Login again, please").then(() => {

            });

        }
    }
    const cancelBtn = async () => {
        if (!selected) {
            reset({ name: '', invoiceType: '', billableHour: '', rateRule: '' });
            setIsCancelButton(false);
        } else {
            dispatch({ type: 'cancel' });
        }
        return false;
    }

    const handleOnChange = useCallback(event => {
        if (event.target) {
            if (event.target.name === 'name') { formData.name = event.target.value; }
            if (formData.name.length > 0 || formData.invoiceType.length > 0 || formData.billableHour.length > 0 || formData.rateRule.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }
        } else {
            formData.invoiceType = event.value;
            if (formData.name.length > 0 || formData.invoiceType.length > 0 || formData.billableHour.length > 0 || formData.rateRule.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }

        }
    });
    const onSubmit = async (form) => {
        form['station'] = station.id;

        let currentRate;
        if (RateObj.length > 0) {
            Object.keys(RateObj).forEach(function (key) {
                if (optionRates.rates[key].id == form.rateRule) {
                    currentRate = optionRates.rates[key];
                }
            });
        }

        if (selected) {
            await handleEdit(form, selected, index, currentRate);
        } else {
            let params = {
                "actions": {
                    "create": {
                        "InvoiceType": {
                            "InvoiceType_1": form
                        }
                    }
                }
            };

            try {
                let response = await api.post('/api/lazy/manage/data', params);
                if (response.status == 200) {
                    const newInvoiceType = { id: response.data.data.responseId, name: form.name, billableHour: form.billableHour, rateId: currentRate.id, rateName: currentRate.name, invoiceType: form.invoiceType }
                    dispatch({ type: 'add', invoiceType: newInvoiceType, isLoading: false });
                } else {
                    alert('Error to create new skill');
                }
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
        reset({ name: '', rateRule: '', billableHour: '', invoiceType: '' });
        if (dropReset.invoiceType) dropReset.invoiceType();
        if (dropReset.billableHour) dropReset.billableHour();
        if (dropReset.rateRule) dropReset.rateRule();
    }

    let invoiceTypeArr = [
        { value: 'AMZL Late Cancellation', name: 'AMZL Late Cancellation' },
        { value: 'DSP Late Cancellation', name: 'DSP Late Cancellation' },
        { value: 'Service Type', name: 'Service Type' },
    ];

    let dropReset = { invoiceType: undefined, billableHour: undefined, rateRule: undefined };

    return (
        <div>
            {/*<TableData  width='150px'>*/}
            <Dropdown
                name='invoiceType'
                ref={register({ required: 'This field can not be empty' })}
                placeholder='Select Invoice Type'
                error={formState.dirty && errors.invoiceType && errors.invoiceType.message}
                size={'small'}
                defaultValue={defaultInvoiceType}
                options={invoiceTypeArr}
                onChange={handleOnChange}
                openTo={index >= 0 ? 'bottom' : 'top'}
                visibleOptionsQty={3}
                resetCallback={(reset) => dropReset.invoiceType = reset}
            />
            <Spacer inline right={3} />
            {/*</TableData>*/}
            {/*<TableData  width='150px'>*/}
            <Input
                name='name'
                ref={register({ required: 'This field can not be empty' })}
                error={errors.name && errors.name.message}
                // size={'small'}
                onChange={handleOnChange}
            />
            <Spacer inline right={3} />
            {/*</TableData>*/}
            {/*<TableData  width='150px'>*/}
            <Dropdown
                name='billableHour'
                ref={register({ required: 'This field can not be empty' })}
                placeholder='Select Billable Hour'
                error={formState.dirty && errors.billableHour && errors.billableHour.message}
                size={'small'}
                defaultValue={defaultHour}
                options={HoursObj}
                onChange={handleOnChange}
                openTo={index >= 0 ? 'bottom' : 'top'}
                visibleOptionsQty={3}
                resetCallback={(reset) => dropReset.billableHour = reset}
            />
            <Spacer inline right={3} />
            {/*</TableData>*/}
            {/*<TableData  width='150px'>*/}
            <Dropdown
                name='rateRule'
                ref={register({ required: 'This field can not be empty' })}
                placeholder='Select Rate'
                error={formState.dirty && errors.rateRule && errors.rateRule.message}
                // size={'small'}
                defaultValue={defaultRate}
                options={RateObj}
                onChange={handleOnChange}
                openTo={index >= 0 ? 'bottom' : 'top'}
                visibleOptionsQty={3}
                resetCallback={(reset) => dropReset.rateRule = reset}
            />
            <Spacer inline right={3} />
            {/*</TableData>*/}
            {/*<TableData width='80px' style={{verticalAlign:'middle',height:'40px',marginTop:(errors.name ? '-23px' : '0px' )}}>*/}
            <Button type='primary' style={{ 'marginRight': '10px' }} onClick={handleSubmit(onSubmit)}>{selected ? 'Save' : 'Add'}</Button>
            {isCancelButton || selected ? <CustomButton onClick={() => { cancelBtn() }} >X</CustomButton> : ''}
            {/*</TableData>*/}
        </div>
    )
}

export default App;
