import React, {useState, useCallback} from 'react';
import {Channel} from "twilio-chat/lib/channel";
import styled from 'styled-components';
// @ts-ignore
import {Button, Icon} from '@dspworkplace/ui';
// @ts-ignore
import ChannelIcon from "./ChannelIcon";
// @ts-ignore
import ChannelTitle from "./ChannelTitle";
// @ts-ignore
import ChannelSubtitle from "./ChannelSubtitle";
// @ts-ignore
import ChannelBadge from "./ChannelBadge";
// @ts-ignore
import {TextInformationContainer} from "./styles";
// @ts-ignore
import {useDispatch, useTrackedState} from "./store";
import Loading from "./Loading";
import {setMessagesConsumed, updateActiveChannel} from "./utils";
import {Client} from "twilio-chat";

// @ts-ignore
const ChannelContainer = styled.div`
    display: grid;
    width: 268px;
    height: 56px;
    grid-template-columns: 40px auto 20px 12px;
    grid-column-gap: 6px;
    padding-top: 8px;
    padding-Bottom: 8px;
    align-items: center;
    border-Bottom: 1px dashed #7C98B6;
    margin-left: 16px;
    margin-right: 16px;
`;
// @ts-ignore
const ArrowRightButton = styled(Button)`
    width: 12px;
    height: 12px;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    &:active, &:focus, &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071BC;
        border-radius: 2px;
    }
`;

type Props = {
    channel: Channel;
    kind: string;
    client?: Client;
    closedCount?: number
}

const ChannelRow: React.FC<Props> = ({channel, kind, closedCount}) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const [loading, setLoading] = useState(false);
    // console.log(`Render ChannelRow ${channel.sid} ${JSON.stringify({loading})}`);

    const openChannel = useCallback(
        async () => {
            if (channel) {
                setLoading(true);
                if (channel.attributes['kind'] !== 'Bot') {
                    await setMessagesConsumed(channel);
                    await updateActiveChannel(channel);
                }
                dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: channel});
                setLoading(false);
            }
        }, [channel, dispatch, setLoading],
    );

    // useEffect(() => {
    //     console.log(`Render ChannelRow ${channel?.friendlyName}`);
    // })

    // check open
    // useEffect(() => {
    //     console.log(`Render ChannelRow useEffect ${channel?.friendlyName}`);
    //     const abortController = new AbortController();
    //     if (channel) {
    //         setLoading(false);
    //         if (channel.attributes['kind'] !== 'Bot') {
    //             if (activeChannels.length > 0) {
    //                 setLoading(true);
    //                 let activeChannel = activeChannels.find(item => item.sid === channel.sid);
    //                 setLoading(false);
    //                 if (activeChannel) {
    //                     // devLog({
    //                     //     activeChannels: activeChannels.map(item => ({sid: item.sid, item: item})),
    //                     //     count: activeChannels.length,
    //                     //     activeChannel,
    //                     //     channel
    //                     // });
    //                     setNotifications(0);
    //                 }
    //             }
    //         }
    //     }
    //     return () => {
    //         abortController.abort();
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [channel, setLoading, activeChannels.length]);

    if (loading) {
        return <Loading containerHeight={'56px'} containerWidth={'268px'} imgHeight={'auto'} imgWidth={'36px'}/>
    }

    return (
        <ChannelContainer
            data-channel={channel.sid}
            onClick={openChannel}
        >
            <ChannelIcon channel={channel} kind={kind}/>
            {/*<div>{channel.friendlyName}</div>*/}
            {/*<div>{JSON.stringify(channel.attributes)}</div>*/}
            <TextInformationContainer>
                <ChannelTitle channel={channel} kind={kind}/>
                <ChannelSubtitle channel={channel} kind={kind}/>
                {/*<div>{channel.friendlyName}</div>*/}
                {/*<div>{channel.sid}</div>*/}
            </TextInformationContainer>
            <ChannelBadge channel={channel} closedCount={closedCount} />
            <ArrowRightButton type='cancel'><Icon.ArrowRight size='12px'/></ArrowRightButton>
        </ChannelContainer>
    );
}

export default ChannelRow;