import React, {useContext, useState} from 'react';
import {Theme, Spacer, Text, Button, Icon, ThirdTitle} from '@dspworkplace/ui';
import {SchedulesContext} from "./index";
import {Table, TableData, TableFooter, TableHeader} from "../../../../components/Table";
import { AccessCheck, IsGranted } from "../../../../security";

const ListView = ({deleteSchedules}) => {
    const schedules = useContext(SchedulesContext);
    const [isEdit,setIsEdit] = useState({update:-1,copy:-1});
    const editSchedules = async (data)=>{
        setIsEdit({update:1});
    }

    return (
        <Table style={{width: '100%'}}>
            <TableHeader
                headers={[
                    {width: '300px', label: 'Name'},
                    {width: '140px', label: 'Drivers'},
                    {width: '140px', label: 'Duration'},
                    {width: '140px', label: 'Recurring'},
                    {width: 'auto', label: 'Shift Types'},
                ]}
            />
            {schedules.state.schedules.map((item, index)=>
                <li key={index} style={{display: 'flex'}}>
                    <TableData width='300px'>{ item.isExpired == true && 'EXPIRED -' }{item.name}</TableData>
                    <TableData width='140px'>{item.drivers}</TableData>
                    <TableData width='140px'>{item.weeks}</TableData>
                    <TableData width='140px'>
                        {item.type ? (
                            <Icon.CheckNarrow size='20px' color={Theme.colors.info.text}/>
                        ) : (
                            <Icon.TimesNarrow size='20px' color={Theme.colors.neutrals.silver}/>
                        )}
                    </TableData>
                    <TableData width='auto'>{item.shiftTypes}</TableData>
                    <TableData width='auto' style={{ marginLeft: 'auto' }}>
                        <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.U}>
                        <Icon.Copy size='20px' color={Theme.colors.info.border} onClick={()=>{schedules.dispatch({'type': 'copy', view: 'Add', copyID:item})}}/>
                        <Spacer inline right={2}/>
                        <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={()=>{schedules.dispatch({'type': 'update', view: 'Add', editID:item})}}/>
                        </IsGranted>
                        <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.D}>
                        <Spacer inline right={2} />
                        <Icon.Trash size='20px' color={Theme.colors.error.text} onClick={()=>{deleteSchedules(item,index)}}/>
                        </IsGranted>
                    </TableData>
                </li>
            )}
            <TableFooter sticky={true}>
                <ThirdTitle style={{fontWeight: 'normal'}}>
                    {schedules.state.schedules.length} schedules
                </ThirdTitle>
                <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.U}>
                <Button type={'primary'} onClick={()=>{
                    schedules.dispatch({
                        type: 'setView',
                        view: 'Add'
                    })
                }}>Add Schedule</Button>
                </IsGranted>
            </TableFooter>
        </Table>
    );
}

export default ListView;
