import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { CloseIcon, LoadingIndicator, useChatContext } from 'stream-chat-react';
import styled from "styled-components";
import { FlexScroll, Grid, ReturnButton, TagInputContainer, Text } from '../styles';
import { Button, Icon, TagInput, async } from '@dspworkplace/ui';
import { useDispatch, useTrackedState } from '../store';
import { initialState, reducer } from '../DMModal';
import { createDuoChannel, getTwilioUsersEmployeeInformation } from '../utils';
import { getUserId, showErrorToast } from '../../Utilities';
import Member from './Member';
import { Divider } from '../../components/UI';
import Loading from '../../components/Loading';

function NewDirectMessageAdd({ isMenConstract, isPage, isDirectMessageModalOpen, setDirectMessageModalOPen }) {
    const dispatch = useDispatch();
    const { chatClient } = useTrackedState();
    const [directMessageState, directMessageDispatch] = useReducer(reducer, initialState);
    const { newMatchedUsers, filterForNewUsers, identities, selectedUser } = directMessageState;
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(1);
    const { setActiveChannel } = useChatContext();

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        if (chatClient) {
            getTwilioUsersEmployeeInformation()
                .then((result) => {
                    let filterUsers = result.users.filter((item) => {
                        return !identities.includes(item.identity);
                    });
                    directMessageDispatch({
                        type: "SET_NEW_USERS",
                        users: filterUsers,
                        skills: result.skills,
                        roles: result.roles,
                        stations: result.stations,
                        schedules: result.schedules,
                    });
                    setLoading(false);
                })
                .catch(async e => {
                    await showErrorToast(e, 'Error fetching employees information.');
                    setLoading(false);
                });
        }
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directMessageDispatch, identities, refresh]);

    const handleTagChange = useCallback(data => {
        directMessageDispatch({ type: "NEW_MATCHED_USERS", data: data });
    }, [directMessageDispatch]);

    const chatWithNewMember = async (user) => {
        try {
            if (!chatClient) return;
            if (chatClient) {
                if (selectedUser) {
                    await setLoading(true);
                    await setDirectMessageModalOPen(false)
                    const channel = await createDuoChannel(chatClient, user);
                    // dispatch({type: 'REMOVE_CHANNEL', channel: newDuoChannel});
                    // dispatch({type: "ADD_CHANNEL", channel: newDuoChannel});
                    // dispatch({type: 'UPDATE_CHANNEL', channel: newDuoChannel});
                    // dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: newDuoChannel});
                    setActiveChannel(channel)
                    let isRemoved = await channel?.data?.isRemoved || [];
                    if (isRemoved.includes(chatClient.userID)) {
                        await isRemoved.splice(isRemoved.indexOf(chatClient.userID), 1);
                    }
                    await channel.updatePartial({
                        set: {
                            isRemoved: isRemoved
                        }
                    });
                    channel.watch();
                    await setLoading(false);
                }
                else
                    await showErrorToast("Error", 'Please select user');
            }
        } catch (e) {
            await showErrorToast(e, 'Error trying to Direct Message user.');
            setLoading(false);
            setDirectMessageModalOPen(false)
        }
    }

    const selectMember = useCallback(
        userIdentifier => {
            console.log(userIdentifier);
            directMessageDispatch({ type: "SET_SELECTED_USER", selectedUser: userIdentifier });
        },
        [directMessageDispatch]
    );

    const removeMember = useCallback(
        () => {
            directMessageDispatch({ type: "REMOVE_USER" });
        },
        [directMessageDispatch]
    );

    return (
        <>
            <div style={{
                width: isMenConstract && isPage ? '95%' : isMenConstract && !isPage ? '91%' : isPage ? '82%' : "65%",
                float: "left", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', height: '100%'
            }} >
                <HeaderContainer>
                    <Text color={'#000000'} fontWeight={"500"} margin="0px" fontSize="18px" >
                        Direct Messages</Text>
                    <div style={{ cursor: 'pointer', }} onClick={() => setDirectMessageModalOPen(false)} >
                        <CloseIcon />
                    </div>
                </HeaderContainer>

                {loading ?
                    <div style={{ display: "flex", height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }} >
                        <Loading height={'60px'} width="60px" />
                    </div>
                    :
                    <div style={{ padding: '16px', backgroundColor: 'white' }} >
                        <TagInputContainer width="auto" borderRadius="7px" templateColumns="100% auto">
                            <TagInput
                                options={filterForNewUsers}
                                size="big"
                                onChange={handleTagChange}
                                name={'tags'}
                                placeholder="Find by name, email, skill, role, station and schedule:"
                            />
                        </TagInputContainer>

                        <Text
                            margin="30px 0 15px 0"
                            fontSize="16px"
                            color="#0071BC"
                        >
                            Start chat with</Text>
                        <Grid rowGap="8px" templateRows="auto auto" height={"auto"}>
                            <FlexScroll height="100%" width="100%" style={{ maxHeight: "712px" }} >
                                {selectedUser && <Text margin="10px" fontSize="14px" color="#0071BC">Current Member</Text>}
                                {newMatchedUsers.map(item => {
                                    if (selectedUser == item.streamIdentity) {
                                        return (
                                            <Member
                                                item={item}
                                                selectedUser={selectedUser}
                                                type="newDirectMessage"
                                                remove={selectedUser == item.streamIdentity ? true : false}
                                                removeMember={() => removeMember()}
                                            />
                                        )
                                    }
                                })}
                                <Text margin="10px" fontSize="14px" color="#0071BC">Select New User</Text>
                                {newMatchedUsers.map(item => {
                                    if (selectedUser != item.streamIdentity) {
                                        if (item.userId == getUserId())
                                            return null
                                        return (
                                            <Member
                                                item={item}
                                                selectedUser={selectedUser}
                                                callback={() => selectMember(item.streamIdentity)}
                                                type="newDirectMessage"
                                            />
                                        )
                                    }
                                })}
                            </FlexScroll>
                        </Grid>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'absolute', right: '40px', bottom: '10px', }} >
                            <div style={{ backgroundColor: selectedUser ? '#0071BC' : '#CDCED0', height: '35px', width: '150px', display: 'flex', justifyContent: 'center', borderRadius: '5px', cursor: selectedUser ? 'pointer' : 'none' }}
                                onClick={() => selectedUser ? chatWithNewMember(selectedUser) : null}  >
                                <Text textAlign="center" color='white' fontWeight="500" >Start Chat</Text>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default NewDirectMessageAdd

export const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 70px;
min-height: 70px;
top: 0;
display: flex;
align-items: center;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
justify-content: space-between;
`;