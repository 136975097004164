import React, {Dispatch, useCallback, useEffect, useRef, useState} from "react";
import {useTrackedState, useDispatch} from "./store";
import {NewChannelAction, NewChannelState} from "./CGCOptions";
import {FlexScroll, Grid, TagInputContainer, TextInformationContainer} from "./styles";
import ChannelIcon from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import {TagInput} from "@dspworkplace/ui";
import {LoadingView} from "../components/Loading";
import {Channel} from "twilio-chat/lib/channel";

type Props = {
    newChannelModalState: NewChannelState;
    newChannelModalDispatch: Dispatch<NewChannelAction>;
    closeModal: () => void;
};

function CGCSearchGroupChannels(props: Props) {
    const {newChannelModalState, newChannelModalDispatch, closeModal} = props;
    const state = useTrackedState();
    const {groupChannels} = state;
    const dispatch = useDispatch();
    const {client} = state;
    const [loading, setLoading] = useState(true);
    const {searchGroupChannelsNames, searchGroupChannelsMatched} = newChannelModalState;
    const mounted = useRef(false);
    const groupChannelsCount = useRef(groupChannels.length);

    useEffect(() => {
        const abortController = new AbortController();
        if (mounted.current) return;
        if (client && groupChannels) {
            mounted.current = true;
            groupChannelsCount.current = groupChannels.length;
            newChannelModalDispatch({type: "SET_GROUP_CHANNELS", searchGroupChannels: groupChannels});
            setLoading(false);
        }
        return () => {
            abortController.abort();
        };
    }, [client, groupChannels, newChannelModalDispatch]);

    useEffect(() => {
        const abortController = new AbortController();
        if (client && groupChannels.length > groupChannelsCount.current) {
            newChannelModalDispatch({type: "ADD_GROUP_CHANNEL", searchGroupChannel: groupChannels[0]});
        }
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newChannelModalDispatch, groupChannels.length]);

    const openChannel = useCallback(
        (item: Channel) => () => {
            if (item && item.sid) {
                setLoading(true);
                dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: item});
                setLoading(false);
                closeModal();
            }
        },
        [closeModal, dispatch]
    );

    const handleTagChange = useCallback(
        data => {
            newChannelModalDispatch({type: "SEARCH_GROUP_CHANNELS", data: data});
        },
        [newChannelModalDispatch]
    );

    if (loading) {
        return <LoadingView containerHeight={"400px"} containerWidth={"100%"} imgHeight={"150px"}/>;
    }

    return (
        <Grid templateRows={"auto auto"} rowGap={"16px"}>
            <TagInputContainer templateRows={"auto"} width={'658px'}>
                <TagInput
                    label="Search Group Channels:"
                    options={searchGroupChannelsNames}
                    size="big"
                    onChange={handleTagChange}
                    name={'groups'}
                />
            </TagInputContainer>
            <FlexScroll height="300px" width="100%">
                <Grid templateColumns={"auto auto auto"} columnGap={"8px"} rowGap={"8px"}>
                    {searchGroupChannelsMatched.map(item => {
                        return (
                            <Grid
                                key={item.sid}
                                templateColumns={"40px auto"}
                                height={"56px"}
                                columnGap={"8px"}
                                padding={"4px"}
                                border={"1px dashed #7C98B6"}
                                width={"100%"}
                                borderRadius={"4px"}
                                onClick={openChannel(item)}
                            >
                                <ChannelIcon channel={item} kind={item.attributes['kind']}/>
                                <TextInformationContainer>
                                    <ChannelTitle channel={item} kind={item.attributes['kind']}/>
                                    <ChannelSubtitle channel={item} kind={item.attributes['kind']}/>
                                </TextInformationContainer>
                            </Grid>
                        );
                    })}
                </Grid>
            </FlexScroll>
        </Grid>
    );
}

export default CGCSearchGroupChannels;
