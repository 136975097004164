import {atomFamily, selectorFamily} from "recoil";
import {VMPlanType} from "./VehicleMaintenancePlanType";

export type VMTemplateCategory = {
    label: string
    value: number
    isLastServiceDateRequired:string
    isLastMileServiceRequired:string
}

const VMPlanCategoryState = atomFamily<VMTemplateCategory[], number>({
    key: 'VMPlanCategoryState',
    default: []
})

export const VMPlanCategory = selectorFamily<VMTemplateCategory[], number>({
    key: 'VMPlanCategory',
    get: planID => ({get}) => {
        if(!planID) return [];
        // const cached = get(VMPlanCategoryState(planID))
        // if (cached.length > 0) return cached
        return get(VMPlanType).find(plan => plan.value === planID)?.children || []
    },
    set: planID => ({set,get}, newCategory) => {
        const categories = get(VMPlanCategory(planID))
        const newCategories = [...categories,newCategory]
        // @ts-ignore
        set(VMPlanCategoryState(planID),newCategories)
    }
})