import React, {Dispatch, useCallback, useEffect} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import Item from "./Item";
import {ContainerItemsType, ActionLoadOutRTSTemplate, useStoreReducer} from './store';
import SectionTitle from "./SectionTitle";
import {useSelectedStation} from "../../../components/StationSelector";
import AddSection from "./AddSection";
import {updateTemplate} from "./util";
import Introduction from "./Inroduction";
import Updated from "./Updated";

type ResultDrag = {
    combine: null;
    destination: {
        droppableId: string;
        index: number;
    };
    index: number;
    draggableId: string;
    mode: string;
    reason: string;
    source: {
        index: number;
        droppableId: string;
    };
    type: string;
}


const onDragEnd = async (result: ResultDrag, template: Array<ContainerItemsType>, setTemplate: (data: Array<ContainerItemsType>) => void, dispatch: Dispatch<ActionLoadOutRTSTemplate>, stationId: number, scenario: string, id: number) => {
    console.log('onDragEnd');
    dispatch({type: 'SET_LOADING', loading: true});
    if (!result.destination) {
        dispatch({type: 'SET_LOADING', loading: false});
        return;
    }
    const {source, destination} = result;
    let templateUpdated;
    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = template.find(e => JSON.stringify(e.id) === source.droppableId);
        const destColumn = template.find(e => JSON.stringify(e.id) === destination.droppableId);// columns[destination.droppableId];
        const sourceItems = [...sourceColumn!.items];
        const destItems = [...destColumn!.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        templateUpdated = template.map(e => {
            if (JSON.stringify(e.id) === source.droppableId) {
                return {...e, items: sourceItems};
            }
            if (JSON.stringify(e.id) === destination.droppableId) {
                return {...e, items: destItems};
            }
            return e;
        })
        setTemplate(templateUpdated);

    } else {
        const column = template.find(e => JSON.stringify(e.id) === source.droppableId);//columns[source.droppableId];
        const copiedItems = [...column!.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        templateUpdated = template.map(e => {
            if (JSON.stringify(e.id) === source.droppableId) {
                return {...e, items: copiedItems};
            }
            return e;
        })
        setTemplate(templateUpdated);
    }
    let res = await updateTemplate(stationId, templateUpdated, scenario, id);
    dispatch({
        type: 'SET_TEMPLATE',
        template: templateUpdated,
        stationId: stationId,
        updatedBy: res.updatedBy,
        updatedAt: res.updatedAt
    });

};

const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    height: calc(100vh - 150px);
    width: calc(100vw - 300px - 50px);
    padding: 0;
`;


const MainContainerTemplate: React.FC<{ scenario: string }> = ({scenario}) => {
    const [stationId] = useSelectedStation();
    const {appState, appDispatch} = useStoreReducer();
    let template = appState.template;

    const setTemplate = useCallback(
        (data) => {
            appDispatch({type: 'SET_TEMPLATE', template: data, stationId: stationId})
        },
        [stationId],
    );

    useEffect(() => {
        if (!appState.id) {
            alert('Error');
        }
    }, [appState.id]);


    return (
        <Container id={'template'}>
            <AddSection/>
            <Updated friendlyName={appState.updatedBy && appState.updatedBy.friendlyName} updatedAt={appState.updatedAt}/>
            <Introduction introduction={appState.introduction!} scenario={scenario} stationId={stationId}/>
            <DragDropContext
                onDragEnd={async result => {
                    appDispatch({type: 'SET_LOADING', loading: true});
                    await onDragEnd(result, template, setTemplate, appDispatch, stationId, scenario, appState.id!);
                }}
            >
                {template.map((e, i) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                            key={e.id}
                        >
                            <SectionTitle section={e} index={i}/>
                            <div style={{margin: 8, width: '100%'}}
                                 id={'section' + JSON.stringify(i + 1)}>
                                <Droppable droppableId={JSON.stringify(e.id)} key={e.id}>
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    background: snapshot.isDraggingOver
                                                        ? "#F5F5F5"
                                                        : "rgba(0,0,0,0.2)",
                                                    padding: 8,
                                                    width: '100%',
                                                    minHeight: 300,
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                {e.items.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={JSON.stringify(item.id)}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            padding: 16,
                                                                            borderRadius: '2px',
                                                                            margin: "8px",
                                                                            minHeight: "48px",
                                                                            backgroundColor: snapshot.isDragging
                                                                                ? "#EAF0F6"
                                                                                : "#FFFFFF",
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                    >
                                                                        <Item label={item.content.name}
                                                                              provided={provided}
                                                                              snapshot={snapshot} item={item}
                                                                              type={item.type} sectionId={e.id}
                                                                              stationId={stationId}
                                                                              scenario={scenario}
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        </div>
                    );
                })}
            </DragDropContext>
            <div style={{marginTop: '300px'}}/>
        </Container>
    );
}

export default React.memo(MainContainerTemplate);