import React, {createContext, useReducer} from 'react';
import DevicesReducer from './reducer';
import {useSelectedStation} from "../../components/StationSelector";

const DevicesStore = createContext({});

const DevicesProvider = ({children}) => {
    const [selectedStation] = useSelectedStation();

    const useDevicesState = useReducer(
        DevicesReducer,
        {
            sortBy: [],
            filter: {isPersonalDevice: 3},
            selectedStation: Array.isArray(selectedStation) ? selectedStation[0] : selectedStation,
            search: '',
            devices: [],
            list: [],
            loading: false,
            androidVersion: null,
            iosVersion: null,
        }
    );

    return (
        <DevicesStore.Provider value={useDevicesState}>
            {children}
        </DevicesStore.Provider>
    )
};

export {DevicesProvider};
export default DevicesStore;