import React, {useEffect, useState, useRef} from 'react';
import { Theme, Icon, SubTitle} from "@dspworkplace/ui";
import {useModal} from '../../components/Modal';
import API from "./api";
import {toast} from "../../components/Toasts";
import Timeline from '../../components/Timeline';
import { Label } from "../../components/UI";

const TimelineButton = ({station,date}) => {
    const [visible, setVisible] = useModal(false);

    return (
        <>
            <Icon.TimeLine size={'20px'} color={Theme.colors.info.border} style={{ cursor: 'pointer' , float: 'right' }} onClick={()=>{
                setVisible(!visible)
            }}/>
            {visible  && <SummaryTimeline
                visible={true}
                toggle={setVisible}
                station={station}
                date={date}
            />}
        </>
    )
}

const SummaryTimeline = ({
    visible,
    toggle,
    station,
    date
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (station && date) {
            API.call({
                actions: {
                    response: {
                        StationNarrativeHistory: {
                            custom: {
                                functionName: "getHistory",
                                get: "timeline",
                                criteria: {
                                    station: station,
                                    date: date,
                                    timezoneOffset: new Date().getTimezoneOffset(),
                                }
                            }
                        }
                    }
                }
            }).then(
                response => {
                    if (!response)
                        return;
    
                    setData(response.data.data.timeline);
                    setLoading(false);
                },
                error => {
                    toggle();
    
                    if (error.toString() !== 'Cancel')
                        toast({
                            type: 'error',
                            title: 'Error loading timeline',
                            content: error.toString()
                        });
                }
            )
        }else{
            setData([]);
        }

        return API.cancel;
    }, []);

    return (
        <Timeline
            visible={visible}
            setVisible={toggle}
            loading={loading}
            title={
                <SubTitle>
                    {'Station Narrative Timeline'}
                    <Label style={{ lineHeight: 1 }}>
                        <small>{date}</small>
                    </Label>
                </SubTitle>
            }
            data={data}
        />
    )
}



export { TimelineButton };
