import React, {createContext, useReducer} from "react";
import ScorecardReducer from "./reducer";
import {Wrapper} from "../../components/UI";
import {Spacer} from "@dspworkplace/ui";

const ScorecardContext = createContext({});

const ScorecardProvider = ({children}) => {
    const reducer = useReducer(
        ScorecardReducer,
        {
            documents: [],
            allDocuments: []
        }
    );

    return (
        <ScorecardContext.Provider value={reducer}>
            <Spacer top={5}/>
            <Wrapper>
                {children}
            </Wrapper>
        </ScorecardContext.Provider>
    )
};

export {ScorecardProvider};
export default ScorecardContext;