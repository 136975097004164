export const IS_DEV = process.env.NODE_ENV !== 'production';

export const IS_DEV_ENV = process.env.NODE_ENV === "development" || window.location.href.match("devapp");

export function devLog(message, ...optionalParams) {
    if (IS_DEV) {
        //console.log(message, ...optionalParams);
    }
}

export const googleMapsApiKey = "AIzaSyAS5ZL-EkYYKUHqlzQJneR5YfKrrCnXobA";