import React from "react";
import { SchedulesAddStateContext, Grid } from "../../index";
import Week from "./Week";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Design = () => {
    const { state } = React.useContext(SchedulesAddStateContext);
    const { shiftTypes, weekArr } = state.design;
    return (
        <Grid>
            <Grid templateColumns="70px 196px repeat(7, 104px)" templateRows="42px" borderBottom="1px solid #7C98B6">
                <Grid borderRight="1px solid #CBD6E2" color="#707070" justifyContent="center" height="100%">
                    Week
                </Grid>
                <Grid borderRight="1px solid #CBD6E2" color="#707070" padding="0 0 0 12px" height="100%">
                    Shift Type
                </Grid>
                {days.map((item, index) => {
                    return (
                        <Grid key={index + 100} color="#707070" padding="0 40px 0 40px">
                            {item}
                        </Grid>
                    );
                })}
            </Grid>
            {weekArr.map((item, index) => {
                if (state.information.recurring === true && (state.isEdit === 1 || state.isCopy === 1)) {
                    if (index < state.originalWeekCount) {
                        return <Week key={(index + item) * item} week={index + 1} arrItems={shiftTypes[index]} stationTimeZone={state.station.timezone} />;
                    }
                } else {
                    if (state.isEdit === 1 || state.isCopy === 1) {
                        if (index < state.originalWeekCount) {
                            return <Week key={(index + item) * item} week={index + 1} arrItems={shiftTypes[index]} stationTimeZone={state.station.timezone} />;
                        }
                    } else {
                        return <Week key={(index + item) * item} week={index + 1} arrItems={shiftTypes[index]} stationTimeZone={state.station.timezone} />;
                    }
                }
            })}
        </Grid>
    );
};

export default React.memo(Design);
