import React, {useCallback, useContext, useState, useRef, useEffect} from 'react';
import { Theme,  Spacer, Text,  Dropdown, ThirdTitle, Icon,Button, InputColor,Input } from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import styled from 'styled-components';
import DefaultStore from './ContextStore';
import {OrderIco, Table, TableData} from "../../../components/Table";
import WeekPicker from '../../../components/Calendar/week.js';
import Modal, {useModal} from "../../../components/Modal";
import moment from "moment";
import { useAuth } from "../../../Auth";
import StationSelector from "../../../components/StationSelector";
import {Label} from "../../../components/UI";
import {FilterWeekDate} from "../../../hooks/useGlobalData";
import Moment from "moment";
import { AccessCheck, IsGranted } from "../../../security";

const Filter = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
    z-index:2;
`;


const Tooltip = styled.div`
    background:#ffffff;
    box-shadow: 0px 3px 6px #33333326;
    padding:16px 12px;
    position:absolute;
    top:18px;
    right:0;
    min-width:400px;
    width:100%;
`

const ColorBox = styled.span`
    width:16px;
    height:16px;
    display:block;
    border-radius:2px;
    background:${props =>  props.color};
    border: 1px solid #CCCCCC;
`

const FitlerApp = () => {
    const [visible,setVisible] = useModal(false);
    const [state,dispatch] = useContext(DefaultStore);
    const [date,setDate] = FilterWeekDate();

    const changeWeek = useCallback(async(value) => {
        setDate(Moment(value).format('YYYY-MM-DD'))
    })
    const makeFilter = useCallback(async(value) => {
        // dispatch({type:'LOADING',payload: true});
        dispatch({type:'FILTER',payload:{'station':value}})
    })

    useEffect(()=>{
        dispatch({type:'LOADING',payload: true});
    },[date])


    return (
        <Filter>
            <div>
                <StationSelector
                    onChange={e => makeFilter(e)}
                />
                <Spacer inline={true} left={5}>
                    <Label>Week #</Label>
                    <WeekPicker date={date} onChange={(e)=>{changeWeek(e)}} />
                </Spacer>
            </div>
            {/*<SettingsInfo rules={state.rules} setVisible={setVisible} />*/}
            <EditGrad visible={visible} setVisible={setVisible} />
        </Filter>
    )
}

const SettingsInfo = ({rules,setVisible}) => {
    const [open,setOpen] = useState(false)
    const innerRef = useRef();

    const handleClick = (e) => {
        if (innerRef.current && !innerRef.current.contains(e.target)) {
            setOpen(false);
        }else{
            if(!open) setOpen(true)
        }

    }

    useEffect(()=>{
        document.addEventListener('mouseup',handleClick)

        return ()=>{
            document.removeEventListener('mouseup',handleClick)
        }
    })

    return (
        <div style={{display:'flex',alignItems:'center',position:'relative', marginBottom: 12}} ref={innerRef}>
            <ThirdTitle style={{fontWeight:'400',cursor:'pointer'}}>Scheduling Preferences</ThirdTitle>
            <Spacer left={2} style={{cursor:'pointer'}}><Icon.Info size='16px' /></Spacer>
            {open &&
                <Tooltip>
                    {rules.map((rule,k)=>{
                        let textMin = rule.min ? `${rule.min} ~ ` : ' < ';
                        if(!rule.max){
                            textMin = ` >= ${rule.min}`;
                        }
                        return (
                            <div key={k} style={{marginBottom:8,display:'flex'}}>
                                <div style={{display:'flex',width:120}}>
                                    <Spacer right={2}><ColorBox color={rule.color} /></Spacer>
                                    <Text>{textMin}{rule.max}%</Text>
                                </div>
                                <Text style={{flex:1}}>{rule.description}</Text>
                            </div>
                        )
                    })}
                    <Spacer top={2}/>
                    <Button size='small' onClick={()=>setVisible(true)}>Change</Button>
                </Tooltip>
            }
        </div>
    )
};

export const GradingTable = ({rules, onAdd, onEdit, onDelete}) => {
    const [key,setKey] = useState(-1);
    const { api } = useAuth();

    const deleteSetting = useCallback(async(key,data) => {
        let params = {
            "actions": {
                "delete": {
                    "GradingSettings": {
                        "delete_grading_settings_1": {
                            "findOneBy": {
                                "id": data.id
                            }
                        }
                    }
                },
            }
        };
        await api.post('/api/lazy/manage/data', params);

        if ( typeof onDelete === 'function' )
            onDelete( data, key )
    });

    let sorted = rules.sort((a,b) => parseInt(a.min) > parseInt(b.min) ? 1 : -1);

    return (
        <Table>
            <li className='header'>
                <TableData width='100px'>Color</TableData>
                <TableData width='100px'>Min</TableData>
                <TableData width='100px'>Max</TableData>
                <TableData width='190px'>Description</TableData>
                <TableData width='70px' />
            </li>
            {sorted.map((item,k)=>{
                if(key === k){
                    return  <AddorEdit key={k} onAdd={onAdd} onEdit={onEdit} item={item} index={k} setKey={setKey} />
                }
                return (
                    <li key={k}>
                        <TableData width='100px'>
                            <span style={{height:16,width:16,background:item.color,display:'block',borderRadius:'100%',border:'1px solid #CCCCCC'}} />
                        </TableData>
                        <TableData width='100px'>{(item.min && item.min >= 0) ? item.min : '-'}</TableData>
                        <TableData width='100px'>{(item.max && item.max >= 0) ? item.max : '-'}</TableData>
                        <TableData width='190px'>{item.description}</TableData>
                        <TableData width='70px'>
                            <div style={{display:'flex',cursor:'pointer'}}>
                                <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                                    <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={()=>setKey(k)} />
                                </IsGranted>
                                <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.D]}>
                                    <Spacer left={1}>
                                    <Icon.Times size='20px' color={Theme.colors.info.border} onClick={() => deleteSetting(k, item)} />
                                    </Spacer>
                                </IsGranted>
                            </div>
                        </TableData>
                    </li>
                )
            })}
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.C,AccessCheck.OPS.U]}>
                <AddorEdit onAdd={onAdd} onEdit={onEdit} />
            </IsGranted>
        </Table>
    )
};

const EditGrad = ({visible,setVisible}) => {
    const [state,dispatch] = useContext(DefaultStore);

    return (
        <Modal
            title='Scheduling Preferences'
            width='620px'
            visible={visible}
            setVisible={setVisible}
        >
            <GradingTable
                rules={state.rules}
                onAdd={( item ) => dispatch({type:'ADD_RULE',payload:item}) }
                onEdit={( item, key ) => dispatch({type:'EDIT_RULE',payload:{data:item,index:key}}) }
                onDelete={( item, key ) => dispatch({type:'DELETE_RULE',payload:key}) }
            />
        </Modal>
    )
}

const FixColor = styled.div`
    label[for="color"] {
        display:none;
    }
`

const Footer = styled.li`
    background:#FAFAFA;
    border-top: 1px solid #516F90;
`

const ErrorM = styled.span`
    display: block;
    margin-top: 5px;
    font-size: 11px;
    color: #333333;
`

const AddorEdit = ({onAdd, onEdit, item,index,setKey}) => {
    const { api } = useAuth();
    const {register, handleSubmit, errors, reset} = useForm({defaultValues:item});

    const onSubmit = useCallback(async(data)=>{
        // data.color = data.color[0];
        data.min = (data.min && data.min >= 0) ? parseInt(data.min) : null;
        data.max = (data.max && data.max >= 0) ? parseInt(data.max) : null;

        if(item) {
            let param = {
                "actions": {
                    "update": {
                        "GradingSettings": {
                            "grading_settings_skill_1": {
                                "findBy": {
                                  "id": item.id
                                },
                                "updateRecord": {
                                  "min":data.min,
                                  "max":data.max,
                                  "color":data.color[0],
                                  "description":data.description,
                                    textColor: data.color[1],
                                }
                              }
                        }
                    }
              }
            }
            await api.post('/api/lazy/manage/data', param);
            data.id = item.id;
            data.color = data.color[0];

            if ( typeof onEdit === 'function' )
                onEdit( data, index );

            setKey(-1);
        }else{
            let params = {
                "actions": {
                    "create": {
                        "GradingSettings": {
                            "grading_settings_1": {
                                "company": localStorage.getItem('company'),
                                "min":data.min,
                                "max":data.max,
                                "color":data.color[0],
                                "description":data.description,
                                textColor: data.color[1]
                            }
                        }
                    }
              }
            }
            const response = await api.post('/api/lazy/manage/data', params);
            data.id=response.data.data.responseId;

            data.color = data.color[0];

            if ( typeof onAdd === 'function' )
                onAdd( data );
        }

        //call api here to add the type

        reset({
            min:'',
            max:'',
            description:''
        })

    })

    return (
      <Footer>
          <TableData width='100px' style={{position:'relative',zIndex:3}}>
            <FixColor>
                <InputColor name='color' ref={register({required:'Color is required'})} value={item && item.color} />
                {errors && errors.color && <ErrorM>{errors.color.message}</ErrorM>}
            </FixColor>
          </TableData>
          <TableData width='100px' style={{position:'relative',zIndex:2}}>
            <FixColor>
              <Input name='min' type='number' size='extraSmall' ref={register({required:'Min is required'})} />
              {errors && errors.min && <ErrorM>{errors.min.message}</ErrorM>}
            </FixColor>
          </TableData>
          <TableData width='100px'>
              <Input name='max' type='number' size='extraSmall' ref={register} />
          </TableData>
          <TableData width='160px' style={{position:'relative',zIndex:2}}>
            <FixColor>
              <Input name='description' size='small' ref={register({required:'Description is required'})} />
              {errors && errors.description && <ErrorM>{errors.description.message}</ErrorM>}
            </FixColor>
          </TableData>
          <TableData width='100px'>
            <Button  style={{marginLeft:'auto'}} onClick={handleSubmit(onSubmit)}>{item ? 'Save' :  'Add'} </Button>
          </TableData>
      </Footer>
    )
}

export default FitlerApp;
