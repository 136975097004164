import React, {useRef} from 'react';
import {useDrop, useDrag, DropTargetMonitor, DragElementWrapper} from "react-dnd";

export interface DragItemProps {
    children: (DragItemReturnProps)=>React.ReactNode,
    type:string,
    index:number,
    id: string | number,
    onMove: (dragIndex: number, hoverIndex: number) => void,
    style?: any
}

export interface DragItemReturnProps {
    previewRef: DragElementWrapper<any>,
    dragRef: DragElementWrapper<any>,
    ui: {
        opacity: number,
        isOverCurrent: boolean,
        canDrop: boolean
    }
}

interface MonitorDragItemProps {
    index: number
    type: string
    id: string | number
}

/**
 * Function that allows to create a simple list with drag and drop
 * @param props.onMove This function should implement always with React.useCallback
 */
const DragItem = (props: DragItemProps) => {
    const {
        children,
        type,
        onMove,
        index,
        id
    } = props

    const dragRef = useRef<HTMLDivElement>(null)

    const [{isOverCurrent,canDrop},drop] = useDrop({
        accept:type,
        collect : (monitor) => ({
            isOverCurrent: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        hover(item: MonitorDragItemProps, monitor: DropTargetMonitor) {
            if (!dragRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    });

    const [{ isDragging }, drag,preview] = useDrag({
        item: { type, index, id },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging()
            }
        }

    });

    const opacity = isDragging ? 1 : 1;
    const containerStyle = {
        ...props.style,
        opacity: opacity
    }

    drag(drop(dragRef))

    return (
        <div ref={dragRef} style={containerStyle}>
            {children({
                previewRef:preview,
                dragRef:drag,
                ui: {
                    isOverCurrent,
                    opacity,
                    canDrop
                }
            })}
        </div>
    )
}

export {DragItem}