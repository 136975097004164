import {atom} from "recoil";

export type VMTaskStatus = {
    label: string
    value: string,
    color: string
}

export const VMTaskPriority = atom<VMTaskStatus[]>({
    key: 'VMTaskPriority',
    default:  [
        { label: "Critical", value: "critical", color: 'red' },
        { label: "Urgent", value: "urgent", color: 'orange' },
        { label: "Soon", value: "soon", color: 'yellow' },
        { label: "Can Wait", value: "can_wait", color: 'green' },
        { label: "Unknown", value: "unknown", color: 'gray' }
    ]
})