import React,{forwardRef, useState} from 'react';
import styled from 'styled-components';
// import Theme from '../Theme';
import Invalid from '../../assets/Invalid.svg';
import Ex from '../../assets/Ex.svg'
import Check from '../../assets/Check.svg'

// import {Label,RequiredText} from '../Common/input';

import {
    Theme,
} from "@dspworkplace/ui";

// import {phone, time} from './mask';


export const phone14 = (value, tz) => {
    return value
        .replace(/\D/g,'')
        .replace(/(\d{2})(\d)/,'$1-$2')
        .replace(/(\d{4})(\d)/,'$1-$2')
        .replace(/(\d{4})(\d)/,'$1-$2')
        .replace(/(\d{4})\d+?$/,'$1');
}
export const phone = (value, tz) => {
   return value
        .replace(/\D/g,'')
        .replace(/(\d{3})(\d)/,'$1-$2')
        .replace(/(\d{3})(\d)/,'$1-$2')
        .replace(/(\d{4})\d+?$/,'$1');
}
  
export const time = value => {
    value = value.trim();

    if ( value[0] === '1' )
        value = value.replace( /^(1[012])(\d{1,2})/, '$1:$2' );
    else
        value = value.replace( /^([1-9])(\d{1,2})/, '$1:$2' );

    value = value.replace( /^(\d{1,2}):?([0-5][0-9]) ?(am|pm)$/, '$1:$2 $3' );

    return value;
};

const borderDefault = `
    border-width:1px;
    border-style:solid;
`;

const Label = styled.label`
  font-size:${Theme.font.medium.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  padding-bottom:2px;
  color:#707070;

  >.requiredField {
    color:${Theme.colors.error.text};
  }

`;

const RequiredText = () => <span className='requiredField'>*</span>;

const handleSize = sizeType => {
  switch(sizeType){
    case 'extraSmall':
      return '80px';
    case 'small':
      return '140px';
    case 'big':
      return '460px';
    default:
      return '300px';
  }
}

const handlerColor = typeColor => {
  switch(typeColor){
    case 'error':
      return Theme.colors.error.text;
    case 'valid':
      return Theme.colors.success.text;
    default:
      return Theme.colors.neutrals.silver;
  }
}

const handleMask = type => {
  switch(type){
    case 'phone':
      return phone;
    case 'phone14':
      return phone14;
    case 'time':
      return time;
    default:
      return null;
  }
}

const InputBase = styled.input`
    background:${Theme.colors.neutrals.white};
    border-color:${props => handlerColor(props.validation)};
    border-width:1px;
    border-style:solid;
    border-radius:${Theme.form.input.radius};
    font-size:${Theme.font.small.size};
    line-height:${Theme.font.small.lineHeight};
    height:40px;
    padding:${Theme.form.input.padding};
    color:${Theme.colors.neutrals.gray};
    width:${({size}) => handleSize(size) };

    &.hasChildren {
      padding-right:40px;
    }

    &.hasChildren.childrenLeft {
      padding-right:${Theme.form.input.padding};
      padding-left:40px;
    }

    &:disabled {
        background: ${Theme.colors.extra.whiteSmoke};
        ${borderDefault};
        border-color: ${Theme.colors.neutrals.silver};
        color: ${Theme.colors.neutrals.medium};
    }

    &:disabled::placeholder {
        color:#eee;
    }

   &:hover {
      ${borderDefault} ;
      border-color:${props => handlerColor(props.validation)};
   }

   &:active {
      ${borderDefault};
      border-color:${props => handlerColor(props.validation)};
   }

   &:focus {
    ${borderDefault};
    box-shadow:0px 0px 6px ${Theme.colors.action};
    border-color:${Theme.colors.secondary};
   }

   &::placeholder {
      color:${Theme.colors.neutrals.silver};
      font-size:${Theme.font.small};
   }

   &:read-only {
    background:${Theme.colors.info.bg};
    border-color:${Theme.colors.neutrals.silver};
    color:#707070;
   }
`;




const Span = styled.span`
  font-size:${Theme.font.extraSmall.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  color:#516F90;
  margin-top:4px;
`;

const SpanError = styled(Span)`
  color:${Theme.colors.error.text}
`;

const Frag = styled.div`
  width:${({size}) => handleSize(size) }
  display:inline-block;
  vertical-align:top;
`;


const Input = forwardRef(
  ({
    name,
    label,
    help,
    invalid,
    valid,
    error,
    mask,
    iconAction,
    children,
    onChange,
    ...props
  }, ref) => {
    const [inputValue,setInputValue] = useState('');

    let validationType = null;
    let useIcon = false;

    if(invalid) {
      useIcon = <span className='requiredField'>*</span>;
      validationType = 'error';
    }

    if(props.Required) useIcon = <RequiredText/>

    if(error){
      validationType = 'error';
    }

    if(valid){
      useIcon = (<img src={Check} />);
      validationType = 'valid';
    }

    let maskFunction = null;
    if(mask)
      maskFunction = handleMask(mask);


    const handleChange = (e) => {
        console.log(e);
      if(onChange) {onChange(e)}
      if(maskFunction)
        e.target.value = maskFunction(e.target.value);
    }

    return (
      <Frag size={props.size} className='formAligner'>
        {label && !useIcon && <Label htmlFor={name}>{label}</Label>}
        {label && useIcon && <Label htmlFor={name}>{label} {useIcon}</Label>}
        <div style={{position:'relative'}}>
          {children && React.cloneElement(React.Children.only(children),{
              size: children.props.size || '24px',
              style: {
                  ...children.props.style,
                  position:'absolute',
                  top: children.props.top || '8px',
                  right: '8px',
                  cursor:'pointer',
                  left: (children.props.left && '8px')
              }
            })
          }
          <InputBase
            name={name}
            id={name}
            className={[children && 'hasChildren',children && children.props.left && 'childrenLeft']}
            {...props}
            validation={validationType}
            ref={ref}
            onChange={handleChange}
          />

          {iconAction && iconAction}
        </div>
        {help && <Span>{help}</Span>}
        {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
      </Frag>
    )

  })



export default Input;