import React, {Dispatch, useEffect, useState, useCallback} from "react";
import {DirectMessageModalState, DirectMessageModalAction} from "./DMModal";
import {createDuoChannel, getTwilioUsersEmployeeInformation} from "./utils";
import {Grid, ReturnButton, TagInputContainer} from "./styles";
import {TagInput, Icon} from "@dspworkplace/ui";
import {LoadingView} from "../components/Loading";
import Person from "../Chat/Person";
import FlexScroll from "../components/SimpleScroll";
import {useTrackedState} from "./store";
import {showErrorToast} from "../Utilities";


type Props = {
    directMessageState: DirectMessageModalState;
    directMessageDispatch: Dispatch<DirectMessageModalAction>;
    cancelModal: () => void;
};

function DMNewDirectMessages(props: Props) {
    const {directMessageDispatch, directMessageState, cancelModal} = props;
    const {newMatchedUsers, filterForNewUsers, identities} = directMessageState;
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(1);
    const state = useTrackedState();
    const {chatClient} = state;

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        if (chatClient) {
            getTwilioUsersEmployeeInformation()
                .then((result) => {
                    let filterUsers = result.users.filter((item) => {
                        return !identities.includes(item.identity);
                    });
                    directMessageDispatch({
                        type: "SET_NEW_USERS",
                        users: filterUsers,
                        skills: result.skills,
                        roles: result.roles,
                        stations: result.stations,
                        schedules: result.schedules,
                    });
                    setLoading(false);
                })
                .catch(async e => {
                    await showErrorToast(e, 'Error fetching employees information.');
                    setLoading(false);
                });
        }
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directMessageDispatch, identities, refresh]);

    const handleTagChange = useCallback(
        data => {
            directMessageDispatch({type: "NEW_MATCHED_USERS", data: data});
        },
        [directMessageDispatch]
    );

    const chatWithNewMember = useCallback(
        user => async () => {
            try {
                if (!chatClient) return;
                if (chatClient) {
                    setLoading(true);
                    await createDuoChannel(chatClient, user);
                    // dispatch({type: 'REMOVE_CHANNEL', channel: newDuoChannel});
                    // dispatch({type: "ADD_CHANNEL", channel: newDuoChannel});
                    // dispatch({type: 'UPDATE_CHANNEL', channel: newDuoChannel});
                    // dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: newDuoChannel});
                    setLoading(false);
                    cancelModal();
                }
            } catch (e) {
                await showErrorToast(e, 'Error trying to Direct Message user.');
                setLoading(false);
                cancelModal();
            }
        },
        [chatClient, cancelModal]
    );

    if (loading) {
        return <LoadingView containerHeight={"400px"} containerWidth={"100%"} imgHeight={"150px"}/>;
    }

    return (
        <Grid templateRows="repeat(3, auto)" rowGap="8px">
            <TagInputContainer>
                <TagInput
                    label="Find by name, email, skill, role, station and schedule:"
                    options={filterForNewUsers}
                    size="big"
                    onChange={handleTagChange}
                    name={'tags'}
                />
                <ReturnButton
                    className={"refresh"}
                    width={"30px"}
                    height={"30px"}
                    borderRadius={"50%"}
                    onClick={() => setRefresh(prevValue => prevValue + 1.0)}
                >
                    <Icon.Refresh size={"24px"}/>
                </ReturnButton>
            </TagInputContainer>
            <Grid color="#707070" fontSize="12px">{`${newMatchedUsers.length} search results:`}</Grid>
            <Grid rowGap="8px" templateRows="auto auto" height={"auto"}>
                <FlexScroll height="300px" width="100%">
                    {newMatchedUsers.map(item => {
                        return (
                            <Person
                                key={item.userId}
                                friendlyName={item.friendlyName}
                                skills={item.skills}
                                image={item.image}
                                stations={item.stations}
                                schedules={item.schedules}
                                role={item.role}
                                chat={true}
                                callback={chatWithNewMember(item)}
                            />
                        );
                    })}
                </FlexScroll>
            </Grid>
        </Grid>
    );
}

export default DMNewDirectMessages;
