import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Toggle, CustomDropdown, Dropdown, Text } from "@dspworkplace/ui";
import { getCompanyId, getUserId, useAuth, getToken } from "../../../Auth";
import { Link } from "../../../components/UI";
import SettingsContext from "../context";
import { toast } from "../../../components/Toasts";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import styled from "styled-components";
import moment from "moment";
import { AccessCheck, IsGranted } from "../../../security";
import { canEditCompanySetting } from "../../Scheduler/Common/AccessCheck";

const RowHead = styled.div`
    box-sizing: border-box;
    margin: 0px;
    width: 1050px;
    height: 28px;
    opacity: 1;
    border-bottom: 1px solid #7c98b6;
    display: grid;
    grid-template-columns: 300px 100px 150px 150px 150px 150px;
    grid-column-gap: 8px;
    grid-row-gap: 11px;
    padding-left: 8px;
`;

const TextHead = styled.p`
    height: 17px;
    font-size: 14px;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
`;

const Row = styled.div`
    width: 1050px;
    height: 44px;
    opacity: 1;
    box-sizing: border-box;
    border-bottom: 1px dashed #7c98b6;
    display: grid;
    grid-template-columns: 300px 100px 150px 150px 150px 150px;
    grid-row-gap: 12px;
    grid-column-gap: 8px;
    padding-left: 8px;
`;

const TextRow = styled(Text)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export default function ADPSettings() {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, reset, watch, errors, getValues } = useForm();
    const [company, setCompany] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let resetDrivers = () => {};

    useEffect(() => reset(state.company), [state.company]);
    const isAdpEnable = watch("isAdp");

    const handleADPSetting = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        //console.log(state.company);
        let param = {
            actions: {
                update: {
                    Company: {
                        update_company: {
                            findBy: {
                                id: getCompanyId(),
                            },
                            updateRecord: {
                                isAdp: data.isAdp,
                                clientId: data.clientId,
                                clientSecret: data.clientSecret,
                                pemFile: data.pemFile,
                                keyFile: data.keyFile,
                                masterAssociateId: data.masterAssociateId,
                            },
                        },
                    },
                },
            },
        };
        api.post("/api/lazy/manage/data", param).then((response) => {
            localStorage.setItem("isADP", data.isAdp);
            dispatch({
                type: "UPDATE_ADP",
                payload: {
                    isAdp: data.isAdp,
                },
            });
            if (data.isAdp) {
                let paramsPaycomSettings = {
                    actions: {
                        response: {
                            PaycomSettings: {
                                custom: {
                                    functionName: "updatePaycomSettings",
                                    criteria: {
                                        company: getCompanyId(),
                                        isEnabled: 0,
                                    },
                                    get: "PaycomSettings",
                                },
                            },
                        },
                    },
                };
                api.post("/api/lazy/manage/data", paramsPaycomSettings);
            }
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "ADP Setting",
                content: "ADP Settings updated.",
            });
        });
    };

    useEffect(() => {
        if (getUserId() == 70 || getUserId() == 1560 || getUserId() == 2696) {
            let param = {
                actions: {
                    response: {
                        Company: {
                            findBy: {
                                criteria: {
                                    isAdp: true,
                                },
                                get: "company",
                                includes: {
                                    0: "id",
                                    1: "name",
                                },
                            },
                        },
                    },
                },
            };
            api.post("/api/lazy/manage/data", param).then((response) => {
                setCompany(response.data.data.company);
            });
        } else {
            getAlldriverFromCompany(getCompanyId());
        }
    }, []);

    const submitAdpAoidForm = async (data, evt) => {
        if (!data.company) {
            data.company = getCompanyId();
        }
        let param = {
            actions: {
                response: {
                    ADPCertificates: {
                        custom: {
                            functionName: "callAdpAoidUpdate",
                            get: "result",
                            criteria: data,
                        },
                    },
                },
            },
        };
        api.post("/api/lazy/manage/data", param).then((response) => {
            toast({
                type: "success",
                title: "AOID Sync",
                content: "AOID Sync Successfully.",
            });
        });
        setIsModalOpen(false);
    };

    const getAlldriverFromCompany = async (company) => {
        resetDrivers();
        reset({
            driversId: [],
        });
        if (company == "all") {
            setDrivers([]);
            return;
        }
        let params = {
            actions: {
                response: {
                    Driver: {
                        custom: {
                            functionName: "getAlldriverFromCompany",
                            get: "drivers",
                            criteria: {
                                company: company,
                            },
                        },
                    },
                },
            },
        };
        try {
            const response = await api.post("/api/lazy/manage/data", params);
            setDrivers(
                response.data.data.drivers.map((d) => {
                    return { name: d.friendlyName, value: d.id };
                })
            );
        } catch (error) {
            console.log(error);
        }
    };

    const values = getValues();

    return (
        <>
            <form onSubmit={handleSubmit(handleADPSetting)}>
                <SubTitle>ADP Settings</SubTitle>
                <Spacer bottom={3} />
                <Toggle
                    ref={register}
                    name="isAdp"
                    help="If this is enabled, the system will automatically fetch ADP punch data."
                    singleText="Enable ADP Integration"
                    on={isAdpEnable}
                />
                <Spacer bottom={3} />
                <Input
                    ref={register({
                        required: isAdpEnable,
                    })}
                    name="clientId"
                    label="Client Id"
                    disabled={!isAdpEnable}
                    Required={isAdpEnable}
                    valid={isAdpEnable && (!errors || !errors.clientId) && values.clientId}
                    invalid={isAdpEnable && errors && errors.clientId}
                    error={isAdpEnable && errors && errors.clientId && "Required field"}
                    autoComplete="off"
                />
                <Spacer bottom={3} />
                <Input
                    ref={register({
                        required: watch("isAdp"),
                    })}
                    name="clientSecret"
                    label="Client Secret"
                    disabled={!isAdpEnable}
                    Required={isAdpEnable}
                    valid={isAdpEnable && (!errors || !errors.clientSecret) && values.clientSecret}
                    invalid={isAdpEnable && errors && errors.clientSecret}
                    error={isAdpEnable && errors && errors.clientSecret && "Required field"}
                    autoComplete="off"
                />
                <Spacer bottom={3} />
                <Input
                    name="pemFile"
                    label="Pem File"
                    size="big"
                    style={{ height: 76 }}
                    as="textarea"
                    ref={register({
                        required: isAdpEnable,
                    })}
                    disabled={!isAdpEnable}
                    Required={isAdpEnable}
                    valid={isAdpEnable && (!errors || !errors.pemFile) && values.pemFile}
                    invalid={isAdpEnable && errors && errors.pemFile}
                    error={isAdpEnable && errors && errors.pemFile && "Required field"}
                    autoComplete="off"
                />
                <Spacer bottom={3} />
                <Input
                    name="keyFile"
                    label="Key File"
                    size="big"
                    style={{ height: 76 }}
                    as="textarea"
                    ref={register({
                        required: isAdpEnable,
                    })}
                    disabled={!isAdpEnable}
                    Required={isAdpEnable}
                    valid={isAdpEnable && (!errors || !errors.keyFile) && values.keyFile}
                    invalid={isAdpEnable && errors && errors.keyFile}
                    error={isAdpEnable && errors && errors.keyFile && "Required field"}
                    autoComplete="off"
                />
                <Spacer bottom={3} />
                <Input
                    ref={register()}
                    name="masterAssociateId"
                    label="Master AssociateId"
                    disabled={!isAdpEnable}
                    autoComplete="off"
                />
                <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                    <Spacer bottom={5} />
                    <Button type="primary">Save</Button>
                </IsGranted>
            </form>
            {(getUserId() == 70 ||
                getUserId() == 1560 ||
                getUserId() == 2696 ||
                state.company.isAdp == true ||
                state.company.isAdp == "true") && (
                <>
                    <Spacer bottom={10} />
                    <SubTitle> ADP AOID </SubTitle>
                    <Spacer bottom={5} />
                    <Button disabled={canEditCompanySetting() ? false : true} style={{ marginRight: "10px" }} type="success" onClick={() => setIsModalOpen(true)}>
                        {" "}
                        Run AOID Sync{" "}
                    </Button>
                </>
            )}
            <Spacer bottom={10} />
            {watch("isAdp") && <ADPPunchsync api={api} />}
            <Spacer bottom={10} />

            {/* <Button type='success' onClick={modalPFXCertificate}>Generate PFX Certificate</Button> */}
            <Modal width="400px" title="Run AOID Sync" visible={isModalOpen} setVisible={() => setIsModalOpen(false)}>
                <form name="adp_aoid_form" onSubmit={handleSubmit(submitAdpAoidForm)}>
                    {(getUserId() == 70 || getUserId() == 1560 || getUserId() == 2696) && (
                        <Dropdown
                            ref={register}
                            name="company"
                            label="Company"
                            placeholder={"Select Company"}
                            options={[
                                {
                                    value: "all",
                                    name: "All Company",
                                },
                            ].concat(
                                company.map((c) => ({
                                    name: c.name,
                                    value: c.id,
                                }))
                            )}
                            onChange={(v) => getAlldriverFromCompany(v.value)}
                        />
                    )}
                    <CustomDropdown
                        name="driversId"
                        label="Drivers"
                        ref={register}
                        placeholder="All"
                        multiple={true}
                        options={drivers}
                        resetCallback={(fn) => {
                            resetDrivers = fn;
                        }}
                    />
                    <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                    <Spacer top={5} style={{ textAlign: "right" }}>
                        <Button type="primary"> Save </Button>
                    </Spacer>
                    </IsGranted>
                </form>
            </Modal>
        </>
    );
}

const ADPPunchsync = ({ api }) => {
    const [adpPunchData, setAdpPunchData] = useState([]);
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);

    const runADPPunchsync = async () => {
        let param = {
            actions: {
                response: {
                    DriverRouteUpdateTracking: {
                        custom: {
                            functionName: "callAnyWorkerORCommandDynamic",
                            get: "result",
                            criteria: {
                                companyId: getCompanyId(),
                                days: 1,
                            },
                        },
                    },
                },
            },
        };
        setLoading(true);
        api.post("/api/lazy/manage/data", param).then(async (response) => {
            let adpPunchData = response.data.data.result.punchData;
            setUrl(response.data.data.result.url);
            let newArr = [];
            await Object.keys(adpPunchData).map((id, i) => {
                Object.keys(adpPunchData[id]["date"]).map((date, data) => {
                    newArr.push({
                        friendlyName: adpPunchData[id].friendly_name,
                        date: date,
                        punchIn: adpPunchData[id]["date"][date]["punchIn"],
                        punchIut: adpPunchData[id]["date"][date]["punchOut"],
                        breakPunchIn: adpPunchData[id]["date"][date]["breakPunchIn"],
                        breakPunchOut: adpPunchData[id]["date"][date]["breakPunchOut"],
                    });
                });
            });
            setAdpPunchData(newArr);
            setLoading(false);
            toast({
                type: "success",
                title: "AOID Sync",
                content: "AOID Sync Successfully.",
            });
        });
    };
    return (
        <>
            <SubTitle> ADP Punch sync </SubTitle>
            <Spacer bottom={5} />
            <Button disabled={canEditCompanySetting() ? false : canEditCompanySetting() == false ? true : loading} style={{ marginRight: "10px" }} type="success" onClick={() => runADPPunchsync()}>
                {" "}
                Run ADP Punch sync {loading && <Loading style={{ height: "15px", width: "15px" }} />}
            </Button>
            <Spacer bottom={10} />
            {url && (
                <>
                    For support, you can use this{" "}
                    <Link target="_blank" href={url} download>
                        link
                    </Link>
                    . Please pass this link to support if this output is desired output.{" "}
                </>
            )}
            <Spacer bottom={5} />
            {adpPunchData.length > 0 && (
                <>
                    <RowHead>
                        <TextHead>Name</TextHead>
                        <TextHead>Date</TextHead>
                        <TextHead>Punch In</TextHead>
                        <TextHead>Punch Out</TextHead>
                        <TextHead>Break Punch In</TextHead>
                        <TextHead>Break Punch Out</TextHead>
                    </RowHead>
                    {adpPunchData.map((adpPunch) => {
                        return (
                            <Row>
                                <TextRow>{adpPunch.friendlyName}</TextRow>
                                <TextRow>{adpPunch.date}</TextRow>
                                <TextRow>
                                    {adpPunch.punchIn ? moment(adpPunch.punchIn).format("YYYY-MM-DD HH:mm") : "-"}
                                </TextRow>
                                <TextRow>
                                    {adpPunch.punchIn ? moment(adpPunch.punchOut).format("YYYY-MM-DD HH:mm") : "-"}
                                </TextRow>
                                <TextRow>
                                    {adpPunch.breakPunchIn
                                        ? moment(adpPunch.breakPunchIn).format("YYYY-MM-DD HH:mm")
                                        : "-"}
                                </TextRow>
                                <TextRow>
                                    {adpPunch.breakPunchOut
                                        ? moment(adpPunch.breakPunchOut).format("YYYY-MM-DD HH:mm")
                                        : "-"}
                                </TextRow>
                            </Row>
                        );
                    })}
                </>
            )}
        </>
    );
};
