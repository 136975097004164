import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Theme, Button, Text, Input, Spacer, SubTitle, Dropdown} from "@dspworkplace/ui";
import {useAuth} from "../../../Auth";
import {toast} from "../../../components/Toasts";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import styled from 'styled-components';
import Loading from "../../../components/Loading";
import Tooltip from "../../../components/Tooltip";
import { AccessCheck, IsGranted } from "../../../security";

const Container = styled.div`
    display:grid;
    width:250px;
`;

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const App = () => {
    const {api} = useAuth();
    const {register, handleSubmit, reset} = useForm();
    const [loading,setLoading] = useState(false);
    const [selectedStation] = useSelectedStation();

    const loadStation = async (station) => {
         const params = {
            actions: {
                response: {
                    Station: {
                        custom: {
                            functionName: "getShiftBufferTimeStripeData",
                            get: "bufferTime",
                            criteria: {
                                stationId: station
                            }
                        }
                    }
                }
            }
         };
 
         api.post("/api/lazy/manage/data", params).then(response =>{
            reset({startOfShiftBufferTime:response.data.data.bufferTime.start,endOfShiftBufferTime:response.data.data.bufferTime.end});
            setLoading(false);
            }
         );
     };

    function onStationChange(data) {
        setLoading(true);
        loadStation(data);
    };

    const handleSchedulerSetting = ( data, evt ) => {
        
        let form = evt.target;
        form.querySelector('button').disabled = true;
        api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    Station: {
                        update_station: {
                            findBy: {
                                id: selectedStation
                            },
                            updateRecord: {
                                startOfShiftBufferTime: parseInt(data.startOfShiftBufferTime),
                                endOfShiftBufferTime: parseInt(data.endOfShiftBufferTime),
                            }
                        }
                    }
                }
            }
        }).then(
            response => {
                toast({
                    type: 'success',
                    title: 'Scheduler Setting',
                    content: 'Scheduler shift buffer time settings updated.'
                });
            }    

        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    useEffect(() => {
        loadStation(selectedStation);
    }, []);

    return (
        <Container>
        <SubTitle>Labor Exception Report Setting</SubTitle>
        <Spacer top={5} bottom={3} >
            <StationSelector
                ref={register}
                name={'station'}   
                onChange={e => { onStationChange(e); }}      
            />
        </Spacer>
        <form onSubmit={handleSubmit(handleSchedulerSetting)}>
            <Spacer bottom={3}/>            
            <Input
                ref={register}
                name='startOfShiftBufferTime'
                placeholder="Minutes"
                label={
                    <>
                        Start of Shift Buffer Time ( Minutes )
                        <Spacer inline left={2}>
                            <Tooltip
                                verticalPosition={'center'}
                                horizontalPosition={'right'}
                                content={<Text>How much earlier than shift start time are<br/> they allowed to punch in before they <br/>trigger landing on the exception report.</Text>}
                            >
                                <Question>?</Question>
                            </Tooltip>
                        </Spacer>
                    </>
                }
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='endOfShiftBufferTime'
                placeholder="Minutes"
                label={
                    <>
                        End of Shift Buffer Time ( Minutes )
                        <Spacer inline left={2}>
                            <Tooltip
                                verticalPosition={'center'}
                                horizontalPosition={'right'}
                                content={<Text>How much later than RTS completion time <br/> are they allowed to punch out before  <br/>they trigger landing on the exception report.</Text>}
                            >
                                <Question>?</Question>
                            </Tooltip>
                        </Spacer>
                    </>
                }
            />
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5}/>
                <Button type='primary'>Save</Button>
            </IsGranted>        
        </form>
        </Container>
    )
}

export default App;