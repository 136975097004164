import React, {useEffect, useRef, useState} from 'react';
import {Channel} from "twilio-chat/lib/channel";
import {getOtherUserDuoChannel} from "./utils";
import styled from "styled-components";
import {Client, User} from "twilio-chat";
import {useChatTrackedState} from "./store";

export const ContainerText = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2px;
    height: 14px;

    div.dot-inline {
        background-color: ${props => (props.status === "Offline" ? "rgb(124, 152, 182)" : "rgb(0, 189, 165)")};
        width: 6px;
        height: 6px;
        border-radius: 50%;
        align-self: center;
        margin-top: -2px;
    }
`;

export const ContainerDot = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
    align-self: center;
    background-color: ${props => (props.status === "Offline" ? "rgb(124, 152, 182)" : "rgb(0, 189, 165)")};
    border-radius: 50%;
    border: 2px solid white;
    top: 10px;
    left: -10px;
    min-width: 14px;
`;


type Props = {
    channel: Channel;
    type?: string;
    client?: Client;
};


const OnlineIndicator: React.FC<Props> = ({channel}) => {
    const {client} = useChatTrackedState();
    const [otherUser, setOtherUser] = useState({loading: false, status: "Offline", user: null});
    const {status} = otherUser;
    const userRef = useRef<User | null>(null);

    useEffect(() => {
        const abortController = new AbortController();
        // if (channel.sid === 'CHc8450eda37694f9094eabcbe274f1608') {
        //     console.log({date: new Date(), userRef: userRef.current, state: otherUser, level: 1});
        // }
        if (channel && client) {
            getOtherUserDuoChannel(channel, client).then(user => {
                // console.log({user});
                if (user) {
                    if (user.online) {
                        setOtherUser({loading: false, status: 'Online', user: user});
                    } else {
                        setOtherUser({loading: false, status: 'Offline', user: user});
                    }
                    userRef.current = user;
                }
            });
        }
        return () => {
            if (channel) {
                channel.removeAllListeners();
            }
            abortController.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try {
            // if (channel.sid === 'CHc8450eda37694f9094eabcbe274f1608') {
            //     console.log({date: new Date(), userRef: userRef.current, state: otherUser, level: 2})
            // }
            if (userRef.current) {
                // @ts-ignore
                userRef.current.on('updated', (event) => {
                    console.log({event, otherUser, name: 'updated', date: new Date(),});
                    if (event.user) {
                        if (event.user.online) {
                            setOtherUser((prevState) => ({
                                ...prevState,
                                status: 'Online',
                            }));
                        } else {
                            setOtherUser((prevState) => ({
                                ...prevState,
                                status: 'Offline',
                            }));
                        }
                    }
                });
            }
        } catch (e) {
        }
        return () => {
            try {

                if (userRef.current) {
                    // @ts-ignore
                    userRef.current.removeListener('updated', () => {
                    });
                }
            } catch (e) {
            }
        };
    }, [otherUser.user]);

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     const interval = setInterval(() => {
    //         if (channel && channel.attributes["kind"] === "Duo") {
    //             getOtherUserFromDuoChannel(channel).then(user => {
    //                 console.log(`Render OnlineIndicator ${channel.sid} ${JSON.stringify({
    //                     status: user.online,
    //                     loading
    //                 })} ${new Date()}`);
    //                 if (user && user.online) {
    //                     if (status !== 'Online') {
    //                         setState(currState => ({...currState, status: 'Online'}));
    //                     }
    //                 } else {
    //                     setState(currState => ({...currState, status: 'Offline'}));
    //                 }
    //             });
    //         }
    //     }, 15000);
    //     return () => {
    //         clearInterval(interval);
    //         if (channel) {
    //             channel.removeAllListeners();
    //         }
    //         abortController.abort();
    //     };
    // }, []);

    return (
        <ContainerDot status={status}/>
    );

    // if (type === 'dot') {
    //     return (
    //         <ContainerDot status={status}/>
    //     );
    // }

    // return (
    //     <ContainerText status={status}>
    //         <div className={"dot-inline"}/>
    //         <Text color="#707070" fontSize="10px" lineHeight="normal" height="unset">{status}</Text>
    //     </ContainerText>
    // );
}

//
function propsAreEqual(prev, next) {
    return prev.channel.sid === next.channel.sid;
}

const MemoizedOnlineIndicator = React.memo(OnlineIndicator, propsAreEqual);
// export default OnlineIndicator;
export default MemoizedOnlineIndicator;