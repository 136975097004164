import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { getStreamChatIdentifier, getChatUserId, setAllowToChatStream, setEnableOldChat, getChatTeam } from "../Auth";
import { useDispatch, useTrackedState } from "./store";
import { getStreamKey } from "../Conf";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  VirtualizedMessageList,
  Thread,
  Window,
  ChannelPreviewUIComponentProps,
  useChatContext
} from "stream-chat-react";
import API from "./api";
import { useForm } from "react-hook-form";
// import './CustomPreview.scss';

import AppChatSidebar from "./AppChatSidebar";
import AppChatClient from "./AppChatClient";
import ChatIconContainer from "./ChatIcon";
import CGCOptions from "./CGCOptions";
import DMModal from "./DMModal";
import "stream-chat-react/dist/css/index.css";
import { Button, Dropdown, Spacer, TagInput, Input, async } from "@dspworkplace/ui";
import ListBadWordsModalContainer from "./ListBadWordsModal";
import ImpersonateModalContainer from "./ImpersonateModal";
import { getBadWords } from "../Chat/utils";
import ArchiveGroupModal from "./ArchiveGroupModal";
import { LoadingView } from "../components/Loading";
import { Provider as BotProvider } from "./BotTask/Store"
import { getImpersonateStreamChatIdentifier } from "../Utilities";
import ExportModal from "./ExportModal";
import { getTrainingVideo } from "./utils";

// const userToken =
//   "vxrdmf8hab7k3sawcjdsfk7wtgqaa7pdec2se4erqshaywzs97ppvx3vmxdhtrnn";

// const filters = { type: "messaging", members: {} };
// TS tweak No1
const sort = { last_message_at: -1 };

const ChatDemo = ({ isPage }) => {
  // TS tweak No2
  const dispatch = useDispatch();
  const state = useTrackedState();

  const [chatClient, setChatClient] = useState(null);
  const [unreadCounterBot, setUnreadCounterBot] = useState(0)
  const [unreadCounterDuo, setUnreadCounterDuo] = useState(0)
  const [unreadCounterGroup, setUnreadCounterGroup] = useState(0)

  const [userList, setUserList] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const { register, handleSubmit, errors, reset, getValues, setError } = useForm({ mode: 'onBlur' });
  const { user } = getValues();
  const filters = { type: "group", members: { $in: [getStreamChatIdentifier()] } };

  useEffect(() => {
    const initChat = async () => {
      const client1 = await StreamChat.getInstance(getStreamKey, { timeout: 15000 });

      await API.addUser().then(async (result) => {
        // if(result.data.code === 200){
        // setValue('id',result.data.data.dailyDriverSummaryId);

        // setSummaryId(result.data.data.dailyDriverSummaryId);
        // idRef.current = result.data.data.dailyDriverSummaryId;
        // toast({
        //     type: 'success',
        //     title: 'Summary information created successfully.'
        // });
        // setUpdatedDate(moment.utc().valueOf() / 1000)

        // server_client = stream_chat.StreamChat(api_key="STREAM_KEY", api_secret="STREAM_SECRET")
        // let token = client.createToken('nimesh');
        await client1.connectUser(
          {
            id: getStreamChatIdentifier(),
          }, result.data.streamToken
        );
        if (getImpersonateStreamChatIdentifier() === undefined || getImpersonateStreamChatIdentifier() === null || getImpersonateStreamChatIdentifier() === '') {
          setAllowToChatStream(result.data.allowToChat)
          setEnableOldChat(result?.data?.enableOldChat)
        }
        var filter = await getBadWords()
        // Training video
        if(state?.trainingVideo.length === 0) {
           var trainingVideo = await getTrainingVideo();
           await dispatch({ type: "TRAINING_VIDEO", trainingVideo: trainingVideo });
        }
        // await client.connectUser(
        //   {
        //     id: getStreamChatIdentifier(),
        //   }, result.data
        // );


        // }else{
        //   alert('Error to create the summary, please contact support.');
        // }
        await dispatch({ type: "SET_CHAT_CLIENT", chatClient: client1 });

        try {
          const filters = {
            type: "initial_channel",
            // cid: { $eq: 'messaging:initial_channel' },
            members: { $in: [getStreamChatIdentifier()] }
          }
          const initialChannel = await client1.queryChannels(filters);
          const newChannel = await initialChannel[0];

          await dispatch({ type: "INIT_CHAT", filter: filter, initialChannel: newChannel });
        } catch (error) {
          console.log(error)
        }

        // const unreadDuo = await client1?.user?.duo_unread_counter;
        // const unreadGroup = await client1?.user?.group_unread_counter;
        // const unreadBot = await client1?.user?.bot_unread_counter;
        // const unreadCounter = await (parseInt(unreadDuo === undefined ? 0 : unreadDuo) +
        //   parseInt(unreadGroup === undefined ? 0 : unreadGroup) +
        //   parseInt(unreadBot === undefined ? 0 : unreadBot))
        // dispatch({ type: "SET_NOTIFICATIONS_COUNT", notificationsCount: unreadCounter });
        await setChatClient(client1);
        // formButton.disabled = false;
      })
    };
    initChat();
  }, []);

  useEffect(() => {
    const handleNewEvent = async (event) => {
      // only for update remove user badge counter
      if (event.type === 'channel.updated' && event?.channel?.isRemoved && event?.channel?.type === 'duo')
        await setDuoNotification()

      if (event.type === 'notification.mark_read' || event.type === "message.new" || event.type === "notification.message_new" || event.type === 'message.read' || event.type === 'notification.mark_unread') {
        if (event.channel_type === "duo")
          await setDuoNotification()
        else if (event.channel_type === "group")
          await setGroupNotification()
        else if (event.channel_type === "bot")
          await setBotNotification()
      }

      if (event.type === 'message.new' && event.unread_count > 0) {
        new Notification(event.user.name, {
          body: event.message.text,
          silent: event.message.silent,
        });
      }
    }
    if (chatClient) {
      chatClient.on(handleNewEvent);
    }
    return () => chatClient?.off(handleNewEvent);
  }, [chatClient])

  useEffect(() => {
    if (chatClient?.user) {
      setBotNotification()
      setDuoNotification()
      setGroupNotification()
    }
  }, [chatClient?.user])

  useEffect(() => {
    if (unreadCounterBot > 0 || unreadCounterDuo > 0 || unreadCounterGroup > 0)
      dispatch({ type: "SET_NOTIFICATIONS_COUNT", notificationsCount: Number(unreadCounterBot) + Number(unreadCounterDuo) + Number(unreadCounterGroup) });
  }, [unreadCounterBot, unreadCounterDuo, unreadCounterGroup])

  async function setDuoNotification() {
    try {
      const sort = { last_message_at: -1 };
      var duolist = await chatClient?.queryChannels({ type: 'duo', members: { $in: [chatClient?.user?.id || ''] }, teams: { $in: [getChatTeam() || ''] }, }, sort, { watch: true, state: true, })
      var totoalCounter = 0
      await duolist?.map((channel) => {
        if (channel.countUnread() > 0 && channel?.data?.isArchive !== true && !channel?.data.isRemoved?.includes(chatClient?.userID))
          totoalCounter = totoalCounter + channel.countUnread()
      })

      await setUnreadCounterDuo(totoalCounter);
      dispatch({ type: "SET_DUO_COUNT", duoCounter: totoalCounter });
    } catch (error) {
      console.log(error, 'setDuoNotification()')
    }
  }

  async function setGroupNotification() {
    try {
      const sort = { last_message_at: -1 };
      var groupList = await chatClient?.queryChannels({ type: 'group', members: { $in: [chatClient?.user?.id || ''] }, teams: { $in: [getChatTeam() || ''] }, }, sort, { watch: true });
      var totoalCounter = 0
      await groupList?.map((channel) => {
        if (channel.countUnread() > 0 && channel?.data?.isArchive !== true)
          totoalCounter = totoalCounter + channel.countUnread();
      })
      await setUnreadCounterGroup(totoalCounter);
      dispatch({ type: "SET_GROUP_COUNT", groupCounter: totoalCounter });
    } catch (err) {
      console.log(err, 'setGroupNotification()')
    }
  }

  async function setBotNotification() {
    try {
      const sort = { last_message_at: -1 };
      var bot = await chatClient?.queryChannels({ type: 'bot', members: { $in: [chatClient?.user?.id || ''] }, teams: { $in: [getChatTeam() || ''] }, }, sort, { watch: true });
      const res = await bot[0]?.query({ messages: { limit: 1000, offset: 0 } });
      const messages = await res?.messages.filter(n => n.type !== 'deleted');
      await setUnreadCounterBot(messages?.length);
      dispatch({ type: "SET_BOT_COUNT", botCounter: messages?.length });
    } catch (error) {
      console.log(error, 'setBotNotification()')
    }
  }

  return (
    <>
      {chatClient ?
        <Chat client={chatClient}>
          <BotProvider>
            {localStorage.getItem("currentUserRole") != "ROLE_CHAT_USER" && <ChatIconContainer />}
            <AppChatSidebar isPage={isPage} />
            <CGCOptions />
            <DMModal />
            <ListBadWordsModalContainer />
            <ImpersonateModalContainer />
            <ExportModal />
            <ArchiveGroupModal />
          </BotProvider>
        </Chat>
        : null}
    </>
  );

  const onCreateChannel = async (params) => {
    console.log(params);

    const conversation = await chatClient.channel('group', {
      members: [...params.user, chatClient.userID],
      name: params.name
    });

    await conversation.watch();
  };
  const getUsers = async (params) => {
    setIsAdd(true);
    const response = await chatClient.queryUsers(
      {
        // id: { $ne: chatClient.userID },
        // $and: [{ name: { $autocomplete: inputText } }],
      },
    );
    // const response = await chatClient.queryUsers(
    //   {
    //     id: { $ne: chatClient.userID },
    //     // $and: [{ name: { $autocomplete: inputText } }],
    //   },
    //   { id: 1 },
    //   { limit: 6 },
    // );
    console.log(response.users);
    await setUserList(response.users);
    return true;
  }

  return (<>
    <ChatIconContainer />
    <AppChatSidebar />
    {!isAdd && <Button onClick={getUsers}>Add New</Button>}
    {isAdd && <form onSubmit={handleSubmit(onCreateChannel)}>
      <Input
        name={'name'}
        label={'Name'}
        ref={register({ required: 'Field Required' })}
      />
      {/* <Dropdown
          ref={register({required: true})}
          name='user'
          label='User List'
          size='small'
          options={userList.map((u) => { return { name: u.name, value: u.id } })}
          // error={errors.user && errors.user}
          // valid={!errors.user && user && user.length > 0}
        ></Dropdown> */}
      <TagInput
        name={'user'}
        ref={register}
        label={'User List'}
        options={userList.map((u) => { return { name: u.name, value: u.id } })}
        canCreate={false}
      />
      <Spacer bottom={5} />
      <Button >Chat</Button>
    </form>}
    <Spacer bottom={5} />
    {chatClient && <Chat client={chatClient}>
      <ChannelList
        filters={filters}
        sort={sort}
        showChannelSearch
      // Preview={previewProp=>(CustomPreview(previewProp,chatClient))}
      // Preview={(previewProps) => (
      //   <>sai</>
      // )}
      // onCreateChannel={onCreateChannel}
      />
      <Channel>
        <Window>
          <ChannelHeader />
          <VirtualizedMessageList
            additionalVirtuosoProps={{
              increaseViewportBy: { top: 400, bottom: 200 }
            }}
          />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>}
  </>);
};
// const CustomPreview = (props: ChannelPreviewUIComponentProps,chatClient) => {
//   const { channel, setActiveChannel } = props;

//   const { channel: activeChannel } = useChatContext();

//   const selected = channel?.id === activeChannel?.id;

//   const renderMessageText = () => {
//     const lastMessageText = channel.state.messages[channel.state.messages.length - 1].text;

//     const text = lastMessageText || 'message text';

//     return text.length < 60 ? lastMessageText : `${text.slice(0, 70)}...`;
//   };

//   if (!channel.state.messages.length) return null;

//   const members = Object.values(channel.state.members).filter(
//     ({ user }) => user?.id !== chatClient.userID,
//   );


//   return (
//     <div
//       className={selected ? 'channel-preview__container selected' : 'channel-preview__container'}
//       onClick={() => setActiveChannel(channel)}
//     >
//       <div className='channel-preview__content-wrapper'>
//         <div className='channel-preview__content-top'>
//           <p className='channel-preview__content-name'>{members.length > 0 ? members[0].user_id : '' }</p>
//           <p className='channel-preview__content-name'>{channel.data?.subtitle}</p>
//         </div>
//         <p className='channel-preview__content-message'>{renderMessageText()}</p>
//       </div>
//     </div>
//   );
// };


export default ChatDemo;
