import React from 'react';
// import styled from "styled-components";

const LeftView  = ({children}) =>  {
    return (
        <div style={{ width: '65%', padding: 8}}>
            {children}
        </div>
    );
};

export default LeftView;
