import React, {useCallback, useContext,useState} from 'react';
import styled from 'styled-components';
import {Button,Title,Spacer} from "@dspworkplace/ui";
import Filter from '../common/filter.js';
import Table from './Table';
import defaultStore, {AppProvider} from './ContextStore'
import { useAuth } from "../../../Auth";
import moment from "moment";
import {FilterWeekDate} from "../../../hooks/useGlobalData";
import {useSelectedStation} from "../../../components/StationSelector";
import {AccessCheck, IsGranted} from "../../../security";

const Body  = styled.div`
    min-height:100vh;
    margin-top:20px;
    display:flex;
    flex-direction:column;
    align-items: stretch;
    padding-left: 156px;
    max-width:85vw;
    
    @media (max-width:1200px){
        padding-left:20px;
        padding-right:20px;
        max-width:calc(100vw - 20px);
    }
    
`;

const App = () => {
    return (
        <Body id={'routeCommitmentbody'}>
            <AppProvider>
                <Title>Route Commitment</Title>
                <Spacer top={5} />

                <Filter />
                <Spacer top={5}/>

                <Table />
                <Spacer top={3}/>

                <Save />
                <Spacer top={5}/>
            </AppProvider>
        </Body>
    )
}

const Save = () => {
    const [state,dispatch] = useContext(defaultStore);
    const [date] = FilterWeekDate();
    const { api } = useAuth();
    let currWeek = moment(date).isoWeek();
    if (moment(date).day() == 0) {
        let dateObj = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        currWeek = moment(dateObj).isoWeek();
    }

    let currYear = parseInt(moment(date).startOf('week').format("YYYY"));
    if(moment(date).startOf("week").format('YYYY') != moment(date).endOf("week").format('YYYY') && currWeek == 1 && currYear == moment(date).startOf("week").format('YYYY')) {
        currYear = parseInt(moment(date).endOf("week").format('YYYY')); 
    }
    const [selectedStation] = useSelectedStation();

    const handleSubmit = async () => {
        const data = state.data;
        let tempJson = {};
        let temp=[];
        let keyShift='';
        let shiftCounts=1;
        if(state.tempData.length > 0 ){
            state.tempData.map((item,key)=>{
                item.shifts.map((shift,key)=>{
                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':state.week,
                        'weekDate':moment(date).day(0).format('YYYY-MM-DD'),
                        'route':shift.w0,
                        'routeAmendment':shift.raw0,
                        'year':currYear
                    };
                    shiftCounts++;

                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(1).format('YYYY-MM-DD'),
                        'route':shift.w1,
                        'routeAmendment':shift.raw1,
                        'year':currYear
                    };
                    shiftCounts++;

                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(2).format('YYYY-MM-DD'),
                        'route':shift.w2,
                        'routeAmendment':shift.raw2,
                        'year':currYear
                    };

                    shiftCounts++;
                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(3).format('YYYY-MM-DD'),
                        'route':shift.w3,
                        'routeAmendment':shift.raw3,
                        'year':currYear
                    };

                    shiftCounts++;
                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(4).format('YYYY-MM-DD'),
                        'route':shift.w4,
                        'routeAmendment':shift.raw4,
                        'year':currYear
                    };

                    shiftCounts++;
                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(5).format('YYYY-MM-DD'),
                        'route':shift.w5,
                        'routeAmendment':shift.raw5,
                        'year':currYear
                    };

                    shiftCounts++;
                    keyShift = 'routeCommitment_p' + shiftCounts;
                    tempJson[keyShift]={
                        'station':item.station,
                        'company':parseInt(localStorage.getItem('company')),
                        'shiftType':shift.id,
                        'week':currWeek,
                        'weekDate':moment(date).day(6).format('YYYY-MM-DD'),
                        'route':shift.w6,
                        'routeAmendment':shift.raw6,
                        'year':currYear
                        };
                        shiftCounts++;
                    })
            });

            let params = {
                "actions": {
                    "response": {
                        "RouteCommitment": {
                            "custom": {
                                "functionName": "deleteRouteCommitments",
                                "criteria": {
                                    "company": parseInt(localStorage.getItem('company')),
                                    "station" : state.tempData[0].station,
                                    "week":currWeek,
                                    "year":currYear
                                },
                                "get":"RouteCommitments"
                            }
                        }
                    },
                    "create": {
                        "RouteCommitment": tempJson
                }
                }
            }
            try{
                dispatch({type:'LOADING',payload:'true'});
                await api.post('/api/lazy/manage/data', params);
                //await api.post('/api/lazy/manage/data', routeCommitmentCommand);
                dispatch({type:'LOADING',payload:false});
              }catch(error){
                  dispatch({type:'default'});
              }
        } else {
              let params = {
                  "actions": {
                      "response": {
                          "RouteCommitment": {
                              "custom": {
                                  "functionName": "deleteRouteCommitments",
                                  "criteria": {
                                      "company": parseInt(localStorage.getItem('company')),
                                      "station" : selectedStation,
                                      "week":currWeek,
                                      "year":currYear
                                  },
                                  "get":"RouteCommitments"
                              }
                          }
                      }
                }
            }
            await api.post('/api/lazy/manage/data', params);
            dispatch({type:'default'});
        }
    };

    return (
        <IsGranted feature={AccessCheck.features.ROUTE_COMMITMENT} ops={AccessCheck.OPS.U}>
        <Button type='primary' onClick={() => {handleSubmit()}} style={{marginLeft:'auto'}}>Save Commitment</Button>
        </IsGranted>
    )
}

export default App;
