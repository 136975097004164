import moment from 'moment';
import createPersistedState from 'use-persisted-state';
import {setToken, getToken} from "../Auth";

const persistedDate = createPersistedState('filterWeekDate');
const persistedToken = createPersistedState('token');
const persistedNarrativeAutoSave = createPersistedState('narrativeAutoSave');
const persistedAnnouncementDay = createPersistedState('announcementDay');

const FilterWeekDate = () => persistedDate(moment().format('YYYY-MM-DD'));
const FilterDayAnnouncement = () => persistedAnnouncementDay({start:moment().format('YYYY-MM-DD'),end:moment().format('YYYY-MM-DD')});
const Token = () => {
    let ret;

    try {
      ret = persistedToken(null);
    }  catch {
        setToken(getToken());
        // this cause an issue in axios request
        // window.location.reload();
    }

    return ret;
}

const NarrativeAutoSave = () => persistedNarrativeAutoSave(true);

export {FilterWeekDate,Token,NarrativeAutoSave,FilterDayAnnouncement}