import {useState, useCallback} from 'react';
import {SortColumn} from "react-data-grid";

type SortCallbackProp = Function | undefined

const useSortColumn = (callbackFn:SortCallbackProp, defaultOrder?: SortColumn[]) => {
    const [sortedColumns, setSortColumns] = useState<readonly SortColumn[]>(defaultOrder ?? []);

    const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
        setSortColumns(sortColumns.slice(-1));
        if(callbackFn) callbackFn({fields:sortColumns})
    }, [callbackFn]);


    return {sortedColumns, onSortColumnsChange}
}

export default useSortColumn