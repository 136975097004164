import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Title, Spacer, Text, Button} from '@dspworkplace/ui';
import {Switch, Route, Redirect} from 'react-router-dom';
import Station, {SkeletonApp} from './station';
import Loader from "../../assets/loading.svg";
import Form from "./form";
import DeletePopup from "./deletePopup";
import Modal, {useModal} from "../../components/Modal";
import Navigator from './Navigator';
import {useAuth, useLocalStorage, getCompanyId, getUserId} from "../../Auth";
import {useSelectedStation} from "../../components/StationSelector";
import {engine} from "../../Utilities";
import {AccessCheck, IsGranted} from "../../security";
import {setItemToStorage} from "../../AppTwilioConversation/utils";

const Body = styled.div`
    box-sizing: border-box;
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items: stretch;
    padding-left: 156px;
    padding-bottom:40px;
`;

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(340px, 340px) );
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

const AddButton = styled(Button)`
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
`;

const Loading = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100vh;
    background:rgba(255,255,255,0.7);
    z-index:10;
    >img {
        width:90px;
        height:90px;
        position:absolute;
        left: 50%;
        margin-left: -45px;
        top: 50%;
    }
`;


const App = () => {
    const [storageStations, setStorageStations] = useLocalStorage('stations');
    const [stations, setStation] = useState({});
    // const [selected, setSelected] = useLocalStorage("selectedStation");
    const [selected, setSelected] = useSelectedStation('');
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState({});
    const [deleteId, setDeleteId] = useState({});
    const [deleteKey, setDeleteKey] = useState({});
    const [copy, setCopy] = useState({});
    const {api} = useAuth();
    const FullRole = ['ROLE_OWNER', 'ROLE_OPERATIONS_ACCOUNT_MANAGER', 'ROLE_OPERATIONS_MANAGER', 'ROLE_FLEET_MANAGER', 'ROLE_HR_ADMINISTRATOR', 'ROLE_CENTRAL_DISPATCHER', 'ROLE_STATION_MANAGER', 'ROLE_ASSISTANT_STATION_MANAGER'];
    const ParsiallyRole = ['ROLE_DISPATCHER', 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE','ROLE_STATION_MANAGER', 'ROLE_ASSISTANT_STATION_MANAGER'];
    const [visible, toogleModal] = useModal(false);
    const [visibleDelete, toogleModalDelete] = useModal(false);

    const Editing = (data, idList) => {
        data.idList = idList;
        setEdit(data);
        toogleModal();
    }

    const Copying = (data, idList) => {
        setCopy(data);
        toogleModal();
    }


    const createStations = async (station) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                setStation(prevState => [...prevState, station]);
                toogleModal(false);
                setLoading(false);
            }, 500)
        });
    }

    const editStation = async ({idList, ...data}) => {
        return new Promise((resolve, reject) => {
            let all_station = Object.assign([], stations);
            all_station[idList] = data;
            setStation(all_station);
            toogleModal(false);
            setLoading(false);
            setEdit({});
        })
    }

    const handleDeleteDispacher = async (station, key) => {
        let allStation = Object.assign([], stations)
        allStation.splice(key, 1);
        setStation(allStation);
        setStorageStations(allStation);
        if (allStation.length <= 0) {
            setSelected(null);
        } else if (station == selected) {
            setSelected(allStation[0].id);
        }
    }

    const handleDelete = async (station, key) => {
            setDeleteId(station);
            setDeleteKey(key);
            toogleModalDelete();
    }

    useEffect(() => {

        async function fetchData() {
            if (FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1) {
                const params = {
                    "actions": {
                        "response": {
                            "Station": {
                                "custom": {
                                    functionName: "getStationByRole",
                                    get: 'Stations',
                                    criteria: {
                                        company: getCompanyId(),
                                        user: getUserId()
                                    },
                                    includes: {
                                        "0": "id",
                                        "1": "name",
                                        "2": "stationAddress",
                                        "3": "stationGeofence",
                                        "4": "parkingLotAddress",
                                        "5": "parkingLotGeofence",
                                        "6": "code",
                                        "7": "timezone",
                                        "8": "stationLongitude",
                                        "9": "stationLatitude",
                                        "10": "parkingLotLatitude",
                                        "11": "parkingLotLongitude",
                                        "12": "isStationSummary",
                                        "13" :"isShiftReminderNotification",
                                        "14": "notificationTime",
                                        "15": "notificationType",
                                        "16": "notificationMessage",
                                        "17": "enableGeofenceStation",
                                        "18": "enableGeofenceParkingLot",
                                        "19": "stationType",
                                        "20": "isStationOwner",
                                        "21": "dailySummeryRunTime",
                                        "22": "attendanceNotificationRunTime",
                                    },
                                }
                            },
                            "Role": {
                                "custom": {
                                    functionName: "getCompanyRolesArray",
                                    get: 'Roles',
                                    criteria: {
                                        companyId: getCompanyId(),
                                    },
                                }
                            },
                        }
                    }
                }
                try {
                    let response = await engine().post('/api/lazy/manage/data', params);
                    if (response.data.data.Roles) {
                        if(Array.isArray(response.data.data.Roles)){
                            setItemToStorage('roles', response.data.data.Roles);
                        }else{
                            setItemToStorage('roles', []);
                        }
                    }
                    if (response.data.data.Stations) {
                        setStation(response.data.data.Stations);
                    }
                } catch (error) {
                    console.log({stations: error});
                    // if (error.response.status == '401') {
                    //     window.location = "/logout";
                    // }
                }
            } else if (localStorage.getItem('driverId') !== null) {
                const params = {
                    "actions": {
                        "response": {
                            "Driver": {
                                "find": {
                                    "get": "Driver",
                                    "id": localStorage.getItem('driverId'),
                                    "excludes": ["Company", "vehicleDriverRecords", "driverIncidentRecords", "user", "drivers", "stations", "employmentStatus", "skills", "scores", "employmentStatus", "assignedManagers", "payRate", "schedules", "emergencyContacts"],
                                    "includes": {
                                        "stations": {
                                            "0": "id",
                                            "1": "name",
                                            "2": "stationAddress",
                                            "3": "stationGeofence",
                                            "4": "parkingLotAddress",
                                            "5": "parkingLotGeofence",
                                            "6": "code",
                                            "7": "timezone",
                                            "8": "stationLongitude",
                                            "9": "stationLatitude",
                                            "10": "parkingLotLatitude",
                                            "11": "parkingLotLongitude",
                                            "12": "isStationSummary",
                                            "13": "isShiftReminderNotification",
                                            "14": "notificationTime",
                                            "15": "notificationType",
                                            "16": "notificationMessage",
                                            "17": "enableGeofenceStation",
                                            "18": "enableGeofenceParkingLot",
                                        },
                                    }
                                }
                            },
                        }
                    }
                };
                let response = await api.post('/api/lazy/manage/data', params);

                if (response.data.data.Driver && response.data.data.Driver.stations) {
                    setStation(response.data.data.Driver.stations);
                }
            }


        };
        fetchData();
    }, [])

    return (
        <Body>
            <Spacer top={5}/>
            <Title>Stations</Title>
            <Spacer top={1}/>

            <Text>Create and manage your station data.</Text>
            <Spacer top={5}/>

            <Wrap>
                {stations && stations.length > 0 ?
                    <>
                        {stations.map((station, key) => (
                            <IsGranted key={key} feature={AccessCheck.features.CM_STATIONS} station={station.id}>
                                <Station
                                    station={station}
                                    idList={key}
                                    setEdit={Editing}
                                    setCopy={Copying}
                                    handleDelete={handleDelete}
                                />
                            </IsGranted>
                        ))}
                    </>
                    :
                    <>
                        <SkeletonApp/>
                        <SkeletonApp/>
                    </>
                }

                {FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1 &&
                <SkeletonApp style={{position: 'relative'}} animation={true}>
                    <AddButton type='primary' onClick={() => {
                        toogleModal(true)
                    }}>Add Station</AddButton>
                </SkeletonApp>}
            </Wrap>

            <Modal
                title={(edit.id ? 'Editing Station ' + edit.code : (copy.id ? 'Copying Station ' + copy.code : 'New Station'))}
                width='1000px'
                visible={visible}
                setVisible={() => {
                    setEdit({});
                    setCopy({});
                    toogleModal();
                }}
            >
                {loading && <Loading><img src={Loader} alt=''/></Loading>}
                <Form toogleModal={toogleModal} setLoading={setLoading} edit={edit} copy={copy} action={{create: createStations, edit: editStation}} />
            </Modal>
            <Modal
                title={'Archive Station'}
                width='1000px'
                visible={visibleDelete}
                setVisible={() => {
                    toogleModalDelete();
                }}
            >
                {loading && <Loading><img src={Loader} alt=''/></Loading>}
                <DeletePopup handleDeleteDispacher={handleDeleteDispacher} toogleModal={toogleModalDelete} setLoading={setLoading} deleteId={deleteId} deleteKey={deleteKey} />
            </Modal>
        </Body>
    );
}


const StationRoute = ({match}) => {
    const path = match.path.substr(0, match.path.length - 1);

    return (
        <Switch>
            <Route exact path={match.path}>
                <App/>
            </Route>
            <Route exact path={`${path}/:stationId`}>
                <Navigator/>
            </Route>
            <Route path='*'>
                <Redirect to={match.path}/>
            </Route>
        </Switch>
    );
};


export default StationRoute;
