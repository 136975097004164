import React, {CSSProperties, useState} from 'react';
import styled from "styled-components";
// @ts-ignore
import LoadingAnimation from '../assets/dsp-loading.svg';

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : '0'};
    min-height: ${props => props.containerHeight ? props.containerHeight : '100%'};
    width: ${props => props.containerWidth ? props.containerWidth : '100%'};

    img {
        display: flex;
        height: ${props => props.imgHeight ? props.imgHeight : 'auto'};
        width: ${props => props.imgWidth ? props.imgWidth : '100px'};
    }
`;

type Props = {
    containerHeight?: string;
    containerWidth?: string;
    imgHeight?: string;
    imgWidth?: string;
    margin?: string;
    style?: CSSProperties;
}

function Loading({containerHeight, containerWidth, imgHeight, imgWidth, margin}: Props) {
    return (
        <LoadingContainer containerHeight={containerHeight} containerWidth={containerWidth} imgHeight={imgHeight}
                          imgWidth={imgWidth} margin={margin}>
            <img src={LoadingAnimation} alt='loading'/>
        </LoadingContainer>
    );
}

export default Loading;

export function useLoading(): { loading: boolean, setLoading: React.Dispatch<React.SetStateAction<boolean>> } {
    const [loading, setLoading] = useState(true);
    return {loading, setLoading}
}