import React, {useEffect, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
import styled from "styled-components";
import {Member} from "twilio-chat/lib/member";

const Container = styled.div`
    display: flex;
    font-size: 10px;
    margin: 0 20px 0 20px;
    color: #516F90;
`;

type Props = {
    channel: Channel;
};

function TypingIndicator(props: Props) {
    const {channel} = props;
    const [isTyping, setIsTyping] = useState({state: false, user: ""});
    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on("typingStarted", async (member: Member) => {
                let user = await member.getUser();
                setIsTyping({state: true, user: user.friendlyName});
            });

            channel.on("typingEnded", async (member: Member) => {
                let user = await member.getUser();
                setIsTyping({state: false, user: user.friendlyName});
            });
        }

        return () => {
            if (channel) {
                channel.removeListener("typingStarted", () => abortController.abort());
                channel.removeListener("typingEnded", () => abortController.abort());
            }
        };
    }, [channel]);

    if (!isTyping.state) return null;

    return <Container>{`${isTyping.user} is typing ...`}</Container>;
}

export default TypingIndicator;
