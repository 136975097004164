// import { Check, Tilde, TrendDown, TrendUp, Warning } from '../../Icons';
import {Theme} from "@dspworkplace/ui";
import {dspIcon} from "../../../../Utilities";

export const ColorOptions = {
    success: Theme.colors.success.text,
    error: Theme.colors.error.text,
    zero: '#CCCCCC',
    tilde: '#CCCCCC',
    warning: '#DBAE60',
};

export const IconOptions = {
    TrendUp: dspIcon({type: 'TrendUp'}),
    TrendDown: dspIcon({type: 'TrendDown'}),
    Tilde: dspIcon({type: 'Tilde'}),
    Zero: dspIcon({type: 'Tilde'}),
    Warning: dspIcon({type: 'Warning'}),
    Check: dspIcon({type: 'Check'}),
};

export type ReportScorecard = {
    date: string;
    id: number;
    week: number;
    year: number;
    ranking: number | null;
    tier: string;
    delivered: number;
    focus: string;
    fico: number;
    signViolationRate: number;
    followingDistanceRate: number;
    distractionsRate: number;
    seatbeltOffRate: number;
    speedingEventRate: number;
    deliveryCompletionRate: number;
    deliveryAndReceived: number;
    photoOnDelivery: number;
    contactCompliance: number;
    scanCompliance: number;
    attendedCompliance: number;
    dnrsCount: number;
    photoOpportunities: number;
    contactOpportunities: number;
};

export type ItemScorecardInformation = {
    metricIdentifier: string;
    metricName: string;
    metricValue: number | string | null;
    metricType: string | null;
    change: number | null;
    trend: string | null;
    trendStyle: string | null;
    scenario: string | null;
    valueField0: string | number | null;
    valueField1: string | number | null;
    message?: string | null;
    textColor?: string;
    icon?: string;
    cardStyle?: string;
    explanation?: string;
    show?: boolean;
};

export type Scorecard = {
    numberOfFields: number;
    sortedList: Array<ItemScorecardInformation>;
    value0: ReportScorecard;
    value1: ReportScorecard;
    countChecker?: Array<any>;
};

export type MobileScorecardInformation = {
    scorecard: Scorecard;
    incidents: {
        sortedList: Array<ItemScorecardInformation>;
    };
    rescues: {
        sortedList: Array<ItemScorecardInformation>;
    };
};

export function getMetricValue(metricType: any, metricValue: any) {
    if (metricType === 'number' && typeof metricValue === 'number') {
        return JSON.stringify(metricValue);
    }

    if (metricType === 'percentage' && typeof metricValue === 'number') {
        return `${metricValue}%`;
    }
    if (typeof metricValue === 'string') {
        return metricValue;
    }
    return 'N/A';
}
