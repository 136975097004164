import React, {forwardRef, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {TagInput, SubTitle, Spacer, Button} from "@dspworkplace/ui";
import SettingsContext from "../context";
import {getCompanyId, showToast} from "../../../Utilities";
import {useAuth} from "../../../Auth";
import {LoadingView} from "../../../components/Loading";

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

function getStateOfWorkHourComplianceNotifyUsers(state) {
    try {
        let workHourComplianceNotifyUsers = Array.isArray(state.company.workHourComplianceNotifyUsers) ? state.company.workHourComplianceNotifyUsers : [];
        return {workHourComplianceNotifyUsers};
    } catch (e) {
        console.error({getStateForWorkHourComplianceNotifyUsers: e});
        return {workHourComplianceNotifyUsers: []};
    }
}

function formatForTagInputForUser(list, user) {
    try {
        if (list.includes(user.userId)) {
            return {name: user.friendlyName, value: user.userId, selected: true};
        } else {
            return {name: user.friendlyName, value: user.userId};
        }
    } catch (e) {
        console.error({formatForTagInputForUser: e, list, user});
        return {name: user.friendlyName, value: user.userId};
    }
}

async function fetchNotifyUsers(api, state, setWorkingHourComplianceNotifyUsers) {
    try {
        let {data} = await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            notEqualRole: 'ROLE_DELIVERY_ASSOCIATE'
        });
        let users = data.users;
        let {workHourComplianceNotifyUsers} = getStateOfWorkHourComplianceNotifyUsers(state);
        let tags = [];
        let user;
        for (let i = 0; i < users.length; i++) {
            user = users[i];
            tags.push(formatForTagInputForUser(workHourComplianceNotifyUsers, user));
        }
        setWorkingHourComplianceNotifyUsers(tags);
        return true
    } catch (e) {
        console.error({fetchBotManagers: e});
        return false;
    }
}

const WorkHourComplianceNotifyUsers = forwardRef((props, ref) => {
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const [workingHourComplianceNotifyUsers, setWorkingHourComplianceNotifyUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetchNotifyUsers(api, state, setWorkingHourComplianceNotifyUsers).then(() => {
            setLoading(false);
        });
    }, [state.company]);

    if (loading) {
        return <LoadingView containerWidth={'400px'} imgWidth={'40px'}/>
    }

    return (
        <>
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Select user(s) to be alerted:"}
                        options={workingHourComplianceNotifyUsers}
                        size="big"
                        name={'workHourComplianceNotifyUsers'}
                        ref={ref}
                        placeholder={workingHourComplianceNotifyUsers.filter(e => e.selected).length === 0 ? "Default to Operation Account Manager users if they exist" : ""}
                    />
                </TagInputContainer>
            </RowContainer>
        </>
    );
});

export default WorkHourComplianceNotifyUsers;
