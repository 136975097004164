import { useEffect } from "react";
import { alert } from "../components/Alert";

const rotateAlertEvent = () => {
    const isTouchDevice = "ontouchstart" in document.documentElement;

    if (window.matchMedia("(orientation: portrait)").matches && isTouchDevice) {
        alert({ text: "Please rotate device to use in landscape mode", icon: true });
    }
}

const useDeviceRotateAlert = () => {
    useEffect(() => {
        rotateAlertEvent();
    },[])

    window.addEventListener("resize", rotateAlertEvent);

    return () => window.removeEventListener("resize", rotateAlertEvent);
}

export default useDeviceRotateAlert