import React from 'react';
import Picker, {IEmojiPickerProps} from "emoji-picker-react";

interface Props extends IEmojiPickerProps {
    show: boolean;
}

const EmojiSelector: React.FC<Props> = (props) => {
    const {
        onEmojiClick,
        disableAutoFocus,
        disableSearchBar,
        disableSkinTonePicker,
        emojiUrl,
        groupNames,
        native,
        pickerStyle,
        preload,
        show,
        skinTone
    } = props;
    return (
        <Picker
            onEmojiClick={onEmojiClick}
            emojiUrl={emojiUrl}
            groupNames={groupNames}
            disableAutoFocus={disableAutoFocus}
            disableSearchBar={disableSearchBar}
            skinTone={skinTone}
            pickerStyle={pickerStyle ? {
                ...pickerStyle,
                display: show ? 'block' : 'none'
            } : {display: show ? 'block' : 'none'}}
            preload={preload}
            disableSkinTonePicker={disableSkinTonePicker}
            native={native}
        />
    );
}

export default EmojiSelector;