import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Icon, Input, Spacer, Text, Title } from '@dspworkplace/ui';
import Loading, { useLoading } from "../../../../components/Loading";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../Auth";
import { patternFloatValue } from '../../../../patterns';
import {AccessCheck, IsGranted} from "../../../../security";

const Table = styled.ul`
    list-style:none;
    margin:0;
    padding:0;
    display:flex;
    flex-direction:column;
    max-width:400px;

    >li.header {
        border-bottom:1px solid #7C98B6;
        >div {
            color:#707070;
        }
    }

    >li {
        display:inline-block;
        border-bottom:1px dashed #CBD6E2;
    }

`;

const TableData = styled.div`
    width:${props => props.width ? props.width : '224px'}
    padding:12px;
    font-size:14px;
    color:#333333;
    display: inline-flex;
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    align-items: center;
    ${props => props.order && `cursor:pointer;`}
    vertical-align: middle;

    svg {
        cursor:pointer;
    }
`;

const CustomButton = styled.a`
    font-family: circe-rounded;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    background: #EAF0F6;
    color: #0071BC;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    font-size: 21px;
`;
const OrderIco = ({ direction = 'ASC' }) => {
    if (direction == 'DESC') return (<Icon.ArrowUp size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />);
    return (<Icon.ArrowDown size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />)
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'create':
            return { ...state, skillrates: action.skillrates, isLoading: action.isLoading };
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'add':
            return { ...state, skillrates: [...state.skillrates, action.skillrate], isLoading: action.isLoading };
        case 'setUpdateKey':
            return { ...state, updateKey: action.updateKey }
        case 'update':
            state.skillrates[action.key] = action.skillrate;
            return { ...state, updateKey: -1 };
        case 'cancel':
            return { ...state, updateKey: -1 };
        case 'filter':
            if (state.filterOrder === 'ASC') {
                if (action.filterType === 'num') {
                    state.skillrates.sort((a, b) => (parseFloat(a[action.filterName]) < parseFloat(b[action.filterName])) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1);
                } else {
                    state.skillrates.sort((a, b) => (a[action.filterName] < b[action.filterName]) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1);

                }
                return { ...state, skillrates: state.skillrates, filterOrder: 'DESC', filterName: action.filterName };
            } else {
                if (action.filterType === 'num') {
                    state.skillrates.sort((a, b) => (parseFloat(a[action.filterName]) > parseFloat(b[action.filterName])) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1);
                } else {
                    state.skillrates.sort((a, b) => (a[action.filterName] > b[action.filterName]) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1);
                }
            }
            return { ...state, skillrates: state.skillrates, filterOrder: 'ASC', filterName: action.filterName };
        case 'delete':
            state.skillrates.splice(action.key, 1);
            return { ...state }
        default:
            return state;
    }
};

const initialState = {
    rates: [],
    updateKey: -1,
    filterName: 'name',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
    isEdit: false,
};

const App = (localState = '') => {
    const { api } = useAuth();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setLoading] = useLoading();
    const [data, setData] = useState({ update: -1, skillrates: [] });
    const [skillList, setSkillList] = useState({ skillList: [] });
    const [filter, setFilter] = useState({ order: { name: 'ASC' } });
    const useLocationState = useLocation().state;
    // const station = useLocation().state.station;

    const getSkills = async (skills) => {
        const params = {
            "actions": {
                "response": {
                    "Skill": {
                        "findBy": {
                            "criteria": { "company": localStorage.getItem('company') },
                            "get": "Skills",
                            orderBy: {
                                'name': 'ASC'
                            },
                            "includes": {
                                "0": "id",
                                "1": "name"
                            },
                            "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                        }
                    }
                }
            }
        }
        try {
            let response = await api.post('/api/lazy/manage/data', params);
            return new Promise((resolve, reject) => {
                setSkillList({ ...skillList, skillList: response.data.data.Skills });
            });
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    };
    const handleDelete = async (id, key) => {
        const confirmation = window.confirm('Confirm that you want to delete this item');
        if (!confirmation) {
            return false;
        }

        try {
            let params = {
                "actions": {
                    "delete": {
                        "SkillRate": {
                            "delete_rate_1": {
                                "findOneBy": {
                                    "id": id
                                }
                            }
                        }
                    },
                }
            };
            await api.post('/api/lazy/manage/data', params);

            dispatch({ type: 'delete', key: key });

        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
            dispatch({ type: 'default' });
        }

    }
    const filterBy = (sortFieldName, sortFieldType) => { dispatch({ type: 'filter', filterName: sortFieldName, filterType: sortFieldType }); }

    useEffect(() => {
        const getSkillRates = async () => {
            try {
                const params = {
                    "actions": {
                        "response": {
                            "SkillRate": {
                                "custom": {
                                    "functionName": "getSkillRateByStation",
                                    "get": "SkillRates",
                                    "criteria": {
                                        "station": (useLocationState && useLocationState.station) ? useLocationState.station.id : localState.state.station.id
                                    },
                                    "includes": {
                                        "0": "defaultRate",
                                        "1": "id",
                                        "skill": ["id", "name"]
                                    },
                                    "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers", "station"]
                                }
                            }
                        }
                    }
                };
                let response = await api.post('/api/lazy/manage/data', params);

                let r = response.data.data.SkillRates.map(item => { return { id: item.id, name: item.skill.name, defaultRate: item.defaultRate, skillId: item.skill.id } });
                dispatch({ type: 'create', skillrates: r, isLoading: false });
                await getSkills();
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                dispatch({ type: 'default' });
            }
        }
        getSkillRates();

    }, [filter]);

    return (
        <>
            <Spacer top={5} />
            <Title>Skill Rates</Title>
            <Spacer top={1} />
            <Text>Set your default hourly rate for the skills that are associated for this station.</Text>
            <Spacer top={5} />
            <Table>
                <li className='header'>
                    <TableData order={1} width='150px' onClick={() => { filterBy('name', 'str') }}>Skill {state.filterOrder && state.filterName === 'name' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />}</TableData>
                    <TableData order={1} width='150px' onClick={() => { filterBy('defaultRate', 'str') }}>Hourly Rate {state.filterOrder && state.filterName === 'defaultRate' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }} />
                </li>
                {state.isLoading && <Loading style={{ height: '80px', marginTop: '20px' }} />}

                {!state.isLoading && state.skillrates &&
                    state.skillrates.sort((a, b) => a.name.localeCompare(b.name)).map((rate, key) => {

                        if (key == state.updateKey) {
                            return (
                                <AddForm key={key} index={key} dispatch={dispatch} data={state} skillList={skillList} station={(useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station} />
                            );
                        }

                        return (
                            <li key={key}>
                                <TableData width='150px'>{rate.name}</TableData>
                                <TableData width='150px'>${rate.defaultRate}</TableData>
                                <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }}>
                                    <IsGranted feature={AccessCheck.features.ST_SKILL_RATES} ops={AccessCheck.OPS.U}>
                                        <Spacer inline right={3}>
                                            <Icon.Edit size='20px' color='#7C98B6'
                                                style={{ position: 'relative', top: '3px' }} onClick={() => { dispatch({ type: 'setUpdateKey', updateKey: key }) }} />
                                        </Spacer>
                                    </IsGranted>
                                    <IsGranted feature={AccessCheck.features.ST_SKILL_RATES} ops={AccessCheck.OPS.D}>
                                        <Icon.Times size='20px' color='#7C98B6' onClick={() => { handleDelete(rate.id, key) }} />
                                    </IsGranted>
                                </TableData>
                            </li>
                        )

                    })
                }
                {!state.isLoading && state.skillrates && state.isEdit === false &&
                    <IsGranted feature={AccessCheck.features.ST_SKILL_RATES} ops={AccessCheck.OPS.C}>
                        <AddForm dispatch={dispatch} data={state} skillList={skillList} station={(useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station} />
                    </IsGranted>}
            </Table>
        </>
    );
}

const AddForm = ({ dispatch, data, index, skillList, station }) => {

    const { api, logout } = useAuth();
    let defaultValues;
    let selected;
    let defaultSkill;
    let skillsObj = [];
    const [isCancelButton, setIsCancelButton] = useState(false);
    const [formData, setFormData] = useState({ name: '', defaultRate: '' });
    if (index > -1) {
        selected = data.skillrates[index];
        defaultSkill = selected.skillId;
        defaultValues =
        {
            name: selected.name,
            defaultRate: selected.defaultRate
        }
        data.isEdit = true;
        dispatch({ type: 'default' });
    } else {
        data.isEdit = false;
        dispatch({ type: 'default' });
    }

    const { register, handleSubmit, errors, reset, getValues } = useForm({ defaultValues: defaultValues });
    let temp = [];
    let skillObj = [];
    const { defaultRate } = getValues();
    Object.keys(data.skillrates).forEach(function (s) {
        skillObj.push(data.skillrates[s].skillId);
    });

    Object.keys(skillList.skillList).forEach(function (skill) {
        if (skillObj.indexOf(skillList.skillList[skill].id) == -1 || data.isEdit === true) {
            temp = {
                'name': skillList.skillList[skill].name.toString(),
                'value': skillList.skillList[skill].id.toString(),
            }
            skillsObj.push(temp);
        }
    });

    const handleEdit = async (form, selected, index, currentSkill) => {
        let editOldData = data.skillrates.filter((skillrate) => parseInt(skillrate.id) === parseInt(selected.id))

        try {
            let param = {
                "actions": {
                    "update": {
                        "SkillRate": {
                            "update_skill_1": {
                                "findBy": {
                                    "id": selected.id
                                },
                                "updateRecord": {
                                    'skill': form.skill,
                                    'defaultRate': form.defaultRate
                                }
                            }
                        },
                    },
                    "response": {
                        "DriverSkill": {
                            "custom": {
                                "functionName": "updateDriverSkillFromSkillRate",
                                "criteria": {
                                    "skillRate": selected.id,
                                    "oldHourlyRate": editOldData[0].defaultRate,
                                    'hourlyRate': form.defaultRate
                                },
                                "get": "driverSkill"
                            }
                        }
                    }
                }
            }
            // "update_skill_rate_1": {
            //   "findBy": {
            //     "skill" : selected.id,
            //     "hourlyRate" : editOldData[0].defaultRate,
            //     "isArchive" : 0
            //   },
            //   "updateRecord": {
            //     'skill':form.skill,
            //     'hourlyRate':form.defaultRate
            //   }
            // }
            await api.post('/api/lazy/manage/data', param);
            const updatedSkillRate = { id: selected.id, name: currentSkill.name, defaultRate: form.defaultRate, skillId: currentSkill.id };
            dispatch({ type: 'update', skillrate: updatedSkillRate, key: index });
            setIsCancelButton(false);
        } catch (error) {
            dispatch({ type: 'default' });
            alert("Error to update rate. Login again, please").then(() => {

            });
        }
    }

    const cancelBtn = async () => {
        if (!selected) {
            reset({ defaultRate: '', skill: 0 });
            setIsCancelButton(false);
        } else {
            dispatch({ type: 'cancel' });
        }
        return false;
    }

    const handleOnChange = useCallback(event => {
        if (event.target) {
            if (event.target.name === 'defaultRate') { formData.defaultRate = event.target.value; }
            if (formData.name.length > 0 || formData.defaultRate.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }
        } else {
            formData.name = event.value;
            if (formData.name.length > 0 || formData.defaultRate.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }

        }

    });
    const onSubmit = async form => {
        let currentSkill;
        Object.keys(skillList.skillList).forEach(function (key) {
            if (skillList.skillList[key].id == form.skill) {
                currentSkill = skillList.skillList[key];
            }
        });
        if (selected) {
            await handleEdit(form, selected, index, currentSkill);
        } else {
            let params = {
                "actions": {
                    "create": {
                        "SkillRate": {
                            "skillRates_1": {
                                'station': station.id,
                                'skill': form.skill,
                                'defaultRate': form.defaultRate

                            }
                        }
                    }
                }
            };

            try {
                let response = await api.post('/api/lazy/manage/data', params);

                if (response.status === 200) {
                    const newSkillRate = { id: response.data.data.responseId, name: currentSkill.name, defaultRate: form.defaultRate, skillId: currentSkill.id };
                    dispatch({ type: 'add', skillrate: newSkillRate, isLoading: false });
                    setIsCancelButton(false);
                } else {
                    alert('Error to create new skill');
                }
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
        reset({ name: '', skill: '' });
    }

    const errorMessages = {
        defaultRate: {
            required: 'This field can not be empty',
            pattern: 'Enter value with 2 decimal places only'
        }
    }

    return (
        <li>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TableData width='150px'>
                    {data.isEdit === true ? <Dropdown
                        name='skill'
                        ref={register({ required: 'This field can not be empty' })}
                        placeholder='Select Skill'
                        error={errors.skill && errors.skill.message}
                        size={'small'}
                        defaultValue={defaultSkill}
                        options={skillsObj}
                        onChange={handleOnChange}
                    /> : <Dropdown
                            name='skill'
                            ref={register({ required: 'This field can not be empty' })}
                            placeholder='Select Skill'
                            error={errors.skill && errors.skill.message}
                            size={'small'}
                            defaultValue={defaultSkill}
                            options={skillsObj}
                            onChange={handleOnChange}
                        />}
                </TableData>
                <TableData width='150px'>
                    <Input
                        name='defaultRate'
                        ref={register({
                            required: true,
                            pattern: patternFloatValue,
                        })}
                        error={errors.defaultRate && errorMessages.defaultRate[errors.defaultRate.type]}
                        valid={!errors.defaultRate && defaultRate && defaultRate.length > 0}
                        size={'small'}
                        type='text'
                        onChange={handleOnChange}
                    />
                </TableData>
                <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px', marginTop: (errors.name ? '-23px' : '0px') }}>
                    <Button type='primary' style={{ 'margin-right': '10px' }}>{index >= 0 ? 'Save' : 'Add'}</Button>
                    {isCancelButton || selected ? <CustomButton onClick={() => { cancelBtn() }} >X</CustomButton> : ''}
                </TableData>
            </form>
        </li>
    )
};
export default App;
