import React from 'react';
import styled from "styled-components";

const mobileWidth = 428;
const cardWidth = mobileWidth - 16 - 16;

export const CardContainerStyle = styled.div`
    &.default {
        display: flex;
        flex-direction: row;
        height: 96px;
        margin: 4px 16px;
        border-radius: 4px;
        border-color: #EAF0F6;
        border-width: 2px;
        align-items: center;
        border-style: solid;
        flex-shrink: 0;
    }

    &.view {
        width: ${cardWidth};
    }

    &.leftView {
        width: 55%;
    }
`;

type Props = {
    style?: string;
    fullWidth?: boolean;
    backgroundColor?: string;
    borderColor?: string;
};

const CardContainer: React.FC<Props> = ({

                                            children,
                                            style = null,
                                            backgroundColor, borderColor
                                        }) => {
    return (
        <CardContainerStyle className={style ? `default view ${style}` : 'default view'}
                            style={{backgroundColor, borderColor}}>
            {children}
        </CardContainerStyle>
    );
};

export default CardContainer;
