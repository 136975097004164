import React, {UIEvent, useEffect, useState} from "react";
import {GoogleMap, LoadScript, Circle, Marker, Autocomplete} from "@react-google-maps/api";
import {LoadingView} from "../Loading";
import {Input, Spacer} from "@dspworkplace/ui";
import styled from "styled-components";
import {useFormContext} from "react-hook-form";
import {devLog, googleMapsApiKey} from "../../utils";


const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

// @ts-ignore
const Container = styled.div`
    display: inline-flex;
    width: 170px;

    div {
        width: 160px;
    }
`;

interface LatLng {
    lat: () => number;
    lng: () => number;
}

interface GoogleMouseEvent<T = Element, E = React.MouseEvent> extends UIEvent<T, E> {
    latLng: LatLng;
    pixel: {
        x: number;
        y: number;
    };
}

interface PlaceResult {
    address_components: Array<any>;
    adr_address: string;
    aspects: Array<string>;
    business_status: string;
    formatted_address: string;
    formatted_phone_number: string;
    geometry: {
        location: LatLng;
        viewport: {
            Va: {
                i: number;
                j: number;
            };
            Za: {
                i: number;
                j: number;
            };
        };
    };
    html_attributions: Array<any>;
    icon: string;
    international_phone_number: string;
    name: string;
    opening_hours?: any;
    photos?: Array<any>;
    place_id: string;
    url: string;
    plus_code: {
        compound_code: string;
        global_code: string;
    };
    scope: string;
    types: Array<any>;
    utc_offset_minutes: number;
    vicinity: string;
    website?: string;
    user_ratings_total?: number;
}

interface GoogleAutocomplete {
    getPlace: () => PlaceResult;
}

type Props = {
    initialRadius: number;
    initialLatitude: number;
    initialLongitude: number;
    initialAddress: string;
    setNewAddress: (arg: string) => void;
    setLatLng: (lat: number, lng: number) => void;
    setGeofence: (radius: number | string) => void;
    watchAllFields?: {
        stationAddress: string;
        stationGeofence: string;
        parkingLotAddress: string;
        parkingLotGeofence: string;
    };
    errors?: {
        stationAddress: { message: string };
        stationGeofence: { message: string };
        parkingLotAddress: { message: string };
        parkingLotGeofence: { message: string };
    };
    register?: (arg: { required: string }) => void;
    label: string;
};

export default function MapAddressGeofence(props: Props) {
    const {
        initialRadius,
        initialLatitude,
        initialLongitude,
        initialAddress,
        label,
        setGeofence,
        setLatLng,
        setNewAddress
    } = props;
    const {register, getValues, setValue, watch} = useFormContext();
    const {radiusStation, radiusParking} = getValues();
    const watchAllFields = watch();
    const initialCenter = {
        lat: initialLatitude ? initialLatitude : 44.85845510827881,
        lng: initialLongitude ? initialLongitude : -93.14065563187714
    };
    const [autoComplete, setAutoComplete] = useState<GoogleAutocomplete | null>(null);
    const [center, setCenter] = useState(initialCenter);
    const [addressValid, setAddressValid] = useState({valid: false, error: false});
    const [radiusValid, setRadiusValid] = useState({valid: false, error: false});
    const [loading, setLoading] = useState(true);
    devLog({radiusStation, radiusParking, watchAllFields});

    useEffect(() => {
        setLoading(true);
        if (label === "Station Address") {
            setNewAddress(initialAddress);
            setLoading(false);
        } else {
            setNewAddress(initialAddress);
            setLoading(false);
        }
    }, []);

    const onLoadCircle = circle => {
        devLog("Circle onLoad circle: ", circle);
        setValue("radius", initialRadius);
        if (label === "Station Address") {
            setValue("radiusStation", initialRadius);
        } else {
            setValue("radiusParking", initialRadius);
        }
        setRadiusValid({error: false, valid: true});
    };

    const onUnmountCircle = circle => {
        devLog("Circle onUnmount circle: ", circle);
    };

    const onLoadMarker = marker => {
        devLog("Marker onLoad marker: ", marker);
    };

    const onDragEndMarker = (e: GoogleMouseEvent) => {
        const newCenter = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };
        setCenter(newCenter);
        setLatLng(newCenter.lat, newCenter.lng);
    };

    const onLoadAutocomplete = (e: GoogleAutocomplete) => {
        devLog("Autocomplete onLoad event:", e);
        devLog({initialAddress});
        if (label === "Station Address") {
            setValue("addressStation", initialAddress);
            setNewAddress(initialAddress);
        } else {
            setValue("addressParking", initialAddress);
            setNewAddress(initialAddress);
        }
        if (typeof initialAddress === "string" && initialAddress.length > 0) {
            setAddressValid({valid: true, error: false});
        }
        setAutoComplete(e);
    };

    const onPlaceChanged = () => {
        if (!!autoComplete) {
            let place = autoComplete.getPlace();
            if (place && place.geometry && place.geometry.location) {
                devLog(place);
                const newCenter = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setCenter(newCenter);
                setLatLng(newCenter.lat, newCenter.lng);
                if (place.formatted_address) {
                    console.log({formatted_address: place.formatted_address, place});
                    setValue("address", place.formatted_address);
                    if (label === "Station Address") {
                        setValue("addressStation", place.formatted_address);
                    } else {
                        setValue("addressParking", place.formatted_address);
                    }
                    setNewAddress(place.formatted_address);
                    setAddressValid({valid: true, error: false});
                } else {
                    setAddressValid({valid: false, error: true});
                }
            } else {
                setAddressValid({valid: false, error: true});
            }
        } else {
            setAddressValid({valid: false, error: true});
        }
    };

    const onUnmountAutocomplete = e => {
        devLog("Autocomplete onUnmount", e);
    };

    const options = {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#aa030a",
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1
    };

    if (loading) {
        return <LoadingView containerHeight={"400px"} containerWidth={"100%"} imgHeight={"200px"}/>;
    }

    return (
        <>
            {/** @ts-ignore */}
            <LoadScript
                googleMapsApiKey={googleMapsApiKey}
                libraries={["places"]}
                loadingElement={<LoadingView containerHeight={"200px"} containerWidth={"100%"} imgHeight={"100px"}/>}
            >
                <Spacer top={2}/>
                <SpacerInline right={5}>
                    {/** @ts-ignore */}
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onUnmount={onUnmountAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <>
                            {label === "Station Address" && (
                                <Input
                                    name="addressStation"
                                    label={label}
                                    size="big"
                                    ref={register}
                                    error={addressValid.error && "You need to provide a valid address."}
                                    valid={addressValid.valid}
                                />
                            )}
                            {label === "Parking Lot Address" && (
                                <Input
                                    name="addressParking"
                                    label={label}
                                    size="big"
                                    ref={register}
                                    error={addressValid.error && "You need to provide a valid address."}
                                    valid={addressValid.valid}
                                />
                            )}
                        </>
                    </Autocomplete>
                </SpacerInline>

                <Container id={"stationGeofenceDiv"}>
                    {label === "Station Address" && (
                        <Input
                            name="radiusStation"
                            label="Geofence (Meters)"
                            size="small"
                            onChange={e => {
                                if (typeof e.target.value === "string" && parseFloat(e.target.value)) {
                                    setValue("radiusStation", e.target.value);
                                    setGeofence(e.target.value);
                                    setRadiusValid({error: false, valid: true});
                                } else {
                                    setRadiusValid({error: true, valid: false});
                                }
                            }}
                            ref={register}
                            error={radiusValid.error && "Geofence required."}
                            valid={radiusValid.valid}
                        />
                    )}
                    {label === "Parking Lot Address" && (
                        <Input
                            name="radiusParking"
                            label="Geofence (Meters)"
                            size="small"
                            onChange={e => {
                                if (typeof e.target.value === "string" && parseFloat(e.target.value)) {
                                    setValue("radiusParking", e.target.value);
                                    setGeofence(e.target.value);
                                    setRadiusValid({error: false, valid: true});
                                } else {
                                    setRadiusValid({error: true, valid: false});
                                }
                            }}
                            ref={register}
                            error={radiusValid.error && "Geofence required."}
                            valid={radiusValid.valid}
                        />
                    )}
                </Container>
            </LoadScript>
            {/** @ts-ignore */}
            <LoadScript
                googleMapsApiKey={googleMapsApiKey}
                libraries={["places"]}
                loadingElement={<LoadingView containerHeight={"400px"} containerWidth={"100%"} imgHeight={"150px"}/>}
            >
                {/** @ts-ignore */}
                <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "500px",
                        marginTop: "16px",
                        overflow: "auto"
                    }}
                    center={center}
                    zoom={18}
                >
                    <Circle
                        onLoad={onLoadCircle}
                        onUnmount={onUnmountCircle}
                        center={center}
                        options={options}
                        radius={
                            label === "Station Address"
                                ? parseFloat(!!radiusStation ? radiusStation : 80)
                                : parseFloat(!!radiusParking ? radiusParking : 80)
                        }
                    />
                    {/** @ts-ignore */}
                    <Marker onLoad={onLoadMarker} position={center} draggable={true} onDragEnd={onDragEndMarker}/>
                </GoogleMap>
            </LoadScript>
        </>
    );
}
