import React, {useEffect, useState} from 'react';
import {Responsive as ResponsiveGridLayout} from "react-grid-layout";
import styled from 'styled-components';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DragBg from '../../../assets/drag-bg.svg';

//const ReactGridLayout = WidthProvider(ResponsiveGridLayout);

const DragField = styled(ResponsiveGridLayout)`

  ${props => props.isDragging ? `
    background: url(${DragBg});
    background-repeat: repeat-y;
    background-position:-3px 0px;
    background-size: 100% 51px;
  `: ''}`;

const cols = {lg:4,sm:2,xs:1};
const breakpoints = {lg: 1280, sm: 768, xs: 480};

const DashboardDragField = ({ children, width, dashboardItems, panelId, onUpdateCardConfig}) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnd = (layout,oldItem,newItem,event,element) => {
        if(!element) return;
        setIsDragging(false);

        if(JSON.stringify(oldItem) !== JSON.stringify(newItem)){
            const [_,id] = newItem?.i.split('-');

            const toUpdate = {
                x: newItem.x,
                y: newItem.y,
                w: newItem.w,
                h: newItem.h
            };
            onUpdateCardConfig(panelId, id, toUpdate);
        }
    }

    useEffect(() => {
        window.addEventListener('mouseup', handleDragEnd);
        return () => window.removeEventListener('mouseup', handleDragEnd);
    }, []);

    return (
        <DragField
            className="layout"
            rowHeight={120}
            width={width}
            cols={cols}
            margin={[12,12]}
            compactType='vertical'
            breakpoints={breakpoints}
            resizeHandles={['se']}
            style={{position:'relative'}}
            onDragStart={() => setIsDragging(true)}
            onDragStop={handleDragEnd}
            draggableHandle='.drag'
            onResizeStart={() => setIsDragging(true)}
            onResizeStop={handleDragEnd}
            isDragging={isDragging}
            isBounded={true}
        >
            {children}
        </DragField>
    );
}

export default DashboardDragField;