import {Dispatch, useDebugValue, useReducer} from "react";
import {createContainer} from "react-tracked";
import {Client} from "twilio-chat";
import {Channel} from "twilio-chat/lib/channel";
import Filter from "bad-words";
import {Task} from "./types";
import {debugLog, setItemToStorage} from "./utils";
import {getRole} from "../Auth";
import {showToast} from "../Utilities";
import {Client as ConversationClient, Conversation} from '@twilio/conversations';

type NewChannelInformation = {
    name: string | null | undefined;
    description: string | null | undefined;
    image: string | null | undefined;
    errorMessage: string;
};

export type NotificationArray = {
    channelSid: string;
    notifications: number;
    ready?: boolean;
}

type AllowToChat = {
    endDatetime?: any;
    startDatetime?: any;
    track: string;
    value: boolean;
};

export type AppState = {
    isOpen: boolean;
    conversationClient?: ConversationClient;
    channels: Array<Channel>;
    conversations: Conversation[];
    loading: boolean;
    activeChannels: Array<Channel>;
    activeConversations: Conversation[];
    duoChannels: Array<Channel>;
    duoConversations: Conversation[];
    groupChannels: Array<Channel>;
    groupConversations: Conversation[];
    botChannels: Array<Channel>;
    botConversations: Conversation[];
    maxChatViews: number;
    groupChannelModal: boolean;
    archiveGroupChannelModal: boolean;
    archiveDuoChannelModal: boolean;
    newChannel: NewChannelInformation;
    directMessageModal: boolean;
    archiveConversations: Array<Conversation>;
    pinChannels: Array<Channel>;
    pinConversations: Array<Conversation>;
    notificationsArray: Array<NotificationArray>;
    notifications: number;
    initClient: boolean;
    filter?: Filter;
    tasks: Task[];
    notificationsCount: number;
    botCount: number;
    allowToChat: AllowToChat;
    listBadWordsModal: boolean;
    update: number;
    initialChannelsReady: boolean;
    duo: any;
    group: any;
    bot: any;
    showFilterForWords: boolean;
    allInitNotificationsArray: boolean;
};

export type AppAction =
    | { type: 'ALLOW_TO_CHAT'; allowToChat: AllowToChat; }
    | { type: "INIT_CHAT"; client: Client; channels: Array<Channel>; loading: boolean, filter: Filter, tasks: Task[] }
    | { type: "SET_UP"; conversationClient: ConversationClient; conversations: Array<Conversation>; loading: boolean, filter: Filter, tasks: Task[] }
    | { type: "ADD_TASK"; task: Task }
    | { type: "UPDATE_TASK"; task: Task }
    | { type: "SET_FILTER"; filter: Filter }
    | { type: "OPEN_CHAT"; isOpen: boolean }
    | { type: "SET_CLIENT"; client: Client }
    | { type: "SET_CONVERSATION_CLIENT"; conversationClient: ConversationClient }
    | { type: "ADD_CONVERSATION"; conversation: Conversation }
    | { type: "REMOVE_CHANNEL"; channel: Channel }
    | { type: "REMOVE_CONVERSATION"; conversation: Conversation }
    | { type: "UPDATE_CHANNEL"; channel: Channel }
    | { type: "JOINED_CHANNEL"; channel: Channel }
    | { type: "MESSAGE_ADDED_ACTIVE_CHANNEL"; channel: Channel }
    | { type: "MESSAGE_ADDED_ACTIVE_CONVERSATION"; conversation: Conversation }
    | { type: "ADD_ACTIVE_CONVERSATION"; conversation: Conversation }
    | { type: "CLOSE_ACTIVE_CHANNEL"; channel: Channel }
    | { type: "CLOSE_ACTIVE_CONVERSATION"; conversation: Conversation }
    | { type: "SET_MAX_CHAT_VIEWS"; maxChatViews: number }
    | { type: "CLOSE_NEW_CHANNEL_MODAL" }
    | { type: "OPEN_NEW_CHANNEL_MODAL" }
    | { type: "SET_NEW_CHANNEL_INFORMATION"; newChannel: NewChannelInformation }
    | { type: "RESET_NEW_CHANNEL_INFORMATION" }
    | { type: "OPEN_DIRECT_MESSAGE_MODAL" }
    | { type: "CLOSE_DIRECT_MESSAGE_MODAL" }
    | { type: "CLOSE_ARCHIVE_GROUP_MODAL" }
    | { type: "OPEN_ARCHIVE_GROUP_MODAL" }
    | { type: "CLOSE_ARCHIVE_DUO_MODAL" }
    | { type: "OPEN_ARCHIVE_DUO_MODAL" }
    | { type: "OPEN_LIST_BAD_WORDS_MODAL" }
    | { type: "CLOSE_LIST_BAD_WORDS_MODAL" }
    | { type: "ARCHIVE_CHANNEL"; channel: Conversation }
    | { type: "PIN_CHANNEL"; channel: Conversation }
    | { type: "UN_ARCHIVE_CHANNEL"; channel: Conversation }
    | { type: "UN_PIN_CHANNEL"; channel: Conversation }
    | { type: "SET_NOTIFICATIONS"; num: number, conversation: Conversation }
    | { type: "INIT_NOTIFICATIONS"; num: number, conversation: Conversation }
    | { type: "ADD_NOTIFICATIONS"; channel: Conversation };

const initialState: AppState = {
    activeConversations: [],
    archiveConversations: [],
    botConversations: [],
    conversations: [],
    duoConversations: [],
    groupConversations: [],
    pinConversations: [],
    isOpen: false,
    conversationClient: undefined,
    channels: [],
    loading: true,
    activeChannels: [],
    duoChannels: [],
    groupChannels: [],
    botChannels: [],
    maxChatViews: 0,
    groupChannelModal: false,
    newChannel: {
        name: "",
        description: "",
        image: null,
        errorMessage: ""
    },
    directMessageModal: false,
    pinChannels: [],
    notificationsArray: [],
    notifications: 0,
    initClient: false,
    archiveGroupChannelModal: false,
    archiveDuoChannelModal: false,
    tasks: [],
    notificationsCount: Math.random(),
    botCount: Math.random(),
    allowToChat: {
        value: true,
        track: 'one'
    },
    listBadWordsModal: false,
    update: Math.random(),
    initialChannelsReady: false,
    duo: [],
    bot: [],
    group: [],
    showFilterForWords: false,
    allInitNotificationsArray: false
};

// CHANNEL
// KIND: IT CANNOT BE NOT UPDATED -> Bot, Duo, Group
// TYPE: IT CAN BE UPDATED

const reducer = (state: AppState, action: AppAction): AppState => {

    // console.log('REDUCER:');
    // console.log(action.type);
    // console.log({action});

    switch (action.type) {
        case "SET_CONVERSATION_CLIENT":
            return {...state, conversationClient: action.conversationClient};
        case "OPEN_LIST_BAD_WORDS_MODAL":
            return {...state, listBadWordsModal: true};
        case "CLOSE_LIST_BAD_WORDS_MODAL":
            return {...state, listBadWordsModal: false};
        case "ALLOW_TO_CHAT":
            return {...state, allowToChat: action.allowToChat};
        case "SET_UP":
            let length = action.conversations.length;
            let initConversations = action.conversations;
            let initBotConversations: Array<Conversation> = [];
            let initGroupConversations: Array<Conversation> = [];
            let initDuoConversations: Array<Conversation> = [];
            let initPinConversations: Array<Conversation> = [];
            let initArchivedConversations: Array<Conversation> = [];
            let item;
            let type;
            for (let i = 0; i < length; i++) {
                item = initConversations[i];
                type = item.attributes["type"];
                if (type === "Duo") {
                    initDuoConversations.push(item);
                }
                if (type === "Bot") {
                    initBotConversations.push(item);
                }
                if (type === "Group") {
                    initGroupConversations.push(item);
                }
                if (type === "Pin") {
                    initPinConversations.push(item);
                }
                if (type === "Archive") {
                    initArchivedConversations.push(item);
                }
            }
            let arrayBot = initBotConversations.map(item => ({
                channelSid: item.sid,
                notifications: action.tasks.length,
                ready: true
            }));
            let arrayDuo = initDuoConversations.map(item => ({channelSid: item.sid, notifications: 0}));
            let arrayThree = initGroupConversations.map(item => ({channelSid: item.sid, notifications: 0}));
            let arrayFour = initPinConversations.map(item => ({channelSid: item.sid, notifications: 0}));
            let arrayTotal = [...arrayBot, ...arrayDuo, ...arrayThree, ...arrayFour];
            debugLog(`Number of Bot Channels -> ${initBotConversations.length}`);
            debugLog(`Number of Group Channels -> ${initGroupConversations.length}`);
            debugLog(`Number of Duo Channels -> ${initDuoConversations.length}`);
            debugLog(`Number of Pin Channels -> ${initPinConversations.length}`);
            debugLog(`Number of Archived Channels -> ${initArchivedConversations.length}`);
            let allowToChatObject = {
                value: true,
                track: 'init7654',
            };
            if (initBotConversations.length > 0 && initBotConversations[0]) {
                let workBotAttributes = initBotConversations[0].attributes;
                if (workBotAttributes && workBotAttributes['allowToChat'] && workBotAttributes['allowToChat']['track']) {
                    allowToChatObject = workBotAttributes['allowToChat'];
                    if (allowToChatObject && !allowToChatObject.value) {
                        showToast({type: 'info', title: 'You are allowed to read, but not send messages.'}).then();
                    }
                }
            }
            setItemToStorage('allowToChat', JSON.stringify(allowToChatObject));
            let role = getRole();
            let rolesArrayForFilterWords = ["ROLE_OWNER", "ROLE_OPERATIONS_ACCOUNT_MANAGER", "ROLE_OPERATIONS_MANAGER"];
            return {
                ...state,
                conversationClient: action.conversationClient,
                conversations: action.conversations,
                duoConversations: initDuoConversations,
                groupConversations: initGroupConversations,
                botConversations: initBotConversations,
                duo: initDuoConversations.map(e => ({sid: e.sid, channel: e})),
                bot: initBotConversations.map(e => ({sid: e.sid, channel: e})),
                group: initGroupConversations.map(e => ({sid: e.sid, channel: e})),
                pinConversations: initPinConversations,
                archiveConversations: initArchivedConversations,
                loading: action.loading,
                notifications: 0,
                notificationsArray: arrayTotal,
                initClient: true,
                filter: action.filter,
                initialChannelsReady: true,
                showFilterForWords: !!rolesArrayForFilterWords.find(e => e === role),
                allowToChat: allowToChatObject,
                allInitNotificationsArray: arrayTotal.length === 0,
            };
        case "SET_FILTER":
            return {...state, filter: action.filter, update: Math.random()};
        case "OPEN_CHAT":
            return {...state, isOpen: action.isOpen};
        case "INIT_NOTIFICATIONS":
            // console.log(`${action.type} ${action.channel.sid} ${action.num} | ${action.channel.friendlyName}`);
            let initResult = setNotifications(state.notificationsArray, action.conversation, action.num);
            let count = 0;
            let len = initResult.tmpArr.length
            for (let i = 0; i < len; i++) {
                if (initResult.tmpArr[i].ready) {
                    count++;
                }
            }
            //@ts-ignore
            let allInit = count === len;
            // console.log({...initResult, count, len, allInit});
            if (allInit) {
                debugLog({allInit: initResult});
                return {
                    ...state,
                    notificationsArray: initResult.tmpArr,
                    notifications: initResult.reduceTotal,
                    notificationsCount: Math.random(),
                    allInitNotificationsArray: true,
                };
            }
            return {
                ...state,
                notificationsArray: initResult.tmpArr,
                allInitNotificationsArray: false,
            };
        case "SET_NOTIFICATIONS":
            // console.log(`${action.type} ${action.channel.sid} ${action.num} ${action.channel.friendlyName}`);
            let resultNotifications = setNotifications(state.notificationsArray, action.conversation, action.num);
            // console.log(resultNotifications);
            return {
                ...state,
                notificationsArray: resultNotifications.tmpArr,
                notifications: resultNotifications.reduceTotal,
                notificationsCount: Math.random(),
            };
        case "ADD_NOTIFICATIONS":
            let addResult = addNotifications(state.notificationsArray, action.channel);
            return {...state, notificationsArray: addResult.tmpArr, notifications: addResult.reduceTotal};
        case "MESSAGE_ADDED_ACTIVE_CONVERSATION":
            if (action.conversation && action.conversation.sid) {
                if (state.duoConversations.length > 0) {
                    if (action.conversation.sid === state.duoConversations[0].sid) {
                        return state;
                    }
                }
                if (state.groupConversations.length > 0) {
                    if (action.conversation && state.groupConversations[0] && action.conversation.sid === state.groupConversations[0].sid) {
                        return state;
                    }
                }
                if (action.conversation.attributes) {
                    if (action.conversation.attributes['type'] === 'Pin') {
                        return state;
                    }
                    if (action.conversation.attributes['kind'] === 'Duo') {
                        return {
                            ...state,
                            duoConversations: [action.conversation, ...state.duoConversations.filter(item => item.sid !== action.conversation.sid)],
                        }
                    }
                    if (action.conversation.attributes['kind'] === 'Group') {
                        return {
                            ...state,
                            groupConversations: [action.conversation, ...state.groupConversations.filter(item => item.sid !== action.conversation.sid)],
                        }
                    }
                }
            }
            return state;
        case "ADD_ACTIVE_CONVERSATION":
            if (state.activeConversations.find(item => item.sid === action.conversation.sid)) return state;

            if (state.activeConversations.length > 0 && state.activeConversations.length === state.maxChatViews) {
                state.activeConversations.pop();
            }

            let activeConversationsArray = [...state.activeConversations, action.conversation];

            if (action.conversation.attributes && action.conversation.attributes['kind'] === 'Bot') {
                return {...state, activeConversations: activeConversationsArray};
            }

            let addActiveResult = setNotifications(state.notificationsArray, action.conversation, 0);
            if (action.conversation.attributes) {
                if (action.conversation.attributes['type'] === 'Pin') {
                    return {
                        ...state,
                        activeConversations: activeConversationsArray,
                        notificationsArray: addActiveResult.tmpArr,
                        notifications: addActiveResult.reduceTotal,
                    };
                }

                if (action.conversation.attributes['kind'] === 'Duo') {
                    return {
                        ...state,
                        activeConversations: activeConversationsArray,
                        notificationsArray: addActiveResult.tmpArr,
                        notifications: addActiveResult.reduceTotal,
                        duoConversations: [action.conversation, ...state.duoConversations.filter(item => item.sid !== action.conversation.sid)],
                    };
                }

                if (action.conversation.attributes['kind'] === 'Group') {
                    return {
                        ...state,
                        activeConversations: activeConversationsArray,
                        notificationsArray: addActiveResult.tmpArr,
                        notifications: addActiveResult.reduceTotal,
                        groupConversations: [action.conversation, ...state.groupConversations.filter(item => item.sid !== action.conversation.sid)],
                    };
                }
            }

            return {
                ...state,
                activeConversations: activeConversationsArray,
                notificationsArray: addActiveResult.tmpArr,
                notifications: addActiveResult.reduceTotal
            };
        case "CLOSE_ACTIVE_CONVERSATION":
            if (action.conversation && action.conversation.attributes && action.conversation.attributes['kind'] === 'Bot') {
                return {
                    ...state,
                    activeConversations: state.activeConversations.filter(item => item.sid !== action.conversation.sid)
                };
            }
            let closeResult = setNotifications(state.notificationsArray, action.conversation, 0);
            return {
                ...state,
                notificationsArray: closeResult.tmpArr,
                notifications: closeResult.reduceTotal,
                activeConversations: state.activeConversations.filter(item => item.sid !== action.conversation.sid)
            };
        case "SET_MAX_CHAT_VIEWS":
            if (state.activeConversations.length > action.maxChatViews) {
                state.activeConversations.splice(0, 1);
            }
            return {...state, maxChatViews: action.maxChatViews};
        case "ADD_CONVERSATION":
            //@ts-ignore
            if (action.conversation.attributes["kind"] === "Duo") {
                return {
                    ...state,
                    conversations: [action.conversation, ...state.conversations],
                    duoConversations: [action.conversation, ...state.duoConversations],
                    notificationsArray: [
                        {
                            channelSid: action.conversation.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            //@ts-ignore
            if (action.conversation.attributes["kind"] === "Group") {
                return {
                    ...state,
                    conversations: [action.conversation, ...state.conversations],
                    groupConversations: [action.conversation, ...state.groupConversations],
                    notificationsArray: [
                        {
                            channelSid: action.conversation.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            //@ts-ignore
            if (action.conversation.attributes["kind"] === "Bot") {
                return {
                    ...state,
                    conversations: [action.conversation, ...state.conversations],
                    botConversations: [action.conversation, ...state.botConversations],
                    notificationsArray: [
                        {
                            channelSid: action.conversation.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            return state;
        case "CLOSE_NEW_CHANNEL_MODAL":
            return {...state, groupChannelModal: false};
        case "OPEN_NEW_CHANNEL_MODAL":
            return {...state, groupChannelModal: true};
        case "CLOSE_ARCHIVE_GROUP_MODAL":
            return {...state, archiveGroupChannelModal: false};
        case "OPEN_ARCHIVE_GROUP_MODAL":
            return {...state, archiveGroupChannelModal: true};
        case "CLOSE_ARCHIVE_DUO_MODAL":
            return {...state, archiveDuoChannelModal: false};
        case "OPEN_ARCHIVE_DUO_MODAL":
            return {...state, archiveDuoChannelModal: true};
        case "SET_NEW_CHANNEL_INFORMATION":
            return {...state, newChannel: action.newChannel};
        case "RESET_NEW_CHANNEL_INFORMATION":
            return {
                ...state,
                newChannel: {
                    name: "",
                    description: "",
                    image: null,
                    errorMessage: ""
                }
            };
        case "OPEN_DIRECT_MESSAGE_MODAL":
            return {...state, directMessageModal: true};
        case "CLOSE_DIRECT_MESSAGE_MODAL":
            return {...state, directMessageModal: false};
        default:
            return state;
    }
};

function setNotifications(
    notificationsArray: Array<NotificationArray>,
    conversation: Conversation,
    num: number,
): { tmpArr: Array<NotificationArray>; reduceTotal: number } {
    let tmpArr = notificationsArray.map(item => {
        if (conversation.sid === item.channelSid) {
            return {channelSid: item.channelSid, notifications: num, ready: true};
        }
        return item;
    });
    let reduceTotal = tmpArr.reduce((total, curr) => total + curr.notifications, 0);
    return {tmpArr, reduceTotal};
}

function addNotifications(
    notificationsArray,
    conversation
): { tmpArr: Array<{ channelSid: string; notifications: number }>; reduceTotal: number } {
    let tmpArr = notificationsArray.map(item => {
        if (conversation.sid === item.channelSid) {
            return {channelSid: item.channelSid, notifications: item.notifications + 1};
        }
        return item;
    });
    let reduceTotal = tmpArr.reduce((total, curr) => total + curr.notifications, 0);
    return {tmpArr, reduceTotal};
}

const useValue = () => useReducer(reducer, initialState);

export const {Provider, useTrackedState, useUpdate: useDispatch, useTracked} = createContainer(useValue);

export function useChatStoreReducer(): {
    state: AppState,
    dispatch: Dispatch<AppAction>,
} {
    const [state, dispatch] = useTracked();
    useDebugValue(state);
    return {state, dispatch};
}

export function useChatTrackedState(): AppState {
    return useTrackedState();
}

export function useChatDispatch(): Dispatch<AppAction> {
    return useDispatch();
}
