import React, {useEffect, useState, useContext} from "react";
import {
    Button,
    CustomDropdown,
    DropUploader,
    Icon,
    Input,
    Spacer,
    Text,
    Theme,
    ThirdTitle,
    Title,
    Password,
    CheckBox
} from "@dspworkplace/ui";
import StationSelector, {useSelectedStation} from "../../components/StationSelector";
import {Table, TableButton, TableData, TableFooter, TableHeader, TableRow} from "../../components/Table";
import {dialogPromise} from "../../components/Dialog";
import {Link, Status} from "../../components/UI";
import {getCompanyId, upload, useAuth} from "../../Auth";
import toast from "../../components/Toasts/toast";
import {fullDateFromUTC} from "../../components/TimeFormat";
import {default as Skeleton} from "../../components/Skeleton";
import Empty from '../../components/Empty';
import Tooltip from "../../components/Tooltip";
import Modal from "../../components/Modal";
import {useForm} from "react-hook-form";
import {
    DOCUMENT_ERROR_KEY,
    DOCUMENT_STATUS,
    DOCUMENT_STATUS_CONFIG,
    DOCUMENT_STATUS_MAP,
    ReportSourceDocumentSchemaInclude
} from "../Scorecard/schema";
import DatePicker from "../../components/Calendar/picker";
import ScorecardContext, {ScorecardProvider} from "../Scorecard/context";
import styled from 'styled-components';
import { validatePassword, mimeTypes, csvExtention, patternGetNameExtension } from "../../patterns";
import ES from "../../EventSource";

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

export default function (props)
{
    return <ScorecardProvider>
        <CompanyDocuments {...props}/>
    </ScorecardProvider>
}

const categoryOptions = [
    {
        name: "Import Employee CSV",
        value: "Employee"
    },
    {
        name: "Import Paycom Punch CSV",
        value: "paycomPunch"
    }    
];

let loadTimeout;

function CompanyDocuments()
{
    const {api} = useAuth();
    const [station,] = useSelectedStation();
    const [state, dispatch] = useContext(ScorecardContext);
    const [status, setStatus] = useState(DOCUMENT_STATUS.FETCHING);
    const {register, getValues, handleSubmit} = useForm();
    
    const fetchDocuments = () => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        const {category, search} = getValues();
        api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    ReportSourceDocument: {
                        findBy: {
                            get: 'documents',
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                isArchive: false,
                                category: category?.length ? category : categoryOptions.map(o => o.value),
                            },
                            matching: search ? {
                                "name": {
                                    contains: search
                                }
                            } : {},
                            orderBy: {
                                createdAt: 'DESC'
                            },
                            includes: ReportSourceDocumentSchemaInclude,
                        }
                    }
                }
            }
        }).then(
            response => {
                if(response?.data?.data?.documents)
                state.documents = response.data.data.documents;
            }
        ).then(
            response => setStatus(DOCUMENT_STATUS.READY)
        );
    };

    const createDocuments = uploads => {
        return api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    ReportSourceDocument: {
                        custom: {
                            functionName: "createReportSourceDocument",
                            get: "createReportSourceDocument",
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                documents: uploads.reduce((all, next) => {
                                    all[next.name] = {
                                        name: next.name,
                                        url: next.ObjectURL,
                                        category: next?.otherRequestedDate?.category || 'Employee',
                                        date: next?.otherRequestedDate?.date || "",
                                        password: next?.otherRequestedDate?.password || "",
                                        send_invitation: next?.otherRequestedDate?.send_invitation || "",
                                        source: 'manual',
                                    };
                                    return all;
                                }, {}),
                            },
                        }
                    },
                },
            }
        }).then(
            result => result && toast({
                type: 'success',
                title: `${uploads.length} ${uploads.length > 1 ? 'documents' : 'document'} uploaded`,
                timeout: 4000
            }) && result
        );
    }

    const reprocessDocument = document => {
        toast({
            type: 'info',
            title: 'Moving document to be reprocessed...',
            timeout: 4000
        });

        return api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    ReportSourceDocument: {
                        [document.name]: {
                            findBy: {
                                id: document.id,
                            },
                            updateRecord: {
                                status: 1 // moving it back to new triggers listener to queue again
                            }
                        }
                    }
                }
            }
        }).then(
            response => {
                toast({
                    type: 'success',
                    title: `Document ${document.name} is back in the queue`,
                    timeout: 4000
                });

                state.documents = (state.documents.map(d => {
                    if (d.id === document.id)
                        d.status = 2;

                    return d;
                }));
            }
        );
    }

    const handleUpload = data => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        toast({
            type: 'info',
            timeout: 8000,
            title: 'Uploading your documents...'
        });

        let formData = new FormData();
        formData.append('category', data.category);
        if(data.date){
            formData.append("date", data.date);
        }
        if(data.password){
            formData.append("password", data.password);
        }
        if(data.send_invitation){
            formData.append("send_invitation", data.send_invitation);
        }
        Array.from(data.documents)
            .forEach(file => formData.append('image[]', file));

        return upload(formData).catch(err => console.log(err));
    }

    const showUploadDialog = () => {
        return dialogPromise((success, cancel)=>
            <UploadDocumentDialog
                success={success}
                cancel={cancel}
            />
        ).then(
            result => result && handleUpload(result)
        ).then(
            result => {
                if (result === false) {
                    setStatus(DOCUMENT_STATUS.READY);
                    return;
                }

                if (!result) {
                    setStatus(DOCUMENT_STATUS.READY);
                    toast({
                        type: 'error',
                        title: 'Oops...',
                        content: 'Something happen while uploading your document. Please try again later.',
                        useIcon: true,
                        useClose: true,
                        timeout: 8000
                    });
                    return;
                }

                let uploads = result?.data;

                if (!Array.isArray(uploads))
                    uploads = [uploads];

                return createDocuments(uploads);
            }
        ).then(
            result => {
                if(result){
                    dispatch({
                        type: 'NEW_DOCUMENT',
                        payload: result.data.data.createReportSourceDocument
                    });
                }
            }
        ).then(
            response => setStatus(DOCUMENT_STATUS.READY)
        );
    }

    const loadDocuments = (data) => {
        clearTimeout(loadTimeout);
        loadTimeout = setTimeout(() => {
            fetchDocuments();
        }, 1000);
    }

    useEffect(() => {
        loadDocuments();
    }, [station]);

    useEffect(() => {
        const es = new ES("document", true);

        es.onmessage = data => {
            dispatch({
                type: "UPDATE_DOCUMENT_STATUS",
                payload: data.data,
            });
        }
    }, [station]);

    return <section>
        <Title>Company Documents</Title>
        <Spacer bottom={3}/>
        <Text>Store all your documents in one place. Upload new documents for up-to-date reports.</Text>
        <Spacer bottom={5}/>
        <form onSubmit={handleSubmit(loadDocuments)}>
            <StationSelector/>
            <Spacer inline right={5}/>
            <CustomDropdown
                ref={register}
                name='category'
                label='Category'
                options={categoryOptions}
                multiple={true}
                onChange={() => handleSubmit(loadDocuments)()}
            />
            <Spacer inline right={5}/>
            <Input
                ref={register}
                name='search'
                label='Name'
                onChange={() => handleSubmit(loadDocuments)()}
            >
                <Icon.Search/>
            </Input>
        </form>
        <Spacer bottom={3}/>
        <Table>
            <TableHeader
                headers={[
                    {label: 'Category', width: '10%'},
                    {label: 'Name', width: '33%'},
                    {label: 'Source', width: '8%'},
                    {label: 'Status', width: '14%'},
                    {label: 'Created', width: '18%'},
                    {label: '', width: '7%'},
                ]}
            />
            {status === DOCUMENT_STATUS.FETCHING && (
                <TableRow>
                    <TableData width='100%'>
                        <Skeleton width={400} height={20}/>
                    </TableData>
                </TableRow>
            )}
            {status === DOCUMENT_STATUS.READY && state.documents.length === 0 && (
                <TableRow>
                    <TableData width='100%' center>
                        <div style={{textAlign: 'center'}}>
                            <Empty/>
                            <ThirdTitle>Upload your first document</ThirdTitle>
                            <Spacer top={3} bottom={5}>
                                <Button type='primary' onClick={showUploadDialog} size='small'>Upload New Document</Button>
                            </Spacer>
                        </div>
                    </TableData>
                </TableRow>
            )}
            {state.documents.map((document, k) =>
                <TableRow key={k}>
                    <TableData width='10%'>
                        {document.category}
                    </TableData>
                    <TableData width='33%'>
                        {document.name}
                    </TableData>
                    <TableData width='8%'>
                        {document.source}
                    </TableData>
                    <TableData width='14%'>
                        <Tooltip content={DOCUMENT_ERROR_KEY[document.status]}>
                            <Status
                                current={document.status}
                                config={DOCUMENT_STATUS_CONFIG}
                            >
                                {DOCUMENT_STATUS_MAP[document.status]}
                            </Status>
                        </Tooltip>
                        {document.status >= 5 && document?.metadata?.errorLogFile
                            ? (
                                <Spacer left={2} inline>
                                    <Tooltip content={'Download report'}>
                                        <Link target='_blank' href={document.metadata.errorLogFile}>
                                            <Icon.Download color={Theme.colors.warnings.text} size="16px" />
                                        </Link>
                                    </Tooltip>
                                </Spacer>
                            ) : null}
                    </TableData>
                    <TableData width='18%'>
                        {fullDateFromUTC(document.createdAt.timestamp * 1000)}
                    </TableData>
                    <TableData width='7%' style={{justifyContent: 'flex-end'}}>
                        <TableButton as='a' target='_blank' href={document.url}>
                            <Icon.Download size='16px'/>
                        </TableButton>
                        <Spacer right={3} inline/>
                        <Tooltip content='Reprocess' horizontalPosition='left' verticalPosition='center'>
                            <TableButton onClick={() => reprocessDocument(document)}>
                                <Icon.Refresh size='20px'/>
                            </TableButton>
                        </Tooltip>
                    </TableData>
                </TableRow>
            )}
            <TableFooter sticky>
                {status === DOCUMENT_STATUS.FETCHING && (
                    <Skeleton width={200} height={24}/>
                )}
                {status !== DOCUMENT_STATUS.FETCHING && (
                    <ThirdTitle>
                        Total: {state.documents.length} document{state.documents.length > 1 && 's'}
                    </ThirdTitle>
                )}
                <Button
                    type='primary'
                    onClick={showUploadDialog}
                    size='small'
                >Upload New Document</Button>
            </TableFooter>
        </Table>
    </section>
}

const UploadDocumentDialog = ({
    success = ()=>{},
    cancel = ()=>{},
}) => {
    const {register, errors, watch, handleSubmit} = useForm({
        defaultValues: {
            category: 'paycomPunch',
        }
    });
    const [station] = useSelectedStation();
    const {category, date, password} = watch();
    const [isNotValid, setIsNotValid] = useState(false);

    const onSubmit = async (formData) => {
        let isUpload = false;
        for (let i = 0; i < formData.documents.length; i++) {
            let file = formData.documents[i];
            let mimeType = file.type;
            if (csvExtention.test(file.name)) {
                let extension = file.name.match(patternGetNameExtension)[1];
                isUpload = mimeTypes[extension] === mimeType || false;
            }
        }

        if (isUpload === true) {
            setIsNotValid(false);
            success(formData);
        } else {
            setIsNotValid(true);
        }
    };
    
    return (
        <Modal
            title={'Upload New Document'}
            width="400px"
            visible={true}
            setVisible={cancel}
        >
            <form as="form" name='document'>
                <CustomDropdown
                    ref={register}
                    name='category'
                    label='Document Type'
                    options={categoryOptions}
                />
                <Spacer bottom={3} />

                <DropUploader
                    name='documents'
                    label={'Document'}
                    multiple={false}
                    ref={register({
                        required: 'Please select file'
                    })}
                    accept={"text/csv"}
                    Required
                    error={errors.document && errors.document.message}
                    valid={!errors.document && document && document.length > 0}
                />
                <Spacer inline left={2}>
                    <Tooltip
                        verticalPosition={'center'}
                        horizontalPosition={'right'}
                        content={<Text>{"Only CSV document allowed."}</Text>}
                    >
                        <Question>?</Question>
                    </Tooltip>
                </Spacer>
                <Text style={{color:"red"}}>{isNotValid && 'Only CSV document allowed.'}</Text>    
                { (category === 'Employee') && (
                    <>
                        <Spacer top={2}>
                            <Text style={{ fontWeight: "normal" }}>
                                Example CSV
                                <a style={{ marginLeft: "10px" }} href={"https://s3.amazonaws.com/dsp.data.storage.general/Employee_Template_Import.xlsx"}>
                                    <Icon.Download size="22px" />
                                </a>
                            </Text>
                        </Spacer>
                        <Spacer top={3}>
                            <Password
                                name="password"
                                label="Default Password"
                                help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                                ref={register({
                                    required: false,
                                    validate: (value) => {
                                        if (value) {
                                            return validatePassword(value, '', []);
                                        }
                                    }
                                })}
                                error={errors?.password && errors?.password?.message}
                                valid={!errors.password && password && password.length > 0}
                            />
                        </Spacer>
                        <Spacer top={3}>
                            <CheckBox
                                name='send_invitation'
                                ref={register}
                                style={{float:'right'}}
                                options={[{label:'Send invitation to all new employees',value:true}]}
                            />
                        </Spacer>
                    </>
                )}  
                <Spacer bottom={5} />
                <Button type='primary' onClick={handleSubmit(onSubmit)}>Save</Button>
            </form>
        </Modal>
    );
 }