import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Button} from "@dspworkplace/ui";
import MessageContent from "./MessageContent";
import {MessageContentContainer} from "./styles";

const ReviewButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 0 0 8px;
    width: 100%;
`;

type Props = {
    text: string | null | undefined;
    identity: string;
}

function BotShowBadMessage(props: Props) {
    const {text, identity} = props;
    const [showBadMessage, setShowBadMessage] = useState(false);
    const [content, setContent] = useState(typeof text === 'string' ? text : 'N/A');
    const [showHandlerButton, setShowHandlerButton] = useState(true);

    const showBadMessageHandler = useCallback(
        () => {
            setShowBadMessage(!showBadMessage);
        },
        [showBadMessage],
    );

    useEffect(() => {
        if (typeof text === 'string') {
            setContent(`${text}`);
            setShowHandlerButton(true);
        } else {
            setShowHandlerButton(false);
        }
    }, []);

    if (!showHandlerButton) return null;

    return (
        <>
            {showBadMessage && <MessageContentContainer width={"300px"} className={"left"} margin={'8px 0 0 0'}>
                <MessageContent
                    isSentByCurrentUser={false}
                    text={content}
                    identity={identity}
                />
            </MessageContentContainer>}
            <ReviewButton onClick={showBadMessageHandler}>
                {showBadMessage ? 'Hide message' : 'Show message'}
            </ReviewButton>
        </>
    );
}

export default BotShowBadMessage;