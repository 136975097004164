import React, { useEffect, useState } from 'react'
import { Button, Icon, Spacer, TagInput, Text, ThirdTitle } from '@dspworkplace/ui';
import StationSelector, { useSelectedStation } from "../../../../components/StationSelector";
import { useForm } from "react-hook-form";
import { fetchScorecardDetails, updateScorecardDetails } from "./util";
import Loading, { LoadingWrapper } from '../../../../components/Loading';
import Overview from "../MobileScorecard/OverviewTemplate/OverviewTab";
import { CardBox, CardItem } from "../../../Vehicles/VanAutoAssignment";
import WithDragAndDrop, { DragItem } from "../../../../components/dragHandler";
import { Table, TableFooter, TableRow } from '../../../../components/Table';
import { Link } from "../../../../components/UI";
import Empty from "../../../../components/Empty";
import { dialogPromise } from "../../../../components/Dialog";
import Modal from "../../../../components/Modal";
import { Employee, fetchActiveEmployees } from "../MobileScorecard/OverviewTemplate/OverviewTemplate";
import OverviewScreen from "../MobileScorecard/OverviewTemplate/OverviewTab/Scores/OverviewScreen";

export interface Item {
    id: number
    text: string
}

const CardList = WithDragAndDrop(({ cards, setCards, data, setData }) => {
    const handleOnMove = (dragIndex, hoverIndex) => {
        setData(d => {
            if (hoverIndex < 0) {
                d.numberOfScoreCardMainFields = dragIndex === 0 ? 1 : dragIndex > d.numberOfScoreCardMainField ? 1 : 0;
                return d;
            }

            d.numberOfScoreCardMainFields +=
                dragIndex >= data.numberOfScoreCardMainFields && hoverIndex < data.numberOfScoreCardMainFields
                    ? 1
                    : dragIndex < data.numberOfScoreCardMainFields && hoverIndex >= data.numberOfScoreCardMainFields
                        ? -1
                        : 0;
            return d;
        });

        setCards(all => {
            const copyListState = [...all];
            const draggedItem = copyListState[dragIndex];

            copyListState.splice(dragIndex, 1);

            if (hoverIndex < 0) {
                hoverIndex = dragIndex >= (data.numberOfScoreCardMainField || 0) ? 0 : all.length;
            }

            copyListState.splice(hoverIndex, 0, draggedItem);

            return copyListState;
        })
    };

    // @ts-ignore
    const render = Array.from(cards).map(([i, card]) =>
        <DragItem key={card.id} index={i} id={card.id} type={'LIST'} onMove={handleOnMove}>
            {({ previewRef, dragRef, ui }) =>
                <div ref={previewRef}>
                    <CardItem ref={dragRef} style={{ opacity: ui.isOverCurrent ? 0 : 1 }}>
                        <Icon.Move size='16px' />
                        <Spacer left={3} inline>
                            <Text>{card.text}</Text>
                        </Spacer>
                    </CardItem>
                </div>
            }
        </DragItem>
    );

    return (
        <CardBox>
            <DragItem
                key={'new'}
                index={-1}
                id={'new'}
                type={'LIST'}
                onMove={handleOnMove}
                style={{
                    display: render.length ? 'none' : 'block'
                }}
            >
                {({ previewRef, dragRef }) =>
                    <div ref={previewRef}>
                        <CardItem ref={dragRef}>
                            Nothing here yet
                        </CardItem>
                    </div>
                }
            </DragItem>
            {render}
        </CardBox>
    )
})

const PreviewDialog = ({
    close
}) => {
    const [station,] = useSelectedStation();
    const [drivers, setDrivers] = useState<Employee[]>([]);
    const { register, watch } = useForm();

    const driver = watch('driver')?.[0];

    useEffect(() => {
        fetchActiveEmployees(station)
            .then((data) => {
                setDrivers(data);
            });
    }, [])

    // @ts-ignore
    return <Modal
        title={'View as a driver'}
        visible={true}
        setVisible={close}
        drawer={'right'}
    >
        <Text>Only saved changes will be available here</Text>
        <Spacer bottom={3} />
        <TagInput
            ref={register}
            name='driver'
            label={'Driver to Preview'}
            visibleOptionsQty={6}
            options={drivers.map(e => ({ name: e.friendlyName, value: e.driverId }))}
            singleOption
            size='big'
        />
        <Spacer bottom={3} />
        {station && driver ? (
            <OverviewScreen
                stationId={station}
                driverId={driver}
            />
        ) : (
            <div style={{ textAlign: 'center' }}>
                <Empty />
                <Text>Select a driver</Text>
            </div>
        )}
    </Modal>
}

const Container = () => {
    const [station,] = useSelectedStation();
    const [cards, setCards] = useState<Item[]>([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ numberOfScoreCardMainFields: 0, scoreCardSortedFields: [] });
    const { handleSubmit, setValue } = useForm();

    const onSubmit = async () => {
        await updateScorecardDetails(station, setLoading, cards, data, setValue);
        // setValue('numberOfScoreCardMainFields', data['numberOfScoreCardMainFields'])
    }

    useEffect(() => {
        const abortController = new AbortController();
        fetchScorecardDetails(station, setLoading, setCards, setValue).then(data => setData(data));
        return () => {
            abortController.abort();
        };
    }, [station]);

    //?
    let available = new Map();
    let inUse = new Map();
    for (const [index, element] of cards.entries())
        if (index < data.numberOfScoreCardMainFields)
            inUse.set(index, element)
        else
            available.set(index, element);
    //

    const showPreviewAsDriver = () => {
        dialogPromise((success) =>
            <PreviewDialog
                close={success}
            />
        )
    }

    if (loading)
        return <LoadingWrapper><Loading /></LoadingWrapper>

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Spacer bottom={5}>
                <StationSelector />
                <Spacer left={5} inline />
            </Spacer>
            <Table>
                <TableRow style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20, position: 'relative' }}>
                    {loading ? <LoadingWrapper><Loading /></LoadingWrapper> : null}
                    <div>
                        <ThirdTitle>Available Metrics</ThirdTitle>
                        <Spacer bottom={3} />
                        <CardList cards={available} setCards={setCards} data={data} setData={setData} />
                    </div>

                    <div>
                        <ThirdTitle>Metrics in Use</ThirdTitle>
                        <Spacer bottom={3} />
                        <CardList cards={inUse} setCards={setCards} data={data} setData={setData} />
                    </div>

                    <div>
                        <ThirdTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
                            Preview
                            <Link onClick={showPreviewAsDriver}>View as a driver</Link>
                        </ThirdTitle>
                        <Spacer bottom={3} />
                        <Overview
                            preview={inUse}
                        />
                    </div>
                </TableRow>
                <TableFooter sticky>
                    <div></div>
                    <Button
                        type={'primary'}
                        Type={'submit'}
                        disabled={loading}
                        style={{
                            verticalAlign: 'bottom'
                        }}
                        size='small'
                    >
                        Save
                    </Button>
                </TableFooter>
            </Table>
        </form>
    )

}


export default Container;