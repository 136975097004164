import React, {useCallback, useState} from 'react';
import ButtonShowOptions from "./ButtonShowOptions";
import {ContainerItemsType, deleteSection, updateSectionName, useStoreReducer} from "./store";
import {useForm} from "react-hook-form";
import {Icon, Button} from '@dspworkplace/ui';
import {Form} from "./Item";
import styled from "styled-components";
import {ButtonComponent} from "./shared";
import {confirm} from '../../../components/Confirm';
import {updateTemplate} from "./util";
import Loading from "../../../Chat/Loading";

type FormTitleProps = {
    section: ContainerItemsType;
    setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

function FormTitle(props: FormTitleProps) {
    const {section, setShowEdit} = props;
    const {appState, appDispatch} = useStoreReducer();
    const {register, handleSubmit} = useForm({
        defaultValues: {
            name: section.name,
        },
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        appDispatch({type: 'SET_LOADING', loading: true});
        setLoading(true);
        let res = await updateTemplate(appState.stationId, updateSectionName(appState, {
            name: data.name,
            sectionId: section.id
        }), appState.scenario, appState.id!, 'UPDATE_SECTION_NAME');
        appDispatch({
            type: 'UPDATE_SECTION_NAME',
            name: data.name,
            sectionId: section.id,
            updatedAt: res.updatedAt,
            updatedBy: res.updatedBy,
        });
        setLoading(false);
        setShowEdit(false);
    }

    return (
        <Form className={'sectionTitle'} onSubmit={handleSubmit(onSubmit)}>
            <input
                className={'sectionTitleInput'}
                name="name" ref={register({
                required: true
            })}/>
            {!loading && <Button type="primary" Type="submit">
                Save
            </Button>}
            {loading && <Loading imgHeight={'28px'} imgWidth={'32px'}/>}
        </Form>
    );
}

export const Row = styled.div`
    display: grid;
    flex-direction: row;
    grid-column-gap: 16px;
    grid-template-columns: max-content auto max-content auto;
    align-items: center;
    margin: 2px 0;

    &.title {
        grid-template-columns: auto max-content;
    }

    .titleText {
        color: #516F90;
        font-family: circe-rounded, serif;
        font-size: 20px;
    }
`;

type Props = {
    section: ContainerItemsType;
    index: number;
}

function SectionTitle(props: Props) {
    const {appState, appDispatch} = useStoreReducer();
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const removeSection = useCallback(
        async () => {
            let r = await confirm('Are you sure you want to delete this section?');
            if (!r) {
                return;
            }
            appDispatch({type: 'SET_LOADING', loading: true});
            setLoading(true);
            let data = deleteSection(appState, {id: props.section.id});
            let res = await updateTemplate(appState.stationId, data, appState.scenario, appState.id!, 'DELETE_SECTION');
            appDispatch({
                type: 'DELETE_SECTION',
                id: props.section.id,
                updatedAt: res.updatedAt,
                updatedBy: res.updatedBy,
            });
        },
        [],
    );

    const showToEdit = useCallback(
        () => {
            setShowEdit(true);
        },
        [],
    );
    const closeEdit = useCallback(
        () => {
            setShowEdit(false);
        },
        [],
    );

    return (
        <Row className={'sectionTitle'}>
            {showEdit && <FormTitle setShowEdit={setShowEdit} section={props.section}/>}
            {!showEdit && <Row className={'title'}>
                <div
                    style={{
                        fontFamily: 'circe-rounded, serif',
                        color: '#0071BC',
                        fontSize: '20px',
                    }}>
                    {props.section.name}
                </div>
                <ButtonComponent onClick={showToEdit}>
                    <Icon.Edit size={"20px"}/>
                </ButtonComponent>
            </Row>}
            {showEdit &&
            <ButtonComponent onClick={closeEdit}>
                <Icon.Times size={"20px"}/>
            </ButtonComponent>
            }
            <ButtonShowOptions index={props.index} section={props.section}/>
            {!loading && <ButtonComponent onClick={removeSection}>
                <Icon.Trash size={"20px"}/>
            </ButtonComponent>}
            {loading && <Loading imgHeight={'28px'} imgWidth={'32px'}/>}
        </Row>
    );
}

export default SectionTitle;