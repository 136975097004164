import React, {useEffect} from 'react';
import {useChatStoreReducer, useTrackedState} from "./store";
import ReactDOM from "react-dom";
import styled from 'styled-components';
import ChannelChatWindow from "./ChannelChatWindow";
import BotTasks from "./BotTasks";
import {Provider as BotProvider} from "./BotStore";

const channelSContainer = document.getElementById('channels-root');

const GridContainer = styled.div`
    display: grid;
    width: ${props => props.width ? props.width : 'auto'};
    grid-template-columns: ${props => props.columns ? props.columns : 'repeat( auto-fit, 340px)'};
    grid-template-rows: auto;
    grid-column-gap: 5px;
    position: fixed;
    bottom: 5px;
    right: 310px;
    justify-content: flex-end;
    z-index: 100;
`;


const Container: React.FC = () => {
    const {state, dispatch} = useChatStoreReducer();

    useEffect(() => {
        function handleResize() {
            let currentWidth = window.innerWidth;
            if (currentWidth) {
                currentWidth = currentWidth - 305;
                let max = Math.floor(currentWidth / 350);
                dispatch({type: 'SET_MAX_CHAT_VIEWS', maxChatViews: max});
            }
        }
        if (state.maxChatViews === 0) {
            handleResize();
        } else {
            window.addEventListener('resize', handleResize);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, state.activeChannels, state.maxChatViews]);

    if (state.activeChannels.length === 0) {
        return null;
    }

    return ReactDOM.createPortal(
        <GridContainer data-id='container' width={`${state.activeChannels.length * 345}px`}>
            {state.activeChannels.map((item) => {
                if (item.friendlyName.includes('workplace_bot')) {
                    return <BotProvider key={item.sid}>
                        <BotTasks channel={item}/>
                    </BotProvider>
                }
                return <ChannelChatWindow channel={item} key={item.sid}/>
            })}
        </GridContainer>, channelSContainer!);
}

const AppContainerForChannels: React.FC = () => {
    const state = useTrackedState();
    if (state.activeChannels.length === 0) {
        return null;
    }
    return <Container/>
}

export default AppContainerForChannels;