import React, {useEffect, useState} from "react";
import {Button, CustomDropdown, Spacer, Title} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import {getCompanyId, useAuth} from "../../../../Auth";
import DayPicker from "../../../../components/Calendar/day";
import DatePicker from '../../../../components/Calendar/picker';
import moment from "moment";
import {Content} from "../../../../components/Menus/Navigator";
import {FilterDayAnnouncement} from "../../../../hooks/useGlobalData";

const Insurance = () => {
    const {api} = useAuth();
    const {register, handleSubmit, errors, getValues} = useForm();
    const [station, setStation] = useState([]);
    const values = getValues();
    const [selectedDate, setSelectedDate] = FilterDayAnnouncement();

    const onSubmit = (data) => {
        if(selectedDate.start && selectedDate.end){
            let params = {
                "actions": {
                    "response": {
                        "Vehicle": {
                            "custom": {
                                "functionName": "getVanInsuranceReport",
                                "get": "file",
                                "criteria": {
                                    "stationCode": data.station,
                                    "startDate": selectedDate.start,
                                    "endDate": selectedDate.end,
                                    "companyId": getCompanyId(),
                                }
                            }
                        }
                    }
                }
            };
    
            api.post('/api/lazy/manage/data', params,{
                cache: {
                    ignoreCache: true
                }
            }).then(
                response => {
                    const element = document.createElement("a");
                    element.href = response.data.data.file;
                    document.body.appendChild(element);
                    element.click();
                },
                error => {}
            );
        }
    }

    useEffect( () => {

        getStation();

        async function getStation(){
            const params={
                "actions": {
                    "response": {
                        "Station": {
                            "findBy": {
                                "criteria": {
                                    "company":getCompanyId(),
                                },
                                "get": "Station",
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "code",
                                },
                                "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                            }
                        }
                    }
                }
            }
            await api.post('/api/lazy/manage/data', params,{
                cache: {
                    ignoreCache: true
                }
            }).then(
                response => {
                    if(response.data.data.Station){
                        setStation(response.data.data.Station.map((s)=>{ return {name:s.name,value:s.code}}));
                    }
                },
                error => {}
            );
        }
    }, []);

    return   (
        <Content className='scrollable content' >
            <Spacer bottom={10} id='company-information'>
                <Title>Insurance Report</Title>
                <Spacer bottom={5}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomDropdown
                        name='station'
                        label='Stations'
                        ref={register}
                        placeholder='All'
                        multiple={true}
                        options={station}
                    />
                    <Spacer bottom={5}/>
                    <DatePicker
                        ref={register({required:'Field Required'})}
                        name='dates'
                        key={"date"}
                        label='Date Range (Select Multiple Days)'
                        range='date'
                        error={errors.dates && 'please select a date range'}
                        valid={
                            !errors.dates &&
                            values.dates &&
                            values.dates.length > 0
                        }
                        onChange={date => {
                            if (date) {
                                const dateRangeArr = date.split(' – ');
                                const startDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[0]) : '';
                                const endDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[1]) : '';
                               if (startDate.isValid() && endDate.isValid()) {
                                    setSelectedDate({
                                        start: startDate.format('YYYY-MM-DD'),
                                        end: endDate.format('YYYY-MM-DD')
                                    });
                                }
                            }
                        }}
                        defaultValues={[new Date(selectedDate.start), new Date(selectedDate.end)]}
                    />
                    <Spacer bottom={5}/>
                    <Button type='primary'>Export</Button>
                </form>
            </Spacer>
        </Content>
    );
}
export default Insurance;
