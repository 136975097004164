import styled from "styled-components";
import { Button, SubTitle } from "@dspworkplace/ui";
import SimpleScroll from "../components/SimpleScroll";

export const MessageContainer = styled.div`
    width: 300px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: ${props => (props.rows ? props.rows : "16px auto")};
    grid-row-gap: 8px;
    margin: 4px 20px 4px 20px;
    word-break: break-word;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};
    
    &.right {
      justify-content: flex-end;
    }
    
    &.left {
      justify-content: flex-start;
    }
`;

export const MessageContentContainer = styled.div`
    display: flex;
    font-size: 14px;
    font-family: circe-rounded, serif;
    color: ${props => (props.color ? props.color : "white")};
    padding: 4px 12px 12px 12px;
    white-space: pre-wrap;
    width: fit-content;
    margin: ${props => (props.margin ? props.margin : "0")};
    max-width: ${props => (props.width ? props.width : "260px")};

    &.left {
        background-color: #516f90;
        justify-self: left;
        border-top-style: solid;
        border-width: 8px;
        border-color: white;
        border-top-color: #516f90;
        border-left-style: solid;
        display: block;
        a {
            color: ${props => (props.color ? props.color : "white")};
            text-decoration: underline;
        }
    }

    &.right {
        background-color: #f5f5f5;
        color: #333333;
        justify-self: right;
        border-top-style: solid;
        border-width: 8px;
        border-color: white;
        border-top-color: #f5f5f5;
        border-right-style: solid;
        display: block;
        a {
            color: ${props => (props.color ? props.color : "#333333")};
            text-decoration: underline;
        }
    }
`;

export const MessageBody = styled.div`
    display: flex;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};
    align-items: center;
    flex-wrap: wrap;
    
    &.right {
      justify-content: flex-end;
    }
    
    &.left {
      justify-content: flex-start;
    }
`;

export const Text = styled.div`
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    color: ${props => (props.color ? props.color : "#333333")};
    opacity: 1;
    font-family: circe-rounded, serif;
    width: ${props => (props.width ? props.width : "fit-content")};
    height: ${props => (props.height ? props.height : "inherit")};
    text-align: ${props => (props.textAlign ? props.textAlign : "left")};
    line-height: ${props => (props.lineHeight ? props.lineHeight : "14px")};
    margin: ${props => (props.margin ? props.margin : "0")};
    padding: ${props => (props.padding ? props.padding : "0")};
    display: grid;
    align-items: center;
`;

export const TextInformationContainer = styled.div`
    display: grid;
    height: 32px;
    grid-template-columns: auto;
    grid-template-rows: 18px 14px;
    cursor: pointer;
`;

export const Grid = styled.div`
    box-sizing: border-box;
    display: ${props => (props.display ? props.display : "grid")};
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "0")};
    grid-template-rows: ${props => (props.templateRows ? props.templateRows : "auto")};
    grid-row-gap: ${props => (props.rowGap ? props.rowGap : "0")};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "normal")};
    align-items: ${props => (props.alignItems ? props.alignItems : "center")};
    padding: ${props => (props.padding ? props.padding : "0")};
    margin: ${props => (props.margin ? props.margin : "0")};
    border: ${props => (props.border ? props.border : "0")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
    color: ${props => (props.color ? props.color : "#333333")};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "unset")};
    width: ${props => (props.width ? props.width : "100%")};
    height: ${props => (props.height ? props.height : "auto")};
    box-shadow: ${props => (props.boxShadow ? props.boxShadow : "unset")};
    justify-self: ${props => (props.justifySelf ? props.justifySelf : "normal")};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : "unset")};
    overflow-y: ${props => (props.overflowY ? props.overflowY : "unset")};
    overflow-x: ${props => (props.overflowX ? props.overflowX : "unset")};
`;

export const ReturnButton = styled(Button)`
    width: ${props => (props.width ? props.width : "fit-content")};
    line-height: ${props => (props.lineHeight ? props.lineHeight : "unset")};
    height: ${props => (props.height ? props.height : "auto")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : "2px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${props => (props.padding ? props.padding : "8px 12px")};
    margin: ${props => (props.margin ? props.margin : "0")};
    justify-self: ${props => (props.justifySelf ? props.justifySelf : "unset")};

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071bc;
        border-radius: ${props => (props.borderRadius ? props.borderRadius : "2px")};
    }
`;

export const PrimaryButton = styled(Button)`
    width: ${props => (props.width ? props.width : "fit-content")};
    line-height: ${props => (props.lineHeight ? props.lineHeight : "unset")};
    height: ${props => (props.height ? props.height : "auto")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${props => (props.padding ? props.padding : "8px 12px")};
    justify-self: ${props => (props.justifySelf ? props.justifySelf : "unset")};

    &.modal {
        width: 120px;
        height: 40px;
    }

    &.cancel {
        background-color: #d94c53;
    }

    /* &:active, &:focus, &:after {
                outline: 0;
                border: 0;
                text-shadow:none;
                box-shadow:none;
            }          */
`;

export const Background = styled.div`
    display: ${props => (props.display ? props.display : "flex")};
    position: fixed;
    z-index: 3000;
    left: 0;
    top: 0;
    width: 100vw;
    height: ${props => (props.height ? props.height : '100%')};
    min-height: ${props => (props.height == 'auto' ? '100vh' : '')};
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    font-family: circe-rounded, serif;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ModalContent = styled.div`
    box-sizing: border-box;
    background-color: #fefefe;
    border: 1px solid #888;
    font-size: 14px;
    width: ${props => (props.width ? props.width : "500px")};
    min-height: ${props => (props.minHeight ? props.minHeight : "400px")};
    display: ${props => (props.display ? props.display : "grid")};
    grid-template-columns: ${props => (props.width ? "calc(" + props.width + " - 40px)" : "460px")};
    grid-template-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 8px;
    padding: 16px;
    z-index: 2900;
    justify-content: flex-start;
    overflow-x: ${props => (props.overflowX ? props.overflowX : "auto")};
    overflow-y: ${props => (props.overflowY ? props.overflowY : "unset")};
    margin: auto;
`;

export const ModalTitle = styled(SubTitle)`
    width: auto;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 18px;
`;

export const ChannelsSettingsContent = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 40px auto;
    grid-row-gap: 20px;
`;

export const TextArea = styled.textarea`
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
    opacity: 1;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 12px;
    width: ${props => (props.width ? props.width : "auto")};
    height: ${props => (props.height ? props.height : "auto")};

    &:hover,
    &:focus,
    &:active {
        border: 1px solid rgb(0, 113, 188);
        box-shadow: rgb(157, 221, 244) 0 0 6px;
    }
`;

export const Label = styled.div`
    font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
    line-height: ${props => (props.height ? props.height : "16px")};
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    font-family: Circe-Rounded, serif;
    display: flex;
    color: #707070;
    align-self: ${props => (props.alignSelf ? props.alignSelf : "auto")};
`;

export const Input = styled.input`
    background: #ffffff;
    border-color: #cccccc;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    padding: 12px 8px;
    color: #333333;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
        border: 1px solid rgb(0, 113, 188);
        box-shadow: rgb(157, 221, 244) 0 0 6px;
    }
`;

export const FlexScroll = styled(SimpleScroll)`
    @media only screen and (max-height: 901px) {
        height: 100px !important;
        margin: 0 0 16px 0 !important;
    }
`;

export const CustomButton = styled(Button)`
    width: ${props => (props.width ? props.width : "40px")};
    height: ${props => (props.height ? props.height : "40px")};
    font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    justify-self: ${props => (props.justifySelf ? props.justifySelf : "end")};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : "2px")};

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }
`;

export const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }
    div {
        width: ${props => (props.width ? props.width : "612px")};
    }
    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }
    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }
    ul.big {
        max-width: unset;
        width: fit-content;
    }
    button.refresh {
       margin-top: 24px;
       margin-left: 8px;
    }
`;


export const ChannelGroupFilters = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`;

export const ChannelGroupFilter = styled.div`
    display: inline-flex;
    background-color: #EAF0F6;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    font-size: 12px;
    font-weight: 600;
    color: #516F90;
    border: 1px solid #7C98B6;
    margin: 2px;
    border-radius: 2px;
`;
