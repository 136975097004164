import { getCompanyId } from "../../Utilities";

export const fetchDrivers = async (api, station) => {
    const params = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        get: "drivers",
                        functionName: "getDrivers",
                        criteria: {
                            station: station,
                            company: getCompanyId(),
                        },
                        includes: {
                            0: "id",
                        },
                    },
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data;
    } catch (error) {}
};
