import React, {useEffect} from "react";
import {getCompanyName, getEmail, getFriendlyName, getToken} from "../Auth";

const CrisptChat = () => {
    const userToken = getToken();

    useEffect(()=>{
        const CRISP_ID = process.env.REACT_APP_CRISP_ID
        if(CRISP_ID){
            window.$crisp=[];
            window.CRISP_WEBSITE_ID=CRISP_ID;
            window.$crisp.push(["set", "user:nickname", [getFriendlyName()]]);
            window.$crisp.push(["set", "user:email", getEmail()]);
            window.$crisp.push(["set", "user:company", [getCompanyName()]]);
            window.$crisp.push(["safe", true])

            if(!userToken){
                window.$crisp.push(["do", "session:reset"])
            }

            (function(){
                var d=document;
                var s=d.createElement("script");
                s.src="https://client.crisp.chat/l.js";
                s.async=1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
        }
    },[userToken])

    return null;
}

export default CrisptChat