import React, { useEffect } from "react";
import { Content, Menu, Navigator } from "../../components/Menus/Navigator";
import { Spacer, SubTitle, Theme } from "@dspworkplace/ui";
import { NavLink, Route, Switch } from "react-router-dom";
import {renderGrantedRoute, ROUTES} from "../../Route_temp";
import { getCompanyId } from "../../Auth";
import { MenuItemBadge } from "../../components/Menus/Main";
import styled from 'styled-components'
import { tokenState } from "../../domain/security/state/Token";
import { useRecoilValue } from "recoil";

const VehicleContent = styled(Content)`
    width: calc(100vw - 225px);
`

const App = (routeProps) => {
    const { history, match, location } = routeProps;
    const { url, isExact } = match;
    const { pathname } = location;

    const routes = ROUTES.find((r) => r.name === "Company Management")
        .routes.find((r) => r.name === "Vehicles")
        .routes.filter((r) => (!r.isCompany ? true : r.isCompany.indexOf(parseInt(getCompanyId())) > -1));

    if (isExact) {
        history.replace(url + routes[0].path);
    }

    return (
        <div style={{ display: "flex", position: "relative" }}>
            <Navigator style={{ width: 225 }} className="scrollable">
                <SubTitle>Vehicles {}</SubTitle>
                <Spacer top={3} style={{ borderBottom: "1px solid " + Theme.colors.info.bg }} />
                <Spacer top={4} />
                <Menu>
                    {routes.map((r) => (
                        <React.Fragment key={r.path}>
                            {r.renderMenu !== false && !r.path.includes("/maintenance/") && (
                                <li>
                                    <NavLink
                                        to={`${url}${r.path}`}
                                        className={
                                            pathname &&
                                            r.path === "/vehicle-management" &&
                                            pathname.includes("/company-management/vehicles/vehicle-images/")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        {r.name}
                                        {r.isCompany?.indexOf(parseInt(getCompanyId())) > -1 ? (
                                            <MenuItemBadge>Beta</MenuItemBadge>
                                        ) : null}
                                    </NavLink>
                                </li>
                            )}
                        </React.Fragment>
                    ))}
                </Menu>
                <Spacer top={4} />
                <SubTitle>Maintenance</SubTitle>
                <Spacer top={3} style={{ borderBottom: "1px solid " + Theme.colors.info.bg }} />
                <Spacer top={4} />
                <Menu>
                    {routes.map((r, k) => {
                        return <React.Fragment key={`${url}${r.path}`}>
                            {r.renderMenu !== false && r.path.includes("/maintenance/") && (
                                <li>
                                    <NavLink to={`${url}${r.path}`}>{r.name}</NavLink>
                                </li>
                            )}
                        </React.Fragment>
                    })}
                </Menu>
                <Spacer top={16} /> 
            </Navigator>
            <VehicleContent className="scrollable" style={{ position: "relative" }}>
                <Switch>
                    {routes.map(({ path, feature, component, avoidPermission }, k) => (
                        <Route
                            path={`${url}${path}`}
                            key={k}
                            render={(props) => {
                                if (avoidPermission && avoidPermission.includes(parseInt(getCompanyId()))) {
                                    return renderGrantedRoute({
                                        feature,
                                        component,
                                        path,
                                        avoidPermission:true
                                    })
                                } else {
                                    return renderGrantedRoute({
                                        feature,
                                        component,
                                        path
                                    })
                                }
                            }}
                        />
                    ))}
                </Switch>
            </VehicleContent>
        </div>
    );
};

export default App;
