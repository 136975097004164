import { useEffect, useCallback } from "react";
import PropTypes from 'prop-types';


const useOutsideClick = (ref,callback,event) => {
    const handleClick = useCallback( e =>{
        if (ref.current && ref.current.contains && !ref.current.contains(e.target)) {
            callback(e);
        }
    },[ref,callback])

    useEffect(() => {
        document.addEventListener(event ? event : "click", handleClick);

        return () => {
            document.removeEventListener(event ? event : "click", handleClick);
        };
    },[event,handleClick]);
};

useOutsideClick.prototype = {
    callback: PropTypes.func,
    event: PropTypes.string
}

export default useOutsideClick;