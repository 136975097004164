import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {Button, Icon, Input, Spacer, Text, Theme, Title} from '@dspworkplace/ui';
import Loading, {useLoading} from "../../../../components/Loading";
import {useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useAuth} from "../../../../Auth";
import {Table, TableData, TableFooter, TableHeader} from "../../../../components/Table";
import {confirm} from '../../../../components/Confirm';

const reducer = (state, action) => {
    switch (action.type) {
        case 'create':
            let balanceGrops = action.balanceGroup.sort((a,b)=> a.name.localeCompare(b.name,undefined,{numeric:true,sensitivity: 'base'}));

            return { ...state, balanceGroup: balanceGrops, isLoading: action.isLoading };
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'add':
            return { ...state, balanceGroup: [...state.balanceGroup, action.balanceGroup], isLoading: action.isLoading};
        case 'setUpdateKey':
            return {...state, updateKey: action.updateKey}
        case 'update':
            state.balanceGroup[action.key] = action.balanceGroup;
            return { ...state, updateKey: -1 };
        case 'cancel':
            return { ...state, updateKey: -1 };
        case 'filter':
            if(state.filterOrder === 'ASC'){
                if(action.filterType === 'num'){
                    state.balanceGroup.sort((a,b) => (parseFloat(a[action.filterName]) < parseFloat(b[action.filterName])) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1 );
                }else{
                    state.balanceGroup.sort((a,b) => (a[action.filterName] < b[action.filterName]) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1 );

                }
                return { ...state, balanceGroup: state.balanceGroup, filterOrder: 'DESC', filterName: action.filterName };
            }else{
                if(action.filterType === 'num'){
                    state.balanceGroup.sort((a,b) => (parseFloat(a[action.filterName]) > parseFloat(b[action.filterName])) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1 );
                }else{
                    state.balanceGroup.sort((a,b) => (a[action.filterName] > b[action.filterName]) ? 1 : (a[action.filterName] === b[action.filterName]) ? ((a.id > b.id) ? 1 : -1) : -1 );
                }
            }
            return { ...state, balanceGroup: state.balanceGroup, filterOrder: 'ASC', filterName: action.filterName };
        case 'delete':
            state.balanceGroup.splice(action.key, 1);
            return {...state}
        default:
            return state;
    }
};

const initialState = {
    balanceGroup: [],
    updateKey: -1,
    filterName: 'name',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
    isEdit:false,
};

const App = (localState = '') => {
    const { api } = useAuth();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setLoading] = useLoading();
    const [data,setData] = useState({update:-1,balanceGroup:[]});
    const [filter, setFilter] = useState({order:{name:'ASC'}});
    const useLocationState = useLocation().state;
    // const station = useLocation().state.station;

    const handleDelete = async (id,key) =>
    {
        const confirmation = await confirm('Confirm that you want to delete this item');
        if(!confirmation){
            return false;
        }

        try{
            let params = {
                "actions": {
                    "update": {
                        "BalanceGroup": {
                            "update_balance_group_1": {
                                "findBy": {
                                    "id": id
                                },
                                "updateRecord": {
                                    'isArchive':true,
                                }
                            }
                        },
                    },
                    "response": {
                        "BalanceGroup": {
                            "custom": {
                                "functionName": "updatedBalanceGroup",
                                "get":"updatedBalanceGroup",
                                "criteria": {
                                    "id" : id
                                },
                            }
                        }
                    }
                },
            };
            await api.post('/api/lazy/manage/data', params);

            dispatch({type:'delete', key: key});

        }catch(error){
            if (error.response.status == '401') {
                window.location = "/logout";
            }
            dispatch({type:'default'});
        }

    }
    const filterBy = (sortFieldName, sortFieldType) =>{ dispatch({type:'filter', filterName: sortFieldName, filterType: sortFieldType}); }

    useEffect (() => {
        const getBalanceGroup = async () => {
            try{
                const params={
                    "actions": {
                        "response": {
                            BalanceGroup: {
                                findBy: {
                                    get: "balanceGroup",
                                    criteria: {
                                        station: (useLocationState && useLocationState.station)?useLocationState.station.id:localState.state.station.id,
                                    },
                                    includes: {
                                        0: "id",
                                        1: "name"
                                    },
                                    excludes: ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers", "station"]
                                }
                            },
                        }
                    }
                };
                let response = await api.post('/api/lazy/manage/data', params);

                dispatch({type:'create', balanceGroup: response.data.data.balanceGroup, isLoading: false});
            }catch(error){
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                dispatch({type:'default'});
            }
        }
        getBalanceGroup();

    },[filter]);

    return (
        <>
            <Spacer top={5} />
            <Title>Balance Group</Title>
            <Spacer top={1} />
            <Text>Set your Balance Group that are associated for this station.</Text>
            <Spacer top={5} />
            <Table width={'408px'}>
                <TableHeader
                    headers={[
                        {width: '100%', label: 'Name'}
                    ]}
                />
                {state.isLoading && <Loading style={{height:'80px',marginTop:'20px'}} />}

                {!state.isLoading && state.balanceGroup &&
                state.balanceGroup.sort((a, b) => a.name.localeCompare(b.name)).map((item, key) => {

                    if(key == state.updateKey){
                        return (
                            <AddForm key={key} index={key} dispatch={dispatch} data={state} station={(useLocationState && useLocationState.station)?useLocationState.station:localState.state.station}/>
                        );
                    }

                    return(
                        <li key={key}>
                            <TableData width='324px'>{item.name}</TableData>
                            <TableData width='84px' style={{verticalAlign: 'middle', height: '40px'}}>
                                <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => { dispatch({type:'setUpdateKey' , updateKey: key}) }}/>
                                <Spacer inline right={3}/>
                                <Icon.Times size='20px' color={Theme.colors.info.border} onClick={() => {handleDelete(item.id, key)}}/>
                            </TableData>
                        </li>
                    )

                })
                }
                {!state.isLoading && state.balanceGroup && state.isEdit === false && <AddForm dispatch={dispatch} data={state} station={(useLocationState && useLocationState.station)?useLocationState.station:localState.state.station} />}
            </Table>
        </>
    );
}

const AddForm = ({dispatch,data,index,station}) => {

    const { api, logout } = useAuth();
    let defaultValues;
    let selected;
    const [isCancelButton, setIsCancelButton] = useState(false);
    const [formData,setFormData] = useState({name:''});

    if(index > -1){
        selected = data.balanceGroup[index];
        defaultValues =
            {
                name: selected.name,
            }
        data.isEdit = true;
        dispatch({type:'default'});
    } else {
        data.isEdit = false;
        dispatch({type:'default'});
    }

    const { register, handleSubmit, errors, reset, getValues } = useForm({defaultValues:defaultValues});
    let temp=[];
    const { name } = getValues();


    const handleEdit = async (form, selected, index) =>
    {
        let editOldData = data.balanceGroup.filter((item) => parseInt(item.id) === parseInt(selected.id))

        try {
            let param = {
                "actions": {
                    "update": {
                        "BalanceGroup": {
                            "update_balance_group_1": {
                                "findBy": {
                                    "id": selected.id
                                },
                                "updateRecord": {
                                    'name':form.name,
                                }
                            }
                        },
                    },
                }
            }
            await api.post('/api/lazy/manage/data', param);
            const updatedBalanceGroup = {id:selected.id, name:form.name };
            dispatch({type:'update', balanceGroup: updatedBalanceGroup, key: index});
            setIsCancelButton(false);
        } catch (error) {
            dispatch({type:'default'});
            alert("Error to update balance group. Login again, please").then(() => {

            });

        }
    }

    const cancelBtn = async ()=>{
        if(!selected){
            reset({name:''});
            setIsCancelButton(false);
        } else {
            dispatch({type:'cancel'});
        }
        return false;
    }

    const handleOnChange = useCallback(event => {
        if(event.target){
            if( event.target.name === 'name' ){ formData.name = event.target.value;}
            if( formData.name.length > 0 ){
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }
        } else {
            formData.name = event.value;
            if( formData.name.length > 0 ){
                setIsCancelButton(true);
            }else {
                setIsCancelButton(false);
            }

        }

    });
    const onSubmit = async form  =>
    {
        if(selected){
            await handleEdit(form,selected,index);
        } else {
            let params = {
                "actions": {
                    "create": {
                        "BalanceGroup": {
                            "balanceGroup_1": {
                                'station':station.id,
                                'name':form.name,
                                'company':localStorage.getItem('company'),
                            }
                        }
                    }
                }
            };

            try {
                let response =  await api.post('/api/lazy/manage/data', params);

                if(response.status === 200){
                    const newBalanceGroup = {id:response.data.data.responseId, name:form.name };

                    dispatch({type:'add', balanceGroup: newBalanceGroup, isLoading: false});
                    setIsCancelButton(false);
                }else{
                    alert('Error to create new balanceGroup');
                }
            } catch(error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
        reset({name:''});
    }

    const Form = (
        <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <Input
                name='name'
                ref={register({ required:'This field can not be empty' })}
                error={errors.name && errors.name.message}
                valid={!errors.name && name && name.length > 0}
                size={'small'}
                type='text'
                onChange={handleOnChange}
            />
            <div>
                <Button type='primary'>{index >= 0 ? 'Save' : 'Add' }</Button>
                { isCancelButton || selected ? (
                    <Button type={'cancel'} onClick={()=>{cancelBtn()}} >
                        <Icon.Times size={'20px'} style={{verticalAlign: 'middle'}}/>
                    </Button>
                ) : '' }
            </div>
        </form>
    );

    return index ? (
        <li>
            <TableData width={'100%'}>
                {Form}
            </TableData>
        </li>
    ): (
        <TableFooter>
            {Form}
        </TableFooter>
    )
};
export default App;
