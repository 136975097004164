import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {UserMessageType} from "./types";
import {setUpDateFormatted, setUpName} from "./utils";

const MessageBoxTop = styled.div`
    display: grid;
    width: ${props => (props.width ? props.width : "auto")};
    grid-template-columns: ${props => (props.columns ? props.columns : "auto auto")};
    grid-template-rows: 16px;
    grid-column-gap: 8px;
    height: 16px;
    background-color: white;
    color: #516f90;
    font-size: 12px;
    font-family: circe-rounded, serif;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "space-between")};
`;

const MessageBoxTopContent = styled.div`
    justify-self: ${props => (props.direction ? props.direction : "start")};
    margin: ${props => (props.margin ? props.margin : "0")};
`;

type Props = {
    showDate: boolean;
    date: string | Date;
    refMessageBox: React.MutableRefObject<HTMLDivElement | undefined>;
    isSentByCurrentUser?: boolean;
    isSystemMessage?: boolean;
    user: UserMessageType | { identity: string, name: string };
};

function MessageHeader({date, user, refMessageBox, isSentByCurrentUser, showDate, isSystemMessage}: Props) {
    const [width, setWidth] = useState("auto");
    const [name,] = useState(setUpName(user));
    const ref = useRef<HTMLDivElement>();
    const [dateFormatted,] = useState(setUpDateFormatted(date));

    useEffect(() => {
        if (!refMessageBox || !refMessageBox.current) return;
        if (!ref || !ref.current) return;
        if (ref.current.offsetWidth < refMessageBox.current.offsetWidth) {
            setWidth(`${refMessageBox.current.offsetWidth}px`);
        } else if (ref.current.offsetWidth > 260) {
            if (isSystemMessage) {
                setWidth(`${ref.current.offsetWidth}px`);
            } else {
                setWidth(`${ref.current.offsetWidth - 20}px`);
            }
        }
    }, [refMessageBox, ref, isSystemMessage]);

    if (!showDate) return null;

    if (isSentByCurrentUser) {
        return (
            <MessageBoxTop width={width} columns={"auto"} justifyContent={"flex-end"} ref={ref}>
                <MessageBoxTopContent>{dateFormatted}</MessageBoxTopContent>
            </MessageBoxTop>
        );
    }

    return (
        <MessageBoxTop width={width} ref={ref}>
            <MessageBoxTopContent>{name}</MessageBoxTopContent>
            <MessageBoxTopContent>{dateFormatted}</MessageBoxTopContent>
        </MessageBoxTop>
    );
}

export default MessageHeader;
