import {ButtonComponent} from "./shared";
import {useTracked} from "./store";
import React, {useCallback} from "react";
import styled from "styled-components";
import {Icon} from '@dspworkplace/ui';

const ButtonComponentContainer = styled(ButtonComponent)`
    display: flex;
    width: 100px;
    height: 32px;
    margin: 4px;
    position: absolute;
    padding-right: 8px;

    .row {
        display: flex;
        flex-direction: row;
        height: 32px;
        align-items: center;
    }

    .icon {
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
    }

    .section {
        display: flex;
        align-items: center;
    }
`;

function AddSection() {
    const [state, dispatch] = useTracked();

    const addSection = useCallback(
        () => {
            dispatch({type: 'ADD_SECTION'});
            let elem = document.getElementById('template');
            if (elem) {
                elem.scrollTo({
                    top: elem.offsetHeight * state.template.length,
                    behavior: 'smooth'
                });
                // //@ts-ignore
                // elem.scrollTop = elem.offsetHeight + 900;
            }
        },
        [],
    );

    return (
        <ButtonComponentContainer
            onClick={addSection}>
            <div className={'row'}>
                <div className={'icon'}>
                    <Icon.Plus size={"24px"}/>
                </div>
                <div className={'section'}>
                    Section
                </div>
            </div>
        </ButtonComponentContainer>
    );
}

export default AddSection;