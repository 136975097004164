import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Icon, Theme} from "@dspworkplace/ui";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import Moment from "moment";

const WeekDayStr =["SUN","MON","TUE","WED","THU","FRI","SAT"];

const WeekContainer = styled.div`
    display: inline-flex;

    >.DayPickerInput {
        >input{
            width:140px;
            background:#fff;
            border:1px solid ${Theme.colors.neutrals.silver};
            height:40px;
            padding:0 8px;
            font-size:14px;
            color:#333333;
        }

        .DayPickerInput-Overlay {
            left:auto;
            right:0;
        }

    }
`;

const Weekbtn = styled.button`
    height:40px;
    width:28px;
    border-radius:2px;
    background:${Theme.colors.info.bg};
    cursor:pointer;
`;

const NavBarContainer = styled.div`
    position:absolute;
    display: flex;
    align-content: left;
    justify-content: space-between;
    width: 100%;
    top: 1.3rem;
    padding: 0 1rem;

    >svg {
        cursor:pointer;
    }
`;

const CaptionText = styled.h5`
    font-family:circe-rounded;
    font-size:14px;
    color:#707070;
    text-align:center;
    margin-top:4px;
`;

const WeekText = styled(CaptionText)`
    font-size:12px;
    font-weight:bold;
    margin:0;
`;

const NavBar = ({onPreviousClick,onNextClick,className}) => {

    return (
        <div className={className}>
            <NavBarContainer>
                <Icon.ArrowLeft size='18px' color={Theme.colors.info.border} onClick={() => onPreviousClick()} />
                <Icon.ArrowRight size='18px' color={Theme.colors.info.border} onClick={() => onNextClick()} />
            </NavBarContainer>
        </div>
    )
}

const Caption = ({date}) =>{
    const TextDate = Moment(date).format('MMMM Y')
    return (
        <div className='DayPicker-Caption'>
            <CaptionText>{TextDate}</CaptionText>
        </div>
    );
}

const WeekDay = ({weekday,className}) => {
    return (
        <div className={className} title={WeekDayStr[weekday]}>
            <WeekText>{WeekDayStr[weekday]}</WeekText>
        </div>
    );
}

const WeekPicker = ({date,onChange}) => {
    const defaultValue = date ? Moment(date,'YYYY-MM-DD').toDate() : null;
    const [CurrDate,setCurrDate] = useState(defaultValue);
    useEffect(()=>{
        if(onChange && defaultValue !== CurrDate){
            onChange(CurrDate);                            
            localStorage.setItem('eventWindowDetect',1);
        }   
        document.addEventListener('DOMContentLoaded', function() {
            window.addEventListener('storage', (e) => {
                if (localStorage.getItem('eventWindowDetect') == 1) {
                    document.querySelector(`.DayPickerInput input`).value ='W'+('0'+Moment(localStorage.getItem('currentDate')).isoWeek()).slice(-2);
                }        
            });   
        }, false); 
    },[CurrDate])
   
     
    
    
    return (
        <WeekContainer>
            <Weekbtn onClick={()=>{NavigateThroughWeeks(CurrDate,setCurrDate,'prev')}}>
                <Icon.ArrowLeft size='12px' color={Theme.colors.info.border} />
            </Weekbtn>
            <DayPickerInput
                dayPickerProps={{
                    showWeekNumbers: true,
                    onWeekClick: (week) => {setCurrDate(Moment().week(week).toDate())},
                    navbarElement:(props)=>{return NavBar(props)},
                    captionElement:(props)=>{return Caption(props)},
                    weekdayElement:(props)=>{return WeekDay(props)},
                    month:CurrDate,
                    className:'WeekCalendar'
                }}
                inputProps={{ readOnly: true }}
                formatDate={formatDate}
                format='ww'
                value={CurrDate}
                onDayChange={(day)=>{setCurrDate(day)}}
            />
            <Weekbtn onClick={()=>{NavigateThroughWeeks(CurrDate,setCurrDate,'next')}}>
                <Icon.ArrowRight size='12px' color={Theme.colors.info.border} />
            </Weekbtn>
        </WeekContainer>
    )
}

const formatDate = (date, format, locale) => {
    if (Moment(date).day() === 0) {
        date = Moment(date).add(1, 'days').toDate();
    }
    return 'W'+('0'+Moment(date).isoWeek()).slice(-2);
}

const NavigateThroughWeeks = (currDate,setCurrDate,direction) => {
    let newDate;
    if(direction === 'next'){
        newDate = Moment(currDate).add(7,'d').toDate();
    }else{
        newDate = Moment(currDate).subtract(7,'d').toDate();
    }
    setCurrDate(newDate);    
    localStorage.setItem('currentDate', Moment(newDate).format('YYYY-MM-DD'));    
}



export default WeekPicker;
