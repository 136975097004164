import React from 'react';
import styled from "styled-components";
import {Grid} from "../../index";
import {Icon} from "@dspworkplace/ui";
import TimeFormat from "../../../../../../../../components/TimeFormat";
import {covertUtcToLocal} from "../../../../../../../../components/TimeFormat";
import moment from 'moment';

const Circle = styled.div`
    box-sizing: border-box;
    height: 17px;
    width: 17px;
    background-color: ${props=>props.color ? props.color : '#516F90'};
    border-radius: 50%;
    opacity: 1;
`;

const ShiftTypeCell = ({name, range, color,category,start, end, startOnDst, endOnDst, stationTimeZone }) => {
    let isDST = moment().isDST();
    return(
        <Grid templateColumns='14px auto' rowGap='4px' columnGap='8px' padding='4px 4px 4px 16px'>
            <Grid>
                {
                    (category === 1) ? (
                        <Circle color={color}></Circle>
                    ) : (category === 2) ? (
                        <Circle color={color}><Icon.Replace size='13px' color='#ffffff' style={{marginLeft: '2px'}}></Icon.Replace></Circle>
                    ) : (category === 3) ? (
                        <Circle color={color}><Icon.GraduationHat size='13px' color='#ffffff' style={{marginLeft: '2px'}}></Icon.GraduationHat></Circle>
                    ) : ''
                }
                </Grid>
            <Grid color='#516F90'>{name}</Grid>
            <Grid></Grid>
            <Grid>
                {
                    isDST ?
                    (
                        <>
                            {covertUtcToLocal(startOnDst, stationTimeZone)}-{covertUtcToLocal(endOnDst, stationTimeZone)}
                        </>
                    ) :
                    (
                        <>
                            {covertUtcToLocal(start, stationTimeZone)}-{covertUtcToLocal(end, stationTimeZone)}
                        </>
                    )
                }
            </Grid>
        </Grid>
        )
};

export default React.memo(ShiftTypeCell);