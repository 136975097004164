import React, {useEffect, useReducer, useState} from "react";
import styled from "styled-components";
import {Channel} from "twilio-chat/lib/channel";
import ChannelHeader from "./ChannelHeader";
import InputMessage from "./InputMessage";
import {
    getValueFromAllowToChat,
    readOnlyChannel,
    sendFile,
    sendMessage,
    setItemToStorage,
    updateReadOnlyChannel
} from "./utils";
import {getChatIdentifier} from "../Auth";
import ChannelSettings from "./ChannelSettings";
import TypingIndicator from "./TypingIndicator";
import ChannelChatMessages from "./ChannelChatMessages";
import {MessageOfChannel} from "./types";
import {useChatStoreReducer} from "./store";
import {getUserId, showErrorToast, showToast} from "../Utilities";

function reducer(state: StateChannelChat, action: any): StateChannelChat {
    let files: any[] = [];
    let formData;
    switch (action.type) {
        case "setFilter":
            return {...state, filter: action.filter};
        case "messageAdded":
            let element = action.message;
            if (state.messages.length - 1 >= 0 && state.messages[state.messages.length - 1].sid === element.sid) {
                return state;
            }
            return {...state, messages: [...state.messages, element]};
        case "setInitialMessages":
            return {
                ...state,
                messages: action.messages,
                init: true,
                previousIndex: action.previousIndex,
                hasPreviousPage: action.hasPreviousPage,
                lastIndex: action.lastIndex
            };
        case "prependMessages":
            let tmp = [...action.messages, ...state.messages];
            let tmpInit = false;
            if (tmp.length > 1200) {
                tmpInit = true;
            }
            return {
                ...state, messages: tmp,
                init: tmpInit,
                previousIndex: action.previousIndex,
                hasPreviousPage: action.hasPreviousPage,
            };
        case "messageRemoved":
            // let indexToRemove = state.messages.findIndex(item => item.sid === action.messageSid);
            // console.log({indexToRemove});
            // state.messages.splice(indexToRemove, 1);
            return {...state, messages: state.messages.filter(item => item.sid !== action.messageSid)};
        case "messageUpdated":
            return {
                ...state, messages: state.messages.map(item => {
                    if (item.sid === action.message.sid) {
                        return action.message;
                    }
                    return item;
                })
            };
        case "sendMessage":
            if (state.channel && state.filter) {
                sendMessage(state.channel, state.filter, action.content).then(r => r);
            }
            return {...state};
        case "setChannel":
            return {...state, channel: action.channel};
        case "setFiles":
            return {...state, files: action.files, openFileModal: action.openFileModal};
        case "removeFile":
            state.files.splice(action.removeIndex, 1);
            return {...state};
        case "sendByS3":
            let promisesForFiles: any[] = [];
            for (let index = 0; index < state.files.length; index++) {
                formData = new FormData();
                formData.append("file", state.files[index]);
                formData.append("name", state.files[index].name);
                files[index] = formData;
                promisesForFiles.push(sendFile(state.channel, files[index], state.files[index]));
            }
            Promise.all(promisesForFiles).then();
            return {...state, files: [], openFileModal: action.openFileModal};
        case "sendFiles":
            for (let index = 0; index < state.files.length; index++) {
                formData = new FormData();
                formData.append(state.files[index].name, state.files[index]);
                // @ts-ignore
                files[index] = formData;
            }
            for (let index = 0; index < state.files.length; index++) {
                sendMessage(state.channel, state.filter, files[index]).then();
            }
            return {...state, files: [], openFileModal: action.openFileModal};
        case "setOpen":
            return {...state, openFileModal: action.openFileModal};
        case "setLoading":
            return {...state, loading: false};
        case "setPreviousIndex":
            return {...state, previousIndex: action.previousIndex};
        case "setLastIndex":
            return {...state, lastIndex: action.lastIndex};
        case "setHasPreviousPage":
            return {...state, hasPreviousPage: action.hasPreviousPage};
        case "setInit":
            return {...state, init: action.init};
        case "setOpenChannelSettings":
            return {...state, openChannelSettings: action.openChannelSettings};
        default:
            return state;
    }
}

export type StateChannelChat = {
    channel: Channel;
    messages: Array<MessageOfChannel>;
    identity: string | null;
    files: Array<any>;
    openFileModal: boolean;
    loading: boolean;
    openChannelSettings: boolean;
    previousIndex: number;
    lastIndex: number;
    hasPreviousPage: boolean;
    init: boolean;
    filter: any;
};

const ChannelChatContainer = styled.div`
    display: grid;
    width: 340px;
    height: calc(8px + 56px + 165px + 330px);
    box-shadow: 0 3px 6px #00000029;
    background-color: white;
    min-height: calc(8px + 56px + 165px + 330px);
    align-content: flex-start;
`;

type Props = {
    channel: Channel;
};

function ChannelChatWindow(props: Props) {
    const {channel} = props;
    const {state: chatState} = useChatStoreReducer();
    const {allowToChat} = chatState;
    const [showInput, setShowInput] = useState(getValueFromAllowToChat());
    const initialState: StateChannelChat = {
        channel: channel,
        messages: [],
        identity: getChatIdentifier(),
        files: [],
        openFileModal: false,
        loading: false,
        openChannelSettings: false,
        previousIndex: 0,
        lastIndex: 0,
        hasPreviousPage: false,
        init: false,
        filter: chatState.filter,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const abortController = new AbortController();
        const checkReadOnly = async () => {
            if (channel) {
                try {
                    // @ts-ignore
                    if (channel.attributes.kind === 'Group' &&
                        // @ts-ignore
                        Array.isArray(channel.attributes.permissions) &&
                        // @ts-ignore
                        channel.attributes.permissions.length > 0) {
                        let userId = getUserId();
                        // @ts-ignore
                        let roleIds = channel.attributes.permissions;
                        let result = await readOnlyChannel(userId, roleIds);
                        if (result.success) {
                            setShowInput(false);
                        } else {
                            // setShowInput(true);
                            setShowInput(false);
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'Error in checking read only for channel ' + channel.friendlyName + '.');
                    // setShowInput(true);
                    setShowInput(false);
                }
            }
        }
        checkReadOnly().then();
        return () => {
            abortController.abort();
        };
    }, []);

    useEffect(() => {
        if (allowToChat && allowToChat.value) {
            // setShowInput(allowToChat.value);
            setShowInput(false);
        } else {
            setShowInput(false);
        }
    }, [allowToChat.track]);

    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on('updated', async (event) => {
                try {
                    // @ts-ignore
                    if (channel.attributes.kind === 'Group') {
                        if (Array.isArray(event.updateReasons) && event.updateReasons.includes("dateUpdated")) {
                            // @ts-ignore
                            if (event.channel.attributes &&
                                Array.isArray(event.channel.attributes.permissions)
                            ) {
                                let userId = getUserId();
                                let roleIds = event.channel.attributes.permissions;
                                let result = await updateReadOnlyChannel(userId, roleIds, channel);
                                setItemToStorage(`permissions_${channel.sid}`, roleIds);
                                if (result.success) {
                                    await showToast({
                                        type: 'info',
                                        title: 'You are allowed to read, but not send messages in this channel.'
                                    });
                                    setShowInput(false);
                                } else {
                                    // setShowInput(true);
                                    setShowInput(false);
                                }
                            }
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'Error in update event for channel ' + channel.friendlyName);
                    // setShowInput(true);
                    setShowInput(false);
                }
            });
        }
        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <ChannelChatContainer data-id={"container-" + channel.sid}>
                <ChannelHeader channel={channel} channelDispatch={dispatch}/>
                <ChannelChatMessages channel={channel} state={state} dispatch={dispatch} showInput={showInput}/>
                <TypingIndicator channel={channel}/>
                {showInput &&
                <InputMessage state={state} dispatch={dispatch} channel={channel} allowToChat={chatState.allowToChat}/>}
            </ChannelChatContainer>
            {state.openChannelSettings && channel && <ChannelSettings channel={channel} channelDispatch={dispatch}/>}
        </>
    );
}

export default ChannelChatWindow;
