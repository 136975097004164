import apiFactory from '../../../api/factory';

const {call} = apiFactory();
export const GetIncidentLookup = async (props) => {
    const {station_id} = props

    const params = {
        "actions": {
            "response": {
                "Incident": {
                    "custom": {
                        "functionName": "getIncidentForTask",
                        "get": "incidents",
                        "excludes": [],
                        "criteria": {
                             "companyId": localStorage.getItem('company'),
                            "stationId": station_id,
                        }
                    }
                }
            }
        }
    };
    const response = await call(params, `/api/lazy/manage/data`, 'post');
    return response.data.data.incidents;
}