import React, {useState, useEffect} from 'react';
import {Content, Menu, Navigator} from "../../components/Menus/Navigator";
import {Spacer, SubTitle, Theme, Icon} from "@dspworkplace/ui";
import {NavLink, Route, Switch} from "react-router-dom";
import {ROUTES} from "../../Route_oold";
import {getRole} from "../../Auth";

const IconsSolver = {
    Messages: <Icon.Megaphone size={'20px'}/>,
    Templates: <Icon.Document size={'20px'}/>,
    Segments: <Icon.Segment size={'20px'}/>
}
let allowedRoles = ['ROLE_STATION_MANAGER', 'ROLE_ASSISTANT_STATION_MANAGER', 'ROLE_OPERATIONS_ACCOUNT_MANAGER', 'ROLE_OPERATIONS_MANAGER'];
const App = (routeProps) => {
    const {history, match} = routeProps;
    const {url, isExact} = match;
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        try {
            let result = ROUTES.find(r => r.name === 'Company Management').routes.find(r => r.name === 'WorkFlow Builders').routes;
            if (Array.isArray(result)) {
                let currentUserRole = getRole();
                if (typeof currentUserRole === 'string' && allowedRoles.find(e => e === currentUserRole)) {
                    setRoutes(result);
                } else {
                    result = result.filter(r => r.name !== 'Load Out' && r.name !== 'RTS')
                    setRoutes(result);
                }

                if (isExact) {
                    history.replace(url + result[0].path);
                }
            }
        } catch (e) {
            console.error({e});
        }

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <div style={{display: 'flex', position: 'relative'}}>
            <Navigator className='scrollable'>
                <SubTitle>WorkFlow Builder</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                <Menu>
                    {routes.map((r, k) =>
                        <li key={k} data-key={JSON.stringify(k)}>
                            <NavLink to={`${url}${r.path}`} onClick={() => {
                                console.log(r);
                            }}>{IconsSolver[r.name]} {r.name}</NavLink>
                        </li>
                    )}
                </Menu>
            </Navigator>
            <Content className='scrollable' style={{position: 'relative'}}>
                <Switch>
                    {routes.map(({path, feature, component}, k) =>
                        <Route
                            path={`${url}${path}`}
                            key={k}
                            render={props => React.createElement(component, props)}
                        />
                    )}
                </Switch>
            </Content>
        </div>
    )
}

export default App;