import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {AiImageType, useStoreReducer} from "./store";
import {Button, CustomDropdown, Spacer, Text, Theme} from '@dspworkplace/ui';
import styled from "styled-components";
// import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";
// import DayPicker from '../../../components/Calendar/day';
import DatePicker from "../../../components/Calendar/picker";
import Tooltip from "../../../components/Tooltip";
// import {tokenState} from "../../../domain/security/state/Token";
import { useRecoilValue } from "recoil";
// import { getUserId } from "../../../Auth";
import { AccessCheck } from "../../../security";
import {planDetailsState} from "../../../state/planDetail";

const CheckboxesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 50px;
    height: 40px;

    input.name {
        width: 270px;
        margin-right: 0px;
    }

    input.sortOrder {
        width: 40px;
        margin-right: 5px;
        text-align: center;
        font-size: 16px;
    }

    input.checked {
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.forDriver {
        margin-left: 64px;
        display: flex;
        align-items: center;
        width: 12.5px;
    }

    input.hide {
        display: none;
    }
`;

const CategoriesHeader = styled.div`
    display: grid;
    grid-template-columns: 295px 75px 40px 165px auto;
    grid-column-gap: 25px;
    align-items: center;
    flex-direction: row;

    .optional {
        display: block;
        font-size: 10px;
        margin-left: 4px;
    }

    .default {
        display: block;
        height: 36px;
    }

    .driver {
        display: block;
        height: 36px;
    }
`;

const FormDiv = styled.div`
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 10px;
`;

const AiCategoryNotification = styled.div`
    span {
        display: flex;
        label {
            padding-left: 10px;
            height: 100%;
        }
    }
    label.hide {
        display: none;
    }
    input.hide {
        display: none;
    }
`;

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const options = [
    {name: 'Every day', value: '1'},
    {name: 'Every 2 days', value: '2'},
    {name: 'Every 7 days', value: '7'},
    {name: 'Every 15 days', value: '15'},
    {name: 'Every 30 days', value: '30'},
];

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: AiImageType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function AiImageInspectionForm(props: Props) {
    const {item, updateItem} = props;
    
    const {appState} = useStoreReducer();
    const { name, description, isPassRequired, aiImageCategories, disabledQuestion, submitToAi, aiCategoryNotification } = item.content;
    // const permissions = useRecoilValue(tokenState);
    const {tier} = useRecoilValue(planDetailsState);
    const aiImageInspectionAddon = AccessCheck.isGranted({ plan: tier, feature:AccessCheck.addons.VEHICLE_AI_PHOTO_INSPECTION, role:null, ops:null, station:null });
    const submitToAiValue = (aiImageInspectionAddon === true) ? submitToAi : false;

    const {register, handleSubmit, reset, control, getValues } = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            // aiImageCategories: appState.aiImageCategories,
            aiImageCategories: aiImageCategories && Array.isArray(aiImageCategories) && aiImageCategories.length > 0 ? aiImageCategories : appState.aiImageCategories,
            disabledQuestion,
            submitToAi: submitToAiValue,
            aiCategoryNotification
        }
    });
    
    const [disabledQuestionFlag, setDisabledQuestionFlag] = useState(false);

    useEffect(() => {
        if (!disabledQuestionFlag) {
            const flag = (getValues().disabledQuestion === true) ? true : false;
            setDisabledQuestionFlag(flag);
        }
    }, [disabledQuestionFlag]);

    const {
        fields,
    } = useFieldArray({
        name: "aiImageCategories",
        control
    });

    useEffect(() => {
        const abortController = new AbortController();
        // checkIfCategoriesAreEmpty(vehicleImageCategories).then();

        return () => {
            abortController.abort();
        };
    }, []);

    const defaultAiCategoryNotification = [
        {
            label: "send_alert_on_inspection_failure",
            description: "Send alert on inspection failure",
            notification: true,
        },
        {
            label: "New damage detected",
            description: "do not alert on 'New damage detected' images",
            notification: false,
        },
        {
            label: "Potential new damage detected",
            description: "do not alert on 'Potential new damage detected' images",
            notification: false,
        },
        {
            label: "Incorrect photo",
            description: "do not alert on 'Incorrect photo' images",
            notification: false,
        },
        {
            label: "Bad angle",
            description: "do not alert on 'Bad angle' images",
            notification: false,
        },
    ];
    
    const inspectionNotification = (aiCategoryNotification != null && aiCategoryNotification != undefined) ? aiCategoryNotification[0].notification : defaultAiCategoryNotification[0].notification;
    const [sendAlertInspectionFailure, setSendAlertInspectionFailure] = useState(inspectionNotification);
    const onHandleSendAlertInspectionFailure = (event) => {
        if (event.target.checked) {
            setSendAlertInspectionFailure(true);
        } else {
            setSendAlertInspectionFailure(false);
        }
    };
    
    return (
        <FormDiv>
            <Form onSubmit={handleSubmit(updateItem)}>
                <label htmlFor="name">Name</label>
                <input name="name" ref={register({
                    required: true
                })}/>
                <label htmlFor="description">Description</label>
                <TextArea name="description" ref={register}/>
                <label>Is required to Pass?</label>
                <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
                {/*<label>Notify?</label>*/}
                {/*<input name="notifyIfChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
                <div className={'choices'}>Vehicle Image Category</div>
                <div>
                    <CategoriesHeader>
                        <div className={"default"}>
                            <div>Name</div>
                        </div>
                        <div className={"default"}>
                            <div>Sort Order</div>
                        </div>
                        <div className={"default"}>
                            <div>Enable</div>
                        </div>
                        <div className={"default"}>
                            <div>Frequency</div>
                        </div>
                        <div className={"default"}>
                            <div>Begin At</div>
                        </div>
                    </CategoriesHeader>
                    {fields.map((field, index) => {
                        return (
                            <CheckboxesContainer key={field.id}>
                                <input
                                    className={'name'}
                                    name={`aiImageCategories[${index}].name`}
                                    defaultValue={field.name}
                                    ref={register()}
                                    disabled={true}
                                />
                                <input
                                    className={"sortOrder"}
                                    name={`aiImageCategories[${index}].sortOrder`}
                                    defaultValue={field.sortOrder}
                                    ref={register()}
                                    disabled={false}
                                />
                                <input
                                    className={'hide'}
                                    name={`aiImageCategories[${index}].id`}
                                    defaultValue={field.id}
                                    ref={register()}
                                    disabled={true}
                                    hidden={true}
                                />
                                <input
                                    name={`aiImageCategories[${index}].checked`}
                                    type="checkbox"
                                    className={'checked'}
                                    defaultChecked={!!field.checked}
                                    ref={register()}/>
                                <CustomDropdown
                                    name={`aiImageCategories[${index}].frequency`}
                                    options={options}
                                    size={'small'}
                                    // label={'Triggers'}
                                    ref={register()}
                                    defaultValue={field.frequency}
                                    // onChange={onChangeTrigger}
                                />
                                <DatePicker
                                    name={`aiImageCategories[${index}].date`}
                                    size={'small'}
                                    style={{fontSize: '14px'}}
                                    // label={opt.name}
                                    // onChange={(date) => {
                                    //     if (date.length > 0) {
                                    //         const splitDate = date.split(" – ");
                                    //         const startDate = splitDate[0] + " 00:00";
                                    //         const endDate = splitDate[1] + " 23:59";
                                    
                                    //         setTimeout(() => selectedTimeframe({ start: startDate, end: endDate }), 1);
                                    //     } else {
                                    //         setTimeout(() => selectedTimeframe(false), 1);
                                    //     }
                                    // }}
                                    ref={register()}
                                    // defaultRangeValue={selectedFilters[opt.key+'d'] && selectedFilters[opt.key+'d']}
                                    // range={"date"}
                                />
                                {/* <DayPicker
                                    name={'aiImageCategories[${index}].frequency'}
                                    label='From Effective Date'
                                    size='medium'
                                    ref={register({ required: true })}
                                    // error={errors.effectiveDate && "Please select Effective Date."}
                                    // valid={!errors.effectiveDate && effectiveDate}
                                /> */}
                                {/* <input
                                    name={`vehicleImageCategories[${index}].checked`}
                                    type="checkbox"
                                    className={'checked'}
                                    defaultChecked={!!field.checked}
                                    ref={register()}/>
                                <input
                                    name={`vehicleImageCategories[${index}].forDriver`}
                                    type="checkbox"
                                    className={'forDriver'}
                                    defaultChecked={!!field.forDriver}
                                    ref={register()}
                                /> */}
                            </CheckboxesContainer>
                        );
                    })}
                </div>
                <div className={"choices"}>Ai Category Notification Alert</div>
                <AiCategoryNotification>
                    {aiCategoryNotification
                        ? aiCategoryNotification.map((category, index) => {
                            return (<span>
                                      <input
                                          type="hidden"
                                          name={`aiCategoryNotification[${index}].label`}
                                          defaultValue={category.label}
                                          ref={register}
                                      />
                                      <input
                                          type="hidden"
                                          name={`aiCategoryNotification[${index}].description`}
                                          defaultValue={category.description}
                                          ref={register}
                                      />
                                      <input
                                          className={index != 0 && sendAlertInspectionFailure == false ? "hide checkbox" : "checkbox"}
                                          name={`aiCategoryNotification[${index}].notification`}
                                          type={"checkbox"}
                                          ref={register}
                                          onChange={(event) => {if(index === 0 ) {onHandleSendAlertInspectionFailure(event);} }}
                                      />
                                      <label className={index != 0 && sendAlertInspectionFailure == false ? "hide" : ""}>{category.description}</label>
                                  </span>)
                          })
                        : defaultAiCategoryNotification.map((category, index) => {
                              return (
                                  <span>
                                      <input
                                          type="hidden"
                                          name={`aiCategoryNotification[${index}].label`}
                                          defaultValue={category.label}
                                          ref={register}
                                      />
                                      <input
                                          type="hidden"
                                          name={`aiCategoryNotification[${index}].description`}
                                          defaultValue={category.description}
                                          ref={register}
                                      />
                                      <input
                                          name={`aiCategoryNotification[${index}].notification`}
                                          type={"checkbox"}
                                          defaultChecked={category.notification}
                                          className={"checkbox"}
                                          ref={register}
                                          onChange={(event) => {if(index === 0) {onHandleSendAlertInspectionFailure(event)} }}
                                      />
                                      <label>{category.description}</label>
                                  </span>
                              );
                          })}
                </AiCategoryNotification>

                <Button
                    typeStyle="delete"
                    style={{ width: "180px" }}
                    onClick={() => {
                        reset();
                    }}
                >
                    Reset
                </Button>
                <ManageDisabledCheckBox className="d_flex">
                    <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                        Save
                    </Button>
                    <div className="ml_100">
                        <ManageDisabledCheckBox className="d_flex">
                            <input
                                name="disabledQuestion"
                                type="checkbox"
                                className={"checkbox"}
                                ref={register}
                                onChange={() => setDisabledQuestionFlag(!disabledQuestionFlag)}
                            />
                            <label>Disable Question</label>
                        </ManageDisabledCheckBox>
                        {!disabledQuestionFlag && <ManageDisabledCheckBox className="d_flex">
                            <input name="submitToAi" type="checkbox" className={"checkbox"} ref={register} disabled={!aiImageInspectionAddon}/>
                            <label>Submit to AI</label>
                            {!aiImageInspectionAddon && <Spacer inline left={1}>
                                <Tooltip
                                    verticalPosition={'center'}
                                    horizontalPosition={'right'}
                                    content={<Text>Features are only available when you sign up to the addon.</Text>}
                                >
                                    <Question>?</Question>
                                </Tooltip>
                            </Spacer>}
                        </ManageDisabledCheckBox>}
                    </div>
                </ManageDisabledCheckBox>
            </Form>
        </FormDiv>
    );
}

export default AiImageInspectionForm;