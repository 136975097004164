import React, {forwardRef, useEffect, useState} from "react";
import styled from 'styled-components';
import {RadioBox, Spacer} from "@dspworkplace/ui";
import ShiftBlock from "./index";

const Style = styled.div`
    ${RadioBox} {
        // options 
        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        
        // Label
        h5 {
            width: 100%;
            
            // Spacer
            ~ div {
                width: 100%;
                padding: 8px 0 0;
                
                // Box
                > div,
                > div > label {
                    width: 100%;
                }
            }
        }
        
        // checkbox
        label {
            // > div {
            //     display: none;
            // }
            
            // Box
            > div + span {
                // margin: 0;
                width: 100%; 
            }
        }
    }
`;

const ShiftBlockSelector = forwardRef(({
    shifts = [],
    ...props
}, ref) => {
    return (
        <Style>
            { shifts.length > 0 
                ? 
                    <RadioBox
                        ref={ref}
                        name='driverRoutes'
                        options={shifts.map((s, i) => ({
                            value: s.id,
                            label: <ShiftBlock
                                type={s.shiftType.name}
                                hour={s.hour}
                                background={s.shiftType.color}
                                color={s.shiftType.textColor}
                            />
                            }))
                        }
                        title={'Shift'}
                        {...props}
                    />
                :
                    <RadioBox
                        ref={ref}
                        name='driverRoutes'
                        disabled={"true"}
                        options={[{ value: "", label: 'No shift available ' }]}
                        title={'Shift'}
                        {...props}
                    />
            }
        </Style>
    )
});

export default ShiftBlockSelector;