import React from "react";
import {Spacer, Text, Title} from "@dspworkplace/ui";
import {ScorecardProvider} from "../context";
import Template from "../../Reports/Scorecards/SortTemplate";

export default function ScorecardDriverApp (props)
{
    return (
        <ScorecardProvider>
            <App {...props}/>
        </ScorecardProvider>
    )
};

const App = () => {
    return <>
        <Title>
            Weekly Overview
        </Title>
        <Spacer bottom={3}/>
        <Text>Manage what your driver sees on Weekly Overview Tab</Text>
        <Spacer bottom={3}/>
        <Template/>
    </>
}