import ComboboxRecoil, {
    ComboboxRecoilTypeBase
} from "../../../components/Form/Combobox/combobox.recoil";
import React from "react";
import {VMTaskPriority} from "../state/VehicleMaintenanceTaskPriority";

const VehicleTaskPriorityCombobox = (props: ComboboxRecoilTypeBase) => {
    return <ComboboxRecoil {...props} store={VMTaskPriority} />
}

export default VehicleTaskPriorityCombobox