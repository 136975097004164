import {atom} from "recoil";

export type VMTaskStatus = {
    label: string
    value: string
}

export const VehicleAmzStatusState = atom<VMTaskStatus[]>({
    key: 'VehicleAmzStatusState',
    default: [
        {label: 'Active', value: 'Active'},
        {label: 'Out of Service', value: 'Out of Service'},
        {label: 'Grounded', value: 'Grounded'},
        {label: 'Maintenance', value: 'Maintenance'},
        {label: 'Body Shop', value: 'Body Shop'}
    ]
})