import React from 'react';
import ModalWindow from "./Modal";
import styled from "styled-components";

type Props = {
    title: string;
    width: string;
    cancelModal: () => void;
    src: string;
}

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    max-width: 600px;
    max-height: 380px;
    padding: 4px;
    background-color: ${props => (props.bgColor ? props.bgColor : "#516F90")};
    border-radius: 2px;
`;

function ImageModal({title, width, cancelModal, src}: Props) {
    return (
        <ModalWindow title={title} width={width} cancelModal={cancelModal} cancelText={'Close'}>
            <ImageContainer>
                <Image src={src} alt="File"/>
            </ImageContainer>
        </ModalWindow>
    );
}

export default ImageModal;