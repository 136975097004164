import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, SubTitle, ThirdTitle, Spacer, Dropdown, Input} from "@dspworkplace/ui";

import {ScrollyModalStyle, ScrollyOverlayStyle} from "../../../components/Modal";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import styled from "styled-components";
import TagInput from "../../../components/TagInput";

export const Subtitle = styled.div`
    color: #516f90;
    font-family: circe-rounded, serif;
    font-size: 16px;
`;
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
  }
  
  const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  

const modalRoot = document.getElementById("modal-root");

const VehicleImageForm = ({api, success, cancel, vehicleImage, categories}) => { 

    const [loading, setLoading] = useState(false);
    const {register, errors, getValues, handleSubmit, reset} = useForm({mode: "onChange", defaultValues: {label:vehicleImage.label}});
    const inputValues = getValues();
    
    if (!vehicleImage.category.map((i) => i.name).includes("AI Failed")) {
        categories = categories.filter((obj1) => obj1.name != "AI Failed");
    }

    const onSubmit = async (data) => {
        setLoading(true);
        let param = {
            "actions": {
                "update": {
                    "VehicleImage": {
                        "update_vehicle_image_1": {
                            "findBy": {
                                "id": vehicleImage.id
                            },
                            "updateRecord": {
                                "label":data.label,
                                "category":data.category
                            }
                        }
                    }
                }
            }
        }
        await api.post('/api/lazy/manage/data', param);
        vehicleImage.label = data.label;
        vehicleImage.category = [];
        categories.map((c) => {
            if(data.category.includes(c.id.toString())){
                vehicleImage.category.push(c);
            }
        });
        setLoading(false);
        success(vehicleImage);

    };


    return (
        <ScrollyOverlayStyle>
            <ScrollyModalStyle
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                width="750px"
                onClick={evt => evt.stopPropagation()}
            >
                <header>
                    <SubTitle>Edit Vehicle Image</SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                {loading ? (
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                ) : (
                    <>
                        <TagInput
                            ref={register()}
                            name='category'
                            label='Category'
                            size='small'
                            defaultValues = {vehicleImage.category.map((c)=>c.id)}
                            valid={!errors.category && inputValues.category && !errors.category}
                            error={errors.category && errors.category.message}
                            options={categories.map((c) => ({ name: c.name, value: c.id, disabled: (c.name === "AI Failed") ? true : false  }))}
                            canCreate={false}
                        />
                        <Spacer inline right={5}/>
                        <Input
                            name="label"
                            label="Note"
                            ref={register()}
                            error={errors.label && errors.label.message}
                            valid={!errors.label && inputValues.label && inputValues.label.length > 0}
                        />

                        <Button type={"primary"} style={{marginLeft: "auto", display: "block", width: 80}}>
                            Edit
                        </Button>
                    </>
                )}
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    );
};

const hide = () =>
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(modalRoot);
    }, 1);

const showVehicleImageEditForm = async opts => {
    let {api, children, vehicleImage, categories} = opts || {};

    return new Promise((resolve, reject) => {
        const success = data => hide() && resolve(data);
        const cancel = data => hide() && reject(data);

        return ReactDOM.render(
            <VehicleImageForm api={api} success={success} cancel={cancel} children={children}  vehicleImage={vehicleImage} categories={categories} />,
            modalRoot
        )
    });
    
};

export {showVehicleImageEditForm};
