const getDefaultFields = (company,id=0,station=0) => {
    const ret = {
        "get": "vehicles",
        "criteria": {
            "isArchive": false,
            "company": company
        },
        "excludes": {
            "0": "company"
        },
        "includes": {
            "0": "id",
            "1": "dateEnd",
            "2": "dateStart",
            "3": "fuelCardNumber",
            "4": "gasType",
            "5": "height",
            "6": "insuranceCompany",
            "7": "licensePlate",
            "8": "licensePlateExpiration",
            "9": "make",
            "10": "model",
            "11": "modelType",
            "12": "notes",
            "20": "policyNumber",
            "21": "state",
            "22": "status",
            "23": "tankCapacity",
            "24": "tollCardNumber",
            "25": "type",
            "26": "vehicleId",
            "27": "vin",
            "28": "year",
            "30": "unitNumber",
            "31": "overnightParkingLocation",
            "32": "currentMileage",
            "33": "currentGasTankLevel",
            "34": "currentUsedBy",
            "35": "tollCardStartDate",
            "36": "tollCardEndDate",
            "37":"eldUnitCode",
            "38":"amazonBranded",
            "39":"cubicFeetStorage",
            "station": {
                "0":"id",
                "1":"code",
                "2":"name",
                "3":"stationAddress"
            }
        }
    }
    if(id > 0){
        ret['criteria']['id'] = id
    }

    if(station > 0){
        ret['criteria']['station'] = station
    }

    return ret;
}

const fetchAllVehicles = async (api,company,selectedStation) => {
    const params = {
            actions: {
                response: {
                    Vehicle:{
                        custom: {
                            functionName: "getAllVanAffinity",
                            get: "vehicles",
                            criteria: {
                                company: company,
                                station: selectedStation
                            }
                        }
                    }
                }
            }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.vehicles;
    } catch (error) {

    }
}

const fetchDrivers = async (api,company,selectedStation) => {
    const params = {
            actions: {
                response: {
                    Driver:{
                        custom: {
                            functionName: "getDriverForVanAffinity",
                            get: "drivers",
                            criteria: {
                                company: company,
                                station: selectedStation
                            }
                        }
                    }
                }
            }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.drivers;
    } catch (error) {

    }
}

const saveVanAffinityData = async (api,data,company,selectedStation) => {
    delete data.valid;

    const affinity = {};
    Object.keys(data).map(v => {
        affinity[`pr1_${v}`] = data[v].primary?.[0]?.[0] || null;
        affinity[`pr2_${v}`] = data[v].primary?.[1]?.[0] || null;
        affinity[`sr1_${v}`] = data[v].secondary?.[0]?.[0] || null;
        affinity[`sr2_${v}`] = data[v].secondary?.[1]?.[0] || null;
    });

    const params = {
        actions: {
            response: {
                Vehicle:{
                    custom: {
                        functionName: "saveVanAffinityData",
                        get: "van",
                        criteria: {
                            company: company,
                            station: selectedStation,
                            data: JSON.stringify(affinity)
                        }
                    }
                }
            }
        }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.van;
    } catch (error) {

    }
}

const saveVanAffinityDriverData = async (api,data,company,selectedStation) => {
    delete data.valid;

    const affinity = {};
    Object.keys(data).map(d => {
        const id = d.replace('d', '');
        affinity[`pv_${id}`] = data[d].primary;
        affinity[`sv_${id}`] = data[d].secondary;
    });

    const params = {
        actions: {
            response: {
                Vehicle:{
                    custom: {
                        functionName: "saveVanAffinityDriverData",
                        get: "driver",
                        criteria: {
                            company: company,
                            station: selectedStation,
                            data: JSON.stringify(affinity)
                        }
                    }
                }
            }
        }
    }

    try {
        const response = await api.post('/api/lazy/manage/data', params);
        return response.data.data.driver;
    } catch (error) {

    }
}


export {fetchAllVehicles,fetchDrivers,saveVanAffinityData,saveVanAffinityDriverData}
