import React, {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { Button, SubTitle, Spacer, Toggle, Dropdown } from "@dspworkplace/ui";
import { useAuth } from "../../../Auth";
import SettingsContext from "../context";
import { getCompanyId, showToast } from "../../../Utilities";
import WorkHourComplianceNotifyUsers from "./WorkHourComplianceNotifyUsers";
import { AccessCheck, IsGranted } from "../../../security";

async function updateWorkHourComplianceNotifyUsers(api, workHourComplianceNotifyUsers) {
    try {
        await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            update: 'workHourComplianceNotifyUsers',
            workHourComplianceNotifyUsers,
        });
        return true
    } catch (e) {
        return false;
    }
}

const WorkHourComplianceWarning = () => {
    const { register, handleSubmit, watch, reset} = useForm();
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const workHourComplianceWarning = watch("workHourComplianceWarning");

    const optionHours = [{name:'00',value:'00'},{name:'01',value:'01'},{name:'02',value:'02'},{name:'03',value:'03'},{name:'04',value:'04'},{name:'05',value:'05'},{name:'06',value:'06'},{name:'07',value:'07'},{name:'08',value:'08'},{name:'09',value:'09'},{name:'10',value:'10'},{name:'11',value:'11'},{name:'12',value:'12'},{name:'13',value:'13'},{name:'14',value:'14'},{name:'15',value:'15'},{name:'16',value:'16'},{name:'17',value:'17'},{name:'18',value:'18'},{name:'19',value:'19'},{name:'20',value:'20'},{name:'21',value:'21'},{name:'22',value:'22'},{name:'23',value:'23'}];

    const optionMinutes = [{name:'00',value:'00'},{name:'01',value:'01'},{name:'02',value:'02'},{name:'03',value:'03'},{name:'04',value:'04'},{name:'05',value:'05'},{name:'06',value:'06'},{name:'07',value:'07'},{name:'08',value:'08'},{name:'09',value:'09'},{name:'10',value:'10'},{name:'11',value:'11'},{name:'12',value:'12'},{name:'13',value:'13'},{name:'14',value:'14'},{name:'15',value:'15'},{name:'16',value:'16'},{name:'17',value:'17'},{name:'18',value:'18'},{name:'19',value:'19'},{name:'20',value:'20'},{name:'21',value:'21'},{name:'22',value:'22'},{name:'23',value:'23'},{name:'24',value:'24'},{name:'25',value:'25'},{name:'26',value:'26'},{name:'27',value:'27'},{name:'28',value:'28'},{name:'29',value:'29'},{name:'30',value:'30'},{name:'31',value:'31'},{name:'32',value:'32'},{name:'33',value:'33'},{name:'34',value:'34'},{name:'35',value:'35'},{name:'36',value:'36'},{name:'37',value:'37'},{name:'38',value:'38'},{name:'39',value:'39'},{name:'40',value:'40'},{name:'41',value:'41'},{name:'42',value:'42'},{name:'43',value:'43'},{name:'44',value:'44'},{name:'45',value:'45'},{name:'46',value:'46'},{name:'47',value:'47'},{name:'48',value:'48'},{name:'49',value:'49'},{name:'50',value:'50'},{name:'51',value:'51'},{name:'52',value:'52'},{name:'53',value:'53'},{name:'54',value:'54'},{name:'55',value:'55'},{name:'56',value:'56'},{name:'57',value:'57'},{name:'58',value:'58'},{name:'59',value:'59'}];

    useEffect(() => {
        const workHourComplianceWarningTimePeriod = (state.company.workHourComplianceWarningTimePeriod ? (state.company.workHourComplianceWarningTimePeriod):"").split(":");
        reset({
            "workHourComplianceWarning" : state.company.workHourComplianceWarning, 
            "workHourComplianceTimeHour" : workHourComplianceWarningTimePeriod[0],
            "workHourComplianceTimeMinute" : workHourComplianceWarningTimePeriod[1]
        });
    }, [state.company]);

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            form.querySelector('button').disabled = true;
            if(data.workHourComplianceNotifyUsers.length == 0){
                data.workHourComplianceNotifyUsers = null;
            }
            let updateRecord = { 
                workHourComplianceWarning: data.workHourComplianceWarning,
                ...(data.workHourComplianceWarning && {workHourComplianceWarningTimePeriod: data.workHourComplianceTimeHour+':'+data.workHourComplianceTimeMinute})
            };
            await api.post('/api/lazy/manage/data', {
                actions: {
                    update: {
                        Company: {
                            update_company: {
                                findBy: {
                                    id: getCompanyId()
                                },
                                updateRecord: updateRecord
                            }
                        }
                    }
                }
            });
            if(data.workHourComplianceWarning == true){
                await updateWorkHourComplianceNotifyUsers(api, data.workHourComplianceNotifyUsers);
                updateRecord = { 
                    ...updateRecord,
                    ...{workHourComplianceNotifyUsers: data.workHourComplianceNotifyUsers}
                };
            }
            await showToast({
                type: 'success',
                title: 'Enable Work Hour Compliance Warning',
                content: 'Updates completed'
            });
            form.querySelector('button').disabled = false;
            dispatch({type: 'UPDATE_COMPANY', payload: {company: {...state.company, ...updateRecord}}})
        } catch (e) {
            await showToast({ type: 'error', title: 'Enable Work Hour Compliance Warning', content: JSON.stringify(e) });
            form.querySelector('button').disabled = false;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Enable Work Hour Compliance Warning</SubTitle>
            <Spacer bottom={2} />
            <Toggle
                ref={register}
                name='workHourComplianceWarning'
                singleText='Send notification HH:MM after Flex login'
                on={workHourComplianceWarning}
            />
            {workHourComplianceWarning && 
            <Spacer top={4}>
                <label style={{fontSize: "16px", fontWeight: "normal", fontFamily: "Circe-Rounded", display: "block", paddingBottom: "2px", color: "#707070"}}>Time period</label>
                <Dropdown
                    name='workHourComplianceTimeHour'
                    label='Hour'
                    ref={register}
                    placeholder='Hour'
                    options={optionHours}
                    style={{marginRight:'5px',fontSize: "12px"}}
                    size='extraSmall'
                    valid={"true"}
                />
                <Dropdown
                    name='workHourComplianceTimeMinute'
                    ref={register}
                    label='Minute'
                    placeholder='Minute'
                    options={optionMinutes}
                    style={{}}
                    size='extraSmall'
                    valid={"true"}
                />
                <Spacer bottom={5} />
                <WorkHourComplianceNotifyUsers ref={register}/>
            </Spacer>} 
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default WorkHourComplianceWarning;