import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Icon, Spacer, Theme, Title, Button } from "@dspworkplace/ui";
import TabPanel from "./tabPanel";
import DayPicker from "../../../components/Calendar/dayNew";
import { WeekProvider } from "./store";
import Store from "./store";
import moment from "moment";
import { useAuth } from "../../../Auth";
import Day from "./day";
import ViewSelector from "../Common/ViewSelector";
import ExportButton from "../Common/ExportButton";
import { Action } from "../Common/UI";
import PublishPopUp from "../Common/PublishPopUp";
import { useSelectedStation } from "../../../components/StationSelector";
import { FilterWeekDate } from "../../../hooks/useGlobalData";
import { showDriverSelection } from "../../SchedulerLoadOut/driverSelection";
import { confirm } from "../../../components/Confirm";

const Body = styled.div`
    box-sizing: border-box;
    max-height: 100vh;
    padding: 20px 20px 0 20px;

    @media (max-width: 1201px) {
        .title{
            font-size:32px
        }     
    }
`;

const ActionBox = styled.div`
    display: flex;
    align-items: center;
`;

const ExportActions = styled.div`
    margin-left: auto;
`;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
`;


const App = () => {
    let [newDate, setNewDate] = FilterWeekDate();
    //let [newDate, setNewDate] = useLocalStorage("schedulerDate", new Date());
    const fakeRefresh = useState(0);
    let selectedDate = moment(newDate).format("YYYY-MM-DD");
    const { api } = useAuth();

    const [selectedStation] = useSelectedStation();
    let week = moment(selectedDate).isoWeek();
    if (moment(selectedDate).day() === 0) {
        week = moment(selectedDate).add(1, 'days').isoWeek();
    }

    let year = parseInt(moment(selectedDate).startOf("week").format("YYYY"));
    if(moment(selectedDate).startOf("week").format('YYYY') != moment(selectedDate).endOf("week").format('YYYY') && week == 1 && year == moment(selectedDate).startOf("week").format('YYYY')) {
        year = parseInt(moment(selectedDate).endOf("week").format('YYYY'));
    }


    return (
        <WeekProvider>
            <Body>
                <ActionBox>
                    <PublishPopUp date={newDate} week={week} year={year} view={'day'} fakeRefresh={fakeRefresh} />
                    <Spacer inline left={5}>
                        <Title className={'title'} >{moment(newDate).format("MMMM D, YYYY")}</Title>
                    </Spacer>
                    <ExportActions>
                        <Spacer
                            inline={true}
                            style={{ position: "relative", verticalAlign: "top", zIndex: 99 }}
                            right={3}
                        >
                            <Action
                                onClick={() => {
                                    setNewDate(moment(newDate).subtract(1, "day"));
                                }}
                            >
                                <Icon.ArrowLeft
                                    size="12px"
                                    color={Theme.colors.info.border}
                                    style={{ verticalAlign: "text-top" }}
                                />
                            </Action>
                            <DayPicker
                                id="day-view-calendar"
                                onChange={date => {
                                    if (date.utc) {
                                        const utc = moment.utc(date.utc);
                                        if (utc.isValid() && selectedDate !== utc.format('YYYY-MM-DD')) {
                                            setNewDate(date.utc);
                                        }
                                    }
                                }}
                                defaultValue={selectedDate}
                                size={"small"}
                            />
                            <Action
                                onClick={() => {
                                    setNewDate(moment(newDate).add(1, "day"));
                                }}
                            >
                                <Icon.ArrowRight
                                    size="12px"
                                    color={Theme.colors.info.border}
                                    style={{ verticalAlign: "text-top" }}
                                />
                            </Action>
                        </Spacer>
                        <ExportButton
                            params={{
                                actions: {
                                    response: {
                                        DriverRoute: {
                                            custom: {
                                                functionName: "getDriverShiftByDateForDayViewForExport",
                                                get: "file",
                                                criteria: {
                                                    date: selectedDate,
                                                    timezoneOffset: new Date().getTimezoneOffset(),
                                                    station: selectedStation,
                                                    week: week,
                                                    year: year,
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                        <Spacer right={3} inline />
                        <ViewSelector />
                        {/*<ExportActionsBtn onClick={() => window.print()}><Icon.Printer size='20px' color={Theme.colors.secondary} /></ExportActionsBtn> */}
                    </ExportActions>
                </ActionBox>
                <Spacer top={5} />

                <Container>
                    <TabPanel >
                        <Spacer top={5}>
                            <AddDriverButton fakeRefresh={fakeRefresh} selectedDate={selectedDate}/>
                        </Spacer>
                    </TabPanel>
                    <Day WeekTable weekDate={selectedDate} fakeRefresh={fakeRefresh} />
                </Container>
            </Body>
        </WeekProvider>
    );
};

const AddDriverButton = ({ fakeRefresh, selectedDate }) => {
    const [updateScheduler, setUpdatedScheduler] = fakeRefresh;
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();
    let [newDate, setNewDate] = FilterWeekDate();
    const [selectedStation] = useSelectedStation();

    const handleAddDriver = async () => {
        showDriverSelection({}, api, {
            type: "SCHEDULER_ADD_ROUTE",
            station: selectedStation,
            date: newDate,
        }).then(async (data) => {
            if (!data)
                return;

            data.driver = data.driver;
            data.date = newDate;

            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "checkSequenceDriverRoute",
                                get: "checkSequenceDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: "",
                                    driverId: data.driver,
                                    newDate: selectedDate,
                                }
                            }
                        }
                    }
                }
            };
            const response = await api.post("/api/lazy/manage/data", params1);
            if (response.data.data.checkSequenceDriverRoute.status) {
                const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
                if (!wc) return undefined;

                const params1 = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "createEventForConsicutiveShift",
                                    get: "createEventForConsicutiveShift",
                                    excludes: [],
                                    criteria: {
                                        driverId: data.driver,
                                        message: response.data.data.checkSequenceDriverRoute.message.bot
                                    }
                                }
                            }
                        }
                    }
                };
                const response1 = await api.post("/api/lazy/manage/data", params1);
            }

            const params = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "schedulerLoadOutAddDriverSave",
                                get: "result",
                                excludes: [
                                    "skill",
                                    "vehicle",
                                    "device",
                                    "driver",
                                    "station",
                                    "shiftType",
                                    "schedule",
                                    "vehicleDriverRecord",
                                    "status",
                                    "tempDriverRoutes",
                                    "kickoffLog",
                                    "returnToStation",
                                    "oldDriver",
                                    "oldDriverRoute",
                                    "driverRoutes",
                                    "oldTempDriverRoutes",
                                ],
                                criteria: {
                                    shift: data.shift,
                                    driverId: data.driver,
                                    invoiceType: data.invoiceType,
                                    routeStatus: data.routeStatus,
                                    backupStatus: data.backupStatus,
                                    onCall: data.onCall,
                                    startTime: data.startTime,
                                    endTime: data.endTime,
                                    timezoneOffset: new Date().getTimezoneOffset(),
                                    date: selectedDate,
                                    station: selectedStation,
                                    inactiveCurrent: data.inactiveCurrent
                                },
                            },
                        },
                    },
                },
            };
            try {
                const response = await api.post("/api/lazy/manage/data", params);
                if (data.station == selectedStation) {
                    if(data.inactiveCurrent == true){
                        setUpdatedScheduler(updateScheduler + 1);
                    } else{
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: "DRIVERS_SHIFT", driverId: data.driver },
                                from: { type: 'none' },
                                shift: response.data.data.result
                            },
                        });
                        dispatch({
                            type: "SET_PUBLISH_COUNT",
                            ids: [response.data.data.result.id],
                            station: response.data.data.stationId,
                            api: false
                        });
                    }
                } else {
                    setUpdatedScheduler(updateScheduler + 1);
                }
                return true;
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
            }
        })
    };

    return (<Button
        size='small'
        onClick={() => { handleAddDriver() }}
        type={'default'}
    >
        Add Driver
    </Button>);
};

export default App;
