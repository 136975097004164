import {AccessCheck} from "../../../security";
import {getRole} from "../../../Auth";

export const canModifyPast = station => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.features.SCHEDULER_PAST,
    ops: [AccessCheck.OPS.C, AccessCheck.OPS.U, AccessCheck.OPS.D],
    station: station,
});

export const canModifyFuture = station => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.features.SCHEDULER_FUTURE,
    ops: [AccessCheck.OPS.C, AccessCheck.OPS.U, AccessCheck.OPS.D],
    station: station,
});

export const canEditCompanyEmployee = station => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.features.CM_EMPLOYEE,
    ops: [AccessCheck.OPS.U],
    station: station,
});

export const canChangePermissionEmployee = station => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.features.CM_E_PERMISSION_CHANGE,
    ops: [AccessCheck.OPS.R],
    station: station,
});

export const canEditCompanySetting = station => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.features.SETTINGS,
    ops: [AccessCheck.OPS.U],
    station: station,
});

export const vehicleAiImageInspectionAddon = (station) => AccessCheck.isGranted({
    role: getRole(),
    feature: AccessCheck.addons.VEHICLE_AI_PHOTO_INSPECTION,
    ops: [AccessCheck.OPS.C, AccessCheck.OPS.R, AccessCheck.OPS.U,AccessCheck.OPS.D],
    station: station,
});