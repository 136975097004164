import React from "react";
import { Spacer, Text, Title } from "@dspworkplace/ui";
import { ScorecardProvider } from "../context";
import Template from "../../Reports/Scorecards/SortTemplate";

export default function AutomatedScorecardCoaching(props) {
    return (
        <ScorecardProvider>
            <App {...props} />
        </ScorecardProvider>
    );
}

const App = () => {
    return (
        <>
            <Title>Forced Coaching Metric</Title>
            <Spacer bottom={3} />
            <Text>Manage forced coaching metric and its sorting order.</Text>
            <Spacer bottom={3} />
            <Template tabs={"AutomatedScorecardCoaching"} />
        </>
    );
};
