import axios from "axios";
import {getCompanyId} from "../../Auth";
import moment from "moment";
import {getOffset} from "../../components/TimeFormat";
import {engine} from "../../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,

    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },

    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },

    fetchSummary: (date,station) => {
        return API.call({
            actions: {
                response: {
                    dailyDriverSummary:{
                        findOneBy: {
                            get: "summary",
                            criteria: {
                                company: getCompanyId(),
                                summaryDate:date,
                                station:station
                            },
                            excludes: {
                                0: "company",
                                1: "skill"
                            },
                            includes: {
                                "0": "id",
                                "1": "summary",
                                "2" : "driversHired",
                                "3" : "driversFired",
                                "4" : "driversQuit",
                                "5" : "scheduledInterviews",
                                "6" : "interviewsCompleted",
                                "7": "lockUser",
                                "8": "reportUrl",
                                "station": ["id","code"],
                                "modifiedBy": ["id","friendlyName"],
                                "updated":"updatedDate"
                            }
                        }
                    }
                }
            }
        });
    },

    addSummary: (summary) => {
        summary.company = getCompanyId();
        summary.createdDate = moment().format();
        summary.updatedDate = summary.createdDate;
        return API.call({
            actions: {
                create: {
                    dailyDriverSummary:{
                        summary1: summary
                    }
                }
            }
        });
    },
    updateSummary: (summary) => {
        const ID = summary.id;
        delete summary.id;
        return API.call({
            actions: {
                update: {
                    dailyDriverSummary:{
                        summary1: {
                            findBy: {
                                id: ID
                            },
                            updateRecord: summary
                        }
                    }
                }
            }
        });
    },
    generateSummary: (params) => {
        return API.call({
            actions: {
                response: {
                    DailyDriverSummary:{
                        custom: {
                            functionName: "createDailySummaryNarrativeReport",
                            get: "dailySummary",
                            criteria: {
                                date : params['currentDate'],
                                station : params['currentStation'],
                                isNightlyReport : false
                            }
                        }
                    }
                }
            }
        });
    },
    dailySummaryDownload: (params) => {
        return API.call({
            actions: {
                response: {
                    DailyDriverSummary:{
                        custom: {
                            functionName: "dailySummaryDownload",
                            get: "pdf",
                            criteria: {
                                company: getCompanyId(),
                                date:params['currentDate'],
                                station:params['currentStation']
                            }
                        }
                    }
                }
            }
        });
    }
}

export default API;