import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Icon, Spacer, Theme, ThirdTitle} from "@dspworkplace/ui";
import SSkeletonPulse from "../Skeleton";
import heic2any from 'heic2any';

const Frag = styled.div`
    display:flex;
    align-items:center;
`;

const RegularThirdTitle = styled(ThirdTitle)`
    font-weight:normal;
    color:${Theme.colors.neutrals.gray};
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
`;

const JobTitle = styled.div`
    font-size:${props => props.big ? Theme.font.small.size : Theme.font.extraSmall.size};
    color:#707070;
    margin-top:${props => props.big ? '4px' : '0' };
`;

const PhotoWrapper = styled.div`
    box-sizing: border-box;
    width:${props => props.big ? '44px' : `${props.pictureSize + 4}px`};
    height:${props => props.big ? '44px' : `${props.pictureSize + 4}px`};
    border:1px solid ${Theme.colors.info.border};
    margin-right: 8px;
    background:${Theme.colors.info.bg};
    border-radius:100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const  People = ({name,jobTitle,img,big,style,isNew, loading, pictureSize = 24}) => {
    if(big) pictureSize = 36;

    if (loading)
        return (
            <div style={{display: 'flex'}}>
                <SSkeletonPulse height={28} width={28} style={{borderRadius: '50%'}}/>
                <Spacer left={2}>
                    <SSkeletonPulse height={10} width={80} style={{display: 'block'}}/>
                    <Spacer top={1}/>
                    <SSkeletonPulse height={12} width={100} style={{display: 'block'}}/>
                </Spacer>
            </div>
        )

    return (
        <Frag style={style}>
            <Photo pictureSize={pictureSize} img={img} color={Theme.colors.info.border} big={big} />
            <div>
                {big ? <ThirdTitle style={{fontSize:'22px'}}>{name}</ThirdTitle> : <RegularThirdTitle>{name}</RegularThirdTitle>}
                <JobTitle big={big}>{jobTitle}</JobTitle>
            </div>
            { isNew && isNew.toString() === 'true' && <Icon.Warning size='20px' color={Theme.colors.primary} style={{verticalAlign: 'middle', marginLeft: 8}}/>}
        </Frag>
    );
};

export default People;


export const Photo = ({pictureSize = 24, img, color = "#7C98B6" , big = false , style = {}}) => {
    const [imageUrl, setImageUrl] = useState("");
    const defaultIcon = <Icon.Person size={pictureSize + 'px'} color={color} />;

    const convertImageToPNG = async () => {
        try {
            await fetch(`${img}`)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob, quality: 0.01, toType: "image/jpeg" }))
                .then((conversionResult) => {
                    const url = URL.createObjectURL(conversionResult);
                    setImageUrl(url);
                })
                .catch(async (e) => {
                    setImageUrl(img);
                })
        } catch (error) {
            setImageUrl(img);
        }
    }

    useEffect(() => {
        if (img?.includes('.heic')) {
            convertImageToPNG()
        } else {
            setImageUrl(img)
        }
    }, [img])

    return (
        <PhotoWrapper big={big} style={style} pictureSize={pictureSize}>
            {!imageUrl ? defaultIcon : (
                <img
                    src={imageUrl}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '100%',
                        fontSize: "0.5em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center"
                    }}
                    alt={'profile'}
                    onError={e => setImageUrl(null)}
                />
            )}
        </PhotoWrapper>
    );
};
