import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useTrackedState } from "./store";
import { Channel } from "twilio-chat/lib/channel";
import ChannelIcon, { AvatarCustome, ContainerDot, getInitials } from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import { ReturnButton, Text, TextInformationContainer } from "./styles";
import { Button, Icon } from "@dspworkplace/ui";
import styled from "styled-components";
import { useDispatch as useClientDispatch } from "./store";
import Loading from "../Chat/Loading";
//@ts-ignore
import { setMessagesConsumed, updateActiveChannelClose } from "./utils";
import { IS_DEV } from "../utils";
import { engine, showErrorToast, showToast, getChatCompanyId, getChatUserId } from '../Utilities';
import {
    Avatar,
    TypingIndicator,
    useChannelStateContext,
    useComponentContext
} from "stream-chat-react";

const ChannelHeaderContainer = styled.div`
    height: 56px;
    max-height: 56px;
    display: grid;
    width: 100%;
    grid-template-columns:${props => (props.gridTemplate ? props.gridTemplate : "16px 40px auto 16px 16px")} ;
    grid-column-gap: 40px;
    grid-template-rows: 40px;
    margin-right: 20px;
    // margin-left: 20px;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    border-bottom: 1px dashed #7c98b6;
    background-color: #ffffff;
`;

const CancelButton = styled(Button)`
    width: ${props => (props.width ? props.width : "22px")};
    height: ${props => (props.height ? props.height : "22px")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071bc;
        border-radius: 50%;
    }
`;

function ChannelHeader(props) {
    const { title, setShowChannelDetail, setShowCreatePoll, setShowPoll,
        RightContent = () => <div style={{ height: 24, width: 24 }} />,
        MuteContent = () => <div style={{ height: 24, width: 24 }} /> } = props;
    const { channel, mutes } = useChannelStateContext();
    const state = useTrackedState();
    const dispatch = useDispatch();
    const { chatClient, showFilterForWords } = state;
    const { watcher_count } = channel.state || {};
    const { type, member_count } = channel.data || {};
    let image = channel?.data.image || "";
    let name = channel?.data.name || "";
    let userTitle = "";
    let status = false;

    if (channel.type == 'duo') {
        Object.keys(channel.state.members).map((member) => {
            if (member != chatClient.userID && member != 'bot') {
                name = channel.state.members[member].user.name;
                image = channel.state.members[member].user.image;
                status = channel.state.members[member].user.online;
                userTitle = channel.state.members[member].user.title;
            }
        });
    }

    useEffect(() => {
        name = channel?.data?.name || "";
        image = channel?.data?.image || "";
    }, [channel, channel?.data?.name, channel?.data?.image])

    return (
        <ChannelHeaderContainer gridTemplate={"16px auto 5px 45px"}>
            <div style={{ width: '40px', height: '40px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px', borderRadius: '50%', position: 'relative' }} >
                <ChannelIcon channel={channel} image={image} type={type} name={name} status={status} />
                {/* {image ?
                    <Avatar image={image} name={name} size={40} />
                    :
                    <AvatarCustome>
                        {getInitials(name).toUpperCase()}
                    </AvatarCustome>
                }
                <ContainerDot status={status} /> */}
            </div>
            <TextInformationContainer>
                <Text
                    height={'18px'}
                    width={'300px'}
                    color={'#000000'}
                    fontSize={name && name.length >= 22 ? '11px' : '14px'}
                    fontWeight="bold"
                >{name}</Text>
                <ChannelSubtitle channel={channel} type={type} userTitle={userTitle} />
            </TextInformationContainer>
            {channel?.type === 'group' && (
                <CancelButton
                    type="cancel"
                    onClick={() => {
                        setShowCreatePoll(true)
                        setShowPoll(false)
                        setShowChannelDetail(false)
                    }}
                    width="27px"
                    height="27px"
                >
                    <svg class="svg-icon" width={24} height={24} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M810.666667 128H213.333333c-47.146667 0-85.333333 38.186667-85.333333 85.333333v597.333334c0 47.146667 38.186667 85.333333 85.333333 85.333333h597.333334c47.146667 0 85.333333-38.186667 85.333333-85.333333V213.333333c0-47.146667-38.186667-85.333333-85.333333-85.333333zM384 725.333333h-85.333333V426.666667h85.333333v298.666666z m170.666667 0h-85.333334V298.666667h85.333334v426.666666z m170.666666 0h-85.333333v-170.666666h85.333333v170.666666z" /></svg>
                </CancelButton>
            )}
            <CancelButton
                type="cancel"
                onClick={() => {
                    setShowChannelDetail(true)
                    setShowCreatePoll(false)
                    setShowPoll(false)
                }}
                width="27px"
                height="27px"
            >
                <Icon.Cog size={"28px"} />
            </CancelButton>
        </ChannelHeaderContainer>
    );

    //@ts-ignore
    // const {channel, channelDispatch, kind = "NORMAL", goBack, goBackHandler} = props;
    // //@ts-ignore
    // const dispatch = useClientDispatch();
    // const mounted = useRef(false);
    // const [showSettings, setShowSettings] = useState(true);
    // const [loading, setLoading] = useState(true);
    // const [open, setOpen] = useState('default');

    // const updateChannelNotification = async () => {
    //     const channelName = channel?.friendlyName
    //     try {
    //         let response = await engine().post("/api/twilio/channel/notification", {
    //             companyId: getChatCompanyId(),
    //             userId: getChatUserId(),
    //             channelSid: channel?.sid,
    //             notificationLevel: open === 'default' ? 'muted' : 'default'
    //         });
    //         setOpen(open === 'default' ? 'muted' : 'default');
    //     await showToast({
    //         type: open === 'default' ? 'error' : 'success',
    //         title: open === 'default' ? `${channelName} Notification Have Been Silenced` : `${channelName} Notifications Enabled`,
    //         content: open === 'default' ? 'Group Notification Silenced' : 'Group Notifications Enabled'
    //     });
    //         return response.data;
    //     } catch (e) {
    //         await showErrorToast(e, 'Error updating channel notification');
    //         return e;
    //     }
    // }

    // useEffect(() => {
    //     if(channel && channel.notificationLevel) setOpen(channel.notificationLevel);
    //     if (mounted.current) return;

    //     if (channel && channel.friendlyName && channel.friendlyName.includes('workplace_bot')) {
    //         mounted.current = true;
    //         if (IS_DEV) {
    //             setShowSettings(true);
    //         } else {
    //             setShowSettings(false);
    //         }
    //         setLoading(false);
    //     } else {
    //         mounted.current = true;
    //         setLoading(false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // if (loading) {
    //     return <Loading containerHeight={"56px"} containerWidth={"300px"} imgHeight={"32px"}/>;
    // }

    // return (
    //     <ChannelHeaderContainer>
    //         {/* <CancelButton
    //             type="cancel"
    //             onClick={async () => {
    //                 if (goBack && goBackHandler) {

    //                     goBackHandler();
    //                 } else if (channel) {

    //                     setLoading(true);
    //                     await setMessagesConsumed(channel);
    //                     if (channel.attributes['kind'] !== 'Bot') {
    //                         await updateActiveChannelClose(channel);
    //                     }
    //                     dispatch({type: "CLOSE_ACTIVE_CHANNEL", channel: channel});
    //                 }
    //             }}
    //         >
    //             {!goBack && <Icon.Times size="16px" color="#7C98B6"/>}
    //             {goBack && <Icon.ArrowLeft size="16px" color="#7C98B6"/>}
    //         </CancelButton> */}
    //         {/* <ChannelIcon channel={channel!} kind={channel?.attributes['kind']}/> */}
    //         <TextInformationContainer>
    //             <ChannelTitle channel={channel!} kind={channel?.attributes['kind']}/>
    //             <ChannelSubtitle channel={channel!} kind={channel?.attributes['kind']}/>
    //         </TextInformationContainer>
    //         <CancelButton
    //             type="cancel"
    //             onClick={updateChannelNotification}
    //         >
    //             {open === 'default' ? (
    //                 <Icon.Volume size="16px" color="#7C98B6" />
    //             ) : (
    //                 <Icon.Mute size="16px" color="#7C98B6" />
    //             )}
    //         </CancelButton>
    //         {showSettings && (
    //             <CancelButton
    //                 type="cancel"
    //                 onClick={() => {
    //                     if (channelDispatch && kind === "NORMAL") {
    //                         channelDispatch({type: "setOpenChannelSettings", openChannelSettings: true});
    //                     } else if (channelDispatch && kind === "TASK") {
    //                         channelDispatch({type: "SHOW_SUMMARY_MODAL"});
    //                     }
    //                 }}
    //             >
    //                 <Icon.Cog size="16px" color="#7C98B6"/>
    //             </CancelButton>
    //         )}
    //     </ChannelHeaderContainer>
    // );
}

export default ChannelHeader;