import React from 'react';
import {ScannerType} from "./store";
import {useForm} from "react-hook-form";
import {Form, TextArea, ManageDisabledCheckBox} from "./Item";
import {Button} from '@dspworkplace/ui';

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: ScannerType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function ScannerQuestionForm(props: Props) {
    const {item, updateItem} = props;
    const {name, description, isPassRequired, length, type, notifyIfSuccess, notifyIfFailure, messageIfFailure, disabledQuestion} = item.content;
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            length,
            type,
            notifyIfSuccess,
            notifyIfFailure,
            messageIfFailure,
            disabledQuestion
        },
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            <label htmlFor="type">Type</label>
            <input name="type" ref={register} disabled={true}/>
            <label htmlFor="length">VIN characters length</label>
            <input name="length" defaultValue={17} ref={register} disabled={true}/>
            <label>Is required to Pass?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {/*<label>Notify if scan is successful?</label>*/}
            {/*<input name="notifyIfSuccess" type="checkbox" className={'checkbox'} ref={register}/>*/}
            {/*<label>Notify if scan has failed?</label>*/}
            {/*<input name="notifyIfFailure" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <label htmlFor="messageIfFailure">Message for Scan Failure</label>
            <input name="messageIfFailure" ref={register}/>
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default ScannerQuestionForm;