import {useState, useCallback} from "react";
import * as React from "react";

export const STATUS = {
    PENDING: 'pending',
    READY: 'ready',
    FILTERING: 'filtering',
    SORTING: 'sorting',
    LOADING_ROW: 'loading_row'
}

export type statusAliases = typeof STATUS.PENDING | typeof STATUS.READY | typeof STATUS.FILTERING | typeof STATUS.SORTING

type statusInterface = statusAliases

export type paginatorInterface = {
    page: number
    limit: number
}

interface apiBaseInterface {
    status: statusAliases
    setStatus: React.Dispatch<React.SetStateAction<string>>
    pageInfo: paginatorInterface
    setPageInfo: (page: number, limit: number) => void
    sorter: string
    setSorter: (any) => void
    filters: string
    setFilters: React.Dispatch<React.SetStateAction<{}>>
    groupByFilter: string
    setGroupByFilter: (any) => void
}

export interface apiBaseDefaultPropsInterface {
    sorter?: string[]
    filter?: string
    groupByFilter?: string[]
}

const useApiV2Base = (props: apiBaseDefaultPropsInterface): apiBaseInterface => {
    const [status, setStatus] = useState<statusInterface>(STATUS.PENDING);
    const [paginator, setPaginator] = useState<paginatorInterface>({page:0,limit:10})
    const [sorter, setSort] = useState<string>(props?.sorter ? props.sorter.join('|') : '');
    const [filters, setFiltersState] = useState<string>(props?.filter ? props.filter : '');
    const [groupByFilter, setGroupsBy] = useState<string>(props?.groupByFilter ? props.groupByFilter.join('|') : '');
    
    const setPageInfo = useCallback((page: number,limit: number)=>{
        setPaginator(currentPageInfo => {
            if(page) currentPageInfo.page = page
            if(limit) currentPageInfo.limit = limit
            return currentPageInfo
        })
    },[])

    const setSorter = useCallback((sorter)=>{
        if(sorter.fields.length < 1) setSort('')
        setSort(()=>{
            const values =  sorter.fields.reduce((acc, obj)=>{
                return acc+`${obj.columnKey}:${obj.direction}|`
            },'')
            return values.slice(0,-1);
        })
    }, [])
    
    const setGroupByFilter = useCallback((groupByFilter)=>{
        if(groupByFilter.fields.length < 1) setGroupsBy('')
        setGroupsBy(()=>{
            const values =  groupByFilter.fields.reduce((acc, obj)=>{
                return acc+`${obj.columnKey}:${obj.direction}|`
            },'')
            return values.slice(0,-1);
        })
    },[])

    const setFilters = useCallback((filters: {})=>{
        const fieldName = Object.keys(filters)
        const queryFilter = fieldName.reduce((acc,field) => {
            let fieldValue = filters[field]
            if(Array.isArray(fieldValue)) {
                fieldValue = fieldValue.join()
            }else if(typeof fieldValue === 'object') fieldValue = fieldValue.value
            if(fieldValue !== null) acc += `${field}=${fieldValue}&`
            return acc
        },'')
        setFiltersState(queryFilter.slice(0,-1))
    },[])

    return {
        status,
        setStatus,
        pageInfo: paginator,
        setPageInfo,
        sorter,
        setSorter,
        filters,
        setFilters,
        groupByFilter,
        setGroupByFilter
    }
}

export default useApiV2Base