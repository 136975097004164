import {STATUS} from './helper';
import FilterServerResolver from "../../../../components/Filter/filterResolver.server";


const matchingConfig = {
    station:'number',
    segments:'in',
    title:'like',
    date:'date'
}

export const initialState = (options) => {
    let matchingObj = {};
    if(options.matching){
        matchingObj = new FilterServerResolver(options.matching,matchingConfig);
    }

    return {
        matching:matchingObj,
        filterLookups: options.filterLookups || [],
        status:'pending',
        data: []
    }
}

export const reducer = (state,action) =>{
    const {payload} = action;
    switch (action.type){
        case 'loading':
            return {...state,status:payload.loading}
        case 'addData':
            state.data = payload.data;
            if(payload.filterOptions){
                const filter = payload.filterOptions;
                if(filter.segments.length){
                    state.filterLookups['segments'] = filter.segments;
                }
            }
            state.status = STATUS.READY;
            return {...state};
        case 'addMessage':
            state.data.push(payload);
            return {...state};
        case 'editMessage':
            const messageUpdateIndex = state.data.findIndex((m)=> m.id === payload.id);

            if(messageUpdateIndex >= 0){
                payload.frequency = state.data[messageUpdateIndex].frequency;
                state.data[messageUpdateIndex] = payload;
            }
        return {...state};
        case 'removeMessage':
            const messageIndex = state.data.findIndex((m)=> m.id === payload.id);
            if(messageIndex >= 0){
                state.data.splice(messageIndex,1);
            }
            return {...state};
        case 'addMetricVideo':
            state.metricVideos = payload.data;
            state.status = STATUS.READY;
            return {...state};    
        case 'addMatching':
            const matchingObj = new FilterServerResolver(payload,matchingConfig);
            return {...state,matching:matchingObj,status:STATUS.FILTERING};
        default:
            return {...state};
    }
}