import React from "react";
import CKEditor from "react-ckeditor-component-4";

const CKeditorComponent = (props) => {
    const { content, onChange } = props;
    const config = {
        width: "840px",
        height: "500px",
        versionCheck: false,
        toolbar: [
            {
                name: "basicstyles",
                items: [
                    "Undo",
                    "Redo",
                    "-",
                    "Bold",
                    "Italic",
                    "Underline",
                    "Strike",
                    "-",
                    "Cut",
                    "Copy",
                    "-",
                    "JustifyLeft",
                    "JustifyCenter",
                    "JustifyRight",
                    "JustifyBlock",
                    "-",
                    "Subscript",
                    "Superscript",
                    "-",
                    "RemoveFormat",
                ],
            },
            { name: "colors", items: ["TextColor", "BGColor"] },
            "/",
            { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
            {
                name: "paragraph",
                items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent"],
            },
            { name: "editing", items: ["Find", "Replace", "-", "SelectAll"] },
            { name: "links", items: ["Link", "Unlink"] },
            { name: "insert", items: ["Smiley", "SpecialChar"] },
            { name: "tools", items: ["Maximize", "Preview"] },
        ],
    };

    return (
        <>
            <CKEditor
                activeClass="p10"
                isScriptLoaded={false}
                scriptUrl={"../../ckeditor/ckeditor.js"}
                config={config}
                content={content}
                events={{
                    change: onChange,
                }}
            />
        </>
    );
};

/** CKeditor Modal View Component */
export const CKeditorModalComponent = (props) => {
    const { content, onChange, readOnly = false } = props;
    const config = {
        width: "405px",
        height: "400px",
        versionCheck: false,
        toolbar: [
            {
                name: "basicstyles",
                items: [
                    "Undo",
                    "Redo",
                    "-",
                    "Bold",
                    "Italic",
                    "Underline",
                    "Strike",
                    "-",
                    "Cut",
                    "Copy",
                    "-",
                    "JustifyLeft",
                    "JustifyCenter",
                    "JustifyRight",
                    "JustifyBlock",
                    "-",
                    "RemoveFormat",
                ],
            },
            "/",
            { name: "colors", items: ["Subscript", "Superscript", "-", "TextColor", "BGColor"] },
            {
                name: "paragraph",
                items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent"],
            },
            { name: "editing", items: ["Find", "Replace", "-", "SelectAll"] },
            "/",
            { name: "links", items: ["Link", "Unlink"] },
            { name: "insert", items: ["Smiley", "SpecialChar"] },
            { name: "tools", items: ["Maximize", "Preview"] },
            "/",
            { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
        ],
        readOnly: readOnly,
    };

    return (
        <>
            <CKEditor
                activeClass="p10"
                isScriptLoaded={false}
                scriptUrl={"../../ckeditor/ckeditor.js"}
                config={config}
                content={content}
                events={{
                    change: onChange,
                }}
            />
        </>
    );
};
export default CKeditorComponent;
