import React, {useEffect,useCallback, useContext, useState} from 'react';
import {Button, Spacer, Text, Toggle} from "@dspworkplace/ui";
import styled from 'styled-components';
import DefaultStore from '../RouteCommitment/ContextStore';
import WeekPicker from '../../../components/Calendar/week.js';
import Moment from "moment";
import {useAuth} from "../../../Auth";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import {FilterWeekDate} from "../../../hooks/useGlobalData";
import { AccessCheck, IsGranted } from "../../../security";

const Filter = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
`;

const FitlerApp = () => {
    const [state,dispatch] = useContext(DefaultStore);
    const { api } = useAuth();
    const [selectedStation] = useSelectedStation();
    const [selected, setSelectedStation] = useState(selectedStation)
    const [date,setDate] = FilterWeekDate();
    const [isStationLoad,setIsStationLoad] = useState(false);

    const makeFilter = useCallback(async(value, selected) => {
        let week = Moment(value).isoWeek();
        if(Moment(value).day() == 0){
          value = Moment(value, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
          week = Moment(value).isoWeek();
        }
        let year = parseInt(Moment(value).startOf("week").format("YYYY"));
        if(Moment(value).startOf("week").format('YYYY') != Moment(value).endOf("week").format('YYYY') && week == 1 && year == Moment(value).startOf("week").format('YYYY')) {
            year = parseInt(Moment(value).endOf("week").format('YYYY'));
        }
        //use value variable to make filter in database
        dispatch({type:'LOADING',payload: true});
        const params={
            "actions": {
                "response": {
                    "Station": {
                        "findOneBy": {
                            "criteria": {
                                "id":selected
                            },
                            "get": "Station",
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "stationAddress",
                                "3": "stationGeofence",
                                "4": "parkingLotAddress",
                                "5": "parkingLotGeofence",
                                "6": "code",
                                "7": "timezone"
                            },
                            "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                        },
                    },
                    "RouteCommitment": {
                        "custom": {
                            "functionName": "getRouteCommitments",
                            "criteria": {
                                "company": localStorage.getItem('company'),
                                "station":selected,
                                "week":week,
                                "year":year
                            },
                            "get":"RouteCommitments",
                            "excludes": [ "shiftType","company","station"],
                        }
                    },
                    "BalanceGroup": {
                        // "custom": {
                        //     "functionName": "getShiftBalanceGroup",
                        //     "get": "balanceGroups",
                        //     "criteria": {
                        //         "company":localStorage.getItem('company'),
                        //         "station":selected
                        //     },
                        //     "excludes": ["company","shift"],
                        //     "includes": {
                        //         "0": "id",
                        //         "1": "name",
                        //         "station":["id"]
                        //     }
                        // }
                        findBy: {
                            get: "balanceGroups",
                            criteria: {
                                station: selected,
                                isArchive: false
                            },
                            includes: {
                                0: "id",
                                1: "name",
                                "station": ["id"]
                            }
                        }
                    }
                }
            }
        }
        try{
            let response = await api.post('/api/lazy/manage/data', params);
            let d = response.data.data.RouteCommitments.current;

            if ( selected ) {
                let ss = Array.isArray(selected) ? selected[0] : selected;
                if ( ss != '-1' )
                    d = d.filter(i => i.station.toString() === ss.toString() );
            }

            await dispatch({type:'ADD',payload:d,week:week,year:year})
            await dispatch({type:'ADD_TEMP_DATA',tempData:response.data.data.RouteCommitments.current})

            await dispatch({type:'ADD_PREVIOUS',previousData:response.data.data.RouteCommitments.previous})
            await dispatch({type:'ADD_GROUP_OBJ',balanceGroupObj:response.data.data.balanceGroups})
            let balanceGroupArr=[];
            Object.keys(response.data.data.RouteCommitments.current).forEach(function(index) {
                balanceGroupArr.push(response.data.data.RouteCommitments.current[index].id)
            });
            let temp=[];
            let skillObj=[];
            Object.keys(response.data.data.balanceGroups).forEach(function(index) {
                if(balanceGroupArr.indexOf(response.data.data.balanceGroups[index].id) == -1 ) {
                    temp = {
                        'name': response.data.data.balanceGroups[index].name.toString(),
                        'value': response.data.data.balanceGroups[index].id.toString(),
                    }
                    skillObj.push(temp);
                }
            });
            dispatch({type:'ADD_BALANCE_GROUP',balanceGroup:skillObj})
            dispatch({type:'ADD_STATION',station:response.data.data.Station})
        }catch(error){
            dispatch({type:'default'});
        }
    });

    useEffect(()=>{
       if(selectedStation !== undefined){
            makeFilter(date, selectedStation);
       }
    },[date,selectedStation]);

    const makeStationFilter = useCallback(async(value) => {
        let results = [];
        let len = state.tempData.length;
        setSelectedStation(value);
        for(let i = 0; i < len; i++){
            if(state.tempData[i].station.toString() === value.toString()){
              results.push(state.tempData[i]);
            }
        }

        if(value == '-1'){
            dispatch({type:'ADD',payload:state.tempData,week:state.week,tempData:state.tempData,year:state.year})
        } else {
            dispatch({type:'ADD',payload:results,week:state.week,tempData:state.tempData,year:state.year})
        }
    });

    const copy = useCallback(()=>{
        dispatch({type:'LOADING',payload:true})
        dispatch({type:'COPY'})
        //make api call here and then change dispatch on line 56 to ADD action
        dispatch({type:'LOADING',payload:false})
    })

    return (
        <Filter>
            <div>

                <StationSelector
                    onChange={e => makeStationFilter(e)}
                />
                <Spacer inline={true} left={4}>
                    <Text>Week #</Text>
                    <Spacer top={2} />
                    <WeekPicker date={date} onChange={(currDate)=>{setDate(Moment(currDate).format('YYYY-MM-DD'))}} />
                </Spacer>

                <Spacer inline={true} left={4}>
                    <Spacer top={8} />
                    <Toggle
                        name='toogle'
                        on={false}
                        singleText='Group By Balance Group'
                        onChange={(e)=>{dispatch({type:'GROUP',payload:e.target.checked})}}
                    />
                </Spacer>
            </div>
            <IsGranted feature={AccessCheck.features.ROUTE_COMMITMENT} ops={AccessCheck.OPS.C}>
            { state.previousData.length > 0 && state.data.length ===0  ? <Button onClick={copy}>Copy Previous Week</Button> : <Button style={{opacity:'0.5'}} > Copy Previous Week </Button> }
            </IsGranted>
        </Filter>
    )
}

export default FitlerApp;
