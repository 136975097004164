import React, {useCallback, useContext, useEffect} from 'react';
import { Theme, Toggle,  Spacer, Text,  Dropdown, Button, ThirdTitle, Icon } from "@dspworkplace/ui";
import styled from 'styled-components';
import DefaultStore from './ContextStore';
import WeekPicker from '../../../components/Calendar/week.js';
import Moment from "moment";
import {useAuth, useLocalStorage} from "../../../Auth";
import StationSelector from "../../../components/StationSelector";
import Tooltip from "../../../components/Tooltip";
import {Label} from "../../../components/UI";

import {FilterWeekDate} from "../../../hooks/useGlobalData";

const Filter = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
`;

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const FilterApp = () => {
    const [state,dispatch] = useContext(DefaultStore);
    const { api } = useAuth();
    const [date,setDate] = FilterWeekDate();

    const makeWeek = useCallback(async(value) => {
      setDate(Moment(value).format('YYYY-MM-DD'))
    });
    const makeFilter = useCallback(async(value) => {
      dispatch({type:'FILTER',payload:{'station':value}})
      // dispatch({type:'LOADING',payload: true});
     });

    useEffect(()=>{
        dispatch({type:'LOADING',payload: true});
    },[date])

    return (
            <Filter>
                <div>
                    <StationSelector
                        onChange={e => makeFilter(e)}
                    />
                    <Spacer inline={true} left={5}>
                        <Label>Week #</Label>
                        <WeekPicker date={date} onChange={(e)=>{makeWeek(e)}} />
                    </Spacer>

                    <Spacer inline={true} left={5}>
                        <Spacer top={8} />
                        <Toggle
                            name='toogle'
                            on={true}
                            singleText={
                                <>
                                    Group Irregular Schedules
                                    <Spacer inline left={2}>
                                        <Tooltip
                                            verticalPosition={'center'}
                                            horizontalPosition={'right'}
                                            content={<Text>Irregular schedules are<br/> those with 5 or less drivers</Text>}
                                        >
                                            <Question>?</Question>
                                        </Tooltip>
                                    </Spacer>
                                </>
                            }
                            onChange={(e)=>{dispatch({type:'GROUP_DATA',payload:e.target.checked})}}
                        />
                    </Spacer>
                </div>
            </Filter>
    )
}

export default FilterApp;
