import React from 'react';
import styled from "styled-components";
import {Theme, ThirdTitle,Icon,Spacer} from "@dspworkplace/ui";
import { useCubeQuery } from "@cubejs-client/react";
import Loading from "../../../components/Loading";
import ChartRender,{openTableRender} from '../components/chartRender';
import {openChartDeleteConfirm, openCopyChart, openEditChart} from '../form';
import {alert} from '../../../components/Alert';
import API from '../api';
import { fixWeekCondition } from '../fixWeekCondition';
import withCubeData from './withCubeData';
import { useDrag } from 'react-dnd'
import DashboardScorecardFilter from './dashboardScorecardFilter';

const Card = styled.div`
    display: block;
    box-sizing: border-box;
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0 3px 6px #33333326;
    width: 100%;
    height: 100%;
    border-radius: ${Theme.defaultRadius};
    overflow:hidden;

    .icon-action {
        &:hover {
            > path {
                fill: ${Theme.colors.info.text};
            }
        }
    }
`;

const ChartContainer = styled.div`
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:8px;
    ${props => props?.type === 'table' && `
        align-items:flex-start;    
        overflow-y:scroll;
        overflow-x:hidden;
    `}   
`;

const defaultIconProps = {
    size: '18px',
    style: {cursor: 'pointer'}
};

const alignStyle = {display: 'flex',justifyContent: 'space-between',alignItems: 'center'};

const DashboardItem = ({name, item, i, panelId, showTools, onEditChart, onDeleteChart,chartLayout,
    dispatch, attendanceSetting, api, hideFilterBtn }) => {

    const [{ isDragging }, drag] = useDrag({
        item: { type: "WidgetType", name },
          collect: (monitor) => ({
            isDragging: monitor.isDragging(),
          })
      })
    const queryWeek = fixWeekCondition(item.chartQuery);
    const realtime = item?.chartConfig?.realtime;
    const { resultSet, isLoading=true, error } = useCubeQuery(queryWeek);

    const schema = item?.chartConfig.schema;
    const query = item.chartQuery;
    const isDashboardFilter = (schema === 'NetradyneEOC') ? true : false;
    const isScorecardFilter = item?.isScorecardAMZ;
    const [ isHeaderFilter, setIsHeaderFilter] = React.useState(false);

    React.useEffect(()=>{
        setIsHeaderFilter(true);
    },[isHeaderFilter])

    if (!schema) {
        return <div>No schema found</div>;
    }

    const handleEditChart = (e)=>{
        const { id, chartConfig } = item;
        e.stopPropagation()
        openEditChart({ item,schema,query,chartConfig }).then(resolve => {
            if(resolve){
                onEditChart(panelId, id, resolve,chartLayout);
            }
        })
    };

    const handleOpenDetails = (e) => {
        e.stopPropagation();
        openTableRender({
            item, resultSet, chartQuery: queryWeek,
            attendanceSetting, tabIndex: 2, isDashboardFilter,
            isScorecardFilter, api, isHeaderFilter, setIsHeaderFilter
        })
    };

    const handleCopy = (e) => {
        e.stopPropagation();
        openCopyChart({name:item.title}).then(
            resolve => {
                if (resolve){
                    const newItem = {...item};
                    newItem.title = resolve;
                    newItem.id = newItem.title + Math.floor(Math.random() * 100);
                    newItem.isTemp = true
                    const tempId = newItem.id;
                    dispatch({ type: 'addChartIntoPanel',payload: {chart:newItem, panelId }});

                    delete newItem.isTemp
                    delete newItem.id

                    newItem.panel = panelId;

                    API.addChart(newItem).then(
                        resolve => {
                            if(resolve){
                                newItem.id = resolve.data.data.lastInserted.id
                                dispatch({ type: 'updateTempChartInPanel',payload: { tempId, chart: newItem, panelId }});
                            }
                        }
                    );
                }
            }
        )
    };

    return (
        <Card>
            <div style={{
                ...alignStyle,
                borderBottom: '1px solid #e8e8e8',
                background: '#f7f7f7',
                paddingLeft: 4,
                cursor: "move"
            }}
                ref={drag}
            >
                <ThirdTitle
                    style={{ padding: 8 }}
                >
                    {item.title}
                </ThirdTitle>
                <div style={{ ...alignStyle, paddingRight: 4 }}>
                    {showTools &&
                        <>
                            <Spacer right={1} top={1} onMouseDown={handleOpenDetails}>
                                <Icon.List
                                    {...defaultIconProps}
                                />
                            </Spacer>
                            <Spacer right={1} top={1} onMouseDown={handleCopy}>
                                <Icon.Copy
                                    {...defaultIconProps}
                                />
                            </Spacer>
                            <Icon.Edit
                                {...defaultIconProps}
                                onMouseDown={handleEditChart}
                            />
                            <Spacer left={1} top={1} onMouseDown={(e) => {
                                e.stopPropagation()
                                openChartDeleteConfirm(item.title).then(async (resolve) => {
                                    if (resolve) {
                                        try {
                                            await onDeleteChart(panelId, item.id, item.title);
                                        } catch {
                                            alert({
                                                icon: true,
                                                text: `There was some error trying to delete ${item.title}`
                                            });
                                        }
                                    }
                                })
                            }}>
                                <Icon.Times
                                    {...defaultIconProps}
                                    size={'20px'}
                                    color={Theme.colors.error.text}
                                />
                            </Spacer>
                        </>
                    }
                </div>
            </div>
            <ChartContainer type={item?.chartConfig?.type} className='drag'>
                {error
                    ?
                    <ThirdTitle>No data Found</ThirdTitle>
                    :
                    (resultSet && <DashContent
                        isLoading={isLoading}
                        isTemp={item.isTemp}
                        cardIndex={i}
                        chartConfig={item.chartConfig}
                        resultSet={resultSet}
                        attendanceSetting={attendanceSetting}
                        item={item}
                        query={query}
                        isDashboardFilter={isDashboardFilter}
                        isScorecardFilter={hideFilterBtn ? false : isScorecardFilter}
                        schema={schema}
                        api={api}
                        isHeaderFilter={isHeaderFilter}
                        setIsHeaderFilter={setIsHeaderFilter}
                    />)
                }

            </ChartContainer>
        </Card>
    )
}


const DashContent = withCubeData(({ isLoading, isTemp, cardIndex, chartConfig, resultSet, attendanceSetting, item, panelId,
    chartLayout, dispatch, onEditChart, query, isDashboardFilter,
    isScorecardFilter, availableMeasures, updateQuery, filterDashboardItemData, schema, api,
    isHeaderFilter, setIsHeaderFilter, ...cubeData }) => {
    if (isLoading || isTemp) {
        return <Loading style={{ height: 50, display: 'block' }} />;
    }

    const { pivotConfig, ...config } = chartConfig;
    const isFilter = isDashboardFilter || isScorecardFilter

    return <>
        <div style={{ height: "100%", width: "100%" }}>
            {isScorecardFilter &&
                <DashboardScorecardFilter
                    availableMeasures={availableMeasures}
                    item={item}
                    updateQuery={updateQuery}
                    schema={schema}
                    resultSet={resultSet}
                    api={api}
                    setIsHeaderFilter={setIsHeaderFilter}
                    isHeaderFilter={isHeaderFilter}
                />
            }
            <ChartRender
                cardConfigIndex={cardIndex}
                chartConfigData={config}
                pivotConfig={pivotConfig}
                resultSet={isFilter ? (filterDashboardItemData || resultSet) : resultSet}
                forceUpdate={false}
                attendanceSetting={attendanceSetting}
                item={item}
                columnOrder={{
                    tableOrder: pivotConfig?.tableOrder,
                    detailTableOrder: pivotConfig?.detailTableOrder
                }}
                onEditChart={onEditChart}
                query={query}
                updateQuery={updateQuery}
                isDashboardFilter={isDashboardFilter}
                showTimeDimension={{
                    basicTimeDimension: pivotConfig?.basicTimeDimension,
                    detailTimeDimension: pivotConfig?.detailTimeDimension
                }}
                thresholds={pivotConfig?.thresholds || []}
                isScorecardFilter={isScorecardFilter}
                isHeaderFilter={isHeaderFilter}
                {...cubeData}
            />
        </div>
    </>
})
export default DashboardItem;