import React from 'react';
import styled from 'styled-components';
import {Theme} from '@dspworkplace/ui';

const TagOption = styled.div`
   display:inline-flex;
   box-sizing:border-box;
   border:1px solid ${Theme.colors.info.border};
   background:${Theme.colors.info.bg};
   border-radius:2px;
   padding:0 8px;
   height:24px;
   align-items: center;
   
   color:${Theme.colors.info.text};
   font-size:12px;
   font-weight:600;

   &.person {
        padding:0 4px;
        font-size: 10px;
   } 
   
   &.skills {
        padding:0 4px;
        font-size: 10px;
   }
   
   &.stations {
        padding:0 4px;
        font-size: 8px;
        color: #0071BC;
   }  
   
   &.schedules {
        padding:0 4px;
        font-size: 8px;
        background-color: transparent;
        width: max-content;
   }      
`;

const Frag = styled.div`
    display:inline-block;
    vertical-align:middle;
    
    >div {
        margin-right:4px;
        margin-bottom:4px;
        &:nth-child(n+4){
           
        }
    }
`;

const Tag = ({options,...props}) => {

    if(props.show){
        return(
            <Frag>
                {Array.isArray(options) && options.map((item,key)=>(
                    <TagOption key={key} className={props.className}>{item}</TagOption>
                ))}
            </Frag>
        );
    }

    if(!options || options.length < 1) return null;

    return(
        <Frag>
            {options.map((item,key)=>(
                <TagOption key={key} className={props.className}>{item}</TagOption>
            ))}
        </Frag>
    );
}


export default Tag;
