import React, { useState, useEffect } from "react";
import { portalPromise } from "../../../../../../components/Dialog";
import { Button } from "@dspworkplace/ui";
import { ScrollyOverlayStyle, ScrollyModalStyle } from "../../../../../../components/Modal";
import { useForm } from "react-hook-form";
import { SubTitle, Spacer, RadioBox, Input, Dropdown } from "@dspworkplace/ui";
import { API } from "../../apiHandler";
import { getEnableAutomatedScorecardCoachingMetric } from "../../../../../../Utilities";

const UploadForm = ({ success = () => {}, cancel = () => {}, showModalSection }) => {
    const enableAutomatedScorecardCoaching = JSON.parse(getEnableAutomatedScorecardCoachingMetric());
    const [uploadType, setUploadType] = useState(1);
    const [videoOptions, setVideoOptions] = useState([]);
    const { register, handleSubmit, errors, watch, getValues } = useForm({
        defaultValues: {},
    });
    const type = watch("uploadType");

    useEffect(() => {
        const params = {
            actions: {
                response: {
                    MetricVideo: {
                        custom: {
                            functionName: "getMetricVideo",
                            get: "getMetricVideo",
                            excludes: {},
                            includes: {},
                            criteria: {},
                        },
                    },
                },
            },
        };
        API.call(params).then(
            (response) => {
                if (response.data.data.getMetricVideo) {
                    let metricVideoOptions = [];
                    metricVideoOptions = response.data.data.getMetricVideo.map((m) => ({
                        name: m.metric,
                        value: m.metricKey,
                    }));
                    setVideoOptions(metricVideoOptions);
                }
            },
            (error) => console.log(error)
        );
    }, []);

    useEffect(() => {
        setUploadType(type);
    }, [type]);

    const onSubmit = async (data) => {
        let payload = {};
        if (data?.videoId && data?.videoId != undefined) {
            payload = { type: "metricVideo", value: data?.videoId };
        } else if (data?.videoLink && data?.videoLink != undefined) {
            payload = { type: "url", value: data?.videoLink };
        }
        // Check Valid Source
        if (payload?.type === "url" && isValidVideoURL(payload?.value)) {
            success(payload);
        } else if (payload?.type === "metricVideo") {
            success(payload);
        }
    };

    const isValidVideoURL = (url) => {
        // Regular expression for basic URL validation
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator

        // Common video file extensions
        const videoExtensions = [".mp4", ".avi", ".mov", ".mkv", ".flv", ".webm", ".wmv"];

        try {
            // Check if URL pattern matches
            const isUrl = !!urlPattern.test(url);

            // Check if URL ends with a valid video extension
            const hasVideoExtension = videoExtensions.some((extension) => url.endsWith(extension));

            return isUrl || hasVideoExtension;
        } catch (e) {
            console.error("Invalid URL:", e);
            return false;
        }
    };

    return (
        <ScrollyOverlayStyle zIndex={99}>
            <ScrollyModalStyle width={"440px"} onClick={(e) => e.stopPropagation()}>
                <header>
                    <SubTitle>
                        {enableAutomatedScorecardCoaching && showModalSection == "Announcements"
                            ? "Add Video"
                            : "Paste the video link -"}
                    </SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {enableAutomatedScorecardCoaching && showModalSection == "Announcements" ? (
                            <>
                                <RadioBox
                                    ref={register()}
                                    name="uploadType"
                                    options={[
                                        { label: "Paste the video link ", value: 1 },
                                        { label: "Select Training Video", value: 2 },
                                    ]}
                                    default={uploadType}
                                />
                                <Spacer bottom={5} />
                                {type == "1" && (
                                    <Input
                                        ref={register({ required: "Paste video link..!" })}
                                        name="videoLink"
                                        size="large"
                                        error={errors.videoLink && errors.videoLink.message}
                                    />
                                )}
                                {type == "2" && (
                                    <Dropdown
                                        ref={register({ required: "Select video link..!" })}
                                        name="videoId"
                                        placeholder="Select Video"
                                        options={videoOptions}
                                        size={"large"}
                                        visibleOptionsQty={5}
                                        error={errors.videoId && errors.videoId.message}
                                        valid={!errors.videoId && getValues().videoId > 0}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <Input
                                    ref={register({ required: "Paste video link..!" })}
                                    name="videoLink"
                                    size="large"
                                    error={errors.videoLink && errors.videoLink.message}
                                />
                                <Spacer bottom={5} />
                            </>
                        )}
                        <Spacer bottom={3} />
                        <Button type="primary" style={{ display: "block", marginLeft: "auto" }}>
                            Save
                        </Button>{" "}
                    </form>
                </div>
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    );
};

export const openTrainingVideoModel = (showModalSection) => {
    return portalPromise((success, cancel) => {
        return <UploadForm success={success} cancel={cancel} showModalSection={showModalSection} />;
    });
};
