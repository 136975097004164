import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Icon, Spacer, async } from "@dspworkplace/ui";
import { filterCubeData } from '../../../pages/Dashboard/form/EditChartForm/index'
import { useForm } from "react-hook-form";
import { fetchDrivers } from "../../Employees/api";

const DashboardScorecardFilter = ({ availableMeasures, item, updateQuery, schema, resultSet,
    api, setIsHeaderFilter, tabIndex }) => {
    const [driverList, setDriverList] = useState(null)
    const [driver, setDriver] = useState('')
    const [timeDim, setTimeDim] = useState('')
    const [metric, setMetric] = useState('')

    const stationSelected = () =>{
        const { filters } = resultSet?.loadResponse?.pivotQuery || {};
        const stations = JSON.parse(localStorage.getItem("stations"));
        const filterValue = filters[0]?.values[0];
        const matchingStation = stations?.find(station => station.code === filterValue);
        return matchingStation?.id;
    }

    const stationId = stationSelected() || localStorage.getItem("stationId");

    const methods = useForm({
        mode: "onBlur",
    });
    const { register, getValues, setValue } = methods;

    useEffect(() => {
        setValue("Associate", "all");
        setValue("TimeFrame", "0");
        setValue("Metric", "all");
    }, []);

    const measuresList = useMemo(() => {
        return filterCubeData(availableMeasures, schema);
    }, [availableMeasures]);

    const measureData = measuresList?.map((e) => {
        return { name: e.name, value: e.value.name }
    })
    measureData && measureData.unshift({ name: 'All', value: 'all' });

    useEffect(() => {
        (async () => {
            const result = await fetchDrivers(api, stationId);
            setDriverList(result?.drivers?.driver)
        })()
    }, []);

    const driverData = driverList?.map((e) => {
        return { name: e.name, value: e.id }
    })
    driverData && driverData.unshift({ name: 'All', value: 'all' });

    const timeFilter = [
        { name: 'All Time', value: '0' },
        { name: 'Last year', value: 'Last year' },
        { name: 'Last quarter', value: 'Last quarter' },
        { name: 'Last 6 weeks', value: 'Last 6 weeks' },
        { name: 'Last 30 days', value: 'Last 30 days' }
    ]

    const driverValue = (filters, driverVal) => {
        if (!driverVal) return filters;
        let filterValue = [...filters];
        let found = false;
        for (let i = 0; i < filterValue.length; i++) {
            if (filterValue[i].member === "AMZWeekly.name") {
                filterValue[i].values = [driverVal];
                filterValue[i].operator = "equals";
                found = true;
                break;
            }
        }
        if (!found) {
            filterValue.push({
                member: "AMZWeekly.name",
                values: [driverVal],
                operator: "equals"
            });
        }
        return filterValue;
    }

    const measureValue = (metricVal, measures) => {
        if (!metricVal || metricVal === "All") return measures;
        return measuresList.filter(measure => measure.name === metricVal).map(measure => measure.value.name);
    }

    const timeDimValue = (timeDimensions, timeDimVal) => {  
        if (!timeDimVal || timeDimVal === "All Time") return timeDimensions;
        const updatedTimeDimensions = timeDimensions.length > 0 ? timeDimensions.map(item => {
            if (item.dimension === 'AMZWeekly.date') {
                return { ...item, dateRange: timeDimVal };
            }
            return item;
        }) : [{ "dimension": "AMZWeekly.date", "granularity": "day", dateRange: timeDimVal }]
        return updatedTimeDimensions;
    }

    const handleClick = (name, label) => {
        setIsHeaderFilter(true)
        const driverVal = (label === 'Associate') ? name : driver;
        const timeDimVal = (label === 'TimeFrame') ? name : timeDim;
        const metricVal = (label === 'Metric') ? name : metric;
        if (name === "All" || name === "All Time") { return handleReset(label,false,driverVal,timeDimVal,metricVal) };
        const { dimensions, measures, order, timeDimensions, filters } = resultSet?.loadResponse?.pivotQuery || {};
        const orderValue = order?.map(item => [item.id, item.desc === true ? 'desc' : 'asc']);
        const measuresValue = measures && measures ? measureValue(metricVal, measures) : [];
        const driverResult = filters && filters ? driverValue(filters, driverVal) : [];
        const timeDimensionsValue = timeDimensions && timeDimensions ? timeDimValue(timeDimensions, timeDimVal) : [];
        updateQuery({
            limit: 50, order: orderValue, measures: measuresValue, dimensions: dimensions,
            timeDimensions: timeDimensionsValue, filters: driverResult
        })
    }

    const handleReset = (label, data, driverVal, timeDimVal, metricVal) => {
        const chartQuery = item?.chartQuery;
        const chartDetailQuery = item?.chartDetailQuery;
        const checkValue = (driverVal === "All" || timeDimVal === "All Time" || metricVal === "All")
        const { dimensions, measures, order,
            timeDimensions, filters } =
            tabIndex === 2 ? chartDetailQuery : chartQuery;
        const measureValue = (metricVal) => {
            if (!metricVal) return measures;
            let output = "";
            for (const item of measuresList) {
                if (item.value.title === `Amz Weekly ${metricVal}`) {
                    output = item.value.name;
                    break;
                }
            }
            return [output];
        }
        setIsHeaderFilter(false)
        if (data) {
            setValue("Associate", 'all');
            setValue("TimeFrame", '0');
            setValue("Metric", 'all');
            setDriver('');
            setTimeDim('');
            setMetric('');
            updateQuery({
                limit: 50, order: order, measures: measures, dimensions: dimensions,
                timeDimensions: timeDimensions, filters: filters
            })
        } else {
            label === "Associate" && setValue("Associate", 'all') && setDriver('');
            label === "TimeFrame" && setValue("TimeFrame", '0') && setTimeDim('');
            label === "Metric" && setValue("Metric", 'all') && setMetric('');
        }
        if (checkValue) {
            updateQuery({
                limit: 50, order: order,
                measures: typeof timeDimVal === 'string' ? metricVal === "All" ? [...measures] : measureValue(metricVal) : measures,
                dimensions: dimensions,
                timeDimensions: (timeDimVal === "" || timeDimVal === "All Time") ? [] :
                    [{ "dimension": "AMZWeekly.date", "granularity": "day", dateRange: timeDimVal }]
                , filters: typeof driverVal === 'string' ? (
                    (driverVal === "All" || driverVal === "") ? [...filters] : [
                        {
                            member: "AMZWeekly.name",
                            values: [driverVal],
                            operator: "equals"
                        },
                        ...filters
                    ]
                ) : filters
            })
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "end" }}>
            <Dropdown
                name='Associate'
                label='Associate'
                options={driverData}
                size={'small'}
                ref={register}
                defaultValue={getValues().Associate || driverData}
                onChange={async (e) => {
                    handleClick(e.name, 'Associate');
                    await setValue("Associate", e.value)
                    setDriver(e.name)
                }}
            />
            <Spacer left={2} />
            <Dropdown
                name='TimeFrame'
                label='Time Frame'
                options={timeFilter}
                size={'small'}
                ref={register}
                defaultValue={getValues().TimeFrame || timeFilter}
                onChange={async (e) => {
                    handleClick(e.name, 'TimeFrame');
                    await setValue("TimeFrame", e.value)
                    setTimeDim(e.name);
                }}
            />
            <Spacer left={2} />
            <Dropdown
                name='Metric'
                label='Metric'
                options={measureData}
                size={'small'}
                ref={register}
                defaultValue={getValues().Metric}
                onChange={async (e) => {
                    handleClick(e.name, 'Metric');
                    await setValue("Metric", e.name)
                    setMetric(e.name);
                }}
            />
            <Spacer left={2} />
            <Button style={{ marginTop: "auto" }} onClick={() => { handleReset(item, true) }}>
                <Icon.Refresh
                    size={"24px"}
                    style={{ display: "flex", alignItem: "center" }}
                />
            </Button>
        </div>
    )
}

export default DashboardScorecardFilter;