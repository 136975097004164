import React, {useEffect, useState, Dispatch} from "react";
import {
    getChannelFilters,
    getMembersIdentitiesOfChannel,
    getMembersOfChannel,
    isAllowToRemove,
    // removeMemberChannel
} from "./utils";
import {Grid, PrimaryButton, FlexScroll, TagInputContainer, ReturnButton} from "./styles";
import Loading from "./Loading";
import Person from "./Person";
import {ChannelSettingsAction, ChannelSettingsState} from "./ChannelSettings";
import {TagInput, Icon} from "@dspworkplace/ui";
import {useCallback} from "react";
import {alert} from "../components/Alert";

type Props = {
    state: ChannelSettingsState;
    dispatch: Dispatch<ChannelSettingsAction>;
    cancelModal: () => void;
};

function ChannelSettingsPanelCurrentMembers(props: Props) {
    const {state, dispatch} = props;
    const [loading, setLoading] = useState(false);
    const {channel, memberMatches, initSearchFilter, removedMembers} = state;
    const [refresh, setRefresh] = useState(1.0);

    const refresher = useCallback(
        () => {
            setLoading(true);
            setRefresh(prev => prev + 1.0);
        },
        [],
    );

    useEffect(() => {
        const abortController = new AbortController();
        if (channel && refresh > 1.0) {
            setLoading(true);
            getMembersIdentitiesOfChannel(channel)
                .then(async identities => {
                    let data = await getMembersOfChannel(identities);
                    let {filterResult: filters, isNotificationMuted} = await getChannelFilters(channel.sid);
                    if (data) {
                        dispatch({
                            type: "SET_UP",
                            users: data.users,
                            members: data.members,
                            previousFilter: filters,
                            roles: data.roles,
                            skills: data.skills,
                            stations: data.stations,
                            schedules: data.schedules,
                            identities: identities,
                            defaultAutoUsers: data.defaultAutoUsers,
                            teams: data.teams,
                            chatVariable: data.chatVariable,
                            channelNotification: isNotificationMuted,
                        });
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error({error});
                });
        }

        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const handleTagChange = useCallback(
        data => {
            dispatch({type: "SET_MEMBER_MATCHES", data: data});
        },
        [dispatch]
    );

    const goBack = useCallback(() => {
        // @ts-ignore
        document.querySelectorAll("[data-tab='tab']")[0].click();
    }, []);

    const goNext = useCallback(() => {
        // @ts-ignore
        document.querySelectorAll("[data-tab='tab']")[2].click();
    }, []);

    const selectMemberToRemove = useCallback(
        user => async () => {
            if (isAllowToRemove()) {
                dispatch({type: "MEMBER_TO_REMOVE", user: user});
            } else {
                await alert('You are not allowed to remove members.');
            }
        },
        [dispatch],
    );

    // const addMemberBack = useCallback(
    //     user => () => {
    //         dispatch({type: "MEMBER_TO_ADD_BACK", user: user});
    //     },
    //     [dispatch],
    // )

    // const deleteSelectedMembers = useCallback(
    //     async () => {
    //         if (channel) {
    //             setLoading(true);
    //             // console.log(removedMembers);
    //             await Promise.all(
    //                 removedMembers.map(async item => {
    //                     const removeResult = await channel.removeMember(item.identity).catch(e => {
    //                         console.error(e);
    //                     });
    //                     return {removeResult, identity: item.identity};
    //                 })
    //             );
    //             await removeMemberChannel(removedMembers, channel);
    //             dispatch({type: "REMOVE_SELECTED_MEMBERS"});
    //             setLoading(false);
    //         }
    //     },
    //     [channel, dispatch, removedMembers],
    // );


    // const removeMember = useCallback(
    //     (userId, identity) => async () => {
    //         if (channel) {
    //             setLoading(true);
    //             await channel.removeMember(identity);
    //             setLoading(false);
    //             dispatch({type: "REMOVE_MEMBER", userId: userId});
    //         }
    //     },
    //     [channel, dispatch]
    // );
    //callback={removeMember(item.userId, item.identity)}

    // const deleteAllMembers = useCallback(async () => {
    //     if (channel) {
    //         setLoading(true);
    //         const result = await Promise.all(
    //             memberMatches.map(async item => {
    //                 const removeResult = await channel.removeMember(item.identity).catch(e => {
    //                     console.error(e);
    //                 });
    //                 return {removeResult, identity: item.identity};
    //             })
    //         );
    //         console.log({deleteAllMembers: result});
    //         dispatch({type: "REMOVE_ALL_MEMBERS"});
    //         setLoading(false);
    //     }
    // }, [channel, dispatch, memberMatches]);
    //onClick={deleteAllMembers}

    // console.log({searchAddFilterContent, filterResult});
    // console.log(removedMembers);

    if (loading) {
        return <Loading containerHeight={"400px"} imgHeight={"80px"} margin={'-28px 0 0 0'}/>;
    }

    return (
        <Grid templateRows={"repeat(4, auto)"} rowGap={"8px"}>
            <TagInputContainer>
                <TagInput
                    label="Find by name, email, skills, role, schedules, station and team:"
                    options={initSearchFilter}
                    size="big"
                    onChange={handleTagChange}
                    name={'tags'}
                />
                <ReturnButton
                    className={"refresh"}
                    width={"30px"}
                    height={"30px"}
                    borderRadius={"50%"}
                    onClick={refresher}
                >
                    <Icon.Refresh size={"24px"}/>
                </ReturnButton>
            </TagInputContainer>
            <Grid templateColumns={"auto"} justifyContent={"flex-start"}>
                <Grid color="#707070" fontSize="16px">
                    {`${memberMatches.length} current members:`}
                </Grid>
            </Grid>
            <FlexScroll
                height={memberMatches.length > 0 && removedMembers.length === 0 ? "300px !important" : "150px !important"}
                width="100%">
                {memberMatches.map(item => {
                    let roleName = item.roles[0].roleName;
                    return (
                        <Person
                            key={item.userId}
                            friendlyName={item.friendlyName}
                            skills={item.skills}
                            image={item.image}
                            stations={item.stations}
                            role={roleName}
                            schedules={item.schedules.map(e => e.name)}
                            remove={false}
                            noOption={true}
                            callback={selectMemberToRemove(item)}
                        />
                    );
                })}
            </FlexScroll>
            {/*<Grid templateColumns={"auto auto"} justifyContent={"space-between"}>*/}
            {/*    <Grid color="#707070" fontSize="16px">*/}
            {/*        {`${removedMembers.length} selected members:`}*/}
            {/*    </Grid>*/}
            {/*    <PrimaryButton type="primary" fontSize={"12px"} padding={"4px 6px"} onClick={deleteSelectedMembers}>*/}
            {/*        Delete Selected Members*/}
            {/*    </PrimaryButton>*/}
            {/*</Grid>*/}
            {/*<FlexScroll height={removedMembers.length > 0 ? "150px !important" : "0 !important"} width="100%">*/}
            {/*    {removedMembers.map(item => {*/}
            {/*        return (*/}
            {/*            <Person*/}
            {/*                key={item.userId}*/}
            {/*                friendlyName={item.friendlyName}*/}
            {/*                skills={item.skills}*/}
            {/*                image={item.image}*/}
            {/*                stations={item.stations}*/}
            {/*                role={item.roles[0].roleName}*/}
            {/*                schedules={item.schedules.map(e => e.name)}*/}
            {/*                plus={true}*/}
            {/*                callback={addMemberBack(item)}*/}
            {/*            />*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</FlexScroll>*/}
            <Grid justifyContent="space-between" templateColumns="auto auto">
                <PrimaryButton className={"modal"} type="secondary" onClick={goBack}>
                    Back
                </PrimaryButton>
                <PrimaryButton className={"modal"} type="secondary" onClick={goNext}>
                    Next
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default ChannelSettingsPanelCurrentMembers;
