import React from 'react';
import {useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {InstructionType} from "./store";
import {Button} from '@dspworkplace/ui';

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: InstructionType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function InstructionQuestionForm(props: Props) {
    const {item, updateItem} = props;
    const { name, description, disabledQuestion} = item.content;
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            name,
            description,
            disabledQuestion
        },
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register({
                required: true
            })} className={'descriptionInstruction'}/>
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default InstructionQuestionForm;