import React, {useRef} from 'react';
import MessageHeader from "./MessageHeader";
import MessageContent from "./MessageContent";
import {MessageBody, MessageContainer, MessageContentContainer} from "./styles";

type Props = {
    message: {
        user: { identity: string, name: string };
        date: string | Date;
        mediaUrl?: string | null;
        text: string;
    },
    direction?: string;
    width?: string;
    isSentByCurrentUser?: boolean,
    showHeader?: boolean,
}

function BotTempMessage({message, direction = "left", width = "300px", isSentByCurrentUser, showHeader = true}: Props) {
    const refMessage = useRef<HTMLDivElement>();
    return (
        <MessageContainer className={direction} rows={showHeader ? undefined : "auto"}>
            <MessageHeader
                user={message.user}
                date={message.date}
                refMessageBox={refMessage}
                isSystemMessage={true}
                showDate={showHeader}
                isSentByCurrentUser={isSentByCurrentUser}
            />
            <MessageBody className={direction}>
                <MessageContentContainer
                    ref={refMessage}
                    width={width}
                    className={direction}
                >
                    <MessageContent
                        isSentByCurrentUser={false}
                        mediaUrl={message.mediaUrl}
                        text={message.text}
                        identity={message.user.identity}
                    />
                </MessageContentContainer>
            </MessageBody>
        </MessageContainer>
    );
}

export default BotTempMessage;