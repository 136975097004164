import moment from 'moment-timezone';

export const fixWeekCondition = (chartQuery) => {
    if (chartQuery.timeDimensions) {
        const query = JSON.parse(JSON.stringify(chartQuery));
        query.timeDimensions.map((t) => {
            if (typeof (t.dateRange) === 'string' && t.dateRange.toLowerCase() === 'this week') {
                let start = moment.tz('UTC');
                let end = moment.tz('UTC');
                t.dateRange = [start.startOf('week').format(), end.endOf('week').format()]
            }

            if (typeof (t.dateRange) === 'string' && t.dateRange.toLowerCase() === 'last week') {
                let start = moment.tz('UTC').add(-1, 'week');
                let end = moment.tz('UTC').add(-1, 'week');
                t.dateRange = [start.startOf('week').format(), end.endOf('week').format()]
            }

            return t
        })
        return query
    }

    return chartQuery
};

export const revertDateRangeToWeekNames = (dateRange) => {
    if (!Array.isArray(dateRange)) {
        return dateRange;
    }
    // this week
    const start = moment.tz('UTC');
    const end = moment.tz('UTC');
    const currentWeekstartDate = start.startOf('week').format()
    const currentWeekendDate = end.endOf('week').format()
    const currentWeekDateString = [new Date(currentWeekstartDate).toDateString(), new Date(currentWeekendDate).toDateString()].join(',');

    // last week
    const startLW = moment.tz('UTC').add(-1, 'week');
    const endLW = moment.tz('UTC').add(-1, 'week');
    const lastWeekstartDate = startLW.startOf('week').format()
    const lastWeekendDate = endLW.endOf('week').format()
    const lastWeekDateString = [new Date(lastWeekstartDate).toDateString(), new Date(lastWeekendDate).toDateString()].join(',');

    const dateRangeString = [new Date(dateRange[0]).toDateString(), new Date(dateRange[1]).toDateString()].join(',');

    if (dateRangeString === currentWeekDateString) {
        return 'This week'
    } else if (dateRangeString === lastWeekDateString) {
        return 'Last week'
    } else {
        return 'Custom'
    }
}