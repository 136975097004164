import React from 'react';
import { Grid, ReturnButton, ModalTitle, Background, ModalContent } from "./styles";
import ReactDOM from "react-dom";

type Props = {
    width?: string,
    minHeight?: string,
    display?: string,
    show?: boolean,
    title: string,
    cancelModal?: () => void,
    cancelText?: string,
    children: any,
    overflowX?: string
}

const channelModal = document.getElementById('channel-modal');

const ModalWindow: React.FC<Props> = (props) => {
    const { cancelText = 'Close' } = props;
    return ReactDOM.createPortal(
        <Background>
            <ModalContent minHeight={props.minHeight} width={props.width} display={props.display} overflowX={props.overflowX}>
                <Grid justifyContent='space-between' width='100%' height='36px' templateColumns='auto auto'>
                    <ModalTitle>{props.title}</ModalTitle>
                    <ReturnButton fontWeight='normal' type='cancel'
                        onClick={props.cancelModal}>{cancelText}</ReturnButton>
                </Grid>
                {props.children}
            </ModalContent>
        </Background>, channelModal!);
}

export default ModalWindow;

export const hideModalWindow = () => {
    ReactDOM.unmountComponentAtNode(channelModal!);
}