import React from 'react';
import CardContainer from "./CardContainer";
import {styleSheetBackgroundColor, styleSheetBorderColor} from "./utils";
import LeftView from "./LeftView";
import MetricName from "./MetricName";
import ArrowRightItem from "./ArrowRightItem";
import GoalAndAvgItem from "./GoalAndAvgItem";
import MetricValueContainer from "./MetricValueContainer";

const Row = ({e}) => {
    return (
        <CardContainer backgroundColor={styleSheetBackgroundColor(e.metricBackgroundColor)}
                       borderColor={styleSheetBorderColor(e.metricBorderColor)}>
            <LeftView>
                <MetricName metricName={e.metricName} metricTextColor={e.metricTextColor}/>
                <MetricValueContainer e={e}/>
            </LeftView>
            <GoalAndAvgItem metricAverageValue={e.metricAverageValue} metricGoalColor={e.metricGoalColor}
                            metricGoalValue={e.metricGoalValue} metricKey={e.metricKey}/>
            <ArrowRightItem/>
        </CardContainer>
    );
};

export default Row;
