import React, {useCallback, useEffect, useState} from 'react';
import Loading, {useLoading} from "./Loading";
import ModalWindow from "./Modal";
import {FlexScroll, Grid, TagInputContainer,} from "./styles";
import {TagInput, Icon, Theme} from "@dspworkplace/ui";
import {FilterTagArrayType, Task} from "./types";
import {useTrackedState as useBotState, useUpdate as useBotDispatch} from "./BotStore";
import {engine, getMainStationId, showToast} from "../Utilities";
import {getUserId} from "../Auth";
import {fullMomentDate} from "./utils";
import moment from "moment-timezone";
import parseHTML from "html-react-parser";

async function archiveTask(task: any) {
    try {
        await engine().post('/api/complete_task', {
            task: task,
            userId: getUserId(),
            stationId: getMainStationId(),
            datetime: moment().toISOString(),
        });
        return true;
    } catch (e) {
        if (e.response && e.response.data && typeof e.response.data.message === 'string') {
            await showToast({
                type: 'Error',
                title: 'Task Error',
                name: e.message
            });
        } else if (typeof e.message) {
            await showToast({
                type: 'Error',
                title: 'Task Error',
                name: e.message
            });
        }
        return false;
    }
}

function ArchiveOption({item, tasks, setTasks}) {
    const {loading, setLoading} = useLoading();

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div style={{cursor: 'pointer'}} onClick={async () => {
            try {
                setLoading(true);
                let res = await archiveTask({id: item.id});
                if (res) {
                    setTasks(tasks.filter(e => e.id !== item.id));
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                await showToast({
                    type: 'Error',
                    title: 'Error',
                    name: 'Archive Task Error.'
                });
            }
        }}>
            {!loading && <Icon.Archive size={"24px"} color={Theme.colors.error.text}/>}
            {loading && <Loading containerHeight={"28px"} containerWidth={"28px"} imgHeight={"24px"}/>}
        </div>
    );
}

function BotTasksSummary() {
    // @ts-ignore
    const dispatch = useBotDispatch();
    // @ts-ignore
    const state = useBotState();
    // @ts-ignore
    const {loading, setLoading} = useLoading();
    // @ts-ignore
    const [searchFilterTask, setSearchFilterTask] = useState<Array<FilterTagArrayType>>([]);
    const [tasks, setTasks] = useState<Array<Task>>([]);

    useEffect(() => {
        engine().post('/api/fetch_tasks', {userId: getUserId()}).then(response => {
            let data: Task[] = response.data;
            setTasks(data);
            setSearchFilterTask(data.map(item => ({name: item.name, value: item.name})));
            setLoading(false);
        })
    }, [setLoading]);

    const closeModal = useCallback(
        () => {
            dispatch({type: "CLOSE_SUMMARY_MODAL"});
        },
        [dispatch],
    );

    const handleTagChange = useCallback(
        () => {
            // @ts-ignore
            // let filterSearch = data.map(item => item.value);
            // @ts-ignore
            // devLog(filterSearch);
            // if (filterSearch.length === 0) return;
            // @ts-ignore
            // let result = archiveChannels.filter(channel => {
            //     return filterSearch.some(item => item.indexOf(channel.friendlyName) >= 0);
            // });
            // @ts-ignore
            // setMatchedArchiveChannels(result);
        },
        []
    );

    if (loading) {
        return (
            <ModalWindow width={"700px"} title={"Tasks Summary"}>
                <Loading containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"}/>
            </ModalWindow>
        );
    }

    return (
        <ModalWindow width={"700px"} title={"Tasks Summary"} cancelModal={closeModal}>
            <Grid templateRows={"auto auto auto"} rowGap={"16px"}>
                <TagInputContainer width={"658px"} templateRows={"auto"}>
                    <TagInput
                        label="Find by name"
                        options={searchFilterTask}
                        size="big"
                        onChange={handleTagChange}
                        name={'names'}
                    />
                </TagInputContainer>
                <Grid
                    templateColumns={"30px 190px 140px 140px 60px auto"}
                    templateRows={"auto"}
                    height={"20px"}
                    columnGap={"10px"}
                    padding={"0 8px 0 8px"}
                    width={"100%"}
                >
                    <Grid>
                        ID
                    </Grid>
                    <Grid>
                        Task
                    </Grid>
                    <Grid>
                        Created Date
                    </Grid>
                    <Grid>
                        Due Date
                    </Grid>
                    <Grid>
                        Status
                    </Grid>
                    <Grid/>
                </Grid>
                <FlexScroll height={"250px"} width="100%">
                    <Grid templateColumns={"auto"} rowGap={"8px"}>
                        {tasks.map((item) => {
                            return (
                                <Grid
                                    key={item.id}
                                    templateColumns={"30px 190px 140px 140px 60px auto"}
                                    templateRows={"auto"}
                                    height={"88px"}
                                    columnGap={"10px"}
                                    padding={"0 8px 0 8px"}
                                    border={"1px dashed #7C98B6"}
                                    width={"100%"}
                                    borderRadius={"4px"}
                                    // onClick={openArchiveChannel(item)}
                                >
                                    <Grid fontSize={"10px"}>
                                        {item.id}
                                    </Grid>
                                    <Grid fontSize={"12px"}>
                                        {parseHTML(item.name)}
                                    </Grid>
                                    <Grid fontSize={"10px"}>
                                        {fullMomentDate(item.createdAt)}
                                    </Grid>
                                    <Grid fontSize={"10px"}>
                                        {fullMomentDate(item.dueDate)}
                                    </Grid>
                                    <Grid fontSize={"10px"}>
                                        {item.isCompleted ? "Completed" : item.isArchived ? "Archived" : "Pending"}
                                    </Grid>
                                    <Grid fontSize={"10px"}>
                                        <ArchiveOption item={item} setTasks={setTasks} tasks={tasks}/>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </FlexScroll>
            </Grid>
        </ModalWindow>
    );
}

export default BotTasksSummary;