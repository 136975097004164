import apiFactory from '../../../api/factory';
type VehiclePlanTasksProps = {
    station_id: number
    sorter: string
    filters: string,
    groupByFilter: string
}

export type VehiclePlanTasksType = {
    id: number,
    status: string,
    priority: string,
    pmMileage: number,
    pmDate: string,
    vehicle: {
        id: number,
        vehicleId: string
    },
    category: {
        id: number,
        label: string,
        parent: {
            id: number,
            label: string,
        }
    }
}

const {call} = apiFactory();
const resource = 'vehicle/maintenance/task'
export const GetVehiclePlanTasks = (props: VehiclePlanTasksProps): VehiclePlanTasksType[] => {
    const {station_id,sorter,filters, groupByFilter} = props
    const filtersString = `${filters}`
    return call(props,`api/v2/${resource}/?station_id=${station_id}&sortBy=${sorter}&groupBy=${groupByFilter}&${filtersString}`,'get')
}

export const GetVehiclePlanTasksLog = (props: VehiclePlanTasksProps): VehiclePlanTasksType[] => {
    const {station_id,sorter,filters} = props
    return call(props,`api/v2/${resource}/history?station_id=${station_id}&sortBy=${sorter}&${filters}`,'get')
}