import React from "react";

import {
    Chart as BizChart,
    useTheme
} from "bizcharts";
import {IAxis} from "bizcharts/lib/components/Axis/index";
import {Types as BizChartType} from "bizcharts/src/index"
import {ChartBar,Bar} from './types/Bar'
import {ChartLine,Line} from './types/Line'
import {ChartPie,Pie} from './types/Pie'

export type ChartChildren = React.ReactElement<ChartBar> | React.ReactElement<ChartLine> | React.ReactElement<ChartPie>;

/*interface AxisType {
    field: string,
    title: string,
    label: IAxis['title']
}*/

export interface ChartComponent {
    x:string
    y:string
    tooltip:boolean
    data: [any],
    title? : {
        x? : IAxis['title']
        y? : IAxis['title']
    }
}

interface Props extends BizChartType.ChartCfg {
    theme? : string
    data: [any],
    forceUpdate: boolean,
    sort: {
        direction:'DESC' | 'ASC',
        fields: [string]
    }
    children : ChartChildren
}

const Chart: React.FC <Props> = (props) => {
    const {
        theme,
        padding,
        autoFit,
        data,
        children,
        forceUpdate = false
    } = props

    const [chartTheme] = useTheme(theme);

    return (
        <BizChart
            data={data}
            appendPadding={padding}
            autoFit={autoFit}
            theme={chartTheme}
            forceUpdate={forceUpdate}
        >
            {children}
        </BizChart>
    )
};

Chart.defaultProps = {
    padding: [8,12,30],
    autoFit: true,
    theme: 'dspworkplace',
}

export default Chart

export {
    Bar,
    Line,
    Pie
}