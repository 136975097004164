import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Spacer, TagInput, Text, Title, Toggle} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../components/Table";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import {alert} from "../../../components/Alert";
import toast from "../../../components/Toasts/toast";
import Empty from "../../../components/Empty";
import {getCompanyId, useAuth} from "../../../Auth";
import Store, {VanAffinityProvider} from "./Context";
import {fetchAllVehicles, fetchDrivers, saveVanAffinityData, saveVanAffinityDriverData} from "./api";

const FilterContainer = styled.div`
    display:flex;
`

const Wrapper = styled.div`
    min-height: 100vh;
`;

const LoadingElement = () =>
    <div style={{position: 'relative'}}>
        <LoadingWrapper style={{height: 80}}>
            <Loading/>
        </LoadingWrapper>
    </div>;

const VanAffinity = () => {
    return (
        <VanAffinityProvider>
            <Wrapper>
                <Spacer top={5} />

                <Title>Van Affinity</Title>
                <Spacer top={1} />

                <Text>
                    Setup van affinity for each driver and van.
                    A driver can have a primary and a secondary preferred vehicle.
                    A vehicle can have 2 primary and 2 secondary preferred drivers.
                    This information can be used in the van auto-assignment feature.
                </Text>
                <Spacer top={5} />

                <Filter/>
                <Spacer top={5} />

                <VanAffinityList />
            </Wrapper>
        </VanAffinityProvider>
    );
}

const VanAffinityList= () =>  {
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();
    const companyId = getCompanyId();
    const [selectedStation] = useSelectedStation();

    const fetchAllVehicleData = async () => {
        if (!state.isLoading)
            dispatch({ type: "LOADING", payload: { status: true } });

        const [result, drivers] = await Promise.all([
            fetchAllVehicles(api, companyId, selectedStation),
            fetchDrivers(api, companyId, selectedStation)
        ]);

        dispatch({ type: "ADD", payload: { data: result, station: selectedStation, company: companyId } });
        dispatch({ type: "ADD_DRIVER", payload: { data: drivers, station: selectedStation } });
    };

    useEffect(() => {
        fetchAllVehicleData();
    }, [selectedStation]);

    if (state.isLoading)
        return <LoadingElement/>;

    return state.isToggle === true
        ? <VanAffinityToggledOnList />
        : <VanAffinityToggledOffList />;
}

const Filter = () =>  {
    const [state, dispatch] = useContext(Store);

    const switchVanAffinity = () => {
        dispatch({ type: "TOGGLE", payload: !state.isToggle });
    };

    return (
        <FilterContainer>
            <form>
                <StationSelector/>

                <Spacer inline={true} left={5}>
                    <Spacer top={9} />
                    <Toggle
                        on={true}
                        singleText='Group By Vehicle'
                        onChange={switchVanAffinity}
                    />
                </Spacer>
            </form>
        </FilterContainer>
    );
}

const VanAffinityToggledOnList = () => {
    const [state, dispatch] = useContext(Store);
    const [status,setStatus] = useState('ready')
    const {api} = useAuth();

    const defaultValues = state.vehicles.reduce((all, v) => {
        all[`v${v.id}`] = {
            primary: [v.primaryDriver1, v.primaryDriver2],
            secondary: [v.secondaryDriver1, v.secondaryDriver2],
        }
        return all;
    }, {});
    const {handleSubmit, ...hookForm} = useForm({
        defaultValues
    });

    const onSubmitVanData = async data => {
        setStatus('pending');

        const res = await saveVanAffinityData(api, data, state.company, state.station);

        if(res) {
            setStatus('ready');
        }else{
            setStatus('save_error');
        }

        toast({
            type: res === true ? 'success' : 'error',
            title: res === true ? 'Van Affinity successfully saved.' : 'Van Affinity failed to save. Check your affinity configuration.',
            timeout: 8000,
            useIcon: true,
            useClose: true
        });

        /*dispatch({
            type: 'ADD',
            payload: {
                data: Object.keys(data).map(v => ({
                    id: v.replace('v', ''),
                    vehicleId: state.vehicles.find(vehicle => vehicle.id === v.replace('v', '')).vehicleId,
                    primaryDriver1: data[v].primary[0][0] || null,
                    primaryDriver2: data[v].primary[1][0] || null,
                    secondaryDriver1: data[v].secondary[0][0] || null,
                    secondaryDriver2: data[v].secondary[1][0] || null,
                })),
                station: state.station,
                company: state.company
            }
        });
        dispatch({
            type: 'ADD_DRIVER',
            payload: {
                data: state.drivers,
                station: state.station
            }
        })*/
    };

    const drivers = state.drivers.map(s => ({
        name: s.friendlyName,
        value: s.id,
    }));

    return (
        <form onSubmit={handleSubmit(onSubmitVanData)}>
            <input type="hidden" name="valid" ref={hookForm.register}/>

            <Table>
                <TableHeader
                    headers={[
                        { width: "100px", label: "Vehicle" },
                        { width: "200px", label: "Primary Driver 1" },
                        { width: "200px", label: "Primary Driver 2" },
                        { width: "200px", label: "Secondary Driver 1" },
                        { width: "200px", label: "Secondary Driver 2" },
                    ]}
                />

                {state.isLoading && <LoadingElement/>}
                {!state.isLoading && state.vehicles.length < 1 && <Empty />}
                {!state.isLoading &&
                    state.vehicles.length > 0 &&
                    state.vehicles.map((data, key) =>
                        <TableRow key={key}>
                            <VehicleItem
                                data={data}
                                drivers={drivers}
                                form={hookForm}
                            />
                        </TableRow>
                    )}

                <TableFooter sticky={true}>
                    <Button type="primary" style={{ marginLeft: "auto" }}>
                        {status === 'pending' ? 'Saving...' : 'Save' }
                    </Button>
                </TableFooter>
            </Table>
        </form>
    );
}

const VehicleItem = ({ data, drivers, form }) => {
    const {register, getValues, setError: setFormError, clearError} = form;
    const [error, setError] = useState({
        primary: [false, false],
        secondary: [false, false],
    });

    const vehicle = `v${data.id}`;

    const checkValidDriver = (driver, position, order) => {
        const values = getValues({nest: true});
        let msg = `Driver already assigned a ${position} vehicle.`;

        if (driver?.value && [values[vehicle].primary, values[vehicle].secondary].includes(driver.value)) {
            msg = `You already assigned this driver to this vehicle`;

            setError(e => {
                e[position][order] = msg;
                return {...e};
            });
            setFormError('valid', 'duplicated', msg);

            return;
        }

        const valid = Object.values(values)
                .map(v => v[position])
                .flat(2)
                .includes(driver?.value) === false;

        if (valid || !driver?.value) {
            if (error[position][order]) {
                setError(e => {
                    e[position][order] = false;
                    return {...e};
                });
                clearError('valid');
            }

            return;
        }

        setError(e => {
            e[position][order] = msg;
            return {...e};
        });
        setFormError('valid', 'duplicated', msg);
    }

    if (!drivers.length)
        return null;

    return (
        <>
            <TableData width="100px">
                <Text>{data.vehicleId}</Text>
            </TableData>
            <TableData width="200px">
                <TagInput
                    name={`${vehicle}.primary[0]`}
                    ref={register}
                    label=''
                    options={drivers}
                    size='small'
                    visibleOptionsQty={6}
                    singleOption={true}
                    onChange={([driver]) => checkValidDriver(driver, 'primary', 0)}
                    error={error.primary[0]}
                    invalid={error.primary[0] !== false}
                />
            </TableData>
            <TableData width="200px">
                <TagInput
                    name={`${vehicle}.primary[1]`}
                    ref={register}
                    label=''
                    options={drivers}
                    size='small'
                    visibleOptionsQty={6}
                    singleOption={true}
                    onChange={([driver]) => checkValidDriver(driver, 'primary', 1)}
                    error={error.primary[1]}
                    invalid={error.primary[1] !== false}
                />
            </TableData>
            <TableData width="200px">
                <TagInput
                    name={`${vehicle}.secondary[0]`}
                    ref={register}
                    label=''
                    options={drivers}
                    size='small'
                    visibleOptionsQty={6}
                    singleOption = {true}
                    onChange={([driver]) => checkValidDriver(driver, 'secondary', 0)}
                    error={error.secondary[0]}
                    invalid={error.secondary[0] !== false}
                />
            </TableData>
            <TableData width="200px">
                <TagInput
                    name={`${vehicle}.secondary[1]`}
                    ref={register}
                    label=''
                    options={drivers}
                    size='small'
                    visibleOptionsQty={6}
                    singleOption = {true}
                    onChange={([driver]) => checkValidDriver(driver, 'secondary', 1)}
                    error={error.secondary[1]}
                    invalid={error.secondary[1] !== false}
                />
            </TableData>
        </>
    );
};

const VanAffinityToggledOffList = () => {
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();

    const defaultValues = state.vehicles.reduce((all, next) => {
        if (next.primaryDriver1)
            all[`d${next.primaryDriver1}`] = {
                ...all[`d${next.primaryDriver1}`],
                primary: [next.id]
            }

        if (next.primaryDriver2)
            all[`d${next.primaryDriver2}`] = {
                ...all[`d${next.primaryDriver2}`],
                primary: [next.id]
            }

        if (next.secondaryDriver1)
            all[`d${next.secondaryDriver1}`] = {
                ...all[`d${next.secondaryDriver1}`],
                secondary: [next.id]
            }

        if (next.secondaryDriver2)
            all[`d${next.secondaryDriver2}`] = {
                ...all[`d${next.secondaryDriver2}`],
                secondary: [next.id]
            }

        return all;
    }, {});
    const {handleSubmit, ...hookForm} = useForm({
        defaultValues
    });

    const onSubmitVanToggledOffData = async(data) => {
        toast({
            type: 'info',
            title: 'Saving...',
            timeout: 3000,
        });

        const res = await saveVanAffinityDriverData(api, data, state.company, state.station);

        toast({
            type: res ? 'success' : 'error',
            title: res ? 'Van Affinity successfully saved.' : 'Some Van\'s already assigned for another Driver.',
            timeout: 8000,
            useIcon: true,
            useClose: true
        });
    };

    const vehicles = state.vehicles.map((s) => ({
        name: s.vehicleId,
        value: s.id,
    }));

    return (
        <form onSubmit={handleSubmit(onSubmitVanToggledOffData)}>
            <input type="hidden" name="valid" ref={hookForm.register}/>

            <Table>
                <TableHeader
                    headers={[
                        { width: "200px", label: "Driver" },
                        { width: "350px", label: "Primary Vehicle" },
                        { width: "350px", label: "Secondary Vehicle" }
                    ]}
                />

                {state.isLoading && <LoadingElement/>}
                {!state.isLoading && state.drivers.length < 1 && <Empty />}
                {!state.isLoading &&
                    state.drivers.length > 0 &&
                    state.drivers.map((data, key) =>
                        <TableRow key={key}>
                            <DriverItem
                                data={data}
                                vehicles={vehicles}
                                form={hookForm}
                            />
                        </TableRow>
                )}

                <TableFooter sticky={true}>
                    <Button type="primary" style={{ marginLeft: "auto" }}>
                        Save
                    </Button>
                </TableFooter>
            </Table>
        </form>
    );
}

const DriverItem = ({ data, vehicles, form }) => {
    const {register, getValues, setError: setFormError, clearError} = form;
    const [error, setError] = useState({
        primary: false,
        secondary: false,
    });

    const driver = `d${data.id}`;

    const checkValidVehicle = (vehicle, position) => {
        const values = getValues({nest: true});
        let msg = `This vehicle already has two ${position} drivers. Please select a different vehicle`;

        if (vehicle?.value && vehicle.value === values[driver][position === 'primary' ? 'secondary' : 'primary']?.[0]) {
            msg = 'This vehicle is already assigned to this driver';
            setError(e => {
                e[position] = msg;
                return {...e};
            });
            setFormError('valid', 'duplicated', msg);

            return;
        }

        const valid = Object.values(values)
            .map(v => v[position])
            .flat()
            .filter(v => v === vehicle?.value)
            .length <= 1;

        if (valid || !vehicle?.value) {
            if (error[position]) {
                setError(e => {
                    e[position] = false;
                    return {...e};
                });
                clearError('valid');
            }

            return;
        }

        setError(e => {
            e[position] = msg;
            return {...e};
        });
        setFormError('valid', 'duplicated', msg);
    }

    if (!vehicles.length)
        return null;

    return (
        <>
            <TableData width="200px">
                <Text>{data.friendlyName }</Text>
            </TableData>
            <TableData width="350px">
                <TagInput
                    name={`${driver}.primary`}
                    ref={register}
                    label=''
                    options={vehicles}
                    size='medium'
                    visibleOptionsQty={6}
                    singleOption={true}
                    onChange={([vehicle]) => checkValidVehicle(vehicle, 'primary')}
                    invalid={error.primary}
                    error={error.primary}
                />
            </TableData>
            <TableData width="350px">
                <TagInput
                    name={`${driver}.secondary`}
                    ref={register}
                    label=''
                    options={vehicles}
                    size='medium'
                    visibleOptionsQty={6}
                    singleOption={true}
                    onChange={([vehicle]) => checkValidVehicle(vehicle, 'secondary')}
                    invalid={error.secondary}
                    error={error.secondary}
                />
            </TableData>
        </>
    );
};

export default VanAffinity;