import React, {useEffect, useRef, useState} from "react";
import Modal from "../../../../../components/Modal";
import {portalPromise} from "../../../../../components/Dialog";
import {Input, Text, Button} from '@dspworkplace/ui';
import {Table, TableHeader, TableRow, TableData, TableFooter} from '../../../../../components/Table'
import {useForm} from "react-hook-form";
import DatePicker from "../../../../../components/Calendar/picker";
import apiFactory from '../../../../../api/factory';
import {toast} from '../../../../../components/Toasts'
import Loading from  '../../../../../components/Loading'
import useArrowNavigation from "../../../../../hooks/useArrowNavigation";

const resource = 'vehicle';
const {call, cancel: cancelFactory} = apiFactory()
const VehicleAssignmentForm = ({success, cancel, planId}) => {
    const tableRef = useRef()
    const [status, setStatus] = useState('pending')
    const [vehicles, setVehicles] = useState([])

    const {register, handleSubmit, errors} = useForm()
    const onSubmit = data => {
        success(data.service)
    }

    /*useArrowNavigation(tableRef,(direction,event)=>{
        console.log('alo',direction,event.target)
    })*/

    useEffect(() => {
        call(null,`api/v2/${resource}/maintenancePlan/${planId}`,'get').then(response => {
            setVehicles(response.data)
        }).catch(err => {
            toast({
                type: "error",
                title: 'error to fetch vehicles',
                useClose: true,
                timeout: 5000
            });
            cancel()
        }).finally(() => {
            setStatus('ready')
        })
        return ()=> cancelFactory()
    },[cancel, planId])

    return (
        // @ts-ignore
        <Modal
            width={'548px'}
            visible={true}
            title={'Vehicle Maintenance Last Service'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {status === 'pending' && <Loading style={{margin:'0 auto', display: 'block', height:50}} />}
            {status === 'ready' &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Table ref={tableRef}>
                        <TableHeader
                            headers={[
                                {label: 'Vehicle ID', width:'180px'},
                                {label: 'Last Mile Service', width:'160px'},
                                {label: 'Last Date Service', width:'160px'}
                            ]}
                        />
                        {vehicles.map((vehicle,key) => {
                            return (
                                <TableRow key={key}>
                                    <TableData width={'180px'}>
                                        <Text>{vehicle.vehicleId}</Text>
                                    </TableData>
                                    <TableData width={'160px'}>
                                        <Input
                                            name={`service[${key}].mileage`}
                                            type={'number'}
                                            ref={register({required: 'Field Required'})}
                                            size={'small'}
                                            error={errors?.service?.[key]?.miles && errors.service[key].miles.message}
                                        />
                                    </TableData>
                                    <TableData width={'160px'}>
                                        <DatePicker
                                            ref={register({required:'field required'})}
                                            name={`service[${key}].date`}
                                            size={"small"}
                                            error={errors?.service?.[key]?.date && errors.service[key].date.message}
                                        />
                                    </TableData>
                                    <input type={'hidden'} ref={register} name={`service[${key}].vehicle_id`} value={vehicle.id} />
                                </TableRow>
                            )
                        })}
                        <TableFooter>
                            <Button type={'primary'} style={{marginLeft:'auto'}}>Save</Button>
                        </TableFooter>
                    </Table>
                </form>
            }
        </Modal>
    )
}

export const openVehicleAssignmentForm = ({planId}) => {
    return portalPromise((success, cancel)=>{
        return <VehicleAssignmentForm
            success={success}
            cancel={cancel}
            planId={planId}
        />
    })
}