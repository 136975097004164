import React, {createContext, useReducer} from 'react';
import VanAffinityReducer from './reducer';

const Store = createContext({});

const initialState = {
    sortBy: [],
    vehicles:[],
    filter:[],
    drivers:[],
    isLoading:true,
    isToggle :true,
    list: [],
    primaryDrivers:[],
    secondaryDrivers:[]
}

const VanAffinityProvider = ({children}) => {
    const useAffinityState = useReducer(VanAffinityReducer,initialState)
    return (
        <Store.Provider value={useAffinityState}>
            {children}
        </Store.Provider>
    )
}
export {VanAffinityProvider}

export default Store
