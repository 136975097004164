import React, {useEffect, useState, Dispatch, SetStateAction, useRef} from "react";
import {Channel} from "twilio-chat/lib/channel";
import styled from "styled-components";
import {AppAction, useDispatch} from "./store";
import {getChatIdentifier} from "../Auth";
import {audio} from "./AppChat";
import {Message} from "twilio-chat/lib/message";
import {showErrorToast} from "../Utilities";

const Notification = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    text-align: center;
    background-color: ${props => (props.bgColor ? props.bgColor : "#d94c53")};
    font-size: 10px;
    color: white;
    cursor: pointer;
`;

type SetUnreadCountInput = {
    channel: Channel;
    setNotifications: Dispatch<SetStateAction<number>>;
    chatDispatch: Dispatch<AppAction>;
    type: 'SET_NOTIFICATIONS' | 'INIT_NOTIFICATIONS';
}

async function setUnreadCount(arg: SetUnreadCountInput): Promise<number> {
    const {channel, setNotifications, type, chatDispatch} = arg;
    try {
        let num: number | null = 0;
        if (channel) {
            // console.log(channel);
            num = await channel.getUnconsumedMessagesCount();
            // console.log({num});
            if (typeof num === "number") {
                setNotifications(num);
                chatDispatch({type: type, channel: channel, num: num});
                return num;
            } else {
                num = await channel.getMessagesCount();
                setNotifications(num);
                chatDispatch({type: type, channel: channel, num: num});
                return num;
            }
        }
        setNotifications(num);
        chatDispatch({type: type, channel: channel, num: num});
        return num;
    } catch (error) {
        await showErrorToast(error, 'Error in updating badge count.');
        setNotifications(0);
        if (channel) {
            chatDispatch({type: type, channel: channel, num: 0});
        }
        return 0;
    }
}

type Props = {
    channel: Channel;
    closedCount?: number
};

const ChannelBadge: React.FC<Props> = (props) => {
    const { channel, closedCount } = props;
    // @ts-ignore
    const [notifications, setNotifications] = useState<number>(0);
    // @ts-ignore
    const dispatch = useDispatch();
    // @ts-ignore
    let currentIdentity = getChatIdentifier();
    const mounted = useRef(false);
    let elem;
    // @ts-ignore
    let t1, t2, t3;

    const handleChannel = () => {

        if (channel) {
            channel.on("messageRemoved", async () => {//( elem: Message )
                // console.log({messageRemoved: elem, channel: elem.channel.friendlyName, author: elem.author});
                try {
                    t3 = setTimeout(async () => {
                        await setUnreadCount({
                            channel,
                            setNotifications,
                            chatDispatch: dispatch,
                            type: 'SET_NOTIFICATIONS'
                        });
                    }, 400);
                } catch (e) {
                    await showErrorToast(e, 'Error in updating badge for workplace_bot.');
                }
            });
            channel.on("messageAdded", async (message: Message) => {
                try {
                    elem = document.querySelector(`[data-id='container-${channel.sid}']`);
                    // console.log({
                    //     messageAdded: message,
                    //     channel: message.channel.friendlyName,
                    //     author: message.author,
                    //     currentIdentity,
                    //     elem
                    // });
                    if (message.author === 'system' && message.channel.attributes['kind'] === 'Bot') {
                        t2 = setTimeout(async () => {
                            let n = await setUnreadCount({
                                channel,
                                setNotifications,
                                chatDispatch: dispatch,
                                type: 'SET_NOTIFICATIONS'
                            });
                            if (n !== 0) {
                                // @ts-ignore
                                audio.play().catch(error => console.log("Error in play()", {error}));
                            }
                        }, 500);
                    } else if (message.author !== currentIdentity && !elem) {
                        t2 = setTimeout(async () => {
                            let n = await setUnreadCount({
                                channel,
                                setNotifications,
                                chatDispatch: dispatch,
                                type: 'SET_NOTIFICATIONS'
                            });
                            if (n !== 0) {
                                // @ts-ignore
                                audio.play().catch(error => console.log("Error in play()", {error}));
                            }
                        }, 750);
                    }
                    dispatch({type: "MESSAGE_ADDED_ACTIVE_CHANNEL", channel: message.channel});
                } catch (e) {
                    await showErrorToast(e, 'Error updating badge.');
                }
            });
            channel.on("updated", async (event) => {
                try {
                    elem = document.querySelector(`[data-id='container-${channel.sid}']`);
                    if (elem) {
                        return;
                    }
                    if (event.channel && event.channel.attributes["kind"] !== 'Bot' && Array.isArray(event.updateReasons)) {
                        let reasons: [string] = event.updateReasons;
                        if (reasons.includes('lastConsumedMessageIndex')) {
                            t1 = setTimeout(async () => {
                                await setUnreadCount({
                                    channel,
                                    setNotifications,
                                    chatDispatch: dispatch,
                                    type: 'SET_NOTIFICATIONS'
                                });
                            }, 750);
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'Error updating badge in last message.');
                }
            });
            if (mounted.current) return;
            setUnreadCount({
                channel,
                setNotifications,
                chatDispatch: dispatch,
                type: 'INIT_NOTIFICATIONS'
            }).then(() => {
                mounted.current = true;
            });
        }
    }

    // console.log(`Render ChannelBadge ${channel?.friendlyName} ${JSON.stringify({notifications, mounted})}`);
    useEffect(() => {
        const abortController = new AbortController();
        // @ts-ignore
        handleChannel()
        return () => {
            clearInterval(t1);
            clearInterval(t2);
            clearInterval(t3);
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        // @ts-ignore
        clearInterval(t1);
        clearInterval(t2);
        clearInterval(t3);
        abortController.abort();
        handleChannel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedCount]);

    // useEffect(() => {
    //     if (channel) {
    //         channel.on("messageAdded", async (message: Message) => {
    //             try {
    //                 let elem = document.querySelector(`[data-id='container-${channel.sid}']`);
    //                 console.log({messageAdded: message, author: message.author, currentIdentity, elem});
    //             } catch (e) {
    //                 await showErrorToast(e, 'Error updating badge.');
    //             }
    //         });
    //     }
    //     return () => {
    //
    //     };
    // }, []);


    if (notifications > 0) {
        return null;
        // return (<Notification>{notifications}</Notification>);
    }

    return (<Notification bgColor={'transparent'} />);
}

export default ChannelBadge;
