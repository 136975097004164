import { AxiosInstance } from "axios";
import TwilioChat, { Client, User } from "twilio-chat";
import { getChatIdentifier, getFriendlyName, getRole, getUserId, setAllowToChatStream, setEnableOldChat } from "../Auth";
import { Channel } from "twilio-chat/lib/channel";
import { Message } from "twilio-chat/lib/message";
import {
    DataForFilter,
    EmployeesResponse,
    EmployeeTwilioUser,
    FetchedTwilioUser,
    FilterArrayType,
    MembersResponse,
    MessageOfChannel,
    Task
} from "./types";
import { UserDescriptor } from "twilio-chat/lib/userdescriptor";
import uniqueInteger from "unique-integer";
import { engine, getCompanyId, getMainStationId, getSelectedStationId, showErrorToast, showToast } from "../Utilities";
import Filter from "bad-words";
import { devLog } from "../utils";
import moment from "moment-timezone";
import { Member } from "twilio-chat/lib/member";

export function getClient(token: string): Promise<Client> {
    return new Promise((resolve, reject) => {
        TwilioChat.create(token)
            .then(client => {
                resolve(client);
            })
            .catch(error => {
                reject(error);
            });
    });
}

// @ts-ignore
export function findBy(
    api: AxiosInstance,
    entity: string,
    criteria: any,
    resultName: string,
    includesJson: any
): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
        try {
            let params = { actions: { response: {} } };
            params.actions.response[`${entity}`] = {
                findBy: {
                    criteria: {},
                    get: "result",
                    includes: { "0": "id" }
                }
            };
            params.actions.response[`${entity}`]["findBy"]["criteria"] = criteria;
            params.actions.response[`${entity}`]["findBy"]["get"] = resultName;
            params.actions.response[`${entity}`]["findBy"]["includes"] = includesJson;
            let response = await api.post("/api/lazy/manage/data", params);
            resolve(response.data["data"][`${resultName}`]);
        } catch (error) {
            reject(error);
        }
    });
}

export function clientInformation(client: Client) {
    console.log(`Client connection State: ${client.connectionState}`);
    console.log(`Client version: ${client.version}`);
    console.log(`Client reachability Enabled: ${client.reachabilityEnabled}`);
}

function saveBadWordsToLocalStorage(badWordsObject: Object) {
    try {
        localStorage.setItem('badWordsObject', JSON.stringify(badWordsObject));
    } catch (e) {
        console.error({ e, functionName: 'saveBadWordsToLocalStorage()' });
    }
}

export async function getBadWords(): Promise<Filter> {
    let filter = new Filter({ emptyList: true });
    
    try {
        let response = await engine().post('/api/filter/bad_words', {
            companyId: getCompanyId(),
            type: 'GET',
        });
        let badWordsObject = response.data;
        saveBadWordsToLocalStorage(badWordsObject);
        if (Array.isArray(badWordsObject['words']) && badWordsObject['words'].length > 0) {
            filter = new Filter({ emptyList: true });
            let s = badWordsObject['words'].sort((a, b) => a.localeCompare(b));
            filter.addWords(...s);
            return filter;
        }
        return filter;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in filter for offensive words',
            name: 'The setup of filter for offensive words has failed.'
        });
        console.error({ getBadWords: e });
        return filter;
    }
}

export async function setBadWords(badWordsObjectUpdated: any): Promise<any> {
    try {
        let response = await engine().post('/api/filter/bad_words', {
            companyId: getCompanyId(),
            type: 'SET',
            badWords: badWordsObjectUpdated,
        });
        let badWordsObject = response.data;
        saveBadWordsToLocalStorage(badWordsObject);
        return badWordsObject;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in filter for offensive words',
            name: 'The filter for bad words fail to update.'
        });
        console.error({ setBadWords: e });
        return null;
    }
}

export async function fetchTasks(): Promise<Array<Task>> {
    try {
        let response = await engine().post('/api/fetch_tasks', { userId: getUserId() });
        return response.data;
    } catch (e) {
        await showToast({
            type: 'Error',
            title: 'Error in fetching Tasks',
            name: 'The setup for Tasks has failed.'
        });
        console.error({ fetchTasks: e });
        return [];
    }
}

export async function checkPermission(permission: PermissionDescriptor | PushPermissionDescriptor) {
    let name = permission.name;
    try {
        if (navigator && navigator.vendor.indexOf('Apple') < 0) {
            let permissionStatus = await navigator.permissions.query(permission);
            // console.log(`Permission ${JSON.stringify(name)} state is ${permissionStatus.state}`);
            permissionStatus.onchange = function () {
                console.log(`Permission ${JSON.stringify(name)} state is ${this.state}`);
            };
            if (permissionStatus.state === 'denied') {
                await showToast({
                    type: 'Error',
                    title: `Enable Permission ${JSON.stringify(name)}`,
                    name: `Please enable Permission ${JSON.stringify(name)} in your browser.`
                });
            }
            if (Notification && permission.name === 'notifications') {
                let response = await Notification.requestPermission();
                if (response !== 'granted') {
                    await showToast({
                        type: 'Error',
                        title: `Enable Notifications`,
                        name: `Please enable Notifications in your browser in order to make the app work properly.`
                    });
                }
            }
        }
    } catch (e) {
        console.error({ e, permission });
        await showToast({
            type: 'Error',
            title: `Enable Permission ${JSON.stringify(name)}`,
            name: `Error: ${e.message}`
        });
    }
}

export async function checkPermissions() {
    if (navigator && navigator.vendor.indexOf('Apple') < 0) {
        await Promise.all([
            checkPermission({ name: 'push', userVisibleOnly: true }),
            // checkPermission({name: 'persistent-storage'}),
            // checkPermission({name: 'background-sync'}),
        ]);
    }
}

// Dispatch<AppAction>
export async function setInitialClient(messaging: any, dispatch: any) {
    try {
        console.time('setInitialClient()');
        await checkPermissions();
        if (typeof getChatIdentifier() === "string" && getChatIdentifier() !== "null") {
            let response = await engine().post("/api/twilio/fetch_auth_token", {
                userId: localStorage.getItem("userId")
            });
            let token = await response.data.token;
            setAllowToChatStream(response.data.allowToChat)
            setEnableOldChat(response?.data?.enableOldChat)
            // console.log({twilioToken: token});
            let client = await getClient(token);
            if (messaging) {
                try {
                    const fcmToken = await messaging.getToken();
                    await client.setPushRegistrationId("fcm", fcmToken);
                } catch (e) {
                    console.error({ e, messaging });
                }
            }
            await getAllSubscribedChannels(client);
            let channels = await client.getLocalChannels({ criteria: "lastMessage", order: "descending" });
            debugLog(`Number of Channels -> ${channels.length}`);
            let responses = await Promise.all([getBadWords()]);
            let filter = responses[0];
            // let tasks = [];
            // if (Array.isArray(responses[1])) {
            //     dispatch({
            //         type: "INIT_CHAT",
            //         client: client,
            //         channels: channels,
            //         loading: false,
            //         filter: filter,
            //         tasks: [],
            //     });
            // } else {
            //     await showToast({
            //         type: 'Error',
            //         title: 'Chat Set Up Error',
            //         name: 'Task set up error.'
            //     });
            //     dispatch({
            //         type: "INIT_CHAT",
            //         client: client,
            //         channels: channels,
            //         loading: false,
            //         filter: filter,
            //         tasks: [],
            //     });
            // }
            dispatch({
                type: "INIT_CHAT",
                client: client,
                channels: channels,
                loading: false,
                filter: filter,
                tasks: [],
            });
            console.timeEnd('setInitialClient()');
        } else {
            await showToast({
                type: 'Error',
                title: 'Chat Set Up Error',
                name: 'User Identifier for chat missing.'
            });
        }
    } catch (error) {
        let errorMessage = error.message;
        await showToast({
            type: 'Error',
            title: 'Chat Set Up Error',
            name: JSON.stringify(error)
        });
        if (typeof errorMessage === "string" && errorMessage.includes('notification permission was not granted and blocked instead')) {
            await showToast({
                type: "error",
                title: 'Chat Notification Error',
                name: 'Please enable the notifications or permissions in your browser. If you are unable, choose other browser or switch browser mode.'
            });
        }
        await showToast({
            type: "error",
            title: 'Error',
            name: 'You current browser or browser mode or session have issues with chat set up.'
        });
    }
}

export function setItemToStorage(key: string, value: any) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        showErrorToast(e, 'Error setting value for key: ' + key).then();
    }
}

export function getItemFromStorage(key: string): any {
    try {
        return JSON.parse(localStorage.getItem(key)!);
    } catch (e) {
        showErrorToast(e, 'Error getting value for key: ' + key).then();
        return undefined;
    }
}

export function getValueFromAllowToChat(): boolean {
    let obj = getItemFromStorage('allowToChat');
    if (obj.track) {
        return obj.value;
    }
    return false;
}

export function getChannelName(channel: Channel, api: AxiosInstance): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {
            if (channel.friendlyName) {
                let identity = getChatIdentifier();
                if (identity && channel.friendlyName.includes(identity)) {
                    let chatIdentifier = channel.friendlyName.replace(identity, "").replace("|", "");
                    let response = await api.post("api/user/fetch/", {
                        value: chatIdentifier,
                        key: "chatIdentifier",
                        includes: ["friendlyName"]
                    });
                    resolve(response.data.user["friendlyName"]);
                } else {
                    resolve(channel.friendlyName);
                }
            }
        } catch (e) {
            reject(e);
        }
    });
}

export async function getOtherUserDescriptorDuoChannel(channel: Channel): Promise<UserDescriptor | any> {
    try {
        if (channel.attributes['kind'] !== "Duo") {
            return ({ error: { message: "Channel is not Duo.", channel } });
        }
        let currentUserIdentifier: string = getChatIdentifier()!;
        let userDescriptors = await channel.getUserDescriptors();
        let users = userDescriptors.items;
        let countOfUsers = users.length;
        if (countOfUsers === 2) {
            let user = users.find(item => item.identity !== currentUserIdentifier);
            return (user);
        }
        return ({ error: { message: "Channel Duo has no 2 members.", sid: channel.sid, channel } });
    } catch (e) {
        return e;
    }
}

export async function getOtherUserDuoChannel(channel: Channel, client: Client): Promise<User | any> {
    try {
        // if(channel.sid === 'CH2bbb42bd7ca84a7286470c376842711d'){
        //     console.log({channel: channel.friendlyName, client});
        // }

        if (channel.attributes['kind'] !== "Duo") {
            return ({ error: { message: "Channel is not Duo.", channel } });
        }
        let currentUserIdentifier: string = getChatIdentifier()!;
        let userDescriptorsResult = await channel.getUserDescriptors();
        let userDescriptors = userDescriptorsResult.items;
        let count = userDescriptors.length;
        if (count === 2) {
            let userDescriptor = userDescriptors.find(item => item.identity !== currentUserIdentifier);
            if (userDescriptor) {
                let user = await client.getUser(userDescriptor.identity);
                if (channel.sid === 'CHc8450eda37694f9094eabcbe274f1608') {
                    debugLog({ online: user.online, name: user.friendlyName, channel: channel.friendlyName });
                }
                return user;
            }
        }
        return ({ error: { message: "Channel Duo has no 2 members.", sid: channel.sid, channel } });
    } catch (e) {
        return e;
    }
}

export async function getMemberCountFromChannel(channel: Channel): Promise<number> {
    try {
        return await channel.getMembersCount();
    } catch (e) {
        await showErrorToast(e, 'Error fetching member count for' + channel.friendlyName);
        return 0;
    }
}

export async function getChannelUsers(channel: Channel): Promise<Member[] | null> {
    try {
        // console.log('getChannelUsers');
        // console.log({channel});
        // let userDescriptor = await channel.getUserDescriptors();
        // console.log({userDescriptor});
        // let {hasNextPage, prevPage, items, hasPrevPage, nextPage} = userDescriptor;
        // // if (!hasNextPage) {
        // //     return items;
        // // }
        // let users = items;
        // // let next = true;
        // // let previous = prevPage;
        //
        // console.log({
        //     hasNextPageType: typeof hasNextPage,
        //     prevPageType: typeof prevPage,
        //     itemsType: typeof items,
        //     hasPrevPageType: typeof hasPrevPage,
        //     nextPageType: typeof nextPage,
        // });
        // console.log({
        //     hasNextPage: hasNextPage,
        //     prevPage: prevPage,
        //     items: items,
        //     hasPrevPage: hasPrevPage,
        //     nextPage: nextPage,
        // });
        // console.log('prev');
        // try {
        //     let prev = await prevPage();
        //     console.log({prev});
        // } catch (e) {
        //     console.log(e);
        //     console.log({e});
        // }
        // console.log('next');
        // try {
        //     let next = await nextPage();
        //     console.log({next});
        // } catch (e) {
        //     console.log(e);
        //     console.log({e});
        // }
        // @ts-ignore
        let members = await channel.getMembers();

        // users = [...users, ...result.items];
        // console.log('loop');
        // while (result.hasPrevPage) {
        //     result = await result.prevPage();
        //     console.log({result});
        //     // next = false;
        //     // next = hasNextPage;
        //     // console.log({next});
        //     // nextItems = nextPage;
        //     users = [...users, ...result.items];
        //     // console.log({users});
        // }
        return members;
    } catch (e) {
        // await showErrorToast(e, 'Users error', 'Error setting up users.');
        return null;
    }
}

export async function getChannelFriendlyName(channel: Channel, client?: Client): Promise<string> {

    try {
        if (channel) {
            if (channel.attributes && channel.attributes["kind"] === "Duo") {
                let userDescriptor = await channel.getUserDescriptors();
                let users = userDescriptor.items;
                let limit = 1;
                if (users.length !== 2 && client) {
                    let currentChannel = await client.getChannelBySid(channel.sid);
                    while (limit < 4) {
                        userDescriptor = await currentChannel.getUserDescriptors();
                        users = userDescriptor.items;
                        if (users.length === 2) {
                            break;
                        }
                        limit++;
                    }
                }
                if (users.length === 2) {
                    let member = users.find(item => item.identity !== getChatIdentifier());
                    if (member) {
                        return member.friendlyName;

                    }
                }
                return channel.friendlyName;
            } else {
                return channel.friendlyName;
            }
        }
        return 'N/A';
    } catch (e) {
        return 'N/A';
    }
}

export function getChannelIconImage(channel: Channel): Promise<string | null> {
    return new Promise(async (resolve, reject) => {
        try {
            if (channel) {
                // console.log(channel.attributes);
                if (channel.attributes["type"] === "Duo") {
                    let userDescriptor = await channel.getUserDescriptors();
                    let members = userDescriptor.items;
                    if (members.length === 2) {
                        let member = members.find(item => item.identity !== getChatIdentifier());
                        if (member && typeof member.attributes["image"] === 'string') {
                            if (member.attributes["image"].includes('.HEIC')) {
                                resolve(null);
                                return;
                            }
                            // let url = await handleHEIC(member.attributes['image']);
                            resolve(member.attributes["image"]);
                        }
                    }
                } else {
                    resolve(channel.attributes["image"]);
                }
            }
            resolve(null);
        } catch (e) {
            reject(e);
        }
    });
}

// export async function handleHEIC(image: string) {
//     try {
//         if (image.includes('.HEIC')) {
//             console.log({image});
//             try {
//                 // https://cors-anywhere.herokuapp.com/' +
//                 let res = await fetch(image, {mode: 'no-cors'});
//                 console.log({res});
//                 let blob = await res.blob();
//                 console.log({blob});
//                 let conversionResult = await heic2any({blob});
//                 console.log({conversionResult});
//                 let url = URL.createObjectURL(conversionResult);
//                 console.log({url});
//                 return url;
//             } catch (e) {
//                 console.log({one: e});
//             }
//             try {
//                 let r = await axios.get(image);
//                 console.log(r);
//             } catch (e) {
//                 console.log({two: e});
//             }
//             return null;
//         }
//         return image;
//     } catch (e) {
//         console.log({handleHEIC: e});
//         return null;
//     }
// }

export function getLastMessageIndex(channel: Channel): Promise<number> {
    return new Promise(resolve => {
        // console.log('[LOG] getLastMessageIndex => ', {lastMessage: channel.lastMessage});
        if (channel.lastMessage && channel.lastMessage.index) {
            resolve(channel.lastMessage.index);
        } else {
            resolve(99);
        }
    });
}

//@ts-ignore
export async function downloadImage(imageSrc, name) {

    // const image = await fetch(imageSrc)
    // const e = await image.arrayBuffer();
    // console.log(e);
    // let fdataobj = new FormData();
    //
    // fdataobj.append("file", blobObject);
    // fdataobj.append("name", name);
    //
    // // FormData object content is displayed in alert box.
    // // @ts-ignore
    // for (let pair of fdataobj.entries()) {
    //     console.log(pair[0] + '–' + pair[1]);
    // }
    // try {
    //     let result = await engine().post('/api/upload', fdataobj);
    //     let s3Url = result.data["ObjectURL"];
    //     console.log({s3Url});
    // }catch (e) {
    //     console.log({e, name});
    // }
    // try {
    //     console.log({imageSrc, name, image});
    //     let form = await image.clone().formData();
    //     console.log({imageSrc, name, image, form});
    // } catch (e) {
    //     console.error({e});
    // }
    // const imageBlob = await image.blob()
    // const imageFormData = new FormData();
    // imageFormData.append('blob' + Math.random(), imageBlob, name);
    // console.log(imageBlob);
    // console.log(imageFormData);
    // let result = await engine().post('/api/upload', imageFormData);
    // let s3Url = result.data["ObjectURL"];
    // console.log({s3Url});


    // const image = await fetch(imageSrc)
    // const imageBlog = await image.blob()
    // const imageURL = URL.createObjectURL(imageBlog)
    // const link = document.createElement('a')
    // link.href = imageURL
    // link.download = name
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
}

export async function getFullMessages(list: Array<Message>): Promise<Array<MessageOfChannel>> {
    return Promise.all(
        list.map(async element => {
            let mediaUrl;
            if (element.type === "media") {
                try {
                    // @ts-ignore
                    mediaUrl = element.attributes.media;
                } catch (e) {
                }
            }
            return {
                text: element.body,
                username: element.author,
                date: element.dateCreated,
                createdAt: element.dateCreated,
                element: element,
                sid: element.sid,
                _id: element.sid,
                mediaUrl: mediaUrl,
                attributes: element.attributes,
                user: {
                    _id: element.memberSid,
                    identity: element.author,
                    name: element.attributes["authorName"] ? element.attributes["authorName"] : element.author,
                    authorName: element.attributes["authorName"] ? element.attributes["authorName"] : "NA"
                }
            };
        })
    );
}

export function getMessages(channel: Channel, index: number) {
    return new Promise((resolve, reject) => {
        // console.log('[LOG] getMessages => ', index);
        if (!index) {
            channel
                .getMessages(100)
                .then(async messagesTwilio => {
                    return await getFullMessages(messagesTwilio.items);
                })
                .then(arrMessages => {
                    resolve(arrMessages);
                })
                .catch(error => {
                    reject(error);
                });
        } else {
            channel
                .getMessages(100, index)
                .then(async messagesTwilio => {
                    return await getFullMessages(messagesTwilio.items);
                })
                .then(arrMessages => {
                    resolve(arrMessages);
                })
                .catch(error => {
                    reject(error);
                });
        }
    });
}

export function filteredArchivedMessages(messages: Message[]) {
    return messages.filter(item => {
        if (item && typeof item.attributes["archived"] === "undefined") {
            return true;
        }
        return item && item.attributes["archived"] === false;
    });
}

export function fetchPreviousMessages(
    channel: Channel,
    pageSize?: number | undefined,
    //@ts-ignore
    index?: number | undefined
): Promise<{
    messages: Array<MessageOfChannel>;
    hasPrevPage: boolean;
    previousIndex: number;
    lastIndex: number;
    lastMessage: Message | null;
}> {
    return new Promise(async (resolve, reject) => {
        try {
            let size = pageSize ? pageSize : 32;
            await setMessagesConsumed(channel);
            let messages: Message[];
            let pagination = await channel.getMessages(size, index);
            messages = pagination.items;
            let afterLength: number;
            let hasPrevPage = pagination.hasPrevPage;
            let currentIndex: number;
            if (messages.length === 0) {
                let s1 = {
                    messages: [],
                    hasPrevPage: hasPrevPage,
                    previousIndex: 0,
                    lastMessage: null,
                    lastIndex: 0
                }
                resolve(s1);
                return;
            }
            let previousIndex = messages[0].index;
            let lastIndex = messages[messages.length - 1].index;
            let lastMessage = messages[messages.length - 1];
            hasPrevPage = pagination.hasPrevPage;
            messages = filteredArchivedMessages(messages);
            afterLength = messages.length;
            if (afterLength === 0 && !hasPrevPage) {
                let s11 = {
                    messages: [],
                    hasPrevPage: hasPrevPage,
                    previousIndex: previousIndex,
                    lastMessage: lastMessage,
                    lastIndex: lastIndex
                }
                resolve(s11);
                return;
            }
            currentIndex = previousIndex;
            while (hasPrevPage && afterLength === 0 && currentIndex - 1 >= 0) {
                pagination = await channel.getMessages(size, currentIndex - 1);
                hasPrevPage = pagination.hasPrevPage;
                messages = pagination.items;
                if (messages.length === 0) {
                    break;
                }
                previousIndex = messages[0].index;
                currentIndex = previousIndex;
                lastIndex = messages[messages.length - 1].index;
                lastMessage = messages[messages.length - 1];
                messages = filteredArchivedMessages(messages);
                afterLength = messages.length;
            }
            if (messages.length === 0) {
                let s2 = {
                    messages: [],
                    hasPrevPage: false,
                    previousIndex: previousIndex,
                    lastMessage: lastMessage,
                    lastIndex: lastIndex
                };
                resolve(s2);
                return;
            }
            let fullMessages = await getFullMessages(messages);
            let s3 = {
                messages: fullMessages,
                hasPrevPage,
                previousIndex,
                lastMessage,
                lastIndex
            };
            resolve(s3);
            return;
        } catch (err) {
            console.error({ fetchPreviousMessages: err });
            reject(err);
            return;
        }
    });
}

export function fetchForwardMessages(
    channel: Channel,
    pageSize?: number | undefined,
    index?: number | undefined
): Promise<{
    messages: Array<MessageOfChannel>;
    hasPrevPage: boolean;
    previousIndex: number;
    lastIndex: number;
    lastMessage: Message;
}> {
    return new Promise(async (resolve, reject) => {
        try {
            await setMessagesConsumed(channel);
            let messages: Message[];
            let pagination = await channel.getMessages(pageSize, index, "forward");
            messages = pagination.items;
            messages = messages.filter(item => {
                if (typeof item.attributes["archived"] === "undefined") {
                    return true;
                }
                return item.attributes["archived"] === false;
            });
            const previousIndex = messages[0].index;
            const lastIndex = messages[messages.length - 1].index;
            const lastMessage = messages[messages.length - 1];
            let fullMessages = await getFullMessages(messages);
            resolve({
                messages: fullMessages,
                hasPrevPage: pagination.hasNextPage,
                previousIndex,
                lastMessage,
                lastIndex
            });
        } catch (err) {
            reject(err);
        }
    });
}

export function getPreviousMessages(channel: Channel) {
    return new Promise(async (resolve, reject) => {
        try {
            let lastIndex = await getLastMessageIndex(channel);
            // console.log("[LOG] getPreviousMessages =>", { lastIndex });
            if (lastIndex < 100) {
                getMessages(channel, 0)
                    .then(arr => {
                        // console.log("[LOG] getMessages =>", { arr });
                        resolve(arr);
                    })
                    .catch(err => {
                        reject(err);
                    });
            } else {
                let count = Math.floor((lastIndex + 1) / 100);
                // console.log("[LOG] getPreviousMessages=>", { count });
                let remain = (lastIndex + 1) % 100;
                // console.log("[LOG] getPreviousMessages=>", { remain: remain - 1 });
                let messages = await getMessages(channel, remain - 1);
                let arr;
                while (count > 0 && remain - 1 >= 0) {
                    remain = remain + 100;
                    // console.log("[LOG] getPreviousMessages => loop", { remain: remain - 1 });
                    arr = await getMessages(channel, remain - 1);
                    // @ts-ignore
                    messages = [...messages, ...arr];
                    count--;
                }
                resolve(messages);
            }
        } catch (err) {
            reject(err);
        }
    });
}

type MessageAPI = {
    body: string,
    channelSid: string,
    channelType: string,
    companyId: string | number,
}

export async function sendMessageAPI(params: MessageAPI) {
    try {
        let response = await engine().post('/api/send_message', params);
        return response.data;
    } catch (e) {
        console.error({ sendMessageAPI: e });
        return e;
    }
}

type MessageBotAPI = {
    body: string,
    companyId: string | number,
    stationId: string | number,
    case: string,
    message: string
}

export async function sendMessageBotAPI(params: MessageBotAPI) {
    try {
        let response = await engine().post('/api/send_message_station', params);
        return response.data;
    } catch (e) {
        console.error({ sendMessageAPI: e });
        return e;
    }
}

export async function sendMessage(channel: Channel, filter: Filter, content: any) {
    try {
        if (typeof content === "string") {
            try {
                let isBadSentence = filter.isProfane(content);
                let currentUserFriendlyName = getFriendlyName();
                if (isBadSentence) {
                    let message = filter.clean(content);
                    console.log(channel);
                    // console.log(channel.channelState.attributes.type);

                    console.log({
                        "body": `${currentUserFriendlyName} has sent an inappropriate message and it has been blocked.`,
                        "companyId": getCompanyId(),
                        "stationId": getSelectedStationId(),
                        "case": "BAD_WORDS",
                        "message": 'Unfiltered content:\n' + content + '\nFiltered content:\n' + message,
                    })
                    await Promise.all([
                        sendMessageBotAPI({
                            "body": `${currentUserFriendlyName} has sent an inappropriate message and it has been blocked.`,
                            "companyId": getCompanyId(),
                            "stationId": getSelectedStationId(),
                            "case": "BAD_WORDS",
                            "message": 'Unfiltered content:\n' + content + '\nFiltered content:\n' + message,
                        }),
                        sendMessageAPI({
                            body: `Hi ${currentUserFriendlyName}, your message was blocked due to inappropriate language, and your manager has been notified.`,
                            channelSid: channel.sid,
                            channelType: 'duo',
                            companyId: getCompanyId()
                        })
                    ]);
                    await channel.setAllMessagesConsumed();
                    return;
                }
            } catch (e) {
                console.error({ e, message: 'Error in filter.' });
            }
        }
        let uniqueId = uniqueInteger();
        uniqueId = uniqueId < 0 ? uniqueId * -1 : uniqueId;

        const attributes = {
            authorName: localStorage.getItem("friendlyName"),
            name: localStorage.getItem("friendlyName"),
            channelName: channel.friendlyName,
            channelFriendlyName: channel.friendlyName,
            channelSid: channel.sid,
            identity: localStorage.getItem("chatIdentifier"),
            messageId: uniqueId.toString(),
            archived: false,
            companyId: localStorage.getItem('company'),
        };

        let index = await channel.sendMessage(content, attributes);
        await channel.updateLastConsumedMessageIndex(index);
        await channel.setAllMessagesConsumed();
    } catch (e) {
        console.error({ e, message: 'Error in sending message.' });
    }
}

export async function fileToBlob(file: File) {
    let blob = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    console.log({ blob });
    return blob;
}

export async function sendFile(channel: Channel, formData: FormData, file?: File | undefined) {
    try {
        let data = formData;
        devLog({ formData, file });
        let uniqueId = uniqueInteger();
        uniqueId = uniqueId < 0 ? uniqueId * -1 : uniqueId;
        const attributes = {
            authorName: localStorage.getItem("friendlyName"),
            name: localStorage.getItem("friendlyName"),
            channelName: channel.friendlyName,
            channelFriendlyName: channel.friendlyName,
            channelSid: channel.sid,
            identity: localStorage.getItem("chatIdentifier"),
            messageId: uniqueId.toString(),
            archived: false
        };
        let result = await engine().post('/api/upload', data);
        let s3Url = result.data["ObjectURL"];
        let index = await channel.sendMessage(s3Url, attributes);
        await channel.updateLastConsumedMessageIndex(index);
        await channel.setAllMessagesConsumed();
    } catch (e) {
        console.error({ sendFile: e });
        console.log(formData);
    }
}

export async function sendMedia(event, filter, channel) {
    try {
        let files = Array.from(event.target.files);
        let formData;
        for (let index = 0; index < files.length; index++) {
            formData = new FormData();
            // @ts-ignore
            formData.append(files[index].name, files[index]);
            // @ts-ignore
            files[index] = formData;
        }

        for (let index = 0; index < files.length; index++) {
            sendMessage(channel, filter, files[index]).then(r => r);
        }
    } catch (e) {
        console.log("[ERROR] sendMedia => ", { e });
    }
}

export async function deleteTwilioMessage(channelSid: string, messageSid: string, attributes: Object) {
    try {
        let response = await engine().post("/api/twilio/delete/message", {
            companyId: getCompanyId(),
            channelSid,
            messageSid,
            attributes
        });
        return response.data;
    } catch (e) {
        await showErrorToast(e, 'Error deleting message');
        return e;
    }
}

export const findMatches = (users, value) => {
    try {
        let matches;
        value = value.toLowerCase();
        if (value.charAt(0) === "@") {
            value = value.replace("@", "");
            matches = users.filter(user => {
                return (
                    user.skills.some(skill => skill.toLocaleLowerCase().indexOf(value) >= 0) ||
                    user.stations.some(station => station.toLocaleLowerCase().indexOf(value) >= 0)
                );
            });
        } else {
            matches = users.filter(item => {
                return (
                    item.email.toLowerCase().indexOf(value) >= 0 ||
                    item.friendlyName.toLowerCase().indexOf(value) >= 0 ||
                    item.role.toLocaleLowerCase().indexOf(value) >= 0
                );
            });
        }
        return matches;
    } catch (e) {
        console.error({ e });
        return [];
    }
};

export function getUsers(api: AxiosInstance): Promise<Array<FetchedTwilioUser>> {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await api.get("/api/twilio/get_twilio_users", {
                params: {
                    userId: getUserId(),
                    companyId: getCompanyId()
                }
            });

            let users = await response.data.users;
            resolve(users);
        } catch (error) {
            reject(error);
        }
    });
}

export function getUsersV2(
    api: AxiosInstance
): Promise<{ users: Array<FetchedTwilioUser>; roles: Array<string>; stations: Array<string>; skills: Array<string> }> {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await api.post("/api/twilio/get_twilio_users_information", {
                userId: getUserId(),
                companyId: getCompanyId()
            });

            let { users, roles, stations, skills } = await response.data;
            resolve({ users, roles, stations, skills });
        } catch (error) {
            reject(error);
        }
    });
}

export function getTwilioUsersEmployeeInformation(): Promise<{
    users: Array<FetchedTwilioUser>;
    roles: Array<{ name: string, roleName: string }>;
    stations: Array<string>;
    skills: Array<string>;
    schedules: Array<string>;
}> {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await engine().post("/api/twilio/get_twilio_users_information_v2", {
                userId: getUserId(),
                companyId: getCompanyId()
            });

            let result = await response.data;
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
}

export async function getMembersOfChannel(identities: Array<string>): Promise<MembersResponse | null> {
    try {
        let response = await engine().post("/api/bot/twilio/employees", {
            userId: getUserId(),
            companyId: getCompanyId()
        });
        let result: EmployeesResponse = await response.data;
        let { users, roles, skills, schedules, stations, defaultAutoUsers, teams, chatVariable } = result;
        let members = users.filter(e => identities.includes(e.identity));
        for (let i = 0; i < identities.length; i++) {
            let user = users.find(e => e.identity === identities[i]);
            if (user) {
                // console.log(user);
                //@ts-ignore
                if (user.employeeStatus !== '2') {
                    debugLog({ user, idt: identities[i] });
                }
            } else {
                debugLog({ not: user, idt: identities[i] });
            }
        }
        return { users, members, skills, roles, stations, schedules, defaultAutoUsers, teams, chatVariable };
    } catch (e) {
        await showErrorToast(e, 'Error fetching employees.');
        return null;
    }
}

export async function getEmployeesTwilio(): Promise<EmployeesResponse | null> {

    try {
        let response = await engine().post("/api/bot/twilio/employees", {
            userId: getUserId(),
            companyId: getCompanyId()
        });
        return response.data;
    } catch (e) {
        console.error({ getEmployeesTwilio: e })
        return null;
    }

}

export function getUsersLoadOut(
    api: AxiosInstance
): Promise<{ users: Array<FetchedTwilioUser>; roles: Array<{ name: string, roleName: string }>; stations: Array<string>; skills: Array<string> }> {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await api.post("/api/twilio/get_twilio_users_information_load_out", {
                user: getUserId(),
                company: getCompanyId()
            });
            let { users, roles, stations, skills } = await response.data;
            resolve({ users, roles, stations, skills });
        } catch (error) {
            reject(error);
        }
    });
}

export function addMembersToExistingChannel(api: AxiosInstance, members, channelSid) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await api.post("/api/twilio/add/members/channel", {
                companyId: getCompanyId(),
                channelSid,
                members: members
            });
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
}

export async function uploadSingleFile(data: FileList): Promise<string | null> {
    try {
        let formData = new FormData();
        formData.append("image", data[0]);
        formData.append("name", data[0].name);
        let result = await engine().post("/api/upload", formData);
        // @ts-ignore
        return result.data["ObjectURL"];
    } catch (e) {
        await showErrorToast(e, 'Error uploading file.');
        return null;
    }
}

export function equalSet(arrA: Array<string>, arrB: Array<string>) {
    let a = new Set(arrA);
    let b = new Set(arrB);
    return a.size === b.size && [...arrA].every(value => b.has(value));
}

export function filterDataForFetchedTwilioUsers(data: Array<FilterArrayType>, members: Array<FetchedTwilioUser>) {
    let filterInput = data.map(item => item.value);
    filterInput = filterInput.filter(item => !item.includes("NAME") && !item.includes("EMAIL"));
    let updatedMatches = members.filter(user => {
        let userFilterData = [...user.skills, ...user.stations, user.roleName, ...user.schedules];
        let intersection = userFilterData.filter(x => filterInput.includes(x));
        return intersection.length === filterInput.length && equalSet(intersection, filterInput);
    });
    let filterUserInput = data.filter(item => item.value.includes("NAME") || item.value.includes("EMAIL"));
    if (filterUserInput.length > 0) {
        let filterUserInputArray = filterUserInput.map(item => {
            return item.value.replace("EMAIL", "").replace("NAME", "");
        });
        updatedMatches = updatedMatches.filter(item => {
            if (item.email) {
                return filterUserInputArray.includes(item.friendlyName) || filterUserInputArray.includes(item.email);
            }
            return filterUserInputArray.includes(item.friendlyName);
        });
    }
    return updatedMatches;
}

export function createChannelWithMembers(
    client: Client,
    channelName: string,
    imageUrl: string | null,
    description: string | null,
    permissions: any[],
    usersToAdd: Array<EmployeeTwilioUser>,
    users: Array<EmployeeTwilioUser>,
    filterResult: Array<FilterArrayType>,
    dataForFilter: {
        stations: Array<{ code: string, id: number }>;
        roles: Array<{ name: string, roleName: string, id: number }>;
        skills: Array<{ name: string, id: number }>;
        schedules: Array<{
            stationCode: string;
            stationId: number;
            name: string, id: number
        }>;
        teams: Array<{
            id: number,
            name: string
        }>;
        chatVariable: Array<{
            name: string,
            chatName: string,
            stationId: number,
            shiftDate: string
        }>
    },
    channelNotification: boolean
): Promise<Channel> {
    return new Promise(async (resolve, reject) => {
        try {
            let attributes = {
                type: "Group",
                kind: "Group",
                image: imageUrl,
                description: description,
                permissions: permissions,
            };
            let resultChannel = await client!.createChannel({
                attributes,
                friendlyName: channelName.trim(),
                isPrivate: true
            });
            await Promise.all(
                usersToAdd.map(async item => {
                    let memberResult = await resultChannel.add(item.identity).catch(e => {
                        console.error({ e, item });
                        return e;
                    });
                    return { identity: item.identity, memberResult, userId: item.userId };
                })
            );

            let {
                roles,
                skills,
                stations,
                schedules,
                specialUsers,
                teams,
                chatVariable
            } = channelFilterForDB(filterResult, dataForFilter, users);
            let params = {
                roles,
                skills,
                stations,
                schedules,
                teams,
                chatVariable,
                filterResult,
                members: specialUsers,
                sid: resultChannel.sid,
                friendlyName: resultChannel.friendlyName,
                company: getCompanyId(),
                env: process.env.NODE_ENV,
                channelNotification
            };

            await engine().post('/api/bot/channel/group', params).catch(async e => {
                await showErrorToast(e, 'Creation Auto Update', 'Auto Update Channel failed.');
            });
            resolve(resultChannel);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
}

export function checkIfDuoChannelExist(
    client: Client,
    identityOne: string,
    identityTwo: string
): Promise<Channel | null> {
    return new Promise(async (resolve, reject) => {
        try {
            let uniqueNameOne = identityOne + "|" + identityTwo;
            let uniqueNameTwo = identityTwo + "|" + identityOne;
            let resultChannel: Channel = await client.getChannelByUniqueName(uniqueNameOne).catch(e => e);
            if (resultChannel && resultChannel.sid) {
                resolve(resultChannel);
            }
            resultChannel = await client.getChannelByUniqueName(uniqueNameTwo).catch(e => e);
            if (resultChannel && resultChannel.sid) {
                resolve(resultChannel);
            }
            resolve(null);
        } catch (e) {
            reject(e);
        }
    });
}

export function createDuoChannel(client: Client, userToAdd: FetchedTwilioUser): Promise<Channel> {
    return new Promise(async (resolve, reject) => {
        try {
            let currentIdentity = getChatIdentifier();
            let resultChannel = await checkIfDuoChannelExist(client, currentIdentity!, userToAdd.identity);
            if (!resultChannel) {
                let channelName = getFriendlyName() + "|" + userToAdd.friendlyName;
                if (currentIdentity === userToAdd.identity) {
                    await showToast({ type: "error", title: 'You cannot direct message to ' + userToAdd.friendlyName });
                    throw new Error('Equal identity');
                }
                resultChannel = await client!.createChannel({
                    attributes: {
                        type: "Duo",
                        kind: "Duo",
                        friendlyName: channelName,
                        identities: currentIdentity + "|" + userToAdd.identity
                    },
                    friendlyName: channelName,
                    uniqueName: currentIdentity + "|" + userToAdd.identity,
                    isPrivate: true,
                });
                await resultChannel.add(userToAdd.identity).catch(e => {
                    console.error({ e });
                    return e;
                });
                await resultChannel.add(currentIdentity!).catch(e => {
                    console.error({ e });
                    return e;
                });
                resultChannel = await client.getChannelBySid(resultChannel.sid);
                resolve(resultChannel);
            } else {
                await showToast({ type: 'warning', title: 'The DM you want to interact has already exists.' })
                resolve(resultChannel);
            }
        } catch (e) {
            reject(e);
        }
    });
}

export function getNewUsersForDuoChannel(
    api: AxiosInstance,
    identities: Array<string>
): Promise<{ users: Array<FetchedTwilioUser>; roles: Array<string>; stations: Array<string>; skills: Array<string> }> {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await api.post("/api/twilio/get_users_to_create_duo_channel", {
                company: { id: getCompanyId() },
                identities: identities
            });
            const { users, skills, roles, stations } = response.data;
            resolve({ users, skills, roles, stations });
        } catch (e) {
            reject(e);
        }
    });
}

export function getAllSubscribedChannels(chatClient: Client): Promise<Array<Channel>> {
    return new Promise(async (resolve, reject) => {
        try {
            let channelsResult = await chatClient.getSubscribedChannels();
            let items = channelsResult.items;
            let hasNextPage = channelsResult.hasNextPage;
            let limit = 1;
            while (hasNextPage && limit <= 24) {
                let nextPageChannels = await channelsResult.nextPage();
                let nexItems = nextPageChannels.items;
                items = [...items, ...nexItems];
                hasNextPage = nextPageChannels.hasNextPage;
                channelsResult = nextPageChannels;
                limit++;
            }
            // items = items.sort((a, b) => {
            //     // @ts-ignore
            //     return new Date(b.dateUpdated.toString()) - new Date(a.dateUpdated.toString());
            // });
            // for (let i = 0; i < items.length; i++) {
            //     console.log(i);
            //     console.log(items[i].uniqueName);
            //     console.log(items[i].friendlyName);
            //     console.log(items[i].dateUpdated.toString());
            //     // console.log(typeof items[i].dateUpdated);
            //     // if (items[i].attributes['type'] === 'Archive') {
            //     //     let attr = Object.assign({}, items[i].attributes);
            //     //     attr['type'] = 'Duo';
            //     //     await items[i].updateAttributes(attr);
            //     // }
            // }
            resolve(items);
        } catch (e) {
            reject(e);
        }
    });
}

export async function getMembersIdentitiesOfChannel(channel: Channel): Promise<Array<string>> {
    try {
        let members = await channel.getMembers();
        return members.map(m => m.identity);
    } catch (e) {
        await showErrorToast(e, 'Error in fetching members.');
        return [];
    }
}

export const setUpName = (user) => {
    if (user.identity === "system") {
        return "workplace_bot";
    }
    if (typeof user.name === "string") {
        return user.name;
    }
    if (typeof user.authorName === "string") {
        return user.authorName;
    }
    return "N/A";
};

export const setUpDateFormatted = (date) => {
    try {
        let currentYear = moment().format('Y');
        let year = moment(date).format('Y');
        if (year === currentYear) {
            return moment(date).format("MMM D, h:mm:ss a")
        } else {
            return moment(date).format("MMM D YYYY, h:mm:ss a")
        }
    } catch (e) {
        return JSON.stringify(e);
    }
}

export async function setMessageForChat(message: Message): Promise<MessageOfChannel> {
    let mediaUrl;
    if (message.type === "media") {
        // @ts-ignore
        mediaUrl = message.attributes.media;
    }
    // @ts-ignore
    let name = message.attributes.authorName ? message.attributes.authorName : "N/A";
    return {
        attributes: message.attributes,
        text: message.body,
        username: message.author,
        date: message.dateCreated,
        createdAt: message.dateCreated,
        element: message,
        sid: message.sid,
        _id: message.sid,
        mediaUrl: mediaUrl,
        user: {
            _id: message.memberSid,
            identity: message.author,
            name: name,
            authorName: name
        }
    };
}

export function userContainsAccountManagerRoles() {
    let allowedRoles = [
        "ROLE_OWNER",
        "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        "ROLE_OPERATIONS_MANAGER"
    ];
    let currentRole = getRole();
    return !!allowedRoles.find(item => item === currentRole);
}

export async function completeTask(task: Task, message: Message) {
    try {
        await engine().post('/api/complete_task', {
            task: task,
            userId: getUserId(),
            stationId: getMainStationId(),
            datetime: moment().toISOString(),
            message: {
                sid: message.sid,
                dateCreated: message.dateCreated,
                channelSid: message.channel.sid,
            }
        });
        return true;
    } catch (e) {
        await showErrorToast(e, 'Complete Task Error.');
        return false;
    }
}

export function countOperationsAccountManager(users: Array<EmployeeTwilioUser>) {
    let count = 0;
    try {
        for (let i = 0; i < users.length; i++) {
            if (users[i].roles.length > 0 && users[i].roles[0].roleName === 'Operations Account Manager') {
                count = count + 1;
            }
        }
        return count;
    } catch (e) {
        showToast({
            type: 'Error',
            title: 'Count Operations Account Manager',
            content: e.message
        }).then();
        console.error({ e });
        return count;
    }
}

export function filtersForUserSearch(stations: Array<{ code: string, id: number }>,
    roles: Array<{ name: string, roleName: string, id: number }>,
    skills: Array<{ name: string, id: number }>,
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>, users: Array<EmployeeTwilioUser>,
    defaultAutoUsers: Array<EmployeeTwilioUser>,
    teams: Array<{
        id: number,
        name: string
    }>,
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>
) {
    let newFilterStationArray = stations.map(item => ({
        name: "Station: " + item.code,
        value: item.code
    }));
    let newFilterSkillsArray = skills.map(item => ({ name: "Skill: " + item.name, value: item.name }));
    let newFilterRolesArray = roles.map(item => ({
        name: "Role: " + item.roleName,
        value: item.name
    }));
    let newFilterSchedulesArray = schedules.map(item => ({
        name: `Schedule: ${item.stationCode} "${item.name}"`,
        value: `${item.id}||${item.name}`,
    }));
    let newFilterNamesArray = users.map(item => ({
        name: "Name: " + item.friendlyName,
        value: "NAME" + item.friendlyName
    }));
    let newFilterEmailArray = users.map(item => ({
        name: "Email: " + item.email,
        value: "EMAIL" + item.email
    }));
    let newFilterTeamArray = teams.map(item => ({
        name: "Team: " + item.name,
        value: item.name
    }));
    let newFilterChatVariableArray = chatVariable.map(item => ({
        name: "Chat: " + item.chatName,
        value: item.name
    }));
    let { tagsDefaultAutoUsers } = informationDefaultAutoUsers(defaultAutoUsers);
    return {
        newFilterEmailArray,
        newFilterNamesArray,
        newFilterRolesArray,
        newFilterSchedulesArray,
        newFilterSkillsArray,
        newFilterStationArray,
        tagsDefaultAutoUsers,
        defaultAutoUsers,
        newFilterTeamArray,
        newFilterChatVariableArray
    };
}

export function setMatchesBasedOnFilter(filterSearch: Array<FilterArrayType>, users: Array<EmployeeTwilioUser>): Array<EmployeeTwilioUser> {
    let stationCount = 0;
    let roleCount = 0;
    let skillCount = 0;
    let scheduleCount = 0;
    let teamCount = 0;
    let chatVariableCount = 0;
    for (let i = 0; i < filterSearch.length; i++) {
        if (filterSearch[i].name.includes('Station:')) {
            stationCount++;
        }
        if (filterSearch[i].name.includes('Role:')) {
            roleCount++;
        }
        if (filterSearch[i].name.includes('Skill:')) {
            skillCount++;
        }
        if (filterSearch[i].name.includes('Schedule:')) {
            scheduleCount++;
        }
        if (filterSearch[i].name.includes('Team:')) {
            teamCount++;
        }
        if (filterSearch[i].name.includes('Chat: ')) {
            chatVariableCount++;
        }
    }
    let track1, track2, track3;
    // Filter Users:
    let filterUserInput = filterSearch.filter(item => item.value.includes("NAME") || item.value.includes("EMAIL"));
    if (stationCount + skillCount + scheduleCount + roleCount + teamCount + chatVariableCount === 0 && filterUserInput.length > 0) {
        let filterUserInputArray = filterUserInput.map(item => item.value.replace("EMAIL", "").replace("NAME", ""));
        return users.filter(item => filterUserInputArray.includes(item.email) || filterUserInputArray.includes(item.friendlyName));
    }
    let filterInput = filterSearch.map(item => item.value);
    filterInput = filterInput.filter(item => !item.includes("NAME") && !item.includes("EMAIL"));

    // Filter Station:
    let updatedMatches = users.filter(user => {
        let intersection = user.stations.filter(x => filterInput.includes(x));
        return intersection.length > 0;
    });
    track1 = updatedMatches.length;
    if (updatedMatches.length === 0) {
        updatedMatches = users;
    }
    // Filter Skill, Roles, Schedules and Teams:
    let afterMatches = updatedMatches.filter(user => {
        let userFilterData = [...user.schedules.map(item => `${item.id}||${item.name}`), ...user.roles.map(e => e.name), ...user.skills, ...user.teams, ...user.chatVariable];
        let intersection = userFilterData.filter(x => filterInput.includes(x));
        return intersection.length > 0;
    });
    track2 = afterMatches.length;
    if (afterMatches.length > 0) {
        updatedMatches = afterMatches;
    }
    track3 = filterUserInput.length;
    if ((track3 + track2 + track1) === 0) {
        return [];
    }
    // Filter Users:
    if (filterUserInput.length > 0) {
        let filterUserInputArray = filterUserInput.map(item => item.value.replace("EMAIL", "").replace("NAME", ""));
        let userMatches = users.filter(item => filterUserInputArray.includes(item.email) || filterUserInputArray.includes(item.friendlyName));
        if (userMatches.length > 0) {
            for (let i = 0; i < userMatches.length; i++) {
                let currentFriendlyName = userMatches[i].friendlyName;
                let index = updatedMatches.findIndex(e => e.friendlyName === currentFriendlyName);
                if (index === -1) {
                    updatedMatches = [userMatches[i], ...updatedMatches];
                } else {
                    let tmp = updatedMatches.filter(e => e.friendlyName !== currentFriendlyName);
                    updatedMatches = [userMatches[i], ...tmp];
                }
            }
        }
    }
    return updatedMatches;
}

export function addingDefaultUsers(matches: Array<EmployeeTwilioUser>, defaultAutoUsers: Array<EmployeeTwilioUser>, users: Array<EmployeeTwilioUser>): Array<EmployeeTwilioUser> {
    try {
        let usersToAdd: Array<EmployeeTwilioUser> = matches;
        let user: EmployeeTwilioUser | undefined;
        let n = defaultAutoUsers.length;
        for (let i = 0; i < n; i++) {
            let id = defaultAutoUsers[i].userId;
            if (!usersToAdd.find(e => e.userId === id)) {
                user = users.find(e => e.userId === id);
                if (user) {
                    usersToAdd.push(user);
                }
            }
        }
        debugLog({ usersToAdd });
        return usersToAdd;
    } catch (e) {
        console.error({ e });
        return matches;
    }
}

export function informationDefaultAutoUsers(defaultAutoUsers: Array<EmployeeTwilioUser>) {
    try {
        let tagsDefaultAutoUsers = defaultAutoUsers.map(item => ({
            name: item.friendlyName,
            value: item.identity
        }));
        return { tagsDefaultAutoUsers, defaultAutoUsers };
    } catch (e) {
        console.error({ informationDefaultAutoUsers: e });
        return { tagsDefaultAutoUsers: [], defaultAutoUsers };
    }
}

export async function getChannelFilters(sid: string): Promise<{ filterResult: Array<FilterArrayType>, removedMembers: Array<EmployeeTwilioUser>, isNotificationMuted: boolean }> {
    try {
        let { data } = await engine().post("/api/bot/channel/group/settings", {
            sid: sid,
        });
        return { filterResult: data.filterResult, removedMembers: data.removedMembers, isNotificationMuted: data.isNotificationMuted };
    } catch (error) {
        await showErrorToast(error, 'Channel filters set up error or missing.');
        return { filterResult: [], removedMembers: [], isNotificationMuted: false };
    }
}

export function getFilterResult(filterCurrentSearch: Array<FilterArrayType>, filterResult: Array<FilterArrayType>) {
    let result = filterCurrentSearch;
    if (filterResult.length > 0) {
        let temp: Array<FilterArrayType> = [];
        for (let i = 0; i < filterCurrentSearch.length; i++) {
            if (!filterResult.find(e => e.value === filterCurrentSearch[i].value)) {
                temp.push(filterCurrentSearch[i]);
            }
        }
        result = [...filterResult, ...temp];
    }
    return result;
}

export function serUsersToAdd(filterCurrentSearch, filterResult, matchUsers) {
    let filter = getFilterResult(filterCurrentSearch, filterResult);
    return { futureUsers: matchUsers, filter };
}

export function selectMatchUsers(filterCurrentSearch, filterResult, matchUsers, usersToAdd, users) {
    let filter = getFilterResult(filterCurrentSearch, filterResult);
    let futureUsers = matchUsers;
    if (usersToAdd.length > 0) {
        let tempMatches = matchUsers.map(e => e.userId);
        let tempFuturesUsers = usersToAdd.map(e => e.userId);
        let setMatches = new Set([...tempMatches, ...tempFuturesUsers]);
        let tmpMerge = Array.from(setMatches);
        futureUsers = users.filter(e => tmpMerge.includes(e.userId));
    }
    return { futureUsers, filter };
}

export async function updateGroupChannel(
    channel: Channel,
    usersToAdd: Array<EmployeeTwilioUser>,
    users: Array<EmployeeTwilioUser>,
    filterResult: Array<FilterArrayType>,
    dataForFilter: DataForFilter
): Promise<Channel> {
    try {
        // console.log({filterResult});
        let usersIdentitiesToAdd = usersToAdd.map(e => e.identity);
        let countUsersToAdd = usersIdentitiesToAdd.length;
        let currentMembers = await channel.getMembers();
        let currentIdentitiesMembers = currentMembers.map(e => e.identity);
        let filteredMembersToAdd: Array<EmployeeTwilioUser> = [];
        for (let i = 0; i < countUsersToAdd; i++) {
            if (!currentIdentitiesMembers.includes(usersToAdd[i].identity)) {
                filteredMembersToAdd.push(usersToAdd[i]);
            }
        }
        // console.log({filteredMembersToAdd});
        let filteredMembersToRemove: string[] = [];
        for (let j = 0; j < currentMembers.length; j++) {
            let tempIdentity = currentMembers[j].identity;
            if (!usersIdentitiesToAdd.includes(tempIdentity)) {
                let tmpUser = users.find(e => e.identity === tempIdentity);
                if (tmpUser) {
                    if (!(tmpUser.roles[0].name === 'ROLE_OPERATIONS_ACCOUNT_MANAGER')) {
                        filteredMembersToRemove.push(tempIdentity);
                    }
                }
            }
        }
        // console.log({filteredMembersToRemove: filteredMembersToRemove.map(identity => users.find(e => e.identity === identity))});

        // remove members
        await Promise.all(filteredMembersToRemove.map(async item => {
            await channel.removeMember(item).catch(e => {
                console.error({ message: `Removing member ${item}`, e, item });
            });
        }));

        // add members
        await Promise.all(
            filteredMembersToAdd.map(async item => {
                let memberResult = await channel.add(item.identity).catch(e => {
                    console.error({ message: `Adding member ${item.friendlyName}`, e, item });
                    return e;
                });
                return { identity: item.identity, memberResult, userId: item.userId };
            })
        );

        let { roles, skills, stations, schedules, specialUsers, teams, chatVariable } = channelFilterForDB(filterResult, dataForFilter, users);
        // console.log({
        //     roles, skills, stations, schedules, specialUsers
        // })
        let m = await channel.getMembers();
        let mi = m.map(e => e.identity);
        let uu: (EmployeeTwilioUser | undefined)[] = [];
        for (let i = 0; i < mi.length; i++) {
            let u = users.find(e => e.identity === mi[i]);
            uu = [...uu, u];
        }
        let params = {
            case: 'members',
            roles,
            skills,
            stations,
            schedules,
            teams,
            chatVariable,
            filterResult,
            members: specialUsers,
            sid: channel.sid,
            friendlyName: channel.friendlyName,
            company: getCompanyId(),
            currentMembers: uu,
            currentMembersCount: uu.length,
            env: process.env.NODE_ENV
        };
        // return channel;
        await engine().post('/api/bot/channel/group/settings', params).catch(async e => {
            await showErrorToast(e, 'Auto Update', 'Auto Update Channel failed.');
        });
        return channel
    } catch (e) {
        await showErrorToast(e, 'Group Channel', 'Channel update members failed.');
        return e;
    }
}

export function channelFilterForDB(filterResult: Array<FilterArrayType>, dataForFilter: DataForFilter, users: Array<EmployeeTwilioUser>) {
    // console.log({filterResult});
    // console.log({dataForFilter});
    let filterResultValue = filterResult.map(e => e.value);
    let roles = dataForFilter.roles.filter(e => filterResultValue.includes(e.name));
    let skills = dataForFilter.skills.filter(e => filterResultValue.includes(e.name));
    let stations = dataForFilter.stations.filter(e => filterResultValue.includes(e.code));
    filterResultValue = filterResult.map(e => e.value.split("||")[0]);
    let schedules = dataForFilter.schedules.filter(e => filterResultValue.includes(String(e.id)));
    filterResultValue = filterResultValue.map(e => e.replace('NAME', "").replace('EMAIL', ''));
    let specialUsers = users.filter(e => {
        return filterResultValue.includes(e.email) || filterResultValue.includes(e.friendlyName);
    });
    let teams = dataForFilter.teams.filter(e => filterResultValue.includes(e.name));
    let chatVariable = dataForFilter.chatVariable.filter(e => filterResultValue.includes(e.name));
    // let currentUser = users.find(e => e.identity === getChatIdentifier()!);
    // if (currentUser) {
    //     specialUsers = [...specialUsers, currentUser];
    // }
    // let hrAdmin = getHrAdmin(users);
    // if (hrAdmin) {
    //     specialUsers = [...specialUsers, hrAdmin];
    // }
    return { roles, skills, stations, schedules, specialUsers, teams, chatVariable };
}

export function getHrAdmin(users: Array<EmployeeTwilioUser>): EmployeeTwilioUser | null {
    for (let i = 0; i < users.length; i++) {
        let e = users[i];
        let hrManager = e.roles.find(e => e.name === 'ROLE_HR_ADMINISTRATOR');
        if (hrManager) {
            return e;
        }
        let accountManager = e.roles.find(e => e.name === 'ROLE_OPERATIONS_ACCOUNT_MANAGER');
        if (accountManager) {
            return e;
        }
        let manager = e.roles.find(e => e.name === 'ROLE_OPERATIONS_MANAGER');
        if (manager) {
            return e;
        }
        let stationManager = e.roles.find(e => e.name === 'ROLE_STATION_MANAGER');
        if (stationManager) {
            return e;
        }
        let owner = e.roles.find(e => e.name === 'ROLE_OWNER');
        if (owner) {
            return e;
        }
    }
    return null;
}

export function removeGroupPicture() {
    try {
        localStorage.removeItem('channelImage');
    } catch (e) {
        console.error({ e });
    }
}

export async function fetchDataForChannelGroup(channel: Channel): Promise<{ data: MembersResponse | null; currentIdentities: Array<string>; filters: Array<FilterArrayType>, isNotificationMuted: boolean }> {
    let currentIdentities = await getMembersIdentitiesOfChannel(channel);
    let data = await getMembersOfChannel(currentIdentities);
    let { filterResult: filters, isNotificationMuted } = await getChannelFilters(channel.sid);
    return { currentIdentities, data, filters, isNotificationMuted }
}

export async function removeMemberChannel(members: Array<EmployeeTwilioUser>, channel: Channel) {
    await engine().post('/api/bot/channel/group/settings', {
        case: "REMOVED_MEMBERS",
        removeMembers: members,
        friendlyName: channel.friendlyName,
        sid: channel.sid,
    }).catch(async e => {
        await showErrorToast(e, 'Remove members', 'Remove members for Auto Update Channel failed.');
    });
}

export function isAllowToRemove(): boolean {
    try {
        let allowedRoles = [
            "ROLE_OWNER",
            "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        ];
        let currentRole = getRole()!;
        let found = allowedRoles.find(e => e === currentRole);
        return !!found;
    } catch (e) {
        console.error({ e });
        return false;
    }
}

export async function updateNotifications(notifications: number, notificationsArray: any[]) {
    try {
        // try {
        //     if (notifications === 0) {
        //         document.title = 'dspworkplace';
        //     } else {
        //         document.title = '(' + notifications + ') ' + 'dspworkplace';
        //     }
        // } catch (e) {
        //     console.error({updateNotifications: e});
        //     document.title = 'dspworkplace';
        // }
        let len = notificationsArray.length;
        let arr;
        // let a;
        for (let i = 0; i < len; i++) {
            arr = notificationsArray[i];
            if (!arr.ready) {
                // a = notificationsArray.filter(e => e.ready).map(e => e);
                // console.log({
                //     i,
                //     e,
                //     type: typeof e.ready,
                //     ready: e.ready,
                //     notificationsArray: notificationsArray.map(e => e),
                //     alt: a,
                //     notifications
                // });
                return [];
            }
        }
        let { data } = await engine().post('/api/update_badge_count', {
            notifications,
            userId: getUserId(),
        });
        return data;
    } catch (e) {
        console.error({ updateNotifications: e });
        return e;
    }
}

export async function setMessagesConsumed(channel: Channel) {
    try {
        if (channel.attributes['kind'] !== 'Bot') {
            await channel.setAllMessagesConsumed();
        }
    } catch (e) {
        console.error({ setMessagesConsumed: e })
    }
}

export async function updateActiveChannel(channel: Channel) {
    let attr = channel.attributes;
    let updatedAttr = Object.assign({}, attr);
    updatedAttr['activeChannel'] = channel.sid;
    await channel.updateAttributes(updatedAttr).catch();
}

export async function updateActiveChannelClose(channel: Channel) {
    let attr = channel.attributes;
    let updatedAttr = Object.assign({}, attr);
    updatedAttr['activeChannel'] = null;
    await channel.updateAttributes(updatedAttr).catch();
}

export function fullMomentDate(date: string | null): string {
    try {
        if (typeof date === 'string') {
            return moment.tz(date, moment.tz.guess()).format('ddd, MMMM Do YYYY, h:mm:ss a')
        }
        return 'N/A';
    } catch (e) {
        console.error({ fullMomentDate: e });
        return 'N/A';
    }
}

type DataReadOnly = {
    readonly: boolean;
    success: boolean;
    userRoleName?: string;
    roleInRoleNames?: boolean;
    roles?: Array<{ id: number; name: string }>;
    event?: any;
};

export async function readOnlyChannel(userId: any, roleIds: any[]): Promise<DataReadOnly> {
    try {
        let { data } = await engine().post('/api/allow_to_chat', {
            scenario: 'READ_ONLY_CHANNEL',
            userId: userId,
            roleIds: roleIds,
        });
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching for read only chat permissions.');
        return { success: false, readonly: false };
    }
}

export async function updateReadOnlyChannel(userId: any, roleIds: any[], channel: Channel): Promise<DataReadOnly> {
    try {
        let { data } = await engine().post('/api/allow_to_chat', {
            scenario: 'UPDATE_READ_ONLY_CHANNEL',
            userId: userId,
            roleIds: roleIds,
            currentRoleIds: getItemFromStorage(`permissions_${channel.sid}`),
            channel: {
                sid: channel.sid,
                friendlyName: channel.friendlyName,
                attributes: channel.attributes,
            },
        });
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error updating for read only chat permissions.');
        return { success: false, readonly: false };
    }
}

export function debugLog(message?: any, ...optionalParams: any[]) {
    try {
        if (localStorage.getItem('debug')) {
            console.log(message, optionalParams);
        }
    } catch (e) {
        console.error({ e });
    }
}
