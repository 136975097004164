import apiFactory from '../../../api/factory';
import {VehiclePlanTasksType} from "./GetVehiclePlanTasks";

const {call} = apiFactory();
const resource = 'vehicle/maintenance/task'
export const CreateVehiclePlanTasks = (plan): Promise<VehiclePlanTasksType> => {
    return call(plan,`api/v2/${resource}`,'post')
}

export const CreateVehiclePlanTasksLog = (plan): Promise<VehiclePlanTasksType> => {
    return call(plan,`api/v2/${resource}/log`,'post')
}

export const DeleteVehicleTask = (plan): Promise<any> => {
    return call(null,`api/v2/${resource}/${plan}`,'delete')
}