import React, {Dispatch, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useTrackedState} from "./store";
import {TagInput} from "@dspworkplace/ui";
import ChannelIcon from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import {DirectMessageModalAction, DirectMessageModalState} from "./DMModal";
import {FlexScroll, Grid, TagInputContainer, TextInformationContainer} from "./styles";
import {Channel} from "twilio-chat/lib/channel";
import {LoadingView} from "../components/Loading";
import {getChatIdentifier} from "../Auth";

type Props = {
    directMessageState: DirectMessageModalState;
    directMessageDispatch: Dispatch<DirectMessageModalAction>;
    cancelModal: () => void;
};

function DMCurrentChannels(props: Props) {
    const {directMessageDispatch, directMessageState, cancelModal} = props;
    const dispatch = useDispatch();
    const {duoChannels} = useTrackedState();
    const [loading, setLoading] = useState(false);
    const {matchedUserChannels, searchUserNames} = directMessageState;
    const duoChannelsCount = useRef(duoChannels.length);

    useEffect(() => {
        console.log(`Duo Channels Length: ${duoChannels.length}`);
        if (duoChannels.length === duoChannelsCount.current) return;
        duoChannelsCount.current = duoChannels.length;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const abortController = new AbortController();

        async function updateDuoChannels() {
            try {
                console.log(`Duo Channels Length: ${duoChannels.length}`);
                let item = duoChannels[0];
                let userDescriptor = await item.getUserDescriptors();
                let member = userDescriptor.items.find(item => item.identity !== getChatIdentifier());
                if (member) {
                    let searchFilter = {name: member.friendlyName, value: member.identity};
                    let identity = member.identity;
                    let duoChannelWithIdentity = {channel: item, identity: member.identity};
                    directMessageDispatch({
                        type: "ADD_USER_CHANNEL",
                        channel: item,
                        identity: identity,
                        searchFilter: searchFilter,
                        matchedSearchUserChannel: duoChannelWithIdentity
                    });
                }
                setLoading(false);
            } catch (e) {
                console.error({updateDuoChannels: e})
            }
        }

        if (duoChannels.length > duoChannelsCount.current) {
            updateDuoChannels().then();
        }
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directMessageDispatch, duoChannels.length]);

    const handleTagChange = useCallback(
        data => {
            directMessageDispatch({type: "MATCH_USERS_CHANNELS", data: data});
        },
        [directMessageDispatch]
    );

    const openChannel = useCallback(
        (item: Channel) => () => {
            if (item && item.sid) {
                setLoading(true);
                dispatch({type: 'ADD_ACTIVE_CHANNEL', channel: item});
                setLoading(false);
                cancelModal();
            }
        },
        [cancelModal, dispatch]
    );

    if (loading) {
        return <LoadingView containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"}/>;
    }

    return (
        <Grid templateRows={"auto auto"} rowGap={"16px"}>
            <TagInputContainer width={"658px"} templateRows={"auto"}>
                <TagInput
                    // label="Find by name"
                    options={searchUserNames}
                    size="big"
                    onChange={handleTagChange}
                    name={'users'}
                />
            </TagInputContainer>
            <FlexScroll height={"250px"} width="100%">
                <Grid templateColumns={"auto auto auto"} columnGap={"8px"} rowGap={"8px"}>
                    {matchedUserChannels.map(item => {
                        return (
                            <Grid
                                key={item.sid}
                                templateColumns={"40px auto"}
                                height={"56px"}
                                columnGap={"8px"}
                                padding={"4px"}
                                border={"1px dashed #7C98B6"}
                                width={"100%"}
                                borderRadius={"4px"}
                                onClick={openChannel(item)}
                            >
                                <ChannelIcon channel={item} kind={item.attributes['kind']}/>
                                <TextInformationContainer>
                                    <ChannelTitle channel={item} kind={item.attributes['kind']}/>
                                    <ChannelSubtitle channel={item} kind={item.attributes['kind']}/>
                                </TextInformationContainer>
                            </Grid>
                        );
                    })}
                </Grid>
            </FlexScroll>
        </Grid>
    );
}

export default DMCurrentChannels;
