import React, { useState } from 'react'
import { CloseIcon } from 'stream-chat-react';
import styled from "styled-components";
import { InputName, Text } from '../styles';
import { Icon, Toggle, RadioBox } from '@dspworkplace/ui';
import { alert } from '../../components/Alert';
import { engine } from '../../Utilities';
import { getCompanyId } from '../../Auth';


function CreatePoll({ isShowCreatePoll, setShowCreatePoll, channel, isPage }) {
    const [numOfOptions, setNumOfOptions] = useState(2);
    const [question, setQuestion] = useState("")
    const [options, setOptions] = useState(["", ""])
    const [isAllowMultiple, setAllowMultiple] = useState(true)
    const [type, setType] = useState('public')
    const [loading, setLoading] = useState(false)

    function onChangeText(value, index) {
        const tempOptions = [...options]
        tempOptions[index] = value
        setOptions(tempOptions)
    }

    function removeOption(key) {
        setNumOfOptions(val => val - 1)
        setOptions(options.filter((val) => val !== options[key]))
    }

    async function createPoll() {
        try {
            if (question === '' || options.some(val => val === '' || val === undefined)) {
                alert('Add a question and at least two options')
                return
            }
            var options_final = await options.map(i => { return { value: i, total_votes: 0 } })
            const message = await channel.sendMessage({
                text: question,
                messageType: 'poll',
                poll_options: options_final,
                allow_multiple: isAllowMultiple,
                groupType: type
            })
            await saveCreatedPoll(message)
            setShowCreatePoll(false)
        } catch (error) {
            console.log(error)
        }
    }

    async function saveCreatedPoll(message) {
        try {
            await setLoading(true)
            const res = await engine().post('/api/lazy/manage/data', {
                "actions": {
                    "response": {
                        "GetStreamMessagePoll": {
                            "custom": {
                                "functionName": "addStreamPoll",
                                "get": "addStreamPoll",
                                "excludes": [

                                ],
                                "criteria": {
                                    "company": getCompanyId(),
                                    "title": question,
                                    "options": options,
                                    "isMultipleOptions": isAllowMultiple,
                                    "channel_id": channel?.id,
                                    "message_id": message?.message?.id,
                                    "groupType": type
                                }
                            }
                        }
                    }
                }
            })
            await setLoading(false)
            console.log(res.data)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div style={{ display: isShowCreatePoll ? 'flex' : 'none', width: isPage ? '500px' : '300px', borderLeft: 'solid 1px #EAF0F6', overflowY: 'scroll' }} >
            <Container>
                <HeaderContainer>
                    <div style={{ cursor: 'pointer', }} onClick={() => setShowCreatePoll(false)} >
                        <CloseIcon />
                    </div>
                    <Text color={'#000000'} fontWeight={500} margin="15px" >
                        Create Poll</Text>
                </HeaderContainer>

                <div style={{ padding: "20px" }} >
                    <Text
                        margin={'0 0 5px'} color='#0071bc'
                        fontWeight="bold"
                        fontSize="16px"
                    >Question</Text>
                    <InputName
                        placeholder="Ask question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        autoFocus
                    />

                    <div style={{ marginTop: '25px' }}>
                        <Text
                            margin={'0 0 5px'} color='#0071bc'
                            fontWeight="bold"
                            fontSize="16px"
                        >Options</Text>
                        {[...Array(numOfOptions)?.keys()]?.map(key => {
                            return (
                                <div style={{ marginTop: '2px', display: 'flex', justifyContent: 'space-between' }} >
                                    <InputName
                                        key={key}
                                        placeholder="Add"
                                        value={options[key]}
                                        onChange={(e) => onChangeText(e.target.value, key)}
                                    />
                                    <div style={{ cursor: 'pointer', marginTop: '10px' }} onClick={() => removeOption(key)} >
                                        <Icon.TimesNarrow color={"#868686"} size={`14px`} />
                                    </div>
                                </div>
                            )
                        })}

                        <div style={{ cursor: 'pointer', marginTop: '10px' }} onClick={() => {
                            setOptions([...options, ''])
                            setNumOfOptions(val => val + 1)
                        }} >
                            <svg viewBox="0 0 40 40" height={25} width={25} fill='#0071bc' ><path d="M29.6 17.6h-7.2v-7.2a2.4 2.4 0 10-4.8 0v7.2h-7.2a2.4 2.4 0 000 4.8h7.2v7.2a2.4 2.4 0 004.8 0v-7.2h7.2a2.4 2.4 0 000-4.8z" /></svg>
                        </div>

                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }} >
                            <Text
                                margin={'0 0 0'} color='#0071bc'
                                fontWeight="bold"
                                fontSize="16px"
                            >Allow multiple answer</Text>

                            <Toggle
                                name={'mute'}
                                onChange={() => setAllowMultiple(!isAllowMultiple)}
                                on={isAllowMultiple}
                            />
                        </div>

                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }} >
                            <Text
                                margin={'0 0 0'} color='#0071bc'
                                fontWeight="bold"
                                fontSize="16px"
                            >Poll Type</Text>
                        </div>
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }} >
                            <RadioBox
                                name='type'
                                options={[
                                    { label: 'public', value: 'public' },
                                    { label: 'private', value: 'private' },
                                ]}
                                Required={false}
                                default={type}
                                onChange={async (e) => {
                                    await setType(e.target.value);
                                }}
                            />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '35px', }}>
                            <div style={{ display: 'flex', backgroundColor: '#0071bc', height: '35px', borderRadius: '5px', width: '200px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => createPoll()}>
                                <Text color={'#ffffff'}>Create Poll Message</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div >
    )
}

export default CreatePoll

const Container = styled.div`
background-color: #ffffff;
display: block;
width: 100%;
`;

const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 70px;
min-height: 70px;
top: 0;
display: flex;
align-items: center;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
`;