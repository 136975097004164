import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import DevicesStore, {DevicesProvider} from "./context";
import {useForm} from "react-hook-form";
import {
    Button,
    Icon,
    Input,
    Spacer,
    SubTitle,
    Text,
    Theme,
    ThirdTitle,
    Title,
    Toggle,
    Dropdown
} from "@dspworkplace/ui";
import Empty from "../../components/Empty";
import {Badge, Link, Tag, Wrapper} from "../../components/UI";
import {getCompanyId, useAuth} from "../../Auth";
import {Table, TableData, TableFooter, TableHeader, ContainerScroll} from "../../components/Table";
import {showDevicesForm} from "./form";
import {confirm} from "../../components/Confirm";
import {alert} from "../../components/Alert";
import {useModal} from "../../components/Modal";
import Timeline from "../../components/Timeline";
import Csv from "./csv";
import StationSelector, {useSelectedStation} from "../../components/StationSelector";
import {showErrorToast, showToast} from "../../Utilities";
import Loading, {LoadingView} from "../../components/Loading";
import ToggleAllow from "./ToggleAllow";
import { Btn } from "../../components/Grid/Tools/Filter/Filter";
import Modal from "../../components/Modal/index";
import styled from "styled-components";
import Tooltip from "../../components/Tooltip/tooltip";
import FilterBottom from "../../components/FilterBottom";

const App = () => {
    return (
        <DevicesProvider>
            <Wrapper>
                <Header />
                <DevicesList />
            </Wrapper>
        </DevicesProvider>
    );
};

export default App;

// header
const Header = () => {
    return (
        <>
            <div style={{ marginTop: "20px" }}></div>
            <Spacer top={4} />
            <Title>Devices</Title>
            <Spacer top={3} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text>Add/track company-owned, mobile devices here. Personal phones auto-populate.</Text>
                <div style={{ display: "flex", marginTop: "-50px" }}>
                    <Filters />
                </div>
            </div>
        </>
    );
};

// filters
const Filters = () => {
    const [state, dispatch] = useContext(DevicesStore);
    const {register, handleSubmit, reset, getValues, setValue} = useForm({
        mode: "onBlur",
        defaultValues: {isPersonalDevice: state.filter.isPersonalDevice}
    });
    const [val, setVal] = useState({ isPersonalDevice: 'all' });
    const [badge, setBadge] = useState(0);
    const [open, setOpen] = useState(false);
    const [appliedFilter, setAppliedFilter] = useState(null);

    const FilterContainer = styled.div`
    display: flex;
    `;

    const Header = styled.header`
    display:flex;
    justify-content:space-between;
    align-items:center;     
    margin-bottom:20px;
    
    >button {
        font-weight:normal;
    }   
    `

    const onSearch = (data) => {
        let temp = { ...val,...data };
        setVal(temp);
        dispatch({
            type: "SEARCH",
            payload: temp
        });
    };

    let timeout;
    const handleChange = () => {
        dispatch({type: "SET_LOADING", loading: true});
        clearTimeout(timeout);
        timeout = setTimeout(() => handleSubmit(onSearch)(), 200)
    }


    const submit = () => {
        handleSubmit(onSearch)();
    }

    useEffect(() => {
        reset(val);
        let i = 0;
        Object.values(getValues()).forEach((item) => {
            if (item !== "all" && item != "") {
                i++;
            }
        });
        setBadge(i);
    }, [state.filter,open])

    const applyFilter = (fromFilterSelect) => {
        submit();
        setOpen(false);
        !fromFilterSelect && setAppliedFilter(null)
    }

    const handleFilterSelect = async (filter) => {
        setAppliedFilter(filter?.name)
        Object.keys(filter?.filterObj).length > 0 && Object.keys(filter?.filterObj).forEach(key => {
            setValue(key, filter?.filterObj[key])
        })
        applyFilter(true)
    }

    const clearFilters = () => {
        reset({ isPersonalDevice: "all", search: "" });
        setVal({ isPersonalDevice: "all" })
        dispatch({
            type: "SEARCH",
            payload: { ...getValues(), isPersonalDevice: "all", search: '' }
        });
        setAppliedFilter(null)
    }

    const TooltipContent = ({content}) => {
        return <div>{content}</div>
    }

    const ButtonRender = ({children,content}) => {
        if(!content || content.length < 1) return children
        return <Tooltip direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
    }

    const TooltipRender = ({label,value}) => {
        return <p>{label} = {value}</p>
    }

    const appliedFilters = useMemo(()=>{
        const filters = []
        const dataAbbr={
            isPersonalDevice :"type",
            station :"station",
            search : "search"
        }


       const employeeStatus=[
            { name: 'Corporate', value: 1 },
            { name: 'Personal', value: 2 },
           { name: 'All', value: "all" }
        ];


        Object.keys(val).forEach(filterKey => {
            const value =  filterKey == "isPersonalDevice" ? employeeStatus?.filter((items) => items.value == val[filterKey])[0]?.name :val[filterKey].hasOwnProperty('value')?val[filterKey].value:val[filterKey];
            
            // @ts-ignore            
            if(value !== null && value != ''&&value!="all" && filterKey != "station" ) filters.push(<TooltipRender label={dataAbbr[filterKey]} value={value} key={filterKey} />);
        })
        return filters
    },[ val])

    return (
        <div>
            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                <StationSelector />
                <Input ref={register} name="search" label="Search" onChange={handleChange}>
                    <Icon.Search />
                </Input>
                <ButtonRender content={appliedFilters}>
                <Btn style={{ marginTop: "25px" }} onClick={() => setOpen(true)}>
                    <span>Filter</span>
                    <Icon.Filter />
                    {badge > 0 && <Badge>{badge}</Badge>}
                </Btn>
                </ButtonRender>
            </div>
            <Spacer left={5} top={5} inline />
            <Modal
                width={'340px'}
                visible={open}
                title={'Filters'}
                setVisible={() => setOpen(false)}
                drawer={'right'}
                key={'modal-filter'}
                closeOnOverlay={true}
                zindex={999}
                removeHeader={true}
                paddingTopBottomZero={true}
            >
                <FilterContainer>
                    {/* <form style={{ display: "flex", flexDirection: "column" }} > */}
                    <div style={{height:"100vh",display:"flex",flexDirection:"column"}}>
                        <div style={{flex:1}}>
                        <Header>
                            <SubTitle>Filters</SubTitle>
                            <Button type="cancel" onClick={clearFilters}>
                                Clear
                            </Button>
                        </Header>
                        <Dropdown
                            name='isPersonalDevice'
                            ref={register}
                            label='Type'
                            options={[
                                { name: 'Corporate', value: 1 },
                                { name: 'Personal', value: 2 },
                                { name: 'All', value: "all" }
                            ]}
                            size={'large'}
                        />
                        </div>
                        <FilterBottom
                            filterList={state?.savedFilterList}
                            onApplyClick={applyFilter}
                            onFilterSelect={handleFilterSelect}
                            appliedFilter={appliedFilter}
                        />
                    </div>
                    {/* </form> */}
                </FilterContainer>
            </Modal>
            <Spacer right={5} inline />
        </div>
    );
};

const prepareObj = device => {
    device.company = localStorage.getItem("company");
    device.isEnabled = 1;
    device.manufacturer = device.manufacturer[0];
    device.carrier = device.carrier[0];
    device.deviceCondition = device.deviceCondition.join(", ");
    device.number = device.number.replace(/[^\d]/g, "");

    device.isOwned = (device.ownership == "1") ? true : false
    device.isRented = (device.ownership == "0") ? true : false;

    delete device.ownership;

    if (device.isOwned) {
        if (device.ownedTermStartDate) {
            let [month, day, year] = device.ownedTermStartDate.split("/");
            device.ownedTermStartDate = `${year}-${month}-${day}`;
        } else {
            device.ownedTermStartDate = null;
        }
        device.rentedStartDate = null;
        device.rentedEndDate = null;
    }

    if (device.isRented) {
        if (device.rentedStartDate && device.rentedEndDate) {
            let [smonth, sday, syear] = device.rentedStartDate.split("/");
            let [emonth, eday, eyear] = device.rentedEndDate.split("/");

            device.rentedStartDate = `${syear}-${smonth}-${sday}`;
            device.rentedEndDate = `${eyear}-${emonth}-${eday}`;
        } else {
            device.rentedStartDate = null;
            device.rentedEndDate = null;
        }
        device.ownedTermStartDate = null;
    }

    if (device.mdmClient == "0") {
        let [month, day, year] = device.lastInventoryDate.split("/");
        device.lastInventoryDate = `${year}-${month}-${day}`;
    } else {
        device.lastInventoryDate = null;
    }

    return device;
};

const DeviceRow = ({d, k}) => {
    const [state, dispatch] = useContext(DevicesStore);
    const {api} = useAuth();

    const [timeline, toggleTimeline] = useModal();

    const handleEdit = async (device, index) => {
        if (typeof device.deviceCondition === "string") {
            device.deviceCondition = device.deviceCondition.split(", ");
        }
        device.manufacturer = [device.manufacturer];
        device.carrier = [device.carrier];
        device.ownedTermStartDate = device.ownedTermStartDate
            ? moment(device.ownedTermStartDate.timestamp * 1000).format("MM/DD/YYYY")
            : null;
        device.rentedStartDate = device.rentedStartDate
            ? moment(device.rentedStartDate.timestamp * 1000).format("MM/DD/YYYY")
            : null;
        device.rentedEndDate = device.rentedEndDate
            ? moment(device.rentedEndDate.timestamp * 1000).format("MM/DD/YYYY")
            : null;
        device.lastInventoryDate = device.lastInventoryDate
            ? moment(device.lastInventoryDate.timestamp * 1000).format("MM/DD/YYYY")
            : null;

        let isOwned = device.isOwned === true ? true : false;
        let isRented = device.isRented === true ? true : false;
        device.ownership = (isOwned === false && isRented === false) ? null : (isOwned == true ? "1" : "0");
        // device.ownership = device.isOwned === null ? null : device.isOwned === true ? "1" : ((device.rentedStartDate && device.rentedEndDate) ? "0" : null);

        if (device.mdmClient == "0") {
            if (device.lastInventoryDate && device.lastInventoryDate.timestamp)
                device.lastInventoryDate = moment(device.lastInventoryDate.timestamp * 1000).format("MM/DD/YYYY");
        }

        if (device.station && device.station.id) device.station = device.station.id;

        showDevicesForm({
            device: device,
            api: api,
            type: 'editDevice'
        }).then(
            newDevice => {
                let deviceObj = prepareObj(newDevice);
                deviceObj.allowKickoff = deviceObj.allowLoadOutProcess;

                const params = {
                    actions: {
                        update: {
                            Device: {
                                device_1: {
                                    findBy: {
                                        id: device.id
                                    },
                                    updateRecord: deviceObj
                                }
                            }
                        },
                        response: {
                            Device: {
                                findBy: {
                                    criteria: {
                                        id: device.id
                                    },
                                    get: "device",
                                    includes: {
                                        0: "id",
                                        station: {
                                            0: "id",
                                            1: "code"
                                        }
                                    }
                                }
                            }
                        }
                    }
                };
                api.post("/api/lazy/manage/data", params).then(response =>
                    dispatch({
                        type: "UPDATE",
                        device: {
                            ...deviceObj,
                            station: response.data.data.device[0].station
                        },
                        key: index
                    })
                );

            },
            cancel => () => {
            }
        );
    };

    const handleDelete = async (device, index) => {
        const confirmation = await confirm({
            icon: true,
            text: "Do you really want to archive this device?"
        });

        if (!confirmation) return;

        let params = {
            actions: {
                delete: {
                    Device: {
                        delete_device_1: {
                            findOneBy: {
                                id: device.id
                            }
                        }
                    }
                },
                response: {
                    Device: {
                        custom: {
                            functionName: "updateFutureDriverRouteNull",
                            get: "result",
                            excludes: [],
                            criteria: {
                                id: device.id
                            },
                        },
                    },
                },
            }
        };

        api.post("/api/lazy/manage/data", params).then(response => dispatch({
            type: "DELETE",
            device: device,
            key: index
        }));
    };

    const switchKickoff = (device, index) => {
        device.allowKickoff = !device.allowKickoff;

        const params = {
            actions: {
                update: {
                    Device: {
                        device_1: {
                            findBy: {
                                id: device.id
                            },
                            updateRecord: {
                                allowKickoff: device.allowKickoff
                            }
                        }
                    }
                }
            }
        };

        api.post("/api/lazy/manage/data", params).then(response =>
            dispatch({type: "UPDATE", device: device, key: index})
        );
    };

    const switchAllowLoadOutProcess = async (device, index, toggleRef, track, setTrack, setLoading) => {
        try {
            if (track > 10.0) {
                setTrack(1.0);
                return;
            }
            let allowLoadOutProcess = !device.allowLoadOutProcess;
            let message = '';
            if (!!allowLoadOutProcess) {
                message = 'Are you sure you want to enable the load out for device (' + device.name + ')?';
            } else {
                message = 'Are you sure you want to disable the load out for device (' + device.name + ')?';
            }

            const confirmation = await confirm({
                icon: true,
                text: message
            });

            if (!confirmation) {
                setLoading(false);
                setTrack(Math.random() + 10.0);
                try {
                    toggleRef.current.click();
                } catch (e) {
                    console.error(e);
                }
                return;
            }
            setLoading(true);

            const params = {
                actions: {
                    update: {
                        Device: {
                            device_1: {
                                findBy: {
                                    id: device.id
                                },
                                updateRecord: {
                                    allowLoadOutProcess: allowLoadOutProcess,
                                    allowKickoff: allowLoadOutProcess,
                                }
                            }
                        }
                    }
                }
            };

            await api.post("/api/lazy/manage/data", params);

            setLoading(false);
            setTrack(1.0);

            /*Update*/
            device.allowLoadOutProcess = allowLoadOutProcess;
            dispatch({type: "UPDATE", device: device, key: index});

            if (!!allowLoadOutProcess) {
                message = 'The device (' + device.name + ') is enabled for Load Out.';
            } else {
                message = 'The device (' + device.name + ') is disabled for Load Out.';
            }
            await showToast({
                type: 'success',
                title: 'Successful Update.',
                content: message
            });
        } catch (e) {
            setLoading(false);
            setTrack(Math.random() + 10.0);
            await showErrorToast(e, 'Error updating the device.');
            try {
                //@ts-ignore
                toggleRef.current.click();
            } catch (e) {
                await showErrorToast(e,
                    'Toggle Failure.');
            }
        }
    };

    const handleTimeline = (device, index) => {
        toggleTimeline();

        const params = {
            deviceId: device.id,
            timezone: moment.tz.guess(),
            timezoneOffset: new Date().getTimezoneOffset(),
        };

        api.post("/api/lazy/get_timeline_events", params)
            .then(response => {
                    // if (!response)
                    //     return;

                    // let timeline = [];

                    // response.data.data.DriverRoutes.map(route => {
                    //     let item = {
                    //         id: route.id,
                    //         date: route.dateCreated.timestamp * 1000,
                    //         line: []
                    //     };
                    //
                    //     if (route.routeCode)
                    //         item.line.push({
                    //             iconType: "CheckNarrow",
                    //             title: `Assigned to ${route.routeCode}`,
                    //             date: route.dateCreated.timestamp * 1000
                    //         });
                    //
                    //     if (route.punchIn)
                    //         item.line.push({
                    //             iconType: "PunchIn",
                    //             title: "Punched-In",
                    //             date: route.punchIn.timestamp * 1000
                    //         });
                    //
                    //     if (route.punchOut)
                    //         item.line.push({
                    //             iconType: "PunchOut",
                    //             title: "Punched-Out",
                    //             date: route.punchOut.timestamp * 1000
                    //         });
                    //
                    //     item.line.reverse();
                    //
                    //     timeline.push(item);
                    // });

                    device.timeline = response.data;

                    dispatch({
                        type: "UPDATE",
                        device: device,
                        key: index
                    });
                },
                async error => {
                    toggleTimeline();

                    if (error.response && error.response.data && error.response.data.message)
                        await showToast({
                            type: 'Error',
                            title: 'Error',
                            content: error.response.data.message
                        })
                }
            );
    };

    return (
        <li key={k} style={{display: "flex", backgroundColor: (d.number == '0000000000') ? "#80808059" : ""}}>
            <TableData width="75px">
                <Tag small={true}>{d.station.code}</Tag>
            </TableData>
            <TableData width="160px">
                <Text>{d.name}</Text>
            </TableData>
            {d.number == '0000000000' ?
                <TableData width="140px">
                    <Text>Bad Number</Text>
                </TableData>
            : <TableData width="140px">
                <Link href={`tel:${d.number}`}>{d.number}</Link>
            </TableData>}
            <TableData width="200px">
                <div style={{textAlign: "center", marginRight: 8}}>
                    {d.platform === "Android" ? (
                        <Icon.TimesNarrow size="16px" color={Theme.colors.info.text}/>
                    ) : (
                        <Icon.CheckNarrow size="16px" color={Theme.colors.info.text}/>
                    )}
                    <Spacer all={0}/>
                    <small>v{d.osVersion}</small>
                </div>
                <Text>
                    {d.manufacturer} <br/>
                    {d.model} <br/>
                    <small style={{display: 'block'}}>Type: {d.isPersonalDevice ? 'Personal' : 'Corporate'}</small>
                </Text>
            </TableData>
            <TableData width="160px">
                <MobileVersion androidVersion={state.androidVersion} iosVersion={state.iosVersion} d={d}/>
            </TableData>
            <TableData width="100px">
                <Text>{d.deviceCondition}</Text>
            </TableData>

            <TableData width="140px" className={'usedBy'}>
                <small>{!!d.usedBy ? d.usedBy : ''}</small>
                <small>{!!d.usedAt ? 'on ' + d.usedAt : ''}</small>
            </TableData>

            <ToggleAllow d={d} k={k} switchAllowLoadOutProcess={switchAllowLoadOutProcess}/>

            <TableData width="auto" style={{marginLeft: "auto"}}>
                <Icon.TimeLine size="20px" color={Theme.colors.info.border} onClick={() => handleTimeline(d, k)}/>
                <Spacer right={2}/>
                <Icon.Edit size="20px" color={Theme.colors.info.border} onClick={() => handleEdit(d, k)}/>
                <Spacer right={2}/>
                <Icon.Times size="20px" color={Theme.colors.info.border} onClick={() => handleDelete(d, k)}/>
            </TableData>
            {timeline && (
                <Timeline
                    visible={timeline}
                    setVisible={toggleTimeline}
                    title={<SubTitle>{d.name} - Timeline</SubTitle>}
                    loading={!d.timeline}
                    data={d.timeline || []}
                />
            )}
        </li>
    );
};

const MobileVersion = ({d, androidVersion, iosVersion}) => {
    androidVersion = androidVersion.split('-');
    androidVersion = androidVersion[0].replace(/\D/g,'');

    iosVersion = iosVersion.split('-');
    iosVersion = iosVersion[0].replace(/\D/g,'');

    const mobileVersion = d.mobileVersion ? d.mobileVersion.replace(/\D/g,'') : null;

    if( d.platform && d.platform.toLowerCase() === "ios" && typeof d.mobileVersion === 'string'  && mobileVersion && iosVersion >= mobileVersion){
        return <>
            <Icon.CheckNarrow size="16px" color={Theme.colors.info.text}/>
            <Spacer left={2}/>
            <Text>{d.mobileVersion}</Text>
        </>;
    }

    if( d.platform && d.platform.toLowerCase() === "android" && typeof d.mobileVersion === 'string' && mobileVersion && androidVersion  >= mobileVersion){
        return <>
            <Icon.CheckNarrow size="16px" color={Theme.colors.info.text}/>
            <Spacer left={2}/>
            <Text>{d.mobileVersion}</Text>
        </>;
    }

    return <>
        <Icon.TimesNarrow size="16px" color={Theme.colors.info.text}/>
        <Spacer left={2}/>
        <Text>{d.mobileVersion ? d.mobileVersion : 'N/A'}</Text>
    </>;
}

const DevicesList = () => {
    const [state, dispatch] = useContext(DevicesStore);
    const {api} = useAuth();
    const [selectedStation] = useSelectedStation();
    const [csvVisible, toggleCsv] = useModal(false);

    const setSortBy = field => {
        console.log('setSortBy ' + field);
        dispatch({
            type: "SET_SORT_BY",
            payload: field
        });
    };

    const loadDevices = async () => {
        dispatch({
            type: "SET_LOADING",
            loading: true
        });
        const params = {
            actions: {
                response: {
                    Device: {
                        findBy: {
                            criteria: {
                                company: localStorage.getItem("company"),
                                station: selectedStation
                            },
                            get: "device",
                            includes: {
                                0: "id",
                                1: "name",
                                2: "number",
                                3: "model",
                                4: "deviceUid",
                                5: "deviceCondition",
                                6: "allowKickoff",
                                7: "manufacturer",
                                8: "carrier",
                                9: "platform",
                                10: "osVersion",
                                11: "username",
                                12: "assetTag",
                                13: "imei",
                                14: "deviceId",
                                15: "mdmClient",
                                16: "isOwned",
                                17: "ownedTermStartDate",
                                18: "rentedStartDate",
                                19: "rentedEndDate",
                                20: "lastInventoryDate",
                                21: "allowLoadOutProcess",
                                22: "isRented",
                                23: "usedBy",
                                24: "usedAt",
                                25: "isPersonalDevice",
                                26: "corporateStatusDate",
                                27: "corporateStatus",
                                28: "mobileVersion",
                                29: "createdAt",
                                30: "updatedAt",
                                station: {
                                    0: "id",
                                    1: "code"
                                }
                            },
                            orderBy: {
                                usedAt: "DESC",
                                name : "ASC"
                            }
                        }
                    },
                    "MobileVersion": {
                        "custom": {
                            "functionName": "getLatestVersion",
                            "serialize": false,
                            "get": "mobileVersion",
                            "criteria": {
                                "id": 1
                            }
                        }
                    }
                },

            },
        };

        api.post("/api/lazy/manage/data", params).then(response =>
            dispatch({
                type: "CREATE",
                devices: response.data.data.device,
                iosVersion: response.data.data.mobileVersion.iosVersion,
                androidVersion: response.data.data.mobileVersion.androidVersion,
            })
        );
    };

    const handleAdd = async () => {
        showDevicesForm({api: api, type: 'addDevice'}).then(
            device => {
                let deviceObj = prepareObj(device);
                deviceObj.allowKickoff = deviceObj.allowLoadOutProcess;
                let params = {
                    actions: {
                        create: {
                            Device: {
                                device_1: deviceObj
                            }
                        }
                    }
                };

                api.post("/api/lazy/manage/data", params).then(
                    success => {

                        device.station = { id : device.station, code : (state.devices.length > 0) ?  state.devices[0].station.code  : '' }
                        device.id = success.data.data.responseId;
                        dispatch({type: "ADD", device: device, isLoading: false});
                    },
                    error => alert(error) && dispatch({type: "default"})
                );

            },
            cancel => () => {
            }
        );
    };

    useEffect(() => {
        loadDevices().then();
        dispatch({ type: "SAVED_FILTER", savedFilterList: undefined });
    }, [selectedStation]);

    if (state.loading) {
        return <ContainerScroll minWidth={1142}>
            <Table style={{minWidth: 1142, marginTop: 64}}>
                <LoadingView/>
            </Table>
        </ContainerScroll>
    }

    return (
        <ContainerScroll minWidth={1142}>
            <Table style={{minWidth: 1142,height:"100vh"}}>
                <TableHeader
                    headers={[
                        {width: "75px", label: "Station", sortBy: "station"},
                        {width: "160px", label: "Name", sortBy: "name"},
                        {width: "148px", label: "Phone Number", sortBy: "number"},
                        {width: "200px", label: "Model", sortBy: "model"},
                        {width: "160px", label: "Mobile Version", sortBy: "mobileVersion"},
                        {width: "100px", label: "Condition", sortBy: "deviceCondition"},
                        {width: "140px", label: "Used on", sortBy: "usedAt"},
                        {width: "100px", label: "Allow Load Out", sortBy: "allowKickoff"},
                    ]}
                    sortByFunc={setSortBy}
                    sortedBy={state.sortBy}
                />
                {!state.list.length && <Empty/>}
                {state.list.map((d, k) => {
                    return <DeviceRow d={d} k={k} key={k}/>
                })}
                <TableFooter sticky={true}>
                    <ThirdTitle style={{fontWeight: "normal"}}>{state.list.length} devices</ThirdTitle>
                    <div>
                        <Button
                            type="primary"
                            // style={{ marginLeft: "70%"}}
                            onClick={() => {
                                toggleCsv(true);
                            }}
                        >
                            Import CSV
                        </Button>
                        <Spacer inline right={3}/>
                        <Button type="primary" onClick={handleAdd}>
                            Add Device
                        </Button>
                        {csvVisible &&
                        <Spacer inline right={3}><Csv toggleCsv={toggleCsv} csvVisible={csvVisible}/></Spacer>}                    </div>
                </TableFooter>
            </Table>
        </ContainerScroll>
    );
};
