import React, { useEffect, useState } from "react";
import {
    Button,
    ButtonGroup,
    Icon,
    Spacer,
    SubTitle,
    TagInput,
    ThirdTitle,
    Title,
    Text,
    Theme,
} from "@dspworkplace/ui";
import StationSelector, { useSelectedStation } from "../../../../../components/StationSelector";
import API from "./api";
import { useForm } from "react-hook-form";
import Loading from "../../../../../components/Loading";
import { Card } from "../../../../../components/UI";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../../../components/Table";
import moment from "moment";
import Modal from "../../../../../components/Modal";
import trophy from '../../../../../assets/trophy.svg';
import styled from "styled-components";
const Image = styled.img`
    width: 20px;
    height: 20px;
`;

const LegendArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CustomTitle = styled(Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const STATUS = {
    PENDING: "pending",
    READY: "ready",
};

const ReportTeamsApp = () => {
    const [status, setStatus] = useState(STATUS.PENDING);
    const [week, setWeek] = useState();
    const [report, setReport] = useState();
    const [competition, setCompetition] = useState();
    const [lookups, setLookups] = useState({ drivers: {}, teams: {} });

    const [showHelp, setShowHelp] = useState(false);

    const [station] = useSelectedStation();

    useEffect(() => {
        if (!competition) return;

        setStatus(STATUS.PENDING);

        API.getCompetitionReport(station, competition.id).then(async(result) => {
            let reportsObject = await result.data.scores;
            let sortReportsObjectKeys = await Object.keys(reportsObject).sort((a,b) =>  new Date(a) - new Date(b));
            let sortReportsObject = {};
            sortReportsObjectKeys.map((key)=>{
                sortReportsObject[key] = reportsObject[key];
            });
            setReport(sortReportsObject);
            setLookups({ drivers: result.data.drivers, teams: result.data.teams });
            if (result.data.scores) {
                const days = Object.keys(result.data.scores);
                setWeek(days[0]);
            }
            setStatus(STATUS.READY);
        });
    }, [competition]);

    const RankingCard = React.useMemo(() => {
        if (!competition) return null;
        return (
            <Card style={{ width: 440 }}>
                <Title>Ranking #</Title>
                <Table width={"400px"}>
                    {competition.ranking.map((r, k) => {
                        const st = k + 1;
                        let textPosition = " Place";
                        if (competition.ranking[k + 1] && competition.ranking[k + 1].lastScore === r.lastScore) {
                            textPosition = "Draw";
                        }

                        if (competition.ranking[k - 1] && competition.ranking[k - 1].lastScore === r.lastScore) {
                            textPosition = "Draw";
                        }

                        return (
                            <TableRow key={r.teamId}>
                                <TableData width={"300px"}>
                                    <p style={{ marginRight: 12 }}>
                                        {r.lastScore && ordinal_suffix_of(st)}
                                        {textPosition}
                                    </p>
                                    <p style={{ marginRight: 12 }}>{r.teamName}</p>

                                    {st === 1 && <Image src={trophy} />}
                                </TableData>

                                <TableData width={"100px"}>
                                    {r.lastScore ? parseFloat(r.lastScore).toFixed(3) : "N/A"}
                                </TableData>
                            </TableRow>
                        );
                    })}
                </Table>
            </Card>
        );
    }, [competition]);

    return (
        <Spacer top={4} bottom={8}>
            <ReportFilter status={status} setStatus={setStatus} setCompetition={setCompetition} />
            <Spacer top={8} />
            {status === STATUS.PENDING && <Loading width={60} style={{ display: "block", margin: "auto" }} />}
            {status === STATUS.READY && !competition && (
                <Spacer all={10} style={{ textAlign: "center" }}>
                    <Icon.TrendUp />
                    <SubTitle>Select a competition to start</SubTitle>
                </Spacer>
            )}
            {RankingCard}

            {status === STATUS.READY && competition && report && (
                <Spacer top={8}>
                    <CustomTitle>
                        <div>
                            <span>Report per team</span>
                            <Spacer left={4} inline>
                                <ButtonGroup>
                                    {Object.keys(report).map((date) => (
                                        <Button
                                            key={date}
                                            type={date === week ? "secondary" : null}
                                            onClick={() => setWeek(date)}
                                        >
                                            W {moment(date).isoWeek()}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Spacer>
                        </div>
                        <LegendArea onClick={() => setShowHelp(true)}>
                            <span style={{ fontSize: Theme.font.small.size }}>Legend</span>
                            <small
                                style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                    //  width: '1em',
                                    height: "1em",
                                    backgroundColor: Theme.colors.info.bg,
                                    borderColor: Theme.colors.info.border,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    fontSize: Theme.font.small.size,
                                    lineHeight: Theme.font.medium.lineHeight,
                                    padding: "4px 8px",
                                    borderRadius: "100px",
                                    verticalAlign: "middle",
                                    margin: "-2px 0 0 8px",
                                    cursor: "pointer",
                                }}
                            >
                                ?
                            </small>
                        </LegendArea>

                        {!showHelp ? null : (
                            <Modal
                                visible={true}
                                setVisible={() => setShowHelp(false)}
                                title={"Legend"}
                                drawer={"right"}
                            >
                                <ThirdTitle>Score 10</ThirdTitle>
                                <Text>Driver met the competition goal.</Text>
                                <Spacer bottom={5} />

                                <ThirdTitle>Score 0</ThirdTitle>
                                <Spacer bottom={1} />
                                <Text>Driver did not meet the competition goal.</Text>

                                <Spacer bottom={3} />
                                <ThirdTitle>Value Avg</ThirdTitle>
                                <Spacer bottom={1} />
                                <Text>
                                    Driver had a 'null' value for this metric, and to normalize the team calculation, we
                                    simply assign the avg from all the other drivers on the team.
                                </Text>
                            </Modal>
                        )}
                    </CustomTitle>
                    <ReportTable data={report} lookups={lookups} week={week} />
                </Spacer>
            )}
        </Spacer>
    );
};

const DRIVER_WIDTH_MULTIPLIER = 8.8;
const ReportTable = ({ data, week, lookups }) => {
    const teamsMetric = data[week];

    if (!teamsMetric) {
        return <SubTitle>No Data Found</SubTitle>;
    }

    return (
        <>
            {Object.keys(teamsMetric).map((teamId) => {
                const team = teamsMetric[teamId];
                const teamDrivers = team.drivers ? Object.keys(team.drivers) : [];
                let total = 0.0;
                return (
                    <div key={teamId} style={{ marginTop: 22, overflowX: "auto" }}>
                        <ThirdTitle>{lookups.teams[teamId]}</ThirdTitle>
                        <Table style={{ display: "inline-flex" }}>
                            <TableHeader
                                headers={[
                                    { width: "168px", label: "Metric" },
                                    { width: "75px", label: "Weight" },
                                ].concat(
                                    teamDrivers.map((driverId) => {
                                        const d = lookups.drivers[driverId];
                                        const width = d.length * DRIVER_WIDTH_MULTIPLIER;

                                        return {
                                            width: width < 102 ? 114 + "px" : width + "px",
                                            label: d.toLowerCase(),
                                            style: { justifyContent: "end", textTransform: "capitalize" },
                                        };
                                    })
                                )}
                                style={{ display: "inline-flex" }}
                            />
                            <TableHeader
                                headers={[
                                    { width: "168px", label: "" },
                                    { width: "75px", label: "" },
                                ].concat(
                                    teamDrivers.map((driverId) => {
                                        const d = lookups.drivers[driverId];
                                        const width = d.length * DRIVER_WIDTH_MULTIPLIER;
                                        return {
                                            width: width < 102 ? 114 + "px" : width + "px",
                                            label: "Score / Value",
                                            style: { justifyContent: "end" },
                                        };
                                    })
                                )}
                                style={{ display: "inline-flex" }}
                            />
                            {team.metrics.map((metric) => {
                                return (
                                    <TableRow key={metric.metricSource + metric.metric + metric.metricTimeframe}>
                                        <TableData
                                            width={"168px"}
                                            style={{
                                                flexDirection: "column",
                                                alignItems: "start",
                                                gap: 4,
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            <strong>{metric.metric}</strong>
                                            <small>
                                                [{metric.metricSource}] {metric.metricTimeframe}
                                            </small>
                                        </TableData>
                                        <TableData width={"75px"}>{metric.weight}</TableData>
                                        {teamDrivers.map((d) => {
                                            let score = "NA";
                                            let calculatedScore = "NA";
                                            let avg = false;
                                            const driver = team.drivers[d] || null;
                                            if (driver) {
                                                const driverMetric = driver[metric.metric];
                                                if (driverMetric) {
                                                    score = driverMetric.avg ? "NA" : driverMetric.score;
                                                    calculatedScore = parseFloat(driverMetric.scoreCalculated).toFixed(
                                                        3
                                                    );
                                                    avg = driverMetric.avg;
                                                }
                                            }

                                            const driverName = lookups.drivers[d];
                                            const width = driverName.length * DRIVER_WIDTH_MULTIPLIER;

                                            return (
                                                <TableData
                                                    width={width < 102 ? 114 + "px" : width + "px"}
                                                    style={{ justifyContent: "end", fontSize: 12 }}
                                                    key={teamId + driverName}
                                                >
                                                    {score} / {avg && "AVG"} {calculatedScore}
                                                </TableData>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}

                            <TableFooter style={{ justifyContent: "normal" }}>
                                <TableData width="232px" style={{ padding: 0 }}>
                                    <strong>Weekly Average:</strong>
                                </TableData>
                                {teamDrivers.map((d) => {
                                    const driverName = lookups.drivers[d];
                                    const width = driverName.length * DRIVER_WIDTH_MULTIPLIER;
                                    const driverTotal = parseFloat(team.drivers[d]?.total || 0).toFixed(3);
                                    total += parseFloat(driverTotal);
                                    return (
                                        <TableData
                                            key={driverName}
                                            width={width < 102 ? 114 + "px" : width + "px"}
                                            style={{ justifyContent: "end" }}
                                        >
                                            {driverTotal}
                                        </TableData>
                                    );
                                })}
                                {teamDrivers.length > 0 && (
                                    <TableData width={"165px"}>
                                        <strong>Team Score</strong>: {parseFloat(total / teamDrivers.length).toFixed(3)}
                                    </TableData>
                                )}
                            </TableFooter>
                        </Table>
                    </div>
                );
            })}
        </>
    );
};

const ReportFilter = ({ status, setStatus, setCompetition }) => {
    const [competitions, setCompetitions] = useState([]);
    const [station] = useSelectedStation();
    const { register, handleSubmit, watch } = useForm();

    const onSubmit = (data) => {
        const competitionIndex = data.competition[0];
        setCompetition(competitions[competitionIndex]);
    };

    useEffect(() => {
        API.fetchCompetitions(station).then((response) => {
            setCompetitions(response?.data?.data?.competitors || []);
            setStatus(STATUS.READY);
        });
    }, [station]);

    return (
        <Spacer
            top={5}
            as="form"
            name="filter"
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", alignItems: "flex-end" }}
        >
            <StationSelector />
            <Spacer right={5} inline />
            <TagInput
                ref={register}
                name="competition"
                label="Competition Name"
                visibleOptionsQty={6}
                options={competitions.map((c, k) => ({ name: c.name, value: k }))}
                singleOption
                placeholder={(() => {
                    if (!competitions.length && status === STATUS.PENDING) return "Loading...";

                    if (competitions.length && status === STATUS.READY && !watch("competition").length)
                        return "Select a competition...";

                    return "";
                })()}
                onChange={()=>setTimeout(()=>handleSubmit(onSubmit)(),1)}
            />
        </Spacer>
    );
};

const ordinal_suffix_of = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};

export { ReportTeamsApp };
