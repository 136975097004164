import React, {useState} from 'react';
import {dialogPromise} from "../../../../components/Dialog";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import withCubeData from "../../components/withCubeData";
import InitialForm from './InitialForm';
import EditChartForm from '../EditChartForm';
import useChartConfig from '../../hooks/useChartConfig'

const ChartWizardForm = withCubeData(({
    success, cancel, idPanel,
    loadingState,
    availableMembers,
    ...cubeProps
}) => {
    const chartConfig = useChartConfig();
    const chartConfigDispatcher = chartConfig[1];
    const [step, setStep] = useState(1);

    const handleSubmitInitialForm = (schema) => {
        chartConfigDispatcher({action:'SET_SCHEMA',schema:schema})
        setStep(2);
    };

    const renderContent = () => {
        if(loadingState === true || !availableMembers || !availableMembers.length) {
            return <Loading style={{height:50,margin:'0 auto',display:'block'}} />;
        }

        if (step === 1) {
            return (
                <InitialForm
                    {...cubeProps}
                    chartConfig={chartConfig}
                    availableMembers={availableMembers}
                    onSubmit={handleSubmitInitialForm}
                    onCancel={cancel}
                />
            );
        }
        return (
            <EditChartForm
                {...cubeProps}
                query={cubeProps.query}
                onSubmit={success}
                chartConfig={chartConfig}
                defaultTitle='Widget title'
                onCancel={cancel}
                loadingState={loadingState}
            />
        );
    };

    return (
        // @ts-ignore
        <Modal
            width={(step === 1) ? '340px' : '860px'}
            visible={true}
            removeHeader
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {renderContent()}
        </Modal>
    )
});



export const openChartWizardForm = (idPanel) => dialogPromise((success, cancel)=> (
    <ChartWizardForm
        success={success}
        cancel={cancel}
        idPanel={idPanel}
    />
))