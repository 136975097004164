import React from 'react';
import styled from "styled-components";

export const Text = styled.div`
    font-family: circe-rounded, serif;
    color: #516F90;
    font-size: 16px;

    &.info {
        color: #516F90;
    }

    &.error {
        color: #D94C53;
    }

    &.success {
        color: #00A38D;
    }

`;


type Props = {
    style?: string;
    fontSize?: 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24;
    fontWeight?: 'bold' | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "normal" | number | "bolder" | "lighter" | undefined;
    color?: string;
};

const DSPText: React.FC<Props> = ({
                                      fontWeight,
                                      fontSize = 16,
                                      children,
                                      style,
                                      color
                                  }) => {
    return (
        <div className={style ? `text ${style}` : 'text'}
             style={{fontWeight: fontWeight, fontSize: fontSize, color: color}}>
            {children}
        </div>
    );
};

export default DSPText;
