import ComboboxRecoil, {
    ComboboxRecoilTypeBase
} from "../../../components/Form/Combobox/combobox.recoil";
import React, {useCallback} from "react";
import {VMPlanCategory} from "../state/VehicleMaintenancePlanCategory";
import {useSetRecoilState} from "recoil";

interface ComboboxRecoilCategoryType extends ComboboxRecoilTypeBase {
    typeId: number
}

const VehiclePlanCategoryCombobox = (props: ComboboxRecoilCategoryType) => {
    const addNewCategory = useSetRecoilState(VMPlanCategory(props.typeId))

    const handleChange = useCallback((data) => {
        if(data?.id === null) {
            addNewCategory(data)
        }
        if(props?.onChange) props.onChange(data)
    },[addNewCategory, props])

    return <ComboboxRecoil
            {...props}
            onChange={handleChange}
            store={VMPlanCategory(props.typeId)}
            textOnNullValue={'You need to select a type first'}
        />
}

export default VehiclePlanCategoryCombobox