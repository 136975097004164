import ComboboxRecoil, {
    ComboboxRecoilTypeBase
} from "../../../components/Form/Combobox/combobox.recoil";
import React from "react";
// import { VMTaskPriority } from "../state/VehicleMaintenanceTaskPriority";
import {VehicleAmzStatusState} from "../../../domain/vehicle/state/VehicleAmzStatusState";

const TaskAmzStatusCombobox = (props: ComboboxRecoilTypeBase) => {
    return <ComboboxRecoil {...props} store={VehicleAmzStatusState} />
}

export default TaskAmzStatusCombobox