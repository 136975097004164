import React, {useEffect, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
// @ts-ignore
import Loading from "./Loading";
import styled from "styled-components";
import {Icon} from "@dspworkplace/ui";
// @ts-ignore
import {getChannelIconImage} from "./utils";
// @ts-ignore
import OnlineIndicator from "./OnlineIndicator";
import {showErrorToast} from "../Utilities";
import {Client} from "twilio-chat";


const ImageChannel = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    overflow: hidden;
    position: relative;
    border: ${props => (props.border ? props.border : "1px solid #516F90")};
    border-radius: 50%;
    cursor: pointer;

    svg {
        border-radius: 50%;
    }

    img {
        height: 100%;
        width: 100%;
        overflow: scroll;
        margin: auto;
        object-fit: cover;
    }

    ${props =>
        props.bot
            ? `
        border: 1px solid #F7931E;
        align-items: center;
        justify-content: center;
        svg {
         border-radius: unset;
        }
    `
            : ""}
`;

type Props = {
    channel: Channel;
    kind: string;
    client?: Client;
};

const ChannelIcon: React.FC<Props> = ({channel, kind}) => {
    const [state, setState] = useState({loading: kind === 'Duo', image: channel.attributes["image"]});
    const {loading, image} = state;

    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on("updated", ({channel}) => {
                if (channel && channel.attributes["kind"] === "Group" && channel.attributes["image"]) {
                    // setImage(channel.attributes["image"]);
                    setState(currState => ({...currState, image: channel.attributes["image"]}));
                }
            });
            getChannelIconImage(channel)
                .then(image => {
                    // console.log({image});
                    // setImage(image);
                    // setLoading(false);
                    setState(currState => ({...currState, image: image, loading: false}));
                })
                .catch(async (e) => {
                    // setLoading(false);
                    setState(currState => ({...currState, loading: false}));
                    await showErrorToast(e, 'Error in Channel Icon.')
                });
        } else {
            setState(currState => ({...currState, loading: false}));
        }
        return () => {
            if (channel) {
                channel.removeAllListeners();
            }
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <Loading imgHeight={"36px"} imgWidth={"36px"}/>;
    }

    if (kind === "Bot") {
        return (
            <>
                <ImageChannel bot>
                    <Icon.DspwNetwork size="32px" color="#F7931E"/>
                </ImageChannel>
            </>
        );
    }

    if (kind === "Group" && !image) {
        return (
            <>
                <ImageChannel>
                    <Icon.Group size="40px" color="#516F90"/>
                </ImageChannel>
            </>
        );
    }

    if (kind === "Duo" && !image) {
        return (
            <div style={{display: "flex"}}>
                <ImageChannel>
                    <Icon.Person size="40px" color="#516F90"/>
                </ImageChannel>
                <OnlineIndicator channel={channel} type={'dot'}/>
            </div>
        );
    }

    if (kind === "Duo" && image) {
        return (
            <div style={{display: "flex"}}>
                <ImageChannel>
                    <img src={image} alt={"Channel Icon"} onError={(e)=>{
                        //@ts-ignore
                        e.target.src = 'https://s3.amazonaws.com/dsp.data.storage.general/d92b41c8_2021_08_20_icon-person.png';
                    }}/>
                </ImageChannel>
                <OnlineIndicator channel={channel} type={'dot'}/>
            </div>
        );
    }

    if (!image) {
        return (
            <>
                <ImageChannel>
                    <Icon.Group size="40px" color="#516F90"/>
                </ImageChannel>
            </>
        );
    }

    return (
        <>
            <ImageChannel>
                <img src={image} alt={"Channel Icon"}/>
            </ImageChannel>
        </>
    );
}

function propsAreEqual(prev, next) {
    return prev.channel.sid === next.channel.sid;
}

const MemoizedChannelIcon = React.memo(ChannelIcon, propsAreEqual);
// export default ChannelIcon;
export default MemoizedChannelIcon;
