import React, {useEffect, useState} from "react";
import {VMPlanCategory} from "../../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanCategory";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {portalPromise} from "../../../../../components/Dialog";
import {Button, Icon, Input, Spacer, ThirdTitle, Toggle, Theme, Text, } from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import Loading from "../../../../../components/Loading";
import {useForm} from "react-hook-form";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../../../components/Table";
import Empty from "../../../../../components/Empty";
import {openTemplateNotificationForm} from "./CreateTemplateNotificationForm";
import {confirmPortal} from "../../../../../components/Confirm";
import VehiclePlanTypeCombobox from "../../../../../domain/vehicle_maintenance_plan/components/TypeCombobox";
import VehiclePlanCategoryCombobox from "../../../../../domain/vehicle_maintenance_plan/components/CategoryCombobox";
import {toast} from "../../../../../components/Toasts";
import {openVehicleAssignmentForm} from "./VehicleAssignmentForm";
import styled from 'styled-components'

export const CustomErrorMessage = styled.span`
    position:absolute;
    bottom:-40px;
    font-size:12px;
    color: ${Theme.colors.error.text};
`

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

const TemplateRulesForm = ({rules, setRules, vehicleQuantity = 0,planId}) => {
    const onSubmit = (data,resetHandler) => {
        openTemplateNotificationForm({rule:data}).then(async notificationData => {
            if(!notificationData) return;

            if(!data.notifications) data.notifications = [];
            data.notifications = notificationData;

            if(vehicleQuantity > 0){
                data.vehicles = await openVehicleAssignmentForm({planId: planId})
            }

            setRules([...rules,data])
            resetHandler()
        })
    }

    return (
        <div>
            <ThirdTitle style={{fontWeight:'normal'}}>Rules *</ThirdTitle>
            <Table>
                <TableHeader
                    headers={[
                        {label: 'Type', width: '140px'},
                        {label: 'Category', width: '220px'},
                        {label: 'Required', width: '90px'},
                        {label: 'Service Interval Miles', width: '160px'},
                        {label: 'Service Interval Days', width: '160px'},
                        {label: '', width: '120px'}
                    ]}
                />
                {rules.length > 0 ?
                    rules.map((rule,key) => <RuleItem rule={rule} key={key} rules={rules} setRules={setRules} index={key} />)
                    :
                    <TableRow style={{margin:'0 auto'}}>
                        <Empty />
                    </TableRow>
                }
                <TableFooter style={{justifyContent: 'initial'}} compact>
                    <RuleForm onSubmit={onSubmit} />
                </TableFooter>
            </Table>
        </div>
    )
}

const RuleItem = ({rule,rules,setRules,index}) => {
    const [view, setView] = useState('list');
    const handleEditNotification = ({index,rule}) => {
        openTemplateNotificationForm({notifications:rule.notifications,rule:rule}).then(notificationData => {
            if(!notificationData) return;

            rule.notifications = notificationData;
            rules[index] = rule
            setRules([...rules])
        })
    }

    const onSubmit = async (data) => {
        openTemplateNotificationForm({notifications:rule.notifications,rule:data}).then(notificationData => {
            if(!notificationData) return;

            data.notifications = notificationData;
            rules[index] = data
            setRules([...rules])
            setView('list');
        })
    }

    if(view === 'form'){
        return <RuleForm rule={rule} onSubmit={onSubmit} />
    }

    return (
        <TableRow>
            <TableData width='140px'>{rule?.type?.label}</TableData>
            <TableData width='220px'>{rule?.category?.label}</TableData>
            <TableData width='90px'>{rule.required ? 'Yes': 'No'}</TableData>
            <TableData width='160px'>{rule?.category?.isLastMileServiceRequired === true ? rule.service_interval_miles : 'Not Configured'}</TableData>
            <TableData width='160px'>{rule?.category?.isLastServiceDateRequired === true ? (rule.service_interval_days != 0 ? rule.service_interval_days : 'Not Configured') :  'Not Configured'}</TableData>
            <TableData width='120px'>
                <div style={{position:'relative',display:'flex',justifyContent:'center',alignItems: 'center',padding:4, cursor:'pointer'}} onClick={()=>{handleEditNotification({index,rule})}}>
                    <Icon.Megaphone size={'16px'} />
                    <span style={{position:'absolute', right: -3, top: -6, background:'#ccc',fontSize:12,height:14,width:14,textAlign:'center',borderRadius:'100%'}}>{rule.notifications.length}</span>
                </div>
                <Spacer left={2}>
                    <Icon.Edit size={'18px'} style={{cursor: 'pointer'}} onClick={()=>setView('form')} />
                </Spacer>
                <Spacer left={2}>
                <Icon.Times size={'18px'} onClick={async () => {
                    if (rule.vehicleMaintenanceTasks.filter((t) => 'archived' != t.status).length != 0) {
                        let res = await confirmPortal({
                            icon: true,
                            text: `There is already an active task for the current rule. Are you sure?`
                        })
                        if (res !== false) {
                            setRules(currentRules => {
                                currentRules.splice(index, 1)
                                return [...currentRules]
                            });
                        }
                    } else {
                        setRules(currentRules => {
                            currentRules.splice(index, 1)
                            return [...currentRules]
                        });
                    }
                }} />
                </Spacer>
            </TableData>
        </TableRow>
    )
}

const RuleForm = ({rule,onSubmit}) => {
    const initialTemplateFormValues = {service_interval_days: 0, service_interval_miles:0,category:0};
    const defaults = rule ? {...rule,category:{...rule?.category, ...(rule?.category?.id && {value: rule?.category?.id})}, type:{...rule.type, ...(rule?.type?.id && {value: rule?.type?.id})}} : initialTemplateFormValues;
    
    const [typeId, setTypeId] = useState(defaults?.type?.value);

    const {register,handleSubmit, errors, reset, control, getValues, watch, setValue} = useForm({defaultValues:defaults})

    const onSubmitRule = data => {
        const type = {...data.type};
        delete type.children;

        data.type = type;

        onSubmit(data,()=>reset())
    }

    const openImportSpreedHelper = () => {
        return portalPromise((success, cancel)=>{
            return <ImportSpreedHelper
                success={success}
                cancel={cancel}
            />
        })
    }

    const watchCategory = watch("category");
    
    return (
        <form onSubmit={handleSubmit(onSubmitRule)}>
            <TableData width={`140px`} style={{ position: "relative" }}>
                {(rule && rule?.vehicleMaintenanceTasks?.filter((t) => 'archived' != t.status).length != 0) ?
                    rule?.type?.label
                    :
                    <VehiclePlanTypeCombobox
                        disabled={true}
                        name={"type"}
                        control={control}
                        size={"full"}
                        onChange={(type) => {
                            setTypeId(type.value);
                            setValue("category", 0);
                        }}
                        rules={{ required: "Choose a type" }}
                        isAdd={false}
                    />
                }
                {errors.type && <CustomErrorMessage style={{ bottom: -6 }}>{errors.type.message}</CustomErrorMessage>}
            </TableData>
            <TableData width={`220px`} style={{ position: "relative" }}>
                {(rule && rule?.vehicleMaintenanceTasks?.filter((t) => 'archived' != t.status).length != 0) ?
                    rule?.category?.label
                    :
                    <VehiclePlanCategoryCombobox
                        name={"category"}
                        control={control}
                        size={"full"}
                        rules={{
                            validate: (value) => value != 0 || "Choose a category",
                        }}
                        typeId={typeId}
                        isAdd={false}
                    />
                }
                {errors.category && (<CustomErrorMessage style={{ bottom: -6 }}>{errors.category.message}</CustomErrorMessage>)}
            </TableData>
            <TableData width={"90px"}>
                <Toggle
                    name="required"
                    on={defaults.required}
                    //onChange={e => isRequired(e.target.checked)}
                    ref={register}
                />
            </TableData>
            <TableData width={"170px"} style={{ position: "relative" }}>
                {watchCategory?.isLastMileServiceRequired === true ? (
                    <Input
                        name={"service_interval_miles"}
                        ref={register({
                            required:"Field is required",
                            min:{
                                value:1,
                                message:"minimum 1 mile required"
                            }
                        })}                        
                        type={"number"}
                        size={"small"}
                        invalid={errors?.service_interval_miles}
                        error={errors.service_interval_miles && errors.service_interval_miles.message}
                    />
                ): 
                    <span style={{cursor:'pointer',display:'block'}} onClick={()=>openImportSpreedHelper()}>
                        <ThirdTitle style={{display: 'inline'}}> - </ThirdTitle>
                        <Question>?</Question>
                    </span>}
            </TableData>
            <TableData width={"170px"} style={{ position: "relative" }}>
                {watchCategory?.isLastServiceDateRequired === true ? (
                    <Input
                        name={"service_interval_days"}
                        ref={register({
                            required:"Field is required",
                            min:{
                                value:1,
                                message:"minimum 1 day required"
                            }
                        })}
                        type={"number"}
                        size={"small"}
                        invalid={errors?.service_interval_days}
                        error={errors.service_interval_days && errors.service_interval_days.message}
                    />
                ): <span style={{cursor:'pointer',display:'block'}} onClick={()=>openImportSpreedHelper()}>
                        <ThirdTitle style={{display: 'inline'}}> -  </ThirdTitle>
                        <Question>?</Question>
                    </span>}
            </TableData>
            <TableData width={"120px"}>
                <Button type="secondary">{rule ? "Save" : "Add"}</Button>
            </TableData>
        </form>
    );
}

const ImportSpreedHelper = ({success,cancel}) =>(
    <Modal
        width={'540px'}
        visible={true}
        title={'Help For Service Inteval Miles and Days'}
        closeOnOverlay={true}
        setVisible={cancel}
    >
        <Spacer top={2} />
            <Text>To Fill the values following steps.</Text>
        <Spacer top={4} />
            <Text>First you go to Maintenance Types</Text>
            <img style={{width:'100%'}} src={'https://s3.amazonaws.com/dsp.data.storage.general/VehicleMaintananceType.png'} />
        <Spacer top={2} />
            <Text>Edit Vehicle Maintenance Type and "Last Mile Service Required" and "Last Service Date Required" checkBox check or uncheck acording your need.</Text>
            <img style={{width:'100%'}} src={'https://s3.amazonaws.com/dsp.data.storage.general/EditVehicleMaintananceType.png'} />
        <Spacer top={2} />
    </Modal>
)

const TemplateForm = ({ data, success = () => {}, cancel = () => {}}) => {
    const defaultValues = typeof data === 'object' ?? data
    const isPromise = typeof data === 'object' && typeof data.then === 'function' ;
    const [state,setState] = useState({status :isPromise ? 'pending'  : 'ready'});
    const [rules, setRules] = useState([]);
    const {register,handleSubmit, errors, reset, getValues} = useForm({defaultValues:defaultValues})

    const onSubmit = (data) => {
        success({name:data.name,rules:rules})
    }

    useEffect(() => {
        if(!isPromise) return
        data.then(resolve => {
            reset(resolve)
            if(resolve.rules) setRules(resolve.rules)
            setState({status:'ready'})
        }).catch(err => {
            toast({
                type: "error",
                title: 'Edit Vehicle Maintenance Plan',
                content: err,
                useClose: true,
                timeout: 5000
            });
            cancel()
        })

    },[cancel, data, isPromise, reset])
    const vehicleQuantityValue = getValues('vehicle_quantity')
    const PlanIdValue = getValues('id')
    return (
        <Modal
            width={'980px'}
            visible={true}
            title={!data ? 'New Vehicle Maintenance Template' : 'Editing Vehicle Maintenance Template'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === 'pending' &&
                <Loading style={{height: 80,display:'block', margin:'0 auto'}}/>
            }

            {state.status === "ready" &&
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type={'hidden'} name={'vehicle_quantity'} />
                        <input type={'hidden'} name={'id'} />
                        <Input
                            type={'text'}
                            name={'name'}
                            label={'Name'}
                            ref={register({required:'Required field'})}
                            error={errors.name && errors.name.message}
                            Required
                        />
                        <Spacer top={2} />
                        <Input
                            type={'hidden'}
                            name={'rule'}
                            ref={register({
                                validate: value => {
                                    if (value && value < 1) {
                                        return 'Please add the rule'
                                    }
                                }
                            })}
                            defaultValue={rules.length}
                            error={errors.rule && errors.rule.message}
                        />
                    </form>

                    <Spacer top={5} />
                    <TemplateRulesForm
                        rules={rules}
                        setRules={setRules}
                        vehicleQuantity={vehicleQuantityValue}
                        planId={PlanIdValue}
                    />
                    <Spacer top={4} />
                    <Button type='primary' onClick={handleSubmit(onSubmit)} style={{display: 'block', marginLeft: 'auto'}}>Save Template</Button>
                </>
            }
        </Modal>
    );
};

export const openTemplateArchiveForm = async (name) => {
    return confirmPortal({
        icon: true,
        text: `Do you really want to archive template "${name}"`
    });
}

export const openTemplateForm = (data) => {
    return portalPromise((success, cancel)=>{
        return <TemplateForm
            data={data}
            success={success}
            cancel={cancel}
        />
    })
}

