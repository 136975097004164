import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {Icon, Input, Button} from '@dspworkplace/ui';
import StatusColored from "../../../../components/Form/Combobox/Options/statusColored";
import useIsFirstRender from "../../../../hooks/useIsFirstRender";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import Tooltip from "../../../../components/Tooltip/tooltip";


const priorities = {
    Critical: { label: "Critical", value: "Critical", color: 'red' },
    Urgent: { label: "Urgent", value: "Urgent", color: 'orange' },
    Soon: { label: "Soon", value: "Soon", color: 'yellow' },
    "Can Wait" :{ label: "Can Wait", value: "Can_wait", color: 'green' },
    Unknown: { label: "Unknown", value: "Unknown", color: 'gray' },
};

export const PriorityCol = ({row,column}) => {
    return (
       <Tooltip content={column?.validValuesKey?.[row.priority]?.label.toUpperCase()} direction={'top'}>
           <StatusColored showLabel={false} row={{label: row.priority, color: column?.validValuesKey?.[row.priority]?.color || null}} />
       </Tooltip>
    )
}

const MiniTable = styled.ul`
    margin: 0;
    padding: 8px 0;
    height: 100%;

    > li {
        display: block;
        border: none;
        padding-bottom: 8px;
        line-height: normal;

        &:last-child {
            padding-bottom: 0;
        }

        > span {
            display: inline-block;
            color: #707070;
            font-size: 14px;
            margin-right: 4px;
        }
        > p {
            display: inline-block;
            font-size: 14px;
            color: #333333;
        }
    }
`;

const SmallInput = styled.div`
    position:relative;
    display: inline-flex;
    input {
        padding: 8px 4px;
        height: 20px;
    }
    button {
        height: 20px;
        line-height: 20px;
        padding:0 2px;
    }
`;
export const VehicleCol = ({row, onRowChange}) => {
    const [mileage, setMileage] = useState(row?.vehicle?.currentMileage | 0)
    const [editMileage, setEditMileage] = useState(false);
    const isFirstRender = useIsFirstRender();
    const mileageInputRef = useRef();

    const handleKey = (e) => {
        e.stopPropagation();
        if(e.keyCode === 13) {
            setMileage(e.target.value)
            setEditMileage(false);
        }

        if(e.keyCode === 27) {
            setEditMileage(false);
        }
    }

    useOutsideClick(
        mileageInputRef,
        (e) => {
            setEditMileage(false);
        },
        "mouseup"
    );

    useEffect(()=>{
        if(!isFirstRender){
            const {vehicle} = row;
            onRowChange({...row,vehicle:{...vehicle,currentMileage:mileage}},true)
        }
    },[mileage]);

    useEffect(()=>{
        if(editMileage && mileageInputRef.current){
            mileageInputRef.current.focus();
            mileageInputRef.current.addEventListener('keydown',handleKey);
        }

        if(!editMileage && mileageInputRef.current){
            mileageInputRef.current.removeEventListener('keydown',handleKey)
        }

        return ()=> {
            if(mileageInputRef.current){
                mileageInputRef.current.removeEventListener('keydown',handleKey)
            }
        }
    },[editMileage,mileageInputRef])

    return (
        <MiniTable>
            <li><span>ID:</span> <p>{row.vehicleId}</p></li>
            <li><span>VIN:</span> <p>{row.VIN}</p></li>
            <li>
                <span>Mileage:</span>
                {!editMileage &&<p>{mileage} <Icon.Edit size={'14px'} style={{cursor:'pointer'}} onClick={()=>{setEditMileage(true)}} /></p>}
                {
                    editMileage &&
                    <SmallInput>
                        <Input
                            ref={mileageInputRef}
                            type='number'
                            size='extraSmall'
                            defaultValue={mileage}
                            onBlur={(e)=>setMileage(e.target.value)}
                        />
                        <Button onClick={()=>setEditMileage(false)}>
                            <Icon.Check
                                size={'20px'}
                                style={{cursor: "pointer"}}
                            />
                        </Button>
                    </SmallInput>
                }
            </li>
        </MiniTable>
    )
}


export const TicketCol = ({row}) => {
    return (
        <MiniTable>
            <li><span>#:</span> <p>{row.id} - 11/02/2021</p></li>
            <li><span>Last Modification by: <br/>`Matt Fisher`</span></li>
        </MiniTable>
    )
}