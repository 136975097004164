import apiFactory from '../../../api/factory';

const {call} = apiFactory();
export const GetTaskLocationLookup = async (props) => {
    const {vendorId} = props

    const params = {
        "actions": {
            "response": {
                "Vehicle\\VehicleMaintenanceLocation": {
                    "findBy": {
                        "criteria": { "vehicleMaintenanceVendor": vendorId },
                        "get": "locations",
                        orderBy: {
                            'name': 'ASC'
                        },
                        "includes": {
                            "0": "id",
                            "1": "name"
                        }
                    }
                }
            }
        }
    };
    const response = await call(params, `/api/lazy/manage/data`, 'post');
    return response.data.data.locations;
}