import React, {useCallback, useEffect, useState} from 'react';
import {DriverFetchTask, DriverRouteShift, FetchTaskSwapShift, MessageOfConversation, MessageConversation} from "./types";
import {useUpdate as useBotDispatch} from "./BotStore";
import Loading, {useLoading} from "./Loading";
// @ts-ignore
import {Channel} from "twilio-chat/lib/channel";
// @ts-ignore
import {engine, getDriverId, getFriendlyName, getMainStationId, getUserId, showToast} from "../Utilities";
// @ts-ignore
import {devLog} from "../utils";
// @ts-ignore
import {ButtonContainer, DriverButton, ScrollContainer} from "./BotTaskInteraction";
import BotTempMessage from "./BotTempMessage";
// @ts-ignore
import InputForBot from "./InputForBot";
// @ts-ignore
import {Button} from "@dspworkplace/ui";
import styled from "styled-components";
import moment from "moment-timezone";

// @ts-ignore
const Container = styled.div`
    width: 300px;
    display: flex;
    row-gap: 2px;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : "column")};;
    margin: 4px 20px 4px 20px;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};

    &.row {
        flex-direction: row;
    }

    &.left {
        flex-direction: row;
        justify-content: flex-start;
    }

    &.right {
        flex-direction: row;
        justify-content: flex-end;
    }

    &.btn {
        padding-left: 8px;
        margin: 8px 20px 8px 20px;
    }
`;

type Props = {
    message: MessageOfConversation;
    twilioMessage?: MessageConversation;
    taskInformation: { id: number, type: string };
    scrollToBottom: () => void;
}

function BotTaskSwapShift({message, taskInformation}: Props) {
    let managerFriendlyName = getFriendlyName();
    // @ts-ignore
    const [systemMsg, setSystemMsg] = useState({
        user: message.user,
        date: message.date,
        text: message.text
    });
    // @ts-ignore
    const dispatch = useBotDispatch();
    // @ts-ignore
    const {loading, setLoading} = useLoading();
    const [taskSwapShift, setTaskSwapShift] = useState<FetchTaskSwapShift>();
    // @ts-ignore
    // const {driverRequester, driverRoute, driverRouteToSwap, driversResult, driverToSwap, task, timezone, timeZoneName} = taskSwapShift;
    // @ts-ignore
    const [stepOneOptions, setStepOneOptions] = useState(true);
    // @ts-ignore
    const [stepTwoOptions, setStepTwoOptions] = useState(false);
    // @ts-ignore
    const [stepThreeOptions, setStepThreeOptions] = useState(false);
    // @ts-ignore
    const [sendMessage, setSendMessage] = useState(false);
    // @ts-ignore
    const [caseTask, setCaseTask] = useState("");
    // @ts-ignore
    const [disabled, setDisabled] = useState(true);
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [placeholder, setPlaceholder] = useState("");
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [submitButtonText, setSubmitButtonText] = useState("Finish Task");
    // @ts-ignore
    const [disableButton, setDisableButton] = useState(true);
    // @ts-ignore
    const [messageToReply, setMessageToReply] = useState("");
    // @ts-ignore
    const [taskCompleted, setTaskCompleted] = useState(false);
    // @ts-ignore
    const [taskLoading, setTaskLoading] = useState(false);
    // @ts-ignore
    const [approvedMessage, setApprovedMessage] = useState<string>("");
    // @ts-ignore
    const [approvedMessageTwo, setApprovedMessageTwo] = useState<string>("");
    // @ts-ignore
    const [rejectedMessageTwo, setRejectedMessageTwo] = useState<string>("");
    const [rejectedMessage, setRejectedMessage] = useState<string>("");

    const generateMessage = (extraMessage, message, rejectMessage, scenario) => {
        if (scenario === 'ACCEPT_TASK') {
            if (typeof extraMessage === "string" && extraMessage.length > 0) {
                return `${message}\n${extraMessage}`;
            }
            return `${message}`;
        }
        if (scenario === 'REJECT_TASK') {
            if (typeof extraMessage === "string" && extraMessage.length > 0) {
                return `${rejectMessage}\n${extraMessage}`;
            }
            return `${rejectMessage}`;
        }
    }

    // @ts-ignore
    const completeTask = async (message?: string) => {
        setDisableButton(true);
        setDisabled(false);
        setTaskLoading(true);
        try {
            let params = {
                case: caseTask,
                task: {
                    id: taskInformation.id,
                    type: taskInformation.type,
                },
                message: generateMessage(message, approvedMessage, rejectedMessage, caseTask),
                messageTwo: generateMessage(message, approvedMessageTwo, rejectedMessageTwo, caseTask),
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getUserId(),
                driverId: getDriverId(),
            };
            devLog(params);
            let response = await engine({hideDefaultMessage: true}).post("/api/complete_task", params);
            devLog(response.data);
            setTaskCompleted(true);
        } catch (e) {
            console.error(e);
            if (typeof e.message === "string") {
                await showToast({
                    type: 'Error',
                    title: 'Error in Task Completion',
                    name: e.message
                });
            }
            if (e.response && e.response.data && typeof e.response.data.message === "string") {
                await showToast({
                    type: 'Error',
                    title: 'Error in Task Completion',
                    name: e.message
                });
            }
        }
    }

    const reset = useCallback(
        () => {
            setStepTwoOptions(false);
            setDisabled(true);
            setDisableButton(true);
            setTaskCompleted(false);
            setTaskLoading(false);
            setCaseTask("");
            setMessageToReply("");
        },
        [],
    );

    const acceptTask = useCallback(
        () => {
            setStepTwoOptions(true);
            setApprovedMessage(`${managerFriendlyName} has approved Swap Shift Request with ${taskSwapShift!.driverToSwap.fullName}. You have been unassigned from your ${taskSwapShift!.driverRouteInformation}, and assigned to ${taskSwapShift!.driverRouteToSwapInformation}.`);
            setApprovedMessageTwo(`${managerFriendlyName} has approved Swap Shift Request with ${taskSwapShift!.driverRequester.fullName}. You have been unassigned from your ${taskSwapShift!.driverRouteToSwapInformation}, and assigned to ${taskSwapShift!.driverRouteInformation}.`);
            setCaseTask("ACCEPT_TASK");
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, taskSwapShift],
    );

    const rejectTask = useCallback(
        () => {
            setStepTwoOptions(true);
            setCaseTask("REJECT_TASK");
            setRejectedMessage(`${managerFriendlyName} has rejected Swap Shift Request with ${taskSwapShift!.driverToSwap.fullName}. You still scheduled for ${taskSwapShift!.driverRouteInformation}.`);
            setRejectedMessageTwo(`${managerFriendlyName} has rejected Swap Shift Request with ${taskSwapShift!.driverRequester.fullName}. You still scheduled for ${taskSwapShift!.driverRouteToSwapInformation}.`);
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, taskSwapShift],
    );


    const appendMessage = async (message?: string) => {
        setDisabled(true);
        setDisableButton(true);
        if (message) {
            setMessageToReply(message);
        }
        await completeTask(message);
    };

    useEffect(() => {
        const abortController = new AbortController();
        engine()
            .post("/api/fetch_task", {
                task: {
                    id: taskInformation.id,
                    type: taskInformation.type,
                },
                taskInformation,
                messageSid: message.sid,
                element: message.element.body,
                attributes: message.element.attributes,
                channelSid: message.element.conversation.sid,
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getUserId(),
                driverId: getDriverId(),
            })
            .then((response) => {
                setSystemMsg({user: message.user!, date: message.date, text: message.text});
                let data: FetchTaskSwapShift = response.data;
                setTaskSwapShift(data);
                setLoading(false);
            })
            .catch(async e => {
                console.error({fetchTaskSwapShift: e});
                if (e.response && e.response.data && e.response.data.message) {
                    await showToast({
                        type: 'Error',
                        title: 'Error in fetching Task.',
                        name: e.response.data.message
                    });
                } else {
                    await showToast({
                        type: 'Error',
                        title: 'Error',
                        name: 'Error in fetching Task.'
                    });
                }
            });
        return () => {
            abortController.abort();
        };
    }, [message.date, message.text, message.user, setLoading, taskInformation.id, taskInformation.type]);

    if (loading) {
        return <Loading containerHeight={"500px"}/>
    }

    return (
        <>
            <ScrollContainer>
                <BotTempMessage message={systemMsg}/>
                <Container className={"btn"}>
                    {taskSwapShift!.drivers.map(item => <DriverButton driver={item} key={item.id} type={'Block'}/>)}
                </Container>
                {stepOneOptions && <Container className={"btn"}>
                    <Button onClick={acceptTask}>Accept</Button>
                    <Button onClick={rejectTask}>Reject</Button>
                </Container>}
                {stepTwoOptions && caseTask === 'ACCEPT_TASK' &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `${taskSwapShift!.driverRequester.fullName} will receive this message: ${approvedMessage}\n${messageToReply}`
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `${taskSwapShift!.driverToSwap.fullName} will receive this message: ${approvedMessageTwo}\n${messageToReply}`
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please type an additional message to the approved drivers or press Finish Task.",
                        }
                    }/>
                </>
                }
                {stepTwoOptions && caseTask === 'REJECT_TASK' &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `${taskSwapShift!.driverRequester.fullName} will receive this message: ${rejectedMessage}\n${messageToReply}`
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `${taskSwapShift!.driverToSwap.fullName} will receive this message: ${rejectedMessageTwo}\n${messageToReply}`
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please type an additional message to the approved drivers or press Finish Task.",
                        }
                    }/>
                </>
                }
                {taskLoading && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Please wait, we are completing the task.",
                }}/>}
                {taskCompleted && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Task Completed!",
                }}/>}
            </ScrollContainer>
            <InputForBot disabled={disabled} submitMessage={appendMessage} placeholder={placeholder}
                         submitButtonText={submitButtonText} disableSendButton={disableButton}/>
        </>
    );
}

export default BotTaskSwapShift;

const Text = styled.div`
    font-size: 16px;
    color: #0071bc;
    display: flex;
    justify-content: flex-start;
`;

const ShiftContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #EAF0F6;
`;

type ShiftProps =
    {
        driverRoute: DriverRouteShift;
        driverRouteToSwap: DriverRouteShift;
        driverToSwap: {
            id: number
        };
        driverRequester: {
            id: number
        };
        drivers: Array<DriverFetchTask>;
    }

// @ts-ignore
function Shift(props: ShiftProps) {
    const {driverRoute, driverRouteToSwap, driverRequester, driverToSwap, drivers} = props;
    let requester = drivers.find(item => item.id === driverRequester.id);
    let toSwap = drivers.find(item => item.id === driverToSwap.id);

    return (
        <>
            <ShiftContainer>
                <Text>{driverRoute.dateCreatedText}</Text>
                <Text>{driverRoute.shiftName}</Text>
                <Text>{driverRoute.startTimeText} - {driverRoute.endTimeText}</Text>
                <ShiftContainer>
                    <Text>{requester!.fullName}</Text>
                    <Text
                        className={"driver"}>{requester!.weekHours.workedHours}/{requester!.weekHours.scheduledHours}</Text>
                </ShiftContainer>
            </ShiftContainer>
            <ShiftContainer>
                <Text>{driverRouteToSwap.dateCreatedText}</Text>
                <Text>{driverRouteToSwap.shiftName}</Text>
                <Text>{driverRouteToSwap.startTimeText} - {driverRouteToSwap.endTimeText}</Text>
                <ShiftContainer>
                    <Text>{toSwap!.fullName}</Text>
                    <Text className={"driver"}>{toSwap!.weekHours.workedHours}/{toSwap!.weekHours.scheduledHours}</Text>
                </ShiftContainer>
            </ShiftContainer>
        </>
    )
}