import React, { useEffect, useState} from "react";
import styled from "styled-components";
import { Button, Dropdown, Input, Spacer, CheckBox, Toggle, Alert, Icon, Theme} from "@dspworkplace/ui";
import { useForm, FormContext } from "react-hook-form";
import { TabList } from "../../components/Tab";
import { MapAddressGeofence } from "../../components/GoogleMap";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import { useAuth, useLocalStorage, getIsEnableSMS, setIsEnableSMS, getCompanyId } from "../../Auth";
import {useSelectedStation} from "../../components/StationSelector";
import toast from "../../components/Toasts/toast";
import {showDefaultErrorToast} from "../../Utilities";
import moment from "moment";
import {confirm} from "../../components/Confirm";
import {getOffset} from "../../components/TimeFormat";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;

    #stationGeofenceDiv {
        display: inline-flex;
        width: 170px;
        div {
            width: 160px;
        }
    }
`;

const formatUTCOffset = (offset) => { // LIKE -07:00
    let sec = Math.abs(offset);
    let formatedOffset = (offset < 0 ? "+" : "-") + ("00" + Math.floor(sec / 60)).slice(-2) + ":" + ("00" + (sec % 60)).slice(-2);
    return formatedOffset;
}

const Tab = ({ children }) => {
    const { onClick, isActive } = useTabState();
    return (
        <h6 onClick={onClick} className={[isActive && "tabActive"]}>
            {children}
        </h6>
    );
};

const Panel = ({ children }) => {
    const isActive = usePanelState();

    return isActive ? <div>{children}</div> : <div style={{ display: "none" }}>{children}</div>;
};

const App = ({ setLoading, action, edit, copy }) => {
    const { api } = useAuth();
    const [storageStations, setStorageStations] = useLocalStorage("stations");
    // const [selected, setSelected] = useLocalStorage("selectedStation");
    const [selected, setSelected] = useSelectedStation();
    const [isNotificationFlag, setIsNotification] = useState(0);
    const [hours, setHours] = useState([]);
    const [optionDailySummeryHours, setOptionDailySummeryHours] = useState([{name:'00',value:'00'},{name:'01',value:'01'},{name:'02',value:'02'},{name:'03',value:'03'},{name:'04',value:'04'},{name:'05',value:'05'},{name:'06',value:'06'},{name:'07',value:'07'},{name:'08',value:'08'},{name:'09',value:'09'},{name:'10',value:'10'},{name:'11',value:'11'},{name:'12',value:'12'},{name:'13',value:'13'},{name:'14',value:'14'},{name:'15',value:'15'},{name:'16',value:'16'},{name:'17',value:'17'},{name:'18',value:'18'},{name:'19',value:'19'},{name:'20',value:'20'},{name:'21',value:'21'},{name:'22',value:'22'},{name:'23',value:'23'}]);
    const [optionDailySummeryTimes, setOptionDailySummeryTimes] = useState([{name:'00',value:'00'},{name:'01',value:'01'},{name:'02',value:'02'},{name:'03',value:'03'},{name:'04',value:'04'},{name:'05',value:'05'},{name:'06',value:'06'},{name:'07',value:'07'},{name:'08',value:'08'},{name:'09',value:'09'},{name:'10',value:'10'},{name:'11',value:'11'},{name:'12',value:'12'},{name:'13',value:'13'},{name:'14',value:'14'},{name:'15',value:'15'},{name:'16',value:'16'},{name:'17',value:'17'},{name:'18',value:'18'},{name:'19',value:'19'},{name:'20',value:'20'},{name:'21',value:'21'},{name:'22',value:'22'},{name:'23',value:'23'},{name:'24',value:'24'},{name:'25',value:'25'},{name:'26',value:'26'},{name:'27',value:'27'},{name:'28',value:'28'},{name:'29',value:'29'},{name:'30',value:'30'},{name:'31',value:'31'},{name:'32',value:'32'},{name:'33',value:'33'},{name:'34',value:'34'},{name:'35',value:'35'},{name:'36',value:'36'},{name:'37',value:'37'},{name:'38',value:'38'},{name:'39',value:'39'},{name:'40',value:'40'},{name:'41',value:'41'},{name:'42',value:'42'},{name:'43',value:'43'},{name:'44',value:'44'},{name:'45',value:'45'},{name:'46',value:'46'},{name:'47',value:'47'},{name:'48',value:'48'},{name:'49',value:'49'},{name:'50',value:'50'},{name:'51',value:'51'},{name:'52',value:'52'},{name:'53',value:'53'},{name:'54',value:'54'},{name:'55',value:'55'},{name:'56',value:'56'},{name:'57',value:'57'},{name:'58',value:'58'},{name:'59',value:'59'}]);

    let defaultValues = {};
    let defMessage = 'Hello [Nickname], this is a reminder that you are currently scheduled to work tomorrow at [ShiftStartTime] and assigned a [scheduledShiftHours] hour [shiftType] shift. See you then!';
    if (edit && edit.id) {
    edit.distance = parseInt(edit.distance);
    let dateTimeDailySummery = edit.dailySummeryRunTime && edit.dailySummeryRunTime.split(':');
    let dateTimeAttandanceNotification = edit.attendanceNotificationRunTime && edit.attendanceNotificationRunTime.split(':');
    let offset = getOffset(new Date(), edit.timezone);
    defaultValues = {
            id: edit.id,
            name: edit.name,
            code: edit.code,
            stationAddress: edit.stationAddress,
            stationGeofence: edit.stationGeofence,
            parkingLotAddress: edit.parkingLotAddress,
            parkingLotGeofence: edit.parkingLotGeofence,
            timezone: edit.timezone,
            distance: edit.distance,
            isStationSummary: edit.isStationSummary,
            addressStation: edit.stationAddress ? edit.stationAddress : "",
            addressParking: edit.parkingLotAddress ? edit.parkingLotAddress : "",
            radiusStation: edit.stationGeofence ? edit.stationGeofence : 80,
            radiusParking: edit.parkingLotGeofence ? edit.parkingLotGeofence : 80,
            isShiftReminderNotification:edit.isShiftReminderNotification,
            notificationTime:edit.notificationTime,
            notificationMessage:edit.notificationMessage ? edit.notificationMessage :defMessage,
            notificationType:edit.notificationType,
            parkingLotLatitude:edit.parkingLotLatitude,
            parkingLotLongitude:edit.parkingLotLongitude,
            stationLongitude:edit.stationLongitude,
            stationLatitude:edit.stationLatitude,
            enableGeofenceParkingLot:edit.enableGeofenceParkingLot,
            enableGeofenceStation:edit.enableGeofenceStation,
            stationType:edit.stationType,
            dailySummeryRunTimeHour: dateTimeDailySummery && dateTimeDailySummery[0],
            dailySummeryRunTimeMinute: dateTimeDailySummery && dateTimeDailySummery[1],
            attendanceNotificationRunTimeHour: dateTimeAttandanceNotification && dateTimeAttandanceNotification[0],
            attendanceNotificationRunTimeMinute: dateTimeAttandanceNotification && dateTimeAttandanceNotification[1],
            utcOffset: formatUTCOffset(offset.offset)
        };

    }
    if (copy && copy.id) {
        copy.distance = parseInt(copy.distance);
        let offset = getOffset(new Date(), copy.timezone);

        let dateTimeDailySummery = copy.dailySummeryRunTime.split(':');
        let dateTimeAttandanceNotification = copy.dailySummeryRunTime.split(':');
        defaultValues = {
            id: copy.id,
            name: "Copy Of " + copy.name,
            code: "Copy Of " + copy.code,
            stationAddress: copy.stationAddress,
            stationGeofence: copy.stationGeofence,
            parkingLotAddress: copy.parkingLotAddress,
            parkingLotGeofence: copy.parkingLotGeofence,
            timezone: copy.timezone,
            distance: copy.distance,
            isStationSummary: copy.isStationSummary,
            addressStation: copy.stationAddress ? copy.stationAddress : "",
            addressParking: copy.parkingLotAddress ? copy.parkingLotAddress : "",
            radiusStation: copy.stationGeofence ? copy.stationGeofence : 80,
            radiusParking: copy.parkingLotGeofence ? copy.parkingLotGeofence : 80,
            isShiftReminderNotification:copy.isShiftReminderNotification,
            notificationTime:copy.notificationTime,
            notificationMessage:copy.notificationMessage ? copy.notificationMessage :defMessage,
            notificationType:copy.notificationType,
            parkingLotLatitude:copy.parkingLotLatitude,
            parkingLotLongitude:copy.parkingLotLongitude,
            stationLongitude:copy.stationLongitude,
            stationLatitude:copy.stationLatitude,
            enableGeofenceParkingLot:edit.enableGeofenceParkingLot,
            enableGeofenceStation:edit.enableGeofenceStation,
            stationType:edit.stationType,
            dailySummeryRunTimeHour: dateTimeDailySummery[0],
            dailySummeryRunTimeMinute: dateTimeDailySummery[1],
            attendanceNotificationRunTimeHour: dateTimeAttandanceNotification[0],
            attendanceNotificationRunTimeMinute: dateTimeAttandanceNotification[1],
            utcOffset: formatUTCOffset(offset.offset)
        };
    }

    useEffect(() => {
        let tempHoursArray = [];
        for(let i=1;i<=24;i++){
            tempHoursArray.push({name:i+'h',value:i});
        }
        setHours(tempHoursArray);

        if (document.querySelector("label[for='stationGeofence']")) {
            document.querySelector("label[for='stationGeofence']").style.width = "160px !important";
        }

        setIsNotification(defaultValues.isShiftReminderNotification);

    }, []);

    const handleIsSentNotification = async()=>{
        if( isNotificationFlag === 0 ){
            setIsNotification(1);
        } else {
            setIsNotification(0);
        }
    }


    const methods = useForm({
        mode: "onBlur",
        defaultValues: defaultValues
    });
    const { register, handleSubmit, errors, getValues, setValue, reset } = methods;
    const { name, code, timezone, isStationSummary,isShiftReminderNotification,notificationMessage, enableGeofenceStation, enableGeofenceParkingLot } = getValues();

    const onSubmit = async data => {
        setLoading(true);
        let offset = getOffset(new Date(), data.timezone);
        if (edit && edit.id) {
            let conf = true;
            if( edit.timezone != data.timezone ) {
                console.log(edit.id,edit.timezone,data.timezone);
                conf = await confirm({
                    icon: true,
                    text: 'PLEASE NOTE: After changing the station timezone, the Station\'s Shift Types will be modified by the time shift. Please review and edit those as needed!',
                });
            }
            if (conf) {
                let param = {
                    actions: {
                        update: {
                            Station: {
                                update_Station_1: {
                                    findBy: {
                                        id: edit.id
                                    },
                                    updateRecord: {
                                        code: data.code,
                                        name: data.name,
                                        stationAddress: data.stationAddress,
                                        stationGeofence: data.stationGeofence ? data.stationGeofence.toString() : 80,
                                        parkingLotAddress: data.parkingLotAddress,
                                        parkingLotGeofence: data.parkingLotGeofence ? data.parkingLotGeofence.toString() : 80,
                                        timezone: data.timezone,
                                        stationLongitude: data.stationLongitude ? data.stationLongitude.toString() : 91.0,
                                        stationLatitude: data.stationLatitude ? data.stationLatitude.toString() : 0.25,
                                        parkingLotLongitude: data.parkingLotLongitude ? data.parkingLotLongitude.toString() : 91.0,
                                        parkingLotLatitude: data.parkingLotLatitude ? data.parkingLotLatitude.toString() : 0.25,
                                        isStationSummary: data.isStationSummary,
                                        isShiftReminderNotification: data.isShiftReminderNotification,
                                        notificationTime: data.notificationTime,
                                        notificationType: data.notificationType,
                                        notificationMessage: data.notificationMessage,
                                        enableGeofenceStation: data.enableGeofenceStation,
                                        enableGeofenceParkingLot: data.enableGeofenceParkingLot,
                                        stationType: data.stationType,
                                        dailySummeryRunTime: data.dailySummeryRunTimeHour + ':' + data.dailySummeryRunTimeMinute,
                                        attendanceNotificationRunTime: data.attendanceNotificationRunTimeHour + ':' + data.attendanceNotificationRunTimeMinute,
                                        utcOffset: formatUTCOffset(offset.offset),
                                    }
                                }
                            }
                        }
                    }
                };
                try {
                    await api.post("/api/lazy/manage/data", param);
                    data.id = edit.id;
                    data.idList = edit.idList;
                    data.dailySummeryRunTime = data.dailySummeryRunTimeHour + ':' + data.dailySummeryRunTimeMinute;
                    data.attendanceNotificationRunTime = data.attendanceNotificationRunTimeHour + ':' + data.attendanceNotificationRunTimeMinute;
                    const updateShiftTimeZoneParams = {
                        "actions":{
                            "response":{
                                "Station": {
                                    "custom":{
                                        "functionName" : "updateShiftAndDriverRouteWithNewTimezone",
                                        "get":"result",
                                        "criteria": {
                                            "id":edit.id,
                                            "oldTimeZone":edit.timezone,
                                            "newTimeZone":data.timezone,
                                        }
                                    }
                                }
                            }
                        }
                    } 
                    await api.post("/api/lazy/manage/data", updateShiftTimeZoneParams);
                    await action.edit(data);
                } catch (error) {
                    await showDefaultErrorToast(error, 'Error updating Station.');
                }
            } else {
                data.timezone = edit.timezone;
                await action.edit(data);
            }
        } else {
            let params = {
                actions: {
                    create: {
                        Station: {
                            station_1: {
                                company: localStorage.getItem("company"),
                                code: data.code,
                                name: data.name,
                                stationAddress: data.stationAddress,
                                stationLatitude: 91.0,
                                stationLongitude: 0.25,
                                stationGeofence: data.radiusStation,
                                parkingLotAddress: data.parkingLotAddress,
                                parkingLotGeofence: data.radiusParking,
                                timezone: data.timezone,
                                parkingLotLongitude: 91.0,
                                parkingLotLatitude: 0.25,
                                isStationSummary: data.isStationSummary,
                                isShiftReminderNotification:data.isShiftReminderNotification,
                                notificationTime:data.notificationTime,
                                notificationType:data.notificationType,
                                notificationMessage:data.notificationMessage,
                                enableGeofenceStation: data.enableGeofenceStation,
                                enableGeofenceParkingLot: data.enableGeofenceParkingLot,
                                stationType:data.stationType,
                                dailySummeryRunTime: data.dailySummeryRunTimeHour + ':' + data.dailySummeryRunTimeMinute,
                                attendanceNotificationRunTime: data.attendanceNotificationRunTimeHour + ':' + data.attendanceNotificationRunTimeMinute,
                                utcOffset: formatUTCOffset(offset.offset)
                            }
                        }
                    }
                }
            };
            try {
                const response = await api.post("/api/lazy/manage/data", params);
                data["id"] = response.data.data.responseId;
                data.dailySummeryRunTime = data.dailySummeryRunTimeHour + ':' + data.dailySummeryRunTimeMinute;
                data.attendanceNotificationRunTime = data.attendanceNotificationRunTimeHour + ':' + data.attendanceNotificationRunTimeMinute;
                if (!selected) {
                    setSelected(data["id"]);
                }
                let storageStations1 = storageStations;
                storageStations1.push({'id':data["id"],'name':data.name,'code':data.code});
                setStorageStations(storageStations1);
                if (copy && copy.id) {
                    let stationCopy = {
                        actions: {
                            response: {
                                Station: {
                                    custom: {
                                        functionName: "stationCopyOldData",
                                        get: "result",
                                        excludes: [
                                            "invoiceType",
                                            "station",
                                            "scheduleDesigns",
                                            "skill",
                                            "shiftTemplate",
                                            "driverRoutes",
                                            "tempDriverRoutes",
                                            "balanceGroup",
                                            "shiftRouteCommitments",
                                            "company"
                                        ],
                                        criteria: {
                                            oldStationId: copy.id,
                                            newStationId: data["id"]
                                        }
                                    }
                                }
                            }
                        }
                    };
                    await api.post("/api/lazy/manage/data", stationCopy);
                    toast({
                        type: 'success',
                        title: "Station Copy process started. Once complete, we will send you a workbot notification. Time to completion may vary depending on the size of the copied station. Thank you for your patience!",
                        useClose:true,
                        timeout: false
                    });

                } else {
                    let shifts = {
                        actions: {
                            response: {
                                Shift: {
                                    custom: {
                                        functionName: "insertDefaultShiftByStation",
                                        get: "result",
                                        excludes: [
                                            "invoiceType",
                                            "station",
                                            "scheduleDesigns",
                                            "skill",
                                            "shiftTemplate",
                                            "driverRoutes",
                                            "tempDriverRoutes",
                                            "balanceGroup",
                                            "shiftRouteCommitments",
                                            "company"
                                        ],
                                        criteria: {
                                            stationId: data["id"],
                                            companyId: localStorage.getItem("company")
                                        }
                                    }
                                }
                            }
                        }
                    };
                    data.dailySummeryRunTime = data.dailySummeryRunTimeHour + ':' + data.dailySummeryRunTimeMinute;
                    data.attendanceNotificationRunTime = data.attendanceNotificationRunTimeHour + ':' + data.attendanceNotificationRunTimeMinute;
                    const responseShift = await api.post("/api/lazy/manage/data", shifts);
                }
                await action.create(data);
            } catch (error) {
                await showDefaultErrorToast(error, 'Error creation Station.');
            }
        }
    };

    let timeZoneName = {
        'ET': 'America/Detroit',
        'CT': 'America/Chicago',
        'MT': 'America/Denver',
        'PT': 'America/Los_Angeles',
        'AKT': 'US/Alaska',
        'HT': 'Pacific/Honolulu',
        'AZ': 'America/Phoenix',
        'CET': 'Europe/Amsterdam'
    };

    return (

        <FormContext {...methods}>
            <Container>
                <form name="new_station" onSubmit={handleSubmit(onSubmit)}>
                    <SpacerInline right={5}>
                        <Input
                            name="code"
                            label="Code"
                            size="small"
                            ref={register({ required: "You need to provide a code" })}
                            error={errors.code && errors.code.message}
                            valid={!errors.code && code && code.length > 0}
                        />
                    </SpacerInline>

                    <Input
                        name="name"
                        label="Name"
                        ref={register({ required: "You need to provide a name" })}
                        error={errors.name && errors.name.message}
                        valid={!errors.name && name && name.length > 0}
                        size="big"
                    />

                    <SpacerInline left={5}>
                        <Dropdown
                            size="small"
                            name="timezone"
                            label="Timezone"
                            ref={register({ required: "Timezone is required" })}
                            error={errors.timezone && errors.timezone.message}
                            valid={!errors.timezone && timezone && timezone.length > 0}
                            //defaultValue={timezone ? timezone : ""}
                            // disabled={edit && edit.id}
                            options={[
                                { name: "Eastern Time", value: "ET" },
                                { name: "Central Time", value: "CT" },
                                { name: "Mountain Time", value: "MT" },
                                { name: "Pacific Time", value: "PT" },
                                { name: "Alaska Time", value: "AKT" },
                                { name: "Hawaii Time", value: "HT" },
                                { name: "Arizona Time", value: "AZ" },
                                { name: "Central European Time", value: "CET" }
                            ]}
                        />
                    </SpacerInline>
                    <SpacerInline left={5}>
                        <Dropdown
                           name='stationType'
                           label='Station Type'
                           ref={register({ required: 'This field required.' })}
                           placeholder='Select Type'
                           size='small'
                           options={ [{name:'DSP',value:'dsp'},{name:'ISP',value:'isp'}]}
                           error={errors.stationType && errors.stationType.message}
                         />
                    </SpacerInline>
                    <Spacer top={2} />
                    <div style={{display: 'flex'}}>
                        <CheckBox
                            name="isStationSummary"
                            title={""}
                            ref={register()}
                            defaultValues={[isStationSummary ? isStationSummary : true]}
                            options={[{ value: true, label: "Enroll in Daily Station Summary Report" }]}
                        />
                        <Spacer left={2} />
                        <CheckBox
                            name="enableGeofenceStation"
                            title={""}
                            ref={register()}
                            defaultValues={[enableGeofenceStation ? enableGeofenceStation : true]}
                            options={[{ value: !!enableGeofenceStation, label: "Enable Geofence Station" }]}
                        />
                        <Spacer left={2} />
                        <CheckBox
                            name="enableGeofenceParkingLot"
                            title={""}
                            ref={register()}
                            defaultValues={[enableGeofenceParkingLot ? enableGeofenceParkingLot : true]}
                            options={[{ value: !!enableGeofenceParkingLot, label: "Enable Geofence Parking Lot" }]}
                        />
                    </div>

                    <Spacer top={2} />
                    <Tabs>
                        <TabList>
                            <Tab>Station Address</Tab>
                            <Tab>Parking Lot Address</Tab>
                            <Tab>Notification</Tab>
                            <Tab>Nightly Report Setting</Tab>
                            <Tab>Attendance Compliance Setting</Tab>
                        </TabList>

                        <Panel>
                            <Input type={"hidden"} name="stationLatitude" size="small" ref={register} />
                            <Input type={"hidden"} name="stationLongitude" size="small" ref={register} />
                            <Input type={"hidden"} name="stationGeofence" size="small" ref={register} />
                            <Input type={"hidden"} name="stationAddress" size="small" ref={register} />
                            <MapAddressGeofence
                                label={"Station Address"}
                                initialRadius={parseFloat(defaultValues.stationGeofence ? defaultValues.stationGeofence : "80")}
                                initialLatitude={parseFloat(defaultValues.stationLatitude)}
                                initialLongitude={parseFloat(defaultValues.stationLongitude)}
                                initialAddress={defaultValues.stationAddress ? defaultValues.stationAddress: ""}
                                setNewAddress={newAddress => {
                                    setValue("stationAddress", newAddress);
                                }}
                                setLatLng={(latitude, longitude) => {
                                    setValue("stationLatitude", latitude);
                                    setValue("stationLongitude", longitude);
                                }}
                                setGeofence={radius => {
                                    setValue("stationGeofence", radius);
                                }}
                            />
                        </Panel>
                        <Panel>
                            <Input type={"hidden"} name="parkingLotAddress" size="big" ref={register} />
                            <Input type={"hidden"} name="parkingLotGeofence"  size="small" ref={register} />
                            <Input type={"hidden"} name="parkingLotLatitude" size="small" ref={register} />
                            <Input type={"hidden"} name="parkingLotLongitude" size="small" ref={register} />
                            <MapAddressGeofence
                                label={"Parking Lot Address"}
                                initialRadius={parseFloat(defaultValues.parkingLotGeofence ? defaultValues.parkingLotGeofence : 80)}
                                initialLatitude={parseFloat(defaultValues.parkingLotLatitude)}
                                initialLongitude={parseFloat(defaultValues.parkingLotLongitude)}
                                initialAddress={defaultValues.parkingLotAddress ? defaultValues.parkingLotAddress: ""}
                                setNewAddress={newAddress => {
                                    setValue("parkingLotAddress", newAddress);
                                }}
                                setLatLng={(latitude, longitude) => {
                                    setValue("parkingLotLatitude", latitude);
                                    setValue("parkingLotLongitude", longitude);
                                }}
                                setGeofence={radius => {setValue("parkingLotGeofence", radius);}}
                            />
                        </Panel>
                        <Panel>
                            <Spacer top={5}/>
                            <Toggle
                                name='isShiftReminderNotification'
                                singleText='Send Notification'
                                ref={register({required: false})}
                                on={isNotificationFlag }
                                onChange={()=>{handleIsSentNotification()}}
                                >
                            </Toggle>
                            <Spacer top={3}/>
                            <Alert.Info
                                content='These changes will be affected after few minutes.'
                            />
                            <Spacer top={3}/>
                            <Dropdown
                                name='notificationTime'
                                label='Notify # hours before shift start time'
                                ref={register({ required: isNotificationFlag ? 'This field required.' : false })}
                                defaultValue={defaultValues.notificationTime}
                                placeholder='Select Hours'
                                size='small'
                                options={ hours}
                                error={errors.notificationTime && errors.notificationTime.message}
                            />
                            <Spacer top={5}/>
                             <Dropdown
                                name='notificationType'
                                label='Notification Type'
                                ref={register({ required: isNotificationFlag ? 'This field required.' : false })}
                                placeholder='Select notification type'
                                size='small'
                                options={getIsEnableSMS() == 'false' ? [{name:'Chat',value:'chat'}]:[{name:'Chat',value:'chat'},{name:'SMS',value:'sms'},{name:'Both',value:'Both'}]}
                                error={errors.notificationType && errors.notificationType.message}
                            />
                            <Spacer top={5}/>
                             <Input
                                name='notificationMessage'
                                label='Message'
                                size='big'
                                ref={register({required: 'This field required.'})}
                                style={{height: 76,width:290}}
                                defaultValue={defMessage}
                                as='textarea'
                            />
                        </Panel>
                        <Panel>
                            <Spacer top={5}/>
                            {defaultValues.timezone ? <>
                            <Alert.Warning
                                content={<>
                                    If you change this after <b>{ moment.utc('01:00', "HH:mm").tz(timeZoneName[defaultValues.timezone]).format("h:mma") }</b> station local time then this will be affected from tomorrow.
                                </>}
                            />
                            <Spacer top={5}/>
                            <label style={{fontSize: "16px", fontWeight: "normal", fontFamily: "Circe-Rounded", display: "block", paddingBottom: "2px", color: "#707070"}}>Run daily at </label>
                            <Dropdown
                                name='dailySummeryRunTimeHour'
                                label='Hour'
                                ref={register}
                                placeholder='Hour'
                                options={optionDailySummeryHours}
                                style={{marginRight:'5px',fontSize: "12px"}}
                                defaultValue={defaultValues.dailySummeryRunTimeHour ? defaultValues.dailySummeryRunTimeHour  : '23'}
                                size='extraSmall'
                                valid={"true"}
                            />
                            <Dropdown
                                name='dailySummeryRunTimeMinute'
                                ref={register}
                                label='Minute'
                                placeholder='Minute'
                                options={optionDailySummeryTimes}
                                style={{}}
                                defaultValue={defaultValues.dailySummeryRunTimeMinute ? defaultValues.dailySummeryRunTimeMinute  : '59'}
                                size='extraSmall'
                                valid={"true"}
                            />
                            </> :
                            <>
                            <Alert.Warning
                                content={<>
                                    Your Nightly report default time to send is <b>11:59pm</b>. You can change this time after creating station from EDIT section.
                                </>}
                            />
                            <Spacer top={5}/>
                            <label style={{fontSize: "16px", fontWeight: "normal", fontFamily: "Circe-Rounded", display: "block", paddingBottom: "2px", color: "#707070"}}>Run daily at </label>
                            <Dropdown
                                name='dailySummeryRunTimeHour'
                                ref={register}
                                label='Hour'
                                placeholder='Hour'
                                options={optionDailySummeryHours}
                                style={{marginRight:'5px',fontSize: "12px"}}
                                defaultValue='23'
                                size='extraSmall'
                                valid={"true"}
                                disabled={"true"}
                            />
                            <Dropdown
                                name='dailySummeryRunTimeMinute'
                                ref={register}
                                label='Minute'
                                placeholder='Minute'
                                options={optionDailySummeryTimes}
                                defaultValue='59'
                                size='extraSmall'
                                valid={"true"}
                                disabled={"true"}
                            />
                            </>
                            }
                            <Spacer bottom={5}/>
                        </Panel>
                        <Panel>
                            <Spacer top={5}/>
                            {defaultValues.timezone ? <>
                            <Alert.Warning
                                content={<>
                                    If you change this after <b>{ moment.utc('01:00', "HH:mm").tz(timeZoneName[defaultValues.timezone]).format("h:mma") }</b> station local time then this will be affected from tomorrow.
                                </>}
                            />
                            <Spacer top={5}/>
                            <Alert.Info
                                content={<>
                                        <Icon.Info
                                        size='16px'
                                        color={Theme.colors.neutrals.medium}
                                        style={{
                                            verticalAlign: 'middle',
                                            position: 'relative',
                                            display: 'inline',
                                            top: -1
                                        }}
                                    />
                                    The time shown below is when the application updates the Attendance Compliance scores and will send out and related messaging that you have configured. These settings are located under the Company Settings (the "gear" icon).
                                </>}
                            />
                            <Spacer top={5}/>
                            <label style={{fontSize: "16px", fontWeight: "normal", fontFamily: "Circe-Rounded", display: "block", paddingBottom: "2px", color: "#707070"}}>Run daily at </label>
                            <Dropdown
                                name='attendanceNotificationRunTimeHour'
                                label='Hour'
                                ref={register}
                                placeholder='Hour'
                                options={optionDailySummeryHours}
                                style={{marginRight:'5px',fontSize: "12px"}}
                                defaultValue={defaultValues.attendanceNotificationRunTimeHour ? defaultValues.attendanceNotificationRunTimeHour  : '23'}
                                size='extraSmall'
                                valid={"true"}
                            />
                            <Dropdown
                                name='attendanceNotificationRunTimeMinute'
                                ref={register}
                                label='Minute'
                                placeholder='Minute'
                                options={optionDailySummeryTimes}
                                style={{}}
                                    defaultValue={defaultValues.attendanceNotificationRunTimeMinute ? defaultValues.attendanceNotificationRunTimeMinute  : '00'}
                                size='extraSmall'
                                valid={"true"}
                            />
                            </> :
                            <>
                            <Alert.Warning
                                content={<>
                                    Your Nightly report default time to send is <b>11:59pm</b>. You can change this time after creating station from EDIT section.
                                </>}
                            />
                            <Spacer top={5}/>
                            <Alert.Info
                                content={<>
                                        <Icon.Info
                                        size='16px'
                                        color={Theme.colors.neutrals.medium}
                                        style={{
                                            verticalAlign: 'middle',
                                            position: 'relative',
                                            display: 'inline',
                                            top: -1
                                        }}
                                    />
                                    The time shown below is when the application updates the Attendance Compliance scores and will send out and related messaging that you have configured. These settings are located under the Company Settings (the "gear" icon).
                                </>}
                            />
                            <Spacer top={5}/>
                            <label style={{fontSize: "16px", fontWeight: "normal", fontFamily: "Circe-Rounded", display: "block", paddingBottom: "2px", color: "#707070"}}>Run daily at </label>
                            <Dropdown
                                name='attendanceNotificationRunTimeHour'
                                ref={register}
                                label='Hour'
                                placeholder='Hour'
                                options={optionDailySummeryHours}
                                style={{marginRight:'5px',fontSize: "12px"}}
                                defaultValue='23'
                                size='extraSmall'
                                valid={"true"}
                                disabled={"true"}
                            />
                            <Dropdown
                                name='attendanceNotificationRunTimeMinute'
                                ref={register}
                                label='Minute'
                                placeholder='Minute'
                                options={optionDailySummeryTimes}
                                defaultValue='59'
                                size='extraSmall'
                                valid={"true"}
                                disabled={"true"}
                            />
                            </>
                            }
                            <Spacer bottom={5}/>
                        </Panel>
                    </Tabs>

                    <Spacer top={5} />

                    <Button type="primary" style={{ float: "right" }}>
                        Save Station
                    </Button>
                </form>
            </Container>
        </FormContext>
    );
};

export default App;
