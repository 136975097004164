import apiFactory from '../../../api/factory';

type VehicleUploadDocumentsInterface = {
    id: number,
    documents: string
}

const {call} = apiFactory();
const resource = 'vehicle/maintenance/task'
export const VehicleUploadDocuments = (props: VehicleUploadDocumentsInterface) => {
    const {id,documents} = props
    return call({documents:documents},`api/v2/${resource}/${id}/uploadDocuments`,'put')
}