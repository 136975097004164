import React from "react";
import styled from "styled-components";
import {useChatTrackedState} from "./store";
import ChatSidebar from "./ChatSidebar";

const ChatContainer = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 100;
    display: ${props => (props.display ? props.display : "flex")};
`;


const AppChatSidebar: React.FC = () => {
    const state = useChatTrackedState();
    const {isOpen, initialChannelsReady} = state;
    // console.log(`Render AppChatSidebar ${JSON.stringify({initialChannelsReady, isOpen})}`);

    if (initialChannelsReady) {
        return (
            <ChatContainer id={"chat-app"} display={isOpen ? "flex" : "none"}>
                <ChatSidebar/>
            </ChatContainer>
        );
    }
    return null;
}

export default AppChatSidebar;
