import React from 'react';
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";

import {DragItem} from './list/index';

const WithDragAndDrop = Component => props => {
    return(
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
           <Component {...props} />
        </DndProvider>
    )
}

export default WithDragAndDrop
export {
    DragItem
}