import React, {useMemo} from 'react';
import {styleSheetGetColor, useGetStringFromValue} from "./utils";
import DSPText from "./DSPText";

type MetricNameProps = {
    metricName: string;
    metricTextColor?: string;
};

const MetricName: React.FC<MetricNameProps> = ({metricName, metricTextColor}) => {
    const getString = useGetStringFromValue();
    const fontSizeHandler = useMemo(() => {
        if (metricName.length > 21) {
            return 18;
        }
        if (metricName.length > 10) {
            return 20;
        }
        return 20;
    }, [metricName.length]);
    return (
        <div>
            <DSPText fontSize={fontSizeHandler} color={styleSheetGetColor(metricTextColor)}>
                {getString(metricName)}
            </DSPText>
        </div>
    );
};

export default MetricName;
