import React, { useEffect } from "react";
import Modal from "../../../components/Modal";
import { Theme, Icon, Button, SubTitle, Text, Spacer } from "@dspworkplace/ui";
import Parser from 'html-react-parser';
import { dialogPromise } from "../../../components/Dialog";

const AddEditDeletePopup = ({
    title = 'Multiple Shifts Selected:',
    text = 'Select the edit option below',
    icon, success, cancel, deleteBtn = 'Delete', confirmBtn = 'Edit', setOpen = () => { } }) => {

    useEffect(() => {
        if (document.getElementById("confirmBtn")) {
            document.getElementById("confirmBtn").focus();
        }
    }, []);

    return (
        <Modal
            width='340px'
            removeHeader={true}
            visible={true}
            setVisible={cancel}
            closeOnOverlay={true}
        >
            <div style={{ textAlign: 'center' }}>
                {icon && <Spacer bottom={5} top={3}><Icon.Warning size='60px' color={Theme.colors.warnings.text} /></Spacer>}
                {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                <Text style={{ wordBreak: "break-word" }}>{Parser(text)}</Text>
                <Spacer bottom={5} />
                <Button id={"confirmBtn"} tabIndex="1" onClick={() => success('add-edit')} type='primary'>{confirmBtn}</Button>
                <Spacer right={5} inline />
                <Button id={"deleteBtn"} onClick={() => { success('delete'); setOpen(false); }} type='delete' tabIndex="2">{deleteBtn}</Button>
            </div>
        </Modal>
    )
};

const addEditDeletePopup = async (opts = {}) => {

    let { title, text, deleteBtn, confirmBtn, setOpen } = opts;

    return dialogPromise((success, cancel) =>
        <AddEditDeletePopup
            success={success}
            cancel={cancel}
            title={title}
            text={text}
            deleteBtn={deleteBtn}
            confirmBtn={confirmBtn}
            setOpen={setOpen}
        />
    );
};

export { addEditDeletePopup };