import apiFactory from '../../../api/factory';

type planInputInterface = {
    vehicle_id: number,
    maintenance_template_id: number
}

const {call} = apiFactory();
const resource = 'vehicle'
export const AssignPlanToVehicle = (props: planInputInterface) => {
    return call(props,`api/v2/${resource}/assignPlan`,'post')
}