import React from 'react';
import {styleSheetGetColor, useGetStringFromValue} from "./utils";
import DSPText from "./DSPText";

const GoalAndAvgItem = ({
                            metricGoalValue,
                            metricGoalColor,
                            metricAverageValue,
                            metricKey,
                        }) => {
    const getString = useGetStringFromValue();
    if (metricKey === 'AMAZON_OVERALL_TIER') {
        return (
            <div style={{width: '25%'}}>
                {!!metricGoalValue && (
                    <div style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <div>
                            <DSPText
                                fontSize={18}
                                style={styleSheetGetColor(metricGoalColor)}
                                fontWeight={'bold'}
                            >
                                Goal
                            </DSPText>
                        </div>
                        <div>
                            {/*<SvgXml*/}
                            {/*    xml={getIcon(metricGoalValue === 'Check' ? 'Check' : 'Times')}*/}
                            {/*    width="20"*/}
                            {/*    height="20"*/}
                            {/*    fill={metricGoalValue === 'Check' ? '#00A38D' : metricGoalColor}*/}
                            {/*/>*/}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div style={{width: '25%'}}>
            {!!metricGoalValue && (
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                    <div>
                        <DSPText
                            fontSize={18}
                            color={styleSheetGetColor(metricGoalColor)}
                            fontWeight={'bold'}
                        >
                            Goal
                        </DSPText>
                    </div>
                    <div>
                        <DSPText
                            fontSize={18}
                            fontWeight={'bold'}
                            color={styleSheetGetColor(metricGoalColor)}
                        >
                            {getString(metricGoalValue)}
                        </DSPText>
                    </div>
                </div>
            )}
            {!!metricAverageValue && (
                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                    <div>
                        <DSPText
                            fontSize={18}
                            fontWeight={'bold'}
                            color={styleSheetGetColor(metricGoalColor)}
                        >
                            Avg
                        </DSPText>
                    </div>
                    <div>
                        <DSPText
                            fontSize={18}
                            fontWeight={'bold'}
                            color={styleSheetGetColor(metricGoalColor)}
                        >
                            {getString(metricAverageValue)}
                        </DSPText>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoalAndAvgItem;
