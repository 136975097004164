import React, {useEffect} from 'react';
// @ts-ignore
import {useChatStoreReducer, useChatTrackedState, useDispatch} from "./store";
// @ts-ignore
import {debugLog, setInitialClient, setItemToStorage} from "./utils";
// @ts-ignore
import {engine, showErrorToast, showToast} from "../Utilities";
// @ts-ignore
import {messaging} from "../firebase";
// @ts-ignore
import {getChatIdentifier} from "../Auth";
// @ts-ignore
import {Token} from "../hooks/useGlobalData";
import {audio} from "./AppChat";

const AppChatClient: React.FC = () => {
    // @ts-ignore
    // const [authToken] = Token();
    const state = useChatTrackedState();
    // @ts-ignore
    const dispatch = useDispatch();
    // @ts-ignore
    const {client} = state;
    // console.log('Render AppChatClient');

    useEffect(() => {
        debugLog('setInitialClient() start');
        const abortController = new AbortController();
        setInitialClient(messaging, dispatch).then(() => {
            debugLog('setInitialClient() end');
            try {
                debugLog({audio});
                audio.addEventListener("canplay", e => {
                    debugLog({canplay: e});
                    if (!e.isTrusted) {
                        alert('PLease interact with the site to enable notifications audio. ');
                    }
                });
                audio.addEventListener("error", e => {
                    debugLog({errorAudio: e});
                    alert('PLease interact with the site to enable notifications audio. ');
                });
                audio.addEventListener("play", e => {
                    debugLog({play: e});
                });
                // @ts-ignore
                // audio.play().catch(error => console.log("Error in play()", {error}));
            } catch (e) {

            }
        });
        return () => abortController.abort();
    }, []);

    useEffect(() => {
        const abortController = new AbortController();

        async function refreshToken() {
            try {
                if (getChatIdentifier() !== "null" && typeof getChatIdentifier() === "string") {
                    let response = await engine().post("/api/twilio/fetch_auth_token", {
                        userId: localStorage.getItem("userId")
                    });
                    let token = await response.data.token;
                    await client!.updateToken(token);
                    dispatch({type: "SET_CLIENT", client: client!});
                } else {
                    alert('Please reload the page or login again.');
                }
            } catch (error) {
                console.error({refreshToken: error});
            }
        }

        if (client) {
            client.on("tokenAboutToExpire", refreshToken);
            client.on("channelJoined", async (channel) => {
                try {
                    if (channel && channel.sid) {
                        await client.getChannelBySid(channel.sid); // sync
                        let newChannel = await client.getChannelBySid(channel.sid);
                        if (newChannel.attributes['kind'] === 'Duo') {
                            let n = await newChannel.getMembersCount();
                            if (n !== 2) {
                                let count = 0;
                                while (n !== 2) {
                                    if (count > 4) {
                                        break;
                                    }
                                    newChannel = await client.getChannelBySid(channel.sid);
                                    n = await newChannel.getMembersCount();
                                    count++;
                                }
                            }
                            if (n === 2) {
                                dispatch({type: "ADD_CHANNEL", channel: newChannel});
                            }
                        } else {
                            dispatch({type: "ADD_CHANNEL", channel: newChannel});
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'Error on channel joined event.');
                }
            });
            client.on("channelRemoved", channel => {
                dispatch({type: "REMOVE_CHANNEL", channel: channel});
            });
            client.on("channelUpdated", (event) => {
                let reasons = event.updateReasons;
                let evalCondition = event.channel && Array.isArray(reasons);
                let attributes = event.channel.attributes;
                if (
                    attributes.kind === 'Bot' &&
                    evalCondition &&
                    reasons.includes('attributes') &&
                    attributes &&
                    attributes.allowToChat
                ) {
                    setItemToStorage('allowToChat', attributes.allowToChat);
                    dispatch({
                        type: 'ALLOW_TO_CHAT',
                        allowToChat: attributes.allowToChat,
                    });
                }
            });
        }
        return () => {
            if (client) {
                client.removeAllListeners();
            }
            abortController.abort();
        };
    }, [client, dispatch]);

    return null;
};

export default AppChatClient;

// useEffect(() => {
//     let es;
//     try {
//         es = new ES(`task`);
//         if (esRef.current) {
//             return;
//         }
//         esRef.current = true;
//         // @ts-ignore
//         es.onmessage = response => {
//             if (response.event === 'CREATION') {
//                 dispatch({type: "ADD_TASK", task: response.data.task});
//             } else if (response.event === 'UPDATE') {
//                 dispatch({type: "UPDATE_TASK", task: response.data.task});
//             }
//         }
//     } catch (e) {
//         console.log({ES: e});
//     }
//     return () => {
//         if (es) {
//             // @ts-ignore
//             es.close();
//         }
//     }
// }, [dispatch]);
