import React from 'react';
import { Theme, SubTitle, Spacer, Icon } from "@dspworkplace/ui";
import { Menu, Navigator } from "../../../components/Menus/Navigator";
import { NavLink } from "react-router-dom";
import { useDrop } from 'react-dnd'
import { useAuth } from '../../../Auth';
import { confirm } from '../../../components/Confirm';
import { toast } from '../../../components/Toasts';
import { useHistory } from 'react-router-dom';

const iconDefaultProps = {
    size: '20px',
    style: { padding: 0 }
};

const PanelItem = ({panel,i,slug,onUpdatePanel,onDeletePanel,title,dispatch,updateWidgetList, onPanelChange}) => {
    const { api } = useAuth();
    let history = useHistory();
    let chartId,panelId;
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "WidgetType",
        drop: async (item) => {
            chartId = item.name.id;
            panelId = panel.id;
            let cMessage = `Do you want to move this widget to ${panel.name}?`;
            if (cMessage) {
                const wc = await confirm(cMessage);
                if (!wc) return undefined;
            }
            const isIdInPanelCharts = panel.charts.some(chart => chart.id === chartId);
            const params = {
                actions: {
                    update: {
                        DashChart: {
                            update_panel_1: {
                                findBy: {
                                    id: chartId
                                },
                                updateRecord: {
                                    panel: panelId // PANEL ID
                                }
                            }
                        }
                    },
                },
            };
            if (!isIdInPanelCharts) {
                const response = await api.post("/api/lazy/manage/data", params).then(
                    (response) => {
                        if (response.status === 200) {
                            dispatch({
                                type: 'removeChartDrag',
                                payload: { chartId }
                            });
                            history.push(`/dashboard/${slug}/${panel.name}`);
                            updateWidgetList()
                        }
                    },
                    (error) => {
                        toast({
                            type: "error",
                            title: `Error to delete the "${item.name.title}" widget`
                        });
                    }
                );
            }
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
    })

    const handleNavigation = () => {
        onPanelChange(panel.id)
    }

    return (
        // @ts-ignore
        <li key={i} ref={drop} onClick={handleNavigation}>
            <NavLink active="false" to={`/dashboard/${slug}/${encodeURI(panel.name).replace('/', '__')}`} style={{ justifyContent: 'space-between' }} >
                <span>{panel.name}</span>
                <div>
                    {title === 'Reports' &&
                        <Icon.Download
                            size={'18px'}
                            style={{ marginRight: 6, position: 'relative', top: -2 }}
                        />
                    }
                    <Icon.Edit
                        {...iconDefaultProps}
                        onClick={(e) => {
                            e.preventDefault();
                            onUpdatePanel(panel, { title: title });
                        }}
                    />
                    <Icon.Times
                        {...iconDefaultProps}
                        color={Theme.colors.error.border}
                        onClick={async (e) => {
                            e.preventDefault();
                            await onDeletePanel(i);
                        }}
                    />
                </div>
            </NavLink>
        </li>
    )
}

const PanelsList = ({title, slug, panels, onUpdatePanel, onDeletePanel, onAddPanel , dispatch, updateWidgetList, fetchPanelData}) => {
 
      return(
    // @ts-ignore
    <>
        <SubTitle>{title}</SubTitle>
        <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
        <Spacer top={4}/>
        <Menu>
                  {panels.map((panel, i) => (
                      <PanelItem
                          panel={panel}
                          i={i}
                          slug={slug}
                          onUpdatePanel={onUpdatePanel}
                          onDeletePanel={onDeletePanel}
                          title={title}
                          dispatch={dispatch}
                          updateWidgetList={updateWidgetList}
                          onPanelChange={fetchPanelData}
                          />
                  ))}
            {panels.length < 10 &&
                <li>
                    <NavLink to={`preventDefault`} onClick={(e)=>{
                        e.preventDefault();
                        onAddPanel({title:title});
                    }}>
                        <Icon.Plus size={'20px'} />
                        <AddPanelButton title={title} />
                    </NavLink>
                </li>
            }
        </Menu>
    </>
)
};

const AddPanelButton = ({title}) => {
    const lastLetter = title[title.length - 1];
    if(lastLetter.toLowerCase() === 's'){
        title = title.slice(0, lastLetter.length-2)
    }

    return<span>Add {title} Board</span>
}

export default PanelsList;