import {Provider} from "../store";
import React from "react";
import Template from "./Template";

function LoadOutTemplate() {
    return (
        <Provider>
            <Template scenario={'LOAD_OUT'}/>
        </Provider>
    );
}

export default LoadOutTemplate