import {getUserId, getCompanyId} from "./Auth";

class ES {
    eventSourceUrl = process.env.REACT_APP_EVENT_SOURCE_URL || 'https://mercure.dspworkplace.com';
    topicPrefix = process.env.REACT_APP_EVENT_SOURCE_PREFIX || 'https://mercure.dspworkplace.com';

    constructor( topic, subscribeToMyEmittedEvents ) {
        let url;

        if ( topic === 'hub' )
            throw new Error( '`hub` is a reserved topic' );

        topic = `${this.topicPrefix}/${getCompanyId()}/${localStorage.getItem('selected')}/${topic}`;

        try {
            url = new URL( topic );
        } catch ( e ) {
            console.log({ES: e});
            throw new Error( 'Topic must be a valid URL.' );
        }

        if ( url.protocol !== 'http:' && url.protocol !== 'https:' )
            throw new Error( 'Topic must be a valid URL.' );

        const self = this;

        self.topic = encodeURIComponent( topic );
        self.subscribeToMyEmittedEvents = subscribeToMyEmittedEvents || false;

        self.build();
    }

    build ()
    {
        const self = this;

        self.es = new EventSource( `${this.eventSourceUrl}?topic=${self.topic}` );

        self.es.onerror = e => {
            self.es.close();
        };

        self.es.onmessage = e => {
            let data = JSON.parse( e.data );

            if ( process.env.NODE_ENV !== 'production' )

                if ( !data.emitter )
                    throw new Error( 'Missing event emitter. Make sure that the Event Source sends the event emitter.' );

            // intentional loose type comparison
            // eslint-disable-next-line eqeqeq
            if ( !self.subscribeToMyEmittedEvents && getUserId() == data.emitter )
                return;

            if ( typeof self.onmessage === 'function' )
                self.onmessage( data );
        };

        self.close = () => self.es.close();
    }

    setRawTopic (topic)
    {
        this.close();
        this.topic = encodeURIComponent(`${this.topicPrefix}/${topic}`);
        this.build();
    }
}

export default ES;