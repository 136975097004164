import {Dispatch, useReducer} from "react";
import {createContainer} from "react-tracked";
import {MessageOfConversation} from "./types";
import {Conversation} from "@twilio/conversations";

type State = {
    conversation?: Conversation;
    loading: boolean;
    messages: Array<MessageOfConversation>,
    files: Array<any>,
    openFileModal: boolean;
    openChannelSettings: boolean;
    previousIndex: number;
    lastIndex: number;
    hasPreviousPage: boolean;
    init: boolean;
    messageKind: string;
    message?: MessageOfConversation | null;
    isReviewing: boolean;
    showSummaryModal: boolean;
};

type Action =
    | { type: "SET_UP"; conversation: Conversation; loading: boolean }
    | { type: "REVIEW_TASK"; message: MessageOfConversation }
    | { type: "GO_BACK"; }
    | { type: "SHOW_SUMMARY_MODAL" }
    | { type: "CLOSE_SUMMARY_MODAL" }
    | { type: "SET_INIT"; init: boolean; }
    | { type: "SET_IS_REVIEWING"; isReviewing: boolean; }
    | { type: "MESSAGE_ADDED"; message: MessageOfConversation; }
    | { type: "MESSAGE_UPDATED"; message: MessageOfConversation; }
    | { type: "MESSAGE_REMOVED"; sid: string; }
    | { type: "PREPEND_MESSAGES"; messages: Array<MessageOfConversation>, previousIndex: number, hasPreviousPage: boolean; }
    | { type: "SET_INITIAL_MESSAGES"; messages: Array<MessageOfConversation>, previousIndex: number, hasPreviousPage: boolean; lastIndex: number }
    ;

const initialChannelState: State = {
    files: [],
    init: false,
    messages: [],
    openChannelSettings: false,
    openFileModal: false,
    loading: true,
    hasPreviousPage: false,
    previousIndex: 0,
    lastIndex: 0,
    messageKind: "",
    isReviewing: false,
    showSummaryModal: false,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_UP":
            return {
                ...state,
                loading: action.loading,
                conversation: action.conversation
            };
        case "SET_INITIAL_MESSAGES":
            return {
                ...state,
                messages: action.messages,
                previousIndex: action.previousIndex,
                hasPreviousPage: action.hasPreviousPage,
                lastIndex: action.lastIndex,
                init: true,
            };
        case "SET_INIT":
            return {...state, init: action.init};
        case "PREPEND_MESSAGES":
            return {
                ...state,
                messages: [...action.messages, ...state.messages],
                previousIndex: action.previousIndex,
                hasPreviousPage: action.hasPreviousPage,
            };
        case "MESSAGE_ADDED":
            let newMessage = action.message;
            if (state.messages.length - 1 >= 0 && state.messages[state.messages.length - 1].sid === newMessage.sid) {
                return state;
            }
            return {...state, messages: [...state.messages, newMessage]};
        case "MESSAGE_REMOVED":
            return {...state, messages: state.messages.filter(item => item.sid !== action.sid)};
        case "MESSAGE_UPDATED":
            return {
                ...state,
                messages: state.messages.map(item => {
                    if (item.sid === action.message.sid) {
                        return action.message;
                    }
                    return item;
                })
            }
        case "SET_IS_REVIEWING":
            return {...state, isReviewing: action.isReviewing};
        case "REVIEW_TASK":
            return {...state, isReviewing: true, message: action.message};
        case "GO_BACK":
            return {...state, isReviewing: false, message: null, init: true};
        case "SHOW_SUMMARY_MODAL":
            return {...state, showSummaryModal: true};
        case "CLOSE_SUMMARY_MODAL":
            return {...state, showSummaryModal: false};
        default:
            return state;
    }
};

const useValue = () => useReducer(reducer, initialChannelState);

export const {Provider, useTrackedState, useUpdate, useTracked} = createContainer(useValue);

export function useBotStoreReducer(): {
    state: State,
    dispatch: Dispatch<Action>,
} {
    const [state, dispatch] = useTracked();
    return {state, dispatch};
}

export function useBotTrackedState(): State {
    return useTrackedState();
}

export function useBotDispatch(): Dispatch<Action> {
    return useUpdate();
}