import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Label } from "../../components/UI";
import { Input, Button, Icon, Text, Theme } from "@dspworkplace/ui";

const mergeRefs = (refs) => {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

const SpanHelp = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    color: #516f90;
    margin-top: 4px;
`;

const SpanError = styled(SpanHelp)`
    color: ${Theme.colors.error.text};
`;

const Frag = styled.div`
    display: inline-block;
    vertical-align: top;
`;

const Wrapper = styled.label`
    position: relative;
    cursor: pointer;

    &:hover {
        .inputFile {
            background: ${Theme.colors.info.bg};
        }
    }

    &:focus {
        outline: none;
    }

    &:focus-within {
        .inputFile {
            border: 1px solid ${Theme.colors.secondary};
            box-shadow: 0px 0px 6px ${Theme.colors.action};
        }

        .InputFileProfile {
            border: 1px solid ${Theme.colors.secondary};
        }
    }

    &.active {
        .inputFile {
            border: 1px solid ${Theme.colors.success.border};
            box-shadow: none;
        }

        .InputFileProfile {
            border: 1px solid ${Theme.colors.success.border};
        }
    }

    &.error {
        .inputFile {
            border: 1px solid ${Theme.colors.error.border};
            box-shadow: none;
        }

        .InputFileProfile {
            border: 1px solid ${Theme.colors.error.border};
        }
    }
`;

const Input1 = styled(Input)`
    box-sizing: border-box;
    background: #fafafa;
    width: 140px;
    border-radius: 20px 2px 2px 20px;
    border: 1px solid transparent;

    &:hover {
        border: none;
    }

    > span {
        text-align: center;
        display: block;
        margin-left: 32px;
        color: #707070;
        font-size: 14px;
        font-weight: 400;

        text-overflow: ellipsis;
        height: 16px;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const Profile = styled.div`
    height: 38px;
    width: 38px;
    border-radius: 38px;
    background: #fafafa;
    border: 1px solid ${Theme.colors.neutrals.silver};
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 38px;
    }
`;

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const Uploader = forwardRef(({ name, label, tooltip, help, error, placeholder, ...props }, ref) => {
    const [picture, setPicture] = useState("");
    const acceptedTypes = ["image/jpeg", "image/png"];

    placeholder = placeholder || <Icon.Person color={Theme.colors.neutrals.silver} size="34px" />;

    const handleFile = (e) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (acceptedTypes.some((v) => v == file.type)) {
                setPicture(file);
            } else {
                setPicture(false);
                e.target.value = "";
            }
        }
    };

    const Check = (
        <Icon.Check color={Theme.colors.success.border} size="17px" style={{ position: "relative", top: "3px" }} />
    );

    return (
        <Frag>
            {label && (
                <Label htmlFor={name}>
                    {label} {picture.type && Check} {/*tooltip && <Help text={tooltip}/>*/}
                </Label>
            )}
            <Wrapper className={[picture.type && "active", picture === false && "error", error && "error"]}>
                <Profile className="InputFileProfile">
                    {picture == false || picture.length < 1 ? placeholder : <img src={URL.createObjectURL(picture)} />}
                </Profile>
                <Input1 as="div" className="inputFile">
                    {!picture || !picture.length ? <span>Select file</span> : <span>{picture.name}</span>}
                </Input1>
                <HiddenInput
                    type="file"
                    name={name}
                    ref={ref}
                    onFocus={() => {}}
                    onChange={handleFile}
                    accept=".jpg,.jpeg,.JPG,.JPEG,.png,.PNG"
                />
            </Wrapper>
            {help && <SpanHelp>{help}</SpanHelp>}
            {error && (
                <SpanError>
                    <img src={"#"} style={{ marginRight: "6px" }} /> {error}
                </SpanError>
            )}
            {picture === false && (
                <SpanError>
                    <img src={"#"} style={{ marginRight: "6px" }} /> Only images are accepted
                </SpanError>
            )}
        </Frag>
    );
});

export default Uploader;

const imageToBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve) => {
        reader.onload = (ev) =>
            resolve({
                type: "image",
                src: ev.target.result,
            });
        reader.readAsDataURL(file);
    });
};

const documentFile = (file) => {
    return new Promise((resolve) =>
        resolve({
            type: "doc",
            src: (
                <>
                    <Icon.Document />
                    <Text>{file.name}</Text>
                </>
            ),
        })
    );
};

const genericFile = (file) => {
    return new Promise((resolve) =>
        resolve({
            type: "file",
            src: (
                <>
                    <Icon.Document />
                    <Text>{file.name}</Text>
                </>
            ),
        })
    );
};

const processFileList = async (fileList) => {
    const promises = Array.from(fileList).map((file) => {
        if (file.type.match("image/")) return imageToBase64(file);

        if (["text/csv"].includes(file.type)) return documentFile(file);

        return genericFile(file);
    });

    return await Promise.all(promises);
};

const DropUploaderList = styled.div`
    display: inline-flex;
`;

const DropUploaderPreview = styled.div`
    box-sizing: border-box;
    width: 92px;
    height: 92px;
    padding: 4px;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid ${Theme.colors.neutrals.silver};
    background-color: ${Theme.colors.extra.whiteSmoke};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: ${Theme.font.extraSmall.size};
    font-family: ${Theme.font.main};
    color: ${Theme.colors.neutrals.silver};
    text-align: center;
    word-break: break-word;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-size: cover;
        background-position: center;
        background-image: url(${(props) => typeof props.preview === "string" && props.preview});
    }

    + div,
    + label {
        margin-left: 8px;
    }

    button {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 4px;
        padding: 4px;
        height: auto;
        line-height: 0;
    }

    label& {
        cursor: pointer;
        border-style: dashed;

        &:hover,
        &.highlight {
            background-color: ${Theme.colors.info.bg};
        }

        &:active, {
        &.highlight {
            border-color: ${Theme.colors.secondary};
            box-shadow: 0 0 6px ${Theme.colors.secondary};
        }
    }
`;

export const DropUploader = forwardRef(({ name, label, multiple = true, accept = "*", ...props }, ref) => {
    let hideAfterFileSelectProp = (typeof props.hideAfterFileSelect !== undefined && typeof props.hideAfterFileSelect === 'boolean') ? props.hideAfterFileSelect : true;

    let [files, setFiles] = useState([]);
    let [fileLists, setFileLists] = useState([]);
    let [showUploader, setShowUploader] = useState(true);
    let [hideAfterFileSelect, setHideAfterFileSelect] = useState(hideAfterFileSelectProp);
    let [changeType, setChangeType] = useState('add');
    let disableProp = (typeof props.disabled == 'boolean' && props.disabled == true) ? true : false;

    const inputRef = useRef();
    const dropRef = useRef();

    const handleFileChanges = async () => {
        if(multiple) {
            let selectedFiles = inputRef.current.files;
            var b = new ClipboardEvent("").clipboardData || new DataTransfer();
            if(changeType == 'add') {
                for (var i = 0, len = fileLists.length; i<len; i++) {
                    b.items.add(fileLists[i]);
                }
            }
            for (var i = 0, len = selectedFiles.length; i<len; i++) {
                b.items.add(selectedFiles[i]);
            }
            setFileLists(b.files);
            inputRef.current.files = b.files;
        }
        changeType = 'add';
        const inputs = await processFileList(inputRef.current.files);
        setFiles(inputs);
        if(hideAfterFileSelect) {
            setShowUploader(false);
        }
    };

    const handleDrop = (e) => {
        inputRef.current.files = e.dataTransfer.files;
        changeType = 'drop';
        handleFileChanges();
    };

    const dropFile = (k) => {
        let nFiles = Array.from(inputRef.current.files);
        const deleted = nFiles.splice(k, 1);
        inputRef.current.files = FileListItem(nFiles);
        changeType = 'drop';
        handleFileChanges();
        if (props.onDelete) props.onDelete(k, deleted);
    };

    const expand = (file) => {
        let w = window.open(
            file.src,
            "_blank",
            `width=${window.outerWidth * 0.9},height=${window.outerHeight * 0.9},scrollbars=1`
        );

        if (!file.src.match(/^http/i)) {
            w.document.write(`
                <img src="${file.src}"/>
            `);
        }
    };

    const download = async (file) => {
        let blob = await fetch(file.src).then(
            (res) => res.blob(),
            (rej) => false
        );

        if (!blob) return window.open(file.src);

        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = file.src.split("/").pop();
        anchor.click();
    };

    const addHighlight = (e) => {
        files.length == 0 && dropRef.current.classList.add("highlight");
    };
    const removeHighlight = (e) => {
        files.length == 0 && dropRef.current.classList.remove("highlight");
    };

    const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const addListeners = () => {
        if (files.length == 0) {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((e) => {
                if (dropRef.current) {
                    dropRef.current.addEventListener(e, preventDefaults, false);
                }
            });

            ["dragenter", "dragover"].forEach((e) => {
                if (dropRef.current) {
                    dropRef.current.addEventListener(e, addHighlight, false);
                }
            });

            ["dragleave", "drop"].forEach((e) => {
                if (dropRef.current) {
                    dropRef.current.addEventListener(e, removeHighlight, false);
                }
            });

            dropRef.current.addEventListener("drop", handleDrop);
        }
    };

    function FileListItem(a) {
        a = [].slice.call(Array.isArray(a) ? a : arguments);
        for (var c, b = (c = a.length), d = !0; b-- && d; ) d = a[b] instanceof File;
        if (!d) throw new TypeError("expected argument to FileList is File or array of File objects");
        for (b = new ClipboardEvent("").clipboardData || new DataTransfer(); c--; ) b.items.add(a[c]);
        return b.files;
    }

    const removeListeners = () => {
        if (files.length == 0) {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((e) => {
                if (dropRef.current) {
                    dropRef.current.removeEventListener(e, preventDefaults);
                    dropRef.current.removeEventListener(e, addHighlight);
                    dropRef.current.removeEventListener(e, removeHighlight);
                    dropRef.current.removeEventListener(e, handleDrop);
                }
            });
        }
    };

    useEffect(() => {
        addListeners();
        disableProp = (typeof props.disabled == 'boolean' && props.disabled == true) ? true : false;
        return removeListeners;
    }, [dropRef.current]);

    // display placeholder
    if (Array.isArray(props.placeholder)) {
        let validPlaceholder = props.placeholder.filter((f) => {
            return typeof f.type === "string" && typeof f.src === "string";
        });

        if (multiple) {
            files = validPlaceholder.concat(files);
        } else if (files.length == 0) {
            files = validPlaceholder.concat(files);
        }
    }

    return (
        <Frag>
            {label && <Label>{label}</Label>}
            {!disableProp && (<HiddenInput
                type={"file"}
                name={name}
                id={`drop-uploader-${name}`}
                ref={mergeRefs([inputRef, ref])}
                accept={accept}
                multiple={multiple}
                onChange={handleFileChanges}
                onFocus={addHighlight}
                onBlur={removeHighlight}
            />)}
            <DropUploaderList>
                {files.map((file, k) => (
                    <DropUploaderPreview key={k} preview={file.src}>
                        <Button
                            type="delete"
                            Type="button"
                            onClick={() => dropFile(k)}
                            style={{ top: "unset", bottom: "4px" }}
                            disabled={disableProp}
                        >
                            <Icon.Times size="16px" color={Theme.colors.error.text} />
                        </Button>
                        {file.type === "image" && (
                            <Button Type="button" onClick={() => expand(file)}>
                                <Icon.Expand size="16px" />
                            </Button>
                        )}
                        {file.type !== "image" && typeof file.src === "string" && (
                            <>
                                <Button Type="button" title={`Download ${file.src}`} onClick={() => download(file)}>
                                    <Icon.Download size="16px" />
                                </Button>
                                <Icon.Document />
                                <Text>{file.src.split("/").pop().substr(-20, 20)}</Text>
                            </>
                        )}
                        {file.type !== "image" && typeof file.src !== "string" && file.src}
                    </DropUploaderPreview>
                ))}
                {(showUploader) && (
                    <DropUploaderPreview as={"label"} htmlFor={`drop-uploader-${name}`} ref={dropRef} style={{cursor: disableProp ? 'not-allowed' : 'pointer'}}>
                        <span>Drop or select</span>
                        <Icon.Upload color={Theme.colors.neutrals.silver} size="24px" />
                        <span>to upload</span>
                    </DropUploaderPreview>
                )}
            </DropUploaderList>
        </Frag>
    );
});
