import React, {createContext, useReducer} from 'react';
import AppReduce from './Reduce';

const DefaultStore = createContext({});

const AppProvider = ({children}) => {
    const useAppState = useReducer(AppReduce,{
        data:[],
        tempData:[],
        balanceGroup:[],
        grouped:false,
        week:0,
        stations:[],
        isLoading:true,
        previousData:[],
        year:0,
        balanceGroupObj:[],
        sortedBy: [{
            field: 'balanceGroup',
            direction: 0
        }],
        station:[]
    });

    return (
        <DefaultStore.Provider value={useAppState}>
            {children}
        </DefaultStore.Provider>
    )
}

export {AppProvider}

export default DefaultStore
