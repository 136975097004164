import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, SubTitle, ThirdTitle, Spacer, Dropdown, Input, DropUploader, Uploader, CheckBox } from "@dspworkplace/ui";

import { AddOrUpdateVehicle, fetchSkills } from "./api";

import { ScrollyModalStyle, ScrollyOverlayStyle } from "../../../components/Modal";
import Loading, { LoadingWrapper } from "../../../components/Loading";
import { patternYear } from "../../../patterns";
import DayPicker from "../../../components/Calendar/day";
import styled from "styled-components";
import StationMap from "../../../components/Map/Station";
import moment from "moment";
import { deleteMedia, upload } from "../../../Auth";
import { patternFloatValue, imageExtention, patternNumericValue } from '../../../patterns';
import { Link } from "../../../components/UI";

export const Subtitle = styled.div`
    color: #516f90;
    font-family: circe-rounded, serif;
    font-size: 16px;
`;

const modalRoot = document.getElementById("modal-root");

const extraFields = {
    Owned: {
        0: "ownedPurchaseConditionPrice",
        1: "ownedPurchaseConditionOdometer",
        2: "ownedPurchaseConditionDate",
        3: "ownedSoldConditionPrice",
        4: "ownedSoldConditionOdometer",
        5: "ownedSoldConditionDate"
    },
    Leased: {
        0: "leasingCompany",
        1: "leasingAgreementNumber",
        2: "leasingPricePerMonth",
        3: "leasingStartDate",
        4: "leasingEndDate",
        5: "leasingDeposit",
        6: "leasingInitialConditionOdometer",
        7: "leasingInitialConditionDate",
        8: "leasingReturnConditionOdometer",
        9: "leasingReturnConditionDate"
    },
    Rented: {
        0: "rentalCompany",
        1: "rentalAgreementNumber",
        2: "rentalPricePerMonth",
        3: "rentalStartDate",
        4: "rentalEndDate",
        5: "rentalDeposit",
        6: "rentalInitialConditionOdometer",
        7: "rentalInitialConditionDate",
        8: "rentalReturnConditionOdometer",
        9: "rentalReturnConditionDate"
    }
};

const normalizeDate = (obj, fields, format = "YYYY-MM-DD") => {
    return fields.reduce((newObj, field) => {
        if (newObj[field] && newObj[field].timestamp) {
            newObj[field] = moment
                .unix(newObj[field].timestamp)
                .utc()
                .format(format);
        }
        return newObj;
    }, obj);
};

async function getObjectURL(data) {
    try {
        let formData = new FormData();
        formData.append('image', data.insuranceCardImage[0]);
        formData.append('name', data.insuranceCardImage[0].name);
        let result = await upload(formData).catch(err => {
            console.log({ err })
        });
        return result.data.ObjectURL;
    } catch (e) {
        console.log({ e })
    }

}


const VehicleForm = ({ vehicle, api, success, cancel, oldVehicleId }) => {

    if (vehicle)
        vehicle = normalizeDate(vehicle, [
            "dateStart",
            "dateEnd",
            "ownedSoldConditionDate",
            "tollCardStartDate",
            "tollCardEndDate",
            "licensePlateExpiration"
        ]);

    const [stations, setStations] = useState([]);
    const [skills, setSkills] = useState([]);
    const [medias, setMedias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [watchedType, setWatchedType] = useState(vehicle ? vehicle.type : "");
    const { register, errors, getValues, handleSubmit, reset, formState: { isSubmitting }, watch } = useForm({ mode: "onBlur", defaultValues: vehicle });
    const inputValues = getValues();
    const companyId = localStorage.getItem("company");
    const acTualStationId = localStorage.getItem("selected");
    const [vehicleId, setVehicleId] = useState({});
    const [insuranceCardImage, setInsuranceCardImage] = useState(null);
    const watchInsuranceCardImage = watch("insuranceCardImage", null);
    const [viewImage , setViewImage] = useState(null)
    useEffect(() => {
        if(watchInsuranceCardImage?.[0]){
            setViewImage(URL.createObjectURL(watchInsuranceCardImage[0]))
        }else if(insuranceCardImage){
            setViewImage(insuranceCardImage);
        }
    }, [watchInsuranceCardImage, insuranceCardImage])
    const onSubmit = async data => {
        if (!loading) setLoading(true);
        let id = vehicle ? vehicle.id : 0;

        if(data.tollCardStartDate){
            data.tollCardStartDate =  (data.tollCardStartDate)? moment(data.tollCardStartDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.tollCardEndDate){
            data.tollCardEndDate =  (data.tollCardEndDate)? moment(data.tollCardEndDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.rentalStartDate){
            data.rentalStartDate =  (data.rentalStartDate)? moment(data.rentalStartDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.rentalEndDate){
            data.rentalEndDate =  (data.rentalEndDate)? moment(data.rentalEndDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.rentalInitialConditionDate){
            data.rentalInitialConditionDate =  (data.rentalInitialConditionDate)? moment(data.rentalInitialConditionDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.rentalReturnConditionDate){
            data.rentalReturnConditionDate =  (data.rentalReturnConditionDate)? moment(data.rentalReturnConditionDate).utc().format('YYYY-MM-DD') : null;
        }
        if(data.licensePlateExpiration){
            data.licensePlateExpiration =  (data.licensePlateExpiration)? moment(data.licensePlateExpiration).utc().format('YYYY-MM-DD') : null;
        }
        let imagePath = null;
        if (data.insuranceCardImage.length > 0) {
            imagePath = await getObjectURL(data);
        } else {
            imagePath = insuranceCardImage;
        }
        delete data.insuranceCardImage;
        data.insuranceCard = imagePath;

        const result = await AddOrUpdateVehicle(api, data, id, oldVehicleId);
        setLoading(false);
        success(result);
    };

    const fetchFormData = async () => {
        let vehicleCall = {};
        let mediaCall;
        if (vehicle) {
            if (vehicle.type) {
                vehicleCall = {
                    findBy: {
                        criteria: {
                            company: companyId,
                            id: vehicle.id
                        },
                        get: "extraVehicle",
                        includes: extraFields[vehicle.type],
                        excludes: [
                            "company",
                            "tempDriverRoutes",
                            "vehicleDriverRecords",
                            "driverRoutes",
                            "location",
                            "station",
                            "incidents",
                            "events",
                            "updatedBy"
                        ]
                    }
                };
            }

            mediaCall = {
                findBy: {
                    criteria: {
                        type: "Vehicle",
                        entityId: vehicle.id
                    },
                    get: "media",
                    includes: ["id", "path"]
                }
            };
            setVehicleId(vehicle.id);
        }
        const result = await api
            .post("/api/lazy/manage/data", {
                actions: {
                    response: {
                        Station: {
                            findBy: {
                                criteria: {
                                    company: companyId
                                },
                                get: "station",
                                includes: {
                                    0: "id",
                                    1: "code"
                                }
                            }
                        },
                        Vehicle: vehicleCall,
                        Media: mediaCall
                    }
                }
            })
            .then(response => {
                setStations(response.data.data.station.map(s => ({ name: s.code, value: s.id })));
                if (response.data.data.media)
                    setMedias(response.data.data.media.map(m => ({ type: "doc", src: m.path, id: m.id })));
                return response.data.data.extraVehicle;
            });

        if (result) {
            const extraInfo = normalizeDate(result[0], [
                "ownedPurchaseConditionDate",
                "ownedSoldConditionDate",
                "leasingStartDate",
                "leasingInitialConditionDate",
                "leasingReturnConditionDate",
                "rentalStartDate",
                "rentalEndDate",
                "rentalInitialConditionDate",
                "rentalReturnConditionDate",
                "leasingEndDate",
                "tollCardStartDate",
                "tollCardEndDate",
                "licensePlateExpiration",
                "eldUnitCode",
                "amazonBranded",
                "cubicFeetStorage"
            ]);
            const vehicle_extraFields = { ...vehicle, ...extraInfo };
            reset(vehicle_extraFields);
        }

        setLoading(false);



    };

    const handleKey = (e, fn) => {
        if (e.keyCode === 27) {
            fn();
        }
    };

    const fetchSkillOptions = async () => {
        const results = await fetchSkills(api, companyId);
        if (results) {
            let skillRes = results.map(s => ({ name: s.name, value: s.id }));
            skillRes.unshift({ name: "Select Skill", value: '' })
            setSkills(skillRes);
        }
    };

    useEffect(() => {
        fetchFormData();
        fetchSkillOptions();
        if(vehicle) {
            setInsuranceCardImage(vehicle.insuranceCard);
        }

        document.addEventListener("keydown", e => handleKey(e, cancel), false);

        return () => {
            document.removeEventListener("keydown", e => handleKey(e, cancel), false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const errorMessages = {
        vin: {
            required: 'This field cannot be empty',
            exists: 'VIN already exist. Please choose a different vin',
        },
        vehicleId: {
            exists: 'Vehicle ID is already in use in this Station or is empty',
        },
        insuranceCardImage: {
            pattern: 'Only images are accepted',
        },
    }

    return (
        <ScrollyOverlayStyle>
            <ScrollyModalStyle
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                width="750px"
                onClick={(evt) => evt.stopPropagation()}
            >
                <header>
                    <SubTitle>Vehicle Details</SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                {loading ? (
                    <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                ) : (
                    <>
                        <ThirdTitle>Important Vehicle Information</ThirdTitle>
                        <Spacer top={2} />
                        <input type="hidden" name="company" value={companyId} ref={register} />

                        <Spacer right={1} inline>
                            <Dropdown
                                size="small"
                                name="stationId"
                                label="Station"
                                ref={register({ required: true })}
                                error={errors.stationId && "Please select a station"}
                                valid={!errors.stationId && inputValues.stationId && inputValues.stationId.length > 0}
                                options={stations}
                                Required={true}
                                visibleOptionsQty={7}
                            />
                        </Spacer>
                        <Spacer right={1} inline>
                            <Dropdown
                                size="small"
                                name="status"
                                label="Status"
                                ref={register({ required: true })}
                                error={errors.status && "This field can not be empty"}
                                valid={!errors.status && inputValues.status && inputValues.status.length > 0}
                                options={[
                                    { name: "Active", value: "Active" },
                                    { name: "Inactive", value: "Inactive" },
                                    { name: "Under Repair", value: "Under Repair" },
                                    { name: "Grounded", value: "Grounded" },
                                    { name: "Being Serviced", value: "Being Serviced" },
                                    { name: 'Body Shop', value: 'Body Shop'},
                                    { name: "Archived", value: "Archived" }
                                ]}
                                Required={true}
                            />
                        </Spacer>
                        <Spacer inline right={1}>
                            <Input
                                name="vehicleId"
                                label="Vehicle ID"
                                Required={true}
                                ref={register({ required: "This field cannot be empty" })}

                                ref={register({
                                    required: true,
                                    validate: {
                                        exists: async value => {                                           

                                            let res = await api.post('/api/lazy/manage/data', {
                                                "actions": {
                                                    "response": {
                                                        "Vehicle": {
                                                            "findBy": {
                                                                "criteria": {
                                                                    "vehicleId": value,
                                                                    "station": acTualStationId,
                                                                },
                                                                "get": "vehicles",
                                                                "includes": {
                                                                    0: "id"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            });

                                            if (res.data.data.vehicles.length > 0) {

                                                if (res.data.data.vehicles[0].id != vehicleId) {
                                                    return false
                                                }
                                            }

                                            return true;

                                        }
                                    }
                                })}



                                error={errors.vehicleId && errorMessages.vehicleId['exists']}
                                valid={!errors.vehicleId && inputValues.vehicleId && inputValues.vehicleId.length > 0}
                            />
                        </Spacer>
                        <Spacer inline>
                            <Input
                                name="currentMileage"
                                label="Mileage"
                                size={"small"}
                                defaultValue={!vehicle && '10000'}
                                Required={true}
                                ref={register({ required: true })}
                                error={errors.currentMileage && "This field cannot be empty"}
                                valid={!errors.currentMileage && inputValues.currentMileage && inputValues.currentMileage.length > 0}
                            />
                        </Spacer>
                        <Spacer top={2}>
                            <Input
                                name="overnightParkingLocation"
                                label="Current Overnight Parking Location"
                                ref={register}
                                error={errors.overnightParkingLocation && errors.overnightParkingLocation.message}
                                valid={
                                    !errors.overnightParkingLocation &&
                                    inputValues.overnightParkingLocation &&
                                    inputValues.overnightParkingLocation.length > 0
                                }
                                style={{ width: 750 }}
                            />
                        </Spacer>
                        <Spacer>
                            <input id="stationLatitude" type="hidden" />
                            <input id="stationLongitude" type="hidden" />
                            <input id="stationGeofence" value="10" type="hidden" />
                            <StationMap
                                id="StationMap"
                                height={150}
                                addressId="overnightParkingLocation"
                                longitudeId="stationLongitude"
                                latitudeId="stationLatitude"
                                geoFenceId="stationGeofence"
                                geoFenceBg="#aa030a"
                                options={{
                                    center: { lat: 42.315775, lng: -85.183631 },
                                    zoom: 13,
                                    mapTypeControl: false
                                }}
                            />
                        </Spacer>
                        <Spacer top={2} />
                        <Input
                            name="notes"
                            size="big"
                            label="Notes"
                            ref={register}
                            as="textarea"
                            style={{ width: 750, height: 150 }}
                        />
                        <Spacer top={6} />
                        <ThirdTitle>Vehicle Details</ThirdTitle>
                        <Spacer top={2} />
                        <Spacer inline right={2}>
                            <Input
                                name="vin"
                                label="VIN"
                                ref={register({ required: "This field cannot be empty" })}
                                ref={register({
                                    required: true,
                                    validate: {
                                        exists: async value => {
                                            let res = await api.post('/api/vehicle/check/vin/exist', {
                                                "vin": value,
                                                "vehicleId": vehicleId,
                                                "company": companyId
                                            });
                                            return res.data.status !== true
                                        }
                                    }
                                })}
                                Required={true}
                                error={errors.vin && errorMessages.vin[errors.vin.type]}
                                valid={!errors.vin && inputValues.vin && inputValues.vin.length > 0}
                                autoComplete='off'
                            />
                        </Spacer>
                        <Spacer inline right={2}>
                            <Input
                                name="state"
                                label="State"
                                size={"small"}
                                ref={register()}
                                error={errors.state && errors.state.message}
                                valid={!errors.state && inputValues.state && inputValues.state.length > 0}
                            />
                        </Spacer>
                        <Spacer inline right={2}>
                            <Input
                                name="licensePlate"
                                label="License Plate"
                                size={"small"}
                                Required={true}
                                ref={register({ required: "This field cannot be empty" })}
                                error={errors.licensePlate && errors.licensePlate.message}
                                valid={
                                    !errors.licensePlate &&
                                    inputValues.licensePlate &&
                                    inputValues.licensePlate.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer inline>
                            <DayPicker
                                name="licensePlateExpiration"
                                label="License Plate Exp."
                                size={"small"}
                                ref={register}
                                error={errors.licensePlateExpiration && errors.licensePlateExpiration.message}
                                valid={
                                    !errors.licensePlateExpiration &&
                                    inputValues.licensePlateExpiration &&
                                    inputValues.licensePlateExpiration.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer top={2} />
                        <Spacer inline right={3}>
                            <Input
                                name="make"
                                label="Make"
                                size={"small"}
                                Required={true}
                                ref={register({ required: "This field cannot be empty" })}
                                error={errors.make && errors.make.message}
                                valid={!errors.make && inputValues.make && inputValues.make.length > 0}
                            />
                        </Spacer>
                        <Spacer inline right={3}>
                            <Input
                                name="model"
                                label="Model"
                                size={"small"}
                                Required={true}
                                ref={register({ required: "This field cannot be empty" })}
                                error={errors.model && errors.model.message}
                                valid={!errors.model && inputValues.model && inputValues.model.length > 0}
                            />
                        </Spacer>
                        <Spacer inline right={3}>
                            <Dropdown
                                name="modelType"
                                label="Model Type"
                                size={"small"}
                                ref={register({ required: "This field cannot be empty" })}
                                error={errors.modelType && errors.modelType.message}
                                valid={!errors.modelType && inputValues.modelType && inputValues.modelType.length > 0}
                                options={[
                                    { name: "CDV Van", value: "cdv_van" },
                                    { name: "Large Cargo", value: "cargo_van" },
                                    { name: "XL Cargo", value: "xl_cargo_van" },
                                    { name: "Small Cargo", value: "small_cargo" },
                                    { name: "Box", value: "box" },
                                    { name: "Step Van", value: "step_van" },
                                    { name: "EDV Van", value: "edv_van" },
                                    { name: "Large Passenger Vehicle", value: "large_passenger_vehicle" }
                                ]}
                                visibleOptionsQty={7}
                            />
                        </Spacer>
                        <Spacer inline right={2}>
                            <Input
                                name="year"
                                label="Year"
                                size={"small"}
                                Required={true}
                                ref={register({ required: true, pattern: patternYear })}
                                error={errors.year && "Enter a valid year. Ex: YYYY"}
                                valid={!errors.year && inputValues.year && inputValues.year.length > 0}
                            />
                        </Spacer>
                        <Spacer inline>
                            <Input
                                name="height"
                                label="Height (inches)"
                                size={"small"}
                                ref={register}
                                error={errors.height && errors.height.message}
                                valid={!errors.height && inputValues.height && inputValues.height.length > 0}
                            />
                        </Spacer>
                        <Spacer inline top={2}>
                            <Spacer inline right={4}>
                                <Input
                                    name="eldUnitCode"
                                    label="ELD Unit Code"
                                    ref={register}
                                    valid={!errors.eldUnitCode && inputValues.eldUnitCode && inputValues.eldUnitCode.length > 0}
                                />
                            </Spacer>
                            <Spacer inline style={{ verticalAlign: 'bottom' }} right={4}>
                                <CheckBox
                                    name='amazonBranded'
                                    title={'Amazon Branded'}
                                    ref={register}
                                    options={[
                                        { value: 'true', label: 'Yes' }
                                    ]}
                                />
                            </Spacer>
                            <Spacer inline >
                                <Input
                                    type="number"
                                    step="any"
                                    name="cubicFeetStorage"
                                    label="Cubic Feet of Storage"
                                    ref={register}
                                    valid={!errors.cubicFeetStorage && inputValues.cubicFeetStorage && inputValues.cubicFeetStorage.length > 0}
                                />
                            </Spacer>
                        </Spacer>

                        <Spacer inline top={2}>
                            <Spacer inline right={4}>
                                <Dropdown
                                    name="skill"
                                    label="Skill"
                                    ref={register({ required: "This field cannot be empty"  })}
                                    defaultValue={vehicle ? vehicle.skill ? vehicle.skill.id : 0 : 0}
                                    options={skills}
                                    Required={true}
                                    error={errors.skill && errors.skill.message}
                                    valid={
                                        !errors.fuelCardNumber &&
                                        inputValues.fuelCardNumber &&
                                        inputValues.fuelCardNumber.length > 0
                                    }
                                    visibleOptionsQty={7}
                                />
                            </Spacer>
                            <Spacer inline style={{ verticalAlign: 'bottom' }} right={4}>
                                <CheckBox
                                    name='unenroll'
                                    title={'Unenroll from Auto-Assignment'}
                                    ref={register}
                                    options={[
                                        { value: 'true', label: 'Yes' }
                                    ]}
                                />
                            </Spacer>
                        </Spacer>

                        <Spacer top={6} />
                        <ThirdTitle>Fuel Details</ThirdTitle>
                        <Spacer top={2} />
                        <Spacer inline right={1}>
                            <Input
                                name="fuelCardNumber"
                                label="Fuel Card #"
                                ref={register}
                                error={errors.fuelCardNumber && errors.fuelCardNumber.message}
                                valid={
                                    !errors.fuelCardNumber &&
                                    inputValues.fuelCardNumber &&
                                    inputValues.fuelCardNumber.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer inline right={1}>
                            <Input
                                name="tankCapacity"
                                label="Tank Capacity (gallons)"
                                ref={register}
                                error={errors.tankCapacity && errors.tankCapacity.message}
                                valid={
                                    !errors.tankCapacity &&
                                    inputValues.tankCapacity &&
                                    inputValues.tankCapacity.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer inline>
                            <Input
                                name="gasType"
                                label="Gas Type"
                                size={"small"}
                                ref={register}
                                error={errors.gasType && errors.gasType.message}
                                valid={!errors.gasType && inputValues.gasType && inputValues.gasType.length > 0}
                            />
                        </Spacer>
                        <Spacer top={6} />
                        <ThirdTitle>Toll Card Details</ThirdTitle>
                        <Spacer top={2} />
                        <Spacer inline right={1}>
                            <Input
                                name="tollCardNumber"
                                label="Toll Card #"
                                size={"big"}
                                ref={register}
                                error={errors.tollCardNumber && errors.tollCardNumber.message}
                                valid={
                                    !errors.tollCardNumber &&
                                    inputValues.tollCardNumber &&
                                    inputValues.tollCardNumber.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer inline right={1} style={{ position: "relative", zIndex: 10 }}>
                            <DayPicker
                                name="tollCardStartDate"
                                label="Date Start"
                                size={"small"}
                                ref={register}
                                error={errors.tollCardStartDate && "Field required"}
                                valid={
                                    !errors.tollCardStartDate &&
                                    inputValues.tollCardStartDate &&
                                    inputValues.tollCardStartDate.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer inline style={{ position: "relative", zIndex: 10 }}>
                            <DayPicker
                                name="tollCardEndDate"
                                label="Date End"
                                size={"small"}
                                ref={register}
                                error={errors.tollCardEndDate && "Field required"}
                                valid={
                                    !errors.tollCardEndDate &&
                                    inputValues.tollCardEndDate &&
                                    inputValues.tollCardEndDate.length > 0
                                }
                            />
                        </Spacer>
                        <Spacer top={6} />
                        <ThirdTitle>Insurance Details</ThirdTitle>
                        <Spacer top={2} />
                        <Spacer style={{"display": "flex", "alignContent": "center"}}>
                            <Spacer inline right={1}>
                                <Input
                                    name="insuranceCompany"
                                    label="Insurance Company"
                                    ref={register}
                                    error={errors.insuranceCompany && errors.insuranceCompany.message}
                                    valid={
                                        !errors.insuranceCompany &&
                                        inputValues.insuranceCompany &&
                                        inputValues.insuranceCompany.length > 0
                                    }
                                />
                            </Spacer>
                            <Spacer inline right={1}>
                                <Input
                                    name="policyNumber"
                                    label="Policy #"
                                    ref={register}
                                    error={errors.policyNumber && errors.policyNumber.message}
                                    valid={
                                        !errors.policyNumber &&
                                        inputValues.policyNumber &&
                                        inputValues.policyNumber.length > 0
                                    }
                                />
                            </Spacer>
                            <Spacer inline>
                                <Uploader
                                    name="insuranceCardImage"
                                    label="Insurance Card #"
                                    viewImage={true}
                                    placeholder={!!insuranceCardImage && <img src={insuranceCardImage} alt={'insuranceCardImage'} onError={e => {
                                        e.target.src = 'https://s3.amazonaws.com/dsp.data.storage.general/d92b41c8_2021_08_20_icon-person.png';
                                    }} />}
                                    ref={register({
                                        pattern: imageExtention
                                    })}
                                    size={"small"}
                                    error={errors.insuranceCardImage && errorMessages.insuranceCardImage[errors.insuranceCardImage.type]}
                                    value={insuranceCardImage}
                                    valid={
                                        !errors.insuranceCardImage &&
                                        inputValues.insuranceCardImage &&
                                        inputValues.insuranceCardImage.length > 0
                                    }
                                />
                                { viewImage && <Link href={viewImage} target='_blank'> View Insurance Card </Link>}
                            </Spacer>
                        </Spacer>
                        <Spacer top={6} />
                        <ThirdTitle>Ownership Details</ThirdTitle>
                        <Spacer top={2} />
                        <Dropdown
                            size="small"
                            name="type"
                            label="Type"
                            ref={register()}
                            error={errors.type && "This field can not be empty"}
                            valid={!errors.type && inputValues.type && inputValues.type.length > 0}
                            options={[
                                { name: "Owned", value: "Owned" },
                                { name: "Leased", value: "Leased" },
                                { name: "Rented", value: "Rented" }
                            ]}
                            onChange={e => {
                                setTimeout(() => {
                                    setWatchedType(e.value);
                                }, 10);
                            }}
                        />

                        <Spacer top={6} />
                        <ThirdTitle>Contract</ThirdTitle>
                        <Spacer top={2} />

                        <DropUploader
                            ref={register}
                            name="file"
                            accept={"application/pdf"}
                            multiple={true}
                            placeholder={medias}
                            onDelete={async (key, file) => {
                                if (medias[key]) {
                                    deleteMedia({ id: medias[key].id, file: medias[key].src });
                                    const nMedias = [...medias];
                                    nMedias.splice(key, 1);
                                    setMedias(nMedias);
                                }
                            }}
                        />
                        {medias.map(m => (
                            <input type="hidden" ref={register} name="file_id[]" value={m.id} />
                        ))}

                        {watchedType === "Owned" && (
                            <OwnedForm register={register} errors={errors} inputValues={inputValues} />
                        )}
                        {watchedType === "Leased" && (
                            <LeasedForm register={register} errors={errors} inputValues={inputValues} />
                        )}
                        {watchedType === "Rented" && (
                            <RentedForm register={register} errors={errors} inputValues={inputValues} />
                        )}
                        <Spacer top={5} />
                        <Button type={"primary"} Type={'submit'} disabled={Object.keys(errors).length > 0 || isSubmitting} style={{ marginLeft: "auto", display: "block", width: 80 }}>
                            Save
                        </Button>
                    </>
                )}
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    );
};

const OwnedForm = ({ register, errors, inputValues }) => (
    <>
        <Spacer top={5} />
        <Subtitle>Location Information</Subtitle>
        <Spacer top={2} />

        <ThirdTitle>Purchase Condition</ThirdTitle>
        <Spacer top={2} />

        <Spacer inline right={1}>
            <Input
                size={"medium"}
                name="ownedPurchaseConditionPrice"
                label="Price"
                ref={register}
                error={errors.ownedPurchaseConditionPrice && "This field should be a number"}
                valid={
                    !errors.ownedPurchaseConditionPrice &&
                    inputValues.ownedPurchaseConditionPrice &&
                    inputValues.ownedPurchaseConditionPrice.length > 0
                }
            />
        </Spacer>
        <Spacer inline right={1}>
            <Input
                size={"medium"}
                name="ownedPurchaseConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.ownedPurchaseConditionOdometer && "This field should be a number"}
                valid={
                    !errors.ownedPurchaseConditionOdometer &&
                    inputValues.ownedPurchaseConditionOdometer &&
                    inputValues.ownedPurchaseConditionOdometer.length > 0
                }
            />
        </Spacer>
        <Spacer inline style={{ position: "relative", zIndex: 11 }}>
            <DayPicker
                size={"small"}
                name="ownedPurchaseConditionDate"
                label="Date"
                ref={register}
                error={errors.ownedPurchaseConditionDate && "Field required"}
                valid={
                    !errors.ownedPurchaseConditionDate &&
                    inputValues.ownedPurchaseConditionDate &&
                    inputValues.ownedPurchaseConditionDate.length > 0
                }
            />
        </Spacer>
        <Spacer top={2} bottom={2} />
        <ThirdTitle>Sold Condition</ThirdTitle>
        <Spacer />
        <Spacer inline right={1}>
            <Input
                size={"medium"}
                name="ownedSoldConditionPrice"
                label="Price"
                ref={register}
                error={errors.ownedSoldConditionPrice && "This field should be a number"}
                valid={
                    !errors.ownedSoldConditionPrice &&
                    inputValues.ownedSoldConditionPrice &&
                    inputValues.ownedSoldConditionPrice.length > 0
                }
            />
        </Spacer>
        <Spacer inline right={1}>
            <Input
                size={"medium"}
                name="ownedSoldConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.ownedSoldConditionOdometer && "This field should be a number"}
                valid={
                    !errors.ownedSoldConditionOdometer &&
                    inputValues.ownedSoldConditionOdometer &&
                    inputValues.ownedSoldConditionOdometer.length > 0
                }
            />
        </Spacer>
        <Spacer inline style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                size={"small"}
                name="ownedSoldConditionDate"
                label="Date"
                ref={register}
                error={errors.ownedSoldConditionDate && "Field required"}
                valid={
                    !errors.ownedSoldConditionDate &&
                    inputValues.ownedSoldConditionDate &&
                    inputValues.ownedSoldConditionDate.length > 0
                }
            />
        </Spacer>
    </>
);

const LeasedForm = ({ register, errors, inputValues }) => (
    <>
        <Spacer top={5} />
        <ThirdTitle>Leasing Information</ThirdTitle>
        <Spacer top={2} />

        <Spacer inline right={1}>
            <Input
                name="leasingCompany"
                label="Leasing Company"
                ref={register()}
                error={errors.leasingCompany && "This field should be a number"}
                valid={!errors.leasingCompany && inputValues.leasingCompany && inputValues.leasingCompany.length > 0}
            />
        </Spacer>
        <Spacer inline right={1}>
            <Input
                size={"medium"}
                name="leasingAgreementNumber"
                label={"Agreement #"}
                ref={register}
                error={errors.leasingAgreementNumber && "This field should be a number"}
                valid={
                    !errors.leasingAgreementNumber &&
                    inputValues.leasingAgreementNumber &&
                    inputValues.leasingAgreementNumber.length > 0
                }
            />
        </Spacer>
        <Spacer inline>
            <Input
                size={"small"}
                name="leasingPricePerMonth"
                label={"Price Per month"}
                ref={register({
                    pattern: patternFloatValue,
                })}
                error={errors.leasingPricePerMonth && "This field should be a number"}
                valid={
                    !errors.leasingPricePerMonth &&
                    inputValues.leasingPricePerMonth &&
                    inputValues.leasingPricePerMonth.length > 0
                }
            />
        </Spacer>

        <Spacer top={2} />

        <Spacer inline right={1} style={{ position: "relative", zIndex: 11 }}>
            <DayPicker
                name="leasingStartDate"
                label={"Start Date"}
                ref={register}
                error={errors.leasingStartDate && "This field should be a number"}
                valid={
                    !errors.leasingStartDate && inputValues.leasingStartDate && inputValues.leasingStartDate.length > 0
                }
            />
        </Spacer>
        <Spacer inline right={1} style={{ position: "relative", zIndex: 11 }}>
            <DayPicker
                name="leasingEndDate"
                label={"End Date"}
                ref={register}
                error={errors.leasingEndDate && "This field should be a number"}
                valid={!errors.leasingEndDate && inputValues.leasingEndDate && inputValues.leasingEndDate.length > 0}
            />
        </Spacer>
        <Spacer inline>
            <Input
                size={"small"}
                name="leasingDeposit"
                label="Deposit"
                ref={register}
                error={errors.leasingDeposit && "This field should be a number"}
                valid={!errors.leasingDeposit && inputValues.leasingDeposit && inputValues.leasingDeposit.length > 0}
            />
        </Spacer>

        <Spacer top={6} />
        <ThirdTitle>Leasing Condition</ThirdTitle>
        <Spacer top={2} />

        <Spacer inline right={4}>
            <Subtitle>Initial Condition</Subtitle>
            <Spacer top={2} />

            <Input
                size={"small"}
                name="leasingInitialConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.leasingInitialConditionOdometer && "This field should be a number"}
                valid={
                    !errors.leasingInitialConditionOdometer &&
                    inputValues.leasingInitialConditionOdometer &&
                    inputValues.leasingInitialConditionOdometer.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={12} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                size={"small"}
                name="leasingInitialConditionDate"
                label="Date"
                ref={register}
                error={errors.leasingInitialConditionDate && "This field should be a number"}
                valid={
                    !errors.leasingInitialConditionDate &&
                    inputValues.leasingInitialConditionDate &&
                    inputValues.leasingInitialConditionDate.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={4}>
            <Subtitle>Return Condition</Subtitle>
            <Spacer top={2} />

            <Input
                size={"small"}
                name="leasingReturnConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.leasingReturnConditionOdometer && "This field should be a number"}
                valid={
                    !errors.leasingReturnConditionOdometer &&
                    inputValues.leasingReturnConditionOdometer &&
                    inputValues.leasingReturnConditionOdometer.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={4} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                size={"small"}
                name="leasingReturnConditionDate"
                label="Date"
                ref={register}
                error={errors.leasingReturnConditionDate && "This field should be a number"}
                valid={
                    !errors.leasingReturnConditionDate &&
                    inputValues.leasingReturnConditionDate &&
                    inputValues.leasingReturnConditionDate.length > 0
                }
            />
        </Spacer>
    </>
);

const RentedForm = ({ register, errors, inputValues }) => (
    <>
        <Spacer top={5} />
        <ThirdTitle>Rental Information</ThirdTitle>
        <Spacer top={2} />

        <Spacer inline right={1}>
            <Input
                name="rentalCompany"
                label="Rental Company"
                ref={register}
                error={errors.rentalCompany && "This field should be a number"}
                valid={!errors.rentalCompany && inputValues.rentalCompany && inputValues.rentalCompany.length > 0}
            />
        </Spacer>
        <Spacer inline right={1}>
            <Input
                name="rentalAgreementNumber"
                label={"Agreement #"}
                ref={register}
                error={errors.rentalAgreementNumber && "This field should be a number"}
                valid={
                    !errors.rentalAgreementNumber &&
                    inputValues.rentalAgreementNumber &&
                    inputValues.rentalAgreementNumber.length > 0
                }
            />
        </Spacer>
        <Spacer inline>
            <Input
                size={"small"}
                name="rentalPricePerMonth"
                label={"Price Per month"}
                ref={register({
                    pattern: patternFloatValue,
                })}
                error={errors.rentalPricePerMonth && "This field should be a number"}
                valid={
                    !errors.rentalPricePerMonth &&
                    inputValues.rentalPricePerMonth &&
                    inputValues.rentalPricePerMonth.length > 0
                }
            />
        </Spacer>

        <Spacer top={2} />

        <Spacer inline right={1} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                name="rentalStartDate"
                label={"Start Date"}
                ref={register}
                error={errors.rentalStartDate && "This field should be a number"}
                valid={!errors.rentalStartDate && inputValues.rentalStartDate && inputValues.rentalStartDate.length > 0}
            />
        </Spacer>
        <Spacer inline right={1} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                name="rentalEndDate"
                label={"End Date"}
                ref={register}
                error={errors.rentalEndDate && "This field should be a number"}
                valid={!errors.rentalEndDate && inputValues.rentalEndDate && inputValues.rentalEndDate.length > 0}
            />
        </Spacer>
        <Spacer inline>
            <Input
                size={"small"}
                name="rentalDeposit"
                ref={register({
                    pattern: patternNumericValue,
                })}
                label="Deposit"
                error={errors.rentalDeposit && "This field should be a number"}
                valid={!errors.rentalDeposit && inputValues.rentalDeposit && inputValues.rentalDeposit.length > 0}
            />
        </Spacer>

        <Spacer top={6} />
        <ThirdTitle>Rental Condition</ThirdTitle>
        <Spacer top={2} />

        <Spacer inline right={4}>
            <Subtitle>Initial Condition</Subtitle>
            <Spacer top={2} />

            <Input
                size="small"
                name="rentalInitialConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.rentalInitialConditionOdometer && "This field should be a number"}
                valid={
                    !errors.rentalInitialConditionOdometer &&
                    inputValues.rentalInitialConditionOdometer &&
                    inputValues.rentalInitialConditionOdometer.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={12} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                size={"small"}
                name="rentalInitialConditionDate"
                label="Date"
                ref={register}
                error={errors.rentalInitialConditionDate && "This field should be a number"}
                valid={
                    !errors.rentalInitialConditionDate &&
                    inputValues.rentalInitialConditionDate &&
                    inputValues.rentalInitialConditionDate.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={4}>
            <Subtitle>Return Condition</Subtitle>
            <Spacer top={2} />

            <Input
                size={"small"}
                name="rentalReturnConditionOdometer"
                label="Odometer"
                ref={register}
                error={errors.rentalReturnConditionOdometer && "This field should be a number"}
                valid={
                    !errors.rentalReturnConditionOdometer &&
                    inputValues.rentalReturnConditionOdometer &&
                    inputValues.rentalReturnConditionOdometer.length > 0
                }
            />
        </Spacer>

        <Spacer inline right={4} style={{ position: "relative", zIndex: 10 }}>
            <DayPicker
                size={"small"}
                name="rentalReturnConditionDate"
                label="Date"
                ref={register}
                error={errors.rentalReturnConditionDate && "This field should be a number"}
                valid={
                    !errors.rentalReturnConditionDate &&
                    inputValues.rentalReturnConditionDate &&
                    inputValues.rentalReturnConditionDate.length > 0
                }
            />
        </Spacer>
    </>
);

const hide = () =>
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(modalRoot);
    }, 1);

const showVehicleForm = async opts => {

    let { api, children, vehicle, oldVehicleId } = opts || {};
    // console.log({one: vehicle});
    if (vehicle) {
        //vehicle.station = vehicle.station.id;
        vehicle.stationId = vehicle.station.id;
    }
    // console.log({two: vehicle});

    return new Promise((resolve, reject) => {
        const success = data => hide() && resolve(data);
        const cancel = data => hide() && reject(data);

        return ReactDOM.render(
            <VehicleForm api={api} vehicle={vehicle} success={success} cancel={cancel} children={children} oldVehicleId={oldVehicleId} />,
            modalRoot
        );
    });
};

export { showVehicleForm, extraFields, normalizeDate };
