import React from "react";
import Modal from "../../../components/Modal";
import {portalPromise} from "../../../components/Dialog";
import {Input, Text, Button} from '@dspworkplace/ui';
import {Table, TableHeader, TableRow, TableData, TableFooter} from '../../../components/Table'
import {useForm} from "react-hook-form";
import DayPicker from '../../../components/Calendar/day';

const PlanForm = ({success, cancel, planItems=[],isValidation=true}) => {
    const {register, handleSubmit, errors} = useForm()
    const onSubmit = data => {
        success(data.service)
    }

    return (
        // @ts-ignore
        <Modal
            width={'748px'}
            visible={true}
            title={'Vehicle Maintenance Last Service'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table>
                    <TableHeader
                        headers={[
                            {label: 'Plan Rule', width:'180px'},
                            {label: 'Last Mile Service', width:'160px'},
                            {label: 'Last Date Service', width:'160px'}
                        ]}
                    />
                    {planItems.map((item,key) => {
                        return (
                            <TableRow key={key}>
                                <TableData width={'180px'}>
                                    <Text>{item.category.label}</Text>
                                </TableData>
                                <TableData width={'160px'}>
                                    {item.category?.isLastMileServiceRequired === true ?
                                        <Input
                                            name={`service[${key}].miles`}
                                            type={'number'}
                                            ref={isValidation ? register({required: 'Field Required'}):register()}
                                            size={'small'}
                                            defaultValue={item.lastMile ?? ""}
                                            error={errors?.service?.[key]?.miles && errors.service[key].miles.message}
                                        />
                                        : "-"}
                                </TableData>
                                <TableData width={'300px'}>
                                    {item.category?.isLastServiceDateRequired === true ?
                                        <DayPicker
                                            name={`service[${key}].date`}
                                            size='medium'
                                            ref={isValidation ? register({required: 'Field Required'}):register()}
                                            defaultValue={item.lastServiceDate ? new Date(item.lastServiceDate.timestamp*1000) : ""}
                                            error={errors?.service?.[key]?.date && errors.service[key].date.message}
                                        />
                                    : "-" }
                                </TableData>
                                <input type={'hidden'} ref={register} name={`service[${key}].item_id`} value={item.id} />
                            </TableRow>
                        )
                    })}
                    <TableFooter>
                        <Button type={'primary'} style={{marginLeft:'auto'}}>Save</Button>
                    </TableFooter>
                </Table>
            </form>
        </Modal>
    )
}

export const openPlanForm = ({planItems = [], isValidation = true}) => {
    return portalPromise((success, cancel)=>{
        return <PlanForm
            success={success}
            cancel={cancel}
            planItems={planItems}
            isValidation={isValidation}
        />
    })
}