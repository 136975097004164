import apiFactory from '../../../api/factory';

type VehicleLookupResource = {

}

type VehicleLookupParam = {
    station_id: number
}

const {call} = apiFactory();
const resource = 'vehicle'
export const GetVehicleLookup = (props: VehicleLookupParam): VehicleLookupResource[] => {
    const {station_id} = props
    return call(props,`api/v2/${resource}/lookup/station/${station_id}`,'get')
}