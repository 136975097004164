import React, { useEffect, useMemo, useRef, useState, useImperativeHandle } from "react";
import styled from "styled-components";
import { Icon, Input, Theme } from "@dspworkplace/ui";
import DayPicker, { DayPickerProps } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import VMasker from "vanilla-masker";

const Container = styled.div`
    position: absolute;
    left: 0;
    z-index: 3;
    transform: translateY(4px);
    background: ${Theme.colors.neutrals.white};

    > .DayPicker {
        border: 1px solid ${Theme.colors.neutrals.silver};
        border-radius: ${Theme.defaultRadius};
        box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}26;
        font-family: ${Theme.font.main};

        .DayPicker-wrapper {
            .DayPicker-Caption {
                text-align: center;
            }

            .DayPicker-Month {
                border-collapse: separate;
                border-spacing: 0 4px;
            }

            .DayPicker-Day {
                border-radius: 0;
                padding: 0;
                font-size: ${Theme.font.small.size};

                &:focus {
                    outline: none;
                }

                &--selected {
                    background-color: ${Theme.colors.info.bg} !important;
                    color: ${Theme.colors.info.text};
                    border-color: ${Theme.colors.info.border};
                    border-width: 1px;
                    border-style: solid;
                    border-radius: ${Theme.defaultRadius};
                }
            }
        }
    }

    > .daypicker-range {
        .DayPicker-Day--start,
        .DayPicker-Day--end {
            width: 100%;
            height: 100%;
        }

        .DayPicker-Day--start {
            &:not(.DayPicker-Day--end) {
                border-right: none;
            }
        }

        .DayPicker-Day--end {
            &:not(.DayPicker-Day--start) {
                border-left: none;
            }
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }

        .DayPicker-Day--start:not(.DayPicker-Day--end) {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .DayPicker-Day--end:not(.DayPicker-Day--start) {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`;

const NavBarContainer = styled.div`
    position: absolute;
    display: flex;
    align-content: left;
    justify-content: space-between;
    width: 100%;
    top: 20px;
    padding: 0 16px;

    > svg {
        cursor: pointer;
    }
`;

const NavBar = ({ onPreviousClick, onNextClick, className }) => {
    return (
        <div className={className}>
            <NavBarContainer>
                <Icon.ArrowLeft size="20px" color={Theme.colors.info.border} onClick={() => onPreviousClick()} />
                <Icon.ArrowRight size="20px" color={Theme.colors.info.border} onClick={() => onNextClick()} />
            </NavBarContainer>
        </div>
    );
};

// const handleDateFormat = (date,format) => {
//     const utc = moment(date).utc().format();
//     const formatted = moment(date).format(format);
//     return {utc:utc,original:formatted};
// }

const mergeRefs = (refs) => {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

type DatePickerRangeInterval = "day" | "week" | "month" | "year";

interface DatePickerRangeOptions {
    interval: DatePickerRangeInterval;
    onFromSelect?: (date: Date | undefined) => void;
    onToSelect?: (date: Date | undefined) => void;
}

interface DisableDatesOptions {
    before?: Date | null;
    after?: Date | null;
}

interface DatePickerOptions extends DayPickerProps {
    /** What time interval to pick */
    picker?: DatePickerRangeInterval;

    /** If set, turns into a date range picker */
    range?: DatePickerRangeOptions | DatePickerRangeInterval | false;

    /** If set, multiple dates can be selected - not compatible with range */
    multiple?: boolean;

    /** Date input format (moment.format) */
    input?: string;

    /** Date output format (moment.format) */
    output?: string;

    /** Connects dates to display */
    glue?: string;

    /** Triggered when input value changes */
    onChange?: (value: string | Array<string>) => void;

    /** Triggered when input value changes */
    onKeyUp?: (value: string | Array<string>) => void;

    /** DayPicker props */
    dayPicker?: DayPickerProps;

    /** Input name */
    name: string;

    disableDates?: DisableDatesOptions;

    [prop: string]: any;

    handleManualDate?: boolean;
}

const DatePicker = React.forwardRef<any, DatePickerOptions>((opts, ref) => {
    let dayPickerProps = opts.dayPicker || {};
    let picker = opts.picker || "day";
    let multiple = opts.multiple;

    let range: DatePickerRangeOptions | false = false;

    const defaultRangeOpts: DatePickerRangeOptions = {
        interval: "day",
        onFromSelect: (day) => setDates([day, dates?.[1]]),
        onToSelect: (day) => setDates([dates?.[0], day]),
    };
    if (opts.range && !multiple) {
        if (typeof opts.range === "string")
            range = {
                ...defaultRangeOpts,
                interval: opts.range,
            };
        else if (typeof opts.range === "object")
            range = {
                ...defaultRangeOpts,
                ...opts.range,
            };

        if (range && range.interval) picker = range.interval;
    }

    let input: string = opts.input || "MM/DD/YYYY";
    let output: string = opts.output || "YYYY-MM-DD";
    let glue: string = opts.glue || " – ";

    let defaultRangeDate = opts.defaultRangeValue || [];
    // const [defaultRangeDate, setDefaultRangeDate] = useState(opts.defaultRangeValue || []);

    // useEffect(() => {
    //     setDefaultRangeDate(opts.defaultRangeValue || []);
    //     setDates(opts.defaultRangeValue || []);
    // }, [opts.defaultRangeValue]);

    const [dates, setDates] = useState<Date | Date[] | undefined>(multiple || range ? defaultRangeDate : undefined);
    const [enteredTo, setEnteredTo] = useState<Date | undefined>(undefined);

    const [open, setOpen] = useState<boolean>(false);

    const dayPickerPropsRef = useRef<any>(dayPickerProps);
    const popupRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const displayRef = useRef<HTMLInputElement>(null);
    const handleManualDate: boolean = opts?.handleManualDate ? opts?.handleManualDate : false;
    const [handleManualDateFlag, setHandleManualDateFlag] = useState(false);

    useImperativeHandle(ref, () => ({
        clear: () => {
            if (displayRef.current?.value) {
                displayRef.current.value = "";
            }
        },
    }));

    const togglePopup = (e, prop = "target") => {
        if (popupRef.current && !popupRef.current.contains(e[prop])) {
            if (range && displayRef.current?.value) {
                const dateRangeArr = displayRef.current.value.split(glue);
                if (dateRangeArr.length < 2 && !handleManualDate) {
                    setOpen(true);
                }
            } else {
                setOpen(false);
            }
        } else if (!open) {
            if (!open && !handleManualDateFlag) {
                setOpen(true);
            } else if (handleManualDateFlag) {
                setOpen(false);
            } else {
                setOpen(true);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", togglePopup);
        return () => document.removeEventListener("click", togglePopup);
    }, [popupRef]);

    const inputToMask = useMemo(() => {
        if (!input) {
            return null;
        }
        let visual = /[\w]{1}(?![^\[]*\])/gi;
        return input
            .replace(/[wW]{1}(?![^\[]*\])/g, "99")
            .replaceAll(visual, "9")
            .replace(/[\[\]]/g, "");
    }, [input]);

    const handleChange = (e) => {
        setHandleManualDateFlag(true);
        let value = e.target.value;
        if (!value) {
            if (opts.onChange) {
                opts.onChange("");
            }
            return;
        }
        if (!range && !multiple) {
            let masked = VMasker.toPattern(value, inputToMask);

            if (displayRef.current) displayRef.current.value = masked;

            let test = moment(masked, input, undefined, true);
            if (!test.isValid()) return;

            setDates(test.toDate());
        }
    };

    const handleKeyUp = (e) => {
        if (!handleManualDate) return;
        setOpen(false);
        let value = e.target.value;
        if (!value) {
            if (opts.onKeyUp) {
                opts.onKeyUp("");
            }
            return;
        }
        const dateRangeRegex = /^\d{2}\/\d{2}\/\d{4}\s*-\s*\d{2}\/\d{2}\/\d{4}$/;
        const isFormatValid = dateRangeRegex.test(value);
        if (isFormatValid === true) {
            let dateRangeArr = value.split(" - ");
            if (Array.isArray(dateRangeArr)) {
                let startDate = moment(dateRangeArr[0]);
                let endDate = moment(dateRangeArr[1]);
                if (startDate.isValid() && endDate.isValid()) {
                    if (getEndOf(endDate.toDate()).getTime() < startDate.toDate().getTime()) {
                        setEnteredTo(startDate.toDate());
                        return;
                    } else {
                        setEnteredTo(undefined);
                        setDates([startDate.toDate(), endDate.toDate()]);
                        return;
                    }
                }
            }
            setEnteredTo(dates?.[0]);
            return;
        } else {
            setEnteredTo(dates?.[0]);
            return;
        }
    };

    const handleBlur = (e) => {
        togglePopup(e, "relatedTarget");
    };

    const handleFocus = (e) => {
        togglePopup(e);
    };

    const getStartOf = (day: Date): Date => moment(day).startOf(picker).toDate();
    const getEndOf = (day: Date): Date => moment(day).endOf(picker).toDate();

    const toggleSelectedDay = (day) => {
        if (!Array.isArray(dates)) throw new TypeError("dates must be an array");

        const copy = [...dates];

        if (copy.includes(day)) copy.splice(copy.indexOf(day), 1);
        else copy.push(getStartOf(day));

        return setDates(copy);
    };

    const handleDayClick = (day) => {
        if (multiple) return toggleSelectedDay(getStartOf(day));
        if (range)
            if (!dates?.[0]) return range.onFromSelect?.(getStartOf(day));
            else if (!dates?.[1]) {
                if (getEndOf(day).getTime() < dates[0].getTime()) return;
                setEnteredTo(undefined);
                setOpen(false);
                return range.onToSelect?.(getEndOf(day));
            } else {
                day = getStartOf(day);
                range.onFromSelect?.(day);
                setDates([day, undefined]);
                return;
            }

        setDates(day);

        if (!range && !multiple) setOpen(false);
    };

    const handleDayMouseEnter = (day) => {
        if (range && Array.isArray(dates) && dates[0] && !dates[1])
            if (getEndOf(day).getTime() < dates[0].getTime()) setEnteredTo(dates[0]);
            else setEnteredTo(getEndOf(day));
    };

    const selectedDays = useMemo(() => {
        if (!dates || (Array.isArray(dates) && dates.length === 0)) return undefined;

        let ret: any = {};

        if (dates && !Array.isArray(dates)) {
            if (picker !== "day")
                ret = {
                    from: getStartOf(dates),
                    to: getEndOf(dates),
                };
            else ret = dates;
        }

        if (range && Array.isArray(dates)) {
            ret.from = dates[0];

            if (dates[1]) ret.to = dates[1];
            else if (enteredTo) ret.to = enteredTo;
        }

        return ret;
    }, [dates, enteredTo]);

    const modifiers = useMemo(() => {
        if (!dates || (Array.isArray(dates) && dates.length === 0)) return undefined;

        let ret: any = {};

        if (picker !== "day" && dates && !Array.isArray(dates)) {
            return {
                start: getStartOf(dates),
                end: getEndOf(dates),
            };
        }

        if (range && Array.isArray(dates)) {
            ret.start = dates[0];
            ret.end = dates[1] || enteredTo;

            if (ret.start && !dates[1])
                ret.disabled = {
                    before: ret.start,
                };

            return ret;
        }

        return undefined;
    }, [dates, enteredTo]);

    const displayValue = useMemo(() => {
        let copy = Array.isArray(dates) ? [...dates] : [dates];
        if (range) {
            return copy.map((d) => d && moment(d).format(input)).join(glue);
        } else {
            return copy
                .map((d) => d && moment(d).format(input))
                .filter((d) => d)
                .join(glue);
        }
    }, [dates, glue, input]);

    const inputValue = useMemo(() => {
        let v = "";

        if (!selectedDays) return v;

        if (!enteredTo && selectedDays.from && selectedDays.to)
            if (range || multiple)
                v = [moment(selectedDays.from).format(output), moment(selectedDays.to).format(output)].join(glue);
            else v = moment(selectedDays.from).format(output);

        if (!range && !multiple) {
            v = moment(selectedDays).format(output);

            if (v === inputRef.current?.value) return v;
        }

        if (v && opts.onChange) opts.onChange(v);

        return v;
    }, [selectedDays]);

    useEffect(() => {
        if (displayRef.current) displayRef.current.value = displayValue;
    }, [displayValue]);

    useEffect(() => {
        if (!range && !multiple && inputValue) dayPickerPropsRef.current.month = moment(inputValue, output).toDate();
    }, [inputValue]);

    useEffect(() => {
        // hook form - default value and reset
        let value = inputRef.current?.value;
        
        if (!value) return;

        if (range) {
        }

        if (multiple) {
        }

        if (!range && !multiple) {
            const test = moment(value, output, undefined, true);

            if (!test.isValid() || value === inputValue) return;

            setDates(test.toDate());
        }
    });

    const getClassName = () => {
        if (multiple) return "daypicker-multiple";

        if (range || picker !== "day") return "daypicker-range";
    };

    return (
        <div ref={popupRef} style={{ display: "inline-block", verticalAlign: "top" }}>
            <div style={{ position: "relative" }}>
                <Input
                    {...opts}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    ref={displayRef}
                    autoComplete={"off"}
                >
                    <Icon.Calendar size="24px" style={{ position: "absolute", right: 0 }} />
                </Input>
                {open && (
                    <Container>
                        {/** @ts-ignore */}
                        <DayPicker
                            showOutsideDays={true}
                            showWeekNumbers={true}
                            navbarElement={NavBar}
                            onDayClick={handleDayClick}
                            onDayMouseEnter={handleDayMouseEnter}
                            selectedDays={selectedDays}
                            modifiers={modifiers}
                            month={dates?.[0] || new Date()}
                            {...dayPickerPropsRef.current}
                            className={`${dayPickerPropsRef.current?.className} ${getClassName()}`}
                            disabledDays={opts.disableDates}
                        />
                    </Container>
                )}
            </div>
            <input type="hidden" name={opts.name} ref={mergeRefs([inputRef, ref])} value={inputValue} />
        </div>
    );
});

export default DatePicker;
