import React, {useEffect, useState} from "react";
import styled, {keyframes} from 'styled-components';
import {Theme, Alert} from '@dspworkplace/ui';

const ToastContainerStyle = styled.div`
    position: fixed;
    left: 30%;
    right: 30%;
    top: -60px;
    height: 60px;
    padding-top: 60px;
    overflow: visible;
    z-index: 15000;
    box-shadow: 0 20px 60px 20px #fff;
    border-radius: 50%;
    opacity: 1;
    transition: opacity .2s;
    
    ${props => !props.visible ? `
    opacity: 0;
    ` : ''}
`;

const ToastAnimation = keyframes`
    0% {
        margin-top: 0;
        opacity: 0;
    }
    
    20% {
        margin-top: 20px;
        opacity: 1;
    }
`;

const ToastStyle = styled.div`
    margin: 20px auto 0;
    width: ${props => props.width || '400'}px;
    box-shadow: 0 3px 6px ${Theme.colors.neutrals.gray}19;
    
    animation: ${ToastAnimation} 1s linear;
`;

export const ToastContainer = () => {
    const [list, setList] = useState([]);
    const [listRemoval, setListRemoval] = useState([]);
    const addToast = evt => {
        let {type, timeout, title} = evt.data;

        timeout = timeout || (timeout !== false)?5000:false;

        if(listRemoval.length > 0){
            let n = listRemoval.length;
            for (let i = 0; i < n; i++) {
                list.shift();
            }
            for (let i = 0; i < n; i++) {
                listRemoval.shift();
            }
        }

        if(list && list.length > 0 && list[list.length - 1].title === title){

            return;
        }else{
            list.push({
                ...evt.data,
                type: type[0].toUpperCase() + type.slice(1),
            });
            setList([...list]);
        }


        if(timeout !== false){
            window.setTimeout(()=> {
                list.shift();
                setList([...list]);
            }, timeout );
        }
    };

    const handleClick = (toast, k) => {
        if (!toast.useClose)
            return;

        listRemoval.push(k);
        setListRemoval(listRemoval);
        list.splice(k, 1);
        setList([...Array.from(list)]);
    }

    useEffect( () => {
        window.addEventListener( 'onToast', addToast );

        return () => window.removeEventListener( 'onToast', addToast );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ToastContainerStyle visible={list.length > 0}>
            {list.reverse().map( (toast, k) =>
                <ToastStyle key={k} onClick={()=> handleClick(toast, k)}>
                    {React.createElement(
                        Alert[toast.type],
                        {
                            ...toast
                        }
                    )}
                </ToastStyle>
            )}
        </ToastContainerStyle>
    );
};