import React, {useEffect, useRef, useState} from "react";
import moment from "moment-timezone";
import {getRole} from "../Auth";
import {MessageOfChannel} from "./types"
import {LoadingView} from "../components/Loading";
import {Channel} from "twilio-chat/lib/channel";
import MessageHeader from "./MessageHeader";
import MessageContent from "./MessageContent";
import MessageOptions from "./MessageOptions";
import ReadReceiptText from "./ReadReceiptText";
import {MessageContainer, MessageContentContainer, MessageBody} from "./styles";


type Props = {
    message: MessageOfChannel;
    currentUser: string;
    previousMessage?: MessageOfChannel | null;
    channel: Channel;
    lastReadMessageIndexes?: {}
};

const Message = ({ message, currentUser, previousMessage, channel, lastReadMessageIndexes = {} }: Props) => {
    let {text, date, element, user, mediaUrl, media, createdAt, username} = message;
    const [isSentByCurrentUser, setIsSentByCurrentUser] = useState(user.identity === currentUser);
    const [openOptions, setOpenOptions] = useState(false);
    const refMessage = useRef<HTMLDivElement>();
    const [loading, setLoading] = useState(true);
    const [showDate, setShowDate] = useState(true);
    const [allowOptions, setAllowOptions] = useState(false);
    const [hasImageError, setHasImageError] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        setIsSentByCurrentUser(user.identity === currentUser);
        let allowedRoles = [
            "ROLE_OWNER",
            "ROLE_STATION_MANAGER",
            "ROLE_OPERATIONS_ACCOUNT_MANAGER",
            "ROLE_OPERATIONS_MANAGER"
        ];
        let currentRole = getRole();

        if (allowedRoles.find(item => item === currentRole)) {
            setAllowOptions(true);
        } else {
            setAllowOptions(false);
        }

        if (previousMessage && previousMessage.createdAt) {
            let prevCreatedAt = moment(previousMessage.createdAt);
            let currentCreatedAt = moment(createdAt);
            if (previousMessage.username === username && currentCreatedAt.diff(prevCreatedAt, "seconds") < 20) {
                setShowDate(false);
            } else {
                setShowDate(true);
            }
        }
        setLoading(false);
        return () => {
            abortController.abort();
        };
    }, [createdAt, currentUser, element, previousMessage, text, user, username]);

    if (loading) {
        return <LoadingView containerHeight="32px" imgHeight="20px" />;
    }

    if (user.identity === "system") {
        return (
            <MessageContainer rows={showDate ? "16px auto" : "auto"} justifyContent={"flex-start"}>
                <MessageHeader
                    showDate={showDate}
                    user={user}
                    date={date}
                    refMessageBox={refMessage}
                    isSentByCurrentUser={isSentByCurrentUser}
                    isSystemMessage={true}
                />
                <MessageBody justifyContent={"flex-start"}>
                    <MessageContentContainer
                        ref={refMessage}
                        id={element.sid}
                        width={"300px"}
                        className={isSentByCurrentUser ? "right" : "left"}
                    >
                        <MessageContent
                            isSentByCurrentUser={isSentByCurrentUser}
                            index={element.index}
                            text={text}
                            media={media}
                            mediaUrl={mediaUrl}
                            identity={user.identity}
                        />
                    </MessageContentContainer>
                </MessageBody>
                {isSentByCurrentUser &&
                    <ReadReceiptText index={element.index} lastReadMessageIndexes={lastReadMessageIndexes} />
                }
            </MessageContainer>
        );
    }

    if (hasImageError) {
        return null;
    }

    return (
        <MessageContainer
            rows={showDate ? "16px auto" : "auto"}
            justifyContent={isSentByCurrentUser ? "flex-end" : "flex-start"}
        >
            <MessageHeader
                showDate={showDate}
                user={user}
                date={date}
                refMessageBox={refMessage}
                isSentByCurrentUser={isSentByCurrentUser}
            />
            <MessageBody justifyContent={isSentByCurrentUser ? "flex-end" : "flex-start"}>
                <MessageContentContainer
                    ref={refMessage}
                    id={element.sid}
                    width={allowOptions ? "260px" : "280px"}
                    className={isSentByCurrentUser ? "right" : "left"}
                >
                    <MessageContent
                        isSentByCurrentUser={isSentByCurrentUser}
                        index={element.index}
                        text={text}
                        media={media}
                        mediaUrl={mediaUrl}
                        identity={user.identity}
                        setHasImageError={setHasImageError}
                    />
                </MessageContentContainer>
                <MessageOptions
                    refMessageElem={refMessage}
                    message={element}
                    allowOptions={allowOptions}
                    setOpenOptions={setOpenOptions}
                    channel={channel}
                    openOptions={openOptions}
                />
            </MessageBody>
            {isSentByCurrentUser &&
                <ReadReceiptText index={element.index} lastReadMessageIndexes={lastReadMessageIndexes} />
            }
        </MessageContainer>
    );
};

export default React.memo(Message);
