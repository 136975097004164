import React from "react";
import { Title, Spacer,Button } from "@dspworkplace/ui";

const DashboardEmptyContent = ({ onAddPanel }) => {
    return (
        <div style={{display: 'flex',justifyContent:'center',alignItems: 'center',height:'100%'}}>
            <div>
                <Title>No panel created yet...</Title>
                <Spacer top={4} />
                <Button
                    type={'primary'}
                    style={{margin:'0 auto',display:'flex'}}
                    onClick={onAddPanel}
                >
                    Add First Panel
                </Button>
            </div>
        </div>
    );
}

export default DashboardEmptyContent;