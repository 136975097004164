import React, {createContext, useReducer} from 'react';
import WorkFlowFormsReducer from './reducer';

const Store = createContext({});

const initialState = {
    forms: [],
    actions: [],
    isLoading:true,
    company:'',
    category:'',
    sub_category:'',
    modalVisible:true
}

const WorkFlowFormProvider = ({children}) => {
    const useWorkFlowFormState = useReducer(WorkFlowFormsReducer,initialState)
    return (
        <Store.Provider value={useWorkFlowFormState}>
            {children}
        </Store.Provider>
    )
}
export {WorkFlowFormProvider}

export default Store
