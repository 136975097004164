import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, CheckBox, Icon, Input, Spacer, TagInput, Text, Toggle} from '@dspworkplace/ui';
import {useForm} from "react-hook-form";
import {DriversDispatchContext, DriversStateContext} from "../../Drivers";
import Loading from "../../../../../../../components/Loading";
import {useAuth} from "../../../../../../../Auth";
import moment from "moment";
import {CheckBoxCustom} from "../../index";
import Modal from '../../../../../../../components/Modal';
import {Table, TableData, TableHeader} from "../../../../../../../components/Table";
import {Link, Tag} from "../../../../../../../components/UI";
import Driver from '../../../../../../../components/People';
import DatePicker from '../../../../../../../components/Calendar/picker';

const HiddenSubmit = styled.button`
opacity:0;
width:1px;
height:1px;
position:relative;
top:-20px;
`;

const AddDriver = (preState) => {
    const { register, handleSubmit, reset, triggerValidation} = useForm({mode:'onBlur', defaultValues: {}});
    const {state} = React.useContext(DriversStateContext);
    const {dispatch} = React.useContext(DriversDispatchContext);
    const { api } = useAuth();
    const [data,setData] = useState({sortFieldName:"name",sortFieldType:"ASC",update:-1,drivers:[],skills:[],otherSchedule:false,tempSchedule:false});
    const [allDrivers,setAllDrivers] = useState({});
    const [selectedDriver,setSelectedDriver] = useState([]);
    const [driverArr,setDriverArr] = useState([]);
    const [driverEffectiveDates, setDriverEffectiveDates] = useState([]);

    const OrderIco = ({direction='ASC'}) => {
        if(direction === 'DESC') return (<Icon.ArrowUp size='16px' color='#7C98B6' style={{position:'relative',top:'0px',left:'4px'}}/>);
        return(<Icon.ArrowDown size='16px' color='#7C98B6' style={{position:'relative',top:'0px',left:'4px'}}/>)
    };

    const handleOrder = (fieldName,direction) => {
        let orderedData = '';
        if(direction == 'ASC'){
            orderedData = sorter(data.drivers,fieldName,'DESC');

            setData({...data,sortFieldName:fieldName,sortFieldType:'DESC',drivers:orderedData});
        }else if (direction == 'DESC'){
            orderedData = sorter(data.drivers,fieldName,'ASC');
            setData({...data,sortFieldName:fieldName,sortFieldType:'ASC',drivers:orderedData});
        }
    }

    const sorter = (array,field,direction="ASC") => {
        //0 DESC
        //1 ASC
        const sorted = array.sort((a, b) => {
            if(direction === "DESC") return (a[field] > b[field]) - (a[field] < b[field]);
            return (b[field] > a[field]) - (b[field] < a[field]);
        });
        return sorted;
    };

    const handleSearchChange = async (data) =>{
        await setTimeout(()=>{
            filterRef.current.click();
        },600)
    }
    const filterRef = useRef();

    const onSearch = async (searchData) => {
        var name= searchData.name.toLowerCase();
        var skills = searchData.skills;
        var otherSchedule = searchData.otherSchedule;
        var results = [];
        let searchDrivers;

        if( name ==='' && skills.length <= 0 && otherSchedule == false){
            setData({...data,drivers:allDrivers,otherSchedule:otherSchedule});
        } else {
            setData({...data,drivers:results});
            allDrivers.forEach(function(drivers){
                let searchPass = false;
                let skillPass = false;

                if( name !==''){
                    for(const property in drivers){
                        if(drivers[property]){
                            if(property !== 'id' && drivers[property].toString().toLowerCase().search(name.toLowerCase()) > -1) {
                                searchPass = true;
                                break;
                            }
                        }
                    }
                } else {
                    searchPass = true;
                }

                if(skills.length > 0){
                    if(drivers.skills){
                        drivers.skills.forEach(function(skill){
                            if(skills.includes(skill.skill.name)){
                                skillPass = true;
                            }
                        });
                    }
                } else {
                    skillPass = true;
                }

                if(searchPass && skillPass) {
                    results.push(drivers);
                }
            });
            setData({...data,drivers:results,otherSchedule:otherSchedule});
        }
    }

    const onAddDrivers = data => {
        let results = allDrivers.filter(d => data.driverIndexes.includes(d.id.toString()))
        dispatch({type: 'addSelectedDrivers', selectedDrivers: results, loading: false});
        let addSelectedDriversEffectiveDate = driverEffectiveDates.filter(item => data.driverIndexes.includes(item.driverId.toString()));
        dispatch({type: 'addSelectedDriversEffectiveDate', driversEffectiveDate: addSelectedDriversEffectiveDate, loading: false});
        dispatch({type:'setOpen', open:false})
        reset({name:'', skills:''});
    }

    const onSubmit = (data) => {

        var results = [];
        var tempArray=[]

        if( selectedDriver.length > 0){
            allDrivers.map((item,index)=>{
                if (selectedDriver.indexOf(item.id.toString()) !== -1) {
                    results.push(item);
                }
            });
            dispatch({type: 'addSelectedDrivers', selectedDrivers: results, loading: false});
            dispatch({type:'setOpen', open:false})
            reset({name:'', skills:''});
        }
    }

    const checkboxCheckEvent = async(id)=>{
        let list = selectedDriver;
        let checkboxObj = document.querySelector("[data-name='driverIndexes_"+id+"']");
        if(checkboxObj.checked){
            if (list.indexOf(id.toString()) === -1) {
                list.push(checkboxObj.value);
            }
        } else {
            list.forEach(function(val,i){
                if( val === id.toString()){
                    list.splice(i, 1);
                }
            });
        }
        setSelectedDriver(list);
    }
    function getDriverId(id){
        let did = 0;
        selectedDriver.forEach(function(val,i){
            if( val === id.toString()){
                did=id;
            }
        });
        return  did;
    }
    const isCurrentSchedule = (schedule) => {
        if( schedule.isArchive === false ){

            let today = moment().format('YYYY-MM-DD');
            let isRecurring = schedule.isRecurring;
            let startDate = moment.unix(schedule.startDate.timestamp).utc().format('YYYY-MM-DD');
            if(isRecurring === true ){
                if(today >= startDate) {
                    return true;
                }
            } else {
                let endtDate = moment.unix(schedule.endDate.timestamp).utc().format('YYYY-MM-DD');
                if( today >= startDate  && endtDate >= today){
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }

    }

    useEffect (() => {

        const getDrivers = async () => {
            try{
                const params={
                    "actions": {
                        "response": {
                            "Driver": {
                                "custom": {
                                    "functionName": "getDrivers",
                                    "criteria": {
                                        "station": preState.preState.preState.station.id,
                                        "startDate":preState.preState.preState.information.startDate,
                                        "endDate":preState.preState.preState.information.recurring ? moment().format('YYYY-MM-DD') : preState.preState.preState.information.endDate,
                                        "isRecurring":preState.preState.preState.information.recurring,
                                        "weeks":preState.preState.preState.information.recurringWeeks
                                    },
                                    "get": "drivers"                                   
                                }
                            },
                        }
                    }
                };

                let response = await api.post('/api/lazy/manage/data', params);

                let tempArr=[]
                if(state.drivers.length > 0){
                    Object.keys(state.drivers).forEach(function(index) {
                        let tempId = state.drivers[index].id;
                        tempArr.push(tempId.toString());
                    });
                }
                setDriverArr(tempArr);
                let searchSkill = [];
                if (response.data.data.drivers.skill.length > 0) {
                    (response.data.data.drivers.skill).forEach(function (item) {
                            searchSkill.push({value: item, name: item});
                    });

                }

                setData({...data,drivers:response.data.data.drivers.driver,skills:searchSkill});
                setAllDrivers(response.data.data.drivers.driver);

            }catch(error){
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
        }
        getDrivers();

    },[]);

    const effectiveDateChangeHandler = (val,driverId) => {
        setDriverEffectiveDates(old => {
            const oldFilter = old.filter((item) => item.driverId != driverId);
            if(val !== ""){
                return [...oldFilter, {"driverId":driverId, "date": val}];
            }else{
                return [...oldFilter]
            }
        })
    }

    const disableBeforeDate = new Date(Math.max(new Date(), new Date(preState.preState.preState.information.startDate)));
	const disableAfterDate = preState.preState.preState.information.recurring  === false && new Date(preState.preState.preState.information.endDate);
    
    //TODO: Show drivers from other schedules
    return (
        <Modal
            title={'Add Drivers'}
            visible={true}
            setVisible={()=>dispatch({type:'setOpen', open:false})}
            width={'90%'}
        >
            <div>
                <Spacer bottom={3} as='form' onSubmit={handleSubmit(onSearch)}>
                    <Spacer inline right={5} style={{verticalAlign: 'top'}}>
                        <Input
                            name='name'
                            label='Search'
                            ref={register}
                        >
                            <Icon.Search onClick={handleSearchChange}/>
                        </Input>
                    </Spacer>
                    <Spacer inline right={5} style={{verticalAlign: 'top'}}>
                        <TagInput
                            name='skills'
                            label='Skills'
                            ref={register}
                            onChange={handleSearchChange}
                            options={data.skills}
                        />
                    </Spacer>
                    <Spacer inline right={5} style={{verticalAlign: 'top'}}>
                        <Spacer top={8}/>
                        <Toggle
                            name='otherSchedule'
                            ref={register}
                            singleText='Show drivers from other schedules'
                            onChange={handleSearchChange}
                        />
                    </Spacer>
                    <HiddenSubmit ref={filterRef} type="submit" />
                </Spacer>
                <form onSubmit={handleSubmit(onAddDrivers)}>
                    <Table>
                        <TableHeader
                            headers={[
                                {
                                    width: '72px',
                                    label: (
                                        <CheckBox
                                            name='allDrivers'
                                            title={''}
                                            options={[{value: true, label: ''}]}
                                            ref={register({required: false})}
                                            onClick={() => {
                                                dispatch({type: 'selectAll'})
                                            }}
                                        />
                                    )
                                },
                                {width: '236px', label: 'Driver'},
                                {width: '250px', label: 'Skills'},
                                {width: '280px', label: 'Current Schedule'},
                                {width: '310px', label: 'Effective Date'},
                            ]}
                        />
                        <li style={{overflow: 'hidden'}}>
                            <Table width={'100%'} style={{height: '45vh', overflow: 'auto', display: 'block'}}>
                                <li></li>
                                {state.loading && <li><Loading/></li>}
                                {!state.loading && data.drivers.length > 0 && data.drivers
                                    .filter(d => {
                                        if( driverArr.includes( d.id.toString() ) ){
                                            return false;
                                        }

                                        if (data.otherSchedule)
                                            return true;

                                        if (!data.otherSchedule && (!d.schedule || !d.schedule.length))
                                            return true;

                                        return false;
                                    })
                                    .map((item,index)=>
                                        <li key={index}>
                                            <TableData width='38px'>
                                                <CheckBoxCustom
                                                    data-driver='driver'
                                                    ref={register}
                                                    data-name={'driverIndexes_' + item.id}
                                                    name={'driverIndexes'}
                                                    options={item.op}
                                                    defaultValues={[getDriverId(item.id.toString()) > 0 ? getDriverId(item.id.toString()) : '0']}
                                                     onClick={(e) => {
                                                         checkboxCheckEvent(item.id)
                                                     }}
                                                />
                                            </TableData>
                                            <TableData width='270px'>
                                                <Driver
                                                    name={item.name}
                                                    jobTitle={item.role}
                                                    img={item.image}
                                                />
                                            </TableData>
                                            <TableData width='250px'>
                                                <div>
                                                    {item.skills && item.skills.length > 0
                                                        ? item.skills.map((s, k) =>
                                                            <Spacer inline right={2} key={k} style={{paddingBottom:'2px'}}>
                                                                <Tag>{s.skill.name}</Tag>
                                                            </Spacer>
                                                        )
                                                        : <Text>–</Text>
                                                    }
                                                </div>
                                            </TableData>
                                            <TableData width='280px'>
                                                <Link>
                                                    {item.schedule && item.schedule.length > 0 ?
                                                        item.schedule.map((value, index) => {
                                                            if (index === item.schedule.length - 1) {
                                                                return value.name;
                                                            }
                                                            return value.name+', ';
                                                        })
                                                        : ''
                                                    }
                                                </Link>
                                            </TableData>
                                            <TableData width='310px'>
                                                {/* <div> */}
                                                    <DatePicker
                                                        name={`driverEffectiveDate_${item.id}`}
                                                        onChange={(val) => effectiveDateChangeHandler(val,item.id)}
                                                        disableDates={{before: disableBeforeDate , ...(disableAfterDate && {after :disableAfterDate})}}
                                                        val
                                                        size="medium"
                                                    />
                                                {/* </div> */}
                                            </TableData>
                                        </li>
                                    )
                                }
                            </Table>
                        </li>
                    </Table>
                    <Spacer top={5}>
                        <Button type={'primary'}>Add Selected</Button>
                    </Spacer>
                </form>
            </div>
        </Modal>
    );
}

export default React.memo(AddDriver);

// document.querySelectorAll("[data-driver='driver']").forEach(item=>{item.click()});
