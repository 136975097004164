import React, { useState, Fragment, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Theme, Button, SubTitle } from "@dspworkplace/ui";
import { CSVLink } from "react-csv";

const OverlayStyle = styled.div`
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: ${props => (props.zindex ? props.zindex : 10000)};
`;

const ModalStyle = styled.div`
    background:${Theme.colors.neutrals.white};
    padding:20px;
    border-radius:${Theme.defaultRadius};
    box-shadow:0 3px 6px #33333326;
    width:${props => (props.width ? props.width : "500px")};
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 90vh;
   
    
    ${props =>
        props.drawer &&
        `        
        top:0;
        max-height:100%;
        height:100%;              
    `}   
    
     ${props =>
         props.drawer === "left" &&
         `        
        left:0;
    `}  
    
    ${props =>
        props.drawer === "right" &&
        `        
        right:0;
    `}
    ${props =>
        props.paddingTopBottomZero && props.paddingTopBottomZero === true &&
        `        
        padding-top:0px;
        padding-bottom:0px;
    `}
    
    >header {
        display:flex;
        justify-content:space-between;
        align-items:center;     
        margin-bottom:20px;
        
        >button {
            font-weight:normal;
        }   
    }
    
  
`;

const ScrollyOverlayStyle = styled(OverlayStyle)`
    overflow: auto;
    padding: 20px;
    height: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
`;

const ScrollyModalStyle = styled(ModalStyle)`
    position: relative;
    max-height: none;
    margin: auto;
    overflow: visible;
`;

const appRoot = document.getElementById("root");

const App = ({ title, width, visible, setVisible, children, drawer, removeHeader, zindex, paddingTopBottomZero, closeOnOverlay = true, exportBtn = false, headers = [], data = [], filename = "default.csv" , cancelLabel="Cancel"}) => {
    let ref = useRef();

    let ModalStyleType = ScrollyModalStyle;
    if (drawer) {
        ModalStyleType = ModalStyle;
    }

    const handleKey = e => {
        if (e.keyCode === 27 && ref.current) {
            setVisible(false);
        }
    };

    useEffect(() => {
        // document.addEventListener('keydown', handleKey,false);
        //
        // return () => {
        //     document.removeEventListener('keydown', handleKey,false);
        // }
    }, []);

    const randomId = Math.random().toString(36).substr(2, 9);

    const Component = !visible ? null : (
        <Fragment>
            <ScrollyOverlayStyle id={randomId} zindex={zindex}  onMouseDown={e => closeOnOverlay && e.target.id === randomId && setVisible(false)}>
                <ModalStyleType width={width} drawer={drawer} paddingTopBottomZero={paddingTopBottomZero} onClick={e => e.stopPropagation()}>
                    {!removeHeader && (
                        <header>
                            <SubTitle>{title}</SubTitle>
                            <div>
                                {
                                    exportBtn ?
                                    <CSVLink data={data} headers={headers} filename={filename}>
                                        <Button type="cancel"
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Export
                                        </Button>
                                    </CSVLink> : ""
                                }
                                <Button type="cancel" onClick={setVisible}>
                                    {cancelLabel}
                                </Button>
                            </div>
                        </header>
                    )}
                    <div>{children}</div>
                </ModalStyleType>
            </ScrollyOverlayStyle>
        </Fragment>
    );

    return ReactDOM.createPortal(Component, appRoot);
};

const useModal = (propVisible = false) => {
    const [visible, setVisible] = useState(propVisible);

    // function toggleModal() {
    //     // let v = b !== undefined ? b : !visible;
    //     setVisible(!visible);
    // }

    const toggleModal = useCallback(() => {
        setVisible(!visible);
    }, [visible]);

    return [visible, toggleModal];
};

export { useModal, OverlayStyle, ModalStyle, ScrollyOverlayStyle, ScrollyModalStyle };

export default App;
