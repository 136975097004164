import React, {useState} from 'react';
import {Icon, Theme} from "@dspworkplace/ui";
import { Popover, Transition } from '@headlessui/react'
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';
import Tooltip from '../Tooltip/tooltip';
import {Placement} from '@popperjs/core'
// @ts-ignore
import style from './actionIcon.module.css';

interface ActionIconProps {
    direction?: Placement
    options: {title:string,icon:React.FC,iconSize:number,onClick: React.MouseEventHandler<HTMLButtonElement>}[]
}

const ActionIcon = ({direction = 'left', options=[]}: ActionIconProps) => {
    const [isShowing, setIsShowing] = useState(false)
    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()
    const {attributes, styles} = usePopper(referenceElement,popperElement, {
        placement: direction
    })
    return (
        <Popover
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
        >
            <div className={style.reference}>
                {/*@ts-ignore*/}
                <Popover.Button ref={setReferenceElement} as='div'>
                    <Icon.DspwNetwork size={'22px'} color={Theme.colors.primary} />
                </Popover.Button>
            </div>
            <PanelPortal>
                <Transition show={isShowing}>
                    {/*@ts-ignore*/}
                    <Popover.Panel as='div' ref={setPopperElement} style={styles.popper} {...attributes.popper}  static={true} focus={true}>
                        <div className={style.content}>
                            {options.map((opt)=>{
                                const {title, icon:Icon, iconSize, onClick} = opt;
                                return (
                                    <Tooltip key={title} content={title} direction={'bottom'}>
                                        <button className={style.button} onClick={onClick}>
                                            {/* @ts-ignore */}
                                            <Icon size={`${iconSize ? iconSize : 16}px`} />
                                        </button>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    </Popover.Panel>
                </Transition>
            </PanelPortal>
        </Popover>
    )
}

const PanelPortal = ({children}) => {
    // @ts-ignore
    return createPortal(children,document.getElementById('modal-root'))
}

export default ActionIcon;