import React, {useState, useRef, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useForm} from "react-hook-form";

import {Theme,Spacer, TagInput, CustomDropdown,Dropdown, Toggle} from "@dspworkplace/ui";
import WeekStore from "./context/weekStore";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import {useLocalStorage} from "../../../Auth";
import ComboBox from '../../../components/ComboBox';

export const Wrapper = styled.form`
    width:180px;
    display:block;

    max-height: calc(100vh - 150px);
    //overflow: auto;

    >.CustomBorder {
        border-top:1px solid ${Theme.colors.info.bg};
        width:140px;
        display:block;
    }

    .CustomToggle {
        label {
            &:before {
                white-space: normal;
                top:-6px;
                width:95px;
                line-height:16px;
            }
        }
    }

    .FixMaxHeightDropDown {
        .small {
            max-height:125px;
            overflow-y:auto;
        }
    }
`;
export const SkillsDropDown = styled.div`
    ul {
        top: 100%;
        max-height: 300px;
    }
`;
export const HiddenSubmit = styled.button`
    opacity:0;
    width:1px;
    height:1px;
    position:relative;
    top:-20px;
`;

const Panel = ( {children}) => {
    const filterRef = useRef();
    const { register, handleSubmit  } = useForm({mode:'onBlur', defaultValues:{}});
    const [state,dispatch] = useContext(WeekStore);
    let {filterOptions} = state;
    const [lookups, setLookups] = useState({stations:[],schedules:[],shiftTypes:[],drivers:[]});
    const [selectedStation,setSelectedStation] = useSelectedStation();
    const [stations] = useLocalStorage('stations');
    if(!selectedStation || selectedStation == -1){
        if(stations && stations[0]){
            setSelectedStation(stations[0].id);
        }
    }

    const onSubmit = data => {
        dispatch({type:'FILTER',payload:data})
        if(state.refTable.current){
            state.refTable.current.recomputeRowHeights();
        }
    }

    const handleDropdownChange = async (v) =>{
        await setTimeout(()=>{
            if(filterRef && filterRef.current && filterRef.current.click){
                filterRef.current.click()
            }
        },600)
    }

    useEffect(()=>{
       setLookups(filterOptions);
    },[filterOptions])

    useEffect(() => {
        if (state.state.length)
            handleDropdownChange();
    }, [selectedStation]);


    return (
        <Wrapper onSubmit={handleSubmit(onSubmit)}>
            <span className='CustomBorder' />
            <Spacer top={4}>
                <StationSelector
                    showAllOption={false}
                    name='stations'
                    ref={register}
                    label='Stations'
                    size='small'
                    onChange={()=>{dispatch({type:'LOADING',payload:{status:true}})}}
                    visibleOptionsQty={4}
                />
            </Spacer>

            <Spacer top={3} className='FixMaxHeightDropDown'>
                <TagInput
                    name='schedules'
                    ref={register}
                    label='Schedules'
                    options={lookups.schedules}
                    defaultValues={[]}
                    size='small'
                    onChange={handleDropdownChange}
                    visibleOptionsQty={6}
                />
            </Spacer>

            <Spacer top={3}>
                <ComboBox
                    name='shift_type'
                    ref={register}
                    label='Shift Type'
                    multiple={true}
                    input={false}
                    options={lookups.shiftTypes}
                    size='small'
                    type='shiftIcon'
                    onChange={handleDropdownChange}
                    visibleOptionsQty={6}
                />
            </Spacer>

            <Spacer top={3} className='FixMaxHeightDropDown'>
                <TagInput
                    name='driver'
                    ref={register}
                    label='Drivers'
                    options={lookups.drivers}
                    size='small'
                    defaultValues={[]}
                    onChange={handleDropdownChange}
                    openTo={'top'}
                    visibleOptionsQty={6}
                />
            </Spacer>

            <Spacer top={3}>
                {/* <CustomDropdown
                    name='overtime'
                    ref={register}
                    label='Overtime'
                    options={[
                        {value:'in_overtime',name:'In Overtime'},
                        {value:'scheduled',name:'Scheduled for Overtime'},
                        {value:'not_scheduled_overtime',name:'Not Scheduled for Overtime'}
                    ]}
                    size='small'
                    multiple={true}
                    openTo={'top'}
                    onChange={handleDropdownChange}
                /> */}
                <SkillsDropDown>
                    <CustomDropdown
                        name="skills"
                        ref={register}
                        label="Skills"
                        options={lookups.skills}
                        size="small"
                        multiple={true}
                        openTo={"bottom"}
                        onChange={handleDropdownChange}
                    />
                </SkillsDropDown>
            </Spacer>

            <Spacer top={5} className='CustomToggle'>
                <Toggle name='unscheduled' ref={register} singleText='Unscheduled Drivers' onChange={handleDropdownChange} />
            </Spacer>

            <HiddenSubmit ref={filterRef} />

            {children}
        </Wrapper>
    )
}

export default Panel;