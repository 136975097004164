import React, { useEffect, useState } from "react";
import { Text } from "./styles";
import { getMemberCountFromChannel, getOtherUserDescriptorDuoChannel } from "./utils";


const ChannelSubtitle = ({ channel, type, width, userTitle }) => {
    const [state, setState] = useState({ loading: type !== 'bot', memberCount: channel.data.member_count, onlineMemberCount: channel.state.watcher_count, title: "" });
    const { loading, memberCount, onlineMemberCount, title } = state;

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     if (channel) {
    //         channel.on("memberLeft", () => {
    //             setState(currState => ({...currState, memberCount: currState.memberCount - 1}));
    //         });
    //         channel.on("memberJoined", () => {
    //             setState(currState => ({...currState, memberCount: currState.memberCount + 1}));
    //         });
    //         if (type === "Duo") {
    //             getOtherUserDescriptorDuoChannel(channel).then(user => {
    //                 if (user && user.attributes) {
    //                     if (user.attributes["title"]) {
    //                         setState(currState => ({...currState, title: user.attributes["title"], loading: false}));
    //                     } else if (user.attributes["role"]) {
    //                         // setTitle(user.attributes["role"]);
    //                         setState(currState => ({...currState, title: user.attributes["role"], loading: false}));
    //                     }
    //                     // setLoading(false);
    //                 }
    //             });
    //         } else if (type === "Group") {
    //             getMemberCountFromChannel(channel).then(number => {
    //                 setState(currState => ({...currState, memberCount: number, loading: false}));
    //                 // setMemberCount(number);
    //                 // setLoading(false);
    //             })
    //         }
    //     }
    //     return () => {
    //         if (channel) {
    //             channel.removeAllListeners();
    //         }
    //         abortController.abort();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // if (loading) {
    //     return <Loading imgHeight={"12px"} containerHeight={"14px"}/>;
    // }

    if (type === "bot") {
        return (
            <Text color="#707070" fontSize="12px" lineHeight="12px" height="14px">
                Your Work Assistant
            </Text>
        );
    }

    if (type === "duo") {
        return (
            <div style={{ display: "flex", columnGap: "2px" }}>
                <Text color="#707070" fontSize="12px" lineHeight="normal" height="unset">{userTitle}</Text>
            </div>
        );
    }

    return (
        <Text
            color="#707070"
            fontSize="12px"
            lineHeight="12px"
            height="14px"
            width={width ? width : '300px'}
        // >{`${type} - ${memberCount} members`} {` (online ${onlineMemberCount}) `}</Text>
        >{`${memberCount} members`}, {`${onlineMemberCount} online `}</Text>

    );
}

function propsAreEqual(prev, next) {
    return prev.channel.sid === next.channel.sid;
}

const MemoizedChannelSubtitle = React.memo(ChannelSubtitle, propsAreEqual);
// export default ChannelSubtitle;
export default MemoizedChannelSubtitle;
