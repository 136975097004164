import React from 'react';
import { QueryBuilder } from '@cubejs-client/react';
import cubeApi from '../../../api/cube';

const withCubeData = (Component, queryBuilderProps = {}) => ({ builderConfig = {}, ...rest }) => (
    <QueryBuilder
        {...queryBuilderProps}
        {...builderConfig}
        cubejsApi={cubeApi}
        disableHeuristics={true}
        render={(data) => (
            <Component
                {...data}
                filterDashboardItemData={data?.resultSet}
                availableMembers={data.availableDimensions.concat(data.availableMeasures)}
                {...rest}
            />
        )}
    />
);

export default withCubeData;