import React, {useState, useRef,useEffect} from 'react';
import styled from 'styled-components';
import {async, Button, Icon, Input, Spacer, TagInput} from '@dspworkplace/ui';
import {useForm} from "react-hook-form";
import DriverCell from "./DriverCell";
import SkillCell from "./SkillCell";
import {CustomButton} from "../../Add";
import AddDriver from "./AddDriver";
import {SchedulesAddStateContext} from "../index";
import {SchedulesAddDispatchContext} from "../index";
import {confirm} from "../../../../../../components/Confirm";
import moment from "moment";

const Grid = styled.div`
box-sizing: border-box;
display: grid;
grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : 'auto'};
grid-column-gap: ${props=>props.columnGap ? props.columnGap : '0'};
grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
grid-row-gap: ${props=>props.rowGap ? props.rowGap : '0'};
justify-content: ${props=>props.justifyContent ? props.justifyContent : 'flex-start'};
align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
padding: ${props=>props.padding ? props.padding : '0'};
margin: ${props=>props.margin ? props.margin : '0'};
border: ${props=>props.border? props.border : '0'};
border-bottom: ${props=>props.borderBottom ? props.borderBottom : '0'};
border-top: ${props=>props.borderTop ? props.borderTop : '0'};
font-size: ${props=>props.fontSize ? props.fontSize : '14px'};
font-weight: ${props=>props.fontWeight ? props.fontWeight : 'normal'};
color: ${props=>props.color ? props.color : '#333333'};
background-color: ${props=>props.backgroundColor ? props.backgroundColor : 'unset'};
text-decoration: ${props=>props.textDecoration ? props.textDecoration : 'unset'};
`;

const TagBox = styled.div`
width: ${props=>props.width ? props.width : '400px'};
padding: ${props=>props.padding ? props.padding : '0'};
margin: ${props=>props.margin ? props.margin : '0'};
font-size:14px;
color:#333333;
display: inline-flex;
justify-content: ${props=>props.justifyContent ? props.justifyContent : 'flex-start'};
align-items: center;
vertical-align: middle;

div{
    width: ${props=>props.width ? props.width : '400px'};
}

ul {
    max-width: ${props=>props.width ? props.width : '400px'};

    li {

    }
}

svg {
    cursor:pointer;
}
`;

const TableData = styled.div`
display: inline-block;
margin:5px;
display:inline-block;
`;

const HiddenSubmit = styled.button`
opacity:0;
width:1px;
height:1px;
position:relative;
top:-20px;
`;


const reducer = (state, action) => {
    switch (action.type) {
        case 'setOpen':
            return { ...state, open: action.open, selectAll: false} ;
        case 'setDrivers':
            let tempDriverSkillArr = state.skill;
            action.drivers.forEach(function(driver){
                if(driver.skills && driver.skills.length > 0){
                    driver.skills.forEach(function(item){
                        if(tempDriverSkillArr.filter(skill => skill.value == item.skill.id).length == 0){
                            tempDriverSkillArr.push({value:item.skill.id,name:item.skill.name});
                        }
                    });
                }
            });
            return { ...state, drivers: action.drivers, loading: action.loading, skill:tempDriverSkillArr, sortFieldName: action.sortFieldName ? action.sortFieldName : 'name',sortFieldType:action.sortFieldType?action.sortFieldType:'ASC'};
        case 'addDriver':
            state.drivers.push(action.driver);
            return { ...state};
        case 'addSelectedDrivers':
            let tempSkillArr = state.skill;
            action.selectedDrivers.forEach(function(driver){
                if(driver.skills && driver.skills.length > 0){
                    driver.skills.forEach(function(item){
                        if(tempSkillArr.filter(skill => skill.value == item.skill.id).length == 0){
                            tempSkillArr.push({value:item.skill.id,name:item.skill.name});
                        }
                    });
                }
            });
            return {...state, drivers: [...state.drivers, ...action.selectedDrivers], skill:tempSkillArr}
        case 'setSearchDrivers':
            return { ...state, searchDrivers: action.searchDrivers, loading: action.loading, isSearch: action.isSearch ? action.isSearch : false, sortFieldName: action.sortFieldName ? action.sortFieldName : 'name',sortFieldType:action.sortFieldType?action.sortFieldType:'ASC'};
        case 'setLoading':
            return { ...state, loading: action.loading};
        case 'delete':
            let deletedDriver = null;
            if(action.id){
                var index;
                for (let i = 0; i < state.drivers.length; i++) {
                    if(state.drivers[i].id === action.id){
                        index = i;
                        break;
                    }
                }
                state.searchDrivers.splice(action.key, 1);
                deletedDriver = state.drivers.splice(index, 1);
            }else{
                deletedDriver = state.drivers.splice(action.key, 1);
            }
            
            if(deletedDriver){
                const removedDriverindex = state.driversEffectiveDate.findIndex(effectiveDate => effectiveDate.driverId == deletedDriver[0].id)
                if(removedDriverindex != -1){
                    state.driversEffectiveDate.splice(removedDriverindex,1);
                }
            }
            
            return {...state};
        case 'selectAll':
            if(!state.selectAll === true){
                document.querySelectorAll("[data-driver='driver']").forEach(item=>{item.checked = true});
            }else{
                document.querySelectorAll("[data-driver='driver']").forEach(item=>{item.checked = false});
            }
            return {...state, selectAll: !state.selectAll}
        case 'addSelectedDriversEffectiveDate':
            return { ...state, driversEffectiveDate: [...state.driversEffectiveDate, ...action.driversEffectiveDate]};
        default:
            return state;
    }
}

export const DriversStateContext = React.createContext();
export const DriversDispatchContext = React.createContext();

const DriversPanel = (preState) => {
    const schedulesAddStateContext= React.useContext(SchedulesAddStateContext);
    const schedulesAddDispatchContext = React.useContext(SchedulesAddDispatchContext);
    const initialState = {
        open: false,
        drivers: schedulesAddStateContext.state.drivers,
        searchDrivers: [],
        sortFieldName:"name",
        sortFieldType:"ASC",
        skill:[],
        loading: false,
        isSearch: false,
        selectAll: false,
        'driversEffectiveDate': schedulesAddStateContext.state.driversEffectiveDate
    }
    const { register, handleSubmit, reset } = useForm({mode:'onBlur', defaultValues: {}});
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const handleSearchChange = async (data) =>{
        await setTimeout(()=>{
            filterRef.current.click();
        },600)
    }
    const filterRef = useRef();

    const onSearch = async (data) => {
        var name= data.name.toLowerCase();
        var skills = data.skills;
        var results = [];
        let searchDrivers;
        dispatch({type: 'setSearchDrivers', searchDrivers: [], loading: true, isSearch: false});

        if( name ==='' && skills.length <= 0){
            // setData({...data,drivers:allDrivers,otherSchedule:otherSchedule});
            dispatch({type: 'setSearchDrivers', searchDrivers: results, loading: false, isSearch: false});
        } else {
            state.drivers.forEach(function(drivers){
                let searchPass = false;
                let skillPass = false;
                if( name !==''){
                    for(const property in drivers){
                        if(drivers[property]){
                            if(property !== 'id' && drivers[property].toString().toLowerCase().search(name.toLowerCase()) > -1) {
                                searchPass = true;
                                break;
                            }
                        }
                    }
                } else {
                    searchPass = true;
                }

                if(skills.length > 0){
                    if(drivers.skills){
                        drivers.skills.forEach(function(skill){
                            if(skills.includes(skill.skill.id.toString())){
                                skillPass = true;
                            }
                        });
                    }
                } else {
                    skillPass = true;
                }

                if(searchPass && skillPass) {
                    results.push(drivers);
                }

            });
            if(results.length > 0){
                dispatch({type: 'setSearchDrivers', searchDrivers: results, loading: false, isSearch: true});
            }else{
                dispatch({type: 'setSearchDrivers', searchDrivers: results, loading: false, isSearch: true});
            }
        }
    }

    const OrderIco = ({direction='ASC'}) => {
        if(direction === 'DESC') return (<Icon.ArrowUp size='16px' color='#7C98B6' style={{position:'relative',top:'0px',left:'4px'}}/>);
        return(<Icon.ArrowDown size='16px' color='#7C98B6' style={{position:'relative',top:'0px',left:'4px'}}/>)
    };

    const handleOrder = (fieldName,direction) => {
        let orderedData = '';
        if(direction == 'ASC'){
            if(state.searchDrivers.length > 0){
                orderedData = sorter(state.searchDrivers,fieldName,'DESC');
                dispatch({type: 'setSearchDrivers', searchDrivers: orderedData,sortFieldName:fieldName,sortFieldType:'DESC', loading: false, isSearch: false});
            }else{
                orderedData = sorter(state.drivers,fieldName,'DESC');
                dispatch({type:'setDrivers', drivers: orderedData,sortFieldName:fieldName,sortFieldType:'DESC'});
            }
        }else if (direction == 'DESC'){
            if(state.searchDrivers.length > 0){
                orderedData = sorter(state.searchDrivers,fieldName,'ASC');
                dispatch({type: 'setSearchDrivers', searchDrivers: orderedData,sortFieldName:fieldName,sortFieldType:'ASC', loading: false, isSearch: false});
            }else {
                orderedData = sorter(state.drivers,fieldName,'ASC');
                dispatch({type:'setDrivers', drivers: orderedData,sortFieldName:fieldName,sortFieldType:'ASC'});
            }
        }
    }

    const sorter = (array,field,direction="ASC") => {
        const sorted = array.sort((a, b) => {
            if(direction === "DESC") return (a[field] > b[field]) - (a[field] < b[field]);
            return (b[field] > a[field]) - (b[field] < a[field]);
        });
        return sorted;
    };

    const onSubmit = (data) => {
        //if(state.drivers.length > 0){
        schedulesAddDispatchContext.dispatch({type:'setDrivers', drivers: state.drivers})
        schedulesAddDispatchContext.dispatch({type:'setDriversEffectiveDate', driversEffectiveDate: state.driversEffectiveDate})
        document.querySelectorAll("[data-tab='tab']")[3].click();
        //}
        reset({name:'', skills:''});
    }

    const onDelete = async(id, index, search) =>{
        const confirmation = await confirm('Remove driver from this Schedule?');
        if(!confirmation){
            return false;
        }
        if(search){
            dispatch({type:'delete', key: index, id: id});
        }else{
            dispatch({type:'delete', key: index});
        }
    }

    const isCurrentSchedule = (schedule) => {
        if( schedule.isArchive === false ){

            let today = moment().utc().format('YYYY-MM-DD');
            let isRecurring = schedule.isRecurring;
            let startDate = moment.unix(schedule.startDate.timestamp).utc().format('YYYY-MM-DD');
            if(isRecurring === true ){
                if(today >= startDate) {
                    return true;
                }
            } else {
                let endtDate = moment.unix(schedule.endDate.timestamp).utc().format('YYYY-MM-DD');
                if( today >= startDate  && endtDate >= today){
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }

    }
    const goBack = async ()=>{
        document.querySelectorAll("[data-tab='tab']")[1].click();
    }
    const cancelBtn = async ()=>{
        document.getElementById('cancelBtn').click()
    }
    useEffect(()=>{
        
        handleOrder('name','ASC');

        dispatch({type:'setDrivers', drivers: schedulesAddStateContext.state.drivers})
        let tempArray = [];
        schedulesAddDispatchContext.dispatch( {type:'setWeeks', weekArr: tempArray} ); 
        schedulesAddStateContext.state.design.weekArr.forEach((i,index) => {
            tempArray.push(schedulesAddStateContext.state.weekArr[index]);
        });
        schedulesAddDispatchContext.dispatch( {type:'setWeeks', weekArr: tempArray} ); 
    },[]);

    const getDriverEffectiveDate = (driverId) => {
        const driverWithDate = state.driversEffectiveDate.find((item) => item.driverId == driverId)
        return driverWithDate?.date;
    }
    return (
        <DriversStateContext.Provider value={{state}}>
            <DriversDispatchContext.Provider value={{dispatch}}>
                <Grid padding='20px 0 0 0'>
                    <form onSubmit={handleSubmit(onSearch)} >
                        <Grid templateColumns='auto auto' columnGap='20px' padding='0 0 20px 0'>
                            <Input
                                name='name'
                                label='Search'
                                ref={register}
                            ><Icon.Search onClick={handleSearchChange}/></Input>
                            <TagBox>
                                <TagInput
                                    width='400px'
                                    name='skills'
                                    label='Skills'
                                    ref={register}
                                    onChange={handleSearchChange}
                                    options={state.skill}
                                ></TagInput>
                            </TagBox>
                            <HiddenSubmit ref={filterRef} type="submit" />
                        </Grid>
                    </form>
                    <Grid templateColumns='280px 320px 180px 200px ' borderBottom='1px solid #7C98B6'>
                        <Grid templateColumns='auto auto' padding='12px 0 12px 40px' columnGap='8px' onClick={()=>{handleOrder('name',state.sortFieldType)}}>
                            <Grid>Driver</Grid> {state.sortFieldType && state.sortFieldName === 'name' ? <OrderIco direction={state.sortFieldType} /> : <OrderIco direction='ASC' /> }
                        </Grid>
                        <Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
                            <Grid>Skills</Grid>
                        </Grid>
                        <Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
                            <Grid>Current Schedule</Grid>
                        </Grid>
                        <Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
                            <Grid>Effective Date</Grid>
                        </Grid>
                        {/*<Grid templateColumns='auto auto' padding='12px 0 12px 0' columnGap='8px'>
        <Grid>Grade</Grid>
    </Grid>*/}
                    </Grid>
                    { !state.isSearch && state.drivers && state.drivers.length > 0 && state.drivers.map((item,index)=>
                    {
                        return(
                            <Grid key={(index+1)*7} templateColumns='280px 320px 180px 152px 48px' borderBottom={index === (state.drivers.length -1) ? '0' : '1px dashed #CBD6E2'}>
                                <DriverCell name={item.name} role={item.role} cel={item.cel} image={item.image} padding='8px'></DriverCell>
                                {item.skills ?
                                    <SkillCell skills={item.skills} padding='8px 8px 8px 0'></SkillCell>
                                    :
                                    <Grid templateColumns='repeat(auto-fill, minmax(50px, auto))' rowGap='4px' columnGap='4px'></Grid>
                                }

                                <Grid textDecoration='underline' color='#0071BC'>
                                    {item.schedule && item.schedule.length > 0 ?
                                        item.schedule.map((value, index) => {

                                            if( item.onAddDriver == false ){
                                                if(isCurrentSchedule(value)){
                                                    if (index === item.schedule.length - 1) {
                                                        return value.name;
                                                    }
                                                    return value.name+',';
                                                }
                                            } else {
                                                if (index === item.schedule.length - 1) {
                                                    return value.name;
                                                }
                                                return value.name+',';
                                            }                                            
                                        })
                                        : ''
                                    }
                                </Grid>
                                <Grid templateColumns='auto' columnGap='8px'>{getDriverEffectiveDate(item.id)}</Grid>
                                {/* <Grid color={item.color} fontWeight='bold'>{item.grade}</Grid> */ }
                                <Grid templateColumns='auto' columnGap='8px'>
                                    {/*<Icon.Expand size='20px' color='#7C98B6'></Icon.Expand>*/}
                                    <Icon.Times  size='20px' color='#7C98B6' onClick={()=>onDelete(item.id, index, null)}></Icon.Times>
                                </Grid>
                            </Grid>
                        );
                    })
                    }

                    { state.isSearch && state.searchDrivers && state.searchDrivers.length > 0 && state.searchDrivers.map((item,index)=>
                    {
                        return(
                            <Grid key={(index+1)*7} templateColumns='280px 320px 180px 152px 48px' borderBottom={index === (state.searchDrivers.length -1) ? '0' : '1px dashed #CBD6E2'}>
                                <DriverCell name={item.name} cel={item.cel} padding='8px'></DriverCell>
                                {item.skills ?
                                    <SkillCell skills={item.skills} padding='8px 8px 8px 0'></SkillCell>
                                    :
                                    <Grid templateColumns='repeat(auto-fill, minmax(50px, auto))' rowGap='4px' columnGap='4px'></Grid>
                                }
                                <Grid textDecoration='underline' color='#0071BC'>
                                    {item.schedule && item.schedule.length > 0 ?
                                        item.schedule.map((value, index) => {
                                            if( item.onAddDriver == false ){
                                                if(isCurrentSchedule(value)){
                                                    if (index === item.schedule.length - 1) {
                                                        return value.name;
                                                    }
                                                    return value.name+',';
                                                }
                                            } else {
                                                if (index === item.schedule.length - 1) {
                                                    return value.name;
                                                }
                                                return value.name+',';                                                
                                            }
                                        })
                                        : ''
                                    }
                                </Grid>
                                <Grid color={item.color} fontWeight='bold'>{item.grade}</Grid>
                                <Grid templateColumns='auto' columnGap='8px'>
                                    <Icon.Expand size='20px' color='#7C98B6'></Icon.Expand>
                                    <Icon.Times  size='20px' color='#7C98B6' onClick={()=>onDelete(item.id, index, null)}></Icon.Times>
                                </Grid>
                            </Grid>
                        );
                    })
                    }
                    <Grid padding='12px' justifyContent='center' borderTop='1px solid #CBD6E2' borderBottom='1px solid #7C98B6'>
                        <CustomButton onClick={()=>dispatch({type:'setOpen', open: true})}>Add Drivers</CustomButton>
                    </Grid>
                    { !state.isSearch && state.drivers && state.drivers.length > 0 && <Grid backgroundColor='#FAFAFA' padding='20px 40px 20px 40px'>
                        <Grid color='#516F90' fontSize='16px'>Total: {state.drivers.length} drivers</Grid>
                    </Grid> }
                    <Spacer top={4}>
                        <Button onClick={handleSubmit(onSubmit)}>Review New Schedule</Button>
                        <Spacer inline right={3}/>
                        <Button type={'button'} onClick={()=>{goBack()}}>Back</Button>
                        <Spacer inline right={3}/>
                        <Button type={'cancel'} onClick={()=>{cancelBtn()}}>Cancel</Button>
                    </Spacer>
                    {state.open && <AddDriver preState={preState}></AddDriver>}
                </Grid>
            </DriversDispatchContext.Provider>
        </DriversStateContext.Provider>
    );
}

export default React.memo(DriversPanel);
