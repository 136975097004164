import React, { useState, useEffect } from 'react';
import { useTrackedState as useBotState } from "./Store";
import Loading, { useLoading } from "../../Chat/Loading";
import styled from "styled-components";
import ScrollToBottom, { useScrollToBottom, useScrollToEnd } from "react-scroll-to-bottom";
import { Button } from "@dspworkplace/ui";
import BotOPenShift from './BotOpenShift';
import BotSwapShift from './BotSwapShift';
import BotReleaseShift from './BotReleaseShift';
import BotSwapShiftUnScheduled from './BotSwapShiftUnscheduled';

// Chat/BotTaskInteraction.tsx
function BotTaskList({ reviewType }) {
    const state = useBotState();
    const { message } = state;
    const { loading, setLoading } = useLoading();

    // useEffect(() => {
    //     if (twilioMessage.dateUpdated && twilioMessage.attributes["task"]) {
    //         setTwilioMessage(message!.element);
    //         let task = twilioMessage.attributes["task"];
    //         if (task["id"] && task["type"]) {
    //             setTaskInformation({id: task["id"], type: task["type"]});
    //             setLoading(false);
    //         }
    //     }
    // }, [message, setLoading, twilioMessage.attributes, twilioMessage.dateUpdated]);

    // if (loading) {
    //     return <Loading containerHeight={"500px"} />
    // }

    if (reviewType === 'OPEN_SHIFT') {
        return (
            <BotOPenShift />
        )
    }

    if (reviewType === 'SWAP_SHIFT') {
        return (
            <BotSwapShift />
        )
    }

    if (reviewType === 'RELEASE_SHIFT') {
        return (
            <BotReleaseShift />
        )
    }

    return null
}

export default BotTaskList


export const Container = styled.div`
    width: 300px;
    display: flex;
    row-gap: 4px;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : "column")};;
    margin: 4px 20px 4px 20px;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};

    &.row {
        flex-direction: row;
    }

    &.left {
        flex-direction: row;
        justify-content: flex-start;
    }

    &.right {
        flex-direction: row;
        justify-content: flex-end;
    }

    &.btn {
        padding-left: 8px;
        margin: 8px 20px 8px 20px;
        justify-content: center;
    align-items: center;
    width: 100%;
    }
`;

export const ContainerMultipleOpenShiftDriver = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 48px;
    background-color: #EAF0F6;
    border-radius: 2px;
`;

export const ContainerItem = styled.div`
    background-color: #EAF0F6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${props => (props.width ? props.width : "100%")};
    padding: ${props => (props.padding ? props.padding : "12px")};
    column-gap: ${props => (props.columnGap ? props.columnGap : "0")};
    max-height: 48px;
`;

export const ButtonContainer = styled(Button)`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin: 4px 0 0 8px;
width: unset;
border-radius:20px;
background-color: #ffffff;
color:#F7931F;
box-shadow:rgba(100, 100, 111, 0.2) 0px 1px 13px 0px !important;
font-weight:normal;
font-family: circe-rounded, serif !important;
&:hover{
    background-color: #F7931F;
    color:#ffffff;
    box-shadow: rgba(247, 147, 31, 0.65) 0px 1px 13px 0px !important;
    font-weight:500
  }
`;

export const Text = styled.div`
    font-family: circe-rounded, serif;
    font-size: 16px;
    color:#0071bc;
display: flex;
justify - content: center;

    &.primary {
    color: white;
}

    &.openShiftDriver {
    font - size: 14px;
    font - weight: 600;
}

    &.driver {
    font - size: 14px;
}
`;

export const ScrollContainer = styled.div`
overflow-y: auto;
overflow-x: hidden;
// height: calc(100vh - 64px);
height:${props => (props.height ? props.height : "80%")};
width: 1000;
&::-webkit-scrollbar {
    width: 0;
}
`;