export const STATUS = {
    PENDING:'pending',
    READY:'Ready',
    ERROR:'error',
    FILTERING:'filtering'
}


export const initialState = (options) => {
    return {
        matching:options.matching || {},
        filterLookups: options.filterLookups || [],
        status:STATUS.PENDING,
        data: [],
        refreshCount:0
    }
}

export const reducer = (state,action) =>{
    const {payload} = action;
    switch (action.type){
        case 'loading':
            return {...state,status:payload.loading}
        case 'addData':
            state.data = payload.data || [];
            state.status = STATUS.READY;
            return {...state};
        case 'createData':
            if(payload.data){
                payload.data.isNew = true;
                state.data.push(payload.data);
            }
            return {...state};
        case 'removeMultipleData':
            payload.id.forEach((id) => {
                const removeIndex = state.data.findIndex((s) => s.id === id);
                if (removeIndex >= 0) {
                    state.data.splice(removeIndex, 1);
                }
            });
            return { ...state };
        case 'editData':
            const {id} = payload.data;
            const editIndex = state.data.findIndex((s)=> s.id === id);
            if(editIndex >= 0){
                payload.data.isNew = true;
                state.data[editIndex] = payload.data;
            }
            return {...state};
        case 'forceRefresh':
            state.refreshCount += 1;
            state.status = STATUS.PENDING;
            return {...state};
        default:
            return {...state};
    }
}