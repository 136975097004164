import React, {useReducer} from "react";
import styled from "styled-components";
import {Tabs, usePanelState, useTabState} from "@bumaga/tabs";
import {TabList} from "./Tabs";
import ModalWindow from "./Modal";
import {useDispatch, useTrackedState} from "./store";
import CGCPanelAddChannelInformation from "./CGCPanelAddChannelInformation";
import CGCPanelAddMembersToNewChannel from "./CGCPanelAddMembersToNewChannel";
// import CGCPanelAddMembersLoadOut from "./CGCPanelAddMembersLoadOut";
import CGCSearchGroupChannels from "./CGCSearchGroupChannels";
import {Channel} from "twilio-chat/lib/channel";
import {EmployeeTwilioUser, FetchedTwilioUser, FilterArrayType} from "./types";
import {
    // @ts-ignore
    addingDefaultUsers,
    filtersForUserSearch,
    setMatchesBasedOnFilter
} from "./utils";

const Tab = ({children}) => {
    const {onClick, isActive} = useTabState();
    return (
        <h6 onClick={onClick} className={isActive ? "tabActive" : ""} data-tab={"tab"}>
            {children}
        </h6>
    );
};

// @ts-ignore
const ContentPanel = styled.div`
    box-sizing: border-box;
    display: flex;
    font-family: circe-rounded, serif;
    flex-direction: column;
`;

// @ts-ignore
const Panel = ({children}) => {
    const isActive = usePanelState();
    return isActive ? <ContentPanel> {children} </ContentPanel> : <div style={{display: "none"}}/>;
};

// @ts-ignore
const Content = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 40px auto;
    grid-row-gap: 12px;
`;

export type NewChannelState = {
    loadingUsers: boolean;
    name: string;
    description: string;
    image?: string | null | FileList;
    errorMessage?: string | null;
    users: Array<EmployeeTwilioUser>;
    matchUsers: Array<EmployeeTwilioUser>;
    filterArray: Array<FilterArrayType>;
    usersToAdd: Array<EmployeeTwilioUser>;
    loadingCreating: boolean;
    loadingCheckingChannelInformation: boolean;
    loadingLoadOutUsers: boolean;
    searchGroupChannels: Array<Channel>;
    searchGroupChannelsMatched: Array<Channel>;
    searchGroupChannelsNames: Array<FilterArrayType>;
    loadOutUsers: Array<FetchedTwilioUser>;
    loadOutMatchUsers: Array<FetchedTwilioUser>;
    loadOutUsersToAdd: Array<FetchedTwilioUser>;
    filterLoadOutArray: Array<FilterArrayType>;
    filterCurrentSearch: Array<FilterArrayType>;
    filterResult: Array<FilterArrayType>;
    stations: Array<{ code: string, id: number }>;
    roles: Array<{ name: string, roleName: string, id: number }>;
    skills: Array<{ name: string, id: number }>;
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
    permissions: number[];
    countTagsDefaultAutoUsers: number;
    tagsDefaultAutoUsers: Array<FilterArrayType>;
    defaultAutoUsers:  Array<EmployeeTwilioUser>;
    channelNotification: boolean;
};

export type NewChannelAction =
    | { type: "SET_GROUP_CHANNELS"; searchGroupChannels: Array<Channel> }
    | { type: "ADD_GROUP_CHANNEL"; searchGroupChannel: Channel }
    | { type: "SEARCH_GROUP_CHANNELS"; data: Array<FilterArrayType> }
    | { type: "LOADING_USERS"; loadingUsers: boolean }
    | { type: "SET_MATCHES"; filterSearch: Array<FilterArrayType> }
    | {
    type: "SET_USERS";
    users: Array<EmployeeTwilioUser>;
    defaultAutoUsers: Array<EmployeeTwilioUser>;
    loadingUsers: boolean;
    stations: Array<{ code: string, id: number }>;
    roles: Array<{ name: string, roleName: string, id: number }>;
    skills: Array<{ name: string, id: number }>;
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
}
    | { type: "LOADING_LOAD_OUT_USERS"; loadingLoadOutUsers: boolean }
    | {
    type: "SET_LOAD_OUT_USERS";
    loadOutUsers: Array<FetchedTwilioUser>;
    loadingLoadOutUsers: boolean;
    stations: Array<string>;
    roles: Array<{ name: string, roleName: string }>;
    skills: Array<string>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
}
    | { type: "LOAD_OUT_USERS_MATCHES"; data: Array<FilterArrayType> }
    | {
    type: "SET_NEW_CHANNEL_INFORMATION";
    name: string;
    description: string;
    image?: string | null;
    errorMessage?: string | null;
    permissions: number[];
    channelNotification: boolean;
}
    | { type: "ADD_NEW_LOAD_OUT_USER"; user: FetchedTwilioUser }
    | { type: "REMOVE_NEW_LOAD_OUT_USER"; user: FetchedTwilioUser }
    | { type: "RESET_NEW_CHANNEL_INFORMATION" }
    | { type: "SELECT_ALL_MATCHES" }
    | { type: "DELETE_ALL_MATCHES" }
    | { type: "ADD_MEMBER"; userId: string | number }
    | { type: "REMOVE_MEMBER"; userId: string | number }
    | { type: "ERROR_CHANNEL_NAME"; errorMessage: string; name: string }
    | { type: "LOADING_CREATING_CHANNEL"; loadingCreating: boolean }
    | { type: "LOADING_CHECKING_CHANNEL_INFORMATION"; loadingCheckingChannelInformation: boolean };

const reducer = (state: NewChannelState, action: NewChannelAction): NewChannelState => {
    switch (action.type) {
        case "SET_GROUP_CHANNELS":
            return {
                ...state,
                searchGroupChannels: action.searchGroupChannels,
                searchGroupChannelsMatched: action.searchGroupChannels,
                searchGroupChannelsNames: action.searchGroupChannels.map(item => ({
                    name: item.friendlyName,
                    value: item.friendlyName
                }))
            };
        case "ADD_GROUP_CHANNEL":
            let channels = [action.searchGroupChannel, ...state.searchGroupChannels];
            return {
                ...state,
                searchGroupChannels: channels,
                searchGroupChannelsMatched: channels,
                searchGroupChannelsNames: channels.map(item => ({
                    name: item.friendlyName,
                    value: item.friendlyName
                }))
            };
        case "SEARCH_GROUP_CHANNELS":
            let filterSearch = action.data.map(item => item.value);
            if (filterSearch.length === 0) {
                return {...state, searchGroupChannelsMatched: state.searchGroupChannels};
            }
            return {
                ...state,
                searchGroupChannelsMatched: state.searchGroupChannels.filter(channel => {
                    return filterSearch.some(item => item.indexOf(channel.friendlyName) >= 0);
                })
            };
        case "LOADING_USERS":
            return {...state, loadingUsers: action.loadingUsers};
        case "SET_USERS":
            let {
                newFilterEmailArray,
                newFilterNamesArray,
                newFilterRolesArray,
                newFilterSchedulesArray,
                newFilterSkillsArray,
                newFilterStationArray,
                tagsDefaultAutoUsers,
                defaultAutoUsers,
                newFilterTeamArray,
                newFilterChatVariableArray
            } = filtersForUserSearch(action.stations, action.roles, action.skills, action.schedules, action.users, action.defaultAutoUsers, action.teams, action.chatVariable);
            return {
                ...state,
                users: action.users,
                loadingUsers: action.loadingUsers,
                matchUsers: action.users,
                stations: action.stations,
                skills: action.skills,
                roles: action.roles,
                schedules: action.schedules,
                teams: action.teams,
                chatVariable: action.chatVariable,
                filterArray: [
                    ...newFilterStationArray,
                    ...newFilterRolesArray,
                    ...newFilterSkillsArray,
                    ...newFilterSchedulesArray,
                    ...newFilterNamesArray,
                    ...newFilterEmailArray,
                    ...newFilterTeamArray,
                    ...newFilterChatVariableArray,
                ],
                defaultAutoUsers: defaultAutoUsers,
                countTagsDefaultAutoUsers: tagsDefaultAutoUsers.length,
                tagsDefaultAutoUsers: tagsDefaultAutoUsers,
            };
        case "SET_LOAD_OUT_USERS":
            return {
                ...state
            };
        case "LOAD_OUT_USERS_MATCHES":
            return {...state, loadOutMatchUsers: []};
        case "ADD_NEW_LOAD_OUT_USER":
            return {
                ...state,
                loadOutUsersToAdd: [action.user, ...state.loadOutUsersToAdd],
                loadOutMatchUsers: state.loadOutMatchUsers.filter(item => item.userId !== action.user.userId)
            };
        case "REMOVE_NEW_LOAD_OUT_USER":
            return {
                ...state,
                loadOutUsersToAdd: state.loadOutUsersToAdd.filter(item => item.userId !== action.user.userId),
            };
        case "RESET_NEW_CHANNEL_INFORMATION":
            return {...state, name: "", description: "", errorMessage: "", image: ""};
        case "SET_NEW_CHANNEL_INFORMATION":
            return {
                ...state,
                name: action.name,
                description: action.description,
                errorMessage: action.errorMessage,
                image: action.image,
                loadingCheckingChannelInformation: false,
                permissions: action.permissions,
                channelNotification: action.channelNotification
            };
        case "SET_MATCHES":
            return {
                ...state,
                matchUsers: setMatchesBasedOnFilter(action.filterSearch, state.users),
                filterCurrentSearch: action.filterSearch
            };
        case "DELETE_ALL_MATCHES":
            return {
                ...state,
                usersToAdd: [],
                filterCurrentSearch: [],
                filterResult: [],
                filterArray: state.filterArray
            };
        case "SELECT_ALL_MATCHES":
            return {
                ...state,
                usersToAdd: addingDefaultUsers(state.matchUsers, state.defaultAutoUsers, state.users),
                matchUsers: [],
                filterResult: state.filterCurrentSearch
            };
        case "ADD_MEMBER":
            let newMember = state.matchUsers.find(item => item.userId === action.userId);
            if (newMember) {
                return {
                    ...state,
                    usersToAdd: [newMember, ...state.usersToAdd],
                    matchUsers: state.matchUsers.filter(item => item.userId !== action.userId)
                };
            }
            return state;
        case "REMOVE_MEMBER":
            let member = state.usersToAdd.find(item => item.userId === action.userId);
            if (member) {
                return {
                    ...state,
                    usersToAdd: state.usersToAdd.filter(item => item.userId !== action.userId),
                    matchUsers: [member, ...state.matchUsers]
                };
            }
            return state;
        case "ERROR_CHANNEL_NAME":
            return {
                ...state,
                errorMessage: action.errorMessage,
                name: action.name,
                loadingCheckingChannelInformation: false,
                loadingCreating: false
            };
        case "LOADING_CREATING_CHANNEL":
            return {...state, loadingCreating: action.loadingCreating};
        case "LOADING_CHECKING_CHANNEL_INFORMATION":
            return {...state, loadingCheckingChannelInformation: action.loadingCheckingChannelInformation};
        case "LOADING_LOAD_OUT_USERS":
            return {...state, loadingLoadOutUsers: action.loadingLoadOutUsers};
        default:
            return state;
    }
};


// export function equalSet(arrA: Array<string>, arrB: Array<string>) {
//     let a = new Set(arrA);
//     let b = new Set(arrB);
//     return a.size === b.size && [...arrA].every(value => b.has(value));
// }

const initialState: NewChannelState = {
    loadingUsers: true,
    name: "",
    description: "",
    image: null,
    errorMessage: "",
    users: [],
    matchUsers: [],
    filterArray: [],
    usersToAdd: [],
    loadingCreating: false,
    loadingCheckingChannelInformation: true,
    loadingLoadOutUsers: true,
    searchGroupChannels: [],
    searchGroupChannelsNames: [],
    searchGroupChannelsMatched: [],
    loadOutUsers: [],
    loadOutMatchUsers: [],
    loadOutUsersToAdd: [],
    filterLoadOutArray: [],
    filterResult: [],
    filterCurrentSearch: [],
    schedules: [],
    skills: [],
    roles: [],
    stations: [],
    teams: [],
    chatVariable: [],
    permissions: [],
    countTagsDefaultAutoUsers: 0,
    tagsDefaultAutoUsers: [],
    defaultAutoUsers: [],
    channelNotification: false,
};

const CGCOptions = () => {
    const state = useTrackedState();
    const dispatch = useDispatch();
    const [newChannelModalState, newChannelModalDispatch] = useReducer(reducer, initialState);

    if (!state.groupChannelModal) {
        return null;
    }

    return (
        <ModalWindow
            width={"700px"}
            cancelModal={() => dispatch({type: "CLOSE_NEW_CHANNEL_MODAL"})}
            title={"Channel Options"}
        >
            <Content>
                <Tabs>
                    <TabList>
                        <Tab>Search</Tab>
                        <Tab>Create Channel</Tab>
                        <Tab>Add Members</Tab>
                        {/*<Tab>Load Out</Tab>*/}
                    </TabList>
                    <Panel>
                        <CGCSearchGroupChannels
                            newChannelModalState={newChannelModalState}
                            newChannelModalDispatch={newChannelModalDispatch}
                            closeModal={() => dispatch({type: "CLOSE_NEW_CHANNEL_MODAL"})}
                        />
                    </Panel>
                    <Panel>
                        <CGCPanelAddChannelInformation
                            newChannelModalState={newChannelModalState}
                            newChannelModalDispatch={newChannelModalDispatch}
                        />
                    </Panel>
                    <Panel>
                        <CGCPanelAddMembersToNewChannel
                            newChannelModalState={newChannelModalState}
                            newChannelModalDispatch={newChannelModalDispatch}
                            closeModal={() => dispatch({type: "CLOSE_NEW_CHANNEL_MODAL"})}
                        />
                    </Panel>
                    {/*<Panel>*/}
                    {/*    <CGCPanelAddMembersLoadOut*/}
                    {/*        newChannelModalState={newChannelModalState}*/}
                    {/*        newChannelModalDispatch={newChannelModalDispatch}*/}
                    {/*        closeModal={() => dispatch({ type: "CLOSE_NEW_CHANNEL_MODAL" })}*/}
                    {/*    />*/}
                    {/*</Panel>*/}
                </Tabs>
            </Content>
        </ModalWindow>
    );
};

export default CGCOptions;
