import {EmployeeRow} from "./employeeRow";
import React,{useRef} from "react";
import {LoadingView} from "../../components/Loading";
import {Table, TableHeader} from "../../components/Table";
import {FlexScroll} from "./index";
import Empty from "../../components/Empty"

let headers = [
    {width: "200px", label: "Employee", sortBy: "name"},
    {width: "160px", label: "Transporter ID", sortBy: "transporterID"},
    {width: "200px", label: "Skills"},
    {width: "180px", label: "Current Schedule", centered: true},
    {width: "152px", label: "Status", centered: true},
    {width: "60px", label: "Enable Chat"},
];

export const EmployeesList = ({employees, isMoreData, loadMoreData, isLoading, modalHandler, handleOrder, sortBy, handleUpdateEmployees,canAddEmployees, rolePermissionLevel}) => {
    const scrollEl = useRef(null);
    // let li = [];
    // if (employees.length > 0) {
    //     employees.map((employee, index) => {
    //         li.push(
    //             <EmployeeRow
    //                 key={index}
    //                 employee={employee}
    //                 modalHandler={modalHandler}
    //                 keyVal={index}
    //                 handleUpdateEmployees={handleUpdateEmployees}
    //             />
    //         );
    //     });
    // }

    if (isLoading || !Array.isArray(employees)) {
        return (
            <LoadingView containerHeight={"calc(100vh - 350px)"}/>
        );
    }
    const scrollChange = () => {
        if(isMoreData){
            if(scrollEl.current.scrollTop + scrollEl.current.offsetHeight > scrollEl.current.scrollHeight - 5){
                loadMoreData()
            }
        }
    };

    if (Array.isArray(employees)) {
        return (
            <>
                <TableHeader headers={headers} sortByFunc={handleOrder} sortedBy={sortBy}/>
                <FlexScroll as={'li'} onScroll={()=>scrollChange()} ref={scrollEl}>
                {employees.length===0 && <Empty/>}
                    <Table width={'100%'}>
                        <li/>
                        {employees.map((employee, index) => {
                            return <EmployeeRow
                                key={index}
                                employee={employee}
                                modalHandler={modalHandler}
                                keyVal={index}
                                handleUpdateEmployees={handleUpdateEmployees}
                                canAddEmployees={canAddEmployees}
                                rolePermissionLevel={rolePermissionLevel}
                            />
                        })}
                    </Table>
                </FlexScroll>
            </>
        );
    }

    return null;
};