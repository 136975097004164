import React from 'react';
import styled from 'styled-components';
import {Icon} from '@dspworkplace/ui';
import Driver from '../../../../../../../components/People';

const Grid = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : 'auto'};
    grid-column-gap: ${props=>props.columnGap ? props.columnGap : '0'};
    grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
    justify-content: ${props=>props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    border: ${props=>props.border? props.border : '0'};
    border-radius: ${props=>props.borderRadius ? props.borderRadius : 'unset'};
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : 'unset'};
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    font-size: ${props=>props.fontSize ? props.fontSize : '14px'};
    color: ${props=>props.color ? props.color : '#333333'};

    > svg {
        box-sizing: border-box;
        border-radius: ${props=>props.borderRadius ? props.borderRadius : 'unset'};
    }
`;

const DriverCell = ({name,role, cel, padding, image}) => {
     return (
            <Grid templateColumns='32px auto' columnGap='8px' padding={padding}>
                <Grid justifyContent='center' borderRadius='50%'>
                    <Driver size='30px' img={(image) ? image : ''}/>
                </Grid>
                <Grid templateRows='18px 14px'>
                    <Grid color='#333333'>
                        {name}
                    </Grid>
                    <Grid color='#707070' fontSize='12px'>
                        {role}
                    </Grid>
                </Grid>
            </Grid>
    );
}

export default React.memo(DriverCell);
