import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useChatStoreReducer } from './store';
import ModalWindow from '../Chat/Modal';
import DatePicker from '../components/Calendar/picker';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { FilterDayAnnouncement } from '../hooks/useGlobalData';
import { Button, CheckBox } from '@dspworkplace/ui';
import Loading from '../Chat/Loading';
import { engine, getCompanyId, setChatCompanyId, setChatUserId, getUserId, setChatTeam, setStreamChatIdentifier, getStreamChatIdentifier, setImpersonateStreamChatIdentifier, getImpersonateStreamChatIdentifier, setIsOpenChat, showToast, setChatUserRole } from "../Utilities";
import { Combobox } from '../components/Form/Combobox';

function ExportModal() {
    const { state, dispatch } = useChatStoreReducer();
    const [loading, setLoading] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [allCompanyUsers, setAllCompanyUsers] = useState([]);
    const [allCompany, setAllCompany] = useState([]);
    const [user, setUser] = useState();
    const [terminatedEmployees, setTerminatedEmployees] = useState(false);
    const { register, handleSubmit, watch, errors, getValues } = useForm();

    let employeeStatusArr = [
        {value: '-2', name: 'Terminated'},
        {value: '-1', name: 'Offboarded'},
        {value: '0', name: 'Inactive'},
        {value: '1', name: 'Onboarding'},
        {value: '2', name: 'Active'},
    ];
    const ref = useRef(null)

    // const changeUser = async (e) => {
        // if (e?.value) {
        //     if (!getImpersonateStreamChatIdentifier()) {
        //         await setImpersonateStreamChatIdentifier(getStreamChatIdentifier());
        //     }
        //     await setChatTeam('company-' + selectedCompanyId);
        //     await setChatUserId(e?.value?.id);
        //     await setChatCompanyId(selectedCompanyId);
        //     await setStreamChatIdentifier(e?.value?.streamChatIdentifier);
        //     await setChatUserRole(e?.value?.roleName)
        //     if (window.location.pathname === "/chat")
        //         setIsOpenChat(false)
        //     else
        //         setIsOpenChat(true);
        //     window.location.reload();
        // }
    // }
    const getAllCompany = async () => {
        if (getUserId() === 1560 || getUserId() === 2696) {
            const companyParams = {
                actions: {
                    response: {
                        Company: {
                            findBy: {
                                criteria: {},
                                get: "company",
                                includes: {
                                    0: "id",
                                    1: "name",
                                },
                                orderBy: {
                                    name: "ASC"
                                }
                            }
                        }
                    }
                }
            };

            let response = await engine().post("/api/lazy/manage/data", companyParams).then(result => {
                if (result) {
                    try {
                        setAllCompany(result.data.data.company);
                        setLoading(false);
                    } catch (e) {
                        console.error({ e });
                    }
                }
            });
        } else {
            setSelectedCompanyId(getCompanyId());
        }

    };
    const getAllEmployees = async () => {
        const params = {
            actions: {
                response: {
                    "User": {
                        "custom": {
                            "functionName": "getImpersonateChatUser",
                            "get": "user",
                            "criteria": {
                                "company": selectedCompanyId
                            },
                            "excludes": {},
                            "includes": {}
                        }
                    }
                },

            },
        };

        if (selectedCompanyId) {
            let response = await engine().post("/api/lazy/manage/data", params).then(async result => {
                if (result) {
                    try {
                        setAllUsers([]);
                        const users = await result.data.data.user;
                        let filterUser = await users.filter(i => (i?.streamChatIdentifier !== null || i?.streamChatIdentifier !== undefined));
                        setAllCompanyUsers(filterUser);
                        if(terminatedEmployees) {
                            let terminated = filterUser.filter(i => (i?.employeeStatus === '-2'));
                            filterUser = terminated;
                        }
                        setAllUsers(filterUser);
                        setLoading(false);
                    } catch (e) {
                        console.error({ e });
                    }
                }
            });
        }
    };

    const handleOnlyTerminated = (terminated) => {
        if(terminated) {
            setTerminatedEmployees(true);
            const filterUser = allCompanyUsers.filter(i => (i?.employeeStatus === '-2'));
            setAllUsers(filterUser);
        } else {
            setTerminatedEmployees(false);
            setAllUsers(allCompanyUsers);
        } 
    }

    useEffect(() => {
        getAllEmployees();
    }, [selectedCompanyId]);

    useEffect(() => {
        try {
            setLoading(true);
            getAllCompany();
        } catch (e) {
            console.error({ e, name: 'ImpersonateModal()' });
        }

    }, []);

    const closeModal = useCallback(
        () => {
            dispatch({ type: "CLOSE_EXPORT_MODAL" });
        },
        [dispatch],
    );

    const onSubmit = async (data) => {
        try {
            await setLoading(true);
            if(user?.value?.streamChatIdentifier){
                const response = await engine().post('/api/stream/channel/export', {
                    "currentUser": user.value.streamChatIdentifier,
                    "sendUserId": getUserId()
                })
            }
            closeModal()
            await setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    if (!state.exportModal) {
        return null;
    }

    return (
        <ModalWindow minHeight={"unset"} width={"500px"} title={"Export Chat"} cancelModal={closeModal} cancelText={"Close"} overflowX={"unset"}>
            <form onSubmit={handleSubmit(onSubmit)} >
                {/* <DatePicker
                    ref={register()}
                    name='dates'
                    label='Date Range (Select Multiple Days)'
                    range='date'
                    onChange={date => {
                        if (date) {
                            const dateRangeArr = date.split(' – ');
                            const startDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[0]) : '';
                            const endDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[1]) : '';
                            if (startDate.isValid() && endDate.isValid()) {
                                setSelectedDate({
                                    start: startDate.format('YYYY-MM-DD'),
                                    end: endDate.format('YYYY-MM-DD')
                                });
                            }
                        }
                    }}
                    defaultValues={[new Date(selectedDate.start), new Date(selectedDate.end)]}
                /> */}
                <div style={{}}>
                    {(getUserId() === 1560 || getUserId() === 2696) &&
                        <Combobox
                            ref={register()}
                            size={'full'}
                            options={allCompany.map((c) => { return { label: c.name, value: c.id } })}
                            placeholder={'All Company'}
                            onChange={(e) => { setSelectedCompanyId(e.value); }}
                            isAdd={false}
                            optionStyle={{ 'overflowX': 'auto', 'maxHeight': '300px' }}
                        />
                    }
                </div>
                <div style={{}}>
                    <CheckBox
                        name='terminatedEmployees'
                        options={[
                            { value: true, label: 'Only Terminated' }
                        ]}
                        ref={register()}
                        defaultValues={[terminatedEmployees]}
                        onClick={(e) => { handleOnlyTerminated(e.target.checked); }}
                    />
                </div>
                <div style={{}}>
                    <Combobox
                        size={'full'}
                        options={allUsers.map((u) => { 
                            let status = employeeStatusArr.find((s) => s.value === u?.employeeStatus); 
                            return { label: `${u?.friendlyName} (${u?.roleName}) - (${status.name})`, value: u } 
                        })}
                        name="user"
                        ref={ref}
                        placeholder={'All Employees'}
                        onChange={(e) => setUser(e)}
                        isAdd={false}
                        optionStyle={{ 'overflowX': 'auto', 'maxHeight': '300px' }}
                    />
                </div>
                {loading ?
                    <Loading containerHeight={'30px'} containerWidth='30px' />
                    :
                    <Button type="primary" style={{"marginTop": "15px"}}>Export</Button>
                }
            </form>
        </ModalWindow>
    )
}
export default ExportModal