import React from "react";
import { Spacer } from "@dspworkplace/ui";
import LaborExceptionReport from './laborExceptionReport'
import RecurringWeeks from './recurringWeeks'
import DailySummary from './dailySummary'
import GradingSettings from './grading'
import styled from 'styled-components';
import MobileShowOptions from "./MobileViewOptions";
import BotManagers from "./BotManagers";
import DefaultAutoUsers from "./DefaultAutoUsers";
import AllowDriverDirectChats from "./allowDriverDirectChats"
import WorkHourComplianceWarning from "./WorkHourComplianceWarning"
import SchedulePrefferSetting from "./schedulePrefferSetting"
import AllowsDispatcherPermission from "./allowsDispatcherPermission";

const Container = styled.div`
    display: grid;
    width: 100%;
`;

const App = () => {
    return (
        <Container>
            <GradingSettings />
            <Spacer bottom={15} />
            <DailySummary />
            <Spacer bottom={15} />
            <RecurringWeeks />
            <Spacer bottom={15} />
            <LaborExceptionReport />
            <Spacer bottom={20} />
            <AllowDriverDirectChats />
            <Spacer bottom={20} />
            <MobileShowOptions />
            <Spacer bottom={20} />
            <BotManagers />
            <Spacer bottom={20} />
            <DefaultAutoUsers />
            <Spacer bottom={20} />
            <WorkHourComplianceWarning />
            <Spacer bottom={20} />
            <SchedulePrefferSetting />
            <Spacer bottom={20} />
            <AllowsDispatcherPermission />
            <Spacer bottom={20} />
            <Spacer bottom={20} />
        </Container>
    )
}
export default App;