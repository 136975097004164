import React, {Suspense} from 'react';
import {Button, Icon, Spacer, Theme, ThirdTitle, Title} from "@dspworkplace/ui";
import spaceship from '../assets/spaceship.png';
import AccessCheck from "./AccessCheck";
import {getRole} from "../Auth";
import Loading, {LoadingWrapper} from "../components/Loading";
import {useRecoilValue} from "recoil";
import {planDetailsState} from "../state/planDetail";
import {Link} from "../components/UI";

const UpgradePlan = () => {
    return (
        <Spacer all={10} style={{textAlign: 'center'}}>
            <img src={spaceship} alt="" style={{width: 300}}/>
            <Spacer bottom={5}/>
            <Title>You must upgrade your plan</Title>
            <Spacer bottom={2}/>
            <ThirdTitle>
                Your current plan does not have access to this feature,
                <br/>
                please upgrade your plan to gain access.
            </ThirdTitle>
            <Spacer bottom={5}/>
            <Link
                as={Button}
                type='primary'
                to={'/settings/package'}
                style={{
                    color: Theme.colors.neutrals.white,
                    textDecoration: 'none'
                }}
            >Upgrade My Plan</Link>
        </Spacer>
    )
}

const Unauthorised = () => {
    return (
        <Spacer all={10} style={{textAlign: 'center'}}>
            <Icon.Locker/>
            <Spacer bottom={5}/>
            <Title>You do not have access to this feature</Title>
            <Spacer bottom={2}/>
            <ThirdTitle>
                Your current job description does not allow you to access this area,
                <br/>
                please contact your admin to gain access.
            </ThirdTitle>
        </Spacer>
    )
}

const LoadingPermissions = () => {
    return (
        <LoadingWrapper>
            <Loading/>
        </LoadingWrapper>
    )
}

const IsGrantedRender = ({
    feature,
    ops = null,
    station = null,
    renderWarning = false,
    children
}) => {
    const {tier} = useRecoilValue(planDetailsState);

    if ( !AccessCheck.isGranted({ plan: tier, feature }) )
        return renderWarning ? <UpgradePlan/> : null;

    if ( !AccessCheck.isGranted({ role: getRole(), feature, ops, station }) )
        return renderWarning ? <Unauthorised/> : null;

    return children;
}

export default function IsGranted(props) {
    const {feature, children} = props;
    if ( !feature )
        return children;

    return (
        <Suspense fallback={<LoadingPermissions />}>
            <IsGrantedRender {...props} feature={feature}>
                {children}
            </IsGrantedRender>
        </Suspense>
    )
}