import React,{useReducer} from 'react';
import {Text} from '@dspworkplace/ui';

type SchemaProps = 'Loadout' | 'Route'  | 'Vehicle'

export type bar = {
    groupBy:[string]
}

export type TypeProps = 'table' | 'pie' | 'bar' | 'line' | 'number'

type chartConfig = bar

interface ChartConfigState {
    schema?: SchemaProps
    type?: TypeProps
    chartConfig?: chartConfig
    pivotConfig?: {},
    labels?: {}
}

interface labels {
    [key: string]: {label: string}
}

type ChartConfigAction =
    {action:'SET_SCHEMA',schema:SchemaProps} |
    {action: 'SET_TYPE', type:TypeProps} |
    {action: 'SET_CHART_PIVOT', pivotConfig: {}} |
    {action: 'SET_LABELS', labels: labels }

const chartConfigReducer = (state:ChartConfigState,action: ChartConfigAction): ChartConfigState => {
    switch(action.action){
        case 'SET_SCHEMA':
            return {...state,schema:action.schema}
        case 'SET_TYPE':
            return {...state,type:action.type}
        case 'SET_CHART_PIVOT':
            return {...state,pivotConfig:action.pivotConfig}
        case 'SET_LABELS':
            return {...state,labels:{...state.labels,...action.labels}}
        default:
            return state;
    }
}

const useChartConfig = (initialState: ChartConfigState = {}): [ChartConfigState, React.Dispatch<ChartConfigAction>] => (
    useReducer(chartConfigReducer,initialState)
)

const chartTypeDesc = {
    Loadout: () => (
        <>
            <Text>
                schema contains data about the first process made by a driver.
            </Text>
            <Text>
                You can build charts based unfilled required items by driver.
            </Text>
        </>
    ),
    Route: () => (
        <>
            <Text>
                schema contains data about the individual route.
            </Text>
            <Text>
                You can build charts based on mileage per day, packages, and every other data related to a route.
            </Text>
            <img src={'https://dspworkplace.com/static/media/scheduler@3x.5d286700.png'} style={{maxWidth:'90%'}} />
        </>
    ),
    Shift: () => (
        <>
            <Text>The shift schema contains all of the basic information tied to a shift/route.</Text>
            <Text>For example, with the shift schema, you can build widgets/reports based on nearly anything associated with your shifts. For example: driver, date, shift type, invoice type, Load out and RTS inputs, shift start and end times, punch times, route code (if assigned), and any incident type assigned to the shift.</Text>
        </>
    ),
    Vehicle: () => (
        <>
            <Text>The vehicles schema contains everything stored under Company Management &gt;&gt; Vehicles.</Text>
            <Text>For example, with the vehicles schema, you can build charts based on a vehicle’s current total mileage, status, type, or even toll card end date</Text>
        </>
    )
}
export const getChartTypeDesc = (chartType: TypeProps | null) => {
    if(!chartType) return null;
    return (chartTypeDesc[chartType] && chartTypeDesc[chartType]()) || <Text>No data found about this schema</Text>
}

export default useChartConfig