import React from 'react'
import { CloseIcon, Message, useChannelStateContext } from 'stream-chat-react'
import styled from "styled-components";
import { Text } from '../styles';

// const MemoizedMessageTeam = React.memo(
//     MessageTeamWithContext,
//     areMessageUIPropsEqual,
// );

function PinnedMessageList({ isOpenPinnedList, setOpenPinnedList, isPage }) {
    const { channel } = useChannelStateContext();
    return (
        <div style={{ display: isOpenPinnedList ? 'flex' : 'none', width: isPage ? '400px' : '200px', borderLeft: 'solid 1px #EAF0F6', }} >
            <Container>
                <HeaderContainer>
                    <div style={{ cursor: 'pointer', }} onClick={() => setOpenPinnedList(false)} >
                        <CloseIcon />
                    </div>
                    <Text color={'#000000'} fontWeight={500} margin="15px" >
                        Pinned Messages</Text>
                </HeaderContainer>

                <div style={{ padding: "10px" }} className='pinned-messages__list'>
                    {channel?.state?.pinnedMessages.map((message) => (
                        <Message
                            groupStyles={['single']}
                            key={message.id}
                            message={message}
                        />
                    ))}
                </div>

                {channel?.state?.pinnedMessages.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text>No pinned messages</Text>
                    </div>)
                }

            </Container>
        </div>
    )
}

export default PinnedMessageList

const Container = styled.div`
background-color: #ffffff;
display: block;
width: 100%;
`;

const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 70px;
min-height: 70px;
top: 0;
display: flex;
align-items: center;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
`;