import {ItemScorecardInformation, MobileScorecardInformation} from '../shared';
import {Dispatch, useReducer} from 'react';
import {createContainer} from 'react-tracked';
import moment from 'moment-timezone';

type WeeklyDataState = {
    loading: boolean;
    data: MobileScorecardInformation | null;
    focusArea?: ItemScorecardInformation | null;
    scoresCards?: Array<ItemScorecardInformation>;
    rescuesCards?: Array<ItemScorecardInformation>;
    incidentsCards?: Array<ItemScorecardInformation>;
    weekNumber?: number;
    currentDay?: string;
    refresher?: number;
    lastDay?: string;
};

const initialState: WeeklyDataState = {
    data: null,
    loading: true,
    refresher: 1.0,
};

type WeeklyDataAction =
    | {
    type: 'SET_NEXT_WEEK';
    data: MobileScorecardInformation;
}
    | {
    type: 'SET_PREVIOUS_WEEK';
    data: MobileScorecardInformation;
}
    | {
    type: 'SET_SCORES_VIEW';
    data: MobileScorecardInformation;
}
    | {
    type: 'UPDATE_SCORES_VIEW';
    data: MobileScorecardInformation;
    day: string;
}
    | {
    type: 'SET_OVERVIEW';
    data: MobileScorecardInformation;
}
    | {
    type: 'SET_LOADING';
    loading: boolean;
};

function reducer(
    state: WeeklyDataState,
    action: WeeklyDataAction
): WeeklyDataState {
    switch (action.type) {
        case 'SET_OVERVIEW':
            let {data} = action;
            let scorecardList = data && data.scorecard && Array.isArray(data.scorecard.sortedList) ? data.scorecard.sortedList : [];
            return {
                loading: false,
                data: data,
                focusArea: scorecardList.find(
                    (e) => e.metricIdentifier === 'focus'
                ),
                scoresCards: scorecardList,
                incidentsCards: data.incidents.sortedList,
                rescuesCards: data.rescues.sortedList,
            };
        case 'SET_SCORES_VIEW':
            let lastDay = action.data.scorecard.value0.date.substr(0, 10);
            return {
                loading: false,
                data: action.data,
                scoresCards: action.data.scorecard.sortedList,
                weekNumber: action.data.scorecard.value0.week,
                currentDay: lastDay,
                lastDay: lastDay,
                focusArea: action.data.scorecard.sortedList.find(
                    (e) => e.metricIdentifier === 'focus'
                ),
            };
        case 'UPDATE_SCORES_VIEW':
            return {
                ...state,
                loading: false,
                data: action.data,
                scoresCards: action.data.scorecard.sortedList,
                weekNumber: action.data.scorecard.value0.week,
                currentDay: action.day,
                focusArea: action.data.scorecard.sortedList.find(
                    (e) => e.metricIdentifier === 'focus'
                ),
            };
        case 'SET_PREVIOUS_WEEK':
            return {
                ...state,
                loading: true,
                currentDay: moment(state.currentDay)
                    .subtract(7, 'days')
                    .format('YYYY-MM-DD'),
            };
        case 'SET_NEXT_WEEK':
            return {
                ...state,
                loading: false,
                currentDay: moment(state.currentDay)
                    .add(7, 'days')
                    .format('YYYY-MM-DD'),
            };
        case 'SET_LOADING':
            return {...state, loading: action.loading};
        default:
            return state;
    }
}

const useValue = () => useReducer(reducer, initialState);

const {Provider, useTrackedState, useUpdate, useTracked} = createContainer(
    useValue
);

export function useWeeklyDataReducer(): {
    weeklyDataState: WeeklyDataState;
    weeklyDataDispatch: Dispatch<WeeklyDataAction>;
} {
    const [state, dispatch] = useTracked();
    return {weeklyDataState: state, weeklyDataDispatch: dispatch};
}

export function useWeeklyDataTrackedState(): {
    weeklyDataState: WeeklyDataState;
} {
    const state = useTrackedState();
    return {weeklyDataState: state};
}

export function useWeeklyDataTrackedDispatch(): {
    weeklyDataDispatch: Dispatch<WeeklyDataAction>;
} {
    const dispatch = useUpdate();
    return {weeklyDataDispatch: dispatch};
}

export default Provider;
