import React, {useCallback, useEffect, useRef, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
import {MessageOfChannel} from "./types";
import MessageHeader from "./MessageHeader";
import {MessageBody, MessageContainer, MessageContentContainer} from "./styles";
import MessageContent from "./MessageContent";
import {useUpdate as useBotDispatch} from "./BotStore";
import {devLog, IS_DEV} from "../utils";
import {TaskButton} from "./BotTaskButton";
import { getUserId } from "../Auth";
import { engine } from "../Utilities";

type Props = {
    message: MessageOfChannel;
    currentIdentity: string;
    previousMessage?: MessageOfChannel;
    channel?: Channel;
};

function BotTaskMessage({message}: Props) {
    let {text, date, user, element, mediaUrl} = message;
    const refMessage = useRef<HTMLDivElement>();
    const dispatch = useBotDispatch();
    const [textButton, setTextButton] = useState("Mark as Viewed");

    const reviewHandler = useCallback(async () => {
        if (element.attributes["task"]) {
            dispatch({type: "REVIEW_TASK", message: message});
        } else if (element && element.sid) {
            let attr = Object.assign({}, element.attributes);
            attr['archived'] = true;
            await element.updateAttributes(attr);
            dispatch({type: "MESSAGE_REMOVED", sid: element.sid});
        }
    }, [dispatch, element, message]);

    useEffect(() => {
        if (element.attributes['task'] && element.attributes['task']['type'] && element.attributes['task']['type']['id']) {
            devLog(element);
            setTextButton("Review");
        } else {
            setTextButton("Mark as Viewed");
        }
    }, [element.dateUpdated, element.attributes, element.lastUpdatedBy, element]);

    const updateReadReceiptBot = async () => {
        let taskId = element?.attributes?.['task']?.id
        try {
            engine().post('/api/twilio_bot_message_status', {
                task_id: taskId,
                user_id: getUserId(),
            });
        } catch (e) {
            console.error({ e });
            return e;
        }
    }

    useEffect(() => {
        updateReadReceiptBot()
    }, [])

    return (
        <MessageContainer justifyContent={"flex-start"} data-sid={message.sid}>
            <MessageHeader user={user} date={date} refMessageBox={refMessage} isSystemMessage={true} showDate={true}/>
            <MessageBody justifyContent={"flex-start"}
                         data-attributes={JSON.stringify(element ? element.attributes : null)}>
                <MessageContentContainer ref={refMessage} width={"300px"} className={"left"}>
                    <MessageContent
                        isSentByCurrentUser={false}
                        mediaUrl={mediaUrl}
                        text={text}
                        identity={user.identity}
                        index={element.index}
                    />
                </MessageContentContainer>
                {IS_DEV && <div>
                    {JSON.stringify(element.attributes)}
                </div>}
                <TaskButton reviewHandler={reviewHandler} attributes={element.attributes} text={textButton}
                            message={element} identity={user.identity}/>
            </MessageBody>
        </MessageContainer>
    );
}

export default BotTaskMessage;

