import apiFactory from "./factory";
import {IS_DEV_ENV} from "../utils";

export default function ()
{
    const {call, ...factory} = apiFactory();

    const fetchSeatsInformation = () => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "getSeatsInformation",
                            get: "result",
                            criteria: {},
                        }
                    }
                }
            }
        }).then(response => response.data.data.result)
    }

    const fetchSubscriptions = () => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "getLegacySubscriptions",
                            get: "subscriptions",
                            criteria: {},
                            includes: {
                                0: "id",
                                1: "processorId",
                                2: "interval",
                                3: "status",
                                4: "data",
                                5: "type",
                                6: "tier",
                                "subscriptionItems": {
                                    0: "id",
                                    1: "processorId",
                                    2: "status",
                                    3: "processorPriceId",
                                    4: "processorPriceData",
                                    5: "name",
                                    "product": {
                                        0: "id",
                                        1: "name",
                                        2: "type",
                                        3: "tier",
                                        4: "interval",
                                        5: "name",
                                        6: "features",
                                        7: "chargeAfterEvent",
                                        8: "description",
                                        9: "processorData",
                                        10: "processorId"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    const fetchInvoices = () => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "getInvoices",
                            get: "invoices",
                            criteria: {}
                        }
                    }
                }
            }
        });
    };

    const fetchUsageSummaries = ({
        subscription,
        subscriptionItem
    }) => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "getUsageSummaries",
                            get: "result",
                            criteria: {
                                subscription,
                                subscriptionItem,
                            }
                        }
                    }
                }
            }
        })
    }

    // const getPaymentMethod = () => {
    //     return fetchInvoices();
    // };

    const getAvailableProducts = (type = 'package') => {
        return call(
            {},
            `/api/subscription/get-available-products?type=${type}&testMode=${IS_DEV_ENV}`,
            'get'
        );
    }

    const previewProration = ({
        subscription,
        product,
        price,
        qty
    }) => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "previewProration",
                            get: "result",
                            criteria: {
                                subscription,
                                product,
                                price,
                                qty,
                            }
                        }
                    }
                }
            }
        })
    }

    const updatePackage = ({
        subscription,
        price,
        qty
    }) => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "updatePackage",
                            get: "result",
                            criteria: {
                                subscription,
                                price,
                                qty,
                            }
                        }
                    }
                }
            }
        });
    }

    const addonSignup = ({
        addon
    }) => {
        return call({
            actions: {
                response: {
                    "Subscription\\Subscription": {
                        custom: {
                            functionName: "addonSignup",
                            get: "result",
                            criteria: {
                                addon: addon.id,
                            }
                        }
                    }
                }
            }
        })
    }

    const toggleSubscriptionItemStatus = ({
        item
    }) => {
        return call({
            actions: {
                update: {
                    "Subscription\\SubscriptionItem": {
                        item: {
                            findBy: {
                                id: item.id
                            },
                            updateRecord: {
                                status: item.status === 'active' ? 'paused' : 'active'
                            }
                        }
                    }
                }
            }
        })
    }

    return {
        INTERVALS: {
            'month': 'monthly',
            'year': 'yearly',
        },
        fetchSeatsInformation,
        fetchSubscriptions,
        fetchInvoices,
        fetchUsageSummaries,
        // getPaymentMethod,
        getAvailableProducts,
        previewProration,
        updatePackage,
        addonSignup,
        toggleSubscriptionItemStatus,
        ...factory
    }
}