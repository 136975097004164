import React from 'react';
import {styleSheetBackgroundColor, styleSheetBorderColor, useGetStringFromValue, styleSheetGetColor} from "./utils";
import DSPText from "./DSPText";
import {Icon} from '@dspworkplace/ui';
const ChangeBox = ({
                       metricChange,
                       metricIcon,
                       metricIconColor,
                       metricChangeBorderColor,
                       metricChangeBackgroundColor,
                       metricChangeTextColor
                   }) => {
    const getValue = useGetStringFromValue();

    let SelectedIcon = Icon[metricIcon] ? Icon[metricIcon] : Icon.Info;
    if (metricIcon && metricIconColor) {
        return (
            <div style={{
                display: 'flex',
                borderWidth: 1,
                borderRadius: 2,
                padding: 4,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 24,
                maxHeight: 24,
                width: 52,
                borderColor: styleSheetBorderColor(metricChangeBorderColor),
                backgroundColor: styleSheetBackgroundColor(metricChangeBackgroundColor)
            }}>
                <SelectedIcon
                    size="16px"
                    color={metricChangeTextColor}
                />
                <DSPText color={styleSheetGetColor(metricChangeBorderColor)}>
                    {getValue(metricChange)}
                </DSPText>
            </div>
        );
    }

    return null;
}

export default ChangeBox;
