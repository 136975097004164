export function mergeDeep(...objects) {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}

export const getColumnAvg = (arr, column) => {
    let arrLength = arr.length;
    let valid = arr.map(v => v[column]).filter(v => v !== null);
    let validArrLength = (valid.length > 0) ? valid.length : arrLength;
    let ret = valid.reduce((all, n) => all + parseFloat(n), 0) / validArrLength;
    if (valid.length === 0)
        return 0;

    if (!isNaN(ret))
        return ret;

    let count = -1;
    let cats = valid.reduce((all, n) => {
        all[n] = all[n] !== undefined ? all[n] + 1 : 1;
        return all;
    }, {});

    return Object.keys(cats).reduce((all, next) => {
        if (count < cats[next]) {
            count = cats[next];
            return next;
        }

        return all;
    }, '');
}

export const getColumnTotal = (arr, column) => {
    let valid = arr.map(v => v[column]).filter(v => v);
    let ret = valid.reduce((all, n) => {
        isNaN(n) ? n = 0 : n = n

        return all + parseFloat(n)
    }, 0);

    if (valid.length === 0)
        return 0;

    if (!isNaN(ret))
        return ret;

    let count = -1;
    let cats = valid.reduce((all, n) => {
        all[n] = all[n] !== undefined ? all[n] + 1 : 1;
        return all;
    }, {});

    return Object.keys(cats).reduce((all, next) => {
        if (count < cats[next]) {
            count = cats[next];
            return next;
        }

        return all;
    }, '');
}