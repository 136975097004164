import React, {useEffect, useRef} from 'react';
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import {fetchPreviousMessages, setMessageForChat} from "./utils";
import {useTrackedState as useBotState, useUpdate as useBotDispatch} from "./BotStore";
import Loading, {useLoading} from "./Loading";
import {getChatIdentifier} from "../Auth";
import BotTaskMessages from "./BotTaskMessages";
// @ts-ignore
import {devLog} from "../utils";

const Scroll = styled(ScrollToBottom)`
    height: 500px;
    visibility: ${props => (props.visibility ? props.visibility : "visible")};;
`;

type Props = {
    visibility: string;
}

function BotTaskList({visibility = "visible"}: Props) {
    const dispatch = useBotDispatch();
    const state = useBotState();
    const identity = getChatIdentifier();
    const {channel, messages, isReviewing} = state;
    const {loading, setLoading} = useLoading();
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) return;
        if (!mounted.current && messages.length > 0) {
            mounted.current = true;
            setLoading(false);
            return;
        }
        if (messages.length > 0) return;
        if (channel) {
            mounted.current = true;
            fetchPreviousMessages(channel, 30)
                .then(({messages, hasPrevPage, lastIndex, previousIndex}) => {
                    dispatch({
                        type: "SET_INITIAL_MESSAGES",
                        messages: messages,
                        previousIndex: previousIndex,
                        hasPreviousPage: hasPrevPage,
                        lastIndex: lastIndex
                    });
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    console.error({fetchPreviousMessages: error});
                });
        }
    }, [channel, dispatch, setLoading, messages.length]);

    // useEffect(() => {
    //     if (isReviewing) return;
    //     if (!isReviewing && messages.length > 0) {
    //
    //     }
    // }, [isReviewing, messages.length]);

    useEffect(() => {
        const abortController = new AbortController();
        if (channel) {
            channel.on("messageAdded", async message => {
                let newMessage = await setMessageForChat(message);
                dispatch({type: "MESSAGE_ADDED", message: newMessage});
            });

            channel.on("messageUpdated", async ({message}) => {
                if (message.sid && message.attributes["archived"] === true) {
                    dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
                } else {
                    let updatedMessage = await setMessageForChat(message);
                    dispatch({type: "MESSAGE_UPDATED", message: updatedMessage});
                }
            });

            channel.on("messageRemoved", async message => {
                if (message.state.sid) {
                    dispatch({type: "MESSAGE_REMOVED", sid: message.state.sid});
                }
            });
        }
        return () => {
            abortController.abort();
        };
    }, [dispatch, channel]);

    if (loading) {
        return <Loading containerHeight={'500px'}/>
    }

    if (isReviewing) return null;

    return (
        <Scroll visibility={visibility}>
            <BotTaskMessages identity={identity!}/>
        </Scroll>
    );
}

export default BotTaskList;