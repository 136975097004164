import React from "react";
import {ChartComponent} from "../index";
import {
    Interval,
    Tooltip,
    Coordinate,
    Legend
} from 'bizcharts'
import {IIntervalGemoProps} from "bizcharts/lib/geometry/Interval";
import {AxisHelper} from "../common";

type ChartBarDirectionType = 'Horizontal' | 'Vertical';

export interface ChartBar extends ChartComponent {
    groupBy?: string
    stackBy?: string,
    direction?: ChartBarDirectionType
}

type IntervalProps = Omit<IIntervalGemoProps,"position">

export const Bar: React.FC<ChartBar> = (props) => {
    const {
        y,
        x,
        tooltip = true,
        groupBy,
        stackBy,
        direction = 'Vertical',
        title,
        data
    } = props

    let extraProps: IntervalProps = {};
    if(groupBy){
        extraProps.color = groupBy
        extraProps.adjust = [{type:'dodge',marginRatio:0}]
    }

    if(stackBy){
        extraProps.color = stackBy
        extraProps.adjust = [{type:'stack'}]
    }
    return (
        <>
            <AxisHelper title={title} x={x} y={y} />
            {direction === 'Horizontal' && <Coordinate transpose />}
            <Tooltip visible={tooltip} />
            <Interval
                position={`${x}*${y}`}
                {...extraProps}
                color={[
                    "colorCode",
                    (colorCode) => {
                        return colorCode;
                    }
                ]}
                tooltip={[
                    'measure*color',
                    (measure, color) => {
                        return { name: color, value: measure }
                    },
                ]}
            />
            <Legend
                itemName={{
                    formatter: (text, item, index) => {
                        try {
                            if (typeof new Date(data?.[index]?.x).getMonth === 'function') {
                                return data?.[index]?.color || text;
                            } else {
                                return data?.[index]?.x || text;
                            }
                        } catch (error) {
                            return data?.[index]?.x || text;
                        }
                    },
                }}
            />
        </>
    )
}