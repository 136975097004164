import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Spacer, Icon, SubTitle,RadioBox } from "@dspworkplace/ui";
import { Tabs, useTabState, usePanelState} from "@bumaga/tabs";
import { TabList } from "../../../../components/Tab";
import ChartRender from '../../components/chartRender';
import TitleInput from './TitleInput';
import ConfigPanel from './ConfigPanel';
import ThresholdsPanel from './ThresholdsPanel';
import ChartTypeSelector from "./ChartType";
import {charts_type} from '../../form/EditChartForm/ChartType'
import Loading from "../../../../components/Loading";
import API from '../../api';
import { revertDateRangeToWeekNames } from '../../fixWeekCondition';
import { showDefaultErrorToast } from '../../../../Utilities';

const ChartContainer = styled.div`
    height: 250px; 
    display: flex; 
    padding: 8px; 
    overflow-y:scroll;
    ${({ type }) => {
        if(type !== 'table') {
            return `
                justify-content: center; 
                align-items: center; 
            `
        }
        return '';
    }}
`;

export function filterCubeData(data, schemaToFilter){
    return data.map((option) => {
            const [schema] = option.name.split('.');
            const isVisible = option?.meta?.isHide;
            return {
                value: option,
                schema,
                isVisible,
                name: option.shortTitle
            };
        })
        .filter(({ schema, isVisible }) => schema.toLowerCase() === schemaToFilter?.toLowerCase() && !isVisible)
        .sort((a,b)=> a.name.localeCompare(b.name));
};

const EditChartForm = ({
    chartConfig,
    defaultTitle,
    onCancel,
    onSubmit,
    query,
    detailQuery,
    loadingState,
    item,
    ...cubeData
}) => {
    let detailQueryObj = query;
    if(detailQuery){
        detailQueryObj = {
            ...query,
            ...detailQuery
        };
    }

    const [title,setTitle] = useState(defaultTitle);
    const [tabIndex,setTabIndex] = useState(0);
    const [cacheQuery,setCacheQuery] = useState({chart:query,detail:detailQuery})
    const [chartConfigData, setChartConfig] = chartConfig
    const [columnOrder, setColumnOrder] = useState({
        tableOrder: chartConfigData?.pivotConfig?.tableOrder || [],
        detailTableOrder: chartConfigData?.pivotConfig?.detailTableOrder || []
    })
    const [showTimeDimension, setShowTimeDimension] = useState({
        basicTimeDimension: chartConfigData?.pivotConfig?.basicTimeDimension || [],
        detailTimeDimension: chartConfigData?.pivotConfig?.detailTimeDimension || []
    })
    const [thresholds, setThresholds] = useState(chartConfigData?.pivotConfig?.thresholds || []);
    const [attendanceSetting, setAttendanceSetting] = useState([]);

    const measuresList = useMemo(() => {
        return filterCubeData(cubeData.availableMeasures, chartConfigData.schema);
    },[cubeData.availableMeasures, chartConfigData.schema]);

    const dimensionsList = useMemo(() => {
        return filterCubeData(cubeData.availableDimensions, chartConfigData.schema);
    },[cubeData.availableDimensions, chartConfigData.schema]);

    const chartRules = useMemo(()=>{
        return charts_type.find((t)=> t.id === chartConfigData.type)
    },[chartConfigData.type])

    const handleSubmit = async () => {
        if(cubeData.pivotConfig){
            if(cubeData.pivotConfig.limit){
                delete cubeData.pivotConfig.limit;
            }
            chartConfigData.pivotConfig = cubeData.pivotConfig;
        }
        let chartQuery = tabIndex === 1 ? query : cacheQuery.chart;
        let chartDetailQuery = tabIndex === 2 ? query : cacheQuery.detail;
        if (
            !chartQuery?.filters?.length ||
            !chartQuery?.filters?.some(f => (f.member === `${chartConfigData.schema}.station`) && f?.values?.length > 0)) {
            await showDefaultErrorToast({ message: 'Please select atleast one station filter in Config' });
            return;
        }
            
        if (!cubeData.pivotConfig) {
            cubeData.pivotConfig = {};
        }

        if(chartQuery?.timeDimensions?.length > 0){
            chartQuery.timeDimensions = chartQuery?.timeDimensions.map(element => {
                const revertedValue = revertDateRangeToWeekNames(element.dateRange)
                if(revertedValue === 'Custom'){
                    return element;
                }
                element.dateRange = revertedValue;
                return element
            });
        }
        if(chartDetailQuery?.timeDimensions?.length > 0){
            chartDetailQuery.timeDimensions = chartDetailQuery?.timeDimensions.map(element => {
                const revertedValue = revertDateRangeToWeekNames(element.dateRange)
                if(revertedValue === 'Custom'){
                    return element;
                }
                element.dateRange = revertedValue;
                return element
            });
        }

        onSubmit({
            title,
            chartQuery: chartQuery,
            chartDetailQuery: chartDetailQuery,
            chartConfig: {
                ...chartConfigData,
                pivotConfig: {
                    ...chartConfigData?.pivotConfig,
                    ...cubeData.pivotConfig,
                    tableOrder: columnOrder?.tableOrder,
                    detailTableOrder: columnOrder?.detailTableOrder,
                    basicTimeDimension: showTimeDimension?.basicTimeDimension,
                    detailTimeDimension: showTimeDimension?.detailTimeDimension,
                    thresholds: thresholds
                }
            },
            ...item?.isScorecardAMZ && {
                isScorecardAMZ: item?.isScorecardAMZ
            }
        });
    };

    useEffect(() => {
        if(!query.limit){
            let internalLimit = 100;
            if (tabIndex === 2) {
                internalLimit = 200;
            }
            cubeData.updatePivotConfig.update({ limit: internalLimit, fillMissingDates: false });
        }
    },[tabIndex])

    useEffect(() => {
        if (columnOrder?.tableOrder?.length <= 0 && columnOrder?.detailTableOrder?.length <= 0) {
            setColumnOrder({
                tableOrder: chartConfigData?.pivotConfig?.tableOrder,
                detailTableOrder: chartConfigData?.pivotConfig?.detailTableOrder
            })
        }
        if (showTimeDimension?.basicTimeDimension?.length <= 0 && showTimeDimension?.detailTimeDimension?.length <= 0) {
            setColumnOrder({
                basicTimeDimension: chartConfigData?.pivotConfig?.basicTimeDimension,
                detailTimeDimension: chartConfigData?.pivotConfig?.detailTimeDimension
            })
        }
        if (thresholds?.length <= 0) {
            setThresholds(chartConfigData?.pivotConfig?.thresholds || [])
        }
    }, [chartConfigData])

    useEffect(() => {
        if(chartConfigData?.schema === 'Employee'){
            API.getAttendanceSetting().then(
                resolve => {
                    setAttendanceSetting(resolve.data.data.attendanceSettings);
                },
                error => {

                }
            );
        }
    }, [chartConfigData?.schema]);

    return (
        <>
            <header style={{display:'flex',justifyContent:'space-between'}}>
                <TitleInput
                    value={title}
                    onChange={setTitle}
                />
                <Button type="cancel" onClick={onCancel}>
                    Cancel
                </Button>
            </header>
            <ChartContainer type={tabIndex === 2 ? (loadingState.isLoading ? 'chart' : 'table') : chartConfigData?.type }>
                {loadingState.isLoading && cubeData.isQueryPresent ?
                        <Loading style={{height:50,margin:'0 auto'}} />
                    :
                    cubeData.resultSet ?
                        (tabIndex === 2 ?
                                <ChartRender
                                    chartConfigData={{...chartConfigData,type:'table'}}
                                    resultSet={cubeData?.resultSet}
                                    pivotConfig={cubeData?.pivotConfig}
                                    query={query}
                                    cubeData={cubeData}
                                    cacheQuery={cacheQuery}
                                    setCacheQuery={setCacheQuery}
                                    columnOrder={columnOrder}
                                    setColumnOrder={setColumnOrder}
                                    availableTimeDimensions={cubeData?.availableTimeDimensions}
                                    updateQuery={cubeData.updateQuery} 
                                    tabIndex={tabIndex}
                                    showTimeDimension={showTimeDimension}
                                    item={item}
                                    attendanceSetting={attendanceSetting}
                                    thresholds={thresholds || []}
                                />
                                :
                                <ChartRender
                                    chartConfigData={chartConfigData}
                                    resultSet={cubeData?.resultSet}
                                    pivotConfig={cubeData?.pivotConfig}
                                    query={query}
                                    cubeData={cubeData}
                                    cacheQuery={cacheQuery}
                                    setCacheQuery={setCacheQuery}
                                    columnOrder={columnOrder}
                                    setColumnOrder={setColumnOrder}
                                    availableTimeDimensions={cubeData?.availableTimeDimensions}
                                    updateQuery={cubeData.updateQuery} 
                                    tabIndex={tabIndex}
                                    showTimeDimension={showTimeDimension}
                                    item={item}
                                    attendanceSetting={attendanceSetting}
                                    thresholds={thresholds || []}
                                />
                                )
                     :
                    <SubTitle>Widget Preview</SubTitle>
                }
            </ChartContainer>
            <Tabs state={[tabIndex,setTabIndex]}>
                <TabList>
                    <Tab>Widget Type</Tab>
                    <Tab>Config</Tab>
                    <Tab>Detail Config</Tab>
                    <Tab>Thresholds</Tab>
                </TabList>
                <Panel id={'chart'} setCacheQuery={setCacheQuery} query={query} updateQuery={cubeData.updateQuery} cacheQuery={cacheQuery.chart}>
                    <Spacer top={4}>
                        <ChartTypeSelector
                            itemsPerLine={4}
                            chartConfig={chartConfig}
                            afterSetType={()=>{
                                setTabIndex(1)
                            }}
                        />
                    </Spacer>
                </Panel>
                <Panel id={'chart'} setCacheQuery={setCacheQuery} query={query} updateQuery={cubeData.updateQuery} cacheQuery={cacheQuery.chart}>
                    <ConfigPanel
                        {...cubeData}
                        query={query}
                        measuresList={measuresList}
                        dimensionsList={dimensionsList}
                        limit={100}
                        chartConfig={chartConfig}
                        chartRules={chartRules}
                        setCacheQuery={setCacheQuery}
                        columnOrder={columnOrder}
                        setColumnOrder={setColumnOrder}
                        tabIndex={tabIndex}
                        showTimeDimension={showTimeDimension}
                        setShowTimeDimension={setShowTimeDimension}
                        attendanceSetting={attendanceSetting}
                    />
                </Panel>
                <Panel id='detail' setCacheQuery={setCacheQuery} query={query} updateQuery={cubeData.updateQuery} cacheQuery={cacheQuery.detail}>
                    <ConfigPanel
                        {...cubeData}
                        query={detailQueryObj}
                        measuresList={measuresList}
                        dimensionsList={dimensionsList}
                        limit={400}
                        chartConfig={chartConfig}
                        withFilter={false}
                        setCacheQuery={setCacheQuery}
                        columnOrder={columnOrder}
                        setColumnOrder={setColumnOrder}
                        tabIndex={tabIndex}
                        showTimeDimension={showTimeDimension}
                        setShowTimeDimension={setShowTimeDimension}
                        attendanceSetting={attendanceSetting}
                    />
                </Panel>
                <Panel id='thresholds' setCacheQuery={setCacheQuery} query={query} updateQuery={cubeData.updateQuery} cacheQuery={cacheQuery.detail}>
                    <ThresholdsPanel
                        {...cubeData}
                        measuresList={measuresList}
                        dimensionsList={dimensionsList}
                        chartConfig={chartConfig}
                        query={detailQueryObj}
                        thresholds={thresholds} 
                        setThresholds={setThresholds}
                    />
                </Panel>
            </Tabs>

            <Spacer top={5} style={{display:'flex',justifyContent:'flex-end'}}>
                <Button
                    type={'primary'}
                    Type={'submit'}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Spacer>
        </>
    );
}

const Panel = ({ children,id,setCacheQuery,query,updateQuery,cacheQuery }) => {
    const isActive = usePanelState();
    const firstLoad = React.useRef(true)

    useEffect(()=>{
        if(firstLoad.current){
            firstLoad.current = false;
        }else{
            if(setCacheQuery){
                if(!isActive){
                    setCacheQuery(prevState => {
                        let newQuery = {};
                        newQuery[id] = query;
                        return {...prevState,...newQuery}
                    })
                }else{
                    updateQuery(cacheQuery)
                }
            }

        }
    },[isActive])

    return isActive ? <div>{children}</div> : <div style={{ display: "none" }}>{children}</div>;
};

const Tab = ({ children }) => {
    const { onClick, isActive } = useTabState();
    return (
        <h6 onClick={onClick} className={[isActive && "tabActive"]}>
            {children}
        </h6>
    );
};

export default EditChartForm;