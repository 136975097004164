import React, {useEffect, useRef} from "react";
import styled from 'styled-components';
import {Theme} from '@dspworkplace/ui';

const TooltipStyle = styled.div`
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}26;
    padding: 20px;
    position: fixed;
    z-index: 2;
    pointer-events: ${props => props.event == 'click' ? 'auto' : 'none' };
    clip: rect(0,0,0,0);
`;

const TooltipStyleArrow = styled.span`
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${Theme.colors.neutrals.white} transparent transparent transparent;
    margin-left: -8px;
    
    ${props => props.v === 'bottom' ? `
    transform: rotate(180deg);
    top: auto;
    bottom: 100%;
    ` : ''}
    
    ${props => props.h === 'left' ? `
    transform: rotate(-90deg);
    top: calc(50% - 4px);
    left: calc(100% + 4px);
    ` : ''}
    
    ${props => props.h === 'right' ? `
    transform: rotate(90deg);
    top: calc(50% - 4px);
    left: auto
    right: calc(100% - 4px);
    ` : ''}
    
    ${props => props.v === 'center' && props.h === 'center' ? `
    display: none;
    ` : ''}
`;

const TooltipWrapper = styled.div`
    display: inline-block;
`;

const px = n => `${n}px`;

const Tooltip = ({
    verticalPosition = 'top',
    horizontalPosition = 'center',
    offset = 12,
    trigger = 'hover',
    content,
    children,
    ...props
}) => {
    const parent = useRef();
    const ref = useRef();

    const show = () => ref.current.style.clip = 'auto';
    const hide = () => ref.current.style.clip = 'rect(0,0,0,0)';

    const addEvents = () => {
        switch (trigger) {
            case 'click':
                parent.current.addEventListener('click', () => {
                    show()
                    document.addEventListener('click', (e)=>{
                        e.stopPropagation();
                        if (!parent.current.contains(e.target) ){
                            hide()
                        }
                    });
                });
            break;
            case 'hover':
            default:
                parent.current.addEventListener('mouseenter', () => {
                    show()
                    parent.current.addEventListener('mouseleave', hide);
                });
                break;
        }

        window.addEventListener('scroll', setPosition, true);
        window.addEventListener('resize', setPosition);
    };

    const removeEvents = () => {
        if (parent.current) {
            parent.current.removeEventListener('mouseenter', show);
            parent.current.removeEventListener('click', show);

            parent.current.removeEventListener('mouseleave', hide);
        }

        window.removeEventListener('scroll', setPosition);
        window.removeEventListener('resize', setPosition);
    };

    const setPosition = () => {
        let rect;
        try {
            rect = parent.current.getBoundingClientRect();
        } catch (e) {
            return;
        }

        const w = ref.current.offsetWidth;
        const h = ref.current.offsetHeight;

        let top, left;

        switch (verticalPosition) {
            case 'bottom':
                top = px(rect.bottom + offset);
                break;
            case 'center':
                top = px(rect.top - h / 2);
                break;
            case 'top':
            default:
                top = px(rect.top - h - offset);
                break;
        }

        switch (horizontalPosition) {
            case 'right':
                left = px(rect.right + offset);
                break;
            case 'left':
                left = px(rect.left - w - offset);
                break;
            case 'center':
            default:
                left = px(rect.left + rect.width / 2 - w / 2);
                break;
        }

        ref.current.style.top = top;
        ref.current.style.left = left;
    };

    useEffect(() => {
        try {
            parent.current.getBoundingClientRect();
        } catch (e) {
            return ()=>{};
        }

        addEvents();
        setPosition();

        return removeEvents;
    }, [parent, ref, verticalPosition, horizontalPosition, content, children]);

    if (!content)
        return children;

    return (
        <TooltipWrapper ref={parent}>
            <TooltipStyle ref={ref} event={trigger}>
                <TooltipStyleArrow v={verticalPosition} h={horizontalPosition}/>
                {content}
            </TooltipStyle>
            {children}
        </TooltipWrapper>
    );
};

export default Tooltip;