import React, {useContext, useEffect, useState} from "react";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import {confirm} from "../../../components/Confirm";
import Empty from "../../../components/Empty";
import {Icon,Button, Spacer, ThirdTitle, TagInput,Input, Text, Title, Dropdown, CheckBox} from "@dspworkplace/ui";
import Store, {WorkFlowProvider} from "./Context";
import styled from "styled-components";
import {getCompanyId, useAuth} from "../../../Auth";
import {fetchAllWorkflowTriggers,removeWorkflowTrigger,getAlldriverFromCompany,getWorkflowTriggers,SaveWorkflow,getWorkflowActions} from "./../Forms/api";
import {useForm} from "react-hook-form";
import {dialog, dialogPromise, loadingDialog} from "../../../components/Dialog";
import Modal from '../../../components/Modal';
import toast from "../../../components/Toasts/toast";
import {showErrorToast} from "../../../Utilities";

const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1060px;
`;

const Trigger = styled.div`
    padding: 8px 16px;
    color: #0071BC;
    border-bottom: 1px solid #EAF0F6;
    margin-top: 4px;
`;

const TriggerWrapper = styled.div`
    border: 1px solid #EAF0F6   ;
    margin-top: 4px;
    position:relative;
`;

const TriggerTitle = styled.div`
    padding: 5px;
    margin-top: 4px;
    display:flex;
    margin: 0 40px;
`;

const ActionTitle = styled.div`
    padding: 5px;
    margin-top: 4px;
    display:flex;
    margin: 0 90px;
`;

const ActionDescription = styled.div`
    padding: 5px;
    margin-top: 4px;
    display:flex;
    color:gray;
    margin: 0 90px;
`;


const CloseBtn = styled.div`
    float: right;
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 7px;
    padding: 2px 3px 0;
`;

const EditBtn = styled.div`
    float: right;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 7px;
    padding: 2px 3px 0;
`;

const TrigderLabel = styled.div`
    margin: 5px 25px;
    color: #0071BC;
`;

const ActionLabel = styled.div`
    margin: 5px 60px;
    color: #0071BC;
`;

const TrigerDesc = styled.div`
    margin: 0 45px;
`;

const ActionWrapper = styled.div`
    display:flex;
    position:relative;
`;

const Workflow = () => {
    return (
        <WorkFlowProvider>
            <Wrapper>
                <Spacer top={5} />

                <Title>Workflow</Title>
                <Spacer top={1} />

                <Text>Setup workflow here</Text>
                <Spacer top={5} />
                <WorkFlowList />
            </Wrapper>
        </WorkFlowProvider>
    );
}

const WorkFlowList = () =>  {
    const { api } = useAuth();
    const companyId = getCompanyId();
    const [state, dispatch] = useContext(Store);

    const fetchAllWorkData = async (id,category,sub_category) => {
        dispatch({ type: "LOADING", payload: { status: true } });
        const [result] = await Promise.all([
            fetchAllWorkflowTriggers(api, companyId),
        ]);

        dispatch({ type: "ADD", payload: { data: result, company: companyId} });
        dispatch({ type: "REFRESH"});
    };
    const removeAction = async (id,key) => {
        let conf = await confirm({
            icon: true,
            text: 'Are you sure to delete this workflow ?',
        });

        if(conf){
            state.lists.splice(key, 1);
            dispatch({ type: "REFRESH"});
            removeWorkflowTrigger(api, id);
        }
        toast({
            type: 'success',
            title: 'Workflow successfully deleted',
            useClose:false,
            timeout: true
        });

    }

    const addAction = async (data,key) => {
        dispatch({ type: "MODAL_VISIBLE", payload: true });
        dispatch({ type: "REFRESH"});
        return dialogPromise((success, cancel) =>
            <AddActions
                api={api}
                data={data}
                dataKey={key}
                cancel={cancel}
                dispatch={dispatch}
                state={state}
            />
        )
    }

    useEffect(() => {
        fetchAllWorkData();
    },[]);

    return(<>
            {!state.isLoading && state.lists.length < 1 && <Empty />}
            {!state.isLoading &&
                    state.lists.length > 0 &&
                    state.lists.map((data, key) =>
                    <TriggerWrapper>
                        <EditBtn  onClick={() => { addAction(data,key); }}><Icon.Edit size='20px' color='#0071BC'/></EditBtn>
                        <CloseBtn  onClick={() => { removeAction(data.id,key); }}><Icon.Times size='20px' color='#FF0000'/></CloseBtn>
                        <Trigger>{data.category}  { data.sub_category !='' && " - "+ data.sub_category +" "  }{ data.tokenName !='' && " ( "+ data.tokenName +" )"  } </Trigger>
                        <TrigderLabel>IF</TrigderLabel>
                            <TriggerTitle>{data.triggerName} </TriggerTitle>
                             <TrigerDesc>
                                <Spacer inline={true}>
                                    <Text>{data.description}</Text>
                                </Spacer>
                            </TrigerDesc>

                            { data.actions.map((ac, key) =><>
                                <ActionLabel>{ key == 0 ? 'THEN' : 'AND' }</ActionLabel>
                                <ActionTitle>{ac.actionName} </ActionTitle>
                                <ActionDescription>{ac.description} </ActionDescription>
                        </>)}
                        <Trigger></Trigger>
                    </TriggerWrapper> )}
    </>);
}
const AddActions = ({ api,data,dataKey, cancel,dispatch,state }) => {
    const [trigger, setTrigger] = useState();
    const [drivers, setDrivers] = useState([]);
    const [modalVisible, setModalVisible] = useState(true);
    const [triggersLists, setTriggerLists] = useState([]);
    const [actionssLists, setActionsLists] = useState([]);
    const [addAction, setAddAction] = useState([]);
    const [addActionCount, setAddActionCount] = useState(1);
    const companyId = getCompanyId();
    const {register, unregister, errors, getValues, handleSubmit, reset} = useForm({mode: "onChange"});

    const addActionMethod = async () => {
        let action = {'id':'new_'+(addActionCount),'data':[],'name':[],'trigger':'','isDriver':false, 'isDriverLicenceExpiration':false };
        setAddActionCount(addActionCount+1);
        setAddAction([...addAction,action]);
    }

    const refreshSelectedActions = () =>{
        let tempAddedActionIds = addAction.map((i)=> i.defaultValue);
        let acArray = actionssLists.map((item,itemKey)=>{
            return (tempAddedActionIds.includes(item.value))?{'name':item.name,'value':item.value,disable:true}:{'name':item.name,'value':item.value};
        });
        setActionsLists(acArray);
    }

    function removeAction(id,key) {
        addAction.splice(key, 1);
        setAddAction([...addAction]);
        // let fieldId = id+'_q';
        // reset({
        //     fieldId:null
        // });
    }

    const getAllDrivers = async () => {

        const results = await Promise.all([
            getAlldriverFromCompany(api, companyId),
        ]);

        // setDrivers(results[0]);

        
        let action = [];
        data.actions.map((i) => {
            if(data.category !='Incident' && (i.action == 'send_bot_message_to_station_manager' || i.action == 'send_bot_message_to_driver')){
              action.push({'id':i.id,'defaultDataVal':i.data,'name':i.name,'defaultValue':i.action,'isDriverLicenceExpiration':true});
            }else{
              action.push({'id':i.id,'defaultdriverVal':i.data,'name':i.name,'defaultValue':i.action,'isDriver':i.data.length > 0 ? true : false });
            }
        });
        setAddAction(action);
        let tempDriverArr = [];
        results[0].forEach(function(item){
            tempDriverArr.push({value:item.id,name:item.friendlyName});
        });
        setDrivers(tempDriverArr);
    };

    const getAllTriggers = async () => {
        const results = await getWorkflowTriggers(api, data.category_id,data.entity ? data.entity : 'null',data.ignoreActions);
        let tempDriverArr = [];
        results.forEach(function(item){
            tempDriverArr.push({value:item.value,name:item.name});
        });
        setTriggerLists(tempDriverArr);

    };

    const getActionsFromTrigger = async (val) => {
        const results = await getWorkflowActions(api, data.category_id,val);
        let tempDriverArr = [];
        let defaultValues = []
        await data.actions.map((i) => {
            defaultValues.push(i.action);
        });
        results.forEach(function(item){
            defaultValues.indexOf(item.value) > -1 ? tempDriverArr.push({value:item.value,name:item.name,disable:true}) : tempDriverArr.push({value:item.value,name:item.name}) ;
        });

        setActionsLists(tempDriverArr);
    }

    function setDriverDropdown(val,key){
        addAction[key]={
            ...addAction[key],
            defaultdriverVal:val.map((v)=>v.value)
            }
            setAddAction([...addAction]);
    }

    function setDriverDropdownStatus(val,key){
            addAction[key]={
            ...addAction[key],
            defaultValue:val,
            isDriver:false,
            isDriverLicenceExpiration:false
            }
            setAddAction([...addAction]);
            refreshSelectedActions();

            if( val == 'send_mail_to_selected_user' || val == 'send_bot_message_to_selected_user' ){
                    addAction[key]={
                        ...addAction[key],
                        isDriver:true
                    }
                    setAddAction([...addAction]);
            }

            if( data.category !='Incident' && (val == 'send_bot_message_to_driver' || val == 'send_bot_message_to_station_manager' )){
                    addAction[key]={
                        ...addAction[key],
                        isDriverLicenceExpiration:true,
                        defaultDataVal:{
                          expirationTypes:[]
                        }
                    }
                    setAddAction([...addAction]);
            }
    }


    const onSubmit = async formData => {
        let category = {'category':data.category_id,'sub_category':data.sub_category_id};
        let response = await SaveWorkflow(api, formData,category,addAction.length,data.token,companyId,data.id);
        if(response){
            state.lists[dataKey] = {
                ...state.lists[dataKey],
                triggerName:response.triggerName,
                description: response.description,
                actions:response.actions
            };
        }
        state.modalVisible = false;
        toast({
            type: 'success',
            title: 'Workflow successfully updated',
            useClose:false,
            timeout: true
        });
        dispatch({ type: "REFRESH"});
        setModalVisible(false);
    }

    const expirationCheckboxCheckEvent =(name, key)=>{
      let defaultData = (addAction[key].defaultDataVal)?addAction[key].defaultDataVal:[];

      if(defaultData.expirationTypes.includes(name)){
        defaultData.expirationTypes.splice(defaultData.expirationTypes.indexOf(name),1);
      }else{
        defaultData.expirationTypes.push(name);
      }

      addAction[key]={
        ...addAction[key],
        defaultDataVal:defaultData,
      }
      setAddAction([...addAction]);
    }

    const expirationDayDropdown = (type, value, key) =>{
        try{
            let defaultData = addAction[key].defaultDataVal;
            defaultData[type] = value;
            addAction[key]={
                ...addAction[key],
                defaultDataVal:defaultData,
            }
            setAddAction([...addAction]);
        }catch (e){
            showErrorToast(e, 'Issue on expiration day dropdown.').then();
        }
    }

    useEffect(() => {
        getAllTriggers();
        getActionsFromTrigger(data.trigger);
        getAllDrivers();
    },[]);

    return(<>
            <Modal
                title={'Create Workflow'}
                visible={state.modalVisible}
                setVisible={cancel}
                closeOnOverlay={false}
                width={'600px'}
                >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <ThirdTitle>IF</ThirdTitle>
                    <Dropdown
                        style={{width: 555}}
                        name="trigger"
                        label="Trigger"
                        ref={register({required: 'Please Select Trigger'})}
                        error={errors.trigger && errors.trigger.message}
                        options={triggersLists}
                        defaultValue={data.trigger}
                        Required={false}
                        onChange={(e) => setTimeout(()=> getActionsFromTrigger(e.value),1)}
                    />
                    <Spacer top={5} />
                    { addAction.length > 0 &&
                        addAction.map((ac, key) => <>
                            <ActionWrapper key={key}>
                                <ThirdTitle>{ key == 0 ? 'THEN' : 'AND'} </ThirdTitle>
                                <CloseBtn key={key} onClick={() => { removeAction(ac.id,key); unregister(`${ac.id}_q`); unregister(`${ac.id}_a`); }}><Icon.Times size='20px' color='red'/></CloseBtn>
                                <Spacer inline={true} bottom={5} top={5} >
                                  <Dropdown
                                      style={{width: 515}}
                                      name={`${ac.id}_q`}
                                      label="Action"
                                      key={key}
                                      ref={register}
                                      options={actionssLists}
                                      defaultValue={ac.defaultValue}
                                      Required={false}
                                      onChange={(e) => setTimeout(()=> setDriverDropdownStatus(e.value,key),1)}
                                      error={errors && errors[`${ac.id}_a`] && 'Please fill the all require field In this action'}
                                  />
                                  { ac.isDriver && <TagInput
                                      ref={register}
                                      name={`${ac.id}_a`}
                                      key={`${ac.id}_a`}
                                      label='Drivers'
                                      style={{width: 515}}
                                      defaultValues={ac.defaultdriverVal}
                                      onChange={(e) => setTimeout(()=> setDriverDropdown(e,key),1)}
                                      options={drivers}
                                  />}
                                { ac.isDriverLicenceExpiration && <>
                                  <Spacer inline={true} bottom={5} top={5} >
                                    <CheckBox
                                      name={`${ac.id}_a['expirationTypes']`}
                                      title={'Expiration Types'}
                                      ref={register({required: false})}
                                      defaultValues={(ac.defaultDataVal && ac.defaultDataVal.expirationTypes)?ac.defaultDataVal.expirationTypes:[]}
                                      onClick={(e)=>{expirationCheckboxCheckEvent(e.target.value, key)}}
                                      options={[
                                          {value:'same_day',label:'Notification same day'},
                                          {value:'after_day',label:'Notification afte day'},
                                          {value:'before_day',label:'Notification before day'},
                                      ]}
                                    />
                                  </Spacer>
                                  { ac.defaultDataVal.expirationTypes && ac.defaultDataVal.expirationTypes.includes('after_day')  &&
                                    <Spacer inline={true} bottom={5} top={5} >
                                      <Dropdown
                                        style={{width: 515}}
                                        label={'After Days'}
                                        name={`${ac.id}_a['after_day']`}
                                        placeholder="Select After Days"
                                        defaultValue={ac.defaultDataVal && ac.defaultDataVal.after_day}
                                        key={`${ac.id}_q`}
                                        ref={register({required: true})}
                                        options={Array.from({length: 60}, (_, index) => {
                                          return {value:index + 1,name:index + 1};
                                        })}
                                        Required={true}
                                        onChange={(e)=>{expirationDayDropdown('after_day',e.value, key)}}
                                      />
                                    </Spacer>
                                  }
                                  { ac.defaultDataVal.expirationTypes && ac.defaultDataVal.expirationTypes.includes('before_day') &&
                                    <Spacer inline={true} bottom={5} top={5} >
                                      <Dropdown
                                        style={{width: 515}}
                                        label={'Before Days'}
                                        name={`${ac.id}_a['before_day']`}
                                        placeholder="Select Before Days"
                                        defaultValue={ac.defaultDataVal && ac.defaultDataVal.before_day}
                                        key={`${ac.id}_q`}
                                        ref={register({required: true})}
                                        options={Array.from({length: 60}, (_, index) => {
                                          return {value:index + 1,name:index + 1};
                                        })}
                                        Required={true}
                                        onChange={(e)=>{expirationDayDropdown('before_day',e.value, key)}}
                                      />
                                    </Spacer>
                                  }
                                </>}
                                </Spacer>
                            </ActionWrapper>
                        </>)}
                    <Spacer top={2} />
                    {actionssLists.length > 0 &&<Button
                        size={'small'}
                        Type={'button'}
                        onClick={()=>{ addActionMethod() }}>Add Action</Button> }

                    <Spacer top={2}/>
                        <Input
                            name="description"
                            size="big"
                            label="Description"
                            ref={register}
                            as="textarea"
                            defaultValue={data.description}
                            style={{width: 555, height: 80}}
                        />
                    <Spacer top={5}/>
                    <Button type={"primary"}  style={{marginLeft: "auto", display: "block", width: 80}}>
                        Save
                    </Button>
                </form>
            </Modal>
        </>
    )
}
export default Workflow;
