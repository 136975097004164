

const sorter = (array,field,direction=1) => {
    //0 DESC
    //1 ASC
    return array.sort((a, b) => {
        if (direction === 0) return (a[field] > b[field]) - (a[field] < b[field]);
        return (b[field] > a[field]) - (b[field] < a[field]);
    });
};

export default sorter;
