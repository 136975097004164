import {upload} from "../../Auth";


export async function getObjectURL(data){
    try {
        let formData = new FormData();
        formData.append('image', data.profile[0]);
        formData.append('name', data.profile[0].name);
        let result = await upload(formData).catch(err => {
            console.log({err})
        });
        return result.data.ObjectURL;
    }catch (e) {
        console.log({e})
    }

}