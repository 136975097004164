import { useReducer } from "react";
import { createContainer } from "react-tracked";
import { MessageProps } from "stream-chat-react";

type NewChannelInformation = {
    name: string | null | undefined;
    description: string | null | undefined;
    image: string | null | undefined;
    errorMessage: string;
};

type State = {
    channel?: NewChannelInformation;
    loading: boolean;
    messages: Array<MessageProps>,
    files: Array<any>,
    openFileModal: boolean;
    openChannelSettings: boolean;
    previousIndex: number;
    lastIndex: number;
    hasPreviousPage: boolean;
    init: boolean;
    messageKind: string;
    message?: MessageProps | null;
    isReviewing: boolean;
    showSummaryModal: boolean;
};

type Action =
    | { type: "SET_UP"; channel: NewChannelInformation; loading: boolean }
    | { type: "REVIEW_TASK"; message: MessageProps }
    | { type: "GO_BACK"; }
    | { type: "SHOW_SUMMARY_MODAL" }
    | { type: "CLOSE_SUMMARY_MODAL" }
    | { type: "SET_INIT"; init: boolean; }
    | { type: "SET_IS_REVIEWING"; isReviewing: boolean; }
    | { type: "MESSAGE_ADDED"; message: MessageProps; }
    | { type: "MESSAGE_UPDATED"; message: MessageProps; }
    | { type: "MESSAGE_REMOVED"; sid: string; }
    | { type: "PREPEND_MESSAGES"; messages: Array<MessageProps>, previousIndex: number, hasPreviousPage: boolean; }
    | { type: "SET_INITIAL_MESSAGES"; messages: Array<MessageProps>, previousIndex: number, hasPreviousPage: boolean; lastIndex: number }
    ;

const initialChannelState: State = {
    files: [],
    init: false,
    messages: [],
    openChannelSettings: false,
    openFileModal: false,
    loading: true,
    hasPreviousPage: false,
    previousIndex: 0,
    lastIndex: 0,
    messageKind: "",
    isReviewing: false,
    showSummaryModal: false,
    message: null
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "REVIEW_TASK":
            return { ...state, isReviewing: true, message: action.message };
        case "MESSAGE_REMOVED":
            return { ...state, messages: state.messages.filter(item => item.sid !== action.sid) };
        case "MESSAGE_ADDED":
            let newMessage = action.message;
            if (state.messages.length - 1 >= 0 && state.messages[state.messages.length - 1].sid === newMessage.sid) {
                return state;
            }
            return { ...state, messages: [...state.messages, newMessage] };
        default:
            return state;
    }
}

const useValue = () => useReducer(reducer, initialChannelState);

export const { Provider, useTrackedState, useUpdate, useTracked, useSelector } = createContainer(useValue);