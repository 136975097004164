import React,{useState, useEffect} from 'react';
import {Button, Input, Spacer, Icon} from "@dspworkplace/ui";

import Modal from "../../components/Modal";
import {useForm} from "react-hook-form";
import API from "../SchedulerLoadOut/api";
import toast from "../../components/Toasts/toast";
import {useAuth} from "../../Auth";
import {AddTable, Table, TableData, OrderIco} from "../../components/Table";

const App = ({driver, setVisible}) => {
    const { register, handleSubmit, errors,getValues } = useForm();
    const { api } = useAuth();
    const [driverAlias, setDriverAlias] = useState({update:-1,data:[]});
    const onSubmit = async (data, key, editData) => {
        if(key>=0){
            console.log(data, key, editData);
            let params = {
                "actions": {
                    "response": {
                        "DriverAlias": {
                            "custom": {
                                "functionName": "updateDriverAlias",
                                "get": "result",
                                "criteria": {
                                    "id": editData.id,
                                    "company": localStorage.getItem("company"),
                                    "name": data.driver_alias,
                                }
                            }
                        }
                    }
                }
            }
            await api.post('/api/lazy/manage/data', params);
            let copy = [...driverAlias.data];
            copy[key] = {id:editData.id,name:data.driver_alias};
            setDriverAlias({data:copy});
        } else {
            let params = {
                "actions": {
                    "create": {
                        "DriverAlias": {
                            "DriverAlias_1": {
                                "user": driver.userId,
                                "name": data.driver_alias,
                                "driver": driver.id,
                                "company": localStorage.getItem("company")
                            }
                        }
                    }
                }
            }
            const response = await api.post('/api/lazy/manage/data', params);
            setDriverAlias({data:[...driverAlias.data,{id:response.data.data.driverAliasId,name:data.driver_alias}]});
        }    
    }

    const onFormSaveSubmit = async (data) => {
       console.log(data);
    }

    const handleDelete = async(key,data) => {
        console.log(key,data);
        const confirmation = window.confirm('Confirm that you want to delete this item');
        if(!confirmation){
            return false;
        }
        let params = {
            "actions": {
                "delete": {
                    "DriverAlias": {
                        "DriverAlias_1": {
                            "findOneBy": {
                                "id": data.id
                            },
                            "isHardDelete": true
                        }
                    }
                },
            }
        };
        await api.post('/api/lazy/manage/data', params);
        driverAlias.data.splice(key,1);
        setDriverAlias({...driverAlias,data:driverAlias.data});
    }

    const getDriverAlias = async () => {
        const driverAliasParam ={
            "actions": {
                "response": {
                    "DriverAlias": {
                        "custom": {
                            "functionName": "getDriverAlias",
                            "get": "result",
                            "criteria": {
                                "driver": driver.id,
                                "company": localStorage.getItem("company")
                            }
                        }
                    }
                }
            }
        }
        const response = await api.post('/api/lazy/manage/data', driverAliasParam);
        let driverAliasResult = response.data.data.result;
        setDriverAlias({data:driverAliasResult});
        console.log(driverAliasResult,driverAlias);
    }

    useEffect(()=>{
        getDriverAlias()
    },[])
    return (
        <Modal
            width='600px'
            title={`Driver Alias: ${driver.friendlyName}`}
            visible={true}
            setVisible={()=> setVisible(false)}
            closeOnOverlay={false}
        >
            <Table>
                <li className='header'>
                    <TableData
                        width='100px'
                        order={1}
                    >  Driver Alias
                    </TableData>
                </li>
                {driverAlias.data.length > 0
                    ?
                        driverAlias.data.map((item,index)=>(
                            (index == driverAlias.update ?
                                <AddForm key={index} onSubmit={onSubmit} keyEdit={index} editData={item} />
                            :
                                <li key={index}>
                                    <TableData width='85%'>{item.name} <input type='hidden' ref={register()} name={`driver_alias[${index}].name`} defaultValue={item.name} /> </TableData>
                                    <TableData width='15%' order={1}>
                                        <input type='hidden' ref={register()} name={`driver_alias[${index}].id`} defaultValue={item.id} />
                                        <Spacer inline>
                                            <Icon.Edit size='20px' color='#7C98B6' onClick={()=>{setDriverAlias({...driverAlias,update:index})}} />
                                        </Spacer>
                                        <Spacer inline left={3}>
                                            <Icon.TimesNarrow size='12px' color='#7C98B6' onClick={()=>{handleDelete(index,item)}} />
                                        </Spacer>
                                    </TableData>
                                </li>
                            )
                        ))
                    :
                    (<li style={{display:'flex',justifyContent:'center',padding:'20px',border:'none'}}>
                        <Icon.Empty color='#CCCCCC' size='60px' />
                    </li>)
                }
            </Table>
                <AddForm onSubmit={onSubmit} />
        </Modal>
    )
}

const AddForm = ({onSubmit,keyEdit,editData}) => {
    let defaultFormValue = {};
    if(keyEdit>=0){
        defaultFormValue = {driver_alias:editData.name};
    }
    const { register, handleSubmit, errors, setValue } = useForm({mode:'onBlur',defaultValues:defaultFormValue});

    const submiter = async data => {
        await onSubmit(data,keyEdit,editData);
        setValue('driver_alias','');
    }
    return (
        <AddTable>
            <Spacer inline right={5}>
                <Input
                    name='driver_alias'
                    size='large'
                    ref={register({required:"Field required"})}
                    error={errors.driver_alias &&  errors.driver_alias.message}
                />
            </Spacer>
            <Spacer inline style={{verticalAlign:'bottom',float:'right'}}>
                <Button size='extraSmall' type='button' onClick={handleSubmit(submiter)}>{(keyEdit>=0) ? 'Save' : 'Add'}</Button>
            </Spacer>
        </AddTable>
    );
}

export default App;