import React, {useRef} from 'react';
import styled from "styled-components";
import BotTaskMessage from "./BotTaskMessage";
import {useTrackedState as useBotState, useUpdate as useBotDispatch} from "./BotStore";
import {fetchPreviousMessages} from "./utils";
import {useObserveScrollPosition} from "react-scroll-to-bottom";
import {MessageOfChannel} from "./types";
import BotTempMessage from "./BotTempMessage";

const MessagesContainer = styled.div`
    display: grid;
    width: 340px;
    max-width: 340px;
    grid-template-columns: 340px;
    grid-template-rows: auto;
    grid-row-gap: 4px;
    padding: 0 0 16px 0;
`;

type Props = {
    identity: string;
    messages?: Array<MessageOfChannel>;
}

function BotTaskMessages({identity}: Props) {
    const dispatch = useBotDispatch();
    const state = useBotState();
    const {previousIndex, hasPreviousPage, channel, init} = state;
    const fetching = useRef(false);
    const top = useRef(0);
    const index = useRef(-1);

    const fetchMessage = async ({scrollTop}) => {
        // console.log([scrollTop, index.current, previousIndex - 1]);
        if (init) {
            dispatch({type: "SET_INIT", init: false});
        } else if (scrollTop <= 650 && !init && !fetching.current) {
            // console.log({
            //     top: top.current,
            //     fetching: fetching.current,
            //     index: index.current,
            //     previousIndex: previousIndex - 1
            // });
            if (!fetching.current) {
                if (previousIndex - 1 >= 0 && hasPreviousPage && (previousIndex - 1) !== index.current) {
                    try {
                        fetching.current = true;
                        index.current = previousIndex - 1;
                        top.current = scrollTop;
                        // console.log({index: previousIndex - 1});
                        // console.log({top: top.current, init, fetching: fetching.current});
                        let response = await fetchPreviousMessages(channel!, 8, previousIndex - 1);
                        // console.log([response.previousIndex, response.hasPrevPage, response.messages.length]);
                        dispatch({
                            type: "PREPEND_MESSAGES",
                            messages: response.messages,
                            previousIndex: response.previousIndex,
                            hasPreviousPage: response.hasPrevPage
                        });
                        fetching.current = false;
                    } catch (e) {
                        fetching.current = false;
                    }
                } else {
                    fetching.current = false;
                }
            }
        }
    }

    useObserveScrollPosition(fetchMessage, [init, previousIndex]);

    if (state.messages.length > 0) {
        return (
            <MessagesContainer>
                {state.messages.map((item) => {
                    return <BotTaskMessage key={item.sid} message={item} currentIdentity={identity!}/>
                })}
            </MessagesContainer>
        );
    }

    return (
        <MessagesContainer>
            <BotTempMessage message={
                {
                    user: {identity: 'system', name: 'system'},
                    date: new Date(),
                    text: 'Hooray! You have no unfinished tasks.'
                }
            }
                            direction={'left'}
                            isSentByCurrentUser={false} showHeader={true}/>
        </MessagesContainer>
    );
}

export default BotTaskMessages;