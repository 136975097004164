import React, {useEffect} from "react";
import styled from "styled-components";
import MainContainerTemplate from "../MainContainerTemplate";
import Loading from "../../../../Chat/Loading";
import {useStoreReducer} from "../store";
import {fetchTemplate} from "../util";
import {useSelectedStation} from "../../../../components/StationSelector";

const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: row;
`;

type scenarioType = 'RTS' | 'LOAD_OUT';

const Template: React.FC<{ scenario: scenarioType }> = ({scenario}) => {
    const [stationId] = useSelectedStation();
    const {appState, appDispatch} = useStoreReducer();

    useEffect(() => {
        const abortController = new AbortController();
        fetchTemplate(stationId, appDispatch, scenario, appState.language).then();
        return () => {
            abortController.abort();
        };
    }, [stationId]);

    if (appState.loading) {
        return (
            <Loading/>
        );
    }

    return (
        <Container>
            <MainContainerTemplate scenario={scenario}/>
        </Container>
    );
}

export default React.memo(Template);
