import React, { useRef , useEffect, useState} from "react";
import Modal from "../../components/Modal";
import {Theme, Icon, Button, SubTitle, Text, Spacer, CheckBox} from "@dspworkplace/ui";
import Parser from 'html-react-parser';
import {dialogPromise, portalPromise} from "../Dialog";
import styled from "styled-components";

const CheckBoxContainer = styled.div`
    span {
        font-size: 13px !important;
    }
`;
const Confirm = ({ title, text, icon, success, cancel, notificationText, cancelBtn = 'Cancel', confirmBtn = 'Confirm', setOpen = () => {} }) => {
    const [sendNotification, setSendNotification] = useState(false);
    const checkBoxRef = useRef(null);

    useEffect(() => {
        if (document.getElementById("confirmBtn")) {
            document.getElementById("confirmBtn").focus();
        }
        const handleKeyDown = (event) => {
            if (event.code === 'ArrowLeft') {
                if (document.getElementById("cancelBtn")) {
                    document.getElementById("cancelBtn").focus();
                }
            }
            if (event.code === 'Space') {
                event.preventDefault();
                setSendNotification(prevState => !prevState);
                const checkBox = checkBoxRef.current;
                if (checkBox) {
                    checkBox.checked = !checkBox.checked;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Modal
            width='340px'
            removeHeader={true}
            visible={true}
            setVisible={cancel}
            closeOnOverlay={false}
        >
            <div style={{textAlign: 'center'}}>
                {icon && <Spacer bottom={5} top={3}><Icon.Warning size='60px' color={Theme.colors.warnings.text}/></Spacer>}
                {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                <Text style={{wordBreak: "break-word"}}>{Parser(text)}</Text>
                {notificationText && <Spacer inline top={5} style={{marginRight:"-20px"}}>
                    <CheckBoxContainer>
                        <CheckBox
                            ref={checkBoxRef}
                            name='sendNotification'
                            defaultValues={[sendNotification]}
                            style={{float:'left'}}
                            onClick={()=>setSendNotification(!sendNotification)}
                            onChange={()=>setSendNotification(!sendNotification)}
                            options={[{label: notificationText, value: true}]}
                        />
                    </CheckBoxContainer>
                </Spacer>}
                <Spacer bottom={5}/>
                <Button id={"cancelBtn"} onClick={() => { cancel(); setOpen(false); }} type='cancel' tabIndex="2">{cancelBtn}</Button>
                <Spacer right={5} inline/>
                <Button id={"confirmBtn"} tabIndex="1" onClick={() => success({sendNotification})} type='primary'>{confirmBtn}</Button>
            </div>
        </Modal>
    )
};

const confirm = async ( opts ) => {
    var title, text, icon;

    if ( typeof opts == 'object' ) {
        var { title, text, icon, cancelBtn, confirmBtn, notificationText, setOpen } = opts;
    } else {
        title = icon = false;
        text  = opts;
    }

    return dialogPromise((success, cancel) =>
        <Confirm
            success={success}
            cancel={cancel}
            title={title}
            text={text}
            icon={icon}
            notificationText={notificationText}
            cancelBtn={cancelBtn}
            confirmBtn={confirmBtn}
            setOpen={setOpen}
        />
    );
};

const confirmPortal = async ( opts ) => {
    var title, text, icon;

    if ( typeof opts == 'object' ) {
        var { title, text, icon, cancelBtn, confirmBtn } = opts;
    } else {
        title = icon = false;
        text  = opts;
    }

    return portalPromise((success, cancel) =>
        <Confirm
            success={success}
            cancel={cancel}
            title={title}
            text={text}
            icon={icon}
            cancelBtn={cancelBtn}
            confirmBtn={confirmBtn}
        />
    );
};

const ConfirmOption = ({ title, text, icon, success, cancel, notificationText, cancelBtn = 'Cancel', confirmBtn = 'Confirm', deniedBtn = 'Denied', setOpen = () => {} }) => {
    const [sendNotification, setSendNotification] = useState(false);
    const checkBoxRef = useRef(null);

    useEffect(() => {
        if (document.getElementById("confirmBtn")) {
            document.getElementById("confirmBtn").focus();
        }
    }, [cancelBtn]);

    return (
        <Modal
            width='340px'
            removeHeader={true}
            visible={true}
            setVisible={cancel}
            closeOnOverlay={false}
        >
            <div style={{textAlign: 'center'}}>
                {icon && <Spacer bottom={5} top={3}><Icon.Warning size='60px' color={Theme.colors.warnings.text}/></Spacer>}
                {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                <Text style={{wordBreak: "break-word"}}>{Parser(text)}</Text>
                <Spacer bottom={5}/>
                <Button id={"confirmBtn"} tabIndex="1" onClick={() => success('confirm')} type='primary'>{confirmBtn}</Button>
                <Spacer right={5} inline/>
                <Button id={"dangerBtn"} tabIndex="1" onClick={() => success('denied')} type='danger'>{deniedBtn}</Button>
                <Spacer right={5} inline/>
                <Button onClick={cancel} type='cancel' tabIndex="2">{cancelBtn}</Button>
            </div>
        </Modal>
    )
};

const confirmOption = async ( opts ) => {
    var title, text, icon;

    if ( typeof opts == 'object' ) {
        var { title, text, icon, cancelBtn, confirmBtn, deniedBtn, notificationText, setOpen } = opts;
    } else {
        title = icon = false;
        text  = opts;
    }

    return dialogPromise((success, cancel) =>
        <ConfirmOption
            success={success}
            cancel={cancel}
            title={title}
            text={text}
            icon={icon}
            notificationText={notificationText}
            cancelBtn={cancelBtn}
            confirmBtn={confirmBtn}
            deniedBtn={deniedBtn}
            setOpen={setOpen}
        />
    );
};

const confirmOptionPortal = async ( opts ) => {
    var title, text, icon;

    if ( typeof opts == 'object' ) {
        var { title, text, icon, cancelBtn, confirmBtn, deniedBtn, deniedBtn } = opts;
    } else {
        title = icon = false;
        text  = opts;
    }

    return portalPromise((success, cancel) =>
        <ConfirmOption
            success={success}
            cancel={cancel}
            title={title}
            text={text}
            icon={icon}
            cancelBtn={cancelBtn}
            confirmBtn={confirmBtn}
            deniedBtn={deniedBtn}
        />
    );
};

export {confirm, confirmPortal,confirmOption, confirmOptionPortal};