import {atom} from "recoil";

export type VMTaskStatus = {
    label: string
    value: string
}

export const VMTaskStatusSelector = atom<VMTaskStatus[]>({
    key: 'VMTaskStatus',
    default: [
        {
            "value": "complete",
            "label": "Complete"
        },
        {
            "value": "assigned",
            "label": "Assigned"
        },
        {
            "value": "pending",
            "label": "Pending"
        },
        {
            "value": "on_hold",
            "label": "On Hold"
        },
        {
            "value": "open",
            "label": "Open"
        },
        {
            "value": "archived",
            "label": "Archived"
        }
    ]
})