import React from "react";
import Card from "./Card";
import styled from "styled-components";
import {useOverviewTracked} from "../Scores/OverviewScreen";

// const width = 428;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: space-between;
    margin: 32px 16px 12px 16px;

    .viewWeek {
        display: flex;
        flex-direction: row;
        height: 30px;
    }

    .text {
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
    }

    .rightText {
        display: flex;
        color: #516F90;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        margin-left: 16px;
    }
`;

const Incidents: React.FC = () => {
    const {state} = useOverviewTracked();
    const {incidentsCards} = state;
    console.log({incidentsCards});
    return (
        <div>
            <Header className={'viewHeader'}>
                <div className={'viewWeek'}>
                    <div>
                        <div className={'text'}>
                            Incidents
                        </div>
                    </div>
                    <div>
                        <div className={'rightText'}>
                            last 30 days
                        </div>
                    </div>
                </div>
            </Header>
            {Array.isArray(incidentsCards) && incidentsCards.map((e) => {
                return (
                    <Card
                        key={e.metricIdentifier}
                        metricIdentifier={e.metricIdentifier}
                        metricName={e.metricName}
                        metricType={e.metricType!}
                        metricValue={e.metricValue!}
                        change={e.change!}
                        trend={e.trend!}
                        cardStyle={e.cardStyle!}
                        icon={e.icon!}
                        textColor={e.textColor!}
                        message={e.message!}
                    />
                );
            })}
        </div>
    );
};

export default Incidents;