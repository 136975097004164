import React from "react";
import { CubeProvider } from "@cubejs-client/react";
import cubeApi from '../../../api/cube';
import {useParams} from "react-router-dom";
import DashboardEmptyContent from './dashboardEmptyContent';
import Dashboard from './dashboard';

const DashboardContent = ({ data, dispatch, onAddChart, onCreateChart, onUpdateCardConfig, onEditChart, onDeleteChart,options }) => {
    const [currentPanel, setCurrentPanel] = React.useState({});
    let { slug } = useParams();

    let bodyStyle = {display:'flex',justifyContent: 'center',alignItems: 'center',height:'100%',flexDirection:'column'};

    React.useEffect(()=>{
        if(slug){
            const p = data.filter((c)=> c.name === decodeURI(slug).replace('__','/'));
            if(p){
                setCurrentPanel(p[0])
            }
        }
    },[slug,data])


    return (
        <div style={!data.length ? bodyStyle : {}}>
            {
                data.length > 0
                ?
                    <CubeProvider cubejsApi={cubeApi}>
                        <Dashboard
                            name={slug.replace('__','/')}
                            panelId={currentPanel?.id}
                            charts={currentPanel?.charts}
                            dispatch={dispatch}
                            onAddChart={onAddChart}
                            onCreateChart={onCreateChart}
                            onEditChart={onEditChart}
                            onUpdateCardConfig={onUpdateCardConfig}
                            onDeleteChart={onDeleteChart}
                            options={options}
                        />

                    </CubeProvider>
                :
                    <DashboardEmptyContent dispatch={dispatch} />
            }
        </div>
    )
}

export default DashboardContent;