import {usePanelState, useTabState} from "@bumaga/tabs";
import styled from "styled-components";
import React from "react";
import {Theme} from "@dspworkplace/ui";

export const Tab = ({children}) => {
    const {onClick, isActive} = useTabState();
    return <h6 onClick={onClick} className={isActive ? "tabActive": ""} data-tab={'tab'}>{children}</h6>;
};

export const ContentPanel = styled.div`
    box-sizing: border-box;
    display: flex;
    font-family: circe-rounded, serif;
    flex-direction: column;
`;

export const Panel = ({children}) => {
    const isActive = usePanelState();
    return (isActive ? <ContentPanel> {children} </ContentPanel> : <div style={{display: 'none'}}/>);
};

export const TabList = styled.div`
    width:100%;
    display:inline-block;
    
    >h6 {
        display:inline-block;
        font-size:${Theme.font.medium.size};
        color:${Theme.colors.secondary};
        padding:10px 16px ;
        font-weight:400;
        font-family:circe-rounded,serif;
        cursor:pointer;
        position:relative;
        &.tabActive {
            font-weight:600;
            
            &::before {
                content:" ";
                height:2px;
                background:${Theme.colors.secondary};
                width:100%;
                display:block;    
                border:1px solid transparent;  
                border-radius:2px;               
                position:absolute;
                left:0;
                bottom:-2.50px;         
            }
        }
    }
    &::after {
        content: " ";
        height:1px;
        background:${Theme.colors.neutrals.silver};
        width:100%;
        display:block;
    }
`;