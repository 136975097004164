import React, {useCallback, useState} from 'react';
import {Icon} from "@dspworkplace/ui";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    showProfileIcon?: boolean;
    profileIconSize?: string;
    profileIconColor?: string;
}

const S3ProfileImage: React.FC<Props> = ({showProfileIcon, profileIconSize = '24px', profileIconColor = '#516F90', ...props}) => {
    const [hasError, setHasError] = useState(false);
    const onError = useCallback(
        () => {
            setHasError(true)
        },
        [],
    );

    if (showProfileIcon) {
        return <Icon.Person size={profileIconSize} color={profileIconColor}/>
    }

    if (hasError) {
        return <Icon.Person size={profileIconSize} color={profileIconColor}/>
    }

    return <img {...props} onError={onError} alt={props.alt ? props.alt : 'Image not specified'}/>;
};

export default S3ProfileImage;
