import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";
import {
    useComponentContext,
    Avatar,
} from "stream-chat-react";

const ImageChannel = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    overflow: hidden;
    position: relative;
    border: ${props => (props.border ? props.border : "1px solid #516F90")};
    border-radius: 50%;
    cursor: pointer;

    svg {
        border-radius: 50%;
    }

    img {
        height: 100%;
        width: 100%;
        overflow: scroll;
        margin: auto;
        object-fit: cover;
    }

    ${props =>
        props.bot
            ? `
        border: 1px solid #F7931E;
        align-items: center;
        justify-content: center;
        svg {
         border-radius: unset;
        }
    `
            : ""}
`;

export const ContainerDot = styled.div`
    position: absolute;
    width: 12px;
    height: 12px;
    align-self: center;
    background-color: ${props => (props.status === true ? "#1fe070" : "rgb(124, 152, 182)")};
    border-radius: 100%;
    border: 2px solid white;
    right: -3px;
    min-width: 12px;
    display:${props => (props.status === true ? "flex" : "none")};
    bottom:0
`;

export const AvatarCustome = styled.div`
display: block;
    width:${props => (props.size ? props.size : '40px')};
    height: ${props => (props.size ? props.size : '40px')};
    object-fit: cover;
    text-align: center;
    background-color:#0071BC;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    color:white;
    font-size:${props => (props.fontSize ? props.fontSize : '14px')};
    font-weight: 500;
`

// const ContainerDot = styled.div`
//     position: relative;
//     width: 14px;
//     height: 14px;
//     align-self: center;
//     background-color: ${props => (props.status === "Offline" ? "rgb(124, 152, 182)" : "rgb(0, 189, 165)")};
//     border-radius: 50%;
//     border: 2px solid white;
//     top: 10px;
//     left: -10px;
//     min-width: 14px;
// `;

export const getInitials = (fullName: string) =>
    fullName
        ?.split(' ')
        .slice(0, 2)
        .map((name) => name.charAt(0))
        .join('');

const ChannelIcon = ({ channel, image, type, name, status }) => {
    // const [state, setState] = useState({loading: type === 'Duo', image: image});
    // const {loading, image} = state;
    if (type === "bot") {
        return (
            <>
                <ImageChannel bot={true}>
                    <Icon.DspwNetwork size="32px" color="#F7931E" />
                </ImageChannel>
            </>
        );
    }

    if (type === "group" && image) {
        return (
            <>
                <Avatar image={image} name={name} size={40} />
            </>
        );
    }

    if (type === "duo" && image) {
        return (
            <div style={{ display: 'flex', position: 'relative' }} >
                <Avatar image={image} name={name} size={40} />
                <ContainerDot status={status?.online} />
            </div>
        );
    }

    if ((type === "duo" || type === "group") && !image) {
        return (
            <div style={{ display: "flex", position: 'relative' }}>
                <AvatarCustome>
                    {getInitials(name)?.toUpperCase()}
                </AvatarCustome>
                <ContainerDot status={status?.online} />
            </div>
        );
    }

    // if (type === "duo" && image) {
    //     return (
    //         <div style={{ display: "flex" }}>
    //             <ImageChannel>
    //                 <img src={image} alt={"Channel Icon"} onError={(e) => {
    //                     //@ts-ignore
    //                     e.target.src = 'https://s3.amazonaws.com/dsp.data.storage.general/d92b41c8_2021_08_20_icon-person.png';
    //                 }} />
    //                 <ContainerDot status={status?.online} />
    //             </ImageChannel>
    //         </div>
    //     );
    // }

    if (!image) {
        return (
            <>
                <ImageChannel>
                    <Icon.Group size="40px" color="#516F90" />
                    <ContainerDot status={status?.online} />
                </ImageChannel>
            </>
        );
    }

    return (
        <>
            <ImageChannel>
                <img src={image} alt={"Channel Icon"} />
                <ContainerDot status={status?.online} />
            </ImageChannel>
        </>
    );
}

function propsAreEqual(prev, next) {
    return prev.channel.sid === next.channel.sid;
}

const MemoizedChannelIcon = React.memo(ChannelIcon, propsAreEqual);
// export default ChannelIcon;
export default MemoizedChannelIcon;
