import styled from "styled-components";
import {Spacer, Theme} from "@dspworkplace/ui";

export const Body = styled.div`
    display:flex;
`;

export const Navigator = styled.div.attrs(props => ({
    menusHeight: (document.querySelector('.nav-main') || {offsetHeight: 0}).offsetHeight
        + (document.querySelector('.sub-nav-main') || {offsetHeight: 0}).offsetHeight
}))`
    padding: 20px;
    border-right: 1px solid ${Theme.colors.info.shadow};
    height:calc(100vh - ${props => props.menusHeight}px);
    width: 300px;
    box-sizing: border-box;
    
    ${props => props.scrollable ? `overflow: auto;` : undefined}
`;

export const Content = styled.div.attrs(props => ({
    menusHeight: (document.querySelector('.nav-main') || {offsetHeight: 0}).offsetHeight
        + (document.querySelector('.sub-nav-main') || {offsetHeight: 0}).offsetHeight
}))`
    box-sizing: border-box;
    padding: 20px;
    width: calc(100vw - 300px);
    height: calc(100vh - ${props => props.menusHeight}px);
    
    ${props => props.scrollable ? `
    overflow: auto;
    padding-bottom: 0;
    ` : undefined}
`;

export const Menu = styled.ul`
    list-style:none;
    margin:0;
    padding:0;

    > li {
        margin-bottom:8px;

        > a {
            display:flex;
            align-items:center;
            color:${Theme.colors.secondary};
            font-size:${Theme.font.medium.size};
            line-height:${Theme.font.medium.lineHeight};
            font-family: ${Theme.font.main};
            min-height: 36px;
            font-weight:normal;
            padding:8px 12px;
            border-radius:${Theme.defaultRadius};
            border-left: 3px solid transparent;

            > svg {
                vertical-align: middle;
                padding-right: 16px;
                path {
                    fill: ${Theme.colors.info.text};
                }
            }

            &:hover {
                background:${Theme.colors.info.bg};
                border-radius:${Theme.defaultRadius};
            }

            &.active {
                background:${Theme.colors.info.bg};
                border-radius:${Theme.defaultRadius};
                border-left:3px solid;
            }
            
            &:hover,
            &.active {
                > svg path {
                    fill: ${Theme.colors.secondary};
                }
            }
        }
    }
`;

export const Back = styled(Spacer).attrs(()=>({
    bottom: 2
}))`
    font-family: ${Theme.font.main};
    
    svg {
        transform: translateY(1px);
        margin-right: 4px;
        
        path {
            fill: ${Theme.colors.info.border};
        }
    }
    
    a span {
        color: ${Theme.colors.info.border};
        text-decoration: none;
    }
`;