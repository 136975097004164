import React from 'react';
import {Icon} from '@dspworkplace/ui';

function ArrowRightItem() {
    return (
        <div style={{width: '10%'}}>
            <Icon.ArrowRight
                size="16px"
                color={'#7C98B6'}
            />
        </div>
    );
}

export default ArrowRightItem;