import React from 'react';
import {useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {InputType} from "./store";
import {Button} from '@dspworkplace/ui';

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: InputType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function InputForm(props: Props) {
    const {item, updateItem} = props;
    const {name, description, isPassRequired, notifyIfChosen, isPhotoRequired, isSignature, disabledQuestion} = item.content;
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {
            name,
            description,
            isPassRequired,
            notifyIfChosen,
            isPhotoRequired,
            isSignature,
            disabledQuestion
        }
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            {/*<label className={'isSignature'}>Is signature required?</label>*/}
            {/*<input name="isSignature" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <label>Is required?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            <label>Require picture</label>
            <div className={'isPhotoRequiredContainer'}>
                <input name="isPhotoRequired" type="checkbox" className={'checkbox'} ref={register}/>
                <label className={'isPhotoRequired'}>*Vehicle need to be attached to Shift before or Scanner VIN need to
                    be executed before</label>
            </div>
            {/*<label>Notify when step is completed?</label>*/}
            {/*<input name="notifyIfChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default InputForm;