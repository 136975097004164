import React from 'react';
// @ts-ignore
import style from './statusColored.module.css';
const StatusColored = ({row,showLabel=true}) => {
    return (
        <div className={style.content}>
            <span className={style.color} style={{background: row.color}} />
            {showLabel && <p className={style.text}>{row.label}</p>}
        </div>
    )
}

export default StatusColored;