import {atom} from "recoil";

export type VMTaskStatus = {
    label: string
    value: string
}

export const VehicleStatusState = atom<VMTaskStatus[]>({
    key: 'VehicleStatusState',
    default: [
        {label: 'All (Non Archived)', value: 'all'},
        {label: 'Active', value: 'Active'},
        {label: 'Inactive', value: 'Inactive'},
        {label: 'Under Repair', value: 'Under Repair'},
        {label: 'Being Serviced', value: 'Being Serviced'},
        {label: 'Body Shop', value: 'Body Shop'},
        {label: 'Archived', value: 'Archived'}
    ]
})