import {Axis, getTheme, registerTheme} from "bizcharts";
import {Theme} from "@dspworkplace/ui";
import React from "react";

export const chartTheme = {
    ...getTheme('default'),
    defaultColor: Theme.colors.info.border,
    fontFamily: Theme.font.main,
    colors10: [
        Theme.colors.info.border,
        Theme.colors.primary,
        Theme.colors.secondary,
        Theme.colors.action,
        Theme.colors.success.border,
        Theme.colors.error.border,
        Theme.colors.warnings.border,
        Theme.colors.info.text,
        Theme.colors.success.text,
        Theme.colors.error.text,
        Theme.colors.warnings.text,
    ],
};
registerTheme('dspworkplace', chartTheme);

export const AxisHelper = ({title,x,y}) => {
    return (
        <>
            {title && title.x && <Axis name={x} title={title.x} label={{autoRotate:true}} />}
            {title && title.y && <Axis name={y} title={title.y} label={{autoRotate:true}} />}
        </>
    );

}