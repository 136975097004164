import React, {useContext} from "react";
import {useFormContext} from "react-hook-form";
import {Alert, Button, Spacer, TagInput, Text, ThirdTitle, Toggle} from "@dspworkplace/ui";
import SettingsContext from "../../context";
import {Divider, Label, Status} from "../../../../components/UI";
import {RouteAssistTM} from "./index";
import { AccessCheck, IsGranted } from "../../../../security";
import { useRecoilValue } from 'recoil';
import { planDetailsState } from "../../../../state/planDetail";

const ComingSoon = ({children}) =>
    <>
        <Status current={'warning'}>Coming Soon</Status>
        <Spacer bottom={3}></Spacer>
        <div style={{ opacity: .5, pointerEvents: 'none' }}>
            {children}
        </div>
    </>

const DisableToggle = ({isDisable, children }) =>
    <>{isDisable == true ?
        <div style={{ opacity: .5, pointerEvents: 'none' }}>
            {children}
        </div>
        :
        <div>
            {children}
        </div>
    }</>

export default function RouteAssistOptions ({
    settings,
    station
}) {
    const [state] = useContext(SettingsContext);
    const {register,watch} = useFormContext();
    const {tier} = useRecoilValue(planDetailsState);
    const isScorecardFeature = AccessCheck.isGranted({ plan: tier, feature:AccessCheck.features.SCORECARD, role:null, ops:null, station:null });

    return <Spacer top={10} bottom={5} style={{position: 'relative'}}>
        <input
            type="hidden"
            ref={register}
            name={`id`}
            defaultValue={settings?.id}
        />

        <input
            type="hidden"
            ref={register}
            name={`station`}
            defaultValue={station?.id}
        />

        {!station ? null : (
            <Spacer bottom={10}>
                <Toggle
                    ref={register}
                    name={`enabled`}
                    singleText={<>Customize <RouteAssistTM/> for this station</>}
                    on={settings?.enabled}
                />
            </Spacer>
        )}

        <div style={{
            opacity: !station || watch('enabled') || settings?.enabled ? 1 : .5,
            pointerEvents: !station || watch('enabled') || settings?.enabled ? 'auto' : 'none',
        }}>
            <ThirdTitle>1. Route Assignment Options</ThirdTitle>
            <Spacer bottom={5}/>

            <Toggle
                ref={register}
                name={`routeCodeAssignmentEnabled`}
                on={settings?.routeCodeAssignmentEnabled}
                singleText={<>Enable route code assignment</>}
                help={<Spacer left={11}>Assign route codes based on available transporter IDs</Spacer>}
            />
            <Spacer bottom={5}/>

            {/*<Toggle*/}
            {/*    ref={register}*/}
            {/*    name={`routeCodeAssignmentOptions.guessShiftTypes`}*/}
            {/*    on={settings?.routeCodeAssignmentOptions?.guessShiftTypes}*/}
            {/*    singleText={<>Guess shift types</>}*/}
            {/*    help={<Spacer left={11}>Automatically create training or rescues routes if the service type is Nursery, or when a second driver were added to the route</Spacer>}*/}
            {/*/>*/}
            {/*<Spacer bottom={5}/>*/}

            {/*<Toggle*/}
            {/*    ref={register}*/}
            {/*    name='lockVehicleType'*/}
            {/*    singleText={<Label>Lock assignment of vehicle type</Label>}*/}
            {/*    help={<Spacer left={11}>Ex.: Prevent assignment of a step van if service level is standard van</Spacer>}*/}
            {/*/>*/}
            {/*<Spacer bottom={5}/>*/}

            <TagInput
                ref={register}
                name={`routeCodeAssignmentOptions.excludeRoles`}
                label='Do not assign routes to these Permissions'
                options={state.roles || []}
                defaultValues={settings?.routeCodeAssignmentOptions?.excludeRoles}
            />

            <Divider top={5} bottom={5}/>

            <ComingSoon>
                <ThirdTitle>2. Staging Locations / Picklist Import</ThirdTitle>
                <Spacer bottom={5}/>
                <Toggle
                    ref={register}
                    name={`picklistImportEnabled`}
                    singleText={<>Request every pick up</>}
                    help={<Spacer left={11}>Import the full daily picklist, or just copy and paste your routes assigned staging locations to easily add and share with your drivers.</Spacer>}
                    on={settings?.picklistImportEnabled}
                />
            </ComingSoon>

            <Divider top={5} bottom={5}/>

            <ComingSoon>
                <ThirdTitle>3. Van Auto Assignment</ThirdTitle>
                <Spacer bottom={5}/>
                <Toggle
                    ref={register}
                    name={`vanAutoAssignmentEnabled`}
                    singleText={<>Run Van Auto Assignment after previous steps</>}
                    help={<Spacer left={11}>Automatically assign vehicles with smart rules to best use your resources.</Spacer>}
                    on={settings?.vanAutoAssignmentEnabled}
                />
            </ComingSoon>

            <Divider top={5} bottom={5}/>

            <ComingSoon>
                <ThirdTitle>4. Assignment Notifications</ThirdTitle>
                <Spacer bottom={5}/>
                <Toggle
                    ref={register}
                    name={`assignmentNotificationsEnabled`}
                    singleText={<>Send assignment notifications after previous steps</>}
                    help={<Spacer left={11}>It can include their route code, vehicle ID and staging location.</Spacer>}
                    on={settings?.assignmentNotificationsEnabled}
                />
                <Spacer bottom={5}/>

                <Label>Only send to drivers assigned with:</Label>
                <Spacer bottom={1}/>
                <Spacer inline right={3}>
                    <Toggle
                         ref={register}
                         name={`assignmentNotificationsOptions.needRouteCode`}
                         singleText='Route Code'
                         on={settings?.assignmentNotificationsOptions?.needRouteCode}
                    />
                </Spacer>
                <Spacer inline right={3}>
                    <strong>AND</strong>
                </Spacer>
                <Spacer inline right={3}>
                    <Toggle
                         ref={register}
                         name={`assignmentNotificationsOptions.needVehicle`}
                         singleText='Vehicle'
                         on={settings?.assignmentNotificationsOptions?.needVehicle}
                    />
                </Spacer>
                <Spacer inline right={3}>
                    <strong>AND</strong>
                </Spacer>
                <Spacer inline>
                    <Toggle
                         ref={register}
                         name={`assignmentNotificationsOptions.needStagingLocation`}
                         singleText='Staging Location'
                         on={settings?.assignmentNotificationsOptions?.needStagingLocation}
                    />
                </Spacer>

                <Spacer bottom={5}></Spacer>
                <Text>Notification example:</Text>
                <Spacer bottom={3}/>
                <Alert.Info
                    content={<>
                        Hi <strong>[Nickname]</strong>. For your scheduled shift today you are assigned to: <br/>
                        <em>[If Vehicle Assigned]</em> Vehicle ID: <strong>[Vehicle ID]</strong> <br />
                        <em>[If Route Code Assigned]</em> Route Code: <strong>[Route Code Assignment]</strong> <br />
                        <em>[If Staging Location Assigned]</em> Staging location: <strong>[Staging Location]</strong> <br />
                        See you soon!
                    </>}
                />

                <Spacer bottom={5}></Spacer>
                <Toggle
                    ref={register}
                    name={`assignmentNotificationsOptions.sendSMS`}
                    singleText={'Send SMS Notifications'}
                />
            </ComingSoon>

            <Divider top={5} bottom={5}/>
            <ThirdTitle>5. Package Delivery Progress</ThirdTitle>
            <Spacer bottom={5}/>

            <Toggle
                ref={register}
                name={`packageDeliveryProgressEnabled`}
                singleText={<>Automatically get updated package delivery progress data of assigned route codes</>}
                help={<Spacer left={11}>Package data are updated every 30 minutes</Spacer>}
                on={settings?.packageDeliveryProgressEnabled}
            />

            <Divider top={5} bottom={5}/>
            <ThirdTitle>6. Safety Documents</ThirdTitle>
            <Spacer bottom={5} />
                <DisableToggle isDisable={!state.isPartnerPackage} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsEnabled`}
                        singleText={<>Enabled Safety Documents Import</>}
                        on={settings?.safetyDocumentsEnabled}
                        disable={true}
                    />
                </DisableToggle>
            <Spacer bottom={5}/>

            <Label>Select the safety documents to import:</Label>
            <Spacer bottom={3}/>

            {/*
            <Spacer bottom={5} style={{
                pointerEvents: 'none',
                opacity: '.5',
            }}>
                <Toggle
                    ref={register}
                    name={`safetyDocumentsOptions.cortexScorecardCsv`}
                    singleText={<>Scorecard Weekly CSV (Coming soon)</>}
                    on={settings?.safetyDocumentsOptions?.cortexScorecardCsv || !!!watch('safetyDocumentsOptions.cortexScorecard')}
                    help={<>Learn how to get your CSV file with <a style={{ textDecoration: 'underline' }} href='https://support.dspworkplace.com/en/article/how-to-manually-import-the-amazon-weekly-overall-scorecard-13bk52h/?bust=1665518619346' target='_blank'>this help article</a></>}
                />
            </Spacer>
            */}

            <Spacer bottom={5}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.cortexScorecard`}
                        singleText={<>Scorecard Weekly PDF</>}
                        on={settings?.safetyDocumentsOptions?.cortexScorecard || (watch('safetyDocumentsOptions.cortexScorecard') && !watch('safetyDocumentsOptions.cortexScorecardCsv'))}
                    />
                </DisableToggle>
            </Spacer>

            <Spacer bottom={5}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.cortexEngineOffCompliance`}
                        singleText={<>Engine Off Compliance</>}
                        on={settings?.safetyDocumentsOptions?.cortexEngineOffCompliance}
                    />
                </DisableToggle>
            </Spacer>

            <Spacer bottom={5}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.cortexCostumerDeliveryFeedback`}
                        singleText={<>Customer Delivery Feedback Weekly PDF</>}
                        on={settings?.safetyDocumentsOptions?.cortexCostumerDeliveryFeedback}
                    />
                </DisableToggle>
            </Spacer>

            <Spacer bottom={5}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.ementorDaily`}
                        singleText={<>eMentor Daily CSV</>}
                        on={settings?.safetyDocumentsOptions?.ementorDaily}
                    />
                </DisableToggle>
            </Spacer>

            <Spacer bottom={5}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.netradyneDaily`}
                        singleText={<>Netradyne Daily CSV</>}
                        on={settings?.safetyDocumentsOptions?.netradyneDaily}
                    />
                </DisableToggle>
            </Spacer>

            <Spacer all={0}>
                <DisableToggle isDisable={!isScorecardFeature} >
                    <Toggle
                        ref={register}
                        name={`safetyDocumentsOptions.properParkingSequenceCsv`}
                        singleText={<>Proper Parking Sequence CSV</>}
                        on={settings?.safetyDocumentsOptions?.properParkingSequenceCsv}
                    />
                </DisableToggle>
            </Spacer>
        </div>
        <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>    
        <Spacer bottom={5}/>
            <Button type={'primary'}>Save {station?.code}</Button>
        </IsGranted>
    </Spacer>
}