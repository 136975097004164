import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
import {
    fetchDataForChannelGroup, addingDefaultUsers,
    filtersForUserSearch,
    getChannelFriendlyName,
    getChannelIconImage,
    getFilterResult,
    removeGroupPicture, setItemToStorage,
    setMatchesBasedOnFilter
} from "./utils";
import ModalWindow from "./Modal";
import {ChannelsSettingsContent} from "./styles";
import {Tabs} from "@bumaga/tabs";
import {TabList, Panel, Tab} from "./Tabs";
import ChannelSettingsModal from "./ChannelSettingsModal";
import ChannelSettingsPanelCurrentMembers from "./ChannelSettingsPanelCurrentMembers";
import ChannelSettingsPanelUpdateMembers from "./ChannelSettingsPanelUpdateMembers";
import {EmployeeTwilioUser, FilterArrayType} from "./types";
import Loading from "./Loading";
import {showErrorToast} from "../Utilities";

export type ChannelSettingsState = {
    currentMembersRefresh: number;
    channel: Channel | null | undefined;
    channelName: string;
    members: Array<EmployeeTwilioUser>;
    memberMatches: Array<EmployeeTwilioUser>;
    removedMembers: Array<EmployeeTwilioUser>;
    users: Array<EmployeeTwilioUser>;
    matches: Array<any>;
    newMembers: Array<any>;
    errorMessage: string;
    kindOfChannel: string;
    description?: string | null;
    file?: any;
    image: string | null;
    searchFilterContent: Array<FilterArrayType>;
    searchAddFilterContent: Array<FilterArrayType>;
    userMatches: Array<EmployeeTwilioUser>;
    usersToAdd: Array<EmployeeTwilioUser>;
    identities: Array<string>;
    filterCurrentSearch: Array<FilterArrayType>;
    filterResult: Array<FilterArrayType>;
    initSearchFilter: Array<FilterArrayType>;
    initUserMatches: Array<EmployeeTwilioUser>;
    initFilter: Array<FilterArrayType>;
    stations: Array<{ code: string, id: number }>;
    roles: Array<{ name: string, roleName: string, id: number }>;
    skills: Array<{ name: string, id: number }>;
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
    permissions: Array<number>;
    countTagsDefaultAutoUsers: number;
    tagsDefaultAutoUsers: Array<FilterArrayType>;
    defaultAutoUsers: Array<EmployeeTwilioUser>;
    channelNotification: boolean;
};

export type ChannelSettingsAction =
    | { type: "SET_CHANNEL_INFORMATION"; channelName: string; description: string | null; image: string | null }
    | { type: "SET_KIND_CHANNEL"; kindOfChannel: string }
    | { type: "SET_FILE"; file: any }
    | { type: "SET_CHANNEL"; channel: Channel }
    | { type: "SET_CHANNEL_NAME"; channelName: string }
    | { type: "SET_CHANNEL_IMAGE"; image: string | null }
    | { type: "SET_CHANNEL_DESCRIPTION"; description: string | null }
    | {
    type: "SET_UP";
    defaultAutoUsers: Array<EmployeeTwilioUser>;
    identities: Array<string>;
    previousFilter: Array<FilterArrayType>;
    users: Array<EmployeeTwilioUser>;
    members: Array<EmployeeTwilioUser>;
    stations: Array<{ code: string, id: number }>;
    roles: Array<{ name: string, roleName: string, id: number }>;
    skills: Array<{ name: string, id: number }>;
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
    channelNotification: boolean;
}
    | {
    type: "SET_MEMBER_MATCHES";
    data: Array<FilterArrayType>;
}
    | { type: "SET_MATCHES"; matches: Array<any> }
    | {
    type: "SET_USERS";
    defaultAutoUsers: Array<EmployeeTwilioUser>;
    previousFilter: Array<FilterArrayType>;
    users: Array<EmployeeTwilioUser>;
    stations: Array<{ code: string, id: number }>;
    roles: Array<{ name: string, roleName: string, id: number }>;
    skills: Array<{ name: string, id: number }>;
    schedules: Array<{
        stationCode: string;
        stationId: number;
        name: string, id: number
    }>;
    teams: Array<{
        id: number,
        name: string
    }>;
    chatVariable: Array<{
        name: string,
        chatName: string,
        stationId: number,
        shiftDate: string
    }>;
}
    | { type: "ADD_NEW_USER"; user: EmployeeTwilioUser }
    | { type: "REFRESH_CURRENT_MEMBERS"; }
    | { type: "REMOVE_NEW_USER"; user: EmployeeTwilioUser }
    | { type: "SELECT_ALL_NEW_USERS" }
    | { type: "FILTER_NEW_USERS"; filter: Array<FilterArrayType> }
    | { type: "DELETE_ALL_NEW_USERS"; }
    | { type: "ADD_NEW_USERS_TO_CHANNEL" }
    | { type: "SET_NEW_MEMBERS"; newMembers: Array<any> }
    | { type: "ADD_NEW_MEMBER"; newMember: any }
    | { type: "SET_ERROR"; errorMessage: string }
    | { type: "REMOVE_MEMBER"; userId: number }
    | { type: "MEMBER_TO_REMOVE"; user: EmployeeTwilioUser }
    | { type: "MEMBER_TO_ADD_BACK"; user: EmployeeTwilioUser }
    | { type: "REMOVE_SELECTED_MEMBERS" }
    | { type: "REMOVE_ALL_MEMBERS" }
    | { type: "RESET" };

function reducer(state: ChannelSettingsState, action: ChannelSettingsAction): ChannelSettingsState {
    switch (action.type) {
        case "REFRESH_CURRENT_MEMBERS":
            return {...state, currentMembersRefresh: state.currentMembersRefresh + 1.0};
        case "SET_KIND_CHANNEL":
            return {...state, kindOfChannel: action.kindOfChannel};
        case "SET_CHANNEL_INFORMATION":
            return {...state, channelName: action.channelName, description: action.description, image: action.image};
        case "SET_FILE":
            return {...state, file: action.file};
        case "SET_CHANNEL":
            return {...state, channel: action.channel};
        case "SET_CHANNEL_NAME":
            return {...state, channelName: action.channelName};
        case "SET_CHANNEL_IMAGE":
            return {...state, image: action.image};
        case "SET_CHANNEL_DESCRIPTION":
            return {...state, description: action.description};
        case "REMOVE_MEMBER":
            return {
                ...state,
                memberMatches: state.memberMatches.filter(item => item.userId !== action.userId),
                members: state.members.filter(item => item.userId !== action.userId)
            };
        case "MEMBER_TO_REMOVE":
            return {
                ...state,
                memberMatches: state.memberMatches.filter(item => item.userId !== action.user.userId),
                removedMembers: [action.user, ...state.removedMembers],
                members: state.members.filter(item => item.userId !== action.user.userId)
            };
        case "MEMBER_TO_ADD_BACK":
            return {
                ...state,
                memberMatches: [action.user, ...state.memberMatches],
                members: [action.user, ...state.members],
                removedMembers: state.removedMembers.filter(item => item.userId !== action.user.userId)
            };
        case "REMOVE_SELECTED_MEMBERS":
            return {...state, removedMembers: []};
        case "REMOVE_ALL_MEMBERS":
            return {...state, memberMatches: [], members: []};
        case "SET_MEMBER_MATCHES":
            return {...state, memberMatches: setMatchesBasedOnFilter(action.data, state.members)};
        case "SET_MATCHES":
            return {...state, matches: action.matches};
        case "SET_UP":
            let filtersForSearch = filtersForUserSearch(action.stations, action.roles, action.skills, action.schedules, action.users, action.defaultAutoUsers, action.teams, action.chatVariable);
            let defaultAutoUsers = filtersForSearch.defaultAutoUsers;
            let initUsersToSearch = setMatchesBasedOnFilter(action.previousFilter, action.users);
            let initSearchFilter = [
                ...filtersForSearch.newFilterStationArray,
                ...filtersForSearch.newFilterRolesArray,
                ...filtersForSearch.newFilterSchedulesArray,
                ...filtersForSearch.newFilterSkillsArray,
                ...filtersForSearch.newFilterNamesArray,
                ...filtersForSearch.newFilterEmailArray,
                ...filtersForSearch.newFilterTeamArray,
                ...filtersForSearch.newFilterChatVariableArray];
            let initSearchValues = action.previousFilter.map(e => e.value);
            initSearchFilter = initSearchFilter.map(e => {
                if (initSearchValues.includes(e.value)) {
                    return {...e, selected: true};
                }
                return e;
            });
            let permissions = [];
            if (state.channel &&
                state.channel.attributes &&
                //@ts-ignore
                state.channel.attributes.permissions &&
                //@ts-ignore
                Array.isArray(state.channel.attributes.permissions)) {
                //@ts-ignore
                permissions = state.channel.attributes.permissions;
            }
            if (state.channel) {
                setItemToStorage(`permissions_${state.channel.sid}`, permissions);
            }
            return {
                ...state,
                identities: action.identities,
                members: action.members,
                memberMatches: action.members,
                searchFilterContent: initSearchFilter,
                users: action.users,
                userMatches: initUsersToSearch,
                filterResult: action.previousFilter,
                filterCurrentSearch: action.previousFilter,
                searchAddFilterContent: initSearchFilter,
                initSearchFilter: initSearchFilter,
                initUserMatches: initUsersToSearch,
                initFilter: action.previousFilter,
                stations: action.stations,
                roles: action.roles,
                skills: action.skills,
                schedules: action.schedules,
                permissions: permissions,
                defaultAutoUsers: defaultAutoUsers,
                countTagsDefaultAutoUsers: defaultAutoUsers.length,
                tagsDefaultAutoUsers: filtersForSearch.tagsDefaultAutoUsers,
                teams: action.teams,
                chatVariable: action.chatVariable,
                channelNotification: action.channelNotification
            };
        case "SET_USERS":
            let {
                newFilterEmailArray: filterEmails,
                newFilterNamesArray: filterNames,
                newFilterRolesArray: filterRoles,
                newFilterSchedulesArray: filterSchedules,
                newFilterSkillsArray: filterSkills,
                newFilterStationArray: filterStations,
                defaultAutoUsers: defaultAutoUsersList,
                tagsDefaultAutoUsers: tagsDefaultAutoUsersList,
                newFilterTeamArray: filterTeams,
                newFilterChatVariableArray: filterChatVariable,
            } = filtersForUserSearch(action.stations, action.roles, action.skills, action.schedules, action.users, action.defaultAutoUsers, action.teams, action.chatVariable);
            let usersToSearch = setMatchesBasedOnFilter(action.previousFilter, action.users);
            let searchFilter = [...filterStations, ...filterRoles, ...filterSchedules, ...filterSkills, ...filterNames, ...filterEmails, ...filterTeams, ...filterChatVariable];
            let searchValues = action.previousFilter.map(e => e.value);
            searchFilter = searchFilter.map(e => {
                if (searchValues.includes(e.value)) {
                    return {...e, selected: true};
                }
                return e;
            });
            return {
                ...state,
                users: action.users,
                userMatches: usersToSearch,
                filterResult: action.previousFilter,
                searchAddFilterContent: searchFilter,
                initSearchFilter: searchFilter,
                filterCurrentSearch: action.previousFilter,
                initUserMatches: usersToSearch,
                initFilter: action.previousFilter,
                stations: action.stations,
                roles: action.roles,
                skills: action.skills,
                schedules: action.schedules,
                defaultAutoUsers: defaultAutoUsersList,
                tagsDefaultAutoUsers: tagsDefaultAutoUsersList,
                countTagsDefaultAutoUsers: tagsDefaultAutoUsersList.length,
                teams: action.teams,
                chatVariable: action.chatVariable,
            };
        case "DELETE_ALL_NEW_USERS":
            return {
                ...state,
                usersToAdd: [],
                userMatches: state.initUserMatches,
                filterResult: state.initFilter,
                searchAddFilterContent: state.initSearchFilter,
            };
        case "ADD_NEW_USER":
            let tempFilterCurrentSearch = state.filterCurrentSearch.find(e => e.value === `NAME${action.user.friendlyName}`) ? state.filterCurrentSearch : [...state.filterCurrentSearch, {
                name: `Name: ${action.user.friendlyName}`,
                value: `NAME${action.user.friendlyName}`
            }];
            return {
                ...state,
                usersToAdd: state.usersToAdd.find(e => e.identity === action.user.identity) ? state.usersToAdd : [action.user, ...state.usersToAdd],
                userMatches: state.userMatches.filter(item => item.userId !== action.user.userId),
                filterCurrentSearch: tempFilterCurrentSearch,
                filterResult: getFilterResult(tempFilterCurrentSearch, state.filterResult),
            };
        case "REMOVE_NEW_USER":
            return {
                ...state,
                usersToAdd: state.usersToAdd.filter(item => item.userId !== action.user.userId),
                userMatches: [action.user, ...state.userMatches],
                filterResult: state.filterResult.filter(e => e.value !== `NAME${action.user.friendlyName}`),
            };
        case "SELECT_ALL_NEW_USERS":
            return {
                ...state,
                userMatches: [],
                usersToAdd: addingDefaultUsers(state.userMatches, state.defaultAutoUsers, state.users),
                filterResult: state.filterCurrentSearch,
            };
        case "FILTER_NEW_USERS":
            return {
                ...state,
                userMatches: setMatchesBasedOnFilter(action.filter, state.users),
                filterCurrentSearch: action.filter
            };
        case "ADD_NEW_USERS_TO_CHANNEL":
            return {...state, usersToAdd: [], userMatches: []};
        case "SET_NEW_MEMBERS":
            return {...state, newMembers: action.newMembers};
        case "ADD_NEW_MEMBER":
            return {...state, newMembers: [...state.newMembers, action.newMember]};
        case "SET_ERROR":
            return {...state, errorMessage: action.errorMessage};
        case "RESET":
            return {
                ...state,
                channel: null,
                channelName: "",
                members: [],
                memberMatches: [],
                users: [],
                matches: [],
                newMembers: [],
                errorMessage: ""
            };
        default:
            return state;
    }
}

type ChannelSettingsProps = {
    channel: Channel;
    channelDispatch: (arg: any) => void;
};

function ChannelSettings(props: ChannelSettingsProps) {
    const {channelDispatch, channel} = props;
    const initialState: ChannelSettingsState = {
        channel: channel,
        channelName: "",
        members: [],
        memberMatches: [],
        users: [],
        matches: [],
        newMembers: [],
        errorMessage: "",
        kindOfChannel: "Group",
        description: "",
        image: null,
        searchFilterContent: [],
        searchAddFilterContent: [],
        userMatches: [],
        usersToAdd: [],
        identities: [],
        schedules: [],
        stations: [],
        skills: [],
        roles: [],
        filterCurrentSearch: [],
        filterResult: [],
        initFilter: [],
        initUserMatches: [],
        initSearchFilter: [],
        currentMembersRefresh: 1.0,
        removedMembers: [],
        permissions: [],
        defaultAutoUsers: [],
        countTagsDefaultAutoUsers: 0,
        tagsDefaultAutoUsers: [],
        teams: [],
        chatVariable: [],
        channelNotification: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(true);

    const setUpGroupChannel = useCallback(
        async () => {
            let {currentIdentities, data, filters, isNotificationMuted} = await fetchDataForChannelGroup(channel);
            if (data) {
                dispatch({
                    type: "SET_UP",
                    users: data.users,
                    members: data.members,
                    previousFilter: filters,
                    roles: data.roles,
                    skills: data.skills,
                    stations: data.stations,
                    schedules: data.schedules,
                    identities: currentIdentities,
                    defaultAutoUsers: data.defaultAutoUsers,
                    teams: data.teams,
                    chatVariable: data.chatVariable,
                    channelNotification: isNotificationMuted,
                });
            }
        },
        [channel],
    );

    const cancelModal = useCallback(() => {
        removeGroupPicture();
        channelDispatch({type: "setOpenChannelSettings", openChannelSettings: false});
    }, [channelDispatch]);

    useEffect(() => {
        setLoading(true);
        try {
            if (channel) {
                dispatch({type: "SET_CHANNEL", channel: channel});
                channel.getAttributes().then(async attributes => {
                    if (channel.friendlyName && channel.attributes["type"] === "Duo") {
                        let channelNameResult = await getChannelFriendlyName(channel);
                        let imageProfile = await getChannelIconImage(channel);
                        dispatch({type: "SET_CHANNEL_IMAGE", image: imageProfile});
                        dispatch({type: "SET_CHANNEL_NAME", channelName: channelNameResult});
                        dispatch({type: "SET_KIND_CHANNEL", kindOfChannel: channel.attributes["kind"]});
                    } else if (channel.friendlyName) {
                        dispatch({type: "SET_CHANNEL_NAME", channelName: channel.friendlyName});
                        dispatch({type: "SET_KIND_CHANNEL", kindOfChannel: channel.attributes["kind"]});
                    }
                    if (attributes["description"]) {
                        dispatch({type: "SET_CHANNEL_DESCRIPTION", description: channel.attributes["description"]});
                    }
                    if (attributes["image"] && channel.attributes["kind"] !== "Duo") {
                        dispatch({type: "SET_CHANNEL_IMAGE", image: channel.attributes["image"]});
                    }
                    if (channel.attributes["kind"] === "Group") {
                        let {currentIdentities, data, filters, isNotificationMuted} = await fetchDataForChannelGroup(channel);
                        if (data) {
                            dispatch({
                                type: "SET_UP",
                                users: data.users,
                                members: data.members,
                                previousFilter: filters,
                                roles: data.roles,
                                skills: data.skills,
                                stations: data.stations,
                                schedules: data.schedules,
                                identities: currentIdentities,
                                defaultAutoUsers: data.defaultAutoUsers,
                                teams: data.teams,
                                chatVariable: data.chatVariable,
                                channelNotification: isNotificationMuted
                            });
                        }
                    }
                    setLoading(false);
                });
            }
        } catch (e) {
            showErrorToast(e, 'Error Fetching Channel details.').then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalWindow width={"700px"} title={"Settings"} cancelModal={cancelModal}>
            {loading && <Loading containerHeight={"400px"} imgHeight={"80px"} margin={'-20px 0 0 0'}/>}
            {!loading && (
                <ChannelsSettingsContent>
                    <Tabs>
                        <TabList>
                            <Tab>Details</Tab>
                            {state.kindOfChannel !== "Duo" && (
                                <>
                                    <Tab>Current Members</Tab>
                                    <Tab>Update Members</Tab>
                                </>
                            )}
                        </TabList>
                        <Panel>
                            <ChannelSettingsModal cancelModal={cancelModal} state={state} dispatch={dispatch}/>
                        </Panel>
                        {state.kindOfChannel !== "Duo" && (
                            <Panel>
                                <ChannelSettingsPanelCurrentMembers
                                    cancelModal={cancelModal}
                                    state={state}
                                    dispatch={dispatch}
                                />
                            </Panel>
                        )}
                        {state.kindOfChannel !== "Duo" && (
                            <Panel>
                                <ChannelSettingsPanelUpdateMembers
                                    cancelModal={cancelModal}
                                    state={state}
                                    dispatch={dispatch}
                                    setUpGroupChannel={setUpGroupChannel}
                                />
                            </Panel>
                        )}
                    </Tabs>
                </ChannelsSettingsContent>
            )}
        </ModalWindow>
    );
}

export default ChannelSettings;
