import React from 'react';
import {styleSheetGetColor, useGetStringFromValue} from "./utils";
import DSPText from "./DSPText";
import ChangeBox from "./ChangeBox";

const MetricValueContainer = ({
    e
                              }) => {
    const {
        metricValue,
        metricIcon,
        metricChange,
        metricChangeBackgroundColor,
        metricChangeBorderColor,
        metricIconColor,
        metricChangeTextColor,
        metricKey,
        week,
        scoreWeek
    } = e;
    const getValue = useGetStringFromValue();
    return (
        <div style={{
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <div style={{marginRight: 16}}>
                <DSPText color={styleSheetGetColor(e.metricTextColor)}>
                    {getValue(metricValue)}
                    {metricKey === "AMAZON_EOC" && Number(scoreWeek) > Number(week) && Number(scoreWeek) !== Number(week) && week !== "" && week !== null && <span style={{fontSize: 12}}>{`  Week ${week}`}</span>}
                </DSPText>
            </div>
            {typeof metricChange === 'string' &&
                metricKey !== 'AMAZON_OVERALL_TIER' && (
                    <ChangeBox
                        metricChange={metricChange}
                        metricChangeBorderColor={metricChangeBorderColor}
                        metricChangeBackgroundColor={metricChangeBackgroundColor}
                        metricIcon={metricIcon}
                        metricIconColor={metricIconColor}
                        metricChangeTextColor={metricChangeTextColor}
                    />
                )}
        </div>
    );
};

export default MetricValueContainer;
