const sorter = (array,field,direction="ASC") => {
    const sorted = array.sort((a, b) => {
        if(direction === "DESC") return (a[field] > b[field]) - (a[field] < b[field]);
        return (b[field] > a[field]) - (b[field] < a[field]);
    });
    return sorted;
};

const AppReduce = (state,action) => {
    const {type, payload} = action;

    switch (type) {
        case 'ADD':
            let sorted = payload.sort((a, b) => {
                return b.drivers.length - a.drivers.length
            });

            if ( state.selectedStation && state.selectedStation !== 'all' )
                sorted = sorted.filter(s => s.station.id === state.selectedStation );

            return {
                ...state,
                data: payload,
                filter: sorted,
                isLoading:false,
            }
        case 'GROUP_DATA':
            state.groupData = payload;
            return {...state};
        case 'LOADING':
            state.isLoading = payload;
            return {...state}
        case 'WEEK':
            return {...state,week:payload.week}
        case 'FILTER':
            let filtered = state.data;
            if ( payload.station !== 'all' )
                filtered = filtered.filter(s => s.station.id === payload.station);

            filtered.sort((a, b) => b.drivers.length - a.drivers.length);

            return {...state, filter: filtered, selectedStation: payload.station};

        default: return state;
    }
}

export default AppReduce;
