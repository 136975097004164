import React, {useEffect, useReducer, useState} from 'react';
// @ts-ignore
import {Button, Icon, Input, Spacer, Text, Theme, Title} from '@dspworkplace/ui';
import Loading from "../../../components/Loading";
import {useSelectedStation} from "../../../components/StationSelector";
// @ts-ignore
import {useForm} from "react-hook-form";
// @ts-ignore
import {Table, TableData, TableFooter, TableHeader} from "../../../components/Table";
import {confirm} from '../../../components/Confirm';
// @ts-ignore
import {alert} from "../../../components/Alert";
import {fetchVehicleImageCategories, updateVehicleImageCategory} from "./utils";
// @ts-ignore
import AddAndEditForm from "./AddAndEditForm";

export type VehicleImageCategory = {
    id: number;
    name: string;
    isArchive?: boolean;
    checked?: boolean;
    forDriver?: boolean;
}

type VehicleImageCategoriesState = {
    data: Array<VehicleImageCategory>,
    updateKey: number,
    filterName: string,
    filterOrder: 'DESC' | 'ASC',
    filterType: 'str',
    isLoading: boolean,
    isEdit: boolean,
}

const initialState: VehicleImageCategoriesState = {
    data: [],
    // oldSupplies: [],
    updateKey: -1,
    filterName: 'item',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
    isEdit: false,
};

export type VehicleImageCategoriesAction =
    | { type: 'SET_DATA'; data: Array<VehicleImageCategory>; }
    | { type: 'ADD'; item: VehicleImageCategory; }
    | { type: 'EDIT_NAME'; item: VehicleImageCategory; }
    | { type: 'ARCHIVE'; item: VehicleImageCategory; }
    | { type: 'SET_LOADING'; isLoading: boolean; }
    | { type: 'SET_UPDATE_KEY'; updateKey: number; }
    | { type: 'RESET'; }


const reducer = (state: VehicleImageCategoriesState, action: VehicleImageCategoriesAction) => {
    switch (action.type) {
        case "RESET":
            return {...state, updateKey: -1, isEdit: false, isLoading: false};
        case "SET_DATA":
            return {...state, data: action.data, isLoading: false, updateKey: -1};
        case "ADD":
            return {...state, data: [...state.data, action.item], updateKey: -1, isLoading: false};
        case "ARCHIVE":
            return {...state, data: state.data.filter(e => e.id !== action.item.id), isLoading: false, updateKey: -1}
        case "SET_LOADING":
            return {...state, isLoading: action.isLoading};
        case "SET_UPDATE_KEY":
            return {...state, updateKey: action.updateKey};
        case "EDIT_NAME":
            return {
                ...state,
                updateKey: -1,
                isLoading: false,
                data: state.data.map(e => {
                    // console.log(e);
                    // console.log(action.item);
                    // console.log(e.id === action.item.id);
                    if (e.id === action.item.id) {
                        return action.item;
                    }
                    return e;
                })
            }
        default:
            return state;
    }
};


const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [filter] = useState({order: {name: 'ASC'}});
    const [stationId] = useSelectedStation();

    const archiveHandler = async (item) => {
        try {
            const confirmation = await confirm('Confirm that you want to archive this item');
            if (!confirmation) {
                return false;
            }
            dispatch(({type: 'SET_LOADING', isLoading: true}));
            let res = await updateVehicleImageCategory(item, 'ARCHIVE');
            dispatch({type: 'ARCHIVE', item: res!});
        } catch (e) {
            console.error({e});
            dispatch(({type: 'SET_LOADING', isLoading: false}));
        }
    }

    const addHandler = async (item) => {
        try {
            const confirmation = await confirm('Confirm that you want to add this item');
            if (!confirmation) {
                return false;
            }
            dispatch(({type: 'SET_LOADING', isLoading: true}));
            let res = await updateVehicleImageCategory(item, 'ADD');
            dispatch(({type: 'ADD', item: res!}));
        } catch (e) {
            console.error({e});
            dispatch(({type: 'SET_LOADING', isLoading: false}));
        }
    }

    const editHandler = async (item) => {
        try {
            const confirmation = await confirm('Confirm that you want to edt this item');
            if (!confirmation) {
                return false;
            }
            dispatch(({type: 'SET_LOADING', isLoading: true}));
            let res = await updateVehicleImageCategory(item, 'EDIT_NAME');
            dispatch(({type: 'EDIT_NAME', item: res!}));
        } catch (e) {
            console.error({e});
            dispatch(({type: 'SET_LOADING', isLoading: false}));
        }
    }

    const cancelHandler = async () => {
        try {
            dispatch({type: 'RESET'});
        } catch (e) {
            console.error({e});
        }
    }

    useEffect(() => {
        fetchVehicleImageCategories(dispatch).then();
    }, [filter]);

    return (
        <>
            <Spacer top={5}/>
            <Title>Vehicle Image Categories</Title>
            <Spacer top={1}/>
            <Text>Set categories names for all of your vehicles.</Text>
            <Spacer top={5}/>
            <Table width={'450px'}>
                <TableHeader
                    headers={[
                        {width: '100%', label: 'Name'},
                    ]}
                    sortByFunc={undefined} sortedBy={undefined} compact={undefined}
                />
                {state.isLoading && <Loading style={{height: '100px', marginTop: '20px'}}/>}
                {!state.isLoading && state.data && Array.isArray(state.data) &&
                state.data.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
                    if (index === state.updateKey) {
                        return (
                            <AddAndEditForm key={index} index={index} isEdit={true} dispatch={dispatch}
                                            data={state}
                                            stationId={stationId} selected={item} add={addHandler} edit={editHandler}
                                            cancel={cancelHandler}/>
                        );
                    }
                    return (
                        <li key={index}>
                            <TableData width='366px'>{item.name}</TableData>
                            <TableData width='84px' style={{verticalAlign: 'middle', height: '40px'}}>
                                <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => {
                                    dispatch({type: 'SET_UPDATE_KEY', updateKey: index});
                                }}/>
                                <Spacer inline right={3}/>
                                <Icon.Times size='20px' color={Theme.colors.info.border} onClick={() => {
                                    archiveHandler(item).then()
                                }}/>
                            </TableData>
                        </li>
                    )
                })
                }
                {!state.isLoading && state.data && <AddAndEditForm
                    dispatch={dispatch}
                    data={state} stationId={stationId} isEdit={false} add={addHandler} edit={editHandler}
                    cancel={cancelHandler}/>}
            </Table>
        </>
    );
}

export default App;
