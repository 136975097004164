import React, {useContext} from "react";
import styled from "styled-components";
import {Button, ButtonDropdown, Spacer, Theme, Title} from "@dspworkplace/ui";
import IncidentsContext from "../context";

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 20px;
    padding-top: 20px;
    padding-left: 164px;
    padding-right: 132px;
    // border-bottom: 1px solid ${Theme.colors.info.shadow};
`;

const ViewSwitcher = ({views, children}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Title>{children}</Title>
            <ButtonDropdown
                type={'cancel'}
                text={''}
                divider={false}
                options={views}
            />
        </div>
    )
};

const IncidentsHeader = () => {
    const [state, dispatch] = useContext(IncidentsContext);
    const views = state.views.map(v => ({
        ...v,
        fn() {
            dispatch({
                type: 'SET_VIEW',
                payload: {
                    id: v.id,
                    filters: v.filters
                }
            })
        }
    }));

    return (
        <Header>
            <Title>{state.currentView.text}</Title>
            {/*
            <ViewSwitcher views={views}>
                {state.currentView.text}
            </ViewSwitcher>
            <div>
                <ButtonDropdown
                    type={'default'}
                    text='Export'
                    divider={false}
                    options={[
                        {
                            text: 'CSV', fn: () => {
                            }
                        },
                        {
                            text: 'Excel', fn: () => {
                            }
                        },
                        {
                            text: 'PDF', fn: () => {
                            }
                        },
                    ]}
                />
                <Spacer inline right={3}/>
                <Button>Share</Button>
                <Spacer inline right={3}/>
                <Button type='primary'>New View</Button>
            </div>
            */}
        </Header>
    )
};

export default IncidentsHeader;