import React, {useCallback, useContext, useEffect, useState, useRef} from "react";
import styled from 'styled-components';
import {Table, TableData, TableHeader} from "../../../components/Table";
import Moment from "moment";
import Loading from "../../../components/Loading";
import DefaultStore from "./ContextStore";
import {Theme, ThirdTitle, SubTitle} from "@dspworkplace/ui";
import { useAuth } from "../../../Auth";
import {Tag} from "../../../components/UI";
import Empty from '../../../components/Empty';


const FooterTab = styled.div`
    display:flex;
    align-items:center;
    background:#FAFAFA;
    padding:0px;
    border-top:1px solid ${Theme.colors.info.text};
    max-width: 1150px;
    width: 100%;

    &.fixed {
        position:fixed;
        bottom:0;
    }


    >h3 {
        font-weight:normal;
    }
`;

const App = ({view,date,context}) => {

    const week =  (Moment(date).day() === 0) ?  Moment(date).add(1, "days").isoWeek() : Moment(date).isoWeek();
    const year = (Moment(date).day() === 0) ? Moment(date).add(1, "days").isoWeekYear() : Moment(date).isoWeekYear();
    const [state,dispatch] = useContext(DefaultStore);
    const HeaderDays = solveHeader(week,year);
    const { api } = useAuth();
    const fetchData = async (week, year) => {
          const params={
            "actions": {
                "response": {
                  "GradingSettings": {
                    "findBy": {
                         "criteria": {
                           "company":localStorage.getItem('company')
                         },
                         "get": "GradingSettings",
                         "includes": {
                           "0":"id",
                           "1":"color",
                           "2":"min",
                           "3":"max",
                           "4":"description",
                             5: "textColor"
                         },
                         "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                      }
                   },
                   "RouteCommitment": {
                        "custom": {
                                "functionName": "getImpliedBalance",
                                "get": "BalanceGroup",
                                "criteria": {"company":localStorage.getItem('company'),"week":week,"year":year,"station":state.selectedStation
                            }
                        }
                    }
               }
            }
          }
          try{
            let response = await api.post('/api/lazy/manage/data', params);
            const result = {groups:response.data.data.BalanceGroup.mainData, rules: response.data.data.GradingSettings,station: response.data.data.BalanceGroup.stationData,context:context};
            dispatch({type:'ADD',payload:result})
          }catch(error){
              if (error.response.status == '401') {
                  window.location = "/logout";
              }
          }
    }

    useEffect(()=>{
        const year = parseInt(Moment(date).startOf('week').format("YYYY"));
        fetchData(week, year)
    },[date]);

    return (
        <Table>
            <TableHeader
                headers={[
                    {width: '157px', label: 'Balance Group', sortBy: 'balanceGroup'}
                ].concat(HeaderDays.map(day => ({width: `104px`, label: day}))).concat([
                    {width: '79px', label: 'Total', sortBy: 'total'}
                ])}
                sortedBy={state[context].sortedBy}
                sortByFunc={sortBy => {
                    dispatch({
                        type: 'SORT',
                        payload: {sortBy:sortBy,context:context}
                    })
                }}
            />
            {state[context].isLoading && <Loading style={{height:40,padding:20,margin:'0 auto'}} />}
            {!state[context].isLoading && state[context].data && <><ListItems context={context} view={view} data={state[context].filter} date={date} /><Footer view={view} data={state[context].filter} /></>}
        </Table>
    )
}

const ListItems = ({data, view, context, date}) => {
    const [state, d] = useContext(DefaultStore);

    return !data.length ? (
        <li>
            <Empty/>
        </li>
    ) : (
        <>
            {data.map((item, k) => {
                let total = Object.keys(item).map(k => {
                    if (typeof item[k] === 'object')
                        return item[k].routes;
                }).reduce((t, n) => !!parseInt(n) ? t+n : t, 0);

                let drivers = Object.keys(item).map(k => {
                    if (typeof item[k] === 'object')
                        return item[k].driver;
                }).reduce((t, n) => !!parseInt(n) ? t+n : t, 0);

                let avg = Math.round(drivers / 7);
                let weeks = [];
                let diffs = [];

                for(let i=0;i<7;++i){
                    let d = item[`w${i}`];

                    if (view === 'count')
                        weeks.push(<WeekBox key={i} data={item[`w${i}`]} index={i} balanceGroupId={item.id} date={date} />);
                    else
                        weeks.push(
                            <TableData width='104px' style={{
                                paddingBottom: 0,
                                fontWeight: 'bold'
                            }}>
                                {d.driver}
                            </TableData>
                        );

                    const percent = (( d.driver / d.routes ) * 100).toFixed(2);
                    const balance = d.driver - avg;

                    const rule = solveWeekClass(percent, state.rules);

                    diffs.push(
                        <TableData
                            key={i}
                            style={{
                                // paddingTop: 0,
                                paddingBottom: 16,
                            }}
                            width='104px'
                        >
                            <Tag small style={{
                                background: rule.color,
                                borderColor: rule.color,
                                color: rule.textColor || '#333'
                            }}>{balance}</Tag>
                        </TableData>
                    )
                }

                return view === 'average' ? (
                    <li key={k}>
                        <Table>
                            <li>
                                <TableData width='157px' style={{
                                    paddingBottom: 0,
                                    fontWeight: 'bold'
                                }}>{item.balanceGroup}</TableData>
                                {weeks}
                                <TableData width='118px' style={{
                                    paddingBottom: 0,
                                    fontWeight: 'bold'
                                }}>{drivers}</TableData>
                            </li>
                            <li>
                                <TableData width='157px' style={{
                                    // paddingTop: 0,
                                    paddingBottom: 16
                                }}><small>Balance</small></TableData>
                                {diffs}
                                <TableData width='118px' style={{
                                    paddingBottom: 16,
                                }}><small>{avg} avg</small></TableData>
                            </li>
                        </Table>
                    </li>
                ) : (
                    <li key={k}>
                        <TableData width='157px'>{item.balanceGroup}</TableData>
                        {weeks}
                        <TableData width='79px'>{total}RC</TableData>
                    </li>
                )
            })}
        </>
    )
}

const WeekBox = ({data, index, balanceGroupId, date}) => {
    const [state,dispatch] = useContext(DefaultStore);
    const [open, setOpen] = useState(false);
    const innerRef = useRef();
    const percent = (( data.driver / data.routes ) * 100).toFixed(2);

    const rule = solveWeekClass(percent,state.rules);
    // const rule = solveWeekClass(data.driver / data.routes,state.rules);

    const handleClick = useCallback((e)=>{
        if (innerRef.current && !innerRef.current.contains(e.target)) {
            setOpen(false);
        }else{
            if(!open) setOpen(true);
        }

    })
    useEffect(()=>{
        document.addEventListener('mouseup',handleClick);

        return () => {
            document.removeEventListener('mouseup',handleClick);
        }
    },[])

    return (
        <TableData ref={innerRef} width='104px' style={{
            position:'relative',
            padding:'20px 8px',
            // paddingBottom: 0,
            cursor:'pointer',
            // fontWeight: 'bold',
            background: rule && rule.color,
            color: rule && rule.textColor
        }}>
            {data.driver}D / {data.routes}RC
            {open && <WeekBoxDetials index={index} balanceGroupId={balanceGroupId} date={date}/> }
        </TableData>
    )
}

const ModalDiv = styled.div`
    position:absolute;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #33333326;
    padding:12px 16px;
    width:772px;
    z-index:2;
    cursor:default;
    top:0;
    left:108px;
    overflow-y:auto;

    &:before {
        content : " ";
        width: 0;
         height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right:6px solid #e4e4e4;
        position:absolute;
        left:-6px;
        top:23px;
    }
`

const WeekBoxDetials = ({index, balanceGroupId,date}) => {
    const [fullState,dispatch] = useContext(DefaultStore);

    const [state,setData] = useState({schedules:[],shifts:[],timeOff:[],isLoading:true})
    
    let isoWeek =  (Moment(date).day() === 0) ?  Moment(date).add(1, "days").isoWeek() : Moment(date).isoWeek();
    let isoYear = (Moment(date).day() === 0) ? Moment(date).add(1, "days").isoWeekYear() : Moment(date).isoWeekYear();
    const HeaderDays = solveHeader(isoWeek,isoYear);
    const { api } = useAuth();
    const clickDate = Moment(date).weekday(index).format('YYYY-MM-DD');
    const fetchAll = async () => {

      const params={
                      "actions": {
                          "response": {
                              "BalanceGroup": {
                                  "custom": {
                                      "functionName": "getImpliedBalanceBySchedule",
                                      "get": "BalanceGroup",
                                      "criteria": {
                                          "company":localStorage.getItem('company'),
                                          "id":balanceGroupId,
                                          "weekDate":clickDate
                                      }
                                  }
                              }
                          }
                     }
                  }
      try{
        let response = await api.post('/api/lazy/manage/data', params);
        const result = {
            shifts: response.data.data.BalanceGroup.shifts,
            schedules:response.data.data.BalanceGroup.schedules,
            timeOff:[]
        };
        setData({schedules:result.schedules,shifts:result.shifts,timeOff:result.timeOff,isLoading:false})

      }catch(error){
          if (error.response.status == '401') {
              window.location = "/logout";
          }
      }

    }

    useEffect(()=>{
        fetchAll();
    },[])

    let listTotal = {};
    let footerTotal = 0;
    let timeOffTotal = 0;


    return (
        <ModalDiv>
            <SubTitle>By Schedules For {HeaderDays[index]}</SubTitle>
            {state.isLoading && <Loading style={{height:100,padding:20,margin:'0 auto',display:'block'}} />}
            {!state.isLoading && <Table>
                <li className='header' style={{display:'flex'}}>
                    <TableData width='132px'>Skills</TableData>
                    {state.shifts.map((item,key)=>(
                        <TableData width='120px' key={key}>{item}</TableData>
                    ))}
                    <TableData width='100px'>Total</TableData>
                </li>
                {state.schedules.map((item,key)=>{
                    let listItem = [];
                    let total = 0;
                    for(let i=0; i<state.shifts.length;++i){
                        if(!listTotal[`s${i}`]) listTotal[`s${i}`] = 0;

                        if(item[`s${i}`]) {
                            total += parseInt(item[`s${i}`])
                            listTotal[`s${i}`] += parseInt(item[`s${i}`]);
                        }
                        listItem.push(<TableData width='120px' key={i}>{item[`s${i}`] ? item[`s${i}`] : '-'}</TableData>);
                    }
                    return (
                        <li key={key} style={{display:'flex'}}>
                            <TableData width='132px'><Tag options={item.skills} /></TableData>
                            {listItem}
                            <TableData width='100px' style={{fontWeight:'bold'}}>{total}</TableData>
                        </li>
                    )
                })}
                <FooterTab style={{padding:'16px 0'}}>
                    <TableData width='132px' style={{color:'#516F90'}}>{state.schedules.length} Skills</TableData>
                    {Object.values(listTotal).map((t,k)=>{
                        footerTotal += parseInt(t);
                        return <TableData width='120px' key={k} style={{color:'#516F90'}}>{t ? t : '-'}</TableData>
                    })}
                    <TableData width='100px' style={{color:'#516F90'}}>{footerTotal}</TableData>
                </FooterTab>
                <FooterTab style={{padding:'16px 0',border:'none'}}>
                    <TableData width='160px'>Time Off Employees</TableData>
                    {state.timeOff.map((t,k)=> {
                        timeOffTotal += parseInt(t);
                        return <TableData width='120px' key={k}>{t ? t : '-'} </TableData>
                    })}
                    <TableData width='100px' style={{fontWeight:'bold'}}>{timeOffTotal}</TableData>
                </FooterTab>
            </Table>}
        </ModalDiv>
    )
}


const Footer = ({data, view}) => {
    let weekTotal = {w0:{driver:0,routes:0},w1:{driver:0,routes:0},w2:{driver:0,routes:0},w3:{driver:0,routes:0},w4:{driver:0,routes:0},w5:{driver:0,routes:0},w6:{driver:0,routes:0}};
    let total = {driver:0,routes:0};

    data.map((item, k) => {
        for (let i = 0; i < 7; ++i) {
            weekTotal[`w${i}`].driver += parseInt(item[`w${i}`].driver);
            weekTotal[`w${i}`].routes += parseInt(item[`w${i}`].routes);

            total.driver += parseInt(item[`w${i}`].driver);
            total.routes += parseInt(item[`w${i}`].routes);
        }
    })

    return (
        <FooterTab>
            <TableData width='157px' style={{padding:'20px 8px'}}>{data.length} balance groups</TableData>
            {Object.values(weekTotal).map((item,k)=>{
                return <TableData key={k} width='104px'>
                    {view === 'count' ? (
                        `${item.driver}D / ${item.routes}RC`
                    ) : (
                        item.driver
                    )}
                </TableData>
            })}
            {view === 'count' ? (
                <TableData width='118px' style={{padding:'20px 12px',fontWeight:'bold'}}>{total.driver}D / {total.routes}RC</TableData>
            ) : (
                <TableData width='118px' style={{padding:'20px 12px',fontWeight:'bold'}}>{total.driver}</TableData>
            )}
        </FooterTab>
    )
}

const solveWeekClass = (percent,rules) =>
{
    return rules.find((rule)=>{
        let min = rule.min != null ?  rule.min : -9999999999999;
        let max = rule.max != null ? rule.max : 9999999999999;

        return parseFloat(percent) >= parseFloat(min) && parseFloat(percent) <= parseFloat(max);
    }) || {
        color: 'unset',
        textColor: Theme.colors.neutrals.medium
    };
}

const solveHeader = (week,year) => {
        const firstDay = Moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek');
        let days = [];
        for(let i=0;i<7;++i){
            days.push(firstDay.weekday(i).format('ddd/DD'))
        }
        return days;
}


export default App;
