import { uploadMedia } from "../../../components/Upload";
import moment from "moment";

import { getUserId, getDriverId, getFriendlyName } from "../../../Auth";

const getDefaultFields = (company, id = 0, station = 0, selectedDates) => {
    const ret = {
        get: "vehicles",
        criteria: {
            company: company,
            isArchive: false,
        },

        matching: selectedDates,

        excludes: {
            0: "company",
            1: "skill",
        },
        includes: {
            0: "id",
            1: "dateEnd",
            2: "dateStart",
            3: "fuelCardNumber",
            4: "gasType",
            5: "height",
            6: "insuranceCompany",
            7: "licensePlate",
            8: "licensePlateExpiration",
            9: "make",
            10: "model",
            11: "modelType",
            12: "notes",
            20: "policyNumber",
            21: "state",
            22: "status",
            23: "tankCapacity",
            24: "tollCardNumber",
            25: "type",
            26: "vehicleId",
            27: "vin",
            28: "year",
            30: "unitNumber",
            31: "overnightParkingLocation",
            32: "currentMileage",
            33: "currentGasTankLevel",
            34: "currentUsedBy",
            35: "tollCardStartDate",
            36: "tollCardEndDate",
            37: "eldUnitCode",
            38: "amazonBranded",
            39: "cubicFeetStorage",
            40: "unenroll",
            41: "insuranceCard",
            42: "isVehicleServiceRecordChanged",
            maintenancePlan: {
                0: "id",
                1: "name"
            },
            station: {
                0: "id",
                1: "code",
                2: "name",
                3: "stationAddress",
            },
            skill: {
                0: "id",
                1: "name",
            },
        },
    };
    if (id > 0) {
        ret["criteria"]["id"] = id;
    }

    if (station > 0) {
        ret["criteria"]["station"] = station;
    }

    return ret;
};

const fetchVehicles = async (api, company, selectedStation, selectedDates) => {
    let matchingdate = {};

    if (selectedDates) {
        matchingdate = {
            vehicleImages: {},
            "vehicleImages.createdAt": { between: [moment(selectedDates.start).utc().format('YYYY-MM-DD HH:mm'), moment(selectedDates.end).utc().format('YYYY-MM-DD HH:mm')] },
        };
    }

    const findCriteria = getDefaultFields(company, 0, selectedStation, matchingdate);

    const params = {
        actions: {
            response: {
                Vehicle: {
                    findBy: findCriteria,
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data.vehicles;
    } catch (error) {}
};

const fetchSkills = async (api, company) => {
    const params = {
        actions: {
            response: {
                Skill: {
                    findBy: {
                        criteria: { company: company },
                        get: "Skills",
                        orderBy: {
                            name: "ASC",
                        },
                        includes: {
                            0: "id",
                            1: "name",
                        },
                        excludes: [
                            "company",
                            "skill",
                            "users",
                            "parent",
                            "childrens",
                            "driverRoutes",
                            "payRate",
                            "skillRates",
                            "shifts",
                            "drivers",
                            "vehicles",
                        ],
                    },
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data.Skills;
    } catch (error) {}
};

const AddOrUpdateVehicle = async (api, data, id = 0, oldVehicleId) => {
    let params;
    data.station = data.stationId;
    delete data.stationId;

    let files = [];
    let files_id = [];
    if (data.file && data.file.length > 0) {
        files = data.file;
        files_id = data.files_id;
    }
    delete data.file;
    delete data.file_id;
    delete data.files_id;

    if (id < 1) {
        params = {
            actions: {
                create: {
                    Vehicle: {
                        vehicle_1: data,
                    },
                },
            },
        };
    } else {
        const company = data.companyId;
        //const id = data.id;
        delete data.companyId;
        delete data.id;
        const findCriteria = getDefaultFields(company, id);

        params = {
            actions: {
                update: {
                    Vehicle: {
                        Vehicle: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: data,
                        },
                    },
                },
                response: {
                    Vehicle: {
                        findBy: findCriteria,
                    },
                },

                create:
                    data.vehicleId != oldVehicleId
                        ? {
                              Event: {
                                  Event_1: {
                                      name: "VEHICLE_VEHICLE-ID_CHANGE",
                                      eventName: "Vehicle ID change",
                                      createdAt: moment(),
                                      user: getUserId(),
                                      vehicle: id,
                                      message: `Vehicle ID changed by ${getFriendlyName()}. From: ${oldVehicleId} To: ${
                                          data.vehicleId
                                      }`,
                                      driver: getDriverId(),
                                  },
                              },
                          }
                        : {},
            },
        };
    }

    try {
        const responseFilterData = await api.post("/api/lazy/manage/data", params);

        if (files.length > 0) {
            await uploadMedia(files, "Vehicle", files_id, id);

            await api.post("/api/lazy/manage/data", {
                actions: {
                    create: {
                        Event: {
                            Event_1: {
                                name: "VEHICLE_NEW_cONTRACT",
                                eventName: "Vehicle New contract",
                                createdAt: moment(),
                                user: getUserId(),
                                vehicle: id,
                                message: `A new contract was uploaded by ${getFriendlyName()}.`,
                                driver: getDriverId(),
                            },
                        },
                    },
                },
            });
        }

        if (id > 0) {
            return responseFilterData.data.data.vehicles[0];
        } else {
            const findCriteria = getDefaultFields(data.company, responseFilterData.data.data.responseId);
            const params = {
                actions: {
                    response: {
                        Vehicle: {
                            findBy: findCriteria,
                        },
                        Media: {
                            findBy: {
                                criteria: {
                                    type: "Vehicle",
                                    entityId: responseFilterData.data.data.responseId,
                                },
                                get: "media",
                                includes: ["id", "path"],
                            },
                        },
                    },
                },
            };
            const response = await api.post("/api/lazy/manage/data", params);

            return response.data.data.vehicles;
        }
    } catch (error) {}
};

const deleteVehicle = async (api, id) => {
    const params = {
        actions: {
            update: {
                Vehicle: {
                    vehicle: {
                        findBy: {
                            id: id,
                        },
                        updateRecord: {
                            isArchive: true,
                        },
                    },
                },
            },
            response: {
                Vehicle: {
                    custom: {
                        functionName: "updateFutureDriverRouteNull",
                        get: "result",
                        excludes: [],
                        criteria: {
                            id: id,
                        },
                    },
                },
            },
        },
    };

    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        await alert("Error occurred");
    }
};

const updateVehicle = async (api, id, status, vehicleId, reason = "") => {
    let params;

    params = {
        actions: {
            update: {
                Vehicle: {
                    vehicle_1: {
                        findBy: {
                            id: id,
                        },
                        updateRecord: {
                            status: status,
                            vehicleId: status == "Archived" ? `${vehicleId}_${moment().format("YYYYMMDD")}` : vehicleId,
                        },
                    },
                },
            },

            create:
                status == "Archived" || reason != ""
                    ? {
                          Event: {
                              Event_1: {
                                  name: reason != "" ? "VEHICLE_UN-ARCHIVED" : "VEHICLE_ARCHIVED",
                                  eventName: reason != "" ? "Vehicle un-archived" : "Vehicle archived",
                                  createdAt: moment(),
                                  user: getUserId(),
                                  vehicle: id,
                                  message:
                                      reason == ""
                                          ? `Vehicle archived by ${getFriendlyName()}`
                                          : `Vehicle un-archived by ${getFriendlyName()}. Reason ${reason}`,
                                  driver: getDriverId(),
                              },
                          },
                      }
                    : {},
        },
    };

    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        await alert("Error occurred");
    }
};

export { AddOrUpdateVehicle, fetchVehicles, deleteVehicle, fetchSkills, updateVehicle };
