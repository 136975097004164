import React from "react";
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";
import { Grid } from "../../../../index";
import TimeFormat from "../../../../../../../../../components/TimeFormat";
import { covertUtcToLocal } from "../../../../../../../../../components/TimeFormat";
import moment from 'moment';

const Circle = styled.div`
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    background-color: ${(props) => (props.color ? props.color : "#516F90")};
    border-radius: 50%;
    opacity: 1;
`;

const Schedule = ({ elem, week, row, stationTimeZone }) => {
    let isDST = moment().isDST();
    return (
        <Grid templateColumns="196px repeat(7, auto)">
            <Grid
                templateColumns="24px auto"
                templateRows="24px 20px"
                height="100%"
                columnGap="8px"
                padding="12px 0 0 12px"
                borderRight="1px dashed #CBD6E2"
                borderBottom="1px dashed #CBD6E2"
            >
                <Grid>
                    {elem.category === 1 ? (
                        <Circle color={elem.color}></Circle>
                    ) : elem.category === 2 ? (
                        <Circle color={elem.color}>
                            <Icon.Replace size="20px" color="#ffffff"></Icon.Replace>
                        </Circle>
                    ) : elem.category === 3 ? (
                        <Circle color={elem.color}>
                            <Icon.GraduationHat size="20px" color="#ffffff"></Icon.GraduationHat>
                        </Circle>
                    ) : (
                        ""
                    )}
                </Grid>
                <Grid color="#516F90">{elem.name}</Grid>
                <Grid></Grid>
                <Grid>
                    {
                        isDST ?
                        (
                            <>
                                {covertUtcToLocal(elem.startTimeOnDst, stationTimeZone)}-{covertUtcToLocal(elem.endTimeOnDst, stationTimeZone)}
                            </>
                        ) :
                        (
                            <>
                                {covertUtcToLocal(elem.startTime, stationTimeZone)}-{covertUtcToLocal(elem.endTime, stationTimeZone)}
                            </>
                        )
                    }
                </Grid>
            </Grid>
            {elem.days &&
                elem.days.map((item, index) => {
                    var value = Object.entries(item)[0][1];
                    if (value === 1) {
                        return (
                            <Grid
                                templateColumns="auto"
                                padding="0 0 0 40px"
                                height="100%"
                                key={`${week}${row}${index}x400`}
                            >
                                <Grid height="100%">
                                    <Icon.Check size="24px" color="#7C98B6"></Icon.Check>
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid
                            templateColumns="auto"
                            padding="0 0 0 40px"
                            height="100%"
                            key={`${week}${row}${index}x400`}
                        >
                            <Grid height="100%">
                                <Icon.Times size="24px" color="#F5F5F5"></Icon.Times>
                            </Grid>
                        </Grid>
                    );
                })}
        </Grid>
    );
};

export default React.memo(Schedule);
