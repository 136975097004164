import styled from 'styled-components';

const SSkeletonPulse = styled.div.attrs({className:'skeleton'})`
  display: inline-block;
  height: ${props => props.height ? `${props.height}px` : '100%'};
  width: ${props => props.width ? `${props.width}px` : '100%'};
  background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation: ${props => props.animation ? 'none' : 'pulse 1.2s ease-in-out infinite'};
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export default SSkeletonPulse;
