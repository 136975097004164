import React from 'react';
import {Spacer, Title} from "@dspworkplace/ui";
import Filter from './filter.js';
import List from './List';
import {AppProvider} from './ContextStore'
import {Wrapper} from "../../../components/UI";

const App = () => {
    return (
        <Wrapper>
            <AppProvider>
                <Spacer top={5} />
                <Title>Employee Balance</Title>
                <Spacer top={5} />

                <Filter />
                <Spacer top={5}/>

                <List />
                <Spacer top={3}/>
            </AppProvider>
        </Wrapper>
    )
}
export default App;
