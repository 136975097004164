import { Button, Icon } from '@dspworkplace/ui';
import React from 'react'
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTrackedState as useBotState } from "./Store";
import { MessageInput } from 'stream-chat-react';

function BotInput({ submitMessage, placeholder = "Thank You", disabled = true, submitButtonText = "Send", disableSendButton = false }) {
    const state = useBotState();
    const { channel } = state;
    const fileUpload = `${channel?.id}-file-upload`;
    const { register, handleSubmit, reset } = useForm();

    const submit = async (form) => {
        let message = form.message;
        if (typeof message === "string" && message.trim().length !== 0) {
            if (submitMessage) {
                await submitMessage(message);
                reset();
            }
        } else {
            if (submitMessage) {
                await submitMessage(placeholder);
                reset();
            }
        }
        reset();
    };

    const onChangeHandler = e => {
        let value = e.target.value;
        if (value.charAt(0) === '\n' || value.charAt(0) === '\r') {
            reset();
            return;
        }
        // if(typeof value === "string" && value.includes(placeholder)){
        //     setValue({message: placeholder});
        // }
    };

    return (
        <div style={{ display: 'block', width: '61%',justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '12px', }} >
            <Form className="form" onSubmit={handleSubmit(submit)} style={{ alignItems: 'center' }}>
                <Input
                    placeholder={placeholder}
                    autoFocus={true}
                    name='message'
                    label='message'
                    ref={register({ required: false })}
                    disabled={disabled}
                    onChange={onChangeHandler}
                // value={placeholder}
                />
                <Button style={{ display: 'contents' }} >{submitButtonText}</Button>
            </Form>
        </div>
    )
    // return (
    //     <Form className="form">

    //         <Send>
    //             {/* <Input
    //                 placeholder={placeholder}
    //                 autoFocus={true}
    //                 name='message'
    //                 label='message'
    //                 ref={register({ required: false })}
    //                 disabled={disabled}
    //             // onKeyDown={onKeyDownHandler}
    //             // onChange={onChangeHandler}
    //             // value={placeholder}
    //             /> */}
    //             {/* <LabelFile htmlFor={fileUpload} > */}
    //             <Icon.Image size='21px' color='#7a7a7a' />
    //             {/* </LabelFile> */}
    //             <InputFile
    //                 id={fileUpload}
    //                 type="file"
    //                 // onChange={e => submitMedia(e)} 
    //                 multiple />
    //             <Input
    //                 placeholder={placeholder}
    //                 autoFocus={true}
    //                 name='message'
    //                 label='message'
    //                 ref={register({ required: false })}
    //                 disabled={disabled}
    //             // onKeyDown={onKeyDownHandler}
    //             // onChange={onChangeHandler}
    //             // value={placeholder}
    //             />
    //             <DSPButtonContainer>
    //                 {/* <CustomButton> */}
    //                 <Icon.DspwNetwork size='21px' color='7a7a7a' />
    //                 {/* </CustomButton> */}
    //             </DSPButtonContainer>
    //         </Send>
    //         <SendButton>
    //             <CustomButton type='submit' width={"100%"}
    //                 padding={"0 8px 0 8px"}
    //                 disabled={disableSendButton}>{submitButtonText}</CustomButton>
    //             {/*<ParallelBorder/>*/}
    //         </SendButton>
    //     </Form>
    // )
}

export default BotInput

const Form = styled.form`
    display: grid;
    grid-template-columns: 90% 8%;
    grid-row-gap: 8px;
    border-top: 1px dashed #7C98B6;
    padding: 8px 0 8px 0;
    justify-content: space-between;
    width: -webkit-fill-available;
`;
const Input = styled.textarea`
    border:none;
    border-radius: 2px;
    opacity: 1;
    width: 300px;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 12px;
    position: relative;
    z-index: 7;
    width: -webkit-fill-available;
    margin-left:10px;
    background: whitesmoke;

    &:hover, &:hover, &:focus, &:active {
        border:none;
    }

    .autocomplete {

        color: silver;
        z-index: 1;
    }
`;

const Send = styled.div`
    min-height: 56px;
    background: whitesmoke;
    border-radius: var(--border-radius-md);
    padding: 10px 10px;
    font-size: var(--lg-font);
    line-height: 17px;
    border: none;
    margin: 0;    
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SendButton = styled.div`
    width: fit-content;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-self: end;
    margin-left: 10px;
`;
// @ts-ignore
const ParallelBorder = styled.div`
    border-color: #CBD6E2;
    border-style: dashed;
    width: 0;
    height: 40px;
    border-width: 1px;
    background-color: #EAF0F6;
`;

const CustomButton = styled(Button)`
    width: ${props => props.width ? props.width : '40px'};
    height: ${props => props.height ? props.height : '40px'};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.padding ? props.padding : '0'};;
    justify-self: ${props => props.direction ? props.direction : 'end'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-right-radius: ${props => props.bbrr ? props.bbrr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-right-radius: ${props => props.btrr ? props.btrr : props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-left-radius: ${props => props.bblr ? props.bblr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-left-radius: ${props => props.btlr ? props.btlr : props.borderRadius ? props.borderRadius : '2px'};

    &:active, &:focus, &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }
`;

const LabelFile = styled.label`
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background-color: #EAF0F6;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};

    &:hover {
        background-color: #CBD6E2;
    }
`;

const InputFile = styled.input`
    display: none;
`;

const DSPButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;