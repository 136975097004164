import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    Spacer,
    ThirdTitle,
    Uploader,
    RadioBox,
    Dropdown,
    CheckBox,
    Alert,
    Icon,
    Theme,
    CustomDropdown
} from "@dspworkplace/ui";
import { useForm } from "react-hook-form";
import Input from '../../components/Input';

import FormSkill from './formSkill';
import FormSkill2 from './formSkill2';
import FormContacts from './formContacts';
import Loading, { useLoading } from "../../components/Loading";
import Modal, { useModal } from "../../components/Modal";
import {confirm} from "../../components/Confirm";
import {alert} from "../../components/Alert";
import {dialog} from "../../components/Dialog";
import { getCompanyId, useAuth, setTitle, getTitle, getDriverId } from "../../Auth";
import { patternEmail, patternNumericValue, patternYear, imageExtention, patternNumericData, validatePassword } from '../../patterns';
import moment from "moment";
import toast from "../../components/Toasts/toast";

import People, { Photo } from "../../components/People";
import Tag from "../../components/Tag";
import { getObjectURL } from "./utils";
import DayPicker from '../../components/Calendar/day';
import { canChangePermissionEmployee } from "../Scheduler/Common/AccessCheck";

const SpacerInline = styled(Spacer)`
    display:inline-block;
`;

const Container = styled.div`
    display:grid;
`;

const LoadingWrapper = styled.div`
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.7);
    z-index:10;
    >img {
        width:90px;
        height:90px;
        position:absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const DateContainer = styled.div`
    label {
        font-size: 13px;
        margin-bottom: 5px;
    }
`;

function password_generator(len) {
    let length = (len) ? (len) : (10);
    let string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    let numeric = '0123456789';
    let punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    let password = "";
    let character = "";
    let crunch = true;
    while (password.length < length) {
        let entity1 = Math.ceil(string.length * Math.random() * Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
        let hold = string.charAt(entity1);
        hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);
        character += hold;
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        password = character;
    }
    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
    return password.substr(0, len);
}


const App = ({ action, edit, formVisible, toggleForm }) => {
    const [scheduleVisible, toggleScheduleVisible] = useModal();
    const [profileImage, setProfileImage] = useState(null);
    const [lookup, setLookup] = useState({ typeDrivers: [], stations: [], managers: [], contacts: [], roles: [], employmentStatus: [], skills: [], exempt: 0, nonExempt: 0 });
    const [isLoading, setLoading] = useLoading(true);
    const { api, logout } = useAuth();
    const [userId, setUserId] = useState({});
    const [oldStationId, setOldStationId] = useState(0);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedSkill, setSelectedSkill] = useState({ update: -1, data: [], sort: [] });
    const [scheduleDriver, setScheduleDriver] = useState('');
    const [selectedStation, setSelectedStation] = useState('');
    const [selectedStationTimezone, setSelectedStationTimezone] = useState('');

    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState('');
    const { register, handleSubmit, errors, getValues, setValue, reset, setError, clearError, formState, watch } = useForm({ mode: 'onBlur', defaultValues: {} });
    const { isSubmitting } = formState;   
    const { name, last_name, middle_name, birth_date, ssn_last, personal_phone, email, marital_status, veteran_status, gender,
        address, station, manager, hire_date, employment_status, transporter_id, role, shirt_size, pant_size, shoe_size, personal_notes, skill, maximum_weekly_hour, profile, paycomId, gas_pin, oldPasswords } = getValues();
    const permissionLevel = ["Station Manager", "Owner", "Operations Manager", "Operations Account Manager"];
    const [oldRole, setOldRole] = useState(''); 
    const [oldRoleId, setOldRoleId] = useState(null);
    const [oldEmail, setOldEmail] = useState("");

    const onSubmit = async (data, type = 'save') => {
        let imagePath = "";
        let order = ["driver_1", "user_1"];
        let contacts = {};
        let contactConnects = {};

        if (data.contact) {
            Object.keys(data.contact).forEach(function (index) {
                order.push('EmergencyContact_' + (parseInt(index) + 1));
                var keyContact = 'EmergencyContact_' + (parseInt(index) + 1);
                contacts[keyContact] = {
                    'name': data.contact[index].name,
                    'number': data.contact[index].number,
                    'relation': data.contact[index].relation
                }
                contactConnects[keyContact] = {
                    "driver": [
                        "driver_1"
                    ]
                }
            });
        }

        let skills = {};
        let skillConnects = {};
        let skillNames = [];
        let isFormSubmit = true;
        if (data.skill) {
            Object.keys(data.skill).forEach(function (index) {
                order.push('DriverSkill_' + (parseInt(index) + 1));
                skillNames.push(data.skill[index].name);
                var keyContact = 'DriverSkill_' + (parseInt(index) + 1);
                skills[keyContact] = {
                    'skill': data.skill[index].id,
                    'hourlyRate': data.skill[index].hourly_rate,
                    'skillRate': data.skill[index].skillRate
                };
                skillConnects[keyContact] = {
                    "driver": [
                        "driver_1"
                    ]
                }
            });
        }

        if(permissionLevel.includes(oldRole) === true && !permissionLevel.includes(selectedRole)) {
            const paramsCheckManager = {
                "actions": {
                    "response": {
                        "Driver": {
                            "custom": {
                                "functionName": "getEmployeeFromManager",
                                "criteria": {
                                    "userId": userId
                                },
                                "get": "users"
                            }
                        }
                    }
                }
            }
            let responsData = await api.post('/api/lazy/manage/data', paramsCheckManager);
            let users = responsData.data.data.users;
            
            if (users.length > 0) {
                isFormSubmit = false;
                await dialog({
                    text: "You changed the role of " + name +" and the "+ name +" is still listed as a manager for a group of employees. Would you like to assign his employees to another manager?",
                    buttons: [
                        {
                            type: 'primary',
                            text: 'Yes'
                        },
                        {
                            text: 'No',
                            callback: ()=> false
                        }
                    ]
                }).then(async empStatus => {
                    if (!empStatus) return false;
                    const paramsGetkManager ={
                        "actions": {
                            "response": {
                                "User": {
                                    "custom": {
                                        "functionName": "findManagers",
                                        "get": "users",
                                        "criteria": {
                                            "company": localStorage.getItem('company')
                                        }
                                    }
                                }
                            }
                        }
                    }
                    await api.post('/api/lazy/manage/data', paramsGetkManager).then( async response => {

                        let userObj = [];
                        let temp = [];
                        response.data.data.users.map(u => {
                            if( u.id != userId ){
                                temp = {
                                    name: u.firstName+' '+u.lastName,
                                    value: u.id
                                };
                                userObj.push(temp);
                            }
                        });

                        await dialog({
                            title:'Assign Manager',
                            fields: [
                                {
                                    field: CustomDropdown,
                                    props: {
                                        name: 'manager',
                                        label: 'Select Manager',
                                        options: userObj,
                                        Required: true,
                                        visibleOptionsQty: 6
                                    }
                                }
                            ],
                            buttons: [
                                {
                                    type: 'primary',
                                    text: 'Assign'
                                },
                                {
                                    text: 'Cancel',
                                    callback: ()=> false,
                                }
                            ]
                        }).then( async managerStatus => {
                            if (!managerStatus) return false;
                            if(managerStatus){
                                const assignNewManager ={
                                    "actions": {
                                        "response": {
                                            "Driver": {
                                                "custom": {
                                                    "functionName": "setNewManager",
                                                    "criteria": {
                                                        "userId": userId,
                                                        "newManager":managerStatus.data.manager
                                                    },
                                                    "get": "users"
                                                }
                                            }
                                        }
                                    }
                                }
                                await api.post("/api/lazy/manage/data", assignNewManager).then((response) => {
                                     isFormSubmit = true;
                                });
                            }
                        });
                    });
                });
            }
        }
        
        if (!isFormSubmit) return;

        if (edit > 0) {
            let img = {};
            let file = {};
            let imgConnect = {};
            let name = '';
            let path = '';
            let isdeleteStatus = false;
            let friendlyName = (data.nick_name.trim() === '') ? data.name.trim() + ' ' + data.last_name.trim() : data.nick_name.trim();
            if(data.station != oldStationId){
                isdeleteStatus = true;
                let oldStation = '';
                let newStation ='';
                lookup.stations.map((st) => {
                    if(st.value == oldStationId){
                        oldStation = st.name;
                    }
                    if(st.value == data.station){
                        newStation = st.name;
                    }
                });


                let getStations = {
                    actions: {
                        response: {
                            Schedule: {
                                custom: {
                                    functionName: "removeDriverFromCurrentStation",
                                    criteria: {
                                        station_id: oldStationId,
                                        driver_id: edit,
                                        result: true
                                    },
                                    get: "removeStation",
                                }
                            }
                        }
                    }
                };

                const scheduleCounts =  await api.post('/api/lazy/manage/data', getStations);

                if(scheduleCounts.data.data.removeStation > 0){
                    let nextDate = moment().add(1,'days').format('MM/DD/YYYY');
                    let message = 'You are about to relocate this driver from '+oldStation+' to '+newStation+'.  All future Shifts for '+friendlyName+' will be archived as of '+nextDate+'.  Press Yes to continue. ';

                    const confirmAlertVar = await confirm({
                        icon: true,
                        text: message
                    });

                    if(!confirmAlertVar){
                        return false;
                    } else {
                        await dialog({
                            icon: <Icon.Warning size='60px' color={Theme.colors.warnings.text}/>,
                            text: "Please remember to add "+ friendlyName +" to an appropriate schedule as needed.",
                            buttons: [
                                {
                                    text: 'Okay',
                                    type: 'primary',
                                    inline: false,
                                    style: {
                                        width: '100%',
                                        marginTop: 8
                                    },
                                    callback: ()=> false,
                                }
                            ]
                        });

                        let removeDriverFromCurrentSchedule = {
                            actions: {
                                response: {
                                    Schedule: {
                                        custom: {
                                            functionName: "removeDriverFromCurrentStation",
                                            criteria: {
                                                station_id: oldStationId,
                                                driver_id: edit,
                                                result: false
                                            },
                                            get: "removeStation",
                                        }
                                    }
                                }
                            }
                        };
                        await api.post('/api/lazy/manage/data', removeDriverFromCurrentSchedule);
                    }
                }
            }

            let updateUser;
            if (data.profile.length > 0) {
                imagePath = await getObjectURL(data);
                updateUser = {
                    "firstName": data.name.trim(),
                    "middleName": data.middle_name.trim(),
                    "lastName": data.last_name.trim(),
                    "friendlyName": friendlyName,
                    "email": data.email,
                    "title": data.title,
                    "userRoles": [data.role],
                    "profileImage": imagePath
                };
            } else {
                updateUser = {
                    "firstName": data.name.trim(),
                    "middleName": data.middle_name.trim(),
                    "lastName": data.last_name.trim(),
                    "friendlyName": friendlyName,
                    "email": data.email,
                    "title": data.title,
                    "userRoles": [data.role]
                };
            }

            let params = {
                "actions": {
                    "update": {
                        "Driver": {
                            "driver_1": {
                                "findBy": {
                                    "id": edit
                                },
                                "updateRecord": {
                                    "birthday": data.birth_date ? moment(data.birth_date).utc().format('YYYY-MM-DD') : null,
                                    "jobTitle": data.name.trim(),
                                    "ssn": data.ssn_last,
                                    "employmentStatus": data.employment_status,
                                    "personalPhone": data.personal_phone,
                                    "maritalStatus": data.marital_status,
                                    "gender": data.gender,
                                    "veteranStatus": data.veteran_status,
                                    "secondaryEmail": data.secondary_email,
                                    "fullAddress": data.address,
                                    "note": data.personal_notes,
                                    "hireDate": data.hire_date ? moment(data.hire_date).format('YYYY-MM-DD') : null,
                                    "shirtSize": data.shirt_size,
                                    "shoeSize": data.shoe_size,
                                    "pantSize": data.pant_size,
                                    "transporterID": data.transporter_id,
                                    "driversLicenseNumber": data.license_number,
                                    "driversLicenseNumberExpiration": data.exp_date ? moment(data.exp_date).format('YYYY-MM-DD') : null,
                                    "licenseState": data.license_state,
                                    "expectedFirstDayOnTheRoad": data.expected_date ? moment(data.expected_date).format('YYYY-MM-DD') : null,
                                    "needsTraining": data.need_traning,
                                    "drivingPreference": data.driving_preferences,
                                    "stations": [data.station],
                                    "assignedManagers": [data.manager],
                                    "maximumWeeklyHour": data.maximum_weekly_hour,
                                    "paycomId": data.paycomId,
                                    "associateOID": data.associateOID,
                                    "gasPin" : data.gas_pin,
                                    "dotMedicalExpDate": data.dot_medical_exp_date ? moment(data.dot_medical_exp_date).format('YYYY-MM-DD') : null
                                }
                            }
                        },
                        "User": {
                            "user_1": {
                                "findBy": {
                                    "id": userId
                                },
                                "updateRecord": updateUser
                            }
                        },
                    }
                }
            };
            let updateStationInFutureDriverRoute = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "updateDriverStationInFutureRoutes",
                                criteria: {
                                    station_id: data.station,
                                    driver_id: edit,
                                    old_station: oldStationId
                                },
                                get: "driverRoute",
                            },
                        },
                    },
                }
            };
            try {
                setLookup({ ...lookup, skills: data.skill });

                // if(data.profile.length > 0){
                //   const FormDataParams = new FormData();
                //   FormDataParams.append('params', JSON.stringify(params));
                //   // FormDataParams.append('files', JSON.stringify(file));
                //   // FormDataParams.append('profile[]',data.profile[0]);
                //   //   FormDataParams.append('image',data.profile[0]);
                //
                //     // upload(FormDataParams).then(result => {
                //     // }).catch(err => {
                //     // });
                //
                //
                //   // return;
                //
                //   const response = await api.post('/api/lazy/manage/data', FormDataParams);
                //   let roleAName='';
                //   Object.keys(lookup.roles).forEach(function(index) {
                //       if( lookup.roles[index].value == data.role ){
                //           roleAName = lookup.roles[index].name;
                //       }
                //   });
                //   await action.editRecord({"id":edit,"job":roleAName,"name":data.name,"email":data.email,"status":1,skills:skillNames,"profile":process.env.REACT_APP_API_ENDPOINT+path+name});
                // }else{

                await api.post('/api/lazy/manage/data', params);
                await api.post('/api/lazy/manage/data', updateStationInFutureDriverRoute);
                //send mail
                if (data.send_invitation) {
                    //Convert string to Array
                    let oldPasswordsArray = data.oldPasswords.split(",");
                    let password = password_generator(8);
                    // Check Valid Password
                    let validPassword = validatePassword(password, data.email, []);
                    while (validPassword !== null) {
                        password = password_generator(8);
                        validPassword = validatePassword(password, data.email, oldPasswordsArray);
                    }
                    api.post('/api/user/update_password/web', {
                        "key": "id",
                        "value": userId,
                        "password": password
                    });

                    let mail = {
                        "company": localStorage.getItem('company'),
                        "email": data.email,
                        "name": data.name.trim(),
                        "password": password,
                    };
                    await api.post('/api/send-employee-registration-mail', mail);
                }

                let roleAName = '';
                Object.keys(lookup.roles).forEach(function (index) {
                    if (lookup.roles[index].value == data.role) {
                        roleAName = lookup.roles[index].name;
                    }
                });

                let stationValue = [];
                Object.keys(lookup.stations).forEach(function (index) {
                    if (lookup.stations[index].value == data.station) {
                        stationValue.push({id:lookup.stations[index].value, name:lookup.stations[index].name});
                    }
                });

                if (getDriverId() == edit) {
                    let driverTitle = (data.title != '') ? data.title : roleAName;
                    setTitle(driverTitle);
                }

                
                if (parseInt(userId) == parseInt(localStorage.getItem("userId"))) {
                    alert({
                        text: 'You have updated your own profile. So you logged out automatically and need to login again.',
                        icon: true
                    }).then(
                        success => {
                            logout().then();
                        }
                    )                   
                }
                await action.editRecord({ "id": edit, "job": (data.title != '')?data.title:roleAName, "name": (data.nick_name == '') ? data.name.trim() + ' ' + data.last_name.trim() : data.nick_name.trim(), "email": data.email, "status": 1, skills: skillNames, "profile": imagePath,'isDelete':isdeleteStatus,'roles':roleAName, "stations" : stationValue });
            } catch (error) {
                await alert(JSON.stringify(error)).catch();
            }

        } else {
            // TODO: make forgot password better later
            let password = password_generator(8);
            // Check Valid Password
            let validPassword = validatePassword(password, data.email, []);
            while (validPassword !== null) {
                password = password_generator(8);
                validPassword = validatePassword(password, data.email, []);
            }
            //let password = 'Private1!';
            let img = {};
            let file = {};
            let imgConnect = {};
            let path = '';
            let name = '';

            // if( data.profile.length > 0 ){
            //     imagePath = await getObjectURL(data);
            // }
            setSelectedStation(data.station);

            let userData;
            if (data.profile.length > 0) {
                imagePath = await getObjectURL(data);
                userData = {
                    "email": data.email,
                    "password": password,
                    "firstName": data.name.trim(),
                    "middleName": data.middle_name.trim(),
                    "lastName": data.last_name.trim(),
                    "title": data.title.trim(),
                    "friendlyName": (data.nick_name.trim() === '') ? data.name.trim() + ' ' + data.last_name.trim() : data.nick_name.trim(),
                    "userRoles": [data.role],
                    "company": localStorage.getItem('company'),
                    "isEnabled": 1,
                    "isArchive": false,
                    "profileImage": imagePath
                };
            } else {
                userData = {
                    "email": data.email,
                    "password": password,
                    "firstName": data.name.trim(),
                    "middleName": data.middle_name.trim(),
                    "lastName": data.last_name.trim(),
                    "title": data.title.trim(),
                    "friendlyName": (data.nick_name.trim() === '') ? data.name.trim() + ' ' + data.last_name.trim() : data.nick_name.trim(),
                    "userRoles": [data.role],
                    "company": localStorage.getItem('company'),
                    "isEnabled": 1,
                    "isArchive": false,
                };
            }
            let params = {
                "actions": {
                    "create": {
                        "User": {
                            "user_1": userData
                        },
                        "EmergencyContact": contacts,
                        "DriverSkill": skills,
                        "Driver": {
                            "driver_1": {
                                "birthday": data.birth_date ? moment(data.birth_date).utc().format('YYYY-MM-DD') : null,
                                "jobTitle": data.name.trim(),
                                "ssn": data.ssn_last,
                                "employmentStatus": data.employment_status,
                                "personalPhone": data.personal_phone,
                                "maritalStatus": data.marital_status,
                                "gender": data.gender,
                                "veteranStatus": data.veteran_status,
                                "secondaryEmail": data.secondary_email,
                                "fullAddress": data.address,
                                "note": data.personal_notes,
                                "hireDate": data.hire_date ? moment(data.hire_date).format('YYYY-MM-DD') : null,
                                "shirtSize": data.shirt_size,
                                "shoeSize": data.shoe_size,
                                "pantSize": data.pant_size,
                                "transporterID": data.transporter_id,
                                "driversLicenseNumber": data.license_number,
                                "driversLicenseNumberExpiration": data.exp_date ? moment(data.exp_date).format('YYYY-MM-DD') : null,
                                "licenseState": data.license_state,
                                "expectedFirstDayOnTheRoad": data.expected_date ? moment(data.expected_date).format('YYYY-MM-DD') : null,
                                "needsTraining": data.need_traning,
                                "drivingPreference": data.driving_preferences,
                                "stations": [data.station],
                                "assignedManagers": [data.manager],
                                "company": localStorage.getItem('company'),
                                "maximumWeeklyHour": data.maximum_weekly_hour,
                                "paycomId": data.paycomId,
                                "associateOID": data.associateOID,
                                "gasPin": data.gas_pin,
                                "dotMedicalExpDate": data.dot_medical_exp_date ? moment(data.dot_medical_exp_date).format('YYYY-MM-DD') : null
                            }
                        }
                    },
                    "loadOrder": order,
                    "connect": {
                        "user": {
                            "user_1": {
                                "driver": [
                                    "driver_1"
                                ]
                            }
                        },
                        "driver": {
                            "driver_1": {
                                "user": [
                                    "user_1"
                                ]
                            }
                        },
                        "EmergencyContact": contactConnects,
                        "DriverSkill": skillConnects
                    }
                }
            };
            try {
                // if(data.profile.length > 0){
                //   const FormDataParams = new FormData();
                //   FormDataParams.append('params', JSON.stringify(params));
                //   FormDataParams.append('files', JSON.stringify(file));
                //   FormDataParams.append('profile[]',data.profile[0]);
                //   const response = await api.post('/api/lazy/manage/data', FormDataParams);
                //
                //   //send mail
                //   let mail ={
                //       "company":localStorage.getItem('company'),
                //       "email":data.email,
                //       "name":data.name
                //   };
                //   await api.post('/api/send-employee-registration-mail', mail);
                //
                //   let roleAName='';
                //   await action.create({"id":response.data.data.responseId,"job":roleAName,"name":data.name,"email":data.email,"status":1,skills:skillNames,isEnabled:1,"picture":process.env.REACT_APP_API_ENDPOINT+path+name});
                // }else{
                const response = await api.post('/api/lazy/manage/data', params);
                // Add old password if send invitation false 
                if (response.data.data.userId && data.send_invitation == false) {
                    const paramsOldPassword = {
                        actions: {
                            response: {
                                User: {
                                    custom: {
                                        functionName: "addOldPasswords",
                                        get: "addOldPasswords",
                                        excludes: [],
                                        criteria: {
                                            companyId: localStorage.getItem("company"),
                                            userId: response.data.data.userId,
                                            oldPassword: password,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    await api.post("/api/lazy/manage/data", paramsOldPassword);
                }
                //send mail
                if (data.send_invitation) {
                    let mail = {
                        "company": localStorage.getItem('company'),
                        "email": data.email,
                        "name": data.name.trim(),
                        "password": password,
                    };
                    await api.post('/api/send-employee-registration-mail', mail);
                }
                // await addOneUserToGeneralChannel(api, localStorage.getItem('company'),  response.data.data['userId']);
                let roleAName = '';
                Object.keys(lookup.roles).forEach(function (index) {
                    if (lookup.roles[index].value === data.role) {
                        roleAName = lookup.roles[index].name;
                    }
                });

                setScheduleDriver(response.data.data.responseId);
                setLookup({ ...lookup, skill: data.skill });
                if (roleAName.toString() === 'Delivery Associate') {
                    toggleScheduleVisible();
                }
                let stationValue = [];
                Object.keys(lookup.stations).forEach(function (index) {
                    if (lookup.stations[index].value == data.station) {
                        stationValue.push({id:lookup.stations[index].value, name:lookup.stations[index].name});
                    }
                });
               await action.create({ "id": response.data.data.responseId, "job": (data.title)?data.title:roleAName, "name": (data.nick_name == '') ? data.name.trim() + ' ' + data.last_name.trim() : data.nick_name.trim() + ' ' + data.last_name.trim(), "email": data.email, "status": 1, skills: skillNames, isEnabled: 1, "picture": imagePath, "employeeStatus":2 , roles: roleAName, "stations": stationValue});

                // }
            } catch (error) {
                alert('Something happen');
                if (error.response.status === '401') {
                    window.location = "/logout";
                }
            }
        }
    };

    const loadEditData = async () => {

        if (edit > 0 && !name) {
            let paramsDelete1 = {
                "actions": {
                    "response": {
                        "DriverSkill": {
                            "custom": {
                                "functionName": "deleteNullSkill",
                                "get": "deleteNullSkill",
                                "criteria": {
                                    "driver": edit
                                }
                            }
                        },
                    }
                }
            };
            
           await api.post('/api/lazy/manage/data', paramsDelete1);
            const paramsRole = {
                actions: {
                    response: {
                        Role: {
                            custom: {
                                functionName: "getRolePermissionLevel",
                                get: "roles",
                                criteria: {},
                            },
                        },
                    },
                },
            };
            const responseRole = await api.post("/api/lazy/manage/data", paramsRole);
            const rolesPermissionLevel = responseRole.data.data.roles.roleArray;
           const params = {
                "actions": {
                    "response": {
                        "Driver": {
                            "find": {
                                "get": "driver",
                                "id": edit,
                                "includes": {
                                    "0": "id",
                                    "1": "jobTitle",
                                    "2": "veteranStatus",
                                    "3": "note",
                                    "4": "personalPhone",
                                    "5": "transporterID",
                                    "6": "hireDate",
                                    "7": "driversLicenseNumber",
                                    "8": "driversLicenseNumberExpiration",
                                    "9": "birthday",
                                    "10": "note",
                                    "11": "shirtSize",
                                    "12": "shoeSize",
                                    "13": "pantSize",
                                    "15": "ssn",
                                    "16": "veteranStatus",
                                    "17": "secondaryEmail",
                                    "18": "fullAddress",
                                    "19": "licenseState",
                                    "20": "expectedFirstDayOnTheRoad",
                                    "21": "needsTraining",
                                    "22": "maritalStatus",
                                    "23": "gender",
                                    "24": "drivingPreference",
                                    "25": "maximumWeeklyHour",
                                    "26": "paycomId",
                                    "27": "associateOID",
                                    "28": "gasPin",
                                    "29": "dotMedicalExpDate",
                                    "user": {
                                        "0": "id",
                                        "1": "firstName",
                                        "2": "email",
                                        "3": "phoneNumber",
                                        "4": "note",
                                        "5": "image",
                                        "6": "profileImage",
                                        "7": "lastName",
                                        "8": "middleName",
                                        "9": "friendlyName",
                                        "10": "title",
                                        "11": "oldPasswords",
                                        "userRoles": ["id", "name"]
                                    },
                                    "driverSkills": {
                                        "0": "id",
                                        "1": "hourlyRate",
                                        "skill": ["id", "name"]
                                    },
                                    "assignedManagers": ["id"],
                                    "schedules": ["id", "name"],
                                    "stations": ["id", "name", "timezone"],
                                    "employmentStatus": ["id", "name"]
                                }
                            }
                        },
                        "Role": {
                            "findBy": {
                                "get": "roles",
                                "criteria": {
                                    "company": localStorage.getItem('company')
                                },
                                "includes": [
                                    "id",
                                    "roleName",
                                    "name"
                                ]
                            }
                        },
                        "EmploymentStatus": {
                            "findBy": {
                                "get": "employmentStatus",
                                "criteria": {
                                    "companyId": localStorage.getItem('company')
                                },

                                "includes": {
                                    "0": "id",
                                    "1": "name"
                                }
                            }
                        },
                        "DriverSkill": {
                            "findBy": {
                                "get": "driver_skills",
                                "criteria": {
                                    "driver": edit
                                },

                                "includes": {
                                    "0": "id",
                                    "1": "hourlyRate",
                                    "skill": ["id", "name"]
                                }
                            }
                        },
                        "EmergencyContact": {
                            "findBy": {
                                "get": "emergency_contacts",
                                "criteria": {
                                    "driver": edit
                                },
                                "includes": [
                                    "id",
                                    "name",
                                    "number",
                                    "relation",
                                    "isArchive"
                                ]
                            }
                        },
                        "Station": {
                            "findBy": {
                                "criteria": {
                                    "company": localStorage.getItem('company'),
                                    "isArchive": false
                                },
                                "get": "Stations",
                                "id": localStorage.getItem('company'),
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "timezone",
                                }
                            }
                        },
                        "User": {
                            "custom": {
                                "functionName": "findManagers",
                                "get": "users",
                                "criteria": {
                                    "company": localStorage.getItem('company')
                                }
                            }
                        },
                        "PaycomSettings": {
                            "findOneBy": {
                                "criteria": {
                                    "company": getCompanyId(),
                                    "isEnabled": 1,
                                    "isArchive": 0
                                },
                                "get": "paycomSettings",
                                "includes": {
                                    0: "isEnabled"
                                }
                            }
                        }
                    }
                }
            };
             try {
                let responseAll = await api.post('/api/lazy/manage/data', params);
                let tempEditRole = [];
                let EditRoleObj = [];
                Object.keys(responseAll.data.data.roles).forEach(function (index) {
                    tempEditRole = {
                        'name': responseAll.data.data.roles[index].roleName.toString(),
                        'value': responseAll.data.data.roles[index].id.toString(),
                    }
                    if (rolesPermissionLevel.includes(responseAll.data.data.roles[index].name.toString())) {
                        EditRoleObj.push(tempEditRole);
                    }
                });
                let response = responseAll.data.data.driver;
                // DATA
                const apiResult = await new Promise((resolve, reject) => {
                    setUserId(responseAll.data.data.driver.user.id);
                    setSelectedStation(responseAll.data.data.driver.stations[0].id.toString());
                    setSelectedStationTimezone(responseAll.data.data.driver.stations[0].timezone.toString());
                    setSelectedEmploymentStatus(response.employmentStatus.id.toString());
                    setOldStationId(responseAll.data.data.driver.stations[0].id.toString());
                    const selectedRoleItem = EditRoleObj.find((item) => item.value == response.user.userRoles[0].id.toString());
                    setOldRole(selectedRoleItem?.name ?? "");
                    setOldRoleId(selectedRoleItem?.value ?? null);
                    setOldEmail(response.user.email ?? "");

                    setTimeout(() => {
                        resolve({
                            data: {
                                "Employee": [{
                                    name: response.user.firstName,
                                    last_name: response.user.lastName,
                                    oldPasswords: response.user.oldPasswords,
                                    nick_name: response.user.friendlyName,
                                    middle_name: response.user.middleName,
                                    title: response.user.title,
                                    profileImage: response.user.profileImage ? response.user.profileImage : '',
                                    birth_date: response.birthday ? moment.unix(response.birthday.timestamp).utc().format("YYYY-MM-DD") : null,
                                    ssn_last: response.ssn,
                                    personal_notes: response.note,
                                    personal_phone: response.personalPhone,
                                    marital_status: response.maritalStatus,
                                    gender: response.gender,
                                    veteran_status: (response.veteranStatus != null) ? response.veteranStatus.toString() : null,
                                    email: response.user.email,
                                    secondary_email: response.secondaryEmail,
                                    driving_preferences: response.drivingPreference,
                                    address: response.fullAddress,
                                    personal_note: response.name,
                                    station: responseAll.data.data.driver.stations[0].id.toString(),
                                    hire_date: moment.unix(response.hireDate.timestamp).utc().format("YYYY-MM-DD"),
                                    employment_status: response.employmentStatus ? response.employmentStatus.id.toString() : '',
                                    role: response.user.userRoles[0].id.toString(),
                                    shirt_size: response.shirtSize,
                                    pant_size: response.pantSize,
                                    shoe_size: response.shoeSize,
                                    transporter_id: response.transporterID,
                                    license_number: response.driversLicenseNumber,
                                    license_state: response.licenseState,
                                    exp_date: response.driversLicenseNumberExpiration ? moment.unix(response.driversLicenseNumberExpiration.timestamp).utc().format("YYYY-MM-DD") : '',
                                    expected_date: response.expectedFirstDayOnTheRoad ? moment.unix(response.expectedFirstDayOnTheRoad.timestamp).utc().format("YYYY-MM-DD") : '',
                                    need_traning: response.needsTraining,
                                    manager: responseAll.data.data?.driver?.assignedManagers?.[0] ? responseAll.data.data?.driver?.assignedManagers?.[0].id?.toString(): "",
                                    maximum_weekly_hour: response.maximumWeeklyHour,
                                    paycomId: response.paycomId,
                                    associateOID: response.associateOID,
                                    employeeStatus: response.employeeStatus,
                                    gas_pin : response.gasPin,
                                    dot_medical_exp_date: response.dotMedicalExpDate ? moment.unix(response.dotMedicalExpDate.timestamp).utc().format("YYYY-MM-DD") : ''
                                }]
                            }
                        })
                    }, 1)
                });
                setLoading(false);
                reset(apiResult.data.Employee[0]);
                setProfileImage(response.user.profileImage ? response.user.profileImage : null);
                await setLookup({
                    ...lookup,
                    stations: responseAll.data.data.Stations.map((st) => { return { name: st.name.toString(), value: st.id.toString(), timezone: st.timezone.toString() } }),
                    typeDrivers: [{ name: 'Driver', value: 1 }, { name: 'Cosmos', value: 2 }, { name: 'test', value: 3 }],
                    roles: EditRoleObj,
                    managers: responseAll.data.data.users.map((user) => { return { name: user.firstName.toString() + ((user.lastName) ?  ' '+user.lastName.toString() : ''), value: user.id.toString() } }),
                    employmentStatus: responseAll.data.data.employmentStatus.map((emp) => { return { name: emp.name.toString(), value: emp.id.toString() } }),
                    contacts: responseAll.data.data.emergency_contacts.map((em) => { return { id: em.id, name: em.name, relation: em.relation, number: em.number } }),
                    skills: responseAll.data.data.driver_skills.map((skill) => { return { id: skill.id, skill_id: skill.skill.id, name: skill.skill.name, hourly_rate: skill.hourlyRate } }),
                    station: apiResult.data.Employee[0],
                    exempt: responseAll.data.data.employmentStatus.filter((emp) => emp.name.toString() == "Exempt"),
                    nonExempt: responseAll.data.data.employmentStatus.filter((emp) => emp.name.toString() == "Non-Exempt"),
                    isPaycomEnabled: responseAll.data.data.paycomSettings !== null,
                    isADP: localStorage.getItem('isADP') == 'true' ? true : false,
                })
            } catch (error) {
                console.error({ error });
                Alert('Something bad happen');
            }
        }
    };

    const loadLookups = async () => {
        const params = {
            "actions": {
                "response": {
                    "Station": {
                        "findBy": {
                            "criteria": {
                                "company": localStorage.getItem('company'),
                                "isArchive": false
                            },
                            "get": "Stations",
                            "id": localStorage.getItem('company'),
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "timezone",
                            },
                            "excludes": ["drivers", "driverRoutes", "vehicleDriverRecord", "company", "invoiceTypes", "shifts", "skillRates", "rates", "schedules", "tempDriverRoutes", "routeCommitments", "stationGradingSettings", "balanceGroups", "location", "devices", "vehicles", "driverShiftStation", "tempDriverRouteShiftStation", "driverRouteCodes"]
                        }
                    },
                    "Role": {
                        "findBy": {
                            "get": "roles",
                            "criteria": {
                                "company": localStorage.getItem('company')
                            },
                            "includes": [
                                "id",
                                "roleName",
                                "name"
                            ]
                        }
                    },
                    "EmploymentStatus": {
                        "findBy": {
                            "get": "employmentStatus",
                            "criteria": {
                                "companyId": localStorage.getItem('company')
                            },
                            "excludes": [
                                "userRoles", "skills", "image", "driver", "managerDrivers", "company"
                            ],
                            "includes": {
                                "0": "id",
                                "1": "name"
                            }
                        }
                    },
                    "User": {
                        "custom": {
                            "functionName": "findManagers",
                            "get": "users",
                            "criteria": {
                                "company": localStorage.getItem('company')
                            }
                        }
                    },
                    PaycomSettings: {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId(),
                                isEnabled: 1,
                                isArchive: 0
                            },
                            get: "paycomSettings",
                            includes: {
                                0: "isEnabled"
                            }
                        }
                    }
                }
            }
        }
        const paramsRole = {
            actions: {
                response: {
                    Role: {
                        custom: {
                            functionName: "getRolePermissionLevel",
                            get: "roles",
                            criteria: {},
                        },
                    },
                },
            },
        };
        const responseRole = await api.post("/api/lazy/manage/data", paramsRole);
        const rolesPermissionLevel = responseRole.data.data.roles.roleArray;
        const response = await api.post('/api/lazy/manage/data', params);
        const apiResult = await new Promise((resolve, reject) => {
            let temp = [];
            let RoleObj = [];
            Object.keys(response.data.data.roles).forEach(function (index) {
                temp = {
                    'name': response.data.data.roles[index].roleName.toString(),
                    'value': response.data.data.roles[index].id.toString(),
                }
                if (rolesPermissionLevel.includes(response.data.data.roles[index].name.toString())) {
                    RoleObj.push(temp);
                }
            });

            setTimeout(() => {
                resolve({
                    data: {
                        "Stations": response.data.data.Stations.map((st) => { return { name: st.name.toString(), value: st.id.toString(), timezone:st.timezone.toString() } }),
                        "TypeDriver": [{ name: 'Driver', value: 1 }, { name: 'Cosmos', value: 2 }, { name: 'test', value: 3 }],
                        "Roles": RoleObj,
                        "Managers": response.data.data.users.map((user) => { return { name: user.firstName.toString() + ((user.lastName) ? ' ' +user.lastName.toString() : ''), value: user.id.toString() } }),
                        "employmentStatus": response.data.data.employmentStatus.map((emp) => { return { name: emp.name.toString(), value: emp.id.toString() } }),
                        "exempt": response.data.data.employmentStatus.filter((emp) => emp.name.toString() == "Exempt"),
                        "nonExempt": response.data.data.employmentStatus.filter((emp) => emp.name.toString() == "Non-Exempt")
                    }
                })
            }, 100)
        });

        setLookup({
            typeDrivers: apiResult.data.TypeDriver,
            stations: apiResult.data.Stations,
            managers: apiResult.data.Managers,
            roles: apiResult.data.Roles.sort((a, b) => (a.name > b.name) ? 1 : -1),
            employmentStatus: apiResult.data.employmentStatus,
            exempt: apiResult.data.exempt,
            nonExempt: apiResult.data.nonExempt,
            isPaycomEnabled: response.data.data.paycomSettings !== null,
            isADP: localStorage.getItem('isADP') == 'true' ? true : false,
        });
        setLoading(false);
    };

    const errorMessages = {
        email: {
            required: 'You need to provide an email',
            pattern: 'Enter a valid email',
            exists: 'Email already exist. Please choose a different email',
        },
        birth_date: {
            required: 'You need to provide an birth date'
        },
        hire_date: {
            required: 'You need to provide an hire date',
            pattern: 'Enter a valid date. Ex: YYYY-MM-DD'
        },
        exp_date: {
            pattern: 'Enter a valid date. Ex: YYYY-MM-DD'
        },
        expected_date: {
            pattern: 'Enter a valid date. Ex: YYYY-MM-DD'
        },
        skill: {
            required: 'you need to add skill',
            notMatch: 'Please choose a skill',
        },
        maximum_weekly_hour: {
            pattern: 'Enter only numeric value',
        },
        ssn_last: {
            required: 'You need to provide an SSN',
            pattern: 'Must be 4 digit and only allowed numbers',
        },
        gas_pin: {
            required: 'Enter only numeric value',
            pattern: 'Must be min. 4 digit and max. 6 digit is valid',
        },
        dot_medical_exp_date: {
            pattern: "Enter a valid date. Ex: YYYY-MM-DD",
        },
        profile: {
            pattern: 'select image format is not valid',
        },
        transporter_id: {
            exists: 'Transporter ID already exist in company. Please choose a different Transporter ID',
        },
    }
    useEffect(() => {
        if (edit > 0 && !name) {
            loadEditData();
        } else {
            loadLookups();
        }
    }, []);

    let shirtSizeArr = [
        { value: 'M - XS', name: 'M - XS' },
        { value: 'M - S', name: 'M - S' },
        { value: 'M - MED', name: 'M - MED' },
        { value: 'M - L', name: 'M - L' },
        { value: 'M - XL', name: 'M - XL' },
        { value: 'M - 2XL', name: 'M - 2XL' },
        { value: 'M - 3XL', name: 'M - 3XL' },
        { value: 'M - 4XL', name: 'M - 4XL' },
        { value: 'M - 5XL', name: 'M - 5XL' },
        { value: 'W - XS', name: 'W - XS' },
        { value: 'W - S', name: 'W - S' },
        { value: 'W - MED', name: 'W - MED' },
        { value: 'W - L', name: 'W - L' },
        { value: 'W - XL', name: 'W - XL' },
        { value: 'W - 2XL', name: 'W - 2XL' },
    ];

    let pantSizeArr = [
        { value: 'M - XS', name: 'M - XS' },
        { value: 'M - S', name: 'M - S' },
        { value: 'M - MED', name: 'M - MED' },
        { value: 'M - L', name: 'M - L' },
        { value: 'M - XL', name: 'M - XL' },
        { value: 'M - 2XL', name: 'M - 2XL' },
        { value: 'M - 3XL', name: 'M - 3XL' },
        { value: 'M - 4XL', name: 'M - 4XL' },
        { value: 'M - 5XL', name: 'M - 5XL' },
        { value: 'W - XS', name: 'W - XS' },
        { value: 'W - S', name: 'W - S' },
        { value: 'W - MED', name: 'W - MED' },
        { value: 'W - L', name: 'W - L' },
        { value: 'W - XL', name: 'W - XL' },
        { value: 'W - 2XL', name: 'W - 2XL' },
    ];

    let shoeSizeArr = [
        { value: 'M - US 4/EU 37', name: 'M - US 4/EU 37' },
        { value: 'M - US 4.5/EU 37', name: 'M - US 4.5/EU 37' },
        { value: 'M - US 5/EU 38', name: 'M - US 5/EU 38' },
        { value: 'M - US 5.5/EU 38', name: 'M - US 5.5/EU 38' },
        { value: 'M - US 6/EU 39', name: 'M - US 6/EU 39' },
        { value: 'M - US 6.5/EU 39', name: 'M - US 6.5/EU 39' },
        { value: 'M - US 7/EU 40', name: 'M - US 7/EU 40' },
        { value: 'M - US 7.5/EU 40', name: 'M - US 7.5/EU 40' },
        { value: 'M - US 8/EU 41', name: 'M - US 8/EU 41' },
        { value: 'M - US 8.5/EU 41', name: 'M - US 8.5/EU 41' },
        { value: 'M - US 9/EU 42', name: 'M - US 9/EU 42' },
        { value: 'M - US 9.5/EU 42', name: 'M - US 9.5/EU 42' },
        { value: 'M - US 10/EU 43', name: 'M - US 10/EU 43' },
        { value: 'M - US 10.5/EU 43', name: 'M - US 10.5/EU 43' },
        { value: 'M - US 11/EU 44', name: 'M - US 11/EU 44' },
        { value: 'M - US 11.5/EU 44', name: 'M - US 11.5/EU 44' },
        { value: 'M - US 12/EU 45', name: 'M - US 12/EU 45' },
        { value: 'M - US 12.5/EU 45', name: 'M - US 12.5/EU 45' },
        { value: 'M - US 13/EU 46', name: 'M - US 13/EU 46' },
        { value: 'M - US 13.5/EU 46', name: 'M - US 13.5/EU 46' },
        { value: 'M - US 14/EU 47', name: 'M - US 14/EU 47' },
        { value: 'M - US 14.5/EU 47', name: 'M - US 14.5/EU 47' },
        { value: 'M - US 15/EU 48', name: 'M - US 15/EU 48' },
        { value: 'W - US 5/EU 36', name: 'W - US 5/EU 36' },
        { value: 'W - US 5.5/EU 36', name: 'W - US 5.5/EU 36' },
        { value: 'W - US 6/EU 37', name: 'W - US 6/EU 37' },
        { value: 'W - US 6.5/EU 37', name: 'W - US 6.5/EU 37' },
        { value: 'W - US 7/EU38', name: 'W - US 7/EU38' },
        { value: 'W - US 7.5/EU 38', name: 'W - US 7.5/EU 38' },
        { value: 'W - US 8/EU 39', name: 'W - US 8/EU 39' },
        { value: 'W - US 8.5/EU 39', name: 'W - US 8.5/EU 39' },
        { value: 'W - US 9/EU 40', name: 'W - US 9/EU 40' },
        { value: 'W - US 9.5/EU 40', name: 'W - US 9.5/EU 40' },
        { value: 'W - US 10/EU 41', name: 'W - US 10/EU 41' },
        { value: 'W - US 10.5/EU 41', name: 'W - US 10.5/EU 41' },
        { value: 'W - US 11/EU 42', name: 'W - US 11/EU 42' },
        { value: 'W - US 11.5/EU 42', name: 'W - US 11.5/EU 42' },
        { value: 'W - US 12/EU 43', name: 'W - US 12/EU 43' },
        { value: 'W - US 12.5/EU 43', name: 'W - US 12.5/EU 43' },
        { value: 'W - US 13/EU 44', name: 'W - US 13/EU 44' },
        { value: 'W - US 13.5/EU 44', name: 'W - US 13.5/EU 44' },
    ];

    useEffect(() => {
        const role = watch("role");
        const selectedRoleItem = lookup.roles.find(item => item.value == role);
        setSelectedRole(selectedRoleItem?.name ?? '');
    }, [watch]);

    useEffect(() => {
        if(selectedSkill.data.length > 0){
            clearError("skill");
        }else{
            setError("skill","notMatch", "Skill is required");
        }
    },[selectedSkill]);
    
    // check company last owner more then one
    const checkCompanyLastOwner = async (e) => {
        if (["Owner", "Operations Account Manager"].includes(oldRole) && !["Owner", "Operations Account Manager"].includes(e.name)) {
            const companyLastOwnerParams = {
                actions: {
                    response: {
                        Driver: {
                            custom: {
                                functionName: "getCompanyLastOwner",
                                criteria: {"employeeEmail": email},
                                get: "result",
                            },
                        },
                    },
                },
            };
            const companyLastOwnerResponse = await api.post("/api/lazy/manage/data", companyLastOwnerParams);
            if (companyLastOwnerResponse.data.data.result.lastOwnerCount === 0 && !["Owner", "Operations Account Manager"].includes(e.name))
            {
                alert({
                    text: "You can't change the permission level because the company has only one active Owner/Operation Account Manager.",
                    btnText: "Cancel",
                });
                setValue("role", oldRoleId);
                return false;
            }
        }
        return true;
    };

    return (
        <Modal
            title={edit > 0 ? 'Edit Employee' : 'New Employee'}
            width='506px'
            visible={formVisible}
            setVisible={() => { toggleForm() }}
            zindex='40'
            closeOnOverlay={false}
        >
            <Container>
                {isLoading && <LoadingWrapper><Loading /></LoadingWrapper>}
                <form name='new_employee' onSubmit={handleSubmit(onSubmit)}>
                    <ThirdTitle style={{ fontWeight: 'normal' }}>Personal Information</ThirdTitle>
                    {/*{profileImage && <Photo img={profileImage}/>}*/}
                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: 'bottom', position: 'relative', top: '0' }} left={5}>
                        <Uploader
                            name='profile'
                            placeholder={!!profileImage && <img src={profileImage} alt={'profile'} onError={e=>{
                                e.target.src = 'https://s3.amazonaws.com/dsp.data.storage.general/d92b41c8_2021_08_20_icon-person.png';
                            }}/>}
                            value={profile}
                            ref={register({
                                pattern: imageExtention
                            })}
                            error={errors.profile && errorMessages.profile[errors.profile.type]}
                            valid={!errors.profile && profile && profile.length > 0}
                        />
                    </SpacerInline>
                    <Spacer top={4} />
                    <Spacer inline right={4} style={{ position: 'relative' }}>
                        <Input
                            name='name'
                            label='First Name *'
                            size='small'
                            ref={register({ required: 'You need to provide a name' })}
                            error={errors.name && errors.name.message}
                            valid={!errors.name && name && name.length > 0}
                            autoFocus
                        />
                    </Spacer>

                    <Spacer inline right={4} style={{ position: 'relative' }}>
                        <Input
                            name='middle_name'
                            label='Middle Name'
                            size='small'
                            ref={register()}
                        />
                    </Spacer>
                    <Input
                        name='last_name'
                        size='small'
                        label='Last Name *'
                        ref={register({ required: 'You need to provide a Last Name' })}
                        error={errors.last_name && errors.last_name.message}
                        valid={!errors.last_name && last_name && last_name.length > 0}
                    />
                    <Spacer top={4} />
                    <input name="oldPasswords" type="hidden" ref={register({ required: false })} value={oldPasswords} />        
                    <Spacer inline right={5} style={{ position: 'relative', zIndex: 5 }}>
                        <Input
                            name='nick_name'
                            size='small'
                            label='Nick Name'
                            ref={register()}
                        />
                    </Spacer>
                    <Spacer inline right={5} style={{ position: 'relative', zIndex: 6 }}>
                        <DayPicker
                            name='birth_date'
                            label='Birth Date'
                            size='small'
                            ref={register({ required: false })}
                        />
                    </Spacer>

                    {ssn_last ? 
                        <Input
                            name='ssn_last'
                            label='SSN Last 4'
                            size='small'
                            ref={register({
                                required: false,
                                pattern: patternYear,
                            })}
                            error={errors.ssn_last && errorMessages.ssn_last[errors.ssn_last.type]}
                            valid={!errors.ssn_last && ssn_last && ssn_last.length > 0}
                        />
                        :
                        <input
                            name='ssn_last'
                            label='SSN Last 4'
                            size='small'
                            type="hidden"
                            ref={register({
                                required: false,
                                pattern: patternYear,
                            })}
                            error={errors.ssn_last && errorMessages.ssn_last[errors.ssn_last.type]}
                            valid={!errors.ssn_last && ssn_last && ssn_last.length > 0}
                        />
                    }

                    <Spacer inline right={5} style={{ position: 'relative', zIndex: 5 }}>
                        <Input
                            name='personal_phone'
                            label='Personal Phone *'
                            size='small'
                            mask={selectedStationTimezone == 'CET'?'phone14':'phone'}
                            ref={register({ required: 'Field required' })}
                            error={errors.personal_phone && errors.personal_phone.message}
                            valid={!errors.personal_phone && personal_phone && personal_phone.length > 0}
                        />
                    </Spacer>
                    <Spacer inline right={5} style={{ position: 'relative', zIndex: 5 }}>
                        <Input
                            name='title'
                            label='Title *'
                            size='small'
                            ref={register}
                        />
                    </Spacer>

                    <Spacer top={4} />
                    {marital_status ? 
                        <Spacer inline={true} right={16} style={{ verticalAlign: 'top' }}>
                            <RadioBox
                                name='marital_status'
                                ref={register}
                                title='Marital Status'
                                inline={false}
                                options={[
                                    { value: 'single', label: 'Single' },
                                    { value: 'married', label: 'Married' },
                                    { value: 'divorced', label: 'Divorced' },
                                    { value: 'widowed', label: 'Widowed' },
                                    { value: 'domestic-partnership', label: 'Domestic Partnership' }
                                ]}
                                error={errors.marital_status && errors.marital_status.message}
                            />
                        </Spacer>
                        :
                        <input
                            name='marital_status'
                            type="hidden"
                            ref={register({
                                required: false,
                            })}
                        />
                    }

                    {gender ? 
                        <Spacer inline right={20} style={{ verticalAlign: 'top' }}>
                            <RadioBox
                                name='gender'
                                ref={register({ required: false })}
                                title='Gender'
                                inline={false}
                                options={[
                                    { value: 'male', label: 'Male' },
                                    { value: 'female', label: 'Female' },
                                    { value: 'non-binary', label: 'Non-binary' },
                                    { value: 'do-not-wish-to-disclose', label: 'Do not wish to disclose' },
                                ]}
                                error={errors.gender && errors.gender.message}
                            />
                        </Spacer>
                        :
                        <input
                            name='gender'
                            type="hidden"
                            ref={register({
                                required: false,
                            })}
                        />
                    }

                    {veteran_status ?
                        <Spacer inline={true} style={{ verticalAlign: 'top' }}>
                            <RadioBox
                                name='veteran_status'
                                ref={register}
                                title='Veteran Status'
                                inline={false}
                                options={[
                                    { value: 1, label: 'Veteran' },
                                    { value: 0, label: 'Not Veteran' },
                                ]}
                                error={errors.veteran_status && errors.veteran_status.message}
                            />
                        </Spacer>
                        :
                        <input
                            name='veteran_status'
                            type="hidden"
                            ref={register({
                                required: false,
                            })}
                        />
                    }

                    <Spacer top={4} />
                    <Input
                        name='email'
                        label='Primary Email (username)'
                        size='big'
                        ref={register({
                            required: true,
                            pattern: patternEmail,
                            validate: {
                                exists: async value => {
                                    if(value == oldEmail) return true;
                                    let res = await api.post('/api/user/check/email/exist', {
                                        "email": value,
                                        "userId": userId
                                    });
                                    return res.data.status !== true
                                }
                            }
                        })}
                        Required={true}
                        error={errors.email && errorMessages.email[errors.email.type]}
                        valid={!errors.email && email && email.length > 0}
                        autoComplete='off'
                    />

                    <Spacer top={4} />
                    <Input
                        name='secondary_email'
                        label='Secondary Email'
                        ref={register}
                        size='big'
                    />

                    {address ?<>
                            <Spacer top={4} />
                            <Spacer inline={true} style={{ verticalAlign: 'top' }}>
                                <Input
                                    name='address'
                                    label='Full Address'
                                    ref={register({ required: false })}
                                    size='big'
                                />
                            </Spacer>
                        </>
                        :
                        <input
                            name='address'
                            type="hidden"
                            ref={register({
                                required: false,
                            })}
                        />
                    }

                    <Spacer top={4} />
                    <Input
                        name='personal_notes'
                        label='Notes'
                        ref={register}
                        size='big'
                    />

                    <Spacer top={4} />
                    <FormContacts registerToParent={register} data={lookup.contacts} edit={edit} />

                    <Spacer top={5} />
                    <ThirdTitle style={{ fontWeight: 'normal' }}>Employee Details</ThirdTitle>

                    <Spacer top={2} />

                    <Spacer inline right={5} style={{ position: 'relative', zIndex: '11' }}>
                        <Dropdown
                            name='station'
                            ref={register({ required: 'Station is required' })}
                            placeholder='Select Station'
                            label='Station *'
                            defaultValue={station}
                            options={lookup.stations}
                            size='small'
                            error={errors.station && errors.station.message}
                            valid={!errors.station && station && station.length > 0}
                            onChange={e => { setSelectedStation(e.value); setSelectedStationTimezone(e.timezone); }}
                        />
                    </Spacer>

                    <Dropdown
                        name='manager'
                        ref={register({ required: 'Manager is required' })}
                        placeholder='Select Manager'
                        defaultValue={manager}
                        label='Manager *'
                        options={lookup.managers}
                        error={errors.manager && errors.manager.message}
                        valid={!errors.manager && manager && manager.length > 0}
                    />

                    <Spacer top={4} />

                    <Spacer inline right={5} style={{ position: 'relative', zIndex: 5 }}>
                        <DayPicker
                            name='hire_date'
                            ref={register({ required: true })}
                            label='Hire Date *'
                            size='small'
                            onChange={value => {
                                if(value != "" && Object.keys(value).length > 0){
                                    clearError("hire_date");
                                }
                            }}
                            error={errors.hire_date && errorMessages.hire_date[errors.hire_date.type]}
                            valid={!errors.hire_date && hire_date && hire_date.length > 0}
                        />
                    </Spacer>

                    <Spacer inline style={{ verticalAlign: 'top' }}>
                        <Dropdown
                            name='employment_status'
                            label='Employment Status *'
                            placeholder='Select Employment Status'
                            ref={register({ required: 'Status is required' })}
                            error={errors.employment_status && errors.employment_status.message}
                            valid={!errors.employment_status && employment_status && employment_status.length > 0}
                            options={lookup.employmentStatus}
                            onChange={
                                (e) => {
                                    setSelectedEmploymentStatus(e.value);
                                }
                            }
                        />
                    </Spacer>

                    <Spacer top={4} />
                    <Dropdown
                        name='role'
                        ref={register({ required: 'Permission Level is required' })}
                        placeholder='Select Permission Level'
                        label='Permission Level *'
                        defaultValue={role}
                        options={lookup.roles}
                        size='big'
                        error={errors.role && errors.role.message}
                        valid={!errors.role && role && role.length > 0}
                        disabled={!canChangePermissionEmployee()}
                        onChange={(e) => {checkCompanyLastOwner(e)}}
                    />

                    <Spacer top={4} />
                    <Spacer inline right={5}>
                        <Dropdown
                            name='shirt_size'
                            //ref={register({ required: 'Shirt Size is required' })}
                            placeholder='Select Shirt Size'
                            label='Shirt Size'
                            defaultValue={shirt_size}
                            options={shirtSizeArr}
                            size='small'
                            // error={errors.shirt_size && errors.shirt_size.message}
                            // valid={!errors.shirt_size && shirt_size && shirt_size.length > 0}
                        />
                    </Spacer>

                    <Spacer inline right={5}>
                        <Dropdown
                            name='pant_size'
                            //ref={register({ required: 'Pant Size is required' })}
                            placeholder='Select Pant Size'
                            label='Pant Size'
                            defaultValue={pant_size}
                            options={pantSizeArr}
                            size='small'
                            // error={errors.pant_size && errors.pant_size.message}
                            // valid={!errors.pant_size && pant_size && pant_size.length > 0}
                        />
                    </Spacer>

                    <Dropdown
                        name='shoe_size'
                        // ref={register({ required: 'Shoe Size is required' })}
                        placeholder='Select Shoe Size'
                        label='Shoe Size'
                        defaultValue={shoe_size}
                        options={shoeSizeArr}
                        size='small'
                        // error={errors.shoe_size && errors.shoe_size.message}
                        // valid={!errors.shoe_size && shoe_size && shoe_size.length > 0}
                    />

                    <Spacer top={2}>
                        <Input
                            name='maximum_weekly_hour'
                            label='Maximum Weekly Hours'
                            size='big'
                            ref={register({
                                required: false,
                                pattern: patternNumericValue
                            })}
                            error={errors.maximum_weekly_hour && errorMessages.maximum_weekly_hour[errors.maximum_weekly_hour.type]}
                            valid={!errors.maximum_weekly_hour && maximum_weekly_hour && maximum_weekly_hour.length > 0}
                        />
                    </Spacer>

                    <Spacer top={5} />
                    <ThirdTitle style={{ fontWeight: 'normal' }}>Driver Details</ThirdTitle>

                    <Spacer top={2} />
                    <Input
                        name='transporter_id'
                        label='Transporter ID'
                        size='big'
                        ref={register({
                            required: false,
                            validate: {
                                exists: async value => {
                                    if(value){
                                        let res = await api.post('/api/lazy/manage/data', {
                                            "actions": {
                                                "response": {
                                                    "Driver": {
                                                        "findBy": {
                                                            "criteria": {
                                                                "company": localStorage.getItem('company'),
                                                                "transporterID": value,
                                                                "isArchive": false
                                                            },
                                                            "get": "driver",
                                                            "includes": {
                                                                "0": "id",
                                                            },
                                                            "excludes": ["drivers", "driverRoutes", "vehicleDriverRecord", "company", "invoiceTypes", "shifts", "skillRates", "rates", "schedules", "tempDriverRoutes", "routeCommitments", "stationGradingSettings", "balanceGroups", "location", "devices", "vehicles", "driverShiftStation", "tempDriverRouteShiftStation", "driverRouteCodes"]
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                        let filteredRes = res.data.data.driver.filter((d) => d.id != edit);
                                        return filteredRes.length <= 0;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        })}
                        Required={false}
                        error={errors.transporter_id && errorMessages.transporter_id[errors.transporter_id.type]}
                        valid={!errors.transporter_id && transporter_id && transporter_id.length > 0}
                        autoComplete='off'
                    />

                    <Spacer top={4} />
                    <Spacer inline right={5}>
                        <Input
                            name='license_number'
                            ref={register}
                            label='License Number'
                            size='small'
                        />
                    </Spacer>

                    <Spacer inline right={5}>
                        <Input
                            name='license_state'
                            ref={register}
                            label='License State'
                            size='small'
                        />
                    </Spacer>
                    <Spacer inline right={5}>
                        <div style={{ position: 'relative', zIndex: 15 }}>
                            <DayPicker
                                name='exp_date'
                                ref={register}
                                error={errors.exp_date && errorMessages.exp_date[errors.exp_date.type]}
                                label='Exp. Date'
                                size='small'
                            />
                        </div>
                    </Spacer>
                    <Spacer inline right={5}>
                        <Input
                            name='gas_pin'
                            label='Gas Pin'
                            size='small'
                            ref={register({
                                required: false,
                                pattern: patternNumericData,
                            })}
                            error={errors.gas_pin && errorMessages.gas_pin[errors.gas_pin.type]}
                            valid={!errors.gas_pin && gas_pin && gas_pin.length > 0}
                        />
                    </Spacer>
                    <Spacer inline right={1}>
                        <DateContainer style={{ position: "relative", zIndex: 15 }}>
                            <DayPicker
                                name="dot_medical_exp_date"
                                ref={register}
                                error={
                                    errors.dot_medical_exp_date &&
                                    errorMessages.dot_medical_exp_date[errors.dot_medical_exp_date.type]
                                }
                                label="DOT Medical Exp. Date"
                                size="small"
                            />
                        </DateContainer>
                    </Spacer>
                    <Spacer top={4} />


                    <Spacer inline right={4} style={{ position: 'relative', zIndex: 10 }}>
                        <DayPicker
                            name='expected_date'
                            ref={register}
                            error={errors.expected_date && errorMessages.exp_date[errors.expected_date.type]}
                            label='Expected First Day On The Road'
                        />
                    </Spacer>

                    <Spacer inline style={{ verticalAlign: 'bottom', position: 'relative', top: '-10px' }} >
                        <CheckBox
                            name='need_traning'
                            ref={register}
                            options={[{ label: 'Needs training', value: true }]}
                        />
                    </Spacer>

                    <Spacer top={5} />

                    {!selectedEmploymentStatus && <>
                        <ThirdTitle style={{fontWeight:'normal'}}>Skills *</ThirdTitle>
                        <Alert.Info
                            title='Note'
                            content='Please select their Employment Status before selecting their Skills.'
                            useIcon={true}
                        />
                    </>}
                    {selectedEmploymentStatus && lookup.exempt && selectedEmploymentStatus == lookup.exempt[0].id &&
                        <FormSkill
                            registerToParent={register}
                            data={lookup.skills}
                            edit={edit}
                            skill={setSelectedSkill}
                            station={selectedStation}
                        />
                    }

                    {selectedEmploymentStatus && lookup.exempt && selectedEmploymentStatus == lookup.nonExempt[0].id &&
                        <FormSkill2
                            registerToParent={register}
                            data={lookup.skills}
                            edit={edit}
                            skill={setSelectedSkill}
                            station={selectedStation}
                        />
                    }

                    <Input
                        name='skill'
                        type='hidden'
                        ref={register}
                        value={selectedSkill.data}
                        error={errors.skill && errors.skill.message}
                        valid={!errors.skill && skill && skill.length > 0}
                    />
                    <Spacer top={5} />
                    <Input
                        name='driving_preferences'
                        ref={register}
                        label='Driving Preferences'
                        size='big'
                        as='textarea'
                        style={{ height: '80px' }}
                    />
                    {lookup.isPaycomEnabled && (
                        <Spacer top={5}>
                            <ThirdTitle>Paycom Details</ThirdTitle>
                            <Spacer top={2} />
                            <Input
                                name='paycomId'
                                ref={register}
                                label='Paycom ID'
                                size='big'
                                help="4 digits number that appears after the driver's name in the clocked in/out screen"
                            />
                        </Spacer>
                    )}
                    {lookup.isADP && (
                        <Spacer top={5}>
                            <ThirdTitle>ADP Details</ThirdTitle>
                            <Spacer top={2} />
                            <Input
                                name='associateOID'
                                ref={register}
                                label='Associate ID'
                                size='big'
                            />
                        </Spacer>
                    )}

                    <Spacer top={5} />
                    <Spacer inline right={4} style={{ verticalAlign: 'bottom', position: 'relative', top: '10px' }} >
                        <CheckBox
                            name='send_invitation'
                            ref={register}
                            style={{ float: 'right' }}
                            options={[{ label: 'Send Invitation', value: true }]}
                        />
                    </Spacer>

                    {edit < 1 && selectedRole.toString() ==
                     'Delivery Associate' ?
                        <Spacer style={{ float: 'right' }}>
                            <Button type='primary' disabled={Object.keys(errors).length > 0 || isSubmitting} onClick={handleSubmit(onSubmit, 'schedule')}>
                                {isSubmitting ? <Loading style={{width: 20}}/> : "Save and Add to Schedule"}</Button>
                        </Spacer>
                        :
                        <Button type='primary' disabled={Object.keys(errors).length > 0 || isSubmitting} style={{ float: 'right' }} Type='submit'> {isSubmitting ? <Loading style={{width: 20}}/> : "Save"}</Button>
                    }
                    <AddToSchedule toggleEdit={toggleForm} scheduleVisible={scheduleVisible} toggleScheduleVisible={toggleScheduleVisible} data={getValues()} stations={lookup.stations} skills={lookup.skill} driver={scheduleDriver} station={selectedStation} />
                </form>
            </Container>
        </Modal>
    );

}

const AddToSchedule = ({ scheduleVisible, toggleScheduleVisible, toggleEdit, data, stations = [], skills = [], edit, driver, station }) => {
    const { api } = useAuth();
    const [schedules, setSchedules] = useState([]);
    const [schedulesLength, setSchedulesLength] = useState(0);
    const [driverSkill, setDriverSkill] = useState([]);
    const [selectedStation, setSelectedStation] = useState("");
    const [selectedShedule, setSelectedShedule] = useState('');

    const { register, handleSubmit, errors, getValues } = useForm({ mode: 'onBlur', defaultValues: {} });
    useEffect(() => {
        if (selectedStation == "" && station) {
            fetchData(station);
            setSelectedStation(station);
        }
        if (skills.length > 0) {
            let skillArr = [];
            skills.forEach(function (skill) {
                skillArr.push(skill.name + ' - $' + skill.hourly_rate)
            });
            setDriverSkill(skillArr);
        }
    }, [scheduleVisible]);

    const handleForm = async (data) => {
        //do schedule here
        let params = {
            "actions": {
                "update": {
                    "Driver": {
                        "driver_1": {
                            "findBy": {
                                "id": driver
                            },
                            "updateRecord": {
                                "schedules": [data.schedule]
                            }
                        }
                    }
                }
            }
        }
        let response = api.post('/api/lazy/manage/data', params);
        let updateDriverRoutesBySchedule = {
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "updateDriverRoutesBySchedule",
                            "criteria": {
                                "id": data.schedule
                            },
                            "get": "schedules"
                        }
                    }
                }
            }
        };

        await api.post('/api/lazy/manage/data', updateDriverRoutesBySchedule);
        toggleScheduleVisible();
        toggleEdit();

    }

    let resetSchedule = () => { };
    let skillArr1 = [];
    if (skills.length > 0) {
        skills.forEach(function (skill) {
            skillArr1.push(skill.id)
        });
    }
    const fetchData = async (stationId) => {
        const params = {
            "actions": {
                "response": {
                    "Schedule": {
                        "custom": {
                            "functionName": "getScheduleByShift",
                            "get": "Schedule",
                            "criteria": {
                                "skills": skillArr1,
                                "station": stationId,
                                "company": localStorage.getItem('company')
                            }
                        }
                    }
                }
            }
        }
        const response = await api.post('/api/lazy/manage/data', params);

        if (response.data) {
            const data = [];
            setSchedules(data);
            if (schedules && schedules.length > 0) {
                resetSchedule();
            }

            await response.data.data.Schedule.forEach(function (schedule,index) {
                if( index == 0 ){
                    setSelectedShedule(schedule.id);
                }
                data.push({ "value": schedule.id, "name": schedule.name });
            });
            setSchedulesLength(data.length);
            setSchedules(data);
        } else {
            console.log({ response });
        }
    }

    return (
        <Modal
            title={'Add to schedule'}
            width='340px'
            visible={scheduleVisible}
            setVisible={() => { toggleScheduleVisible(); toggleEdit() }}
            zindex='50'
        >
            <form name='addSchedule'>
                <ThirdTitle style={{ fontWeight: '400' }}>Driver</ThirdTitle>
                <Spacer top={2} />
                <People name={data.name} jobTitle='Driver' style={{ marginLeft: '10px' }} />
                <Spacer top={5} />

                <ThirdTitle style={{ fontWeight: '400' }}>Skills</ThirdTitle>
                <Spacer top={2} />
                <Tag options={driverSkill} />

                <Spacer top={5} />
                <div style={{ border: '1px solid #f5f5f5' }} />
                <Spacer top={5} />

                <Dropdown
                    name='station'
                    label='Station'
                    options={stations}
                    defaultValue={selectedStation}
                    ref={register({ required: 'Select a station' })}
                    error={errors.station && errors.station.message}
                    onChange={e => { fetchData(e.value); setSelectedStation(e.value); }}
                />
                <Spacer top={4} />

                <Dropdown
                    name='schedule'
                    label='Schedule'
                    defaultValue={selectedShedule}
                    placeholder={schedules.length <= 0 && 'Select a station first'}
                    options={schedules}
                    ref={register}
                    resetCallback={(fn) => { resetSchedule = fn }}
                />
                <Spacer top={4} />
                {schedulesLength <= 0 && selectedStation != '' &&
                    <Alert.Error
                        title='No matching schedule found'
                        content='We could not find any schedules with shift types that match the drivers skills.Please add more skills or add'
                        useIcon={true}
                    />}
                {schedulesLength <= 0 && selectedStation == '' &&
                    <Alert.Info
                        title='Not all schedules will be available for selection'
                        content='We’ll match the driver skills with the shift types in this schedule to show only schedules that drivers can be part of.'
                        useIcon={true}
                    />
                }
                <Spacer top={4} />
                {schedulesLength > 0 && selectedStation != '' &&
                    <Button type='primary' style={{ float: 'right' }} onClick={handleSubmit(handleForm)}>Add to Schedule</Button>
                }
                <Button type='primary' style={{ marginLeft: "5px" }} onClick={() => { toggleScheduleVisible(); toggleEdit() }}>Skip</Button>
            </form>

        </Modal>
    )
}

export default App;
