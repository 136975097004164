import React, {useContext, useEffect, useState} from "react";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import Empty from "../../../components/Empty";
import Modal from '../../../components/Modal';
import {Icon,Button, Spacer, ThirdTitle, TagInput,Input, Text, Title, Dropdown, CheckBox} from "@dspworkplace/ui";
import styled from "styled-components";
import {getCompanyId, useAuth} from "../../../Auth";
import Store, {WorkFlowFormProvider} from "./Context";
import {dialog, dialogPromise, loadingDialog} from "../../../components/Dialog";
import {ScrollyModalStyle, ScrollyOverlayStyle} from "../../../components/Modal";
import toast from "../../../components/Toasts/toast";
import Accordion from "./components/Accordion";
import {fetchIncidentType,fetchAllIncidentTypes, getAlldriverFromCompany,SaveWorkflow,getWorkflowTriggers,getWorkflowActions} from "./api";
import {useForm} from "react-hook-form";

const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1060px;
`;

const Phase = styled.div`
    padding: 8px 16px;
    background-color: #0071BC;
    color: #fff;
    border: 0;
    margin-top: 4px;
    border-radius: 10px;
`;
const Action = styled.div`
    float:right;
    cursor: pointer;
`;


const Step = styled.div`
    color: #0071BC;
    padding: 8px 16px;
    background-color: #EAF0F6;

    border: 0;
    margin-top: 4px;
    border-radius: 10px;
    margin-left: 20px;
`;
const Question = styled.div`
    color: #262c2d;
    padding: 8px 16px;
    background-color: #4e595c;
    color: #fff;
    border: 0;
    margin-top: 4px;
    border-radius: 10px;
    margin-left: 40px;
`;
const FilterContainer = styled.div`
    display:flex;
`;

const ActionWrapper = styled.div`
    display:flex;
    position:relative;
`;

const CloseBtn = styled.div`
    float: right;
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 7px;
    padding: 2px 3px 0;
`;




const Forms = () => {
    return (
            <WorkFlowFormProvider>
                <Wrapper>
                    <Spacer top={5} />

                    <Title>Form</Title>
                    <Spacer top={1} />

                    <Text> Setup Form here </Text>
                    <FormTypeList />
                </Wrapper>
            </WorkFlowFormProvider>
    );
}

const FormTypeList= () =>  {
    const { api } = useAuth();
    const companyId = getCompanyId();
    const [state, dispatch] = useContext(Store);
    const {register, errors, getValues, handleSubmit, reset} = useForm({mode: "onBlur"});
    const [filterOptions, setFilterOptions] = useState({'types':[],'options':[]});
    const [hasSubForm, setHasSubForm] = useState(false);
    const [hasForm, setHasForm] = useState(false);
    const [category, setCategory] = useState();
    const [loading, setLoading] = useState(false);

    const formType = [
        {name: "Incident", value: "incident" },
        {name: "Sent Home", value: "sent_home"},
        {name: "Report Incident", value: "report_incident"},
        {name: "Driver License expiration", value: "driver_license_expiration"},
    ];

    const fetchAllFormsData = async (id,category,sub_category) => {
        const [result] = await Promise.all([
            fetchIncidentType(api, id),
        ]);
        dispatch({ type: "ADD", payload: { data: result, company: companyId,category: category,sub_category:sub_category } });
        dispatch({ type: "REFRESH"});
    };

    const getSubFormOptions = async(type) => {

        switch (type) {
            case 'incident':
                const results = await Promise.all([
                    fetchAllIncidentTypes(api, companyId),
                ]);
                let tempOptionArr = [];
                results[0].forEach(function(item){
                        tempOptionArr.push({value:item.id,name:item.name});
                });
                setFilterOptions({'types':formType,'options':tempOptionArr});
                dispatch({ type: "SET_CATEGORY", payload: type });
                break;

            default:
                setFilterOptions({'types':formType,'options':[]});
                dispatch({ type: "SET_CATEGORY", payload: type });
                break;
        }
    }
    const onSubmit = async (data) => {
        setLoading(true);
        switch (data.category ) {
            case 'incident':
                    if( data.form_option ){
                        await fetchAllFormsData(data.form_option,'incident', data.form_option);
                    } else {
                        await getSubFormOptions(data.category);
                    }
                    setHasForm(false)
                    setHasSubForm(true);
                    setLoading(false);
                    break;

            case 'sent_home':
                setHasSubForm(false);
                setHasForm(true);

                dispatch({ type: "ADD", payload: { data: [], company: companyId } });
                dispatch({ type: "SET_CATEGORY", payload: data.category });
                setLoading(false);
                break;
            case 'unsent_home':
                setHasSubForm(false);
                setHasForm(true)

                dispatch({ type: "ADD", payload: { data: [], company: companyId } });
                dispatch({ type: "SET_CATEGORY", payload: data.category });
                setLoading(false);
                break;
            case 'report_incident':
                setHasSubForm(false);
                setHasForm(true)

                dispatch({ type: "ADD", payload: { data: [], company: companyId } });
                dispatch({ type: "SET_CATEGORY", payload: data.category });
                setLoading(false);
                break;
            case 'driver_license_expiration':
                setHasSubForm(false);
                setHasForm(true);

                dispatch({ type: "ADD", payload: { data: [], company: companyId } });
                dispatch({ type: "SET_CATEGORY", payload: data.category });
                setLoading(false);
                break;

            default:
                getSubFormOptions(data.category);
                setHasSubForm(true);
                setLoading(false);
                break;
        }


    }

    const setFilterOptionsData = async () => {
        setFilterOptions({'types':formType,'options':[]});
    }

    const addAction = async (data,type) => {

        dispatch({ type: "MODAL_VISIBLE", payload: true });
        dispatch({ type: "REFRESH"});

        return dialogPromise((success, cancel) =>
            <AddActions
                api={api}
                data={data}
                cancel={cancel}
                register={register}
                dispatch={dispatch}
                state={state}
                formType={type}
                handleSubmit={handleSubmit}
            />
        )
    }
    useEffect(() => {
        setFilterOptionsData();
    },[]);

    return(<>
            {loading && <LoadingWrapper><Loading style={{width: 40}}/></LoadingWrapper> }
            <FilterContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Spacer top={5} />
                    <Spacer inline={true}>
                            <Dropdown
                                name="category"
                                label="Category"
                                placeholder="Select Category"
                                size={"small"}
                                ref={register}
                                options={filterOptions.types}
                                onChange={(e) => {
                                    setCategory(e.value);
                                    onSubmit({category:e.value})}}
                            />
                    </Spacer>
                    <Spacer inline={true} left={5}>
                            {hasSubForm && <Dropdown
                                name="form_option"
                                label="Select Sub Category"
                                placeholder="Select Sub Category"
                                size={"small"}
                                ref={register}
                                options={filterOptions.options}
                                onChange={(e) => {
                                    onSubmit({category:category,form_option:e.value})
                                }}
                            />}
                    </Spacer>
                    </form>
                    { hasForm &&
                     <Spacer inline={true} top={12}><Button type={"primary"}   onClick={() => { addAction({token:state.category}) }} style={{width: 250}}>
                        Add Workflow
                    </Button></Spacer> }

            </FilterContainer>
            <Spacer bottom={5} />

            {!state.isLoading &&
                    state.forms.length > 0 &&
                    state.forms.map((data, key) => <>
                            <Phase>{data.name} <Action  onClick={() => { addAction(data,'IncidentType') }}><Icon.Edit size='20px' color='#fff'/>Add Action</Action></Phase>
                            { data.steps.length > 0 &&
                                data.steps.map((step, key) => <>
                                    <Step>{step.name}</Step>
                                    { step.questions.length > 0 &&
                                        step.questions.map((question, key) => <>
                                            <Accordion
                                                title={ question.label }
                                                class="accordion_question_section"
                                                data={question}
                                                addAction = {addAction}
                                            />
                                        </>)}
                                </> )}
                    </>
            )}
            <Spacer bottom={50} />

    </>);
}

const AddActions = ({ api,data,cancel,dispatch,state ,formType}) => {

    const [trigger, setTrigger] = useState();
    const [drivers, setDrivers] = useState();
    const [triggersLists, setTriggerLists] = useState([]);
    const [actionssLists, setActionsLists] = useState([]);
    const [addAction, setAddAction] = useState([]);
    const [addActionCount, setAddActionCount] = useState(1);
    const companyId = getCompanyId();
    const {register, unregister, errors, getValues, handleSubmit, reset} = useForm({mode: "onChange"});

    const refreshSelectedActions = () =>{
        let tempAddedActionIds = addAction.map((i)=> i.defaultValue);
        let acArray = actionssLists.map((item,itemKey)=>{
            return (tempAddedActionIds.includes(item.value))?{'name':item.name,'value':item.value,disable:true}:{'name':item.name,'value':item.value};
        });
        setActionsLists(acArray);
    }

    const addActionMethod = async () => {
        let action = {'id':'new_'+addActionCount,'data':[],'name':[],'trigger':'','isDriver':false, 'isDriverLicenceExpiration' :false};
        setAddActionCount(addActionCount+1);
        setAddAction([...addAction,action]);
    }

    function removeAction(key) {
        addAction.splice(key, 1);
        setAddAction([...addAction]);
    }

    const getAllDrivers = async () => {

        const results = await Promise.all([
            getAlldriverFromCompany(api, companyId),
        ]);

        let tempDriverArr = [];
        results[0].forEach(function(item){
            tempDriverArr.push({value:item.id,name:item.friendlyName});
        });
        setDrivers(tempDriverArr);
    };

    const getAllTriggers = async () => {
        const results = await getWorkflowTriggers(api, state.category,formType,data.ignoreActions);
        let tempDriverArr = [];
        results.forEach(function(item){
            tempDriverArr.push({value:item.value,name:item.name});
        });
        setTriggerLists(tempDriverArr);
    };


    const getActionsFromTrigger = async (val) => {
        const results = await getWorkflowActions(api, state.category,val,data.ignoreActions);
        let tempActionArr = [];
        results.forEach(function(item){
            tempActionArr.push({value:item.value,name:item.name});
        });
        setActionsLists(tempActionArr);
        //setTrigger(e.value)
    }
    const setDriverDropdown = (val,key) => {
        addAction[key]={
            ...addAction[key],
            defaultdriverVal:val.map((v)=>v.value)
            }
            setAddAction([...addAction]);
    };
    const expirationCheckboxCheckEvent =(name, key)=>{
      let defaultData = (addAction[key].defaultDataVal)?addAction[key].defaultDataVal:[];

      if(defaultData.expirationTypes.includes(name)){
        defaultData.expirationTypes.splice(defaultData.expirationTypes.indexOf(name),1);
      }else{
        defaultData.expirationTypes.push(name);
      }

      addAction[key]={
        ...addAction[key],
        defaultDataVal:defaultData,
      }
      setAddAction([...addAction]);
    }

    const expirationDayDropdown = (type, value, key) =>{
      let defaultData = addAction[key].defaultDataVal;

      defaultData[type] = value;

      addAction[key]={
        ...addAction[key],
        defaultDataVal:defaultData,
      }
      setAddAction([...addAction]);
    }

    function changeAction(val,key){
            addAction[key]={
            ...addAction[key],
            defaultValue:val,
            isDriver:false,
            isDriverLicenceExpiration:false
            }
            setAddAction([...addAction]);
            refreshSelectedActions();

            // addAction[key]={
            //     ...addAction[key],
            //     isDriver:false
            // }
            // setAddAction([...addAction]);
        if( val == 'send_mail_to_selected_user' || val == 'send_bot_message_to_selected_user' ){
                addAction[key]={
                    ...addAction[key],
                    isDriver:true
                }
                setAddAction([...addAction]);
        }
        if( state.category != 'incident' && (val == 'send_bot_message_to_driver' || val == 'send_bot_message_to_station_manager') ){
                addAction[key]={
                    ...addAction[key],
                    isDriverLicenceExpiration:true,
                    defaultDataVal:{
                      expirationTypes:[]
                    }
                }
                setAddAction([...addAction]);
        }
    }


    const onSubmit = async formData => {
        let subCat = '';
        if(state.sub_category){
            subCat = state.sub_category+'-'+formType;
        }
        let category = {'category':state.category,'sub_category':subCat};
        formData['ignoreActions'] = data.ignoreActions;
        await SaveWorkflow(api, formData,category,addAction.length,data.token,companyId,0);
        toast({
            type: 'success',
            title: 'Workflow successfully saved',
            useClose: true,
            timeout: true
        });
        setAddActionCount(1);
        setAddAction([]);
        setActionsLists([]);
        setTriggerLists([]);
        state.modalVisible = false;
        dispatch({ type: "REFRESH"});
        reset();
    }

    useEffect(() => {
        setAddActionCount(1);
        setAddAction([]);
        setActionsLists([]);
        setTriggerLists([]);
        getAllTriggers();
        getAllDrivers();
    },[]);

    return(<>
            <Modal
                title={'Create Workflow'}
                visible={state.modalVisible}
                setVisible={cancel}
                width={'600px'}
                >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <ThirdTitle>IF</ThirdTitle>
                    <Dropdown
                        style={{width: 555}}
                        name="trigger"
                        label="Trigger"
                        placeholder="Select Trigger"
                        ref={register({required: 'Please Select Trigger'})}
                        error={errors.trigger && errors.trigger.message}
                        options={triggersLists}
                        Required={false}
                        onChange={(e) => setTimeout(()=> getActionsFromTrigger(e.value),1)}
                    />
                    <Spacer top={5} />
                    <Spacer top={5} />
                    { addAction.length > 0 &&
                        addAction.map((ac, key) => <>
                            <ActionWrapper key={key}>
                                <ThirdTitle>{ key == 0 ? 'THEN' : 'AND'} </ThirdTitle>
                                <CloseBtn key={key} onClick={() => { removeAction(key); unregister(`${ac.id}_q`); unregister(`${ac.id}_a`); }}><Icon.Times size='20px' color='red'/></CloseBtn>
                                <Spacer inline={true} bottom={5} top={5} >
                                <Dropdown
                                    style={{width: 515}}
                                    name={`${ac.id}_q`}
                                    label="Action"
                                    placeholder="Select Action"
                                    key={`${ac.id}_q`}
                                    ref={register({required: true})}
                                    options={actionssLists}
                                    defaultValue={ac.defaultValue}
                                    Required={false}
                                    onChange={(e) => setTimeout(()=> changeAction(e.value,key),1)}
                                    error={errors && errors[`${ac.id}_a`] && 'Please fill the all require field In this action'}
                                />
                                { ac.isDriver && <TagInput
                                    ref={register}
                                    key={`${ac.id}_a`}
                                    name={`${ac.id}_a`}
                                    label='Drivers'
                                    placeholder="Select Driver"
                                    style={{width: 515}}
                                    defaultValues={ac.defaultdriverVal}
                                    options={drivers}
                                    onChange={(e) => setDriverDropdown(e,key)}
                                />}
                                { ac.isDriverLicenceExpiration && <>
                                  <Spacer inline={true} bottom={5} top={5} >
                                    <CheckBox
                                      name={`${ac.id}_a['expirationTypes']`}
                                      title={'Expiration Types'}
                                      ref={register({required: false})}
                                      onClick={(e)=>{expirationCheckboxCheckEvent(e.target.value, key)}}
                                      options={[
                                          {value:'same_day',label:'Notification same day'},
                                          {value:'after_day',label:'Notification afte day'},
                                          {value:'before_day',label:'Notification before day'},
                                      ]}
                                    />
                                  </Spacer>
                                  { ac.defaultDataVal.expirationTypes && ac.defaultDataVal.expirationTypes.includes('after_day')  &&
                                    <Spacer inline={true} bottom={5} top={5} >
                                      <Dropdown
                                        style={{width: 515}}
                                        label={'After Days'}
                                        name={`${ac.id}_a['after_day']`}
                                        placeholder="Select After Days"
                                        key={`${ac.id}_aAfter`}
                                        ref={register({required: true})}
                                        options={Array.from({length: 60}, (_, index) => {
                                          return {value:index + 1,name:index + 1};
                                        })}
                                        defaultValue={ac.defaultDataVal && ac.defaultDataVal.after_day}
                                        Required={true}
                                        onChange={(e)=>{expirationDayDropdown('after_day',e.value, key)}}
                                      />
                                    </Spacer>
                                  }
                                  { ac.defaultDataVal.expirationTypes && ac.defaultDataVal.expirationTypes.includes('before_day')  &&
                                    <Spacer inline={true} bottom={5} top={5} >
                                      <Dropdown
                                        style={{width: 515}}
                                        label={'Before Days'}
                                        name={`${ac.id}_a['before_day']`}
                                        placeholder="Select Before Days"
                                        key={`${ac.id}_aBefore`}
                                        ref={register({required: true})}
                                        options={Array.from({length: 60}, (_, index) => {
                                          return {value:index + 1,name:index + 1};
                                        })}
                                        defaultValue={ac.defaultDataVal && ac.defaultDataVal.before_day}
                                        Required={true}
                                        onChange={(e)=>{expirationDayDropdown('before_day',e.value, key)}}
                                      />
                                    </Spacer>
                                  }
                                </>}
                                </Spacer>
                            </ActionWrapper>
                        </>)}
                    <Spacer top={2} />
                    {actionssLists.length > 0 &&
                    <Button
                        size={'small'}
                        Type={'button'}
                        onClick={()=>{ addActionMethod() }}>Add Action</Button>
                    }
                    <Spacer top={2}/>
                        <Input
                            name="description"
                            size="big"
                            label="Description"
                            ref={register}
                            as="textarea"
                            style={{width: 555, height: 80}}
                        />
                    <Spacer top={5}/>
                    <Button type={"primary"}  style={{marginLeft: "auto", display: "block", width: 80}}>
                        Save
                    </Button>
                </form>
            </Modal>
        </>
    )
}


export default Forms;
