import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Password, DropUploader, Dropdown, CustomDropdown } from "@dspworkplace/ui";
import { getCompanyId, getUserId, useAuth, getToken} from "../../../Auth";
import SettingsContext from "../context";
import {toast} from "../../../components/Toasts";
import Modal from "../../../components/Modal";
import {dialogPromise} from "../../../components/Dialog";
import { canEditCompanySetting } from "../../Scheduler/Common/AccessCheck";


export default function ADPCertificate()
{
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const {register, handleSubmit, reset, watch, errors, getValues} = useForm();
    let resetDrivers = () => {};


    const modalCSRCertificate = async () => {
        let paramCertificate = {
            actions: {
                response: {
                    ADPCertificates:{
                        custom: {
                            functionName: "getGenerateCertificateByCompany",
                            get: "result",
                            criteria: {
                                companyId: getCompanyId()
                            },
                        }
                    }
                }
            }
        };
        await api.post('/api/lazy/manage/data', paramCertificate).then(
            response =>  {
                if (response.data.data.result) {
                    reset({
                        countryName : response.data.data.result.countryName,
                        stateOrProvinceName : response.data.data.result.stateOrProvinceName,
                        localityName : response.data.data.result.localityName,
                        organizationName : response.data.data.result.organizationName,
                        organizationalUnitName : response.data.data.result.organizationUnitName,
                        commonName : response.data.data.result.commonName,
                        emailAddress : response.data.data.result.emailAddress,
                    });    
                }
            }

        );
        const submitCSRCertificateForm = async (data, evt) => {
           let form = evt.target;
           form.querySelector('button').disabled = true;
           data.companyId = getCompanyId();
           let param = {
                    actions: {
                        response: {
                            ADPCertificates: {
                                custom: {
                                    functionName: "saveAndGenerateCertificate",
                                    get: "result",
                                    criteria: data
                                }
                            }
                        }
                    }
               };
           api.post('/api/lazy/manage/data', param).then(
               response =>  {
                   form.querySelector('button').disabled = false;
                   toast({
                       type: 'success',
                       title: 'ADP CSR Certificate',
                       content: 'ADP CSR Certificate Generated Successfully.'
                    });
                    window.open(response.data.data.result.pdf.csrFile);
                    window.open(response.data.data.result.pdf.keyFile);
               }
   
           );
        }
        return dialogPromise((success, cancel) =>
            <Modal
            width="506px"
            title='Generate CSR Certificate'
            visible={true}
            setVisible={success}
            >
                <form name="adp_certificate_form"  onSubmit={handleSubmit(submitCSRCertificateForm)}>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='countryName'
                        label='Country Name'
                        Required={true}
                        valid={(!errors || !errors.countryName) && values.countryName}
                        invalid={errors && errors.countryName}
                        error={errors && errors.countryName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='stateOrProvinceName'
                        label='State or Province Name'
                        Required={true}
                        valid={(!errors || !errors.stateOrProvinceName) && values.stateOrProvinceName}
                        invalid={errors && errors.stateOrProvinceName}
                        error={errors && errors.stateOrProvinceName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='localityName'
                        label='Locality Name'
                        Required={true}
                        valid={(!errors || !errors.localityName) && values.localityName}
                        invalid={errors && errors.localityName}
                        error={errors && errors.localityName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='organizationName'
                        label='Organization Name'
                        Required={true}
                        valid={(!errors || !errors.organizationName) && values.organizationName}
                        invalid={errors && errors.organizationName}
                        error={errors && errors.organizationName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        Required={true}
                        name='organizationalUnitName'
                        label='Organization Unit Name'
                        valid={(!errors || !errors.organizationalUnitName) && values.organizationalUnitName}
                        invalid={errors && errors.organizationalUnitName}
                        error={errors && errors.organizationalUnitName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='commonName'
                        label='Common Name'
                        Required={true}
                        valid={(!errors || !errors.commonName) && values.commonName}
                        invalid={errors && errors.commonName}
                        error={errors && errors.commonName && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Input
                        ref={register({
                            required: true
                        })}
                        name='emailAddress'
                        label='Email Address'
                        Required={true}
                        valid={(!errors || !errors.emailAddress) && values.emailAddress}
                        invalid={errors && errors.emailAddress}
                        error={errors && errors.emailAddress && 'Required field'}
                        autoComplete="off"
                    />
                    <Spacer bottom={3}/>
                    <Password
                        name='challengePassword'
                        label='A challenge password'
                        ref={register({})}
                        autoComplete="off"
                    />
                    {canEditCompanySetting() && <Spacer top={5} style={{ textAlign: "right" }} >
                        <Button type="primary" >Save</Button>
                    </Spacer>}
                </form>
            </Modal>
        )
    };    

    const modalPFXCertificate = async () => {
        const submitPFXCertificateForm = async (data, evt) => {
           console.log(data);
           let form = evt.target;
           form.querySelector('button').disabled = true;
           const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                }
           };
           let formData = new FormData();
            formData.append('file', data.crtFile[0]);
            formData.append('companyId', getCompanyId());
            api.post('api/adp/certificate/upload', formData).then(
            response =>  {
                form.querySelector('button').disabled = false;
                toast({
                    type: 'success',
                    title: 'ADP PFX Certificate',
                    content: 'ADP PFX Certificate Generated Successfully.'
                });
                window.open(response.data.data.result.pdf);
            }
   
           );
        }
        return dialogPromise((success, cancel) =>
            <Modal
            width="400px"
            title='Generate PFX Certificate'
            visible={true}
            setVisible={success}
            >
                <form name="adp_pfx_certificate_form"  onSubmit={handleSubmit(submitPFXCertificateForm)}>
                    <DropUploader
                        name='crtFile'
                        label={'CER/CRT File'}
                        multiple={false}
                        ref={register({
                            required: 'Please select file'
                        })}
                        // accept={"text/csr"}
                        Required
                        error={errors.document && errors.document.message}
                        valid={!errors.document && document && document.length > 0}
                    />
                    <Spacer top={5} style={{ textAlign: "right" }} >
                        <Button type="primary" >Save</Button>
                    </Spacer>
                </form>
            </Modal>
        )
    };   

    const values = getValues();
    

    return (
        <>
            <SubTitle>ADP Certificate</SubTitle>
            <Spacer bottom={5}/>
            <Button style={{ marginRight: "10px" }} type='success' onClick={modalCSRCertificate}>Generate CSR Certificate</Button>
        </>    
    )    
}