import React, { useState } from "react";
import DatePicker from "../../../components/Calendar/picker";
import { Button, Dropdown, Icon, Spacer } from "@dspworkplace/ui";
import { useForm } from "react-hook-form";

const DashboardItemFilter = ({ item, resultSet, updateQuery }) => {
    const [selectedButton, setSelectedButton] = useState('');
    const [timeDate, setTimeDate] = useState('');
    const timeFilter = [
        { name: 'All Time', value: '0' },
        { name: 'Today', value: 'Today' },
        { name: 'This week', value: 'This week' },
        { name: 'Last week', value: 'Last week' },
        { name: 'Last 30 days', value: 'Last 30 days' },
        { name: 'Custom', value: 'Custom' }
    ]

    const methods = useForm({
        mode: "onBlur",
    });
    const { register, getValues, setValue } = methods;

    const captureTodayDate = (buttonText) => {
        if (buttonText && buttonText === 'Custom') return;
        if (buttonText && buttonText === '0') { return handleReset(item) }
        const { measures, dimensions, timeDimensions, order, filters } = resultSet?.loadResponse?.pivotQuery || {};
        const orderValue = order?.map(item => [item.id, item.desc === true ? 'desc' : 'asc']);
        const output = timeDimensions?.length > 0
            ? timeDimensions?.map(rs => {
                return rs.dimension === "NetradyneEOC.date" ? { ...rs, dateRange: buttonText } : rs
            }) : [{ dimension: "NetradyneEOC.date", granularity: "day", dateRange: buttonText }];
        updateQuery({
            limit: 50, order: orderValue, measures: measures, dimensions: dimensions,
            timeDimensions: output, filters: filters
        })
    };

    const handleReset = (item) => {
        setValue("timeFilter", '0')
        const { dimensions, measures, order, timeDimensions, filters } = item?.chartQuery || [];
        updateQuery({
            limit: 50, order: order, measures: measures, dimensions: dimensions,
            timeDimensions: timeDimensions, filters: filters
        })
        setSelectedButton('')
    }

    const handleButtonClick = (e) => {
        const buttonText = e.value;
        captureTodayDate(buttonText);
        setSelectedButton(buttonText);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>
                {selectedButton === 'Custom' &&
                    <DatePicker
                        name={'date'}
                        range={'date'}
                        placeholder="Custom"
                        onChange={async (date) => {
                            if (date.length > 0) {
                                const splitDate = date.split(" – ");
                                captureTodayDate(splitDate);
                                setTimeDate(splitDate)
                            }
                            else {
                                setTimeDate(false)
                            }
                        }}
                        defaultValue={timeDate ? [new Date(timeDate[0]), new Date(timeDate[1])] : false}
                    />}
                <Spacer right={2} />
                <Dropdown
                    name='timeFilter'
                    // label='Time Filter'
                    options={timeFilter}
                    size={'small'}
                    ref={register}
                    defaultValue={getValues().timeFilter || timeFilter}
                    onChange={async (e) => {
                        handleButtonClick(e);
                        await setValue("timeFilter", e.value)
                    }}
                />
                <Spacer right={2} />
                <Button>
                    <Icon.Refresh
                        size={"24px"}
                        style={{ display: "flex", alignItem: "center" }}
                        onClick={() => { handleReset(item) }}
                    />
                </Button>
            </div>
        </>
    )
}

export default DashboardItemFilter;