import React, {useCallback, useEffect, useState} from 'react';
import InputForBot from "./InputForBot";
import styled from "styled-components";
import {
    DriverFetchTask,
    FetchTaskOpenShift,
     MessageOfConversation,
    ShiftDataTime, SystemMessage,
    Task,
} from "./types";
import {
    CheckBox,
} from "@dspworkplace/ui";
import {
    engine,
    getCompanyId,
    getDriverId,
    getFriendlyName,
    getMainStationId,
    getUserId, showErrorToast
} from "../Utilities";
import Loading, {useLoading} from "./Loading";
import {Button} from "@dspworkplace/ui";
import BotTempMessage from "./BotTempMessage";
import {useUpdate as useBotDispatch} from "./BotStore";
// @ts-ignore
import {devLog} from "../utils";
import {ScrollContainer} from "./BotTaskInteraction";
import {useForm} from "react-hook-form";
import moment from "moment-timezone";
import {Message as MessageConversation} from '@twilio/conversations';

const Container = styled.div`
    width: 300px;
    display: flex;
    row-gap: 4px;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : "column")};;
    margin: 4px 20px 4px 20px;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};

    &.row {
        flex-direction: row;
    }

    &.left {
        flex-direction: row;
        justify-content: flex-start;
    }

    &.right {
        flex-direction: row;
        justify-content: flex-end;
    }

    &.btn {
        padding-left: 8px;
        margin: 8px 20px 8px 20px;
    }
`;

async function setTaskForOpenShifts(taskInformation: { id: number, type: string }, message: MessageConversation): Promise<FetchTaskOpenShift | null> {
    try {
        let {data} = await engine({hideDefaultMessage: true})
            .post("/api/fetch_task", {
                task: {
                    id: taskInformation.id,
                    type: taskInformation.type,
                },
                companyId: getCompanyId(),
                stationId: getMainStationId(),
                userId: getUserId(),
                driverId: getDriverId(),
                datetime: moment().toISOString(),
                messageSid: message.sid,
                channelSid: message.conversation.sid,
                attributes: message.attributes,
                taskInformation,
            });
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error in fetching the task information for Open Shifts.')
        return null;
    }
}

type Props = {
    message: MessageOfConversation;
    twilioMessage: MessageConversation;
    taskInformation: { id: number, type: string };
    scrollToBottom: () => void;
}

function BotTaskOpenShifts({message, taskInformation, twilioMessage}: Props) {
    const [systemMsg, setSystemMsg] = useState<SystemMessage>({
        user: message.user,
        date: message.date,
        text: message.text
    });
    const dispatch = useBotDispatch();
    const {loading, setLoading} = useLoading();
    const [drivers, setDrivers] = useState<Array<DriverFetchTask>>([]);
    const [shift, setShift] = useState<ShiftDataTime>();
    const [shiftInformation, setShiftInformation] = useState<string>("");
    const [count, setCount] = useState(0);
    const [task, setTask] = useState<Task>();

    const removeMessage = useCallback(
        async () => {
            // only js. not api
            dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
        },
        [dispatch, message.element, message.sid],
    );

    useEffect(() => {
        const abortController = new AbortController();
        setTaskForOpenShifts(taskInformation, twilioMessage).then(data => {
            if (data) {
                setSystemMsg({user: message.user!, date: message.date, text: message.text});
                setShiftInformation(data.shiftInformation);
                setShift(data.shift);
                setTask(data.task);
                setDrivers(data.drivers);
                setCount(data.count);
                setLoading(false);
            }
        })
        return () => {
            abortController.abort();
        };
    }, []);
// message.date, message.text, message.user, setLoading, taskInformation.id, taskInformation.type

    if (loading) {
        return <Loading containerHeight={"500px"}/>
    }

    return (
        <>
            <OpenShiftChat drivers={drivers} shift={shift!} systemMsg={systemMsg} task={task!} count={count}
                           removeMessage={removeMessage} shiftInformation={shiftInformation}/>
        </>
    );
}

export default BotTaskOpenShifts;

const ButtonContainer = styled(Button)`
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    font-family: circe-rounded, serif;
    font-size: 16px;
    color: #0071bc;
    display: flex;
    justify-content: center;

    &.primary {
        color: white;
    }

    &.openShiftDriver {
        font-size: 14px;
        font-weight: 600;
    }

    &.driver {
        font-size: 14px;
    }
`;

type OpenShiftDriverProps = {
    driver: DriverFetchTask;
    selectDriver: (driver: DriverFetchTask) => void;
}

function OpenShiftDriverButton({driver, selectDriver}: OpenShiftDriverProps) {
    return (
        <ButtonContainer onClick={selectDriver(driver)}>
            <Text className={"openShiftDriver"}>{driver.fullName}</Text>
            <Text className={"openShiftDriver"}>{" - "}</Text>
            <Text className={"openShiftDriver"}>{driver.weekHours.workedHours}/{driver.weekHours.scheduledHours}</Text>
        </ButtonContainer>
    );
}


const ContainerItem = styled.div`
    background-color: #EAF0F6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${props => (props.width ? props.width : "100%")};
    padding: ${props => (props.padding ? props.padding : "12px")};
    column-gap: ${props => (props.columnGap ? props.columnGap : "0")};
    max-height: 48px;
`;

const ContainerMultipleOpenShiftDriver = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: 48px;
    background-color: #EAF0F6;
    border-radius: 2px;
`;

type SelectedDriver = {
    id: number;
    disabled: boolean;
    selected: boolean;
    fullName: string;
    weekHours: {
        workedHours: number;
        scheduledHours: number;
    }
}

type MultipleOpenShiftDriverProps = {
    drivers: Array<DriverFetchTask>;
    approveMultipleDriver: (drivers: Array<SelectedDriver>) => void;
    count: number;
}

// @ts-ignore
function MultipleOpenShiftDriver({drivers, count, approveMultipleDriver}: MultipleOpenShiftDriverProps) {
    // @ts-ignore
    const {register} = useForm({mode: 'onBlur'});
    const [checkedDrivers, setCheckedDrivers] = useState<Array<SelectedDriver>>([]);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();
        setCheckedDrivers((drivers.map(e => ({...e, disabled: false, selected: false}))));
        return () => {
            abortController.abort();
        };
    }, [drivers]);

    useEffect(() => {
        const abortController = new AbortController();
        if (currentCount === count) {
            setCheckedDrivers(prevDrivers => {
                return prevDrivers.map(e => {
                    if (!e.selected) {
                        return {...e, disabled: true};
                    }
                    return e;
                });
            });
        }
        return () => {
            abortController.abort();
        };
    }, [count, currentCount]);

    // @ts-ignore
    const onClick = (driverId, isSelected) => {
        if (!isSelected) {
            setCheckedDrivers(prevDrivers => {
                return prevDrivers.map(e => {
                    if (e.id === driverId) {
                        if (currentCount + 1 > count) {
                            return {...e};
                        } else {
                            setCurrentCount(prevCount => prevCount + 1);
                        }
                        return {...e, selected: true};
                    }
                    return e;
                });
            });
        } else {
            if (currentCount === count) {
                setCheckedDrivers(prevDrivers => {
                    return prevDrivers.map(e => {
                        if (e.id === driverId) {
                            setCurrentCount(prevCount => prevCount - 1);
                            return {...e, selected: false};
                        }
                        return {...e, disabled: false};
                    });
                });
            } else {
                setCheckedDrivers(prevDrivers => {
                    return prevDrivers.map(e => {
                        if (e.id === driverId) {
                            setCurrentCount(prevCount => prevCount - 1);
                            return {...e, selected: false};
                        }
                        return e;
                    });
                });
            }
        }
    }


    return (
        <>
            {checkedDrivers.map((driver) => {
                return <ContainerMultipleOpenShiftDriver key={driver.id}>
                    <ContainerItem columnGap={"4px"} className={'driver'}>
                        <Text className={"openShiftDriver"}>{driver.fullName}</Text>
                        <Text className={"openShiftDriver"}>{" - "}</Text>
                        <Text className={"openShiftDriver"}>
                            {driver.weekHours.workedHours}/{driver.weekHours.scheduledHours}</Text>
                    </ContainerItem>
                    <ContainerItem width={'auto'} className={'checkBox'} padding={'0'}>
                        <CheckBox
                            name={`driverId`}
                            disabled={driver.disabled}
                            ref={register}
                            onClick={() => {
                                onClick(driver.id, driver.selected);
                            }}
                            options={[{label: "", value: driver.id}]}
                        />
                    </ContainerItem>
                </ContainerMultipleOpenShiftDriver>
            })}
            <Button size="small"
                    onClick={approveMultipleDriver(checkedDrivers)}>{"Approve"}</Button>
        </>
    );
}

type MessagesToSentProps = {
    drivers: Array<DriverFetchTask>,
    systemMsg: SystemMessage,
    message: string,
    scenario: string,
}

function MessagesToSent({drivers, systemMsg, message, scenario}: MessagesToSentProps) {
    return (
        <>
            {drivers.map((e, i) => {
                if (i === 0) {
                    return <BotTempMessage
                        key={e.id}
                        message={
                            {
                                user: systemMsg.user,
                                date: new Date(),
                                text: scenario === "APPROVAL" ? `The approved driver will receive this message: "Hi ${e.fullName}. ${message}` : `The rejected driver will receive this message: "Hi ${e.fullName}. ${message}`,
                            }
                        }/>
                }
                return <BotTempMessage
                    key={e.id}
                    showHeader={false}
                    message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: scenario === "APPROVAL" ? `The approved driver will receive this message: "Hi ${e.fullName}. ${message}` : `The rejected driver will receive this message: "Hi ${e.fullName}. ${message}`,
                        }
                    }/>
            })}
        </>
    )
}

type OpenShiftChatProps = {
    drivers: Array<DriverFetchTask>,
    shift: ShiftDataTime,
    task: Task,
    systemMsg: SystemMessage,
    count: number,
    removeMessage: () => void,
    shiftInformation: string,
}

// @ts-ignore
function OpenShiftChat({drivers, shift, task, systemMsg, count, removeMessage, shiftInformation}: OpenShiftChatProps) {
    let managerFriendlyName = getFriendlyName();
    const [showDriversToSelect, setShowDriversToSelect] = useState(true);
    const [selectedDrivers, setSelectedDrivers] = useState<Array<DriverFetchTask>>([]);
    const [step, setStep] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [placeholder, setPlaceholder] = useState("");
    const [rejectedDrivers, setRejectedDrivers] = useState<Array<DriverFetchTask>>([]);
    const [showRejectionPart, setShowRejectionPart] = useState(false);
    const [approvedMessage, setApprovedMessage] = useState<string>("");
    const [rejectedMessage, setRejectedMessage] = useState<string>("");
    const [submitButtonText, setSubmitButtonText] = useState("Send");
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [taskLoading, setTaskLoading] = useState(false);
    const [showFinalPart, setShowFinalPart] = useState(false);
    const [showApprovalPart, setShowApprovalPart] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const approveMultipleDriver = useCallback(
        (checkedDrivers: Array<SelectedDriver>) => () => {
            let selected = checkedDrivers.filter(e => e.selected);
            let notSelected = checkedDrivers.filter(e => !e.selected);
            if (selected.length === 0) {
                return;
            }
            setSelectedDrivers(selected);
            setRejectedDrivers(notSelected);
            setShowDriversToSelect(false);
            let msg = `${managerFriendlyName} has approved your open shift request ${shiftInformation}.`;
            setApprovedMessage(msg);
            setPlaceholder("Thank You!");
            setDisabled(false);
            setShowApprovalPart(true);
            setStep('SEND');
            if (selected.length === 1 && notSelected.length === 0) {
                setStep(" FINISH_TASK_ALL_PASS");
                setSubmitButtonText("Finish Task");
            }
            if (selected.length === drivers.length) {
                setStep(" FINISH_TASK_ALL_PASS");
                setSubmitButtonText("Finish Task");
            }
        },
        [drivers.length, managerFriendlyName, shiftInformation],
    );

    const selectOneDriver = useCallback(
        (driver: DriverFetchTask) => () => {
            setShowDriversToSelect(false);
            let approved = [driver];
            let notApproved = drivers.filter(e => e.id !== driver.id);
            setSelectedDrivers(approved);
            setRejectedDrivers(notApproved);
            let msg = `${managerFriendlyName} has approved your open shift request ${shiftInformation}.`;
            setApprovedMessage(msg);
            setPlaceholder('Thank You!');
            setStep('SEND');
            setDisabled(false);
            setShowApprovalPart(true);
            if (drivers.length === 1) {
                setStep(" FINISH_TASK_ALL_PASS");
                setSubmitButtonText("Finish Task");
            }
        },
        [drivers, managerFriendlyName, shiftInformation],
    );

    const rejectDriver = useCallback(
        () => {
            setShowDriversToSelect(false);
            setRejectedDrivers(drivers);
            setShowRejectionPart(true);
            setShowApprovalPart(false);
            setPlaceholder("Sorry, this shift has been assigned, please look for another open shift.");
            setStep("FINISH_TASK");
            setDisabled(false);
            let msg = `${managerFriendlyName} has denied your open shift request ${shiftInformation}.`;
            setRejectedMessage(msg);
            setShowRejectionPart(true);
            setSubmitButtonText("Finish Task");
        },
        [drivers, managerFriendlyName, shiftInformation],
    );

    const reset = useCallback(
        () => {
            setSelectedDrivers([]);
            setRejectedDrivers([]);
            setApprovedMessage("");
            setShowDriversToSelect(true);
            setPlaceholder("");
            setDisabled(true);
            setTaskLoading(false);
            setTaskCompleted(false);
            setRejectedMessage("");
            setShowRejectionPart(false);
            setSubmitButtonText("Send");
            setStep("");
            setShowFinalPart(false);
            setShowApprovalPart(false);
            setDisableButton(false);
        },
        [],
    );

    const completeTask = useCallback(
        async ({approvedMsg, rejectedMsg}) => {
            try {
                setDisableButton(true);
                setStep("");
                let params = {
                    datetime: moment().toISOString(),
                    task,
                    selectedDrivers,
                    rejectedDrivers,
                    approvedMessage: approvedMsg,
                    rejectedMessage: rejectedMsg,
                    stationId: getMainStationId(),
                    userId: getUserId(),
                    driverId: getDriverId(),
                }
                await engine().post('/api/complete_task', params);
                setTaskCompleted(true);
                setDisabled(true);
                await removeMessage();
            } catch (e) {
                await showErrorToast(e, 'Error in Task Open Shift completion.');
            }
        },
        [removeMessage, rejectedDrivers, selectedDrivers, task],
    );

    const submitMessage = useCallback(
        async (message: string) => {
            let msg = `${managerFriendlyName} has denied your open shift request ${shiftInformation}.`;
            if (step === "SEND") {
                setApprovedMessage(prevState => prevState + "\n" + message);
                setShowApprovalPart(false);
                setPlaceholder("Sorry, this shift has been assigned, please look for another open shift.");
                setStep("FINISH_TASK");
                setRejectedMessage(msg);
                setShowRejectionPart(true);
                setSubmitButtonText("Finish Task");
                return;
            }
            if (step === "FINISH_TASK") {
                setRejectedMessage(msg + '\n' + message);
                setShowFinalPart(true);
                setShowRejectionPart(false);
                setDisabled(true);
                setTaskLoading(true);
                setPlaceholder("");
                setDisabled(true);
                setSubmitButtonText("Send");
                await completeTask(
                    {
                        approvedMsg: approvedMessage,
                        rejectedMsg: msg + '\n' + message,
                    });
            }
            if (step === " FINISH_TASK_ALL_PASS") {
                setApprovedMessage(prevState => prevState + "\n" + message);
                setShowFinalPart(true);
                setShowRejectionPart(false);
                setDisabled(true);
                setTaskLoading(true);
                setPlaceholder("");
                setDisabled(true);
                setSubmitButtonText("Send");
                await completeTask(
                    {
                        approvedMsg: approvedMessage + '\n' + message,
                        rejectedMsg: msg,
                    });
            }
        },
        [approvedMessage, completeTask, managerFriendlyName, shiftInformation, step],
    );

    const showFinishTaskMessage = useCallback(
        () => {
            return (selectedDrivers.length === 1 && rejectedDrivers.length === 0) || (selectedDrivers.length === drivers.length);
        },
        [drivers.length, rejectedDrivers.length, selectedDrivers.length],
    );


    return (
        <>
            <ScrollContainer mode={'bottom'}>
                <BotTempMessage message={systemMsg}/>
                {count > 1 && <BotTempMessage message={{
                    ...systemMsg,
                    text: `There are ${count} available Open Shifts.`
                }} showHeader={false}/>}
                {count > 1 && drivers.length > 1 && showDriversToSelect &&
                <Container className={"btn"}>
                    <MultipleOpenShiftDriver drivers={drivers}
                                             count={count}
                                             approveMultipleDriver={approveMultipleDriver}
                    />
                    <Button size="small"
                            onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</Button>
                </Container>}
                {count > 1 && drivers.length === 1 && showDriversToSelect &&
                <Container className={"btn"}>
                    {drivers.map(item => <OpenShiftDriverButton driver={item} key={item.id}
                                                                selectDriver={selectOneDriver}/>)}
                    <Button size="small"
                            onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</Button>
                </Container>}
                {count === 1 && showDriversToSelect &&
                <Container className={"btn"}>
                    {drivers.map(item => <OpenShiftDriverButton driver={item} key={item.id}
                                                                selectDriver={selectOneDriver}/>)}
                    <Button size="small"
                            onClick={rejectDriver}>{drivers.length === 1 ? "Reject" : "Reject All"}</Button>
                </Container>}
                <MessagesToSent systemMsg={systemMsg} message={approvedMessage}
                                drivers={selectedDrivers} scenario={"APPROVAL"}/>
                {showApprovalPart && selectedDrivers.length > 0 && approvedMessage &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: showFinishTaskMessage() ? "Please type an additional message to the approved driver or press Finish Task." : "Please type an additional message to the approved driver(s) or press Send.",
                        }
                    }/>
                </>
                }
                {showRejectionPart && rejectedDrivers.length > 0 &&
                <>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `The rejected driver(s) will receive the following messages:`,
                        }
                    }/>
                    <MessagesToSent systemMsg={systemMsg} message={rejectedMessage}
                                    drivers={rejectedDrivers} scenario={"REJECTION"}/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `Please type an additional message to send to the rejected driver(s) or press Finish Task.`,
                        }
                    }/>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                </>
                }
                {showFinalPart &&
                <>
                    <MessagesToSent systemMsg={systemMsg} message={rejectedMessage}
                                    drivers={rejectedDrivers} scenario={"REJECTION"}/>
                </>
                }
                {taskLoading && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Please wait, we are completing the task.",
                }}/>}
                {taskCompleted && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Task Completed!",
                }}/>}
            </ScrollContainer>
            <InputForBot submitMessage={submitMessage} disabled={disabled} placeholder={placeholder}
                         submitButtonText={submitButtonText} disableSendButton={disableButton}/>
        </>
    );
}