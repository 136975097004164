import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    CheckBox,
    Dropdown,
    Icon,
    Input,
    Spacer,
    SubTitle,
    TagInput,
    Text,
    Theme,
    ThirdTitle,
    Title
} from "@dspworkplace/ui";
import ScorecardContext, {ScorecardProvider} from "../context";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import {ButtonIcon, Divider, Label} from "../../../components/UI";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../components/Table";
import {dialogPromise} from "../../../components/Dialog";
import Modal from "../../../components/Modal";
import DatePicker from "../../../components/Calendar/picker";
import LineChart from "../../../components/Charts/LineChart";
import useScorecardApi from "../../../api/scorecard";
import Skeleton from "../../../components/Skeleton";
import Empty from "../../../components/Empty";
import {TEAMS_COLUMNS} from "../schema";
import {showToast, engine, showErrorToast} from "../../../Utilities";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import {confirm} from "../../../components/Confirm"
import {useForm} from "react-hook-form";
import {CompetitionApp} from "./Coaching/Competition";
import {ReportTeamsApp} from "./Coaching/Report";
import {TabList} from "../../../components/Tab";

export default function (props)
{
    return (
        <ScorecardProvider>
            <TabContent {...props} />
        </ScorecardProvider>
    )
};

const TabContent = (props) => {
    const tab = useMemo(() => {
        return props.location.pathname.split('/').length === 4
            ? (props.location.pathname.split('/')).pop()
            : 'manage';
    }, [props.location.pathname]);


    return (
        <>
            <Title>
                Teams
            </Title>
            <Spacer top={3}/>
            <Text>Create teams by grouping your drivers to add some fun and a little bit of competition.</Text>
            <Spacer top={6}/>

            <TabList
                defaultTab={tab}
                tabs={{
                    'manage': {
                        title: 'Manage',
                        content: <ScorecardTeams {...props}/>
                    },
                    'competition': {
                        title:'Competitions',
                        content: <CompetitionApp />
                    },
                    'report': {
                        title: 'Report',
                        content:<ReportTeamsApp />
                    }
                }}
                onChange={newTab => {
                    if (props.location.pathname.match(tab))
                        props.history.replace(props.location.pathname.replace(tab, newTab))
                    else
                        props.history.replace(props.location.pathname + `/${newTab}`)
                }}
            />

        </>)
}

const SaveTeamDialog = ({
    team,
    success,
    cancel,
}) => {
    const [{entities, loading}] = useContext(ScorecardContext);
    const {loadEntitiesByType, saveTeam} = useScorecardApi(ScorecardContext);
    const [members, setMembers] = useState(team?.members ?? []);

    const {register, getValues, errors, handleSubmit} = useForm({
        defaultValues: team
    });

    const drivers = entities?.INDIVIDUAL ? Object.values(entities?.INDIVIDUAL ?? {})
        .reduce((all, next) => {
            if (next.employeeStatus != -2) {
                all[next.id] = next
            }
            return all;
        }, {}) : undefined;

    useEffect(() => {
        if (!drivers)
            loadEntitiesByType('INDIVIDUAL');
    }, [drivers]);

    const onSubmit = data => {
        if(typeof data.leaders !== "object"){
            data.leaders = [data.leaders];
        }

        saveTeam({
            ...team,
            ...data,
            members: members.map(m => m.id)
        }).then(async () => {
            await engine().post('/api/bot/channel/group/member/update', team).catch(async e => {
                await showErrorToast(e, 'Update Team Channel Member', 'Update Team Channel Member failed.');
            });
            success();
        });
    }

    const addMember = member => {
        let copy = [...members];
        copy.push(member)
        setMembers(copy);
    }

    const removeMember = member => {
        let copy = [...members];
        copy.splice(copy.findIndex(m => m.id === member.id), 1);
        setMembers(copy);
    }

    const values = getValues();

    return (
        <Modal
            visible={true}
            setVisible={() => cancel()}
            closeOnOverlay={false}
            title={team?.id ? 'Edit Team' : 'Add Team'}
            width={'340px'}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                {(loading?.savingTeam || loading?.entities) ? (
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                ) : null}

                <Input
                    ref={register({
                        required: true,
                    })}
                    name={'name'}
                    label={'Name'}
                    Required={true}
                    valid={values.name}
                    error={errors.name && 'Invalid'}
                />
                <Spacer bottom={3}/>

                <Label>Members</Label>
                <Table width={'300px'}>
                    <TableHeader
                        compact={true}
                        headers={[
                            {label: 'Name', width: '200px'},
                            {label: 'Leaders', width: 'auto'},
                        ]}
                    />
                    {!members.length ? (
                        <TableRow>
                            <TableData compact={true} width={'100%'} style={{justifyContent: 'center'}}>
                                <Empty/>
                            </TableData>
                        </TableRow>
                    ) : members.map((m) =>
                        ( drivers && drivers.hasOwnProperty(m.id) && <TableRow key={m.id}>
                            <TableData compact={true} width={'200px'}>
                               {drivers && drivers.hasOwnProperty(m.id) && (drivers?.[m.id].user.friendlyName)} {drivers && drivers.hasOwnProperty(m.id) && (drivers?.[m.id] && drivers?.[m.id].employeeStatus === "-2" && ` (Terminated)`)}
                            </TableData>
                            <TableData compact={true} width={'auto'}>
                                <CheckBox
                                    ref={register}
                                    name={'leaders'}
                                    options={[
                                        {value: m.id, label: ''}
                                    ]}
                                />
                            </TableData>
                            <TableData compact={true} width={'auto'} style={{marginLeft: 'auto'}}>
                                <ButtonIcon
                                    icon={Icon.Times}
                                    color={Theme.colors.error.text}
                                    onClick={() => removeMember(m)}
                                />
                            </TableData>
                        </TableRow>)
                    )}
                    <TableFooter compact={true}>
                        <TagInput
                            options={Object.values(drivers || {})
                                .filter(d => !entities?.TEAM?.reduce((all, next) => {
                                    all.push(next.members?.map(m => m.id))
                                    return all;
                                }, members.map(m => m.id)).flat().includes(d.id)).map(d => ({
                                    name: d.user.friendlyName,
                                    value: d.id,
                                    entity: d,
                                }))}
                            placeholder="Add Driver"
                            label="Add Driver"
                            size={'small'}
                            singleOption={true}
                            onChange={([driver]) => addMember(driver.entity)}
                        />
                    </TableFooter>

                    <Spacer top={5} style={{textAlign: 'right'}}>
                        <Button
                            type={'primary'}
                            size={'small'}
                        >
                            Save
                        </Button>
                    </Spacer>
                </Table>
            </form>
        </Modal>
    )
}

const EditTeamButton = ({team}) => {
    const [visible, setVisible] = useState(false);
    const {loadEntitiesByType} = useScorecardApi(ScorecardContext);

    const success = () => {
        setVisible(false);
        loadEntitiesByType('TEAM');
        showToast({
            type: 'success',
            title: 'Team Saved',
            timeout: 5000,
        })
    };

    const cancel = () => {
        setVisible(false);
    }

    return <>
        <ButtonIcon
            title={'Edit team'}
            icon={Icon.Edit}
            onClick={() => setVisible(true)}
        />

        {visible && (
            <SaveTeamDialog
                team={team}
                success={success}
                cancel={cancel}
            />
        )}
    </>
}

const ArchiveTeamButton = ({team}) => {
    const {saveTeam, loadEntitiesByType} = useScorecardApi(ScorecardContext);

    const handleClick = async () => {
        if (!await confirm({
            icon: true,
            text: 'Are you sure you want to archive this team?'
        }))
            return;

        team.members = team.members.map((m)=> m.id)

        saveTeam({
            ...team,
            isArchive: true,
        }).then(() => {
            loadEntitiesByType('TEAM');
            showToast({
                type: 'success',
                title: `${team.name} team was archived`,
                timeout: 5000,
            })
        })
    }

    return <>
        <ButtonIcon
            title={'Archive team'}
            icon={Icon.Times}
            onClick={handleClick}
            color={Theme.colors.info.border}
        />
    </>
}

const AddTeamButton = () => {
    const [visible, setVisible] = useState(false);
    const {loadEntitiesByType} = useScorecardApi(ScorecardContext);

    const success = () => {
        setVisible(false);
        loadEntitiesByType('TEAM');
        showToast({
            type: 'success',
            title: 'Team Saved',
            timeout: 5000,
        })
    };

    const cancel = () => {
        setVisible(false);
    }

    return <>
        <Button
            onClick={() => setVisible(true)}
            size={'small'}
            type={'primary'}
        >Add Team</Button>

        {visible && (
            <SaveTeamDialog
                success={success}
                cancel={cancel}
            />
        )}
    </>
}

const ScorecardTeams = () => {
    const [{loading, ...state}] = useContext(ScorecardContext);
    const {loadEntitiesByType} = useScorecardApi(ScorecardContext);
    const [selectedStation] = useSelectedStation();

    useEffect(() => {
        loadEntitiesByType('TEAM');
    }, [selectedStation])

    const showPerformanceDialog = t =>
        dialogPromise((success, cancel) =>
            <TeamPerformanceDialog
                team={t}
                close={cancel}
            />
        )

    const teams = state?.entities?.TEAM;

    return <Spacer top={4} bottom={8}>
        <Spacer top={5} bottom={5}>
            <StationSelector/>
        </Spacer>

        <Table>
            <TableHeader
                sortedBy={[{field: 'progress', direction: 0}]}
                headers={Object.values(TEAMS_COLUMNS)}
            />

            {loading?.entities && !teams ? (
                <TableRow>
                    <TableData width={TEAMS_COLUMNS.name.width}>
                        <Skeleton height={20}/>
                    </TableData>
                    <TableData width={TEAMS_COLUMNS.members.width}>
                        <Skeleton height={20}/>
                    </TableData>
                    {/*<TableData width={TEAMS_COLUMNS.progress.width}>*/}
                    {/*    <Skeleton height={20}/>*/}
                    {/*</TableData>*/}
                </TableRow>
            ) : teams?.length === 0 ? (
                <TableRow style={{justifyContent: 'center'}}>
                    <Empty/>
                </TableRow>
            ) : teams?.map(team =>
                <TableRow key={team.id}>
                    <TableData width={TEAMS_COLUMNS.name.width}>
                        {team.name}
                    </TableData>
                    <TableData width={TEAMS_COLUMNS.members.width}>
                        {team.membersNames.join(', ')}
                    </TableData>
                    {/*<TableData width={TEAMS_COLUMNS.progress.width}>*/}
                        {/*<ProgressBar*/}
                        {/*    progress={t.progress}*/}
                        {/*    description={t.description}*/}
                        {/*    progressBg={*/}
                        {/*        t.progress > 90*/}
                        {/*            ? Theme.colors.success.bg*/}
                        {/*            : t.progress < 50*/}
                        {/*                ? Theme.colors.error.bg*/}
                        {/*                : null*/}
                        {/*    }*/}
                        {/*    progressBorder={*/}
                        {/*        t.progress > 90*/}
                        {/*            ? Theme.colors.success.border*/}
                        {/*            : t.progress < 50*/}
                        {/*                ? Theme.colors.error.border*/}
                        {/*                : null*/}
                        {/*    }*/}
                        {/*/>*/}
                    {/*</TableData>*/}
                    <TableData width='auto' style={{gap: 8, marginLeft: 'auto'}}>
                        {/*<ButtonIcon title='Reports' icon={Icon.TrendUp} onClick={() => showPerformanceDialog(team)}/>*/}
                        {/*<ButtonIcon title='Goals' icon={Icon.Skill} onClick={() => {}}/>*/}
                        <EditTeamButton
                            team={team}
                        />
                        <ArchiveTeamButton
                            team={team}
                        />
                    </TableData>
                </TableRow>
            )}

            <TableFooter sticky>
                <ThirdTitle>
                    {teams?.length} teams
                </ThirdTitle>
                <AddTeamButton/>
            </TableFooter>
        </Table>
    </Spacer>
}

function TeamPerformanceDialog ({
    team = {},
    close = () => {}
}) {
    const [compare, setCompare] = useState();

    return (
        <Modal
            visible={true}
            setVisible={close}

            title={`${team.nickname}'s performance`}
            drawer='right'

            width={compare ? '800px' : undefined}
        >
            <DatePicker
                name='date'
                label='Date Range'
                range={{
                    interval: 'day'
                }}
            />
            <Spacer inline right={5}/>
            <Dropdown
                label='Compare with'
                options={[
                    {name: 'None', value: 0},
                    {name: 'Beta Team', value: 1},
                    {name: 'Gama Team', value: 2},
                ]}
                onChange={v => setCompare(v.value > 0)}
                size='small'
            />

            <Divider top={5} bottom={5}/>

            <Spacer bottom={10} style={{
                display: 'grid',
                gap: 40,
                gridTemplateColumns: compare ? 'calc(50% - 20px) calc(50% - 20px)' : '1fr'
            }}>
                <div>
                    {compare ? <Spacer bottom={5}><SubTitle>{team.nickname}</SubTitle></Spacer> : null}
                    <LineChart
                        title='FICO Score'
                        data={[
                            {
                                x: '10/01',
                                y: 800
                            },
                            {
                                x: '10/02',
                                y: 820
                            },
                            {
                                x: '10/03',
                                y: 825
                            },
                        ]}
                        max={850}
                    />

                    <Spacer bottom={5}/>

                    <LineChart
                        title='Green Zone'
                        data={[
                            {
                                x: '10/01',
                                y: 88
                            },
                            {
                                x: '10/02',
                                y: 82
                            },
                            {
                                x: '10/03',
                                y: 84
                            },
                        ]}
                        max={100}
                    />
                </div>

                {compare ? (
                    <div>
                        <Spacer bottom={5}><SubTitle>Omega Team</SubTitle></Spacer>
                        <LineChart
                            title='FICO Score'
                            data={[
                                {
                                    x: '10/01',
                                    y: 788
                                },
                                {
                                    x: '10/02',
                                    y: 812
                                },
                                {
                                    x: '10/03',
                                    y: 839
                                },
                            ]}
                            max={850}
                        />

                        <Spacer bottom={5}/>

                        <LineChart
                            title='Green Zone'
                            data={[
                                {
                                    x: '10/01',
                                    y: 75
                                },
                                {
                                    x: '10/02',
                                    y: 78
                                },
                                {
                                    x: '10/03',
                                    y: 84
                                },
                            ]}
                            max={100}
                        />
                    </div>
                ) : null}
            </Spacer>

            <Spacer all={5} style={{
                border: '1px dashed',
                borderColor: Theme.colors.neutrals.silver,
                borderRadius: Theme.defaultRadius,
                textAlign: 'center',
            }}>
                <Button size="small">Add Metric</Button>
            </Spacer>
        </Modal>
    )
}