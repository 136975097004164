import React, { useRef, useState } from "react";
import { Icon, Theme } from "@dspworkplace/ui";
import { engine } from "../../../Utilities";
import { dialog } from "../../../components/Dialog";
import { alert } from "../../../components/Alert";

const useGhostShiftApi = () => {
    const canceler = useRef(() => {});

    // Function to show an alert dialog
    const showAlert = async (text, btnText = "Cancel") => {
        await alert({
            text,
            btnText,
        });
    };

    // Function to show a confirmation dialog
    const showConfirmationDialog = async (dialogMsg) => {
        return await dialog({
            icon: <Icon.Warning color={Theme.colors.warnings.text} />,
            text: dialogMsg,
            buttons: [
                {
                    text: "Cancel",
                    callback: async () => false,
                },
                {
                    type: "primary",
                    text: "Confirm",
                },
            ],
        });
    };

    const handleGhostShift = async ({ stationId, driverId, date, actionName = "", options = [] }) => {
        
        let driverIds = Array.isArray(driverId) ? driverId : [driverId];
        const response = await engine().post("/api/lazy/manage/data", {
            actions: {
                response: {
                    Driver: {
                        custom: {
                            functionName: "checkDriverRoute",
                            get: "result",
                            excludes: [
                                "stations",
                                "employmentStatus",
                                "user",
                                "assignedManagers",
                                "payRate",
                                "company",
                                "schedules",
                                "cortexLogs",
                            ],
                            criteria: { stationId, checkIncident: true, driverId: driverIds, date },
                        },
                    },
                },
            },
        });

        const { availableDriverIds, isTodayPast, success } = response.data.data.result;
        if (actionName == "SCHEDULER_ADD_ROUTE_WITH_DATE" || actionName == "SCHEDULER_ADD_ROUTE") {
            let dialogMsg = "";
            if (availableDriverIds.length > 0 && isTodayPast) {
                dialogMsg = `Driver ${availableDriverIds[0].name} already has a scheduled shift in ${availableDriverIds[0].code}. You can't move a shift that already happened.`;
                await alert({
                    text: dialogMsg,
                    btnText: "Cancel",
                });
                return false;
            }

            if (success && availableDriverIds.length > 0) {
                let publishDriverId = [];
                let availableDriverId = availableDriverIds.map((driver) => driver.driverId);
                let filterAvailableDriverIds = driverIds
                    .filter((item) => !availableDriverId.includes(Number(item)))
                    .map(Number);

                let messageString = "";
                let messageStringTemp = "";
                let isPublish = false;
                let isTemp = false;
                let dialogBoxStatus = false;
                let publishDrivers = [];

                const generateMessageTable = (drivers, title) => {
                    return `
                        <table style="width: 100%; border-collapse: collapse; margin-top: 13px;">
                            <tbody>
                                <tr><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Driver's name</th><th style="border: 1px solid rgb(230, 216, 216); padding: 5px;">Station</th></tr>
                                ${drivers
                                    .map(
                                        (driver) => `
                                    <tr>
                                        <td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">${driver.name}</td>
                                        <td style="border: 1px solid rgb(230, 216, 216); padding: 5px;">${driver.code}</td>
                                    </tr>
                                `
                                    )
                                    .join("")}
                            </tbody>
                        </table>`;
                };

                if (availableDriverIds.length === 1) {
                    const driver = availableDriverIds[0];
                    if (driver.type === "temp") {
                        dialogMsg = `Driver ${driver.name} already has a scheduled shift in ${driver.code}. You can't create the shift, please publish or discard temp changes to other station shifts before proceeding.`;
                        await alert({ text: dialogMsg, btnText: "Cancel" });

                        if (!filterAvailableDriverIds.length) return false;
                        dialogBoxStatus = true;
                        const foundDriver = options
                            .filter((driver) => filterAvailableDriverIds.includes(driver.id))
                            .map((driver) => driver.name)
                            .join(", ");
                        dialogMsg = `Are you sure you want to create a shift for the selected drivers: "${foundDriver}"?`;
                    } else {
                        publishDriverId.push(driver.driverId);
                        dialogMsg = `Driver ${driver.name} already has a scheduled shift in ${driver.code}. \n The original shift will be restored, and the current shift will be deleted.`;
                    }
                } else {
                    publishDrivers = availableDriverIds.filter((driver) => driver.type === "publish");
                    let tempDrivers = availableDriverIds.filter((driver) => driver.type === "temp");

                    if (publishDrivers.length) {
                        isPublish = true;
                        messageString = generateMessageTable(publishDrivers, "Publish Drivers");
                    }
                    if (tempDrivers.length) {
                        isTemp = true;
                        messageStringTemp = generateMessageTable(tempDrivers, "Temp Drivers");
                    }

                    const publishMsg = isPublish
                        ? `Some selected drivers already have scheduled shifts in other stations. Would you like to move these drivers anyway? ${messageString}`
                        : "";
                    const tempMsg = isTemp
                        ? `Some selected drivers already have scheduled shifts in other stations. You can't create the shift, please publish or discard temp changes to other station shifts before proceeding. ${messageStringTemp}`
                        : "";

                    if (isTemp && !isPublish) {
                        await alert({ text: tempMsg, btnText: "Cancel" });
                        if (!filterAvailableDriverIds.length) return false;

                        dialogBoxStatus = true;
                        const foundDriver = options
                            .filter((driver) => filterAvailableDriverIds.includes(driver.id))
                            .map((driver) => driver.name)
                            .join(", ");
                        dialogMsg = `Are you sure you want to create a shift for the selected drivers: "${foundDriver}"?`;
                    } else {
                        dialogMsg = tempMsg + publishMsg;
                    }
                }

                const dialogReturn = await dialog({
                    icon: <Icon.Warning color={Theme.colors.warnings.text} />,
                    text: dialogMsg,
                    modal: {
                        width: "390px",
                    },
                    buttons: [
                        { text: "Cancel", callback: async () => false },
                        { type: "primary", text: "Confirm" },
                    ],
                }).then(async (dialogResponse) => {
                    publishDriverId = publishDriverId.map(Number);
                    if (!dialogResponse) {
                        publishDriverId =
                            publishDriverId.length === 0
                                ? publishDrivers.map((driver) => Number(driver.driverId))
                                : publishDriverId;
                        let selectedDriverIds = filterAvailableDriverIds.filter(
                            (item) => !publishDriverId.includes(item)
                        );
                        if (selectedDriverIds.length && !dialogBoxStatus) {
                            const foundDriver = options
                                .filter((driver) => selectedDriverIds.includes(driver.id))
                                .map((driver) => driver.name)
                                .join(", ");
                            return await dialog({
                                text: `Are you sure you want to create a shift for the selected drivers: "${foundDriver}"?`,
                                buttons: [
                                    { text: "Cancel", callback: async () => false },
                                    { type: "primary", text: "Yes" },
                                ],
                            }).then(async (response) => {
                                if (!response) return false;
                                return selectedDriverIds;
                            });
                        }
                        return false;
                    }

                    return dialogBoxStatus
                        ? filterAvailableDriverIds
                        : [...new Set([...publishDriverId, ...filterAvailableDriverIds])];
                });
                return dialogReturn;
            }
            return driverIds;
        } else {
            // Check if driver is already scheduled
            if (availableDriverIds.length > 0) {
                const driverInfo = availableDriverIds[0];

                // If the shift has already happened
                if (isTodayPast) {
                    await showAlert(
                        `Driver ${driverInfo.name} already has a scheduled shift in ${driverInfo.code}. \n You can't move a shift that already happened.`
                    );
                    return false;
                }

                // If the shift creation is successful
                if (success) {
                    let dialogMsg;

                    // Temp driver scenario
                    if (driverInfo.type === "temp") {
                        dialogMsg = `Driver ${driverInfo.name} already has a scheduled shift in ${driverInfo.code}. \n You can't create the shift. Please publish or discard temp changes to other station shifts before proceeding.`;

                        await showAlert(dialogMsg);
                        return false;
                    }

                    // Regular shift scenario
                    dialogMsg = `Driver ${driverInfo.name} already has a scheduled shift in ${driverInfo.code}. \n The original shift will be restored, and the current shift will be deleted.`;

                    const dialogResponse = await showConfirmationDialog(dialogMsg);
                    if (!dialogResponse) return false;

                    return true;
                }
            }
        }

        return true;
    };
    return {
        canceler,
        handleGhostShift,
    };
};

export default useGhostShiftApi;
