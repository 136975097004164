import React from "react";
import {Text, Spacer} from '@dspworkplace/ui';
import FormCombobox, {FormComboboxProps} from "./combobox.hookForm";
import {RecoilValue, useRecoilValueLoadable} from "recoil";
// @ts-ignore
import LoadingAnimation from "../../../assets/dsp-loading.svg";
import DefaultPadding from "./Options/defaultPadding";


export type ComboboxRecoilTypeBase = Omit<FormComboboxProps, "initialValue, options">
export interface ComboboxRecoilType extends ComboboxRecoilTypeBase {
    store: RecoilValue<any>
    textOnNullValue?: string
}

export const LoadingCombobox = () => {
    return (
        <div style={{display: 'flex',alignItems: 'center',padding:4}}>
            <Text>Loading</Text>
            <Spacer left={1}>
                <img src={LoadingAnimation} height={18} alt='loading' />
            </Spacer>
        </div>
    )
}

const ComboboxRecoil = (props: ComboboxRecoilType) => {
    const {OptionRender, placeholder, store } =  props
    const statusLoadable = useRecoilValueLoadable(store)

    let options = statusLoadable.state === 'loading' ? [{label:'loading',value:'loading', disabled: true}] : statusLoadable.contents
    let placeholderText = statusLoadable.state === 'loading' ? 'Loading...' : placeholder
    let optionView = statusLoadable.state === 'loading' ? LoadingCombobox : (OptionRender ? OptionRender : DefaultPadding)

    if(options.length < 1){
        const text = props.textOnNullValue || 'waiting dependencies'
        options = [{label:text, value: 'loading', disabled: true}]
        placeholderText = text
    }

    return <FormCombobox
        options={options}
        {...props}
        OptionRender={optionView}
        placeholder={placeholderText}
        optionStyle={{maxHeight:'218px'}}
    />
}

export default ComboboxRecoil;