import React, { useEffect, useState } from "react";
import { Button, Icon, Spacer, Text, ThirdTitle } from "@dspworkplace/ui";
import StationSelector, { useSelectedStation } from "../../../../components/StationSelector";
import { useForm } from "react-hook-form";
import { fetchAutomatedScorecardCoachingMetricDetails, updateAutomatedScorecardCoachingMetricDetails } from "./util";
import Loading, { LoadingWrapper } from "../../../../components/Loading";
//import Overview from "../MobileScorecard/OverviewTemplate/OverviewTab";
import { CardBox, CardItem } from "../../../Vehicles/VanAutoAssignment";
import WithDragAndDrop, { DragItem } from "../../../../components/dragHandler";
import { Table, TableFooter, TableRow } from "../../../../components/Table";
//import { Link } from "../../../../components/UI";
//import Empty from "../../../../components/Empty";
//import { dialogPromise } from "../../../../components/Dialog";
//import Modal from "../../../../components/Modal";
//import { Employee, fetchActiveEmployees } from "../MobileScorecard/OverviewTemplate/OverviewTemplate";
//import OverviewScreen from "../MobileScorecard/OverviewTemplate/OverviewTab/Scores/OverviewScreen";
import styled from "styled-components";
export interface Item {
    id: number;
    text: string;
    onOff: boolean;
}

const GreenCircle = styled.div`
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    margin-top: 7px;
`;

const RedCircle = styled.div`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-top: 7px;
`;

const Row = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
`;

const CardList = WithDragAndDrop(({ cards, setCards, data, setData }) => {
    const handleOnMove = (dragIndex, hoverIndex) => {
        if(dragIndex >= 0) {
            console.log(dragIndex, hoverIndex);
            setData((d) => {
                if (hoverIndex < 0) {
                    d.numberOfAutomatedScorecardCoachingMetric =
                        dragIndex === 0 ? 1 : dragIndex > d.numberOfScoreCardMainField ? 1 : 0;
                    return d;
                }

                d.numberOfAutomatedScorecardCoachingMetric +=
                    dragIndex >= data.numberOfAutomatedScorecardCoachingMetric &&
                    hoverIndex < data.numberOfAutomatedScorecardCoachingMetric
                        ? 1
                        : dragIndex < data.numberOfAutomatedScorecardCoachingMetric &&
                        hoverIndex >= data.numberOfAutomatedScorecardCoachingMetric
                        ? -1
                        : 0;
                return d;
            });

            setCards((all) => {
                const copyListState = [...all];
                const draggedItem = copyListState[dragIndex];

                copyListState.splice(dragIndex, 1);

                if (hoverIndex < 0) {
                    hoverIndex = dragIndex >= (data.numberOfScoreCardMainField || 0) ? 0 : all.length;
                }

                copyListState.splice(hoverIndex, 0, draggedItem);

                return copyListState;
            });
        }
    };

    // @ts-ignore
    const render = Array.from(cards).map(([i, card]) => (
        <DragItem key={card.id} index={i} id={card.id} type={"LIST"} onMove={handleOnMove}>
            {({ previewRef, dragRef, ui }) => (
                <div ref={previewRef}>
                    <CardItem ref={dragRef} style={{ opacity: ui.isOverCurrent ? 0 : 1 }}>
                        <Row>
                            <div>
                                <Icon.Move size="16px" />
                                <Spacer left={3} inline>
                                    <Text>{card.text}</Text>
                                </Spacer>
                            </div>
                            <Spacer inline>{card.onOff ? <GreenCircle /> : <RedCircle />}</Spacer>
                        </Row>
                    </CardItem>
                </div>
            )}
        </DragItem>
    ));

    return (
        <CardBox>
            <DragItem
                key={"new"}
                index={-1}
                id={"new"}
                type={"LIST"}
                onMove={handleOnMove}
                style={{
                    display: render.length ? "none" : "block",
                }}
            >
                {({ previewRef, dragRef }) => (
                    <div ref={previewRef}>
                        <CardItem ref={dragRef}>Nothing here yet</CardItem>
                    </div>
                )}
            </DragItem>
            {render}
        </CardBox>
    );
});

const ContainerAutomatedScorecardCoachingMetric = () => {
    const [station] = useSelectedStation();
    const [cards, setCards] = useState<Item[]>([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        numberOfAutomatedScorecardCoachingMetric: 0,
        automatedScorecardCoachingMetric: [],
    });
    const { handleSubmit, setValue } = useForm();

    const onSubmit = async () => {
        await updateAutomatedScorecardCoachingMetricDetails(station, setLoading, cards, data, setValue);
    };

    useEffect(() => {
        const abortController = new AbortController();
        fetchAutomatedScorecardCoachingMetricDetails(station, setLoading, setCards, setValue).then((data) =>
            setData(data)
        );
        return () => {
            abortController.abort();
        };
    }, [station]);

    //?
    let available = new Map();
    let inUse = new Map();
    for (const [index, element] of cards.entries())
        if (index < data.numberOfAutomatedScorecardCoachingMetric) inUse.set(index, element);
        else available.set(index, element);


    if (loading)
        return (
            <LoadingWrapper>
                <Loading />
            </LoadingWrapper>
        );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Spacer bottom={5}>
                <StationSelector />
                <Spacer left={5} inline />
            </Spacer>
            <Table>
                <TableRow
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20, position: "relative" }}
                >
                    {loading ? (
                        <LoadingWrapper>
                            <Loading />
                        </LoadingWrapper>
                    ) : null}
                    <div>
                        <ThirdTitle>Available Metrics</ThirdTitle>
                        <Spacer bottom={3} />
                        <CardList cards={available} setCards={setCards} data={data} setData={setData} />
                    </div>

                    <div>
                        <ThirdTitle>Metrics in Use</ThirdTitle>
                        <Spacer bottom={3} />
                        <CardList cards={inUse} setCards={setCards} data={data} setData={setData} />
                    </div>
                </TableRow>
                <TableFooter sticky>
                    <div></div>
                    <Button
                        type={"primary"}
                        Type={"submit"}
                        disabled={loading}
                        style={{
                            verticalAlign: "bottom",
                        }}
                        size="small"
                    >
                        Save
                    </Button>
                </TableFooter>
            </Table>
        </form>
    );
};

export default ContainerAutomatedScorecardCoachingMetric;
