import React, {useEffect} from "react";
import {Button, Input, Spacer, Toggle, Icon, Text} from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import {useFieldArray, useForm} from "react-hook-form";
import {TableData, Table, TableRow} from "../../../../../components/Table";
import ReactDOM from "react-dom";
import VehicleTaskPriorityCombobox
    from "../../../../../domain/vehicle_maintenance_task/components/TaskPriorityCombobox";
import {RecoilRoot} from "recoil";
import styled from "styled-components";
import {CustomErrorMessage} from "./CreateTemplateForm";

const modalRoot = document.getElementById('modal-root');
const portal = async render => {
    const el = document.createElement('div');
    el.setAttribute('id',Date.now());
    modalRoot.appendChild(el);

    const hideDialog = ()=> setTimeout( ()=> {
        ReactDOM.unmountComponentAtNode( el );
        modalRoot.removeChild(el);
    }, 1 );

    return new Promise( ( resolve, reject ) => {
        const success = data => hideDialog() && resolve( data );
        const cancel = data => hideDialog() &&  resolve( false );

        setTimeout(()=>
                ReactDOM.render( (
                    render(success, cancel)
                ), el )
            , 10);
    });
}


const ComboboxData = styled(TableData)`
    label {
        margin-bottom:10px;
    }
`
const TemplateNotificationModal = ({ success = () => {}, cancel = () => {}, notifications = [], planItem= {}}) => {
    const defaultValues = notifications.map(notification => ({
        days_prior: notification.days_prior,
        ground_van: notification.ground_van,
        id: notification.id,
        miles_prior: notification.miles_prior,
        //priority: notification.modify_task_priority_to,
        modify_task_priority_to: notification.modify_task_priority_to,
        planItem: notification.planItem
    }))   
    const {control,register,handleSubmit,errors, getValues} = useForm({defaultValues:{notification:defaultValues}, mode: 'onBlur'})
    const {fields, append, remove} = useFieldArray({control,name:'notification'})

    const {notification:error} = errors;

    useEffect(()=>{
        if(fields.length < 1){
            append({});
        }
    },[append, fields])

    const onSubmit = (data) => {
        success(data.notification);
    }

    return (
        <Modal
            width={'740px'}
            visible={true}
            title={'Vehicle Maintenance Notification'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text>A rule must have one or more notifications.</Text>
                <Table>
                    {fields.map((field, index) => {
                        return (
                            <TableRow key={`field-${field.id}`} style={{ alignItems: "flex-start" }}>
                                <input type="hidden" name={`notification[${index}].id`} />
                                {planItem?.category?.isLastMileServiceRequired === true &&
                                    <TableData width="160px" style={{ position: "relative" }}>
                                        <Input
                                            ref={register({
                                                validate: (value) => {
                                                    if (value) {
                                                        if (value && parseInt(value) < 0) {
                                                            return "Invalid Value";
                                                        }
                                                        if (
                                                            parseInt(value) > parseInt(planItem.service_interval_miles)
                                                        ) {
                                                            return `Notification trigger cannot be larger than the maintenance interval.`;
                                                        }
                                                        if (
                                                            parseInt(value) < 1 &&
                                                            getValues(`notification[${index}].days_prior`) &&
                                                            getValues(`notification[${index}].days_prior`) < 1
                                                        ) {
                                                            return "Please fill Miles Prior or Days Prior";
                                                        }
                                                    } else {
                                                        return "Please fill Miles Prior";
                                                    }
                                                },
                                            })}
                                            type="number"
                                            name={`notification[${index}].miles_prior`}
                                            invalid={error?.[index]?.miles_prior}
                                            label={"Miles Prior"}
                                            size={"small"}
                                            error={error?.[index]?.miles_prior && error[index].miles_prior.message}
                                            required
                                        />
                                    </TableData>
                                }
                                {planItem?.category?.isLastServiceDateRequired === true &&
                                    <TableData width="160px">
                                        <Input
                                            ref={register({
                                                validate: (value) => {
                                                    if (value) {
                                                        if (parseInt(value) < 0) {
                                                            return "Invalid Value";
                                                        }
                                                        if (
                                                            parseInt(value) > parseInt(planItem.service_interval_days)
                                                        ) {
                                                            return `Days prior must be less or equal the rule interval day.`;
                                                        }
                                                    } else {
                                                        return "Please fill Days Prior";
                                                    }
                                                },
                                            })}
                                            type="number"
                                            name={`notification[${index}].days_prior`}
                                            label={"Days Prior"}
                                            size={"small"}
                                            invalid={error?.[index]?.days_prior?.message}
                                            error={error?.[index]?.days_prior?.message}
                                        />
                                    </TableData>
                                }
                                <ComboboxData width="160px">
                                    <VehicleTaskPriorityCombobox
                                        // ref={
                                        //     register()
                                        // }
                                        name={`notification[${index}].modify_task_priority_to`}
                                        label={"Change priority"}
                                        error={
                                            error?.[index]?.modify_task_priority_to &&
                                            error[index].modify_task_priority_to.message
                                        }
                                        size="small"
                                        Required
                                        control={control}
                                    />
                                </ComboboxData>
                                <TableData width="160px" style={{ paddingTop: 30 }}>
                                    <Toggle
                                        ref={register}
                                        name={`notification[${index}].ground_van`}
                                        singleText="Ground Van?"
                                        on={field.ground_van}
                                    />
                                </TableData>
                                <TableData width="42px" style={{ paddingTop: 30 }}>
                                    <Icon.Times
                                        size="18px"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => remove(index)}
                                    />
                                </TableData>
                            </TableRow>
                        );
                    })}
                </Table>
            </form>

            <Spacer top={4} style={{display: 'flex',justifyContent: 'end'}}>
                <Button type='secondary' onClick={()=>append({})}>Add Notification</Button>
                <Spacer left={4}>
                    <Button type='primary' onClick={handleSubmit(onSubmit)}>Save</Button>
                </Spacer>
            </Spacer>
        </Modal>
    );
};

export const openTemplateNotificationForm = ({notifications,rule}) => {
    return portal((success, cancel)=>{
        return (
            <RecoilRoot>
                <TemplateNotificationModal
                    success={success}
                    cancel={cancel}
                    notifications={notifications}
                    planItem={rule}
                />
            </RecoilRoot>
        )
    })
}

