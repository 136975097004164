import React from 'react';
import styled from "styled-components";
import {ColorOptions} from "../../../shared";
import {dspIcon} from "../../../../../../../Utilities";
import {Theme} from '@dspworkplace/ui';

const width = 428;
const cardWidth = width - 16 - 16;
const cardWidthInside = cardWidth - 2 - 2;

const CardContainer = styled.div`
    display: flex;
    // width: ${cardWidth}px;
    height: ${80 + 16}px;
    margin: 4px 16px;
    border-radius: 4px;
    flex-direction: row;
    border-color: #EAF0F6;
    border-width: 2px;
    border-style: solid;
    align-items: center;

    .leftView {
        display: flex;
        // width: ${cardWidthInside}px;
        flex-direction: column;
        // backgroundColor: 'brown',
    }

    .rightOneView {
        display: flex;
        // width: ${cardWidthInside * 0.2}px;
        // backgroundColor: 'yellow',
    }

    .rightTwoView {
        display: flex;
        // width: ${cardWidthInside * 0.2}px;
        // backgroundColor: 'blue',
    }

    .rightThreeView {
        display: flex;
        // width: ${cardWidthInside * 0.1}px;
        // backgroundColor: 'green',
    }

    .viewMetricNameContainer {
        display: flex;
        margin-left: 12px;
        // marginTop: 12,
    }

    .metricNameText {
        display: flex;
        color: #516F90;
        font-size: 22px;
    }

    .viewMetricValue {
        display: flex;
        flex-direction: row;
        margin-left: 12px;
        align-items: center;
    }

    .viewMetricValueText {
        display: flex;
        margin-right: 16px;
    }

    .viewError {
        display: flex;
        background-color: #FDEDEE;
        border-color: ${Theme.colors.error.shadow};
    }

    .viewZero {
        display: flex;
        background-color: #E5F8F6;
        border-color: ${Theme.colors.success.shadow};
    }

    .viewSuccess {
        display: flex;
        // backgroundColor: '#E5F8F6',
        border-color: ${Theme.colors.success.shadow};
    }

    .viewWarning {
        display: flex;
        background-color: #FEF8F0;
        border-color: #DBAE60;
    }

    .metricValueText {
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
    }

    .warningText {
        display: flex;
        color: #DBAE60;
        margin-left: 4px;
        font-size: 16px;
    }

    .errorText {
        display: flex;
        color: ${Theme.colors.error.text};
        margin-left: 4px;
        font-size: 16px;
    }

    .successText {
        display: flex;
        color: ${Theme.colors.success.text};
        margin-left: 4px;
        font-size: 16px;
    }

    .iconOrInfoTextSuccess {
        display: flex;
        color: #00A38D;
    }

    .viewIconAndMessage {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const IconAndMessage: React.FC<{
    change: number;
    icon: string;
    textColor: string;
    message: string;
}> = React.memo(({icon, textColor, message}) => {
    return (
        <div className={'viewIconAndMessage'}>
            {dspIcon({type: icon, color: ColorOptions[textColor], size: '24px'})}
            {message && (
                <div
                    className={textColor + 'Text'}
                >
                    {message}
                </div>
            )}
        </div>
    );
});

type Props = {
    metricIdentifier: string;
    metricName: string;
    metricType: string;
    metricValue: number | string;
    change: number;
    trend: string;
    icon: string;
    textColor: string;
    cardStyle: string;
    message: string;
};

const Card: React.FC<Props> = ({
                                   metricName,
                                   change,
                                   metricValue,
                                   textColor,
                                   icon,
                                   cardStyle,
                                   message,
                               }) => {
    return (
        <CardContainer className={'view' + cardStyle}>
            <div className={'leftView'}>
                <div className={'viewMetricNameContainer'}>
                    <div className={'metricNameText'}>
                        {metricName}
                    </div>
                </div>
                <div className={'viewMetricValue'}>
                    <div className={'viewMetricValueText'}>
                        <div className={`metricValueText ${textColor}`}>
                            {typeof metricValue === 'number' ? metricValue : 'N/A'}
                        </div>
                    </div>
                    <IconAndMessage
                        change={change}
                        icon={icon}
                        textColor={textColor}
                        message={message}
                    />
                </div>
            </div>
        </CardContainer>
    );
};

export default React.memo(Card);