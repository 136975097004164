import React, {createContext, useReducer} from "react";
import LoadOutReducer from "./reducer";
import {useAuth} from "../../Auth";
import {useSelectedStation} from "../../components/StationSelector";
import {FilterWeekDate} from "../../hooks/useGlobalData";
import moment from "moment";

const LoadOutStore = createContext({});

const LoadOutProvider = ({ children }) => {
    const [selectedStation] = useSelectedStation();
    let [newDate, setNewDate] = FilterWeekDate();
    const useLoadOutState = useReducer(LoadOutReducer, {
        appliedFilters: 0,
        sortBy: [
            {
                field: "routes",
                direction: 0,
            },
            // {
            //     field: "name",
            //     direction: 0,
            // },
        ],
        selectedDate: moment(newDate).format('YYYY-MM-DD'),
        quickShiftType: null,
        isBalanceGroup: null,
        filterNotPunchIn: true,
        filterNotDeparted: false,
        waveData: {
            station: Array.isArray(selectedStation) ? selectedStation[0] : selectedStation,
            startTime: null,
            balanceGroups: [],
            openRoutes: [],
            notPunchedIn: 0,
            notDeparted: 0,
            drivers: [],
        },
        filterData: {
            stations: [],
            startTimes: [],
            schedules: [],
            shiftTypes: [],
            skills: [],
            clockedOut: false,
            ncns: false,
        },
        changes: {},
        incidentTypes: [],
        drivers: [],
        routeCommitments:[],
        sendedHome: [],
        stationMap: [],
        pageLoader: false,
    });

    return (
        <LoadOutStore.Provider value={useLoadOutState}>
            {children}
        </LoadOutStore.Provider>
    );
};

export { LoadOutProvider };
export default LoadOutStore;
