import React from 'react';
import styled from "styled-components";

const FlexScroll = styled.div`
  box-sizing: border-box;
  font-family: circe-rounded, serif;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: ${props => (props.height ? props.height : "auto")};
  width: ${props => (props.width ? props.width : "auto")};
  flex-direction: column;
    
  &::-webkit-scrollbar {
        ///* width: ${props => (props.scrollbarWidth ? props.scrollbarWidth : "0px")}; */
  }
    
  &::-webkit-scrollbar-track-piece {
        background: ${props => (props.scrollbarTrackPiece ? props.scrollbarTrackPiece : "transparent")};
  }
    
  &::-webkit-scrollbar-thumb {
        background: ${props => (props.scrollbarThumb ? props.scrollbarThumb : "transparent")};
  }
  
  .bg-white{
    background-color: #ffffff;
  }
 
    
`;

function Index(props) {
    return (
        <FlexScroll key={props.key} height={props.height} width={props.width} scrollbarWidth={props.scrollbarWidth}
                    scrollbarTrackPiece={props.scrollbarTrackPiece} scrollbarThumb={props.scrollbarThumb}>
            {props.children}
        </FlexScroll>
    );
}

export default Index;