import React, {createContext, useReducer} from 'react';
import VehicleReduce from './reducer';

const Store = createContext({});

const initialState = {
    sortBy: [],
    vehicles:[],
    filter:[],
    isLoading:true,
    list: [],
    vehiclesOpts:[],
    selectedDate:false
}

const VehicleProvider = ({children}) => {
    const useWeekState = useReducer(VehicleReduce,initialState)
    return (
        <Store.Provider value={useWeekState}>
            {children}
        </Store.Provider>
    )
}

export {VehicleProvider}

export default Store
