import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {WorkerConfig} from "./components/NewVersion";
import ErrorBoundary from './components/Error/ErrorHandler'
import {RecoilRoot} from 'recoil'
import Loading from "./components/Loading";

//all others service works should be inside this component
//generateThemeVariables();

ReactDOM.render(
    <RecoilRoot>
        <ErrorBoundary>
            <Suspense fallback={<Loading />}>
                <App />
            </Suspense>
        </ErrorBoundary>
    </RecoilRoot>,
    document.getElementById('root'));

serviceWorker.register(WorkerConfig);