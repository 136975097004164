import React from "react";
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";
import { Grid, DesignPanelDispatchContext } from "../../index";
import TimeFormat from "../../../../../../../../components/TimeFormat";
import { covertUtcToLocal } from "../../../../../../../../components/TimeFormat";
import moment from 'moment';

const Circle = styled.div`
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    background-color: ${(props) => (props.color ? props.color : "#516F90")};
    border-radius: 50%;
    opacity: 1;
`;

const ShiftTypeBox = ({ name, range, color, row, col, category, start, end, startOnDst, endOnDst, stationTimeZone }) => {
    const { dispatch } = React.useContext(DesignPanelDispatchContext);
    let isDST = moment().isDST();
    return (
        <Grid
            templateColumns="20px 24px auto"
            templateRows="24px 20px"
            height="100%"
            columnGap="8px"
            padding="12px 0 0 12px"
            borderRight="1px dashed #CBD6E2"
            borderBottom="1px dashed #CBD6E2"
        >
            <Grid>
                <Icon.Times
                    size="18px"
                    color="#516F90"
                    onClick={() => {
                        dispatch({ type: "delete", row: row - 1, col: col });
                    }}
                ></Icon.Times>
            </Grid>
            <Grid>
                {category === 1 ? (
                    <Circle color={color}></Circle>
                ) : category === 2 ? (
                    <Circle color={color}>
                        <Icon.Replace size="20px" color="#ffffff"></Icon.Replace>
                    </Circle>
                ) : category === 3 ? (
                    <Circle color={color}>
                        <Icon.GraduationHat size="20px" color="#ffffff"></Icon.GraduationHat>
                    </Circle>
                ) : (
                    ""
                )}
            </Grid>
            <Grid color="#516F90">{name}</Grid>
            <Grid></Grid>
            <Grid></Grid>

            <Grid>
                {
                    isDST ?
                    (
                        <>
                            {covertUtcToLocal(startOnDst, stationTimeZone)}-{covertUtcToLocal(endOnDst, stationTimeZone)}
                        </>
                    ) :
                    (
                        <>
                            {covertUtcToLocal(start, stationTimeZone)}-{covertUtcToLocal(end, stationTimeZone)}
                        </>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default React.memo(ShiftTypeBox);
