import React from 'react';
import {FuelLevelType, useStoreReducer} from "./store";
import {useFieldArray, useForm} from "react-hook-form";
import {Form, TextArea,ManageDisabledCheckBox} from "./Item";
import {Button} from '@dspworkplace/ui';
import styled from "styled-components";
import {CheckboxesContainerMileageType} from "./MileageQuestionForm";
import {VehicleImageCategory} from "../../Vehicles/VehicleImageCategories";

const ChoicesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
    height: 40px;

    input.name {
        width: 320px;
    }

    input.checkbox {
        display: flex;
        align-items: center;
        height: 100%;
    }

    label.isPassRequired {
        display: flex;
        align-items: center;
        height: 100%;
    }
`;

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: FuelLevelType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function FuelLevelQuestionForm(props: Props) {
    const {appState} = useStoreReducer();
    const {item, updateItem} = props;
    const {name, description, choices, minFuelLevel, isPhotoRequired, vehicleImageCategories, disabledQuestion} = item.content;
    const {register, handleSubmit, control, reset, watch} = useForm({
        defaultValues: {
            name,
            description,
            choices,
            minFuelLevel,
            isPhotoRequired,
            vehicleImageCategories: Array.isArray(vehicleImageCategories) && vehicleImageCategories.length > 0 ? vehicleImageCategories : appState.vehicleImageCategories,
            disabledQuestion
        }
    });
    const isPhotoRequiredWatch = watch("isPhotoRequired");
    const {fields} = useFieldArray<VehicleImageCategory>({
        name: "vehicleImageCategories",
        control
    });

    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            {/*<label htmlFor="minFuelLevel">Min. Fuel Level</label>*/}
            {/*<input name="minFuelLevel"  type="number" ref={*/}
            {/*    register({*/}
            {/*        min: 0*/}
            {/*    })*/}
            {/*}/>*/}

            <div className={'choices'}>Choices</div>
            <div>
                {choices.map((field, index) => {
                    return (
                        <ChoicesContainer key={index}>
                            <input
                                className={'name'}
                                name={`choices[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                                disabled={true}
                            />
                        </ChoicesContainer>
                    );
                })}
            </div>
            <label>Require picture</label>
            <input name="isPhotoRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {isPhotoRequiredWatch && <div className={'choices'}>Vehicle Image Category</div>}
            <div>
                {isPhotoRequiredWatch && fields.map((field, index) => {
                    return (
                        <CheckboxesContainerMileageType key={field.id}>
                            <input
                                className={'name'}
                                name={`vehicleImageCategories[${index}].name`}
                                defaultValue={field.name}
                                ref={register()}
                                disabled={true}
                            />
                            <input
                                className={'hide'}
                                name={`vehicleImageCategories[${index}].id`}
                                defaultValue={field.id}
                                ref={register()}
                                disabled={true}
                                hidden={true}
                            />
                            <input
                                name={`vehicleImageCategories[${index}].checked`}
                                type="checkbox"
                                className={'checked'}
                                defaultChecked={!!field.checked}
                                ref={register()}/>
                        </CheckboxesContainerMileageType>
                    );
                })}
            </div>
            {!isPhotoRequiredWatch && <div></div>}
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default FuelLevelQuestionForm;