import React, {createContext, useReducer} from 'react';
import VanAutoAssignmentReducer from './reducer';

const Store = createContext({});

const initialState = {
    packageRules: [],
    vehicleOrder:[],
    driverAffinity:[],
    conflict:[],
    // setting: {},
    isVanAutoAssignment: false
}

const VanAutoAssignmentProvider = ({children}) => {
    const useVanAutoAssignment = useReducer(VanAutoAssignmentReducer,initialState)
    return (
        <Store.Provider value={useVanAutoAssignment}>
            {children}
        </Store.Provider>
    )
}
export {VanAutoAssignmentProvider}

export default Store
