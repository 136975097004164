import React from "react";
import { SchedulesAddStateContext, Grid } from "../../index";
import moment from "moment";

const Information = () => {
    const { state } = React.useContext(SchedulesAddStateContext);
    const { name, recurring, startDate, endDate } = state.information;
    return (
        <Grid>
            <Grid padding="20px 0 0 0" color="#516F90" fontSize="22px" fontWeight="bold">
                Information
            </Grid>
            <Grid templateColumns="180px 100px" rowGap="4px" columnGap="8px">
                <Grid color="#516F90" fontSize="16px">
                    Name:
                </Grid>
                <Grid color="#516F90" fontSize="16px">
                    Recurring:
                </Grid>
                <Grid>{name}</Grid>
                <Grid>{recurring && recurring === true ? "Yes" : "No"}</Grid>
            </Grid>
            <Grid padding="12px 0 0 0" templateColumns="180px 100px" rowGap="4px" columnGap="8px">
                <Grid color="#516F90" fontSize="16px">
                    Start Date:
                </Grid>
                <Grid color="#516F90" fontSize="16px">
                    {" "}
                    {endDate && <>End Date:</>}
                </Grid>
                <Grid>{moment(startDate).utc().format("YYYY-MM-DD")}</Grid>
                <Grid>{endDate && moment(endDate).utc().format("YYYY-MM-DD")}</Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(Information);
