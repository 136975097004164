import {selector} from "recoil";
import {tokenState} from "../domain/security/state/Token";

export type planDetailsProps = {
    tier: string | undefined,
    interval: string | undefined
}

const defaultPlanDetails: planDetailsProps = {
    tier: undefined,
    interval: undefined,
}

export const planDetailsState = selector<planDetailsProps>({
    key: 'planDetails',
    get: ({get}) => {
        const token = get(tokenState);

        try {
            if (token['X-Plan-Tier'] && token['X-Payment-Interval'])
                return {
                    tier: token['X-Plan-Tier'],
                    interval: token['X-Plan-Interval'],
                }
            else
                return defaultPlanDetails;
        } catch (e) {
            return defaultPlanDetails;
        }
    }
})