import React from "react";
import { Icon, Spacer, Text, Theme } from "@dspworkplace/ui";
import ShiftType from "./ShiftType";
import { CustomButton, Grid } from "../../../Add";
import { DesignPanelDispatchContext } from "../index";

const Week = ({ arrItems, week, register, days, showRemove }) => {
    const { dispatch } = React.useContext(DesignPanelDispatchContext);

    const removeWeek = () => {
        dispatch({
            type: "removeWeek",
            week: week,
        });
    };

    var weekName = week + "th";
    if (week === 1) {
        weekName = week + "st";
    }
    if (week === 2) {
        weekName = week + "nd";
    }
    if (week === 3) {
        weekName = week + "rd";
    }
    return (
        <Grid templateColumns="70px auto" borderBottom="1px solid #CBD6E2" templateRows="auto" height="100%">
            <Grid borderRight="1px dashed #CBD6E2" justifyContent="center" height="100%" style={{ display: "flex" }}>
                {showRemove && (
                    <Spacer right={1}>
                        <Icon.Times
                            size={"20px"}
                            color={Theme.colors.info.shadow}
                            onClick={removeWeek}
                            style={{ verticalAlign: "bottom" }}
                        />
                    </Spacer>
                )}
                <Text>{weekName}</Text>
            </Grid>
            <Grid templateColumns="auto" height="100%">
                {arrItems &&
                    arrItems.length > 0 &&
                    arrItems.map((item, index) => {
                        return (
                            <ShiftType
                                key={week + index + 1}
                                elem={item}
                                week={week}
                                row={index}
                                register={register}
                                days={days}
                                shifts={arrItems}
                            ></ShiftType>
                        );
                    })}
                <Grid templateColumns="auto" justifyContent="center" padding="16px 0 16px 0">
                    <CustomButton width="148px" onClick={() => dispatch({ type: "setOpen", open: true, week: week })}>
                        Add Shift Type
                    </CustomButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(Week);
