import React, {useCallback, Dispatch} from "react";
import {useForm} from "react-hook-form";
import {uploadSingleFile} from "../api/utils";
import ChannelDetails from "./ChannelDetails";
import {useDispatch} from "./store";
import {ChannelSettingsState, ChannelSettingsAction} from "./ChannelSettings";
import {confirm} from "../components/Confirm";
import {alert} from "../components/Alert";
import {removeGroupPicture, userContainsAccountManagerRoles} from "./utils";
import {showErrorToast} from "../Utilities";

type Props = {
    cancelModal: () => void;
    state: ChannelSettingsState;
    dispatch: Dispatch<ChannelSettingsAction>;
};

function ChannelSettingsModal(props: Props) {
    const {state, cancelModal, dispatch} = props;
    const {channel, permissions, roles} = state;
    const chatDispatch = useDispatch();
    const {register, handleSubmit} = useForm({
        mode: "onBlur",
        defaultValues: {
            channelName: state.channelName,
            channelDescription: state.description,
            permissions: permissions,
            channelNotification: state.channelNotification
        }
    });

    const onSubmit = async data => {
        dispatch({
            type: "SET_CHANNEL_INFORMATION",
            channelName: data.channelName,
            description: data.channelDescription,
            image: data.channelImage
        });
        if (document.querySelectorAll("[data-tab='tab']")) {
            // @ts-ignore
            document.querySelectorAll("[data-tab='tab']")[1].click();
        }
    };

    const onUpdate = async data => {
        try {
            if (channel) {
                // @ts-ignore
                if (data.channelName !== channel.friendlyName) {
                    // @ts-ignore
                    await channel.updateFriendlyName(data.channelName).catch(e => {
                        showErrorToast(e, 'Error updating channel name.').then();
                    });
                }
                let update = false;
                let attributes = channel.attributes;
                if (attributes["description"] !== data.channelDescription) {
                    // @ts-ignore
                    attributes["description"] = data.channelDescription;
                    update = true;
                }
                if (data.channelImage.length > 0) {
                    // @ts-ignore
                    attributes["image"] = await uploadSingleFile(data.channelImage);
                    update = true;
                }
                if (data.permissions && Array.isArray(data.permissions)) {
                    attributes['permissions'] = data.permissions.map(e => parseInt(e, 10));
                    update = true;
                }
                if (update) {
                    // @ts-ignore
                    await channel.updateAttributes(attributes);
                    removeGroupPicture();
                }
            }
        } catch (e) {
            await showErrorToast(e, 'Error updating channel information.');
        }
        dispatch({type: "RESET"});
        cancelModal();
    };

    const archiveChannel = useCallback(async () => {
        if (channel) {
            if (channel.attributes["type"] !== "Archive") {
                if (userContainsAccountManagerRoles()) {
                    const confirmation = await confirm('Are you sure you want to archive this Group Channel?');
                    if (!confirmation) {
                        return;
                    }
                    chatDispatch({type: "ARCHIVE_CHANNEL", channel: channel});
                    let attributes = Object.assign(channel.attributes);
                    attributes["type"] = "Archive";
                    await channel.updateAttributes(attributes);
                    dispatch({type: "SET_CHANNEL", channel: channel});
                    cancelModal();
                    return;
                }
                await alert('You are not allowed to archive this Group Channel.');
            } else if (channel.attributes["type"] === "Archive") {
                let attributes = Object.assign(channel.attributes);
                attributes["type"] = channel.attributes["kind"];
                await channel.updateAttributes(attributes);
                dispatch({type: "SET_CHANNEL", channel: channel});
                chatDispatch({type: "UN_ARCHIVE_CHANNEL", channel: channel});
                cancelModal();
            }
        }
    }, [channel, chatDispatch, cancelModal]);

    const pinChannel = useCallback(async () => {
        if (channel) {
            if (channel.attributes["type"] !== "Pin") {
                chatDispatch({type: "PIN_CHANNEL", channel: channel});
                let attributes = Object.assign(channel.attributes);
                attributes["type"] = "Pin";
                await channel.updateAttributes(attributes);
                cancelModal();
            } else if (channel.attributes["type"] === "Pin") {
                let attributes = Object.assign(channel.attributes);
                attributes["type"] = channel.attributes["kind"];
                await channel.updateAttributes(attributes);
                chatDispatch({type: "UN_PIN_CHANNEL", channel: channel});
                cancelModal();
            }
        }
    }, [channel, chatDispatch, cancelModal]);

    return (
        <ChannelDetails
            errorMessage={state.errorMessage}
            inputRef={register({required: "You need to provide a channel name", maxLength: 256})}
            pictureUploaderRef={register({required: false})}
            descriptionRef={register({required: false})}
            notificationRef={register({required: false})}
            showNextCallback={handleSubmit(onSubmit)}
            image={state.image}
            updateNow={true}
            update={handleSubmit(onUpdate)}
            showNext={state.kindOfChannel === "Group" || state.kindOfChannel === "Pin"}
            singleChannelName={state.channelName}
            channelKind={state.kindOfChannel}
            isArchive={!(channel && channel.attributes['type'] === "Archive")}
            isArchiveCallback={archiveChannel}
            isPin={!(channel && channel.attributes['type'] === "Pin")}
            isPinCallback={pinChannel}
            numberOfButtons={state.kindOfChannel === "Duo" ? 2 : 4}
            kind={channel ? channel.attributes['kind'] : 'Group'}
            dropdownRef={register}
            roles={roles.map(e => ({name: e.roleName, value: e.id}))}
            permissions={permissions}
        />
    );
}

export default ChannelSettingsModal;
