import axios from "axios";
import {getCompanyId} from "../../../../Auth";
import {engine} from "../../../../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

const SegmentIncludes = {
    0:"id",
    1:"name",
    2:"employeesQuantity",
    3:"updatedAt"
}

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchSegments(params = {}){
        return API.call({
            actions: {
                response: {
                    AnncSegment:{
                        findBy: {
                            get: "AnncSegment",
                            criteria: {
                                company: getCompanyId()
                            },
                            orderBy: {
                                updatedAt: 'DESC'
                            },
                            matching: params.matching || {},
                            includes: SegmentIncludes
                        }
                    }
                }
            }
        });
    }
}

export default API;