import React, {useCallback, useEffect, useState} from 'react';
import {DriverFetchTask, FetchTaskReleaseShift, MessageOfChannel, Task} from "./types";
import {Message} from "twilio-chat/lib/message";
import {useUpdate as useBotDispatch} from "./BotStore";
import Loading, {useLoading} from "./Loading";
// @ts-ignore
import {
    engine, getCompanyId,
    getDriverId,
    getFriendlyName,
    getMainStationId,
    getUserId, showErrorToast,
    showToast
} from "../Utilities";
import BotTempMessage from "./BotTempMessage";
import moment from "moment-timezone";
// @ts-ignore
import InputForBot from "./InputForBot";
import {DriverButton, ScrollContainer} from "./BotTaskInteraction";
import {Button} from "@dspworkplace/ui";
import styled from "styled-components";
// @ts-ignore
import {deleteTwilioMessage} from "./utils";

const Container = styled.div`
    width: 300px;
    display: flex;
    row-gap: 2px;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : "column")};;
    margin: 4px 20px 4px 20px;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};

    &.row {
        flex-direction: row;
    }

    &.left {
        flex-direction: row;
        justify-content: flex-start;
    }

    &.right {
        flex-direction: row;
        justify-content: flex-end;
    }

    &.btn {
        padding-left: 8px;
        margin: 8px 20px 8px 20px;
    }
`;

type Props = {
    message: MessageOfChannel;
    twilioMessage?: Message;
    taskInformation: { id: number, type: string };
    scrollToBottom: () => void;
}

function BotTaskReleaseShift({message, taskInformation, scrollToBottom, twilioMessage}: Props) {
    let managerFriendlyName = getFriendlyName();
    // @ts-ignore
    const [systemMsg, setSystemMsg] = useState({
        user: message.user,
        date: message.date,
        text: message.text
    });
    // @ts-ignore
    const dispatch = useBotDispatch();
    // @ts-ignore
    const {loading, setLoading} = useLoading();
    // @ts-ignore
    const [taskCompleted, setTaskCompleted] = useState(false);
    // @ts-ignore
    const [task, setTask] = useState<Task>();
    // @ts-ignore
    const [disabled, setDisabled] = useState(true);
    // @ts-ignore
    const [sendMessage, setSendMessage] = useState(false);
    // @ts-ignore
    const [caseTask, setCaseTask] = useState("");
    // @ts-ignore
    const [replyTheMessage, setReplyTheMessage] = useState(false);
    // @ts-ignore
    const [messageToReply, setMessageToReply] = useState("");
    // @ts-ignore
    const [drivers, setDrivers] = useState<Array<DriverFetchTask>>([]);
    // @ts-ignore
    const [taskLoading, setTaskLoading] = useState(false);
    // @ts-ignore
    const [stepOneOptions, setStepOneOptions] = useState(true);
    // @ts-ignore
    const [showButtons, setShowButtons] = useState(true);
    // @ts-ignore
    const [stepTwoOptions, setStepTwoOptions] = useState(false);
    // @ts-ignore
    const [stepThreeOptions, setStepThreeOptions] = useState(false);
    // @ts-ignore
    const [shiftNameInformation, setShiftNameInformation] = useState<string>();
    const [reasonForRelease, setReasonForRelease] = useState<string>();
    // @ts-ignore
    const [selectedDrivers, setSelectedDrivers] = useState<Array<DriverFetchTask>>([]);
    // @ts-ignore
    const [placeholder, setPlaceholder] = useState("");
    // @ts-ignore
    const [submitButtonText, setSubmitButtonText] = useState("Finish Task");
    // @ts-ignore
    const [disableButton, setDisableButton] = useState(true);
    // @ts-ignore
    const [approvedMessage, setApprovedMessage] = useState<string>("");
    // @ts-ignore
    const [rejectedMessage, setRejectedMessage] = useState<string>("");
    // @ts-ignore
    const [withSelectedDriver, setWithSelectedDriver] = useState(false);
    // @ts-ignore
    const [showOptionsForShift, setShowOptionsForShift] = useState(false);
    // @ts-ignore
    const [optionForShift, setOptionForShift] = useState<string>("");

    // @ts-ignore
    const acceptTask = useCallback(
        () => {
            setCaseTask("ACCEPT");
            setApprovedMessage(`${managerFriendlyName} has approved Drop Shift Request for "${shiftNameInformation}"`);
            setShowOptionsForShift(true);
            scrollToBottom();
        },
        [managerFriendlyName, shiftNameInformation],
    );

    // @ts-ignore
    const acceptTaskWithDriver = useCallback(
        () => {
            setCaseTask("ACCEPT");
            setApprovedMessage(`${managerFriendlyName} has approved Drop Shift Request for "${shiftNameInformation}"`);
            setWithSelectedDriver(true)
            setDisabled(false);
            setDisableButton(false);
            scrollToBottom();
        },
        [managerFriendlyName, shiftNameInformation],
    );

    // @ts-ignore
    const rejectTask = useCallback(
        () => {
            setCaseTask("REJECT");
            setRejectedMessage(`${managerFriendlyName} has rejected Drop Shift Request for "${shiftNameInformation}"`);
            setDisabled(false);
            setDisableButton(false);
            scrollToBottom();
        },
        [managerFriendlyName, shiftNameInformation],
    );

    // @ts-ignore
    const replyMessage = useCallback(
        () => {
            setReplyTheMessage(true);
            setDisabled(false);
            setStepTwoOptions(false);
            setStepThreeOptions(true);
        },
        [],
    );

    const reset = useCallback(
        () => {
            setReplyTheMessage(false);
            setCaseTask("");
            setSendMessage(false);
            setDisabled(true);
            setMessageToReply("");
            setTaskLoading(false);
            setTaskCompleted(false);
            setStepOneOptions(true);
            setStepTwoOptions(false);
            setStepThreeOptions(false);
            setDisabled(true);
            setDisableButton(true);
            setWithSelectedDriver(false);
            setShowOptionsForShift(false);
            setOptionForShift("");
        },
        [],
    );

    const moveToOpenSift = useCallback(
        () => {
            setOptionForShift('OPEN_SHIFT');
            setShowOptionsForShift(false);
            setDisabled(false);
            setDisableButton(false);
            scrollToBottom();
        },
        [],
    );

    const archiveShift = useCallback(
        () => {
            setOptionForShift('ARCHIVE_SHIFT');
            setShowOptionsForShift(false);
            setDisabled(false);
            setDisableButton(false);
            scrollToBottom();
        },
        [],
    );

    const completeTask = async (msg) => {
        try {
            setStepThreeOptions(false);
            setTaskLoading(true);
            setDisabled(true);
            setDisableButton(true);
            let params = {
                task: taskInformation,
                case: caseTask,
                option: optionForShift,
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getUserId(),
                driverId: getDriverId(),
                message: caseTask === 'ACCEPT' ? `${approvedMessage}` : `${rejectedMessage}`,
                extraMessage: typeof msg === 'string' ? msg : '',
                withSelectedDriver: withSelectedDriver,
                twilioMessage: {
                    sid: message.sid,
                    attributes: message.attributes,
                    channelSid: message.element.channel.sid,
                    body: message.element.body,
                }
            };
            // alert(JSON.stringify(params));
            await engine({hideDefaultMessage: true}).post('/api/complete_task', params);
            setTaskCompleted(true);
            setStepTwoOptions(false);
            scrollToBottom();
            // dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
        } catch (e) {
            reset();
            await showErrorToast(e, 'Error in fetching task of Releasing Shifts.');
        }
    }

    const appendMessage = async (message?: string) => {
        if (message) {
            setMessageToReply(message);
            setDisabled(false);
        }
        await completeTask(message);
    }

    useEffect(() => {
        const abortController = new AbortController();
        const fetchTask = async () => {
            try {
                let response = await engine()
                    .post("/api/fetch_task", {
                        task: {
                            id: taskInformation.id,
                            type: taskInformation.type,
                        },
                        datetime: moment().toISOString(),
                        scenario: 'RELEASE_SHIFT',
                        companyId: getCompanyId(),
                        stationId: getMainStationId(),
                        userId: getUserId(),
                        driverId: getDriverId(),
                        attributes: twilioMessage!.attributes,
                        messageSid: twilioMessage!.sid,
                        channelSid: twilioMessage!.channel.sid,
                        taskInformation,
                    });
                setSystemMsg({user: message.user!, date: message.date, text: message.text});
                let data: FetchTaskReleaseShift = response.data;
                setTask(data.task);
                setDrivers(data.drivers);
                setShiftNameInformation(data.shiftNameInformation);
                setReasonForRelease(data.reasonForRelease);
                setSelectedDrivers(data.selectedDrivers);
                setLoading(false);
            } catch (e) {
                console.error(e);
                console.error({fetchTaskReleaseShift: e});
                console.error(e.message);
                if (e.response && e.response.data && typeof e.response.data.message === 'string') {
                    console.error(JSON.stringify(e.response.data.message === 'Task is archived.'));
                    console.error(e.response.data.message);
                    if (e.response.data.message === 'Task is archived.') {
                        await deleteTwilioMessage(message.element.channel.sid, message.sid, message.attributes);
                    }
                    await showToast({
                        type: 'Error',
                        title: 'Error API Task',
                        content: e.response.data.message + JSON.stringify(e.response.data.message === 'Task is archived.'),
                    });
                }
                if (typeof e.message === "string") {
                    if (e.message === 'Task is archived.') {
                        await deleteTwilioMessage(message.element.channel.sid, message.sid, message.attributes);
                    }
                    await showToast({
                        type: 'Error',
                        title: 'Error in Task',
                        content: e.message + JSON.stringify(e.message === 'Task is archived.'),
                    });
                }
                await showToast({
                    type: 'Error',
                    title: 'Error in Task',
                    content: 'Error in fetching the task information.',
                });
            }

        }
        fetchTask().then();
        return () => {
            abortController.abort();
        };
    }, [message.date, message.text, message.user, setLoading, taskInformation.id, taskInformation.type]);

    if (loading) {
        return <Loading containerHeight={"500px"}/>
    }

    return (
        <>
            <ScrollContainer>
                <BotTempMessage message={
                    {
                        user: systemMsg.user,
                        date: new Date(),
                        text: systemMsg.text + `\nReason for Drop Shift Request: ${reasonForRelease}`,
                    }
                }/>
                <Container className={"btn"}>
                    {drivers.map(item => <DriverButton driver={item} key={item.id}/>)}
                </Container>
                {Array.isArray(selectedDrivers) && selectedDrivers.length > 0 &&
                <>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `The suggested driver for pick up this shift release shift.`,
                        }
                    }/>
                    <Container className={"btn"}>
                        {selectedDrivers.map(item => <DriverButton driver={item} key={item.id}/>)}
                    </Container>
                </>}
                {showButtons && selectedDrivers.length === 0 && <Container className={"btn"}>
                    <Button onClick={acceptTask}>Accept</Button>
                    <Button onClick={rejectTask}>Reject</Button>
                </Container>}
                {showButtons && selectedDrivers.length > 0 && <Container className={"btn"}>
                    <Button onClick={acceptTask}>Accept</Button>
                    <Button onClick={acceptTaskWithDriver}>Accept With Driver</Button>
                    <Button onClick={rejectTask}>Reject</Button>
                </Container>}
                {showOptionsForShift &&
                <>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please choose an option for the Drop Shift Request",
                        }
                    }/>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                        <Button onClick={moveToOpenSift}>Move to Open Shift</Button>
                        <Button onClick={archiveShift}>Archive Shift</Button>
                    </Container>
                </>
                }
                {optionForShift === 'ARCHIVE_SHIFT' && caseTask === 'ACCEPT' && !withSelectedDriver &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "The shift will be archived.",
                        }
                    }/>
                </>
                }
                {optionForShift === 'OPEN_SHIFT' && caseTask === 'ACCEPT' && !withSelectedDriver &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "The shift will be moved to Open Shifts section..",
                        }
                    }/>
                </>
                }
                {optionForShift.length > 0 && caseTask === 'ACCEPT' && !withSelectedDriver &&
                <>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `The approved driver ${drivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }
                    }/>

                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please type an additional message to the approved driver or press Finish Task.",
                        }
                    }/>
                </>
                }
                {optionForShift.length > 0 && caseTask === 'ACCEPT' && withSelectedDriver &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `The approved driver ${drivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: `The approved driver ${selectedDrivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please type an additional message to the approved driver or press Finish Task.",
                        }
                    }/>
                </>
                }
                {caseTask === 'REJECT' &&
                <>
                    <Container className={"btn"}>
                        <Button size="small" onClick={reset}>{"Back"}</Button>
                    </Container>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: 'The rejected driver will receive this message: ' + rejectedMessage + "\n" + messageToReply,
                        }
                    }/>
                    <BotTempMessage message={
                        {
                            user: systemMsg.user,
                            date: new Date(),
                            text: "Please type an additional message to the rejected driver or press Finish Task.",
                        }
                    }/>
                </>
                }
                {taskLoading && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Please wait, we are completing the task.",
                }}/>}
                {taskCompleted && <BotTempMessage message={{
                    user: systemMsg.user,
                    date: new Date(),
                    text: "Task Completed!",
                }}/>}
            </ScrollContainer>
            <InputForBot disabled={disabled} submitMessage={appendMessage} placeholder={placeholder}
                         submitButtonText={submitButtonText} disableSendButton={disableButton}/>
        </>
    );
}

export default BotTaskReleaseShift;