import React from 'react';
import { Button, Spacer, CustomDropdown, SubTitle } from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import {getChartTypeDesc} from '../../hooks/useChartConfig';

const InitialForm = ({ availableMembers, onSubmit, chartConfig, onCancel }) => {
    const { register, handleSubmit, watch } = useForm(
        {
            defaultValues:{
                schema: chartConfig?.schema
            }
        });
    const schema = watch('chartSchema',null);

    const schemas = availableMembers
        .map((member) => {
            const [schema] = member.name.split('.');
            return schema;
        })
        .filter((schema, i, self) => self.indexOf(schema) === i)
        .map((schema) => ({ name: schema,  value: schema }));

    const handleOnSubmit = (data) => {
        onSubmit(data.chartSchema);
    };

    return (
        <>
            <header style={{display:'flex',justifyContent:'space-between'}}>
                <SubTitle style={{display:'flex',alignItems:'center'}}>
                    <span>Choose an schema</span>
                </SubTitle>
                <Button type="cancel" onClick={onCancel}>
                    Cancel
                </Button>
            </header>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <Spacer top={2}>
                    <CustomDropdown
                        name='chartSchema'
                        options={schemas}
                        ref={register()}
                    />
                </Spacer>
                <Spacer top={2}>
                    {getChartTypeDesc(schema)}
                </Spacer>
                <Spacer top={5} style={{display:'flex',justifyContent:'flex-end'}}>
                    <Button
                        type={'primary'}
                        Type={'submit'}
                    >
                        Continue
                    </Button>
                </Spacer>
            </form>
        </>
    );
};

export default InitialForm;