import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from "styled-components";
import {
  Button, ButtonDropdown, Icon, Input, Spacer, ThirdTitle,
  InputColor, Theme
} from "@dspworkplace/ui";
import { useForm } from 'react-hook-form';
import DatePicker from '../../../../components/Calendar/picker';


const SettingsElements = ({
  thresholds,
  setThresholds,
  settingIndex,
  setting,
  tIndex,
  setIsEditMode,
  isEditMode,
  item
}) => {

  const { register, errors, getValues, handleSubmit, reset, watch } = useForm({
    defaultValues: setting
  });
  const [isEdit, setIsEdit] = useState(!setting?.color);
  const {
    color,
    label,
    min,
    max,
    value
  } = getValues();
  const isTimeDimension = item?.isTimeDimension;
  const sortedRangeArray = useRef([])
  const dateArray = useRef([]);
  const isInputElements = ['AMZWeekly.tier', 'CustomerDeliveryFeedback.tier'];

  const LabelContainer = styled.div`
    width: 140px;
    display: inline-block;
    vertical-align: top;
  `;

  const LabelName = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    padding-bottom: 2px;
    color: #707070;
  `;

  const LabelValue = styled.div`
    font-size: 14px;
    line-height: 16px;
    padding: 6px 0px;
    color: #333333;
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;

  const onSubmit = (data) => {
    const label = data.label || null;
    const tempThresholds = [...thresholds];
    if (tempThresholds?.[tIndex]?.thresholdsSettings?.[settingIndex]) {
      const newData = {
        ...data,
        color: data?.color?.[0],
        label: label
      }
      tempThresholds[tIndex].thresholdsSettings[settingIndex] = newData
      setThresholds(tempThresholds)
    }
    handleEdit()
  }

  const handleEdit = () => {
    setIsEdit(!isEdit)
  }

  const handleDelete = () => {
    const tempThresholds = [...thresholds];
    if (tempThresholds?.[tIndex]?.thresholdsSettings?.length === 1) {
      tempThresholds.splice(tIndex, 1)
      setThresholds(tempThresholds);
    } else if (tempThresholds?.[tIndex]?.thresholdsSettings?.length > 1) {
      tempThresholds[tIndex].thresholdsSettings.splice(settingIndex, 1);
      setThresholds(tempThresholds);
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
    if (!setting?.color) {
      handleDelete();
    } else {
      handleEdit();
    }
  }

  const editClick = () => {
    reset(setting)
    handleEdit();
  }

  useEffect(() => {
    setIsEditMode(isEdit)
    processThresholds()
  }, [])

  useEffect(() => {
    setIsEditMode(isEdit)
    processThresholds()
  }, [isEdit])

  const processThresholds = () => {
    const max = setting.max;
    const min = setting.min;
    dateArray.current = [];
    sortedRangeArray.current = [];
    let arrayToUpdate = isTimeDimension ? dateArray : sortedRangeArray;
    for (const threshold of item?.thresholdsSettings) {
      const min = threshold.min;
      const max = threshold.max;
      if (!isNaN(min) && !isNaN(max)) {
        const numMin = parseFloat(min);
        const numMax = parseFloat(max);
        for (let i = numMin; i <= numMax; i++) {
          sortedRangeArray.current.push(i);
        }
        sortedRangeArray.current.sort((a, b) => a - b);
      } else {
        const startDate = new Date(min);
        const endDate = new Date(max);
        while (startDate <= endDate) {
          dateArray.current.push(startDate.toISOString().split('T')[0]);
          startDate.setDate(startDate.getDate() + 1);
        }
      }
    }
    if (isEdit && arrayToUpdate) {
      arrayToUpdate.current = arrayToUpdate.current.filter(value => value < min || value > max);
    }
  };

  const validateMinMax = (minValue, maxValue) => {
    let arrayToUpdate;
    isTimeDimension ? arrayToUpdate = dateArray.current : arrayToUpdate = sortedRangeArray.current;
    if (minValue === maxValue) {
      return "Min and Max cannot be the same";
    }
    if (minValue >= maxValue) {
      return "Min date must be less than Max date";
    }
    for (let i = 0; i < arrayToUpdate.length; i++) {
      if (minValue <= arrayToUpdate[i] && maxValue >= arrayToUpdate[i]) {
        return 'Values are outside the allowed range. Please correct them.';
      }
    }
  };

  return isEdit ?
    <form>
      <div style={{ display: 'flex', marginBottom: '12px' }}>
        <Spacer right={3} style={{ zIndex: 1, paddingTop: "25px" }} inline>
          <InputColor
            name="color"
            required
            ref={register({
              required: 'Pick',
              validate: (selectedColor) => {
                if(selectedColor[0] === setting?.color) return true;
                const colorExists = item.thresholdsSettings.some((threshold) =>
                  threshold.color === selectedColor[0]
                );
                if (colorExists) {
                  return 'Exists';
                }
                return true;
              },
            })}
            value={setting?.color ? setting?.color : ''}
            error={errors.color && errors.color.message}
            valid={!errors.color && color && color.length > 0}
          />
        </Spacer>
        <Spacer right={3} inline>
          <Input
            ref={register}
            name={'label'}
            label={'Label'}
            size="small"
            error={errors.label && errors.label.message}
            valid={!errors.label && label && label.length > 0}
          />
        </Spacer>
        <Spacer right={3} inline>
          {isTimeDimension ?
            <DatePicker
              name={'min'}
              label={'Min'}
              ref={register({
                required: 'Field required',
                validate: {
                  inRange: (value) => {
                    const minFieldValue = watch('min');
                    const maxFieldValue = watch('max');
                    if (value === setting?.min) return true;
                    if (value) {
                      return validateMinMax(minFieldValue, maxFieldValue);
                    }
                  },
                  notEqualToMaxDate: (value) => {
                    const maxFieldValue = watch('max');
                    return value !== maxFieldValue || 'Min and Max date cannot be the same';
                  },
                  lessThanMaxDate: (value) => {
                    const maxFieldValue = watch('max');
                    return value < maxFieldValue || 'Min date must be less than Max date';
                  },
                },
              })}
              error={errors.min && errors.min.message}
              size={'small'}
              required
            />
            : (item.type === 'string' || isInputElements.includes(item?.name)) ?
              <Input
                ref={register({
                  required: 'Required field',
                  validate: (equal) => {
                    if(equal === setting?.value) return true;
                    const valueExists = item.thresholdsSettings.some((threshold) =>
                      threshold.value === equal
                    );
                    if (valueExists) {
                      return 'Value is already exists';
                    }
                    return true;
                  }
                })}
                name={'value'}
                label={'Value'}
                type="string"
                size="small"
                error={errors.value && errors.value.message}
                valid={!errors.value && value && value.length > 0}
              />
              : <Input
                ref={register({
                  required: "Enter valid value",
                  validate: {
                    inRange: (value) => {
                      const minFieldValue = parseFloat(watch('min'));
                      const maxFieldValue = parseFloat(watch('max'));
                      if (value === setting?.min) return true;
                      if (value) {
                        return validateMinMax(minFieldValue, maxFieldValue);
                      }
                    },
                    notEqualToMax: (value) => {
                      const maxFieldValue = parseFloat(watch('max'));
                      return parseFloat(value) !== maxFieldValue || "Min and Max cannot be the same";
                    },
                    lessThanMax: (value) => {
                      const maxFieldValue = parseFloat(watch('max'));
                      return (
                        parseFloat(value) < maxFieldValue ||
                        "Min must be less than Max"
                      );
                    },
                  },
                })}
                name={'min'}
                label={'Min'}
                type="number"
                size="small"
                error={errors.min && errors.min.message}
                valid={!errors.min && min && min.length > 0}
              />}
        </Spacer>
        <Spacer right={3} inline>
          {isTimeDimension ?
            <DatePicker
              name={'max'}
              label={'Max'}
              ref={register({
                required: 'Field required',
                validate: {
                  inRange: (value) => {
                    const minFieldValue = watch('min');
                    const maxFieldValue = watch('max');
                    if (value === setting?.max) return true;
                    if (value) {
                      return validateMinMax(minFieldValue, maxFieldValue);
                    }
                  },
                  notEqualToMinDate: (value) => {
                    const minFieldValue = watch('min');
                    return value !== minFieldValue || 'Min and Max date cannot be the same';
                  },
                  greaterThanMinDate: (value) => {
                    const minFieldValue = watch('min');
                    return value > minFieldValue || 'Max date must be greater than Min date';
                  },
                },
              })}
              error={errors.max && errors.max.message}
              size={'small'}
              required
            />
            :
            (item?.type === 'string' || isInputElements.includes(item?.name)) ? null : <Input
              ref={register({
                required: "Enter valid value",
                validate: {
                  inRange: (value) => {
                    const minFieldValue = parseFloat(watch('min'));
                    const maxFieldValue = parseFloat(watch('max'));
                    if (value === setting?.max) return true;
                    if (value) {
                      return validateMinMax(minFieldValue, maxFieldValue);
                    }
                  },
                  notEqualToMin: (value) => {
                    const minFieldValue = parseFloat(watch('min'));
                    return parseFloat(value) !== minFieldValue || "Min and Max cannot be the same";
                  },
                  greaterThanMin: (value) => {
                    const minFieldValue = parseFloat(watch('min'));
                    return (
                      parseFloat(value) > minFieldValue ||
                      "Max must be greater than Min"
                    );
                  },
                },
              })}
              name={'max'}
              label={'Max'}
              type="number"
              size="small"
              error={errors.max && errors.max.message}
              valid={!errors.max && max && max.length > 0}
            />}
        </Spacer>
        <div style={{ display: "flex", marginLeft: "auto", paddingTop: "30px" }}>
          <button
            onClick={handleSubmit(onSubmit)}
            style={{
              padding: 0,
              margin: 0,
              background: 'white',
              display: 'block',
              height: "30px",
              width: '30px'
            }}
          >
            <Icon.Check
              size={'30px'}
              style={{ cursor: 'pointer' }}
            />
          </button>
          <div onClick={handleCancel}>
            <Icon.Times
              size={'30px'}
              color={Theme.colors.error.border}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </form> :
    <div style={{ display: "flex", alignItems: 'center' }}>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <Spacer right={3}>
          <span
            style={{
              height: 35,
              width: 35,
              background: setting?.color,
              display: 'block',
              borderRadius: '100%',
              border: '1px solid #CCCCCC',
              marginRight: "5px"
            }}
          />
        </Spacer>
        <Spacer right={3}>
          <LabelContainer>
            <LabelName>
              Label
            </LabelName>
            <LabelValue>
              {setting?.label || "-"}
            </LabelValue>
          </LabelContainer>
        </Spacer>
        {(item?.type === "string" || isInputElements.includes(item?.name)) ?
          <Spacer right={3}>
            <LabelContainer>
              <LabelName>
                Value
              </LabelName>
              <LabelValue>
                {setting?.value}
              </LabelValue>
            </LabelContainer>
          </Spacer> :
          <>
            <Spacer right={3}>
              <LabelContainer>
                <LabelName>
                  Min
                </LabelName>
                <LabelValue>
                  {setting?.min}
                </LabelValue>
              </LabelContainer>
            </Spacer>
            <Spacer right={3}>
              <LabelContainer>
                <LabelName>
                  Max
                </LabelName>
                <LabelValue>
                  {setting?.max}
                </LabelValue>
              </LabelContainer>
            </Spacer>
          </>}
      </div>
      <div style={{ display: "flex", marginLeft: "auto" }}>
        <div onClick={!isEditMode ? editClick : null}>
          <Icon.Edit
            size={'30px'}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div onClick={!isEditMode ? handleDelete : null}>
          <Icon.Trash
            size={'30px'}
            color={Theme.colors.error.border}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
}

const SelectedThresholds = ({
  thresholds,
  setThresholds,
  setIsEditMode,
  isEditMode
}) => {

  const handleButtonClick = (key) => {
    if (key >= 0 && key < thresholds.length) {
      const newThresholds = [...thresholds];
      newThresholds[key].thresholdsSettings.push({
        color: '',
        label: '',
        min: '',
        max: '',
        value: ''
      });
      setThresholds(newThresholds);
    }
  }

  return (
    thresholds.map((item, tIndex) => (
      item.isTimeDimension ? null : <div key={tIndex + 'thresholds'}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} >
          <ThirdTitle>{item?.shortTitle}</ThirdTitle>
          <Spacer left={4} />
          <Button
            size="small"
            style={{ display: "flex", alignItems: "center", pointerEvents: isEditMode ? 'none' : '' }}
            onClick={() => { handleButtonClick(tIndex) }}
            disabled={isEditMode}

          >
            <Icon.Plus size={"12px"} />
          </Button>
        </div>
        {item?.thresholdsSettings?.map((setting, settingIndex) => (
          <div key={settingIndex + 'thresholdsSettings'}>
            <SettingsElements
              item={item}
              setting={setting}
              thresholds={thresholds}
              setThresholds={setThresholds}
              settingIndex={settingIndex}
              tIndex={tIndex}
              setIsEditMode={setIsEditMode}
              isEditMode={isEditMode}
            />
            <Spacer bottom={3} />
          </div>
        ))}
      </div>
    ))
  )

}

const ThresholdsPanel = ({
  measuresList,
  chartConfig,
  availableTimeDimensions,
  thresholds = [],
  setThresholds,
  dimensionsList
}) => {
  const [chartConfigData] = chartConfig;

  const [isEditMode, setIsEditMode] = useState(false)
  const schema = chartConfigData?.schema
  const updatedAvailableTimeDimensions = () => {
    return availableTimeDimensions.filter((t) => {
      if (t.name.includes(schema + '.')) return true
      else return false
    });
  }

  const dropdownOptions = (options, thresholds, setThresholds, isTimeDimension) => {
    if (!options) return false;
    const list = options.map((m) => {
      let exist = false;
      exist = !!thresholds.filter((t) => t.name === m.name).length;
      return !exist ? ({
        text: m.shortTitle, fn: () => {
          const newData = [...thresholds];
          setThresholds([
            ...newData,
            {
              shortTitle: m?.shortTitle,
              title: m?.title,
              name: m?.name,
              isTimeDimension: isTimeDimension,
              type: m?.type,
              thresholdsSettings: [{
                color: '',
                label: '',
                min: '',
                max: '',
                value: ''
              }]
            }
          ])
        }
      }) : null
    })
    return list?.filter(item => item !== null)
  }

  const timeDimensions = updatedAvailableTimeDimensions()
  const addNewTimeDimensionOptions = useMemo(() => {
    return dropdownOptions(timeDimensions, thresholds, setThresholds, true)
  }, [schema, timeDimensions]);

  const measures = measuresList?.length > 0 ? measuresList.map(item => item.value) : null
  const measureOptions = useMemo(() => {
    return dropdownOptions(measures, thresholds, setThresholds, false)
  }, [schema, measures]);

  const filteredDimensionsList = dimensionsList.filter(dimension => dimension.value.type !== 'time');
  const dimensions = filteredDimensionsList?.length > 0 ? filteredDimensionsList.map(item => item.value) : null

  const dimensionOptions = useMemo(() => {
    return dropdownOptions(dimensions, thresholds, setThresholds, false)
  }, [schema, dimensions]);

  return (
    <>
      <Spacer top={4} />
      <div style={{ display: 'flex' }}>
        <div
          style={{
            pointerEvents: isEditMode ? 'none' : '',
            opacity: isEditMode ? '0.5' : '1'
          }}
        >
          <ButtonDropdown
            text='Dimension'
            divider={false}
            options={dimensionOptions}
            disabled={isEditMode}
          />
        </div>
        <Spacer right={4} />
        <div
          style={{
            pointerEvents: isEditMode ? 'none' : '',
            opacity: isEditMode ? '0.5' : '1'
          }}
        >
          <ButtonDropdown
            text='Measures'
            divider={false}
            options={measureOptions}
            disabled={isEditMode}
          />
        </div>
        <Spacer right={4} />
        <div
          style={{
            pointerEvents: isEditMode ? 'none' : '',
            opacity: isEditMode ? '0.5' : '1'
          }}
        >
          {/* <ButtonDropdown
            text='Time Dimension'
            divider={false}
            options={addNewTimeDimensionOptions}
          /> */}
        </div>
        <Spacer top={4} />
      </div>
      <Spacer top={4} />
      <SelectedThresholds
        thresholds={thresholds}
        setThresholds={setThresholds}
        setIsEditMode={setIsEditMode}
        isEditMode={isEditMode}
      />
    </>
  );
}

export default ThresholdsPanel