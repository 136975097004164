import {AxiosError, AxiosInstance} from "axios";
import {upload} from "../Auth";
import moment from "moment-timezone";
import {showErrorToast, showToast} from "../Utilities";

// return in KM
export function findRadius(latitude1: number, longitude1: number, latitude2: number, longitude2: number): number {
    latitude1 = toRadians(latitude1);
    longitude1 = toRadians(longitude1);
    latitude2 = toRadians(latitude2);
    longitude2 = toRadians(longitude2);
    return 6371 * Math.acos(
        Math.sin(latitude1) * Math.sin(latitude2)
        + Math.cos(latitude1) * Math.cos(latitude2) * Math.cos(longitude2 - longitude1));
}

// Converts numeric degrees to radians
export function toRadians(value: number): number {
    return value * Math.PI / 180;
}

export function fetchPopupTimelineData(api: AxiosInstance, driverId: number | string, driverRouteId: number | string, shiftDate: string) {
    return new Promise(async (resolve, reject) => {
        try {
            const params = {
                driverId: driverId,
                driverRouteId: driverRouteId,
                timezone: moment.tz.guess(),
                timezoneOffset: new Date().getTimezoneOffset(),
                shiftDate: shiftDate
            }
            let response = await api.post('/api/driver/get_day_timelines', params);
            if (response.data && response.data.success) {
                resolve(response.data);
            }
            reject(response);
        } catch (e) {
            reject(e)
        }
    });
}

export function fetchDayTimelineData(api: AxiosInstance, driverId: number | string) {
    return new Promise(async (resolve, reject) => {
        try {
            const params = {
                driverId: driverId,
                timezone: moment.tz.guess(),
                timezoneOffset: new Date().getTimezoneOffset()
            }
            let response = await api.post('/api/driver/get_day_timelines', params);
            if (response.data && response.data.success) {
                resolve(response.data);
            }
            reject(response);
        } catch (e) {
            reject(e)
        }
    });
}

export function fetchTodayTimelineData(api: AxiosInstance, driverId: number | string) {
    return new Promise(async (resolve, reject) => {
        try {
            const params = {
                driverId: driverId,
                timezone: moment.tz.guess(),
            }
            let response = await api.post('/api/driver/get_today_timeline', params);
            if (response.data && response.data.success) {
                resolve(response.data);
            }
            reject(response);
        } catch (e) {
            reject(e)
        }
    });
}

type EngineResult = {
    result: any,
    code: number,
    message: string | any,
    responseTime: any,
    response: any
}


export async function post(api: AxiosInstance, path: string, params: any): Promise<EngineResult | AxiosError> {
    let response = await api.post(path, params).catch(error => {
        console.log('[ERROR] createVehicleAndLocation => ', error);
        return error;
    });
    let {data: {data: result, code, message, responseTime}} = response;
    return {result, code, message, responseTime, response};
}

export async function uploadSingleFile(data: FileList): Promise<string | null> {
    try {
        if (data.length === 0) {
            await showToast(
                {
                    type: 'Error',
                    title: 'Failed to upload one file since there is no file selected.'
                });
            return null;
        }
        let formData = new FormData();
        formData.append('image', data[0]);
        formData.append('name', data[0].name);
        let result = await upload(formData);
        // @ts-ignore
        return result.data['ObjectURL'];
    } catch (e) {
        await showErrorToast(e, 'Failed to upload one file.');
        return null;
    }
}

