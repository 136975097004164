import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Icon} from '@dspworkplace/ui';
import {addItemSection, ContainerItemsType, useStoreReducer} from "./store";
import {
    AI_ASSISTED_IMAGE_INSPECTION,
    aiAssistedImageInspectionSample,
    CUSTOM_METER_ENTRY,
    customMileageSample,
    ENTRY_TEXT,
    entryInstructionSample,
    entryTextSample,
    FUEL_LEVEL,
    fuelLevelSample,
    INSTRUCTION,
    MILEAGE,
    mileageSample,
    MULTIPLE_CHOICES,
    multipleChoicesSample,
    PACKAGES_MISSING,
    PACKAGES_RETURNING,
    packagesMissingSample,
    packagesReturningSample,
    PASS_QUESTION,
    SCANNER,
    scannerSample,
    VEHICLE_IMAGE,
    vehicleImageSample,
    ynQuestionSample
} from "./data";
import {ButtonComponent} from "./shared";
import {updateTemplate} from "./util";
import Loading from "../../../Chat/Loading";

function useVisible(initialIsVisible) {
    const [isVisible, setIsVisible] = useState(initialIsVisible);
    const [isInside, setIsInside] = useState(false);
    const ref = useRef<any>(null);
    const mainRef = useRef<any>(null);

    const handleClickOutside = (event) => {
        if (event.target.innerText === 'x') {
            return;
        }
        if (ref.current && !ref.current.contains(event.target)) {
            // console.log('outside');
            if (event.target.innerText === 'x') {
                // console.log('inside');
                return;
            }
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return {ref, isVisible, setIsVisible, isInside, setIsInside, mainRef};
}

const OptionsContainer = styled.div`
    position: absolute;
    z-index: 1000;
    top: 240px;
    left: 324px;
    //left: 16px;
    background-color: #fefefe;
    //margin-top: 80px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #0071BC;
    font-size: 16px;
    font-family: circe-rounded, serif;
    font-weight: normal;
    border: 1px solid #7C98B6;
`;

const OptionsItem = styled.div`
    display: flex;
    color: #0071BC;
    font-size: 16px;
    font-family: circe-rounded, serif;
    font-weight: normal;
    padding: 8px 12px;
    border-radius: 2px;
    width: 100%;
    border-left: 4px solid transparent;

    &:hover {
        border-left: 4px solid #0071BC;
        box-shadow: 0 0 6px #0071BC;
    }
`;

const OptionsItemTitle = styled.div`
    display: flex;
    color: #0071BC;
    font-size: 20px;
    font-family: circe-rounded, serif;
    font-weight: normal;
    padding: 8px 12px;
    border-radius: 2px;
    width: 100%;
    border-bottom: 2px solid #0071BC;
`;


type Props = {
    index: number;
    section: ContainerItemsType;
}

function ButtonShowOptions({index, section}: Props) {
    const {appState, appDispatch} = useStoreReducer();
    const {isVisible, ref, setIsVisible} = useVisible(false);
    const [loading, setLoading] = useState(false);

    const createItem = (type) => async () => {
        setLoading(true);
        let newItem;
        if (type === PASS_QUESTION) {
            newItem = ynQuestionSample();
        } else if (type === VEHICLE_IMAGE) {
            newItem = vehicleImageSample();
        } else if (type === ENTRY_TEXT) {
            newItem = entryTextSample();
        } else if (type === MILEAGE) {
            newItem = mileageSample();
        } else if (type === MULTIPLE_CHOICES) {
            newItem = multipleChoicesSample();
        } else if (type === SCANNER) {
            newItem = scannerSample();
        } else if (type === FUEL_LEVEL) {
            newItem = fuelLevelSample();
        } else if (type === INSTRUCTION) {
            newItem = entryInstructionSample();
        } else if (type === PACKAGES_MISSING) {
            newItem = packagesMissingSample();
        } else if (type === PACKAGES_RETURNING) {
            newItem = packagesReturningSample();
        } else if (type === CUSTOM_METER_ENTRY) {
            newItem = customMileageSample();
        } else if (type === AI_ASSISTED_IMAGE_INSPECTION) {
            newItem = aiAssistedImageInspectionSample();
        }
        let data = addItemSection(appState, {sectionId: section.id, item: newItem});
        let result = await updateTemplate(appState.stationId, data, appState.scenario, appState.id!, 'ADD_ITEM_SECTION');
        if(result.id === 0){
            setLoading(false);
            setIsVisible(false);
            return;
        }
        appDispatch({
            type: 'ADD_ITEM_SECTION',
            sectionId: section.id,
            item: newItem,
            updatedAt: result.updatedAt,
            updatedBy: result.updatedBy
        });
        setLoading(false);
        setIsVisible(false);
    }

    if (loading) {
        return <Loading imgHeight={'28px'} imgWidth={'32px'}/>
    }

    return (
        <>
            <ButtonComponent
                onClick={() => {
                    setIsVisible(!isVisible);
                }}>{isVisible ? <Icon.Times size={"20px"}/> : <Icon.Plus size={"20px"}/>}
            </ButtonComponent>
            {isVisible && <OptionsContainer ref={ref}>
                <OptionsItemTitle>{`Add to Section #${index + 1}`}</OptionsItemTitle>
                <OptionsItem onClick={createItem(1)}>Pass Question</OptionsItem>
                <OptionsItem onClick={createItem(2)}>Vehicle Image</OptionsItem>
                <OptionsItem onClick={createItem(3)}>Multiple Choices</OptionsItem>
                <OptionsItem onClick={createItem(4)}>Input</OptionsItem>
                <OptionsItem onClick={createItem(5)}>Scanner</OptionsItem>
                <OptionsItem onClick={createItem(6)}>Meter Entry</OptionsItem>
                <OptionsItem onClick={createItem(7)}>Fuel Level</OptionsItem>
                <OptionsItem onClick={createItem(8)}>Instruction</OptionsItem>
                <OptionsItem onClick={createItem(11)}>Custom Meter Entry</OptionsItem>
                <OptionsItem onClick={createItem(12)}>AI Multi-Photo Question</OptionsItem>

                {appState.scenario === 'RTS' &&
                    <>
                        <OptionsItem onClick={createItem(9)}>Packages Missing</OptionsItem>
                        <OptionsItem onClick={createItem(10)}>Packages Returning</OptionsItem>
                    </>
                }
            </OptionsContainer>}
        </>
    );
}

export default ButtonShowOptions;