import React from 'react';
import styled from "styled-components";
import {Icon, Theme} from '@dspworkplace/ui';
import {getMetricValue} from "../../../shared";
//@ts-ignore
import {dspIcon} from "../../../../../../../Utilities";

const mobileWidth = 428;
const cardWidth = mobileWidth - 16 - 16;
const cardWidthInside = cardWidth - 2 - 2;
const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    // width: ${cardWidth}px;
    height: 96px;
    margin: 4px 16px;
    border-radius: 4px;
    border-color: #EAF0F6;
    border-width: 2px;
    align-items: center;
    border-style: solid;
    flex-shrink: 0;

    &.errorView {
        background-color: #FDEDEE67 !important;
        border-color: #F2545B !important;
    }

    .leftView {
        display: flex;
        flex-direction: column;
        // width: ${cardWidthInside * 0.5}px;
    }

    .rightOneView {
        display: flex;
        flex-direction: column;
        // width: ${cardWidthInside * 0.2}px;
    }

    .rightTwoView {
        display: flex;
        flex-direction: column;
        // width: ${cardWidthInside * 0.2}px;
    }

    .rightThreeView {
        display: flex;
        flex-direction: column;
        // width: ${cardWidthInside * 0.1}px;
    }

    .viewMetricNameContainer {
        display: flex;
        margin-left: 12px;
    }

    .metricNameTextSmallFont {
        display: flex;
        color: #516F90;
        font-size: 18px;
    }

    .metricNameText {
        display: flex;
        color: #516F90;
        font-size: 22px;
    }

    .viewMetricValue {
        display: flex;
        flex-direction: row;
        margin-left: 12px;
        align-items: center;
    }

    .viewMetricValueText {
        margin-right: 16px;
    }

    .metricValueText {
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
    }

    .viewTrendDown {
        display: flex;
        background-color: #FDEDEE;
        border-color: #F8A9AD;
        border-width: 2px;
        border-radius: 4px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .textTrendDown {
        display: flex;
        color: #D94C53;
        font-size: 12px;
        margin-left: 4px;
    }

    .viewTilde {
        display: flex;
        background-color: #FAFAFA;
        border-color: #CCCCCC;
        border-width: 1px;
        border-radius: 2px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .textTilde {
        display: flex;
        color: #CCCCCC;
        font-size: 12px;
        margin-left: 4px;
    }

    .viewTrendUp {
        display: flex;
        background-color: #E5F8F6;
        border-color: #7FDED2;
        border-width: 2px;
        border-radius: 4px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .textTrendUp {
        display: flex;
        color: #00BDA5;
        font-size: 12px;
        margin-left: 4px;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

`;


const FocusAreaContainer = styled.div`
    display: flex;
    background-color: #EAF0F6;
    border-color: #7C98B6;
    border-radius: 2px;
    border-width: 1px;
    padding: 12px;
    // width: ${cardWidth};
    flex-direction: row;
    align-items: center;
    height: 80 + 16;
    max-height: 80 + 16;
    margin: 4px 16px;


    .focusAreaText {
        display: flex;
        color: #516F90;
        font-size: 20px;
    }

    .focusAreaTextBold {
        display: flex;
        color: #516F90;
        font-size: 20px;
        font-weight: bold;
        margin-left: 4px;
    }

    .focusAreaExplanationText {
        display: flex;
        color: #516F90;
        font-size: 16px;
    }

    .focusAreaLeftView {
        display: flex;
        flex-direction: column;
        // width: 64px;
    }

    .focusAreaRightView {
        display: flex;
        flex-direction: column;
        // width: ${cardWidth - 12 - 12 - 64 - 12}px;
        margin-left: 12px;
    }
`;

export const FocusArea: React.FC<{ text: string; explanation: string }> = ({
                                                                               text,
                                                                               explanation,
                                                                           }) => {
    return (
        <FocusAreaContainer className={'focusAreaView'}>
            <div className={'focusAreaLeftView'}>
                {dspIcon({type: 'Info', color: '#7C98B6', size: '64px'})}
            </div>
            <div className={'focusAreaRightView'}>
                <div>
                    <div className={'focusAreaText'}>
                        {'Focus Area: '}
                        <div className={'focusAreaTextBold'}>
                            {text ? text : ''}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'focusAreaExplanationText'}>
                        {explanation ? explanation : ''}
                    </div>
                </div>
            </div>
        </FocusAreaContainer>
    );
};

function getChange(isFico?: boolean, change?: number | null | string) {
    if (isFico) {
        return `${change}%`;
    }
    if (typeof change === 'number') {
        return JSON.stringify(change);
    }
    if (typeof change === 'string') {
        return change;
    }
    return 'N/A';
}

type TrendBoxProps = {
    change: null | number;
    trend: string | null;
    scenario: string | null;
    metricValue?: string | null | number;
    isFico?: boolean;
    trendStyle: string | null;
};

const TrendBox: React.FC<TrendBoxProps> = ({
                                               change,
                                               trend,
                                               scenario,
                                               isFico,
                                               metricValue,
                                               trendStyle,
                                           }) => {


    //Tilde icon missing
    if (typeof change === 'number' && change === 0) {
        return (
            <div className={'viewTilde'}>
                <div className={'icon'}>
                    {/*<Icon.Tilde*/}
                    {/*    size="16px"*/}
                    {/*    color={'#CCCCCC'}*/}
                    {/*/>*/}
                </div>
                <div className={'textTilde'}>
                    {getChange(isFico, change)}
                </div>
            </div>
        );
    }

    if (!metricValue || !change || !trendStyle) {
        return null;
    }

    if (scenario === 'tilde' && typeof change === 'number') {
        return (
            <div className={'viewTilde'}>
                <div className={'icon'}>
                    {change > 0 ? dspIcon({type: 'TrendUp', color: '#CCCCCC'}) : dspIcon({
                        type: 'TrendDown',
                        color: '#CCCCCC'
                    })}
                </div>
                <div className={'textTilde'}>
                    {getChange(isFico, change)}
                </div>
            </div>
        );
    }

    if (trend === '-') {
        return (
            <div className={'view' + trendStyle}>
                <div className={'icon'}>
                    <Icon.TrendDown
                        size="16px"
                        color={scenario === 'error'
                            ? Theme.colors.error.text
                            : Theme.colors.success.text}

                    />
                </div>
                <div className={'text' + trendStyle}>
                    {getChange(isFico, change)}
                </div>
            </div>
        );
    }

    return (
        <div className={'view' + trendStyle}>
            <div className={'icon'}>
                <Icon.TrendUp
                    size="16px"
                    color={scenario === 'error'
                        ? Theme.colors.error.text
                        : Theme.colors.success.text}

                />
            </div>
            <div className={'text' + trendStyle}>
                {getChange(isFico, change)}
            </div>
        </div>
    );

}

type MetricValueContainerProps = {
    metricType: string | null;
    metricValue: string | null | number;
    change: number | null;
    trend: string | null;
    scenario: string | null;
    metricName: string;
    trendStyle: string | null;
};

const MetricValueContainer: React.FC<MetricValueContainerProps> = ({
                                                                       metricValue,
                                                                       change,
                                                                       trend,
                                                                       scenario,
                                                                       metricType,
                                                                       metricName,
                                                                       trendStyle
                                                                   }) => {

    return (
        <div className={'viewMetricValue'}>
            <div className={"viewMetricValueText"}>
                <div className={"metricValueText"}>
                    {getMetricValue(metricType, metricValue)}
                </div>
            </div>
            <TrendBox
                change={change}
                trend={trend}
                scenario={scenario}
                isFico={metricName === 'FICO'}
                metricValue={metricValue}
                trendStyle={trendStyle}
            />
        </div>
    );
}


type MetricNameContainerProps = {
    metricName: string;
};

const MetricNameContainer: React.FC<MetricNameContainerProps> = ({
                                                                     metricName,
                                                                 }) => {
    return (
        <div className={'viewMetricNameContainer'}>
            <div
                className={
                    metricName.length >= 18
                        ? 'metricNameTextSmallFont'
                        : 'metricNameText'
                }

            >
                {metricName}
            </div>
        </div>
    );
};


type Props = {
    metricName: string;
    metricValue: string | number | null;
    change: number | null;
    trend: string | null;
    average?: string | number;
    goal?: string | number;
    scenario: string | null;
    metricType: string | null;
    trendStyle: string | null;
};

const Card: React.FC<Props> = ({
                                   metricName,
                                   metricValue,
                                   change,
                                   trend,
                                   scenario,
                                   metricType,
                                   trendStyle,
                               }) => {
    return (
        <CardContainer className={scenario ? scenario + 'View' : ''}>
            <div className={'leftView'}>
                <MetricNameContainer metricName={metricName}/>
                <MetricValueContainer
                    metricType={metricType}
                    metricValue={metricValue}
                    change={change}
                    trend={trend}
                    scenario={scenario}
                    metricName={metricName}
                    trendStyle={trendStyle}/>
            </div>
            <div className={"rightOneView"}>
                {/*<CustomText>Right #1</CustomText>*/}
            </div>
            <div className={"rightTwoView"}>
                {/*<CustomText>Right #2</CustomText>*/}
            </div>
            <div className={"rightThreeView"}>
                {/*<SvgXml xml={ArrowRight} width="24" height="24" fill="#7C98B6" />*/}
            </div>
        </CardContainer>);
}

export default Card;