import React, {useCallback} from "react";
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";
import { alert } from "../components/Alert";
import { toast } from "../components/Toasts";
import { Message } from "twilio-chat/lib/message";
import { Channel } from "twilio-chat/lib/channel";
import { confirm } from "../components/Confirm";

const TimesEditContainer = styled.div`
    justify-self: ${props => (props.direction ? props.direction : "start")};
    margin: ${props => (props.margin ? props.margin : "0")};
`;

const OptionsContainer = styled.div`
    width: 56px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 6px #0071bc;
    display: ${props => (props.display ? props.display : "grid")};
    //grid-template-columns: auto auto auto;
    grid-template-columns: auto auto;
    grid-column-gap: 4px;
    justify-content: center;
    align-content: center;
    position: relative;
    top: ${props => (props.top ? props.top : "0")};
    right: ${props => (props.right ? props.right : "0")};
    left: ${props => (props.left ? props.left : "unset")};
`;

const OptionsIcon = styled.div`
    width: 16px;
    height: 16px;
    display: grid;
    justify-content: center;
    align-content: center;
    .svg {
        display: grid;
    }
    &:hover {
        background-color: #eaf0f6;
    }
`;

type OptionsProps = {
    message: Message;
    setOpenOptions: React.Dispatch<React.SetStateAction<boolean>>;
    openOptions: boolean;
    channel?: Channel;
    refMessageElem: React.MutableRefObject<HTMLDivElement | undefined>;
    allowOptions: boolean;
    setHideMessage?: any;
};

const MessageOptions = (props: OptionsProps) => {
    const { setOpenOptions, message, refMessageElem, openOptions, allowOptions } = props;
    const refMessageNode = refMessageElem.current;

    const deleteMessageHandler = useCallback(async () => {
        try {
            const confirmation = await confirm("Are you sure you want to archive this message?");
            if (!confirmation) {
                setOpenOptions(false);
            } else {
                if (allowOptions) {
                    let attr = Object.assign({}, message.attributes);
                    attr["archived"] = true;
                    let updateAttributes = await message.updateAttributes(attr);
                    console.log({ updateAttributes });
                    setOpenOptions(false);
                } else {
                    setOpenOptions(false);
                    await alert("Sorry, but you are not allowed to archive this message.");
                }
            }
        } catch (e) {
            console.log("ERROR deleteMessageHandler() =>", { e });
            setOpenOptions(false);
            await alert("Sorry, there has been an error. Please report it.");
        }
    }, [message, setOpenOptions]);

    if (!allowOptions) return null;

    return (
        <>
            <TimesEditContainer
                margin="0 0 0 4px"
                onClick={() => {
                    setOpenOptions(!openOptions);
                }}
            >
                {openOptions && <Icon.Times size={"16px"} color={"#7c98b6"} />}
                {!openOptions && <Icon.Edit size={"16px"} color={"#7c98b6"} />}
            </TimesEditContainer>
            <OptionsContainer display={openOptions ? "grid" : "none"}>
                <OptionsIcon
                    onClick={() => {
                        let range = document.createRange();
                        // @ts-ignore
                        range.selectNode(refMessageNode);
                        // @ts-ignore
                        window.getSelection().removeAllRanges();
                        // @ts-ignore
                        window.getSelection().addRange(range);
                        document.execCommand("copy");
                        // @ts-ignore
                        window.getSelection().removeAllRanges();
                        setOpenOptions(false);
                        toast({
                            type: "Success",
                            title: "Your text was successfully copied.",
                            content: "",
                            timeout: 1000
                        });
                    }}
                >
                    <Icon.Copy size={"12px"} />
                </OptionsIcon>
                {/*<OptionsIcon>*/}
                {/*    <Icon.Edit*/}
                {/*        size={"12px"}*/}
                {/*        onClick={async () => {*/}
                {/*            await alert("Edit message feature not available yet.");*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</OptionsIcon>*/}
                <OptionsIcon onClick={deleteMessageHandler}>
                    <Icon.Trash size={"12px"} />
                </OptionsIcon>
            </OptionsContainer>
        </>
    );
};

export default MessageOptions;