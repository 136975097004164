import moment from "moment";

const updateDispatch = (oldShift, newShift, selectValue, rescue, dispatch, state) => {

    const handleEditOrAddTemp = (currentOldShift, currentNewShift, item, hasAlert, addChange) => {
        dispatch({
            type: "UPDATE",
            payload: {
                from: {
                    driverId: currentOldShift.driverId,
                    week: moment(currentOldShift.shiftDate).weekday(),
                    id: currentOldShift.id,
                },
                to: {
                    driverId: currentNewShift.driverId,
                    week: moment(currentNewShift.shiftDate).weekday(),
                },
                item: item,
                hasAlert: hasAlert,
                addChange: addChange,
            },
        });
    }

    for (var i = 0; i < selectValue.length; i++) {
        const currentOldShift = oldShift[i];
        const currentNewShift = newShift[i];
        const currentrescue = rescue[i];
        const result = oldShift[i];
        switch (selectValue[i].method) {
            case "editRescue":
                handleEditOrAddTemp(currentOldShift, currentOldShift, currentOldShift, currentOldShift.hasAlert);
                handleEditOrAddTemp(currentrescue, currentrescue, currentrescue, false);
                break;
            case "DeletedShiftEdit":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, false, false);
                break;
            case "addTrainer":
                let driver = {};
                let shift = {};
                if (currentOldShift.isTrain) {
                    currentOldShift.trainee.forEach((trainee) => {
                        driver = state.state.find((d) => d.id == trainee.driverId);
                        shift = driver["w" + moment(currentOldShift.shiftDate).weekday()].find((r) => r.id == trainee.id);
                        shift.priorShiftRouteId = null;
                        shift.priorShiftType = "";
                        handleEditOrAddTemp(shift, shift, shift, false);
                    });
                }
                if (result.isTrain) {
                    result.trainee.forEach((trainee) => {
                        driver = state.state.find((d) => d.id == trainee.driverId);
                        shift = driver["w" + moment(result.shiftDate).weekday()].find((r) => r.id == trainee.id);
                        shift.paired = [
                            {
                                id: currentOldShift.id,
                                driverId: currentOldShift.driver.id,
                                driverName: currentOldShift.driver.name,
                                profile: currentOldShift.driver.img,
                            },
                        ];
                        shift.priorShiftRouteId = result.id;
                        shift.priorShiftType = "Trainer";
                        let msg =
                            moment().format("YYYY-MM-DD") +
                            " => Trained by " +
                            currentOldShift.driver.name +
                            " (assigned by " +
                            localStorage.friendlyName +
                            ")";
                        shift.newNote = shift.newNote + msg;
                        handleEditOrAddTemp(shift, shift, shift, false);
                    });
                }
                handleEditOrAddTemp(result, result, result, false);
                break;
            case "editNormalShift":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, false, false);
                break;
            case "editTemp":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, true, true);
                break;
            case "addTemp":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, true, true);
                break;
            case "sentHome":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, true, true);
                break;
            case "rescueShift":
                handleEditOrAddTemp(currentOldShift, currentNewShift, currentNewShift, true, true);
                break;
        }
    }
}

export default updateDispatch;