import React, {useState} from 'react'
import {SchedulesAddStateContext, Grid, SchedulesAddDispatchContext} from "../../index";
import {Button, Icon, Input, Spacer, TagInput, Text, Theme, ThirdTitle, Alert, Toggle} from '@dspworkplace/ui';
import moment from "moment";
import Modal from '../../../../../../../components/Modal';
import {Table, TableData, TableHeader} from "../../../../../../../components/Table";
import {Link, Tag} from "../../../../../../../components/UI";
import Driver from '../../../../../../../components/People';
import {useForm} from "react-hook-form";
import styled from 'styled-components';
import ShiftBlock from "../../../../../../../components/ShiftBlock";
import DayPicker from '../../../../../../../components/Calendar/day';

const CreateSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    padding: 16px;
    border-top: 1px solid ${Theme.colors.info.text};
    // max-width: 1064px;
    width: 100%;

    position: sticky;
    bottom: 0;

    &.fixed {
        position: fixed;
        bottom: 0;
    }

    > h3 {
        font-weight: normal;
    }
`;

const ConflictRoutes = () => {
    const {state}= React.useContext(SchedulesAddStateContext);
    const schedulesAddDispatchContext = React.useContext(SchedulesAddDispatchContext);
    const { register, handleSubmit, errors, getValues } = useForm({mode:'onSubmit', defaultValues: {}});
    const {effectiveDate} = getValues();

    const onSubmit = async (data) =>
    {
        schedulesAddDispatchContext.dispatch({type:'setEffectiveDate',date:moment(data.effectiveDate).utc().format('YYYY-MM-DD')});
        schedulesAddDispatchContext.dispatch({type:'setIsRouteConflictModalOpen',open:false});
        return false;
    }

    const noSubmit = async () =>
    {
        schedulesAddDispatchContext.dispatch({type:'setConflictStatus',status:false})
        schedulesAddDispatchContext.dispatch({type:'setIsRouteConflictModalOpen',open:false});
        return false;
    }

    const errorMessages = {
        effectiveDate: {
            required: 'You need to provide an effective date'
        }
    }
    return (
        <Modal
            title={'Conflict shift'}
            visible={true}
            removeHeader={true}
            setVisible={()=>schedulesAddDispatchContext.dispatch({type:'setIsRouteConflictModalOpen',open:false})}
            closeOnOverlay={false}
            width={'50%'}
        >
            <div>
                <header><h3 class="sc-iyvyFf ifQfeY">Would you like to remove the following driver(s) from their current, assigned Schedule?</h3></header>
                    <Table>
                        <li style={{overflow: 'hidden'}}>
                            <Table width={'100%'} style={{height: '45vh', overflow: 'auto', display: 'block'}}>
                                { state.conflictRoutes.length > 0 && state.conflictRoutes.map((item,index)=>
                                        <li key={index}>
                                            <TableData width='270px'>
                                                <Driver
                                                    name={item[0].driverName}
                                                    jobTitle={item[0].title}
                                                    img={item[0].img}
                                                />
                                            </TableData>
                                            <TableData width='520px'>
                                                <div>
                                                    {item.map((s, k) =>
                                                            <Spacer inline right={2} bottom={2} key={k}>
                                                                <ShiftBlock
                                                                    type={s.type}
                                                                    hour={s.date+' ['+s.time+']'}
                                                                    background={s.bg}
                                                                    color={s.color}
                                                                />
                                                            </Spacer>
                                                        )
                                                    }
                                                </div>
                                            </TableData>
                                        </li>
                                    )
                                }
                            </Table>
                        </li>
                    </Table>
                                      
                    
                    <form onSubmit={handleSubmit(onSubmit)}>                    
                    { state.information.checkbox == "true" && <><Spacer inline right={3}/><Alert.Error
                    title='Its a Priority Schedule. So effective date will not applicable here.'
                    content=''/><Spacer bottom={2}/></>}
                    <CreateSection>
                        <ThirdTitle>

                            <Spacer inline right={5}>
                                Effective Date
                                <DayPicker
                                name='effectiveDate'
                                ref={register({required:true})}
                                label=''
                                disabled={ state.information.checkbox == "true" ? "disabled" : ''}
                                defaultValue={state.information.checkbox == "true" ? moment(state.information.startDate).utc().format('YYYY-MM-DD') : '' }
                                size='small'
                                error={errors.effectiveDate && errorMessages.effectiveDate[errors.effectiveDate.type]}
                                valid={!errors.effectiveDate && effectiveDate && effectiveDate.length > 0 }
                            />
                            </Spacer>

                        </ThirdTitle>
                        <Button
                            type="primary"
                            style={{ marginLeft: "53%",marginRight: "10px"}}
                            onClick={()=>{noSubmit()}}
                        >
                            No
                        </Button>
                        <Button
                            type="primary"
                            Type='submit'
                        >
                            Yes
                        </Button>
                    </CreateSection>
                    </form>
            </div>
        </Modal>)
};

export default React.memo(ConflictRoutes);
