import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Icon, Input, Theme} from "@dspworkplace/ui";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import moment from "moment";
import {Action} from "../../pages/Scheduler/Common/UI";


const Container = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    min-width: 260px;
    top: 100%;
    background: #FFF;
    z-index: 9999 !important;
    border-radius: ${Theme.defaultRadius};
    
    > .dayPicker {
        width: 100%;
        border:1px solid ${Theme.colors.neutrals.silver};
        box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}19;
        font-family: ${Theme.font.main};
        border-radius: ${Theme.defaultRadius};
        
        .DayPicker {
            &-Caption {
                text-align:center;
                color: ${Theme.colors.neutrals.medium};
                font-size: ${Theme.font.small.size};
                line-height: ${Theme.font.small.lineHeight};
                margin: 0;
                padding: 16px 0 8px;
            }
            
            &-Month {
                margin: 0;
                width: 100%;
            }
            
            &-Day {
                font-size: ${Theme.font.small.size};
                color: ${Theme.colors.neutrals.medium};
                
                &--today {
                    color: ${Theme.colors.secondary};
                }
                
                &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                    background: unset;
                    color: ${Theme.colors.info.text}; 
                    position: relative;
                    
                    &::before {
                        position: absolute;
                        border-radius: 50%;
                        display: block; 
                        content: "";
                        height: 32px;
                        width: 32px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -53%);
                        z-index: -1;
                        background: ${Theme.colors.info.bg};
                        border: 1px solid ${Theme.colors.info.border};
                    }
                }
            }
            
            &-Weekday {
                font-weight: bold;
            }
        }
    }
`


const handleDateFormat = (date,format) => {
    const formats = [
        'MM/DD/YYYY'
    ];
    const utc = moment.utc(date, formats).format();
    const formatted = moment(date, formats).format(format);
    return {utc:utc,original:formatted};
}

const mergeRefs = refs => {
    return value => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value)
            } else if (ref != null) {
                ref.current = value
            }
        })
    }
};


const NavBarContainer = styled.div`
    position:absolute;
    display: flex;
    align-content: left;
    justify-content: space-between;
    width: 100%;
    top: 12px;
    padding: 0 8px;

    >svg {
        cursor:pointer;
    }
`;
const NavBar = ({onPreviousClick,onNextClick,className}) => {

    return (
        <div className={className}>
            <NavBarContainer>
                <Icon.ArrowLeft size='18px' color={Theme.colors.info.border} onClick={() => onPreviousClick()} />
                <Icon.ArrowRight size='18px' color={Theme.colors.info.border} onClick={() => onNextClick()} />
            </NavBarContainer>
        </div>
    )
}

const InputDate = React.forwardRef(({name,defaultValue,useControllers,...props},ref)=> {
    const [date,setDate] = useState({utc:'',original:''});
    const [error,setError] = useState('');
    const [open,setOpen] = useState(false);

    const innerRef = useRef();
    const inputRef = useRef();

    const innerFormat = 'MM/DD/YYYY';
    const separator = '/';
    const format = 'YYYY-MM-DD';

    const handleDayClick = (day,{selected}) => {
        if(!selected){
            const date = handleDateFormat(day,innerFormat);
            setDate(date);
            setError('')
            setOpen(false)
        }
    }

    const dateMask = (e) => {
        if(open) setOpen(false);
        const v = e.target.value
                .replace(/\D/g,'')
                .replace(/(\d{2})(\d)/,`$1${separator}$2`)
                .replace(/(\d{2})(\d)/,`$1${separator}$2`)
                .replace(/(\d{4})\d+?$/,'$1');
        setDate({...date,original:v})
    }

    const handleBlur = (e) => {
        if(!e.target.value){
            return
        }

        const date = moment(e.target.value,innerFormat,true);
        const isValid = date.isValid();

        if(!isValid) setError('Wrong format '+innerFormat)
        if(isValid) {
            setError('');
            const newDate = handleDateFormat(date.format(innerFormat),innerFormat);
            setDate(newDate);
        }
    }

    const handlePopup = (e) => {
        if ( !innerRef.current.contains( e.target ) ){
            setOpen( false );
        }
    }

    const solveInitialData = (inputValue) => {
        if(!inputValue) {return};
        if(inputValue === 'reset') {
            setDate({utc:'',original:''});
            inputRef.current.value = '';
            return;
        }

        if(inputValue){
            const originalDate= moment(inputValue,format,true);
            if(originalDate.isValid()){

                const newDate = handleDateFormat(originalDate.format(innerFormat),innerFormat);
                if(newDate.utc != date.utc){
                    setDate(newDate);
                    setError('')
                }
            }else{
                setError('Invalid default date')
            }
        }
    }

    const navigateBetweenDays = (direction) => {
        if(error) return;
        let newUtc,newOriginal;

        if(direction === 'next'){
            newUtc = moment(date.original,innerFormat).add(1,'days');
            newOriginal = moment(date.original).add(1,'days');
        }else{
            newUtc = moment(date.original,innerFormat).subtract(1,'days');
            newOriginal = moment(date.original).subtract(1,'days');
        }

         setDate({utc:newUtc.utc().format(),original:newOriginal.format(innerFormat)})
    }

    useEffect(()=>{
        inputRef.current.value = date.utc;
        if(props.onChange) props.onChange(date);
    },[date])

    useEffect(()=>{
         solveInitialData(defaultValue)
    },[defaultValue])


    useEffect(()=>{
        document.addEventListener('click',handlePopup,false);
        const inputValue = inputRef.current.value  ? inputRef.current.value : defaultValue ;
        if(inputValue) solveInitialData(inputValue)

        return () => document.removeEventListener('click',handlePopup,false);
    },[])

    useEffect(()=>{
        if(!inputRef.current) return;
        //console.log('input',inputRef.current)
        if(inputRef.current.value !== date.utc){
            solveInitialData(inputRef.current.value)
        }
    })

    const originalDateIsValid = moment(date.original,innerFormat,true).isValid();

    let controllerStyle = {};
    if(useControllers) {
        controllerStyle.display = 'inline-flex'
        controllerStyle.alignItems = 'flex-end'
    }

    return (
        <div ref={innerRef} style={{display:'inline-block'}}>
            <div style={{position:'relative'}}>
                    <div style={controllerStyle}>
                    {useControllers &&
                        <Action Type='button' onClick={(e)=>{e.stopPropagation();navigateBetweenDays('prev')}}>
                            <Icon.ArrowLeft  size='12px' color={Theme.colors.info.border} style={{verticalAlign: 'text-top'}}/>
                        </Action>
                    }
                        <Input
                            {...props}
                            value={date.original}
                            onChange={dateMask}
                            onBlur={handleBlur}
                            error={error ? error : props.error ? props.error : ''}
                            onClick={()=> {
                                if (!props.readOnly && !props.disabled)
                                    setOpen(true)
                            }}
                        >
                            <Icon.Calendar size='24px' style={{position:'absolute', right:0}}  />
                        </Input>
                    {useControllers &&
                        <Action Type='button' onClick={(e)=>{e.stopPropagation(); navigateBetweenDays('next')}}>
                            <Icon.ArrowRight  size='12px' color={Theme.colors.info.border} style={{verticalAlign: 'text-top'}}/>
                        </Action>
                    }
                    </div>
                {open &&
                    <Container>
                        <DayPicker
                            selectedDays={originalDateIsValid ? new Date(date.original) : null}
                            month={originalDateIsValid ? new Date(date.original) : null}
                            onDayClick={handleDayClick}
                            showWeekNumbers={true}
                            navbarElement={NavBar}
                            className='dayPicker'
                        />
                    </Container>
                }
            </div>
            <input type='hidden' name={name} ref={mergeRefs([inputRef,ref])}  />
        </div>
    )
})


export default InputDate;
