import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch, useTrackedState } from '../store';
import styled from "styled-components";
import { HeaderContainer } from './NewDirectMessageAdd';
import { CloseIcon, useChatContext } from 'stream-chat-react';
import { FlexScroll, Grid, Input, TagInputContainer, Text } from '../styles';
import { TagInput, Toggle, async, CustomDropdown } from '@dspworkplace/ui';
import { initialState, reducer } from '../CGCOptions';
import Member from './Member';
import { getEmployeesTwilio, getItemFromStorage } from '../utils';
import Loading from '../../components/Loading';
import PictureUploader from '../../Chat/PictureUploader';
import { useForm } from 'react-hook-form';
import { getChatCompanyId, getChatTeam, upload } from '../../Auth';
import { engine } from '../../Utilities';
import { alert } from '../../components/Alert';
import { mimeTypes } from '../../patterns';

function NewGroupChannelAdd({ isMenConstract, isPage, isGroupAddModalOpen, setGroupAddModalOPen }) {
    const dispatch = useDispatch();
    const { chatClient, isNewChannel, currentChannel } = useTrackedState();
    const [newChannelModalState, newChannelModalDispatch] = useReducer(reducer, initialState);
    const { setActiveChannel } = useChatContext();
    const {
        loadingUsers,
        errorMessage,
        matchUsers,
        usersToAdd,
        loadingCreating,
        tagsDefaultAutoUsers,
        defaultUsers,
        channelNotification
    } = newChannelModalState;
    var [refresh, setRefresh] = useState(1);
    const [groupName, setGroupName] = useState("");
    const [groupDescription, setDescription] = useState("");

    const [imageUrl, setImageUrl] = useState(currentChannel?.data?.image);
    const [imageFile, setImageFile] = useState(currentChannel?.data?.image);
    const [roles, setRoles] = useState([]);
    const [selectedRolse, setRoleSelection] = useState([])
    const [mute, setMute] = useState(false);

    const handleTagChange = data => {
        setTimeout(() => {
            newChannelModalDispatch({ type: "SET_MATCHES", filterSearch: data });
        }, 50);
    };

    const tagsDefaultAutoUsersStreamIndentity = tagsDefaultAutoUsers.map(tag => tag.value);
    const currentUserCount = `Total ${matchUsers.filter(user => !tagsDefaultAutoUsersStreamIndentity.includes(user.streamIdentity)).length+defaultUsers.length} (${defaultUsers.length} default) members`;

    useEffect(() => {
        const abortController = new AbortController();
        newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
        getEmployeesTwilio()
            .then(async result => {
                if (result) {
                    try {
                        var matchUser = await result?.users?.filter(function (cv) {
                            return !result.defaultAutoUsers.find(function (e) {
                                return e.userId == cv.userId;
                            });
                        });
                        await newChannelModalDispatch({
                            type: "SET_USERS",
                            users: result.users,
                            loadingUsers: false,
                            roles: result.roles,
                            skills: result.skills,
                            stations: result.stations,
                            schedules: result.schedules,
                            teams: result.teams,
                            chatVariable: result.chatVariable,
                            defaultAutoUsers: result.defaultAutoUsers,
                            matchUser: [],
                        });
                    } catch (e) {
                        console.error({ e });
                    }
                }

            })
            .catch(error => {
                console.error({ error });
                newChannelModalDispatch({
                    type: "SET_USERS",
                    users: [],
                    loadingUsers: false,
                    roles: [],
                    skills: [],
                    stations: [],
                    schedules: [],
                    teams: [],
                    chatVariable: [],
                    defaultAutoUsers: [],
                });
            });

        return () => {
            abortController.abort();
        };
    }, [newChannelModalDispatch, refresh]);

    useEffect(() => {
        const abortController = new AbortController();
        if (chatClient) {
            let currentRoles = getItemFromStorage('roles');
            if (Array.isArray(currentRoles)) {
                setRoles(currentRoles);
            }
        }
        return () => {
            abortController.abort();
        };
    }, [chatClient]);

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function randomString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    const createChannel = async () => {
        let userList = []
        if (defaultUsers?.filter(i => i.streamIdentity === chatClient.userID))
            userList = [chatClient.userID, ...matchUsers.map(item => { return item.streamIdentity }), ...defaultUsers.map(item => { return item.streamIdentity })];
        else
            userList = [chatClient.userID, ...matchUsers.map(item => { return item.streamIdentity }), ...defaultUsers.map(item => { return item.streamIdentity })];

        userList = await [...new Set(userList)];
        console.log(userList, userList?.length)

        if (imageFile) {
            if(mimeTypes["images"].includes(imageFile?.type)){
                let formData = new FormData();
                formData.append('image', imageFile);
                formData.append('name', imageFile.name);
                var result = await upload(formData);
            } else {
                alert({ text: "Failed to upload profile photo.! Upload jpg, png image format.", btnText: "Cancel" });
                return false;
            }
        }
        let channelId = `group-company-${getChatCompanyId()}-${randomString(30)}`;
        let filterResultValue = newChannelModalState.filterCurrentSearch.map(e => e.value);
        let roles = newChannelModalState.roles.filter(e => filterResultValue.includes(e.name));
        let skills = newChannelModalState.skills.filter(e => filterResultValue.includes(e.name));
        let stations = newChannelModalState.stations.filter(e => filterResultValue.includes(e.code));
        filterResultValue = newChannelModalState.filterCurrentSearch.map(e => e.value.split("||")[0]);
        let schedules = newChannelModalState.schedules.filter(e => filterResultValue.includes(String(e.id)));
        filterResultValue = filterResultValue.map(e => e.replace('NAME', "").replace('EMAIL', ''));
        let specialUsers = newChannelModalState.users.filter(e => {
            return filterResultValue.includes(e.email) || filterResultValue.includes(e.friendlyName);
        });
        let teams = newChannelModalState.teams.filter(e => filterResultValue.includes(e.name));
        let chatVariable = newChannelModalState.chatVariable.filter(e => filterResultValue.includes(e.name));
        let params = {
            roles: roles,
            skills: skills,
            stations: stations,
            schedules: schedules,
            chatVariable: chatVariable,
            teams: teams,
            filterResult: newChannelModalState.filterCurrentSearch,
            members: specialUsers,
            company: getChatCompanyId(),
            sid: channelId,
            friendlyName: groupName,
            env: process.env.NODE_ENV,
            channelNotification,
        }
        await engine().post('/api/bot/channel/group',params);
        if (groupName?.length != 0) {
            const arrayOfChunks = chunkArray(userList, 99);

            if(arrayOfChunks.length > 0) {
                const newChannel = await chatClient.channel('group', channelId, {
                    name: groupName,
                    members: arrayOfChunks[0],
                    teams: getChatTeam(),
                    pinUser: [],
                    image: imageFile ? result?.data?.ObjectURL : '',
                    description: groupDescription,
                    read_only_roles: selectedRolse,
                    mute_for_all: mute
                });
                await newChannel.create();

                if(arrayOfChunks.length > 1) {
                    for (let i = 1; i < arrayOfChunks.length; i++) {
                        newChannel.addMembers(arrayOfChunks[i]);
                    }
                }
                await setActiveChannel(newChannel);
            }

            await newChannelModalDispatch({
                type: "RESET_NEW_CHANNEL_INFORMATION",
            });
            await setGroupAddModalOPen(false);
            return true;
        } else {
            newChannelModalDispatch({
                type: "ERROR_CHANNEL_NAME",
                errorMessage: `group name cannot be empty.`,
                name: ""
            });
        }

    };

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize);
          chunks.push(chunk);
        }
        return chunks;
    }

    const addMember = useCallback(
        userIdentifier => {
            console.log(userIdentifier);
            newChannelModalDispatch({ type: "ADD_MEMBER", userId: userIdentifier });
        },
        [newChannelModalDispatch]
    );

    const removeMember = useCallback(
        userIdentifier => {
            console.log(userIdentifier);
            newChannelModalDispatch({ type: "REMOVE_MEMBER", userId: userIdentifier });
        },
        [newChannelModalDispatch]
    );

    async function undoSelection() {
        setRefresh(++refresh)
        setDescription('')
        setImageFile(currentChannel?.data?.image)
        setImageUrl(currentChannel?.data?.image)
        setGroupName('')
    }

    async function selectAll() {
        try {
            // var addUser = await matchUsers.slice(0, 100 - defaultUsers?.length - 1)
            var addUser = await matchUsers;
            var matchUser = await matchUsers?.filter(function (cv) {
                return !addUser.find(function (e) {
                    return e.streamIdentity == cv.streamIdentity;
                });
            });
            newChannelModalDispatch({ type: "SELECT_ALL", usersToAdd: addUser, matchUser: matchUser })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{
            width: isMenConstract && isPage ? '95%' : isMenConstract && !isPage ? '91%' : isPage ? '82%' : "65%",
            float: "left", backgroundColor: '#F5F6FA', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', height: '100%'
        }} >
            <HeaderContainer>
                <Text color={'#000000'} fontWeight={"500"} margin="0px" fontSize="18px" >
                    Create Group</Text>
                <div style={{ cursor: 'pointer', }} onClick={() => setGroupAddModalOPen(false)} >
                    <CloseIcon />
                </div>
            </HeaderContainer>

            {loadingUsers || loadingCreating ?
                <div style={{ display: "flex", height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }} >
                    <Loading height={'60px'} width="60px" />
                </div>
                :
                <div style={{ padding: '16px', backgroundColor: 'white' }} >
                    <Grid margin={'12px'} justifyContent="center" >
                        {!isNewChannel ?
                            <PictureUploader
                                name={"channelImage"}
                                id={"channelImage"}
                                image={imageUrl}
                                enable={false}
                                height="70px"
                                width="70px"
                                setImageUrl={setImageUrl}
                                imageUrl={imageUrl}
                                setImageFile={setImageFile}
                            />
                            :
                            <PictureUploader
                                name={"channelImage"}
                                id={"channelImage"}
                                image={imageUrl == "" ? null : imageUrl}
                                height="70px"
                                width="70px"
                                setImageUrl={setImageUrl}
                                imageUrl={imageUrl}
                                setImageFile={setImageFile}
                            />
                        }
                    </Grid>
                    <Input
                        type="text"
                        name="channelName"
                        label="Name"
                        placeholder='Group Name'
                        borderRadius="7px"
                        defaultValue={newChannelModalState.name}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    {errorMessage && errorMessage.length > 0 && (
                        <Grid color="#D94C53" fontSize="12px">
                            {errorMessage}
                        </Grid>
                    )}
                    <Input
                        type="text"
                        name="channelName"
                        label="Name"
                        placeholder='You can write a description about the channel...'
                        borderRadius="7px"
                        defaultValue={groupDescription}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ marginTop: '10px' }}
                    />
                    <div style={{ marginTop: '10px' }} >
                        <TagInputContainer width="auto" borderRadius="7px" templateColumns="100% auto">
                            <TagInput
                                options={newChannelModalState.filterArray}
                                size="big"
                                onChange={handleTagChange}
                                name={'tags'}
                                placeholder="Find by name, email, skill, role, station and schedule:"
                            />
                        </TagInputContainer>
                    </div>

                    <Grid templateColumns="auto auto" borderRadius="7px" style={{ marginTop: '10px' }} >
                        {/* <Input type="search" name="channelName" label="Name" ref={inputRef}/> */}
                        <CustomDropdown
                            name='permissions'
                            placeholder='Groups selected below can Read Only'
                            // ref={dropdownRef}
                            // placeholder='All'
                            multiple={true}
                            options={roles.map(e => ({ name: e.roleName, value: e.roleName }))}
                            // size={'big'}
                            // defaultValue={selectedRolse}
                            onChange={(e) => {
                                setTimeout(() => {
                                    setRoleSelection(e)
                                }, 50)
                            }}
                        />
                    </Grid>

                    <Grid style={{ marginTop: '10px' }} >
                        <Toggle
                            name={'mute'}
                            onChange={() => setMute(!mute)}
                            on={mute}
                            singleText='Mute Channel Notification For All Users'
                        />
                    </Grid>

                    {/* <div style={{ marginTop: '10px', padding: '5px', border: 'solid 1px darkseagreen', width: 'fit-content' }} >
                        <Text fontSize={'14px'} color='darkseagreen' >
                            Comming soon : Mute Channel </Text>
                    </div> */}

                    {/* <div style={{ marginTop: '10px' }} >
                        <TagInputContainer width="auto" borderRadius="7px" templateColumns="100% auto">
                            <TagInput
                                // options={newChannelModalState.filterArray}
                                size="big"
                                // onChange={handleTagChange}
                                // name={'tags'}
                                placeholder="Groups selected below can Read Only"
                                disable={true}
                            />
                        </TagInputContainer>
                    </div> */}

                    {/* <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                        <Toggle
                            name={"pin"}
                            // onChange={() => pinChannel()}
                            on={false}
                        />
                        <Text margin='12px' fontSize={'16px'} >
                            Mute Channel Notification For All Users</Text>
                    </div> */}

                    <Text
                        margin="30px 0 15px 0"
                        fontSize="16px"
                        color="#0071BC"
                    >
                        Start chat with</Text>
                    <Grid rowGap="8px" templateRows="auto auto" height={"auto"}>
                        <FlexScroll height={"526px"} width="100%" key="second">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text margin="10px" fontSize="14px" color="#0071BC">Current Members : {currentUserCount}</Text>
                                <Text margin="10px" fontSize="14px" color="gray" style={{ borderBottom: '1px solid', borderBottomColor: 'gray', cursor: 'pointer' }}
                                    onClick={() => undoSelection()}  >
                                    Undo selection</Text>
                            </div>
                            {Array.isArray(defaultUsers) && defaultUsers.map((item, index) => {
                                // if (item.streamIdentity == chatClient.userID) {
                                //     return <></>;
                                // }
                                let isNewUser = true;
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity === item.streamIdentity)) {
                                    isNewUser = false;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        removeMember={() => removeMember(item.streamIdentity)}
                                        selectedUser={item}
                                        remove={tagsDefaultAutoUsers.filter(e => e.name === item.friendlyName).length === 0}
                                        index={index}
                                        isNewUser={isNewUser}
                                    />
                                );
                            })}
                            {Array.isArray(matchUsers) && matchUsers.map((item, index) => {
                                // if (item.streamIdentity == chatClient.userID) {
                                //     return <></>;
                                // }
                                console.log(usersToAdd);
                                let isNewUser = true;
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity === item.streamIdentity)) {
                                    isNewUser = false;
                                }
                                if(tagsDefaultAutoUsers.filter(e => e.value == item.streamIdentity).length > 0){
                                    return <></>;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        removeMember={() => removeMember(item.streamIdentity)}
                                        selectedUser={item}
                                        remove={tagsDefaultAutoUsers.filter(e => e.name === item.friendlyName).length === 0}
                                        index={index}
                                        isNewUser={isNewUser}
                                    />
                                );
                            }).reverse()}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text margin="10px" fontSize="14px" color="#0071BC">Select New Members</Text>
                                <Text margin="10px" fontSize="14px" color="gray" style={{ borderBottom: '1px solid', borderBottomColor: 'gray', cursor: 'pointer' }} onClick={() => selectAll()}  >
                                    Select all users
                                </Text>
                            </div>
                            {Array.isArray(matchUsers) && matchUsers.map((item, index) => {
                                if (item.streamIdentity == chatClient.userID) {
                                    return <></>;
                                }
                                if (usersToAdd.length > 0 && usersToAdd.find(e => e.streamIdentity === item.streamIdentity)) {
                                    return <></>;
                                }
                                return (
                                    <Member
                                        key={index}
                                        item={item}
                                        type="createGroup"
                                        callback={() => addMember(item.streamIdentity)}
                                        index={index}
                                    />
                                );
                            })} */}
                        </FlexScroll>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'absolute', right: '40px', bottom: '10px', }} >
                        <div style={{ backgroundColor: (groupName && groupName.length > 0 && (matchUsers.length + defaultUsers.length) > 0) ? '#0071BC' : '#CDCED0', height: '35px', width: '150px', display: 'flex', justifyContent: 'center', borderRadius: '5px', cursor: (groupName && groupName.length > 0 && (matchUsers.length + defaultUsers.length) > 0) ? 'pointer' : 'none' }}
                            onClick={() => (groupName && groupName.length > 0 && (matchUsers.length + defaultUsers.length) > 0) ? createChannel() : null}  >
                            <Text textAlign="center" color='white' fontWeight="500" >Create</Text>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default NewGroupChannelAdd