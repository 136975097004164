import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useForm} from "react-hook-form";
import HTML5Backend from 'react-dnd-html5-backend'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import update from 'immutability-helper';
import Parser from 'html-react-parser';
import {Alert, Button, Icon, Input, Spacer, SubTitle, Text, Theme, ThirdTitle, Title, Toggle} from "@dspworkplace/ui";
import Store, {VanAutoAssignmentProvider} from "./Context";
import {cancel, fetchAllSettings, saveAllSettings, loadFixtures} from "./api";
import toast from "../../../components/Toasts/toast";
import {Link} from "../../../components/UI";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";

const Form = styled.form`
    > div:first-child {
        width: 60%;
        float: left;
        padding-right: 80px;
    }

    > div:last-child {
        float: right;
        width: 40%;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
`;

const RuleQuestions = ({ questions, data, register}) => {
    if (!questions.length)
        return null;

    return (
        <Spacer top={3} key={data.id.toString()}>
            {questions.map((question, key) =>
                <Spacer key={key} top={key > 0 ? 3 : 0}>
                    <Input
                        ref={register}
                        key={data.id.toString()}
                        name={`question.'${data.id.toString()}'.'${question.id.toString()}'`}
                        label={question.label}
                        defaultValue={question.value}
                        Required={false}
                        size='small'
                    />
                </Spacer>
            )}
        </Spacer>
    );
}

const Arrow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    background-color: ${Theme.colors.info.shadow};

    &:after {
        position: absolute;
        bottom: -6px;
        left: -5px;
        content: "\\25BC";
        color: ${Theme.colors.info.shadow};
    }
`;

export const CardBox = styled(Spacer).attrs(() => ({
    all: 3
}))`
    background: #fff;
    border: 1px solid ${Theme.colors.info.shadow};

    &:empty::after {
        content: "Nothing enabled here";
        font-size: smaller;
        color: ${Theme.colors.neutrals.medium};
        font-family: inherit;
    }
`;

export const CardItem = styled(CardBox)`
    display: flex;
    align-items: center;
    cursor: move;
    margin-bottom: 8px;
    border-radius: ${Theme.defaultRadius};

    &:hover {
        border-color: ${Theme.colors.info.border};
    }

    ${props => props.isDragging ? `
    opacity: .5;
    ` : ''}
`;

const Card = ({ id, text, index, moveCard, type, state }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop: (item) => {
            // updateVanAutoAssignmentSettingOrder(api, state.setting.id, item,state[item.type] );
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: type, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));
    return (
        <div ref={ref}>
            <CardItem isDragging={isDragging} data-handler-id={handlerId}>
                <Icon.Move size='16px'/>
                <Spacer left={3} inline>
                    <Text>{text}</Text>
                </Spacer>
            </CardItem>
        </div>
    );
}

const VanAutoAssignment = () => {
    return (
        <VanAutoAssignmentProvider>
            <section>
                <Title>Van Auto Assignment</Title>
                <Spacer top={3} />
                <Text>Automatically assign vehicles with smart rules to best use your resources.</Text>
                <Spacer top={5} />
                <StationSelector/>
                <Spacer bottom={5}/>
                <VanAutoAssignmentForm/>
            </section>
        </VanAutoAssignmentProvider>
    );
}

const VanAutoAssignmentForm = () => {
    const [state, dispatch] = useContext(Store);
    const {register, handleSubmit} = useForm();
    const [selectedStation, ] = useSelectedStation();

    const fetchVanAutoAssignment = async () => {
        const response = await fetchAllSettings(selectedStation);

        if (response)
            dispatch({
                type: "ADD",
                payload: {
                    packageRules: response.packageRules,
                    vehicleOrder: response.vehicleOrder,
                    driverAffinity: response.driverAffinity,
                    conflict: response.conflict,
                    setting:response.setting
                }
            });
        else
            loadFixtures(selectedStation).then(fetchVanAutoAssignment);
    }

    const switchVanAutoAssignment = async() => {
        dispatch({
            type: "IS_SETTING",
            payload: !state.setting.isEnable
        });
    };

    const switchVanAutoAssignmentsettings = async(id,type) => {
        let tempRef = state[type][id];
        if(type === 'driverAffinity'){
            if(tempRef){
                state[type][id] = {
                    ...state[type][id],
                    isAffinityEnable:!tempRef.isAffinityEnable
                }
            }
        } else {
            if(tempRef){
                state[type][id] = {
                    ...state[type][id],
                    isEnable:!tempRef.isEnable
                }
            }
        }
        dispatch({ type: "REFRESH" });
    }

    const handleVanAutoAssignment = async( data, evt ) => {
        let groups = ['packageRules', 'vehicleOrder', 'driverAffinity', 'conflict'];
        for (let i = 0; i < groups.length; i++) {
            if (data[groups[i]]) {
                for (let id in data[groups[i]]) {
                    data[groups[i]][id].order = state[groups[i]].findIndex(r => "'"+r.id+"'" == id) + 1;
                }
            }
        }
        try {
            let response = await saveAllSettings(data,selectedStation);
            toast({
                type: response === true ? 'success' : 'error',
                title: response === true ? 'Van Auto Assignment setting successfully saved.' : 'Van Auto Assignment setting failed to save. Check your Van Auto Assignment setting configuration.',
                timeout: 4000,
                useIcon: true,
                useClose: true
            });
        } catch(e) {}
    }

    const moveCard = (type) => (dragIndex, hoverIndex) => {
        const dragData = state[type][dragIndex];
        let tempData = update(state[type], {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragData],
            ],
        });
        switch (type) {
            case 'vehicleOrder':
                dispatch({ type: "VEHICLE_ORDER", payload: tempData });
                break;
            case 'packageRules':

               dispatch({ type: "PACKAGE_RULES", payload: tempData });
               break;
            case 'driverAffinity':
               dispatch({ type: "DRIVER_AFINITY", payload: tempData });
               break;
            case 'conflict':
               dispatch({ type: "CONFLICT", payload: tempData });
               break;
            default:
                break;
        }
    };

    const renderCard = (card, index, type) => {
        return (<Card key={card.id} index={index} id={card.id} moveCard={moveCard(type)} text={card.label}  type={type} state={state}/>);
    };

    useEffect(() => {
        if (parseInt(selectedStation) > 0)
            fetchVanAutoAssignment();

        return cancel;
    }, [selectedStation]);

    return !state.setting
    ? <LoadingWrapper><Loading/></LoadingWrapper>
    : <Spacer bottom={10}>
        <Toggle
            ref={register}
            name='isSettingEnabled'
            on={state?.setting?.isEnable}
            singleText='Enable Van Auto Assignment'
            onChange={switchVanAutoAssignment}
            help={<Spacer left={11}>
                When enabled, you will also have the option to upload the day's picklist on the Load Out screen.
                <br/>This will provide both the staging location and corporate package count.
            </Spacer>}
        />
        <Spacer top={10} style={{position: 'relative'}}>
            {state.setting && !state.setting?.isEnable && <LoadingWrapper style={{height: "calc(100% - 40px)",zIndex: 9}}/> }
            <Form onSubmit={handleSubmit(handleVanAutoAssignment)}>
                <div>
                    <SubTitle>Van Assignment Rules</SubTitle>
                    <Spacer top={3} />
                    <Text>Applied before all other preferences, containing business logic to make the most out of your fleet.</Text>

                    { state.packageRules.map((data, key) =>
                        <div key={key}>
                            <input type="hidden" ref={register} name={`rule.'${data.id.toString()}'.order`} value={key + 1}/>
                            <Spacer top={5}>
                                <Toggle
                                    ref={register}
                                    name={`rule.'${data.id.toString()}'.isEnabled`}
                                    on={data.isEnable}
                                    singleText={data.label}
                                    onChange={() => switchVanAutoAssignmentsettings(key, 'packageRules')}
                                />
                            </Spacer>
                            <Spacer left={11} top={5}>
                                <Text as={'div'}>{Parser(data.description, {
                                    replace: node => {
                                        if (node.name === 'alert')
                                            return <Alert.Warning {...node.attribs}/>

                                        return node;
                                    }
                                })}</Text>
                                <RuleQuestions questions={data.questions} data={data} register={register}/>
                            </Spacer>
                        </div>
                    )}

                    <Spacer top={10} />
                    <SubTitle>Van Assignment Order</SubTitle>
                    <Spacer top={3} />
                    <Text>Defines the order of what operational vehicles will be assigned first. If a rule is enabled and is listed above another enabled rule, it will take precedence and ensure that all vans of that type are dispatched first.</Text>
                    <Spacer top={2} />
                    { state.vehicleOrder.map((data, key) =>
                        <Spacer top={3} key={key}>
                            <input type="hidden" ref={register} name={`vehicleOrder.'${data.id.toString()}'.order`} value={key + 1}/>
                            <Toggle
                                ref={register}
                                name={`vehicleOrder.'${data.id.toString()}'.isEnable`}
                                on={data.isEnable}
                                singleText={data.label}
                                onChange={() => switchVanAutoAssignmentsettings(key, 'vehicleOrder')}
                            />
                        </Spacer>
                    )}

                    <Spacer top={10} />
                    <SubTitle>Van/Driver Affinity</SubTitle>

                    { state.driverAffinity.map((data, key) =>
                        <Spacer top={5} key={key}>
                            <input type="hidden" ref={register} name={`driverAffinity.'${data.id.toString()}'.order`} value={key + 1}/>
                            <Toggle
                                ref={register}
                                name={`driverAffinity.'${data.id.toString()}'.isEnable`}
                                on={data.isAffinityEnable}
                                singleText= {data.label}
                                onChange={() => switchVanAutoAssignmentsettings(key, 'driverAffinity')}
                                help={<Spacer left={11}>
                                    Assign vans by driver preference. If the primary van assignment is not operational,{' '}
                                    it will look to assign the secondary. <br/>
                                    You can manage these settings here:{' '}
                                    <Link
                                        style={{fontSize: 'inherit'}}
                                        to="/company-management/vehicles/van-affinity"
                                    >van affinity</Link>
                                </Spacer>}
                            />
                        </Spacer>
                    )}

                    <Spacer top={10} />
                    <SubTitle>Conflict Resolution</SubTitle>
                    <Spacer top={3} />
                    <Text>
                        When one vehicle is assigned to two or more drivers on the same day, these rules will be applied in
                        {' '}order in an attempt to resolve the conflict. At least one rule must be enabled.
                    </Text>

                    { state.conflict.map((data, key) =>
                        <Spacer top={5} key={key}>
                            <input type="hidden" ref={register} name={`conflict.'${data.id.toString()}'.order`} value={key + 1}/>
                            <Toggle
                                ref={register}
                                name={`conflict.'${data.id.toString()}'.isEnable`}
                                on={data.isEnable}
                                singleText= {data.label}
                                onChange={() => switchVanAutoAssignmentsettings(key, 'conflict')}
                                help={<Spacer left={11}>{data.description}</Spacer>}
                            />
                        </Spacer>
                    )}

                    <Spacer bottom={5}/>
                    <Button type='primary'>Save</Button>
                </div>

                <div>
                    <SubTitle>Execution Order</SubTitle>
                    <Spacer top={3}/>
                    <Spacer left={5} style={{position: 'relative'}}>
                        <Arrow/>
                        <Text>
                            Any required driver's Skill is the highest precedence. For example, if a driver does not
                            {' '}possess a Step Van Skill, they will not be assigned a Step Van. Also, van "Status" in
                            {' '}the Vehicle Inventory must be set to "Active" or the vehicle will not be eligible to
                            {' '}be assigned to a driver.
                        </Text>
                        <Spacer top={5} />
                        <DndProvider backend={HTML5Backend}>
                            <ThirdTitle>Package Rules</ThirdTitle>
                            <Spacer top={3}/>
                            <CardBox>
                                {state.packageRules.filter(d => d.isEnable).map((data, i) =>
                                    renderCard(data, i,'packageRules')
                                )}
                            </CardBox>

                            <Spacer top={5}/>
                            <ThirdTitle>Vehicle Orders</ThirdTitle>
                            <Spacer top={3}/>
                            <CardBox>
                                {state.vehicleOrder.filter(d => d.isEnable).map((data, i) =>
                                    renderCard(data, i,'vehicleOrder')
                                )}
                            </CardBox>

                            <Spacer top={5}/>
                            <ThirdTitle>Driver Affinity</ThirdTitle>
                            <Spacer top={3}/>
                            <CardBox>
                                {state.driverAffinity.filter(d => d.isAffinityEnable).map((data, i) =>
                                    renderCard(data, i,'driverAffinity')
                                )}
                            </CardBox>

                            <Spacer top={5}/>
                            <ThirdTitle>Conflict</ThirdTitle>
                            <Spacer top={3}/>
                            <CardBox>
                                {state.conflict.filter(d => d.isEnable).map((data, i) =>
                                    renderCard(data, i,'conflict')
                                )}
                            </CardBox>
                        </DndProvider>
                        <Spacer top={5}/>
                        <Text>Last order of precedence: operational vehicles are randomly assigned to properly Skilled drivers.</Text>
                    </Spacer>
                </div>
            </Form>
        </Spacer>
    </Spacer>;
}

export default VanAutoAssignment;