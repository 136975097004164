import React, {useCallback, useEffect, useState} from "react";
import {toast} from '../components/Toasts';
import {useSelectedStation} from "../components/StationSelector/StationPicker";
import useApiV2Base, {apiBaseDefaultPropsInterface, paginatorInterface, STATUS} from "../components/Grid/apiBase";

type LoadInterface = {
    pageInfo: paginatorInterface
    sorter: string
    station_id: number,
    groupByFilter: string
}

interface gridApiInterface {
    load: ({pageInfo,sorter,station_id,groupByFilter}: LoadInterface) => Promise<any>
    cancelRequest: () => void
    state: React.ComponentState
    default: apiBaseDefaultPropsInterface
}

const useGridApi = (props: gridApiInterface) => {
    const {load, cancelRequest, state} = props
    const [data, setData] = state
    
    const [selectedStation] = useSelectedStation();
    const {setStatus,pageInfo,sorter,status, setSorter,filters,setFilters, setPageInfo, groupByFilter, setGroupByFilter} = useApiV2Base(props.default)

    useEffect(() => {
        let page = false;
        let pageNumber = '0';
        if (filters != "") {
            const paramsArray = filters.split('&').map(param => {
                const [key, value] = param.split('=');
                return { key, value };
            });
            
            for (let i = 0; i < paramsArray.length; i++) {
                if (paramsArray[i].key === 'page') {
                    page = true;
                    pageNumber = paramsArray[i].value;
                }
            }
            if (!page) {
                setPageInfo(0, 10);
            }
        }
        setStatus(STATUS.PENDING);
        load({ pageInfo, sorter, station_id: selectedStation, filters, groupByFilter }).then(response => {
            if (page && pageNumber !== '0') {
                const newData = [...data, ...response.data];
                setData(newData);
            } else {
                setData(response.data);
            }
    
        }).catch(error => {
            //do something with the error
            console.log('error',error)
        }).finally(() => setStatus(STATUS.READY))

        return () => {
            if(cancelRequest) cancelRequest()
        }
    },[filters,cancelRequest, load, pageInfo, selectedStation, setData, setStatus, sorter, groupByFilter])

    const Add = useCallback((addApi: (newRow) => Promise<any>,newRow, errorMessage: string)=>{
        newRow.stationId = selectedStation;
        let _INDEX = -1;
        setData(previousState => {
            newRow._STATUS = 'LOADING-ROW'
            previousState.push({...newRow,id:0})
            _INDEX = previousState.length -1
            return previousState
        })
        setStatus(STATUS.PENDING);

        delete newRow._STATUS
        return addApi(newRow).then( response => {
            const result = response.data;
            const newItem = {...result, _STATUS: 'INSERT-SUCCESS'}
            setData(previousState => {
                if(_INDEX >= 0) previousState[_INDEX] = newItem;
                return previousState
            })
            return result
        }).catch(error => {
            setData(previousState => {
                previousState.splice(newRow._INDEX,1)
                return previousState
            })
            const apiResponse = error?.response?.data?.message || 'No response returned from api'
            const content = error?.message === 'Unable to save the data because you change the page before the connection ends' ? 'a' : apiResponse
            toast({
                type: "error",
                title: errorMessage,
                content: content,
                timeout: 5000,
                useClose: true
            })
        }).finally(() => setStatus(STATUS.READY))

    },[selectedStation, setData, setStatus])

    const Update = useCallback((updateAPI: Promise<any>, index, row, oldRow, errorMessage: string, updateResult: boolean = true)=>{
        row.stationId = selectedStation;
        setData( previousState => {
            if (index == null) {
                const currentData = [...previousState]
                Object.keys(currentData).forEach(function (key) {
                    if (row.id == currentData[key].id) {
                        index = key;
                    }
                });
            }
            previousState[index] = row
            return previousState
        })
        setStatus(STATUS.PENDING)
        updateAPI.then(response => {
            if (updateResult) {
                
                const result = response.data;
                setData(previousState => {
                    if (index == null) {
                        const currentData = [...previousState]
                        Object.keys(currentData).forEach(function (key) {
                            if (row.id == currentData[key].id) {
                                index = key;
                            }
                        });
                    }
                    result.parentId = row.parentId;
                    result.parentLabel = row.parentLabel;
                    previousState[index] = result
                    return previousState
                })
            }
        }).catch(error => {
            if(updateResult){
                setData( previousState => {
                    previousState[index] = oldRow
                    return previousState
                })
            }
            const apiResponse = error?.response?.data?.message || 'No response returned from api'
            const content = error?.message === 'Unable to save the data because you change the page before the connection ends' ? 'a' : apiResponse
            toast({
                type: "error",
                title: errorMessage,
                content: content,
                timeout: 5000,
                useClose: true
            })
        }).finally(() => setStatus(STATUS.READY))

        if (row.isArchive && row.isArchive == true) {
            setStatus(STATUS.PENDING);
            setData((previousState) => {
                const currentData = [...previousState]
                let tempKey = null;
                Object.keys(currentData).forEach(function (key) {
                    if (row.id == currentData[key].id) {
                        tempKey = key;
                    }
                });
                
                if (tempKey) {
                    currentData.splice(tempKey,1)
                }
                return currentData
            })
            setStatus(STATUS.READY);
        }

    },[selectedStation, setData, setStatus])

    const Delete = useCallback((deleteApi: (id: number) => Promise<any>, row, errorMessage: string) => {
        setData((previousState) => {
            const currentData = [...previousState]
            let tempKey = null;
            Object.keys(currentData).forEach(function (key) {
                if (row.id == currentData[key].id) {
                    tempKey = key;
                }
            });
            
            if (tempKey) {
                currentData.splice(tempKey,1)
            }
            return currentData
        })

        setStatus(STATUS.PENDING);

        deleteApi(row.id).then(response => {
            if(!response) return;
        }).catch(error => {
            setData(previousState => {
                row._STATUS = 'DELETE-ERROR'
                previousState.splice(row._INDEX,0,row)
                return previousState
            })

            const apiResponse = error?.response?.data?.message || 'No response returned from api'
            const content = error?.message === 'Unable to delete the data because you change the page before the connection ends' ? 'a' : apiResponse

            toast({
                type: "error",
                title: errorMessage,
                content: content
            });
        }).finally(()=> setStatus(STATUS.READY))

    },[setData, setStatus])


    const Copy = useCallback((copyApi: (id: number) => Promise<any>, row, errorMessage: string) => {
        let _INDEX = -1;
        setData(previousState => {
            previousState.push({_STATUS: 'LOADING-ROW',id:0})
            _INDEX = previousState.length -1
            return previousState
        })
        setStatus(STATUS.PENDING);

        copyApi(row.id).then(response => {
            if(!response) return;
            setData((previousState) => {
                previousState[_INDEX] =  {_STATUS: 'INSERT-SUCCESS', ...response.data}
                return previousState
            })
        }).catch(error => {
            const apiResponse = error?.response?.data?.message || 'No response returned from api'
            const content = error?.message === 'Unable to copy the data because you change the page before the connection ends' ? 'a' : apiResponse

            toast({
                type: "error",
                title: errorMessage,
                content: content
            });
        }).finally(()=> setStatus(STATUS.READY))

    },[setData, setStatus])

    return {
        data,
        status,
        Add,
        Update,
        Delete,
        Copy,
        setSorter,
        setFilters,
        filters,
        setPageInfo,
        pageInfo,
        setGroupByFilter
    }
}

export default useGridApi