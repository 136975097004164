import {dialogPromise, portalPromise} from "../../../../components/Dialog";
import React, {useState} from "react";
import {Input, TagInput, Spacer, Button, Alert, Text,ThirdTitle,SubTitle} from '@dspworkplace/ui';
import Modal from "../../../../components/Modal";
import {useForm} from "react-hook-form";
import API from "./api";
import {confirm} from "../../../../components/Confirm";
import Loading from "../../../../components/Loading";
import {Table, TableData, TableHeader,TableRow} from "../../../../components/Table";
import {Tag} from "../../../../components/UI";
import Tooltip from "../../../../components/Tooltip";
import {Question} from "../../../../components/UI";

const StationMapForm = ({success,cancel,stationMapObj,idStationMap,station}) => {
    const defaultLocations = stationMapObj && stationMapObj.locations ? stationMapObj.locations.map((l)=>({value:l,name:l})) : [];
    let defaultValues = {};
    if(stationMapObj){
        defaultValues ={name:stationMapObj.name,locations:stationMapObj.locations};
    }

    const [state,setState] = useState({status:idStationMap ? 'PENDING' : 'READY',error:'',locations:defaultLocations});
    const {register,handleSubmit,reset,errors} = useForm({defaultValues:defaultValues});

    React.useEffect(()=>{
      if(idStationMap){
            API.getStationMap(idStationMap).then(
                resolve => {
                    const stationMap = resolve.data.data.StationMap;
                    const locations = stationMap.locations.map((l)=>({value:l,name:l}));
                    reset({name:stationMap.name,locations:stationMap.locations});
                    setState({...state,status:'READY',locations:locations})
                }
            )
      }
    },[idStationMap,stationMapObj])

    const onSubmit = (data) => {
        const stationMapIdentifier = idStationMap ? idStationMap : (stationMapObj ? stationMapObj.id : false);

        setState({...state,status:'SUBMITTING'})
        data.station = station.id;
        if(data.locations) data.locations = JSON.stringify(data.locations);

        API.addMapLocation(data, stationMapIdentifier).then(
            resolve => {
                if(resolve) success(resolve);
            },
            error=>{
                setState({...state,status:'CREATE_ERROR',error:error})
            }
        )
    }

    return (
        <Modal
            width={'340px'}
            visible={true}
            title={'New Station Map'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === 'PENDING'
                ?
                    <Loading style={{height: 80,margin:'50px auto',display:'block'}} />
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(stationMapObj && (stationMapObj.name == null || stationMapObj.name == '')) ?
                        <Input
                            name={'name'}
                            label={'Reference Location'}
                            ref={register()}
                            errors={errors.location && errors.location.name}
                        />
                        :
                        <Input
                            name={'name'}
                            label={'Reference Location'}
                            ref={register({ required: 'Field Required' })}
                            errors={errors.location && errors.location.name}
                        />
                    }
                    <Spacer top={4} />
                    <TagInput
                        name={'locations'}
                        ref={register}
                        label={'Staging Location'}
                        options={state.locations}
                        canCreate={true}
                    />
                    {state.status === 'CREATE_ERROR' &&
                    <Spacer top={5}>
                        <Alert.Error
                            title={`Unable to save the station Map`}
                            content={'Try to refresh the page, if the problem persist please contact support.'} />
                    </Spacer>
                    }
                    <Spacer top={4} style={{display:'flex',justifyContent: 'flex-end'}}>
                        <Button
                            type={'primary'}
                            disabled={state.status == 'SUBMITTING' ? true : false}
                        >
                            {state.status == 'SUBMITTING' ? 'Saving Station Map' : 'Save Station Map'}
                        </Button>
                    </Spacer>
                </form>
            }
        </Modal>
    )
}

const openStationMapForm = ({station,idStationMap,stationMapObj}) => {
    return dialogPromise((success, cancel)=>{
        return <StationMapForm
            success={success}
            cancel={cancel}
            idStationMap={idStationMap}
            stationMapObj={stationMapObj}
            station={station}
        />
    })
}

const ImportSpreedHelper = ({success,cancel}) =>(
    <Modal
        width={'540px'}
        visible={true}
        title={'Using the Station Map Import Tool'}
        closeOnOverlay={true}
        setVisible={cancel}
    >
        <Spacer top={2} />
            <Text>To quickly import your station map, first create your station in a spreadsheet where the columns are the Reference Locations (Lane, Door, Pad, etc.) and under each reference location, list its assigned Staging locations.</Text>
        <Spacer top={3} />
            <ThirdTitle>Example: </ThirdTitle>
            <img style={{width:'100%'}} src={'https://images.dspworkplace.com/stationMapSpreed.png'} />
        <Spacer top={3} />
            <Text>If your station does not use reference locations, simply list all staging locations under a single column labeled by the station's 4 character code.</Text>
            <Text>Next, select the entire station you just created, and then copy the data:</Text>
        <Spacer top={3} />
            <img style={{width:'100%'}} src={'https://images.dspworkplace.com/stationMapSpreedCopy.png'} />
        <Spacer top={2} />
            <Text>Once you have copied the data, proceed to the Station Map located in: Company Management &gt; Stations &gt; Manage, then select the Import XLS button.</Text>
        <Spacer top={3} />
            <img style={{width:'100%'}} src={'https://images.dspworkplace.com/stationMap.png'} />
        <Spacer top={2} />
            <Text>Next, simply paste the station data you copied into the Station Map Import box below:</Text>
        <Spacer top={3} />
            <img style={{width:'100%'}} src={'https://images.dspworkplace.com/stationMapImport.png'} />
        <Spacer top={2} />
            <Text>Once complete, it should process the data and create the Station Map automatically:</Text>
        <Spacer top={3} />
            <img style={{width:'100%'}} src={'https://images.dspworkplace.com/stationMapImported.png'} />
        <Spacer top={2} />
            <Text>If it looks good, be sure to scroll down to the bottom and press the Save button.</Text>
            <Text>If there are issues, simply make the changes in your spreedsheet then copy and paste again</Text>
        <Spacer top={2} />
    </Modal>
)

const openImportSpreedHelper = () => {
    return portalPromise((success, cancel)=>{
        return <ImportSpreedHelper
            success={success}
            cancel={cancel}
        />
    })
}

const StationMapSpreadCopyForm = ({success,cancel,station}) => {
    const [temp,setTemp] = useState({data:[],status:'PENDING'});

    const handlePaste = (e) => {
       e.preventDefault();
       const text = e.clipboardData.getData('text');
       let textRows = text.split('\n');
       let header = textRows.slice(0,1)[0]?.split('\t');
       if(header.length > 0){
           header = header.filter((h) => h.length > 0)
       }

       const table = header.map((h,k)=>{
           return {
               name:h,
               locations: textRows.reduce((acc,t,i)=>{
                   if(i > 0 && t.trim().length > 0){
                       const line = t.split('\t');
                       const data = line[k].trim();
                       if(data && data.length > 0){
                           acc.push(data);
                       }
                   }
                   return acc;
               },[])
           }
       })
        setTemp({...temp,data:table,status:'PROCESSED'})
    }

    return (
        <Modal
            width={'640px'}
            visible={true}
            title={'Station Map Import'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
         <div>
             <Input
                 onPaste={handlePaste}
                 style={{width:'590px'}}
                 placeholder={'Copy all Staging and Reference locations and paste here.'}
             />
             <Spacer top={3} bottom={2}>
                 <span style={{cursor:'pointer',display:'block'}} onClick={()=>openImportSpreedHelper()}>
                     <ThirdTitle style={{display: 'inline'}}>Need Help </ThirdTitle>
                     <Question>?</Question>
                 </span>
             </Spacer>

             {temp.status === 'CREATING' &&
                <Loading style={{height: 80,margin:'50px auto',display:'block'}} />
             }

             {temp.status === 'PROCESSED' &&
             <Table width={'100%'}>
                 <TableHeader
                     headers={[
                         {label: 'Reference Location', width: '25%'},
                         {label: 'Staging Location', width: '75%'},
                     ]}
                 />
                 {temp.data.map((t,k)=>{
                    return (
                        <TableRow key={k} style={{flexWrap: 'wrap',position:'relative'}} >
                            <TableData width='25%'>
                                {t.name}
                            </TableData>
                            <TableData width='75%' style={{display:'grid',gridTemplateColumns: `repeat(4,minmax(40px, auto) ) `,gridGap:8}}>
                                {t.locations.map((f,k)=><Tag key={k} style={{marginRight:4}} >{f}</Tag>)}
                            </TableData>
                        </TableRow>
                    )
                 })}
             </Table>
             }

             {temp.status === 'PROCESSED' &&
             <Spacer top={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                 <Button
                     type={'primary'}
                     onClick={()=>{
                         setTemp({...temp,status:'CREATING'})

                         API.addMultipleMapLocation(temp.data.map((d)=>{ d.locations=JSON.stringify(d.locations); return d}),station).then(
                             resolve => {
                                 success(true);
                             },
                             error => {
                                 setTemp({...temp,status:'PENDING'})
                             }
                         )
                     }}
                 >
                     Save Data
                 </Button>
             </Spacer>
             }
         </div>
        </Modal>
    )
}

const openStationMapSpreadCopyForm = ({station}) => {
    return dialogPromise((success, cancel)=>{
        return <StationMapSpreadCopyForm
            success={success}
            cancel={cancel}
            station={station}
        />
    })
}

const openStationMapArchiveForm = async (name) => {
    return confirm({
        icon: true,
        text: `Do you really want to archive "${name}"?`
    });
}

export {openStationMapForm,StationMapForm,openStationMapArchiveForm,openStationMapSpreadCopyForm}