import React, {useCallback, useEffect, useReducer, useState} from "react";
import {useChatTrackedState, useDispatch, useTrackedState} from "./store";
import ModalWindow from "./Modal";
import {Panel, TabList} from "./Tabs";
import {Tabs, useTabState} from "@bumaga/tabs";
import {Channel} from "twilio-chat/lib/channel";
import DMCurrentChannels from "./DMCurrentChannels";
import styled from "styled-components";
import DMNewDirectMessages from "./DMNewDirectMessages";
// import DMDevelopment from "./DMDevelopment";
import {FetchedTwilioUser, FilterTagArrayType} from "./types";
import {filterDataForFetchedTwilioUsers} from "./utils";
import {getChatIdentifier} from "../Auth";
import {LoadingView} from "../components/Loading";
import {showErrorToast} from "../Utilities";

const Content = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 40px auto;
    grid-row-gap: 20px;
`;

type FilterArraySearchType = {
    channel: Channel;
    identity: string;
};

export type DirectMessageModalState = {
    userChannels: Array<Channel>;
    matchedUserChannels: Array<Channel>;
    matchedSearchUserChannels: Array<FilterArraySearchType>;
    searchUserNames: Array<FilterTagArrayType>;
    newUsers: Array<FetchedTwilioUser>;
    newMatchedUsers: Array<FetchedTwilioUser>;
    filterForNewUsers: Array<FilterTagArrayType>;
    loading: boolean;
    identities: Array<string>;
};

const initialState: DirectMessageModalState = {
    userChannels: [],
    matchedUserChannels: [],
    matchedSearchUserChannels: [],
    searchUserNames: [],
    newUsers: [],
    newMatchedUsers: [],
    filterForNewUsers: [],
    loading: true,
    identities: []
};

export type DirectMessageModalAction =
    | {
    type: "SET_USER_CHANNELS";
    channels: Array<Channel>;
    searchFilter: Array<FilterTagArrayType>;
    identities: Array<string>;
    matchedSearchUserChannels: Array<FilterArraySearchType>;
}
    | { type: "ADD_USER_CHANNEL", identity: string, matchedSearchUserChannel: FilterArraySearchType, searchFilter: FilterTagArrayType, channel: Channel }
    | { type: "MATCH_USERS_CHANNELS"; data: Array<FilterTagArrayType> }
    | { type: "SET_NEW_USERS_LOADING"; loading: boolean }
    | { type: "NEW_MATCHED_USERS"; data: Array<FilterTagArrayType> }
    | {
    type: "SET_NEW_USERS";
    users: Array<FetchedTwilioUser>;
    stations: Array<string>;
    roles: Array<{ name: string, roleName: string }>;
    skills: Array<string>;
    schedules: Array<string>;
};

function reducer(state: DirectMessageModalState, action: DirectMessageModalAction): DirectMessageModalState {
    switch (action.type) {
        case "SET_USER_CHANNELS":
            return {
                ...state,
                identities: action.identities,
                userChannels: action.channels,
                matchedUserChannels: action.channels,
                searchUserNames: action.searchFilter,
                matchedSearchUserChannels: action.matchedSearchUserChannels
            };
        case "ADD_USER_CHANNEL":
            return {
                ...state,
                identities: [action.identity, ...state.identities],
                userChannels: [action.channel, ...state.userChannels],
                matchedUserChannels: [action.channel, ...state.userChannels],
                searchUserNames: [action.searchFilter, ...state.searchUserNames],
                matchedSearchUserChannels: [action.matchedSearchUserChannel, ...state.matchedSearchUserChannels]
            };
        case "MATCH_USERS_CHANNELS":
            if (action.data.length === 0) {
                return {
                    ...state,
                    matchedUserChannels: state.matchedSearchUserChannels.map(item => item.channel)
                };
            }
            let filterInput = action.data.map(item => item.value);
            return {
                ...state,
                matchedUserChannels: state.matchedSearchUserChannels
                    .filter(item => filterInput.includes(item.identity))
                    .map(item => item.channel)
            };
        case "SET_NEW_USERS":
            return {
                ...state,
                loading: false,
                newUsers: action.users,
                newMatchedUsers: action.users,
                filterForNewUsers: [
                    ...action.roles.map(item => ({name: "Role: " + item.roleName, value: item.name})),
                    ...action.skills.map(item => ({name: "Skill: " + item, value: item})),
                    ...action.stations.map(item => ({name: "Station: " + item, value: item})),
                    ...action.schedules.map(item => ({name: "Schedule: " + item, value: item})),
                    ...action.users.map(item => ({
                        name: "Name: " + item.friendlyName,
                        value: "NAME" + item.friendlyName
                    })),
                    ...action.users.map(item => ({
                        name: "Email: " + item.email,
                        value: "EMAIL" + item.email
                    }))
                ]
            };
        case "NEW_MATCHED_USERS":
            return {...state, newMatchedUsers: filterDataForFetchedTwilioUsers(action.data, state.newUsers)};
        case "SET_NEW_USERS_LOADING":
            return {...state, loading: action.loading};
        default:
            return state;
    }
}

const Tab = ({children}) => {
    const {onClick, isActive} = useTabState();
    return (
        <h6 onClick={onClick} className={isActive ? "tabActive" : ""} data-tab={"tab"}>
            {children}
        </h6>
    );
};

function DMModal() {
    const dispatch = useDispatch();
    const {client, duoChannels, directMessageModal} = useTrackedState();
    const [directMessageState, directMessageDispatch] = useReducer(reducer, initialState);
    const {identities} = directMessageState;
    const [loading, setLoading] = useState(true);

    const cancelModal = useCallback(() => {
        dispatch({type: "CLOSE_DIRECT_MESSAGE_MODAL"});
    }, [dispatch]);

    useEffect(() => {
        const abortController = new AbortController();

        async function setDuoChannels() {
            try {
                setLoading(true);
                console.time('Users Duo Channels');
                let searchFilter: Array<FilterTagArrayType> = [];
                let currentIdentities: Array<string> = [];
                let duoChannelWithIdentity: Array<FilterArraySearchType> = [];

                let userDescriptors = await Promise.all(duoChannels.map(async channel => {
                    let userDescriptor = await channel.getUserDescriptors();
                    let member = userDescriptor.items.find(item => item.identity !== getChatIdentifier());
                    if (member) {
                        let searchFilter = {name: member.friendlyName, value: member.identity};
                        let identity = member.identity;
                        let channelSearchUser = {channel: channel, identity: member.identity}
                        return {searchFilter, identity, channelSearchUser};
                    }
                    return null;
                }));

                for (let i = 0; i < userDescriptors.length; i++) {
                    let userDescriptor = userDescriptors[i];
                    if (userDescriptor) {
                        searchFilter.push(userDescriptor.searchFilter);
                        currentIdentities.push(userDescriptor.identity);
                        duoChannelWithIdentity.push(userDescriptor.channelSearchUser);
                    }
                }
                currentIdentities.push(getChatIdentifier()!);
                directMessageDispatch({
                    type: "SET_USER_CHANNELS",
                    channels: duoChannels,
                    identities: currentIdentities,
                    searchFilter: searchFilter,
                    matchedSearchUserChannels: duoChannelWithIdentity
                });
                setLoading(false);
                console.timeEnd('Users Duo Channels');
            } catch (e) {
                await showErrorToast(e, 'Error fetching Duo Channels.')
            }
        }

        if (client) {
            setDuoChannels().then();
        }
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!directMessageModal) {
        return null;
    }

    if (loading) {
        return (
            <ModalWindow width={"700px"} cancelModal={cancelModal} title={"Direct Messages"}>
                <LoadingView containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"}/>
            </ModalWindow>
        );
    }

    if (identities.length === 0) {
        return (
            <ModalWindow width={"700px"} cancelModal={cancelModal} title={"Direct Messages"}>
                <Content>
                    <Tabs>
                        <TabList>
                            <Tab>Find</Tab>
                        </TabList>
                        <Panel>
                            <DMNewDirectMessages
                                directMessageState={directMessageState}
                                directMessageDispatch={directMessageDispatch}
                                cancelModal={cancelModal}
                            />
                        </Panel>
                    </Tabs>
                </Content>
            </ModalWindow>
        );
    }

    return (
        <ModalWindow width={"700px"} cancelModal={cancelModal} title={"Direct Messages"}>
            <Content>
                <Tabs>
                    <TabList>
                        <Tab>Search Existing</Tab>
                        <Tab>Find New</Tab>
                        {/*{process.env.NODE_ENV === "development" && <Tab>Development</Tab>}*/}
                    </TabList>
                    <Panel>
                        <DMCurrentChannels
                            directMessageDispatch={directMessageDispatch}
                            directMessageState={directMessageState}
                            cancelModal={cancelModal}
                        />
                    </Panel>
                    <Panel>
                        <DMNewDirectMessages
                            directMessageState={directMessageState}
                            directMessageDispatch={directMessageDispatch}
                            cancelModal={cancelModal}
                        />
                    </Panel>
                    {/*{process.env.NODE_ENV === "development" && (*/}
                    {/*    <Panel>*/}
                    {/*        <DMDevelopment/>*/}
                    {/*    </Panel>*/}
                    {/*)}*/}
                </Tabs>
            </Content>
        </ModalWindow>
    );
}

function DMModalContainer() {
    const {directMessageModal} = useChatTrackedState();
    if (!directMessageModal) {
        return null;
    }
    return <DMModal/>
}

export default DMModalContainer;
