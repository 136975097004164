import React, {useEffect, useState} from "react";
import {portalPromise} from "../../../../../components/Dialog";
import {Button, Icon, Input, Spacer, ThirdTitle, Toggle, Theme, TagInput, CheckBox} from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import Loading from "../../../../../components/Loading";
import {useForm, useFieldArray} from "react-hook-form";
import {confirmPortal} from "../../../../../components/Confirm";
import {toast} from "../../../../../components/Toasts";
import styled from 'styled-components'
import VehiclePlanTypeCombobox from "../../../../../domain/vehicle_maintenance_plan/components/TypeCombobox";

export const CustomErrorMessage = styled.span`
    position:absolute;
    bottom:-40px;
    font-size:12px;
    color: ${Theme.colors.error.text};
`

const CategoryForm = ({ data, type = 'category', success = () => {}, cancel = () => {}}) => {
    const defaultValues = typeof data === 'object' ?? data
    const categoryDefaultValues = { category: "", standardPricing:"", isLastServiceDateRequired:true, isLastMileServiceRequired:true };
    const isPromise = typeof data === 'object' && typeof data.then === 'function' ;
    const [state,setState] = useState({status :isPromise ? 'pending'  : 'ready'});
    const [rules, setRules] = useState([]);

    const { register, handleSubmit, errors, reset, getValues, control } = useForm({
        defaultValues: {
        children: [categoryDefaultValues]
      }
    })


    const { fields, append, remove } = useFieldArray({ control, name: "children", keyName:"idx" });

    const onSubmit = (data) => {
        if (type == "subcategory") {
            success({ label: data.label, parent: data.parent.value, children: data.children, rules: rules });
        } else {
            success({
                label: data.label,
                rules: rules,
                children: data.children?.map(child => ({...child, standardPricing: parseFloat(child.standardPricing)})),
            });
        }
    }

    useEffect(() => {
        if(!isPromise) return
        data.then((resolve) => {
            reset(resolve);
            if(resolve.rules) setRules(resolve.rules)
            setState({status:'ready'})
        }).catch(err => {
            toast({
                type: "error",
                title: 'Edit Vehicle Maintenance Type',
                content: err,
                useClose: true,
                timeout: 5000
            });
            cancel()
        })

    },[cancel, data, isPromise, reset])

    useEffect(() => {
        if(fields.length === 0){
            append(categoryDefaultValues);
        }
    }, [fields])
    return (
        <Modal
            width={"790px"}
            visible={true}
            title={!data ? "New Vehicle Maintenance Type" : "Editing Vehicle Maintenance Type"}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === "pending" && <Loading style={{ height: 80, display: "block", margin: "0 auto" }} />}

            {state.status === "ready" && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type={"hidden"} name={"id"} />
                        <Input
                            type={"text"}
                            name={"label"}
                            label={"Type Name"}
                            ref={register({ required: "Required field" })}
                            error={errors.label && errors.label.message}
                            Required
                        />
                        <Spacer bottom={5}></Spacer>
                        {fields.map((child, index) => (
                            <div key={child.idx}>
                                {data && child.id && <input type="hidden" ref={register()} name={`children[${index}].id`} value={child.id} />}
                                <Spacer inline={true} top={2}>
                                    <Input
                                        label="Category"
                                        ref={register({ required: "Required field" })}
                                        size="medium"
                                        name={`children[${index}].category`}
                                        defaultValue={`${child.category}`}
                                        error={
                                            errors.children?.[index]?.category &&
                                            errors.children[index].category.message
                                        }
                                    />
                                    <Spacer inline={true} left={5}/>
                                    <Input
                                        size={"small"}
                                        name={`children[${index}].standardPricing`}
                                        label="Standard Pricing"
                                        defaultValue={`${child.standardPricing}`}
                                        ref={register({ valueAsNumber: true })}
                                        type="number"
                                        step={".01"}
                                        error={
                                            errors.children?.[index]?.standardPricing &&
                                            errors.children[index].standardPricing.message
                                        }
                                    />
                                    <Spacer inline={true} left={5} top={7} style={{ verticalAlign: "top" }}>
                                    <CheckBox
                                        name={`children[${index}].isLastMileServiceRequired`}
                                        ref={register()}
                                        style={{ float: "right" }}
                                        options={[{ label: "Last Mile Service Required", value: true }]}
                                        defaultValues={[Boolean(child.isLastMileServiceRequired)]}
                                    />
                                    <CheckBox
                                        name={`children[${index}].isLastServiceDateRequired`}
                                        ref={register()}
                                        style={{ float: "right" }}
                                        options={[{ label: "Last Service Date Required", value: true }]}
                                        defaultValues={[Boolean(child.isLastServiceDateRequired)]}
                                        error={
                                            errors.children?.[index]?.isLastServiceDateRequired &&
                                            errors.children[index].isLastServiceDateRequired.message
                                        }
                                    />
                                    </Spacer>
                                    <Spacer
                                        style={{ verticalAlign: "top", position: "relative", top: "0" }}
                                        inline={true}
                                        left={5}
                                        top={1}
                                    >
                                        <Icon.Trash
                                            size="20px"
                                            style={{ position: "relative", top: "30px" }}
                                            color={Theme.colors.error.text}
                                            onClick={() => remove(index)}
                                        />
                                    </Spacer>
                                </Spacer>
                            </div>
                        ))}

                        <Spacer top={5}>
                            <Button
                                size={"small"}
                                Type={"button"}
                                onClick={() => {
                                    append(categoryDefaultValues);
                                }}
                            >
                                Add Category
                            </Button>
                        </Spacer>
                        <Spacer top={5} />
                        <Button type="primary" style={{ display: "block", marginLeft: "auto" }}>
                            Save Category
                        </Button>
                    </form>
                </>
            )}
        </Modal>
    );
};

export const openCategoryArchiveForm = async (name) => {
    return confirmPortal({
        icon: true,
        text: `Do you really want to archive Type "${name}"`
    });
}

export const openCategoryForm = (data) => {
    return portalPromise((success, cancel)=>{
        return <CategoryForm
            data={data}
            success={success}
            cancel={cancel}
        />
    })
}

export const openSubCategoryForm = (data) => {
    return portalPromise((success, cancel)=>{
        return <CategoryForm
            data={data}
            type={'subcategory'}
            success={success}
            cancel={cancel}
        />
    })
}

