import React, {useEffect, useRef, useState} from "react";
import {Channel} from "twilio-chat/lib/channel";
import ChannelIcon from "./ChannelIcon";
import ChannelTitle from "./ChannelTitle";
import ChannelSubtitle from "./ChannelSubtitle";
import {TextInformationContainer} from "./styles";
import {Button, Icon} from "@dspworkplace/ui";
import styled from "styled-components";
import {useDispatch as useClientDispatch} from "./store";
import Loading from "./Loading";
//@ts-ignore
import {setMessagesConsumed, updateActiveChannelClose} from "./utils";
import {IS_DEV} from "../utils";
import {engine, showErrorToast, showToast, getCompanyId, getUserId} from '../Utilities';

const ChannelHeaderContainer = styled.div`
    height: 56px;
    max-height: 56px;
    display: grid;
    width: 300px;
    grid-template-columns: 16px 40px auto 16px 16px;
    grid-column-gap: 8px;
    grid-template-rows: 40px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    border-bottom: 1px dashed #7c98b6;
`;

const CancelButton = styled(Button)`
    width: 12px;
    height: 12px;
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 0 6px #0071bc;
        border-radius: 2px;
    }
`;

type Props = {
    channel?: Channel;
    channelDispatch?: (arg: any) => void;
    kind?: string;
    goBack?: boolean;
    goBackHandler?: () => void;
};

function ChannelHeader(props: Props) {
    //@ts-ignore
    const {channel, channelDispatch, kind = "NORMAL", goBack, goBackHandler} = props;
    //@ts-ignore
    const dispatch = useClientDispatch();
    const mounted = useRef(false);
    const [showSettings, setShowSettings] = useState(true);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState('default');

    const updateChannelNotification = async () => {
        const channelName = channel?.friendlyName
        try {
            let response = await engine().post("/api/twilio/channel/notification", {
                companyId: getCompanyId(),
                userId: getUserId(),
                channelSid: channel?.sid,
                notificationLevel: open === 'default' ? 'muted' : 'default'
            });
            setOpen(open === 'default' ? 'muted' : 'default');
        await showToast({
            type: open === 'default' ? 'error' : 'success',
            title: open === 'default' ? `${channelName} Notification Have Been Silenced` : `${channelName} Notifications Enabled`,
            content: open === 'default' ? 'Group Notification Silenced' : 'Group Notifications Enabled'
        });
            return response.data;
        } catch (e) {
            await showErrorToast(e, 'Error updating channel notification');
            return e;
        }
    }

    useEffect(() => {
        if(channel && channel.notificationLevel) setOpen(channel.notificationLevel);
        if (mounted.current) return;

        if (channel && channel.friendlyName && channel.friendlyName.includes('workplace_bot')) {
            mounted.current = true;
            if (IS_DEV) {
                setShowSettings(true);
            } else {
                setShowSettings(false);
            }
            setLoading(false);
        } else {
            mounted.current = true;
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <Loading containerHeight={"56px"} containerWidth={"300px"} imgHeight={"32px"}/>;
    }

    return (
        <ChannelHeaderContainer>
            <CancelButton
                type="cancel"
                onClick={async () => {
                    if (goBack && goBackHandler) {

                        goBackHandler();
                    } else if (channel) {

                        setLoading(true);
                        await setMessagesConsumed(channel);
                        if (channel.attributes['kind'] !== 'Bot') {
                            await updateActiveChannelClose(channel);
                        }
                        dispatch({type: "CLOSE_ACTIVE_CHANNEL", channel: channel});
                    }
                }}
            >
                {!goBack && <Icon.Times size="16px" color="#7C98B6"/>}
                {goBack && <Icon.ArrowLeft size="16px" color="#7C98B6"/>}
            </CancelButton>
            <ChannelIcon channel={channel!} kind={channel?.attributes['kind']}/>
            <TextInformationContainer>
                <ChannelTitle channel={channel!} kind={channel?.attributes['kind']}/>
                <ChannelSubtitle channel={channel!} kind={channel?.attributes['kind']}/>
            </TextInformationContainer>
            <CancelButton
                type="cancel"
                onClick={updateChannelNotification}
            >
                {open === 'default' ? (
                    <Icon.Volume size="16px" color="#7C98B6" />
                ) : (
                    <Icon.Mute size="16px" color="#7C98B6" />
                )}
            </CancelButton>
            {showSettings && (
                <CancelButton
                    type="cancel"
                    onClick={() => {
                        if (channelDispatch && kind === "NORMAL") {
                            channelDispatch({type: "setOpenChannelSettings", openChannelSettings: true});
                        } else if (channelDispatch && kind === "TASK") {
                            channelDispatch({type: "SHOW_SUMMARY_MODAL"});
                        }
                    }}
                >
                    <Icon.Cog size="16px" color="#7C98B6"/>
                </CancelButton>
            )}
        </ChannelHeaderContainer>
    );
}

export default ChannelHeader;
