import React from 'react';
import axios from "axios";
import { engine as api } from "./Utilities";
// import {alert} from "./components/Alert";

export const AuthContext = React.createContext(undefined)
export function useAuth() {
    return React.useContext(AuthContext)
}

export function useLocalStorage(key, defaultValue) {

    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            let item = window.localStorage.getItem(key);
            if (item === null)
                return defaultValue
            else
                try {
                    return JSON.parse(item)
                } catch (e) {
                    return item;
                }
        } catch (error) {
            return defaultValue;
        }
    });

    const setValue = value => {
        try {
            if (value) {
                let valueToStore = value;
                if (typeof (value) === "object") {
                    valueToStore = JSON.stringify(value);
                }
                window.localStorage.setItem(key, valueToStore);
                setStoredValue(value);
            } else {
                setStoredValue(defaultValue);
                window.localStorage.removeItem(key);
                // window.localStorage.clear();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export let baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://127.0.0.1:8000';

// This does not allow to login
export const auth = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    headers: { 'Content-Type': 'multipart/form-data' },
});

export const login = axios.create({
    baseURL: baseUrl,
    responseType: "json",
});


export function upload(formData, ignoreMimeTypeCheck = false) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`
        }
    };
    formData.append("ignoreMimeTypeCheck", ignoreMimeTypeCheck);
    return axios.post(baseUrl + '/api/upload', formData, config);
}

export function uploadToMedia(formData) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`
        }
    };

    return axios.post(baseUrl + '/api/uploadFile', formData, config);
}

export function deleteMedia(formData) {
    const config = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    };

    return axios.post(baseUrl + '/api/deleteFile', formData, config);
}

export function getToken() {
    return JSON.parse(localStorage.getItem('token'));
}

export const setToken = (str) => {
    if (typeof str === "string") {
        localStorage.setItem('token', JSON.stringify(str));
    }
};

export const setUserId = (userId) => {
    localStorage.setItem('userId', userId);
};

export function getUserId() {
    return parseInt(localStorage.getItem('userId'), 10);
}

export const setPositionName = (position) => {
    localStorage.setItem('position', position);
};

export function getPositionName() {
    return localStorage.getItem('position');
}

export const setRole = (role) => {
    localStorage.setItem('role', role);
};

export function getRole() {
    return localStorage.getItem('role');
}

export const getChatIdentifier = () => {
    return localStorage.getItem('chatIdentifier');
}

export const setChatIdentifier = (chatIdentifier) => {
    return localStorage.setItem('chatIdentifier', chatIdentifier);
}

export const getStreamChatIdentifier = () => {
    return localStorage.getItem('streamChatIdentifier');
}

export const setStreamChatIdentifier = (streamChatIdentifier) => {
    return localStorage.setItem('streamChatIdentifier', streamChatIdentifier);
}

export const getImpersonateStreamChatIdentifier = () => {
    return localStorage.getItem('impersonateStreamChatIdentifier');
}

export const setImpersonateStreamChatIdentifier = (streamChatIdentifier) => {
    return localStorage.setItem('impersonateStreamChatIdentifier', streamChatIdentifier);
}

export const getIsOpenChat = () => {
    return localStorage.getItem('isOpenChat');
}

export const setIsOpenChat = (isOpenChat) => {
    return localStorage.setItem('isOpenChat', isOpenChat);
}

export const setFriendlyName = (friendlyName) => {
    localStorage.setItem('friendlyName', friendlyName);
};

export function getFriendlyName() {
    return localStorage.getItem('friendlyName');
}

export const setCompanyName = (companyName) => {
    localStorage.setItem('companyName', companyName);
};

export function getCompanyName() {
    return localStorage.getItem('companyName');
}

export const setCompanyId = (companyId) => {
    localStorage.setItem('company', companyId);
};

export const getCompanyId = () => {
    return localStorage.getItem('company');
};

export const setChatCompanyId = (companyId) => {
    localStorage.setItem('chatCompany', companyId);
};

export const getChatCompanyId = () => {
    return (localStorage.getItem('chatCompany'))?localStorage.getItem('chatCompany'):getCompanyId();
};

export const setChatUserId = (userId) => {
    localStorage.setItem('chatUser', userId);
};

export const getChatUserId = () => {
    return (localStorage.getItem('chatUser'))?localStorage.getItem('chatUser'):getUserId();
};

export const setChatTeam = (ChatTeam) => {
    localStorage.setItem('chatTeam', ChatTeam);
};

export const getChatTeam = () => {
    return localStorage.getItem('chatTeam');
};

export const setDriverId = (driverId) => {
    localStorage.setItem('driverId', driverId);
};

export const getDriverId = () => {
    return localStorage.getItem('driverId');
};

export const setEmail = (email) => {
    localStorage.setItem('email', email);
};

export function getEmail() {
    return localStorage.getItem('email');
}

export const getIsEnabled = () => {
    return localStorage.getItem('isEnabled');
};

export const setIsEnabled = (isEnabled) => {
    localStorage.setItem('isEnabled', isEnabled);
};

export const setTitle = (title) => {
    localStorage.setItem('title', title);
};

export function getTitle() {
    return localStorage.getItem('title');
}

export const setAllowToChatStream = (allowToChat) => {
    localStorage.setItem('allowToChatStream', allowToChat);
};

export function getAllowToChatStream() {
    return localStorage.getItem('allowToChatStream');
}

export const setEnableOldChat = (allowToChat) => {
    localStorage.setItem('enableOldChat', allowToChat);
};

export function getEnableOldChat() {
    return localStorage.getItem('enableOldChat');
}

export const setIsEnableSMS = (isEnableSMS) => {
    localStorage.setItem("isEnableSMS", isEnableSMS);
};

export function getIsEnableSMS() {
    return localStorage.getItem("isEnableSMS");
}

export const setEnableAutomatedScorecardCoachingMetric = (automatedScorecardCoachingMetric) => {
    localStorage.setItem("enableAutomatedScorecardCoachingMetric", automatedScorecardCoachingMetric);
};

export function getEnableAutomatedScorecardCoachingMetric() {
    return localStorage.getItem("enableAutomatedScorecardCoachingMetric");
}

export const setAllowsDispatcherPermission = (allowsDispatcherPermission) => {
    localStorage.setItem("allowsDispatcherPermission", allowsDispatcherPermission);
};

export function getAllowsDispatcherPermission() {
    return localStorage.getItem("allowsDispatcherPermission");
}