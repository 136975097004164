import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Icon, Button} from '@dspworkplace/ui';
import FileModal from "./FileModal";
import {StateChannelChat} from './ChannelChatWindow';
import Loading, {useLoading} from "./Loading";
import {engine, getCompanyId, getSelectedStationId, showErrorToast, showToast} from "../Utilities";
import {getChatIdentifier} from "../Auth";
import {useChatStoreReducer} from "./store";
import EmojiSelector from "./EmojiSelector";
import {Conversation, Participant} from "@twilio/conversations";

const Form = styled.div`
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 100px 40px;
    grid-row-gap: 8px;
    margin-right: 20px;
    margin-left: 20px;
    border-top: 1px dashed #7C98B6;
    padding: 8px 0 8px 0;
    max-height: 173px;
`;

const Input = styled.textarea`
    border: 1px solid #CCCCCC !important;
    border-radius: 2px;
    opacity: 1;
    width: 300px;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 12px;

    &:hover, &:hover, &:focus, &:active {
        border: 1px solid #CCCCCC;
    }
`;

const Send = styled.div`
    display: grid;
    width: 300px;
    height: 40px;
    grid-template-columns: 40px 40px auto;
    grid-template-rows: 40px;
    grid-column-gap: 8px;
    border-radius: 2px;
`;

const SendButtonContainer = styled.div`
    width: 100px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-self: end;
`;

const ParallelBorder = styled.div`
    border-color: #CBD6E2;
    border-style: dashed;
    width: 0;
    height: 40px;
    border-width: 1px;
    background-color: #EAF0F6;
`;

const CustomButton = styled(Button)`
    width: ${props => props.width ? props.width : '40px'};
    height: ${props => props.height ? props.height : '40px'};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    justify-self: ${props => props.direction ? props.direction : 'end'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-right-radius: ${props => props.bbrr ? props.bbrr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-right-radius: ${props => props.btrr ? props.btrr : props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-left-radius: ${props => props.bblr ? props.bblr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-left-radius: ${props => props.btlr ? props.btlr : props.borderRadius ? props.borderRadius : '2px'};

    &:active, &:focus, &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }
`;

const LabelFile = styled.label`
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background-color: #EAF0F6;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};
    opacity: ${props => props.opacity ? props.opacity : 1};

    &:hover {
        background-color: #CBD6E2;
    }
`;

const InputFile = styled.input`
    display: none;
`;

const OpenSMSViewContainer = styled.div`
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #CCCCCC;
    box-shadow: 0 0 6px #CCCCCC;
    border-radius: 2px;
    color: #707070;
    font-size: 14px;
    padding: 4px 8px;
    position: relative;
    top: -64px;
    right: 8px;
    cursor: pointer;
`;

export const DSPButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const OpenOptionsDSPContainer = styled.div`
//     background: #FFFFFF 0 0 no-repeat padding-box;
//     border: 1px solid #CCCCCC;
//     box-shadow: 0 0 6px #CCCCCC;
//     border-radius: 2px;
//     color: #707070;
//     font-size: 14px;
//     padding: 2px 8px;
//     position: absolute;
//     bottom: 58px;
//     width: 170px;
//
//     div {
//         height: 32px;
//         display: flex;
//         align-items: center;
//         cursor: pointer;
//     }
//
// `;

// types of media Twilio
// video/
// image/
// audio/
// application/zip
// text/csv
// text/rtf
// text/javascript
// application/pdf
// empty

type Props = {
    state: StateChannelChat;
    dispatch: (arg: any) => void;
    allowToChat: any;
    conversation: Conversation;
}

const InputMessage = (props: Props) => {
    const {state, dispatch} = props;
    const fileUpload = `${state.conversation.sid}-file-upload`;
    const formRef = useRef<null | HTMLDivElement>(null);
    const inputRef = useRef<null | HTMLTextAreaElement>(null);
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [openSMSView, setOpenSMSView] = useState<boolean>(false);
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [openOptionsDSP, setOpenOptionsDSP] = useState<boolean>(false);
    // @ts-ignore
    const [users, setUsers] = useState<Member[]>([]);
    const {conversation} = props;
    const {loading, setLoading} = useLoading();
    // @ts-ignore
    const [disabled, setDisabled] = useState(false);
    const [content, setContent] = useState("");
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [chosenEmoji, setChosenEmoji] = useState(null);
    // @ts-ignore
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    // const {state: appState} = useChatStoreReducer();
    // console.log({appState});
    // console.log(appState.allowToChat);
    // @ts-ignore
    // const [allowToChat, setAllowToChat] = useState(!!appState.allowToChat);

    // @ts-ignore
    const showEmojiPickerHandler = useCallback(
        () => {
            setShowEmojiPicker(!showEmojiPicker);
        },
        [showEmojiPicker],
    );
    // @ts-ignore
    const onEmojiClick = (event, emojiObject) => {
        // setChosenEmoji(emojiObject);
        if (inputRef.current) {
            inputRef.current.value = inputRef.current.value + emojiObject.emoji;
        }
        if (content) {
            setContent(prev => prev.concat(emojiObject.emoji))
        } else {
            setContent(emojiObject.emoji);
        }
    };

    const reset = () => {
        setContent("");
        if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = "";
        }
    }

    // const onSubmit = async (form) => {
    //     console.log({form, disabled});
    //     if (!disabled && form.message && form.message.trim().length !== 0) {
    //         dispatch({type: 'sendMessage', content: form.message});
    //         reset();
    //     }
    // };

    const onSubmit = async () => {
        // console.log({onSubmit: {content, disabled}});
        if (!disabled && content && content.trim().length !== 0) {
            dispatch({type: 'sendMessage', content: content});
            reset();
        }
    };

    function submitMedia(event) {
        if (event.target.files.length > 0) {
            // devLog('submitMedia');
            // devLog(event.target.files);
            // devLog(Array.from(event.target.files));
            dispatch({type: 'setFiles', files: Array.from(event.target.files), openFileModal: true});
        }
        return false;
    }

    const onKeyDownHandler = async e => {
        /** @type {string} */
        let value = e.target.value;
        // console.log({onKeyDownHandler: value});
        if (e.keyCode === 13 && e.shiftKey) {
            value.concat('\n');
        } else if (e.keyCode === 13 && !e.shiftKey && value && value.trim().length !== 0) {
            // devLog(`onKeyDownHandler : ${value}`);
            dispatch({type: 'sendMessage', content: value});
            reset();
        } else {
            await conversation.typing();
        }
    };

    const onChangeHandler = e => {
        /** @type {string} */
        let value = e.target.value;
        // console.log({onChangeHandler: value})
        if (value) {
            if (value.charAt(0) === '\n' || value.charAt(0) === '\r') {
                reset();
            } else {
                setContent(value);
            }
        }
    };

    useEffect(() => {
        if (formRef.current) {
            setLoading(false);
            return;
        }
        // if(appState.allowToChat){
        //     setAllowToChat(appState.allowToChat.value);
        // }
        setLoading(true);
        if (conversation.sid) {
            conversation.getParticipants().then(result => {
                if (result) {
                    setUsers(result);
                    setLoading(false);
                }
            }).catch(() => {
                setUsers([]);
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if(appState.allowToChat && appState.allowToChat.track){
    //         setAllowToChat(appState.allowToChat.value);
    //     }
    // }, [appState.allowToChat.track]);

    if (loading) {
        return <Loading containerHeight={'140px'} containerWidth={'300px'}/>
    }

    return (
        <>
            <EmojiSelector show={showEmojiPicker} onEmojiClick={onEmojiClick}
                           pickerStyle={{position: "fixed", overflow: "auto", minHeight: '300px'}} preload={true}
                           disableSkinTonePicker={true} native={true}/>
            <Form ref={formRef}>
                <Input
                    autoFocus={true}
                    name='message'
                    label='message'
                    ref={inputRef}
                    onKeyDown={onKeyDownHandler}
                    onChange={onChangeHandler}
                    // disabled={!allowToChat}
                />
                <Send>
                    <LabelFile htmlFor={fileUpload}
                        // opacity={allowToChat ? 1 : 0.5}
                    >
                        <Icon.Image size='21px' color='##0071BC'/>
                    </LabelFile>
                    <InputFile
                        id={fileUpload}
                        type="file"
                        // disabled={disabled ? disabled : !allowToChat}
                        disabled={disabled}
                        onChange={e => submitMedia(e)} multiple
                    />
                    <DSPButtonContainer>
                        <CustomButton onClick={showEmojiPickerHandler}
                                      disabled={disabled}
                            // disabled={disabled ? disabled : !allowToChat}
                        >
                            <Icon.DspwNetwork size='21px' color='#F7931E'/>
                        </CustomButton>
                        {/*{showEmojiPicker && <OpenOptionsDSPContainer onClick={showEmojiPickerHandler}>*/}
                        {/*    /!*<div>*!/*/}
                        {/*    /!*    Send open shifts*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*    /!*<div>*!/*/}
                        {/*    /!*    Assign to open shift*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*    /!*<div>*!/*/}
                        {/*    /!*    Add time/day off*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*    /!*<div>*!/*/}
                        {/*    /!*    Report incident*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*</OpenOptionsDSPContainer>}*/}
                    </DSPButtonContainer>
                    <SendButtonContainer>
                        <CustomButton width='65px' borderRadius='0' bblr='2px'
                                      btlr='2px' onClick={onSubmit}
                            // disabled={disabled ? disabled : !allowToChat}
                                      disabled={disabled}
                        >Send</CustomButton>
                        <ParallelBorder/>
                        <ArrowSMSView users={users} disabled={disabled} setDisabled={setDisabled}
                                      input={content}
                                      reset={reset} dispatch={dispatch}/>
                    </SendButtonContainer>
                </Send>
            </Form>
            {
                state.openFileModal && <FileModal state={state} dispatch={dispatch}/>
            }
        </>
    )
};

export default InputMessage;

type ArrowSMSViewProps = {
    users: Participant[],
    disabled: boolean,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>,
    input: string;
    reset: () => void;
    dispatch: (arg: any) => void;
}

function ArrowSMSView({users, disabled, setDisabled, input, dispatch, reset}: ArrowSMSViewProps) {
    const smsRef = useRef<null | HTMLDivElement>(null);
    // @ts-ignore
    const [openSMSView, setOpenSMSView] = useState<boolean>(false);
    // @ts-ignore
    const [isProcessing, setIsProcessing] = useState(false);
    // @ts-ignore
    const {loading, setLoading} = useLoading();
    const {state: chatState} = useChatStoreReducer()

    // @ts-ignore
    const sendMessage = async () => {
        try {
            let content = input.trim();
            if (content.length > 0) {
                setLoading(true);
                setIsProcessing(true);
                setOpenSMSView(false);
                dispatch({type: 'sendMessage', content: content});
                let filteredValue = chatState.filter.clean(content);
                let isBadSentence = filteredValue.includes("*");
                if (isBadSentence) {
                    await showToast({
                        type: 'Error',
                        title: 'SMS Error',
                        content: 'SMS will not be delivered due to inappropriate language.'
                    });
                } else {
                    let destinationDrivers = users.filter(e => e.identity !== getChatIdentifier()).map(e => e.identity);
                    destinationDrivers = Array.from(new Set(destinationDrivers));
                    try {
                        let {data} = await engine().post('/api/send/sms/drivers', {
                            "companyId": getCompanyId(),
                            "stationId": getSelectedStationId(),
                            "destinationDrivers": destinationDrivers,
                            "content": content,
                        });
                        if (!data.success) {
                            await showToast({
                                type: 'Error',
                                title: 'Error sending SMS.',
                            });
                        }
                        if (Array.isArray(data.results)) {
                            for (let i = 0; i < data.results.length; i++) {
                                await showToast({
                                    type: 'Error',
                                    title: 'Error sending SMS.',
                                    content: data.results[i].message,
                                });
                            }
                        }
                    } catch (e) {
                        await showErrorToast(e, 'SMS Error', 'Error sending SMS.');
                    }
                }
                setLoading(false);
                setDisabled(false);
                setIsProcessing(false);
                reset();
            }
        } catch (e) {
            console.error({e});
            await showErrorToast(e, 'SMS Error', 'Error sending message and SMS.')
            setLoading(false);
            setOpenSMSView(false);
            setDisabled(false);
            setIsProcessing(false);
            reset();
        }
    }

    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [chatState.allowToChat.track]);

    return (
        <>
            <CustomButton width='31px' borderRadius='0' bbrr='2px' btrr='2px'
                // disabled={!chatState.allowToChat.value}
                          onClick={() => {
                              if (!isProcessing) {
                                  setOpenSMSView((!openSMSView));
                                  setDisabled(!disabled);
                              }
                          }}>
                {openSMSView && <Icon.ArrowDown size='16px' color='#7C98B6'/>}
                {!openSMSView && <Icon.ArrowUp size='16px' color='#7C98B6'/>}
            </CustomButton>
            {
                openSMSView && <OpenSMSViewContainer ref={smsRef} onClick={sendMessage}>
                    Send & SMS
                </OpenSMSViewContainer>
            }
            {loading &&
                <OpenSMSViewContainer ref={smsRef}>
                    <Loading containerWidth={'90px'} containerHeight={'26px'} imgHeight={'24px'} imgWidth={'24px'}/>
                </OpenSMSViewContainer>
            }
        </>
    );

}
