//@ts-ignore
import React, {useEffect, useRef, useState} from 'react';
import {TableData} from "../../components/Table";
//@ts-ignore
import {Toggle, Button} from "@dspworkplace/ui";
import {confirm} from "../../components/Confirm";
//@ts-ignore
import {showToast, engine, showErrorToast} from "../../Utilities";
import Loading from "../../Chat/Loading";
// import {useForm} from 'react-hook-form';

type Employee = { userId: string, allowToChat: boolean | undefined | null, friendlyName: string; id: number; }

export async function updateEmployee(employees, tempIndex) {
    try {
        let employee = employees.data[tempIndex];
        let userId = employee.userId;
        let driverId = employee.id;
        let friendlyName = employee.friendlyName;
        await allowToChatUser(userId, driverId, friendlyName);
    } catch (e) {
        await showErrorToast(e, 'Error in update Employee to enable chat.');
    }
}

type ResultAllowToChat = {
    allowToChat?: boolean | null;
    success: boolean;
    employeeStatus?: number | string;
}

export async function allowToChatUser(userId: number | string, driverId: number | string, friendlyName: string): Promise<ResultAllowToChat> {
    try {
        let response = await engine().post("/api/allow_to_chat", {
            userId,
            driverId,
            friendlyName,
        });
        let allowToChat = response.data.allowToChat;
        let message;
        let employeeStatus = response.data.employeeStatus;
        if (employeeStatus === '-2' || employeeStatus === -2) {
            await showToast({
                type: 'warning',
                title: 'The employee is Terminated.',
                content: 'The employee is not enabled to chat, to enable chat, please update its status.'
            });
            return {allowToChat, success: true, employeeStatus};
        }
        if (!allowToChat) {
            message = `The employee ${friendlyName} is allowed to read, but not send messages.`;
        } else {
            message = `The employee ${friendlyName} is allowed to both read and send messages.`;
        }
        await showToast({
            type: 'success',
            title: 'Successful Update.',
            content: message
        });
        return {allowToChat: allowToChat, success: true, employeeStatus};
    } catch (e) {
        await showErrorToast(e, 'Error in update to allow user to chat.');
        return {success: false};
    }
}

// const Switcher: React.FC<{ on: boolean; onChange: (arg?: any) => void; track: any }> = ({on, onChange, track}) => {
//     return <Toggle name={'allowToChat'}
//                    onChange={onChange}
//                    on={on}
//                    track={track}
//     />;
// }
type Props = {
    width?: string;
    employee: Employee;
    handleUpdateEmployees: any;
    keyVal: any;
}
const AllowToChat: React.FC<Props> = (props) => {
    //@ts-ignore
    const {width = '58px', employee, handleUpdateEmployees, keyVal} = props;
    //@ts-ignore
    const [on, setOn] = useState(JSON.stringify(employee.allowToChat) !== 'false');
    //@ts-ignore
    const [track, setTrack] = useState<any>(1.0);
    const [loading, setLoading] = useState(false);
    //@ts-ignore
    const toggleRef = useRef<any>();
    //@ts-ignore
    // const {register, reset, watch, errors, handleSubmit} = useForm({
    //     mode: "onBlur",
    //     defaultValues: {
    //         allowToChat: !!employee.allowToChat,
    //     }
    // });

    const onChange = async () => {
        // console.log(employee);
        try {
            let message: string;
            if (employee.allowToChat) {
                message = "Disable this user's ability to respond to chats?";
            } else {
                message = 'Enable this user to both read and respond to chats?';
            }
            const confirmation = await confirm({
                icon: true,
                text: message
            });
            if (!confirmation) {
                setLoading(false);
                setTrack(Math.random() + 10.0);
                setOn(!!employee.allowToChat);
                try {
                    toggleRef.current.click();
                } catch (e) {
                    console.error(e);
                }
                return;
            }
            setLoading(true);
            let {success, allowToChat, employeeStatus} = await allowToChatUser(employee.userId, employee.id, employee.friendlyName);
            if (success) {
                try {
                    handleUpdateEmployees(employee, keyVal, 'allowToChat', allowToChat);
                } catch (e) {
                    await showErrorToast(e,
                        'Database update is correct, but failure on web app.',
                        'There has been an error trying to update the row of the employee selected.');
                }
                setTrack(1.0);
            }
            if(employeeStatus === -2 || employeeStatus === '-2'){
                setTrack(Math.random() + 10.0);
                try {
                    //@ts-ignore
                    toggleRef.current.click();
                } catch (e) {
                    await showErrorToast(e,
                        'Failure toggle update.');
                }
            }
            if (!success) {
                setTrack(Math.random() + 10.0);
                try {
                    //@ts-ignore
                    toggleRef.current.click();
                } catch (e) {
                    await showErrorToast(e,
                        'Failure toggle update.');
                }

            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            await showErrorToast(e,
                'Failure updating employee.',
                'Please try again. If the issue continues please contact support@dspworkplace.com.');
        }
    }

    //@ts-ignore
    // const onSubmit = async (data, evt) => {
    //     console.log({data, evt});
    //     reset({allowToChat: !!employee.allowToChat});
    //     // await onChange();
    // }
    // useEffect(() => {
    //     console.log('useEffect []', employee.friendlyName);
    // }, []);
    // useEffect(() => {
    //     console.log('useEffect []');
    //     setOn(!!employee.allowToChat);
    // }, []);
    // useEffect(() => {
    //     console.log('useEffect [track]');
    //     if (track > 10.0) {
    //         console.log({track, allowToChat: employee.allowToChat, on: !!employee.allowToChat});
    //         setOn(!!employee.allowToChat)
    //     }
    // }, [track]);

    return (
        <TableData width={width} className={'allowToChat'}>
            {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
            {/*<Switcher*/}
            {/*    on={on}*/}
            {/*    onChange={onChange}*/}
            {/*    track={track}*/}
            {/*/>*/}
            { !loading && <Toggle name={'allowToChat'}
                ref={toggleRef}
                onChange={
                    async () => {
                        // console.log(`Track is => ${track}`);
                        if (track > 10.0) {
                            setTrack(1.0);
                            return;
                        }
                        await onChange();
                    }
                }
                on={JSON.stringify(employee.allowToChat) !== 'false'}
                // on={on}
            />}
            <div style={{fontSize: '5px', position: "relative", top: 0, left: 0, display: 'flex'}}>
                {/*{JSON.stringify({*/}
                {/*    allowToChat: employee.allowToChat,*/}
                {/*    type: typeof employee.allowToChat,*/}
                {/*    test1: employee.allowToChat ? true : null,*/}
                {/*    test2: employee.allowToChat ? true : false,*/}
                {/*    test3: JSON.stringify(employee.allowToChat) === 'false' ? false : true,*/}
                {/*    test4: JSON.stringify(employee.allowToChat) !== 'false',*/}
                {/*    on: on,*/}
                {/*    userId: employee.userId,*/}
                {/*    driverId: employee.id*/}
                {/*})}*/}
            </div>
            {loading &&
            <Loading containerHeight={'28px'} containerWidth={'28px'} imgHeight={'24px'} imgWidth={'24px'}
                     margin={'4px 0 0 -6px'}/>}
            {/*</form>*/}
            {/*</form>*/}
            {/*<Loading containerHeight={'28px'} containerWidth={'28px'} imgHeight={'24px'} imgWidth={'24px'}/>*/}
        </TableData>
    );
}

export default AllowToChat;