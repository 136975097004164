import React, {useEffect} from 'react';
import styled from "styled-components";
import {Theme} from '@dspworkplace/ui';
import Scores, {fetchMobileScorecardInformation} from "./Scores/Scores";
import Provider, {useWeeklyDataReducer} from "../../WeeklyDataProvider";
// import Incidents from "./Incidents/Incidents";
import {LoadingView} from '../../../../../../components/Loading';
// import Rescues from "./Rescues/Rescues";
import {Item} from "../../../SortTemplate/Container";
import Card from "./Scores/Card";

// const mobileWidth = 428;
const mobileHeight = 580;
//    // width: ${mobileWidth}px;
//     // max-width: ${mobileWidth}px;
const MobileContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
 
    height: ${mobileHeight}px;
    max-height: ${mobileHeight}px;
    overflow-x: unset;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid ${Theme.colors.info.border};
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const Overview: React.FC<{ driverId?: number, stationId?: number, preview?: Map<number, Item> }> = ({driverId, stationId, preview}) => {
    const {weeklyDataDispatch, weeklyDataState} = useWeeklyDataReducer();
    const {loading, data} = weeklyDataState;

    useEffect(() => {
        if (preview)
            return () => {};

        if (driverId && stationId) {
            const abortController = new AbortController();
            weeklyDataDispatch({type: 'SET_LOADING', loading: true});
            fetchMobileScorecardInformation(stationId, driverId).then((result) => {
                if (result) {
                    weeklyDataDispatch({type: 'SET_OVERVIEW', data: result!});
                }
            })

            return () => {
                abortController.abort();
            }
        }
    }, [driverId]);

    if (preview)
        return (
            <MobileContainer>
                {Array.from(preview).map(([, card]) =>
                    <Card
                        key={card.id}
                        metricName={card.text}
                        metricType={'number'}
                        metricValue={'-'}
                        change={0}
                        trend={'+'}
                        scenario={'tilde'}
                        trendStyle={''}
                    />
                )}
            </MobileContainer>
        )

    if (
        driverId && stationId &&
        !loading &&
        data &&
        data.scorecard &&
        data.rescues &&
        data.incidents &&
        data.rescues.sortedList &&
        data.scorecard
    ) {
        return (
            <MobileContainer>
                <Scores driverId={driverId} stationId={stationId}/>
                {/*<Incidents/>*/}
                {/*<Rescues/>*/}
            </MobileContainer>
        );
    }

    return <LoadingView containerWidth={'100%'} containerHeight={mobileHeight}/>;

};

const OverviewWrapper: React.FC<{ driverId?: number, stationId?: number, preview?: Map<number, Item> }> = ({driverId, stationId, preview}) => {
    return (
        <Provider>
            <Overview driverId={driverId} stationId={stationId} preview={preview}/>
        </Provider>
    );
};
export default OverviewWrapper;


