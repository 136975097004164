import {registerServiceWorker} from "../../firebase";

const notificator = document.getElementById('update-root');

export const WorkerConfig = {
    onSuccess: reg => {
        if (notificator && notificator.style.display !== 'none') {
            notificator.style.display = 'none';
        }
        registerServiceWorker(reg);
    },
    onUpdate: async (reg, controller) => {
        if (reg && reg.waiting) {
            const button = notificator.querySelector('button');
            button.addEventListener('click', () => {
                if(reg && reg.waiting && reg.waiting.postMessage){
                    reg.waiting.postMessage({type: 'SKIP_WAITING'});
                }
                if(controller && controller.postMessage){
                    controller.postMessage({type: 'SKIP_WAITING'})
                }
                window.location.reload(true)
            })
            notificator.style.display = 'flex';
        }

    }
}


