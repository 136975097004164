import React, { useState } from "react";
import styled from "styled-components";
import {
    Button,
    Spacer,
    ThirdTitle,
    CheckBox,
    Alert,
    Icon,
    Text,
    Password
} from "@dspworkplace/ui";
import { useForm } from "react-hook-form";

import Loading, { useLoading } from "../../components/Loading";
import Modal from "../../components/Modal";
import {useAuth, upload } from "../../Auth";
import { csvExtention, validatePassword } from "../../patterns";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;
`;

const LoadingWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    > img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const App = ({ action, csvVisible, toggleCsv }) => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, getValues } = useForm({ defaultValues: {} });
    const [isLoading, setLoading] = useLoading(false);
    const [log, setLog] = useState("");
    const [success, setSuccess] = useState(false);
    const { profile, password } = getValues();

    const onSubmit = async data => {
        if (data.profile.length > 0) {
            setLoading(true);

            let formData = new FormData();
            formData.append('image', data.profile[0]);
            formData.append('name', data.profile[0].name);
            formData.append('csvUpload', true);
            let result = await upload(formData).catch(err => {
                console.log({err})
            });

            if (localStorage.getItem("userId") && localStorage.getItem('email') && localStorage.getItem('company')){
                
                let email = localStorage.getItem('email');
                //if(email == 'undefined'){
                    let params1 = {
                        actions: {
                            response: {
                                "User": {
                                    findOneBy: {
                                        get: "user",
                                        criteria: {
                                            "id": localStorage.getItem("userId") 
                                        },
                                        includes: {
                                            0: "email"
                                        },
                                       
                                    }
                                },
                            }
                        }
                    };
                   
                    
                    try {
                        let response = await api.post('/api/lazy/manage/data', params1);
                        email = await response.data.data.user.email;
                    } catch(error) {
                        console.log('error', error);
                    }
                //}                


                let params = {
                    "actions": {
                        "create": {
                            "ImportUser": {
                                "importUser_1": {
                                    'userId':localStorage.getItem("userId"),
                                    'email':email,
                                    'companyId':localStorage.getItem('company'),
                                    'filePath':result.data.ObjectURL,
                                    'sendInvitation':data.send_invitation,
                                    'password':data.password
                                }
                            }
                        }
                    }
                };

                try {
                    let response =  await api.post('/api/lazy/manage/data', params);

                    if(response.status === 200){
                        setSuccess(true);
                    }else{
                        alert('Error to Import User');
                    }
                } catch(error) {
                    if (error.response.status == '401') {
                        window.location = "/logout";
                    }
                }
            } else {
                window.location = "/logout";
            }

            setLoading(false);

            console.log(result.data.ObjectURL);

        }
    };

    const errorMessages = {
        profile: {
            required: "You need to provide an csv",
            pattern: "select file format is not valid"
        },
        password: {
            required: "You need to provide a password to log in",
            pattern: "You password must respect all rules",
        },
    };

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([log], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = "log.txt";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Modal
            title={"Import Employee CSV"}
            width="506px"
            visible={csvVisible}
            setVisible={() => {
                toggleCsv();
            }}
            zindex="40"
        >
            <Container>
                {isLoading && (
                    <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                )}
                <form name="new_employee" onSubmit={handleSubmit(onSubmit)}>
                    {success === true && (
                        <Alert.Success title="Success" content="CSV uploaded successfully. Please allow 15-30 minutes for your upload to complete. You will be notified by email once your records have been added/updated successfully." />
                    )}
                    <Spacer top={4} />
                    <ThirdTitle style={{ fontWeight: "normal" }}>Select CSV File</ThirdTitle>
                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <input
                            type="file"
                            name="profile"
                            ref={register({
                                required: true,
                                pattern: csvExtention
                            })}
                            error={errors.profile && errorMessages.profile[errors.profile.type]}
                            valid={!errors.profile && profile && profile.length > 0}
                        />
                        <Text style={{color:"red"}}>{errors.profile && errorMessages.profile[errors.profile.type]}</Text>
                    </SpacerInline>
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <Text style={{ fontWeight: "normal" }}>
                            Example CSV
                            <a style={{ marginLeft: "10px" }} href={"https://s3.amazonaws.com/dsp.data.storage.general/Employee_Template.xlsx"}>
                                <Icon.Download size="22px" />
                            </a>
                        </Text>
                    </SpacerInline>
                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <Password
                            name="password"
                            label="Default Password"
                            help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                            ref={register({
                                required: false,
                                validate: (value) => {
                                    if (value) {
                                        return validatePassword(value, '', []);
                                    }
                                }
                            })}
                            error={
                                errors?.password &&
                                errors?.password?.message
                            }
                            valid={!errors.password && password && password.length > 0}
                        />
                    </SpacerInline>
                    {log !== "" && (
                        <>
                            <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={2}>
                                <Text style={{ color: "red" }}>
                                    Please check log file for errors
                                    <span style={{ color: "blue", cursor: "pointer" }} onClick={downloadTxtFile}>
                                        {" "}
                                        Click Here
                                    </span>
                                </Text>
                            </SpacerInline>
                            <Spacer top={4} />
                        </>
                    )}
                    <Spacer inline right={4} style={{verticalAlign:'bottom',position:'relative',top:'10px'}} >
                      <CheckBox
                          name='send_invitation'
                          ref={register}
                          style={{float:'right'}}
                          options={[{label:'Send invitation to all new employees',value:true}]}
                      />
                    </Spacer>
                    <Button type="primary" style={{ float: "right" }} Type="submit">
                        Import
                    </Button>
                </form>
            </Container>
        </Modal>
    );
};

export default App;
