// @ts-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useTrackedState } from "./store";
// import {useChatTrackedState, useDispatch, useTrackedState} from "./store";
import styled from "styled-components";
import { Icon, Theme } from "@dspworkplace/ui";
// import {updateNotifications} from "./utils";
import { setIsOpenChat, getIsOpenChat } from "../Utilities";
import { updateNotifications } from './utils';

const Notification = styled.div`
    position: absolute;
    top: -1px;
    right: -5px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 10px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;
    background: ${Theme.colors.error.text};
    border-radius: 50%;
`;

export function BadgeContainer() {
    const state = useTrackedState();
    const { notificationsCount } = state;

    useEffect(() => {
        updateNotifications(notificationsCount).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsCount]);

    if (typeof notificationsCount === "number" && notificationsCount > 0) {
        return <>
            <Notification>{notificationsCount}</Notification>
        </>;
    }
    return <></>;
}

const IconChatContainer = styled.div`
    cursor: pointer;
    position: absolute;
    right: ${props => props.right ? props.right : '400px'};
    top: 12px;
    z-index: 5;
    //left: 16px;
    //bottom: 16px;

    img {
        height: 28px;
        width: 40px;
        display: inline-block;
    }
`;

const ChatIconContainer: React.FC = () => {
    // const state = useChatTrackedState();
    // if (state.allInitNotificationsArray) {
    return <ChatIcon />
    // }
    return null;
}

const ChatIcon: React.FC = () => {
    const dispatch = useDispatch();
    const state = useTrackedState();
    const { isOpen } = state;
    const [right, setRight] = useState<String | null>(null);

    const openChatHandler = useCallback(() => {
        dispatch({ type: "OPEN_CHAT", isOpen: !isOpen });
    }, [dispatch, isOpen]);

    useEffect(() => {
        if (getIsOpenChat() == 'true') {
            dispatch({ type: "OPEN_CHAT", isOpen: !isOpen });
            setIsOpenChat(false);
        }
    }, []);


    const positionFinder = () => {
        let mobileActionsElem = document.querySelector<HTMLElement>('#mobile-actions-container');
        let profileElem = document.querySelector<HTMLElement>('#profile-container');
        let settingsElem = document.querySelector<HTMLElement>('#settings-icon');
        let summaryElem = document.querySelector<HTMLElement>('#summary-button');
        let releaseElem = document.querySelector<HTMLElement>('#release-button');
        let right = [profileElem, settingsElem, summaryElem, releaseElem].reduce((all, elm) => {
            if (elm !== null && elm.offsetWidth > 0 && elm.offsetHeight > 0) {
                if (elm.id === 'profile-container') {
                    all += elm.offsetWidth + 16 * 2 ?? 0;
                } else if (elm.id === 'summary-button') {
                    all += elm.offsetWidth + 16 * 2 ?? 0;
                } else {
                    all += elm.offsetWidth + 16 ?? 0;
                }
            }
            return all;
        }, 0)
        if (mobileActionsElem)
            right += mobileActionsElem.offsetWidth + 16;

        setRight(right + 'px');
        return;
    }

    useEffect(() => {
        let timeoutHandler;

        function resizeHandler() {
            if (timeoutHandler) {
                clearTimeout(timeoutHandler);
            }
            timeoutHandler = setTimeout(() => {
                positionFinder();
            }, 100);
        }

        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    // if (right) {
    return (
        <IconChatContainer onClick={openChatHandler} data-id={"chat-icon"} right={right - 20}>
            <Icon.Chat size="32px" color={"#F7931E"} />
            <BadgeContainer />
        </IconChatContainer>
    );
    // }

    return null;
}

export default ChatIconContainer;
