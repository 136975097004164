import React, { useEffect, useReducer, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Title, Spacer, Text, Dropdown, Button, Icon, Input } from '@dspworkplace/ui';
import Loading from "../../../../components/Loading";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../Auth";
import { patternFloatValue } from '../../../../patterns';
import SimpleScroll from "../../../../components/SimpleScroll";
import { Table, TableData, TableFooter } from "../../../../components/Table";
import {AccessCheck, IsGranted} from "../../../../security";

const CustomButton = styled.a`
    font-family: circe-rounded;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    background: #EAF0F6;
    color: #0071BC;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    font-size: 21px;
`;

const OrderIco = ({ direction = 'ASC' }) => {
    if (direction === 'DESC') return (<Icon.ArrowUp size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />);
    return (<Icon.ArrowDown size='16px' color='#7C98B6' style={{ position: 'relative', top: '0px', left: '4px' }} />)
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'create':
            return { ...state, rates: action.rates, isLoading: action.isLoading };
        case 'loading':
            return { ...state, isLoading: action.isLoading };
        case 'add':
            return { ...state, rates: [...state.rates, action.rate], isLoading: action.isLoading };
        case 'setUpdateKey':
            return { ...state, updateKey: action.updateKey }
        case 'update':
            state.rates[action.key] = action.rate;
            return { ...state, updateKey: -1 };
        case 'cancel':
            return { ...state, updateKey: -1 };
        case 'filter':
            if (state.filterOrder === 'ASC') {
                state.rates.sort((a, b) => b[action.filterName].toString().localeCompare(a[action.filterName].toString(), undefined, { numeric: true }));

                return { ...state, rates: state.rates, filterOrder: 'DESC', filterName: action.filterName };
            } else {
                state.rates.sort((a, b) => a[action.filterName].toString().localeCompare(b[action.filterName].toString(), undefined, { numeric: true }));
            }
            return { ...state, rates: state.rates, filterOrder: 'ASC', filterName: action.filterName };
        case 'delete':
            state.rates.splice(action.key, 1);
            return { ...state }
        default:
            return state;
    }
}
// set cumulativeRate to 0 not null or empty
// let rates = [];

let criteria = [
    { id: '1', name: 'Hourly' },
    { id: '2', name: 'Daily' },
    { id: '3', name: 'Weekly' },
    { id: '4', name: 'Bi-Weekly' },
    { id: '5', name: 'Monthly' },
    { id: '6', name: 'Per Piece' },
];

const initialState = {
    rates: [],
    updateKey: -1,
    filterName: 'name',
    filterOrder: 'ASC',
    filterType: 'str',
    isLoading: true,
}


const Rate = (localState = '') => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { api } = useAuth();
    const useLocationState = useLocation().state;
    const FullRole = ['ROLE_OWNER', 'ROLE_OPERATIONS_ACCOUNT_MANAGER', 'ROLE_OPERATIONS_MANAGER', 'ROLE_FLEET_MANAGER', 'ROLE_HR_ADMINISTRATOR', 'ROLE_CENTRAL_DISPATCHER'];
    const ParsiallyRole = ['ROLE_DISPATCHER', 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE', 'ROLE_STATION_MANAGER', 'ROLE_ASSISTANT_STATION_MANAGER'];
    // const station = useLocation().state.station;

    const handleDelete = async (id, key) => {
        const confirmation = window.confirm('Confirm that you want to delete this item');
        if (!confirmation) {
            return false;
        }

        try {
            let params = {
                "actions": {
                    "delete": {
                        "Rate": {
                            "delete_rate_1": {
                                "findOneBy": {
                                    "id": id
                                }
                            }
                        }
                    },
                }
            };

            await api.post('/api/lazy/manage/data', params);

            dispatch({ type: 'delete', key: key });

        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
            dispatch({ type: 'default' });
        }
    }

    const filterBy = (sortFieldName, sortFieldType) => { dispatch({ type: 'filter', filterName: sortFieldName, filterType: sortFieldType }); }

    useEffect(() => {
        const getRates = async () => {

            try {
                const params = {
                    "actions": {
                        "response": {
                            "Rate": {
                                "custom": {
                                    "functionName": "getRateByStation",
                                    "get": "Rates",
                                    "criteria": {
                                        "station": (useLocationState && useLocationState.station) ? useLocationState.station.id : localState.state.station.id
                                    },
                                    "excludes": ["company", "station", "users", "parent", "childrens", "driverRoutes", "payRate", "Rates", "skillRates", "shifts", "drivers"],
                                    "includes": {
                                        "0": "id",
                                        "1": "name",
                                        "2": "criteria",
                                        "3": "effectiveRate",
                                        "4": "cumulativeRate",
                                    },
                                }
                            }
                        }
                    }
                };
                let response = await api.post('/api/lazy/manage/data', params);

                let r = response.data.data.Rates.map(item => { return { id: item.id, name: item.name, criteria: item.criteria, effectiveRate: item.effectiveRate, cumulativeRate: item.cumulativeRate ? item.cumulativeRate : 0 } });
                dispatch({ type: 'create', rates: r, isLoading: false });
            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                dispatch({ type: 'default' });
            }

        }

        getRates();
    }, []);

    return (
        <>
            <Spacer top={5} />
            <Title>Rate Card</Title>
            <Spacer top={1} />
            <Text>Enter rates as they appear on your Amazon rate card.</Text>
            <Spacer top={5} />
            <Table>
                <li className='header'>
                    <TableData width='320px' onClick={() => { filterBy('name', 'str') }}>Name {state.filterOrder && state.filterName === 'name' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData width='160px' onClick={() => { filterBy('criteria', 'str') }}>Criteria {state.filterOrder && state.filterName === 'criteria' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />} </TableData>
                    <TableData width='160px' onClick={() => { filterBy('effectiveRate', 'num') }}>Effective Rate {state.filterOrder && state.filterName === 'effectiveRate' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />}</TableData>
                    <TableData width='244px' onClick={() => { filterBy('cumulativeRate', 'num') }}>Cumulative Rate {state.filterOrder && state.filterName === 'cumulativeRate' ? <OrderIco direction={state.filterOrder} /> : <OrderIco direction='ASC' />}</TableData>
                </li>
                <SimpleScroll height={'calc(100vh - 400px)'}>
                    {state.isLoading && <Loading style={{ height: '80px', marginTop: '20px' }} />}
                    {!state.isLoading && state.rates &&
                        state.rates.map((rate, key) => {
                            if (key === state.updateKey) {
                                return (
                                    <AddForm key={key} index={key} dispatch={dispatch} data={state} station={(useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station} />
                                );
                            }

                            return (
                                <li key={key}>
                                    <TableData width='320px'>{rate.name}</TableData>
                                    <TableData width='160px'>{rate.criteria}</TableData>
                                    <TableData width='160px'>${rate.effectiveRate}</TableData>
                                    <TableData width='180px'>{rate.cumulativeRate !== '' ? rate.cumulativeRate === 0 || rate.cumulativeRate === '0' ? '' : '$' + rate.cumulativeRate : ''}</TableData>
                                    {(FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1 || ParsiallyRole.indexOf(localStorage.getItem('currentUserRole'))) && <TableData width='64px'>
                                    <IsGranted feature={AccessCheck.features.ST_RATE_CARD} ops={AccessCheck.OPS.U}>
                                        <Spacer inline right={3} top={2}>
                                            <Icon.Edit size='20px' color='#7C98B6' onClick={() => { dispatch({ type: 'setUpdateKey', updateKey: key }) }} />
                                        </Spacer>
                                    </IsGranted>
                                    <IsGranted feature={AccessCheck.features.ST_RATE_CARD} ops={AccessCheck.OPS.D}>
                                        <Spacer inline right={3} top={2}>
                                            <Icon.Times size='20px' color='#7C98B6' onClick={() => { handleDelete(rate.id, key) }} />
                                        </Spacer>
                                    </IsGranted>
                                    </TableData>}
                                </li>
                            )

                        })
                    }
                </SimpleScroll>
                {!state.isLoading && state.rates && (FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1 || ParsiallyRole.indexOf(localStorage.getItem('currentUserRole'))) && <AddForm dispatch={dispatch} data={state} station={(useLocationState && useLocationState.station) ? useLocationState.station : localState.state.station} />}
            </Table>
        </>
    );
}

const AddForm = ({ dispatch, data, index, station }) => {

    const { api, logout } = useAuth();

    let defaultValues;
    let selected;
    if (index > -1) {
        selected = data.rates[index];
        defaultValues =
        {
            name: selected.name,
            criteria: selected.criteria,
            effectiveRate: selected.effectiveRate,
            cumulativeRate: selected.cumulativeRate,
        }
    }

    const { register, handleSubmit, errors, reset, getValues } = useForm({ defaultValues: defaultValues });
    const { name, effectiveRate, cumulativeRate } = getValues();
    const [isCancelButton, setIsCancelButton] = useState(false);
    const [formData, setFormData] = useState({ name: '', criteria: '', effectiveRate: '', cumulativeRate: '' });
    const handleEdit = async (form, selected, index) => {
        try {
            let params =
            {
                "actions": {
                    "update": {
                        "Rate": {
                            "rate_1": {
                                "findBy": {
                                    "id": selected.id
                                },
                                "updateRecord": {
                                    "name": form.name,
                                    "criteria": form.criteria,
                                    "effectiveRate": form.effectiveRate,
                                    "cumulativeRate": form.cumulativeRate
                                }
                            }
                        }
                    }
                }
            }

            await api.post('/api/lazy/manage/data', params);

            selected.name = form.name;
            selected.criteria = form.criteria;
            selected.effectiveRate = form.effectiveRate;
            selected.cumulativeRate = form.cumulativeRate;
            dispatch({ type: 'update', rate: selected, key: index });
            setIsCancelButton(false);
        } catch (error) {
            dispatch({ type: 'default' });
            alert("Error to update rate. Login again, please").then(() => {

            });
        }
    }

    const cancelBtn = async () => {
        if (!selected) {
            reset({ name: '' });
            setIsCancelButton(false);
        } else {
            dispatch({ type: 'cancel' });
        }
        return false;
    }

    const handleOnChange = useCallback(event => {
        if (event.target) {
            if (event.target.name === 'name') { formData.name = event.target.value; }
            if (event.target.name === 'effectiveRate') { formData.effectiveRate = event.target.value; }
            if (event.target.name === 'cumulativeRate') { formData.cumulativeRate = event.target.value; }
            if (formData.criteria.length > 0 || formData.name.length > 0 || formData.effectiveRate.length > 0 || formData.cumulativeRate.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }
        } else {
            formData.criteria = event.value;
            if (formData.criteria.length > 0 || formData.name.length > 0 || formData.effectiveRate.length > 0 || formData.cumulativeRate.length > 0) {
                setIsCancelButton(true);
            } else {
                setIsCancelButton(false);
            }

        }
    });
    const onSubmit = async form => {
        form['company'] = localStorage.getItem('company');

        for (const property in form) {
            form[property] = form[property].trim();
        }

        if (selected) {
            await handleEdit(form, selected, index);
        } else {

            try {
                form['station'] = station.id;
                form['company'] = localStorage.getItem('company');
                form.cumulativeRate = form.cumulativeRate.trim();

                let params = {
                    "actions": {
                        "create": {
                            "Rate": {
                                "Rate_1": form
                            }
                        }
                    }
                };

                let response = await api.post('/api/lazy/manage/data', params);

                const newRate = { id: response.data.data.responseId, name: form.name, criteria: form.criteria, effectiveRate: form.effectiveRate, cumulativeRate: form.cumulativeRate };
                dispatch({ type: 'add', rate: newRate, isLoading: false });
                setIsCancelButton(false);

            } catch (error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
                alert('Error to create new rate');
                dispatch({ type: 'default' });

            }
        }
        reset();
    }

    const errorMessages = {
        effectiveRate: {
            required: 'This field can not be empty',
            pattern: ' Enter value with 2 decimal places only'
        },
        cumulativeRate: {
            pattern: ' Enter value with 2 decimal places only.'
        }
    }

    const Form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TableData width='320px' style={{ verticalAlign: 'top' }}>
                <Input
                    name='name'
                    ref={register({ required: 'This field can not be empty' })}
                    error={errors.name && errors.name.message}
                    onChange={handleOnChange}
                />
            </TableData>

            <TableData width={'160px'} style={{ verticalAlign: 'top' }}>
                <Dropdown
                    name='criteria'
                    placeholder='Select Criteria'
                    openTo={'top'}
                    visibleOptionsQty={20}
                    size='small'
                    ref={register({ required: 'This field can not be empty' })}
                    error={errors.criteria && errors.criteria.message}
                    defaultValue={selected ? selected.criteria : ''}
                    options={criteria.map((item) => { return { name: item.name, value: item.name } })}
                    onChange={handleOnChange}
                />
            </TableData>

            <TableData width={'160px'} style={{ verticalAlign: 'top' }}>
                <Input
                    size='extraSmall'
                    name='effectiveRate'
                    ref={register({
                        required: true,
                        pattern: patternFloatValue,
                    })}
                    error={errors.effectiveRate && errorMessages.effectiveRate[errors.effectiveRate.type]}
                    valid={!errors.effectiveRate && effectiveRate && effectiveRate.length > 0}
                    onChange={handleOnChange}
                />
            </TableData>

            <TableData width={'180px'} style={{ verticalAlign: 'top' }}>
                <Input
                    size='extraSmall'
                    name='cumulativeRate'
                    defaultValue=''
                    ref={register({
                        required: false,
                        pattern: patternFloatValue,
                    })}
                    error={errors.cumulativeRate && errorMessages.cumulativeRate[errors.cumulativeRate.type]}
                    valid={!errors.cumulativeRate && cumulativeRate && cumulativeRate.length > 0}
                    onChange={handleOnChange}
                />
            </TableData>

            <TableData width='150px' style={{ verticalAlign: 'top' }}>
                <Button type='primary' style={{ 'margin-right': '10px' }}>{selected ? 'Save' : 'Add'}</Button>
                {isCancelButton || selected ? <Button type={'cancel'} onClick={() => { cancelBtn() }} >
                    <Icon.Times size={'20px'} style={{ verticalAlign: 'middle' }} />
                </Button> : ''}
            </TableData>
        </form>
    );

    return index > -1 ? (
        <li>
            {Form}
        </li>
    ) : (
            <TableFooter style={{ padding: 0 }}>
            <IsGranted feature={AccessCheck.features.ST_RATE_CARD} ops={AccessCheck.OPS.U}>
                {Form}
            </IsGranted>    
            </TableFooter>
        );
}

export default Rate;
