import React, { useContext, useEffect, useReducer } from 'react';
import { CheckBoxCustom } from "../../index";
import ShiftTypeBox from "../ShiftTypeBox";
import { Grid } from "../../../index";
import moment from "moment";
import { SchedulesAddStateContext } from "../../../index";
const ShiftType = ({ elem, week, row, register, days, shifts }) => {
    const { state } = React.useContext(SchedulesAddStateContext);

    const handleOverlap = async (name, index, dayName) => {
        let checkboxObj = document.querySelector("[id='" + name + "']");
        if (checkboxObj.checked) {
            let cnt = 1;
            let tempDate = new Date();

            // copy recurring week data logic
            let days = {
                "Sun": 0,
                "Mon": 0,
                "Tue": 0,
                "Wed": 0,
                "Thu": 0,
                "Fri": 0,
                "Sat": 0
            };
            if (state.information.recurring === false && week === 1) {
                let shiftDays = [];
                Object.keys(shifts).forEach(function (index) {
                    let dayArray = {};
                    if (state.information.recurring === false) {
                        Object.keys(days).forEach(function (day) {
                            let ck = document.querySelector("[id='week[0][" + (index) + "][days]." + day + "']").checked;
                            dayArray[day] = ck == true ? 1 : 0;
                        });
                        shiftDays[shifts[index].id] = dayArray;
                    }
                });
                state.weekData = shiftDays;
            }
            // end copy recurring week data logic

            let daysCounts = 0;
            Object.keys(shifts).forEach(function (index) {
                let startTime = moment(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + shifts[index].startTime + ':00').utc().format('HH:mm');
                let endTime = moment(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + shifts[index].endTime + ':00').utc().format('HH:mm');
                let shiftStartTime = moment(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + elem.startTime + ':00').utc().format('HH:mm');
                let shiftEndTime = moment(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + elem.endTime + ':00').utc().format('HH:mm');;

                if (document.querySelector("[id='week[" + (week - 1) + "][" + (index) + "][days]." + dayName + "']").checked) {
                    if (shifts[index].id !== elem.id) {
                        if (startTime == shiftStartTime || endTime == shiftEndTime || startTime == shiftEndTime || endTime == shiftStartTime || startTime <= shiftStartTime && shiftStartTime <= endTime || startTime <= shiftEndTime && shiftEndTime <= endTime || shiftStartTime <= startTime && startTime <= shiftEndTime || shiftStartTime <= endTime && endTime <= shiftEndTime) {
                            alert('You have two or more shifts that overlap each other. Please review and make sure that two shifts do not overlap.');
                            // checkboxObj.click();
                            checkboxObj.checked = false
                            return false;
                        }
                    }
                    cnt++;
                }

                Object.keys(days).forEach(function (day) {
                    if (document.querySelector("[id='week[" + (week - 1) + "][" + (index) + "][days]." + day + "']").checked) {
                        daysCounts++;
                    }
                });
            });
            if (daysCounts > 6) {
                alert('7 days not selected');
                // checkboxObj.click();
                checkboxObj.checked = false
                return false;
            }

        }
    }

    return (<Grid templateColumns='196px repeat(7, 104px)' templateRows='60px' height='100%'>
        <ShiftTypeBox name={elem.name} range={elem.range} row={week} col={row} color={elem.color} category={elem.category} start={elem.startTime} end={elem.endTime} startOnDst={elem.startTimeOnDst} endOnDst={elem.endTimeOnDst} stationTimeZone={state.station.timezone}></ShiftTypeBox>
        {elem.days && elem.days.map((item, index) => {
            var entry = Object.entries(item)[0];
            return (<Grid templateColumns='auto' padding='0 0 0 40px' key={`${week}${row}${index}x400`} >
                <CheckBoxCustom
                    name={`week[${(week - 1)}][${row}][days]`}
                    title={''}
                    ref={register({ required: false })}
                    options={[{ value: entry[0], label: '' }]}
                    defaultValues={[entry[1] === 0 ? '' : entry[0]]}
                    onClick={() => { handleOverlap(`week[${(week - 1)}][${row}][days].` + entry[0], row, entry[0]) }}
                ></CheckBoxCustom>
            </Grid>)
        })
        }
        {!elem.days && days.map((item, index) => {
            var entry = Object.entries(item)[0];

            return (<Grid templateColumns='auto' padding='0 0 0 40px' key={`${week}${row}${index}x500`} >
                <CheckBoxCustom
                    name={`week[${(week - 1)}][${row}][days]`}
                    title={''}
                    ref={register({ required: false })}
                    options={[{ value: entry[0], label: '' }]}
                    defaultValues={[entry[1] === 0 ? '' : entry[0]]}
                    onClick={() => { handleOverlap(`week[${(week - 1)}][${row}][days].` + entry[0], row, entry[0]) }}
                ></CheckBoxCustom>
            </Grid>)
        })
        }

    </Grid>)
};

export default React.memo(ShiftType);