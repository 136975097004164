import React, {useEffect, useState} from "react";
import { SubTitle, Button, Spacer } from "@dspworkplace/ui";
import DashboardDragField from './dashboardDragField';
import DashboardItem from './dashboardItem';
import { AutoSizer } from "react-virtualized";
import { alert } from '../../../components/Alert';
import API from '../api';
import { useAuth } from "../../../Auth";
import { getSelectedStationCode } from "../../../Utilities";

const defaultLayout = (c,id) => ({
    x: c ? c.x : 0,
    y: c ? c.y : 0,
    w: c ? c.w : 1,
    h: c ? c.h : 2,
    minW: c ? c.minW || 1 : 1,
    minH: c ? c.minH || 1 : 2
});

const Dashboard = ({ id, name, charts, panelId, onAddChart, onCreateChart, onUpdateCardConfig, onEditChart, onDeleteChart, options, dispatch }) => {
    const [attendanceSetting, setAttendanceSetting] = useState([]);
    const { api } = useAuth();

    const handleAddChart = async () => {
        try {
            await onAddChart(panelId, defaultLayout());
        } catch(e) {
            alert({ text: e.message, icon:true });
        }
    };

    const handleCreateChart = async () => {
        try {
            await onCreateChart(panelId, defaultLayout());
        } catch(e) {
            alert({ text: e.message, icon:true });
        }
    };

    const removeACRTimeDimension = (timeDimension) => {
        const allowedDateRange = ['Today', 'Yesterday', 'This week', 'This month', 'This quarter',
            'This year', 'Last 7 days', 'Last 14 days', 'Last 30 days', 'Last 42 days', 'Last 90 days', 'Last week', 'Last month',
            'Last quarter', 'Last year', 'Next 30 Days'];
        const newTimeDimensions = timeDimension.filter(item => {
            if (item?.dateRange && (allowedDateRange.includes(item.dateRange) || Array.isArray(item.dateRange))) {
                return item;
            } else {
                delete item.dateRange;
                return item;
            }
        })
        return newTimeDimensions;
    }

    const getDefaultStationFilter = (schema = '', filters = []) => {
        if (filters.length > 0 && filters.some(f => f.member === `${schema}.station`)) {
            return filters;
        } else {
            const newFilters = [...filters]
            newFilters.push({
                member: `${schema}.station`,
                values: [getSelectedStationCode()],
                operator: "equals"
            })
            return newFilters;
        }
    }

    const getChartItem = (c) => {

        if (c?.chartQuery?.timeDimensions?.length > 0) {
            c.chartQuery.timeDimensions = removeACRTimeDimension(c.chartQuery.timeDimensions)
        }
        if (c?.chartDetailQuery?.timeDimensions?.length > 0) {
            c.chartDetailQuery.timeDimensions = removeACRTimeDimension(c.chartDetailQuery.timeDimensions);
        }
        c.chartQuery.filters = getDefaultStationFilter(c?.chartConfig?.schema, c?.chartQuery?.filters);
        return c;

    }

    useEffect(()=>{
        API.getAttendanceSetting().then(
            resolve => {
                setAttendanceSetting(resolve.data.data.attendanceSettings);
            },
            error => {

            }
        );
    },[]);

    return (
        <div>
            <div style={{padding:'16px 20px',background:'#FAFAFA',borderBottom:'1px solid #EAF0F6',display:'flex',justifyContent:'space-between'}}>
                <SubTitle>{name}</SubTitle>
                <div>
                    <Spacer inline right={2}>
                        <Button
                            type={'default'}
                            onClick={handleCreateChart}
                        >
                            {options.createChart}
                        </Button>
                    </Spacer>
                    <Button
                        type={'primary'}
                        onClick={handleAddChart}
                    >
                        {options.chartLibrary}
                    </Button>
                </div>
            </div>
            <AutoSizer style={{minHeight:'88vh',width:'100%'}}>
                {({width }) => {
                    if(width < 1) return;
                    return (
                        <DashboardDragField
                            width={width}
                            dashboardItems={charts}
                            panelId={panelId}
                            onUpdateCardConfig={onUpdateCardConfig}
                        >
                            {(charts || []).map((c,k)=> {
                                const grid = defaultLayout(c.cardConfig);
                                return (
                                    <div key={`dashitem-${c.id}`} data-grid={grid}>
                                        <DashboardItem
                                            item={getChartItem(c)}
                                            i={k}
                                            panelId={panelId}
                                            showTools
                                            onEditChart={onEditChart}
                                            onDeleteChart={onDeleteChart}
                                            chartLayout={grid}
                                            dispatch={dispatch}
                                            attendanceSetting={attendanceSetting}
                                            name={c}
                                            api={api}
                                        />
                                    </div>
                                )
                            })}
                        </DashboardDragField>
                    )
                }}
            </AutoSizer>
        </div>
    );
}

export default Dashboard;