import React from "react";
import ReactDOM from 'react-dom';
import Modal from "../../components/Modal";
import {Theme, Icon, Button, SubTitle, Text, Spacer} from "@dspworkplace/ui";
import Parser from 'html-react-parser';

const modalRoot = document.getElementById('modal-root');

const Alert = ({title, text, icon, success, btnText}) => {
    return (
        <Modal
            width='340px'
            removeHeader={true}
            visible={true}
            setVisible={success}
            closeOnOverlay={true}
        >
            <div style={{textAlign: 'center'}}>
                {icon && <Spacer bottom={5} top={3}><Icon.Warning size='60px' color={Theme.colors.warnings.text}/></Spacer>}
                {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                <Text>{Parser(text)}</Text>
                <Spacer bottom={5}/>
                <Button onClick={success}>{btnText}</Button>
            </div>
        </Modal>
    )
};

const hide = ()=> setTimeout( ()=> {
    ReactDOM.unmountComponentAtNode( modalRoot );
}, 1 );

const alert = async ( opts ) => {
    let title, text, icon;
    let btnText = "Ok";
    if ( typeof opts == 'object' ) {
        title = opts.title;
        text  = opts.text;
        icon  = opts.icon;
        btnText = opts.btnText;
    } else {
        title = icon = false;
        text  = opts;
    }

    return new Promise( ( resolve, reject ) => {
        const success = data => hide() && resolve( true );

        return ReactDOM.render( (
            <Alert
                success={success}
                title={title}
                text={text}
                icon={icon}
                btnText={btnText}
            />
        ), modalRoot );
    });
};

export {alert};