import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : 'auto'};
    grid-column-gap: ${props=>props.columnGap ? props.columnGap : '0'};
    grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
    grid-row-gap: ${props=>props.rowGap ? props.rowGap : '0'};
    justify-content: ${props=>props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    border: ${props=>props.border? props.border : '0'};
    border-radius: ${props=>props.borderRadius ? props.borderRadius : 'unset'};
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : 'unset'};
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    font-size: ${props=>props.fontSize ? props.fontSize : '14px'};
    color: ${props=>props.color ? props.color : '#333333'};
`;

const SkillCell = ({skills, padding}) => {

    return (
            <Grid templateColumns='repeat(auto-fill, minmax(50px, auto))' rowGap='4px' columnGap='4px' padding={padding}>
                {  skills && skills.map((item, index)=>
                    {
                        return <Grid key={index*123} padding='0 4px 0 4px' justifyContent='center' borderRadius='2px' fontSize='12px' color='#516F90' border='1px solid #7C98B6' backgroundColor='#EAF0F6'>{item.skill.name}</Grid>
                    })
                }
            </Grid>
    );
}

export default React.memo(SkillCell);
