const AppReduce = (state,action) => {
    const {type, payload} = action;

    switch (type) {
        case 'ADD':
            state[payload.context] = {
                data:payload.groups,
                filterData:[],
                filter:sortBy(filterByStation(payload.groups, state.selectedStation), state[payload.context].sortedBy[0]),
                isLoading:false,
                sortedBy:state[payload.context].sortedBy
            }
            state.rules = payload.rules;
            return {...state,
                station:payload.station
            }
        case 'GROUP':
            state.grouped = payload;
            return {...state}
        case 'DELETE_RULE':
            state.rules.splice(payload,1);
            return {...state};
        case 'ADD_RULE':
            state.rules.push(payload);
            return {...state}
        case 'EDIT_RULE':
            state.rules[payload.index] = payload.data;
            return {...state}
        case 'LOADING':
            if(payload.context) state[payload.context].isLoading = payload.status;
            if(!payload.context){
                state['current'].isLoading = payload;
                state['next'].isLoading = payload;
            }

            return {...state}
        case 'WEEK':
            return {...state,week:payload.week, isLoading: true}

        case 'SORT':
            let sort = state[payload.context].sortedBy[0];

            if ( !sort )
                sort = {field: payload.sortBy, direction: 0}
            else
            if ( payload.sortBy === sort.field )
                sort.direction = !sort.direction
            else
                sort = {
                    field: payload.sortBy,
                    direction: 0,
                }

            let data = sortBy(filterByStation(state[payload.context].data,state.selectedStation), sort);
            let dataContext = state[payload.context];
            dataContext.filter = data;
            dataContext.sortedBy = [sort];

            state[payload.context] = dataContext;

            return {...state};

        case 'FILTER':

            let current = state['current'];
            let next = state['next'];

            current.filter = sortBy(filterByStation(current.data, payload.station),payload.station)
            current.isLoading = false;

            next.filter = sortBy(filterByStation(next.data, payload.station),payload.station)
            next.isLoading = false;


            return {...state,selectedStation: payload.station,current:current,next:next}
        default: return state;
    }
}

export default AppReduce;

const sortBy = ( collection, criteria ) => {
    collection.sort((a, b) => {
        a.total = [a.w0.routes, a.w1.routes, a.w2.routes, a.w3.routes, a.w4.routes, a.w5.routes, a.w6.routes]
            .reduce((a,b) => a + b, 0)
            .toString();

        if ( a.hasOwnProperty(criteria.field) && typeof a[criteria.field] === 'string' )
            return a[criteria.field].localeCompare(b[criteria.field], undefined, {numeric: true});
    });

    if ( criteria.direction )
        collection.reverse();

    return collection;
}

const filterByStation = (collection, station) => {
    if ( !station || station === 'all' )
        return collection;
    else
        return collection.filter(b => b.station === station);
}