const ScorecardReducer = (state, action) => {
    const {type, payload, callback} = action;

    switch (type) {
        case 'ADD':
            return {
                ...state,
                ...payload
            };

        case 'FETCHING':
            return {
                ...state,
                loading: {
                    ...(state.loading ?? {}),
                    [payload]: true
                },
            };

        case 'READY':
            return {
                ...state,
                ...payload,
                loading: {
                    ...(Object.keys(payload).reduce((all, next) => {
                        all[next] = false;
                        return all;
                    }, state.loading ?? {}))
                },
            };

        case 'ERROR':
            let newState = ScorecardReducer(state, {
                type: 'READY',
                payload: payload,
            });

            return {
                ...newState,
                error: {
                    ...(Object.keys(payload).reduce((all, next) => {
                        all[next] = payload[next];
                        return all;
                    }, state.error ?? {}))
                },
            };
        case 'UPDATE_DOCUMENT_STATUS':
            state.documents.forEach((document, index) => {
                if (document.id === payload.id) {
                    state.documents[index].status = payload.status;
                }
            });
            state.allDocuments.forEach((document, index) => {
                if (document.id === payload.id) {
                    state.allDocuments[index].status = payload.status;
                }
            });
            return {...state}
        case 'NEW_DOCUMENT':
            state.documents.unshift(...payload);
            state.allDocuments.unshift(...payload);
            return { ...state };
        default:
            return state;
    }
};

export default ScorecardReducer;