import React, {useContext, useEffect, useState, useRef, useCallback} from 'react';
import {Icon, Spacer, Theme} from "@dspworkplace/ui";
import Store from "./store";


const HeaderColumnComponent = ({Wrapper,label,colWidth,popUpDirection,options,dataKey,resetTopPosition}) => {
    const [state,dispatch] = useContext(Store);
    const [open, setOpen] = useState(false);

    const popupRef = useRef();

    const handleClick = useCallback((e) => {
        if (popupRef.current && popupRef.current.contains(e.target)) {
            return
        }
        setOpen(false);
    },[])

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const arrowStyle = { position: 'top', top: '2px' };

    return (
        <div ref={popupRef} onClick={()=>{setOpen(!open);}} style={{width:'100%',padding:'8px',display:'flex',justifyContent:'space-between',borderBottom:'1px solid #ccc',cursor:'pointer'}}>
            {label}
            { open ? <Icon.ArrowUp size='16px' color={Theme.colors.info.border} style={arrowStyle} /> : <Icon.ArrowDown size='16px' color={Theme.colors.info.border} style={arrowStyle} />}
            {open &&  <Wrapper style={{minWidth:colWidth+1}} direction={popUpDirection}>
                <li className='title' style={{cursor:'default'}}>Order By</li>
                {options.map((item,key)=> {
                        return (<li key={key}   onClick={() => {
                            dispatch({
                                type: 'ORDER',
                                payload: {mainFields: item.fields}
                            });
                        }}>{item.label}</li>)
                    }
                )}
            </Wrapper>}
        </div>
    )
}

const calculateShiftSizes = (array,isBackup=false,open,sizes) => {
    let result = array;
    if(isBackup){
        //console.log('ARRAY',array)
        //if its backup has to separate all shifts
        result = array.reduce((arr,el,index)=> {
            const shifts = el.shifts.map(item => {item.driverId = el.id; item.driverIndex=index; return item})
            Array.prototype.push.apply(arr,shifts);
            return arr;
        },[])
    }else{
        //if its open shift order by start time by default
        result = array.sort((a,b)=> a.startTime - b.startTime);
    }

    let resultLength = result.length;
    let backupReference = 'shift';
    if(isBackup && (array.length >= resultLength || open)){  //if is backup and has more drivers then shift use driver length
        resultLength = array.length;
        backupReference = 'driver';
    }

    if(!open){
        //if the cell its not open i need count only different type of shifts
        if(isBackup){
            resultLength = result.reduce((totalShift,shift)=>{
                const exist = checkIfShiftAlreadyExist(totalShift,shift,'backup');
                if(exist < 0) totalShift.push(shift);
                return totalShift;
            },[]).length;
        }else{
            resultLength = result.reduce((totalShift,shift)=>{
                const exist = checkIfShiftAlreadyExist(totalShift,shift);
                if(exist < 0) totalShift.push(shift);
                return totalShift;
            },[]).length;
        }

    }

    let sizeHeader;
    if(isBackup){
        if(open){
            sizeHeader = (resultLength * sizes.defaultClose) + 66;
        }else{
            sizeHeader = (resultLength * sizes.defaultClose);
        }
        if(sizeHeader <= sizes.backupHeader) sizeHeader = sizes.backupHeader;
    }else{
        sizeHeader = resultLength * sizes.defaultClose ;
        if(sizeHeader <= sizes.defaultClose) sizeHeader = sizes.defaultClose;
    }


    return {qty:resultLength,shifts:result,size:sizeHeader};
}

const checkIfShiftAlreadyExist = (array,shift,type='open') => {
    return array.findIndex((el) => {
        if(type === 'open')
            return el.startTime == shift.startTime && el.typeId == shift.typeId && el.endTime == shift.endTime;

        if(!el) return false;
        return el.typeId == shift.typeId;
    });
}

let internalKey = {open:-1,backup:-1};
const getShiftFormatted = (array,type,dataKey,open=false,firstKey) => {
    //if(![6,7].includes(dataKey)) return []

    if(dataKey === firstKey) {internalKey[type] = 0;}

    const ret = array.reduce((filterShifts,item,key)=>{
        const [hour,min] = item.startTime.toFixed(2).toString().split('.');
        if(dataKey == hour)
        {
            item.originalKey=key;

            const sameTime = checkIfShiftAlreadyExist(filterShifts,item);
            if(!open) {
                if (sameTime >= 0) {
                    filterShifts[sameTime].qty += 1
                } else {
                    item.key = internalKey[type]++;
                    item.qty = 1;
                }
            }else{
                item.qty = 1;
                if(type === 'backup'){
                    item.key = item.driverIndex;
                }else{
                    item.key = internalKey[type]++;
                }
            }

            let shouldPush = true;
            if(!open && sameTime >= 0) shouldPush = false;
            if(shouldPush) filterShifts.push(item);
        }
        return filterShifts;
    },[]);


    return ret;
}

const ShiftWrapper = (endTime,startTime,columnWidth)=>{
    //startTime = parseFloat(startTime);
    //endTime = parseFloat(endTime);

    let [endHour,endMin] = endTime.toFixed(2).toString().split('.');
    let [startHour,startMin] = startTime.toFixed(2).toString().split('.');
    if(!endMin) endMin=0;
    if(!startMin) startMin=0;

    startMin = parseInt(startMin);
    endMin = parseInt(endMin);

    startMin = startMin/60;
    endMin = endMin/60;

    const distance = parseInt(endHour) - parseInt(startHour);
    let widthCol = distance*columnWidth + (endMin * columnWidth);

    // const start_pl = startMin > 0 && ((startMin / columnWidth )* 100) * (columnWidth / 100);
    const start_pl = columnWidth * startMin;
    // let end_pr = ((endMin / columnWidth )* 100) * (columnWidth / 100);
    // if(end_pr > 0) {
    //     //end_pr = columnWidth - end_pr;
    //     widthCol += end_pr;
    // }

    return {
        marginLeft: start_pl,
        marginRight: 0,
        distance: distance,
        columnWidth: widthCol
    };
}


export {
    HeaderColumnComponent,
    calculateShiftSizes,
    checkIfShiftAlreadyExist,
    getShiftFormatted,
    ShiftWrapper
};
