import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Input, Spacer, SubTitle, Dropdown, Toggle} from "@dspworkplace/ui";
import {useAuth} from "../../../Auth";
import SettingsContext from "../context";
import {toast} from "../../../components/Toasts";
import { AccessCheck, IsGranted } from "../../../security";

const App = () => {
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const {register, handleSubmit, reset} = useForm();
    const [defaultWeeks,setDefaultWeeks] = useState(parseInt(localStorage.getItem('recurringWeeks')));

    useEffect(() => reset(state.company), [state.company]);

    let employeeStatusArr = [];
    for(let week = 1; week<=15; week++){
        employeeStatusArr.push({'name':week.toString(),'value':week.toString()});
    }
    //let defaultWeeks = parseInt(localStorage.getItem('recurringWeeks'));
    const getRecurringWeek = async() => { 
        const params={
            "actions": {
            "response": {
                "Company": {
                    "custom": {
                        "functionName":"getRecurringWeeks",
                        "get":"recurringWeek",
                        "criteria": {
                        "id":localStorage.getItem("company")
                        }
                    }
                }
            }
            }
        }
        let companyRecurringWeeks = await api.post('/api/lazy/manage/data', params);
        setDefaultWeeks(companyRecurringWeeks.data.data.recurringWeek);
    }    
    useEffect(() => {
        getRecurringWeek();
    }, []);
   
    const handleRecurringWeekSetting = ( data, evt ) => {
        let form = evt.target;
        form.querySelector('button').disabled = true;
        
        const updateRecord = {
            recurringWeeks: parseInt(data.recurringWeeks),
            postAsUnpublish:data.postAsUnpublish
        }

        api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    Company: {
                        update_company: {
                            findBy: {
                                id: parseInt(state.company.id)
                            },
                            updateRecord: updateRecord
                        }
                    }
                },
                response: {
                    Schedule: {
                        custom: {
                            functionName: "sendWeekChangeMail",
                            criteria: {
                                company: state.company.id,
                                newWeek: data.recurringWeeks,
                                oldWeek:localStorage.getItem("recurringWeeks")

                            },
                            get: "recurring"
                        },
                    }
                }
            }
        }).then(
            response => {
                localStorage.setItem("recurringWeeks",parseInt(data.recurringWeeks));
                setDefaultWeeks(parseInt(data.recurringWeeks));

                toast({
                    type: 'success',
                    title: 'Recurring Week Setting',
                    content: 'Recurring Week Settings updated.'
                });

                dispatch({type: 'UPDATE_COMPANY', payload: {company: {...state.company, ...updateRecord}}})
            }    

        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    return (
        <form onSubmit={handleSubmit(handleRecurringWeekSetting)}>
            <SubTitle>Recurring Week Settings</SubTitle>
            <Spacer bottom={3}/>
            <Dropdown
                  name='recurringWeeks'
                  placeholder='Select Recurring Weeks'
                  ref={register()}
                  label='Select Recurring Weeks'
                  options={employeeStatusArr}
                  defaultValue={defaultWeeks}
              />
            <Spacer bottom={3}/>
            <Toggle
                ref={register}
                name='postAsUnpublish'
                singleText='Post but do not publish' //postAsUnpublish
            />
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default App;