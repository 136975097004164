class Filter {
    constructor(fieldsDefinition,data,state) {
        this.fieldsDefinition = fieldsDefinition;
        this.data = data;
        this.state = [...state];
        return this.apply();
    }

    query =  {
        string: (stateValue,filterValue) => {
            const stateV = typeof stateValue === 'object' ? JSON.stringify(stateValue) : stateValue;
            return RegExp(filterValue,'ig').test(stateV);
        },
        exactString: (stateValue,filterValue) => {
           return stateValue.toLowerCase() === filterValue.toLowerCase();
        },
        array: (stateValue,filterValue) => {
            return stateValue.includes(filterValue);
        },
        object: (stateValue,filterValue) => {
            return Object.keys(stateValue).some(key => {
                return stateValue[key] == filterValue
            });
        }
    }

    apply = () => {
        const filterKeys = Object.keys(this.fieldsDefinition);
        return this.state.filter(obj => {
            const checkFilter = filterKeys.map(key => {
                const type = this.fieldsDefinition[key];
                if(type !== 'function' && !type){
                    return false;
                }

                const stateValue = obj[key];
                const filterValue = this.data[key];

                //if(!stateValue) return false;

                if (key == 'status' && (filterValue === 'all' || !filterValue)) {
                    if(stateValue === 'Archived'){
                        return false;
                    }else{
                        return true;
                    }
                }

                if(key == 'category' && filterValue != 'all'){
                    let categoryIds = stateValue.map((i)=>i.id.toString());
                    if(categoryIds.includes(filterValue.toString())){
                        return true;
                    }else{
                        return false;
                    }
                }

                if(!filterValue || filterValue === 'all' || filterValue == -1) return true;

                if(typeof type === 'function'){
                    return type(stateValue,filterValue);
                }else if(type === 'multiString'){
                    return this.query.string(obj,filterValue);
                } else{
                    return this.query[type](stateValue,filterValue);
                }
            })
            return checkFilter.every(result => result === true);
        }
        )
    }

}

export default Filter;