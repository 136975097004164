import React, {useMemo, useState} from 'react'
import {Icon} from "@dspworkplace/ui";
import { openCreateForm } from './Common/CreateForm';
import { openLocationForm } from './Common/LocationForm';
import {DataGrid} from "../../../../components/Grid";
import {GridAction} from "../../../../components/Grid/formatters";
import {VehicleMaintenanceVendor} from "../../../../domain/vehicle_maintenance_vendor/types";
import useGridApi from "../../../../api/useGridApi";
import { confirm as confirmPortal } from "../../../../components/Confirm";
import {GetMaintenanceVendors} from "../../../../domain/vehicle_maintenance_vendor/api/getVendors";
import {CreateVehicleVendor, DeleteVehicleVendor, UpdateVehicleVendor} from "../../../../domain/vehicle_maintenance_vendor/api/CreateVehicleVendor";
import { useSelectedStation } from "../../../../components/StationSelector/StationPicker";

const getColumns = (handleDeleteVendor, handleEditVendor,handleEditLocation) => {
    return [
        {
            name: "Name",
            key: "name",
        },
        {
            name: "Phone",
            key: "phone"
        },
        {
            name: "Email",
            key: "email"
        },
        {
            name: "Location",
            key: "location"
        },
        {
            key: 'actions',
            name: '',
            width: 40,
            formatter:({row})=>{
                return (
                    <GridAction options={[
                        {title: 'Edit', icon: Icon.Edit, onClick: ()=>{handleEditVendor(row)}},
                        {title: 'Delete', icon: Icon.Times,onClick: ()=>{handleDeleteVendor(row)}},
                    ]} />
                )
            }
        }
    ]
}

const MaintenanceVendorApp = () => {
    const vendorState = useState<VehicleMaintenanceVendor[]>([]);
    const [selectedStation] = useSelectedStation();

    const {data,status,Update, Add, Delete} = useGridApi({load: GetMaintenanceVendors,  state: vendorState})
    
    const handleNewVendor = async () => {
        const formResult = await openCreateForm()
        if(!formResult) return

        const params = {
            name: formResult.name,
            phone: formResult.phone,
            location: formResult.location,
            email: formResult.email,
            station_id:selectedStation
        }

        Add(CreateVehicleVendor,params,`Error to create new manual vendor`)
    }
    
    const columns = useMemo(()=>{
        const handleEditVendor = async (vendor) => {
            const formResult = await openCreateForm(vendor)
            if(!formResult) return
    
            const params = {
                id: vendor.id,
                name: formResult.name,
                phone: formResult.phone,
                location: formResult.location,
                email: formResult.email,
                station_id:selectedStation
            }
            
            Update(UpdateVehicleVendor(vendor.id, params), vendor._INDEX, vendor, vendor, `Error to update vendor ${vendor.id}`)
        }
        const handleEditLocation = async (vendor) => {
            const formResult = await openLocationForm(vendor)
            if(!formResult) return
    
            const params = {
                id: vendor.id,
                name: formResult.name,
                phone: formResult.phone,
                location: formResult.location,
                email: formResult.email,
                station_id:selectedStation
            }
            
            Update(UpdateVehicleVendor(vendor.id, params), vendor._INDEX, vendor, vendor, `Error to update vendor ${vendor.id}`)
        }
    
        const handleDeleteVendor = (row) => {
            // openVendorArchiveForm(row.name).then(resolve => {
            //     if(!resolve) return;
                
                Delete(DeleteVehicleVendor, row, `Error to delete the vendor "${row.name}"`)
            // })
        }
        return getColumns(handleDeleteVendor, handleEditVendor,handleEditLocation)
    }, [Delete, Update, DeleteVehicleVendor, selectedStation, UpdateVehicleVendor])

    return (
        <DataGrid
            title={'Maintenance Vendors'}
            status={status}
            rows={data}
            columns={columns}
            onAdd={handleNewVendor}
        />
    )
}

export const openVendorArchiveForm = async (name) => {
    return confirmPortal({
        icon: true,
        text: `Do you really want to archive Vendor "${name}"`
    });
}

export default MaintenanceVendorApp