import axios from "axios";
import { engine } from "../../../../../Utilities";


const CancelToken = axios.CancelToken;
let canceler;

const competitionRow = {
    0: "id",
    1: "name",
    2: "ranking",
    3: "startDate",
    4: "endDate",
    5: "tieBreakers",
    6: "createdAt",
    teams: {
        0: "id",
        1: "name",
        2: "leaders",
    },
    goals: {
        0: "id",
        1: "goal",
        2: "options",
        3: "driverDescription",
        4: "metricWeight",
        metric: {
            0: "key",
        },
    },
};

const API = {
    current: undefined,
    call(params, endpoint = "/api/lazy/manage/data") {
        API.current = engine()
            .post(endpoint, params, {
                cancelToken: new CancelToken(function executor(c) {
                    canceler = c;
                }),
            })
            .finally(() => (API.current = undefined));

        return API.current;
    },
    cancel() {
        if (API.current) return canceler();
        else return false;
    },
    fetchCompetitions(station, filter) {
        let matching = {};
        if (filter.period) {
            matching["startDate"] = { lte: filter.period };
            matching["endDate"] = { gte: filter.period };
        }
        return API.call({
            actions: {
                response: {
                    TeamCompetitor: {
                        findBy: {
                            get: "competitors",
                            criteria: {
                                station: station,
                            },
                            matching: matching,
                            orderBy: {
                                startDate: "DESC",
                            },
                            includes: competitionRow,
                        },
                    },
                    "Scorecard\\ScorecardGoal": {
                        findBy: {
                            get: "metrics",
                            criteria: {
                                station: station,
                            },
                            includes: {
                                0: "goal",
                                metric: {
                                    0: "key",
                                    1: "name",
                                    2: "description",
                                    3: "options",
                                    4: "metricType",
                                    5: "coaching",
                                    6: "baseType",
                                    7: "source",
                                    8: "sourceAbbr",
                                    9: "timeframe",
                                    10: "description",
                                    11: "driverDescription",
                                    12: "coaching",
                                    13: "onOff",
                                    14: "triggers",
                                },
                            },
                        },
                    },
                },
            },
        });
    },
    createCompetition({ competitionName, station, teamsId = [], startDate, endDate, tieBreakers = [] }) {
        return API.call({
            actions: {
                create: {
                    TeamCompetitor: {
                        competition: {
                            name: competitionName,
                            station: station,
                            teams: teamsId,
                            startDate: startDate,
                            endDate: endDate,
                            tieBreakers: tieBreakers,
                        },
                    },
                },
                response: {
                    findLastInserted: {
                        competition: competitionRow,
                    },
                },
            },
        });
    },
    addCompetitionMetric({ id, goal, metric, weightsUpdateArray, stationId }) {
        const goalRow = {
            type: "TEAM_COMPETITION",
            metric: metric.key,
            options: JSON.stringify(metric.options),
            goal: goal.goal,
            entity: "TeamCompetitor",
            entityId: id,
            status: 1,
            metricWeight: goal.metricWeight,
            station: stationId,
        };
        const weightsUpdates = weightsUpdateArray.reduce((acc, w, k) => {
            acc[`goal_${k}`] = {
                findBy: {
                    id: w.goalId,
                },
                updateRecord: {
                    metricWeight: parseFloat(w.value).toFixed(3),
                },
            };
            return acc;
        }, {});

        const actions = {
            update: {
                "Scorecard\\ScorecardGoal": weightsUpdates,
            },
            create: {
                "Scorecard\\ScorecardGoal": {
                    goal: goalRow,
                },
            },
            response: {
                TeamCompetitor: {
                    findOneBy: {
                        get: "competitors",
                        criteria: {
                            id: id,
                        },
                        includes: competitionRow,
                    },
                },
                findLastInserted: {
                    goal: {
                        0: "id",
                        1: "goal",
                        2: "options",
                        3: "driverDescription",
                        4: "metricWeight",
                        metric: {
                            0: "key",
                        },
                    },
                },
            },
        };
        if (!weightsUpdateArray.length) {
            delete actions.update;
        }

        return API.call({
            actions: actions,
        });
    },
    editCompetitionGoal({ id, goal }) {
        return API.call({
            actions: {
                update: {
                    "Scorecard\\ScorecardGoal": {
                        goal: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                goal: goal.goal,
                                coaching: goal.coaching,
                                options: JSON.stringify(goal.options),
                                metric: goal.metric.key,
                                driverDescription: goal.driverDescription,
                            },
                        },
                    },
                },
            },
        });
    },
    removeCompetitionGoal({ id, weightsUpdate = [] }) {
        const weightUpdates = weightsUpdate.reduce((acc, w, k) => {
            acc[`goal_${k}`] = {
                findBy: {
                    id: w.goalId,
                },
                updateRecord: {
                    metricWeight: parseFloat(w.value).toFixed(3),
                },
            };
            return acc;
        }, {});

        return API.call({
            actions: {
                update: {
                    "Scorecard\\ScorecardGoal": {
                        goal: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                isArchive: true,
                            },
                        },
                        ...weightUpdates,
                    },
                },
            },
        });
    },
    deleteCompetition({ id }) {
        return API.call({
            actions: {
                update: {
                    TeamCompetitor: {
                        competition: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                isArchive: true,
                            },
                        },
                    },
                },
            },
        });
    },
    editCompetition({ id, competition }) {
        return API.call({
            actions: {
                update: {
                    TeamCompetitor: {
                        competition: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                name: competition.name,
                            },
                        },
                    },
                },
            },
        });
    },
    editCompetitionTeams({ id, teams }) {
        return API.call({
            actions: {
                update: {
                    TeamCompetitor: {
                        team: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                teams: teams,
                            },
                        },
                    },
                },
            },
        });
    },
    editCompetitionWeights({ competitionId, goals }) {
        let records = {};

        for (let i = 0; i < goals.length; i++) {
            records["goals_" + i] = {
                findBy: {
                    id: goals[i].goalId,
                },
                updateRecord: {
                    metricWeight: goals[i].value,
                },
            };
        }

        return API.call({
            actions: {
                update: {
                    "Scorecard\\ScorecardGoal": { ...records },
                },
                response: {
                    TeamCompetitor: {
                        findOneBy: {
                            get: "competitors",
                            criteria: {
                                id: competitionId,
                            },
                            includes: competitionRow,
                        },
                    },
                },
            },
        });
    },
    editCompetitionTieBreakers({ competitionId, tieBreakers }) {
        if (typeof tieBreakers === "string") {
            tieBreakers = JSON.parse(tieBreakers);
        }

        return API.call({
            actions: {
                update: {
                    TeamCompetitor: {
                        competition: {
                            findBy: {
                                id: competitionId,
                            },
                            updateRecord: {
                                tieBreakers: JSON.stringify(tieBreakers),
                            },
                        },
                    },
                },
            },
        });
    },
    getAllTeams({ stationId }) {
        return API.call({
            actions: {
                response: {
                    Team: {
                        findBy: {
                            get: "teams",
                            criteria: {
                                station: stationId,
                            },
                            orderBy: {
                                name: "ASC",
                            },
                            includes: {
                                0: "id",
                                1: "name",
                                2: "leaders",
                            },
                        },
                    },
                },
            },
        });
    },
};

export default API;
