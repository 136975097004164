import React, {useCallback, useEffect, useState} from 'react';
import Loading, {useLoading} from "./Loading";
import ModalWindow from "./Modal";
import {useChatStoreReducer} from "./store";
import {FlexScroll, Grid, TagInputContainer} from "./styles";
import {Button, Icon, TagInput} from "@dspworkplace/ui";
import {FilterTagArrayType} from "./types";
import styled from "styled-components";
// import lang from "bad-words/lib/lang.json";
import {setBadWords} from "./utils";
import {showToast} from "../Utilities";

const Row = styled.div`
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 16px;
    grid-template-columns: repeat(5, 118px);
    grid-row-gap: 8px;
`;

const WordContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    border: 1px solid #7C98B6;
    background: #EAF0F6;
    border-radius: 2px;
    padding: 0 8px;
    height: 24px;
    align-items: center;
    color: #516F90;
    font-size: 12px;
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;

    div.smallFont {
        font-size: 8px;
    }

    div.iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 6px #0071BC;
            border-radius: 2px;
        }
    }
`;

const AddRow = styled.div`
    height: 40px;
    width: 100%;
    color: #333;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    input {
        font-family: circe-rounded, serif;
        display: flex;
        box-sizing: border-box;
        width: 300px;
        border-radius: 4px;
        border: 1px solid #EAF0F6;
        font-size: 16px;
        height: 40px;
        margin-right: 16px;
    }
`;

function Search({handleTagChange, filterTagBadWords}) {
    useEffect(() => {
        // console.log({len: filterTagBadWords.length});
    }, [filterTagBadWords.length]);

    return <TagInputContainer width={"658px"} templateRows={"auto"}>
        <TagInput
            label="Search:"
            options={filterTagBadWords}
            size="big"
            onChange={handleTagChange}
            defaultValues={filterTagBadWords}
            name={'names'}
        />
    </TagInputContainer>
}

function ListBadWordsModal() {
    const {state, dispatch} = useChatStoreReducer();
    const {loading, setLoading} = useLoading();
    // @ts-ignore
    const [badWordsObject, setBadWordsObject] = useState();
    // @ts-ignore
    const [filterTagBadWords, setFilterTagBadWords] = useState<Array<FilterTagArrayType>>([]);
    // @ts-ignore
    const [listOfWords, setListOfWords] = useState<Array<string>>([]);
    // @ts-ignore
    const [matchValues, setMatchValues] = useState<Array<string>>([]);
    // @ts-ignore
    const [currentTagData, setCurrentTagData] = useState<Array<FilterTagArrayType>>([]);
    // @ts-ignore
    const [newWord, setNewWord] = useState<string | null | undefined>();

    const closeModal = useCallback(
        () => {
            dispatch({type: "CLOSE_LIST_BAD_WORDS_MODAL"});
        },
        [dispatch],
    );

    const deleteWord = (word) => async () => {
        try {
            setLoading(true);
            let filtered = listOfWords.filter(e => e !== word);
            filtered = filtered.sort((a, b) => a.localeCompare(b));
            setListOfWords(filtered);
            let updatedMatches = matchValues.filter(e => e !== word);
            setMatchValues(updatedMatches);
            state.filter.removeWords(word);
            let filterBagWords = filtered.map(e => ({name: e, value: e, selected: false}));
            setFilterTagBadWords(filterBagWords);
            dispatch({type: "SET_FILTER", filter: state.filter});
            let updatedBadWordsObject = badWordsObject;
            // @ts-ignore
            updatedBadWordsObject['words'] = filtered;
            await setBadWords(updatedBadWordsObject);
            setLoading(false);
        } catch (e) {
            await showToast({
                type: 'Error',
                title: 'Error',
                name: 'Error removing word.'
            });
            console.error({addWord: e});
        }

    };

    const addWord = async () => {
        try {
            if (typeof newWord === 'string' && newWord.length > 0) {
                setLoading(true);
                let updatedBadWordsObject = badWordsObject;
                // @ts-ignore
                updatedBadWordsObject['words'] = [newWord, ...listOfWords];
                // @ts-ignore
                let sorted = updatedBadWordsObject['words'].sort((a, b) => a.localeCompare(b));
                // @ts-ignore
                setListOfWords(sorted);
                // @ts-ignore
                state.filter.addWords(newWord);
                dispatch({type: "SET_FILTER", filter: state.filter});
                await setBadWords(updatedBadWordsObject);
                setNewWord('');
                setLoading(false);
            }
        } catch (e) {
            await showToast({
                type: 'Error',
                title: 'Error',
                name: 'Error adding new word.'
            });
            console.error({addWord: e});
        }
    }

    const handleTagChange = useCallback(
        (data: Array<FilterTagArrayType>) => {
            try {
                setCurrentTagData(data);
                if (Array.isArray(data) && data.length === 0) {
                    setMatchValues(listOfWords)
                } else {
                    let value = data.map(e => e.name);
                    setMatchValues(value);
                }
            } catch (e) {
                console.error({e});
            }
        },
        []
    );

    useEffect(() => {
        try {
            let badWordsString = localStorage.getItem('badWordsObject');
            // @ts-ignore
            let badWordsObject = JSON.parse(badWordsString);
            setBadWordsObject(badWordsObject);
            let badWords: Array<string> = badWordsObject['words'];
            setListOfWords(badWords);
            let filterBagWords = badWords.map(e => ({name: e, value: e, selected: false}));
            setFilterTagBadWords(filterBagWords);
            setLoading(false);
        } catch (e) {
            console.error({e, name: 'ListBadWordsModal()'});
        }

    }, []);

    if (!state.listBadWordsModal) {
        return null;
    }

    if (loading) {
        return (
            <ModalWindow width={"700px"} title={"List of Offensive Words"} cancelModal={closeModal} cancelText={"Close"}>
                <Loading containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"}/>
            </ModalWindow>
        );
    }

    return (
        <ModalWindow width={"700px"} title={"List of Offensive Words"} cancelModal={closeModal} cancelText={"Close"}>
            <Grid templateRows={"auto"} rowGap={"16px"}>
                <Search filterTagBadWords={filterTagBadWords} handleTagChange={handleTagChange}/>
                <FlexScroll height={matchValues.length > 0 ? "100px" : '0'} width="100%">
                    <Row>
                        {matchValues.map(e => {
                            return <WordContainer key={e}>
                                <div className={e.length > 17 ? 'smallFont' : ''}>
                                    {e}
                                </div>
                                <div className={'iconContainer'}>
                                    <Icon.Times
                                        size={"16px"}
                                        onClick={deleteWord(e)}
                                    />
                                </div>
                            </WordContainer>
                        })}
                    </Row>
                </FlexScroll>
                <FlexScroll height={"240px"} width="100%">
                    <Row>
                        {listOfWords.map(e => {
                            return <WordContainer key={e}>
                                <div className={e.length > 17 ? 'smallFont' : ''}>
                                    {e}
                                </div>
                                <div className={'iconContainer'}>
                                    <Icon.Times
                                        size={"16px"}
                                        onClick={deleteWord(e)}
                                    />
                                </div>
                            </WordContainer>
                        })}
                    </Row>
                </FlexScroll>
                <AddRow>
                    <input
                        className={'newWord'}
                        name="newWord"
                        defaultValue={newWord ? newWord : ''}
                        onChange={(e) => {
                            if (e.target && e.target.value) {
                                setNewWord(e.target.value);
                            } else {
                                setNewWord('');
                            }
                        }}
                    />
                    <Button type="primary" Type="submit" onClick={addWord} className={'addWord'}>
                        Add
                    </Button>
                </AddRow>
            </Grid>
        </ModalWindow>
    );
}

function ListBadWordsModalContainer() {
    const {state} = useChatStoreReducer();
    if (!state.listBadWordsModal) {
        return null;
    }
    return <ListBadWordsModal/>
}

export default ListBadWordsModalContainer;