import React, {useState} from 'react';
import LoadingAnimation from '../../assets/dsp-loading.svg';
import styled from "styled-components";

const LoadingWrapper = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.7);
    z-index:10;
    
    >img {
        width: 40px;
        position:absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
`;


const useLoading = (initialState = true) => {
    const [isLoading, setInternalLoading] = useState(initialState);

    const setLoading = (status) => {
        setInternalLoading(status);
    };

    return [isLoading, setLoading];

};

const Loading = ({...props}) => (<img src={LoadingAnimation} {...props} alt='loading'/>);


const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : '0'};
    height: ${props => props.containerHeight ? props.containerHeight : '100%'};
    width: ${props => props.containerWidth ? props.containerWidth : '100%'};
    
    img {
        height: ${props => props.imgHeight ? props.imgHeight : 'auto'};
        width: ${props => props.imgWidth ? props.imgWidth : '100px'};
    }
`;

const LoadingView = ({containerHeight = null, containerWidth = null, imgHeight = null, imgWidth = null}) => {
    return (
        <LoadingContainer containerHeight={containerHeight} containerWidth={containerWidth} imgHeight={imgHeight}
                          imgWidth={imgWidth}>
            <img src={LoadingAnimation} alt='loading'/>
        </LoadingContainer>
    );
};

export {useLoading, LoadingView, LoadingWrapper};
export default Loading;
