import moment from "moment";
import { confirm } from "../../../components/Confirm";
import { getRole, getAllowsDispatcherPermission } from "../../../Auth";
import { alert } from "../../../components/Alert";

export const dayDndMoveCopy = async (data, item, dropResult, TYPES, dispatch, api, selectedRows,
    dropTarget, shift, setSelectedRows, schedulerApi, openShiftDrop, driverToOpenShift, tableRef) => {
    const userId = localStorage.getItem('userId');

    const checkWarning = async (message) => {
        const value = data ? data : dropTarget;
        if (item.shift.category == 8) {
            return true;
        }
        if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE' && item.type == TYPES.open) {
            return alert("You don't have permission.");
        }
        let selectedDate = document.querySelector("#day-view-calendar").value;
        let timeoffornot = false;
        let shiftArr = [];
        let cMessage;
        await Object.keys(value).map((d) => {
            if (d.indexOf("shift_") > -1) {
                shiftArr.push(value[d]);
                if (value[d].category == 8) {
                    timeoffornot = true;
                }
            }
        });

        const { minHour, maxHour } = shiftArr.reduce(
            (obj, shift) => {
                obj.minHour = Math.min(obj.minHour, shift.startTime);
                obj.maxHour = Math.max(obj.maxHour, shift.endTime);
                return obj;
            },
            { minHour: 24, maxHour: 0 }
        );
        if (item.shift.startTime >= minHour && item.shift.startTime <= maxHour) {
            cMessage = `You are assigning ${dropTarget?.name} overlapping shifts. Do you wish to proceed?`;
        }
        if (item.shift.endTime >= minHour && item.shift.endTime <= maxHour) {
            cMessage = `You are assigning ${dropTarget?.name} overlapping shifts. Do you wish to proceed?`;
        }

        if (cMessage) {
            const wc = await confirm(cMessage);
            if (!wc) return true;
        }

        if (timeoffornot) {
            return true;
        }
        // todo replace this call with schedulerApi.tempEditShift call
        if (item.shift.isRescuer === true) {
            return alert(`You cannot ${message} rescue route`);
        } else if (item.shift.isTrain === true) {
            return alert(`You cannot ${message} train route`);
        } else if (item.shift.isSecondShift === true) {
            return alert(`You cannot ${message} second shift`);
        } else if (item.shift.isDuty === true) {
            return alert(`You cannot ${message} light duty route`);
        }
    }

    const checkSelectedDriverWarning = async (newDriverId, item, message) => {
        item.shift.newNote = "";

        let cMessage1 = `Are you sure to ${message} this shift?`;
        if (cMessage1) {
            const wc1 = await confirm(cMessage1);
            if (!wc1) return true;
        }
        const sequenceDriverRoute = await schedulerApi.checkSequenceDriverRoute({
            routeId: item.shift.routeId,
            driverId: newDriverId,
            newDate: moment(item.shift.shiftDate).format("YYYY-MM-DD"),
        });

        if (sequenceDriverRoute.data.data.checkSequenceDriverRoute.status) {
            const wc = await confirm(sequenceDriverRoute.data.data.checkSequenceDriverRoute.message.web);
            if (!wc) return undefined;
            const consecutiveShift = await schedulerApi.getConsecutiveShift({
                driverId: newDriverId,
                message: sequenceDriverRoute.data.data.checkSequenceDriverRoute.message.bot,
            });
        }
    }

    const multicheckSequenceDriverRoute = async (multiSelectPasteToDate, method) => {
        const pasteToDate = multiSelectPasteToDate.map((e) => e.pasteToDate)[0];
        const multisequenceDriverRoute = await schedulerApi.multicheckSequenceDriverRoute({
            routeId: multiSelectPasteToDate[0].routeId,
            driverId: multiSelectPasteToDate[0].driverId,
            timezoneOffset: -330,
            newDate: pasteToDate,
            pasteToDate: pasteToDate,
            copyToDate: pasteToDate,
            method: method
        });
        if (multisequenceDriverRoute.data.data.checkSequenceDriverRoute.status) {
            const wc = await confirm(multisequenceDriverRoute.data.data.checkSequenceDriverRoute.message.web);
            if (!wc) return true;

            const consecutiveShift = await schedulerApi.getConsecutiveShift({
                driverId: item.shift.driver.id,
                message: multisequenceDriverRoute.data.data.checkSequenceDriverRoute.message.bot,
            });
        }
    }


    const moveDrop = async () => {
        let newDriverId = openShiftDrop ? dropTarget?.id : null;

        if (newDriverId != undefined && newDriverId != null && "ROLE_DISPATCHER" == getRole() && getAllowsDispatcherPermission() == "false") {
            const driverSkillParam = {
                actions: {
                    response: {
                        Driver: {
                            custom: {
                                functionName: "checkDriverAndShiftSkillMatch",
                                get: "result",
                                excludes: [],
                                criteria: {
                                    driverId: newDriverId,
                                    shiftId: item.shift.typeId,
                                },
                            },
                        },
                    },
                },
            };
            const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
            if (!responseDriverSkill.data.data.result.result) {
                if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                    alert({
                        text: 'WARNING: "' + responseDriverSkill.data.data.result.driverName + '" does not have the required "' + responseDriverSkill.data.data.result.shiftSkillName + '" skill to be assigned this shift type.',
                        btnText: "Cancel",
                    });
                    return false;
                }
            }
        } 
            if (driverToOpenShift) {
                const warningResult = await checkWarning("move");
                if (warningResult) return;

                const checkSelectedWarning = await checkSelectedDriverWarning(newDriverId, item, "move");
                if (checkSelectedWarning) return;
            }
            const dayMoveDrop = await schedulerApi.getDayMoveDrop({
                routeId: item.shift.routeId,
                isNew: true,
                oldDriverId: data?.id ? data?.id : null,
                newDriverId: newDriverId ? newDriverId : null,
                newDate: moment(item.shift.shiftDate).format("YYYY-MM-DD"),
                weekFrom: moment(item.shift.shiftDate).weekday(),
                weekTo: moment(item.shift.shiftDate).weekday(),
                startTime: item.shift.utcStartTime,
                endTime: item.shift.utcEndTime,
                routeStatus: item.shift.routeStatus,
                isOpenShift: !driverToOpenShift ? true : false,
                shiftId: item.shift.typeId,
                isTemp: true,
                item: {
                    note: item.shift.note,
                    newNote: "",
                },
                shiftInvoiceType: item.shift.invoiceTypeId,
                hasAlert: true,
                timezoneOffset: new Date().getTimezoneOffset(),
            });
            const newShift = dayMoveDrop.data.data.tempDriverRoute;
            if (driverToOpenShift) {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.driver, driverId: newDriverId },
                        from: item.from,
                        shift: newShift,
                        hasAlert: true,
                    },
                });
            } else {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.open },
                        from: item.from,
                        shift: newShift,
                    },
                });
            }

            dispatch({
                type: "SET_PUBLISH_COUNT",
                ids: [item.shift.id],
                station: item.shift.stationId,
                api: false,
            });
            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
    }


    const copyDrop = async () => {
        let newDriverId = openShiftDrop ? dropTarget?.id : null;

        if (driverToOpenShift) {
            const warningResult = await checkWarning("copy");
            if (warningResult) return;


            const checkSelectedWarning = await checkSelectedDriverWarning(newDriverId, item, "copy");
            if (checkSelectedWarning) return;
        }

        const copyPreviousDataAndPasteNewData = await schedulerApi.copyPreviousDataAndPasteNewData({
            routeId: shift.routeId,
            driverId: newDriverId ? newDriverId : null,
            pasteToDate: shift.shiftDate,
            userId: userId,
            timezoneOffset: -330,
            hasAlert: false,
            message: "copy",
            isOpenShift: !driverToOpenShift ? true : false,
            isNew: true
        });
        const newShift = copyPreviousDataAndPasteNewData.data.data.driverRoute.data[0][0];
        if (driverToOpenShift) {
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: newDriverId, method: "copy" },
                    from: { type: "none" },
                    shift: newShift,
                },
            });
        } else {
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.open },
                    from: { type: "none" },
                    shift: item.shift,
                    method: "copy"
                },
            });
        }
        dispatch({
            type: "SET_PUBLISH_COUNT",
            ids: [newShift.id],
            station: item.shift.stationId,
            api: false,
        });
    }

    const copyMultiSelected = async () => {
        let newDriverId = openShiftDrop ? dropTarget?.id : null;
        let dateCreatedValues;
        if (!openShiftDrop) {
            dateCreatedValues = Object.entries(dropTarget).filter(([key]) => key.startsWith("shift_")).map(([key, value]) => value.shiftDate)[0];
        }
        let message = 'Copy';
        let cMessage = `Are you sure to copy the selected shifts?`;
        if (cMessage) {
            const wc = await confirm(cMessage);
            if (!wc) return true;
        }

        const multiSelectPasteToDate = selectedRows.map((selected, index) => {
            return {
                routeId: selected.routeId,
                driverId: newDriverId ? newDriverId : null,
                pasteToDate: openShiftDrop ? shift.shiftDate : dateCreatedValues,
                userId: userId,
                timezoneOffset: -330,
                hasAlert: false,
                message: message,
                isOpenShift: !driverToOpenShift ? true : false,
                isNew: true
            };
        });

        if (!openShiftDrop) {
            const checkSequenceDriverRoute = await multicheckSequenceDriverRoute(multiSelectPasteToDate, "copy")
            if (checkSequenceDriverRoute) return;
        }

        const params1 = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "copyPreviousDataAndPasteNewData",
                            get: "driverRoute",
                            excludes: [],
                            criteria: multiSelectPasteToDate
                        }
                    }
                }
            }
        }
        const response1 = await api.post("/api/lazy/manage/data", params1);
        const dropTargets = response1.data.data.driverRoute.data[0];
        const publishIds = dropTargets.map((e) => e.id);

        if (driverToOpenShift) {
            let driverId = selectedRows[0].driverId;
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: newDriverId, method: "copyMulti" },
                    from: { type: "copy", openToShift: driverId === "0.0.0" ? "copyOpenToShift" : null },
                    shift: dropTargets,
                },
            });
        } else {
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.open, target: "copyShiftToOpen" },
                    from: item.from,
                    shift: dropTargets,
                },
            });
        }
        dispatch({
            type: "SET_PUBLISH_COUNT",
            ids: publishIds,
            station: item.shift.stationId,
            api: false
        });
        setSelectedRows([])
    }

    const moveMultiSelected = async () => {
        let newDriverId = driverToOpenShift ? dropTarget?.id : null;
        let cMessage = `Are you sure to move the selected shifts?`;
        if (cMessage) {
            const wc = await confirm(cMessage);
            if (!wc) return true;
        }
        const dateCreatedValue = data ? data : dropTarget
        const dateCreatedValues = Object.entries(dateCreatedValue).filter(([key]) => key.startsWith("shift_")).map(([key, value]) => value.shiftDate)[0];
        const multiSelectPasteToDate = selectedRows.map((selected, index) => {
            return {
                method: "addTemp",
                routeId: selected.hasAlert ? selected.routeId : selected.id,
                isNew: true,
                isTemp: false,
                hasAlert: false,
                newDriverId: newDriverId ? newDriverId : null,
                oldDriverId: selected.driverId,
                newDate: dateCreatedValues,
                routeStatus: selected.routeStatus,
                isVoluntary: selected.isVoluntary,
                startTime: selected.utcStartTime || selected.startTime,
                endTime: selected.utcEndTime || selected.endTime,
                shiftId: selected.shiftId || selected.typeId || selected.shiftType.id,
                timezoneOffset: new Date().getTimezoneOffset(),
                shiftInvoiceType: selected.shiftInvoiceType,
                autoApproveDriverRequest: selected.autoApproveDriverRequest,
                item: {
                    qty: selected.qty,
                    note: selected.note,
                    newNote: selected.newNote
                },
                backupStatus: selected.backupStatus,
                onCall: selected.onCall,
                type: selected.type,
                driverId: newDriverId ? newDriverId : null,
                isDrag: true,
                pasteToDate: dateCreatedValues,
                isOpenShift: !driverToOpenShift ? true : false
            };
        });
        if (driverToOpenShift) {
            const checkSequenceDriverRoute = await multicheckSequenceDriverRoute(multiSelectPasteToDate, 'move')
            if (checkSequenceDriverRoute) return;
        }
        const response = await schedulerApi.multiEditShift([], [], multiSelectPasteToDate, 'move');
        const dropTargets = response.data.data.tempDriverRoute;
        const publishIds = dropTargets.map((e) => e.id);

        if (driverToOpenShift) {
            let driverId = selectedRows[0].driverId;
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: dropTarget.id, oldDriverId: selectedRows[0].driverId, method: "multiMove" },
                    from: { type: "multiMove", openToShift: driverId === "0.0.0" ? "openToShift" : null },
                    shift: dropTargets,
                },
            });
        } else {
            console.log('else');
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.open, target: "moveShiftToOpen" },
                    from: item.from,
                    shift: dropTargets,
                },
            });
        }
        dispatch({
            type: "SET_PUBLISH_COUNT",
            ids: publishIds,
            station: item.shift.stationId,
            api: false,
            method: "multiMove"
        });
        setSelectedRows([])
    }

    if (dropResult?.dropEffect === 'copy') {
        item.method = 'copy';
    }
    if (dropResult?.dropEffect === 'move') {
        item.method = 'move';
    }
    if (dropResult?.dropEffect === 'copy' && selectedRows?.length > 0) {
        item.method = 'multiSelectedCopy';
    }
    if (dropResult?.dropEffect === 'move' && selectedRows?.length > 0) {
        item.method = 'multiSelectedMove';
    }

    switch (item.method) {
        case 'multiSelectedCopy':
            copyMultiSelected();
            break;
        case 'multiSelectedMove':
            moveMultiSelected();
            break;
        case 'copy':
            copyDrop();
            break;
        case 'move':
            await moveDrop();
            break;
        default:
            await moveDrop();
    }
}