import React, {useState, useMemo} from "react";
import { Icon} from "@dspworkplace/ui";

import {openCategoryArchiveForm, openCategoryForm} from "./Common/CreateCategoryForm";
import {DataGrid} from "../../../../components/Grid";
import {GridAction} from "../../../../components/Grid/formatters";
import useGridApi from "../../../../api/useGridApi";
import {
    useVehicleMaintenanceCategory,
    VehicleMaintenanceCategoryInterface
} from "../../../../domain/vehicle_maintenance_category/api";
import {GridColumnProps} from "../../../../components/Grid/types";
import {useSelectedStation} from "../../../../components/StationSelector/StationPicker";
import {VehicleMaintenancePlan} from "../../../../domain/vehicle_maintenance_category/state/VehicleMaintenancePlan";
import {useRecoilRefresher_UNSTABLE} from "recoil";
import { VMPlanType } from "../../../../domain/vehicle_maintenance_category/state/VehicleMaintenancePlanType";

type InsertUpdateCategoryInterface = {
    label?: string
}

const parseFormRequestToApi = (formRequest): InsertUpdateCategoryInterface[] => {
    return formRequest.rules.map(item => {
        const itemRequest = {
            id: item.id || null,
            label: item?.label,
            children: item?.children
        };

        return itemRequest
    })
}

const getColumns = ({handleRemove,handleEdit}): GridColumnProps[] => {
    return [
        {
            name: "Type",
            key: "label",
        },
        {
            name: "Category",
            key: "children",
            formatter: ({row}) => {
                return <span>{row.children && row.children.map((i)=>i.label).join(' , ')}</span>
            }
        },
        {
            key: 'actions',
            name: '',
            width: 40,
            formatter:({row})=>{
                return (
                    <GridAction options={[
                        {title: 'Edit', icon: Icon.Edit, onClick: ()=>{handleEdit(row)}},
                        {title: 'Delete', icon: Icon.Times,onClick: ()=>{handleRemove(row)}}
                    ]} />
                )
            }
        }
    ]
}

const VehicleMaintenanceTypes = () => {
    const categoryState = useState<VehicleMaintenanceCategoryInterface[]>([]);
    const {
        fetchVehicleMaintenanceCategory,
        addVehicleMaintenanceCategory,
        updateVehicleMaintenanceCategory,
        deleteVehicleMaintenanceCategory,
        cancelApiRequest
    } = useVehicleMaintenanceCategory();
    const [selectedStation] = useSelectedStation();
    const updateCategory = useRecoilRefresher_UNSTABLE(VMPlanType)

    const {data,status,Add,Update,Delete} = useGridApi({load: fetchVehicleMaintenanceCategory, cancelRequest: cancelApiRequest, state: categoryState})

    const handleAdd = () => {
        openCategoryForm().then(resolve => {
            if(!resolve) return;

            const items: InsertUpdateCategoryInterface[] = parseFormRequestToApi(resolve)
            const shouldUpdateCategory = items.some(i => !!i.category)
            Add(addVehicleMaintenanceCategory,{label:resolve.label,children:resolve.children, entity: 'VehicleMaintenancePlanItem', itemsCount:0,items},`Error to save the template "${resolve.name}"`).then(newCtaegory => {
                updateCategory()
            })

        })
    }

    const columns  = useMemo(()=>{
        const handleRemove = (row) => {
            openCategoryArchiveForm(row.label).then(resolve => {
                if(!resolve) return;
                
                Delete(deleteVehicleMaintenanceCategory,row,`Error to delete the category "${row.label}"`)
            })
        }

        const handleEdit = (row) => {
            const editPromise = new Promise( (resolve) => {
                resolve({id:row.id, label: row.label,rules:row.items,children:row.children.map((v) => ({id:v.id, category:v.label, standardPricing:v.standardPricing, isLastServiceDateRequired: v.isLastServiceDateRequired, isLastMileServiceRequired: v.isLastMileServiceRequired }))})
            })

            openCategoryForm(editPromise).then(async(resolve) => {
                if (!resolve) return;

                const items:InsertUpdateCategoryInterface[] = parseFormRequestToApi(resolve)
                const rowToUpdate = {id:row.id ,label: resolve.label, children: resolve.children,items: items}

                await Update(updateVehicleMaintenanceCategory(row.id, rowToUpdate), row._INDEX, rowToUpdate, row, `Error to update the template "${row.label}"`)
                await updateCategory();
            })
        }

        return getColumns({handleRemove,handleEdit})
    // },[selectedStation])
    },[Delete, Update, deleteVehicleMaintenanceCategory, selectedStation, updateVehicleMaintenanceCategory])

    return (
        <DataGrid
            status={status}
            title={'Maintenance Types'}
            rows={data}
            columns={columns}
            onAdd={handleAdd}
            isStation= {false}
        />
    );
};


export default VehicleMaintenanceTypes;
