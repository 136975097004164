import React from 'react';
import {RadioBox} from '@dspworkplace/ui'
import {TypeProps} from '../../hooks/useChartConfig'
// @ts-ignore
import TableIcon from '../../../../assets/charts/table.svg'
// @ts-ignore
import ColumnIcon from '../../../../assets/charts/bar.svg'
// @ts-ignore
import LineIcon from '../../../../assets/charts/line.svg'
// @ts-ignore
import PieIcon from '../../../../assets/charts/pie.svg'
// @ts-ignore
import NumberIcon from '../../../../assets/charts/number.svg'

export const charts_type: { name: string; icon: JSX.Element; id: TypeProps, config: {} }[] = [
    {id:'table',name:'Table',icon:TableIcon,config:{} },
    {id:'bar',name:'Bar',icon:ColumnIcon,config:{dimension:{limit:2},measure:{limit:2}} },
    {id:'line',name:'Line',icon:LineIcon,config:{dimension:{limit:2}} },
    {id:'pie',name:'Pie',icon:PieIcon,config:{measure:{limit:1}} },
    {id:'number',name:'Number',icon:NumberIcon,config:{measure:{limit:2,allowRenameLabel:true},dimension:{limit:0}} }
]

const  ChartTypeSelector = ({
    chartConfig,
    itemsPerLine=5,
    afterSetType
}) => {
    const [chartConfigData,chartConfigDispatcher] = chartConfig;
    return (
        <div style={{display:'grid',gridTemplateColumns:`repeat(${itemsPerLine},1fr)`,gridGap:20}}>
            {charts_type.map((type)=>{
                let labelStyle = {margin:'0 auto',cursor:'pointer'};
                let imagestyle = {height:100}
                if(type.id === chartConfigData?.type){
                    imagestyle['padding'] = 5;
                    imagestyle['backgroundColor'] = '#f1f1f1'
                }
                // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                return (
                    <label key={type.id} style={labelStyle}>
                        <div style={{opacity:0}}>
                            <RadioBox
                                name='options'
                                options={[
                                    {value:type.id,label:type.name}
                                ]}
                                default={chartConfigData?.type}
                                onChange={(e)=> {
                                    chartConfigDispatcher({action:'SET_TYPE',type:e.target.value})
                                    if(afterSetType && typeof(afterSetType) === 'function'){
                                        afterSetType()
                                    }
                                }}
                            />
                        </div>
                        <img src={type.icon} style={imagestyle} />
                    </label>
                )
            })}
        </div>
    )
}

export default ChartTypeSelector;