import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Driver from "../../components/People";
import {Theme, Icon, Spacer, Text, ThirdTitle} from "@dspworkplace/ui";
import Modal from "../../components/Modal";
import Tag from "../../components/Tag";
import ShiftBlock from "../../components/ShiftBlock";
import ReportEvolution from "../../components/Report";
import Loading, {useLoading} from "../../components/Loading";
import {Link} from "../../components/UI";
import moment from "moment";
import {getCompanyId, useAuth} from "../../Auth";
import {IS_DEV_ENV} from "../../utils";
import {showErrorToast} from "../../Utilities";
import {AccessCheck, IsGranted} from "../../security";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Infos = styled.div`
    display: flex;

    > p {
        color: ${Theme.colors.neutrals.gray};
        display: inline-block;
    }

    > p:not(.title) {
        flex: 1;
    }

    > p.title {
        color: #707070;
        min-width: 135px;
    }

    > p.label {
        font-size:12px;
    }
`;

const ScheduleList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 148px 148px 148px;
    grid-gap: 8px;
`;

const ScoreContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

const LoadingWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;

    > img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const Grid = styled.span`
    box-sizing: border-box;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "0")};
    grid-template-rows: ${props => (props.templateRows ? props.templateRows : "auto")};
    grid-row-gap: ${props => (props.rowGap ? props.rowGap : "0")};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "normal")};
    align-items: ${props => (props.alignItems ? props.alignItems : "center")};
    padding: ${props => (props.padding ? props.padding : "0")};
    margin: ${props => (props.margin ? props.margin : "0")};
    border: ${props => (props.border ? props.border : "unset")};
    border-bottom: ${props => (props.borderBottom ? props.borderBottom : "unset")};
    border-top: ${props => (props.borderTop ? props.borderTop : "unset")};
    border-left: ${props => (props.borderLeft ? props.borderLeft : "unset")};
    border-right: ${props => (props.borderRight ? props.borderRight : "unset")};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : "unset")};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
    color: ${props => (props.color ? props.color : "#333333")};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#FFFFFF")};
    text-decoration: ${props => (props.textDecoration ? props.textDecoration : "unset")};
    text-align: ${props => (props.textAlign ? props.textAlign : "unset")};
    width: ${props => (props.width ? props.width : "auto")};
    height: ${props => (props.height ? props.height : "auto")};
    cursor: ${props => (props.cursor ? props.cursor : "auto")};
    grid-area: ${props => (props.gridArea ? props.gridArea : "auto")};
    line-height: 20px;
`;
const Details = ({
                     detailVisible,
                     toggleDetails,
                     modalHandler = undefined,
                     profileId,
                     isEdit = "false",
                     station = undefined
                 }) => {
    const [details, setDetails] = useState({
        data: [],
        sheduleShifts: [],
        scores: [],
        rescues: [],
        timeoffs: [],
        schedules: []
    });
    const [isLoading, setLoading] = useLoading();
    let FullRole = [
        "ROLE_OWNER",
        "ROLE_OPERATIONS_ACCOUNT_MANAGER",
        "ROLE_OPERATIONS_MANAGER",
        "ROLE_FLEET_MANAGER",
        "ROLE_HR_ADMINISTRATOR",
        "ROLE_CENTRAL_DISPATCHER",
        "ROLE_STATION_MANAGER",
        "ROLE_ASSISTANT_STATION_MANAGER"
    ];
    let ParsiallyRole = ["ROLE_DISPATCHER", "ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE", "ROLE_STATION_MANAGER", "ROLE_ASSISTANT_STATION_MANAGER"];

    const {api} = useAuth();

    const fetchDetails = async () => {
        setLoading(true);
        const params = {
            actions: {
                response: {
                    Driver: {
                        find: {
                            get: "driver",
                            id: profileId,
                            excludes: [
                                "vehicleDriverRecords",
                                "driverIncidentRecords",
                                "company",
                                "user",
                                "drivers",
                                // "stations",
                                "employmentStatus",
                                "skills",
                                "scores",
                                "employmentStatus",
                                "assignedManagers",
                                "payRate",
                                "schedules",
                                "emergencyContacts",
                                "employmentStatus"
                            ],
                            includes: {
                                "0": "id",
                                "1": "jobTitle",
                                "2": "veteranStatus",
                                "3": "note",
                                "4": "personalPhone",
                                "5": "transporterID",
                                "6": "hireDate",
                                "7": "driversLicenseNumber",
                                "8": "driversLicenseNumberExpiration",
                                "9": "fullName",
                                "10": "paycomId",
                                "11": "associateOID",
                                stations: ["id", "name", "code"],
                                employmentStatus: ["id", "name"],
                                user: {
                                    "0": "id",
                                    "1": "firstName",
                                    "2": "image",
                                    "3": "isEnabled",
                                    "4": "isArchive",
                                    "5": "email",
                                    "6": "profileImage",
                                    "7": "middleName",
                                    "8": "lastName",
                                    "9": "userSid",
                                    "10": "title",
                                    "11": "allowToChat",
                                    userRoles: ["id", "roleName"]
                                },
                                driverSkills: {
                                    "0": "id",
                                    // "1": "hourlyRate",
                                    "2": "isArchive",
                                    skill: ["id", "name"]
                                },
                                assignedManagers: {
                                    "0": "id",
                                    "1": "firstName",
                                    "2": "image",
                                    "3": "isEnabled",
                                    "4": "isArchive",
                                    "5": "email",
                                    userRoles: ["id", "roleName"]
                                },
                                schedules: ["id", "name", "startDate", "endDate", "isRecurring", "isArchive"],
                                company: ["isAdp","clientId"]
                            }
                        },
                        custom: {
                            functionName: "getScheduleDataByDriver",
                            get: "schedules",
                            criteria: {
                                id: profileId,
                            }
                        }
                    },   
                    PaycomSettings: {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId(),
                                isEnabled: true
                            },
                            get: "paycomSettings",
                            includes: {
                                0: "id",
                                1: "clientCode",
                                2: "isEnabled"
                            }
                        }
                    },
                    DriverRoute: {
                        custom: {
                            functionName: "getSheduledShiftOfDriver",
                            get: "scheduledAndRescueShifts",
                            criteria: {
                                id: profileId,
                                company: getCompanyId(),
                                timezoneOffset: new Date().getTimezoneOffset()
                            }
                        }
                    },
                    Score: {
                        custom: {
                            functionName: "getScores",
                            get: "scores",
                            criteria: {
                                id: profileId
                            }
                        }
                    },
                    ReportScorecard: {
                        custom: {
                            functionName: "getScorecardOverallTier",
                            get: "overallTierScore",
                            criteria: {
                                driverId: profileId
                            }
                        }
                    }
                }
            }
        };

        const sheduledShiftParams = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getSheduledShiftOfDriver",
                            get: "scheduledAndRescueShifts",
                            criteria: {
                                id: profileId,
                                company: getCompanyId(),
                                timezoneOffset: new Date().getTimezoneOffset()
                            }
                        }
                    }
                }
            }
        };
        try {
            let response = await api.post("/api/lazy/manage/data", params);
            let sheduledShiftResponse = await api.post("/api/lazy/manage/data", sheduledShiftParams);
            return new Promise((resolve, reject) => {
                Object.keys(response.data.data.driver.driverSkills).forEach(function (i) {
                    if (response.data.data.driver.driverSkills[i].isArchive === false && response.data.data.driver.driverSkills[i].skill != null) {
                        response.data.data.driver.driverSkills[i] =
                            response.data.data.driver.driverSkills[i].skill.name
                    } else {
                        delete response.data.data.driver.driverSkills[i];
                    }
                });
                let scheduleArray = [];
                Object.keys(response.data.data.schedules).forEach(function (i) {
                    if (response.data.data.schedules[i].isArchive != "1") {
                        let today = moment().format("YYYY-MM-DD");
                        let isRecurring = response.data.data.schedules[i].isRecurring;
                        let startDate = response.data.data.schedules[i].startDate;
                        if (isRecurring === "1") {
                            if (today >= startDate) {
                                scheduleArray.push(response.data.data.schedules[i].name);
                            }
                        } else {
                            let endtDate = response.data.data.schedules[i].endDate;
                            if (today >= startDate && endtDate >= today) {
                                scheduleArray.push(response.data.data.schedules[i].name);
                            }
                        }
                    }
                });

                setDetails({
                    data: response.data.data.driver,
                    isPaycom: response.data.data.paycomSettings,
                    sheduleShifts: sheduledShiftResponse.data.data.scheduledAndRescueShifts.scheduledShift,
                    scores: response.data.data.scores,
                    rescues: sheduledShiftResponse.data.data.scheduledAndRescueShifts.rescues,
                    timeoffs: sheduledShiftResponse.data.data.scheduledAndRescueShifts.timeoffShifts,
                    timeoffByDays:sheduledShiftResponse.data.data.scheduledAndRescueShifts.timeoffByDays,
                    timeoffCurrentYear:sheduledShiftResponse.data.data.scheduledAndRescueShifts.timeoffCurrentYear,
                    schedules: scheduleArray,
                    rescuerShifts: sheduledShiftResponse.data.data.scheduledAndRescueShifts.rescuerShifts,
                    rescuedShifts: sheduledShiftResponse.data.data.scheduledAndRescueShifts.rescuedShifts,
                    attendanceInformation: sheduledShiftResponse.data.data.scheduledAndRescueShifts.attendanceInformation,
                    driverTodayDeviceNumber: sheduledShiftResponse.data.data.scheduledAndRescueShifts.driverTodayDeviceNumber,
                    scorecardWeek: response?.data?.data?.overallTierScore
                });

                setLoading(false);
            });
        } catch (error) {
            await showErrorToast(error, 'Error fetching details.');
        }
    };

    useEffect(() => {
        fetchDetails().then();
    }, []);

    if (isLoading) {
        // return (<LoadingWrapper><Loading style={{height:'80px',marginTop:'20px'}} /></LoadingWrapper>)
    }

    return (
        <Modal
            width="506px"
            visible={detailVisible}
            drawer="right"
            removeHeader={true}
            closeOnOverlay={true}
            setVisible={toggleDetails}
        >
            {isLoading ? (
                <LoadingWrapper>
                    <Loading style={{height: "80px", marginTop: "20px"}}/>
                </LoadingWrapper>
            ) : (
                <>
                    <Header>
                        <Driver
                            name={details.data.fullName}
                            jobTitle={details.data.user.title}
                            img={details.data.user.profileImage ? details.data.user.profileImage : ""}
                            big={true}
                        />
                        <Spacer inline style={{cursor: "pointer"}}>
                            {isEdit !== "false" && (
                                <Icon.TimeLine
                                    color={Theme.colors.info.border}
                                    size="28px"
                                    onClick={() => {
                                        toggleDetails();
                                        modalHandler(0, "timeline");
                                    }}
                                />
                            )}
                            {isEdit !== "false" &&
                            (FullRole.indexOf(localStorage.getItem("currentUserRole")) > -1 ||
                                (ParsiallyRole.indexOf(localStorage.getItem("currentUserRole")) > -1 &&
                                    parseInt(details.data.user.id) !==
                                    parseInt(localStorage.getItem("userId")))) && (
                                <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.U}>        
                                <Spacer inline left={4}>
                                    <Icon.Edit
                                        color={Theme.colors.info.border}
                                        size="28px"
                                        onClick={() => {
                                            toggleDetails();
                                            modalHandler(0, "edit");
                                        }}
                                    />
                                </Spacer>
                                </IsGranted> 
                            )}
                            <IsGranted feature={AccessCheck.features.CM_EMPLOYEE} ops={AccessCheck.OPS.D}>
                            <Spacer inline left={4}>
                                <Icon.Times
                                    color={Theme.colors.neutrals.silver}
                                    size="28px"
                                    onClick={() => {
                                        toggleDetails();
                                    }}
                                />
                            </Spacer>
                            </IsGranted>
                        </Spacer>
                    </Header>
                    <Spacer top={5}/>
                    <div style={{width: "100%", height: "1px", background: "#F5F5F5", display: "block"}}/>
                    <Spacer top={5}/>
                    <Block title="Personal Information" expandable={true}>
                        <Infos>
                            <Text className="title">First Name</Text>
                            <Text>{details.data.user.firstName}</Text>
                        </Infos>

                        {details.data.user.middleName && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Middle Name</Text>
                                    <Text>{details.data.user.middleName}</Text>
                                </Infos>
                            </>
                        )}

                        {details.data.user.lastName && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Last Name</Text>
                                    <Text>{details.data.user.lastName}</Text>
                                </Infos>
                            </>
                        )}

                        <Spacer top={2}/>
                        <Infos>
                            <Text className="title">Email</Text>
                            <Text>{details.data.user.email}</Text>
                        </Infos>

                        <Spacer top={2}/>
                        <Infos>
                            <Text className="title">Personal Phone</Text>
                            <Text>{details.data.personalPhone}</Text>
                        </Infos>
                        <Spacer top={2}/>
                        <Infos>
                            <Text className="title">Today's Work Phone</Text>
                            <Text>{details.driverTodayDeviceNumber ?  <Link href={`tel:${details.driverTodayDeviceNumber}`}>{details.driverTodayDeviceNumber}</Link> : "-"}</Text>
                           
                        </Infos>
                        {details.data.user && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Allow to chat</Text>
                                    <Text>{JSON.stringify(details.data.user.allowToChat) === 'true' ? 'Yes' : 'No'}</Text>
                                </Infos>
                            </>
                        )}
                        {details.data.note && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Notes</Text>
                                    <Text>{details.data.note}</Text>
                                </Infos>
                            </>
                        )}
                    </Block>

                    <Block title="Driver Information" expandable={true}>
                        <Infos>
                            <Text className="title">Transporter ID</Text>
                            <Text>{details.data.transporterID}</Text>
                        </Infos>
                        <Spacer top={2}/>
                            <Infos>
                                <Text className="title">Payroll ID</Text>
                                <Text>{((details.isPaycom && details.data.paycomId) ? details.data.paycomId + ' (Paycom Id)' : ((details.data.company.isAdp && details.data.associateOID) ? details.data.associateOID +' (ADP Associate OID)':'No Value'))}</Text>
                            </Infos>
                            <Spacer top={2}/>
                        <Infos>
                            <Text className="title">Manager</Text>
                            <Text>{Array.isArray(details.data.assignedManagers) && details.data.assignedManagers.length > 0 && details.data.assignedManagers[0].firstName ? details.data.assignedManagers[0].firstName : 'N/A'}</Text>
                        </Infos>
                        <Spacer top={2}/>
                        {/*{IS_DEV_ENV && <Infos>*/}
                        {/*    <Text>{JSON.stringify(Object.keys(details.data))}</Text>*/}
                        {/*</Infos>}*/}
                        {/*{IS_DEV_ENV && Object.entries(details.data).map(e => {*/}
                        {/*    return <Infos>*/}
                        {/*        <Text className="title">{e[0]}</Text>*/}
                        {/*        <Text>{JSON.stringify(e[1])}</Text>*/}
                        {/*    </Infos>*/}
                        {/*})}*/}
                        <Spacer top={2}/>
                        <Infos>
                            <Text className="title">Hire Date</Text>
                            <Text>
                                {moment
                                    .unix(details.data.hireDate.timestamp)
                                    .utc()
                                    .format("ddd, DD MMM YYYY")}
                            </Text>
                        </Infos>
                        {details.data.driversLicenseNumber && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">License Number</Text>
                                    <Text>{details.data.driversLicenseNumber}</Text>
                                </Infos>
                            </>
                        )}
                        {details.data.driversLicenseNumberExpiration && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Expiration</Text>
                                    <Text>
                                        {details.data.driversLicenseNumberExpiration &&
                                        moment
                                            .unix(details.data.driversLicenseNumberExpiration.timestamp)
                                            .utc()
                                            .format("ddd, DD MMM YYYY")}
                                    </Text>
                                </Infos>{" "}
                            </>
                        )}
                        {details.data.id && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Driver ID</Text>
                                    <Text>{details.data.id}</Text>
                                </Infos>
                            </>
                        )}
                        {details.data.user && details.data.user.id && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">User ID</Text>
                                    <Text>{details.data.user.id}</Text>
                                </Infos>
                            </>
                        )}
                        {details.data.user && details.data.user.userSid && (
                            <>
                                <Spacer top={2}/>
                                <Infos>
                                    <Text className="title">Twilio ID</Text>
                                    <Text>{details.data.user.userSid}</Text>
                                </Infos>
                            </>
                        )}
                        {details.data &&
                        Array.isArray(details.data.stations) &&
                        details.data.stations.length > 0 &&
                        details.data.stations.map((station, index) => {
                            return (
                                <Spacer top={2} key={station.id}>
                                    <Infos>
                                        <Text className="title">{`Station #${index + 1}`} </Text>
                                        <Text>{station.name}</Text>
                                    </Infos>
                                </Spacer>
                            );
                        })}
                        {/* {station && (
                            <Spacer top={2}>
                                <Infos>
                                    <Text className="title">Current Station</Text>
                                    <Text>{station}</Text>
                                </Infos>
                            </Spacer>
                        )} */}
                    </Block>

                    <Block title="Skills">
                        <Tag options={details.data.driverSkills}/>
                    </Block>

                    {details.attendanceInformation && (<>
                            <Block title={"In the last " + details.attendanceInformation.reportDays + " days"} >
                            <Infos>
                                <Text className="title">Sent Home</Text>
                                <Text>{details.attendanceInformation.sentHome}</Text>
                            </Infos>
                            <Spacer top={2} />
                            <Infos>
                                    <Text className="title">Total Number Day Scheduled :</Text>
                                    <Text>{details.attendanceInformation.totalScheduledDays?details.attendanceInformation.totalScheduledDays:0}</Text>
                            </Infos>
                            <Spacer top={2} />
                            <Infos>
                                <Text className="title">Total Hours Worked :</Text>
                                    <Text>{details.attendanceInformation.actualWorkHours}</Text>
                            </Infos>
                            {/* <Spacer top={2} />
                            <Infos>
                                <Text className="title">Total Schedule Hours :</Text>
                                <Text>{details.attendanceInformation.actualScheduleHours}</Text>
                            </Infos> */}
                            {details.attendanceInformation.percentage && <>
                                <Spacer top={2} />
                                <Infos>
                                    <Text className="title">Percentage :</Text>
                                        <Text style={{ color: details.attendanceInformation.percentageColor }}>
                                            {details.attendanceInformation.percentage.toFixed(2)}%
                                            {details.attendanceInformation.totalDayInCompany && details.attendanceInformation.totalDayInCompany <= details.attendanceInformation.reportDays && ' - Less then '+details.attendanceInformation.reportDays+' days'}   
                                        </Text>
                                </Infos>
                            </>}
                            {details.attendanceInformation.incident.length > 0 && details.attendanceInformation.incident.map((i)=>(<><Spacer top={2}/>
                                <Infos>
                                    <Text className="title">{i.name}</Text>
                                    <Text>{i.incidentCount}</Text>
                                </Infos>
                            </>))}
                        </Block>
                    </>)}

                    <Block title="Scorecard (Last 6 weeks)">
                        <Spacer inline style={{ width: "100%" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "5px", width: "100%" }}>
                                {details.scorecardWeek?.length > 0 ?
                                    details.scorecardWeek.map((data, index) => {
                                        const isOddIndex = index % 2 !== 0;
                                        const alignmentStyle = isOddIndex ? { textAlign: 'right' } : { textAlign: 'left' };
                                        return (
                                            <Infos key={index}>
                                                <Text style={{ ...alignmentStyle, minWidth: "100px", display: "flex" }} className={'title'}>{"W" + data.week + "(" + data.year + ")" + ":"}</Text>
                                                <Text>{data.tier}</Text>
                                            </Infos>
                                        )
                                    }) :
                                    <Infos>
                                        <Text style={{ minWidth: "100px", display: "flex" }} className={'title'}>Scorecard not available</Text>
                                    </Infos>}
                            </div>
                        </Spacer>
                    </Block>

                    <Block title="Schedules">
                        {details.schedules.length > 0 ? (
                            <Infos>
                                <Text className="title">Current</Text>
                                <Text>
                                    {details.schedules.map((item, i, arr) => (
                                        <span key={i}>
                                            <Grid textDecoration="underline" color="#0071BC">
                                                {item}
                                            </Grid>
                                            {i != arr.length - 1 ? ", " : ""}
                                        </span>
                                    ))}
                                </Text>
                            </Infos>
                        ) : (
                            ""
                        )}
                    </Block>

                    <Block title="Scheduled Shifts">
                        <ScheduleList>
                            {details.sheduleShifts &&
                            details.sheduleShifts.map((shift, index) => {
                                return (
                                    <ShiftBlock
                                        key={index}
                                        date={shift.date}
                                        hour={shift.time}
                                        type={shift.name}
                                        background={shift.color}
                                        color={shift.textColor}
                                        category={shift.category}
                                    />
                                );
                            })}
                        </ScheduleList>
                    </Block>
                    <Block title="Time off">
                        <Spacer top={2}>
                            <div>
                                <SubTitle style={{marginLeft:"0px"}}>{`Scheduled`}</SubTitle>
                                <ScheduleList>
                                    {details.timeoffs && details.timeoffs.map((shift, index) => {
                                        return (
                                            <ShiftBlock
                                                key={index}
                                                date={shift.date}
                                                hour={shift.time}
                                                type={shift.name}
                                                background={shift.color}
                                                color={shift.textColor}
                                                schedule={shift.scheduleName}
                                                isTimeOff={true}
                                            />
                                        );
                                    })}
                                </ScheduleList>
                            </div>
                        </Spacer>
                        <Spacer inline top={2}>
                            <div style={{display:"inline-block"}}>
                                <SubTitle style={{marginLeft:"0px"}}>{`Last ${details.attendanceInformation.reportDays} Days`}</SubTitle>
                                <Infos>
                                    <Text className={'title label'}>PTO :</Text>
                                    <Text>{details.timeoffByDays.PTO}</Text>
                                </Infos>
                                <Spacer top={2} />
                                <Infos>
                                    <Text className={'title label'}>UPTO :</Text>
                                    <Text>{details.timeoffByDays.UPTO}</Text>
                                </Infos>
                                <Spacer top={2} />
                                <Infos>
                                    <Text className={'title label'}>VTO Flag:</Text>
                                    <Text>{details.timeoffByDays.VTO}</Text>
                                </Infos>
                            </div>
                            <Spacer inline left={40}>
                                <div style={{display:"inline-block"}}>
                                    <SubTitle style={{marginLeft:"0px"}}>{`Current Calendar Year`}</SubTitle>
                                    <Infos>
                                        <Text className={'title label'}>PTO :</Text>
                                        <Text>{details.timeoffCurrentYear.PTO}</Text>
                                    </Infos>
                                    <Spacer top={2} />
                                    <Infos>
                                        <Text className={'title label'}>UPTO :</Text>
                                        <Text>{details.timeoffCurrentYear.UPTO}</Text>
                                    </Infos>
                                    <Spacer top={2} />
                                    <Infos>
                                        <Text className={'title label'}>VTO Flag :</Text>
                                        <Text>{details.timeoffCurrentYear.VTO}</Text>
                                    </Infos>
                                </div>
                            </Spacer>
                        </Spacer>
                    </Block>

                    {/*<Block title='Scores' subtitle='Last 7 Days'>
                        <ScoreContent>
                            <ReportEvolution title='Delivery' value={details.scores.sevendays ? details.scores.sevendays.deliveryScore:0} oldValue={details.scores.seven_of_sevenday? details.scores.seven_of_sevenday.deliveryScore:0} />
                            <ReportEvolution title='Core Value' value={details.scores.sevendays ? details.scores.sevendays.coreValueScore:0 } oldValue={details.scores.seven_of_sevenday ? details.scores.seven_of_sevenday.coreValueScore : 0} />
                            <ReportEvolution title='Attendence' value={details.scores.sevendays ? details.scores.sevendays.attendanceScore:0} oldValue={details.scores.seven_of_sevenday ? details.scores.seven_of_sevenday.attendanceScore:0} />
                            <ReportEvolution title='Grade' value={'B'} oldValue={'C'} />
                        </ScoreContent>
                    </Block>

                    <Block title='Strikes' subtitle='This quarter'>
                        <Spacer inline>
                            <ReportEvolution title='NCNS' value={4} oldValue={2} />
                        </Spacer>
                        <Spacer inline left={11}>
                            <ReportEvolution title='Disciplinary' value={0} oldValue={0} />
                        </Spacer>
                    </Block> */}

                    <Block title="Rescues" subtitle="Last 90 Days">
                        <Spacer inline>
                            <ReportEvolution
                                title="Rescuer"
                                value={
                                    details.rescues.currentQuarterShiftCounfOfRescuerAndRescued
                                        ? details.rescues.currentQuarterShiftCounfOfRescuerAndRescued.rescuer
                                        : 0
                                }
                                oldValue={
                                    details.rescues.previousQuarterShiftCounfOfRescuerAndRescued
                                        ? details.rescues.previousQuarterShiftCounfOfRescuerAndRescued.rescuer
                                        : 0
                                }
                            />
                        </Spacer>
                        <Spacer inline left={33}>
                            <ReportEvolution
                                title="Rescued"
                                value={
                                    details.rescues.currentQuarterShiftCounfOfRescuerAndRescued
                                        ? details.rescues.currentQuarterShiftCounfOfRescuerAndRescued.rescued
                                        : 0
                                }
                                oldValue={
                                    details.rescues.previousQuarterShiftCounfOfRescuerAndRescued
                                        ? details.rescues.previousQuarterShiftCounfOfRescuerAndRescued.rescued
                                        : 0
                                }
                            />
                        </Spacer>

                        <Spacer top={2}/>

                        <Spacer inline style={{width: "148px"}}>
                            {details.rescuerShifts &&
                            details.rescuerShifts.map((shift, index) => {
                                return (
                                    <ShiftBlock
                                        key={index}
                                        date={shift.date}
                                        hour={shift.time}
                                        type={shift.name}
                                        background={shift.color}
                                        color={shift.textColor}
                                    />
                                );
                            })}
                        </Spacer>
                        <Spacer inline left={11}>
                            <Spacer inline style={{width: "148px"}}>
                                {details.rescuedShifts &&
                                details.rescuedShifts.map((shift, index) => {
                                    return (
                                        <ShiftBlock
                                            key={index}
                                            date={shift.date}
                                            hour={shift.time}
                                            type={shift.name}
                                            background={shift.color}
                                            color={shift.textColor}
                                            category={shift.category}
                                        />
                                    );
                                })}
                            </Spacer>
                        </Spacer>

                        {/* <Spacer top={2} />

                        <Spacer inline style={{width:'148px'}}>
                            <ShiftBlock date='Sat, 19 Oct' hour='6:30a – 10p' type='AM Cosmos' background='#B13372' color='#FFFFFF' />
                        </Spacer> */}
                    </Block>
                    {/*  <Block title='Scores' subtitle='Last 7 Days'>
                        <ScoreContent>
                            <ReportEvolution title='Delivery' value={details.scores.sevendays ? details.scores.sevendays.deliveryScore:0} oldValue={details.scores.seven_of_sevenday? details.scores.seven_of_sevenday.deliveryScore:0} />
                            <ReportEvolution title='Core Value' value={details.scores.sevendays ? details.scores.sevendays.coreValueScore:0 } oldValue={details.scores.seven_of_sevenday ? details.scores.seven_of_sevenday.coreValueScore : 0} />
                            <ReportEvolution title='Attendence' value={details.scores.sevendays ? details.scores.sevendays.attendanceScore:0} oldValue={details.scores.seven_of_sevenday ? details.scores.seven_of_sevenday.attendanceScore:0} />
                            <ReportEvolution title='Overall Grade' value={'B'} oldValue={'C'} />
                        </ScoreContent>
                    </Block> */}

                    {/* Commenting out till Phase 2 */}
                    {/*<Block title='Strikes' subtitle='Past 90 days'>
                        <Spacer inline>
                            <ReportEvolution title='NCNS' value={4} oldValue={2} />
                        </Spacer>
                        <Spacer inline left={11}>
                            <ReportEvolution title='Disciplinary' value={0} oldValue={0} />
                        </Spacer>
                    </Block>*/}

                    {/*<Block title='Rescues' subtitle='Past 90 days'>
                        <Spacer inline>
                            <ReportEvolution title='Rescuer' value={details.rescues.quarter ? details.rescues.quarter.rescuer:0} oldValue={details.rescues.quarterBeforeQuarter?details.rescues.quarterBeforeQuarter.rescuer:0} />
                        </Spacer>
                        <Spacer inline left={33}>
                            <ReportEvolution title='Rescued' value={details.rescues.quarter?details.rescues.quarter.rescued:0} oldValue={details.rescues.quarterBeforeQuarter?details.rescues.quarterBeforeQuarter.rescued:0} />
                        </Spacer>

                        <Spacer top={2} />

                        <Spacer inline style={{width:'148px'}}>
                            <ShiftBlock date='Wed, 16 Oct' hour='6:45a – 7:45p' type='Hourly' background='#EAF0F6' color='#333333' />
                        </Spacer>
                        <Spacer inline left={11}>
                            <Spacer inline style={{width:'148px'}}>
                                <ShiftBlock date='Wed, 16 Oct' hour='6:45a – 7:45p' type='Hourly' background='#EAF0F6' color='#333333' />
                            </Spacer>
                        </Spacer>

                        <Spacer top={2} />

                        <Spacer inline style={{width:'148px'}}>
                            <ShiftBlock date='Sat, 19 Oct' hour='6:30a – 10p' type='AM Cosmos' background='#B13372' color='#FFFFFF' />
                        </Spacer>
                    </Block> */}
                </>
            )}
        </Modal>
    );
};

const SubTitle = styled.span`
    font-size: 12px;
    font-family: circe-rounded;
    color: ${Theme.colors.info.border};
    display: inline;
    margin-left: 5px;
`;
const Block = ({title, subtitle, expandable, children}) => {
    return (
        <>
            <Header>
                <ThirdTitle style={{fontWeight: "normal"}}>
                    {title} {subtitle && <SubTitle>{subtitle}</SubTitle>}
                </ThirdTitle>
                {expandable && <Icon.Expand size="20px" color="#7C98B6"/>}
            </Header>

            <Spacer top={2}/>

            {children}

            <Spacer top={5}/>
            <div style={{width: "100%", height: "1px", background: "#F5F5F5", display: "block"}}/>
            <Spacer top={5}/>
        </>
    );
};

export default Details;
