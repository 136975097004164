import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Button, Input, Spacer, SubTitle, Toggle} from "@dspworkplace/ui";
import {useAuth} from "../../../Auth";
import SettingsContext from "../context";
import {toast} from "../../../components/Toasts";
import {Label} from "../../../components/UI";
import { AccessCheck, IsGranted } from "../../../security";

const App = () => {
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const {register, handleSubmit, reset} = useForm();

    useEffect(() => reset(state.company), [state.company]);

    const handleSaveCompanySummarySetting = ( data, evt ) => {
        let form = evt.target;

        form.querySelector('button').disabled = true;

        api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    Company: {
                        update_company: {
                            findBy: {
                                id: state.company.id
                            },
                            updateRecord: {
                                overtimeHours: data.overtimeHours,
                                rescueHours: data.rescueHours,
                                backupDriverHours: data.backupDriverHours,
                                isOptOutMissedLunchPunch: data.isOptOutMissedLunchPunch
                            }
                        }
                    }
                }
            }
        }).then(
            response => toast({
                title: 'Company Summary Settings updated',
                type: 'info',
                timeout: 8000,
                useIcon: true,
                useClose: true
            }) && dispatch({
                type: 'ADD',
                payload: {
                    ...state,
                    company: {
                        ...state.company,
                        ...data
                    }
                }
            }),
            error => toast({
                title: 'Error',
                content: error.response ? error.response.data.message : error.message,
                type: 'error',
                timeout: 8000,
                useIcon: true,
                useClose: true
            })
        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    return (
        <form onSubmit={handleSubmit(handleSaveCompanySummarySetting)}>
            <SubTitle>Daily Summary Settings</SubTitle>
            <Spacer bottom={3}/>
            <Label>Percent of Budgeted Shift time excess per Amazon Paid Route Hour</Label>
            <Spacer bottom={1}/>
            <Input
                ref={register}
                name='overtimeHours'
                size='extraSmall'
            />
            <Spacer bottom={3}/>
            <Label>Percent of Budgeted Rescue time per Amazon Paid Route Hour</Label>
            <Spacer bottom={1}/>
            <Input
                ref={register}
                name='rescueHours'
                size='extraSmall'
            />
            <Spacer bottom={3}/>
            <Label>Percent of Backup Driver time per Amazon Paid Route Hour</Label>
            <Spacer bottom={1}/>
            <Input
                ref={register}
                name='backupDriverHours'
                size='extraSmall'
            />
            <Spacer bottom={3}/>
            <Toggle
                ref={register}
                name='isOptOutMissedLunchPunch'
                singleText='Do not include missed lunch punches in punch exception report'
            />
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default App;