import React from 'react';
import {Button, Spacer} from "@dspworkplace/ui";
import Modal from "../../components/Modal";
import {useForm} from "react-hook-form";
import API from "../SchedulerLoadOut/api";
import toast from "../../components/Toasts/toast";
import {useAuth} from "../../Auth";
import DayPicker from '../../components/Calendar/day';
import Driver from "../../components/People";
import { confirm } from "../../components/Confirm";
import moment from 'moment';

const App = ({setVisible,driver}) => {
    const { register, handleSubmit, errors,getValues } = useForm();
    const {effectiveDate} = getValues();
    const { api } = useAuth();

    const onSubmit = async (data) => {

            let res  = await confirm({
                text: `Do you really want to delete all shifts from `+moment(data.effectiveDate).format('MM-DD-YYYY')+` for this driver?`
            });
            if(!res)
                return;


            let response = await API.deleteDriverRoute(api,data.driverId, data.effectiveDate);
            setVisible(false);
            if (response) {
                await toast({
                    type: 'success',
                    title: 'Shift deleted successfully.',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                });
            } 
    }


    return (
        <Modal
            width='340px'
            title='Remove Shift'
            visible={true}
            setVisible={()=> setVisible(false)}
            closeOnOverlay={false}
        >
            <form name="shift_delete_form"  onSubmit={handleSubmit(onSubmit)}>
                <input type='hidden' name='driverId' value={driver.id} ref={register} />
                <input type='hidden' name='driverEmail' value={driver.email} ref={register} />
                <input type='hidden' name='driverName' value={driver.friendlyName} ref={register} />
                <Driver
                    name={driver.name ? driver.name : driver.friendlyName}
                    jobTitle={driver.job}
                    img={driver.picture}
                />
                <Spacer top={5}/>

                <DayPicker
                    name='effectiveDate'
                    label='From Effective Date'
                    size='medium'
                    ref={register({ required: true })}
                    error={errors.effectiveDate && "Please select Effective Date."}
                    valid={!errors.effectiveDate && effectiveDate}
                />
                {/*<Spacer top={3}/>*/}
                <Spacer top={5} style={{ textAlign: "right" }} >
                    <Button type="primary" >Remove</Button>
                </Spacer>
            </form>
        </Modal>
    )
}

export default App;
