import React, { useEffect, useState } from "react";
import { Button, CustomDropdown, Spacer, Title, TagInput, RadioBox, CheckBox } from "@dspworkplace/ui";
import { Label } from "../../../../components/UI";
import { useForm } from "react-hook-form";
import { getCompanyId, useAuth, getUserId, getDriverId } from "../../../../Auth";
import DayPicker from "../../../../components/Calendar/day";
import DatePicker from "../../../../components/Calendar/picker";
import moment from "moment";
import { Content } from "../../../../components/Menus/Navigator";
import { FilterDayAnnouncement } from "../../../../hooks/useGlobalData";
import { fetchStations, fetchDrivers, exportDynamicReport, callDynamicReportRoutes } from "../../api";
import { showToast } from "../../../../Utilities";
import Loading, { LoadingWrapper } from "../../../../components/Loading";
import { IS_DEV } from "../../../../utils";

function setStationDefaultValue(stations, placeholder) {
    try {
        let station = JSON.parse(localStorage.getItem("station"));
        let code = station.code;
        if (Array.isArray(stations)) {
            let r = stations.find((e) => e.code === code);
            if (r && typeof r.code === "string") {
                return r.code;
            }
        }
        if (placeholder) {
            return "";
        }
        return null;
    } catch (e) {
        return null;
    }
}

const SubDynamic = ({ report, subReport, name, fields }) => {
    const { api } = useAuth();
    const { register, handleSubmit, watch, errors, getValues } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange" | "onBlur",
    });
    const [station, setStation] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedDate, setSelectedDate] = FilterDayAnnouncement();
    const [datePickerField, setDatePickerField] = useState(false);
    const [dynamicRadio, setDynamicRadio] = useState(false);
    const userId = getUserId();
    const companyId = getCompanyId();
    const [loading, setLoading] = useState(false);
    const stationField = fields.station;
    const driverField = fields.driver;
    const driverStatusField = fields?.driverStatus ? fields?.driverStatus : false;
    const dynamicCheckbox = fields.dynamicCheckbox;
    const values = getValues();

    let employeeStatusArr = [
        { value: "-2", name: "Terminated" },
        { value: "-1", name: "Offboarded" },
        { value: "0", name: "Inactive" },
        { value: "1", name: "Onboarding" },
        { value: "2", name: "Active" },
    ];

    useEffect(() => {
        setDatePickerField(fields.datePicker);
    }, [fields.datePicker]);
    useEffect(() => {
        setDynamicRadio(fields.dynamicRadio);
    }, [fields.dynamicRadio]);
    const callDynamicReportOnChangeRadio = async (radioSelectedData) => {
        setLoading(true);
        let data = { radioSelectedData: radioSelectedData, report: "compliance-report" };
        await callDynamicReportRoutes(api, companyId, userId, false, report, data).then((data) => {
            setDynamicRadio(data.dynamicRadio);
            setDatePickerField(data.datePicker);
            setLoading(false);
        });
    };
    const onSubmit = (data) => {
        setLoading(true);
        let currentSelectedStations = [];
        let currentSelectedDrivers = [];
        let currentSelectedStartDate = null;
        let currentSelectedEndDate = null;
        let currentSelectedDynamicRadio = null;
        let currentSelectedDynamicCheckbox = null;
        let currentSelectedDriverStatus = [];
        if (data.stations) {
            currentSelectedStations = data.stations;
            currentSelectedStations = Array.isArray(currentSelectedStations)
                ? currentSelectedStations
                : [currentSelectedStations];
        }

        if (currentSelectedStations.length === 0) {
            currentSelectedStations = [setStationDefaultValue(station)];
        }
        if (data.drivers) {
            currentSelectedDrivers = data.drivers;
        }
        if (data.dynamicRadio) {
            currentSelectedDynamicRadio = data.dynamicRadio;
        }
        if (data.dynamicCheckbox) {
            currentSelectedDynamicCheckbox = data.dynamicCheckbox;
        }
        if (data.dates) {
            currentSelectedStartDate = typeof selectedDate.start !== "undefined" ? selectedDate.start : "";
            currentSelectedEndDate = typeof selectedDate.end !== "undefined" ? selectedDate.end : "";
        }
        if (data?.driverStatus && data?.driverStatus.length > 0) {
            currentSelectedDriverStatus = data.driverStatus;
        }

        exportDynamicReport(
            api,
            companyId,
            report,
            subReport,
            currentSelectedStations,
            currentSelectedDrivers,
            currentSelectedStartDate,
            currentSelectedEndDate,
            currentSelectedDynamicRadio,
            currentSelectedDynamicCheckbox,
            getDriverId(),
            currentSelectedDriverStatus
        ).then((response) => {
            response = response?.data;
            if (response.success) {
                if (response?.data?.worker) {
                    let message = response?.message;
                    showToast({
                        type: "Success",
                        title: "Success",
                        content: message,
                    });
                } else {
                    let file = response?.data?.file;
                    if (file) {
                        const element = document.createElement("a");
                        element.href = file;
                        document.body.appendChild(element);
                        element.click();
                    }
                }
                setLoading(false);
            } else {
                let message = response?.message;
                if (message) {
                    showToast({
                        type: "Error",
                        title: "Error",
                        content: message,
                    });
                }
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        fetchStations(api, companyId).then((response) => {
            if (response.data.data.Station) {
                setStation(response.data.data.Station);
            }
        });
        let s = JSON.parse(localStorage.getItem("station"));
        fetchDrivers(api, s.code, companyId).then((response) => {
            if (response.data.data.drivers) {
                setDrivers(response.data.data.drivers);
            }
        });
    }, []);

    useEffect(() => {
        if (driverField.enable) {
            let s = JSON.parse(localStorage.getItem("station"));
            fetchDrivers(api, s.code, companyId).then((response) => {
                if (response.data.data.drivers) {
                    setDrivers(response.data.data.drivers);
                }
            });
        }
    }, [localStorage.getItem("station")]);

    const handleChange = async (value) => {
        let selectedStationArray = Array.isArray(value) ? value : [value];
        await station.map((s) => {
            if (s.code == value) {
                localStorage.setItem("station", JSON.stringify(s));
            }
        });
        // if (driverField.enable) {
        //     fetchDrivers(api, selectedStationArray, companyId).then((response) => {
        //         if (response.data.data.drivers) {
        setDrivers([]);
        //         }
        //     });
        // }
    };
    const checkValidDate = (dateString) => {
        const newDate = new Date(dateString);
        const isDateValid = !isNaN(newDate.getTime()) && /^\d{2}\/\d{2}\/\d{4}$/.test(dateString);
        return isDateValid;
    };
    const validateInput = (value) => {
        // Your validation logic here
        return value.length > 5 ? true : "Input must be at least 6 characters";
    };
    return (
        <Content className="scrollable content">
            <Spacer bottom={10} id="company-information">
                <Title>{name}</Title>
                <Spacer bottom={5} />
                {loading && (
                    <LoadingWrapper>
                        <Loading style={{ height: "80px", marginTop: "20px" }} />
                    </LoadingWrapper>
                )}
                {/* {IS_DEV && <div>
                    {JSON.stringify(stationField)}
                </div>}
                {IS_DEV && <div>
                    {JSON.stringify({stations1: setStationDefaultValue(station, true), stations2: setStationDefaultValue(station)})}
                </div>} */}
                {!loading && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {stationField.enable && (
                            <CustomDropdown
                                name="stations"
                                label="Station"
                                ref={register}
                                placeholder={setStationDefaultValue(station, true)}
                                defaultValue={setStationDefaultValue(station)}
                                multiple={stationField.options.isMultiple}
                                options={station.map((s) => {
                                    return { name: s.name, value: s.code };
                                })}
                                onChange={handleChange}
                            />
                        )}
                        {stationField.enable && <Spacer bottom={5} />}
                        {dynamicRadio && dynamicRadio.enable && (
                            <>
                                <Label>{dynamicRadio.options.label}</Label>
                                <Spacer bottom={1} />
                                <RadioBox
                                    name="dynamicRadio"
                                    ref={register}
                                    options={dynamicRadio.options.data}
                                    default={dynamicRadio.options.defaultData}
                                    Required={false}
                                    onChange={async (e) => {
                                        await callDynamicReportOnChangeRadio(e.target.value);
                                    }}
                                />
                            </>
                        )}
                        {dynamicRadio && dynamicRadio.enable && <Spacer bottom={5} />}
                        {dynamicCheckbox && dynamicCheckbox.enable && (
                            <>
                                <CheckBox
                                    name="dynamicCheckbox"
                                    ref={register}
                                    disabled={dynamicCheckbox.options.disabled}
                                    options={dynamicCheckbox.options.data}
                                    defaultValues={dynamicCheckbox.options.defaultData}
                                />
                            </>
                        )}
                        {dynamicCheckbox && dynamicCheckbox.enable && <Spacer bottom={5} />}
                        {driverField.enable && (
                            <TagInput
                                ref={register({ required: driverField?.required ? driverField?.required : false })}
                                name="drivers"
                                label="Driver"
                                visibleOptionsQty={6}
                                options={drivers.map((u) => {
                                    let status = employeeStatusArr.find((s) => s.value === u?.employeeStatus);
                                    return { name: `${u?.friendlyName} - (${status.name})`, value: u.driverId };
                                })}
                                singleOption={!driverField.options.isMultiple}
                                error={
                                    errors.drivers &&
                                    (driverField?.validationMsg ? driverField?.validationMsg : "Please select driver")
                                }
                                placeholder={(() => {
                                    if (!drivers.length) return "Loading...";

                                    if (drivers.length) return "Select a driver...";

                                    return "";
                                })()}
                            />
                        )}
                        {driverField.enable && <Spacer bottom={5} />}
                        {datePickerField && datePickerField.enable && datePickerField.options.singleDate && (
                            <DayPicker
                                label="Date"
                                className="picker"
                                name="dates"
                                ref={register}
                                size={"large"}
                                useControllers={true}
                                onChange={(date) => {
                                    if (date && date.utc) {
                                        const startDate = moment.utc(date.utc);
                                        const endDate = moment.utc(date.utc);
                                        if (startDate.isValid() && endDate.isValid()) {
                                            setSelectedDate({
                                                start: startDate.format("YYYY-MM-DD"),
                                                end: endDate.format("YYYY-MM-DD"),
                                            });
                                        }
                                    }
                                }}
                            />
                        )}
                        {datePickerField && datePickerField.enable && datePickerField.options.singleWeek && (
                            <DatePicker
                                ref={register({
                                    required: "Field Required",
                                    validate: {
                                        exists: async (date) => {
                                            const dateRangeArr = date.split(" – ");
                                            const startDate = dateRangeArr[0] ? moment.utc(dateRangeArr[0]) : "";
                                            const endDate = dateRangeArr[0] ? moment.utc(dateRangeArr[1]) : "";
                                            if (endDate.diff(startDate, "days") > 7) {
                                                return false;
                                            }
                                            return true;
                                        },
                                    },
                                })}
                                name="dates"
                                label="Single Week"
                                range="week"
                                input="[W]W/YYYY"
                                error={errors.dates && "Please Select Single week (Sun - Sat)"}
                                valid={!errors.dates && values.dates && values.dates.length > 0}
                                onChange={(date) => {
                                    if (date) {
                                        const dateRangeArr = date.split(" – ");
                                        const startDate = dateRangeArr[0] ? moment.utc(dateRangeArr[0]) : "";
                                        const endDate = dateRangeArr[0] ? moment.utc(dateRangeArr[1]) : "";
                                        if (startDate.isValid() && endDate.isValid()) {
                                            setSelectedDate({
                                                start: startDate.format("YYYY-MM-DD"),
                                                end: endDate.format("YYYY-MM-DD"),
                                            });
                                        }
                                    }
                                }}
                            />
                        )}
                        {datePickerField && datePickerField.enable && datePickerField.options.weekRange && (
                            <DatePicker
                                ref={register}
                                name="dates"
                                label="Week Range"
                                range="week"
                                input="[W]W/YYYY"
                                onChange={(date) => {
                                    if (date) {
                                        const dateRangeArr = date.split(" – ");
                                        const startDate = dateRangeArr[0] ? moment.utc(dateRangeArr[0]) : "";
                                        const endDate = dateRangeArr[0] ? moment.utc(dateRangeArr[1]) : "";
                                        if (startDate.isValid() && endDate.isValid()) {
                                            setSelectedDate({
                                                start: startDate.format("YYYY-MM-DD"),
                                                end: endDate.format("YYYY-MM-DD"),
                                            });
                                        }
                                    }
                                }}
                            />
                        )}
                        {datePickerField.enable && datePickerField.options.dateRange && (
                            <DatePicker
                                ref={
                                    subReport == "bonus-report"
                                        ? register({
                                              required: "Field Required",
                                              validate: {
                                                  exists: async (date) => {
                                                      const dateRangeArr = date.split(" – ");
                                                      const startDate = dateRangeArr[0]
                                                          ? moment.utc(dateRangeArr[0])
                                                          : "";
                                                      const endDate = dateRangeArr[0]
                                                          ? moment.utc(dateRangeArr[1])
                                                          : "";
                                                      if (subReport == "bonus-report") {
                                                          if (endDate.diff(startDate, "days") > 7) {
                                                              return false;
                                                          }
                                                      }

                                                      return true;
                                                  },
                                              },
                                          })
                                        : register({
                                              required: datePickerField?.required ? datePickerField?.required : false,
                                          })
                                }
                                // ref={register()}
                                name="dates"
                                key={subReport + "-date"}
                                label="Date Range (Select Multiple Days)"
                                range="date"
                                error={
                                    errors.dates &&
                                    (datePickerField.validationMsg
                                        ? datePickerField.validationMsg
                                        : "Please select date range")
                                }
                                valid={
                                    subReport == "bonus-report"
                                        ? !errors.dates && values.dates && values.dates.length > 0
                                        : ""
                                }
                                onChange={(date) => {
                                    if (date) {
                                        const dateRangeArr = date.split(" - ");
                                        const startDate = dateRangeArr[0] ? moment.utc(dateRangeArr[0]) : "";
                                        const endDate = dateRangeArr[0] ? moment.utc(dateRangeArr[1]) : "";
                                        if (startDate.isValid() && endDate.isValid()) {
                                            setSelectedDate({
                                                start: startDate.format("YYYY-MM-DD"),
                                                end: endDate.format("YYYY-MM-DD"),
                                            });
                                        }
                                    }
                                }}
                                glue={" - "}
                                handleManualDate={subReport === "incident-summary-pdf" ? true : false}
                                defaultValues={[new Date(selectedDate.start), new Date(selectedDate.end)]}
                            />
                        )}
                        {driverStatusField && driverStatusField?.enable && <Spacer bottom={5} />}
                        {driverStatusField && driverStatusField?.enable && (
                            <TagInput
                                ref={register({
                                    required: driverStatusField?.required ? driverStatusField?.required : false,
                                })}
                                name="driverStatus"
                                label="Driver Status"
                                visibleOptionsQty={6}
                                options={employeeStatusArr.map((u) => {
                                    return { name: `${u?.name}`, value: u.value };
                                })}
                                defaultValues={driverStatusField?.defaultValue}
                                singleOption={!driverStatusField.options.isMultiple}
                                error={
                                    errors.drivers &&
                                    (driverStatusField?.validationMsg
                                        ? driverStatusField?.validationMsg
                                        : "Please select driver status")
                                }
                                placeholder={(() => {
                                    if (!employeeStatusArr.length) return "Loading...";

                                    if (employeeStatusArr.length) return "Select a driver status...";

                                    return "";
                                })()}
                            />
                        )}
                        <Spacer bottom={5} />
                        <Button type="primary">Export</Button>
                    </form>
                )}
            </Spacer>
        </Content>
    );
};

export default SubDynamic;
