import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import React from 'react';
import Container from './Container';
import ContainerAutomatedScorecardCoachingMetric from "./ContainerAutomatedScorecardCoachingMetric";
// import {fetchScorecardDetails} from "./util";
// import Loading from "../../../../Chat/Loading";
// import {useSelectedStation} from "../../../../components/StationSelector";
interface Props {
    tabs: string | null
}

export default function Template(props: Props) {
    const tabName = props?.tabs ?? "";
    // const [station,] = useSelectedStation();
    // const [cards, setCards] = useState<Item[]>([]);
    // const [loading, setLoading] = useState(true);
    // const [num, setNum] = useState(4);

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     fetchScorecardDetails(station, setLoading, setCards, setNum).then();
    //     return () => {
    //         abortController.abort();
    //     };
    // }, [station]);

    // if (loading) {
    //     return <Loading containerWidth={'500px'} containerHeight={'400px'}/>;
    // }

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                {tabName === "AutomatedScorecardCoaching" ? (
                        <ContainerAutomatedScorecardCoachingMetric />
                ) : (
                        <Container />
                )}
            </DndProvider>
        </div>
    );
}