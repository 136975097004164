import {useState, useCallback, useEffect} from "react";
import {DataGridProps} from "react-data-grid";
import useIsFirstRender from "../../../hooks/useIsFirstRender";

type defaultValueProps = DataGridProps<any>['groupBy'];

const uniqueProp = (data,key) => {
    let startIndex = 0, lastIndex = data.length
    const result = {}
    while (startIndex < lastIndex) {
        const firstData = data[startIndex]
        if(!result[firstData[key]]) {
            result[firstData[key]] = true
        }
        startIndex++

        if(data[lastIndex]){
            const lastData = data[lastIndex]
            if(!result[lastData[key]]) {
                result[lastData[key]] = true
            }
            lastIndex++
        }
    }

    return Object.keys(result)
}

const useGroupColumn = (rows, defaultValue:defaultValueProps) => {
    const [groups, setGroups] = useState<readonly string[]>(
        () => defaultValue ? defaultValue : []
    )

    const [expandedIds, setExpandedIds] = useState<ReadonlySet<unknown>>(
        () => new Set<unknown>([])
    )

    const isFirstRender = useIsFirstRender()

    const ExpandGroupById = useCallback((newSetIds)=>{
        setExpandedIds(newSetIds)
    },[])

    const setGroup = useCallback((row)=>{
        setGroups(row)
    },[])

    useEffect(() => {
        if(rows.length > 0 && groups.length > 0){
            setExpandedIds(()=>new Set<unknown>(uniqueProp(rows,groups[0])))
        }else{
            if(!isFirstRender) return
            setExpandedIds(()=>new Set<unknown>([]))
        }
    },[isFirstRender,rows,groups])

    return {selectedGroups:groups,setGroup, expandedIds, ExpandGroupById }
}

export default useGroupColumn