
const VanAutoAssignmentReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {

        case 'ADD':
            return {
                ...state,
                ...payload,
            };

        case 'PACKAGE_RULES':
            return {...state,packageRules:payload};

        case 'VEHICLE_ORDER':
            return {...state,vehicleOrder:payload};            

        case 'DRIVER_AFINITY':
            return {...state,driverAffinity:payload};            

        case 'CONFLICT':
            return {...state,conflict:payload};                        

        case 'IS_SETTING':
            return {
                ...state,
                setting: {
                    ...state.setting,
                    isEnable: payload
                }
            };

        case "REFRESH":
            return { ...state };

        default:
            return state;
    }
}
export default VanAutoAssignmentReducer
