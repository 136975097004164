import React, { useEffect } from 'react';
import { Spacer, ThirdTitle } from "@dspworkplace/ui";
import {DropMemberList, LimitInput, OrderMemberList} from './MembersSelect';
import { chart_types } from '../../lookups';
import {ChartFilters,ChartFilterTimeDimension} from "../../components/chartFilter";

const ConfigPanel = ({
    measures,
    measuresList,
    dimensions,
    dimensionsList,
    limit,
    query,
    updateMeasures,
    updateDimensions,
    updatePivotConfig,
    updateQuery,
    chartRules,
    chartConfig,
    filters,
    timeDimensions,
    availableTimeDimensions,
    updateFilters,
    updateTimeDimensions,
    withFilter=true,
    columnOrder,
    setColumnOrder,
    tabIndex,
    showTimeDimension,
    setShowTimeDimension,
    attendanceSetting,
    ...cube
}) => {
    const [chartConfigData] = chartConfig;

    useEffect(() => {
        cube.updateChartType(chartConfigData?.schema);
        updatePivotConfig.update({fillMissingDates:false });
    },[]);

    const updatedAvailableTimeDimensions = () => {
        return availableTimeDimensions.filter((t) => {
            if(t.name.includes(chartConfigData?.schema + '.')) return true
            else return false
        });
    }

    if(!query.limit) return null;

    const addTimeDimensionsToOrdermembers = () => {
        const tempData = cube.orderMembers || [];
        function isOrderExists(id) {
            return tempData.some(member => member.id === id);
        }
        timeDimensions.forEach(timeDimension => {
            if (timeDimension.dimension && timeDimension.dimension.name) {
                const { name, shortTitle } = timeDimension.dimension || {};
                const order = {
                    id: name,
                    order: 'none',
                    title: shortTitle,
                };
                if (!isOrderExists(order.id)) {
                    tempData.push(order);
                }
            }
        });
        return tempData;
    };

    return (
        <>
            <Spacer top={4} />
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
                <DropMemberList
                    queryKey={'dimensions'}
                    title={'Dimensions'}
                    members={
                        query?.dimensions
                            ?
                            dimensionsList.filter((d)=> {
                                if(query.dimensions.includes(d.value.name)) return false;
                                if(d.value.type === 'time')  return false;
                                return true;
                            })
                            :
                            dimensionsList.filter(d => d.value.type !== 'time')
                    }
                    selectedMembers={dimensions}
                    updateMembers={updateDimensions}
                    updateQuery={updateQuery}
                    itemLimit={chartRules?.config?.dimension?.limit}
                    allowRenameLabel={chartRules?.config?.dimension?.allowRenameLabel}
                    chartConfig={chartConfig}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    tabIndex={tabIndex}
                />
                <DropMemberList
                    queryKey={'measures'}
                    title={'Measures'}
                    members={
                        query?.measures
                        ?
                            measuresList.filter((m)=>!query.measures.includes(m.value.name))
                        :
                            measuresList
                    }
                    selectedMembers={measures}
                    updateMembers={updateMeasures}
                    updateQuery={updateQuery}
                    itemLimit={chartRules?.config?.measure?.limit}
                    allowRenameLabel={chartRules?.config?.measure?.allowRenameLabel}
                    chartConfig={chartConfig}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    tabIndex={tabIndex}
                />
                <LimitInput
                    limit={limit}
                    querylimit={query.limit}
                    onChange={ limit =>{
                        updatePivotConfig.update({ limit: parseInt(limit) })
                    }}
                />
            </div>
            <Spacer top={8} />
            <ThirdTitle>Time Dimensions</ThirdTitle>
            <ChartFilterTimeDimension
                updateTimeDimensions={updateTimeDimensions}
                availableTimeDimensions={updatedAvailableTimeDimensions()}
                timeDimensions={timeDimensions}
                chartConfig={chartConfig}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                tabIndex={tabIndex}
                showTimeDimension={showTimeDimension}
                setShowTimeDimension={setShowTimeDimension}
                attendanceSetting={attendanceSetting}
            />

            {withFilter &&
                <>
                    <Spacer top={4} />
                        <ThirdTitle>Filters</ThirdTitle>
                        <ChartFilters
                            measuresList={measuresList}
                            dimensionsList={dimensionsList}
                            chartConfig={chartConfig}
                            filters={filters}
                            updateFilters={updateFilters}
                        />
                </>}

            <Spacer top={4} />
            <OrderMemberList
                title={'Order Fields'}
                availableMembers={addTimeDimensionsToOrdermembers()}
                selectedMembers={query.order}
                schema={chartConfigData.schema}
                updateOrder={cube.updateOrder}
                updateQuery={updateQuery}
            />
        </>
    );
}

export default ConfigPanel