import React, {createContext, useReducer} from 'react';
import WorkFlowReducer from './reducer';

const Store = createContext({});

const initialState = {
    lists: [],
    isLoading:true,
    company:'',
    modalVisible: true
}

const WorkFlowProvider = ({children}) => {
    const useWorkFlowState = useReducer(WorkFlowReducer,initialState)
    return (
        <Store.Provider value={useWorkFlowState}>
            {children}
        </Store.Provider>
    )
}
export {WorkFlowProvider}

export default Store
