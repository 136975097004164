import React from 'react';
import {portalPromise} from "../../../components/Dialog";
import {useForm} from "react-hook-form";
import {Button, Input, Spacer} from "@dspworkplace/ui";
import Modal from "../../../components/Modal";

const CopyChartModal = ({success,name}) => {
    const {register,handleSubmit} = useForm({defaultValues:{field:name}})

    const onSubmit = (data) => {
        success(data.field)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input name={'field'} ref={register} label={'Widget Title'}  />
            <Spacer top={4} />
            <Button type={'primary'} style={{marginLeft:'auto',display:'block'}}>Save as a copy</Button>
        </form>
    )
}

export const openCopyChart = ({ name }) => {
    return portalPromise((success, cancel)=> (
        <Modal
            visible={true}
            closeOnOverlay={true}
            setVisible={cancel}
            title={`Copying widget ${name}`}
            width={'340px'}
        >
            <CopyChartModal
                success={success}
                cancel={cancel}
                name={name}
            />
        </Modal>
    ))
};