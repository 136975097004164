import React from 'react';
import styled from "styled-components";
import {ColorOptions} from "../../../shared";
import {dspIcon} from "../../../../../../../Utilities";
import {Theme} from '@dspworkplace/ui';

const width = 428;
const cardWidth = width - 16 - 16;
const cardWidthInside = cardWidth - 2 - 2;

const CardContainer = styled.div`
    display: flex;
    // width: ${cardWidth}px;
    height: ${80 + 16}px;
    margin: 4px 16px;
    border-radius: 4px;
    flex-direction: row;
    border-color: #EAF0F6;
    border-width: 2px;
    border-style: solid;
    align-items: center;

    .leftView {
        display: flex;
        // width: ${cardWidthInside}px;
        flex-direction: column;
        // backgroundColor: 'brown',
    }

    .rightOneView {
        display: flex;
        // width: ${cardWidthInside * 0.2}px;
        // backgroundColor: 'yellow',
    }

    .rightTwoView {
        display: flex;
        // width: ${cardWidthInside * 0.2}px;
        // backgroundColor: 'blue',
    }

    .rightThreeView {
        display: flex;
        // width: ${cardWidthInside * 0.1}px;
        // backgroundColor: 'green',
    }

    .viewMetricNameContainer {
        display: flex;
        margin-left: 12px;
        // marginTop: 12,
    }

    .metricNameText {
        display: flex;
        color: #516F90;
        font-size: 22px;
    }

    .viewMetricValue {
        display: flex;
        flex-direction: row;
        margin-left: 12px;
        align-items: center;
    }

    .viewMetricValueText {
        display: flex;
        margin-right: 16px;
    }

    .viewError {
        display: flex;
        background-color: #FDEDEE;
        border-color: ${Theme.colors.error.shadow};
    }

    .viewZero {
        display: flex;
        background-color: #E5F8F6;
        border-color: ${Theme.colors.success.shadow};
    }

    .viewSuccess {
        display: flex;
        border-color: ${Theme.colors.success.shadow};
    }

    .viewWarning {
        display: flex;
        background-color: #FEF8F0;
        border-color: #DBAE60;
    }

    .metricValueText {
        display: flex;
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
    }

    .viewZero {
        display: flex;
        background-color: #FAFAFA;
        border-color: #CCCCCC;
        border-width: 1px;
        border-radius: 2px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .viewTrendDown {
        display: flex;
        background-color: #FDEDEE;
        border-color: ${Theme.colors.error.shadow};
        border-width: 2px;
        border-radius: 4px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .textTrendDown {
        display: flex;
        color: #D94C53;
        font-size: 12px;
        margin-left: 4px;
    }

    .viewTrendUp {
        display: flex;
        background-color: #E5F8F6;
        border-color: ${Theme.colors.success.shadow};
        border-width: 2px;
        border-radius: 4px;
        padding: 4px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        max-height: 24px;
    }

    .textTrendUp {
        display: flex;
        color: #00BDA5;
        font-size: 12px;
        margin-left: 4px;
    }

    .textZero {
        display: flex;
        color: #CCCCCC;
        font-size: 12px;
        margin-left: 4px;
    }

`;

type ChangeProps = {
    change: string;
    trend: string;
    icon: string;
    textColor: string;
};

const Change: React.FC<ChangeProps> = React.memo(
    ({change, icon, textColor}) => {
        return (
            <div className={'view' + icon}>
                {dspIcon({type: icon, color: ColorOptions[textColor], size: '16px'})}
                <div className={'text' + icon}>{change}</div>
            </div>
        );
    }
);

type Props = {
    metricName: string;
    metricValue: string | number | null;
    change: string;
    trend: string;
    average?: string | number;
    goal?: string | number;
    textColor: string;
    icon: string;
};

const Card: React.FC<Props> = ({
                                   metricName,
                                   change,
                                   metricValue,
                                   textColor,
                                   icon,
                                   trend
                               }) => {
    return (
        <CardContainer>
            <div className={'leftView'}>
                <div className={'viewMetricNameContainer'}>
                    <div className={'metricNameText'}>
                        {metricName}
                    </div>
                </div>
                <div className={'viewMetricValue'}>
                    <div className={'viewMetricValueText'}>
                        <div className={`metricValueText ${textColor}`}>
                            {typeof metricValue === 'number' ? metricValue : 'N/A'}
                        </div>
                    </div>
                    <Change
                        change={change}
                        trend={trend}
                        icon={icon}
                        textColor={textColor}
                    />
                </div>
            </div>
            <div className={'rightOneView'}/>
            <div className={'rightTwoView'}/>
            <div className={'rightThreeView'}/>
        </CardContainer>
    );
};

export default React.memo(Card);