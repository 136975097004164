import React from 'react';
import styled from 'styled-components';
import {Text} from "@dspworkplace/ui";
import {Link} from "../UI";

const Footer = styled.footer`
    height:76px;
    padding:20px;
    display:flex;
    justify-content:center;
    background:#CBD6E2;
    flex-direction:column;
    font-size: small;
    text-align:center;
`;

const App = () => {
    return (
        <Footer>
            <Text>&copy; {new Date().getFullYear()} <Link target='_blank' href={'https://dspworkplace.com'}>dspworkplace</Link> | All rights reserved.</Text>
            <Link href='https://dspworkplace.com/privacy' target='_blank'>Privacy Policy</Link>
            <Link href='https://dspworkplace.com/terms-and-conditions' target='_blank'>Terms of Service</Link>
        </Footer>
    );
}
export default App;
