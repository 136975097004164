import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";
import { Icon, Input, TagInput, CheckBox } from "@dspworkplace/ui";
import { useForm } from "react-hook-form";
import Loading from "../../../../../../../components/Loading";
import { DesignPanelAddContext } from "../index";
import { DesignPanelDispatchContext } from "../index";
import { Grid } from "../index";
import { CustomButton, SchedulesAddStateContext } from "../../../Add";
import ShiftTypeCell from "./ShiftTypeCell";
import SkillCell from "./SkillCell";
import { useAuth } from "../../../../../../../Auth";
import sorter from "../../../../../../../components/SorterFn";
import { covertUtcToLocal } from "../../../../../../../components/TimeFormat";

const Scroll = styled.div`
    box-sizing: border-box;
    display: flex;
    font-family: circe-rounded;
    height: 300px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    width: auto;
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const Modal = styled.div`
    display: ${(props) => (props.display ? props.display : "flex")};
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: circe-rounded;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const TagBox = styled.div`
    width: ${(props) => (props.width ? props.width : "400px")};
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    font-size: 14px;
    color: #333333;
    display: inline-flex;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "flex-start")};
    align-items: center;
    vertical-align: middle;

    div {
        width: ${(props) => (props.width ? props.width : "400px")};
    }

    ul {
        max-width: ${(props) => (props.width ? props.width : "400px")};

        li {
        }
    }

    svg {
        cursor: pointer;
    }
`;

const HiddenSubmit = styled.button`
    opacity: 0;
    width: 1px;
    height: 1px;
    position: relative;
    top: -20px;
`;
const AddShiftType = (preState) => {
    const { register, handleSubmit, reset } = useForm({
        mode: "onBlur",
        defaultValues: {},
    });
    const { state } = React.useContext(DesignPanelAddContext);
    const { dispatch } = React.useContext(DesignPanelDispatchContext);
    const { api, logout } = useAuth();
    const [allShifts, setAllShifts] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const station = preState.preState.station;
    const schedulesAddStateContext = useContext(SchedulesAddStateContext);
    const [selectedShift, setSelectedShift] = useState([]);

    const getShiftTypes = async () => {
        const params = {
            actions: {
                response: {
                    Shift: {
                        custom: {
                            functionName: "getNoramlAndBackupShiftByStationAndCompany",
                            criteria: {
                                station: station.id,
                                company: localStorage.getItem("company"),
                            },
                            get: "Shifts",
                            excludes: ["company", "shift"],
                            includes: {
                                0: "id",
                                1: "name",
                                2: "startTime",
                                3: "endTime",
                                4: "startTimeOnDst",
                                5: "endTimeOnDst",
                                6: "unpaidBreak",
                                7: "note",
                                8: "color",
                                9: "category",
                                rateRule: ["name"],
                                invoiceType: ["id", "name"],
                                skill: ["id", "name"],
                            },
                        },
                    },
                },
            },
        };

        try {
            let response = await api.post("/api/lazy/manage/data", params);
            return new Promise((resolve, reject) => {
                let temp = [];
                let skillObj = [];
                let dataResponseTemp = [];
                let shiftArr = [];
                let dataResponse = [];
                if (state.shiftTypes.length > 0 && state.shiftTypes[state.week - 1]) {
                    Object.keys(state.shiftTypes[state.week - 1]).forEach(function (index) {
                        if (
                            schedulesAddStateContext.state.weekArr[state.week - 1] ==
                            state.shiftTypes[state.week - 1][index].week
                        ) {
                            shiftArr.push(state.shiftTypes[state.week - 1][index].id);
                        }
                    });
                }

                Object.keys(response.data.data.Shifts).forEach(function (index) {
                    if (
                        shiftArr.indexOf(response.data.data.Shifts[index].id) == -1 &&
                        response.data.data.Shifts[index].skill
                    ) {
                        temp = {
                            name: response.data.data.Shifts[index].skill.name.toString(),
                            value: response.data.data.Shifts[index].skill.name.toString(),
                        };
                        skillObj.push(temp);
                        dataResponseTemp = {
                            id: response.data.data.Shifts[index].id,
                            name: response.data.data.Shifts[index].name,
                            color: response.data.data.Shifts[index].color,
                            range:
                                covertUtcToLocal(response.data.data.Shifts[index].startTime, state.station.timezone) +
                                " - " +
                                covertUtcToLocal(response.data.data.Shifts[index].endTime, state.station.timezone),
                            rangeOnDst:
                                covertUtcToLocal(response.data.data.Shifts[index].startTimeOnDst, state.station.timezone) +
                                " - " +
                                covertUtcToLocal(response.data.data.Shifts[index].endTimeOnDst, state.station.timezone),
                            skill: response.data.data.Shifts[index].skill.name,
                            skillId: response.data.data.Shifts[index].skill.id,
                            invoice: response.data.data.Shifts[index].invoiceType
                                ? response.data.data.Shifts[index].invoiceType.name
                                : "-",
                            break: response.data.data.Shifts[index].unpaidBreak + "m",
                            startTime: response.data.data.Shifts[index].startTime,
                            endTime: response.data.data.Shifts[index].endTime,
                            startTimeOnDst: response.data.data.Shifts[index].startTimeOnDst,
                            endTimeOnDst: response.data.data.Shifts[index].endTimeOnDst,
                            category: response.data.data.Shifts[index].category,
                        };
                        dataResponse.push(dataResponseTemp);
                    }
                });

                setAllSkills(
                    skillObj.filter((ele, ind) => ind === skillObj.findIndex((elem) => elem.name === ele.name))
                );
                setTimeout(() => {
                    // let dataResponse = response.data.data.Shifts.map((shift) => { return {id: shift.id, name:shift.name, color:shift.color, range: shift.startTime+' - '+shift.endTime, skill:shift.skill.name, invoice: shift.invoiceType.name, break: shift.unpaidBreak+'m'}});
                    setAllShifts(dataResponse);
                    dispatch({
                        type: "setSearchShiftTypes",
                        searchShiftTypes: dataResponse,
                        loading: false,
                    });
                }, 10);
            });
        } catch (error) {
            alert("Error to update rate. Login again, please").then(() => {});
        }

        /*
    return */
    };

    const handleSearchChange = async (data) => {
        await setTimeout(() => {
            if (filterRef.current) {
                filterRef.current.click();
            }
        }, 600);
    };
    const filterRef = useRef();

    const onSearch = async (data) => {
        try {
            dispatch({
                type: "setSearchShiftTypes",
                searchShiftTypes: [],
                loading: true,
            });
            let name = data.name.toLowerCase();
            let skills = data.skills;
            let results = [];
            let b = allShifts;
            let searchShiftTypes = allShifts;
            searchShiftTypes.forEach((item, index) => {
                if (item.name.toLowerCase().search(name) > -1 && skills.length === 0) {
                    results.push(item);
                } else if (item.name.toLowerCase().search(name) > -1 && skills.length > 0) {
                    for (let i = 0; i < skills.length; i++) {
                        if (searchShiftTypes[index].skill.includes(skills[i])) {
                            results.push(item);
                            break;
                        }
                    }
                }
            });
            dispatch({
                type: "setSearchShiftTypes",
                searchShiftTypes: results,
                loading: false,
            });
        } catch (error) {
            alert("Error to update rate. Login again, please").then(() => {});
        }
        // reset({name:'', skills:''});
    };

    const onSubmit = (data) => {
        let results = [];
        allShifts.map((item, index) => {
            if (selectedShift.indexOf(item.id.toString()) !== -1) {
                const elem = item;
                elem["week"] = schedulesAddStateContext.state.weekArr[state.week - 1];
                results.push(elem);
            }
        });
        reset({ name: "", skills: "" });
        dispatch({
            type: "addSelectedShiftTypes",
            selectedShiftTypes: results,
            loading: false,
            open: false,
            week: state.week,
            isCopyWeek: 1,
        });
    };

    const handleOrder = (fieldName, direction) => {
        const orderedData = sorter(state.searchShiftTypes, fieldName, !direction);
        dispatch({
            type: "setSearchShiftTypes",
            searchShiftTypes: orderedData,
            loading: false,
        });
    };

    const handlerCurrentOrder = (fieldName) => {
        if (!state.searchShiftTypes.sort || !state.searchShiftTypes.sort[0]) return 0;
        return state.searchShiftTypes.sort && state.searchShiftTypes.sort[0] == fieldName
            ? !state.searchShiftTypes.sort[1]
            : 0;
    };

    const checkboxCheckEvent = async (id) => {
        let list = selectedShift;
        let checkboxObj = document.querySelector("[data-name='shiftIndexes_" + id + "']");
        if (checkboxObj.checked) {
            if (list.indexOf(id.toString()) === -1) {
                list.push(checkboxObj.value);
            }
        } else {
            list.forEach(function (val, i) {
                if (val === id.toString()) {
                    list.splice(i, 1);
                }
            });
        }
        setSelectedShift(list);
    };
    function getDriverId(id) {
        let did = 0;
        selectedShift.forEach(function (val, i) {
            if (val === id.toString()) {
                did = id;
            }
        });
        return did;
    }

    useEffect(() => {
        getShiftTypes();
    }, []);

    return (
        <Modal>
            <Grid padding="20px" width="auto" height="auto">
                <Grid templateColumns="auto auto" padding="0 0 20px 0" justifyContent="space-between">
                    <Grid color="#516F90" fontSize="22px">
                        Add Shift Types
                    </Grid>
                    <Grid
                        color="#0071BC"
                        fontSize="14px"
                        cursor="pointer"
                        onClick={() => dispatch({ type: "setOpen", open: false, week: 0 })}
                    >
                        Cancel
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit(onSearch)}>
                    <Grid templateColumns="auto auto" columnGap="20px" padding="0 0 20px 0" justifyContent="start">
                        <Input
                            name="name"
                            label="Search"
                            ref={register({ required: false })}
                            onBlur={handleSearchChange}
                        >
                            <Icon.Search onClick={handleSearchChange} />
                        </Input>
                        <TagBox>
                            <TagInput
                                width="400px"
                                name="skills"
                                label="Skills"
                                ref={register({ required: false })}
                                options={allSkills}
                                onChange={handleSearchChange}
                            ></TagInput>
                        </TagBox>
                        <HiddenSubmit ref={filterRef} type="submit" />
                    </Grid>
                </form>
                <Grid templateColumns="68px 240px 320px 140px 240px" borderBottom="1px solid #7C98B6">
                    <Grid padding="0 0 0 8px">
                        <CheckBox
                            name={"allShiftTypes"}
                            title={""}
                            options={[{ value: "", label: "" }]}
                            onClick={() => {
                                dispatch({ type: "selectAll" });
                            }}
                        ></CheckBox>
                    </Grid>
                    <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px" justifyContent="start">
                        <Grid
                            onClick={() => {
                                handleOrder("name", handlerCurrentOrder("name"));
                            }}
                        >
                            Shift Type{" "}
                        </Grid>{" "}
                        <Icon.ArrowDown
                            size="16px"
                            color="#7C98B6"
                            onClick={() => {
                                handleOrder("name", handlerCurrentOrder("name"));
                            }}
                        ></Icon.ArrowDown>
                    </Grid>
                    <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px" justifyContent="start">
                        <Grid
                            onClick={() => {
                                handleOrder("skill", handlerCurrentOrder("skill"));
                            }}
                        >
                            Skills
                        </Grid>{" "}
                        <Icon.ArrowDown
                            size="16px"
                            color="#7C98B6"
                            onClick={() => {
                                handleOrder("skill", handlerCurrentOrder("skill"));
                            }}
                        ></Icon.ArrowDown>
                    </Grid>
                    <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px" justifyContent="start">
                        <Grid
                            onClick={() => {
                                handleOrder("break", handlerCurrentOrder("break"));
                            }}
                        >
                            Break
                        </Grid>{" "}
                        <Icon.ArrowDown
                            size="16px"
                            color="#7C98B6"
                            onClick={() => {
                                handleOrder("break", handlerCurrentOrder("break"));
                            }}
                        ></Icon.ArrowDown>
                    </Grid>
                    <Grid templateColumns="auto auto" padding="12px 0 12px 0" columnGap="8px" justifyContent="start">
                        <Grid
                            onClick={() => {
                                handleOrder("invoice", handlerCurrentOrder("invoice"));
                            }}
                        >
                            Invoice Type
                        </Grid>{" "}
                        <Icon.ArrowDown
                            size="16px"
                            color="#7C98B6"
                            onClick={() => {
                                handleOrder("invoice", handlerCurrentOrder("invoice"));
                            }}
                        ></Icon.ArrowDown>
                    </Grid>
                </Grid>

                <Scroll padding={state.loading ? "40px" : "0"}>
                    {state.loading && <Loading></Loading>}
                    {state.searchShiftTypes.length > 0 ? (
                        !state.loading &&
                        state.searchShiftTypes.map((item, index) => {
                            return (
                                <Grid
                                    key={(index + 1) * 88}
                                    templateColumns="28px 280px 320px 140px 216px 24px"
                                    borderBottom="1px dashed #CBD6E2"
                                >
                                    <Grid padding="8px 0 0 8px">
                                        <CheckBox
                                            data-shift-type={"shift-type"}
                                            name="shiftTypesIndexes"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: item.id, label: "" }]}
                                            data-name={"shiftIndexes_" + item.id}
                                            options={[{ value: item.id, label: "" }]}
                                            defaultValues={[getDriverId(item.id) > 0 ? getDriverId(item.id) : ""]}
                                            onClick={() => {
                                                checkboxCheckEvent(item.id);
                                            }}
                                        ></CheckBox>
                                    </Grid>
                                    <ShiftTypeCell
                                        name={item.name}
                                        range={item.range}
                                        rangeOnDst={item.rangeOnDst}
                                        color={item.color}
                                        category={item.category}
                                        start={item.startTime}
                                        end={item.endTime}
                                        startOnDst={item.startTimeOnDst}
                                        endOnDst={item.endTimeOnDst}
                                        stationTimeZone={state.station.timezone}
                                    ></ShiftTypeCell>

                                    <SkillCell skill={item.skill} padding="8px 8px 8px 0"></SkillCell>
                                    <Grid>{item.break}</Grid>
                                    <Grid>{item.invoice}</Grid>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "20px",
                                border: "none",
                            }}
                        >
                            <Icon.Empty color="#CCCCCC" size="60px" />
                        </Grid>
                    )}
                </Scroll>
                <Grid padding="20px 0 0 0" justifyContent="start">
                    <CustomButton type="primary" onClick={handleSubmit(onSubmit)}>
                        Add Selected
                    </CustomButton>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default AddShiftType;
