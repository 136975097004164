import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/logo.svg';
import {Theme, ThirdTitle, Spacer, Text, Input, Password, Button} from '@dspworkplace/ui';
import {useForm} from "react-hook-form";
import Footer from '../../components/Footer';
import {patternEmail, validatePassword} from "../../patterns";
import {auth} from "../../Auth";

const Body  = styled.div`
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items: stretch;
`;

const Section = styled.section`
    background:${Theme.colors.info.bg}
    display: flex;
    min-height: 100%;
    justify-content:center;
    align-items:center;
    flex-grow:1;

`;

const Card = styled.div`
    background:${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px #33333326;
    padding:20px;
    border-radius:${Theme.defaultRadius};
    max-width:340px;
`;

const Image = styled.img`
    width:300px;
    margin:0 auto;
    display:block;
    height:auto;

`;

const Btn = styled(Button)`
    width:100%;
`;

const errorMessages = {
    email: {
        required: 'You need to provide an email',
        pattern: 'Enter a valid email'
    },
    password: {
        required: 'You need to provide a password to log in',
        pattern: 'Your password must respect all rules'
    }
}

const App = (changeRoute) => {
    const { register, handleSubmit, errors, getValues  } = useForm();
    const {email, password} = getValues();
    localStorage.clear();
    const onSubmit = data =>
    {
        auth.post('/register', {
            email: data.email,
            password: data.password
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error.response);
          });

          changeRoute.history.push('/login');

    }


    return (
        <Body>
            <Section>
                <div>
                    <Image src={Logo} />
                    <Spacer top={6} />
                    <Card>
                        <ThirdTitle>Registration</ThirdTitle>
                        <Spacer top={2} />

                        <Text>You were invited to join this dspworkplace by Jon Doe. Enter your password to complete or registration.</Text>
                        <Spacer top={4} />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                name='email'
                                label='Email'
                                ref={register({
                                    required:true,
                                    pattern: patternEmail
                                })}
                                error={errors.email && errorMessages.email[errors.email.type]}
                                valid={!errors.email && email && email.length > 0}
                                autoComplete='off'
                            />
                            <Spacer top={4} />

                            <Password
                                name='password'
                                label='Password'
                                help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                                ref={register({
                                    required:true,
                                    validate: (value) => {
                                        if (value) {
                                            return validatePassword(value, email, []);
                                        }
                                    }
                                })}
                                error={errors.password && errorMessages.password[errors.password.type]}
                                valid={!errors.password && password && password.length > 0}
                            />
                            <Spacer top={4} />

                            <Btn type='primary'>Register</Btn>

                        </form>
                    </Card>

                </div>
            </Section>
            <Footer/>
        </Body>
    );
}

export default App;
