import React, {useEffect, useState, Dispatch, useCallback} from "react";
import {getChannelFilters, getEmployeesTwilio, updateGroupChannel} from "./utils";
import {
    Grid,
    PrimaryButton,
    TagInputContainer,
    ReturnButton,
    ChannelGroupFilter,
    ChannelGroupFilters,
    CustomButton
} from "./styles";
import {LoadingView} from "../components/Loading";
import FlexScroll from "../components/SimpleScroll";
import Person from "./Person";
import {ChannelSettingsState, ChannelSettingsAction} from "./ChannelSettings";
import {Icon, TagInput} from "@dspworkplace/ui";
import {showToast} from "../Utilities";

type Props = {
    state: ChannelSettingsState;
    dispatch: Dispatch<ChannelSettingsAction>;
    cancelModal: () => void;
    setUpGroupChannel: () => void;
};

function ChannelSettingsPanelUpdateMembers(props: Props) {
    const {state, dispatch, setUpGroupChannel} = props;
    const [loading, setLoading] = useState(false);
    const {
        channel,
        userMatches,
        searchAddFilterContent,
        usersToAdd,
        filterResult,
        stations,
        roles,
        schedules,
        skills,
        users,
        tagsDefaultAutoUsers,
        teams,
        chatVariable
    } = state;
    const [refresh, setRefresh] = useState(1.0);

    const goBack = () => {
        // @ts-ignore
        document.querySelectorAll("[data-tab='tab']")[1].click();
    };

    const handleTagChange = useCallback(
        data => {
            dispatch({type: "FILTER_NEW_USERS", filter: data});
        },
        [dispatch]
    );

    const selectAllMatches = useCallback(() => {
        dispatch({type: "SELECT_ALL_NEW_USERS"});
    }, [dispatch]);

    const addUser = useCallback(
        user => () => {
            dispatch({type: "ADD_NEW_USER", user: user});
        },
        [dispatch]
    );

    const removeNewUser = useCallback(
        user => () => {
            dispatch({type: "REMOVE_NEW_USER", user: user});
        },
        [dispatch]
    );

    const updateRefresh = useCallback(
        () => {
            setLoading(true);
            setRefresh(prev => prev + 1.0);
        },
        [],
    );

    const addNewUsersToChannel = useCallback(async () => {
        if (channel && usersToAdd.length > 0) {
            setLoading(true);
            await updateGroupChannel(channel, usersToAdd, users, filterResult, {
                stations,
                skills,
                schedules,
                roles,
                teams,
                chatVariable,
            });
            // @ts-ignore
            await setUpGroupChannel();
            // @ts-ignore
            document.querySelectorAll("[data-tab='tab']")[1].click();
        } else {
            await showToast({
                type: 'info',
                title: 'Issue updating members',
                name: 'Please click the "Update List" button before updating users.'
            })
        }
    }, [channel, usersToAdd, users, filterResult, stations, skills, schedules, roles, setUpGroupChannel, teams, chatVariable]);

    const deleteAllNewUsers = useCallback(() => {
        dispatch({type: 'DELETE_ALL_NEW_USERS'});
    }, [dispatch]);

    useEffect(() => {
        const abortController = new AbortController();
        if (channel && refresh > 1.0) {
            setLoading(true);
            getEmployeesTwilio()
                .then(async result => {
                    try {
                        if (result) {
                            let {filterResult: filters} = await getChannelFilters(channel.sid);
                            dispatch({
                                type: "SET_USERS",
                                users: result.users,
                                roles: result.roles,
                                skills: result.skills,
                                stations: result.stations,
                                schedules: result.schedules,
                                previousFilter: filters,
                                defaultAutoUsers: result.defaultAutoUsers,
                                teams: result.teams,
                                chatVariable: result.chatVariable,
                            });
                            setLoading(false);
                        }
                    } catch (e) {
                        console.error({e});
                    }
                })
        }

        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    // console.log({searchAddFilterContent, filterResult});

    if (loading) {
        return <LoadingView containerHeight={"400px"} imgHeight={"80px"}/>;
    }

    return (
        <Grid templateRows={"repeat(6, auto)"} rowGap={"12px"}>
            <TagInputContainer>
                <TagInput
                    label="Find by name, email, skill, role, schedules, station and team:"
                    options={searchAddFilterContent}
                    defaultValues={filterResult}
                    size="big"
                    onChange={handleTagChange}
                    name={'tags'}
                />
                <ReturnButton
                    className={"refresh"}
                    width={"30px"}
                    height={"30px"}
                    borderRadius={"50%"}
                    onClick={updateRefresh}
                >
                    <Icon.Refresh size={"24px"}/>
                </ReturnButton>
            </TagInputContainer>

            <Grid templateColumns={"auto auto"} justifyContent={"space-between"}>
                <Grid color="#707070" fontSize="16px">
                    {`${userMatches.length} search results:`}
                </Grid>
                <PrimaryButton type="primary" fontSize={"14px"} padding={"4px 6px"} onClick={selectAllMatches}>
                    Update Filter and Members
                </PrimaryButton>
            </Grid>

            <FlexScroll
                height={userMatches.length > 0 && usersToAdd.length === 0 ? "200px !important" : "150px !important"}
                width="100%">
                {userMatches.map(item => {
                    return (
                        <Person
                            key={item.userId}
                            friendlyName={item.friendlyName}
                            skills={item.skills}
                            image={item.image}
                            stations={item.stations}
                            role={item.roles[0].roleName}
                            schedules={item.schedules.map(e => e.name)}
                            plus={false}
                            callback={addUser(item)}
                            noOption={true}
                        />
                    );
                })}
            </FlexScroll>

            <Grid templateRows={"auto auto auto auto auto"} rowGap={"4px"} margin={'0'}>
                <Grid templateColumns={"auto auto"} justifyContent={"space-between"}>
                    <Grid color="#707070"
                          fontSize="14px">{`${usersToAdd.length} Members selected.`}</Grid>
                    <CustomButton width="156px" height="24px" type="primary" fontSize={"14px"}
                                  onClick={deleteAllNewUsers}>
                        Undo
                    </CustomButton>
                </Grid>
                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`This the new search filter to be saved:`}</Grid>
                <ChannelGroupFilters>
                    {filterResult.map(e => {
                        return <ChannelGroupFilter key={e.value}>
                            {e.name}
                        </ChannelGroupFilter>
                    })}
                </ChannelGroupFilters>
                {Array.isArray(tagsDefaultAutoUsers) && tagsDefaultAutoUsers.length > 0 && <>
                    <Grid color="#707070" fontSize="12px"
                          fontWeight={"bold"}>{`The following default users will be included:`}</Grid>
                    <ChannelGroupFilters>
                        {tagsDefaultAutoUsers.map(e => {
                            return <ChannelGroupFilter key={e.value}>
                                {e.name}
                            </ChannelGroupFilter>
                        })}
                    </ChannelGroupFilters>
                </>}
                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`This group chat will consist of the following users:`}</Grid>
                <FlexScroll height={usersToAdd.length > 0 ? "150px !important" : "0 !important"} width="100%">
                    {usersToAdd.map(item => {
                        return (
                            <Person
                                key={item.userId}
                                friendlyName={item.friendlyName}
                                skills={item.skills}
                                image={item.image}
                                stations={item.stations}
                                role={item.roles[0].roleName}
                                schedules={item.schedules.map(e => e.name)}
                                remove={false}
                                callback={removeNewUser(item)}
                                noOption={true}
                            />
                        );
                    })}
                </FlexScroll>
                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`There are ${usersToAdd.length} total users to be saved and updated.`}</Grid>
            </Grid>

            <Grid justifyContent="space-between" templateColumns="auto auto" margin={"16px 0 0 0"}>
                <PrimaryButton className={"modal"} type="secondary" onClick={goBack}>
                    Back
                </PrimaryButton>
                <PrimaryButton className={"modal"} type="primary" onClick={addNewUsersToChannel}
                               disabled={usersToAdd.length <= 0}>
                    Save Changes
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default ChannelSettingsPanelUpdateMembers;
