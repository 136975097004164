import React, {useState, useMemo} from "react";
import { Icon} from "@dspworkplace/ui";

import {openTemplateArchiveForm, openTemplateForm} from "./Common/CreateTemplateForm";
import {DataGrid} from "../../../../components/Grid";
import {GridAction} from "../../../../components/Grid/formatters";
import useGridApi from "../../../../api/useGridApi";
import {
    useVehicleMaintenancePlan,
    VehicleMaintenancePlanInterface
} from "../../../../domain/vehicle_maintenance_plan/api";
import {GridColumnProps} from "../../../../components/Grid/types";
import {useSelectedStation} from "../../../../components/StationSelector/StationPicker";
import {VehicleMaintenancePlan} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlan";
import {useRecoilRefresher_UNSTABLE} from "recoil";
import {VMPlanType} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanType";

type InsertUpdatePlanItemInterface = {
    category_id: number,
    category?: string,
    category_parent_id?: number,
    service_interval_days: number,
    service_interval_miles: number,
    notifications: {
        miles_prior: number,
        days_prior: number,
        modify_task_priority_to: string,
        ground_van: boolean
    }[]
}

const parseFormRequestToApi = (formRequest): InsertUpdatePlanItemInterface[] => {
    return formRequest.rules.map(item => {
        const itemRequest = {
            id: item.id || null,
            required:item?.required,
            category_id: item?.category?.value || item?.category?.id,
            category_parent_id: item.type.value,
            service_interval_days: parseInt(item.service_interval_days),
            service_interval_miles: parseInt(item.service_interval_miles),
            notifications: item.notifications.map(notification => ({
                miles_prior: parseInt(notification?.miles_prior || 0),
                days_prior: parseInt(notification?.days_prior || 0),
                modify_task_priority_to: notification.modify_task_priority_to?.value || notification.modify_task_priority_to,
                ground_van: notification.ground_van
            }))
        }
        if(item?.category && item.category?.value === item.category.label){
            delete itemRequest.category_id
            itemRequest['category'] = item.category.label
        }

        return itemRequest
    })
}

const getColumns = ({handleRemove,handleEdit,handleCopy}): GridColumnProps[] => {
    return [
        {
            name: "Name",
            key: "name",
            sortable: true,
            filterable: true
        },
        {
            name: "Rules Quantity",
            key: "rules_quantity",
            formatter: ({row}) => {
                return <span>{row.items.length}</span>
            }
        },
        {
            key: 'actions',
            name: '',
            width: 40,
            formatter:({row})=>{
                return (
                    <GridAction options={[
                        {title: 'Edit', icon: Icon.Edit, onClick: ()=>{handleEdit(row)}},
                        {title: 'Delete', icon: Icon.Times,onClick: ()=>{handleRemove(row)}},
                        {title: 'Copy', icon: Icon.Copy,onClick: ()=>{handleCopy(row)}}
                    ]} />
                )
            }
        }
    ]
}

const VehicleMaintenancePlanPage = () => {
    const planState = useState<VehicleMaintenancePlanInterface[]>([]);
    const {
        fetchVehicleMaintenancePlan,
        addVehicleMaintenancePlan,
        updateVehicleMaintenancePlan,
        deleteVehicleMaintenancePlan,
        cancelApiRequest,
        copyVehicleMaintenancePlan
    } = useVehicleMaintenancePlan();
    const [selectedStation] = useSelectedStation();
    const updateTemplate = useRecoilRefresher_UNSTABLE(VehicleMaintenancePlan(selectedStation))
    const updateCategory = useRecoilRefresher_UNSTABLE(VMPlanType)
    
    const {data,status,Add,Update,Delete,Copy} = useGridApi({load: fetchVehicleMaintenancePlan, cancelRequest: cancelApiRequest, state: planState})

    const handleAdd = () => {
        openTemplateForm().then(resolve => {
            if(!resolve) return;

            const items:InsertUpdatePlanItemInterface[] = parseFormRequestToApi(resolve)
            const shouldUpdateCategory = items.some(i => !!i.category)
            Add(addVehicleMaintenancePlan,{name:resolve.name,itemsCount:0,items},`Error to save the template "${resolve.name}"`).then(newPlan => {
                updateTemplate()
                if(shouldUpdateCategory) updateCategory()
            })

        })
    }

    const columns  = useMemo(()=>{
        const handleRemove = (row) => {
            openTemplateArchiveForm(row.name).then(resolve => {
                if(!resolve) return;
                Delete(deleteVehicleMaintenancePlan,row,`Error to delete the template "${row.name}"`)
            })
        }

        const handleEdit = (row) => {
            const editPromise = new Promise( (resolve) => {
                resolve({id:row.id, name: row.name,rules:row.items})
            })

            openTemplateForm(editPromise).then(resolve => {
                if(!resolve) return;
                const items:InsertUpdatePlanItemInterface[] = parseFormRequestToApi(resolve)

                const rowToUpdate = {name: resolve.name, station_id: selectedStation, items: items}

                Update(updateVehicleMaintenancePlan(row.id,rowToUpdate),row._INDEX,rowToUpdate, row,`Error to update the template "${row.name}"`)
            })
        }

        const handleCopy = async (row) => {
            const copyTemplate = {itemsCount:0};
            Copy(copyVehicleMaintenancePlan, row,`Error to copy the template "${row.name}"`)
        }
    
        return getColumns({handleRemove,handleEdit, handleCopy})
    },[Delete, Update, deleteVehicleMaintenancePlan, selectedStation, updateVehicleMaintenancePlan])

    return (
        <DataGrid
            status={status}
            title={'Maintenance Templates'}
            rows={data}
            columns={columns}
            onAdd={handleAdd}
        />
    );
};


export default VehicleMaintenancePlanPage;
