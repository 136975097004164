import {selector} from "recoil";
import apiFactory from '../../../api/factory';
import { VMTemplateCategory } from "./VehicleMaintenancePlanCategory";
import {getCompanyId} from "../../../Auth";

export type VMTemplateType = {
    label: string
    value: number,
    children: VMTemplateCategory[]
}

export const VMPlanType = selector<VMTemplateType[]>({
    key: 'VMPlanType',
    get: async () => {
        const {call} = apiFactory();
        const response = await call(null,`api/v2/category/?entity=VehicleMaintenancePlanItem&company=${getCompanyId()}&level=0`,'get')
        const types: VMTemplateType[] = [];
        response?.data.forEach((type)=> {
            const children = type.children.map(child => ({label:child.label,value:child.id}))
            types.push({label:type.label,value:type.id,children})
        })
        return types;
    }
})