import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, SubTitle, ThirdTitle, Spacer, Dropdown, Input} from "@dspworkplace/ui";
import {Label} from "../../../components/UI";
import {useDropzone} from 'react-dropzone'
import {AddVehicleImage} from "./api";

import {ScrollyModalStyle, ScrollyOverlayStyle} from "../../../components/Modal";
import Loading, {LoadingWrapper} from "../../../components/Loading";
import styled from "styled-components";
import TagInput from "../../../components/TagInput";

export const Subtitle = styled.div`
    color: #516f90;
    font-family: circe-rounded, serif;
    font-size: 16px;
`;
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
  }
  
  const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  

const modalRoot = document.getElementById("modal-root");

const VehicleImageForm = ({api, success, cancel, vehicle, categories}) => { 

    const [loading, setLoading] = useState(false);
    const {register, errors, getValues, handleSubmit, reset} = useForm({mode: "onChange"});
    const inputValues = getValues();
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
        accept: 'image/*',
        multiple: true,
        onDrop: acceptedFiles => {
          setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));
        }
    });
    
    useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    const onSubmit = async (data) => {
        setLoading(true);
        const paramsData = await AddVehicleImage(api,data,files,vehicle.id)
        let params = {
            "actions": {
                "create": {
                    "VehicleImage": paramsData
                },
            }
        };
 
        const responseFilterData = await api.post('/api/lazy/manage/data', params);
 
        setLoading(false);
        success(responseFilterData);

    };


    return (
        <ScrollyOverlayStyle>
            <ScrollyModalStyle
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                width="750px"
                onClick={evt => evt.stopPropagation()}
            >
                <header>
                    <SubTitle>Vehicle Image</SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                {loading ? (
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                ) : (
                    <>
                    
                        <TagInput
                            ref={register()}
                            name='category'
                            label='Category'
                            size='small'
                            valid={!errors.category && inputValues.category && !errors.category}
                            error={errors.category && errors.category.message}
                            options={categories.filter((c) => c.name !== "AI Failed").map((c) => ({'name':c.name,'value':c.id}))}
                            canCreate={false}
                        />
                        <Spacer inline right={5}/>
                        <Input
                            name="label"
                            label="Note"
                            ref={register()}
                            error={errors.label && errors.label.message}
                            valid={!errors.label && inputValues.label && inputValues.label.length > 0}
                        />
                        <Spacer top={10}/>
                        <Label>Images</Label>
                        <Container {...getRootProps({className: 'dropzone'})} className="container" >
                            <div>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                                <aside style={thumbsContainer}>
                                    {files.map((file,i) => (
                                        <div style={thumb} key={file.name}>
                                        <div style={thumbInner}>
                                            <img
                                            src={file.preview}
                                            style={img}
                                            />
                                        </div>
                                        </div>
                                    ))}
                                </aside>
                            </div>
                        </Container>
                        <Input
                            name="files"
                            type='hidden'
                            Required={true}
                            defaultValue={(files.length > 0)?files.length:''}
                            ref={register({ required: 'File is required' })}
                            error={errors.files && errors.files.message}
                            valid={!errors.files && inputValues.files && inputValues.files.length > 0}
                        />

                        <Button type={"primary"} style={{marginLeft: "auto", display: "block", width: 80}}>
                            Save
                        </Button>
                    </>
                )}
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    );
};

const hide = () =>
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(modalRoot);
    }, 1);

const showVehicleImageForm = async opts => {
    let {api, children, vehicle, categories} = opts || {};

    return new Promise((resolve, reject) => {
        const success = data => hide() && resolve(data);
        const cancel = data => hide() && reject(data);

        return ReactDOM.render(
            <VehicleImageForm api={api} success={success} cancel={cancel} children={children}  vehicle={vehicle} categories={categories} />,
            modalRoot
        )
    });
    
};

export {showVehicleImageForm};
