import React from 'react';
import {Theme,Icon} from '@dspworkplace/ui';
/*
const StatusLookup = [
    {name: 'All', value: 'all'},
    {name: 'Active', value: 'Active'},
    {name: 'Available', value: 'Available'},
    {name: 'In Transit to Station', value: 'In Transit to Station'},
    {name: 'Not Available', value: 'Not Available'},
    {name: 'Repair', value: 'Repair'},
    {name: 'Service', value: 'Service'},
    {name: 'Being Serviced', value: 'Being Serviced'}
]*/

const StatusLookup =[
                    {name: 'All (Non Archived)', value: 'all'},
                    {name: 'Active', value: 'Active'},
                    {name: 'Inactive', value: 'Inactive'},
                    {name: 'Under Repair', value: 'Under Repair'},
                    {name: 'Being Serviced', value: 'Being Serviced'},
                    {name: 'Body Shop', value: 'Body Shop'},
                    {name: 'Archived', value: 'Archived'},
                ]

const statusIcon = {
    Active: <Icon.CheckNarrow size='16px' color={Theme.colors.success.text} />,
    Available: <Icon.Clock size='16px' color={Theme.colors.success.text} />,
    InTransittoStation: <Icon.Marker size='16px' color={Theme.colors.error.text} />,
    NotAvailable: <Icon.TimesNarrow size='16px' color={Theme.colors.error.text} />,
    Repair: <Icon.Maintenance size='16px' color={Theme.colors.secondary} />,
    BeingServiced: <Icon.VehicleOil size='18px' color={Theme.colors.error.text} />,
}

export {StatusLookup,statusIcon}
