import React, { useEffect } from "react";
import styled from "styled-components";
import { useChatTrackedState } from "./store";
import ChatSidebar from "./ChatSidebar";
import { Chat, useChatContext } from "stream-chat-react";
import { async } from "@dspworkplace/ui";
import { getChatCompanyId, getChatTeam, getChatUserId } from "../Auth";

const ChatContainer = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 100;
    display: ${props => (props.display ? props.display : "flex")};
`;

const AppChatSidebar: React.FC = ({ isPage }) => {

    const state = useChatTrackedState();
    const { isOpen, initialChannelsReady, chatClient, initialChannel } = state;

    const { setActiveChannel } = useChatContext();

    useEffect(() => {
        setChannel()

    }, [isOpen])

    async function setChannel() {
        const channel = await chatClient.queryChannels({ type: 'bot', members: { $in: [chatClient.userID] }, teams: { $in: [getChatTeam()] } });
        const botChannel = await channel[0];
        const messages = botChannel?.state?.messages
        const isMessageAvailable = await messages?.filter(i => i.type === 'regular')
        // if(botChannel?.state?.messages)
        if (isOpen === false) {
            if (isMessageAvailable?.length > 0)
                setActiveChannel(botChannel)
            else
                setActiveChannel(initialChannel)
        }
    }
    // const isOpen = true;
    // console.log(`Render AppChatSidebar ${JSON.stringify({initialChannelsReady, isOpen})}`);

    // if (initialChannelsReady) {

    if (isPage) {
        return (
            <div id={"chat-app"} >
                <ChatSidebar isPage={isPage} />
            </div>
        )
    }

    return (
        <ChatContainer id={"chat-app"} display={isOpen ? "flex" : "none"}>
            <ChatSidebar />
        </ChatContainer>
    );
    // }
    return null;
}

export default AppChatSidebar;
