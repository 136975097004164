import React, {useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {Icon, Button, Theme} from '@dspworkplace/ui';
import styled from 'styled-components';
import {Label} from "../../components/UI";

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const DropUploaderPreview = styled.div`
    box-sizing: border-box;
    width: 92px;
    height: 92px;
    padding: 4px;
    border-radius: ${Theme.defaultRadius};
    border: 1px solid ${Theme.colors.neutrals.silver};
    background-color: ${Theme.colors.extra.whiteSmoke};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: ${Theme.font.extraSmall.size};
    font-family: ${Theme.font.main};
    color: ${Theme.colors.neutrals.silver};
    text-align: center;
    word-break: break-word;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-size: cover;
        background-position: center;
        background-image: url(${props => typeof props.preview === 'string' && props.preview});
    }

    + div,
    + label {
        margin-left: 8px;
    }

    button {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 4px;
        padding: 4px;
        height: auto;
        line-height: 0;
    }

    label& {
        cursor: pointer;
        border-style: dashed;

        &:hover,
        &.highlight {
            background-color: ${Theme.colors.info.bg};
        }

        &:active, {
        &.highlight {
            border-color: ${Theme.colors.secondary};
            box-shadow: 0 0 6px ${Theme.colors.secondary};
        }
    }
`;

// const Signature = React.forwardRef(({name,defaultValue,useControllers,...props},ref)=> {
const Signature = React.forwardRef(({name,placeholder,...props},ref)=> {
    const signatreRef = useRef();
    const inputRef = useRef();
    const [value1, setValue] = useState([]);

    const mergeRefs = refs => {
        return value => {
            refs.forEach(ref => {
                if (typeof ref === 'function') {
                    ref(value)
                } else if (ref != null) {
                    ref.current = value
                }
            })
        }
    };

    const getClear = () => {
        signatreRef.current.clear();
    };
    function FileListItem(a) {
        a = [].slice.call(Array.isArray(a) ? a : arguments)
        for (var c, b = c = a.length, d = !0; b-- && d;) d = a[b] instanceof File
        if (!d) throw new TypeError("expected argument to FileList is File or array of File objects")
        for (b = (new ClipboardEvent("")).clipboardData || new DataTransfer; c--;) b.items.add(a[c])
        return b.files
    }
    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    const onChange = async() => {
        const dataURI = await signatreRef.current.toDataURL('image/png');
        const blob = dataURLtoBlob(dataURI);
        const isBlob = input => 'Blob' in window && input instanceof Blob;
        let fileObj = await new File([blob], `test.png`, {
            type: 'image/png'
         });
         const isFile = input => 'File' in window && input instanceof File;
         let nFiles = Array.from(inputRef.current.files);
         nFiles[0] = fileObj;
         inputRef.current.files = FileListItem(fileObj);
         setValue(fileObj);
    };

    const expand = file => {
        let w = window.open(file.src, '_blank', `width=${window.outerWidth * .9},height=${window.outerHeight * .9},scrollbars=1`);

        if (!file.src.match(/^http/i)) {
            w.document.write(`
                <img src="${file.src}"/>
            `);
        }
    };

    return (
        <div style={{display:'inline-block'}}>
            {(placeholder && placeholder.length > 0) ? placeholder.map((file, k) =>
                <DropUploaderPreview
                key={k}
                preview={file.src}
                >
                    {file.type === 'image' && (
                        <Button Type='button' onClick={()=>expand(file)}>
                            <Icon.Expand size='16px'/>
                        </Button>
                    )}
                    {file.type !== 'image' && typeof file.src !== 'string' && file.src}
                </DropUploaderPreview>
            ) :
            (<></>)}
            <Label>{ props.label }</Label>
            { props && !props.disabled && (<>
            <div style={{position:'relative',border:'1px dashed #7C98B6'}}>
                <SignatureCanvas onEnd={()=>{onChange()}} penColor='blue'  ref={signatreRef} canvasProps={{width: 200, height: 150, className: 'sigCanvas'}} />    
            </div>
            <div onClick={getClear} style={{paddingTop : '5px',float:'right'}}> 
                <Icon.Broom /> 
            </div></>)}
            <HiddenInput
                type={'file'}
                name={name}
                ref={mergeRefs([inputRef, ref])}
                accept={'*'}
                multiple={true}
                validate={{"required":"this field is required"}}
            />
        </div>
    )
})


export default Signature;
