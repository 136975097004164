import React from "react";
import Tag from "../components/Tag";
import styled from "styled-components";
import {Icon} from "@dspworkplace/ui";
import {ReturnButton} from "./styles";
import S3ProfileImage from "../components/Image/s3ProfileImage";

const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.noOption ? "30px 168px 244px 40px 120px" : "30px 160px 220px 40px 120px 32px")};
    grid-column-gap: 8px;
    border-bottom: 1px dashed #7c98b6;
    margin-bottom: 2px;
    align-content: center;
    align-items: center;
    padding: 0 0 2px 4px;

    div.punchIn {
        width: 30px;
        height: 30px;
        display: grid;
        align-items: center;
    }
`;

const Image = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    border: 1px solid #516f90;
    border-radius: 50%;
    cursor: pointer;
    justify-items: center;
    align-items: center;
    flex-direction: column;

    svg {
        display: flex;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #0071bc;
    }

    img {
        display: flex;
        width: 23px;
        height: 23px;
        border-radius: 50%;
    }
`;

const NameAndRole = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 0;
    align-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 0;

    div.name {
        font-size: 14px;
    }

    div.role {
        color: #707070;
        font-size: 8px;
    }
`;

type Props = {
    image: string | null | undefined,
    friendlyName: string,
    skills: Array<string>,
    stations: Array<string>,
    schedules?: Array<string>,
    role: string,
    callback?: () => void,
    checked?: boolean,
    id?: number,
    remove?: boolean,
    check?: boolean,
    punchIn?: boolean,
    chat?: boolean,
    plus?: boolean,
    noOption?: boolean,
}

function Person(props: Props) {
    const {
        image,
        friendlyName,
        skills,
        role,
        stations,
        schedules,
        callback,
        remove,
        punchIn,
        chat,
        plus,
        noOption
    } = props;
    return (
        <Container noOption={noOption}>
            {image && (
                <Image>
                    <S3ProfileImage src={image} alt={"channel"}/>
                </Image>
            )}
            {!image && (
                <Image>
                    <Icon.Person size="24px" color="#516F90"/>
                </Image>
            )}
            <NameAndRole>
                <div>{friendlyName}</div>
                <div className={"role"}>{role}</div>
            </NameAndRole>
            <Tag options={skills} className={'skills'} show={true}/>
            <Tag options={stations} className={'stations'} show={true}/>
            <Tag options={schedules} className={'schedules'} show={true}/>
            {punchIn && (
                <div className={"punchIn"}>
                    <Icon.PunchIn size={"24px"} color={"#00A38D"}/>
                </div>
            )}
            {punchIn === false && (
                <div className={"punchIn"}/>
            )}
            {plus && (
                <ReturnButton width={"25px"} height={"25px"} borderRadius={"50%"} onClick={callback}>
                    <Icon.Plus size={"24px"}/>
                </ReturnButton>
            )}
            {remove && (
                <ReturnButton width={"25px"} height={"25px"} borderRadius={"50%"} onClick={callback}
                              margin={"0 0 0 8px"}>
                    <Icon.Times size="24px"/>
                </ReturnButton>
            )}
            {chat && (
                <ReturnButton width={"25px"} height={"25px"} borderRadius={"50%"} onClick={callback}>
                    <Icon.Chat size="24px"/>
                </ReturnButton>
            )}
        </Container>
    );
}

export default Person;
