import React, {useState, useEffect} from 'react';
import {useTrackedState as useBotState} from "./BotStore";
import Loading, {useLoading} from "./Loading";
import BotTaskOpenShifts from "./BotTaskOpenShifts";
import styled from "styled-components";
import ScrollToBottom, {useScrollToBottom, useScrollToEnd} from "react-scroll-to-bottom";
import BotTaskReleaseShift from "./BotTaskReleaseShift";
import {DriverFetchTask} from "./types";
import {Button} from "@dspworkplace/ui";
import {alert} from "../components/Alert";
import BotTaskSwapShift from "./BotTaskSwapShift";

export const ScrollContainer = styled(ScrollToBottom)`
    font-family: circe-rounded, serif;
    height: 330px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
`;

function BotTaskInteraction() {
    const state = useBotState();
    const {message} = state;
    const {loading, setLoading} = useLoading();
    const [taskInformation, setTaskInformation] = useState<{ id: number, type: string }>();
    const [twilioMessage, setTwilioMessage] = useState(state.message!.element);
    const scrollToBottom = useScrollToBottom();
    const scrollToEnd = useScrollToEnd();

    const scrollDown = () => {
        scrollToBottom();
        scrollToEnd();
    }

    useEffect(() => {
        if (twilioMessage.dateUpdated && twilioMessage.attributes["task"]) {
            setTwilioMessage(message!.element);
            let task = twilioMessage.attributes["task"];
            if (task["id"] && task["type"]) {
                setTaskInformation({id: task["id"], type: task["type"]});
                setLoading(false);
            }
        }
    }, [message, setLoading, twilioMessage.attributes, twilioMessage.dateUpdated]);
    // message, setLoading, twilioMessage.attributes, twilioMessage.dateUpdated

    if (loading) {
        return <Loading containerHeight={"500px"}/>
    }

    if (taskInformation && taskInformation.type === "OPEN_SHIFT" && taskInformation.id) {
        return <BotTaskOpenShifts taskInformation={taskInformation} message={message!}
                                  twilioMessage={message!.element} scrollToBottom={scrollDown}/>
    }

    if (taskInformation && taskInformation.type === "RELEASE_SHIFT" && taskInformation.id) {
        return <BotTaskReleaseShift taskInformation={taskInformation} message={message!}
                                    twilioMessage={message!.element} scrollToBottom={scrollDown}/>
    }

    if (taskInformation && taskInformation.type === "NEW_VEHICLE" && taskInformation.id) {
        return <div>NEW_VEHICLE</div>;
    }

    if (taskInformation && taskInformation.type === "SWAP_SHIFT" && taskInformation.id) {
        return <BotTaskSwapShift message={message!} taskInformation={taskInformation} scrollToBottom={scrollDown}/>;
    }

    return null;
}

export default BotTaskInteraction;

export const ButtonContainer = styled(Button)`
    font-family: circe-rounded, serif;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
`;

export const BlockRow = styled.div`
    font-family: circe-rounded, serif;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
    background: #EAF0F6;
    color: #0071BC;
    height: 40px;
    border-radius: 4px;
`;

export const Text = styled.div`
    font-family: circe-rounded, serif;
    font-size: 16px;
    color: #0071bc;
    display: flex;
    justify-content: center;

    &.primary {
        color: white;
    }

    &.openShiftDriver {
        font-size: 14px;
    }

    &.driver {
        font-size: 14px;
    }
`;

type DriverButtonProps = {
    driver: DriverFetchTask;
    selectDriver?: (driver: DriverFetchTask) => void;
    disabled?: boolean;
    type?: string;
}

export function DriverButton({driver, selectDriver, disabled = false, type = 'Button'}: DriverButtonProps) {
    let workedHours = typeof driver.weekHours.workedHours === 'number' ? driver.weekHours.workedHours.toFixed(3) : 'N/A';
    let scheduledHours = typeof driver.weekHours.scheduledHours === 'number' ? driver.weekHours.scheduledHours : 'N/A';
    if (type !== 'Button') {
        return (<BlockRow>
                <Text className={"driver"}>{driver.fullName}</Text>
                <Text className={"driver"}>{" - "}</Text>
                <Text className={"driver"}>{workedHours}/{scheduledHours}</Text>
            </BlockRow>
        );
    }

    return (
        <ButtonContainer onClick={async () => {
            if (selectDriver) {
                try {
                    selectDriver(driver);
                } catch (e) {
                    await alert('Error in selecting Driver.');
                }
            }
        }} disabled={disabled}>
            <Text className={"driver"}>{driver.fullName}</Text>
            <Text className={"driver"}>{" - "}</Text>
            <Text className={"driver"}>{workedHours}/{scheduledHours}</Text>
        </ButtonContainer>
    );
}