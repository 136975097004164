import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {getCompanyId, useAuth} from "../../../Auth";
import SettingsContext from "../context";
import {Button, Input, Password, Spacer, SubTitle, Text, ThirdTitle, Toggle} from "@dspworkplace/ui";
import {toast} from "../../../components/Toasts";
import {Label} from "../../../components/UI";

export default function PaycomSettings()
{
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const {register, handleSubmit, errors, reset, watch} = useForm();

    useEffect(() => reset(state.paycomSettings), [state.paycomSettings]);

    const isPaycomEnabled = watch('isEnabled');
    
    const handleSavePaycomSettings = ( data, evt ) => {
        let form = evt.target;

        form.querySelector('button').disabled = true;

        let params = { actions: {} };

        if ( data.id > 0 )
               if (data.isEnabled) {
                    params.actions.update = {
                        PaycomSettings: {
                            update_paycom_1: {
                                findBy: {
                                    id: data.id
                                },
                                updateRecord: {
                                    password: null,
                                    pin1: null,
                                    pin2: null,
                                    pin3: null,
                                    pin4: null,
                                    pin5: null
                                }
                            },
                            update_paycom_2: {
                                findBy: {
                                    id: data.id
                                },
                                updateRecord: data
                            }
                        },
                        Company: {
                            update_company: {
                                findBy: {
                                    id: getCompanyId()
                                },
                                updateRecord: {
                                    isAdp: false,
                                }
                            }
                        }
                    };
               } else {
                    params.actions.update = {
                        PaycomSettings: {
                            update_paycom_1: {
                                findBy: {
                                    id: data.id
                                },
                                updateRecord: {
                                    password: null,
                                    pin1: null,
                                    pin2: null,
                                    pin3: null,
                                    pin4: null,
                                    pin5: null
                                }
                            },
                            update_paycom_2: {
                                findBy: {
                                    id: data.id
                                },
                                updateRecord: data
                            }
                        },
                    };
               }
                
        else
            if (data.isEnabled) {   
                params.actions = {
                    create: {
                        PaycomSettings: {
                            create_paycom: {
                                isEnabled: data.isEnabled,
                                clientCode: data.clientCode,
                                username: data.username,
                                company: getCompanyId()
                            }
                        }
                    },
                    update: {
                        PaycomSettings: {
                            update_paycom_1: {
                                findBy: {
                                    company: getCompanyId()
                                },
                                updateRecord: {
                                    password: data.password,
                                    pin1: data.pin1,
                                    pin2: data.pin2,
                                    pin3: data.pin3,
                                    pin4: data.pin4,
                                    pin5: data.pin5,
                                }
                            }
                        },
                        Company: {
                            update_company: {
                                findBy: {
                                    id: getCompanyId()
                                },
                                updateRecord: {
                                    isAdp: false,
                                }
                            }
                        }

                    }
                };
            } else {
                params.actions = {
                    create: {
                        PaycomSettings: {
                            create_paycom: {
                                isEnabled: data.isEnabled,
                                clientCode: data.clientCode,
                                username: data.username,
                                company: getCompanyId()
                            }
                        }
                    },
                    update: {
                        PaycomSettings: {
                            update_paycom_1: {
                                findBy: {
                                    company: getCompanyId()
                                },
                                updateRecord: {
                                    password: data.password,
                                    pin1: data.pin1,
                                    pin2: data.pin2,
                                    pin3: data.pin3,
                                    pin4: data.pin4,
                                    pin5: data.pin5,
                                }
                            }
                        }
                    }
                };   
            }    

        api.post('/api/lazy/manage/data', params).then(
            response => {
                if(response?.data?.created?.create_paycom.entityId > 0)
                    data.id = response.data.created.create_paycom.entityId;

                toast({
                    title: 'Paycom Integration settings updated',
                    type: 'info',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                })

                dispatch({
                    type: 'ADD',
                    payload: {
                        ...state,
                        paycomSettings: {
                            ...state.paycomSettings,
                            ...data
                        }
                    }
                })
            },
            error => toast({
                title: 'Error',
                content: error.response ? error.response.data.message : error.message,
                type: 'error',
                timeout: 8000,
                useIcon: true,
                useClose: true
            })
        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    return <section>
        <SubTitle>Payroll</SubTitle>
        <Spacer bottom={3}/>
        <form onSubmit={handleSubmit(handleSavePaycomSettings)}>
            <input type='hidden' name='id' ref={register} autoComplete="off" />
            <Toggle
                ref={register}
                name='isEnabled'
                help='If this is enabled, the system will automatically fetch Paycom punch data.'
                singleText='Enable Paycom integration'
                // on={state && state.isEnabled}
            />
            <Spacer bottom={3}/>
            <Input
                ref={register({
                    required: watch('isEnabled')
                })}
                name='clientCode'
                label='Client Code'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('clientCode')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='username'
                label='Username'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('username')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer bottom={3}/>
            <Password
                ref={register}
                name='password'
                label='Password'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('password')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer bottom={5}/>
            <Label>Please enter the 5 answers to 5 security questions associated with the Paycom Username. These questions can be found under Paycom -&gt; Change Security Preferences. The answers must be entered in the same order they questions are listed.</Label>
            <Spacer bottom={3}/>
            <Password
                ref={register}
                name='pin1'
                label='Answer #1'
                size='small'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('pin1')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer right={5} inline/>
            <Password
                ref={register}
                name='pin2'
                label='Answer #2'
                size='small'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('pin2')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer right={5} inline/>
            <Password
                ref={register}
                name='pin3'
                label='Answer #3'
                size='small'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('pin3')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer right={5} inline/>
            <Password
                ref={register}
                name='pin4'
                label='Answer #4'
                size='small'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('pin4')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer right={5} inline/>
            <Password
                ref={register}
                name='pin5'
                label='Answer #5'
                size='small'
                disabled={!isPaycomEnabled}
                Required={isPaycomEnabled}
                valid={isPaycomEnabled && (!errors || !errors.clientCode) && watch('pin5')}
                invalid={isPaycomEnabled && errors && errors.clientCode}
                error={isPaycomEnabled && errors && errors.clientCode && 'Required field'}
                autoComplete="off"
            />
            <Spacer bottom={5}/>
            <Button type='primary'>Save</Button>
        </form>
    </section>
}