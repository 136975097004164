import React, {createContext, useReducer} from 'react';
import AppReduce from './Reduce';
import moment from "moment";
import {useAuth} from "../../../Auth";
import {useSelectedStation} from "../../../components/StationSelector";

const DefaultStore = createContext({});

const AppProvider = ({children}) => {
    let week = moment(new Date(), 'DD-MM-YYYY').week();
    const [selectedStation] = useSelectedStation();

    const useAppState = useReducer(AppReduce,{
        data:[],
        limit:5,
        groupData:true,
        week:week,
        isLoading:true,
        filter:[],
        selectedStation: Array.isArray(selectedStation) ? selectedStation[0] : selectedStation,
    })

    return (
        <DefaultStore.Provider value={useAppState}>
            {children}
        </DefaultStore.Provider>
    )
}

export {AppProvider}

export default DefaultStore
