
const VanAffinityReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'ADD':
            let vehicles = payload.data.sort((a,b)=> a.vehicleId.localeCompare(b.vehicleId,undefined,{numeric:true,sensitivity: 'base'}));

            return {
                ...state,
                vehicles:vehicles,
                filtered:vehicles,
                isLoading:false,
                drivers:[],
                station: payload.station,
                company: payload.company
            };

        case 'ADD_DRIVER':
            return {...state,drivers:payload.data,filteredDriver:payload.data};

        case 'ADD_SCHEDULE':
            return {...state,schedules:payload.data,filteredDriver:payload.data};            

        case 'ADD_PRIMARY_DRIVER':
            return {...state,schedules:payload.data,filteredDriver:payload.data};            

        case 'ADD_SECONDARY_DRIVER':
            return {...state,schedules:payload.data,filteredDriver:payload.data};                        

        case 'TOGGLE':
            return {...state,isToggle: payload};             
                    
        case 'LOADING':
            return {...state,isLoading: payload.status};

        default:
            return state;
    }
}
export default VanAffinityReducer
