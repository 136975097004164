import * as firebase from "firebase/app";
import "firebase/messaging";

let messaging = null;
try {
    if (firebase.messaging.isSupported()) {
        const initializedFirebaseApp = firebase.initializeApp({
            projectId: "dspworkplace-beta",
            messagingSenderId: "1088693804665",
            apiKey: "AIzaSyCiSWKFASJ75lCfa6TU-j2WDqX37LzzcgQ",
            appId: "1:1088693804665:web:f6115109b45744f49a7ea8",
        });
        messaging = initializedFirebaseApp.messaging();
    }
} catch (e) {
    console.error({messaging: e});
    alert('Error with Firebase');
    alert(`Error : ${JSON.stringify(e.message)}`);
}


export default messaging;