import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button, Icon, Spacer, Text, Theme, ThirdTitle} from '@dspworkplace/ui';
import {SchedulesContext} from "./index";
import Skeleton from "../../../../components/Skeleton";
import { AccessCheck, IsGranted } from "../../../../security";

const Grid = styled.div`
    box-sizing: border-box;
    display: grid;
    width: calc(100% + 6px);
    padding-right: 6px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`;

const Card = styled.div`
    position: relative;
    box-sizing: border-box;
    background-color: ${Theme.colors.neutrals.white};
    background-origin: padding-box;
    background-repeat: no-repeat;
    box-shadow: 0px 3px 6px #33333326;
    border-radius: ${Theme.defaultRadius};
    opacity: 1;
    padding: ${props=>props.padding ? props.padding : '20px'};
    margin: ${props=>props.margin ? props.margin : '0 0 2px 2px'};
`;

const Row = styled.div`
    box-sizing: border-box;
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
`;

const GridView = ({deleteSchedules,sid}) => {
    const schedules = useContext(SchedulesContext);
    const [isEdit,setIsEdit] = useState({update:-1,copy:-1});
    const editSchedules = async (data)=>{
        setIsEdit({update:1});
    }
    useEffect(() => {
        if (sid && schedules.state.schedules) {
            let sidObj = schedules.state.schedules.find((s) => s.id == sid);
            if (sidObj) {
                schedules.dispatch({ 'type': 'update', view: 'Add', editID: sidObj });
            }
        }
    }, [sid, schedules.state.schedules]);
    return (
        <Grid>
            <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.U}>
            <Card padding={'0'}>
                <Spacer all={5} style={{opacity: .5}}>
                    <Row>
                        <Skeleton style={{width:'50%', height: 20}} animation={1}/>
                        <div style={{marginLeft: 'auto'}}>
                            <Icon.Copy size='20px' color={Theme.colors.info.border}/>
                            <Spacer inline right={1}/>
                            <Icon.Edit size='20px' color={Theme.colors.info.border}/>
                            <Spacer inline right={1}/>
                            <Icon.Times size='20px' color={Theme.colors.info.border}/>
                        </div>
                    </Row>
                    <Spacer top={2} />
                    <Row>
                        <Icon.Person size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2}/>
                        <Text><Skeleton style={{width:100, height: 16}} animation={1}/></Text>
                    </Row>
                    <Spacer top={1} />
                    <Row>
                        <Icon.Calendar size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2} />
                        <Text><Skeleton style={{width:150, height: 16}} animation={1}/></Text>
                    </Row>
                    <Spacer top={1} />
                    <Row>
                        <Icon.Clock size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2} />
                        <Text><Skeleton style={{width:120, height: 16}} animation={1}/></Text>
                    </Row>
                </Spacer>
                <Button
                    type='primary'
                    onClick={()=> {
                        schedules.dispatch({'type': 'setView', view: 'Add'})
                    }}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: 3
                    }}
                >Add Schedule</Button>
            </Card>
            </IsGranted>
            {schedules.state.schedules.map((item, index)=> (
                <Card key={index}>
                    <Row>
                        <ThirdTitle style={{fontWeight: 'normal', maxWidth: '65%'}}>{ item.isExpired == true && 'EXPIRED -' }  {item.name}</ThirdTitle>
                        <div style={{ marginLeft: 'auto' }}>
                            <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.U}>
                            <Icon.Copy size='20px' color={Theme.colors.info.border} onClick={() => {
                                schedules.dispatch({'type': 'copy', view: 'Add', copyID: item})
                            }}/>
                            <Spacer inline right={1}/>
                            <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => {
                                schedules.dispatch({'type': 'update', view: 'Add', editID: item})
                            }}/>
                            </IsGranted>
                            <IsGranted feature={AccessCheck.features.ST_SCHEDULES} ops={AccessCheck.OPS.D}>
                            <Spacer inline right={1}/>
                            <Icon.Times size='20px' color={Theme.colors.info.border} onClick={()=>{
                                deleteSchedules(item,index)
                            }}/>
                            </IsGranted>
                        </div>
                    </Row>
                    <Spacer top={2} />
                    <Row>
                        <Icon.Person size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2}/>
                        <Text>{item.drivers}</Text>
                    </Row>
                    <Spacer top={1} />
                    <Row>
                        <Icon.Calendar size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2} />
                        <Text>{item.weeks}</Text>
                    </Row>
                    <Spacer top={1} />
                    {item.type && (
                        <Row>
                            <Icon.Refresh size='16px' color={Theme.colors.info.border}/>
                            <Spacer right={2} />
                            <Text>Recurring</Text>
                        </Row>
                    )}
                    <Spacer top={1} />
                    <Row>
                        <Icon.Clock size='16px' color={Theme.colors.info.border}/>
                        <Spacer right={2} />
                        <Text>{item.shiftTypes}</Text>
                    </Row>
                </Card>
            ))}
        </Grid>
    );
}

export default GridView;
