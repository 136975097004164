const patternEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
const patternPassword = {
    upper_lower_number: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@_!$%^&#*)(.-]).{8,32}.*$/,
};
const patternDate = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
const patternFloatValue = /^-?(?:\d+|(\d*\.\d\d|\d*\.\d))$/;
const patternTimeValue = /^(1[012]|[1-9]):[0-5][0-9] ?(am|pm)$/i;
const patternYear = /^[0-9]{4}$/;
const patternNumericData = /^[0-9]{4,6}$/;
const patternNumericValue = /^[0-9]{1,8}$/;
const imageExtention = /([a-zA-Z0-9\s_\\.\-():])+(.jpeg|.jpg|.png)$/;
const csvExtention = /([a-zA-Z0-9\s_\\.\-():])+(.csv)$/;
const patternNumericTenDigitsValue = /^[02-9][0-9]{9}$/;
const patternPhone = /^\(?([2-9][0-8][0-9])\)?([2-9][0-9]{2})([0-9]{4})$/;
const patternGetNameExtension = /(?:\.([^.]+))?$/;
const mimeTypes = {
    csv: "text/csv",
    pdf: "application/pdf",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    avif: "image/avif",
    svg: "image/svg+xml",
    webp: "image/webp",
    csv: "text/csv",
    pdf: "application/pdf",
    xls: [
        "application/vnd.openxmlformats-",
        "application/wps-office.xlsx",
        "application/vnd.ms-excel",
        "officedocument.spreadsheetml.sheet",
    ],
    xlsx: [
        "application/vnd.openxmlformats-",
        "application/wps-office.xlsx",
        "application/vnd.ms-excel",
        "officedocument.spreadsheetml.sheet",
    ],
    images: ["image/jpeg", "image/png", "image/gif", "image/avif", "image/svg+xml", "image/webp"],
    documents: [
        "text/plain",
        "text/csv",
        "application/pdf",
        "application/wps-office.xlsx",
        "application/vnd.amazon.ebook",
        "application/msword",
        "application/vnd.openxmlformats-",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-",
        "application/vnd.rar",
        "application/rtf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-",
        "application/zip",
        "application/x-zip-compressed",
        "application/gzip",
        "officedocument.spreadsheetml.sheet",
        "officedocument.presentationml.presentation",
        "officedocument.wordprocessingml.document",
    ],
};
const validatePassword = (password, username, lastUsedPasswords) => {
  // Criteria 1: Minimum length of 8 characters
  if (password.length < 8) {
    return "Password must be at least 8 characters long.";
  }

  // Criteria 2: At least one character of each group
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
    return "Password must include lower case, upper case, number, and special character.";
  }

  // Criteria 3: Not allow 3 repeated or sequential characters
  const repeatedOrSequential = /(.)\1\1|012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|yxw|xwv|wvu|vut|uts|tsr|srq|rqp|qpo|pon|onm|nml|mlk|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|210|321|432|543|654|765|876|987|098/i;
  if (repeatedOrSequential.test(password)) {
    return "Password cannot have 3 repeated or sequential characters.";
  }

  // Criteria 4: Not allow portions of the username
  const lowerUsername = username.toLowerCase();
  const lowerPassword = password.toLowerCase();
  for (let i = 0; i <= lowerUsername.length - 3; i++) {
    if (lowerPassword.includes(lowerUsername.substring(i, i + 3))) {
      return "Password cannot contain portions of the username.";
    }
  }

  // Criteria 5: Not allow the password to be the same from the last 6 used passwords
  if (lastUsedPasswords.includes(password)) {
    return "Password cannot be the same as the last 6 used passwords.";
  }

  return null; // No errors, password is valid
};

export {
    patternEmail,
    patternPassword,
    patternDate,
    patternFloatValue,
    patternTimeValue,
    patternYear,
    patternNumericValue,
    imageExtention,
    csvExtention,
    patternNumericTenDigitsValue,
    patternNumericData,
    patternPhone,
    validatePassword,
    patternGetNameExtension,
    mimeTypes
};
