import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { TagInput, Icon } from "@dspworkplace/ui";
import { LoadingView } from "../components/Loading";
import {
    Grid,
    CustomButton,
    TagInputContainer,
    ReturnButton,
    ChannelGroupFilters,
    ChannelGroupFilter,
} from "./styles";
// @ts-ignore
import { uploadSingleFile, createChannelWithMembers, getEmployeesTwilio, removeGroupPicture, debugLog } from "./utils";
import Person from "../Chat/Person";
import FlexScroll from "../components/SimpleScroll";
import { NewChannelAction, NewChannelState } from "./CGCOptions";
import { useForm } from "react-hook-form";
import { useTrackedState } from "./store";
import { showErrorToast } from "../Utilities";
import { getChatUserId,getChatTeam,getStreamChatIdentifier } from "../Auth";
import { useChatContext } from "stream-chat-react";

type Props = {
    newChannelModalState: NewChannelState;
    newChannelModalDispatch: Dispatch<NewChannelAction>;
    closeModal: () => void;
    isNewChannel: boolean;
    currentChannel;
};

const CGCPanelAddMembersToNewChannel = (props: Props) => {
    // @ts-ignore
    const { isNewChannel, currentChannel, newChannelModalState, newChannelModalDispatch, closeModal } = props;
    const {
        loadingUsers,
        errorMessage,
        matchUsers,
        usersToAdd,
        loadingCreating,
        image,
        name,
        description,
        permissions,
        tagsDefaultAutoUsers,
        channelNotification
    } = newChannelModalState;
    const { register } = useForm();
    const state = useTrackedState();
    const { client, chatClient } = state;
    const [refresh, setRefresh] = useState(1.0);
    const { setActiveChannel } = useChatContext();

    const handleTagChange = data => {
        setTimeout(() => {
            newChannelModalDispatch({ type: "SET_MATCHES", filterSearch: data });
        }, 50);
    };


    const updateChannel = async () => {

        let userList = [chatClient.userID, ...usersToAdd.map(item => { return item.userId })];
        // console.log("-----", usersToAdd)

        userList = await [...new Set(userList)];

        let currentChannelMember = await currentChannel.state.members;
        currentChannelMember = Object.keys(currentChannelMember);

        userList = userList.filter((member) => {
            const index = currentChannelMember.indexOf(member);
            if (index > -1) {
                // console.log(member);
                currentChannelMember.splice(index, 1);
                return false;
            } else {
                return true;
            }
        });

        console.log(currentChannelMember, userList);
        await currentChannel.updatePartial({ set: { name: "test 2" } });
        if (currentChannelMember.length > 0) {
            const updateChannel = await currentChannel.removeMembers(currentChannelMember);
            currentChannel.watch();
        }
        if (userList.length > 0) {
            const updateChannel = await currentChannel.addMembers(userList);
            currentChannel.watch();
        }
        closeModal();
    }
    const createChannel = async () => {
        let userList = [chatClient.userID, ...usersToAdd.map(item => { return item.userId })];
        userList = await [...new Set(userList)];

        // if (newChannelModalState.name.trim().length === 0) {
        //     console.log('Please select a channel name');
        //     newChannelModalDispatch({
        //         type: "ERROR_CHANNEL_NAME",
        //         errorMessage: `Channel name cannot be empty.`,
        //         name: ""
        //     });
        //     // @ts-ignore
        //     document.querySelectorAll("[data-tab='tab']")[0].click();
        //     return;
        // }
        // if (userList.length > 0) {
        //     newChannelModalDispatch({
        //         type: "ERROR_CHANNEL_NAME",
        //         errorMessage: `Channel name cannot be empty.`,
        //         name: ""
        //     });
        //     // @ts-ignore
        //     document.querySelectorAll("[data-tab='tab']")[0].click();
        //     return;
        // }

        // console.log("createChannel", {
        //     members: userList,
        //     name: newChannelModalState.name,
        //     teams: getChatTeam(),
        //     pinUser: [],
        //     image: newChannelModalState.image
        // });

        if (newChannelModalState.name.trim().length != 0) {
            const conversation = await chatClient.channel('group', {
                members: userList,
                name: newChannelModalState.name,
                teams: getChatTeam(),
                pinUser: [],
                image: newChannelModalState.image
            });
            await conversation.watch();
            await setActiveChannel(conversation)
            await newChannelModalDispatch({
                type: "RESET_NEW_CHANNEL_INFORMATION",
            });
            await closeModal();
            return true;
        } else {
            newChannelModalDispatch({
                type: "ERROR_CHANNEL_NAME",
                errorMessage: `Channel name cannot be empty.`,
                name: ""
            });
        }

    };
    const createChannel1 = async () => {
        try {
            if (!client) {
                return;
            }
            newChannelModalDispatch({ type: "LOADING_CREATING_CHANNEL", loadingCreating: true });
            let channels = await client.getSubscribedChannels();
            const channelExist = channels.items.findIndex(item => item.friendlyName.trim() === newChannelModalState.name.trim());
            if (channelExist >= 0) {
                newChannelModalDispatch({
                    type: "ERROR_CHANNEL_NAME",
                    errorMessage: `Channel with the name of ${JSON.stringify(
                        newChannelModalState.name
                    )} already exists.`,
                    name: ""
                });
                // @ts-ignore
                document.querySelectorAll("[data-tab='tab']")[1].click();
            } else if (newChannelModalState.name.trim().length === 0) {
                newChannelModalDispatch({
                    type: "ERROR_CHANNEL_NAME",
                    errorMessage: `Channel name cannot be empty.`,
                    name: ""
                });
                // @ts-ignore
                document.querySelectorAll("[data-tab='tab']")[1].click();
            } else {
                debugLog({ name, image, description, permissions, usersToAdd });
                let imageUrl: string | null = null;
                if (image && image[0]) {
                    // @ts-ignore
                    let objectUrl = await uploadSingleFile(image).catch(e => console.error({ e }));
                    if (objectUrl) {
                        imageUrl = objectUrl;
                    }
                }
                debugLog({ name, image, imageUrl, description, permissions, usersToAdd });
                removeGroupPicture();
                let newChannel = await createChannelWithMembers(client,
                    name, imageUrl, description, permissions, usersToAdd, newChannelModalState.users,
                    newChannelModalState.filterResult, {
                    stations: newChannelModalState.stations,
                    schedules: newChannelModalState.schedules,
                    skills: newChannelModalState.skills,
                    roles: newChannelModalState.roles,
                    teams: newChannelModalState.teams,
                    chatVariable: newChannelModalState.chatVariable,
                }, channelNotification);
                let channelSid = newChannel.sid;
                setTimeout(() => {
                    let elem = document.querySelector(`[data-channel='${channelSid}']`);
                    newChannelModalDispatch({ type: "LOADING_CREATING_CHANNEL", loadingCreating: false });
                    if (elem) {
                        closeModal();
                        // @ts-ignore
                        elem.click();
                    } else {
                        closeModal();
                    }
                }, 300);
            }
        } catch (error) {
            await showErrorToast(error, 'Error creating Group channel.');
            newChannelModalDispatch({ type: "LOADING_CREATING_CHANNEL", loadingCreating: false });
            // closeModal();
        }
    };

    const selectAllMatches = useCallback(() => {
        newChannelModalDispatch({ type: "SELECT_ALL_MATCHES" });
    }, [newChannelModalDispatch]);

    const deleteAllMatches = useCallback(() => {
        newChannelModalDispatch({ type: "DELETE_ALL_MATCHES" });
    }, [newChannelModalDispatch]);

    const addMember = useCallback(
        userId => {
            newChannelModalDispatch({ type: "ADD_MEMBER", userId: userId });
        },
        [newChannelModalDispatch]
    );

    const removeMember = useCallback(
        userId => {
            newChannelModalDispatch({ type: "REMOVE_MEMBER", userId: userId });
        },
        [newChannelModalDispatch]
    );

    const goBack = useCallback(() => {
        // @ts-ignore
        document.querySelectorAll("[data-tab='tab']")[0].click();
    }, []);

    const reload = useCallback(
        () => {
            newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
            setRefresh(prev => prev + 1.0);
        },
        [newChannelModalDispatch],
    );

    useEffect(() => {
        if (newChannelModalState.users.length == 0) {
            const abortController = new AbortController();
            newChannelModalDispatch({ type: "LOADING_USERS", loadingUsers: true });
            getEmployeesTwilio()
                .then(result => {
                    if (result) {
                        try {
                            newChannelModalDispatch({
                                type: "SET_USERS",
                                users: result.users,
                                loadingUsers: false,
                                roles: result.roles,
                                skills: result.skills,
                                stations: result.stations,
                                schedules: result.schedules,
                                teams: result.teams,
                                chatVariable: result.chatVariable,
                                defaultAutoUsers: result.defaultAutoUsers
                            });
                        } catch (e) {
                            console.error({ e });
                        }
                    }

                })
                .catch(error => {
                    console.error({ error });
                    newChannelModalDispatch({
                        type: "SET_USERS",
                        users: [],
                        loadingUsers: false,
                        roles: [],
                        skills: [],
                        stations: [],
                        schedules: [],
                        teams: [],
                        chatVariable: [],
                        defaultAutoUsers: [],
                    });
                });

            return () => {
                abortController.abort();
            };
        }
    }, [newChannelModalDispatch, refresh]);

    if (loadingUsers) {
        return <LoadingView containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"} />;
    }

    if (loadingCreating) {
        return <LoadingView containerHeight={"300px"} containerWidth={"100%"} imgHeight={"150px"} />;
    }

    return (
        <Grid templateRows="repeat(6, auto)" rowGap="6px">
            <Grid rowGap="8px" templateRows="auto auto" height={"auto"}>
                <FlexScroll height={"200px"} width="100%" key="first">
                    {Array.isArray(usersToAdd) && usersToAdd.map(item => {
                        return (
                            <Person
                                key={item.userId}
                                friendlyName={item.friendlyName}
                                skills={item.skills}
                                image={item.image}
                                stations={item.stations}
                                schedules={item.schedules.map(e => e.name)}
                                role={item.roles[0].roleName}
                                remove={tagsDefaultAutoUsers.filter(e => e.name === item.friendlyName).length === 0}
                                callback={() => removeMember(item.userId)}
                            // noOption={true}
                            />
                        );
                    })}
                </FlexScroll>
            </Grid>
            <TagInputContainer>
                <TagInput
                    label="Find by name, email, skill, role,  station, schedule and team:"
                    options={newChannelModalState.filterArray}
                    size="big"
                    ref={register}
                    onChange={handleTagChange}
                    name={'tags'}
                />
                {/* <ReturnButton
                    className={"refresh"}
                    width={"30px"}
                    height={"30px"}
                    borderRadius={"50%"}
                    onClick={reload}
                >
                    <Icon.Refresh size={"24px"}/>
                </ReturnButton> */}
            </TagInputContainer>
            <Grid templateColumns={"auto auto"} justifyContent={"space-between"}>
                <Grid color="#707070" fontSize="12px">{`${matchUsers.length} search results:`}</Grid>
                <CustomButton width="128px" height="28px" type="primary" fontSize={"12px"} onClick={selectAllMatches}>
                    Select users
                </CustomButton>
            </Grid>
            <Grid rowGap="8px" templateRows="auto auto" height={"auto"}>
                {errorMessage && errorMessage.length > 0 && (
                    <Grid color="#D94C53" fontSize="12px">
                        {errorMessage}
                    </Grid>
                )}
                <FlexScroll height={"200px"} width="100%" key="second">
                    {Array.isArray(matchUsers) && matchUsers.map(item => {
                        if (usersToAdd.length > 0 && usersToAdd.find(e => e.userId === item.userId)) {
                            return <></>;
                        }
                        return (
                            <Person
                                key={item.userId}
                                friendlyName={item.friendlyName}
                                skills={item.skills}
                                image={item.image}
                                stations={item.stations}
                                schedules={item.schedules.map(e => e.name)}
                                role={item.roles[0].roleName}
                                plus={isNewChannel ? true : !isNewChannel && currentChannel?.data.created_by.id === `${getStreamChatIdentifier()}` ? true : false}
                                callback={() => addMember(item.userId)}
                            // noOption={true}
                            />
                        );
                    })}
                </FlexScroll>
            </Grid>

            {/* <Grid margin={"0"} templateRows={"auto auto auto auto auto auto"} rowGap={"4px"}>
                <Grid templateColumns={"auto auto"} justifyContent={"space-between"}>
                    <Grid color="#707070"
                          fontSize="12px">{`${matchUsers.length} Members selected`}</Grid>
                    <CustomButton width="128px" height="28px" type="primary" fontSize={"12px"}
                                  onClick={deleteAllMatches}>
                        Undo
                    </CustomButton>
                </Grid>
                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`The following search filter to be saved:`}</Grid>
                <ChannelGroupFilters>
                    {newChannelModalState.filterResult.map(e => {
                        return <ChannelGroupFilter key={e.value}>
                            {e.name}
                        </ChannelGroupFilter>
                    })}
                </ChannelGroupFilters>

                {Array.isArray(tagsDefaultAutoUsers) && tagsDefaultAutoUsers.length > 0 && <>
                    <Grid color="#707070" fontSize="12px"
                          fontWeight={"bold"}>{`The following default users will be included:`}</Grid>
                    <ChannelGroupFilters>
                        {tagsDefaultAutoUsers.map(e => {
                            return <ChannelGroupFilter key={e.value}>
                                {e.name}
                            </ChannelGroupFilter>
                        })}
                    </ChannelGroupFilters>
                </>}

                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`The following users will be added:`}</Grid>
                <FlexScroll height={usersToAdd.length > 0 ? "150px !important" : "0"} width="100%" key="third">
                    {Array.isArray(usersToAdd) && usersToAdd.map(item => {
                        return (
                            <Person
                                key={item.userId}
                                friendlyName={item.friendlyName}
                                skills={item.skills}
                                image={item.image}
                                stations={item.stations}
                                schedules={item.schedules.map(e => e.name)}
                                role={item.roles[0].roleName}
                                remove={false}
                                callback={() => removeMember(item.userId)}
                                noOption={true}
                            />
                        );
                    })}
                </FlexScroll>
                <Grid color="#707070" fontSize="12px"
                      fontWeight={"bold"}>{`There are ${usersToAdd.length} total users to be added.`}</Grid>
            </Grid> */}
            <Grid justifyContent="space-between" templateColumns="auto auto" padding={"4px 0 0 0"}>
                <CustomButton width="117px" height="36px" type="secondary" onClick={goBack}>
                    Back
                </CustomButton>
                {!isNewChannel ?
                    <CustomButton width="117px" height="36px" type="primary" onClick={updateChannel}
                        disabled={usersToAdd.length <= 0}>
                        Update
                    </CustomButton>
                    :
                    <CustomButton width="117px" height="36px" type="primary" onClick={createChannel}
                        disabled={usersToAdd.length <= 0}>
                        Create
                    </CustomButton>
                }
            </Grid>
        </Grid>
    );
};

export default CGCPanelAddMembersToNewChannel;
