import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Linkify from "react-linkify-always-blank";
import Emoji from "react-emoji-render";
import {Media} from "twilio-chat/lib/media";
import {Message} from "twilio-chat/lib/message";
import ImageModal from "./ImageModal";
import Loading from "./Loading";
import {IS_DEV} from "../utils";
import ModalWindow from "./Modal";
import {engine} from "../Utilities";
import {Icon} from "@dspworkplace/ui";

const Video = styled.video`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;

const AudioContainer = styled.audio`
    width: 250px;
    height: 50px;
    background-color: #516f90;
`;

const ImageContainer = styled.div`
    max-width: 268px;
    max-height: 100px;
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    max-width: 260px;
    max-height: 96px;
    background-color: ${props => (props.bgColor ? props.bgColor : "#EAF0F6")};
    padding: 4px;
    border-radius: 2px;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.noOption ? "30px 168px 244px 40px 120px" : "30px 160px 220px 40px 120px 32px")};
    grid-column-gap: 8px;
    border-bottom: 1px dashed #7c98b6;
    margin-bottom: 2px;
    align-content: center;
    align-items: center;
    padding: 0 0 2px 4px;

    div.punchIn {
        width: 30px;
        height: 30px;
        display: grid;
        align-items: center;
    }
`;

const DriverImage = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    border: 1px solid #516f90;
    border-radius: 50%;
    cursor: pointer;
    justify-items: center;
    align-items: center;
    flex-direction: column;

    svg {
        display: flex;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #0071bc;
    }

    img {
        display: flex;
        width: 23px;
        height: 23px;
        border-radius: 50%;
    }
`;

const NameAndRole = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 0;
    align-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 0;

    div.name {
        font-size: 14px;
    }

    div.role {
        color: #707070;
        font-size: 8px;
    }
`;

export function ImageWrapper({s3Image, isSentByCurrentUser, showModalWindowHandler, setHasImageError}) {
    const [hasError, setHasError] = useState(false);

    if (hasError) {
        return null;
    }

    return <ImageContainer style={{justifyContent: "center"}} onClick={showModalWindowHandler}>
        <Image src={s3Image} alt="File" bgColor={isSentByCurrentUser ? "#EAF0F6" : "#516F90"} onError={() => {
            setHasError(true);
            if (typeof setHasImageError === 'function') {
                setHasImageError(true);
            }
        }
        }/>
    </ImageContainer>
}

const linkParserInformation = text => {
    if (!text) {
        return null;
    }
    const regex = /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*[-a-zA-Z0-9@:%_\+~#?&\/=])*/i;
    return text.match(regex);
};

type Props = {
    existLinks?: boolean;
    text: string;
    media?: Media | null;
    mediaUrl?: string | null;
    element?: Message;
    isSentByCurrentUser: boolean;
    identity: string;
    index?: number | null;
    setHasImageError?: any;
};

function MessageContent({text, media, mediaUrl, isSentByCurrentUser, identity, index = 0, setHasImageError}: Props) {
    const [loading, setLoading] = useState(true);
    const [s3Image, setS3Image] = useState<string>();
    const [s3Video, setS3Video] = useState<string>();
    const [textValue, setTextValue] = useState(text);
    const [showPopup, setShowPopup] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [popupText, setPopupText] = useState(<div></div>);
    const [showModalImage, setShowModalImage] = useState(false);
    const [existLinks, setExistLinks] = useState(false);
    const [driverList, setDriverList] = useState([]);

    const cancelModal = () =>{
        setShowPopup(false);
    }

    useEffect(() => {
        if (linkParserInformation(text) !== null) {
            setExistLinks(true);
        }
        if (identity && text) {
            const searchTerm = "https://s3.amazonaws.com/dsp.data.storage.general/";
            const indexOfFirst = text.indexOf(searchTerm);
            const popupFindStart = text.indexOf('[popupstart]');
            if(popupFindStart !== -1){
                setIsPopup(true);
                const popupFindEnd = text.indexOf('[popupend]');
                const popupClickStartIndex = text.indexOf('[popupclickstart]');
                const popupClickEndIndex = text.indexOf('[popupclickend]');
                let tempDriverString = text.slice(popupFindStart+12, popupFindEnd);

                const messageStartText = text.slice(0, popupClickStartIndex);
                const messageButtonText = text.slice(popupClickStartIndex+17, popupClickEndIndex);
                const messageEndText = text.replace('[popupstart]'+tempDriverString+'[popupend]', '').slice(popupClickEndIndex+15, text.length);
                setPopupText(<div>{messageStartText} <span style={{cursor: 'pointer'}} onClick={()=>{getDriverList(tempDriverString)}}><u>{messageButtonText}</u></span> {messageEndText}</div>);
            }

            let s3Url = text.substring(indexOfFirst, text.length);
            if (s3Url.includes('.jpg') || s3Url.includes('.jpeg') || s3Url.includes('.JPEG') || s3Url.includes('.JPG')) {
                setS3Image(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            } else if (s3Url.includes('.png') || s3Url.includes('.PNG')) {
                setS3Image(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            } else if (s3Url.includes('.webp') || s3Url.includes('.WEBP')) {
                setS3Image(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            } else if (s3Url.includes('.gif') || s3Url.includes('.GIF')) {
                setS3Image(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            } else if (s3Url.includes('.bmp') || s3Url.includes('.BMP')) {
                setS3Image(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            } else if (s3Url.includes('.mp4') || s3Url.includes('.MP4')) {
                setS3Video(s3Url);
                setTextValue(text.substring(0, indexOfFirst));
            }
        }
        setLoading(false);
    }, [identity, text]);

    const getDriverList = async(driverIds) => {
        await engine().post("/api/lazy/manage/data",{
            actions: {
                response: {
                    "Driver": {
                        custom: {
                            get: "driver",
                            functionName: "getDriverByIdForBotNotification",
                            criteria: {
                                driverIds: driverIds.split(',')
                            },
                            includes: {
                                0: "id",
                                1: "friendlyName",
                                2: "profileImage",
                                3: "role"
                            }
                        }
                    }
                }
            }
        }).then(
            response => {
                setDriverList(response.data.data.driver);
                setShowPopup(true);
            }
        );
    };
    const showModalWindowHandler = useCallback(
        () => {
            setShowModalImage(true);
        },
        [],
    );
    const hideModalWindowHandler = useCallback(
        () => {
            setShowModalImage(false);
        },
        [],
    );

    if (loading) {
        return <Loading containerHeight={"96px"} containerWidth={"auto"} imgHeight={"32px"}/>;
    }

    if (s3Image) {
        return (
            <div>
                {existLinks && <Linkify>{IS_DEV ? `${textValue} Index #: ${index}` : textValue}</Linkify>}
                {!existLinks && text && <Emoji text={textValue}/>}
                <ImageWrapper s3Image={s3Image} isSentByCurrentUser={isSentByCurrentUser} showModalWindowHandler={showModalWindowHandler} setHasImageError={setHasImageError}/>
                {showModalImage &&
                <ImageModal title={'Image'} width={'700px'} cancelModal={hideModalWindowHandler} src={s3Image}/>}
            </div>
        );
    }


    if (s3Video) {
        return (
            <div>
                {existLinks && <Linkify>{IS_DEV ? `${textValue} Index ##: ${index}` : textValue}</Linkify>}
                {!existLinks && text && <Emoji text={textValue}/>}
                <Video controls>
                    <source src={s3Video}/>
                    Your browser does not support the video tag.
                </Video>
            </div>
        );
    }

    return (
        <>
            {existLinks && <Linkify>{IS_DEV ? `${textValue} \nIndex: ${index}` : textValue}</Linkify>}
            {!existLinks && text && isPopup == false && <Emoji text={IS_DEV ? `${textValue} \nIndex: ${index}` : textValue}/>}
            {!existLinks && text && isPopup && isPopup == true && popupText }
            {!existLinks && mediaUrl && (
                <>
                    <ImageWrapper s3Image={mediaUrl} isSentByCurrentUser={isSentByCurrentUser} showModalWindowHandler={showModalWindowHandler} setHasImageError={setHasImageError}/>
                    {showModalImage &&
                    <ImageModal title={'Image'} width={'700px'} cancelModal={hideModalWindowHandler} src={mediaUrl!}/>}
                </>
            )}
            {!existLinks && !!media && media.contentType.includes("video") && (
                <Video controls>
                    <source src={mediaUrl!} type={media.contentType}/>
                    Your browser does not support the video tag.
                </Video>
            )}
            {!existLinks && !!media && media.contentType.includes("audio") && (
                <AudioContainer controls>
                    <source src={mediaUrl!} type={media.contentType}/>
                    Your browser does not support the audio element.
                </AudioContainer>
            )}
            {showPopup && <ModalWindow display={"list-item"} width={"515px"} title={"Driver List"} cancelModal={cancelModal}>
            {driverList.map((driver, index) => {
                return (
                    <Container key={index}>
                        {(driver['profileImage'] && driver['profileImage'] != null && driver['profileImage'] != '') ? (
                            <DriverImage>
                                <img src={driver['profileImage']} alt={"channel"}/>
                            </DriverImage>
                        )
                        :
                        (
                            <DriverImage>
                                <Icon.Person size="24px" color="#516F90"/>
                            </DriverImage>
                        )}
                        <NameAndRole>
                            <div>{driver['friendlyName']}</div>
                            <div className={"role"}>{driver['role']}</div>
                        </NameAndRole>
                    </Container>
                )
            })}
            </ModalWindow>}
        </>
    );
}

export default MessageContent;
