import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Icon, Input, Spacer, Theme, CheckBox } from "@dspworkplace/ui";
import WeekStore from "../context/weekStore";
import moment from "moment";
import styled from "styled-components";
import { confirm } from "../../../../components/Confirm";
import { dialog } from "../../../../components/Dialog";
import { alert } from "../../../../components/Alert";

const CellDropHint = styled.div`
    width: 100%;
    height: 36px;
    padding: 8px;
    border-bottom: 1px solid rgb(204, 204, 204);

    &.pastday {
        background: ${Theme.colors.neutrals.silver}1f;
    }

    &.today {
        background: ${Theme.colors.primary}10;
    }

    &.today,
    &.futureday {
        // cursor: pointer;
        //
        // &:hover {
        //     background: ${Theme.colors.primary}2f;
        // }
    }
`;

const ComponentItem = ({ item }) => {
    let countString = "";
    if (item.date <= moment().format("YYYY-MM-DD")) countString = item.code + "R /";
    return (
        <div className="item">
            <div style={{ display: "flex", alignItems: "start" }}>
                <span className="circle" style={{ background: item.background, minWidth: "16px" }} />
                <Spacer inline left={2}>
                    <span>{item.type}</span>
                </Spacer>
            </div>
            <div style={{ fontSize: "12px", textAlign: "right" }}>
                <span style={{ color: item.grading ? item.grading.color : "" }}>
                    {item.d}{item.countInWeekViewAsDriver !== "0" && `D`}
                    {item.type == "NCNS" || item.type == "Call out" || item.shiftCategory == "8" || item.shiftCategory == "9"
                        ? ""
                        : "/" + countString + "" + item.r + "RC"}
                </span>
            </div>
        </div>
    );
}

const FooterColumnComponent = ({ groups, colPosition, popupPosition, component, cstyle }) => {
    const [open, setOpen] = useState(false);

    const popupRef = useRef();

    const handleClick = useCallback((e) => {
        if (popupRef.current && popupRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    let counted, notCounted;

    if (groups) {
        ({ counted =[], notCounted =[] } = groups.sort((a, b) => (a.type.toUpperCase() > b.type.toUpperCase() ? 1 : a.type.toUpperCase() < b.type.toUpperCase() ? -1 : 0)).sort((a, b) => (a.countInWeekViewAsDriver.toString() < b.countInWeekViewAsDriver.toString() ? 1 : a.countInWeekViewAsDriver.toString() > b.countInWeekViewAsDriver.toString() ? -1 : 0)).reduce((acc, obj) => {
            const key = obj["countInWeekViewAsDriver"] !== "0" ? "counted" : "notCounted";
            const curGroup = acc[key] ?? [];
            return { ...acc, [key]: [...curGroup, obj] };
        }, {}));
    }

    return (
        <div
            onClick={() => {
                setOpen(!open);
            }}
            ref={popupRef}
            style={{ width: "100%", height: "100%", ...cstyle }}
        >
            {component}

            {groups && open && (
                <div
                    className={`popup ${colPosition == 7 || colPosition == 6 ? "right" : "left"} `}
                    style={popupPosition}
                >
                    <div className="scroll">
                        {counted && counted.length > 0 && counted.map((item, key) => <ComponentItem key={key} item={item} />)}
                        {counted && counted.length > 0 && notCounted && notCounted.length > 0 && <div style={{ borderBottom: "1px solid rgb(124, 152, 182)", marginBottom: "10px" }}></div>}
                        {notCounted && notCounted.length > 0 && notCounted.map((item, key) => <ComponentItem key={key} item={item} />)}
                    </div>
                </div>
            )}
            {groups && !open && (
                <Icon.ArrowDown size="16px" color="#7C98B6" style={{ position: "absolute", right: 8 }} />
            )}
            {groups && open && <Icon.ArrowUp size="16px" color="#7C98B6" style={{ position: "absolute", right: 8 }} />}
        </div>
    );
};

const HeaderColumnComponent = ({ Wrapper, label, colWidth, popUpDirection, options, dataKey }) => {
    const [state, dispatch] = useContext(WeekStore);
    const [open, setOpen] = useState(false);

    const popupRef = useRef();

    const handleClick = useCallback((e) => {
        if (popupRef.current && popupRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const arrowStyle = { position: "top", top: "2px" };

    let dayClass = "driver";
    if (document.querySelector(".DayPickerInput input") && dataKey !== "driver") {
        let week = document.querySelector(".DayPickerInput input").value.replace(/[^\d]/g, "");
        let today = moment().format("YYYY-MM-DD");
        let thisDay = moment().isoWeek(week).weekday(dataKey.replace("w", "")).format("YYYY-MM-DD");
        dayClass = today == thisDay ? "today" : today > thisDay ? "pastday" : "futureday";
    }

    return (
        <CellDropHint className={dayClass}>
            <div
                id="hiddenRefeshFetchList"
                ref={popupRef}
                onClick={() => {
                    setOpen(!open);
                }}
                style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between" }}
            >
                {label}
                {open ? (
                    <Icon.ArrowUp size="16px" color={Theme.colors.info.border} style={arrowStyle} />
                ) : (
                    <Icon.ArrowDown size="16px" color={Theme.colors.info.border} style={arrowStyle} />
                )}
                {open && (
                    <Wrapper style={{ minWidth: colWidth + 1 }} direction={popUpDirection}>
                        <li className="title">Order By</li>
                        {options.map((item, key) => {
                            if (dataKey != "driver" && key > 2) {
                                return (
                                    <li
                                        key={key}
                                        className={item.className || ""}
                                        onClick={async () => {
                                            if (item.data_elem === "allOpenShift") {
                                                let conf = await confirm({
                                                    icon: true,
                                                    text: "Are you sure you want to delete all open shift?",
                                                });
                                                if (conf) {
                                                    let routeIds = [];
                                                    let openShift = await state.state.find((s) => s.id == "0.0.0")[dataKey] || [];
                                                    routeIds = await openShift.map((s) => s.id);
                                                    dispatch({
                                                        type: "DELETE_OPENSHIFT",
                                                        payload: { 'routeIds': routeIds, "week": dataKey }
                                                    });
                                                }
                                            } else if (item.data_elem === "unschedule") {
                                                let action;
                                                action = await dialog({
                                                    icon: (
                                                        <Icon.Warning size="60px" color={Theme.colors.warnings.text} />
                                                    ),
                                                    title: "Are you sure you want to unassign all shifts?",
                                                    text: "Choose one option bellow:",
                                                    buttons: [
                                                        {
                                                            text: "Cancel",
                                                            type: "cancel",
                                                            inline: false,
                                                            style: {
                                                                width: "100%",
                                                                marginTop: 8,
                                                            },
                                                            callback: () => false,
                                                        },
                                                        {
                                                            text: "Unassign and Post to Open",
                                                            inline: false,
                                                            style: {
                                                                width: "100%",
                                                                marginTop: 8,
                                                            },
                                                            callback: () => "Unassign",
                                                        },
                                                        {
                                                            text: "Unassign and Delete Shifts",
                                                            type: "primary",
                                                            inline: false,
                                                            style: {
                                                                width: "100%",
                                                                marginTop: 8,
                                                            },
                                                            callback: () => "Unassign-and-delete",
                                                        },
                                                    ],
                                                });

                                                // console.log({action});
                                                if (!action) return;

                                                let message = await dialog({
                                                    title: "Send message to all scheduled drivers?",
                                                    fields: [
                                                        {
                                                            field: Input,
                                                            props: {
                                                                name: "msg",
                                                                as: "textarea",
                                                                style: {
                                                                    height: 80,
                                                                    resize: "none",
                                                                },
                                                            },
                                                        },
                                                        {
                                                            field: CheckBox,
                                                            props: {
                                                                name: "sendMessage",
                                                                options: [
                                                                    { value: "true", label: "Send SMS Notification" },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                    buttons: [
                                                        {
                                                            type: "primary",
                                                            text: "Send",
                                                        },
                                                        {
                                                            text: "Skip",
                                                            callback: () => "skip",
                                                        },
                                                        {
                                                            type: "cancel",
                                                            text: "Cancel",
                                                            callback: () => "false",
                                                        },
                                                    ],
                                                });

                                                if (message == "skip") {
                                                    alert(message);
                                                    let conf = await confirm({
                                                        icon: true,
                                                        text: "This action will cancel all in progress shifts and unassign/delete all shifts not started. Would you like to continue?",
                                                    });
                                                    if (conf) {
                                                        dispatch({
                                                            type: "UNSCHEDULE",
                                                            payload: {
                                                                week: item.dataKey,
                                                                type: action,
                                                                status: "skip",
                                                                data: {},
                                                            },
                                                        });
                                                    }
                                                    return true;
                                                } else if (message != "false") {
                                                    if (
                                                        message.data.sendMessage == "true" ||
                                                        message.data.sendMessage == false
                                                    ) {
                                                        let confirmation = await confirm({
                                                            icon: true,
                                                            text: "This action will send your message to call back all, in progress shifts and unassign/delete all shifts not started.  Would you like to continue?",
                                                        });
                                                        if (confirmation) {
                                                            dispatch({
                                                                type: "UNSCHEDULE",
                                                                payload: {
                                                                    week: item.dataKey,
                                                                    type: action,
                                                                    status: "send",
                                                                    data: message.data,
                                                                },
                                                            });
                                                        }
                                                        return false;
                                                    }
                                                } else {
                                                    return false;
                                                }
                                            } else if (item.data_elem === "holiday_week") {
                                                const confirmation = window.confirm(
                                                    "Are you sure you want to set as holiday/non-business day?"
                                                );
                                                if (confirmation) {
                                                    dispatch({
                                                        type: "HOLLIDAY_WEEK",
                                                        payload: { week: item.dataKey },
                                                    });
                                                }
                                            } else {
                                                dispatch({
                                                    type: key == 4 ? "HOLLIDAY_WEEK" : "UNSCHEDULE",
                                                    payload: { dataKey: dataKey },
                                                });
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </li>
                                );
                            }

                            return (
                                <li
                                    key={key}
                                    onClick={() => {
                                        dispatch({
                                            type: "ORDER",
                                            payload: {
                                                mainFields: item.fields,
                                                childFields: item.weekFields,
                                                dataKey: item.dataKey,
                                            },
                                        });
                                        state.refTable.current.recomputeRowHeights();
                                    }}
                                >
                                    {item.label}
                                </li>
                            );
                        })}
                    </Wrapper>
                )}
            </div>
        </CellDropHint>
    );
};

export { HeaderColumnComponent, FooterColumnComponent };
