import React from "react";
import {Spacer, ThirdTitle} from "@dspworkplace/ui";
import {Chart, Legend, LineAdvance, Tooltip, useTheme} from "bizcharts";
import './common';
import Empty from '../Empty';
import {mergeDeep} from "../../pages/Reports/common";

export default function LineChart({
    data = [],
    min,
    max,
    trend = true,
    avg = true,
    title,
    children,
    ...props
})
{
    const [chartTheme, setChartTheme] = useTheme('dspworkplace');
    if (!data.length)
        return <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Empty/>
        </div>;

    const defaultChartProps = {
        scale: {
            x: {
                range: [0.05, .95]
            }
        },
    };

    const chartProps = mergeDeep(defaultChartProps, props);

    return <>
        {title && <ThirdTitle>{title}</ThirdTitle>}
        {title && <Spacer bottom={5}/>}
        <Chart
            data={data}
            height={200}
            autoFit
            padding='0'
            theme={chartTheme}
            {...chartProps}
        >
            <Legend visible={false}/>

            <LineAdvance
                position="x*y"
                color='type'
                shape='smooth'
                point
                area
            />

            <Tooltip showCrosshairs/>

            {children}
        </Chart>
    </>
}

LineChart.extract = ({
    source = [],
    x = 'x',
    y = 'y',
    type = 'y'
}) => {
    return source.map(entry => {
        return {
            x: entry[x],
            y: entry[y],
            type: entry[type] || 'a'
        }
    });
}