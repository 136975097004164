import {engine, getCompanyId, showToast} from "../../../Utilities";
import {VehicleImageCategoriesAction, VehicleImageCategory} from "./index";
import {Dispatch} from "react";

export async function fetchVehicleImageCategories(dispatch: Dispatch<VehicleImageCategoriesAction>) {
    try {
        dispatch({type: "SET_LOADING", isLoading: true});
        let response = await engine().post('/api/vehicle/image/categories', {
            companyId: getCompanyId(),
            scenario: 'FETCH',
        });
        let data = response.data;
        dispatch({type: 'SET_DATA', data: data});
        return data;
    } catch (e) {
        console.error({e});
        if (e.response && e.response.data && typeof e.response.data.message === "string") {
            await showToast({
                type: 'Error',
                title: 'Error in fetching Vehicle Image Categories',
                content: e.response.data.message,
            });
        } else {
            await showToast({
                type: 'Error',
                title: 'Error in fetching Vehicle Image Categories',
            });
        }
        return [];
    }
}

export async function updateVehicleImageCategory(item: { id?: number, name: string }, scenario: 'ARCHIVE' | 'EDIT_NAME' | 'ADD', dispatch?: Dispatch<VehicleImageCategoriesAction>): Promise<VehicleImageCategory | null> {
    try {
        let response = await engine().post('/api/vehicle/image/categories', {
            id: item.id,
            name: item.name,
            scenario: scenario,
            companyId: getCompanyId(),
        });
        let data = response.data;
        if (dispatch) {

        }
        console.log({scenario, data});
        return data;
    } catch (e) {
        console.error({e});
        if (e.response && e.response.data && typeof e.response.data.message === "string") {
            await showToast({
                type: 'Error',
                title: 'Error in archiving Vehicle Image Category',
                content: e.response.data.message,
            });
        } else {
            await showToast({
                type: 'Error',
                title: 'Error in archiving Vehicle Image Category',
            });
        }
        return null;
    }
}