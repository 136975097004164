import React, {useEffect, useState} from 'react';
import {SubTitle} from "@dspworkplace/ui";
import API from "../api";
import Timeline from '../../../components/Timeline';
import toast from "../../../components/Toasts/toast";
import {Label} from "../../../components/UI";
import moment from 'moment';

const IncidentTimeline = ({
    visible,
    toggle,
    incident
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        API.fetchIncidentTimeline(incident.id).then(
            response => {
                if ( !response )
                    return;
                
                const incidentSubmittedPhase=response.data.data.incidentSubmittedPhase;
                incidentSubmittedPhase.forEach((phase) => {
                    phase.line.forEach(l => {
                        if(l['date']){
                            l['information'] = `${l['information']} ${moment(l['date']).format('[on] M/D/YYYY [at] h:mm A')}.`;
                        }
                    })
                })
                setData(response.data.data.incidentSubmittedPhase);
                setLoading(false);
            },
            error => {
                toggle();

                if ( error.toString() !== 'Cancel' )
                    toast({
                        type: 'error',
                        title: 'Error loading timeline',
                        content: error.toString()
                    });
            }
        )

        return API.cancel;
    },[incident, toggle]);

    return (
        <Timeline
            visible={visible}
            setVisible={toggle}
            loading={loading}
            title={
                <SubTitle>
                    {incident.incidentId}
                    <Label style={{lineHeight: 1}}>
                        <small>{incident.incidentType.name} - {incident.title}</small>
                    </Label>
                </SubTitle>
            }
            data={data}
        />
    )
}

export default IncidentTimeline;
