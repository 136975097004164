import {atom} from "recoil";
import {getToken} from "../../../Utilities";

export type tokenProps = {
    user: number,
    company: number,
    features: string[],
    'X-Plan-Tier': string,
    'X-Payment-Interval': string,
    [key: string]: any,
}

const emptyToken: tokenProps = {
    user: 0,
    company: 0,
    features: [],
    "X-Plan-Tier": '',
    "X-Payment-Interval": ''
}

export function parseToken()
{
    return JSON.parse(decodeURIComponent(
        window.atob(
            ((getToken().split('.')[1]).replace(/-/g, '+').replace(/_/g, '/'))
        ).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
    ))
}

export const tokenState = atom<tokenProps>({
    key: 'token',
    default: new Promise<tokenProps>(resolve => {
        try {
            resolve(parseToken());
        } catch (e) {
            resolve(emptyToken);
        }
    })
})