import React, {useEffect, useState} from 'react';
import StationPicker, {useSelectedStation} from "../../../../../components/StationSelector/StationPicker";
import {Spacer,TagInput} from "@dspworkplace/ui";
import {engine, getCompanyId, showErrorToast} from "../../../../../Utilities";
import {LoadingView} from "../../../../../components/Loading";
import styled from "styled-components";
import Overview from "./OverviewTab";

const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    height: auto;
`;

const Filter = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
`;

export type Employee = {
    userId: number;
    friendlyName: string;
    profileImage: string | null;
    driverId: string;
    firstName: string;
    lastName: string;
    identity: string;
}

type ReportScorecard = {
    date: string;
    id: number;
    week: number;
    year: number;
    ranking: number | null;
    tier: string;
    delivered: number;
    focus: string;
    fico: number;
    signViolationRate: number;
    followingDistanceRate: number;
    distractionsRate: number;
    seatbeltOffRate: number;
    speedingEventRate: number;
    deliveryCompletionRate: number;
    deliveryAndReceived: number;
    photoOnDelivery: number;
    contactCompliance: number;
    scanCompliance: number;
    attendedCompliance: number;
    dnrsCount: number;
    photoOpportunities: number;
    contactOpportunities: number;
};

export type ItemScorecardInformation = {
    metricIdentifier: string;
    metricName: string;
    metricValue: number | string | null;
    metricType: string | null;
    change: number | null;
    trend: string | null;
    trendStyle: string | null;
    scenario: string | null;
    valueField0: string | number | null;
    valueField1: string | number | null;
    message?: string | null;
    textColor?: string;
    icon?: string;
    cardStyle?: string;
    explanation?: string;
    show?: boolean;
};

export type MobileScorecardInformation = {
    scorecard: {
        numberOfFields: number;
        sortedList: Array<ItemScorecardInformation>;
        value0: ReportScorecard;
        value1: ReportScorecard;
        countChecker?: Array<any>;
    };
    incidents: {
        sortedList: Array<ItemScorecardInformation>;
    };
    rescues: {
        sortedList: Array<ItemScorecardInformation>;
    };
};

export async function fetchActiveEmployees(stationId: number): Promise<Employee[]> {
    try {
        let {data} = await engine({hideDefaultMessage: true}).post('/api/employees', {
            companyId: getCompanyId(),
            stationId: stationId,
        });
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching employees for Scorecard.');
        return [];
    }
}

const OverviewTemplate = () => {
    const [state, setState] = useState<{ drivers: Employee[]; tagOptions: any[]; loading: boolean; driverId?: number, mobileScorecard?: MobileScorecardInformation }>({
        drivers: [],
        tagOptions: [],
        loading: true
    });
    const {loading, tagOptions, driverId} = state;
    const [station] = useSelectedStation();
    const onChangeTagHandler = (data) => {
        console.log(data);
        if (Array.isArray(data) && data.length > 0 && data[0].value) {
            setState(prevState => ({...prevState, driverId: data[0].value}))
        }
    }

    useEffect(() => {
        setState(prevState => ({...prevState, loading: true}));
        fetchActiveEmployees(station).then((data) => {
            if (data.length > 0) {
                setState({
                    tagOptions: data.map(e => ({name: e.friendlyName, value: e.driverId})),
                    drivers: data,
                    loading: false
                });
            } else {
                setState({drivers: [], loading: false, tagOptions: []});
            }
        });
    }, [station]);

    if (loading) {
        return <LoadingView containerWidth={'500px'} containerHeight={'500px'}/>
    }

    return (
        <Container>
            <Filter>
                <StationPicker/>
                <Spacer right={5} inline/>
                <TagInput
                    name='driver'
                    label='Driver'
                    visibleOptionsQty={6}
                    options={tagOptions}
                    singleOption
                    onChange={onChangeTagHandler}
                />
            </Filter>
            {driverId && station &&
                <Overview driverId={driverId} stationId={station}/>
            }
        </Container>
    );
};

export default OverviewTemplate;
