import Filter from "../../../components/Filter";

import FilterServerResolver from "../../../components/Filter/filterResolver.server";

const filterDefinition = {
    station: "object",
    status: "exactString",
    type: "string",
    search: "multiString",
    vehicleId: "exactString",
    modelType: "string",
    unenroll:"string"
};

const matchingConfig = {
    date: "date",
};

const VehicleReducer = (state, action) => {
    const { type, payload } = action;

    let sorted;

    switch (type) {

        case 'SELECTED_DATE':
            return { ...state, selectedDate: payload };
        case 'VEHICLES_OPTS':
            const vehiclesOpts = payload.data.map(v => { return { name: v.vehicleId, value: v.vehicleId } })
            vehiclesOpts.unshift({ name: 'All', value: 'all' })
            return { ...state, vehiclesOpts: vehiclesOpts };
        case "ADD":
            const vehiclesFiltered = new Filter(filterDefinition, { station: payload.station }, payload.data);
            return { ...state, vehicles: payload.data, list: vehiclesFiltered, isLoading: false };
        case "NEW":
            state.vehicles.push(payload);
            state.list.push(payload);
            return { ...state };
        case "EDIT":
            state.vehicles[payload.index] = payload.data;
            return { ...state, list: state.vehicles };
        case "REMOVE":
            return {
                ...state,
                vehicles: state.vehicles.filter((item) => item.id !== payload.id),
                list: state.list.filter((item) => item.id !== payload.id),
            };
        case "SEARCH":
            sorted = sortBy(state.vehicles, state.sortBy);
            const filtered = new Filter(filterDefinition, payload, sorted);
            return { ...state, list: filtered, appliedFilters: payload };
        case "LOADING":
            return { ...state, isLoading: payload.status };
        case "SET_SORT_BY":
            let index = state.sortBy.findIndex((criteria) => criteria.field === payload);

            if (index >= 0) {
                let criteria = state.sortBy[index];

                if (criteria.direction === 0) criteria.direction = 1;
                else if (criteria.direction === 1) state.sortBy.splice(index, 1);
            } else {
                state.sortBy.push({
                    field: payload,
                    direction: 0,
                });
            }
            sorted = sortBy(state.vehicles, state.sortBy);
            const filteredData = new Filter(filterDefinition, (state.appliedFilters || {}), sorted);
            return { ...state, list: filteredData, appliedFilters: (state.appliedFilters || {}) };
        default:
            return state;
    }
};

const sortBy = (vehicles, criterias) => {
    criterias.reverse().map((c) => {
        let d = c.direction;

        vehicles.sort((f, s) => {
            switch (c.field) {
                case "station":
                    return d === 0
                        ? f.station.code.localeCompare(s.station.code)
                        : s.station.code.localeCompare(f.station.code);

                default:
                    if (d === 0) {
                        if (f[c.field] && s[c.field])
                            return f[c.field]
                                .toString()
                                .localeCompare(s[c.field].toString(), undefined, { numeric: true });
                        else return undefined;
                    } else {
                        if (f[c.field] && s[c.field])
                            return s[c.field]
                                .toString()
                                .localeCompare(f[c.field].toString(), undefined, { numeric: true });
                        else return undefined;
                    }
            }
        });
    });

    return vehicles;
};

export default VehicleReducer;
