import axios from "axios";
import {engine} from "../../../../../Utilities";

const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c) {
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if (API.current)
            return canceler();
        else
            return false;
    },
    fetchCompetitions(station) {
        return API.call({
            actions: {
                response: {
                    TeamCompetitor: {
                        findBy: {
                            get: "competitors",
                            criteria: {
                                station: station,
                            },
                            orderBy: {
                                name: 'ASC'
                            },
                            includes: {
                                "0": "id",
                                "1": "name",
                                "2": "ranking"
                            }
                        }
                    }
                }
            }
        })
    },
    getCompetitionReport(station,competitionId) {
        return API.call({
            station:station,
            competitionId:competitionId,
            format:true
        },'api/scorecard/competition/result')
    }
}

export default API;
