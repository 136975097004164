import apiFactory from '../../../api/factory';

type VehicleChangeMileageInterface = {
    vehicle_id: number,
    mileage: string
}

const {call} = apiFactory();
const resource = 'vehicle'
export const VehicleChangeMileage = (props: VehicleChangeMileageInterface) => {
    const {vehicle_id,mileage} = props
    return call({mileage:mileage},`api/v2/${resource}/${vehicle_id}/changeMileage`,'put')
}