import React, { useState, useMemo, useCallback, useImperativeHandle } from 'react';
import { Combobox as HeadlessCombobox } from '@headlessui/react'
import { Icon } from '@dspworkplace/ui';
// @ts-ignore
import style from './combobox.module.css';

import { DefaultOption } from './Options';

import { usePopper } from "react-popper";

const availableSizes = { extraSmall: 80, small: 140, default: 300, big: 460 };

export interface ComboboxPropsOption {
    label: string | number
    value: string | number | null
    disabled?: boolean
    [key: string]: any
}
export type OptionRenderProps = React.FC<{ row: ComboboxPropsOption, index: number }>

export interface ComboboxProps {
    label?: string;
    size?: keyof typeof availableSizes | 'full' | number;
    options?: ComboboxPropsOption[];
    OptionRender?: OptionRenderProps | null;
    initialValue?: number | string;
    onChange?: Function;
    startOpen?: boolean;
    placeholder?: string;
    nullText?: string;
    hasError?: boolean;
    isAdd: boolean;
    optionStyle: object;
}

const findOptionByProp = (options: ComboboxPropsOption[number], prop: string, value: string | number | undefined): ComboboxPropsOption | null => {
    let first = 0, last = options.length - 1;
    const valueString = value ? value.toString() : '';
    while (first <= last) {
        if (options?.[first]?.[prop]?.toString() === valueString) {
            return options[first];
        }
        first++

        if (options[last] && options?.[last]?.[prop]?.toString() === valueString) {
            return options[last];
        }
        last--
    }

    return null
}

const Combobox = ({ label, size = 'default', options: paramOpt = [], OptionRender, initialValue, onChange, startOpen = false, placeholder, nullText, isAdd = true, hasError = false, optionStyle = {maxHeight:'218px'} }: ComboboxProps, ref) => {
    const [selectedOption, setSelectedOption] = useState<ComboboxPropsOption | null>(() => {
        return findOptionByProp(paramOpt, 'value', initialValue)
    })
    const [query, setQuery] = useState('')
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        //strategy: 'fixed',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top-start'],
                },
            },
        ],
    })

    useImperativeHandle(ref, () => ({
        reset: (value) => {
            setSelectedOption(() => findOptionByProp(paramOpt, 'value', value));
        }
    }));

    const LayoutOption = useMemo(() => {
        return OptionRender || DefaultOption
    }, [OptionRender])

    const options = useMemo(() => {
        if (!nullText) return paramOpt

        const optCopy = [...paramOpt]
        optCopy.unshift({ label: nullText, value: null })
        return optCopy
    }, [nullText, paramOpt])

    const inlineStyle = useMemo(() => {
        let inlineStyle = {};
        if (size && size !== 'full') inlineStyle['width'] = typeof size === 'number' ? size + 'px' : availableSizes[size] + 'px';
        if (size === 'full') inlineStyle['height'] = '100%';
        return inlineStyle;
    }, [size])

    const handleChange = useCallback((selectedOption, emitEvent = true) => {
        setQuery('')
        setSelectedOption(selectedOption)
        if (emitEvent && onChange) {
            onChange(selectedOption);
        }
    }, [onChange])

    const handleActive = useCallback(({ active }) => {
        return `${style.comboboxOption} ${active ? style.comboboxOptionActive : ''}`
    }, [])

    const handleActiveButton = useCallback(({ open }) => {
        return open ? <Icon.ArrowUp /> : <Icon.ArrowDown />
    }, [])

    const handleDisplayValue = (opt: ComboboxPropsOption) => {
        return opt?.label.toString()
    }

    const onInputChange = useCallback((event) => {
        setQuery(event.target.value.trim())
    }, [])

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) => {
                const label = option.label.toString();
                return label.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <HeadlessCombobox
            value={selectedOption}
            onChange={handleChange}
            as={'div'}
            className={style.combobox}
            style={inlineStyle}
            nullable={true}
        >
            {label && <HeadlessCombobox.Label className={style.comboboxLabel}>{label}</HeadlessCombobox.Label>}
            <HeadlessCombobox.Button as={'div'} className={style.comboboxContent}>
                <HeadlessCombobox.Input
                    onChange={onInputChange}
                    className={`${style.comboboxInput} ${size === 'full' ? style.comboboxInputFull : ''} ${hasError === true ? style.comboboxWithError : ''}`}
                    autoFocus={startOpen}
                    displayValue={handleDisplayValue}
                    placeholder={placeholder}
                    ref={setReferenceElement}
                    autoComplete={'off'}
                />
                <HeadlessCombobox.Options
                    className={style.comboboxOptions}
                    ref={setPopperElement}
                    style={styles.popper, optionStyle}
                    {...attributes.popper}
                >
                    {isAdd && query.length > 0 && (
                        <HeadlessCombobox.Option
                            value={{ id: null, value: query, label: query }}
                            className={`${style.comboboxOptionCreate} ${style.comboboxOption}`}
                        >
                            <LayoutOption row={{ value: query, label: `Create "${query}"` }} index={filteredOptions.length + 1} />
                        </HeadlessCombobox.Option>
                    )}
                    {filteredOptions.map((option, key) => (
                        <HeadlessCombobox.Option
                            disabled={option.disabled}
                            value={option}
                            key={option.value}
                            className={handleActive}
                        >
                            <LayoutOption row={option} index={key} />
                        </HeadlessCombobox.Option>
                    ))}
                </HeadlessCombobox.Options>
                <HeadlessCombobox.Button className={style.comboboxButton}>
                    {handleActiveButton}
                </HeadlessCombobox.Button>
            </HeadlessCombobox.Button>
        </HeadlessCombobox>
    )
}
export default React.memo(React.forwardRef(Combobox));