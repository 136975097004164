import React, {useEffect, useState} from 'react';
import {Button, Icon, Input, Spacer, Dropdown, TagInput, ThirdTitle} from "@dspworkplace/ui";

import {Table, TableData, OrderIco, AddTable} from "../../components/Table";
import sorter from '../../components/SorterFn';
import {useForm} from "react-hook-form";
import { useAuth } from "../../Auth";
import {patternFloatValue} from '../../patterns';



const Skills = ({registerToParent,driverOptions,data,edit,skill,station}) => {
    const [skills, setSkills] = useState({update:-1,data:[],sort:[]});
    const [skillRates, setSkillRates] = useState();
    const [driversOptions, setDriversOptions] = useState();
    const { api } = useAuth();

    const getSkills = async (skills,station) => {
        const params={
              "actions": {
                    "response": {
                      "Skill": {
                        "findBy": {
                            "get": "Skills",
                            "criteria": {
                                "company":localStorage.getItem('company'),
                                "isArchive":0
                             },
                            "excludes": ["company", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers", "station"],
                            "includes": {
                                "0": "id",
                                "1": "name"
                            }
                          }
                       },
                      "SkillRate": {
                        "findBy": {
                            "get": "SkillRate",
                            "criteria": {
                                "station":station,
                                "isArchive":0
                             },
                            "excludes": ["company", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers", "station"],
                            "includes": {
                                "0": "id",
                                "1": "defaultRate",
                                "skill": ["id","name"]
                            }
                          }
                       }
                     }
                 }
        }
        try {
          let response = await api.post('/api/lazy/manage/data', params);
          let temp=[];
          let skillsObj=[];
          setSkillRates(response.data.data.SkillRate);
          Object.keys(response.data.data.Skills).forEach(function(skill) {
              temp = {
                  'name':response.data.data.Skills[skill].name.toString(),
                  'value':response.data.data.Skills[skill].id.toString(),
              }
              skillsObj.push(temp);
          });

          return new Promise((resolve,reject) => {
              setDriversOptions(skillsObj);
          });
        } catch(error) {
          if (error.response.status == '401') {
              window.location = "/logout";
          }
        }
    };
    const findDriverByKey = (id) => {
        return driversOptions.find((item,index)=> item.value == id)
    }

    const handleOrder = (fieldName,direction) => {
        const orderedData = sorter(skills.data,fieldName,!direction);
        setSkills({sort:[fieldName,direction],data:orderedData});
    }

    const handlerCurrentOrder = (fieldName) => {
        if(!skills.sort || !skills.sort[0]) return 0;
        return skills.sort && skills.sort[0] == fieldName ? !skills.sort[1] : 0
    }

    const findSkillRate = (skill_id) =>{
        let skillRate = skillRates.filter((item)=> {
          if(item.skill && parseInt(item.skill.id) === parseInt(skill_id)){
            return true;
          } else {
            return false;
          }
        });
        if(skillRate.length > 0){
            skillRate = skillRate[0];
        }

        return skillRate;
    }

    const onSubmit = async(data,key,editData) =>
    {
        let skillsData = [];
        let selectedSkills= {};
        let item = data.skill;
        // data.skill.map((item)=>{
        // console.log(item);
        let skillRate = findSkillRate(item);


            var keyContact = 'DriverSkill_'+item;
            if(skillRate){
              skillsData.push({id:item,name:findDriverByKey(item).name,hourly_rate:skillRate.defaultRate,skill_id:item,skillRate:skillRate.id});
              selectedSkills[keyContact]= {
                  'skill':item,
                  'driver':edit,
                  'hourlyRate':skillRate.defaultRate,
                  'skillRate':skillRate.id
              }
            }else {
              skillsData.push({id:item,name:findDriverByKey(item).name,hourly_rate:skillRate.defaultRate,skill_id:item,skillRate:null});
              selectedSkills[keyContact]= {
                'skill':item,
                'driver':edit,
                'hourlyRate':0
              }
            }
        // });

        let copy = [...skills.data];
        if(key>=0){
            if(edit > 0){
                let params = {
                    "actions": {
                        "update": {
                            "DriverSkill": {
                                "DriverSkill_1": {
                                    "findBy": {
                                        "id": editData.id
                                    },
                                    "updateRecord": {
                                        "skill": data.skill,
                                        "hourlyRate": (skillRate && skillRate.defaultRate),
                                        "skillRate": (skillRate && skillRate.id)
                                    }
                                }
                            }
                        }
                    }
                }
                await api.post('/api/lazy/manage/data', params);
            }
            skillsData[0].id = editData.id;
            skillsData[0].hourly_rate = parseInt(data.hourly_rate);
            skillsData[0].skill_id = parseInt(data.skill);
            copy[key] = skillsData[0];
            setSkills({data:copy});
        }else{
            if(edit > 0){
              let params = {
                  "actions": {
                      "create": {
                          "DriverSkill": selectedSkills
                      }
                  }
              }
               let response = await api.post('/api/lazy/manage/data', params);
                skillsData[0].id = response.data.data.responseId;
            }
            copy = copy.concat(skillsData);
            await setSkills({data:copy});
            skill({data:copy});
        }
    }

    const handleDelete = async(key,data) => {
        if (edit > 0) {
            if (skills.data.length <= 1) {
                alert('Atleast one skill assigned to Employee. So you can not delete this.');
                return false;
            }else{
                const confirmation = await window.confirm('Confirm that you want to delete this skill as this will be deleted from employee directly without save Employee form.');
                if(!confirmation){
                    return false;
                }
            }
        }else{
            const confirmation = await window.confirm('Confirm that you want to delete this item ?');
            if(!confirmation){
                return false;
            }
        }

        if(edit > 0){
            let params = {
                "actions": {
                    "delete": {
                        "DriverSkill": {
                            "DriverSkill_1": {
                                "findOneBy": {
                                    "id": data.id
                                },
                                "isHardDelete": true
                            }
                        }
                    },
                }
            };
            await api.post('/api/lazy/manage/data', params);
        }
        skills.data.splice(key,1);
        setSkills({...skills,data:skills.data});
        skill(skills);
    }

    useEffect(()=>{

        if(data){
            setSkills({...skills,data})
            skill({...skills,data});
        }
        getSkills(data,station);
    },[data,station])


    return (
        <>
            <ThirdTitle style={{fontWeight:'normal'}}>Skills *</ThirdTitle>
                <Table>
                    <li className='header'>
                        <TableData
                            width='188px'
                            order={1}
                            onClick={()=>{handleOrder('name', handlerCurrentOrder('name') )}}
                        >
                            Skill <OrderIco direction={handlerCurrentOrder('name') } />
                        </TableData>
                        <TableData width='80px' />
                    </li>
                    {skills.data.length > 0
                        ?
                        skills.data.map((item,index)=>(
                            (index == skills.update ?
                                    <AddForm key={index} onSubmit={onSubmit} keyEdit={index} editData={item} driversOptions={driversOptions}/>
                                    :
                                    <li key={index}>
                                        <TableData width='188px'>{item.name} <input type='hidden' ref={registerToParent} name={`skill[${index}].name`} defaultValue={item.name} /> </TableData>
                                        <TableData width='80px' order={1}>
                                            <input type='hidden' ref={registerToParent} name={`skill[${index}].id`} defaultValue={item.id} />
                                            <input type='hidden' ref={registerToParent} name={`skill[${index}].hourly_rate`} defaultValue={item.hourly_rate} />
                                            <input type='hidden' ref={registerToParent} name={`skill[${index}].skillRate`} defaultValue={item.skillRate} />
                                            <Spacer inline>
                                                <Icon.Edit size='20px' color='#7C98B6' onClick={()=>{setSkills({...skills,update:index})}} />
                                            </Spacer>
                                            <Spacer inline left={3}>
                                                <Icon.TimesNarrow size='12px' color='#7C98B6' onClick={()=>{handleDelete(index,item)}} />
                                            </Spacer>
                                        </TableData>
                                    </li>
                            )
                        ))
                        :
                        (<li style={{display:'flex',justifyContent:'center',padding:'20px',border:'none'}}>
                            <Icon.Empty color='#CCCCCC' size='60px' />
                        </li>)
                    }
                </Table>
                <AddForm onSubmit={onSubmit} driversOptions={driversOptions}/>
        </>
    );
}

const AddForm = ({onSubmit,keyEdit,editData,driversOptions}) => {
    let defaultFormValue = {};
    if(keyEdit>=0){
        defaultFormValue = {hourly_rate:editData.hourly_rate,skill:editData.skill};
    }

    const { register, handleSubmit, errors, setValue,formState, skill } = useForm({defaultValues:defaultFormValue});

    const submiter = data => {
        if(resetTag){
            if(!keyEdit) resetTag();
        }
         onSubmit(data,keyEdit,editData);
        setValue('hourly_rate','');
        setValue('skill', '',false);

    }
    let resetTag;
    const errorMessages = {
        skill: {
            required: 'No skill available in the system. Please choose from the available list',
        },
        hourly_rate: {
            required: 'You need to provide an hourly rate',
            pattern: 'Enter value with 2 decimal places only',
        }
    }



    return (
        <AddTable>
            <Spacer inline right={12}>
              <Dropdown
                  size='small'
                  name='skill'
                  placeholder="Select Skill"
                  ref={register({required:'Skill is required'})}
                  error={formState.dirty &&  errors.skill && errors.skill.message}
                  valid={!errors.skill && skill && skill.length > 0 }
                  defaultValue={keyEdit >= 0 && editData.skill_id.toString()}
                  resetCallback={(fn)=>{resetTag=fn}}
                  options={driversOptions}
              />

            </Spacer>

            <Spacer inline right={7}>
            </Spacer>

            <Spacer inline style={{verticalAlign:'bottom',float:'right'}}>
                <Button size='extraSmall' type='button' onClick={handleSubmit(submiter)}>{(keyEdit>=0) ? 'Save' : 'Add'}</Button>
            </Spacer>
        </AddTable>
    );
}

export default Skills;
