import React, { useCallback, Dispatch, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { LoadingView } from "../components/Loading";
import { NewChannelAction, NewChannelState } from "./CGCOptions";
import { useTrackedState } from "./store";
import { uploadSingleFile, getItemFromStorage } from "./utils";
import { getChatTeam } from "../Auth"

import { Grid, PrimaryButton } from "./styles";
import { Input, Spacer, TagInput, CustomDropdown, Toggle } from "@dspworkplace/ui";
import PictureUploader from "../Chat/PictureUploader";
import { Label, TextArea } from "./styles";
import { useChatContext } from 'stream-chat-react';

type Props = {
    newChannelModalState: NewChannelState;
    newChannelModalDispatch: Dispatch<NewChannelAction>;
    isNewChannel: boolean;
    closeModal: () => void;
    currentChannel;
}

const CGCPanelAddChannelInformation = (props: Props) => {
    const { isNewChannel, closeModal, newChannelModalState, newChannelModalDispatch, currentChannel } = props

    let name = '';
    let image = '';
    let errorMessage = '';
    // const {name, description, errorMessage, loadingCheckingChannelInformation, image} = newChannelModalState;

    const state = useTrackedState();
    const { chatClient, initialChannel } = state;
    const [roles, setRoles] = useState<any[]>([]);
    const [type, setType] = useState('group');
    const [userList, setUserList] = useState([]);

    const { setActiveChannel } = useChatContext();

    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
        // defaultValues: {
        //     channelName: name,
        //     channelImage: image,
        // }
    });

    const getUsers = async (params) => {
        // if (isAdd){
        //     setIsAdd(false);
        //     return true;
        // }
        // setIsAdd(true);
        // const response = await chatClient.queryUsers(
        //   {
        //     id: { $ne: chatClient.userID },
        //     // $and: [{ name: { $autocomplete: inputText } }],
        //   },
        // );
        // $and: [{ name: { $autocomplete: inputText } }],
        // code: "DMS2"
        const response = await chatClient.queryUsers(
            {
                id: { $ne: chatClient.userID },
                team: getChatTeam(),
            },
            { id: 1 },
            { limit: 100 },
        );

        await setUserList(response.users);

        return true;
    }
    useEffect(() => {
        getUsers();
    }, []);

    const onUpdate = async (data) => {
        document.querySelectorAll("[data-tab='tab']")[1].click();
    };
    const onSubmit =
        async data => {
            try {
                let dataImg = '';
                if (data.channelImage.length > 0) {
                    // @ts-ignore
                    dataImg = await uploadSingleFile(data.channelImage);
                    // update = true;
                } else {
                    dataImg = newChannelModalState.image;
                }
                // console.log({
                //     members: [...data.user, chatClient.userID],
                //     name: data.channelName,
                //     teams: getChatTeam(),
                //     pinUser: [],
                //     image: dataImg
                // });
                // const conversation = await chatClient.channel('group', {
                //     members: [...data.user, chatClient.userID],
                //     name: data.channelName,
                //     teams: getChatTeam(),
                //     pinUser: [],
                //     image: dataImg
                // });

                // await conversation.watch();

                await newChannelModalDispatch({
                    type: "SET_NEW_CHANNEL_INFORMATION",
                    name: data.channelName,
                    description: '',
                    image: dataImg,
                    errorMessage: '',
                    permissions: '',
                    channelNotification: '',
                });
                // @ts-ignore
                document.querySelectorAll("[data-tab='tab']")[1].click();
                // props.closeModal();
            } catch (e) {
                console.error(e);
            }
        }

    // if (loadingCheckingChannelInformation) {
    //     return (<LoadingView containerHeight={'315px'} containerWidth={'100%'} imgHeight={'150px'}/>);
    // }

    async function archiveGroup() {
        await currentChannel?.updatePartial({ set: { isArchive: true } });
        await setActiveChannel(initialChannel);
        await closeModal();
    }

    async function unArchiveGroup() {
        await currentChannel?.updatePartial({ set: { isArchive: false } });
        await closeModal()
    }


    return (
        <Grid>
            {errorMessage && errorMessage.length > 0 && (
                <Grid color="#D94C53" fontSize="16px" height="16px" margin="0 0 8px 0">
                    {errorMessage}
                </Grid>
            )}
            <Grid templateColumns="auto auto">
                <Grid>
                    {isNewChannel ?
                        <Grid>
                            <Grid justifyContent="start" width="100%" columnGap="4px" templateColumns="auto auto">
                                <Input type="text" name="channelName" label="Name" ref={register({ required: true })} defaultValue={newChannelModalState.name} />
                            </Grid>
                        </Grid>
                        :
                        <Grid templateColumns={"auto auto"} columnGap={"12px"} justifyContent={"flex-start"}>
                            <Label>Nickname: </Label>
                            <Grid fontSize="16px">{currentChannel?.data?.name}</Grid>
                        </Grid>
                    }
                </Grid>
                <Grid margin={'4px 8px'}>
                    {!isNewChannel ?
                        <PictureUploader
                            register={register({ required: false })}
                            name={"channelImage"}
                            id={"channelImage"}
                            image={currentChannel.data.image}
                            enable={false}
                        />
                        :
                        <PictureUploader
                            register={register({ required: false })}
                            name={"channelImage"}
                            id={"channelImage"}
                            image={newChannelModalState.image == "" ? null : newChannelModalState.image}
                        // enable={type === "Group"}
                        />
                    }
                </Grid>
            </Grid>
            {/* <Spacer top={5}/>
                <TagInput
                    name={'user'}
                    ref={register({required: true})}
                    label={'User List'}
                    options={userList.map((u) => { return { name: u?.name || '-', value: u?.id || '-' } })}
                    onChange={(event) => { console.log(event)}}
                    canCreate={false}
                />
            <Spacer top={5}/> */}

            {/* {type !== 'Duo' && <Spacer top={8}/>}
            {type !== 'Duo' && <Grid>
                <Toggle
                    name='channelNotification'
                    ref={register({required: false})}
                    singleText='Mute Channel Notification For All Users'
                />
            </Grid>}
            {type === 'Duo' && <Spacer top={8}/>}
            {type !== 'Duo' && <Spacer top={32}/>} */}
            {/* {type === 'Duo' && <Grid
                templateColumns={`repeat(2, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
                margin={'100px 0 0 0'}
            >
                {isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Pin
                    </PrimaryButton>
                )}
                {!isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Unpin
                    </PrimaryButton>
                )}
            </Grid>} */}
            <Spacer top={8} />
            {type === 'group' && <Grid
                templateColumns={`repeat(4, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
            >
                {chatClient?.user.role === 'admin' ?
                    !isNewChannel &&
                    <>
                        {currentChannel.data.isArchive ?
                            <PrimaryButton className={"modal cancel"} type="primary" onClick={() => unArchiveGroup()}>
                                Unarchive*
                            </PrimaryButton>
                            :
                            <PrimaryButton className={"modal cancel"} type="primary" onClick={() => archiveGroup()} >
                                Archive
                            </PrimaryButton>
                        }
                    </>
                    : null
                }
                {/* {isArchive && (
                    <PrimaryButton className={"modal cancel"} type="primary" onClick={isArchiveCallback}>
                        Archive
                    </PrimaryButton>
                )}
                {typeof isArchive === "boolean" && !isArchive && (
                    <PrimaryButton className={"modal cancel"} type="primary" onClick={() => {
                        console.log('archive');
                        try {
                            if (typeof unArchiveCallback === 'function') {
                                unArchiveCallback();
                            } else if (typeof isArchiveCallback === 'function') {
                                isArchiveCallback();
                            }
                        } catch (e) {
                            console.error(e);
                        }

                    }}>
                        Unarchive*
                    </PrimaryButton>
                )}
                {isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Pin
                    </PrimaryButton>
                )}
                {typeof isPin === "boolean" && !isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Unpin
                    </PrimaryButton>
                )}

                {updateNow && (
                    <PrimaryButton className={"modal"} type="primary" onClick={update}>
                        Update
                    </PrimaryButton>
                )}
                {showNext && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={showNextCallback}>
                        Next
                    </PrimaryButton>
                )} */}
                {!isNewChannel ?
                    <PrimaryButton className={"modal"} type="secondary" onClick={handleSubmit(onUpdate)}>
                        Next
                    </PrimaryButton> :
                    <PrimaryButton className={"modal"} type="secondary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </PrimaryButton>}
            </Grid>}
            <Grid
                templateColumns={`repeat(2, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
            >
                {/* {updateNow && (
                    <PrimaryButton className={"modal"} type="primary" onClick={update}>
                        Update
                    </PrimaryButton>
                )} */}
                {/* {showNext && ( */}
                {/* )} */}
            </Grid>
        </Grid>
    );
}

export default CGCPanelAddChannelInformation;