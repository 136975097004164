import ComboboxRecoil, {
    ComboboxRecoilTypeBase
} from "../../../components/Form/Combobox/combobox.recoil";
import React from "react";
import {VMPlanType} from "../state/VehicleMaintenancePlanType";

const VehiclePlanTypeCombobox = (props: ComboboxRecoilTypeBase) => {
    return <ComboboxRecoil {...props} store={VMPlanType} />
}

export default VehiclePlanTypeCombobox