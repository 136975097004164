import React, { useContext, useEffect, useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Icon,
    Input,
    Spacer,
    SubTitle,
    Text,
    Theme,
    ThirdTitle,
    Title,
    Toggle,
} from "@dspworkplace/ui";
import { formatCurrency, formatDate } from "./subscriptionInvoices";
import SettingsContext from "../context";
import { Card, GridColumns, Label, Link, Status } from "../../../components/UI";
import Skeleton from "../../../components/Skeleton";
import { Dialog, dialog, dialogPromise, loadingDialog } from "../../../components/Dialog";
import { alert } from "../../../components/Alert";
import { engine, getCompanyId } from "../../../Utilities";
import moment from "moment";
import useSubscriptionsApi from "../../../api/useSubscriptionsApi";
import Loading, { LoadingWrapper } from "../../../components/Loading";
import Tooltip from "../../../components/Tooltip";
import useDebounce from "../../../hooks/useDebounce";
import { canEditCompanySetting } from "../../Scheduler/Common/AccessCheck";

const PlanUpdateConfirmation = ({ selected, pricing, current }) => {
    const { previewProration, cancel } = useSubscriptionsApi();

    const [qty, setQty] = useState(() => parseInt(selected.qty ?? current.data.items.data[0].quantity));
    const [proration, setProration] = useState(null);

    const debouncedQty = useDebounce(qty, 1000);

    useEffect(() => {
        setProration(null);

        if (qty)
            previewProration({
                subscription: current.processorId,
                price: selected.processorData.prices.find(
                    (p) => p.recurring.interval === (pricing === "yearly" ? "year" : "month")
                ).id,
                qty,
            }).then((response) => setProration(response.data.data.result));

        return () => cancel();
    }, [debouncedQty]);

    const sentence =
        proration === null ? (
            <Skeleton height={20} width={120} />
        ) : proration.type ? (
            proration.message
        ) : (
            <table cellPadding={4} style={{ width: "100%" }}>
                {pricing === "monthly" ? null : (
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                {proration.total > 0 ? "We will charge you today:" : "You will receive in credits:"}
                            </td>
                            <th align="right">{formatCurrency(proration.total / 100)}</th>
                        </tr>
                    </tfoot>
                )}
                <tbody>
                    {!proration.lines.data[1] ? null : (
                        <tr>
                            <td>{proration.lines.data[1].quantity}x</td>
                            <td>
                                <u>{proration.lines.data[1].product.name}</u> until{" "}
                                {moment(proration.lines.data[1].period.end * 1000).format("ll")}
                            </td>
                            <td align="right">
                                <strong>{formatCurrency(proration.lines.data[1].amount / 100)}</strong>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>{proration.lines.data[0].quantity}x</td>
                        <td>
                            Credit for unused time of <u>{proration.lines.data[0].product.name}</u> paid on{" "}
                            {moment(proration.lines.data[0].period.start * 1000).format("ll")}
                        </td>
                        <td align="right">
                            <strong>{formatCurrency(proration.lines.data[0].amount / 100)}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    return (
        <>
            <Text>Please review and confirm the changes:</Text>
            <Spacer bottom={5} />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                    gridTemplateRows: "auto auto auto auto",
                    gap: 20,
                    alignItems: "center",
                }}
            >
                <div></div>
                <Label>Current</Label>
                <Label>New</Label>

                <Label>Package</Label>
                <Text style={{ textTransform: "capitalize" }}>
                    {current.subscriptionItems[0].product.name}
                    <br />
                    <small>{current.interval}</small>
                </Text>
                <Text style={{ textTransform: "capitalize" }}>
                    {selected.name}
                    <br />
                    <small>{pricing}</small>
                </Text>

                <Label>Active Employees</Label>
                <Text>{selected.qty ?? current.data.items.data[0].quantity}</Text>
                <Input
                    id="new-qty"
                    onChange={(v) => setQty(parseInt(v.target.value) || qty)}
                    name="qty"
                    defaultValue={qty}
                    size="extraSmall"
                />
            </div>
            {proration && proration.type ? (
                <Alert.Warning useIcon={true} title={sentence} />
            ) : (
                <Alert.Info useIcon={true} title={sentence} />
            )}
            <Spacer bottom={3} />
            {proration === null || (proration && proration.type) ? (
                <LoadingWrapper style={{ height: 80, top: "auto" }} />
            ) : null}
        </>
    );
};

const startPlanUpdate = (params) => {
    const props = {
        ...params,
        selected: {
            ...(params?.selected ?? {}),
            qty: document.getElementById("qty")?.value,
        },
    };

    return dialog({
        modal: {
            removeHeader: false,
            title: "You are updating your package",
            width: "600px",
        },
        align: {
            content: "left",
        },
        children: <PlanUpdateConfirmation {...props} />,
        buttons: [
            {
                type: "cancel",
                text: "Cancel",
                size: "default",
                callback: () => false,
            },
            {
                type: "primary",
                text: "Confirm Package Update",
                size: "default",
                callback: () => {
                    return {
                        ...props,
                        selected: {
                            ...props.selected,
                            qty: document.getElementById("new-qty")?.value ?? props.selected.qty,
                        },
                    };
                },
            },
        ],
    }).then((action) => {
        if (!action) return false;

        const qty = action.selected.qty;

        if (!qty || qty == 0) {
            dialog({
                text: "Please selected a quantity greater than zero",
                buttons: [
                    {
                        text: "Ok",
                        action: "ok",
                    },
                ],
            }).then(() => startPlanUpdate(params));

            return false;
        }

        return action;
    });
};

const UpdateSeatsButton = () => {
    const { updatePackage, cancel } = useSubscriptionsApi();
    const [state] = useContext(SettingsContext);
    const [subscription] = state.package;

    const handleClick = () => {
        startPlanUpdate({
            current: subscription,
            selected: subscription.subscriptionItems[0].product,
            pricing: subscription.interval,
        }).then((action) => {
            if (!action) return;

            const hide = loadingDialog();

            const unitPrice =
                (subscription.data.items.data.reduce((t, i) => t + parseFloat(i.price.unit_amount_decimal), 0) *
                    (state.package[0].interval === "monthly" ? 30 : 1)) /
                100;

            updatePackage({
                subscription: subscription.processorId,
                product: action.selected.processorId,
                price: action.selected.processorData.prices.find(
                    (p) => p.recurring.interval === "year" && p.unit_amount === Math.round(unitPrice * 100)
                ).id,
                qty: action.selected.qty,
            })
                .then((result) => {
                    dialog({
                        icon: <Icon.CheckNarrow color={Theme.colors.success.text} />,
                        title: "Subscription Updated",
                        text: "The app you reload your information and apply the change now.",
                        buttons: [
                            {
                                text: "Confirm",
                                type: "primary",
                                callback: () => window.location.reload(),
                            },
                        ],
                    });
                })
                .catch((error) =>
                    alert({
                        icon: true,
                        title: "Fail to update your subscription",
                        text: error.message,
                    })
                );
        });
    };

    useEffect(() => {
        return () => cancel();
    }, []);

    return (
        <Button type={"primary"} onClick={handleClick}>
            Update
        </Button>
    );
};

const ChangePackageButton = () => {
    const { getAvailableProducts, updatePackage, ...factory } = useSubscriptionsApi();
    const [state] = useContext(SettingsContext);

    const [subscription] = state.package;

    const [change, setChange] = useState(false);
    const [packages, setPackages] = useState({});
    const [pricing, setPricing] = useState(() => subscription?.interval);

    const { basic, partner } = packages;
    if (basic && partner)
        [basic, partner].forEach((p) => {
            p.price =
                p.processorData &&
                p.processorData.prices.find(
                    (price) => price.lookup_key && price.recurring.interval === (pricing === "yearly" ? "year" : "month")
                );
            p.unitPrice =
                p.price &&
                parseFloat((p.price.unit_amount_decimal / 100) * (pricing === "yearly" ? 1 / 12 : 30)).toFixed(2);
        });

    const processPackageUpdate = (action) => {
        if (!action) return;

        const { current, selected } = action;

        setChange(false);
        const hide = loadingDialog();

        updatePackage({
            subscription: current.processorId,
            product: selected.processorId,
            price: selected.price?.id,
            qty: selected.qty,
        })
            .then((result) => {
                if (result.data.data.result == true) {
                    dialog({
                        icon: <Icon.CheckNarrow color={Theme.colors.success.text} />,
                        title: "Subscription Updated",
                        text: "The app you reload your information and apply the change now.",
                        buttons: [
                            {
                                text: "Confirm",
                                type: "primary",
                                callback: () => window.location.reload(),
                            },
                        ],
                    });
                } else {
                    alert({
                        icon: true,
                        title: "Fail to Subscription Update",
                        text: result.data.data.result,
                    });
                }
            })
            .catch((error) =>
                alert({
                    icon: true,
                    title: "Fail to update your subscription",
                    text: error.message,
                })
            );
    };

    useEffect(() => {
        getAvailableProducts().then((packages) => setPackages(packages.data));

        return factory.cancel;
    }, []);

    return (
        <>
            <Button disabled={canEditCompanySetting() ? false : true} type="primary" style={{ width: "100%" }} onClick={() => setChange(true)}>
                Change Package
            </Button>

            {change && (
                <Dialog
                    title="Change Package"
                    modal={{
                        removeHeader: false,
                        width: "1000px",
                    }}
                    cancel={() => setChange(false)}
                >
                    <Label>Payments Interval</Label>
                    <Spacer bottom={1} />
                    <ButtonGroup active={pricing} Type="button">
                        <Button track="monthly" onClick={() => setPricing("monthly")}>
                            Monthly
                        </Button>
                        <Button track="yearly" onClick={() => setPricing("yearly")}>
                            Annual
                        </Button>
                    </ButtonGroup>
                    <Spacer bottom={5} />

                    <GridColumns columns={2}>
                        {subscription && basic?.name ? (
                            <>
                                {[basic, partner].map((t) => {
                                    const isCurrentPackage =
                                        state.package?.[0].subscriptionItems[0].product.id === t.id &&
                                        state.package?.[0].interval === pricing;

                                    return (
                                        <Card key={t}>
                                            <Title>{t.name}</Title>
                                            <Spacer bottom={1} />
                                            <ThirdTitle>{formatCurrency(t.unitPrice)}/user/month</ThirdTitle>
                                            <Spacer top={5} />
                                            <Button
                                                type="primary"
                                                Type="button"
                                                disabled={isCurrentPackage}
                                                onClick={() =>
                                                    startPlanUpdate({
                                                        current: subscription,
                                                        selected: t,
                                                        pricing,
                                                    }).then(processPackageUpdate)
                                                }
                                            >
                                                {isCurrentPackage ? "Your Current Package" : "Select"}
                                            </Button>

                                            <Spacer top={5} />
                                            <Text>{t.description}</Text>
                                        </Card>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                {Array(2).map((i) => (
                                    <Card key={i}>
                                        <Title>
                                            <Skeleton width={180} height={40} />
                                        </Title>
                                        <Spacer bottom={1} />
                                        <ThirdTitle>
                                            <Skeleton width={80} height={20} /> /user /month
                                        </ThirdTitle>
                                        <Spacer top={5} />
                                        <Button type="primary" disabled={true}>
                                            Select
                                        </Button>

                                        <Spacer top={5} />
                                        <Label>Includes:</Label>
                                        <Spacer top={2} bottom={2} as={Text} key={Math.random()}>
                                            <Skeleton width={220} height={14} />
                                        </Spacer>
                                        <Spacer top={2} bottom={2} as={Text} key={Math.random()}>
                                            <Skeleton width={220} height={14} />
                                        </Spacer>
                                        <Spacer top={2} bottom={2} as={Text} key={Math.random()}>
                                            <Skeleton width={220} height={14} />
                                        </Spacer>
                                    </Card>
                                ))}
                            </>
                        )}
                    </GridColumns>

                    <Spacer top={5} />
                    <Link target="_blank" href={"https://dspworkplace.com/pricing"}>
                        See feature comparison &#8599;
                    </Link>
                </Dialog>
            )}
        </>
    );
};

const AddonSignup = ({ addon }) => {
    const { addonSignup } = useSubscriptionsApi();

    const handleClick = () => {
        dialog({
            modal: {
                removeHeader: false,
                title: "You are signing up for an addon",
                width: "600px",
            },
            align: {
                content: "left",
                footer: "left",
            },
            children: (
                <>
                    <SubTitle style={{ textTransform: "capitalize" }}>{addon.name}</SubTitle>
                    <Spacer bottom={1} />
                    {addon.processorData.prices[0].unit_amount === 0 ? (
                        <ThirdTitle>Free</ThirdTitle>
                    ) : addon.processorData.prices[0].recurring.usage_type === "metered" ? (
                        <ThirdTitle>
                            {formatCurrency(parseFloat(addon.processorData.prices[0].unit_amount_decimal) / 100, 4)}/
                            {addon.processorData.unit_label ?? "unit"}
                        </ThirdTitle>
                    ) : (
                        <ThirdTitle>
                            {formatCurrency(
                                (addon.processorData.prices[0].unit_amount_decimal / 100) * addon.processorData.quantity
                            )}
                        </ThirdTitle>
                    )}
                    <Spacer top={3} />
                    <Text>{addon.description}</Text>
                </>
            ),
            buttons: [
                {
                    type: "cancel",
                    text: "Cancel",
                    size: "default",
                    callback: () => false,
                },
                {
                    type: "primary",
                    text: "Confirm Addon Sign Up",
                    size: "default",
                    callback: () => true,
                },
            ],
        }).then((action) => {
            if (!action) return false;

            loadingDialog();

            addonSignup({ addon })
                .then((result) => {
                    if (result.data.data.result == true) {
                        dialog({
                            icon: <Icon.CheckNarrow color={Theme.colors.success.text} />,
                            title: "Your Addon is Ready",
                            text: "You can manage your addon subscription anytime.",
                            buttons: [
                                {
                                    text: "Confirm",
                                    type: "primary",
                                    callback: () => window.location.reload(),
                                },
                            ],
                        });
                    } else {
                        alert({
                            icon: true,
                            title: "Fail to sign up to " + addon.name,
                            text: result.data.data.result,
                        });
                    }
                })
                .catch((error) =>
                    alert({
                        icon: true,
                        title: "Fail to sign up to " + addon.name,
                        text: error.message,
                    })
                );
        });
    };

    return (
        <Button disabled={canEditCompanySetting() ? false : true} type={"primary"} size={"small"} onClick={handleClick}>
            Sign Up
        </Button>
    );
};

const AddonToggler = ({ item }) => {
    const [active, setActive] = useState(item.status === "active");
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState(true);

    const { toggleSubscriptionItemStatus, fetchSeatsInformation } = useSubscriptionsApi();

    const handleChange = () => {
        setLoading(true);
        setActive(!active);
        toggleSubscriptionItemStatus({
            item: {
                ...item,
                status: active ? "active" : "paused",
            },
        })
            .catch(() => setActive(active))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (item.product.features.includes("FLEXIBLE_SEATS"))
            fetchSeatsInformation().then(({ activeEmployees, reservedSeats }) =>
                setWarning(
                    activeEmployees > reservedSeats ? (
                        <Text>
                            You have {activeEmployees - reservedSeats} active employees over your reserved seats.
                            <br /> To turn off flexible seats your active employees
                            <br /> count must be no higher than your reserved seats count.
                        </Text>
                    ) : (
                        false
                    )
                )
            );
        else setWarning(false);
    }, []);

    return (
        <div
            style={{
                position: "relative",
                display: "inline-block",
                cursor: warning ? "not-allowed" : "default",
            }}
        >
            <Tooltip content={warning}>
                {loading && (
                    <LoadingWrapper>
                        <Loading style={{ height: 20 }} />
                    </LoadingWrapper>
                )}
                {warning && <LoadingWrapper />}
                <Toggle on={active} singleText={`Enable ${item.product.name}`} onChange={handleChange} />
            </Tooltip>
        </div>
    );
};

const ViewUsageButton = ({ subscription, item }) => {
    const { fetchUsageSummaries } = useSubscriptionsApi();

    const handleClick = () => {
        const hide = loadingDialog();

        fetchUsageSummaries({
            subscription: subscription.processorId,
            subscriptionItem: item.processorId,
        })
            .then((response) => {
                const summary = response.data.data.result[0];
                return dialogPromise((close) => (
                    <Dialog
                        success={close}
                        cancel={close}
                        align={{
                            header: "left",
                            content: "left",
                        }}
                        title={`${item.name} Usage`}
                        buttons={[
                            {
                                text: "Close",
                                type: "cancel",
                                callback: close,
                            },
                        ]}
                    >
                        <>
                            <Label>
                                Reported usage
                                {summary.period.start && summary.period.end
                                    ? `between ${summary.period.start} and ${summary.period.end}`
                                    : "in this billing cycle"}
                                :
                            </Label>
                            <Spacer as={Text} top={1} bottom={3}>
                                {summary.total_usage}
                            </Spacer>
                            <Text>
                                <small>Older periods usage can be seen on the invoices page.</small>
                            </Text>
                        </>
                    </Dialog>
                ));
            })
            .catch(hide);
    };

    return (
        <Button type={"default"} size={"small"} onClick={handleClick}>
            View Usage
        </Button>
    );
};

export default function SubscriptionPackage() {
    const { fetchSeatsInformation, getAvailableProducts, fetchSubscriptions, cancel } = useSubscriptionsApi();

    const [state, dispatch] = useContext(SettingsContext);

    useEffect(() => {
        dispatch({
            type: "ADD",
            payload: {
                ...state?.subscriptions?.reduce((all, s) => {
                    all[s.type] = all[s.type] || [];

                    s.subscriptionItems = Object.values(s.subscriptionItems).filter((i) => i.status !== "cancelled");
                    all[s.type].push(s);

                    return all;
                }, {}),
            },
        });
    }, [state.subscriptions]);

    useEffect(() => {
        fetchSeatsInformation().then(({ activeEmployees }) => {
            dispatch({
                type: "ADD",
                payload: {
                    activeEmployeesCount: activeEmployees,
                },
            });
        });

        return () => cancel();
    }, []);

    useEffect(() => {
        if (!state.package)
            fetchSubscriptions().then((response) => {
                dispatch({
                    type: "ADD",
                    payload: {
                        subscriptions: response.data.data.subscriptions,
                    },
                });
            });

        if (!state.availableAddons && state.package)
            getAvailableProducts("addon").then(({ data }) => {
                dispatch({
                    type: "ADD",
                    payload: {
                        availableAddons: data.filter(
                            (addon) =>
                                (!addon.tier || (addon.tier && addon.tier === state.package[0].tier)) &&
                                (!addon.interval || (addon.interval && addon.interval === state.package[0].interval))
                        ),
                    },
                });
            });

        return () => cancel();
    }, [state.package]);

    return (
        <section>
            <GridColumns columns={2}>
                <div>
                    <Spacer bottom={3} />
                    <SubTitle>Main package</SubTitle>
                    <Spacer bottom={3} />
                </div>
            </GridColumns>
            <GridColumns columns={2}>
                {!state.package ? (
                    <Card>
                        <Skeleton height={40} width={300} />
                        <Spacer bottom={1} />
                        <Skeleton height={24} width={180} />
                        <Spacer top={3} />
                        <Label>Number of Active Employees</Label>
                        <Spacer bottom={1} />
                        <Skeleton height={40} width={80} />
                        <Spacer top={3} />
                        <Button disabled={true} type="primary" style={{ width: "100%" }}>
                            {" "}
                        </Button>
                    </Card>
                ) : null}
                {state.package?.map((subscription) => (
                    <Card style={{ height: "auto" }} key={subscription.id}>
                        {subscription.data.trial_end && subscription.data.trial_end > new Date().getTime() && (
                            <Spacer bottom={1}>
                                <Status current="warning">
                                    Trial ends in {formatDate(new Date(subscription.trial_end))}
                                </Status>
                            </Spacer>
                        )}
                        <Title style={{ textTransform: "capitalize" }}>
                            {subscription.subscriptionItems[0].product.name}
                        </Title>
                        <Spacer bottom={1} />
                        <ThirdTitle>
                            <span style={{ textTransform: "capitalize" }}>{subscription.interval} payments</span> -{" "}
                            {formatCurrency(
                                (subscription.data.items.data.reduce(
                                    (t, i) => t + parseFloat(i.price.unit_amount_decimal),
                                    0
                                ) *
                                    (subscription.interval === "monthly" ? 30 : 1)) /
                                    100
                            )}
                            /user
                        </ThirdTitle>

                        <Spacer top={3} />
                        <Label>Number of Active Employees</Label>
                        <Spacer bottom={1}>
                            <Input
                                name="users"
                                defaultValue={(() => {
                                    let max = Math.max(...subscription.data.items.data.map((d) => d.quantity));

                                    if (!max || isNaN(max)) return state?.activeEmployeesCount;

                                    return max;
                                })()}
                                size="extraSmall"
                                id="qty"
                                readOnly={subscription.interval === "monthly"}
                            />
                            {subscription.interval !== "monthly" ? (
                                <>
                                    <Spacer inline right={3} />
                                    <UpdateSeatsButton />
                                </>
                            ) : null}
                        </Spacer>

                        <Spacer top={3} />
                        <ChangePackageButton />
                    </Card>
                ))}
            </GridColumns>
            <Spacer top={10} bottom={3}>
                <Link target="_blank" href={"https://billing.stripe.com/p/login/6oEbM451B3tv6nS288"}>
                    <Button disabled={canEditCompanySetting() ? false : true} type="primary" style={{ width: "49.5%" }}>
                        Change Payment Information
                    </Button>
                </Link>
            </Spacer>
            <Spacer top={3} bottom={3}>
                <SubTitle>Usage Based Subscriptions</SubTitle>
            </Spacer>
            <GridColumns columns={2}>
                {!state.availableAddons ? (
                    <Card>
                        <Skeleton height={40} width={140} />
                        <Spacer bottom={1} />
                        <Skeleton height={24} width={100} />
                        <Spacer top={3} />
                        <Skeleton height={20} width={200} />
                    </Card>
                ) : null}
                {state.availableAddons?.map((addon) => {
                    const item = state?.addon?.reduce((all, next) =>
                        all ?? next.subscriptionItems.find((i) => i.product.id === addon.id)
                    , null);

                    const price = item
                        ? item.processorPriceData
                        : addon.processorData.prices[0];

                    return <Card style={{ height: "auto" }} key={addon.id}>
                        <Title style={{ textTransform: "capitalize" }}>{addon.name}</Title>
                        <Spacer bottom={1} />

                        {!price ? 'what?' : (price.unit_amount === 0 ? (
                            <ThirdTitle>Free</ThirdTitle>
                        ) : price.recurring.usage_type === "metered" ? (
                            <ThirdTitle>
                                {formatCurrency(
                                    parseFloat(price.unit_amount_decimal) / 100,
                                    4
                                )}
                                /{addon.processorData.unit_label ?? "unit"}
                            </ThirdTitle>
                        ) : (
                            <ThirdTitle>
                                {formatCurrency(
                                    parseFloat(price.unit_amount_decimal / 100)
                                )}
                            </ThirdTitle>
                        ))}

                        <Spacer top={3} />
                        <Text>{addon.description}</Text>

                        <Spacer top={5} />
                        {!state.addon?.length ? <AddonSignup addon={addon} /> : null}
                        {state.addon?.map((a) => {
                            const item = a.subscriptionItems.find((i) => i.product.id === addon.id);

                            if (!item) return <AddonSignup addon={addon} key={a.id} />;

                            return (
                                <div key={a.id}>
                                    {canEditCompanySetting() && <AddonToggler item={item} />}

                                    {item.product.chargeAfterEvent ? (
                                        <Spacer top={5}>
                                            <ViewUsageButton subscription={a} item={item} />
                                        </Spacer>
                                    ) : null}
                                </div>
                            );
                        })}
                    </Card>
                })}
            </GridColumns>
            <Spacer bottom={10} />
        </section>
    );
}
