import React, { useCallback, useContext, useState } from 'react';
import SettingsContext from "../context";
import { SubTitle, Theme, Icon, Button, Input, Toggle } from "@dspworkplace/ui";
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { Table, TableData } from "../../../components/Table";
import { useAuth, getCompanyId } from "../../../Auth";
import { AccessCheck, IsGranted } from "../../../security";

export const SettingTable = () => {
    const [state, dispatch] = useContext(SettingsContext);
    const [key, setKey] = useState(-1);
    const { api } = useAuth();

    const onEdit = (item, index) => {
        if (index == 1) {
            dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, minLevel1: item.min, maxLevel1: item.max, notificationToDriverLower: item.notificationToDriver, notificationToManagerLower: item.notificationToManager } })
        } else if (index == 2) {
            dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, minLevel2: item.min, maxLevel2: item.max, notificationToDriverUpper: item.notificationToDriver, notificationToManagerUpper: item.notificationToManager } })
        } else if (index == 3) {
            dispatch({ type: 'UPDATE_ATTENDENCE_SETTING', payload: { ...state.attendanceRules, minLevel3: item.min, maxLevel3: item.max} })
        }
    };

    return (<>
        <section>
            <SubTitle>Attendance Settings</SubTitle>
            <div style={{ width: 720 }}>
                {state.attendanceRules  && <Table>
                    <li className='header'>
                        <TableData width='100px'>Color</TableData>
                        <TableData width='100px'>Min</TableData>
                        <TableData width='100px'>Max</TableData>
                        <TableData width='170px'>Notification To Driver</TableData>
                        <TableData width='170px'>Notification To Manager</TableData>
                        <TableData width='170px' />
                    </li>
                    {key === 1 && <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}><AddorEdit key={1} onEdit={onEdit} item={{ ...state.attendanceRules, min: state.attendanceRules.minLevel1, max: state.attendanceRules.maxLevel1, color: state.attendanceRules.colorLevel1, notificationToDriver: state.attendanceRules.notificationToDriverLower, notificationToManager: state.attendanceRules.notificationToManagerLower }} index={1} setKey={setKey} /></IsGranted>}
                    
                    {key != 1 && <li key={1}>
                        <TableData width='100px'>
                            <span style={{ height: 16, width: 16, background: state.attendanceRules.colorLevel1, display: 'block', borderRadius: '100%', border: '1px solid #CCCCCC' }} />
                        </TableData>
                        <TableData width='100px'>{(state.attendanceRules.minLevel1 && state.attendanceRules.minLevel1 >= 0) ? state.attendanceRules.minLevel1 : '-'}</TableData>
                        <TableData width='100px'>{(state.attendanceRules.maxLevel1 && state.attendanceRules.maxLevel1 >= 0) ? state.attendanceRules.maxLevel1 : '-'}</TableData>
                        <TableData width='170px'>{state.attendanceRules.notificationToDriverLower == true ?'true':'false'}</TableData>
                        <TableData width='170px'>{state.attendanceRules.notificationToManagerLower == true ? 'true' : 'false'}</TableData>
                        <TableData width='170px'>
                            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => setKey(1)} />
                            </div>
                            </IsGranted>
                        </TableData>
                    </li>}
                    {key === 2 && <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}><AddorEdit key={2} onEdit={onEdit} item={{ ...state.attendanceRules, min: state.attendanceRules.minLevel2, max: state.attendanceRules.maxLevel2, color: state.attendanceRules.colorLevel2, notificationToDriver: state.attendanceRules.notificationToDriverUpper, notificationToManager: state.attendanceRules.notificationToManagerUpper }} index={2} setKey={setKey} /></IsGranted>}
                    {key != 2 && <li key={2}>
                        <TableData width='100px'>
                            <span style={{ height: 16, width: 16, background: state.attendanceRules.colorLevel2, display: 'block', borderRadius: '100%', border: '1px solid #CCCCCC' }} />
                        </TableData>
                        <TableData width='100px'>{(state.attendanceRules.minLevel2 && state.attendanceRules.minLevel2 >= 0) ? state.attendanceRules.minLevel2 : '-'}</TableData>
                        <TableData width='100px'>{(state.attendanceRules.maxLevel2 && state.attendanceRules.maxLevel2 >= 0) ? state.attendanceRules.maxLevel2 : '-'}</TableData>
                        <TableData width='170px'>{state.attendanceRules.notificationToDriverUpper == true ? 'true' : 'false'}</TableData>
                        <TableData width='170px'>{state.attendanceRules.notificationToManagerUpper == true ? 'true' : 'false'}</TableData>
                        <TableData width='170px'>
                            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => setKey(2)} />
                            </div>
                            </IsGranted>
                        </TableData>
                    </li>}
                    {key === 3 && <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}><AddorEdit key={3} onEdit={onEdit} item={{ ...state.attendanceRules, min: state.attendanceRules.minLevel3, max: state.attendanceRules.maxLevel3, color: state.attendanceRules.colorLevel3 }} index={3} setKey={setKey} /></IsGranted>}
                    {key != 3 && <li key={3}>
                        <TableData width='100px'>
                            <span style={{ height: 16, width: 16, background: state.attendanceRules.colorLevel3, display: 'block', borderRadius: '100%', border: '1px solid #CCCCCC' }} />
                        </TableData>
                        <TableData width='100px'>{(state.attendanceRules.minLevel3 && state.attendanceRules.minLevel3 >= 0) ? state.attendanceRules.minLevel3 : '-'}</TableData>
                        <TableData width='100px'>{(state.attendanceRules.maxLevel3 && state.attendanceRules.maxLevel3 >= 0) ? state.attendanceRules.maxLevel3 : '-'}</TableData>
                        <TableData width='170px'>-</TableData>
                        <TableData width='170px'>-</TableData>
                        <TableData width='170px'>
                            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                <Icon.Edit size='20px' color={Theme.colors.info.border} onClick={() => setKey(3)} />
                            </div>
                            </IsGranted>
                        </TableData>
                    </li>}
                </Table>}
                </div>
            </section>
        </>
    )
};

const FixColor = styled.div`
    label[for="color"] {
        display:none;
    }
`

const Footer = styled.li`
    background:#FAFAFA;
    border-top: 1px solid #516F90;
`

const ErrorM = styled.span`
    display: block;
    margin-top: 5px;
    font-size: 11px;
    color: #333333;
`

const AddorEdit = ({ onAdd, onEdit, item, index, setKey }) => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, reset } = useForm({ defaultValues: item });

    const onSubmit = useCallback(async (data) => {

        if (item) {
            let updateData = {};
            updateData["minLevel" + index] = data.min;
            updateData["maxLevel" + index] = data.max;
            if (index == 1) {
                updateData["notificationToDriverLower"] = data.notificationToDriver;
                updateData["notificationToManagerLower"] = data.notificationToManager;
            } else if (index == 2) {
                updateData["notificationToDriverUpper"] = data.notificationToDriver;
                updateData["notificationToManagerUpper"] = data.notificationToManager;
                
            }
            let param = {
                "actions": {
                    "update": {
                        "AttendanceSettings": {
                            "attendance_settings_1": {
                                "findBy": {
                                    "company": getCompanyId()
                                },
                                "updateRecord": updateData
                            }
                        }
                    }
                }
            }
            await api.post('/api/lazy/manage/data', param);
            item["min"] = data.min;
            item["max"] = data.max;

            if (typeof onEdit === 'function') {
                onEdit({ min: data.min, max: data.max, notificationToDriver: data.notificationToDriver, notificationToManager:data.notificationToManager }, index);
            }

            setKey(-1);
        }

        reset({
            min: '',
            max: '',
        })

    })

    return (
        <Footer>
            <TableData width='100px' style={{ position: 'relative', zIndex: 3 }}>
                <FixColor>
                    <span style={{ height: 16, width: 16, background: item.color, display: 'block', borderRadius: '100%', border: '1px solid #CCCCCC' }} />
                </FixColor>
            </TableData>
            <TableData width='100px' style={{ position: 'relative', zIndex: 2 }}>
                <FixColor>
                    <Input name='min' type='number' size='extraSmall' ref={register({ required: 'Min is required', max: 100, min: 0 })} />
                    {errors && errors.min && <ErrorM>{errors.min.message}</ErrorM>}
                </FixColor>
            </TableData>
            <TableData width='100px'>
                <FixColor>
                    <Input name='max' type='number' size='extraSmall' ref={register({ required: 'Max is required', max: 100, min: 0 })} />
                    {errors && errors.max && <ErrorM>{errors.max}</ErrorM>}
                </FixColor>
            </TableData>
            {index != 3 ? <>
                <TableData width='170px'>
                    <Toggle
                        ref={register}
                        name='notificationToDriver'
                        on={item.notificationToDriver}
                    />
                </TableData>
                <TableData width='170px'>
                    <Toggle
                        ref={register}
                        name='notificationToManager'
                        on={item.notificationToManager}
                    />
                </TableData>
            </> : <TableData width='340px'></TableData>}
            <TableData width='170px'>
                <Button style={{ marginLeft: 'auto' }} onClick={handleSubmit(onSubmit)}>{item ? 'Save' : 'Add'} </Button>
            </TableData>
        </Footer>
    )
}

export default SettingTable;