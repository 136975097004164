import axios from "axios";
import {getCompanyId} from "../../../../Auth";
import {engine} from "../../../../Utilities/index";
import {HandleUpdateSlide,HandleCreateSlide} from "../Slides/apiHandler";

const CancelToken = axios.CancelToken;
let canceler;

let TemplateIncludes = {
    0:"id",
    1:"name",
    2:"tags",
    3:"slidesQuantity"
}

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchTemplates(params = {}){
        return API.call({
            actions: {
                response: {
                    AnncTemplate:{
                        findBy: {
                            get: "AnncTemplate",
                            criteria: {
                                company: getCompanyId()
                            },
                            orderBy: {
                                name: 'DESC'
                            },
                            matching: params.matching || {},
                            includes: TemplateIncludes
                        }
                    }
                }
            }
        });
    },
    fetchTemplatesWithSlides(params = {}){
        TemplateIncludes["slides"] = {
            "0":"id",
            "1":"name",
            "2":"slideOrder"
        }
        return API.call({
            actions: {
                response: {
                    AnncTemplate:{
                        findBy: {
                            get: "AnncTemplate",
                            criteria: {
                                company: getCompanyId()
                            },
                            orderBy: {
                                name: 'DESC'
                            },
                            matching: params.matching || {},
                            includes: TemplateIncludes
                        }
                    }
                }
            }
        });
    },
    fetchTemplateData(idTemplate){

        TemplateIncludes.slides  = {
            0:"id",
            1:"name",
            2:"content",
            3:"isRequired",
            4:"slideOrder"
        };

        return API.call({
            actions: {
                response: {
                    AnncTemplate: {
                        findOneBy: {
                            get: "AnncTemplate",
                            criteria: {
                                company:getCompanyId(),
                                id:idTemplate
                            },
                            includes: TemplateIncludes
                        }
                    }
                }
            }
        })
    },
    addTemplate(params){
        if(params.tags && typeof params.tags === 'object'){
            params.tags = JSON.stringify(Object.assign({},params.tags));
        }
        params.company = getCompanyId();
        const slides = params.slides;
        delete params.slides;
        if(params.id){
            const id = params.id;
            delete params.id;

            let extra = HandleUpdateSlide(slides,{template:id});

            return API.call({
                actions: {
                    create: {
                      ...extra.create
                    },
                    delete: {
                        ...extra.delete
                    },
                    update: {
                        ...extra.update,
                        AnncTemplate: {
                            template1: {
                                findBy: {id:id},
                                updateRecord:params
                            }
                        }
                    },
                    response: {
                        findLastInserted: {
                            template1: TemplateIncludes
                        }
                    }
                }
            })
        }

        let extraSlides = HandleCreateSlide(slides,'template1');

        return API.call({
            actions: {
                create: {
                    AnncTemplate: {
                        template1: params
                    },
                    AnncSlide: {...extraSlides.create.slide}
                },
                connect: {...extraSlides.connections},
                response: {
                    findLastInserted: {
                        template1: TemplateIncludes
                    }
                }
            }
        })
    },
    removeTemplate: (id) => {
        return API.call({
            actions: {
                delete: {
                    AnncTemplate: {
                        template1: {
                            findOneBy: {
                                id: id
                            }
                        }
                    }
                }
            }
        })
    },
    fetchMetricVideo() {
        return API.call({
            actions: {
                response: {
                    MetricVideo: {
                        custom: {
                            functionName: "getMetricVideo",
                            get: "getMetricVideo",
                            excludes: {},
                            includes: {},
                            criteria: {},
                        },
                    },
                },
            },
        });
    },
}

export default API;