import React, {createContext, useReducer} from 'react';
import AppReduce from './Reduce';
import moment from "moment";
import {useSelectedStation} from "../../../components/StationSelector";

const DefaultStore = createContext({});

const settings = [
    {color:"#00A38D", min:0,max:0},
    {color:"#D94C53", min:null, max:1},
    {color:"#DBAE60", min:1, max:null}
]

const AppProvider = ({children}) => {
    let week = moment(new Date(), 'DD-MM-YYYY').week();
    const [selectedStation] = useSelectedStation();

    const useAppState = useReducer(AppReduce,{
        current:{
            data:[],
            week:week,
            isLoading:true,
            sortedBy: [{
                field: 'balanceGroup',
                direction: 0
            }],
            filter:[]
        },
        next:{
            data:[],
            week:week,
            isLoading:true,
            sortedBy: [{
                field: 'balanceGroup',
                direction: 0
            }],
            filter:[]
        },
        rules:settings,
        selectedStation: Array.isArray(selectedStation) ? selectedStation[0] : selectedStation,
    })

    return (
        <DefaultStore.Provider value={useAppState}>
            {children}
        </DefaultStore.Provider>
    )
}

export {AppProvider}

export default DefaultStore
