import React, {useContext, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Button, Input, Spacer, SubTitle} from "@dspworkplace/ui";
import {toast} from "../../../components/Toasts";
import {useAuth} from "../../../Auth";
import SettingsContext from "../context";
import {AccessCheck, IsGranted} from "../../../security";

export default function Information()
{
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const {register, handleSubmit, reset} = useForm();

    useEffect(() => reset(state.company), [state.company]);

    const handleSaveCompanyInfo = ( data, evt ) => {
        let form = evt.target;

        form.querySelector('button').disabled = true;

        api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    Company: {
                        update_company: {
                            findBy: {
                                id: state.company.id
                            },
                            updateRecord: {
                                name: data.name,
                                amazonAbbreviation: data.amazonAbbreviation,
                                domain: data.domain,
                            }
                        }
                    }
                }
            }
        }).then(
            response => toast({
                title: 'Company Information updated',
                type: 'info',
                timeout: 8000,
                useIcon: true,
                useClose: true
            }) && dispatch({
                type: 'ADD',
                payload: {
                    ...state,
                    company: {
                        ...state.company,
                        ...data
                    }
                }
            }),
            error => toast({
                title: 'Error',
                content: error.response ? error.response.data.message : error.message,
                type: 'error',
                timeout: 8000,
                useIcon: true,
                useClose: true
            })
        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    return (
        <form onSubmit={handleSubmit(handleSaveCompanyInfo)}>
            <SubTitle>Company Information</SubTitle>
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='name'
                label='Name'
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='amazonAbbreviation'
                label='Amazon Abbreviation'
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='domain'
                label='Domain'
            />
            <Spacer bottom={5}/>
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}