import {STATUS} from './helper';

export const initialState = (options) => {
    return {
        matching:options.matching || {},
        filterLookups: options.filterLookups || [],
        status:'pending',
        data: [],
        metricVideos: [],
    }
}

export const reducer = (state,action) =>{
    const {payload} = action;
    switch (action.type){
        case 'loading':
            return {...state,status:payload.loading}
        case 'addData':
            state.data = payload.data;
            state.status = STATUS.READY;
            return {...state};
        case 'addTemplate':
            state.data.push(payload);
            return {...state};
        case 'editTemplate':
            const templateUpdateIndex = state.data.findIndex((m)=> m.id === payload.id);
            if(templateUpdateIndex >= 0){
                state.data[templateUpdateIndex] = payload;
            }
        return {...state};
        case 'removeTemplate':
            const templateIndex = state.data.findIndex((m)=> m.id === payload.id);
            if(templateIndex >= 0){
                state.data.splice(templateIndex,1);
            }
            return { ...state };
        case 'addMetricVideo':
            state.metricVideos = payload.data;
            state.status = STATUS.READY;
            return {...state};
        default:
            return {...state};
    }
}