const addFirebaseWorker = async () => {
    if (navigator && "serviceWorker" in navigator) {
        try {
            let registration = await navigator.serviceWorker
                .register("./firebase-messaging-sw.js",{scope:'./chat-firebase'})
            //console.log("Registration successful, scope is");
            //console.log({registration});
        } catch (e) {
            console.log("Service worker registration failed, error:");
            console.log({e});
        }
    } else {
        console.log('Navigator is not found in your browser');
        alert('Your browser does not support service worker');
    }
}

export const checkingNotificationsPermissions = async () => {
    if (Notification) {
        try {
            let response = await Notification.requestPermission();
            if (response !== 'granted') {
                alert('Please enable Notifications in your browser in order to make the app work properly');
                Notification.requestPermission().then((response) => {
                    console.log(`Notification is ${response}`);
                    if (response !== 'granted') {
                        alert('Please enable Notifications in your browser MANUALLY in order to make the app work properly');
                    }
                });
            }
        } catch (e) {
            alert(e.message);
            alert('Please enable Notifications in your browser MANUALLY in order to make the app work properly');
        }
    } else {
        alert('Please enable Notifications in your browser MANUALLY in order to make the app work properly');
    }
}

const registerServiceWorker = () => {
    console.log('registerServiceWorker');
    try {
        if (navigator && navigator.vendor.indexOf('Apple') < 0) {
            Promise.all([checkingNotificationsPermissions(), addFirebaseWorker()]).then();
        }
    } catch (e) {
        alert(`Error Service Worker : ${JSON.stringify(e.message)}`);
    }
};

export default registerServiceWorker;