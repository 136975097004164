import React, {useState} from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import {Channel} from "twilio-chat/lib/channel";
import Messages from "./Messages";
import {LoadingView} from "../components/Loading";
import {MessageOfChannel} from "./types";

const Scroll = styled(ScrollToBottom)`
    height: 500px;

    &.hideInput {
        height: 500px;
    }

    &.showInput {
        height: 330px;
    }
`;

type Props = {
    channel: Channel;
    messages: Array<MessageOfChannel>;
    lastIndex: number;
    dispatch: any;
    previousIndex: number;
    hasPreviousPage: boolean;
    init: boolean;
    showInput: boolean;
};

function ScrollToBottomMessages({
                                    messages,
                                    channel,
                                    lastIndex,
                                    dispatch,
                                    previousIndex,
                                    hasPreviousPage,
                                    init,
                                    showInput
                                }: Props) {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Scroll mode={"bottom"} className={showInput ? 'showInput' : 'hideInput'}>
                {/*{loading && <LoadingView containerHeight={"32px"} imgHeight={"20px"} />}*/}
                {loading && hasPreviousPage && <LoadingView containerHeight={"32px"} imgHeight={"20px"}/>}
                <Messages
                    channel={channel}
                    messages={messages}
                    lastIndex={lastIndex}
                    dispatch={dispatch}
                    previousIndex={previousIndex}
                    hasPreviousPage={hasPreviousPage}
                    init={init}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Scroll>
        </>
    );
}

export default ScrollToBottomMessages;
