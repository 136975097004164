import {Button, Input, Spacer, Title} from "@dspworkplace/ui";
import React, {useEffect,useState} from "react";
import {useForm} from "react-hook-form";
import {getEmail, getFriendlyName, getUserId, setEmail, setFriendlyName, useAuth} from "../../../Auth";
import toast from "../../../components/Toasts/toast";
import { patternEmail } from '../../../patterns';

const App = ({user}) => {
    const {api} = useAuth();
    const {register, handleSubmit, errors, reset} = useForm({ mode: 'onBlur' });

    const [status,setStatus] = useState('loaded');

    useEffect(()=>{
        if(user) reset(user);
    },[user])

    const onSubmit = updateRecord => {
        setStatus('submitting');

        if(status === 'submitting') return false;
        const param =  {
            actions: {
                update: {
                    User: {
                        userUpdate: {
                            findBy: {
                                id: getUserId()
                            },
                            updateRecord
                        }
                    }
                }
            }
        }

        api.post('/api/lazy/manage/data', param,{
            cache: {
                ignoreCache: true
            }
        }).then(
            response => {
                if(updateRecord.friendlyName != getFriendlyName()) setFriendlyName(updateRecord.friendlyName);
                if(updateRecord.email != getEmail()) setEmail(updateRecord.friendlyName);

                toast({
                    type: 'success',
                    title: 'Success',
                    content: 'New data saved with success'
                })
                setStatus('loaded')

            },
            error => {
                toast({
                    type: 'Error',
                    title: 'Error',
                    content: 'There was some error to update the record, try again latter.'
                })
                setStatus('loaded')
            }
        )
    }

    const errorMessages = {
        email: {
            required: 'You need to provide an email',
            pattern: 'Enter a valid email',
            exists: 'Email already exist. Please choose a different email',
        },
    }

    return (
        <Spacer bottom={10} id='user-information'>
            <Title>Edit Information</Title>
            <Spacer bottom={5}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    ref={register({required:'First name is required'})}
                    name='firstName'
                    label='First Name'
                />
                <Spacer top={3}/>
                <Input
                    ref={register({required:'Last name is required'})}
                    name='lastName'
                    label='lastName'
                />
                <Spacer top={3}/>
                <Input
                    ref={register({required:'Nickname is required'})}
                    name='friendlyName'
                    label='Nickname'
                />
                <Spacer top={3}/>
                <Input
                    name='email'
                    label='Email'
                    ref={register({
                        required: true,
                        pattern: patternEmail,
                        validate: {
                            exists: async value => {
                                let res = await api.post('/api/user/check/email/exist', {
                                    "email": value,
                                    "userId": getUserId()
                                });
                                return res.data.status !== true
                            }
                        }
                    })}
                    Required={true}
                    error={errors.email && errorMessages.email[errors.email.type]}
                    valid={!errors.email}
                    autoComplete='off'                    
                />
                <Spacer bottom={5}/>
                <Button type='primary' disabled={status === 'submitting' ? true : false}>{status === 'submitting' ? 'Saving Data...'  : 'Save'}</Button>
            </form>
        </Spacer>
    )
}

export default App;
