import ComboboxRecoil, {
    ComboboxRecoilTypeBase
} from "../../../components/Form/Combobox/combobox.recoil";
import React from "react";
import { VMVendor } from "../../vehicle_maintenance_vendor/state/VehicleMaintenanceVendor";
import { useSelectedStation } from "../../../components/StationSelector";

const VendorCombobox = (props: ComboboxRecoilTypeBase) => {
    const [selectedStation] = useSelectedStation();
    return <ComboboxRecoil {...props} store={VMVendor(selectedStation)} />
}

export default VendorCombobox