import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Alert,
    Button,
    CheckBox,
    CustomDropdown,
    Icon,
    Input,
    RadioBox,
    Spacer, SubTitle,
    Text, Theme,
    ThirdTitle
} from "@dspworkplace/ui";
import moment from 'moment';

import Modal from "../../../../components/Modal";
import {dialogPromise} from "../../../../components/Dialog";
import DatePicker from '../../../../components/Calendar/picker';
import Loading, {LoadingWrapper} from "../../../../components/Loading";
import API from "./api";
import {useLocalStorage} from "../../../../Auth";
import {Label} from "../../../../components/UI";
import {SlideList} from "../Slides";
import Tooltip from "../../../../components/Tooltip";
import {useSelectedStation} from "../../../../components/StationSelector";
import toast from "../../../../components/Toasts/toast";
import Parser from "html-react-parser";

const FREQUENCY_OPTIONS = ['Once','Weekly','Monthly'];

let months_day = [];
for(let i=1;i<=31;++i){
    const iString = i.toString();
    months_day.push({name:iString,value:iString});
}

const MessageForm = ({
   success = ()=>{},
   cancel = ()=>{},
   idMessage,
   metricVideo = []
}) => {
    const [selectedStation] = useSelectedStation();
    const [state,setState] = useState({status:'pending',segments:[],slides:[],reference:false});
    const {register,handleSubmit,watch,errors,reset} = useForm({defaultValues:{
            frequency: {
                type: 'Once'
            },
            propagate: "true"
        }});

    const [stations]  = useLocalStorage('stations', []);
    const stationCode = stations.filter((s)=> s.id == selectedStation)

    const watchedFrequency = watch("frequency.type");

    useEffect(()=>{
        API.fetchMessagesData(idMessage).then(
            response => {
                const segments = response.data.data.AnncSegment.map((s)=>({name:`${s.name} (quantity: ${s.employeesQuantity})`,value:s.id}));

                let slides = [];
                let ref = null;
                if(idMessage){
                    const message = response.data.data.message;
                    let frequency = {
                        type: message.frequency.type
                    }
                    frequency[frequency.type.toLowerCase()] = message.frequency[frequency.type.toLowerCase()];
                    if(frequency.once){
                        frequency.once = moment(frequency.once).format('YYYY-MM-DD')
                    }

                    reset({
                        segments:message.segments.reduce((acc,s)=> {acc.push(s.id); return acc},[]),
                        title:message.title,
                        dateExecution: message.dateExecution ? moment.utc(message.dateExecution.timestamp,"X").format('YYYY-MM-DD') : null,
                        frequency: frequency
                    })
                    const templates = message.templates.map((t,k)=>({template:t.id,name:`Template: ${t.template.name} (${t.template.slidesQuantity} slides)`,slideOrder:t.slideOrder}))
                    slides =  [...message.slides,...templates];
                    if(message.reference && message.reference != ""){
                        ref = message.reference;
                    }
                }
                setState({...state,status:'ready',segments,slides,reference:ref})
            },
            error => {
                setState({...state,status:'error'});
            }
        );
    },[idMessage])

    const onSubmit = (data) => {
        setState({...state,status:'submitting'});
        data.status = 'Active';

        if(idMessage){
            data.id = idMessage;
            if(data.propagate == "true"){
                data.queueStatus = 'Running';
            }else{
                data.queueStatus = 'RemovingReference';
            }
            delete data.propagate;
        }

        API.addMessage(data).then(
            response => {
                let message = response.data.data.lastInserted;
                let toastTitle = "Message successfully updated! Please allow up to 10 minutes while the system it is updating the other messages.";
                if(!idMessage){
                    message.frequency = JSON.parse(data.frequency);
                    toastTitle = "Message successfully created! Please allow up to 10 minutes while the system it is creating the other messages.";
                }
               success(message);

                if(message.frequency.type != 'Once'){
                    if(!idMessage || (message && message.reference)){
                        toast({
                            type: 'success',
                            title: toastTitle,
                            useClose:true,
                            timeout: false
                        });
                    }

                }

            },
            error => {
                setState({...state,status:'createMessageError'});
            }
        );
    }

    return (
        <Modal
            width={'340px'}
            visible={true}
            title={idMessage ? 'Edit Message': 'New Message'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === 'pending' ?
                <LoadingWrapper>
                    <Loading style={{height: 80}}/>
                </LoadingWrapper>
            :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type='hidden'
                        name={'station'}
                        label={'Station'}
                        ref={register}
                        disabled={true}
                        defaultValue={selectedStation}
                    />
                    <Input
                        type='text'
                        disabled={true}
                        defaultValue={stationCode[0].code}
                    />

                    <Spacer top={5} />
                    <Input
                        type={'text'}
                        name={'title'}
                        label={'Title'}
                        ref={register({required:'Required field'})}
                        error={errors.title && errors.title.message}
                        Required
                    />
                    <Spacer top={3}>
                        <Spacer top={3}>
                            <SlideList
                                data={state.slides}
                                ref={register}
                                name={'slides'}
                                includeTemplate={true}
                                metricVideo={metricVideo}
                            />
                        </Spacer>
                    </Spacer>
                    {!idMessage && <Spacer top={5}>
                        <Tooltip
                            verticalPosition={'center'}
                            horizontalPosition={'right'}
                            content={<Text>Write a help here</Text>}
                        >
                            <ThirdTitle>Frequency <span style={{fontSize:12,color:'#969696',fontWeight:400}}>(Hover to know how it works)</span></ThirdTitle>
                        </Tooltip>
                        <Spacer bottom={2}/>
                        <RadioBox
                            name='frequency.type'
                            ref={register}
                            options={FREQUENCY_OPTIONS.map((f)=>({value:f,label:f}))}
                            default={watchedFrequency}
                            Required
                        />
                    </Spacer>}
                    {!idMessage && watchedFrequency === 'Once' &&
                        <Spacer top={3}>
                            <DatePicker
                                name={'frequency.once'}
                                label={'Date'}
                                ref={register({required:'Field Required'})}
                                error={errors.frequency && errors.frequency.once && errors.frequency.once.message}
                                disableDates={{before: new Date()}}
                                Required
                            />
                        </Spacer>
                    }
                    {!idMessage &&  watchedFrequency === 'Weekly' &&
                        <Spacer top={3}>
                            <Spacer style={{display:'flex',alignItems: 'center'}}>
                                <Label>Every</Label>
                                <Spacer inline right={2}/>
                                <Input
                                    name={'frequency.weekly.interval'}
                                    ref={register({required:'Required field'})}
                                    size='extraSmall'
                                    error={errors.frequency &&  errors.frequency.weekly && errors.frequency.weekly.interval && errors.frequency.weekly.interval.message}
                                    Required
                                />
                                <Spacer inline right={2}/>
                                <Label>Week{watch('frequency.weekly.interval') === '1' ? '' : 's'}</Label>
                            </Spacer>
                            <Spacer top={3} />
                            <Label>Week Days</Label>
                            <Spacer bottom={2}/>
                            <CheckBox
                                name={'frequency.weekly.days'}
                                ref={register({required:'Required field'})}
                                options={[
                                    {value:'1',label:'Monday'},
                                    {value:'2',label:'Tuesday'},
                                    {value:'3',label:'Wednesday'},
                                    {value:'4',label:'Thursday'},
                                    {value:'5',label:'Friday'},
                                    {value:'6',label:'Saturday'},
                                    {value:'0',label:'Sunday'}
                                ]}
                                error={errors.frequency && errors.frequency.weekly && errors.frequency.weekly.days && errors.frequency.weekly.days.message}
                                Required
                            />
                        </Spacer>
                    }
                    {!idMessage &&  watchedFrequency === 'Monthly' &&
                        <Spacer top={3}>
                            <CustomDropdown
                                name={'frequency.monthly.days'}
                                ref={register({required:'Required field'})}
                                label={'Days'}
                                options={months_day}
                                multiple={true}
                                visibleOptionsQty={4}
                                error={errors.frequency && errors.frequency.monthly && errors.frequency.monthly.days && errors.frequency.monthly.days.message}
                                Required
                            />
                        </Spacer>
                    }
                    {!idMessage && watchedFrequency != 'Once' &&
                        <>
                            <Spacer top={3}>
                                <DatePicker
                                    name={'dateReference'}
                                    label={'Start Date'}
                                    ref={register({required:'Field required'})}
                                    error={errors.dateReference && errors.dateReference.message}
                                    disableDates={{before: new Date()}}
                                    required
                                />
                            </Spacer>
                            <Spacer top={3}>
                                <DatePicker
                                    name={'dateEnd'}
                                    label={'End Date'}
                                    ref={register({required:'Field required'})}
                                    error={errors.dateEnd && errors.dateEnd.message}
                                    disableDates={{before: new Date()}}
                                    required
                                />
                            </Spacer>
                        </>
                    }

                    {idMessage &&
                        <Spacer top={3}>
                            <DatePicker
                                name={'dateExecution'}
                                label={'Execution Date'}
                                ref={register({required:'Field required'})}
                                error={errors.dateExecution && errors.dateExecution.message}
                                disableDates={{before: new Date()}}
                                required
                            />
                        </Spacer>
                    }

                    {state.status === 'error' &&
                        <Spacer top={5}>
                            <Alert.Error
                                title={'Unable to fetch data'}
                                content={'Try to refresh the page, if the problem persist please contact support.'} />
                        </Spacer>
                    }
                    {state.status === 'createMessageError' &&
                        <Spacer top={5}>
                            <Alert.Error
                                title={`Unable to ${idMessage ? 'update' : 'create'} the message`}
                                content={'Try to refresh the page, if the problem persist please contact support.'} />
                        </Spacer>
                    }
                    <Spacer top={5} style={{display:'flex',justifyContent:idMessage && watchedFrequency != 'Once' && state.reference  ? 'space-around' : 'flex-end',alignItems: 'center'}}>
                        {idMessage && watchedFrequency != 'Once' && state.reference &&
                            <CheckBox
                                name='propagate'
                                ref={register}
                                options={[
                                    {value:'true',label:'Save changes to all future, schedule messages?'},
                                ]}
                                defaultValues={['true']}
                            />
                        }
                        <Button
                            type={'primary'}
                            Type={'submit'}
                            disabled={state.status === 'submitting'}
                        >
                            {state.status === 'submitting' ? (idMessage ? 'Updating...' : 'Creating...') : (!idMessage ? 'Create' : 'Update')}
                        </Button>
                    </Spacer>
                </form>
            }
        </Modal>
    )
};

const openMessageForm = (idMessage,metricVideo = []) => {
    return dialogPromise((success, cancel)=>{
        return <MessageForm
            success={success}
            cancel={cancel}
            idMessage={idMessage}
            metricVideo={metricVideo}
        />
    })
}


const DeleteModal = ({title, text, icon, success, cancel}) => {
    const {register,watch} = useForm({defaultValues:{
            propagate: "true"
        }});

    const propagate = watch("propagate");

    return (
        <Modal
            width='340px'
            removeHeader={true}
            visible={true}
            setVisible={cancel}
            closeOnOverlay={false}
        >
            <div style={{textAlign: 'center'}}>
                {icon && <Spacer bottom={5} top={3}><Icon.Warning size='60px' color={Theme.colors.warnings.text}/></Spacer>}
                {title && <Spacer bottom={3}><SubTitle>{title}</SubTitle></Spacer>}
                <Text>{Parser(text)}</Text>
                <Spacer bottom={5}/>
                <CheckBox
                    name='propagate'
                    options={[
                        {value:'true',label:'Delete to all future, schedule messages?'},
                    ]}
                    ref={register}
                />
                <Spacer bottom={5}/>
                <Button onClick={cancel} type='cancel'>Cancel</Button>
                <Spacer right={5} inline/>
                <Button onClick={()=>{success({propagate})}} type='primary'>Confirm</Button>
            </div>
        </Modal>
    )
};

const confirmDelete = ({icon,text}) => {
    return dialogPromise((success, cancel)=>{
        return <DeleteModal
            success={success}
            cancel={cancel}
            icon={icon}
            text={text}
            title={null}
        />
    })
};
const openMessageArchiveForm = async (title) => {
    return confirmDelete({
        icon: true,
        text: `Do you really want to archive the message ${title}?`
    });
};
export {openMessageForm,openMessageArchiveForm,MessageForm}