import apiFactory from '../../../api/factory';

type VehicleChangeStatusInterface = {
    vehicle_id: number,
    status: string
}

const {call} = apiFactory();
const resource = 'vehicle'
export const VehicleChangeStatus = (props: VehicleChangeStatusInterface) => {
    const {vehicle_id,status} = props
    return call({status:status},`api/v2/${resource}/${vehicle_id}/changeStatus`,'put')
}