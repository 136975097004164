import React from 'react';
import {Content, Menu, Navigator} from "../../components/Menus/Navigator";
import {Spacer, SubTitle, Theme, Icon} from "@dspworkplace/ui";
import {NavLink, Route, Switch} from "react-router-dom";
import {ROUTES} from "../../Route_oold";

const IconsSolver = {
    Messages: <Icon.Megaphone size={'20px'} />,
    Templates: <Icon.Document size={'20px'} />,
    Segments: <Icon.Segment size={'20px'} />
}

const App = (routeProps) => {
    const {history, match} = routeProps;
    const {url, isExact} = match;

    const routes = ROUTES.find(r => r.name === 'Company Management').routes.find(r => r.name === 'Announcements').routes;
    if (isExact){
        history.replace(url + routes[0].path);
    }

    return(
        <div style={{display: 'flex', position: 'relative'}}>
            <Navigator className='scrollable'>
                <SubTitle>Announcements</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                <Menu>
                    {routes.map((r, k) =>
                        <li key={k}>
                            <NavLink to={`${url}${r.path}`}>{IconsSolver[r.name]} {r.name}</NavLink>
                        </li>
                    )}
                </Menu>
            </Navigator>
            <Content className='scrollable' style={{position: 'relative'}}>
                <Switch>
                    {routes.map(({path, feature, component}, k) =>
                        <Route
                            path={`${url}${path}`}
                            key={k}
                            render={props => React.createElement(component, props)}
                        />
                    )}
                </Switch>
            </Content>
        </div>
    )
}

export default App;