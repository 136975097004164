import React from "react";
import { Grid } from "../../../index";
import Schedule from "./Schedule";

const Week = ({ arrItems, week, stationTimeZone }) => {
    var weekName = week + "th";
    if (week === 1) {
        weekName = week + "st";
    }
    if (week === 2) {
        weekName = week + "nd";
    }
    if (week === 3) {
        weekName = week + "rd";
    }

    return (
        <Grid templateColumns="70px auto" borderBottom="1px solid #CBD6E2" templateRows="auto" height="100%">
            <Grid borderRight="1px dashed #CBD6E2" justifyContent="center" height="100%">
                {weekName}
            </Grid>
            <Grid templateColumns="auto" height="100%">
                {arrItems && arrItems.length > 0
                    ? arrItems.map((item, index) => {
                          return <Schedule key={week + index + 1} elem={item} week={week} row={index} stationTimeZone={stationTimeZone} />;
                      })
                    : "Empty week"}
            </Grid>
        </Grid>
    );
};

export default React.memo(Week);
