import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Button,
    Spacer,
    CustomDropdown,
    CheckBox,
    Password,
    Alert
} from "@dspworkplace/ui";
import { validatePassword } from "../../patterns";
import { useForm } from "react-hook-form";

import Loading, { useLoading } from "../../components/Loading";
import Modal from "../../components/Modal";
import {useAuth} from "../../Auth";
import { dialog } from "../../components/Dialog";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;
`;

const LoadingWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    > img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const App = ({ selectedStation, allEmployees, changePasswordVisible, toggleChangePassword }) => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, getValues } = useForm({ defaultValues: {} });
    const [isLoading, setLoading] = useLoading(false);
    const [roles, setRoles] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [employeePlaceholder, setEmployeePlaceholder] = useState('All Employees');
    const { password } = getValues();
    const [roleArray, setRoleArray] = useState([]);
    const [message, setMessage] = useState([]);

    const onSubmit = async data => {
        if (data.employee.length == 0) {
            dialog({
                text: "You have selected to send an invite to ALL Employees in the station. This will reset their password. Please confirm you would like to do this.",
                buttons: [
                    {
                        type: "primary",
                        text: "Yes",
                    },
                    {
                        text: "Cancel",
                        callback: () => { 
                            return false;
                        }
                    },
                ],
            }).then( async (action) => {
                if (!action) {
                    toggleChangePassword();
                } else {
                    setLoading(true);
                    const params = {
                        actions: {
                            response: {
                                User: {
                                    custom: {
                                        functionName: "changeUserPassword",
                                        get: "changeUserPassword",
                                        excludes: [],
                                        criteria: {
                                            companyId: localStorage.getItem("company"),
                                            role: data.pemission,
                                            userId: data.employee,
                                            stationId: [selectedStation],
                                            force_password_reset: data.force_password_reset,
                                            terminateAllSession: data.terminateAllSession,
                                            password: data.password,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    
                    const response = await api.post('/api/lazy/manage/data', params);
                    setLoading(false);
                    if (response.data.data.changeUserPassword.success === false) {
                        setMessage(response.data.data.changeUserPassword.userNotSendArr);
                    } else {
                        toggleChangePassword();
                    }
                }
            });
        } else {
            setLoading(true);
            const params = {
                actions: {
                    response: {
                        User: {
                            custom: {
                                functionName: "changeUserPassword",
                                get: "changeUserPassword",
                                excludes: [],
                                criteria: {
                                    companyId: localStorage.getItem("company"),
                                    role: data.pemission,
                                    userId: data.employee,
                                    stationId: [selectedStation],
                                    force_password_reset: data.force_password_reset,
                                    terminateAllSession: data.terminateAllSession,
                                    password: data.password,
                                },
                            },
                        },
                    },
                },
            };
            
            const response = await api.post('/api/lazy/manage/data', params);
            setLoading(false);
            if (response.data.data.changeUserPassword.success === false) {
                setMessage(response.data.data.changeUserPassword.userNotSendArr);
            } else {
                toggleChangePassword();
            }
        }
    };

    const getRoles = async() =>{
        const params = {
            "actions": {
                "response": {
                    "Role": {
                        "findBy": {
                            "get": "roles",
                            "criteria": {
                                "company": localStorage.getItem('company')
                            },
                            "includes": [
                                "id", "roleName", "name"
                            ],
                            "excludes": [
                                "company", "users", "incidentPhases"
                            ]
                        }
                    },
                }
            }
        };
        const response = await api.post('/api/lazy/manage/data', params);
        setRoles(response.data.data.roles);
    }

    const getRolesPermissionLevel = async() =>{
        const params = {
            "actions": {
                "response": {
                    "Role": {
                        "custom": {
                            "functionName": "getRolePermissionLevel",
                            "get": "roles",
                            "criteria": {},
                        },
                    },
                },
            },
        };
        const response = await api.post('/api/lazy/manage/data', params);
        setRoles(response.data.data.roles.roleObject);
        setRoleArray(response.data.data.roles.roleArray);
    }

    const permissionChange = (data) => {
        let selectedPermission = [];
        if(data.length > 0){
            selectedPermission = roles.filter((r) => data.includes(r.id)).map((r) => { return r.roleName });
            setEmployeePlaceholder('All '+selectedPermission.toString());
        }else{
            setEmployeePlaceholder('All Employees');
        }
        resetEmployee(); 

        if (selectedPermission.length == 0) { 
            selectedPermission = roles.map((role) => role.roleName);
        }

        setEmployee(allEmployees.data.filter((e)=>{
            if(e.employeeStatus == 2 && (e.roles && e.roles[0] && selectedPermission.includes(e.roles[0]) || selectedPermission.length == 0)){
                return true;
            }else{
                return false;
            }
        }));
    }

    useEffect(()=>{
        getRolesPermissionLevel();
    },[]);
    useEffect(()=>{
        setEmployee(allEmployees.data.filter((e)=>{ 
            if(e.employeeStatus == 2 && roleArray.includes(e.roleName)){
                return true;
            }else{
                return false;
            }
        }));
    },[allEmployees.data,roleArray]);

    const errorMessages = {
        password: {
            required: "You need to provide a password to continue.",
            pattern: "You password must respect all rules",
        },
    };

    let resetEmployee = () => { };

    return (
        <Modal
            title={"Change Password"}
            width="340px"
            visible={changePasswordVisible}
            setVisible={() => {
                toggleChangePassword();
            }}
            zindex="40"
        >
            <Container>
                {isLoading && (
                    <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                )}
                <form name='change_password' onSubmit={handleSubmit(onSubmit)}>
                    <CustomDropdown
                        ref={register}
                        name='pemission'
                        placeholder='All'
                        label='Permission Level'
                        options={roles.map((r) => {
                            return { 'name': r.roleName, 'value': r.id };
                        })}
                        multiple={true}
                        onChange={permissionChange}
                        />
                    <Spacer top={4} />
                    <CustomDropdown
                        ref={register}
                        placeholder={employeePlaceholder}
                        name='employee'
                        label='Employee'
                        resetCallback={(fn) => { resetEmployee = fn }}
                        options={employee.map((e)=>{
                            return {'name':e.name,'value':e.userId};
                        })}
                        multiple={true}
                    />
                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} >
                        <Password
                            name="password"
                            label="New Password"
                            help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    if (value) {
                                        return validatePassword(value, '', []);
                                    }
                                }
                            })}
                            error={
                                errors.password &&
                                errors.password.message
                            }
                            valid={!errors.password && password && password.length > 0}
                        />
                    </SpacerInline>
                    <Spacer top={3}>
                        <CheckBox
                            name='force_password_reset'
                            ref={register}
                            style={{float:'right'}}
                            options={[{label:'Force Password Reset',value:true}]}
                            defaultValues={[true]}
                        />
                    </Spacer>
                    <Spacer top={3}>
                        <CheckBox
                            name='terminateAllSession'
                            ref={register}
                            style={{float:'right'}}
                            options={[{label:'Terminate all active sessions?',value:true}]}
                            defaultValues={false}
                        />
                    </Spacer>
                    {message && message.length > 0 &&
                        <Alert.Error
                            useIcon={true}
                            useClose={false}
                            content={<>
                                <div style={{ "font-size": "13px", "padding-bottom": "3px" }}>
                                    {'The change password of below user fail because the generated password cannot contain any part of the username.'}
                                    <br />
                                    {message.map((m) => {
                                        return <><span>{ m }</span><br /></> 
                                    })}
                                </div>
                            </>}
                        />
                    }
                    <Spacer top={4} />
                    <Spacer style={{ float: 'right' }}>
                        <Button type='primary' onClick={handleSubmit(onSubmit)}>Change Password</Button>
                    </Spacer>
                </form>
            </Container>
        </Modal>
    );
};

export default App;
