const SettingsReducer = (state, action) => {
    const {type, payload} = action;
    
    switch (type) {
        case 'ADD':
            if (payload.company) {
                let company = payload.company;
                localStorage.setItem('showMobileReleaseShift', JSON.stringify(!!company.showMobileReleaseShift));
                localStorage.setItem('showMobileSwapShift', JSON.stringify(!!company.showMobileSwapShift));
            }
            return {
                ...state,
                ...payload
            };
        case 'ADD_ROUTE_ASSIST_SETTINGS':
            return {...state,...payload};
        case 'ADD_RULE':
            state.gradingRules.push(payload);
            return {...state};

        case 'UPDATE_RULE':
            state.gradingRules[payload.index] = payload.item;
            return {...state};
        case 'DELETE_RULE':
            state.gradingRules.splice(payload.index, 1);
            return {...state};
        case 'UPDATE_COMPANY':
            return {...state, company: payload.company}
        case 'ADD_INCIDENT_SETTING':
            state.incidentSetting.push(payload);
            return {...state}
        case 'UPDATE_INCIDENT_SETTING':
            state.incidentSetting.forEach((incident, index) => {
                if (incident.id === payload.data.id) {
                    state.incidentSetting[index] = payload.data;
                }
            });
            return {...state}
        case 'DELETE_INCIDENT_SETTING':
            state.incidentSetting.forEach((incident, index) => {
                if (incident.id === payload.data.id) {
                    state.incidentSetting.splice(index, 1);
                }
            });
            return {...state}
        case 'SORTING_INCIDENT_SETTING':
            state.incidentSetting.sort((a, b) => (a['incidentTypeName']+a['category']).toString().localeCompare((b['incidentTypeName']+b['category']).toString(), undefined, { numeric: true }));
            return {...state}
        case 'UPDATE_ATTENDENCE_RULE':
            return { ...state, attendanceRules: payload }
        case 'UPDATE_ATTENDENCE_SETTING':
            return { ...state, attendanceRules: payload }
        case 'UPDATE_ADP':
            let company = state.company;
            company.isAdp = payload.isAdp;
            return { ...state, company: company }
        case 'UPDATE_COMPNY_SETTING':
            return { ...state, companySetting: payload }
        default:
            return state;
    }
};

export default SettingsReducer;