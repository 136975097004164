import React, {useCallback, useEffect, useRef} from 'react';
import {Channel} from "twilio-chat/lib/channel";
import ChannelHeader from "./ChannelHeader";
import {useTrackedState as useBotState, useUpdate as useBotDispatch} from "./BotStore";
import styled from "styled-components";
import Loading from "./Loading";
import BotTaskList from "./BotTaskList";
import BotTaskInteraction from "./BotTaskInteraction";
import BotTasksSummary from "./BotTasksSummary";

const ChannelChatContainer = styled.div`
    display: grid;
    width: 340px;
    height: calc(8px + 56px + 165px + 330px);
    box-shadow: 0 3px 6px #00000029;
    background-color: white;
    min-height: calc(8px + 56px + 165px + 330px);
    align-content: flex-end;
`;

type Props = {
    channel: Channel;
}

function BotTasks(props: Props) {
    const {channel} = props;
    const mounted = useRef(false);
    const dispatch = useBotDispatch();
    const state = useBotState();
    const {loading, isReviewing, showSummaryModal} = state;

    const goBackHandler = useCallback(
        () => {
            dispatch({type: "GO_BACK"});
        },
        [dispatch],
    );

    useEffect(() => {
        if (mounted.current) return;
        if (channel && channel.friendlyName.includes('workplace_bot')) {
            mounted.current = true;
            dispatch({
                type: "SET_UP",
                channel: channel,
                loading: false,
            });
        } else {
            mounted.current = true;
            dispatch({
                type: "SET_UP",
                channel: channel,
                loading: false,
            });
        }
    }, [channel, dispatch]);

    if (loading) {
        return <Loading containerHeight={'calc(8px + 56px + 165px + 330px)'}/>
    }

    return (
        <>
            <ChannelChatContainer data-id={"container-" + channel.sid} data-sid={channel.sid}>
                <ChannelHeader channel={channel} channelDispatch={dispatch} goBack={isReviewing}
                               goBackHandler={goBackHandler} kind={'TASK'}/>
                <BotTaskList visibility={isReviewing ? "hidden" : "visible"}/>
                {isReviewing && <BotTaskInteraction/>}
            </ChannelChatContainer>
            {showSummaryModal && <BotTasksSummary/>}
        </>
    );
}

export default BotTasks;