import React, { useCallback, useEffect, useState } from 'react'
import {
    engine,
    getChatCompanyId,
    getDriverId,
    getFriendlyName,
    getMainStationId,
    getChatUserId,
    showErrorToast,
    showToast,
} from "../../Utilities";
import { useLoading } from '../../Chat/Loading';
import { useTrackedState, useUpdate as useBotDispatch } from './Store';
import styled from "styled-components";
import { Button, CheckBox } from "@dspworkplace/ui";
import { useForm } from 'react-hook-form';
import BotMessage from './BotMessage';
import BotInput from './BotInput';
import { ButtonContainer, Container, ScrollContainer, Text } from './BotTaskList';
import { DriverButton } from './DriverButton';
import moment from 'moment';
import { deleteTwilioMessage } from '../utils';

function BotReleaseShift({ taskInformation, channel }) {
    const state = useTrackedState();
    const { message } = state;
    const dispatch = useBotDispatch();
    let managerFriendlyName = getFriendlyName();
    const [systemMsg, setSystemMsg] = useState(message);
    const { loading, setLoading } = useLoading();
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [task, setTask] = useState();
    const [disabled, setDisabled] = useState(true);
    const [sendMessage, setSendMessage] = useState(false);
    const [caseTask, setCaseTask] = useState("");
    const [replyTheMessage, setReplyTheMessage] = useState(false);
    const [messageToReply, setMessageToReply] = useState("");
    const [drivers, setDrivers] = useState([]);
    const [taskLoading, setTaskLoading] = useState(false);
    const [stepOneOptions, setStepOneOptions] = useState(true);
    const [showButtons, setShowButtons] = useState(true);
    const [stepTwoOptions, setStepTwoOptions] = useState(false);
    const [stepThreeOptions, setStepThreeOptions] = useState(false);
    const [shiftNameInformation, setShiftNameInformation] = useState();
    const [reasonForRelease, setReasonForRelease] = useState();
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [placeholder, setPlaceholder] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState("Finish");
    const [disableButton, setDisableButton] = useState(true);
    const [approvedMessage, setApprovedMessage] = useState("");
    const [rejectedMessage, setRejectedMessage] = useState("");
    const [withSelectedDriver, setWithSelectedDriver] = useState(false);
    const [showOptionsForShift, setShowOptionsForShift] = useState(false);
    const [optionForShift, setOptionForShift] = useState("");

    useEffect(() => {
        const abortController = new AbortController();
        const fetchTask = async () => {
            try {
                let response = await engine()
                    .post("/api/fetch_task", {
                        task: {
                            id: taskInformation.id,
                            type: taskInformation.type,
                        },
                        datetime: moment().toISOString(),
                        scenario: 'RELEASE_SHIFT',
                        companyId: getChatCompanyId(),
                        stationId: getMainStationId(),
                        userId: getChatUserId(),
                        driverId: getDriverId(),
                        attributes: message.attributes,
                        messageSid: message.id,
                        channelSid: channel.id,
                        taskInformation,
                    });
                setSystemMsg({ user: message.user, date: message.date, text: message.text });
                let data = response.data;
                setTask(data.task);
                setDrivers(data.drivers);
                setShiftNameInformation(data.shiftNameInformation);
                setReasonForRelease(data.reasonForRelease);
                setSelectedDrivers(data.selectedDrivers);
                setLoading(false);
            } catch (e) {
                console.error(e);
                console.error({ fetchTaskReleaseShift: e });
                console.error(e.message);
                if (e.response && e.response.data && typeof e.response.data.message === 'string') {
                    console.error(JSON.stringify(e.response.data.message === 'Task is archived.'));
                    console.error(e.response.data.message);
                    if (e.response.data.message === 'Task is archived.') {
                        await deleteTwilioMessage(channel.id, message.id, message.attributes);
                    }
                    await showToast({
                        type: 'Error',
                        title: 'Error API Task',
                        content: e.response.data.message + JSON.stringify(e.response.data.message === 'Task is archived.'),
                    });
                }
                if (typeof e.message === "string") {
                    if (e.message === 'Task is archived.') {
                        await deleteTwilioMessage(channel.id, message.id, message.attributes);
                    }
                    await showToast({
                        type: 'Error',
                        title: 'Error in Task',
                        content: e.message + JSON.stringify(e.message === 'Task is archived.'),
                    });
                }
                await showToast({
                    type: 'Error',
                    title: 'Error in Task',
                    content: 'Error in fetching the task information.',
                });
            }

        }
        fetchTask().then();
        return () => {
            abortController.abort();
        };
    }, [message.created_at, message.text, message.user, setLoading, taskInformation.id, taskInformation.type]);


    const acceptTask = useCallback(
        () => {
            setCaseTask("ACCEPT");
            setApprovedMessage(`${managerFriendlyName} has approved Drop Shift Request for "${shiftNameInformation}"`);
            setShowOptionsForShift(true);
        },
        [managerFriendlyName, shiftNameInformation],
    );

    const acceptTaskWithDriver = useCallback(
        () => {
            setCaseTask("ACCEPT");
            setApprovedMessage(`${managerFriendlyName} has approved Drop Shift Request for "${shiftNameInformation}"`);
            setWithSelectedDriver(true)
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, shiftNameInformation],
    );

    const rejectTask = useCallback(
        () => {
            setCaseTask("REJECT");
            setRejectedMessage(`${managerFriendlyName} has rejected Drop Shift Request for "${shiftNameInformation}"`);
            setDisabled(false);
            setDisableButton(false);
        },
        [managerFriendlyName, shiftNameInformation],
    );

    const replyMessage = useCallback(
        () => {
            setReplyTheMessage(true);
            setDisabled(false);
            setStepTwoOptions(false);
            setStepThreeOptions(true);
        },
        [],
    );

    const reset = useCallback(
        () => {
            setReplyTheMessage(false);
            setCaseTask("");
            setSendMessage(false);
            setDisabled(true);
            setMessageToReply("");
            setTaskLoading(false);
            setTaskCompleted(false);
            setStepOneOptions(true);
            setStepTwoOptions(false);
            setStepThreeOptions(false);
            setDisabled(true);
            setDisableButton(true);
            setWithSelectedDriver(false);
            setShowOptionsForShift(false);
            setOptionForShift("");
        },
        [],
    );

    const moveToOpenSift = useCallback(
        () => {
            setOptionForShift('OPEN_SHIFT');
            setShowOptionsForShift(false);
            setDisabled(false);
            setDisableButton(false);
        },
        [],
    );

    const archiveShift = useCallback(
        () => {
            setOptionForShift('ARCHIVE_SHIFT');
            setShowOptionsForShift(false);
            setDisabled(false);
            setDisableButton(false);
        },
        [],
    );

    const completeTask = async (msg) => {
        try {
            setStepThreeOptions(false);
            setTaskLoading(true);
            setDisabled(true);
            setDisableButton(true);
            let params = {
                task: taskInformation,
                case: caseTask,
                option: optionForShift,
                datetime: moment().toISOString(),
                stationId: getMainStationId(),
                userId: getChatUserId(),
                driverId: getDriverId(),
                message: caseTask === 'ACCEPT' ? `${approvedMessage}` : `${rejectedMessage}`,
                extraMessage: typeof msg === 'string' ? msg : '',
                withSelectedDriver: withSelectedDriver,
                twilioMessage: {
                    sid: message.id,
                    attributes: message.attributes,
                    channelSid: channel.id,
                    body: message.text,
                }
            };
            // alert(JSON.stringify(params));
            await engine({ hideDefaultMessage: true }).post('/api/complete_task', params);
            setTaskCompleted(true);
            setStepTwoOptions(false);
            // dispatch({type: "MESSAGE_REMOVED", sid: message.sid});
        } catch (e) {
            reset();
            await showErrorToast(e, 'Error in fetching task of Releasing Shifts.');
        }
    }

    const appendMessage = async (message) => {
        if (message) {
            setMessageToReply(message);
            setDisabled(false);
        }
        await completeTask(message);
    }

    return (
        <>
            <ScrollContainer>
                <BotMessage currentMessage={{
                    ...message,
                    text: systemMsg.text + `\nReason for Drop Shift Request: ${reasonForRelease}`,
                }} />
                <Container className={"btn"}>
                    {drivers.map(item => <DriverButton driver={item} key={item.id} />)}
                </Container>
                {Array.isArray(selectedDrivers) && selectedDrivers.length > 0 &&
                    <>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `The suggested driver for pick up this shift release shift.`,
                        }} />
                        <Container className={"btn"}>
                            {selectedDrivers.map(item => <DriverButton driver={item} key={item.id} />)}
                        </Container>
                    </>
                }
                {showButtons && selectedDrivers.length === 0 &&
                    <Container className={"btn"}>
                        <ButtonContainer onClick={acceptTask}>Accept</ButtonContainer>
                        <ButtonContainer onClick={rejectTask}>Reject</ButtonContainer>
                    </Container>
                }
                {showButtons && selectedDrivers.length > 0 &&
                    <Container className={"btn"}>
                        <ButtonContainer onClick={acceptTask}>Accept</ButtonContainer>
                        <ButtonContainer onClick={acceptTaskWithDriver}>Accept With Driver</ButtonContainer>
                        <ButtonContainer onClick={rejectTask}>Reject</ButtonContainer>
                    </Container>
                }
                {showOptionsForShift &&
                    <>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "Please choose an option for the Drop Shift Request",
                        }} />
                        <Container className={"btn"}>
                            <ButtonContainer size="small" onClick={reset}>{"Back"}</ButtonContainer>
                            <ButtonContainer onClick={moveToOpenSift}>Move to Open Shift</ButtonContainer>
                            <ButtonContainer onClick={archiveShift}>Archive Shift</ButtonContainer>
                        </Container>
                    </>
                }
                {optionForShift === 'ARCHIVE_SHIFT' && caseTask === 'ACCEPT' && !withSelectedDriver &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer size="small" onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "The shift will be archived.",
                        }} />
                    </>
                }
                {optionForShift === 'OPEN_SHIFT' && caseTask === 'ACCEPT' && !withSelectedDriver &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer size="small" onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "The shift will be moved to Open Shifts section..",
                        }} />
                    </>
                }
                {optionForShift.length > 0 && caseTask === 'ACCEPT' && !withSelectedDriver &&
                    <>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `The approved driver ${drivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }} />

                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "To complete the approval, please add any additional text to the message box below and press Finish Task.",
                        }} />
                    </>
                }
                {optionForShift.length > 0 && caseTask === 'ACCEPT' && withSelectedDriver &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer size="small" onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `The approved driver ${drivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }} />
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: `The approved driver ${selectedDrivers[0].fullName} will receive this message: ` + approvedMessage + "\n" + messageToReply,
                        }} />
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "To complete the approval, please add any additional text to the message box below and press Finish Task.",
                        }} />
                    </>
                }
                {caseTask === 'REJECT' &&
                    <>
                        <Container className={"btn"}>
                            <ButtonContainer size="small" onClick={reset}>{"Back"}</ButtonContainer>
                        </Container>
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: 'The rejected driver will receive this message: ' + rejectedMessage + "\n" + messageToReply,
                        }} />
                        <BotMessage currentMessage={{
                            ...message,
                            created_at: new Date(),
                            text: "To complete the rejection, please add any additional text to the message box below and press Finish Task.",
                        }} />
                    </>
                }
                {taskLoading &&
                    <BotMessage currentMessage={{
                        ...message,
                        created_at: new Date(),
                        text: "Please wait, we are completing the task.",
                    }} />
                }
                {taskCompleted &&
                    <BotMessage currentMessage={{
                        ...message,
                        created_at: new Date(),
                        text: "Task Completed!",
                    }} />
                }
            </ScrollContainer>
            <BotInput disabled={disabled} submitMessage={appendMessage} placeholder={placeholder}
                submitButtonText={submitButtonText} disableSendButton={disableButton} />
        </>
    )
}

export default BotReleaseShift

const taskData = {
    "shiftNameInformation": "(10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET",
    "reasonForRelease": "Test ",
    "task": {
        "id": 641239,
        "name": "Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.",
        "description": null,
        "data": {
            "assignees": [
                {
                    "id": 1559,
                    "friendlyName": "Joe Rogan"
                },
                {
                    "id": 4546,
                    "friendlyName": "Shawn Kohen"
                },
                {
                    "id": 5586,
                    "friendlyName": "Test Driver"
                }
            ]
        },
        "isCompleted": false,
        "completedAt": null,
        "dueDate": "2023-07-15T06:53:20+00:00",
        "isArchived": false,
        "type": "RELEASE_SHIFT",
        "assignees": [
            {
                "id": 1559,
                "friendlyName": "Joe Rogan",
                "identity": "a11a0bf4b51b2992a5570dab45dc5549",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 4546,
                "friendlyName": "Shawn Kohen",
                "identity": "4546-460c06a4cde3f6",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 136,
                        "roleName": "Station Manager",
                        "name": "ROLE_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            },
            {
                "id": 5586,
                "friendlyName": "Test Driver",
                "identity": "5586-79a55b0f755da7",
                "isArchive": false,
                "userRoles": [
                    {
                        "id": 145,
                        "roleName": "Assistant Station Manager",
                        "name": "ROLE_ASSISTANT_STATION_MANAGER",
                        "isArchive": false
                    }
                ]
            }
        ],
        "completedBy": null,
        "isCanceled": false,
        "driverRoute": {
            "id": 2071800,
            "isArchive": false,
            "shiftName": "10 Hour Standard",
            "dateCreated": "2023-07-14T00:00:00+00:00",
            "startTime": "1970-01-01T12:00:00+00:00",
            "endTime": "1970-01-01T23:00:00+00:00",
            "category": 1
        },
        "messagesTwilioData": [
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM3439a52ccc4242fbbae4584cf3b36d6d",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM3439a52ccc4242fbbae4584cf3b36d6d",
                    "body": "Hi Joe Rogan. Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.",
                    "index": 332,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM3439a52ccc4242fbbae4584cf3b36d6d/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH70ce7932db0f4b19b25479fe74f6ed51/Messages/IM3439a52ccc4242fbbae4584cf3b36d6d/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641239,\"type\":\"RELEASE_SHIFT\",\"createdAt\":\"2023-07-13T06:53:20+00:00\",\"name\":\"Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:53:20.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:53:20.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH70ce7932db0f4b19b25479fe74f6ed51"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM5d3c7b5861b3486a8fe073b06c55771c",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM5d3c7b5861b3486a8fe073b06c55771c",
                    "body": "Hi Shawn Kohen. Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.",
                    "index": 51,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM5d3c7b5861b3486a8fe073b06c55771c/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH42282464978b4d91889b7a10f7efb6f6/Messages/IM5d3c7b5861b3486a8fe073b06c55771c/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641239,\"type\":\"RELEASE_SHIFT\",\"createdAt\":\"2023-07-13T06:53:20+00:00\",\"name\":\"Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:53:22.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:53:22.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH42282464978b4d91889b7a10f7efb6f6"
                }
            },
            {
                "message": "Message created.",
                "success": true,
                "twilioMessage": {
                    "sid": "IM7f375ffc860c4f8f92f540c2e2b5f80f",
                    "url": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IM7f375ffc860c4f8f92f540c2e2b5f80f",
                    "body": "Hi Test Driver. Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.",
                    "index": 52,
                    "links": {
                        "channel_metadata": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IM7f375ffc860c4f8f92f540c2e2b5f80f/ChannelMetadata",
                        "delivery_receipts": "https://conversations.twilio.com/v1/Services/IS6887d1c4c26c4013b9dc9b25ffc33f46/Conversations/CH23eb87dbef004f9f86b582068acec329/Messages/IM7f375ffc860c4f8f92f540c2e2b5f80f/Receipts"
                    },
                    "media": null,
                    "author": "system",
                    "delivery": null,
                    "accountSid": "AC95e6ed81a11bca348d7e9e6eb368d6cf",
                    "attributes": "{\"task\":{\"id\":641239,\"type\":\"RELEASE_SHIFT\",\"createdAt\":\"2023-07-13T06:53:20+00:00\",\"name\":\"Steven Chat requests Drop Shift Request for: (10 Hour Standard) Friday, July 14th, 8:00am - 7:00pm ET.\",\"assignees\":[{\"id\":1559,\"friendlyName\":\"Joe Rogan\"},{\"id\":4546,\"friendlyName\":\"Shawn Kohen\"},{\"id\":5586,\"friendlyName\":\"Test Driver\"}],\"env\":\"dev\",\"host\":\"api.dspworkplace.com\"},\"companyId\":85}",
                    "contentSid": null,
                    "dateCreated": {
                        "date": "2023-07-13 06:53:23.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "dateUpdated": {
                        "date": "2023-07-13 06:53:23.000000",
                        "timezone": "Z",
                        "timezone_type": 2
                    },
                    "chatServiceSid": "IS6887d1c4c26c4013b9dc9b25ffc33f46",
                    "participantSid": null,
                    "conversationSid": "CH23eb87dbef004f9f86b582068acec329"
                }
            }
        ],
        "isRejected": false,
        "isAccepted": false,
        "message": null,
        "openShiftRequest": null,
        "hrFormIncident": null,
        "updatedMessagesTwilioData": [],
        "updatedAt": "2023-07-13T06:53:23+00:00",
        "createdAt": "2023-07-13T06:53:20+00:00"
    },
    "drivers": [
        {
            "id": 2444,
            "fullName": "Steven Pignataro",
            "weekHours": {
                "workedHours": 0,
                "scheduledHours": 33,
                "week": "28",
                "year": "2023",
                "$weekStartEndDay": {
                    "week_start": "2023-07-09",
                    "week_end": "2023-07-15"
                },
                "$sumWorked": 0,
                "$sumScheduled": 1980,
                "$minutesScheduled": [
                    660,
                    660,
                    660
                ],
                "$minutesWorked": [],
                "driverRoutes": [
                    {
                        "week": 28,
                        "id": 2071798,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-10T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071799,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-12T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    },
                    {
                        "week": 28,
                        "id": 2071800,
                        "unpaidBreak": 30,
                        "dateCreated": "2023-07-14T00:00:00+00:00",
                        "punchIn": null,
                        "startTime": "1970-01-01T07:00:00-05:00",
                        "punchOut": null,
                        "endTime": "1970-01-01T18:00:00-05:00",
                        "isoWeek": "28"
                    }
                ]
            }
        }
    ],
    "selectedDrivers": [],
    "countRequests": 1,
    "releaseShiftRequest": {
        "id": 4009,
        "reasonForRelease": "Test ",
        "isCompleted": false,
        "isArchived": false,
        "isRejected": false,
        "isCanceled": false,
        "data": [],
        "completedAt": null,
        "createdAt": "2023-07-13T06:53:20+00:00",
        "requestedBy": {
            "id": 2696,
            "friendlyName": "Steven Chat",
            "identity": "2696-fc8909c66172ed",
            "isArchive": false,
            "driver": {
                "id": 2444,
                "employeeStatus": "2"
            }
        },
        "task": {
            "id": 641239,
            "isArchive": false,
            "isCanceled": false,
            "completed": false
        },
        "selectedDriver": null
    }
}
