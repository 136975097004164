import React, { useState, useMemo } from 'react';
import Modal from "../../../Modal";
import {Button, Icon, SubTitle, Spacer, TagInput, Input} from '@dspworkplace/ui';
import styled from 'styled-components';
import {useForm} from "react-hook-form";
import {Badge} from '../../../UI'
import Tooltip from '../../../Tooltip/tooltip'
import FormCombobox from "../../../Form/Combobox/combobox.hookForm";
import ComboboxRecoil from "../../../Form/Combobox/combobox.recoil";
import DayPicker from "../../../../components/Calendar/day";
import moment from "moment";
import DatePicker from "../../../../components/Calendar/picker";
import { useEffect } from 'react';
import FilterBottom from '../../../FilterBottom';

 export const Btn = styled(Button)`
    display: flex;
    align-items: center;
    position: relative;
    > svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;
    }
`;

const FormLayout = styled.form`
    display:flex;
    flex-direction: column;
    overflow:hidden;
    height: 100vh;
`

const FormOptions = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    height:30%;
`

export const Header = styled.header `
    display:flex;
    justify-content:space-between;
    align-items:center;     
    margin-bottom:20px;
    
    >button {
        font-weight:normal;
    }   
`

const TooltipContent = ({content}) => {
    return <div>{content}</div>
}

const ButtonRender = ({children,content}) => {
    if(!content || content.length < 1) return children
    return <Tooltip direction='bottom-start' content={<TooltipContent content={content} />}>{children}</Tooltip>
}

const TooltipRender = ({label,value}) => {
    return <p>{label} = {value}</p>
}

const Filter = ({options, setAllFilters, selectedFilters = {}, defaultFiltersSelected}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [fieldLabels, setFieldLabels] = useState((defaultFiltersSelected?.fieldLabels) ? defaultFiltersSelected?.fieldLabels : {});
    const [remove, setRemove] = useState(true);

    const appliedFilters = useMemo(()=>{
        const filters = []

        Object.keys(selectedFilters).forEach(filterKey => {
            const label = selectedFilters[filterKey].hasOwnProperty('label')?selectedFilters[filterKey].label:Array.isArray(selectedFilters[filterKey]) ? selectedFilters[filterKey].join(", ") : selectedFilters[filterKey]
            const value = selectedFilters[filterKey].hasOwnProperty('value')?selectedFilters[filterKey].value:selectedFilters[filterKey];
            const fieldName = fieldLabels[filterKey] || 'empty'
            
            // @ts-ignore            
            if(value !== null && value != '' ) filters.push(<TooltipRender label={fieldName} value={label} key={filterKey} />);
        })
        return filters
    },[fieldLabels, selectedFilters])

    const onRemove = (data) => {
        setAllFilters(data);
        setRemove(false);
    }
    useEffect(() => {
        if (remove == false) {
            setRemove(true);
        }
    }, [remove]);
    const onSubmit = data => {
        const fieldWithValuesData = JSON.parse(JSON.stringify(data))
        const {label} = fieldWithValuesData;
        delete fieldWithValuesData.label;
        setFieldLabels(label);
        setAllFilters(fieldWithValuesData);
        setOpen(false);
    }

    return (
        <div>
            <ButtonRender content={appliedFilters}>
                <Btn onClick={()=>setOpen(true)}>
                    <span>Filter</span>
                    <Icon.Filter />
                    {appliedFilters.length > 0 && <Badge>{appliedFilters.length}</Badge>}
                </Btn>
            </ButtonRender>
            <Modal
                width={'340px'}
                visible={open}
                title={'Filters'}
                setVisible={()=>setOpen(false)}
                drawer={'right'}
                key={'modal-filter'}
                closeOnOverlay={true}
                zindex={999}
                removeHeader={true}
                paddingTopBottomZero={true}
            >
                {remove == true && <FilterForm selectedFilters={selectedFilters} onRemove={onRemove} onSubmit={onSubmit} options={options} />}
            </Modal>
        </div>
    )
}

const FilterForm = ({selectedFilters,onSubmit,onRemove,options}) => {
    const { register, reset, control, handleSubmit, watch } = useForm({
        mode: "onBlur",
        defaultValues: selectedFilters
    });

    const remove = async () => {
        let opts = {};
        await options.map((opt) => {
            if (opt.recoilOptions && !opt.validValues) {
                opts[opt.key] = { "value": "", "label": "" };
            }  else if (opt.validValues && opt.multiple != true && opt.filterMultiple != true) {
                opts[opt.key] = { "value": "", "label": ""};
            } else {
                opts[opt.key] = "";
            }
        });
        reset(opts);
        selectedFilters = {}
        onRemove(opts);
    }
    const type = watch('type');

    const handleFilterSelect = (filter) => {
        console.log("filter>>", filter);
    }

    return (<>
        <FormLayout onSubmit={handleSubmit(onSubmit)}>
        <Header>
            <SubTitle>Filters</SubTitle>
            <Button type="cancel" onClick={remove}>
                Clear
            </Button>
        </Header>
            <FormOptions>
                {options.map((opt) => {
                    if (opt.isDate) {
                        if (selectedFilters[opt.key]) {
                            let temp = selectedFilters[opt.key].split(' – ');
                            if (temp.length > 1) {
                                selectedFilters[opt.key+'d'] = [new Date(temp[0]), new Date(temp[1])];
                            }
                        }
                    }
                    return (
                        <div key={opt.key}>
                            {opt.validValues && (opt.multiple == true || opt.filterMultiple == true ?
                                <TagInput
                                    ref={register()}
                                    name={opt.key}
                                    label={opt.name}
                                    options={opt.filterMultiple == true ? opt.validValues.map(item => ({name: item.label, value: item.value})) : opt.validValues }
                                    visibleOptionsQty={10}
                                /> :
                                <FormCombobox
                                    isAdd={opt.isAdd ?? false}
                                    name={opt.key}
                                    label={opt.name}
                                    options={opt.validValues}
                                    // nullText="--"
                                    control={control}
                                />
                            )}
                            {opt.recoilOptions && !opt.validValues && <ComboboxRecoil
                                isAdd={opt.isAdd ?? false}
                                name={opt.key}
                                label={opt.name}
                               // nullText="--"
                                control={control}
                                store={typeof opt.recoilOptions == "function" ?  opt.recoilOptions(type) :  opt.recoilOptions}
                                />}
                            {opt.isDate && 
                                <DatePicker
                                    name={opt.key}
                                    label={opt.name}
                                    // onChange={(date) => {
                                    //     if (date.length > 0) {
                                    //         const splitDate = date.split(" – ");
                                    //         const startDate = splitDate[0] + " 00:00";
                                    //         const endDate = splitDate[1] + " 23:59";
                                    
                                    //         setTimeout(() => selectedTimeframe({ start: startDate, end: endDate }), 1);
                                    //     } else {
                                    //         setTimeout(() => selectedTimeframe(false), 1);
                                    //     }
                                    // }}
                                    ref={register}
                                    defaultRangeValue={selectedFilters[opt.key+'d'] && selectedFilters[opt.key+'d']}
                                    range={"date"}
                                />
                            }
                            {opt.isInput &&
                               <Input
                                placeholder={opt.name}
                                autoFocus={false}
                                name={opt.key} 
                                label={opt.name}
                                ref={register}
                                />
                            }
                            <input type='hidden' ref={register} name={`label.${opt.key}`} value={opt.name} />
                            <Spacer top={4} />
                        </div>
                    )
                })}
                <Spacer top={4} />
            </FormOptions>
            <FilterBottom
                onFilterSelect={handleFilterSelect}
            />
        </FormLayout>
    </>)
}

export default Filter;
