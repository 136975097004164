import {useCallback} from "react";
import apiFactory from '../../api/factory';

type CategoryInterface = {
    id: number
    label: string
}

type VehicleMaintenancePlanItemNotificationInterface = {
    id: number
    miles_prior: number
    days_prior: number
    modify_task_priority_to: string
    ground_van: boolean
}

type planItemInterface = {
    id:number
}

export type VehicleMaintenancePlanItemInterface = {
    id: number
    required: boolean
    service_interval_days: number
    service_interval_miles: number
    planItem: planItemInterface|null
    category: CategoryInterface
    type: CategoryInterface
    notifications: VehicleMaintenancePlanItemNotificationInterface[]
}

export type VehicleMaintenancePlanInterface = {
    id: number
    name: string
    items: VehicleMaintenancePlanItemInterface[]
}

const resource = 'vehicle/maintenance/plan'
const {call, cancel} = apiFactory();
export const useVehicleMaintenancePlan = () => {
    const fetchVehicleMaintenancePlan = useCallback(({pageInfo,sorter,station_id}) => {
        return call(null,`api/v2/${resource}?page=${pageInfo.page}&sortBy=${sorter}&limit=${pageInfo.limit}&station_id=${station_id}`,'get')
    },[])

    const addVehicleMaintenancePlan = useCallback((newRow: Omit<VehicleMaintenancePlanInterface,"id">)=>{
        return call(newRow,`api/v2/${resource}`,'post')
    },[])

    const updateVehicleMaintenancePlan = useCallback((id: number, row)=>{
        return call(row,`api/v2/${resource}/${id}`,'put')
    },[])

    const findVehicleMaintenancePlan = useCallback((id: number)=>{
        return call(null,`api/v2/${resource}/${id}`,'get')
    },[])

    const deleteVehicleMaintenancePlan = useCallback((id: number)=>{
        return call(null,`api/v2/${resource}/${id}`,'delete')
    },[])

    const copyVehicleMaintenancePlan = useCallback((id: number)=>{
        return call({id:id},`api/v2/${resource}/copy`,'post')
    },[])

    return {
        fetchVehicleMaintenancePlan,
        addVehicleMaintenancePlan,
        updateVehicleMaintenancePlan,
        findVehicleMaintenancePlan,
        deleteVehicleMaintenancePlan,
        cancelApiRequest: cancel,
        copyVehicleMaintenancePlan
    }
}