import apiFactory from '../../../api/factory';

type TimeLineEntityInputType = {
    vehicle_maintenance_task_id: number
    date: string
}

const {call} = apiFactory();
const resource = 'event'
export const GetTimeLineEntity = (props: TimeLineEntityInputType) => {
    return call(props,`api/v2/${resource}/entity`,'post')
}