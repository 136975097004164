import React from 'react';
import { dialogPromise } from "../../../components/Dialog";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import withCubeData from "../components/withCubeData";
import EditChartForm from './EditChartForm';
import useChartConfig from "../hooks/useChartConfig";
import { fixWeekCondition } from '../fixWeekCondition';

const EditChartModal = withCubeData(({
    item,
    success,
    cancel,
    loadingState,
    ...cubeData
}) => {
    const chartConfig = useChartConfig(item.chartConfig);

    const renderContent = () => {
        const { availableMembers } = cubeData;
        if(loadingState === true || !availableMembers || !availableMembers.length){
            return <Loading style={{height:50,margin:'0 auto',display:'block'}} />;
        }

        const { chartSchema } = item;

        return <EditChartForm
            {...cubeData}
            chartSchema={chartSchema}
            defaultTitle={item.title}
            defaultLimit={10}
            defaultChartType={item.chartConfig.type}
            onCancel={cancel}
            onSubmit={(data)=>success(data)}
            chartConfig={chartConfig}
            detailQuery={item.chartDetailQuery}
            loadingState={loadingState}
            item={item}
        />;
    }

    return (
        <Modal
            width={'860px'}
            visible={true}
            closeOnOverlay={false}
            removeHeader
        >
            {renderContent()}
        </Modal>
    );
});

export const openEditChart = ({ item }) => {

    const queryWeek = fixWeekCondition(item.chartQuery);

    return dialogPromise((success, cancel)=> (
        <EditChartModal
            success={success}
            cancel={cancel}
            item={item}
            builderConfig={{
                defaultQuery: queryWeek
            }}
        />
    ))
};