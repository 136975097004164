import React from 'react';
import './index.css';
import {googleMapsApiKey} from "../../../utils";

class StationMap extends React.Component {
    constructor(props) {
        super(props);
        this.googleMapsApiKey = googleMapsApiKey;
        this.onScriptLoad = this.onScriptLoad.bind(this);
    }

    componentDidMount() {
        var script = 'https://maps.google.com/maps/api/js?libraries=places&key='
            + this.googleMapsApiKey;
        var isScriptAlreadyIncluded = function (src) {
            var scripts = document.getElementsByTagName("script");
            for (var i = 0; i < scripts.length; i++)
                if (scripts[i].getAttribute('src') == src) return scripts[i];
            return false;
        }
        var scriptEle = isScriptAlreadyIncluded(script);

        if (!window.google) {
            if (!scriptEle) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://maps.google.com/maps/api/js?libraries=places&key='
                    + this.googleMapsApiKey;
                script.id = 'StationMap';
                document.body.appendChild(script);
                script.addEventListener('load', e => {
                    this.onScriptLoad();
                })
            } else {
                scriptEle.addEventListener('load', e => {
                    this.onScriptLoad();
                })
            }
        } else {
            this.onScriptLoad();
        }
    }

    componentWillUnmount() {
        document.getElementById('StationMap').remove();
    }

    onScriptLoad() {
        var props = this.props; //resolve scope issues
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById(props.addressId)
        );
        const map = new window.google.maps.Map(
            document.getElementById(this.props.id),
            this.props.options
        );
        //marker will be placed from address field

        var marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            anchorPoint: new window.google.maps.Point(0, -29)
        });
        //this is the geofence
        var circle = new window.google.maps.Circle({
            map: map,
            radius: parseInt(document.getElementById(props.geoFenceId) ? document.getElementById(props.geoFenceId).value : 10),
            strokeOpacity: 0,
            fillColor: props.geoFenceBg
        });
        autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
        //locate and place marker, also set geo fence radius to marker
        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var markerPosition = place.geometry.location;
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(markerPosition);
                map.setZoom(17);
            }
            marker.setVisible(false);
            marker.setPosition(markerPosition);
            marker.setVisible(true);
            circle.setRadius(parseInt(document.getElementById(props.geoFenceId) ? document.getElementById(props.geoFenceId).value : 10))
            circle.bindTo('center', marker, 'position');
        });
        //update map when geofence radius input is changed
        document.getElementById(props.geoFenceId).onchange = function (event) {
            circle.setRadius(parseInt(document.getElementById(props.geoFenceId).value))
            circle.bindTo('center', marker, 'position');
        }
        //draggable marker
        window.google.maps.event.addListener(marker, 'dragend', function (evt) {
            document.getElementById(props.longitudeId).value = evt.latLng.lng();
            document.getElementById(props.latitudeId).value = evt.latLng.lat();
        });
        //initialise the starting state
        let autocompleteService = new window.google.maps.places.AutocompleteService();
        let request = {input: document.getElementById(props.addressId).value};

        // autocompleteService.getPlacePredictions(
        // window.google.maps.places.AutocompletionRequest, (predictionsArr, placesServiceStatus) => {

        // try {
        // if(!predictionsArr) return;
        // let placeRequest = {placeId: predictionsArr[0].place_id};
        // let placeService = new window.google.maps.places.PlacesService(map);
        // placeService.getDetails(placeRequest, (placeResult, placeServiceStatus) => {
        //     autocomplete.set('place', placeResult);
        //     window.google.maps.event.trigger(autocomplete, 'place_changed');
        //     //set up the initial marker position if long and lat have been saved
        //     if(document.getElementById(props.longitudeId) && document.getElementById(props.longitudeId).value) {
        //         var markerPosition = new window.google.maps.LatLng(
        //             document.getElementById(props.latitudeId).value,
        //             document.getElementById(props.longitudeId).value
        //         );
        //         map.setCenter(markerPosition);map.setZoom(18);
        //         marker.setVisible(false);
        //         marker.setPosition(markerPosition);
        //         marker.setVisible(true);
        //         circle.setRadius(parseInt(document.getElementById(props.geoFenceId).value||10))
        //         circle.bindTo('center', marker, 'position');
        //     }
        // });
        // }catch (e){
        //     console.log({e})
        // }


        //});


    }

    render() {
        var style = {height: this.props.height, marginTop: 10};
        return (
            <div style={style} id={this.props.id}/>
        )
    }
}

export default StationMap;
