import { useEffect, useReducer } from "react";
import API from './api';
import { initialState, reducer,STATUS } from "./reducer";
import { openPanelForm, openPanelDeleteConfirm, openAddChartForm, openChartWizardForm } from './form';
import { useRouteMatch } from "react-router-dom";
import {alert} from "../../components/Alert";

export const useDashboardState = (history,pageReference,slug) => {
    const [state,dispatch] = useReducer(reducer, {},()=>initialState({pageReference,slug}));
    let match = useRouteMatch(`/dashboard/${slug}/:slug`);
    const actions = generateActions(dispatch,pageReference);

    const initPanelsList = async (useCache) => {
        let panels = [];
        if(useCache){
            panels = state.data;
        }else{
            panels = await actions.getPanels({pageReference:state.pageReference});
        }

        if (panels) {
            const currentPanel = panels.filter((p)=> match?.params?.slug === p.name );
            if(!currentPanel.length && panels[0]){
                const route = encodeURI(panels[0].name).replace('/','__');
                history.push(`/dashboard/${slug}/${route}`)
                await actions.getPanelById(panels[0].id);
            } else {
                await actions.getPanelById(currentPanel[0]?.id);
            }

            if(!currentPanel.length && !panels[0]){
                history.push(`/dashboard`)
            }
        }
    }

    useEffect(()=>{
        initPanelsList();

        return API.cancel
    },[]);

    useEffect(()=>{
        if(!match && state.status === STATUS.READY && state.data){
            initPanelsList(true);
            return API.cancel
        }
    },[match]);

    return [{...state,initPanelsList}, {
        ...actions,
        dispatch
    }];
};

export const generateActions = (dispatch,pageReference) => {
    const createChart = async (panelId, chart, cardConfig) => {
        if(!chart){
            return;
        }

        chart.cardConfig = cardConfig;
        const id = chart.title + Math.floor(Math.random() * 100);
        chart.id = id;
        chart.isTemp = true;

        chart.panel = panelId;

        delete chart.id;
        delete chart.isTemp;

        const stations = JSON.parse(localStorage.getItem("station"));
        if (chart.isScorecardAMZ) {
            chart.chartQuery = {
                ...chart.chartQuery,
                filters: [{ member: "AMZWeekly.station", operator: "equals", values: [stations.code] }]
            }
            chart.chartDetailQuery = {
                ...chart.chartDetailQuery,
                filters: [{ member: "AMZWeekly.station", operator: "equals", values: [stations.code] }]
            }
        }

        try {
            const resolve = await API.addChart(chart);
            if(!resolve){
                return;
            }

            chart.id = resolve.data.data.lastInserted.id;
            if(typeof chart.cardConfig === 'string') chart.cardConfig = JSON.parse(chart.cardConfig);
            if(typeof chart.chartConfig === 'string') chart.chartConfig = JSON.parse(chart.chartConfig);
            if(typeof chart.chartQuery === 'string') chart.chartQuery = JSON.parse(chart.chartQuery);
            if(typeof chart.chartDetailQuery === 'string') chart.chartDetailQuery = JSON.parse(chart.chartDetailQuery);

            dispatch({ type:'addChartIntoPanel',payload:{ chart, panelId }});
            //dispatch({ type: 'updateTempChartIntoPanel',payload: { tempId, chart, panelId }});
        } catch(e) {
            dispatch({
                type: 'removeChartFromPanel',
                payload: { chartId: id, panelId }
            });
            throw e;
        }
    };

    const updateChartFn = async (id,chart) => {
        if(!chart){
            return;
        }
        const resolve = await API.updateChart({id,query:chart.chartQuery,chartDetailQuery:chart.chartDetailQuery,chartConfig:chart.chartConfig,title:chart.title});
        if(resolve.status === 200){
            return resolve;
        }else{
            throw new Error('There was some error to update the chart '+chart?.title);
        }
    }

    return {
        getPanels: () => {
            return API.fetchPanels({pageReference}).then(
                resolve => {
                    const panels = resolve.data.data.panel;
                    dispatch({type:'setPanelList',payload:panels});
                    return panels;
                },
                error => {
                    dispatch({type:'loading',payload: { status: STATUS.ERROR }})
                }
            );
        },
        getPanelById: (panelId) => {
            return API.fetchPanelById({ panelId }).then(
                resolve => {
                    const charts = resolve.data.data.chart;
                    dispatch({ type: 'setPanelListById', payload: { charts, panelId } });
                },
                error => {
                    dispatch({ type: 'loading', payload: { status: STATUS.ERROR } })
                }
            );
        },
        updatePanel: ({ id, name },params) => {
            return openPanelForm(id,name,pageReference,params).then(resolve => {
                if(resolve){
                    dispatch({ type:"updatePanel",payload:resolve });
                    return resolve;
                }

                return false;
            });
        },

        deletePanel: async ({ id, name }) => {
            const confirmed = await openPanelDeleteConfirm(name);

            if(confirmed) {
                const result = await API.deletePanel({ id });
                if(result) {
                    dispatch({type:"removePanel",payload: { id }});
                    return true;
                }
            }
        },

        addPanel: (params) => {
            return openPanelForm(false,false,pageReference,params).then(resolve => {
                if(resolve){
                    resolve.pageReference = pageReference;
                    dispatch({type:"addPanel",payload:resolve});
                    return resolve;
                }
            })
        },

        createChart: async (panelId, cardConfig) => {
            try{
                let chart = await openChartWizardForm(panelId);
                await createChart(panelId, chart, cardConfig);
            } catch {
                throw new Error('There was some error to create the chart');
            }
        },

        addChart: async (panelId, cardConfig) => {
            try {
                await openAddChartForm(panelId, cardConfig, dispatch);
                //await createChart(panelId, chart, cardConfig);
            } catch {
                throw new Error('There was some error to add the chart');
            }
        },

        addChartCustom: async (panelId, chart, cardConfig) => {
            try {
                await createChart(panelId, chart, cardConfig);
            } catch {
                throw new Error('There was some error to add the chart');
            }
        },

        updateCardConfig: (panelId, chartId, cardConfig) => {
            dispatch({ type:'UpdateCardConfig',payload: { cardConfig ,chartId, panelId }})
            const result = API.updateCardConfig({ id: chartId, config: cardConfig })
        },

        updateTempChart: (panelId, id, chart, chartLayout) => {
            dispatch({
                type: 'setChartTemp',
                payload: { chartId:id, panelId,temp:true }
            });
            setTimeout(()=>{
                const response = updateChartFn(id,chart)
                if(response){
                    chart.cardConfig = chartLayout;
                    dispatch({
                        type:'updateTempChartIntoPanel',
                        payload: { panelId ,tempId: id,chart }
                    });
                }
            },500)
        },

        deleteChart: async (panelId, chartId,titleChart) => {
            dispatch({
                type: 'setChartTemp',
                payload: { chartId, panelId,temp:true }
            });

            const result = await API.deleteChart({ id: chartId });
            if(result.status !== 200){
                await alert({text: `The chart "${titleChart}" was not deleted, try again or contact support`, icon: true});
                dispatch({
                    type: 'setChartTemp',
                    payload: { chartId, panelId,temp:false }
                });
            }else{
                dispatch({
                    type: 'removeChartFromPanel',
                    payload: { chartId, panelId }
                });
            }
        }
    };
}