import React, {useState, useEffect} from "react";
import {Icon} from "@dspworkplace/ui";
import styled from "styled-components";
import Tooltip from '../../../../components/Tooltip/tooltip';
import {DataGrid} from '../../../../components/Grid';
import {EditorCombobox, EditorNumber} from '../../../../components/Grid/Editors'
import {toast} from '../../../../components/Toasts'

import {openTaskForm} from './Common/CreateTaskForm'

import { confirmPortal } from "../../../../components/Confirm";
import {GridAction} from "../../../../components/Grid/formatters";
import TextModal from "../../../../components/Grid/Editors/TextModal/TextModal";
import {VMTaskPriority} from "../../../../domain/vehicle_maintenance_task/state/VehicleMaintenanceTaskPriority";
import {useRecoilCallback} from "recoil";
import {VehicleStatusState} from "../../../../domain/vehicle/state/VehicleStatusState";
import {VehicleMaintenancePlanInterface} from "../../../../domain/vehicle_maintenance_plan/api";
import useGridApi from "../../../../api/useGridApi";
import { GetVehicleLookup } from "../../../../domain/vehicle/api/getVehicles";
import {useSelectedStation} from "../../../../components/StationSelector";
import {
    GetVehiclePlanTasksLog
} from "../../../../domain/vehicle_maintenance_task/api/GetVehiclePlanTasks";
import {UpdateVehiclePlanTasks} from "../../../../domain/vehicle_maintenance_task/api/UpdateVehiclePlanTasks";
import {VehicleChangeStatus} from "../../../../domain/vehicle/api/VehiclechangeStatus";
import {VehicleChangeMileage} from "../../../../domain/vehicle/api/VehiclechangeMileage";
import {VehicleUploadImages} from "../../../../domain/vehicle/api/VehicleUploadImages";
import {VehicleUploadDocuments} from "../../../../domain/vehicle/api/VehicleUploadDocuments";

import { openTimeline } from '../../../../components/Timeline'
import {openUploadTaskFileForm} from './Common/UploadTaskFileForm'
import {openUploadTaskImageForm} from './Common/UploadTaskImageForm'
import moment from "moment";
import {VMPlanType} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanType";
import {VMPlanCategory} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanCategory";
import {
    CreateVehiclePlanTasks,
    CreateVehiclePlanTasksLog
} from "../../../../domain/vehicle_maintenance_task/api/CreateVehiclePlanTasks";
import {useAuth} from "../../../../Auth";


const Wrapper = styled.div`
    min-height: 100%;
    //max-width: 850px;
    width: 100%;
`;

const TaskListPage = () => {
    return (
        <Wrapper>
            <TaskList />
        </Wrapper>
    );
};

const parseRowToAPIRequest = (row,station) => {
    return {
        station_id: station,
        status: row.status,
        priority: row.priority,
        notes: row.notes,
        quote: parseFloat(row.quote),
        estimateCost: parseFloat(row.estimateCost),
        category_id: row?.category?.id,
        vehicle_id: row?.vehicle?.id,
        isArchive: row.isArchive
    }
}

const getColumns = ({lookups,openTimeline,handleUploadImage,handleUploadDocuments,handleDeleteTask,vehicles}) => {

    return [
        {
            key:"dateServiced",
            name: "Date Serviced",
            sortable: true,
            width: 150,
            filterable: true,
            isDate: true,
            formatter:({row}) => {
                return row?.dateServiced ? moment.utc(row?.dateServiced).local().format('dddd, MMM Do') : '-';
            }
        },
        {
            key:"createdAt",
            name: "Ticket Created",
            sortable: true,
            width: 140,
            formatter:({row}) => {
                return moment.utc(row?.createdAt).local().format('dddd, MMM Do')
            }
        },
        {
            key:"vehicleId",
            name: "Vehicle ID",
            minWidth: 140,
            filterable: true,
            multiple: true,
            validValues: vehicles,
            formatter:({row})=>{
                return <Tooltip content={row?.vehicle?.vehicleId} direction={'bottom'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row?.vehicle?.vehicleId}</span></Tooltip>
            }
        },
        {
            key:"vehicle.status",
            name: "Van Status",
            width:140,
            editor: EditorCombobox,
            formatter:({row})=>{
                return row?.vehicle?.status
            },
            editorOptions: {
                editOnClick: true
            },
            validValues: lookups.vehicleStatus
        },
        /*{
            key:"AMZ",
            name: 'AMZ Status',
            editor: EditorCombobox,
            width:140,
            editorOptions: {
                editOnClick: true,
            }
        },*/
        {
            key:"vehicle.currentMileage",
            name: "Mileage on Service Date",
            minWidth:180,
            formatter:({row})=>{
              return row?.vehicle?.currentMileage
            },
            editor: EditorNumber
        },
        {
            key:"pmMileage",
            name: "Next PM Mileage",
            minWidth:180
        },
        {
            key:"type",
            name: "Type",
            minWidth: 90,
            maxWidth: 150,
            filterable: true,
            recoilOptions: VMPlanType,
            isWatch:true,
            formatter:({row})=>{
                return <Tooltip content={row?.category?.parent?.label} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.category.parent.label}</span></Tooltip>
            }
        },
        {
            key:"category",
            name: "Category",
            minWidth: 150,
            maxWidth: 200,
            filterable: true,
            recoilOptions: (type) => VMPlanCategory(type?.value),
            formatter:({row})=>{
                return <Tooltip content={row?.category?.label} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.category.label}</span></Tooltip>
            }
        },
        {
            key:"notes",
            name: "Notes",
            minWidth: 200,
            editor: TextModal,
            formatter:({row})=>{
                return <Tooltip content={row.notes} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.notes}</span></Tooltip>
            }
        },
        {
            key:"estimateCost",
            name: "Cost",
            editor: EditorNumber,
            formatter:({row})=>{
              return row?.estimateCost
            },
        },
        {
            key:"quote",
            name: "Estimate",
            editor: EditorNumber
        },
        {
            key:"incidents",
            name: "Associated Incident",
            width: 200,
            formatter: ({ row }) => {
                let incident = (row.incidents) ? row.incidents.map((i) => i.label).join(' ,') : ''
                return <Tooltip content={incident} direction={'bottom'}><span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>{incident}</span></Tooltip>;
            }
        },
        {
            key:"vendor",
            name: "Service Vendor",
            width: 150,
            formatter: ({ row }) => {
                return (row.vendor) ? <Tooltip content={row.vendor.name + ' - ' + row.vendor.phone} direction={'left'}><span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>{row.vendor.name + ' - ' + row.vendor.phone}</span></Tooltip> : '';
            }
        },
        {
            key:"documents",
            name: "Documents",
            width: 80,
            formatter: ({ row }) => {
                return (<Tooltip key={'Upload File'} content={'Upload File'} direction={'bottom'}>
                            <Icon.Document style={{ height: '16px', width: '60px' }} onClick={() => { handleUploadDocuments(row) }} />
                        </Tooltip>);
            }
        },
        {
            key:"images",
            name: "Images",
            width: 80,
            formatter: ({ row }) => {
                return (<Tooltip key={'Upload Images'} content={'Upload Images'} direction={'bottom'}>
                            <Icon.Image style={{ height: '16px', width: '60px' }} onClick={() => { handleUploadImage(row) }} />
                        </Tooltip>);
            }
        },
        /*
        {
            key:"lastMileage",
            name: "Mileage on last service date",
            sortable: true
        },*/
        {
            key: 'actions',
            name: '',
            width: 40,
            formatter:({row})=><GridAction options={[
                // {title: 'Edit', icon: Icon.Edit, onClick: ()=>handleEditTask(row)},
                {title: 'Timeline', icon: Icon.TimeLine,onClick: ()=>{openTimeline({vehicle_maintenance_task_id:row.id})}},
                {title: 'Delete', icon: Icon.Times,onClick: ()=>{handleDeleteTask(row)}}
                // {title: 'Upload File', icon: Icon.Image,onClick: ()=>{handleUploadImage(row)}}
            ]} />
        },
        {
            key: "search",
            name: "Search",
            filterable: true,
            hideOn:"grid",
            isInput:true,
        },
    ]
}


const TaskList = () => {
    const taskState = useState<VehicleMaintenancePlanInterface[]>([]);
    const { api } = useAuth();
    const [selectedStation] = useSelectedStation();
    const [vehicles, setVehicles] = useState( [{name:'Loading', value: 'loading', disabled: true}])
    const [storeFilters, setStoreFilters] = useState({});
    const [dataCount, setDataCount] = useState(0);
    // @ts-ignore
    const { data, status, setSorter, setFilters, filters, Update, Add, setPageInfo, pageInfo } = useGridApi({
        load: GetVehiclePlanTasksLog,
        state: taskState,
        default: {
            sorter: ["createdAt"],
            filter: "page=0",
        },
    });

    const defaultFiltersSelected = {
        filters: {},
        fieldLabels: {},
    };

    useEffect(() => {
        GetVehicleLookup({station_id: selectedStation}).then(response => {
            setVehicles(response.data.map((vehicle)=>({
                value: vehicle.id,
                name: vehicle.vehicleId
            })))
        })
    }, [selectedStation]);

    const lookups = useRecoilCallback(({snapshot}) => () => {
        const taskPriority = snapshot.getLoadable(VMTaskPriority).getValue()
        const vehicleStatus = snapshot.getLoadable(VehicleStatusState).getValue()
        return {taskPriority, vehicleStatus}
    },[])

    const handleUploadImage = async (row) => {
        const formResult = await openUploadTaskImageForm(row);
        if (formResult) {
            Update(VehicleUploadImages({id: row.id, images: formResult}),null ,row,row, `Error to update IMages from task ${row.id}`,true)
        }
        return true;
    }
    const handleUploadDocuments = async (row) => {
        const formResult = await openUploadTaskFileForm(row);
        if (formResult) {
            Update(VehicleUploadDocuments({id: row.id, documents: formResult}),null ,row,row, `Error to update Documents from task ${row.id}`,true)
        }
        return true;
    }

    const handleDeleteTask = async (row) => {
        let confirmation = await confirmPortal(`Confirm you want to delete this Task ?`);
        if (!confirmation) return

        row.isArchive = true;
        const rowToUpdate = parseRowToAPIRequest(row,selectedStation)
        Update(UpdateVehiclePlanTasks(row.id,rowToUpdate),row._INDEX,row,row, `Error to delete from task ${row.id}`)

    }


    const TaskColumn = getColumns({lookups:lookups(),openTimeline,handleUploadImage,handleUploadDocuments,handleDeleteTask, vehicles});

    const handleSort = (props)=>{
        setSorter(props)
    }

    const handlePrint = async (data) => {
        const paramsNew = {
            "actions":{
                "response":{
                    "Vehicle":{
                        "custom":{
                            "functionName":"printMaintainanceTaskLog",
                            "get":"printMaintainanceTaskLog",
                            "criteria":{
                                "companyId":localStorage.getItem("company"),
                                "driverId":localStorage.getItem("driverId"),
                                'data':data,
                                'filters': filters
                            }
                        }
                    }
                }
            }
        };

        let result = await api.post("/api/lazy/manage/data", paramsNew).then(()=>{
            toast({
                type: "success",
                title: `Print Maintenance TaskTracker`,
                content: `You can get this pdf from your workplace_bot chat in few minutes`
            })
        });
    }
    const handleExport = async (data) => {
        const paramsNew = {
            "actions":{
                "response":{
                    "Vehicle":{
                        "custom":{
                            "functionName":"exportMaintainanceTaskLog",
                            "get":"exportMaintainanceTaskLog",
                            "criteria":{
                                "companyId":localStorage.getItem("company"),
                                'data': data,
                                'filters': filters
                            }
                        }
                    }
                }
            }
        };

        let result = await api.post("/api/lazy/manage/data", paramsNew);

        let element = document.createElement("a");
        element.setAttribute("href", result.data.data.exportMaintainanceTaskLog);
        element.setAttribute(
            "download",
            result.data.data.exportMaintainanceTaskLog
        );
        element.setAttribute("target", "_blank");
        // target='_blank'
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        
    }

    const handleEditorChange = async (props)=>{
        const {value,row,column,index} = props

        if(column.key === 'vehicle.status'){
            // const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the vehicle ${row.vehicle.status}?`)
            // if(!result) return
            Update(VehicleChangeStatus({vehicle_id: row.vehicle.id, status: row.vehicle.status}),index,row,row, `Error to update ${column.name} from task ${row.id}`,false)
            return
        }
        if(column.key === 'vehicle.currentMileage'){
            // const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the vehicle ${row.vehicle.vehicleId}?`)
            // if(!result) return
            Update(VehicleChangeMileage({vehicle_id: row.vehicle.id, mileage: row.vehicle.currentMileage}),index,row,row, `Error to update ${column.name} from task ${row.id}`,false)
            return
        }
        // const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the task ${row.id}?`)
        // if(!result) return
        const rowToUpdate = parseRowToAPIRequest(row,selectedStation)
        Update(UpdateVehiclePlanTasks(row.id,rowToUpdate),index,row,row, `Error to update ${column.name} from task ${row.id}`)


    }

    const handleNewTaskLog = async () => {
        const formResult = await openTaskForm(null,true)
        if(!formResult) return

        const params = {
            vehicleId: formResult.vehicleId.value,
            priority: '',
            categoryId: formResult.category.value,
            quote: parseInt(formResult.quote),
            estimate_cost: parseInt(formResult.estimateCost),
            pm_date: formResult.pm_date,
            pm_mileage: parseFloat(formResult.pm_mileage),
            date_serviced: formResult.date_serviced,
            notes: formResult.notes,
            incidents: formResult.incidents.join(','),
            vendorId: formResult.vendor?formResult.vendor.value:null
        }

        Add(CreateVehiclePlanTasksLog,params,`Error to create new manual task`)
    }

    const handleFilter = (props) => {
        props.vehicleId = (props.vehicleId && props.vehicleId != '')?props.vehicleId.join(',').toString():'';
        if(props.type && props.type.value == "") delete props.type;
        if(props.category && props.category.value == "") delete props.category;
        setFilters(props)
    }

    const isAtBottom = ({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean => {
        if (currentTarget.scrollTop + currentTarget.offsetHeight > currentTarget.scrollHeight - 10) {
            return true;
        }
        return false;
    }
    
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (!isAtBottom(event)) return;
        
        if (isAtBottom(event) && dataCount != taskState[0].length) {
            let page = false;
            if (filters != "") {
                const paramsArray = filters.split("&").map((param) => {
                    const [key, value] = param.split("=");
                    return { key, value };
                });

                for (let i = 0; i < paramsArray.length; i++) {
                    if (paramsArray[i].key === "page") {
                        page = true;
                    }
                }
            }
            setDataCount(taskState[0].length);

            // it's for filter
            let clone = storeFilters;
            if (page) {
                clone.page = pageInfo.page + 1;
                setPageInfo(pageInfo.page + 1, pageInfo.limit);
            } else {
                clone.page = 1;
                setPageInfo(1, pageInfo.limit);
            }
            setFilters(clone);
        }
        
    }

    return (
        <DataGrid
            title={"Maintenance Log"}
            columns={TaskColumn}
            status={status}
            rows={data}
            onSort={handleSort}
            onEditorChange={handleEditorChange}
            onFilter={handleFilter}
            onAdd={handleNewTaskLog}
            onPrint={handlePrint}
            onExport={handleExport}
            rowHeight={46}
            headerRowHeight={70}
            sortColumns={[{ columnKey: "createdAt", direction: "DESC" }]}
            onScroll={handleScroll}
            storeFilters={setStoreFilters}
            defaultFiltersSelected={defaultFiltersSelected}
        />
    );
};

export default TaskListPage;
