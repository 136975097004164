import React, {useState, useRef, useEffect, useContext} from 'react';
import {useForm} from "react-hook-form";

import {Theme,Spacer, TagInput, CustomDropdown,Dropdown, Toggle, async} from "@dspworkplace/ui";
import Store from "./store";

import {Wrapper, HiddenSubmit} from '../Week/tabPanel';
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import { useAuth } from "../../../Auth";
import API from "../../SchedulerLoadOut/api";
import moment from "moment";
import ComboBox from '../../../components/ComboBox';
import styled from "styled-components";

export const SkillsDropDown = styled.div`
    ul {
        top: 100%;
        max-height: 300px;
    }
`;

const Panel = ( {children}) => {
    const filterRef = useRef();
    const { register, handleSubmit, getValues, setValue } = useForm({ mode: 'onBlur' });
    const [state,dispatch] = useContext(Store);
    // let {filterOptions} = state;
    const { api } = useAuth();
    const [lookups, setLookups] = useState({stations:[],schedules:[],shiftTypes:[],drivers:[]});

    const [selectedStation, setSelected] = useSelectedStation();

    const formsValues = getValues();
    useEffect(()=>{
        if(formsValues && Object.keys(state.initialFilter).length < 1){
            dispatch({type:'INITIAL_FILTER',payload:formsValues})
        }
        setLookups(state.sidebarFilter);
    },[formsValues])

    const onSubmit = async(data) => {
        // let response = await API.fetchPendingChanges(
        //     {
        //         station: data.stations,
        //         wknumber: moment(new Date()).format("w"),
        //         unpublishDataDate: moment(new Date()).format("YYYY-MM-DD"),
        //         year:moment(new Date()).format("YYYY"),
        //         type:'day'
        //     },
        //     api
        // );
        // let changesIds = [];
        // response.forEach(function(change) {
        //     changesIds.push(change.id);
        // });
        // dispatch({
        //     type: "SET_PUBLISH_COUNT",
        //     ids: changesIds,
        //     station: data.stations,
        //     api:true
        // });

        dispatch({type:'FILTER',payload:data})
    }

    const handleDropdownChange = async (v) =>{
        await setTimeout(()=>{
            if(filterRef && filterRef.current && filterRef.current.click){
                filterRef.current.click()
            }
        },600)
    }

    useEffect(() => {
        if (!state.isLoading)
        handleDropdownChange();
    }, [selectedStation, state.isLoading]);

    useEffect(() => {
        if(!state.isLoading){
            setTimeout(() => {
                setValue('schedules', state.initialFilter.schedules);
            }, 0)
        }
    }, [state.isLoading])

    useEffect(() => {
        state.initialFilter.driver = [];
        state.initialFilter.skills = [];
        state.initialFilter.schedules = [];
        state.initialFilter.shift_type = [];
    }, [selectedStation])


    function useMappedState(stateProp) {
        const [mappedValues, setMappedValues] = useState([]);

        useEffect(() => {
            setMappedValues(stateProp?.map(Number) || []);
        }, [stateProp]);

        return mappedValues;
    }

    const shiftTypeValues = useMappedState(state.initialFilter.shift_type);
    const skillsValues = useMappedState(state?.initialFilter?.skills);

    if(state.isLoading) return null;

    return (
        <Wrapper onSubmit={handleSubmit(onSubmit)}>
            <span className='CustomBorder' />
            <Spacer top={4}>
                <StationSelector
                    showAllOption={false}
                    name='stations'
                    ref={register}
                    label='Stations'
                    size='small'
                    onChange={v => {
                        dispatch({type:'LOADING'});
                        setSelected(v)
                    }}
                    visibleOptionsQty={4}
                />
            </Spacer>

            <Spacer top={3} className='FixMaxHeightDropDown'>
                <TagInput
                    name='schedules'
                    ref={register}
                    label='Schedules'
                    options={lookups.schedules}
                    size='small'
                    onChange={handleDropdownChange}
                    visibleOptionsQty={6}
                />
            </Spacer>

            <Spacer top={3}>
                <ComboBox
                    name='shift_type'
                    ref={register}
                    label='Shift Type'
                    multiple={true}
                    input={false}
                    options={lookups.shiftTypes}
                    size='small'
                    type='shiftIcon'
                    onChange={handleDropdownChange}
                    visibleOptionsQty={6}
                    defaultValue={shiftTypeValues}
                />
            </Spacer>

            <Spacer top={3} className='FixMaxHeightDropDown'>
                <TagInput
                    name='driver'
                    ref={register}
                    label='Drivers'
                    options={lookups.drivers}
                    size='small'
                    defaultValues={state.initialFilter.driver}
                    onChange={handleDropdownChange}
                    openTo={'top'}
                    visibleOptionsQty={6}
                />
            </Spacer>

            <Spacer top={3}>
                {/* <CustomDropdown
                    name='overtime'
                    ref={register}
                    label='Overtime'
                    options={[
                        {value:'in_overtime',name:'In Overtime'},
                        {value:'scheduled',name:'Scheduled for Overtime'},
                        {value:'not_scheduled_overtime',name:'Not Scheduled for Overtime'}
                    ]}
                    size='small'
                    multiple={true}
                    openTo={'top'}
                    onChange={handleDropdownChange}
                /> */}
                <SkillsDropDown>
                    <CustomDropdown
                        name='skills'
                        ref={register}
                        label='Skills'
                        options={lookups.skills}
                        size='small'
                        multiple={true}
                        openTo={'bottom'}
                        onChange={handleDropdownChange}
                        defaultValue={skillsValues}
                    />
                </SkillsDropDown>
            </Spacer>

            <Spacer top={5} className='CustomToggle'>
                <Toggle name='unscheduled' ref={register} singleText='Unscheduled Drivers' onChange={handleDropdownChange} />
            </Spacer>

            <HiddenSubmit ref={filterRef} />

            {children}
        </Wrapper>
    )
}

export default Panel;
