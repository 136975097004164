import axios from "axios";
import {getCompanyId} from "../../../../Auth";
import {engine} from "../../../../Utilities/index";
import {HandleCreateSlide, HandleUpdateSlide} from "../Slides/apiHandler";
import moment from 'moment';

const CancelToken = axios.CancelToken;
let canceler;

const messageParam = (messageObj) => {
    messageObj.company = getCompanyId();
    if(messageObj.frequency) messageObj.frequency = JSON.stringify(messageObj.frequency);

    const slides = messageObj.slides;
    delete messageObj.slides;
    let action;
    if(messageObj.id){
        const {id} = messageObj;
        delete messageObj.id;
        let extra = HandleUpdateSlide(slides,{message:id});

         action = {
             create: {
                 ...extra.create
             },
             delete: {
                 ...extra.delete
             },
            update: {
                ...extra.update,
                AnncMessage: {
                    message1: {
                        findBy: {
                            id:id
                        },
                        updateRecord:messageObj
                    }
                }
            }
        };
    }else{
        let extraSlides = HandleCreateSlide(slides,'message1');
        messageObj.dateReference = moment().format('YYYY-MM-DD');
        delete messageObj.dateExecution;
         action = {
            create: {
                AnncMessage: {
                    message1: messageObj
                },
                AnncSlide: {...extraSlides.create.slide},
                AnncMessageTemplates: {...extraSlides.create.template}
            },
            connect: {...extraSlides.connections}
        };
    }


    return {
        actions: {
            ...action,
            response: {
                findLastInserted: {
                    message1: MessageIncludes
                }
            }
        }
    }
}

const MessageIncludes = {
    0:"id",
    1:"title",
    3:"frequency",
    4:"status",
    5:"dateReference",
    6:"dateEnd",
    7:"dateExecution",
    8:"reference",
    "station":{
        2:"code"
    },
    "user": {
        "0":"friendlyName"
    }
}

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchList(params = {}) {
        let segment = null;
        if(params.segment){
            segment = {
                AnncSegment:{
                    findBy: {
                        get: "AnncSegment",
                        criteria: {
                            company: getCompanyId()
                        },
                        orderBy: {
                            name:'ASC'
                        },
                        matching:{},
                        includes:{
                            0:"id",
                            1:"name",
                            2:"employeesQuantity"
                        }
                    }
                }
            }
        }
        return API.call({
            actions: {
                response: {
                    AnncMessage:{
                        custom: {
                            functionName: "getAnncMessages",
                            get: "AnncMessage",
                            criteria: {
                                company: getCompanyId(),                                
                                dateExecution:params.matching.dateExecution,
                                station:params.matching.station,
                                search:(params.matching.title && params.matching.title.contains) || '',
                                status:(params.matching.status && params.matching.status) || ''
                            }
                        }
                    },
                    ...segment
                }
            }
        });
    },
    fetchMessages(params = {}){
        let segment = null;
        if(params.segment){
            segment = {
                AnncSegment:{
                    findBy: {
                        get: "AnncSegment",
                        criteria: {
                            company: getCompanyId()
                        },
                        orderBy: {
                            name:'ASC'
                        },
                        matching:{},
                        includes:{
                            0:"id",
                            1:"name",
                            2:"employeesQuantity"
                        }
                    }
                }
            }
        }
        return API.call({
            actions: {
                response: {
                    AnncMessage:{
                        findBy: {
                            get: "AnncMessage",
                            criteria: {
                                company: getCompanyId()
                            },
                            orderBy: {
                                messageOrder: 'DESC'
                            },
                            matching: params.matching || {},
                            includes: MessageIncludes
                        }
                    },
                    ...segment
                }
            }
        });
    },
    fetchMessagesData: (idMessage) => {
        let params =  {
            actions: {
                response: {
                    AnncSegment:{
                        findBy: {
                            get: "AnncSegment",
                            criteria: {
                                company: getCompanyId()
                            },
                            orderBy: {
                                name:'ASC'
                            },
                            matching:{},
                            includes:{
                                0:"id",
                                1:"name",
                                2:"employeesQuantity"
                            }
                        }
                    }
                }
            }
        };
        if(idMessage){
            params.actions.response['AnncMessage'] = {
                findOneBy: {
                    get: "message",
                    criteria: {
                        company: getCompanyId(),
                        id:idMessage
                    },
                    matching:{},
                    includes: {
                        0:"id",
                        1:"title",
                        2:"dateExecution",
                        3:"frequency",
                        4:"reference",
                        "segments":{
                            0:"id"
                        },
                        "station":{
                            0:"id"
                        },
                        "slides":{
                            0:"id",
                            1:"name",
                            2:"content",
                            3:"slideOrder",
                            4:"isRequired"
                        },
                        "templates":{
                            "0":"slideOrder",
                            "1":"id",
                            "template":{
                                "0":"name",
                                "1":"slidesQuantity",
                                "2":"id"
                            }
                        }
                    }
                }
            }
        }
        return API.call(params);
    },
    addMessage: (params) => {
        let apiParam = messageParam(params);
        return API.call(apiParam);
    },
    removeMessage: (id,propagate) => {
        let update =  {
            AnncMessage: {
                message1: {
                    findBy: {id: id},
                    updateRecord: {
                        queueStatus: 'Deleting'
                    }
                }
            }
        }
        return API.call({
            actions: {
                update: {...update},
                delete: {
                    AnncMessage: {
                        message1: {
                            findOneBy: {
                                id: id
                            }
                        }
                    }
                }
            }
        })
    },
    changeStatus: (status,id) => {
        return API.call({
            actions: {
                update: {
                    AnncMessage: {
                        message1: {
                            findBy: {
                                id:id
                            },
                            updateRecord:{
                                status:status,
                                queueStatus:'ChangingStatus'
                            }
                        }
                    }
                }
            }
        })
    },
    fetchMetricVideo() {
        return API.call({
            actions: {
                response: {
                    MetricVideo: {
                        custom: {
                            functionName: "getMetricVideo",
                            get: "getMetricVideo",
                            excludes: {},
                            includes: {},
                            criteria: {},
                        },
                    },
                },
            },
        });
    },
}

export default API;