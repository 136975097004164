import {useCallback} from "react";

export function useGetStringFromValue() {
    return useCallback((value) => {
        return getStringFromValue(value);
    }, []);
}

export function getStringFromValue(value: any) {
    if (typeof value === 'number') {
        return JSON.stringify(value);
    }
    if (typeof value === 'string' && value.split(' ').length < 2) {
        if (!isNaN(parseFloat(value))) {
            return JSON.stringify(parseFloat(value));
        }
        return value;
    }
    if (typeof value === 'string') {
        return value;
    }
    if (value === null) {
        return 'N/A';
    }
    if (typeof value === 'undefined') {
        return 'N/A';
    }
    try {
        return JSON.stringify(value);
    } catch (e) {
        return 'N/A';
    }
}

export function styleSheetGetColor(value?: string) {
    try {
        return value ? value : '#516F90';
    } catch (e) {
        return '#516F90'
    }
}

export function styleSheetBackgroundColor(value?: string) {
    try {
        return value ? value : '#FDEDEE67'
    } catch (e) {
        return '#FFF'
    }
}

export function styleSheetBorderColor(value?: string) {
    try {
        return value ? value : '#EAF0F6'
    } catch (e) {
        return '#EAF0F6'
    }
}