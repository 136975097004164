import React from 'react';
import {engine, getCompanyId, getCurrentTimezone, showErrorToast} from "../../../../../../../Utilities";
import moment from "moment-timezone";
import {MobileScorecardInformation} from "../../OverviewTemplate";

import styled from "styled-components";
//@ts-ignore
import {Icon, Theme} from '@dspworkplace/ui';
import Card, {FocusArea} from "./Card";
import {useWeeklyDataReducer} from "../../../WeeklyDataProvider";


const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: space-between;
    margin: 12px 16px;

    .viewWeek {
        display: flex;
        flex-direction: row;
        height: 30px;
    }

    .textWeek {
        display: flex;
        color: #516F90;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
    }

    .rightTextWeek {
        display: flex;
        color: #516F90;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        margin-left: 16px;
    }

    .fullScoresButton {
        display: flex;
        height: 30px;
        flex-direction: row;
    }

    .fullScoreText {
        display: flex;
        font-size: 18px;
        line-height: 30px;
        color: #0071BC;
        text-decoration: underline;
        text-decoration-color: #0071BC;
    }

    .fullScoresArrow {
        display: flex;
        height: 30px;
        flex-direction: row;
        align-items: center;

    }
`;

type HeaderProps = {
    week?: number | string | null;
}
//@ts-ignore
const Header: React.FC<HeaderProps> = ({week = 'N/A'}) => {
    return (
        <HeaderContainer>
            <div className={'viewWeek'}>
                <div className={'textWeek'}>
                    Scores
                </div>
                <div className={'rightTextWeek'}>
                    week #{week}
                </div>
            </div>
            <div className={'fullScoresButton'}>
                <div className={'fullScoreText'}>
                    Full Score
                </div>
                <div className={'fullScoresArrow'}>
                    <Icon.ArrowRight
                        size="16px"
                        color={'#7C98B6'}

                    />
                </div>
            </div>
        </HeaderContainer>
    );
};

const ScoresContainer = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
`;


export async function fetchMobileScorecardInformation(stationId: number, driverId: number): Promise<MobileScorecardInformation | null> {
    try {
        let params = {
            driverId,
            stationId,
            companyId: getCompanyId(),
            datetime: moment().toISOString(),
            timezone: getCurrentTimezone(),
        };
        let {data} = await engine().post('/api/v2/scorecard', params);
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching Scorecard.');
        return null;
    }
}

const Scores: React.FC<{ driverId: number, stationId: number }> = () => {
    const {weeklyDataState} = useWeeklyDataReducer();
    const {loading, scoresCards, focusArea, data} = weeklyDataState;

    if (
        !loading &&
        scoresCards &&
        Array.isArray(scoresCards) &&
        data &&
        data.scorecard.value0
    ) {
        return <ScoresContainer>
            <Header week={data.scorecard.value0.week}/>
            {scoresCards.map((e, i) => {
                if (e.metricIdentifier === 'focus' || i+1 > data.scorecard.numberOfFields) {
                    return null;
                }
                return (
                    <Card key={e.metricIdentifier}
                          metricName={e.metricName}
                          metricValue={e.metricValue}
                          change={e.change} trend={e.trend!}
                          scenario={e.scenario} metricType={e.metricType}
                          trendStyle={e.trendStyle}/>
                )
            })}
            <FocusArea
                text={
                    focusArea &&typeof focusArea.metricValue === 'string'
                        ? focusArea.metricValue
                        : 'N/A'
                }
                explanation={focusArea && focusArea.explanation ? focusArea.explanation : ''}
            />
        </ScoresContainer>

    }

    return null

}

export default Scores;