import React, { useState, useEffect } from "react";
import { Spacer, Button, Input, InputColor, Dropdown, TagInput, CheckBox } from "@dspworkplace/ui";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../Auth";
import { asDatabaseTime, databaseToMilliseconds } from "../../../../components/TimeFormat";
import Loading, { LoadingWrapper } from "../../../../components/Loading";
import { alert } from "../../../../components/Alert";
import moment from "moment";
import TimePicker from "../../../../components/TimePicker";
import { patternNumericValue } from '../../../../patterns';

const App = ({ setLoading, action, edit, copy, station }) => {
    const { api } = useAuth();
    const [sendNotification, setSendNotification] = useState(false);
    let isDST = moment().isDST();
    const [options, setOptions] = useState({
        loaded: false,
        skills: [],
        balanceGroups: [],
        categories: [],
        invoiceTypes: [],
    });

    let defaultValues = {};

    if (copy && copy.id) {
        defaultValues = {
            ...copy,
            name: "Copy Of " + copy.name,
            startTime: asDatabaseTime(copy.startTime.timestamp * 1000),
            endTime: asDatabaseTime(copy.endTime.timestamp * 1000),
            startTimeOnDst: asDatabaseTime(copy.startTimeOnDst.timestamp * 1000),
            endTimeOnDst: asDatabaseTime(copy.endTimeOnDst.timestamp * 1000),
            skill: copy.skill ? copy.skill.id : "",
            invoiceType: copy.invoiceType ? copy.invoiceType.id : "",
            balanceGroup: copy.balanceGroup ? copy.balanceGroup.name : "",
            //category: copy.category.toString()
        };
    }

    if (edit && edit.id) {
        defaultValues = {
            ...edit,
            startTime: asDatabaseTime(edit.startTime.timestamp * 1000),
            endTime: asDatabaseTime(edit.endTime.timestamp * 1000),
            startTimeOnDst: asDatabaseTime(edit.startTimeOnDst.timestamp * 1000),
            endTimeOnDst: asDatabaseTime(edit.endTimeOnDst.timestamp * 1000),
            skill: edit.skill ? edit.skill.id : "",
            invoiceType: edit.invoiceType ? edit.invoiceType.id : "",
            balanceGroup: edit.balanceGroup ? edit.balanceGroup.name : "",
            //category: edit.category.toString()
        };
    }

    const { register, handleSubmit, errors, getValues } = useForm({
        mode: "onBlur",
        defaultValues: defaultValues,
    });
    const {
        name,
        startTime,
        skill,
        invoiceType,
        endTime,
        unpaidBreak,
        note,
        color,
        category,
        balanceGroup,
        notification,
        startTimeOnDst,
        endTimeOnDst,
    } = getValues();

    const onSubmit = async (data) => {
        if (station.timezone == "HT" || station.timezone == "AZ") {
            if (isDST) {
                data.startTime = data.startTimeOnDst;
                data.endTime = data.endTimeOnDst;
            } else {
                data.startTimeOnDst = data.startTime;
                data.endTimeOnDst = data.endTime;
            }
        } else {
            if (isDST) {
                let dstStartTimeInMilliseconds = databaseToMilliseconds(data.startTimeOnDst, true);
                let dstEndTimeInMilliseconds = databaseToMilliseconds(data.endTimeOnDst, true);
                let startTimeInMilliseconds = dstStartTimeInMilliseconds + 60 * 60 * 1000;
                let endTimeInMilliseconds = dstEndTimeInMilliseconds + 60 * 60 * 1000;
                data.startTime = asDatabaseTime(startTimeInMilliseconds);
                data.endTime = asDatabaseTime(endTimeInMilliseconds);
            } else {
                let notDstStartTimeInMilliseconds = databaseToMilliseconds(data.startTime, true);
                let notDstEndTimeInMilliseconds = databaseToMilliseconds(data.endTime, true);
                let startTimeOnDstInMilliseconds = notDstStartTimeInMilliseconds - 60 * 60 * 1000;
                let endTimeOnDstInMilliseconds = notDstEndTimeInMilliseconds - 60 * 60 * 1000;
                data.startTimeOnDst = asDatabaseTime(startTimeOnDstInMilliseconds);
                data.endTimeOnDst = asDatabaseTime(endTimeOnDstInMilliseconds);
            }
        }
        if (edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver") {
            data.color = edit.color;
            data.textColor = edit.textColor;
        } else {
            let colorVal = data.color;
            if (colorVal.length > 0) {
                data.color = colorVal[0];
                data.textColor = colorVal[1] ? colorVal[1] : "#ffffff";
            } else {
                data.color = "";
                data.textColor = "";
            }
        }

        let shiftType = data;

        setLoading(true);

        if (edit && edit.id) {
            if (edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver") {
                shiftType.balanceGroup = edit.balanceGroup ? [edit.balanceGroup.name] : [];
                shiftType.skill = edit.skill ? edit.skill.id : "";
                shiftType.invoiceType = edit.invoiceType ? edit.invoiceType.id : "";
            }
            const previousShift = Object.assign({}, defaultValues);
            previousShift.balanceGroup = edit.balanceGroup && edit.balanceGroup.id ? edit.balanceGroup.id : "";
            previousShift.textColor = data.textColor;
            if (edit.skill && data.skill.toString() !== edit.skill.id.toString()) {
                let getDriverRoutes = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "getRoutesFromShiftAndSkill",
                                    criteria: {
                                        id: edit.skill.id,
                                        shiftId: edit.id,
                                        type: "skill",
                                        companyId: localStorage.getItem("company"),
                                        submittedSkill: shiftType.skill,
                                    },
                                    get: "routes",
                                },
                            },
                        },
                    },
                };

                let shiftResponse = await api.post("/api/lazy/manage/data", getDriverRoutes);
                if (shiftResponse.data.data.routes.length > 0) {
                    let messageString = "";
                    shiftResponse.data.data.routes.map((item, key) => {
                        messageString =
                            messageString +
                            '<tr><td style="border: 1px solid #e6d8d8;padding:5px;">' +
                            item.schedule +
                            '</td> <td style="border: 1px solid #e6d8d8;padding:5px;">' +
                            item.driver +
                            "</td></tr> ";
                    });
                    messageString =
                        'Current skill is related with future schedules below. So you can not change that.</br></br></br><table  style="width:100%;border-collapse: collapse;margin-top: 13px;"><tr><th style="border: 1px solid #e6d8d8;padding:5px;">Schedule</th><th style="border: 1px solid #e6d8d8;padding:5px;">Driver</th></tr>' +
                        messageString +
                        "</table><br/>";
                    alert(messageString);
                    setLoading(false);
                    return false;
                }
            }

            let checkExist = {
                actions: {
                    response: {
                        BalanceGroup: {
                            custom: {
                                functionName: "checkIfExistBalanceGroup",
                                criteria: {
                                    name: data.balanceGroup[0],
                                    station: station.id,
                                },
                                get: "balanceGroup",
                            },
                        },
                    },
                },
            };

            let response = await api.post("/api/lazy/manage/data", checkExist);
            let balanceGroupName = data.balanceGroup[0];
            shiftType.balanceGroup = response.data.data.balanceGroup;
            if (shiftType.invoiceType == "0") shiftType.invoiceType = "";

            let notification = {};
            let updatedShift = shiftType;
            updatedShift.id = edit.id;
            if (shiftType.hasOwnProperty("sendNotifications")) {
                notification["sendNotifications"] = shiftType.sendNotifications === "true";
                delete shiftType.sendNotifications;
            }

            if (shiftType.hasOwnProperty("sendSMSNotifications")) {
                notification["sendSMSNotifications"] = shiftType.sendSMSNotifications === "true";
                delete shiftType.sendSMSNotifications;
            }

            if (shiftType.hasOwnProperty("notification")) {
                notification["message"] = shiftType.notification;
                notification["type"] = "Shift";
                delete shiftType.notification;
            }

            // delete shiftType.sendNotifications;
            // if(shiftType.notification){
            //     delete shiftType.notification;
            // }

            let params = {
                previousRecord: previousShift,
                updatedRecord: updatedShift,
                notification: notification,
                actions: {
                    update: {
                        Shift: {
                            shift_1: {
                                findBy: {
                                    id: edit.id,
                                },
                                updateRecord: shiftType,
                                previousRecord: previousShift,
                                notification: notification,
                            },
                        },
                    },
                },
            };
            if (previousShift.category === 2) {
                let unScheduledCategoryShift = {
                    actions: {
                        response: {
                            Shift: {
                                findBy: {
                                    get: "shifts",
                                    criteria: {
                                        station: station.id,
                                        category: 7,
                                    },
                                    excludes: [
                                        "company",
                                        "users",
                                        "parent",
                                        "childrens",
                                        "driverRoutes",
                                        "payRate",
                                        "skillRates",
                                        "shifts",
                                        "drivers",
                                        "station",
                                    ],
                                    includes: {
                                        0: "id",
                                        1: "name",
                                    },
                                },
                            },
                        },
                    },
                };
                let unScheduledCategory = await api.post("/api/lazy/manage/data", unScheduledCategoryShift);
                if (unScheduledCategory.data.data.shifts.length > 0) {
                    let updateUnscheduleShift = {
                        actions: {
                            response: {
                                Shift: {
                                    custom: {
                                        functionName: "updateUnscheduleCategoryShift",
                                        criteria: {
                                            station: station.id,
                                            startTime: data.startTime,
                                            endTime: data.endTime,
                                            startTimeOnDst: data.startTimeOnDst,
                                            endTimeOnDst: data.endTimeOnDst,
                                            note: data.note,
                                            unpaidBreak: data.unpaidBreak,
                                            invoiceType: data.invoiceType,
                                            skill: data.skill,
                                            balanceGroup: data.balanceGroup,
                                        },
                                        get: "shift",
                                    },
                                },
                            },
                        },
                    };
                    let response = await api.post("/api/lazy/manage/data", updateUnscheduleShift);
                }
            }
            try {
                await api.post("/api/lazy/manage/data", params);

                let paramsForUpdateRoutes = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "updateFutureDateRoutes",
                                    criteria: {
                                        id: edit.id,
                                    },
                                    get: "routes",
                                },
                            },
                        },
                    },
                };
                await api.post("/api/lazy/manage/data", paramsForUpdateRoutes);

                data.id = edit.id;

                let currentSkill;
                // skills.map((skillRate) => {
                //     if(skillRate.skill.id == shiftType.skill){
                //         currentSkill = skillRate.skill;
                //     }
                // });
                //
                // let currentInvoiceType;
                // invoiceTypes.map((invoiceType) => {
                //     if(invoiceType.id == shiftType.invoiceType){
                //         currentInvoiceType = invoiceType;
                //     }
                // });
                const newShiftType = {
                    edit: "yes",
                    id: edit.id,
                    name: shiftType.name,
                    color: shiftType.color,
                    textColor: shiftType.textColor,
                    category: parseInt(shiftType.category),
                    skill: currentSkill,
                    // invoiceType:currentInvoiceType,
                    startTime: shiftType.startTime,
                    endTime: shiftType.endTime,
                    startTimeOnDst: shiftType.startTimeOnDst,
                    endTimeOnDst: shiftType.endTimeOnDst,
                    unpaidBreak: shiftType.unpaidBreak,
                    countInWeekViewAsDriver: shiftType.countInWeekViewAsDriver,
                    note: shiftType.note,
                    balanceGroup: { id: balanceGroupName, name: balanceGroupName },
                };

                setLoading(false);
                await action.edit(newShiftType);
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
            }
        } else {
            //creation
            shiftType.station = station.id;
            let checkExist = {
                actions: {
                    response: {
                        BalanceGroup: {
                            custom: {
                                functionName: "checkIfExistBalanceGroup",
                                criteria: {
                                    name: data.balanceGroup[0],
                                    station: station.id,
                                },
                                get: "balanceGroup",
                            },
                        },
                    },
                },
            };

            let response = await api.post("/api/lazy/manage/data", checkExist);
            let balanceGroupName = data.balanceGroup[0];
            shiftType.balanceGroup = data.balanceGroup[0];
            data.balanceGroup = response.data.data.balanceGroup;
            data.company = localStorage.getItem("company");
            data.category = 1;

            if (shiftType.invoiceType == "0") shiftType.invoiceType = "";

            let params = {
                actions: {
                    create: {
                        Shift: {
                            shift_1: data,
                        },
                    },
                },
            };

            try {
                let response = await api.post("/api/lazy/manage/data", params);
                let currentSkill;

                // skills.map((skillRate) => {
                //     if(skillRate.skill.id == shiftType.skill){
                //         currentSkill = skillRate.skill;
                //     }
                // });
                //
                // let currentInvoiceType;
                // invoiceTypes.map((invoiceType) => {
                //     if(invoiceType.id == shiftType.invoiceType){
                //         currentInvoiceType = invoiceType;
                //     }
                // });
                const newShiftType = {
                    id: response.data.data.responseId,
                    name: shiftType.name,
                    color: shiftType.color,
                    textColor: shiftType.textColor,
                    category: parseInt(shiftType.category),
                    skill: currentSkill,
                    // invoiceType:currentInvoiceType,
                    startTime: shiftType.startTime,
                    endTime: shiftType.endTime,
                    startTimeOnDst: shiftType.startTimeOnDst,
                    endTimeOnDst: shiftType.endTimeOnDst,
                    unpaidBreak: shiftType.unpaidBreak,
                    countInWeekViewAsDriver: shiftType.countInWeekViewAsDriver,
                    note: shiftType.note,
                    balanceGroup: { id: balanceGroupName, name: balanceGroupName },
                };
                setLoading(false);
                await action.create(newShiftType);
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
            }
        }
    };

    const errorMessages = {
        startTime: {
            required: "You need to provide a startTime",
            pattern: "Enter a valid start time. Ex: HH:MM",
        },
        endTime: {
            required: "You need to provide a endTime",
            pattern: "Enter a valid end time. Ex: HH:MM",
        },
        startTimeOnDst: {
            required: "You need to provide a startTime",
            pattern: "Enter a valid start time. Ex: HH:MM",
        },
        endTimeOnDst: {
            required: "You need to provide a endTime",
            pattern: "Enter a valid end time. Ex: HH:MM",
        },
        unpaidBreak: {
            required: '"You need to provide a unpaid break"',
            pattern: 'Enter only numeric value',
        }
    };

    const loadOptions = () => {
        api.post("/api/lazy/manage/data", {
            actions: {
                response: {
                    Skill: {
                        findBy: {
                            get: "skills",
                            criteria: {
                                company: localStorage.getItem("company"),
                            },
                            includes: {
                                0: "id",
                                1: "name",
                            },
                        },
                    },
                    BalanceGroup: {
                        findBy: {
                            get: "balanceGroups",
                            criteria: {
                                company: localStorage.getItem("company"),
                                station: station.id,
                            },
                            includes: {
                                0: "id",
                                1: "name",
                            },
                        },
                    },
                    InvoiceType: {
                        findBy: {
                            get: "invoiceTypes",
                            criteria: {
                                station: station.id,
                            },
                            includes: {
                                0: "id",
                                1: "name",
                            },
                        },
                    },
                    Shift: {
                        custom: {
                            functionName: "findCategories",
                            get: "categories",
                            criteria: {},
                        },
                    },
                },
            },
        }).then(
            (response) => {
                let invoiceOptions = [{ id: 0, name: "Select Invoice" }];
                let temp = [];
                response.data.data.invoiceTypes.map((invoiceType) => {
                    temp = {
                        id: invoiceType.id.toString(),
                        name: invoiceType.name.toString(),
                    };
                    invoiceOptions.push(temp);
                });
                setOptions({
                    invoiceTypes: invoiceOptions,
                    skills: response.data.data.skills,
                    balanceGroups: response.data.data.balanceGroups,
                    categories: response.data.data.categories,
                    loaded: true,
                });
            },
            (error) => (error.response.status == 401 ? (window.location = "/logout") : null)
        );
    };

    const checkboxCheckEvent = (id) => {
        let checkboxObj = document.querySelector(`[name='${id}']`);
        if (checkboxObj && checkboxObj.checked) {
            setSendNotification(true);
        } else {
            setSendNotification(false);
        }
    };

    useEffect(() => {
        loadOptions();
    }, []);

    return !options.loaded ? (
        <LoadingWrapper>
            <Loading />
        </LoadingWrapper>
    ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
            {edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver" ? (
                ""
            ) : (
                <Spacer right={5} inline>
                    <TagInput
                        canCreate={true}
                        label="Balance Group"
                        singleOption={true}
                        name="balanceGroup"
                        size="small"
                        ref={register({ required: false })}
                        options={options.balanceGroups.map((bg) => ({ name: bg.name, value: bg.name }))}
                        placeholder="Type or select"
                        error={errors.balanceGroup}
                        valid={!errors.balanceGroup}
                    />
                </Spacer>
            )}
            <Spacer right={5} top={3} inline>
                <Input
                    name="name"
                    label="Name"
                    ref={register({ required: "You need to provide a name" })}
                    error={errors.name && errors.name.message}
                    valid={!errors.name && name && name.length > 0}
                    disabled={edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver"}
                />
            </Spacer>
            {edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver" ? (
                ""
            ) : (
                <Spacer right={0} inline style={{width:"140px", position: "relative", zIndex: 2 }}>
                    <InputColor
                        name="color"
                        value={edit && edit.color ? edit.color : copy && copy.id ? copy.color : ""}
                        ref={register({ required: "You need to select a color" })}
                        error={errors.color && errors.color.message}
                        valid={!errors.color && color && color.length > 0}
                    />
                </Spacer>
            )}
            {edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver" ? (
                ""
            ) : (
                <Spacer right={5} inline top={3}>
                    <Dropdown
                        name="skill"
                        label="Skill Level"
                        ref={register({ required: "Required field" })}
                        placeholder="Select Skill"
                        size="small"
                        options={options.skills.map((sr) => ({ name: sr.name, value: sr.id }))}
                        error={errors.skill && errors.skill.message}
                        valid={!errors.skill && skill}
                    />
                </Spacer>
            )}
            {edit && edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver" ? (
                ""
            ) : (
                <Spacer right={0} inline>
                    <Dropdown
                        name="invoiceType"
                        label="Invoice Type"
                        ref={register}
                        placeholder="Select Invoice"
                        options={options.invoiceTypes.map((it) => ({ name: it.name, value: it.id.toString() }))}
                    />
                </Spacer>
            )}
            {isDST ? (
                <>
                    <Spacer right={5} inline top={3}>
                        <TimePicker
                            label="Start Time"
                            size="small"
                            name="startTimeOnDst"
                            timeZoneShortName = {station.timezone}
                            ref={register({
                                required: true,
                            })}
                            error={errors.startTimeOnDst && errorMessages.startTimeOnDst[errors.startTimeOnDst.type]}
                            valid={!errors.startTimeOnDst && startTimeOnDst && startTimeOnDst.length > 0}
                        />
                    </Spacer>
                    <Spacer right={5} inline>
                        <TimePicker
                            label="End Time"
                            size="small"
                            name="endTimeOnDst"
                            timeZoneShortName = {station.timezone}
                            ref={register({
                                required: true,
                            })}
                            error={errors.endTimeOnDst && errorMessages.endTimeOnDst[errors.endTimeOnDst.type]}
                            valid={!errors.endTimeOnDst && endTimeOnDst && endTimeOnDst.length > 0}
                        />
                    </Spacer>
                </>
            ) : (
                <>
                    <Spacer right={5} inline top={3}>
                        <TimePicker
                            label="Start Time"
                            size="small"
                            name="startTime"
                            timeZoneShortName = {station.timezone}
                            ref={register({
                                required: true,
                            })}
                            error={errors.startTime && errorMessages.startTime[errors.startTime.type]}
                            valid={!errors.startTime && startTime && startTime.length > 0}
                        />
                    </Spacer>
                    <Spacer right={5} inline>
                        <TimePicker
                            label="End Time"
                            size="small"
                            name="endTime"
                            timeZoneShortName = {station.timezone}
                            ref={register({
                                required: true,
                            })}
                            error={errors.endTime && errorMessages.endTime[errors.endTime.type]}
                            valid={!errors.endTime && endTime && endTime.length > 0}
                        />
                    </Spacer>
                </>
            )}

            <Spacer right={0} inline>
                <Input
                    label="Unpaid Break"
                    size="small"
                    help="Duration in minutes"
                    name="unpaidBreak"
                    ref={register({
                        required: true,
                        pattern: patternNumericValue,
                    })}
                    error={errors.unpaidBreak && errorMessages.unpaidBreak[errors.unpaidBreak.type]}
                    valid={!errors.unpaidBreak && unpaidBreak && unpaidBreak.length > 0}
                />
            </Spacer>
            <Spacer top={3}>
                <CheckBox
                    name="countInWeekViewAsDriver"
                    title={""}
                    ref={register({ required: false })}
                    defaultValues= {[edit && edit.countInWeekViewAsDriver ? edit.countInWeekViewAsDriver :true]}
                    options={[{ value: true, label: "Count in Week view as driver"}]}
                />
            </Spacer>
            <Spacer right={5} top={3} inline>
                <Input
                    name="note"
                    label="Notes"
                    size="big"
                    style={{ height: 76 }}
                    as="textarea"
                    ref={register({})}
                    error={errors.note && errors.note.message}
                    valid={!errors.note && note && note.length > 0}
                />
            </Spacer>
            {edit && edit.id && !(edit.category == 7 && edit.name == "ATTN NEEDED: Unscheduled Driver") ? (
                <>
                    <CheckBox
                        name="sendNotifications"
                        title={""}
                        ref={register({ required: false })}
                        onClick={() => {
                            checkboxCheckEvent("sendNotifications");
                        }}
                        options={[{ value: true, label: "Notification send to Drivers" }]}
                    />
                    <CheckBox
                        name="sendSMSNotifications"
                        title={""}
                        ref={register({ required: false })}
                        options={[{ value: true, label: "SMS Notification send to Drivers" }]}
                    />
                </>
            ) : null}

            {sendNotification && (
                <Spacer right={5} top={3} inline>
                    <Input
                        name="notification"
                        label="Message"
                        size="big"
                        style={{ height: 76 }}
                        as="textarea"
                        ref={register({ required: true })}
                        error={errors.notification && errors.notification.message}
                        valid={!errors.notification && notification && notification.length > 0}
                    />
                </Spacer>
            )}

            <Spacer top={5} />
            <Button type="primary">Save Shift Type</Button>
        </form>
    );
};

export default App;
