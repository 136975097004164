import React, {useEffect} from 'react';
import useScript from '../hooks/useScript';
import {getUserId,getEmail,getCompanyId,getPositionName,getToken,getCompanyName} from "../Auth";

const Analytics = ({children}) => {
    if(process.env.REACT_APP_ANALYTICS_CODE){
        return <AnalyticTracking app={children} />
    }else{
        return children;
    }
}

const AnalyticTracking = ({app}) => {
    const trackingCode = process.env.REACT_APP_ANALYTICS_CODE;
    const status = useScript(`https://www.googletagmanager.com/gtag/js?id=${trackingCode}`);
    const token = getToken();

    useEffect(()=>{
        const userId = getUserId();
        if(token && status === 'ready'){
            window.dataLayer = window.dataLayer || [];
            const gtagFnc = function(){
                window.dataLayer.push(arguments);
            };
            gtagFnc('js', new Date());
            gtagFnc('config',trackingCode,{
                'user_id':userId
            });

            const gaProperties = {
                email: getEmail(),
                company: getCompanyId(),
                companyName:getCompanyName(),
                position: getPositionName()
            }
            gtagFnc('set','user_properties',gaProperties);

            window.gtag = gtagFnc;
        }
    },[token,status])

    return app;
}

export default Analytics;
