import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Dropdown, Toggle, Theme, Icon } from "@dspworkplace/ui";
import { useAuth, getCompanyId } from "../../../Auth";
import SettingsContext from "../context";
import { toast } from "../../../components/Toasts";
import { Label, TextArea } from "../../../AppTwilioConversation/styles";
// import { apiKeyTinyMce } from "../../../Conf";
// import { Editor } from "@tinymce/tinymce-react";
// import TinyMce from "../../../components/TinyMce";
import styled from "styled-components";
import { AccessCheck, IsGranted } from "../../../security";
import CKeditorComponent from "../../../components/CkEditor/CKeditorComponent";

const SpanTag = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    color: rgb(81, 111, 144);
    margin-top: 4px;
`;

const Description = styled.div`
    border: 1px gray dashed;
    padding-left: 10px;
    padding-bottom: 5px;
`;

const App = () => {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, errors, handleSubmit, reset } = useForm();
    const [isSchedulePreffer, setIsSchedulePreffer] = useState(false);
    const [markAvailable, setMarkAvailable] = useState(false);
    const [markAvailableWeeks, setMarkAvailableWeeks] = useState(null);
    const [reminder, setReminder] = useState(false);
    const [reminderDays, setReminderDays] = useState();
    const [scheduleAvailableReminderMessage, setScheduleAvailableReminderMessage] = useState();
    const editorRef = useRef(null);
    const [showDescription, setShowDescription] = useState(false);

    useEffect(() => reset(state.company), [state.company]);

    const dayArr = [
        { 'name': 'Monday', 'value': 'monday' },
        { 'name': 'Tuesday', 'value': 'tuesday' },
        { 'name': 'Wednesday', 'value': 'wednesday' },
        { 'name': 'Thursday', 'value': 'thursday' },
        { 'name': 'Friday', 'value': 'friday' },
        { 'name': 'Saturday', 'value': 'saturday' },
        { 'name': 'Sunday', 'value': 'sunday' },
    ];

    const weekArr = [...Array(6).keys()].map(i => { return { 'name': (i + 1).toString() + ' week', 'value': (i + 1).toString() } });
    // for(let week = 1; week<=15; week++){
    //     employeeStatusArr.push({'name':week.toString(),'value':week.toString()});
    // }
    //let defaultWeeks = parseInt(localStorage.getItem('recurringWeeks'));
    const getCompanySetting = async () => {
        const params = {
            actions: {
                response: {
                    CompanySetting: {
                        findOneBy: {
                            get: "settings",
                            criteria: {
                                company: getCompanyId()
                            },
                            includes: [
                                'id',
                                'isSchedulePreffer',
                                'markAvailable',
                                'markAvailableWeeks',
                                'reminder',
                                'reminderDays',
                                'scheduleAvailableReminderMessage',
                            ]
                        }
                    }
                }
            }
        };
        let companySettings = await api.post('/api/lazy/manage/data', params);
        if (companySettings && companySettings?.data?.data?.settings) {

            let defaultScheduleAvailableReminderMessage = 'Attention [DRIVER_NAME]: If you want to change your work availabillity for Week [WEEK] - Sunday [WEEK_START_DATE] though Saturday [WEEK_END_DATE], please do so by Saturday evening. You can review your settings on your mobile calendar!';

            setIsSchedulePreffer(companySettings?.data?.data?.settings.isSchedulePreffer);
            setMarkAvailable(companySettings?.data?.data?.settings.markAvailable);
            setMarkAvailableWeeks(companySettings?.data?.data?.settings.markAvailableWeeks);
            setReminder(companySettings?.data?.data?.settings.reminder);
            setReminderDays(companySettings?.data?.data?.settings.reminderDays);
            setScheduleAvailableReminderMessage((companySettings?.data?.data?.settings.scheduleAvailableReminderMessage != null) ? companySettings?.data?.data?.settings.scheduleAvailableReminderMessage : defaultScheduleAvailableReminderMessage);
        }
    }
    useEffect(() => {
        getCompanySetting();
    }, []);

    const handleSettingUpdate = (data, evt) => {
        let form = evt.target;
        form.querySelector('button').disabled = true;
        let tempScheduleAvailableReminderMessage = editorRef?.current?.getContent() ? editorRef.current.getContent : scheduleAvailableReminderMessage;

        api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId()
                            },
                            updateRecord: {
                                isSchedulePreffer: isSchedulePreffer,
                                markAvailable: markAvailable,
                                markAvailableWeeks: markAvailableWeeks,
                                reminder: reminder,
                                reminderDays: reminderDays,
                                scheduleAvailableReminderMessage: tempScheduleAvailableReminderMessage,
                            }
                        }
                    }
                }
            }
        }).then(
            response => {
                toast({
                    type: 'success',
                    title: 'Company Setting',
                    content: 'Company settings updated sucessfully.'
                });
            }

        ).then(
            () => form.querySelector('button').disabled = false
        );
    };

    return (
        <form onSubmit={handleSubmit(handleSettingUpdate)}>
            <SubTitle>Weekly Driver Schedule Availability Settings</SubTitle>
            <Spacer bottom={3} />
            <Toggle
                ref={register}
                name='isSchedulePreffer'
                singleText='Turn on driver ability to mark their weekly availability'
                on={isSchedulePreffer}
                onChange={(e) => { setIsSchedulePreffer(e.target.checked) }}
            />
            <Spacer bottom={6} />
            {isSchedulePreffer && <>
                <Toggle
                    ref={register}
                    name='markAvailable'
                    singleText='Close window for driver to edit their availability'
                    on={markAvailable}
                    onChange={(e) => { setMarkAvailable(e.target.checked) }}
                />
                <Spacer bottom={3} />
                {markAvailable &&
                    <Dropdown
                        name='markAvailableWeeks'
                        placeholder='Select Days'
                        ref={register({ required: true })}
                        label='Calendar week(s) in advance of current week to lock availability.changes.lendar week:'
                        options={weekArr}
                        defaultValue={markAvailableWeeks}
                        onChange={(s) => {
                            setMarkAvailableWeeks(s.value);
                        }}
                        error={errors.markAvailableWeeks && "You need to provide a days"}
                    />}
                <Spacer bottom={6} />
                <Toggle
                    ref={register}
                    name='reminder'
                    singleText='Send drivers reminder to mark weekly availability'
                    on={reminder}
                    onChange={(e) => { setReminder(e.target.checked) }}
                />
                <Spacer bottom={3} />
                {reminder && <>
                    <Dropdown
                        name='reminderDays'
                        placeholder='Select Days'
                        ref={register({ required: true })}
                        label='Notify drivers of closing window to mark availabililty X days in advance of closure'
                        options={dayArr}
                        defaultValue={reminderDays}
                        onChange={(s) => {
                            setReminderDays(s.value);
                        }}
                        error={errors.reminderDays && "You need to provide a days"}
                    />
                    <Spacer bottom={3} />
                    <Label>Schedule Available Reminder Message</Label>
                    <Spacer bottom={2} />
                    <div style={{ display: "flex" }}>
                        {/* <Editor
                            apiKey={apiKeyTinyMce}
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={scheduleAvailableReminderMessage}
                            init={{
                                height: 350,
                                width: 740,
                                plugins: [
                                    "preview",
                                    "searchreplace",
                                    "autolink",
                                    "code",
                                    "visualblocks",
                                    "visualchars",
                                    "fullscreen",
                                    "image",
                                    "link",
                                    "media",
                                    "template",
                                    "codesample",
                                    "table",
                                    "charmap",
                                    "pagebreak",
                                    "nonbreaking",
                                    "anchor",
                                    "insertdatetime",
                                    "advlist",
                                    "lists",
                                    "wordcount",
                                    "help",
                                    "charmap",
                                    "quickbars",
                                    "emoticons",
                                ],
                                menubar: true,
                                menu: {
                                    file: { title: "File", items: "preview" },
                                    edit: {
                                        title: "Edit",
                                        items: "undo redo | cut copy paste pastetext | selectall | searchreplace",
                                    },
                                    view: { title: "View", items: "code" },
                                    insert: { title: "Insert", items: "link emoticons insertdatetime" },
                                    format: {
                                        title: "Format",
                                        items: "bold italic underline strikethrough superscript subscript | forecolor backcolor | removeformat",
                                    },
                                    table: { title: "Table", items: "" },
                                    tools: { title: "Tools", items: "wordcount" },
                                    help: { title: "Help", items: "" },
                                },
                                toolbar:
                                    "undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist | forecolor backcolor emoticons link | removeformat preview",
                                toolbar_mode: "sliding",
                                content_style:
                                    "body { font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size:11px }",
                                default_link_target: "_blank",
                                contextmenu: "bold italic underline strikethrough link lists",
                                quickbars_insert_toolbar: "",
                                quickbars_selection_toolbar: "",
                            }}
                        /> */}
                        <CKeditorComponent
                            content={scheduleAvailableReminderMessage}
                            onChange={(evt) => {
                                var content = evt.editor.getData();
                                setScheduleAvailableReminderMessage(content);
                            }}
                        />
                    </div>
                    <div>
                        <div
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                                setShowDescription(!showDescription);
                            }}
                        >
                            <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                <Icon.Info
                                    size="16px"
                                    color={Theme.colors.neutrals.medium}
                                    style={{
                                        verticalAlign: "middle",
                                        position: "relative",
                                        top: -1,
                                    }}
                                />{" "}
                                Schedule Available Reminder Message Variable
                            </SpanTag>
                        </div>
                    </div>
                    <div>
                        {showDescription && (
                            <Description>
                                <SpanTag>
                                    <span>[DRIVER_NAME] = "Driver name of Driver Routes"</span>
                                </SpanTag>
                                <SpanTag>
                                    <span>[WEEK] = "Week number"</span>
                                </SpanTag>
                                <SpanTag>
                                    <span>[WEEK_START_DATE] = "Week start date"</span>
                                </SpanTag>
                                <SpanTag>
                                    <span>[WEEK_END_DATE] = "Week end date"</span>
                                </SpanTag>
                            </Description>
                        )}
                    </div>
                </>}

                <Spacer bottom={3} />
            </>}
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>Save</Button>
            </IsGranted>
        </form>
    )
}

export default App;