import React from 'react';
import Routes from './Route_temp';
import useDeviceRotateAlert from "./hooks/useDeviceRotateAlert";

function App() {
    useDeviceRotateAlert()

    return <Routes />;
}

export default App;