import React, {useContext, useEffect, useMemo} from "react";
import {FormContext, useForm} from "react-hook-form";
import {Input, Password, Spacer, SubTitle, ThirdTitle} from "@dspworkplace/ui";
import SettingsContext from "../../context";
import {toast} from "../../../../components/Toasts";
import {Link, Status} from "../../../../components/UI";
import apiFactory from "../../../../api/factory";
import Loading, {LoadingWrapper} from "../../../../components/Loading";
import {TabList} from "../../../../components/Tab";
import {useLocalStorage} from "../../../../Auth";
import RouteAssistOptions from "./options";
import Skeleton from "../../../../components/Skeleton";
import {MenuItemBadge} from "../../../../components/Menus/Main";

export const RouteAssistTM = () => <span dangerouslySetInnerHTML={{__html: 'RouteAssist&TRADE;'}}/>

const CookieStatus = ({status}) =>
    <Status current={{
        NEEDS_SETUP: 'info',
        IDLE: 'warning',
        NEEDS_REFRESH: 'error',
        ACTIVE: 'success'
    }[status]}>
        {status?.replace('_', ' ') ?? <Skeleton width={40} height={12}/>}
    </Status>

export default function RouteAssistSettings()
{
    const {call, cancel} = apiFactory();
    const [state, dispatch] = useContext(SettingsContext);
    const formMethods = useForm();
    const [stations] = useLocalStorage('stations');

    const settings = useMemo(() =>
        state?.routeAssistSettings?.reduce((settings, s) => {
            if (s.station)
                settings.stations[s.station.code] = s;
            else
                settings.all = s;

            return settings;
        }, {all: {}, stations: {}})
    , [state.routeAssistSettings]);

    const fetchRouteAssistSetting = () =>{
        const params = {
            actions: {
                response: {
                    RouteAssistSettings: {
                        findBy: {
                            criteria: {
                                company: state.company.id
                            },
                            get: "routeAssistSettings",
                            includes: {
                                0:  "id",
                                20: "enabled",
                                1:  "cortexDspId",
                                2:  "cortexCookiesStatus",
                                3:  "cortexCookiesLastUpdated",
                                4:  "ementorCookiesStatus",
                                5:  "ementorCookiesLastUpdated",
                                18: "ementorUsername",
                                19: "ementorPassword",
                                6:  "netradyneCookiesStatus",
                                7:  "netradyneCookiesLastUpdated",
                                21: "netradyneUsername",
                                22: "netradynePassword",
                                8:  "packageDeliveryProgressEnabled",
                                9:  "packageDeliveryProgressOptions",
                                10: "routeCodeAssignmentEnabled",
                                11: "routeCodeAssignmentOptions",
                                12: "vanAutoAssignmentEnabled",
                                13: "vanAutoAssignmentOptions",
                                14: "assignmentNotificationsEnabled",
                                15: "assignmentNotificationsOptions",
                                16: "safetyDocumentsEnabled",
                                17: "safetyDocumentsOptions",
                                station: ["id", "code"]
                            }
                        }
                    }
                }
            }
        };

        call(params).then(
            response => dispatch({
                type: 'ADD_ROUTE_ASSIST_SETTINGS',
                payload: response.data.data
            }),
            error => {}
        );
    };

    const fetchRoles = async () =>
        call({
            actions: {
                response: {
                    Role: {
                        findBy: {
                            get: "roles",
                            criteria: {
                                "company": state.company.id
                            },
                            includes: [
                                "id",
                                "roleName",
                                "name"
                            ],
                            orderBy: {
                                'name': 'ASC'
                            }
                        }
                    },
                }
            }
        }).then(response =>
            dispatch({
                type: 'ADD',
                payload: {
                    roles: response.data.data.roles
                        .map(r => ({name: r.roleName, value: r.name}))
                        .filter(r => r.value !== 'ROLE_ALLOWED_TO_SWITCH')
                        .sort((a, b) => a.name.localeCompare(b.name))
                }
            })
        )

    const handleSaveCortex = ( data, evt ) => {
        let form = evt.target;

        form.querySelector('button').disabled = true;

        const record = Object.entries(data).reduce((all, [prop, value]) => {
            all[prop] = (typeof value === 'object') ? JSON.stringify(value) : value
            return all;
        }, {});

        record.company = state.company.id;
        const id = record.id;
        delete record.id;

        let params = {
            actions: {
                update: {
                    RouteAssistSettings: {}
                },
                create: {
                    RouteAssistSettings: {}
                }
            }
        };
        
        let settingIds = [];
        if ( Object.keys(settings.all).length ) {
            settingIds.push(settings.all.id);
        }
        if ( Object.keys(settings.stations).length ) {
            Object.values(settings.stations).forEach(station => {
                settingIds.push(station.id);
            })
        }
        settingIds.forEach((settingId, index) => {
            params.actions.update.RouteAssistSettings["a"+index] = {
                findBy: {
                  id:settingId
                },
                updateRecord: {
                    cortexDspId: record.cortexDspId,
                    ementorUsername: record.ementorUsername,
                    ementorPassword: record.ementorPassword,
                    netradyneUsername: record.netradyneUsername,
                    netradynePassword: record.netradynePassword,
                }
            };
        });

        if ( Object.keys(settings.all).length ) {
        } else {
            params.actions.create.RouteAssistSettings.c1 = record;
        }

        if ( !id ) {
            params.actions.create.RouteAssistSettings = {
                create: record
            }
        } else {
            params.actions.update.RouteAssistSettings.u3 = {
                findBy: {
                    id
                },
                updateRecord: record
            };
        }

        call(params).then(
            response => {
                fetchRouteAssistSetting();
                toast({
                    title: <><RouteAssistTM/> information updated</>,
                    type: 'info',
                    timeout: 8000,
                    useIcon: true,
                    useClose: true
                });
                // const routeAssistSettings = [...state.routeAssistSettings];
                // const i = routeAssistSettings.findIndex(s => s.id == data.id);
                // const {station, id} = state.routeAssistSettings[i];
                // routeAssistSettings[i] = {...data, ...({station, id})};
                
                // dispatch({
                //     type: 'ADD',
                //     payload: {
                //         routeAssistSettings: [...routeAssistSettings]
                //     }
                // })
            },
            error => toast({
                title: 'Error',
                content: error.response ? error.response.data.message : error.message,
                type: 'error',
                timeout: 8000,
                useIcon: true,
                useClose: true
            })
        ).then(
            ()=> form.querySelector('button').disabled = false
        );
    };

    useEffect(() => {
        if (state.company.id)
            fetchRoles()

        return cancel;
    }, [state.company.id]);

    const isLoading = !state.roles?.length;
    const {handleSubmit, register} = formMethods;

    return <FormContext {...formMethods}>
        <section>
            <SubTitle>
                <RouteAssistTM/>
                {/*<HelpButton>How Does It Work?</HelpButton>*/}
            </SubTitle>
            <Spacer bottom={5}/>
            <form onSubmit={handleSubmit(handleSaveCortex)}>
                <ThirdTitle>Credentials</ThirdTitle>
                <Spacer bottom={3}/>

                <Input
                    ref={register}
                    name='cortexDspId'
                    label='DSP ID'
                    size={'full'}
                    help={<div style={{whiteSpace: "nowrap"}}>
                        In order to successfully run <RouteAssistTM/>, your 'DSP ID' must be entered correctly.
                        You can find it by looking in Cortex and by clicking Overview under the{' '}
                        <Link
                            target='_blank'
                            href='https://logistics.amazon.com/metrics/organization_summary_view?navMenuVariant=external#'
                            style={{fontSize: 'inherit'}}
                        >Performance</Link>
                        {' '}menu item.
                        <br/>
                        If you look at the URL once the page loads, in it you will see: "&companyId=<b>[YOUR DSP ID]</b>&".
                        Copy that string of characters and paste it into the field below.
                    </div>}
                    defaultValue={settings?.all?.cortexDspId}
                    autoComplete="off"
                />
                
                {/* <Spacer bottom={5}/>
                Commenting below code since we don't require it now but might require later
                <ThirdTitle>Convenience Tools Status</ThirdTitle>
                <Spacer bottom={3}/>
                <Text>Download our Chrome extension in <Link href='https://chrome.google.com/webstore/detail/dspworkplace-routeassist/hdmloohpnpebjjcdgimcdhhiimpbgdcp?hl=en&authuser=2' target='_blank'>Chrome Web Store</Link> and setup and keep our convenience tools running by following provided instructions.</Text>
                <Spacer bottom={3}/>
                <Table>
                    <TableRow>
                        <TableData compact>Cortex</TableData>
                        <TableData compact width={'120px'}>
                            <CookieStatus status={settings?.all?.cortexCookiesStatus}/>
                        </TableData>
                        <TableData compact width={'auto'}>
                            Last Updated: {settings?.all?.cortexCookiesLastUpdated
                                ? fullDateFromUTC(settings?.all?.cortexCookiesLastUpdated.timestamp * 1000)
                                : 'Never'}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableData compact>eMentor</TableData>
                        <TableData compact width={'120px'}>
                            <CookieStatus status={settings?.all?.ementorCookiesStatus}/>
                        </TableData>
                        <TableData compact width={'auto'}>
                            Last Updated: {settings?.all?.ementorCookiesLastUpdated
                                ? fullDateFromUTC(settings?.all?.ementorCookiesLastUpdated.timestamp * 1000)
                                : 'Never'}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableData compact>Netradyne</TableData>
                        <TableData compact width={'120px'}>
                            <CookieStatus status={settings?.all?.netradyneCookiesStatus}/>
                        </TableData>
                        <TableData compact width={'auto'}>
                            Last Updated: {settings?.all?.netradyneCookiesLastUpdated
                                ? fullDateFromUTC(settings?.all?.netradyneCookiesLastUpdated.timestamp * 1000)
                                : 'Never'}
                        </TableData>
                    </TableRow>
                </Table> */}
                <Spacer bottom={5}/>

                <ThirdTitle>eMentor Credentials</ThirdTitle>
                <Spacer bottom={3}/>
                <Input
                    ref={register}
                    name={'ementorUsername'}
                    label={'eMentor Username'}
                    defaultValue={settings?.all?.ementorUsername}
                    autoComplete={'off'}
                />
                <Spacer bottom={3}/>
                <Password
                    ref={register}
                    name={'ementorPassword'}
                    label={'eMentor Password'}
                    defaultValue={settings?.all?.ementorPassword}
                    autoComplete={'off'}
                />
                <Spacer bottom={5}/>

                <ThirdTitle>Netradyne Credentials</ThirdTitle>
                <Spacer bottom={3}/>
                <Input
                    ref={register}
                    name={'netradyneUsername'}
                    label={'Netradyne Username'}
                    defaultValue={settings?.all?.netradyneUsername}
                    autoComplete={'off'}
                />
                <Spacer bottom={3}/>
                <Password
                    ref={register}
                    name={'netradynePassword'}
                    label={'Netradyne Password'}
                    defaultValue={settings?.all?.netradynePassword}
                    autoComplete={'off'}
                />
                <Spacer bottom={5}/>

                <div style={{position: 'relative'}}>
                    {isLoading ? <LoadingWrapper><Loading/></LoadingWrapper> : null}
                    <TabList
                        defaultTab={'all'}
                        tabs={{
                            all: {
                                title: 'All Stations',
                                content: <RouteAssistOptions settings={settings?.all}/>
                            },
                            ...stations.sort((a, b) => {
                                return a.code.localeCompare(b.code, undefined, {numeric: true})
                            }).reduce((all, s) => {
                                all[s.code] = {
                                    title: <>{s.code} {settings?.stations?.[s.code]?.enabled ? <MenuItemBadge>custom</MenuItemBadge> : null}</>,
                                    content: <RouteAssistOptions settings={settings?.stations?.[s.code]} station={s}/>
                                }
                                return all;
                            }, {})
                        }}
                        onChange={() => {
                            formMethods.unregister(
                                Object.keys(formMethods.getValues())
                                    .filter(k => ![
                                        'cortexDspId',
                                        'ementorUsername',
                                        'ementorPassword',
                                        'netradyneUsername',
                                        'netradynePassword',
                                    ].includes(k))
                            )
                        }}
                    />
                </div>

                <Spacer bottom={10}/>
            </form>
        </section>
    </FormContext>
}