import {Dispatch, useDebugValue, useReducer} from "react";
import {createContainer} from "react-tracked";
import {Client} from "twilio-chat";
import {Channel} from "twilio-chat/lib/channel";
import Filter from "bad-words";
import {Task} from "./types";
import {debugLog, setItemToStorage} from "./utils";
import {getRole} from "../Auth";
import {showToast} from "../Utilities";

type NewChannelInformation = {
    name: string | null | undefined;
    description: string | null | undefined;
    image: string | null | undefined;
    errorMessage: string;
};

export type NotificationArray = {
    channelSid: string;
    notifications: number;
    ready?: boolean;
}

type AllowToChat = {
    endDatetime?: any;
    startDatetime?: any;
    track: string;
    value: boolean;
};

export type AppState = {
    isOpen: boolean;
    client?: Client;
    channels: Array<Channel>;
    loading: boolean;
    activeChannels: Array<Channel>;
    duoChannels: Array<Channel>;
    groupChannels: Array<Channel>;
    botChannels: Array<Channel>;
    maxChatViews: number;
    groupChannelModal: boolean;
    archiveGroupChannelModal: boolean;
    archiveDuoChannelModal: boolean;
    newChannel: NewChannelInformation;
    directMessageModal: boolean;
    archiveChannels: Array<Channel>;
    pinChannels: Array<Channel>;
    notificationsArray: Array<NotificationArray>;
    notifications: number;
    initClient: boolean;
    filter?: Filter;
    tasks: Task[];
    notificationsCount: number;
    botCount: number;
    allowToChat: AllowToChat;
    listBadWordsModal: boolean;
    update: number;
    initialChannelsReady: boolean;
    duo: any;
    group: any;
    bot: any;
    showFilterForWords: boolean;
    allInitNotificationsArray: boolean;
    closedCount: number;
};

export type AppAction =
    | { type: 'ALLOW_TO_CHAT'; allowToChat: AllowToChat; }
    | { type: "INIT_CHAT"; client: Client; channels: Array<Channel>; loading: boolean, filter: Filter, tasks: Task[] }
    | { type: "ADD_TASK"; task: Task }
    | { type: "UPDATE_TASK"; task: Task }
    | { type: "SET_FILTER"; filter: Filter }
    | { type: "OPEN_CHAT"; isOpen: boolean }
    | { type: "SET_CLIENT"; client: Client }
    | { type: "INIT_CLIENT"; client: Client; initClient: boolean }
    | { type: "SET_CHANNELS"; channels: Array<Channel> }
    | { type: "ADD_CHANNEL"; channel: Channel }
    | { type: "REMOVE_CHANNEL"; channel: Channel }
    | { type: "UPDATE_CHANNEL"; channel: Channel }
    | { type: "JOINED_CHANNEL"; channel: Channel }
    | { type: "MESSAGE_ADDED_ACTIVE_CHANNEL"; channel: Channel }
    | { type: "ADD_ACTIVE_CHANNEL"; channel: Channel }
    | { type: "CLOSE_ACTIVE_CHANNEL"; channel: Channel }
    | { type: "SET_MAX_CHAT_VIEWS"; maxChatViews: number }
    | { type: "CLOSE_NEW_CHANNEL_MODAL" }
    | { type: "OPEN_NEW_CHANNEL_MODAL" }
    | { type: "SET_NEW_CHANNEL_INFORMATION"; newChannel: NewChannelInformation }
    | { type: "RESET_NEW_CHANNEL_INFORMATION" }
    | { type: "OPEN_DIRECT_MESSAGE_MODAL" }
    | { type: "CLOSE_DIRECT_MESSAGE_MODAL" }
    | { type: "CLOSE_ARCHIVE_GROUP_MODAL" }
    | { type: "OPEN_ARCHIVE_GROUP_MODAL" }
    | { type: "CLOSE_ARCHIVE_DUO_MODAL" }
    | { type: "OPEN_ARCHIVE_DUO_MODAL" }
    | { type: "OPEN_LIST_BAD_WORDS_MODAL" }
    | { type: "CLOSE_LIST_BAD_WORDS_MODAL" }
    | { type: "ARCHIVE_CHANNEL"; channel: Channel }
    | { type: "PIN_CHANNEL"; channel: Channel }
    | { type: "UN_ARCHIVE_CHANNEL"; channel: Channel }
    | { type: "UN_PIN_CHANNEL"; channel: Channel }
    | { type: "SET_NOTIFICATIONS"; channel: Channel; num: number }
    | { type: "INIT_NOTIFICATIONS"; channel: Channel; num: number }
    | { type: "ADD_NOTIFICATIONS"; channel: Channel };

const initialState: AppState = {
    isOpen: false,
    client: undefined,
    channels: [],
    loading: true,
    activeChannels: [],
    duoChannels: [],
    groupChannels: [],
    botChannels: [],
    maxChatViews: 0,
    groupChannelModal: false,
    newChannel: {
        name: "",
        description: "",
        image: null,
        errorMessage: ""
    },
    directMessageModal: false,
    archiveChannels: [],
    pinChannels: [],
    notificationsArray: [],
    notifications: 0,
    initClient: false,
    archiveGroupChannelModal: false,
    archiveDuoChannelModal: false,
    tasks: [],
    notificationsCount: Math.random(),
    botCount: Math.random(),
    allowToChat: {
        value: true,
        track: 'one'
    },
    listBadWordsModal: false,
    update: Math.random(),
    initialChannelsReady: false,
    duo: [],
    bot: [],
    group: [],
    showFilterForWords: false,
    allInitNotificationsArray: false,
    closedCount: 0
};

// CHANNEL
// KIND: IT CANNOT BE NOT UPDATED -> Bot, Duo, Group
// TYPE: IT CAN BE UPDATED

const reducer = (state: AppState, action: AppAction): AppState => {

    // console.log('REDUCER:');
    // console.log(action.type);
    // console.log(action);

    switch (action.type) {
        case "OPEN_LIST_BAD_WORDS_MODAL":
            return {...state, listBadWordsModal: true};
        case "CLOSE_LIST_BAD_WORDS_MODAL":
            return {...state, listBadWordsModal: false};
        case "ALLOW_TO_CHAT":
            return {...state, allowToChat: action.allowToChat};
        case "UPDATE_TASK":
            if (action.task.isArchived) {
                if (state.botChannels.length > 0) {
                    let sid = state.botChannels[0].sid;
                    return {
                        ...state,
                        tasks: state.tasks.filter(e => e.id !== action.task.id),
                        notificationsArray: state.notificationsArray.map(e => {
                            if (e.channelSid === sid) {
                                return {channelSid: e.channelSid, notifications: state.tasks.length - 1, ready: true}
                            }
                            return e;
                        })
                    };
                }
            }
            return state;
        case "ADD_TASK":
            if (state.botChannels.length > 0) {
                let sid = state.botChannels[0].sid;
                return {
                    ...state,
                    tasks: [action.task, ...state.tasks],
                    notifications: state.notifications + 1,
                    notificationsArray: state.notificationsArray.map(e => {
                        if (e.channelSid === sid) {
                            return {channelSid: e.channelSid, notifications: state.tasks.length + 1, ready: true}
                        }
                        return e;
                    }),
                    notificationsCount: Math.random(),
                    botCount: Math.random(),
                };
            }
            return state;
        case "INIT_CHAT":
            let actionChannelsLength = action.channels.length;
            let initialBotChannels: Array<Channel> = [];
            let initialGroupChannels: Array<Channel> = [];
            let initialDuoChannels: Array<Channel> = [];
            let initialPinChannels: Array<Channel> = [];
            let initialArchiveChannels: Array<Channel> = [];
            let name;
            for (let i = 0; i < actionChannelsLength; i++) {
                // console.log(action.channels[i]);
                if (action.channels[i].attributes["type"] === "New") {
                    continue;
                }
                if (action.channels[i].attributes["scenario"] === "New") {
                    continue;
                }
                if (action.channels[i].attributes["type"] === "Duo") {
                    initialDuoChannels.push(action.channels[i]);
                }
                if (action.channels[i].attributes["type"] === "Bot") {
                    name = action.channels[i].uniqueName;
                    if (
                        typeof name === 'string' &&
                        action.channels[i].uniqueName.includes('workplace_bot|')
                    ) {
                        initialBotChannels.push(action.channels[i]);
                    }
                }
                if (action.channels[i].attributes["type"] === "Group") {
                    initialGroupChannels.push(action.channels[i]);
                }
                if (action.channels[i].attributes["type"] === "Pin") {
                    initialPinChannels.push(action.channels[i]);
                }
                if (action.channels[i].attributes["type"] === "Archive") {
                    initialArchiveChannels.push(action.channels[i]);
                }
            }
            let arr1 = initialBotChannels.map(item => ({
                channelSid: item.sid,
                notifications: action.tasks.length,
                ready: true
            }));
            let arr2 = initialDuoChannels.map(item => ({channelSid: item.sid, notifications: 0}));
            let arr3 = initialGroupChannels.map(item => ({channelSid: item.sid, notifications: 0}));
            let arr4 = initialPinChannels.map(item => ({channelSid: item.sid, notifications: 0}));
            let arr = [...arr1, ...arr2, ...arr3, ...arr4];
            debugLog(`Number of Bot Channels -> ${initialBotChannels.length}`);
            debugLog(`Number of Group Channels -> ${initialGroupChannels.length}`);
            debugLog(`Number of Duo Channels -> ${initialDuoChannels.length}`);
            debugLog(`Number of Pin Channels -> ${initialPinChannels.length}`);
            debugLog(`Number of Archived Channels -> ${initialArchiveChannels.length}`);
            let allowToChat = {
                value: true,
                track: 'init7654',
            };
            if (initialBotChannels.length > 0 && initialBotChannels[0]) {
                let workBotAttributes = initialBotChannels[0].attributes;
                if (workBotAttributes['allowToChat'] && workBotAttributes['allowToChat']['track']) {
                    allowToChat = workBotAttributes['allowToChat'];
                    if (allowToChat && !allowToChat.value) {
                        showToast({type: 'info', title: 'You are allowed to read, but not send messages.'}).then();
                    }
                }
            }
            setItemToStorage('allowToChat', JSON.stringify(allowToChat));
            let userRole = getRole();
            let rolesForFilterWords = ["ROLE_OWNER", "ROLE_OPERATIONS_ACCOUNT_MANAGER", "ROLE_OPERATIONS_MANAGER"];
            return {
                ...state,
                allowToChat: allowToChat,
                client: action.client,
                channels: action.channels,
                duoChannels: initialDuoChannels,
                groupChannels: initialGroupChannels,
                botChannels: initialBotChannels,
                duo: initialDuoChannels.map(e => ({sid: e.sid, channel: e})),
                bot: initialBotChannels.map(e => ({sid: e.sid, channel: e})),
                group: initialGroupChannels.map(e => ({sid: e.sid, channel: e})),
                pinChannels: initialPinChannels,
                archiveChannels: initialArchiveChannels,
                loading: action.loading,
                notifications: 0,
                notificationsArray: arr,
                initClient: true,
                filter: action.filter,
                tasks: Array.isArray(action.tasks) ? action.tasks : [],
                initialChannelsReady: true,
                showFilterForWords: !!rolesForFilterWords.find(e => e === userRole),
            };
        case "SET_FILTER":
            return {...state, filter: action.filter, update: Math.random()};
        case "OPEN_CHAT":
            return {...state, isOpen: action.isOpen};
        case "INIT_NOTIFICATIONS":
            // console.log(`${action.type} ${action.channel.sid} ${action.num} | ${action.channel.friendlyName}`);
            let initResult = setNotifications(state.notificationsArray, action.channel, action.num);
            let count = 0;
            let len = initResult.tmpArr.length
            for (let i = 0; i < len; i++) {
                if (initResult.tmpArr[i].ready) {
                    count++;
                }
            }
            //@ts-ignore
            let allInit = count === len;
            // console.log({...initResult, count, len, allInit});
            if (allInit) {
                debugLog({allInit: initResult});
                return {
                    ...state,
                    notificationsArray: initResult.tmpArr,
                    notifications: initResult.reduceTotal,
                    notificationsCount: Math.random(),
                    allInitNotificationsArray: true,
                };
            }
            return {
                ...state,
                notificationsArray: initResult.tmpArr,
                notifications: initResult.reduceTotal,
                allInitNotificationsArray: true,
            };
        case "SET_NOTIFICATIONS":
            // console.log(`${action.type} ${action.channel.sid} ${action.num} ${action.channel.friendlyName}`);
            let resultNotifications = setNotifications(state.notificationsArray, action.channel, action.num);
            // console.log(resultNotifications);
            return {
                ...state,
                notificationsArray: resultNotifications.tmpArr,
                notifications: resultNotifications.reduceTotal,
                notificationsCount: Math.random(),
            };
        case "ADD_NOTIFICATIONS":
            let addResult = addNotifications(state.notificationsArray, action.channel);
            return {...state, notificationsArray: addResult.tmpArr, notifications: addResult.reduceTotal};
        case "SET_CLIENT":
            return {...state, client: action.client};
        case "INIT_CLIENT":
            return {...state, client: action.client, initClient: action.initClient};
        case "SET_CHANNELS":
            return {
                ...state,
                channels: action.channels,
                duoChannels: action.channels.filter(item => item.attributes["type"] === "Duo"),
                groupChannels: action.channels.filter(item => item.attributes["type"] === "Group"),
                botChannels: action.channels.filter(item => item.attributes["type"] === "Bot")
            };
        case "ADD_CHANNEL":
            if (action.channel.attributes["scenario"] === "New") {
                return state;
            }
            if (action.channel.attributes["type"] === "New") {
                return state;
            }
            if (action.channel.attributes["kind"] === "Duo") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    duoChannels: [action.channel, ...state.duoChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            if (action.channel.attributes["kind"] === "Group") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    groupChannels: [action.channel, ...state.groupChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            if (action.channel.attributes["kind"] === "Bot") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    botChannels: [action.channel, ...state.botChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            return state;
        case "UPDATE_CHANNEL":
            if (action.channel.attributes["type"] === "Duo") {
                return {
                    ...state,
                    duoChannels: state.duoChannels.map(item => {
                        if (item.sid === action.channel.sid) {
                            return action.channel;
                        }
                        return item;
                    })
                };
            }
            if (action.channel.attributes["type"] === "Group") {
                return {
                    ...state,
                    groupChannels: state.groupChannels.map(item => {
                        if (item.sid === action.channel.sid) {
                            return action.channel;
                        }
                        return item;
                    })
                };
            }
            if (action.channel.attributes["type"] === "Bot") {
                return {
                    ...state,
                    botChannels: state.botChannels.map(item => {
                        if (item.sid === action.channel.sid) {
                            return action.channel;
                        }
                        return item;
                    })
                };
            }
            return state;
        case "JOINED_CHANNEL":
            if (action.channel.attributes["type"] === "Duo") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    duoChannels: [action.channel, ...state.duoChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            if (action.channel.attributes["type"] === "Group") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    groupChannels: [action.channel, ...state.groupChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            if (action.channel.attributes["type"] === "Bot") {
                return {
                    ...state,
                    channels: [action.channel, ...state.channels],
                    botChannels: [action.channel, ...state.botChannels],
                    notificationsArray: [
                        {
                            channelSid: action.channel.sid,
                            notifications: 0
                        },
                        ...state.notificationsArray
                    ]
                };
            }
            return {...state};
        case "MESSAGE_ADDED_ACTIVE_CHANNEL":
            if (action.channel && action.channel.sid) {
                if (state.duoChannels.length > 0) {
                    if (action.channel.sid === state.duoChannels[0].sid) {
                        return state;
                    }
                }
                if (state.groupChannels.length > 0) {
                    if (action.channel && state.groupChannels[0] && action.channel.sid === state.groupChannels[0].sid) {
                        return state;
                    }
                }
                if (action.channel.attributes['type'] === 'Pin') {
                    return state;
                }
                if (action.channel.attributes['kind'] === 'Duo') {
                    return {
                        ...state,
                        duoChannels: [action.channel, ...state.duoChannels.filter(item => item.sid !== action.channel.sid)],
                    }
                }
                if (action.channel.attributes['kind'] === 'Group') {
                    return {
                        ...state,
                        groupChannels: [action.channel, ...state.groupChannels.filter(item => item.sid !== action.channel.sid)],
                    }
                }
            }
            return state;
        case "ADD_ACTIVE_CHANNEL":
            if (state.activeChannels.find(item => item.sid === action.channel.sid)) return state;

            if (state.activeChannels.length > 0 && state.activeChannels.length === state.maxChatViews) {
                state.activeChannels.pop();
            }

            let activeChannelsArray = [...state.activeChannels, action.channel];

            if (action.channel.attributes['kind'] === 'Bot') {
                return {...state, activeChannels: activeChannelsArray};
            }

            let addActiveResult = setNotifications(state.notificationsArray, action.channel, 0);

            if (action.channel.attributes['type'] === 'Pin') {
                return {
                    ...state,
                    activeChannels: activeChannelsArray,
                    notificationsArray: addActiveResult.tmpArr,
                    notifications: addActiveResult.reduceTotal,
                };
            }

            if (action.channel.attributes['kind'] === 'Duo') {
                return {
                    ...state,
                    activeChannels: activeChannelsArray,
                    notificationsArray: addActiveResult.tmpArr,
                    notifications: addActiveResult.reduceTotal,
                    duoChannels: [action.channel, ...state.duoChannels.filter(item => item.sid !== action.channel.sid)],
                };
            }

            if (action.channel.attributes['kind'] === 'Group') {
                return {
                    ...state,
                    activeChannels: activeChannelsArray,
                    notificationsArray: addActiveResult.tmpArr,
                    notifications: addActiveResult.reduceTotal,
                    groupChannels: [action.channel, ...state.groupChannels.filter(item => item.sid !== action.channel.sid)],
                };
            }

            return {
                ...state,
                activeChannels: activeChannelsArray,
                notificationsArray: addActiveResult.tmpArr,
                notifications: addActiveResult.reduceTotal
            };
        case "UN_PIN_CHANNEL":
            if (action.channel.attributes["kind"] === "Duo") {
                return {
                    ...state,
                    duoChannels: [action.channel, ...state.duoChannels],
                    pinChannels: state.pinChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["kind"] === "Group") {
                return {
                    ...state,
                    groupChannels: [action.channel, ...state.groupChannels],
                    pinChannels: state.pinChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            return {...state};
        case "UN_ARCHIVE_CHANNEL":
            if (action.channel.attributes["kind"] === "Duo") {
                return {
                    ...state,
                    duoChannels: [action.channel, ...state.duoChannels]
                };
            }
            if (action.channel.attributes["kind"] === "Group") {
                return {
                    ...state,
                    groupChannels: [action.channel, ...state.groupChannels]
                };
            }
            return {...state};
        case "PIN_CHANNEL":
            if (action.channel.attributes["kind"] === "Duo") {
                return {
                    ...state,
                    duoChannels: state.duoChannels.filter(item => item.sid !== action.channel.sid),
                    pinChannels: [action.channel, ...state.pinChannels]
                };
            }
            if (action.channel.attributes["kind"] === "Group") {
                return {
                    ...state,
                    groupChannels: state.groupChannels.filter(item => item.sid !== action.channel.sid),
                    pinChannels: [action.channel, ...state.pinChannels]
                };
            }
            return {...state};
        case "ARCHIVE_CHANNEL":
            if (action.channel.attributes["type"] === "Duo") {
                return {
                    ...state,
                    duoChannels: state.duoChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Group") {
                return {
                    ...state,
                    groupChannels: state.groupChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Bot") {
                return {
                    ...state,
                    botChannels: state.botChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Pin") {
                return {
                    ...state,
                    pinChannels: state.pinChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            return {
                ...state,
                activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
            };
        case "REMOVE_CHANNEL":
            if (action.channel.attributes["type"] === "Duo") {
                return {
                    ...state,
                    channels: state.channels.filter(item => item.sid !== action.channel.sid),
                    duoChannels: state.duoChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Group") {
                return {
                    ...state,
                    channels: state.channels.filter(item => item.sid !== action.channel.sid),
                    groupChannels: state.groupChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Bot") {
                return {
                    ...state,
                    channels: state.channels.filter(item => item.sid !== action.channel.sid),
                    botChannels: state.botChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            if (action.channel.attributes["type"] === "Pin") {
                return {
                    ...state,
                    channels: state.channels.filter(item => item.sid !== action.channel.sid),
                    pinChannels: state.pinChannels.filter(item => item.sid !== action.channel.sid),
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            return {
                ...state,
                channels: state.channels.filter(item => item.sid !== action.channel.sid),
                activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
            };
        case "CLOSE_ACTIVE_CHANNEL":
            if (action.channel && action.channel.attributes['kind'] === 'Bot') {
                return {
                    ...state,
                    activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
                };
            }
            let closeResult = setNotifications(state.notificationsArray, action.channel, 0);
            return {
                ...state,
                notificationsArray: closeResult.tmpArr,
                notifications: closeResult.reduceTotal,
                closedCount: state.closedCount + 1,
                activeChannels: state.activeChannels.filter(item => item.sid !== action.channel.sid)
            };
        case "SET_MAX_CHAT_VIEWS":
            if (state.activeChannels.length > action.maxChatViews) {
                state.activeChannels.splice(0, 1);
            }
            return {...state, maxChatViews: action.maxChatViews};
        case "CLOSE_NEW_CHANNEL_MODAL":
            return {...state, groupChannelModal: false};
        case "OPEN_NEW_CHANNEL_MODAL":
            return {...state, groupChannelModal: true};
        case "CLOSE_ARCHIVE_GROUP_MODAL":
            return {...state, archiveGroupChannelModal: false};
        case "OPEN_ARCHIVE_GROUP_MODAL":
            return {...state, archiveGroupChannelModal: true};
        case "CLOSE_ARCHIVE_DUO_MODAL":
            return {...state, archiveDuoChannelModal: false};
        case "OPEN_ARCHIVE_DUO_MODAL":
            return {...state, archiveDuoChannelModal: true};
        case "SET_NEW_CHANNEL_INFORMATION":
            return {...state, newChannel: action.newChannel};
        case "RESET_NEW_CHANNEL_INFORMATION":
            return {
                ...state,
                newChannel: {
                    name: "",
                    description: "",
                    image: null,
                    errorMessage: ""
                }
            };
        case "OPEN_DIRECT_MESSAGE_MODAL":
            return {...state, directMessageModal: true};
        case "CLOSE_DIRECT_MESSAGE_MODAL":
            return {...state, directMessageModal: false};
        default:
            return state;
    }
};

function setNotifications(
    notificationsArray: Array<NotificationArray>,
    channel: Channel,
    num: number,
): { tmpArr: Array<NotificationArray>; reduceTotal: number } {
    let tmpArr = notificationsArray.map(item => {
        if (channel.sid === item.channelSid) {
            return {channelSid: item.channelSid, notifications: num, ready: true};
        }
        return item;
    });
    let reduceTotal = tmpArr.reduce((total, curr) => total + curr.notifications, 0);
    return {tmpArr, reduceTotal};
}

function addNotifications(
    notificationsArray,
    channel
): { tmpArr: Array<{ channelSid: string; notifications: number }>; reduceTotal: number } {
    let tmpArr = notificationsArray.map(item => {
        if (channel.sid === item.channelSid) {
            return {channelSid: item.channelSid, notifications: item.notifications + 1};
        }
        return item;
    });
    let reduceTotal = tmpArr.reduce((total, curr) => total + curr.notifications, 0);
    return {tmpArr, reduceTotal};
}

const useValue = () => useReducer(reducer, initialState);

export const {Provider, useTrackedState, useUpdate: useDispatch, useTracked, useSelector} = createContainer(useValue);

export function useChatStoreReducer(): {
    state: AppState,
    dispatch: Dispatch<AppAction>,
} {
    const [state, dispatch] = useTracked();
    useDebugValue(state);
    return {state, dispatch};
}

export function useChatTrackedState(): AppState {
    return useTrackedState();
}

export function useChatDispatch(): Dispatch<AppAction> {
    return useDispatch();
}
