import React, {createContext, useReducer} from 'react';
import DayReducer from './reducer';
import {useAuth, useLocalStorage} from "../../../Auth";
import {useSelectedStation} from "../../../components/StationSelector";

const Store = createContext({});

const initialState = {
    data:{
        hours:[],
        openShifts:[],
        backupDrivers:[],
        driversShifts:[]
    },
    filter:[],
    openFiltered:[],
    backupFiltered:[],
    changes:{},
    lastModified: new Date().getTime(),
    filterOptions:[],
    initialFilter: {},
    hasFilter:false,
    refTable:undefined,
    openShifts:false,
    driverShifts:false,
    isLoading:true,
    sidebarFilter:{
        stations:[],
        schedules:[],
        shiftTypes:[],
        drivers:[]
    }
}

const WeekProvider = ({children}) => {
    const [selectedStation,setSelectedStation] = useSelectedStation();
    const [stations] = useLocalStorage('stations');
    if(!selectedStation || selectedStation == -1){
        if(stations && stations[0]){
            setSelectedStation(stations[0].id);
        }
    }
    const useWeekState = useReducer(DayReducer,{
        ...initialState,
        selectedStation: selectedStation
    })
    return (
        <Store.Provider value={useWeekState}>
            {children}
        </Store.Provider>
    )
}

export {WeekProvider}

export default Store
