import axios from "axios";
import {engine} from "../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

export default function apiFactory (instance = engine()) {
    let current = undefined;

    const call = (params, endpoint = '/api/lazy/manage/data', method = 'post') => {
        current = instance[method](
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => current = undefined
        );

        return current;
    }

    const cancel = () => {
        if ( current )
            return canceler('Connection cancelled');
        else
            return false;
    }

    return {
        call,
        cancel
    }
}