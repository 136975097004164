import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Button,
    Input,
    Spacer,
    ThirdTitle,
    Uploader,
    RadioBox,
    Dropdown,
    CheckBox,
    Alert,
    Icon,
    Text
} from "@dspworkplace/ui";
import { useForm } from "react-hook-form";

import Loading, { useLoading } from "../../components/Loading";
import {confirm} from "../../components/Confirm";
import Modal, { useModal } from "../../components/Modal";
import { getCompanyId, useAuth, baseUrl, upload } from "../../Auth";
import { csvExtention } from "../../patterns";

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const Container = styled.div`
    display: grid;
`;

const LoadingWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
    > img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const App = ({ action, csvVisible, toggleCsv }) => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, getValues, reset, setError, clearError } = useForm({ defaultValues: {} });
    const [isLoading, setLoading] = useLoading(false);
    const [log, setLog] = useState("");
    const [success, setSuccess] = useState(false);
    const { profile } = getValues();

    const onSubmit = async data => {
        if (data.profile.length > 0) {
            setLoading(true);

            let formData = new FormData();
            formData.append('image', data.profile[0]);
            formData.append('name', data.profile[0].name);
            formData.append('csvUpload', true);
            let result = await upload(formData).catch(err => {
                console.log({err})
            });
            if(result){
                const FormDataParams = new FormData();
                FormDataParams.append("filePath", result.data.ObjectURL);
                FormDataParams.append("company", localStorage.getItem("company"));
                FormDataParams.append("user", localStorage.getItem("userId"));
                FormDataParams.append("type", 'csv');
                let apiStatus  = false;
                let csvStatus  = false;
                let csvResponse = await api.post("/api/devices/csv/check/duplicate", FormDataParams);
                setLog(csvResponse.data.message.msg);
                if (csvResponse.data.message.msg == "") {
                    if(csvResponse.data.message.count > 0 ){
                        let conf = await confirm({
                            icon: true,
                            text: 'Your CSV has '+csvResponse.data.message.count+' duplicate name and/or phone number in it. Duplicate records will result in an update to the reflect the last record listed in your CSV.  Do you want to continue?',
                        });
                        if(conf){
                            csvStatus  = true;
                        }
                    } else {
                        csvStatus = true;
                    }                      
                }

                if( csvStatus == true){
                    FormDataParams.append("type", 'database');
                    let response = await api.post("/api/devices/csv/check/duplicate", FormDataParams);
                    if (response.data.message.msg == "") {
                        if(response.data.message.count > 0 ){
                            let confDb = await confirm({
                                icon: true,
                                text: 'Your CSV contains '+response.data.message.count+' records that already exist in the database. Would you like to update the existing records?',
                            });
                            if(confDb){
                                apiStatus  = true;
                            }
                        } else {
                            apiStatus  = true;
                        }
                    }
                }

                if( apiStatus == true){
                    let response = await api.post("/api/devices/csv/upload", FormDataParams);
                    if (response.data.message) {
                        setLog(response.data.message);
                        if (response.data.message == "") {
                            setSuccess(true);
                        } else {
                            setSuccess(false);
                        }
                    } else {
                        setLog("");
                        setSuccess(true);
                    }
                }

            }
            setLoading(false);
        }
    };

    const errorMessages = {
        profile: {
            required: "You need to provide an csv",
            pattern: "select file format is not valid"
        }
    };

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([log], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = "log.txt";
        document.body.appendChild(element);
        element.click();
    };

    return (
        <Modal
            title={"Import Device CSV"}
            width="506px"
            visible={csvVisible}
            setVisible={() => {
                toggleCsv();
            }}
            zindex="40"
        >
            <Container>
                {isLoading && (
                    <LoadingWrapper>
                        <Loading />
                    </LoadingWrapper>
                )}
                <form name="new_employee" onSubmit={handleSubmit(onSubmit)}>
                    {success === true && (
                        <Alert.Success title="Success" content="Csv record add/updated successfully." />
                    )}
                    <Spacer top={4} />
                    <ThirdTitle style={{ fontWeight: "normal" }}>Select CSV File</ThirdTitle>

                    <Spacer top={4} />
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <input
                            type="file"
                            name="profile"
                            ref={register({
                                required: true,
                                pattern: csvExtention
                            })}
                            error={errors.profile && errorMessages.profile[errors.profile.type]}
                            valid={!errors.profile && profile && profile.length > 0}
                        />
                        <Text style={{color:"red"}}>{errors.profile && errorMessages.profile[errors.profile.type]}</Text>
                    </SpacerInline>
                    <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={5}>
                        <Text style={{ fontWeight: "normal" }}>
                            Example CSV
                            <a style={{ marginLeft: "10px" }} href={"https://s3.amazonaws.com/dsp.data.storage.general/Device_template.xlsx"}>
                                <Icon.Download size="22px" />
                            </a>
                        </Text>
                    </SpacerInline>
                    <Spacer top={4} />
                    {log !== "" && (
                        <>
                            <SpacerInline style={{ verticalAlign: "bottom", position: "relative", top: "0" }} left={2}>
                                <Text style={{ color: "red" }}>
                                    Please check log file for errors
                                    <span style={{ color: "blue", cursor: "pointer" }} onClick={downloadTxtFile}>
                                        {" "}
                                        Click Here
                                    </span>
                                </Text>
                            </SpacerInline>
                            <Spacer top={4} />
                        </>
                    )}

                    <Button type="primary" style={{ float: "right" }} Type="submit">
                        Import
                    </Button>
                </form>
            </Container>
        </Modal>
    );
};

export default App;
