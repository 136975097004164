import React, { useState } from 'react';
import { Input, Icon, SubTitle, Theme } from "@dspworkplace/ui";

const TitleInput = ({ value, onChange }) => {
    const [editing, setEditing] = useState(false);
    return (
        <SubTitle style={{display:'flex',alignItems:'center'}}>
            {editing
                ?
                    <>
                        <Input
                            type='text'
                            defaultValue={value}
                            onBlur={(e)=>{
                                onChange(e.target.value);
                                setEditing(false);
                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter') {
                                    onChange(e.target.value);
                                    setEditing(false);
                                }
                            }}
                        />
                        <Icon.Check
                            size={'26px'}
                            color={Theme.colors.primary}
                            style={{cursor:'pointer'}}
                            onClick={()=> setEditing(false)}
                        />
                    </>
                :
                    <>
                        <span>{value}</span>
                        <Icon.Edit
                            size={'26px'}
                            color={Theme.colors.info.border}
                            style={{cursor:'pointer'}}
                            onClick={() => setEditing(true)}
                        />
                    </>
            }
        </SubTitle>
    );
}

export default TitleInput;