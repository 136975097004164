import React from 'react';
import styled from 'styled-components';
import {Theme, Spacer, Icon} from '@dspworkplace/ui';

const Title = styled.h6`
    font-size:12px;
    font-family:circe-rounded;
    color:${Theme.colors.info.text};
    display:block;
`;

const Number = styled.h5`
    color:${Theme.colors.info.text};
    font-size:22px;
    font-family:circe-rounded;
    font-weight:bold;
    display:inline-block;
    
    ${props=>props.value == 'A' && `color:#00A38D`}
    ${props=>props.value == 'B' && `color:#DBAE60`}
    ${props=>props.value == 'C' && `color:#DBAE60`}
    ${props=>props.value == 'D' && `color:#D94C53`}
`;

const Result = styled.span`
    border:1px solid;
    border-radius:2px;
    padding:0 4px;
    font-size:10px;
    font-weight:bold;
    height:18px;
    box-sizing:border-box;
    
    color:${props => props.text};
    background:${props => props.bg};
    border-color:${props => props.border};
`;

const solveColor = result => {
    let colors = {bg:'#FAFAFA',border:Theme.colors.neutrals.silver,text:Theme.colors.neutrals.silver};
    if(result < 0){
        colors.bg = '#E5F8F6 ';
        colors.border = '#00BDA5';
        colors.text ='#00A38D';
    }
    if(result > 0){
        colors.bg = Theme.colors.error.bg;
        colors.border = Theme.colors.error.border;
        colors.text =Theme.colors.error.text;
    }

    return colors;
}

const Evolution = ({title,value,oldValue}) => {
    let result = oldValue-value;
    let isLetter = 0;
    if(value.toString().toUpperCase() != value.toString().toLowerCase()){
        result = oldValue.charCodeAt(0) - value.charCodeAt(0);
        isLetter=1;
    }


    const colors = solveColor(result);

    let IconTrend = '~'
    const IconPosition = {position:'relative',top:'2px'}
    if(result <0){
        IconTrend = <Icon.TrendUp size='12px' color={colors.border} style={IconPosition} />
        result = '+'+result*-1;
    }else if(result >0){
        IconTrend = <Icon.TrendDown size='12px' color={colors.border} style={IconPosition} />
        result = '-'+result
    }


    return (
        <div>
            <Title>{title}</Title>
            <Spacer top={2} style={{display:'flex'}}>
                <Number value={value}>{value}</Number>
                <Spacer inline left={2} style={{paddingTop:'1px'}}>
                    <Result bg={colors.bg} text={colors.text} border={colors.border}>{IconTrend} {isLetter ? oldValue : result}</Result>
                </Spacer>
            </Spacer>
        </div>
    )
}

export default Evolution;
