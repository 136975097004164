import apiFactory from '../../../api/factory';
import {VehicleMaintenanceVendorGetParams} from "./getVendors";

const {call} = apiFactory();
const resource = 'vehicle/maintenance/vendor'
export const CreateVehicleVendor = (vendor): Promise<VehicleMaintenanceVendorGetParams> => {
    return call(vendor,`api/v2/${resource}`,'post')
}
export const DeleteVehicleVendor = (vendorId): Promise<any> => {
    return call(null,`api/v2/${resource}/${vendorId}`,'delete')
}
export const UpdateVehicleVendor = (id: number, vendor): Promise<VehicleMaintenanceVendorGetParams> => {
    return call(vendor,`api/v2/${resource}/${id}`,'put')
}

