import React, {forwardRef} from "react";
import {CustomDropdown} from "@dspworkplace/ui";
import createPersistedState from 'use-persisted-state';
import styled from 'styled-components';
//@ts-ignore
import {getCompanyId, getUserId, useLocalStorage} from "../../Auth";
// import {engine} from "../../Utilities";
// import {StationInclude} from "./schema";

export const useSelectedStation = createPersistedState('selected');

const Container = styled.span`

    .open {
        ul {
            > li {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }
`

type Station = {
    id: number;
    code: string;
    name: string;
}

type Props = {
    showAllOption?: boolean;
    defaultValue?: string | number;
    onChange?: (arg?: any) => void;
    multiple?: boolean;
}
// const params = {actions: {response: {}}};
const StationPicker: React.FC<Props> = forwardRef(({
                                                       showAllOption = true,
                                                       ...props
                                                   }, ref) => {

    const value = props.defaultValue || null;
    const [selected, setSelected] = useSelectedStation(value);
    const [stations,] = useLocalStorage('stations', []);
    console.log('Render StationPicker');

    const getOptions = () => {
        let ret: Station[] = [];
        if (stations.length) {
            ret = ret.concat(stations.map(s => ({value: s.id, name: s.code})));
        }
        if (ret.length) {
            ret = ret.sort((a, b) => a.name.localeCompare(b.name))
        }
        console.log({options: ret});
        return ret;
    };

    const getDefaultValue = () => {
        let defaultValue = value || selected;
        if (props.multiple) {
            if (!Array.isArray(selected)) {
                defaultValue = [selected];
            }
        } else if (Array.isArray(selected) && selected.length > 0) {
            defaultValue = selected[0];
        }
        console.log({defaultValue});
        return defaultValue;
    }

    const handleChange = value => {
        console.log({handleChange: value});
        if (typeof props.onChange === 'function')
            props.onChange(value);

        setSelected(value);
    };


    if (!stations || (Array.isArray(stations) && stations.length === 0)) {
        return null;
    }

    return (
        <Container>
            <CustomDropdown
                options={getOptions()}
                size={'extraSmall'}
                label={'Station'}
                ref={ref}
                {...props}
                defaultValue={getDefaultValue()}
                onChange={handleChange}
            />
        </Container>
    )
});

export default StationPicker;
