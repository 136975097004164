import React, {Dispatch} from 'react';
import {Button, Icon, Input} from '@dspworkplace/ui';
import {TableData, TableFooter} from "../../../components/Table";
import {useForm} from "react-hook-form";

type Props = {
    dispatch: Dispatch<any>;
    data: any | Array<any>;
    selected?: any;
    index?: number;
    stationId: number;
    isEdit?: boolean;
    add: (arg?: any) => void;
    edit: (arg?: any) => void;
    cancel: (arg?: any) => void;
}

const AddAndEditForm: React.FC<Props> = (
    {
        selected,
        isEdit,
        cancel,
        add,
        edit
    }) => {

    const {register, handleSubmit, errors, reset, getValues, watch} = useForm(
        {
            defaultValues: {item: selected && selected.name ? selected.name : ''}
        });
    const {item} = getValues();
    const watchedItem = watch(['item']);

    const cancelBtn = async () => {
        if (!isEdit) {
            reset();
        }
        cancel();
    }

    //@ts-ignore
    const onSubmit = async form => {
        try {
            if (isEdit) {
                edit({id: selected.id, name: form.item});
            } else {
                add({id: Math.random(), name: form.item});
            }
        } catch (e) {
            console.error({e});
        }
    }
    const Form = (
        <form onSubmit={handleSubmit(onSubmit)}
              style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <Input
                name='item'
                ref={register({required: 'This field cannot be empty.'})}
                error={errors.item && errors.item.message}
                valid={!errors.item && item && item.length > 0}
                type='text'
            />
            <div>
                <Button type='primary'>{isEdit ? 'Save' : 'Add'}</Button>
                {(watchedItem.item && watchedItem.item.length > 0) || selected ? (
                    <Button type={'cancel'} onClick={() => {
                        cancelBtn().then();
                    }}>
                        <Icon.Times size={'20px'} style={{verticalAlign: 'middle'}}/>
                    </Button>
                ) : ''}
            </div>
        </form>
    );

    return isEdit ? (
        <li>
            <TableData width={'100%'}>
                {Form}
            </TableData>
        </li>
    ) : (
        <TableFooter>
            {Form}
        </TableFooter>
    )
};

export default AddAndEditForm;