import React, {useState} from 'react';
import {Theme, Title, SubTitle,Spacer, Icon, ButtonGroup, Button,} from "@dspworkplace/ui";
import moment from  'moment';
import styled from 'styled-components';
import Filter from './filter';
import Table from "./table.js";
import {AppProvider} from './ContextStore';
import {FilterWeekDate} from "../../../hooks/useGlobalData";

const Body = styled.div`
    box-sizing: border-box;
    min-height:100vh;
    margin-top:20px;
    display:flex;
    flex-direction:column;
    align-items: stretch;
    padding-left: 156px;
    padding-bottom:40px;
    max-width: 85vw;
    @media (max-width:1200px){
        padding-left:20px;
        padding-right:20px;
        max-width:calc(100vw - 20px);
    }
`;

const App = () => {
    const [view, setView] = useState('count');
    const [date] = FilterWeekDate();

    const nextWeek = moment(date).add('7','d').format('YYYY-MM-DD');
    return (
        <Body>
            <AppProvider>
                <Spacer bottom={5} style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Title>Schedule Balance</Title>
                    <ButtonGroup active={view}>
                        <Button onClick={()=>setView('count')} track={'count'}>D/RC</Button>
                        <Button onClick={()=>setView('average')} track={'average'}>Balance</Button>
                    </ButtonGroup>
                </Spacer>

                <Filter />
                <Spacer top={5} />
                <SubTitle>Current Week</SubTitle>
                <Spacer bottom={2} />
                <Table date={date} view={view} context={'current'}/>
                {view === 'average' &&
                    <>
                        <Spacer top={5} />
                        <SubTitle>Next Week</SubTitle>
                        <Spacer bottom={2} />
                        <Table date={nextWeek} view={view} context={'next'}/>
                    </>
                }
            </AppProvider>
        </Body>
    )
}

export default App;
