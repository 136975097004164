import {useCallback} from "react";
import apiFactory from '../../api/factory';
import {getCompanyId} from "../../Auth";

type CategoryInterface = {
    id: number
    label: string
}

type VehicleMaintenancePlanItemNotificationInterface = {
    id: number
    miles_prior: number
    days_prior: number
    modify_task_priority_to: string
    ground_van: boolean
}

export type VehicleMaintenancePlanItemInterface = {
    id: number
    required: boolean
    service_interval_days: number
    service_interval_miles: number
    category: CategoryInterface
    type: CategoryInterface
    notifications: VehicleMaintenancePlanItemNotificationInterface[]
}

export type VehicleMaintenanceCategoryInterface = {
    id: number,
    name: string,
    items: VehicleMaintenanceCategoryInterface[],
    parent: number,
    children: any
}

export type VehicleMaintenanceTypeInterface = {
    id: number
    name: string
    items: VehicleMaintenanceTypeInterface[]
}

const resource = 'category'
const {call, cancel} = apiFactory();
export const useVehicleMaintenanceCategory = () => {
    const fetchVehicleMaintenanceCategory = useCallback(({pageInfo,sorter,station_id}) => {
        return call(null,`api/v2/${resource}/?entity=VehicleMaintenancePlanItem&page=${pageInfo.page}&sortBy=${sorter}&limit=${pageInfo.limit}&company=${getCompanyId()}&level=0`,'get')
    },[])

    const fetchVehicleMaintenanceSubCategory = useCallback( ({ pageInfo, sorter, station_id }) => {
        let subCategory = call(null, `api/v2/${resource}/?entity=VehicleMaintenancePlanItem&page=${pageInfo.page}&sortBy=${sorter}&limit=${pageInfo.limit}&company=${getCompanyId()}&level=1`, 'get');

        return subCategory;
    },[])

    const addVehicleMaintenanceCategory = useCallback((newRow: Omit<VehicleMaintenanceCategoryInterface, "id">) => {
        newRow.company_id = parseInt(getCompanyId());
        if (newRow.stationId) {
            delete newRow.stationId;
        }
        return call(newRow,`api/v2/${resource}`,'post')
    },[])

    const updateVehicleMaintenanceCategory = useCallback((id: number, row) => {
        console.log(row);
        
        return call(row,`api/v2/${resource}/${id}`,'put')
    },[])

    const findVehicleMaintenanceCategory = useCallback((id: number)=>{
        return call(null,`api/v2/${resource}/${id}`,'get')
    },[])

    const deleteVehicleMaintenanceCategory = useCallback((id: number)=>{
        return call(null,`api/v2/${resource}/${id}`,'delete')
    },[])

    return {
        fetchVehicleMaintenanceCategory,
        fetchVehicleMaintenanceSubCategory,
        addVehicleMaintenanceCategory,
        updateVehicleMaintenanceCategory,
        findVehicleMaintenanceCategory,
        deleteVehicleMaintenanceCategory,
        cancelApiRequest: cancel
    }
}