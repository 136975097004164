import React from "react";
import {Grid, PrimaryButton} from "./styles";
import {Input, Spacer, CustomDropdown, Toggle} from "@dspworkplace/ui";
import PictureUploader from "./PictureUploader";
import {Label, TextArea} from "./styles";

type ChannelDetailsProps = {
    errorMessage?: string | null;
    inputRef: any;
    pictureUploaderRef: any;
    descriptionRef: any;
    showNextCallback: any;
    image?: string | null;
    updateNow?: boolean;
    update?: any;
    showNext?: boolean;
    singleChannelName?: string;
    channelKind?: string;
    isArchive?: boolean;
    isArchiveCallback?: any;
    unArchiveCallback?: any;
    isPin?: any;
    isPinCallback?: any;
    kind: any;
    numberOfButtons?: number;
    dropdownRef?: any;
    roles: Array<{ name: string; value: number; }>;
    permissions?: number[];
    notificationRef?: any;
};

function ChannelDetails(props: ChannelDetailsProps) {
    const {
        errorMessage,
        inputRef,
        pictureUploaderRef,
        descriptionRef,
        showNextCallback,
        image = null,
        updateNow = false,
        update,
        showNext = true,
        singleChannelName = "",
        channelKind = "Group",
        isArchive = null,
        isArchiveCallback,
        unArchiveCallback,
        isPin = null,
        isPinCallback,
        kind,
        dropdownRef,
        roles,
        permissions,
        notificationRef
    } = props;
    // console.log({roles});
    // console.log(JSON.stringify(roles));
    // const showArchiveAndPin = type === "Group" || type === "Duo" || type === "Pin";

    return (
        <Grid>
            {errorMessage && errorMessage.length > 0 && (
                <Grid color="#D94C53" fontSize="16px" height="16px" margin="0 0 8px 0">
                    {errorMessage}
                </Grid>
            )}
            {kind === 'Duo' && <Grid templateColumns="400px auto">
                {!showNext && kind === 'Duo' && (
                    <Grid templateColumns={"auto auto"} columnGap={"12px"} justifyContent={"flex-start"}>
                        <Label>Nickname: </Label>
                        <Grid fontSize="16px">{singleChannelName}</Grid>
                    </Grid>
                )}
                <PictureUploader
                    register={pictureUploaderRef}
                    name={"channelImage"}
                    id={"channelImage"}
                    image={image}
                    enable={channelKind === "Group"}
                />
            </Grid>}
            {kind !== 'Duo' && <Grid templateColumns="auto auto">
                {showNext && <Input type="search" name="channelName" label="Name" ref={inputRef}/>}
                <CustomDropdown
                    name='permissions'
                    label='Groups selected below can Read Only'
                    ref={dropdownRef}
                    // placeholder='All'
                    multiple={true}
                    options={roles}
                    // size={'big'}
                    defaultValue={permissions}
                />
            </Grid>}
            <Spacer top={5}/>
            {kind !== 'Duo' && <Grid templateColumns="auto auto">
                <Grid>
                    <Grid justifyContent="start" width="100%" height="16px" columnGap="4px" templateColumns="auto auto">
                        <Label>Description </Label>
                        <Label height="16px" fontSize="12px" alignSelf="end">
                            (optional)
                        </Label>
                    </Grid>
                    <TextArea
                        name="channelDescription"
                        label="Description"
                        ref={descriptionRef}
                        placeholder="You can a write a description about the channel ..."
                        width={'300px'}
                    />
                </Grid>
                <Grid margin={'4px 8px'}>
                    <PictureUploader
                        register={pictureUploaderRef}
                        name={"channelImage"}
                        id={"channelImage"}
                        image={image}
                        enable={channelKind === "Group"}
                    />
                </Grid>
            </Grid>}

            {kind !== 'Duo' && <Spacer top={8}/>}
            {kind !== 'Duo' && <Grid>
                <Toggle
                    name='channelNotification'
                    ref={notificationRef}
                    singleText='Mute Channel Notification For All Users'
                />
            </Grid>}
            {kind === 'Duo' && <Spacer top={8}/>}
            {kind !== 'Duo' && <Spacer top={32}/>}
            {kind === 'Duo' && <Grid
                templateColumns={`repeat(2, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
                margin={'100px 0 0 0'}
            >
                {isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Pin
                    </PrimaryButton>
                )}
                {!isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Unpin
                    </PrimaryButton>
                )}
            </Grid>}
            {kind === 'Group' && <Grid
                templateColumns={`repeat(4, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
            >
                {isArchive && (
                    <PrimaryButton className={"modal cancel"} type="primary" onClick={isArchiveCallback}>
                        Archive
                    </PrimaryButton>
                )}
                {typeof isArchive === "boolean" && !isArchive && (
                    <PrimaryButton className={"modal cancel"} type="primary" onClick={() => {
                        console.log('archive');
                        try {
                            if (typeof unArchiveCallback === 'function') {
                                unArchiveCallback();
                            } else if (typeof isArchiveCallback === 'function') {
                                isArchiveCallback();
                            }
                        } catch (e) {
                            console.error(e);
                        }

                    }}>
                        Unarchive*
                    </PrimaryButton>
                )}
                {isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Pin
                    </PrimaryButton>
                )}
                {typeof isPin === "boolean" && !isPin && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={isPinCallback}>
                        Unpin
                    </PrimaryButton>
                )}

                {updateNow && (
                    <PrimaryButton className={"modal"} type="primary" onClick={update}>
                        Update
                    </PrimaryButton>
                )}
                {showNext && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={showNextCallback}>
                        Next
                    </PrimaryButton>
                )}
            </Grid>}
            {kind === 'Create Channel' && <Grid
                templateColumns={`repeat(2, auto)`}
                columnGap="16px"
                justifyContent="flex-end"
                width="100%"
                height="40px"
            >
                {updateNow && (
                    <PrimaryButton className={"modal"} type="primary" onClick={update}>
                        Update
                    </PrimaryButton>
                )}
                {showNext && (
                    <PrimaryButton className={"modal"} type="secondary" onClick={showNextCallback}>
                        Next
                    </PrimaryButton>
                )}
            </Grid>}
        </Grid>
    );
}

export default ChannelDetails;
