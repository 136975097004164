import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Toggle, SubTitle, Spacer, Button } from "@dspworkplace/ui";
import SettingsContext from "../context";
import { getCompanyId, showToast } from "../../../Utilities";
import { toast } from "../../../components/Toasts";
import { useAuth } from "../../../Auth";
import { AccessCheck, IsGranted } from "../../../security";

const AllowsDispatcherPermission = () => {
    // @ts-ignore
    const { api } = useAuth();
    // @ts-ignore
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit } = useForm();
    const [loadingSave, setLoadingSave] = useState(false);
    const [allowsDispatcherPermission, setAllowsDispatcherPermission] = useState(true);

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            setLoadingSave(true);
            form.querySelector("button").disabled = true;
            let updateRecord = { allowsDispatcherPermission: data.allowsDispatcherPermission };
            setAllowsDispatcherPermission(data.allowsDispatcherPermission);
            api.post("/api/lazy/manage/data", {
                actions: {
                    update: {
                        CompanySetting: {
                            update_company_setting: {
                                findBy: {
                                    company: getCompanyId(),
                                },
                                updateRecord: updateRecord,
                            },
                        },
                    },
                },
            }).then((response) => {
                setLoadingSave(false);
                toast({
                    type: "success",
                    title: "Allows Dispatcher Permission",
                    content: "Updates completed",
                });
                localStorage.setItem("allowsDispatcherPermission", data.allowsDispatcherPermission);
                let companySetting = state.companySetting;
                state.companySetting.allowsDispatcherPermission = data.allowsDispatcherPermission;
                dispatch({
                    type: "UPDATE_COMPNY_SETTING",
                    payload: companySetting,
                });
            })
            .then(() => (form.querySelector("button").disabled = false));
        } catch (e) {
            await showToast({ type: "error", title: "Error", content: JSON.stringify(e) });
            form.querySelector("button").disabled = false;
            setLoadingSave(false);
        }
    };

    useEffect(() => {
        setAllowsDispatcherPermission(state.companySetting?.allowsDispatcherPermission);
    }, [state]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Allows Dispatcher Permission</SubTitle>
            <Spacer bottom={3} />
            <Toggle
                ref={register}
                name="allowsDispatcherPermission"
                singleText="Allows Dispatcher permission, in the Scheduler views, to click through a warning message and assign a Shift Type to a driver without the required skill."
                on={allowsDispatcherPermission}
                onChange={(e) => {
                    setAllowsDispatcherPermission(e.target.checked);
                }}
            />
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type="primary">{loadingSave ? "Saving ..." : "Save"}</Button>
            </IsGranted>
        </form>
    );
};

export default AllowsDispatcherPermission;
