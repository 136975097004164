import React from "react";
import ActionIcon from "../../UI/actionIcon";
// @ts-ignore
import style from './actions.module.css';

const Actions = ({options}) => {
    return (
        <div className={style.content}>
            <ActionIcon options={options} />
        </div>
    )
}

export default Actions