import moment from "moment-timezone";

export function formatDevices(devices: any[] | any) {
    if (Array.isArray(devices)) {
        return devices.map(e => {
            if (e.usedAt && e.usedAt.timestamp) {
                return {...e, usedAt: moment.utc(e.usedAt.timestamp * 1000).local().format('YYYY-MM-DD')};
            } else if (e.updatedAt && e.updatedAt.timestamp) {
                return {...e, usedAt: moment.utc(e.updatedAt.timestamp * 1000).local().format('YYYY-MM-DD')};
            } else if (e.createdAt && e.createdAt.timestamp) {
                return {...e, usedAt: moment.utc(e.createdAt.timestamp * 1000).local().format('YYYY-MM-DD')};
            }
            return {...e, usedAt: null};
        })
    }
    return devices;
}

export function sortByDates(date1: string, date2: string) {
    // @ts-ignore
    return new Date(date1) - new Date(date2);
}

type Criteria = {
    field: string;
    direction: number; //1 ORDER BY DESC and 0 ORDER BY ASC
}

export const sortBy = (devices: any[], arrCriteria: Criteria[]) => {
    arrCriteria.forEach(c => {
        let d = c.direction;
        devices.sort((f, s) => {
            switch (c.field) {
                case 'station':
                    return d === 0
                        ? f.station.code.localeCompare(s.station.code)
                        : s.station.code.localeCompare(f.station.code);
                default:
                    if (d === 0) {
                        if (f[c.field] && s[c.field]) {
                            return f[c.field].toString().localeCompare(s[c.field].toString(), undefined, {numeric: true});
                        } else
                            return 1000;
                    } else {
                        if (f[c.field] && s[c.field]) {
                            return s[c.field].toString().localeCompare(f[c.field].toString(), undefined, {numeric: true});
                        } else
                            return 1000;
                    }
            }
        });
    });

    return devices;
};