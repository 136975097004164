import {GridColumnProps} from "../types";
import {useMemo} from "react";

type selectedGroupsProp = readonly string[]

const hideOnModifier = (column,selectedGroups: selectedGroupsProp) => {
    if(column.hideOn === 'grid'){
        return selectedGroups.length < 1;
    }
    if(column.hideOn === 'group' && selectedGroups.length > 0){
        return selectedGroups[0] !== column.key
    }
    return false
}


const useColumnResolver = (columns: GridColumnProps[], selectedGroups:selectedGroupsProp = []) => {
    return useMemo(() => {
        const groupsAvailable: GridColumnProps[] = []
        const filtersAvailable: GridColumnProps[] = []
        const legendsAvailable: GridColumnProps[] = []
        const newColumns: GridColumnProps[] = []
        for (let i = 0; i < columns.length; i++) {
            let column = columns[i]
            if (column.groupable){
                if(column.width){
                    // @ts-ignore
                    column.width = column.width + 5
                }

                if(column.minWidth){
                    // @ts-ignore
                    column.minWidth += 5
                }
                groupsAvailable.push(column)
            }
            if (column.filterable) filtersAvailable.push(column)
            if (column?.legend) legendsAvailable.push(column)
            if(!hideOnModifier(column,selectedGroups)){
                if(column.editor && !column.editorOptions) {
                    column.editorOptions = {}
                }
                // @ts-ignore
                if(column.editor && !column?.editorOptions.editOnClick) {
                    // @ts-ignore
                    column.editorOptions.editOnClick = true
                }

                newColumns.push(column);
            }
        }
        return {groupsAvailable,filtersAvailable,legendsAvailable,columnsArray:newColumns}
    }, [columns,selectedGroups])
}

export default useColumnResolver