import {EditorProps} from "react-data-grid";
import React, {useEffect, useState, useRef, useCallback} from "react";
import Modal from '../../../Modal'
import {Input, Spacer, Button} from '@dspworkplace/ui'
import {createPortal} from "react-dom";

const TextModal = (props:EditorProps<any>) => {
    const {column ,row, onClose, onRowChange} = props;

    const handleColumChange = (text) => {
        row[column.key] = text
        onRowChange({...row,[row[column.key]]:text},true)
        onClose()
    }

    const handleCancel = () => {
        onClose()
    }

    // @ts-ignore
    return createPortal(<TextEditorModal title={column.name} value={row[column.key]} handleSubmit={handleColumChange} handleCancel={handleCancel} />,document.getElementById('modal-root'))
}

const TextEditorModal = ({title,value,handleSubmit,handleCancel}) => {
    const [newText, setNewText] = useState(value)
    const inputRef = useRef(null)

    const handleBlur = (e) => {
        setNewText(e.target.value)
    }

    const handleButtonClick = () => {
        handleSubmit(newText)
    }

    const handleEnter = useCallback((e)=>{
        e.stopPropagation();

        if(e.keyCode === 13) {
            handleSubmit(e.target.value)
        }

        if(e.keyCode === 27) {
            handleCancel()
        }
    },[handleSubmit])

    useEffect(()=>{
        if(!inputRef?.current) return

        // @ts-ignore
        inputRef.current.focus();
        // @ts-ignore
        inputRef.current.addEventListener('keydown',handleEnter)

        return () => {
            // @ts-ignore
            inputRef.current.removeEventListener('keydown',handleEnter)
        }
    },[handleEnter, inputRef])

    return (
        <Modal
            width={'340px'}
            visible={true}
            title={`Editing ${title}`}
            closeOnOverlay={true}
            setVisible={handleCancel}
        >
            <Input label={title} as={'textarea'} defaultValue={newText} onBlur={handleBlur} ref={inputRef} />
            <Spacer top={4}>
                <Button type={'primary'} onClick={handleButtonClick} style={{display:'block',marginLeft:'auto'}}>Save changes</Button>
            </Spacer>
        </Modal>
    )
}

export default TextModal