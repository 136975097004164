import React from 'react';
import styled from 'styled-components';
import {Icon, Button} from '@dspworkplace/ui';
import {useForm} from "react-hook-form";
import {useTrackedState as useBotState} from "./BotStore";
import {DSPButtonContainer} from "./InputMessage";

const Form = styled.form`
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 100px 40px;
    grid-row-gap: 8px;
    margin-right: 20px;
    margin-left: 20px;
    border-top: 1px dashed #7C98B6;
    padding: 8px 0 8px 0;
    max-height: 173px;
`;

const Input = styled.textarea`
    border: 1px solid #CCCCCC !important;
    border-radius: 2px;
    opacity: 1;
    width: 300px;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 12px;
    position: relative;
    z-index: 7;

    &:hover, &:hover, &:focus, &:active {
        border: 1px solid #CCCCCC;
    }

    .autocomplete {

        color: silver;
        z-index: 1;
    }
`;

const Send = styled.div`
    display: grid;
    width: 300px;
    height: 40px;
    grid-template-columns: 40px 40px auto;
    grid-template-rows: 40px;
    grid-column-gap: 8px;
    border-radius: 2px;
`;

const SendButton = styled.div`
    width: fit-content;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-self: end;
`;
// @ts-ignore
const ParallelBorder = styled.div`
    border-color: #CBD6E2;
    border-style: dashed;
    width: 0;
    height: 40px;
    border-width: 1px;
    background-color: #EAF0F6;
`;

const CustomButton = styled(Button)`
    width: ${props => props.width ? props.width : '40px'};
    height: ${props => props.height ? props.height : '40px'};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.padding ? props.padding : '0'};;
    justify-self: ${props => props.direction ? props.direction : 'end'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-right-radius: ${props => props.bbrr ? props.bbrr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-right-radius: ${props => props.btrr ? props.btrr : props.borderRadius ? props.borderRadius : '2px'};
    border-bottom-left-radius: ${props => props.bblr ? props.bblr : props.borderRadius ? props.borderRadius : '2px'};
    border-top-left-radius: ${props => props.btlr ? props.btlr : props.borderRadius ? props.borderRadius : '2px'};

    &:active, &:focus, &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }
`;

const LabelFile = styled.label`
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    background-color: #EAF0F6;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};

    &:hover {
        background-color: #CBD6E2;
    }
`;

const InputFile = styled.input`
    display: none;
`;

// const OpenSMSViewContainer = styled.div`
//   background: #FFFFFF 0 0 no-repeat padding-box;
//   border: 1px solid #CCCCCC;
//   box-shadow: 0 0 6px #CCCCCC;
//   border-radius: 2px;
//   color: #707070;
//   font-size: 14px;
//   padding: 2px 8px;
//   position: relative;
//   top: -64px;
//   right: 8px;
//   cursor: pointer;
// `;
//
// const DSPButtonContainer = styled.div`
//     width: 40px;
//     height: 40px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     padding: 0;
//     margin: 0;
// `;
//
//
// const OpenOptionsDSPContainer = styled.div`
//   background: #FFFFFF 0 0 no-repeat padding-box;
//   border: 1px solid #CCCCCC;
//   box-shadow: 0 0 6px #CCCCCC;
//   border-radius: 2px;
//   color: #707070;
//   font-size: 14px;
//   padding: 2px 8px;
//   position: absolute;
//   bottom: 58px;
//   width: 170px;
//
//   div{
//     height: 32px;
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//   }
//
// `;

type Props = {
    submitMessage?: (message: string) => void;
    disabled?: boolean;
    placeholder?: string;
    submitButtonText?: string;
    disableSendButton?: boolean;
}

const InputForBot = ({
                         submitMessage,
                         disabled = true,
                         placeholder = "Thank You",
                         submitButtonText = "Send",
                         disableSendButton = false
                     }: Props) => {
    const state = useBotState();
    const {conversation} = state;
    const fileUpload = `${conversation!.sid}-file-upload`;
    const {register, handleSubmit, reset} = useForm();
    // const smsRef = useRef<HTMLDivElement>();
    // const [openSMSView, setOpenSMSView] = useState<boolean>(false);
    // const [openOptionsDSP, setOpenOptionsDSP] = useState<boolean>(false);

    const submit = async (form) => {
        let message = form.message;
        if (typeof message === "string" && message.trim().length !== 0) {
            if (submitMessage) {
                await submitMessage(message);
                reset();
            }
        } else {
            if (submitMessage) {
                await submitMessage(placeholder);
                reset();
            }
        }
        reset();
    };

    function submitMedia(event) {
        if (event.target.files.length > 0) {
            let files = event.target.files;
            console.log({files});
        }
        return false;
    }

    const onKeyDownHandler = async e => {
        let value = e.target.value;
        if (e.keyCode === 13 && e.shiftKey) {
            value.concat('\n');
        } else if (e.keyCode === 13 && !e.shiftKey && value && value.trim().length !== 0) {
            let message = value.trim();
            if (submitMessage) {
                await submitMessage(message);
            }
            reset();
        } else {
            conversation!.typing();
        }
    };

    const onChangeHandler = e => {
        let value = e.target.value;
        if (value.charAt(0) === '\n' || value.charAt(0) === '\r') {
            reset();
            return;
        }
        // if(typeof value === "string" && value.includes(placeholder)){
        //     setValue({message: placeholder});
        // }
    };

    return (
        <>
            <Form className="form" onSubmit={handleSubmit(submit)}>
                <Input
                    placeholder={placeholder}
                    autoFocus={true}
                    name='message'
                    label='message'
                    ref={register({required: false})}
                    disabled={disabled}
                    onKeyDown={onKeyDownHandler}
                    onChange={onChangeHandler}
                    // value={placeholder}
                />
                {/*<Input className="autocomplete" value={placeholder} disabled={true}/>*/}
                <Send>
                    <LabelFile htmlFor={fileUpload}>
                        <Icon.Image size='21px' color='##0071BC'/>
                    </LabelFile>
                    <InputFile
                        id={fileUpload}
                        type="file"
                        onChange={e => submitMedia(e)} multiple/>
                    <DSPButtonContainer>
                        <CustomButton>
                            <Icon.DspwNetwork size='21px' color='#F7931E'/>
                        </CustomButton>
                    </DSPButtonContainer>
                    <SendButton>
                        <CustomButton type='submit' width={"100%"}
                                      padding={"0 8px 0 8px"}
                                      disabled={disableSendButton}>{submitButtonText}</CustomButton>
                        {/*<ParallelBorder/>*/}
                    </SendButton>
                </Send>
            </Form>
        </>
    )
};

export default InputForBot;
