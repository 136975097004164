import apiFactory from "../../../api/factory";
import {getCompanyId} from "../../../Auth";

const {call, cancel} = apiFactory();

const fetchAllSettings = async (selectedStation) => {
    const params = {
        actions: {
            response: {
                VanAutoAssignmentSetting:{
                    custom: {
                        functionName: "getVanAutoAssignmentSetting",
                        get: "settings",
                        criteria: {
                            company: getCompanyId(),
                            station: selectedStation
                        }
                    }
                }
            }
        }
    }

    try {
        const response = await call(params);
        return response.data.data.settings;
    } catch (error) {

    }
}
const getVanAutoAssignmentDateData = async (id,date) => {
    const params = {
        actions: {
            response: {
                VanAutoAssignmentSetting:{
                    custom: {
                        functionName: "getVanAutoAssignmentDateData",
                        get: "settings",
                        criteria: {
                            company: getCompanyId(),
                            id: id,
                            date: date
                        }
                    }
                }
            }
        }
    }

    try {
        const response = await call(params);
        return response.data.data.settings;
    } catch (error) {

    }
}

const saveAllSettings = async (data,station) => {
    const params = {
        actions: {
            response: {
                VanAutoAssignmentSetting:{
                    custom: {
                        functionName: "saveVanAutoAssignmentSetting",
                        get: "saveSetting",
                        criteria: {
                            company: getCompanyId(),
                            station: station,
                            data
                        }
                    }
                }
            }
        }
    }

    try {
        const response = await call(params);
        return response.data.data.saveSetting;
    } catch (error) {

    }
}

const loadFixtures = async (selectedStation) => {
    try {
        await call({
            actions: {
                response: {
                    VanAutoAssignmentSetting: {
                        custom: {
                            functionName: "loadFixtures",
                            get: "result",
                            criteria: {
                                company: getCompanyId(),
                                station: selectedStation
                            }
                        }
                    }
                }
            }
        });
    } catch (e) {

    }
}

export {
    cancel,
    fetchAllSettings,
    getVanAutoAssignmentDateData,
    saveAllSettings,
    loadFixtures
}
