import React, {useEffect, useContext, useState, useCallback, useMemo, useRef} from "react";
import styled from "styled-components";
import { Theme, Button, Icon, Spacer, Text, ThirdTitle, SubTitle, Title, Dropdown, Input } from "@dspworkplace/ui";
import { Table, TableData, TableFooter, TableHeader } from "../../../components/Table";

import Empty from "../../../components/Empty";
import { useAuth, getUserId } from "../../../Auth";
import Loading from "../../../components/Loading";
import {confirm as confirmPortal} from "../../../components/Confirm";

import { Link } from "../../../components/UI";
import { fetchVehicles, deleteVehicle, updateVehicle } from "./api";
import { extraFields, showVehicleForm, normalizeDate } from "./form";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import Store, { VehicleProvider } from "./Context";
import Filter from "./Filter";
import { statusIcon } from "./helper";
import Modal, { useModal } from "../../../components/Modal";
import Csv from "./csv";
import moment from "moment";
import Timeline from "../../../components/Timeline";
import { engine } from "../../../Utilities";
import toast from "../../../components/Toasts/toast";
import { dialog } from "../../../components/Dialog";
import {useRecoilValue} from "recoil";
import {VehicleMaintenancePlan} from "../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlan";
import {Combobox} from "../../../components/Form/Combobox";
import {AssignPlanToVehicle} from "../../../domain/vehicle/api/assignPlan";
import {openPlanForm} from "./PlanForm";
import {tokenState} from "../../../domain/security/state/Token";
import {planDetailsState} from "../../../state/planDetail";
import Tooltip from "../../../components/Tooltip";
import { Btn } from "../../../components/Grid/Tools/Filter/Filter";

const Wrapper = styled.div`
    min-height: 100vh;
`;



const App = () => {
    const [open, setOpen] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    return (
        <VehicleProvider>
            <Wrapper>
                <Spacer top={5} />
                <Title>Vehicles</Title>
                <Spacer top={3} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Text>Any type of vehicle that you own, rent, or lease will be displayed here.</Text>
                    <div style={{ display: "flex", marginTop: "-50px" }}>
                        <Filter setIsButtonClicked={setIsButtonClicked} isButtonClicked={isButtonClicked} open={open} setOpen={setOpen} />
                    </div>
                </div>
                <Spacer top={10} />
                <VehicleList />
            </Wrapper>
        </VehicleProvider>
    );
};

const VehicleList = () => {
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();
    const companyId = localStorage.getItem("company");
    const [csvVisible, toggleCsv] = useModal(false);
    const [selectedStation] = useSelectedStation();
    const permissions = useRecoilValue(tokenState)
    const selectedDate = state.selectedDate;

    const tableHeaders = useMemo(() => {
        const header = [
            { width: "80px", label: "Status", sortBy: "status" },
            { width: "116px", label: "Vehicle ID", sortBy: "vehicleId" },
            { width: "300px", label: "Vehicle", sortBy: "model" },
            //{ width: "224px", label: "Location", sortBy: "station" },
            { width: "72px", label: "Type", sortBy: "type" },
            { width: "200px", label: "Assigned PM Template"},
            { width: "200px", label: "Status", centered: true },
            { width: "200px", label: "" },
        ]

        if(!permissions?.features?.includes('VEHICLE_MAINTENANCE_PLAN')){
            if([1560, 2696].includes(getUserId())){
                return header
            }
            header.splice(4,1)
        }
        return header
    },[permissions])

    const fetchAll = async () => {
        if (!state.isLoading) dispatch({ type: "LOADING", payload: { status: true } });
        const result = await fetchVehicles(api, companyId, selectedStation, selectedDate);
        dispatch({ type: "ADD", payload: { data: result, station: selectedStation } });
    };

    const handleAdd = () => {
        showVehicleForm({ api: api }).then(
            (vehicle) => {
                dispatch({ type: "NEW", payload: vehicle[0] });
            },
            (cancel) => () => {}
        );
    };

    useEffect(() => {
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation]);

    const setSortBy = (field) => {
        dispatch({
            type: "SET_SORT_BY",
            payload: field,
        });
    };

    return (
        <Table style={{height:"100vh"}}>
            <TableHeader
                headers={tableHeaders}
                sortByFunc={setSortBy}
                sortedBy={state.sortBy}
            />
            {state.isLoading && <Loading style={{ height: 96, padding: 176 }} />}
            {!state.isLoading && state.list.length < 1 && <Empty />}
            {!state.isLoading &&
                state.list.length > 0 &&
                state.list.map((data, key) => <VehicleItem data={data} key={data.id} index={key}/>)}

            <TableFooter sticky={true}>
                <ThirdTitle style={{ fontWeight: "normal" }}>
                    {state.list.length} Vehicle{state.list.length > 1 && "s"}
                </ThirdTitle>
                <Button
                    type="primary"
                    style={{ marginLeft: "69%" }}
                    onClick={() => {
                        toggleCsv(true);
                    }}
                >
                    Import CSV
                </Button>
                <Button type="primary" onClick={handleAdd}>
                    Add Vehicle
                </Button>
            </TableFooter>
            {csvVisible && <Csv toggleCsv={toggleCsv} csvVisible={csvVisible} />}
        </Table>
    );
};

const MiniTable = styled.ul`
    margin: 0;
    padding: 0;

    > li {
        display: block;
        border: none;
        padding-bottom: 8px;

        &:last-child {
            padding-bottom: 0;
        }

        > span {
            display: inline-block;
            width: ${(props) => (props.info ? "55px" : "45px")};
            color: #707070;
            font-size: 14px;
        }
        > p {
            display: inline-block;
            font-size: 14px;
            color: #333333;
        }
    }
`;

const MaintenanceCombobox = ({ vehicleInternalId, vehicleID, plan, isVehicleServiceRecordChanged }) => {
    
    const [state, dispatch] = useContext(Store);
    const ref = useRef(null);
    const [selectedStation] = useSelectedStation();
    const MaintenanceList = useRecoilValue(VehicleMaintenancePlan(selectedStation));
    const [currentPlan,setCurrentPlan] = useState(plan);
    const { api } = useAuth();
    const initalPlan = plan;
    let serviceIntervals = [];
    
    const handleChange = useCallback(async (plan)=>{
        if (plan?.value != null) {
            
            const params = {
                actions: {
                    response: {
                        "Vehicle": {
                            find: {
                                get: "vehicleServices",
                                id: vehicleInternalId,
                                includes: {
                                    0: "id",
                                    vehicleServiceRecords: {
                                        0: "id",
                                        category: {
                                            0: "id",
                                            1: "label"
                                        },
                                        2: "lastMile",
                                        3: "lastServiceDate"
                                    }
                                }
                            },
                        },
                        "Vehicle\\VehicleMaintenancePlan": {
                            find: {
                                get: "vehicleMaintenancePlan",
                                id: plan.value,
                                includes: {
                                    0: "id",
                                    items: {
                                        0: "id",
                                        category: {
                                            0: "id",
                                            1: "label",
                                            2: "isLastMileServiceRequired",
                                            3: "isLastServiceDateRequired"
                                        }
                                    }
                                }
                            },
                        }
                    }
                }
            };
                    
            let response = await api.post("/api/lazy/manage/data", params);
            const vehicleServiceRecords = response.data.data.vehicleServices?.vehicleServiceRecords;
            const planItemsData = response.data.data.vehicleMaintenancePlan?.items;
            const planItems = planItemsData.reduce((unique, item) => {
                const { planItemsWithNotRequiedLastMileAndLastDate, planItemsWithRequiedLastMileOrLastDate } = unique;
                if (!item.category.isLastMileServiceRequired && !item.category.isLastServiceDateRequired) {
                    if (!planItemsWithNotRequiedLastMileAndLastDate.some(obj => obj.category && obj.category.id === item.category.id)) {
                        planItemsWithNotRequiedLastMileAndLastDate.push(item)
                    }
                } else {
                    if (!planItemsWithRequiedLastMileOrLastDate.some(obj => obj.category && obj.category.id === item.category.id)) {
                        const found = vehicleServiceRecords.find(record => record.category.id === item.category.id);
                        if (found) {
                            planItemsWithRequiedLastMileOrLastDate.push({ ...item, ...{ lastMile: found.lastMile, lastServiceDate: found.lastServiceDate } });
                        } else {
                            planItemsWithRequiedLastMileOrLastDate.push(item);
                        }
                    }
                }
                return { planItemsWithNotRequiedLastMileAndLastDate, planItemsWithRequiedLastMileOrLastDate };
            }, { "planItemsWithNotRequiedLastMileAndLastDate": [], 'planItemsWithRequiedLastMileOrLastDate': [] });
        
            
            if (planItems.planItemsWithRequiedLastMileOrLastDate.length > 0) {
                const formResult = await openPlanForm({ planItems: planItems.planItemsWithRequiedLastMileOrLastDate })
                if (formResult) {
                    serviceIntervals = formResult
                } else {
                    setCurrentPlan(null);
                    ref.current.reset(initalPlan ? initalPlan.value : null);
                    return false;
                }
            }
            
            if (planItems.planItemsWithNotRequiedLastMileAndLastDate.length > 0) {
                const planItemId = planItems.planItemsWithNotRequiedLastMileAndLastDate.map(planItem => ({ item_id: planItem.id }))
                serviceIntervals = [...serviceIntervals, ...planItemId]
            }

            if (!serviceIntervals) {
                const confirm = await confirmPortal({
                    icon: true,
                    text: `Do you really want to change vehicle template to '${plan.label}'`
                })
                if (!confirm) {
                    setCurrentPlan(null);
                    ref.current.reset(initalPlan ? initalPlan.value : null);
                    return false;
                }
            }
        } 

        setCurrentPlan(plan)
        AssignPlanToVehicle({
            maintenance_template_id:plan.value,
            vehicle_id: vehicleInternalId,
            service_interval: serviceIntervals.map(service => ({
                mileage: parseFloat(service.miles),
                item_id: parseInt(service.item_id),
                date: service.date
            }))
        }).then((response)=>{
            if(!response?.data) return;
            //unset isVehicleServiceRecordChanged 
            const vehicles = [...state?.vehicles] || [];
            const currIndex = vehicles?.findIndex(veh => veh.id === vehicleInternalId);
            const data = vehicles[currIndex];
            data.isVehicleServiceRecordChanged = false;
            dispatch({ type: "EDIT", payload: { data: data, index: currIndex } });
          
            toast({
                type: "success",
                title: `Plan '${plan.label}' assign to vehicle ${vehicleID} `,
                content: `It can take a few minutes to generate all tasks`,
                useClose: true,
                timeout: 5000
            });
        }).catch((error)=>{
            setCurrentPlan(null)
            toast({
                type: "error",
                title: `Error to assign plan '${plan.label}' to vehicle ${vehicleID} `,
                content: error?.response?.data.message,
                useClose: true,
                timeout: 5000
            });
        })
    },[currentPlan, vehicleID, vehicleInternalId])
   
    return (
            <> 
                <Combobox
                    ref={ref}
                    size={'small'}
                    options={MaintenanceList}
                    placeholder={'None'}
                    onChange={handleChange}
                    initialValue={currentPlan ? currentPlan?.value : plan?.value}
                    hasError={isVehicleServiceRecordChanged}
                />
                {isVehicleServiceRecordChanged  === true && plan && <Spacer inline left={1}>
                <Tooltip content={'There is some changes in your Assigned PM Template. Please check and update required changes.'}>
                <Icon.Warning 
                    size='20px' 
                    color={"#F2545B"}
                    onClick={(() => handleChange(plan))}
                /></Tooltip></Spacer>}
            </>
    )
}

const VehicleItem = ({ data, index }) => {
    const [state, dispatch] = useContext(Store);
    const [visible, toogleModal] = useModal(false);
    const { api } = useAuth();
    const [showTimeline, setShowTimeline] = useState(false);
    // const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [dataTimeline, setDataTimeline] = useState(null);

    const permissions = useRecoilValue(tokenState)

    const hasVehicleMaintenance = useMemo(() => {
        if([1560, 2696].includes(getUserId())){
            return true;
        }
       return permissions?.features?.includes('VEHICLE_MAINTENANCE_PLAN')
    },[permissions])


    const handleEdit = (data, index) => {
        showVehicleForm({ api: api, vehicle: data, oldVehicleId: data.vehicleId }).then(
            (vehicle) => {
                if (data.stationId != vehicle.station.id) {
                    dispatch({ type: "REMOVE", payload: { id: vehicle.id } });
                } else {
                    const vehicles = [...state?.vehicles] || [];
                    const currIndex = vehicles?.findIndex(veh => veh.id === vehicle.id);
                    dispatch({ type: "EDIT", payload: { data: vehicle, index: currIndex } });
                    if (state?.appliedFilters) {
                        dispatch({ type: "SEARCH", payload: state?.appliedFilters });
                    }
                }
            },
            (cancel) => () => {}
        );
    };
    const handleDeletion = async (id, title) => {
        let confirmation = await confirmPortal(`Confirm you want to delete Vehicle number ${title} ?`);
        if (confirmation) {
            await deleteVehicle(api, id);
            dispatch({ type: "REMOVE", payload: { id: id } });
        }
    };

    const handleTimeline = useCallback(async (data) => {
        try {
            setShowTimeline(true);
            let response = await engine().post("/api/lazy/get_timeline_events", {
                vehicleId: data.id,
                timezone: moment.tz.guess(),
                timezoneOffset: new Date().getTimezoneOffset(),
            });
            setDataTimeline(response.data);
            // setLoadingTimeline(true);
        } catch (e) {
            // setLoadingTimeline(false);
            setShowTimeline(false);
        }
    }, []);

    // const toggleTimeline = useCallback(
    //     () => {
    //         setShowTimeline(!showTimeline);
    //     },
    //     [showTimeline],
    // );

    let employeeStatusArr = [
        { value: "Archived", name: "Archived" },
        { value: "Under Repair", name: "Under Repair" },
        { value: "Inactive", name: "Inactive" },
        { value: "Grounded", name: "Grounded" },
        { value: "Being Serviced", name: "Being Serviced" },
        { value: 'Body Shop', name: 'Body Shop'},
        { value: "Active", name: "Active" },
    ];

    const updateVehicleData = (id, newStatus) => {
        const vehicles = [...state?.vehicles] || [];
        const currIndex = vehicles?.findIndex(veh => veh.id === id);
        const data = vehicles[currIndex];
        data.status = newStatus;
        dispatch({ type: "EDIT", payload: { data: data, index: currIndex } });
        if (state?.appliedFilters) {
            dispatch({ type: "SEARCH", payload: state?.appliedFilters });
        }
    };

    const modalHandler = async (id, vehicle, newStatus, actualSatus, vehicleId) => {
        let confirmation = await dialog({
            text: "You are about to change Vehicle ID " + vehicle + " status to " + newStatus,

            fields:
                actualSatus == "Archived"
                    ? [{ field: Input, props: { name: "reason", label: "Reason to un-archive:", Required: true } }]
                    : [],

            buttons: [
                {
                    type: "primary",
                    text: "Yes",
                },
                {
                    text: "No",
                    callback: () => {
                        const vehicles = [...state?.vehicles] || [];
                        const currIndex = vehicles?.findIndex(veh => veh.id === id);
                        const data = vehicles[currIndex];
                        dispatch({ type: "EDIT", payload: { data: data, index: currIndex } });
                        return false;
                    },
                },
            ],
        });

        if (confirmation != false) {
            await updateVehicle(api, id, newStatus, vehicleId, confirmation.data.reason).then((answer) => {
                if (answer) {
                    updateVehicleData(id, newStatus);
                    return toast({
                        type: "success",
                        title: "Vehicle status changed successfully.",
                    });
                }
            });
        }
    };

    return (
        <li style={{ display: "flex" }}>
            <TableData center width="80px" alt={data.status}>
                {statusIcon[data.status.replace(/\s/g, "")] ? (
                    statusIcon[data.status.replace(/\s/g, "")]
                ) : (
                    <Icon.Info size="20px" />
                )}
            </TableData>
            <TableData width="116px">
                <Text>{data.vehicleId}</Text>
            </TableData>
            <TableData width="300px">
                {data.vehicleId && (
                    <MiniTable>
                        <li>
                            <span>ID</span> <p>{data.vehicleId}</p>
                        </li>
                        <li>
                            <span>VIN</span> <p>{data.vin}</p>
                        </li>
                        <li>
                            <span>Model</span> <p>{data.model}</p>
                        </li>
                        <li>
                            <span>State</span>
                            <p>{data.state}</p> <span />
                            <span>Plate</span> <p>{data.licensePlate}</p>
                        </li>
                        <li>
                            <span style={{ width: "60px" }}>Mileage</span>{" "}
                            <p>{data.currentMileage ? data.currentMileage : "N/A"}</p>
                        </li>
                        <li>
                            <span style={{ width: "100px" }}>Gas Tank Level</span>{" "}
                            <p>{data.currentGasTankLevel ? data.currentGasTankLevel : "N/A"}</p>
                        </li>
                        <li>
                            <span style={{ width: "110px" }}>Current Used By</span>{" "}
                            <p>{data.currentUsedBy ? data.currentUsedBy : "N/A"}</p>
                        </li>
                    </MiniTable>
                )}
            </TableData>
            {/*<TableData width="224px">
                {data.station && (
                    <MiniTable>
                        <li>
                            <p>At Station</p>
                        </li>
                        <li>
                            <Tag options={[data.station.code]} />
                            <Spacer inline left={1}>
                                <ThirdTitle> {data.station.name}</ThirdTitle>
                            </Spacer>
                        </li>
                        <li>
                            <p>{data.station.stationAddress}</p>
                        </li>
                    </MiniTable>
                )}
            </TableData>*/}
            <TableData width="72px">
                <Text>{data.type}</Text>
            </TableData>
            {hasVehicleMaintenance && <TableData width="200px" style={{alignSelf:'center'}}>
                <React.Suspense fallback={<Text>Loading Maintenance Plans</Text>}>
                    <MaintenanceCombobox vehicleInternalId={data.id} vehicleID={data.vehicleId} plan={data.maintenancePlan ? {value:data.maintenancePlan.id,label:data.maintenancePlan.name} : null} isVehicleServiceRecordChanged={data.isVehicleServiceRecordChanged}/>
                </React.Suspense>
            </TableData>}
            <TableData width="200px">
                <Dropdown
                    name="employee_status"
                    placeholder="Select Employee Status"
                    options={employeeStatusArr}
                    defaultValue={data.status.toString()}
                    size="small"
                    onChange={(newStatus) =>
                        modalHandler(
                            data.id,
                            data.vehicleId,
                            newStatus.value,
                            data.status.toString(),
                            data.vehicleId.toString()
                        )
                    }
                />
            </TableData>

            <TableData width="200px" style={{ marginLeft: "auto" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", cursor: "pointer" }}>
                    <Icon.Expand size="26px" color={Theme.colors.info.border} onClick={toogleModal} />
                    <Icon.TimeLine size="26px" color={Theme.colors.info.border} onClick={() => handleTimeline(data)} />
                    <Icon.Edit
                        id={`edit-vehicle-${JSON.stringify(data.id)}`}
                        data-id={`edit-vehicle-${JSON.stringify(data.id)}`}
                        size="26px"
                        color={Theme.colors.info.border}
                        onClick={() => handleEdit(data, index)}
                    />
                    {/* <Icon.Trash
                        size="26px"
                        color={Theme.colors.error.border}
                        onClick={() => handleDeletion(data.id, data.vehicleId)}
                    /> */}
                    <Link to={`vehicle-image/` + data.id}>
                        <Icon.Image size="26px" color={Theme.colors.info.border} />
                    </Link>
                </div>
            </TableData>
            {visible && <ShowVehicleDetail visible={visible} setVisible={toogleModal} vehicle={data} />}
            {showTimeline && (
                <Timeline
                    visible={showTimeline}
                    setVisible={setShowTimeline}
                    title={<SubTitle>Vehicle ID: {data.vehicleId} - Timeline</SubTitle>}
                    loading={!dataTimeline}
                    data={dataTimeline}
                />
            )}
        </li>
    );
};

const ListInfo = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
        font-size: 14px;
        color: #333333;
        margin-bottom: 8px;
        display: flex;

        > span {
            color: #707070;
            width: 150px;
        }

        > p {
            width: 350px;
        }
    }
`;
const ShowVehicleDetail = ({ visible, setVisible, vehicle }) => {
    const [extraData, setExtraData] = useState(false);
    const { api } = useAuth();

    const fetchExtraInfo = async () => {
        if(vehicle.type){
            await api.post("/api/lazy/manage/data", {
                actions: {
                    response: {
                        Vehicle: {
                            findBy: {
                                criteria: {
                                    company: localStorage.getItem("company"),
                                    id: vehicle.id,
                                },
                                get: "extraVehicle",
                                includes: extraFields[vehicle.type],
                                excludes: [
                                    "company",
                                    "tempDriverRoutes",
                                    "vehicleDriverRecords",
                                    "driverRoutes",
                                    "location",
                                    "station",
                                    "incidents",
                                    "events",
                                    "updatedBy",
                                    "lastUsedByUser",
                                ],
                            },
                        },
                    },
                },
            })
            .then((response) => {
                setExtraData(response.data.data.extraVehicle[0]);
            });
        }else{
            setExtraData([]);
        }
    };

    useEffect(() => {
        fetchExtraInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Modal
            title="Vehicle Timeline"
            width="500px"
            drawer="right"
            visible={visible}
            setVisible={setVisible}
            removeHeader={true}
        >
            <Spacer
                inline
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                bottom={2}
            >
                <SubTitle>Vehicle Details</SubTitle>
                <div style={{ display: "flex", justifyContent: "space-between", width: "130px", cursor: "pointer" }}>
                    <Icon.DspwNetwork size="26px" color={Theme.colors.primary} />
                    <Icon.TimeLine size="26px" color={Theme.colors.info.border} />
                    <Icon.Edit size="26px" color={Theme.colors.info.border} />
                    <Icon.Times size="26px" color="#CCCCCC" onClick={setVisible} />
                </div>
            </Spacer>

            <Text
                style={{
                    color: statusIcon[vehicle.status] ? statusIcon[vehicle.status].props.color : "#777",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {statusIcon[vehicle.status.replace(/\s/g, "")] ? (
                    statusIcon[vehicle.status.replace(/\s/g, "")]
                ) : (
                    <Icon.Info size="20px" />
                )}{" "}
                <span style={{ marginLeft: 8 }}>{vehicle.status}</span>
            </Text>

            <Spacer top={5} bottom={5}>
                <div style={{ height: 1, width: "100%", background: "#F5F5F5" }} />
            </Spacer>

            <ThirdTitle style={{ fontWeight: "normal" }}>Vehicle Information</ThirdTitle>
            <Spacer top={2} />

            <ListInfo>
                <li>
                    <span>ID</span> <p>{vehicle.vehicleId}</p>
                </li>
                <li>
                    <span>VIN</span> <p>{vehicle.vin}</p>
                </li>
                <li>
                    <span>Model</span> <p>{vehicle.model}</p>
                </li>
                <li>
                    <span>Height</span> <p>{vehicle.height}</p>
                </li>
                <li>
                    <span>State</span> <p>{vehicle.state}</p>
                </li>
                <li>
                    <span>Plate</span> <p>{vehicle.licensePlate}</p>
                </li>
                <li>
                    <span>Toll Card ID</span> <p>{vehicle.tollCardNumber}</p>
                </li>
                <li>
                    <span>Date Start</span>{" "}
                    <p>
                        {vehicle.tollCardStartDate
                            ? moment.unix(vehicle.tollCardStartDate.timestamp).utc().format("MM/DD/YYYY")
                            : "N/A"}
                    </p>
                </li>
                <li>
                    <span>Date End</span>{" "}
                    <p>
                        {vehicle.tollCardEndDate
                            ? moment.unix(vehicle.tollCardEndDate.timestamp).utc().format("MM/DD/YYYY")
                            : "N/A"}
                    </p>
                </li>
                <li>
                    <span>Tank Capacity</span> <p>{vehicle.tankCapacity}</p>
                </li>
                <li>
                    <span>Gas Type</span> <p>{vehicle.gasType}</p>
                </li>
                <li>
                    <span>Fuel Card #</span> <p>{vehicle.fuelCardNumber}</p>
                </li>
                <li>
                    <span>Insurance #</span> <p>{vehicle.insuranceNumber}</p>
                </li>
                <li>
                    <span>Insurance Card #</span> <p>{vehicle.insuranceCard ? <Link href={vehicle.insuranceCard} target='_blank'>Insurance Card</Link> : '-'}</p>
                </li>
                <li>
                    <span>Notes</span> <p>{vehicle.notes}</p>
                </li>
            </ListInfo>

            {!extraData && <Loading style={{ height: 100, margin: "0 auto", padding: 50, display: "block" }} />}
            {extraData && vehicle.type === "Owned" && <ExtraInfoOwned vehicle={extraData} />}
            {extraData && vehicle.type === "Rented" && <ExtraInfoRented vehicle={extraData} />}
            {extraData && vehicle.type === "Leased" && <ExtraInfoLeased vehicle={extraData} />}
        </Modal>
    );
};

const ExtraInfoOwned = ({ vehicle }) => {
    vehicle = normalizeDate(vehicle, ["ownedPurchaseConditionDate", "ownedSoldConditionDate"], "MM/DD/YYYY");
    return (
        <>
            <Spacer top={5} bottom={5}>
                <div style={{ height: 1, width: "100%", background: "#F5F5F5" }} />
            </Spacer>

            <ThirdTitle style={{ fontWeight: "normal" }}>Rental Information</ThirdTitle>
            <Spacer top={2} />

            <ThirdTitle style={{ fontWeight: "normal", fontSize: 14 }}>Purchase Condition</ThirdTitle>
            <Spacer top={2} />

            <ListInfo>
                <li>
                    <span>Price</span>{" "}
                    <p>{vehicle.ownedPurchaseConditionPrice ? vehicle.ownedPurchaseConditionPrice : "N/A"}</p>
                </li>
                <li>
                    <span>Odometer</span>{" "}
                    <p>
                        {vehicle.ownedPurchaseConditionOdometer ? "$" + vehicle.ownedPurchaseConditionOdometer : "N/A"}
                    </p>
                </li>
                <li>
                    <span>Date</span>{" "}
                    <p>{vehicle.ownedPurchaseConditionDate ? vehicle.ownedPurchaseConditionDate : "N/A"}</p>
                </li>
            </ListInfo>

            <Spacer top={4} />
            <ThirdTitle style={{ fontWeight: "normal", fontSize: 14 }}>Selling Condition</ThirdTitle>
            <Spacer top={2} />

            <ListInfo>
                <li>
                    <span>Price</span>{" "}
                    <p>{vehicle.ownedSoldConditionPrice ? vehicle.ownedSoldConditionPrice : "N/A"}</p>
                </li>
                <li>
                    <span>Odometer</span>{" "}
                    <p>{vehicle.ownedSoldConditionOdometer ? "$" + vehicle.ownedSoldConditionOdometer : "N/A"}</p>
                </li>
                <li>
                    <span>Date</span> <p>{vehicle.ownedSoldConditionDate ? vehicle.ownedSoldConditionDate : "N/A"}</p>
                </li>
            </ListInfo>
        </>
    );
};

const ExtraInfoRented = ({ vehicle }) => {
    vehicle = normalizeDate(vehicle, ["rentalStartDate", "rentalEndDate"], "MM/DD/YYYY");
    return (
        <>
            <Spacer top={5} bottom={5}>
                <div style={{ height: 1, width: "100%", background: "#F5F5F5" }} />
            </Spacer>

            <ThirdTitle style={{ fontWeight: "normal" }}>Rental Information</ThirdTitle>
            <Spacer top={2} />
            <ListInfo>
                <li>
                    <span>Rental Company</span> <p>{vehicle.rentalCompany}</p>
                </li>
                <li>
                    <span>Agreement #</span> <p>{vehicle.rentalAgreementNumber}</p>
                </li>
                <li>
                    <span>Price per month</span>{" "}
                    <p>{vehicle.rentalPricePerMonth ? "$" + vehicle.rentalPricePerMonth : "N/A"}</p>
                </li>
                <li>
                    <span>Deposit</span> <p>{vehicle.rentalDeposit ? "$" + vehicle.rentalDeposit : "N/A"}</p>
                </li>
                <li>
                    <span>Date Start</span> <p>{vehicle.rentalStartDate ? vehicle.rentalStartDate : "N/A"}</p>
                </li>
                <li>
                    <span>Date End</span> <p>{vehicle.rentalEndDate ? vehicle.rentalEndDate : "N/A"}</p>
                </li>
                <li>
                    <span>Initial Odometer</span> <p>{vehicle.rentalInitialConditionOdometer}</p>
                </li>
            </ListInfo>
        </>
    );
};

const ExtraInfoLeased = ({ vehicle }) => {
    vehicle = normalizeDate(vehicle, ["leasingStartDate", "leasingEndDate"], "MM/DD/YYYY");
    return (
        <>
            <Spacer top={5} bottom={5}>
                <div style={{ height: 1, width: "100%", background: "#F5F5F5" }} />
            </Spacer>

            <ThirdTitle style={{ fontWeight: "normal" }}>Leasing Information</ThirdTitle>
            <Spacer top={2} />
            <ListInfo>
                <li>
                    <span>Company</span> <p>{vehicle.leasingCompany}</p>
                </li>
                <li>
                    <span>Agreement #</span> <p>{vehicle.leasingAgreementNumber}</p>
                </li>
                <li>
                    <span>Price per month</span> <p>${vehicle.leasingPricePerMonth}</p>
                </li>
                <li>
                    <span>Deposit</span> <p>${vehicle.leasingDeposit}</p>
                </li>
                <li>
                    <span>Date Start</span> <p>{vehicle.leasingStartDate ? vehicle.leasingStartDate : "N/A"}</p>
                </li>
                <li>
                    <span>Date End</span> <p>{vehicle.leasingEndDate ? vehicle.leasingEndDate : "N/A"}</p>
                </li>
                <li>
                    <span>Initial Odometer</span>{" "}
                    <p>{vehicle.leasingInitialConditionOdometer ? vehicle.leasingInitialConditionOdometer : "N/A"}</p>
                </li>
            </ListInfo>
        </>
    );
};

export default App;
