import moment from "moment";
import { confirm } from "../../../components/Confirm";
import TimeFormat from "../../../components/TimeFormat";
import { showFlexibleShiftBlockForm } from "../Week/components/shiftBlockForms";

export const isEmptyWeekBoxNonSelectable = async (state, stateKey, driver, currDate) => {
	if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE' && driver.id == '0.0.0') {
		return true
	}
	let dayBox = await state.state.filter((obj) => obj.id == driver.id)[0]["w" + stateKey[1][1]];
	if (dayBox[0]) {
		let returnOrNot = false;
		await dayBox.map((s) => {
			if (s.category == 8 || s.category == 9) {
				returnOrNot = true;
			}
		});
		if (returnOrNot) {
			return true;
		}
	}
	let day = stateKey[1][1];
	let week = moment(currDate, "YYYY-MM-DD").isoWeek();
	if (
		driver.employeeStatus == false &&
		(driver.inactiveDate < moment(currDate).week(week).day(day).format("YYYY-MM-DD") ||
			driver.terminationDate < moment(currDate).week(week).day(day).format("YYYY-MM-DD") ||
			driver.offboardedDate < moment(currDate).week(week).day(day).format("YYYY-MM-DD"))
	) {
		return true
	}
	return false;
}

export const isEmptyDayBoxNonSelectable = async (driver) => {
	if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE' && driver.id == '0.0.0') {
		return true
	}
	let timeoffornot = false;
	await Object.keys(driver).map((d) => {
		if (d.indexOf("shift_") > -1) {
			console.log(driver[d].category);
			if (driver[d].category == 8) {
				timeoffornot = true;
			}
		}
	});
	if (timeoffornot) {
		return false;
	}
	let getSelectedDate = document.querySelector("#day-view-calendar").value;
	let selectedDate = moment(getSelectedDate).format("YYYY-MM-DD");
	/*if ( selectedDate < moment().format("YYYY-MM-DD"))
		return alert("You cannot add shifts to the past");*/

	if (
		driver.employeeStatus == false &&
		(driver.inactiveDate < selectedDate ||
			driver.terminationDate < selectedDate ||
			driver.offboardedDate < selectedDate)
	) {
		return true
	}
}

const getSequenceCriteria = (selectedRows) => {
	const criteria = []
	selectedRows.forEach(row => {
		if (row.id != "0.0.0") {
			const existingDriver = criteria.find(c => c.driverId === row.id)
			if (existingDriver) {
				const tempDates = [...existingDriver.selectedDate]
				tempDates.push(row.date)
				existingDriver.selectedDate = [...new Set(tempDates)]
			} else {
				criteria.push({
					routeId: "",
					driverId: row.id,
					selectedDate: [row.date]
				})
			}
		}
	})
	return criteria
}

const getCreateEventForConsicutiveShiftCriteria = (sequences = []) => {
	const criteria = []
	sequences.forEach(seq => {
		if (seq.status) {
			criteria.push({
				driverId: seq.driverId,
				message: seq.message.bot,
			})
		}
	})
	return criteria
}

const generateDayViewAddCriteria = (selectedRows, shift) => {
	const criteria = []
	selectedRows.forEach(row => {
		criteria.push({
			method: "addTemp",
			routeId: null,
			isNew: true,
			isTemp: true,
			hasAlert: true,
			newDriverId: row.driverId,
			oldDriverId: row.driverId,
			newDate: row.date,
			oldDate: row.date,
			routeStatus: shift.routeStatus,
			startTime: shift.startTime,
			endTime: shift.endTime,
			shiftId: shift.shiftType,
			timezoneOffset: new Date().getTimezoneOffset(),
			shiftInvoiceType: shift.shiftInvoiceType,
			item: {},
			onCall: false,
			eventData: {
				event_name: "Multiple Shift Created",
				message: `Shift created "${shift.extra.name}" by ${localStorage.getItem('friendlyName')} posted to ${row.name} on ${moment().format("dddd, MMMM Do")}`
			}
		})
	})
	return criteria
}

export const openCreateMultipleShiftPopup = async (state, selectedRows = [], api, schedulerApi, isDayView = false, currDate) => {
	try {
		const newSelectedRows = selectedRows.map(item => ({ ...item, driverId: item.id }));
		let now = moment(currDate);

		const params1 = {
			"actions": {
				"response": {
					"DriverRoute": {
						"custom": {
							"functionName": "multicheckSequenceDriverRoute",
							"get": "checkSequenceDriverRoute",
							"excludes": [],
							"criteria": getSequenceCriteria(newSelectedRows)
						}
					}
				}
			}
		};
		const response = await api.post("/api/lazy/manage/data", params1);
		if (response.data.data.checkSequenceDriverRoute.status) {
			const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
			if (!wc) return undefined;

			const params1 = {
				actions: {
					response: {
						DriverRoute: {
							custom: {
								functionName: "createEventForConsicutiveShift",
								get: "createEventForConsicutiveShift",
								excludes: [],
								criteria: getCreateEventForConsicutiveShiftCriteria(response.data.data.checkSequenceDriverRoute.multiSequence),
							},
						},
					},
				},
			};
			await api.post("/api/lazy/manage/data", params1);
		}

		const paramsStationObject = {
			"actions": {
				"response": {
					"Station": {
						"findOneBy": {
							"criteria": {
								"id": state.selectedStation
							},
							"get": "Station",
							"includes": {
								"0": "id",
								"1": "name",
								"2": "stationAddress",
								"3": "stationGeofence",
								"4": "parkingLotAddress",
								"5": "parkingLotGeofence",
								"6": "code",
								"7": "timezone"
							},
							"excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
						}
					}
				}
			}
		}
		let responseStationObj = await api.post('/api/lazy/manage/data', paramsStationObject);
		let timeZoneShortName = null;
		if (responseStationObj.data.data.Station) {
			let currentSelectedStationObj = responseStationObj.data.data.Station;
			timeZoneShortName = currentSelectedStationObj.timezone;
		}

		const filteredShifts = newSelectedRows.filter(shift => shift.isTrain || shift.isDuty);
		const filteredIsTrain = filteredShifts.find(shift => shift?.isTrain);
		const filteredIsDuty = filteredShifts.find(shift => shift?.isDuty);
		const firstRow = newSelectedRows[0];
		if (firstRow.id === '0.0.0') {
			firstRow.stationId = state.selectedStation
		}
		const shiftOptionsResponse = await schedulerApi.getShiftOptions({
			shift: {
				...firstRow, isTrain: filteredIsTrain?.isTrain, isDuty: filteredIsDuty?.isDuty,
				isEditTrainDutyShift: filteredIsDuty?.isEditTrainDutyShift
			},
		});

		let invoiceTypes = shiftOptionsResponse.data.data.invoiceTypes;
		const ShiftTypeOption = shiftOptionsResponse.data.data.shift;
		const shift = await showFlexibleShiftBlockForm({
			options: {
				shiftTypes: ShiftTypeOption,
				shiftInvoiceTypes: invoiceTypes,
				loaded: true,
			},
			title: "Add Shift",
			timeZoneShortName: timeZoneShortName,
			driver: {},
		});
		if (!shift) return undefined;
		const responseDriverSkill = await schedulerApi.checkMultipleDriverSkills(newSelectedRows, shift);
		if (responseDriverSkill.data.data.result.some(res => !res.result)) {
			const confirmation = await confirm(
				`WARNING: Few drivers does not have the required skill to be assigned this shift type. Are you sure you want to continue?`
			);
			if (!confirmation) {
				return undefined;
			}
		}

		const getWeekTo = (row, currDate) => {
			let day = row.stateKey[1][1];
			let normalWeek = moment(currDate, "YYYY-MM-DD").week();
			let date = now.week(normalWeek).day(day);
			return date.weekday()
		}

		let params = {
			actions: {
				response: {
					TempDriverRoute: {
						custom: {
							functionName: "multiUpdateDriverRoute",
							get: "tempDriverRoute",
							excludes: [],
							criteria: isDayView ? generateDayViewAddCriteria(selectedRows, shift) : selectedRows.map(driver => ({
								method: 'addTemp',
								timezoneOffset: new Date().getTimezoneOffset(),
								isNew: true,
								isTemp: true,
								newDriverId: driver.id || null,
								newDate: driver.date,
								isOpenShift: driver.id ? false : true,
								shiftId: shift.shiftType,
								routeId: "",
								weekTo: getWeekTo(driver, currDate),
								shiftInvoiceType: shift.shiftInvoiceType,
								startTime: shift.startTime,
								endTime: shift.endTime,
								routeStatus: shift.routeStatus,
								isVoluntary: shift.isVoluntary,
								autoApproveDriverRequest: shift.autoApproveDriverRequest,
								item: {
									...shift,
									type: shift.extra.name,
									hour:
										TimeFormat({ time: shift.extra.startTime }) +
										"–" +
										TimeFormat({ time: shift.extra.endTime }),
									bg: shift.extra.color,
									color: shift.extra.textColor,
									isNew: true,
									//note: "–",
									time: "00:00",
									stationId: driver.id ? driver.stationId : false,
								},
								hasAlert: true,
								eventData: {
									event_name: "Multiple Shift Created",
									message: `Shift created "${shift.extra.name}" by ${localStorage.getItem('friendlyName')} posted to ${driver.name} on ${moment().format("dddd, MMMM Do")}`
								}
							})),
						},
					},
				},
			},
		};

		const finalResponse = await api.post("/api/lazy/manage/data", params);
		return finalResponse;
	} catch (error) {
		console.error("Error in openCreateMultipleShiftPopup:", error);
		return undefined;
	}
};