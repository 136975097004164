import React from "react";
import styled from "styled-components";
import {SubTitle, Button, Icon} from "@dspworkplace/ui";

const ModalContent = styled.div`
    box-sizing: border-box;
    background-color: #fefefe;
    border: 1px solid #888;
    font-size: 14px;
    width: 644px;
    height: 400px;
    display: grid;
    grid-template-columns: 604px;
    grid-template-rows: 40px 100px 108px 40px;
    grid-column-gap: 0;
    grid-row-gap: 20px;
    padding: 20px;
`;

const Row = styled.div`
    width: ${props => (props.width ? props.width : "604px")};
    height: ${props => (props.height ? props.height : "40px")};
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
`;

const Title = styled(SubTitle)`
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
`;

const CustomButton = styled(Button)`
    width: ${props => (props.width ? props.width : "40px")};
    height: ${props => (props.height ? props.height : "40px")};
    font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    justify-self: ${props => (props.direction ? props.direction : "end")};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : "2px")};
    border-bottom-right-radius: ${props => (props.bbrr ? props.bbrr : props.borderRadius ? props.borderRadius : "2px")};
    border-top-right-radius: ${props => (props.btrr ? props.btrr : props.borderRadius ? props.borderRadius : "2px")};
    border-bottom-left-radius: ${props => (props.bblr ? props.bblr : props.borderRadius ? props.borderRadius : "2px")};
    border-top-left-radius: ${props => (props.btlr ? props.btlr : props.borderRadius ? props.borderRadius : "2px")};

    &:active,
    &:focus,
    &:after {
        outline: 0;
        border: 0;
        text-shadow: none;
        box-shadow: none;
    }
`;

const TextArea = styled.textarea`
    border: 1px solid rgb(204, 204, 204);
    border-radius: 2px;
    opacity: 1;
    font-size: 14px;
    font-family: circe-rounded, serif;
    resize: none;
    padding: 12px;
    width: ${props => (props.width ? props.width : "auto")};
    height: ${props => (props.height ? props.height : "auto")};

    &:hover,
    &:hover,
    &:focus,
    &:active {
        border: 1px solid rgb(0, 113, 188);
        box-shadow: rgb(157, 221, 244) 0px 0px 6px;
    }
`;

const FilesGrid = styled.div`
    display: grid;
    grid-template-columns: 16px 548px 16px;
    grid-template-rows: 108px;
    grid-column-gap: 12px;
    grid-template-areas: "arrowLeft fileScroll arrowRight";
`;

const FilesScroll = styled.div`
    grid-area: fileScroll;
    width: 548px;
    height: 108px;
    overflow: hidden;
    overflow-x: scroll;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-template-rows: 100px;
    grid-column-gap: 10px;
    align-content: center;
`;

const Arrow = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-area: ${props => (props.area ? props.area : "arrowLeft")};
    cursor: pointer;

    svg {
        padding: ${props => (props.svgPad ? props.svgPad : "0")};
    }
    svg:hover {
        background-color: #eaf0f6;
        border-radius: 50px;
        cursor: pointer;
        padding: ${props => (props.svgPad ? props.svgPad : "0")};
    }
`;

const Modal = styled.div`
    display: ${props => (props.display ? props.display : "flex")};
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: circe-rounded, serif;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const FileContainer = styled.div`
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 2px;
    border-width: 4px;
    border-style: solid;
    border-color: #eaf0f6;
    
`;

const AnyFile = styled.div`
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    background-color: ${props => (props.bgColor ? props.bgColor : "#EAF0F6")};
    padding: 4px;
    border-radius: 2px;
    font-family: circe-rounded, sans-serif;
    font-size: 10px;
    flex-direction: column;
    text-align: center;
`;

const Image = styled.img`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &:hover {
        opacity: 0.7;
    }
`;

const Video = styled.video`
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &:hover {
        opacity: 0.8;
    }
`;

// @ts-ignore
const Overlay = styled.div`
    position: absolute;
    height: 100px;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: rgba(0, 0, 0, 0.1);
`;

const IconX = styled.div`
    position: relative;
    top: -104px;
    right: -88px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eaf0f6;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;
    opacity: 0;

    &:hover {
        opacity: 1;
    }
`;

type Props = {
    state: any;
    dispatch: (arg: any) => void;
};

const FileModal = (props: Props) => {
    const {state, dispatch} = props;

    return (
        <Modal display={state.openFileModal ? "flex" : "none"}>
            <ModalContent>
                <Row>
                    <Title>Upload Files</Title>
                    <CustomButton
                        type="cancel"
                        fontWeight="normal"
                        onClick={() => {
                            dispatch({type: "setOpen", openFileModal: false});
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Row>
                <TextArea data-id="message" placeholder="You can a write message before uploading your files..."/>
                <FilesGrid>
                    <Arrow
                        onClick={() =>
                            document.querySelector("[data-id='fileScroll']")!.scrollBy({
                                top: 0,
                                left: -100,
                                behavior: "smooth"
                            })
                        }
                        svgPad="0 1px 0 0"
                    >
                        <Icon.ArrowLeft size="16px"/>
                    </Arrow>
                    <FilesScroll data-id="fileScroll">
                        {state.files.map((item, index) => {
                            return (
                                <div key={index} className='containerFile'>
                                    <FileContainer>
                                        {item.type.includes("image") && <Image src={URL.createObjectURL(item)}/>}
                                        {item.type.includes("video") && (
                                            <Video width="100%" height="100%" controls>
                                                <source src={URL.createObjectURL(item)} type={item.type}/>
                                                Your browser does not support the video tag.
                                            </Video>
                                        )}
                                        {!item.type.includes("image") && !item.type.includes("video") && (
                                            <AnyFile>
                                                <div>{item.type}</div>
                                                <div>{item.name}</div>
                                            </AnyFile>
                                        )}
                                    </FileContainer>
                                    <IconX className={"overlay"}>
                                        <Icon.Times
                                            size="16px"
                                            onClick={() => {
                                                dispatch({type: "removeFile", removeIndex: index});
                                            }}
                                        />
                                    </IconX>
                                </div>
                            );
                        })}
                    </FilesScroll>
                    <Arrow
                        area="arrowRight"
                        onClick={() =>
                            document.querySelector("[data-id='fileScroll']")!.scrollBy({
                                top: 0,
                                left: 100,
                                behavior: "smooth"
                            })
                        }
                        svgPad="0 0 0 1px"
                    >
                        <Icon.ArrowRight size="16px"/>
                    </Arrow>
                </FilesGrid>

                <CustomButton
                    width="117px"
                    height="40px"
                    type="primary"
                    onClick={() => {
                        let elem = document.querySelector("[data-id='message']")!;
                        if (elem["value"].trim().length > 0) {
                            dispatch({type: "sendMessage", content: elem["value"].trim()});
                            dispatch({
                                type: "sendByS3",
                                openFileModal: false
                            });
                        } else {
                            dispatch({
                                type: "sendByS3",
                                openFileModal: false,
                            });
                        }
                    }}
                >
                    Upload
                </CustomButton>
            </ModalContent>
        </Modal>
    );
};

export default React.memo(FileModal);
